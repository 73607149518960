import React from 'react';

const TitleAusentismosTable = ({ empleado }) => {
    return (
        <div className="row">
            <div className="col margintb">
                <div className="row">
                    <div className="col">
                        <h4>Consulta de Ausentismos</h4>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-6">
                        <strong>Empleado: </strong>{ empleado.nombreCompleto }
                    </div>
                    <div className="col">
                        <strong>Número Único de Empleado: </strong>{ empleado.empleadoClave }
                    </div>
                    <div className="col">
                        <strong>Nómina: </strong>{ empleado.nominaNombre }
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default TitleAusentismosTable;