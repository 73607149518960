import React from 'react';  
import ModalService from './../../../../Usuario/ModalService/ModalService';  
import FormularioReciboPago from './FormularioReciboPago';

const ModalConsultaReciboPago = ({ openEdit, setOpenEdit, errorTable, editar, successTable, elementSelect, nombreModulo  }) => {
 
    const cerrarModal = () => { 
        setOpenEdit(false); 
    }; 

    return (
        <ModalService 
            title="Recibo de Pago" 
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal} 
            minWidth='80%' 
        > 
            <FormularioReciboPago
                elementSelect={elementSelect}
            /> 
            
        </ModalService>
    );
};

export default ModalConsultaReciboPago;