import React from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, servicios_grupohit, servicios_grupohit_org, servicios_grupohit_rh, servicios_grupohit_admon } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import AlertErrorDescarga from './AlertErrorDescarga';

import { urlExcelProyectos, urlExcelEmpleados, excelPlazas, excelProrrogaContratos } from './ArchivoProyectos';

export const modulosDeCarga = [
    {
        icono: "storage",
        id: 21,
        nombre: "Proyectos",
        superior: 18
    },
    {
        icono: "people",
        id: 30,
        nombre: "Empleados",
        superior: 29,
    },
    {
        icono: "view-list",
        id: 25,
        nombre: "Tabuladores de Sueldo",
        superior: 22,
    },
    {
        icono: "view_module",
        id: 27,
        nombre: "Tabuladores de Quinquenio",
        superior: 22,
    },
    {
        icono: "account_tree",
        id: 901,
        nombre: "Plazas",
        superior: 35,
    },
    {
        icono: "event_available",
        id: 902,
        nombre: "Prórrogas de Contrato",
        superior: 35,
    },
    {
        icono: "rotate_left",
        id: 903,
        nombre: "Cálculos de Retroactivo",
        superior: 35,
    },
    {
        icono: "today",
        id: 904,
        nombre: "Control de Pagos Únicos",
        superior: 35,
    },
    {
        icono: "delete_sweep",
        id: 905,
        nombre: "Baja Masiva de Empleados",
        superior: 35,
        sinCarga: true,
    },
    {
        icono: "update",
        id: 906,
        nombre: "Actualización de Proyectos",
        superior: 35,
        sinCarga: true,
    },
];

/* Funcion que permitira determinar si un modulo existe en los modulos obtenidos por esl servicio de menu */
export function obtenerAccesos( menu, modulo ) {
    const superior = menu.find( moduloSuperior => moduloSuperior === modulo.superior );
    console.log( superior );
}


/* ---------------------- Funciones para obtener informacion necesaria para cada carga de archivos ---------------------- */
export function obtenerInformacionExcel( idModulo, setInformacion ){
    switch( idModulo ) {
        case 21: //Proyectos
            setInformacion({
                informacion: urlExcelProyectos,
                nombreArchivo: 'Masivo_Proyectos',
            });
            break;
        case 30: //Empleados
            setInformacion({
                informacion: urlExcelEmpleados,
                nombreArchivo: 'Masivo_Empleados',
            });
            break;
        case 25: //Tabuladores Sueldos
            axiosTabuladoresSueldo( setInformacion );
            break;
        case 27: //Quinquenios
            axiosTabuladoresQuinquenios( setInformacion );
            break;
        case 83: //Pagos Únicos
            axiosNominaPagosUnicos( setInformacion );
            break;
        case 901: //Plazas
            setInformacion({
                informacion: excelPlazas,
                nombreArchivo: 'Masivo_Plazas',
            });
            break;
        case 902:
            setInformacion({
                informacion: excelProrrogaContratos,
                nombreArchivo: 'Prórroga_Contratos',
            });
            break;
        case 905: //Baja Masiva Empleados
            axiosEmpleadosActivos( setInformacion );
            break;
        case 906: //Actualización de Proyectos
            axiosInformacionProyectos( setInformacion );
            break;

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

/* --- Funciones para obtener fomratoExcel de Tabuladores de Sueldo */
const axiosTabuladoresSueldo = ( setInformacion ) => {
    axios.get( `${servicios_grupohit_catalogo}/nvlaboral/byrelab/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacion({
                        informacion: formatoTabuladoresSueldo(respuesta.data.dataResponse),
                        nombreArchivo: 'Carga_tabuladores',
                    });
                    break;
                case 404:
                    //setInformacion( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
}

const formatoTabuladoresSueldo = ( tabuladoresSueldo ) =>  {
    return tabuladoresSueldo.map( item => ({
        //'Clave del indicador / Nivel': (item.nivlaboralClave !== null) ? item.nivlaboralClave : '',
        'Nivel': (item.nivlaboralClave !== null) ? item.nivlaboralClave : '',
        'CLAVE DEL CONCEPTO 1': '',
        'CLAVE DEL CONCEPTO 2': '',
        'CLAVE DEL CONCEPTO 3': '',
        'CLAVE DEL CONCEPTO N': '',
    }));
}

/* --- Funciones para obtener fomratoExcel de Tabuladores de Quinquenios */
const axiosTabuladoresQuinquenios = ( setInformacion ) => {
    axios.get( `${servicios_grupohit_catalogo}/nvlaboral/byrelab/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacion({
                        informacion: formatoTabuladoresQuinquenios(respuesta.data.dataResponse),
                        nombreArchivo: 'Tabulador_quinquenios',
                    });
                    break;
                case 404:
                    //setInformacion( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
}

const axiosNominaPagosUnicos = setInformacion => {
    axios.get(`${servicios_grupohit}/buscador/pagos/pendientes/autorizados`, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                const pagosUnicos = res.data.dataResponse;
                setInformacion({
                    informacion: formatoNominaPagosUnicos(pagosUnicos),
                    nombreArchivo: 'Nominas_Pagos_Unicos',
                });
                break;
            case 404:
                //setInformacion( [] );
                break;
            default:
                break;
        }
    })
    .catch(error => { console.log( error ) })
};

const formatoTabuladoresQuinquenios = ( tabuladoresSueldo ) =>  {
    return tabuladoresSueldo.map( item => ({
        'Nivel': (item.nivlaboralClave !== null) ? item.nivlaboralClave : '',
    }));
}

const formatoNominaPagosUnicos = info => {
    if(info.length > 0) {
        return info.map( item => ({
            'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
            'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
            'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
            'NOMBRE': `${(item.empleadoPat) ? `${item.empleadoPat}` : ''} ${(item.empleadoMat) ? `${item.empleadoMat}` : ''} ${(item.empleadoNom) ? `${item.empleadoNom}` : ''}`,
            'CLAVE DE LA NÓMINA': (item.nominaClave !== null) ? item.nominaClave : '',
            'NOMBRE DE LA NÓMINA': (item.nominaNombre !== null) ? item.nominaNombre : '',
            'FECHA DE APLICACIÓN': '',
            'CANTIDAD': '',
            'CLAVE DE CONCEPTO': '',
            'IMPORTE': '',
            'AÑO INICIAL': '',
            'PERIODO INICIAL': '',
            'AÑO FINAL': '',
            'PERIODO FINAL': '',
        }));
    } else {
        return [{
            'NUE': null,
            'NUP': null,
            'RFC': null,
            'NOMBRE': null,
            'CLAVE DE LA NÓMINA': null,
            'NOMBRE DE LA NÓMINA': null,
            'FECHA DE APLICACIÓN': null,
            'CANTIDAD': null,
            'CLAVE DE CONCEPTO': null,
            'IMPORTE': null,
            'AÑO INICIAL': null,
            'PERIODO INICIAL': null,
            'AÑO FINAL': null,
            'PERIODO FINAL': null,
        }]
    }
};

/* --- Funciones para obtener fomratoExcel de Baja Masiva de Empleados */
const axiosEmpleadosActivos = ( setInformacion ) => {
    //axios.get( `${servicios_grupohit_rh}/empleado/activos/`, headersAuth() )
    axios.get( `${servicios_grupohit_rh}/empleado/infovista/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacion({
                        informacion: formatoBajaEmpleados(respuesta.data.dataResponse),
                        nombreArchivo: 'Baja_Masiva_Empleados',
                    });
                    break;
                case 404:
                    //setInformacion( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
}

const formatoBajaEmpleados = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
        'NOMBRE COMPLETO': (item.nombreCompleto !== null) ? item.nombreCompleto : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',

        'CLAVE PRESUPUESTAL': (item.cpresupuestalClave !== null) ? "'" + item.cpresupuestalClave : '',
        'CLAVE DEL PUESTO': (item.puestoCve !== null) ? item.puestoCve : '',
        'PUESTO': (item.puestoNom !== null) ? item.puestoNom : '',
        'CURP': (item.empleadoCurp !== null) ? item.empleadoCurp : '',

        'FECHA DE BAJA (DD/MM/YYYY)': '',
        'MOTIVO DE BAJA': '',
        'DESCRIPCIÓN DE LA BAJA': '',
    }));
}

/* --- Funciones para obtener fomratoExcel de Baja Masiva de Empleados */
const axiosInformacionProyectos = ( setInformacion ) => {
    axios.get( `${servicios_grupohit_admon}/presupuestal/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacion({
                        informacion: formatoActualizacionProyectos(respuesta.data.dataResponse),
                        nombreArchivo: 'Actualización_De_Proyectos',
                    });
                    break;
                case 404:
                    //setInformacion( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
}

const formatoActualizacionProyectos = ( proyectos ) =>  {
    return proyectos.map( item => ({
        'CLAVE PRESUPUESTAL': (item.cpresupuestalclave !== null) ? "'" + item.cpresupuestalclave : '',

        'UNIDAD RESPONSABLE': (item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaUr !== null) ? item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaUr : '',
        'NOMBRE UNIDAD RESPONSABLE': (item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaRazonSocial !== null) ? item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaRazonSocial : '',
        'UNIDAD EJECUTORA': (item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoraclave !== null) ? `'${item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoraclave.substring(3,6)}` : '',
        'NOMBRE UNIDAD EJECUTORA': (item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoranom !== null) ? item.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoranom : '',

        'PROGRAMA': (item.obraAccion.proyecto.subPrograma.programa.programaclave !== null) ? `'${item.obraAccion.proyecto.subPrograma.programa.programaclave.substring(6,9)}` : '',
        'NOMBRE PROGRAMA': (item.obraAccion.proyecto.subPrograma.programa.programanom !== null) ? item.obraAccion.proyecto.subPrograma.programa.programanom : '',
        'SUBPROGRAMA': (item.obraAccion.proyecto.subPrograma.subProgramaClave !== null) ? `'${item.obraAccion.proyecto.subPrograma.subProgramaClave.substring(9,11)}` : '',
        'NOMBRE SUBPROGRAMA': (item.obraAccion.proyecto.subPrograma.subProgramaNom !== null) ? item.obraAccion.proyecto.subPrograma.subProgramaNom : '',

        'PROYECTO ACCIÓN': (item.obraAccion.proyecto.proyectoclave !== null) ? `'${item.obraAccion.proyecto.proyectoclave.substring(11,14)}` : '',
        'NOMBRE PROYECTO ACCIÓN': (item.obraAccion.proyecto.proyectonom !== null) ? item.obraAccion.proyecto.proyectonom : '',
        'OBRA ACCIÓN': (item.obraAccion.obraAccionClave !== null) ? `'${item.obraAccion.obraAccionClave.substring(14,17)}` : '',
        'NOMBRE OBRA ACCIÓN': (item.obraAccion.obraAccionNom !== null) ? item.obraAccion.obraAccionNom : '',

        'CENTRO DE TRABAJO': (item.cpresupuestalclave !== null) ? `'${item.cpresupuestalclave.substring(17,19)}` : '',
        'NOMBRE CENTRO DE TRABAJO': (item.cpresupuestalnom !== null) ? item.cpresupuestalnom : '',

        'NUEVA UNIDAD RESPONSABLE': '',
        'NUEVO NOMBRE UNIDAD RESPONSABLE': '',
        'NUEVA UNIDAD EJECUTORA': '',
        'NUEVO NOMBRE UNIDAD EJECUTORA': '',

        'NUEVO PROGRAMA': '',
        'NUEVO NOMBRE PROGRAMA': '',
        'NUEVO SUBPROGRAMA': '',
        'NUEVO NOMBRE SUBPROGRAMA': '',

        'NUEVO PROYECTO ACCIÓN': '',
        'NUEVO NOMBRE PROYECTO ACCIÓN': '',
        'NUEVA OBRA ACCIÓN': '',
        'NUEVO NOMBRE OBRA ACCIÓN': '',

        'NUEVO CENTRO DE TRABAJO': '',
        'NUEVO NOMBRE CENTRO DE TRABAJO': '',
    }));
}

/* -------------------------------- Funciones para carga de archivos & axios a servicios -------------------------------- */
export function axiosExcel( idModulo, archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ){
    switch( idModulo ) {
        case 21: //Proyectos
            axiosExcelProyectos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;
        case 30: //Empleados
            axiosExcelEmpleados( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;
        case 25: //Tabuladores Sueldos
            axiosExcelTabuladores( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;
        case 27: //Quinquenios
            axiosExcelTabQuinquenios( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;
        case 83: //Nominas / Pagos Unicos
            axiosExcelNominasPagosUnicos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;
        case 901: //Plazas
            axiosExcelPlazas( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;
        case 905: //Baja Masiva Empleados
            axiosExcelBajaEmpleados( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;
        case 906: //Actualización de Proyectos
            axiosActualizacionProyectos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            break;

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const axiosExcelTabuladores = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ) => {
    setLoading(true);
    axios.post( `${servicios_grupohit_org}/tab/masivos/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_tabuladores" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( respuesta.data.msg );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
        .finally(() => setLoading(false));
}

const axiosExcelTabQuinquenios = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ) => {
    setLoading(true);
    axios.post( `${servicios_grupohit_org}/quinquenios/masivos/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_quinquenios" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
        .finally(() => setLoading(false));
}

const axiosExcelNominasPagosUnicos = (archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading) => {
    setLoading(true);
    axios.post(`${servicios_grupohit_rh}/movimientos/pagosUnicos/excel/`, archivoExcel, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                successAlert('El archivo se cargó correctamente');
                setProgreso(0);
                setDocumentoExcel(null);
                break;
            case 400:
                setProgreso(0);
                setError(<AlertErrorDescarga data={res.data} fileName="Error_Pagos_Unicos" />);
                break;
            default:
                setProgreso(0);
                errorAlert(res.data.msg);
                break;
        }
    })
    .catch(error => {
        setProgreso(0);
        errorAlert('Error al cargar archivo');
    })
    .finally(() => setLoading(false));
};

const axiosExcelProyectos = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ) => {
    setLoading(true);
    axios.post( `${servicios_grupohit}/masivos/proyectos/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Proyectos" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
        .finally(() => setLoading(false));
}

const axiosExcelEmpleados = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ) => {
    setLoading(true);
    axios.post( `${servicios_grupohit_rh}/empleado/masivos/procesos/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                case 404:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Empleados" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
        .finally(() => setLoading(false));
}

const axiosExcelPlazas = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ) => {
    setLoading(true);
    axios.post( `${servicios_grupohit_org}/plaza/excel/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                case 404:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Plazas" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
        .finally(() => setLoading(false));
}

const axiosExcelBajaEmpleados = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ) => {
    setLoading(true);
    axios.post( `${servicios_grupohit_rh}/empleado/status/masivos/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                case 404:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Bajas_Empleados" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
        .finally(() => setLoading(false));
}

const axiosActualizacionProyectos = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading ) => {
    setLoading(true);
    axios.put( `${servicios_grupohit}/masivos/proyectos/actualiza/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                case 404:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Actualizacion_Proyectos" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
        .finally(() => setLoading(false));
}