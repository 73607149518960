import { GUARDA_PERIODO_ACTUAL, LIMPIA_PERIODO_ACTUAL } from '../actionTypes';

const initialstate = {
    nominasRL: [],
    periodoActual: null
}

const PeriodoActualReducer = (state = initialstate, action) => {

    switch( action.type ) {
        case GUARDA_PERIODO_ACTUAL:
            return{
                ...state,
                nominasRL: action.payload.nominasRL,
                periodoActual: action.payload.periodoActual
            }
        case LIMPIA_PERIODO_ACTUAL:
            return {
                ...state,
                nominasRL: [],
                periodoActual: null
            }
        default:
            return state;
    }
}

export default PeriodoActualReducer;