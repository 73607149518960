import React, { useState, Fragment} from 'react';
import { connect } from 'react-redux';
import { eliminarEmpleado } from '../../../../../../Redux/actions/EmpleadosActions';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteEmpleadosEspeciales = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable, eliminarEmpleado }) => {

    const [isLoading, setIsLoading] = useState(false);

    const axiosEliminar = () => { 
        setIsLoading(true);
        eliminarEmpleado(elementSelect.id, eliminar, errorTable, setIsLoading);
    };

    return (
        <Fragment>
            <Alertwarning 
                show={alertWarning} 
                SetopenALert={setAlertWarning} 
                text={'¡El empleado será eliminado!'} 
                textButtonAceptar="Aceptar" 
                textButtonCancelar="Cancelar" 
                action={axiosEliminar} 
                requirePassword={true} 
                subtitle={"Si existen acumulados o CFDI's timbrados es necesario eliminarlos antes de realizar el movimiento."}
            />
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading} />
        </Fragment>
    );
};

const mapDispatchToProps = { eliminarEmpleado };

export default connect(null, mapDispatchToProps)(ModalDeleteEmpleadosEspeciales);