import React, { Fragment, useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import axios from 'axios';
import { servicios_grupohit_plantillas, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import TitleCard from './components/TitleCard';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import TableEmpleados from './TableEmpleados';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import './styles/styles.css';

const AssignPlantilla = ({ nombreModulo, plantilla, onShowTable, empleadosActivos, obtenerEmpleadosActivos }) => {

    const [openLoading, setOpenLoading] = useState(false);
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [contieneSecuencia, setContieneSecuencia] = useState(false);

    useEffect(() => {
        obtenerEmpleadosActivos((msg) => {
            setMensajeError(msg);
            setOpenAlertSyE(true);
        });

        //llamada a servicio de variables de la plantilla 
        axios.get(`${servicios_grupohit_plantillas}/variables/${plantilla.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const vSecuencia = res.data.dataResponse.find(item => item === '{{secuencia}}');
                    
                    if(vSecuencia === '{{secuencia}}') {
                        setContieneSecuencia(true);
                    }
                    break;
                case 404:
                    setContieneSecuencia(false);
                    break;
                default:
                    setMensajeError(res.data.msg);
                    setOpenAlertSyE(true);
                    setOpenLoading(false);
                    break;
            }
        })
        .catch(error => {
            setMensajeError(verifique_conexion);
            setOpenAlertSyE(true);
            setOpenLoading(false);
        });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const crearZip = data => {
        var zip = new JSZip();

        data.forEach((value,i) => {
            zip.file(`${value.empleadoClave} - ${value.nombreCompleto}.pdf`,value.pdf, { base64: true });
        });
        
        zip.generateAsync({ type:"blob" }).then(function(content) {
            saveAs(content, `${plantilla.nombre}.zip`);
        });

    };
 
    const enviaEmpleados = (data, secuencia=null) => {
        setOpenLoading(true);
        const empleados = data.map(item => item.id)

        let request = { plantillaId: plantilla.id, empleados };
        if(contieneSecuencia && (secuencia !== null) ) {
            request.secuencia = secuencia;
        }

        axios.post(`${servicios_grupohit_plantillas}/pdf/`, request, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    crearZip(res.data.dataResponse);
                    break;
                default:
                    setMensajeError(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
            setOpenLoading(false);
        })
        .catch(error => {
            setMensajeError(verifique_conexion);
            setOpenAlertSyE(true);
            setOpenLoading(false);
        });
    };

    const cerrarModal = () => {
        setMensajeError('');
        setOpenAlertSyE(false);
    };

    return (
        <Fragment>
            <BtnBack tooltipText="Regresar a tabla de plantillas" action={onShowTable}/>
            <CardComponent title={<TitleCard title="Asignar Plantilla a Empleados"/>}>
                <TableEmpleados empleados={empleadosActivos} enviaEmpleados={enviaEmpleados} contieneSecuencia={contieneSecuencia} />
            </CardComponent>
            <ModalLoading nombreModulo={nombreModulo} open={openLoading} setOpen={setOpenLoading}/>
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={mensajeError} textbtnconfirm="Aceptar" type="error" action={cerrarModal} />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});
  
const mapDispatchToProps = { obtenerEmpleadosActivos };
 
export default connect(mapStateToProps, mapDispatchToProps)(AssignPlantilla);
