import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const crearZip = (elementSelect, data, type='alta') => {
    let zip = new JSZip();
    zip.file(`REPORTE DE ${(!elementSelect) ? (type === 'reingreso') ? 'REINGRESOS' : 'ALTAS' : (type === 'reingreso') ? 'REINGRESO' : 'ALTA'} ${(elementSelect) ? `${elementSelect.empleadoClave} - ${elementSelect.empleadoNomCompleto}` : ''}.pdf`, data, { base64: true }); 
    zip.generateAsync({ type:"blob" }).then(function(content) {
        saveAs(content, `REPORTE DE ${(!elementSelect) ? (type === 'reingreso') ? 'REINGRESOS' : 'ALTAS' : (type === 'reingreso') ? 'REINGRESO' : 'ALTA'} ${(elementSelect) ? `${elementSelect.empleadoClave} - ${elementSelect.empleadoNomCompleto}` : ''}.zip`);
    });
};

export default crearZip;