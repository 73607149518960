import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import { ausentismos_ } from '../../../../../../Constants/Constants';
import InformacionAusentismos from './InformacionAusentismos';
import MovimientosExcel from '../../Nomina/Variables/DataTableMovimientos/MovimientosExcel';

const ModalAddAusentismosExcel = ({ openAdd, setOpenAdd, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const cerrarModal = () => {
        setOpenAdd( false );
    }

    return (
        <ModalService title={`Carga Masiva de ${ausentismos_}`} parentNode={nombreModulo} minWidth="33%" maxWidth="35%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <b>Descarga</b>
                <div>1. Seleccione la nómina.</div>
                <div>2. Baje el archivo.</div>
                <div>3. Realice los cambios necesarios en el archivo descargado.</div>
                <br/>
                <b>Carga</b>
                <div>1. Busque el archivo con extensión .csv.</div>
                <div>2. Una vez que el sistema lo analice:</div>
                <div>Si no se presentan incidencias, haga la carga. De lo contrario, modifique el archivo y repita el procedimiento.</div>
                <hr/>
                <InformacionAusentismos
                    idModulo={nombreModulo}
                    errorTable={errorTable}
                    successTable={successTable}
                    nombreModulo={ausentismos_}
                />
                <hr/>
                <MovimientosExcel
                    idModulo={nombreModulo}
                    errorTable={errorTable}
                    successTable={successTable}
                    informacion={informacionAdicional}
                    nombreModulo={ausentismos_}
                />
        </ModalService>
    );
};

export default ModalAddAusentismosExcel;