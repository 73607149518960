import React, { useState } from 'react';
import { connect } from 'react-redux';

import { longDateToInputDate } from '../../../../Services/Validation/HelperValidation';

import AlertTitle from '../../../../Services/Alerts/AlertTitle';
import AlarmOff from '@material-ui/icons/AlarmOff';
import { quitarNavegador } from '../../../../Redux/actions/NavActions';

const ModuloRestringido = ({ id, position, restricciones, navegadoresStore, quitarNavegador, componenteActivoID }) => {
    const [ alertError, setAlertError ] = useState( true );
    const elementSelect = {
        fecini: longDateToInputDate(restricciones[0].fecini),
        fecfin: longDateToInputDate(restricciones[0].fecfin),
    };

    return (
        <div id={`modulo${id}`}>
            <AlertTitle open={alertError} setOpen={setAlertError} title="Módulo Restringido" text={`Del ${elementSelect.fecini} al ${elementSelect.fecfin}`} textBtnConfirm="Aceptar" type="error"
                action={() => quitarNavegador( id, componenteActivoID, position, navegadoresStore )} customIcon={<AlarmOff/>}/>
        </div>
    );
};

/* ------------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
const mapDispatchToProps = { quitarNavegador };
/* ------------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, mapDispatchToProps)(ModuloRestringido);