import React, { useState, useEffect, createRef } from 'react';
/* -----------------------------------------------------------------------------------------------------  */
import { formatExcel, convertColumnasPDF, convertColumnasPrint, getStylesPDF } from './ActionsDatatable';
import { obtenerModalAdd, obtenerModalEdit, obtenerModalDelete, obtenerModalDeleteForm, obtenerModalForm } from './HerlperDialogs';
import { localization, tableIcons } from './ElementTable';
import { ArrayJsonTablasImpuestosAdd } from '../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../Services/Alerts/AlertSyE'; //Alertas
import AlertTable from './AlertTable';
/* import ModalPDF from './ModalPDF'; */
import { longDateToInput, inputDateToLong, hoyMilisegundos } from '../../../../Services/Validation/HelperValidation';
import ConvertDate, { ConvertDateTime } from '../Helpers/ConvertDate';
/* --------------------------------------- Librerias Adicionales ---------------------------------------  */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { csvDownload } from '../../../../Services/Validation/HelperValidation';
import printJS from 'print-js'
import copy from 'copy-to-clipboard';
import { pushSuccess, pushWarning } from '../../../../Services/Notifications/PushService';
/* -----------------------------------------------------------------------------------------------------  */
import {
    SaveAlt, PictureAsPdf, FileCopy, Print, Add, Edit, Delete, Description, Payment, Save, Person, Apps, MonetizationOn,
    AlarmOff, AlarmOn, PostAdd, ChromeReaderMode, Lock, WorkOff, CompareArrows, RepeatOne, AccountTree, SettingsBackupRestore,
    MultilineChart, Cancel, Today, Pageview, RecentActors, SyncAlt
} from '@material-ui/icons';
import Folder from '@material-ui/icons/Folder';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import Group from '@material-ui/icons/Group';
import { IconButton } from '@material-ui/core';
import MaterialTable, { MTableToolbar, MTableAction, MTableCell } from 'material-table';
import { MuiThemeProvider, createMuiTheme, Switch, Tooltip } from '@material-ui/core';
import './StyleDataTable.css';
import {searchComponentTex} from '../../Usuario/MenuDrawer/Header/SearchComponent';
import { correspondencia_conceptos, historico_, pagos_pendientes, verifique_conexion } from '../../../../Constants/Constants';
import { ignoraTildes } from '../Helpers/filtroDataTable';
import reporteAltaPersonal from '../Modulos/Tablero/Estadisticas/EstadPorPeriodo/Tablas/reporteAltaPersonal';
import crearZip from '../Modulos/Tablero/Estadisticas/EstadPorPeriodo/Tablas/descargaZipdeReporteAltaPersonal';
import TooltipComponent from '../../../../Services/Tooltip/TooltipComponent';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    connected:{
      color: green[500],
      fontSize: 17,
      marginLeft: 5,
      marginBottom: 3
    }
  }));

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

function DataTableService({
    id, title, columns, data, setData, informacionAdicional, actionAlert, onShowTable, action, hideIconAdd, hideIconEdit, hideIconDelete, showIconDescription, isReport,
    showIconPayment, showIconEmployee, showIconApps, showIconNewFolder, showIconAux, showIconAuxAdd, showIconPostAdd, showIconChromeReaderMode, showIconLook, showIconView, permisos, showIconPDF,
    showIconWorkOff, showIconCompareArrows, showIconRepeatOne, showIconAccountTree, customOptionsTable, showIconSettingsBackupRestore, showIconMultilineChart, showCancel, showIconToday, showIconPageview,
    showDownloadEmployeeFile, showSyncAlt, notEstadistica
}) {
    const classes = useStyles();
    const tableRef = createRef();
    const columnasPDF = convertColumnasPDF( columns ); //this.props.columns
    const estilosPDF = getStylesPDF(id);
    //const columnasPrint = convertColumnasPrint( columns ); //this.props.columns
    let dataContent = data.map(element=> 
        Object.keys( element ).forEach( key => {
                element[key] = typeof element[key] === 'string' && id !==78 && id !== 19
                                ? (id === 82)
                                    ? (key === 'key') //para no convertir los token en sesiones
                                        ? element[key]
                                        : `${element[key]}`.toUpperCase()
                                    : `${element[key]}`.toUpperCase()
                                : element[key];
        })
    );
        
    const [columnsTable, setColumnsTable] = useState(isReport
                                                    ? columns.map(element=>{const newElement=element; newElement.grouping = !data.every(item=>item[element.field]===data[0][element.field]); newElement.draggable=newElement.grouping; newElement.filtering=newElement.grouping; newElement.filterPlaceholder='Filtrar'; return newElement})
                                                    : columns.map(item => {
                                                        if(!item.customFilterAndSearch) {
                                                            item.customFilterAndSearch = (term, rowData) => ignoraTildes(term, rowData, item.field)
                                                        }
                                                        return item;
                                                    }));
   
    useEffect(()=>{
        if(tableRef.current && tableRef.current.dataManager.searchText!=="" && searchComponentTex.text!=='' ){
            tableRef.current.onSearchChange(searchComponentTex.text)
        }
    },[tableRef])
    
    useEffect(() => {
        setDataTable(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const [ textAlert, setTextAlert ] = useState(''); //this.props.data
    const [ dataTable, setDataTable ] = useState( data ); //this.props.data
    const [ openAdd, setOpenAdd ] = useState( false );
    const [ openEdit, setOpenEdit ] = useState( false );
    const [ openDeleteForm, setOpenDeleteForm ] = useState( false );
    const [ openModalForm, setOpenModalForm ] = useState( false );
    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ error, setError ] = useState( null );

    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );

    const [ modalName, setModalName ] = useState('');
    /* -----------------------------------------------------------------------------------------------------  */

    useEffect(() => {
        if(openEdit === false) {
            switch (id) {
                case 16: //conceptos generales
                case 34+'puesto': //historico puestos
                    setElemmentSelect({});
                    break;
                default:
                    break
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openEdit]);

    const detectarContenido = () => {
        if( dataContent.length > 0 ){
            setError( null );
            return true;
        }else{
            setError( <AlertTable mensaje="Asegúrate de tener contenido para exportar" closeAlert={ () => setError(null) } /> );
            return false;
        }
    }
    /* ---------------------------------------------------------------------------------------------- */
    const optionsTableReports={
        grouping       : true,
        filtering      : true,
        defaultExpanded: true,
        doubleHorizontalScroll: true
    }

    const optionsTable = {
        rowStyle: { textTransform: id !== 19 ? 'uppercase' : '' },
        columnsButton: true,
        pageSize: (dataTable.length <= 5) ? 5 : 10,
        headerStyle: { zIndex: "0", color: '#4C638E', fontweight: 'bold', textTransform: 'uppercase'},
        actionsColumnIndex:-1,
        searchFieldAlignment: "left",
        searchText:searchComponentTex.text ? searchComponentTex.text: isReport && informacionAdicional ? informacionAdicional.filtroGeneral:''
    }

    const dataVisible = (columns, data, quitarSalto=false)=>{    
        return extractDataGroup(data, columnsVisibles(columns), groupByData(columns), quitarSalto);
    }

    const groupByData = (columns) => {
        return columns.filter(columnDef => columnDef.tableData.groupOrder !== undefined);
    }

    const columnsVisibles = (columns) => { 
        return columns.filter(columnDef => (columnDef.hidden !== true  && columnDef.tableData.groupOrder === undefined)).sort((a, b)=> a.tableData.columnOrder > b.tableData.columnOrder ? 1 : a.tableData.columnOrder < b.tableData.columnOrder ? -1 :0 );
    }

    const extractDataGroup=(data, columnsVisibles, groupBy, quitarSalto)=>{
        var result = [];
        for (const key in data) {  
            if (data[key].groups) { 
                const colName = groupBy.filter(element => element.tableData.groupOrder===data[key].path.length-1)[0];
                result.push({" ": `${colName.title}: ${data[key].value}`}); 
                if(data[key].groups.length>0){ 
                    const group = extractDataGroup(data[key].groups, columnsVisibles, groupBy);
                    for (const key in group) {
                        result.push( group[key]);
                    } 
                }else{
                    const dataVisble = data[key].data.map(rowData =>{ const newJson={};  Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = rowData[columnDef.field]; return element})); return newJson;});
                    dataVisble.map(element=>result.push(element)); 
                }
            }else{ 
                const newJson = {};
                Object.assign( newJson, ...columnsVisibles.map(columnDef => {
                    const element={};
                    if( 
                        (data[key][columnDef.field] !== null) &&
                        (isNaN(data[key][columnDef.field]) && data[key][columnDef.field].trim() !== '') &&
                        (
                            columnDef.field === 'cpresupuestalClave' ||
                            columnDef.field === 'cpresupuestalclave' ||
                            columnDef.field === 'cpresupuestalCve' ||
                            columnDef.field === 'cpresupuestalcve' ||
                            columnDef.field === 'empleadoNss' ||
                            columnDef.field === 'proyectoClave'
                        )
                    ) { //Se agrega comilla a campo Clave Presupuestal
                        try {
                            element[columnDef.title] = "'" + ((quitarSalto) ? (data[key][columnDef.field]) ? data[key][columnDef.field].replace(/(\r\n|\n|\r)/gm," ") : data[key][columnDef.field] : data[key][columnDef.field]);
                        } catch(e) {
                            element[columnDef.title] = "'" + data[key][columnDef.field];
                        }
                    } else {
                        try {
                            element[columnDef.title] = (quitarSalto) ? (data[key][columnDef.field]) ? data[key][columnDef.field].replace(/(\r\n|\n|\r)/gm," ") : data[key][columnDef.field] : data[key][columnDef.field];
                        } catch(e) {
                            element[columnDef.title] = data[key][columnDef.field];
                        }
                    }
                    return element;
                }));
                result.push(newJson);
            }
        }
        return result;
    } 


    const csvDownloadReport= () => {
        const dataAcumulados = informacionAdicional.acumulados;
        let headerAcumulados = id===48 ? dataAcumulados.map( (element, index) => `Clave Acumulado: ${element.acumuladoClave},Nombre Acumulado: ${element.acumuladoNombre} ${index===dataAcumulados.length-1 ?'\n' :''}`) : null;
        const infoNomina =  id===48 ? `Fecha Periodo: ${dataAcumulados[0].periodoFecini} - ${dataAcumulados[0].periodoFecfin}\nAño: ${dataAcumulados[0].periodoAnio},Periodo: ${dataAcumulados[0].periodoPeranio}\nClave Nómina: ${dataAcumulados[0].nomindClave},Nombre Nómina: ${dataAcumulados[0].nominaNomCorto}\n\n` : null
        
        let csvContent = formatExcel( dataVisible(tableRef.current.state.columns, tableRef.current.state.data, true), groupByData(tableRef.current.state.columns).length,  ',');
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent( headerAcumulados ?  "\uFEFF"+headerAcumulados.join('\n')+infoNomina+csvContent : "\uFEFF"+csvContent));
        a.setAttribute('download', `${title.toUpperCase()}.csv`);
        window.document.body.appendChild(a);
        a.click();
    }

    const csvDownloadOptional = () => {
        let csvContent = formatExcel( dataVisible(tableRef.current.state.columns, tableRef.current.state.data, true), groupByData(tableRef.current.state.columns).length,  ',');
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF"+csvContent));
        a.setAttribute('download', `${title.toUpperCase()}.csv`);
        window.document.body.appendChild(a);
        a.click();
    }

    const obtieneInfo2 = (item, tipo) => {
        const info2 = item.data.map(item => {
            item.informacion =  (tipo === null)
                                ?   (item.tipo === 'Clave Presupuestal / Proyecto'.toUpperCase())
                                    ?   `Clave Presupuestal / Proyecto: ${item.informacion?.presupuestal?.cpresupuestalnom}, Clave Presupuestal: ${item.informacion?.presupuestal?.cpresupuestalclave}`
                                    :   item.tipo === 'Plaza'.toUpperCase()
                                        ?   `NUP: ${item.informacion?.plazaid?.plazaclave}`
                                        :   item.tipo === 'Zona de Pago'.toUpperCase()
                                            ?   `Tipo de Domicilio: ${item.informacion?.domcia?.tipoDom}, Calle: ${item.informacion?.domcia?.domCiaCalle}, Núm. Int.: ${item.informacion?.domcia?.domCiaNumInt? item.informacion?.domcia?.domCiaNumInt :'-'}, Núm. Ext.: ${item.informacion?.domcia?.domCiaNumExt? item.informacion?.domcia?.domCiaNumExt :'-'}, Teléfono: ${item.informacion?.domcia?.domCiaTel ? item.informacion?.domcia?.domCiaTel :'-'}, Extensión Telefónica: ${item.informacion?.domcia?.domCiaExt ? item.informacion?.domcia?.domCiaExt :'-'}, Compañía: ${item.informacion?.domcia?.compania? item.informacion?.domcia?.compania :'-'}, CP.: ${item.informacion?.domcia?.codPostal? item.informacion?.domcia?.codPostal :'-'}`
                                            :   item.tipo === 'Puesto'.toUpperCase()
                                                ?   `Clave Puesto: ${item.informacion?.puesto?.puestoCve}, Nombre Puesto: ${item.informacion?.puesto?.puestoNom}`
                                                :   item.tipo === 'Nómina'.toUpperCase()
                                                    ?   `Clave Nómina: ${item.informacion?.nomina?.nominaClave}, Nombre Nómina: ${item.informacion?.nomina?.nominaNombre}`
                                                    :   item.tipo === 'Turno'.toUpperCase()
                                                        ?   `Clave Jornada: ${item.informacion?.turno?.jornadaClave}, Nombre Jornada: ${item.informacion?.turno?.jornadaNombre}, Nombre Turno: ${item.informacion?.turno?.turnoNombre}`
                                                        :   item.tipo === 'Status'.toUpperCase()
                                                            ?   (item.informacion && typeof item.informacion === 'object')
                                                                ?   `Clave Baja: ${item.informacion?.baja?.claveBaja}, Motivo Baja: ${item.informacion?.baja?.nombreBaja}${(item.informacion?.baja?.fechaReanudacion) ? `, Fecha Reanudación: ${item.informacion?.baja?.fechaReanudacion}` : ''}${(item.informacion?.baja?.descripcion) ? `, Descripción: ${item.informacion?.baja?.descripcion}` : ''}`
                                                                :   'Activo'
                                                            :   item.tipo === 'Contrato'.toUpperCase()
                                                                ?   `Contrato: ${item.informacion?.contrato?.contratoSaoNom}`
                                                                :   ''
                                :   item.informacion
            return item;
        })

        return info2;
    };

    const obtieneInfo1 = (item, tipo=null) => {
        const info1 = item.groups.map(item => {
            item.data = obtieneInfo2(item, tipo);
            return item;
        });

        return info1;
    };

    const isEstadistica = () => {
        try {
            if(notEstadistica) {
                return false;    
            }
            if(id && id.substring(0,19) === '1001EstadPorPeriodo') {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    const actionsTable = [
        ...[ {
            icon: SaveAlt, tooltip: 'CSV',
            isFreeAction: true,
            onClick: (event) => {
                if( detectarContenido() ){
                    if(isReport){
                        csvDownloadReport();
                    }else{
                        const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);
                        let dataCsv = [];
                        if((id === 62+"secondary") || (id === 1001+"secondary")) {
                            dataCsv = tableRef.current.state.data.map(item => {
                                item.groups = obtieneInfo1(item, 'csv');
                                return item;
                            });
                        } else {
                            dataCsv = tableRef.current.state.data;
                        }
                        let resultData = dataVisible(tableRef.current.state.columns, dataCsv, true);
                        if(columnas.length > 0) {
                            if(resultData) {
                                if( id === 904 ) {
                                    csvDownloadOptional(); //Pagos Únicos
                                } else {
                                    csvDownload( resultData, `${title}.csv`, id );
                                }
                            }
                        } else {
                            errorTable('No ha seleccionado columnas para descargar');
                        }
                    }
                }
            }
        }],
        {
            icon: PictureAsPdf, tooltip: 'PDF',
            isFreeAction: true,
            onClick: (event) => {
                if( detectarContenido() ){
                    const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);

                    if(columnas.length > 0) {
                        const estadistica = isEstadistica();

                        const doc = new jsPDF( estilosPDF.orientation );
                        let titulo = isReport ? title : `LISTA DE ${title.toUpperCase()}`;
                        
                        if(estadistica) {
                            const nombre = title.toUpperCase()
                            titulo = `GOBIERNO DEL ESTADO DE OAXACA\nSECRETARÍA DE ADMINISTRACIÓN - ${informacionAdicional.usuario.deptoNom}\nREPORTE DE ${nombre}, NÓMINA: ${informacionAdicional.nomina}, AÑO: ${informacionAdicional.anio}, PERIODO: ${informacionAdicional.periodo} (${informacionAdicional.infoPeriodo ? (informacionAdicional.infoPeriodo).toLowerCase() : '' })\n${(informacionAdicional.usuario.paterno) ? informacionAdicional.usuario.paterno : ''} ${(informacionAdicional.usuario.materno) ? informacionAdicional.usuario.materno : ''} ${(informacionAdicional.usuario.nombre) ? informacionAdicional.usuario.nombre : ''} (${informacionAdicional.usuario.username})`;
                        }

                        let dataPdf = [];
                        if((id === 62+"secondary") || (id === 1001+"secondary")) {
                            dataPdf = tableRef.current.state.data.map(item => {
                                item.groups = obtieneInfo1(item);
                                return item;
                            });
                        } else {
                            dataPdf = tableRef.current.state.data;
                        }

                        let columnsPDF = [];
                        if(!isReport && (id !== 62+'secondary')) {
                            if(columnsVisibles(tableRef.current.state.columns).length < columnasPDF.length) {
                                columnsVisibles(tableRef.current.state.columns).forEach(column => {
                                    columnsPDF.push(columnasPDF.find(columnPDF => columnPDF.dataKey === column.title))
                                });
                            } else {
                                columnsPDF = columnasPDF;
                            }
                        }
                        doc.autoTable((isReport || (id === 62+'secondary'))
                                        ? convertColumnasPDF(columnsVisibles(tableRef.current.state.columns), isReport, groupByData(tableRef.current.state.columns).length>0) 
                                        : columnsPDF, isReport
                                                        ?   dataVisible(tableRef.current.state.columns, dataPdf)
                                                        :   (id ===62+"secondary" || (id === 1001+"secondary" && title === historico_) )
                                                            ?   dataVisible(tableRef.current.state.columns, dataPdf)
                                                            :   dataVisible(tableRef.current.state.columns, dataPdf), {
                                                                margin: (estadistica)
                                                                        ? { top: 30, bottom: 30 }
                                                                        : { top: 15, bottom: 15 },
                                                                rowPageBreak: 'avoid',
                                                                headStyles: { fillColor: [76, 99, 142] }, //#4C638E
                                                                didDrawPage: function(data) {
                                                                    if(estadistica) {
                                                                        doc.setFontSize(10);
                                                                        doc.text(titulo, 14, 12);
                                                                        doc.setFontSize(8);
                                                                        doc.text(`${ConvertDateTime(hoyMilisegundos(), true)}`, 250, 28);
                                                                    } else {
                                                                        doc.text(titulo, 14, 12);
                                                                    }
                                                                    doc.setFontSize(10);
                                                                    (estilosPDF.orientation === 'landscape')
                                                                    ?   doc.text(`Página ${data.pageNumber}`, 260, 200)
                                                                    :   doc.text(`Página ${data.pageNumber}`, 170, 290)
                                                                },
                                                                styles: (estadistica) ? getStylesPDF(id, tableRef.current.state.columns).styles : estilosPDF.styles,
                                                                columnStyles: (estadistica) ? getStylesPDF(id, tableRef.current.state.columns).columnStyles : estilosPDF.columnStyles
                        });
                        //var string = doc.output('datauristring');
                        if(estadistica) {
                            doc.setDrawColor(0, 0, 0);
                            doc.setLineWidth(0.3);
                            doc.setFont("helvetica");
                            doc.setFontType("bold");
                            doc.setFontSize(8);
                            doc.line(25, 198, 75, 198);
                            doc.text('ELABORÓ', 43, 202);
                            doc.line(85, 198, 135, 198);
                            doc.text('ENTREGÓ', 103, 202);
                            doc.line(145, 198, 195, 198);
                            doc.text('RECIBIÓ', 164, 202);
                            doc.line(205, 198, 255, 198);
                            doc.text('AUTORIZÓ', 224, 202);
                            doc.setFontSize(9);
                            doc.text(`TOTAL: ${dataPdf.length}`, 260, 195);
                        }
                        doc.save(title+'.pdf');
                        //setPdfSelect( string );
                        /* setOpenPDF( true ); */
                    } else {
                        errorTable('No ha seleccionado columnas para descargar');
                    }
                }
            }
        },
        {
            icon: FileCopy, tooltip: 'Copiar',
            isFreeAction: true,
            onClick: (event) => {
                const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);

                if(columnas.length > 0) {
                    if( detectarContenido() ){
                        let dataCopy = [];
                        if((id === 62+"secondary") || (id === 1001+"secondary")) {
                            dataCopy = tableRef.current.state.data.map(item => {
                                item.informacion =  item.tipo === 'Clave Presupuestal / Proyecto'.toUpperCase()
                                        ?   `Clave Presupuestal / Proyecto: ${item.informacion.presupuestal.cpresupuestalnom}, Clave Presupuestal: ${item.informacion.presupuestal.cpresupuestalclave}`
                                        :   item.tipo === 'Plaza'.toUpperCase()
                                            ?   `NUP: ${item.informacion.plazaid.plazaclave}`
                                            :   item.tipo === 'Zona de Pago'.toUpperCase()
                                                ?   `Tipo de Domicilio: ${item.informacion?.domcia?.tipoDom}, Calle: ${item.informacion?.domcia?.domCiaCalle}, Núm. Int.: ${item.informacion?.domcia?.domCiaNumInt? item.informacion?.domcia?.domCiaNumInt :'-'}, Núm. Ext.: ${item.informacion.domcia.domCiaNumExt? item.informacion.domcia.domCiaNumExt :'-'}, Teléfono: ${item.informacion.domcia.domCiaTel ? item.informacion.domcia.domCiaTel :'-'}, Extensión Telefónica: ${item.informacion.domcia.domCiaExt ? item.informacion.domcia.domCiaExt :'-'}, Compañía: ${item.informacion.domcia.compania? item.informacion.domcia.compania :'-'}, CP.: ${item.informacion.domcia.codPostal? item.informacion.domcia.codPostal :'-'}`
                                                :   item.tipo === 'Puesto'.toUpperCase()
                                                    ?   `Clave Puesto: ${item.informacion?.puesto?.puestoCve}, Nombre Puesto: ${item.informacion?.puesto?.puestoNom}`
                                                    :   item.tipo === 'Nómina'.toUpperCase()
                                                        ?   `Clave Nómina: ${item.informacion.nomina.nominaClave}, Nombre Nómina: ${item.informacion.nomina.nominaNombre}`
                                                        :   item.tipo === 'Turno'.toUpperCase()
                                                            ?   `Clave Jornada: ${item.informacion.turno.jornadaClave}, Nombre Jornada: ${item.informacion.turno.jornadaNombre}, Nombre Turno: ${item.informacion.turno.turnoNombre}`
                                                            :   item.tipo === 'Status'.toUpperCase()
                                                                ?   (item.informacion && typeof item.informacion === 'object')
                                                                    ?   `Clave Baja: ${item.informacion.baja.claveBaja}, Motivo Baja: ${item.informacion.baja.nombreBaja}${(item.informacion?.baja?.fechaReanudacion) ? `, Fecha Reanudación: ${item.informacion?.baja?.fechaReanudacion}` : ''}${(item.informacion?.baja?.descripcion) ? `, Descripción: ${item.informacion?.baja?.descripcion}` : ''}`
                                                                    :   'Activo'
                                                                :   item.tipo === 'Contrato'.toUpperCase()
                                                                    ?   `Contrato: ${item.informacion.contrato.contratoSaoNom}`
                                                                    :   ''
                                return item;
                            });
                        } else {
                            dataCopy = tableRef.current.state.data;
                        }
                        const resultData = dataVisible(tableRef.current.state.columns, dataCopy, true);
                        if(resultData){
                            const cadenaExcel = formatExcel( resultData, groupByData(tableRef.current.state.columns).length );
                            copy( cadenaExcel, {
                                format: 'text'
                            });
                            pushSuccess({ title: `Se han copiado ${resultData.length} registros al portapapeles` });
                        }
                    }
                } else {
                    errorTable('No ha seleccionado columnas para copiar');
                }
            }
        },
        {
            icon: Print, tooltip: 'Imprimir',
            isFreeAction: true,
            onClick: (event) => {
                const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);

                if(columnas.length > 0) {
                    if( detectarContenido() ){
                        let dataPrint = [];
                        if((id === 62+"secondary") || (id === 1001+"secondary")) {
                            dataPrint = tableRef.current.state.data.map(item => {
                                item.informacion =  item.tipo === 'Clave Presupuestal / Proyecto'.toUpperCase()
                                        ?   `Clave Presupuestal / Proyecto: ${item.informacion.presupuestal?.cpresupuestalnom}, Clave Presupuestal: ${item.informacion.presupuestal?.cpresupuestalclave}`
                                        :   item.tipo === 'Plaza'.toUpperCase()
                                            ?   `NUP: ${item.informacion.plazaid.plazaclave}`
                                            :   item.tipo === 'Zona de Pago'.toUpperCase()
                                                ?   `Tipo de Domicilio: ${item.informacion.domcia.tipoDom}, Calle: ${item.informacion.domcia.domCiaCalle}, Núm. Int.: ${item.informacion.domcia.domCiaNumInt? item.informacion.domcia.domCiaNumInt :'-'}, Núm. Ext.: ${item.informacion.domcia.domCiaNumExt? item.informacion.domcia.domCiaNumExt :'-'}, Teléfono: ${item.informacion.domcia.domCiaTel ? item.informacion.domcia.domCiaTel :'-'}, Extensión Telefónica: ${item.informacion.domcia.domCiaExt ? item.informacion.domcia.domCiaExt :'-'}, Compañía: ${item.informacion.domcia.compania? item.informacion.domcia.compania :'-'}, CP.: ${item.informacion.domcia.codPostal? item.informacion.domcia.codPostal :'-'}`
                                                :   item.tipo === 'Puesto'.toUpperCase()
                                                    ?   `Clave Puesto: ${item.informacion.puesto.puestoCve}, Nombre Puesto: ${item.informacion?.puesto?.puestoNom}`
                                                    :   item.tipo === 'Nómina'.toUpperCase()
                                                        ?   `Clave Nómina: ${item.informacion.nomina.nominaClave}, Nombre Nómina: ${item.informacion.nomina.nominaNombre}`
                                                        :   item.tipo === 'Turno'.toUpperCase()
                                                            ?   `Clave Jornada: ${item.informacion.turno.jornadaClave}, Nombre Jornada: ${item.informacion.turno.jornadaNombre}, Nombre Turno: ${item.informacion.turno.turnoNombre}`
                                                            :   item.tipo === 'Status'.toUpperCase()
                                                                ?   (item.informacion && typeof item.informacion === 'object')
                                                                    ?   `Clave Baja: ${item.informacion.baja.claveBaja}, Motivo Baja: ${item.informacion.baja.nombreBaja}${(item.informacion?.baja?.fechaReanudacion) ? `, Fecha Reanudación: ${item.informacion?.baja?.fechaReanudacion}` : ''}${(item.informacion?.baja?.descripcion) ? `, Descripción: ${item.informacion?.baja?.descripcion}` : ''}`
                                                                    :   'Activo'
                                                                :   item.tipo === 'Contrato'.toUpperCase()
                                                                    ?   `Contrato: ${item.informacion.contrato.contratoSaoNom}`
                                                                    :   ''
                                return item;
                            });
                        } else {
                            dataPrint = tableRef.current.state.data;
                        }
                        const resultData = dataVisible(tableRef.current.state.columns, dataPrint); 
                        const lenghtGroupBy = groupByData(tableRef.current.state.columns).length
                        const resultVisible = resultData.map((element) => { 
                            const key= Object.keys(element);  
                            if(key.length===1){ 
                                let result = element;
                                Object.keys(resultData[resultData.length-1]).forEach(elements => {
                                    result[elements]=" ";
                                }); 
                                return result;
                            }else{
                                let result = element;
                                Object.keys(element).forEach(keys => {
                                    if(result[keys]===null){
                                        result[keys]="";
                                    }
                                }); 
                                if(lenghtGroupBy>0) result[" "]=" "; 
                                return result;
                            }
                        });
                        printJS({
                            printable: (lenghtGroupBy > 0) ? resultVisible : resultData,
                            properties: convertColumnasPrint(columnsVisibles(tableRef.current.state.columns), isReport, groupByData(tableRef.current.state.columns).length>0),
                            type: 'json'
                        })
                    }
                } else {
                    errorTable('No ha seleccionado columnas para imprimir');
                }
            }
        },
        ...( showDownloadEmployeeFile
            ?   [
                    {
                        icon: RecentActors, tooltip: 'Descargar Fichas de los Empleados',
                        isFreeAction: true,
                        onClick: (event) => {
                            if(detectarContenido()) {
                                const empleadosArray = tableRef.current.state.data.map(emp => emp.id);

                                const request = {
                                    PeriodoAnio: Number(informacionAdicional.anio),
                                    Periodo: informacionAdicional.periodo,
                                    empleadoId: empleadosArray
                                };

                                reporteAltaPersonal(request)
                                .then(res => {
                                    if(res.data.status === 200) {
                                        crearZip(null, res.data.dataResponse)
                                    } else {
                                        setTextAlert(res.data.msg);
                                        setAlertError(true);
                                    }
                                })
                                .catch(error => {
                                    setTextAlert(verifique_conexion);
                                    setAlertError(true);
                                });
                            }
                        }
                    },
                ]
            :   []
        ),
        ...( showIconLook ? [
            {
                icon: Lock, tooltip: 'Restricciones',
                isFreeAction: true,
                onClick: (event) => {
                    setOpenModalForm(true);
                }
            }
        ] : []),

        ...( showIconView ? [
            {
                icon: Group, tooltip: 'Usuarios',
                isFreeAction: true,
                onClick: (event) => {
                    setOpenDeleteForm(true);
                }
            }
        ] : []),

        ...(showIconMultilineChart
        ?   (permisos && (permisos.find(permiso => permiso.id === 4)))
            ?   [
                    {
                        icon: MultilineChart, tooltip: 'Ver Movimientos Escalafonarios',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            onShowTable(2);
                        }
                    }
                ] 
            : []
        : []),
        ...( showSyncAlt
            ?   ( permisos && (permisos.find(permiso => permiso.id === 1)) )
                ?   [
                        {
                            icon: SyncAlt, tooltip: correspondencia_conceptos,
                            isFreeAction: true,
                            onClick: (event) => {
                                setOpenModalForm(true);
                            }
                        }
                    ]
                :   []
            :   []
            ),
        ...( hideIconAdd ? [] :
            ( permisos && permisos.find(permiso => permiso.id === 1) ) || !permisos ? [ //Permiso 1: Registrar
                {
                    icon: showIconNewFolder ? CreateNewFolder : showIconAccountTree ? AccountTree : (showIconRepeatOne) ? RepeatOne :Add,
                    tooltip: (id === 32) ? 'Reingresar desde Histórico' : showIconNewFolder ? 'Carga Másiva' : showIconAccountTree ? 'Movimientos Escalafonarios' : 'Agregar',
                    isFreeAction: true,
                    onClick: (event) => {
                        switch(id) {
                            case 62:
                                onShowTable(1);
                                break;
                            default:
                                setOpenAdd( true );
                                break;
                        }
                    }
                }
        ] : []),

        ...( hideIconEdit ? [] :
            ( permisos && permisos.find(permiso => permiso.id === 2) ) || !permisos ? [ //Permiso 2: Modificar
                {
                    icon: (id === 33) ? Folder : (id === 51) ? Payment : Edit, tooltip: (id === 51) ? 'Dispersar' : 'Editar',
                    onClick: (event, rowData) => {
                        switch(id) {
                            case 25:
                            case 27:
                                onShowTable(true, rowData.id, null);
                                break;
                            case 67:
                            case 72:
                                onShowTable(true, rowData, null);
                                break;
                            default:
                                setElemmentSelect(rowData);
                                setOpenEdit(true);
                                break;
                        }
                    }
                }
        ] : []),

        ...( showIconAuxAdd ? [
            {
                icon: PostAdd, tooltip: 'Agregar Pagos',
                onClick: (event, rowData) => {
                    let rowDataAux = rowData;
                    rowDataAux.agregar = true;
                    setElemmentSelect(rowDataAux);
                    setOpenModalForm(true);
                }
            }
        ] : []),
        ...( showIconAux ? [
            {
                icon: AlarmOff, tooltip: 'Finalizar',
                onClick: (event, rowData) => {
                    delete rowData.agregar;
                    setElemmentSelect(rowData);
                    setOpenModalForm(true);
                }
            }
        ] : []),

        ...( showIconPayment
            ?   ( permisos && (permisos.find(permiso => permiso.id === 1 || permiso.id === 2 || permiso.id === 3)) ) || !permisos
                ?   [
                        {
                            icon: Payment, tooltip: 'Procesar Nómina',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                setOpenEdit(true);
                            }
                        }
                    ]
                : []
            : []),
        ...( showIconEmployee
            ?   ( permisos && (permisos.find(permiso => permiso.id === 1 || permiso.id === 2)) ) || !permisos
                ?   [
                        {
                            icon: Person, tooltip: 'Acceso al Portal del Empleado',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                setOpenModalForm(true);
                            }
                        }
                    ]
                : []
            : []),
        ...( showIconApps ? [
            {
                icon: (id === 75) ? MonetizationOn : Apps, tooltip: 'Consultar',
                onClick: (event, rowData) => {
                    setElemmentSelect(rowData);
                    setOpenModalForm(true);
                }
            }
        ] : []),
        ...( showIconPDF ? [
            {
                icon: PictureAsPdf, tooltip: 'Descargar',
                onClick: (event, rowData) => {
                    setElemmentSelect(rowData);
                    setOpenEdit(true);
                }
            }
        ] : []),
        ...(showIconPostAdd
            ?   (permisos && (permisos.find(permiso => permiso.id === 2)) ) || !permisos
                ?   [
                        {
                            icon: PostAdd, tooltip: 'Editar Plantilla',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                onShowTable(rowData, 'editar', dataTable);
                            }
                        }
                    ]
                : []
            : []),
        ...(showIconChromeReaderMode
            ?   (permisos && (permisos.find(permiso => permiso.id === 2)) ) || !permisos
                ?   [
                        {
                            icon: ChromeReaderMode, tooltip: 'Asignar Plantilla a Empleados',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                onShowTable(rowData, 'asignar');
                            }
                        }
                    ]
                : []
            : []),
        ...(showIconWorkOff
            ?   (permisos && (permisos.find(permiso => permiso.id === 2)) ) || !permisos
                ?   [
                        {
                            icon: WorkOff, tooltip: 'Cerrar Sesión del Usuario',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                setAlertWarning(true);
                            }
                        }
                    ]
                : []
            : []
        ),
        ...(showIconPageview
            ?   ((permisos && permisos.find(permiso => permiso.id === 4)) || (id === '1001EstadPorPeriodoEscalafonListado'))
                ?   [
                        {
                            icon: Pageview, tooltip: 'Ver Listado de Empleados del Movimiento Escalafonario',
                            onClick: (event, rowData) => {
                                if(id === 1001+'EstadPorPeriodoEscalafonListado') {
                                    setElemmentSelect(rowData);
                                    setOpenModalForm(true);
                                } else {
                                    onShowTable(rowData);
                                }
                            }
                        }
                    ]
                :   []
            :   []
        ),
        ...( hideIconDelete ? [] :
            ( permisos && permisos.find(permiso => permiso.id === 3) ) || !permisos ? [ //Permiso 3: Eliminar
                {
                    icon: Delete, tooltip: (id !== 30) ? 'Eliminar' : 'Baja',
                    onClick: (event, rowData) => {
                        setElemmentSelect(rowData);
                        switch(id) {
                            case 30:
                                setOpenDeleteForm(true);
                            break;
                            case 62:
                                setModalName('');
                                setAlertWarning(true);
                                break;
                            case 62+"secondary":
                                setElemmentSelect({rowData, dataContent, idEmpleado : informacionAdicional.idEmpleado});
                                setAlertWarning(true);
                            break;
                            default:
                                setAlertWarning(true);
                            break;
                        }
                    }
                }
        ] : []),
        ...(showCancel
            ?   (permisos && permisos.find(permiso => permiso.id === 3) )
                ?   [{
                        icon: Cancel, tooltip: 'Cancelar',
                        onClick: (event, rowData) => {
                            setElemmentSelect(rowData);
                            setAlertWarning(true);
                        }
                    }]
                :   []
            :   []),

        ...( showIconDescription ? 
            ( permisos && permisos.find(permiso => permiso.id === 4) ) || !permisos ?
            [{
                icon: Description, tooltip: 'Mostrar información',
                onClick: (event, rowData) => {
                    switch(id) {
                        case 31: //ausentismos
                        case 34: //historico
                        case 41: //Movimientos Variables
                        case 68: //Movimientos Fijos
                        case 69: //Movimientos Relacionados
                        case 70: //Movimientos Pendientes
                            onShowTable( rowData );
                        break;
                        case 1001: //Busqueda Avanzada
                        case 80: //Auditorias
                        case 48+'simRecibos': //Reportes de Nómina, simulación de recibos de pago
                        case 48+"secondary":
                        case 83: //Carga Masiva Dtos / Carga Pagos Únicos
                            setElemmentSelect(rowData);
                            setOpenEdit(true);
                        break;
                        case 62: //Movimientos Especiales
                            setModalName('secondary');
                            setElemmentSelect(rowData);
                            setOpenEdit(true);
                        break;
                        default:
                            onShowTable(true, rowData, null, null);
                            break;
                    }
                }
            }]
            :[]
        : []),
        ...( showIconCompareArrows ? 
            ( permisos && permisos.find(permiso => permiso.id === 2) ) || !permisos ?
            [{
                icon: CompareArrows, tooltip: 'Permutas y Transferencias',
                onClick: (event, rowData) => {
                    setModalName('');
                    setElemmentSelect(rowData);
                    setOpenEdit(true);
                }
            }]
            :[]
        : []),
        ...( showIconRepeatOne ? 
            ( permisos && permisos.find(permiso => permiso.id === 2) ) || !permisos ?
            [{
                icon: RepeatOne, tooltip: 'Reingresar',
                onClick: (event, rowData) => {
                    setElemmentSelect(rowData);
                    setOpenDeleteForm(true);
                }
            }]
            :[]
        : []),
        ...( showIconToday ? 
            ( permisos && permisos.find(permiso => permiso.id === 2) ) || !permisos ?
            [{
                icon: Today, tooltip: pagos_pendientes,
                onClick: (event, rowData) => {
                    setElemmentSelect(rowData);
                    setOpenModalForm(true);
                }
            }]
            :[]
        : []),
        ...( !isReport || informacionAdicional.isStatic ? [] : [
            {
                icon: Save, tooltip: 'Guardar Configuración de Reporte',
                isFreeAction: true,
                onClick: () => {
                    setElemmentSelect([...tableRef.current.state.columns, {filtroGeneral:tableRef.current.state.searchText, infoReport: informacionAdicional.infoReport? informacionAdicional.infoReport: null}]);
                    setOpenEdit(true);
                }
            }
        ]),
        ...( showIconSettingsBackupRestore
            ?   ( permisos && (permisos.find(permiso => permiso.id === 3)) )
                ?   [
                        {
                            icon: SettingsBackupRestore, tooltip: (id === 32) ? 'Deshacer Baja' : 'Deshacer Reingreso',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                setAlertWarning(true);
                                if(id === 62) {
                                    setModalName('reingresos');
                                }
                            }
                        }
                    ]
                : []
            : []),
    ]

    /* -------------------------------------- contenidoActual() ------------------------------------- */
    const contenidoActual = ( propsData ) => { dataContent = propsData };
    /* ------------------- Actualizaciones de estado(Contenido) Acciones API-State ------------------ */
    const agregar = ( newElement, info = null  ) => {
        switch(id) {
            case 16: //conceptos generales
                setDataTable([ newElement, ...dataTable ]);
                break;
            case 25:
            case 27:
                onShowTable(true, null, info);
                break;
            case 32:
                //solo manda alerta desde ingreso de empleado en reingresos
                setTextAlert('Registro Agregado');
                setAlertSuccess(true);
                break;
            case 39: //politicas
                let sortArray = dataTable.sort((a, b) => inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 : 0);
                if(dataTable.length > 0) {
                    //se obtienen los elementos de la misma politica
                    const indicadorPolitica = newElement.id.substring(newElement.id.length-2, newElement.id.length);
                    const elements = dataTable.filter(e => indicadorPolitica === e.id.substring(e.id.length-2, e.id.length));
                    //se obienen elementos que no pertenece a la misma politica
                    const elementsNoP = dataTable.filter(e => indicadorPolitica !== e.id.substring(e.id.length-2, e.id.length));
                    
                    if(elements.length > 0) {
                        //se asigna la fechainicial menos un día a la fecha final del registro anterior
                        if(!inputDateToLong(newElement.fechaFi)) {
                            elements[0].fechaFi = longDateToInput(inputDateToLong(newElement.fechaIn)-86400000);
                            elements[0].fechaFin = ConvertDate(longDateToInput(inputDateToLong(newElement.fechaIn)-86400000));
                        }
                        sortArray = [ ...elements, ...elementsNoP ];
                        sortArray = sortArray.sort((a, b) => inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 : 0);
                    } else {
                        sortArray = dataTable.sort((a, b) => inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 : 0);
                    }
                }
                setDataTable([ newElement, ...sortArray ]);
                setTextAlert('Registro Agregado');
                setAlertSuccess(true);
                break;
            case 64: //imss
            case 71: //pensiones
                const sortArray6471 = dataTable.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
                if(dataTable.length > 0) {
                    //se asigna la fechainicial menos un día a la fecha final del registro anterior
                    if(!inputDateToLong(newElement.fechafin)) {
                        sortArray6471[0].fechafin = longDateToInput(inputDateToLong(newElement.fechaini)-86400000);
                        sortArray6471[0].fechafinTab = ConvertDate(longDateToInput(inputDateToLong(newElement.fechaini)-86400000));
                    }
                }
                setDataTable([ newElement, ...sortArray6471 ]);
                setTextAlert('Registro Agregado');
                setAlertSuccess(true);
                break;
            case 65: //SalarioMinimoUMA
                if(dataTable.length > 0) {
                    //se obtienen los elementos de la misma zona
                    const indicadorSMUMA = newElement.zonaId;
                    const elements = dataTable.filter(e => indicadorSMUMA === e.zonaId);
                    //se obienen elementos que no pertenece a la misma zona
                    const elementsNoZ = dataTable.filter(e => indicadorSMUMA !== e.zonaId);
                    
                    if(elements.length > 0) {
                        //se asigna la fechainicial menos un día a la fecha final del registro anterior
                        if(!inputDateToLong(newElement.fechaFin)) {
                            elements[0].fechaFinInp = longDateToInput(inputDateToLong(newElement.fechaIniInp)-86400000);
                            elements[0].fechaFin = ConvertDate(longDateToInput(inputDateToLong(newElement.fechaIniInp)-86400000));
                        }
                        sortArray = [ ...elements, ...elementsNoZ ];
                        sortArray = sortArray.sort((a, b) => inputDateToLong(a.fechaIniInp) < inputDateToLong(b.fechaIniInp) ? 1 : inputDateToLong(a.fechaIniInp) > inputDateToLong(b.fechaIniInp) ? -1 : 0);
                    } else {
                        sortArray = dataTable.sort((a, b) => inputDateToLong(a.fechaIniInp) < inputDateToLong(b.fechaIniInp) ? 1 : inputDateToLong(a.fechaIniInp) > inputDateToLong(b.fechaIniInp) ? -1 : 0);
                    }
                }
                setDataTable([ newElement, ...dataTable ]);
                setTextAlert('Registro Agregado');
                setAlertSuccess(true);
                break;
            case 67:
            case 72:
                onShowTable(true, info, true);
                break;
            default:
                setDataTable([ newElement, ...dataTable ]);
                setTextAlert('Registro Agregado');
                setAlertSuccess(true);
                break;
        }
    }

    const editar = ( newElement ) => {
        switch(id) {
            case 16: //conceptos generales
                setDataTable( dataTable.map( element => element.id === newElement.id ? element = newElement : element ) );
                setElemmentSelect(newElement);
                break;
            case 48:
            case 49:
                    setTextAlert('Configuración Guardada');
                    setAlertSuccess( true );
                    onShowTable(newElement);
                break;
            default:
                    setDataTable( dataTable.map( element => element.id === newElement.id ? element = newElement : element ) );
                    setTextAlert('Registro Actualizado');
                    setAlertSuccess( true );
                break;
        }
    }

    const eliminar = ( idElement ) => {
        const mensajeSuccess = (mensaje="Registro Eliminado") => {
            setTextAlert(mensaje);
            setAlertSuccess(true);
        };

        if(id === '62secondary') {
            const typeElementDelete = dataTable.find( element => element.id === idElement ).tipo;
            let newDataTable = dataTable.filter(element => element.id !== idElement);
            let elementosMismoTipoOrdenados = newDataTable.filter(item => item.tipo === typeElementDelete);
            elementosMismoTipoOrdenados = elementosMismoTipoOrdenados.sort((a, b) => a.fechaFinLong < b.fechaFinLong ? 1 : a.fechaFinLong > b.fechaFinLong ? -1 : 0);
            const ultimoElemento = elementosMismoTipoOrdenados[0];
            newDataTable = newDataTable.map(item => {
                if((item.id === ultimoElemento.id) && (item.tipo === ultimoElemento.tipo)) {
                    item.fechaFin = null;
                    item.fechaFinLong = null;
                }
                return item;
            })
            setDataTable(newDataTable);
            mensajeSuccess();
            return;
        }

        if (id === 67 || id === 72){
            let editELement= dataTable.findIndex(element=> element.periodoImpto === idElement.periodoImpto); 
            if(editELement !==-1){ 
                let newElement=dataTable[editELement];
                newElement.status=true;                
                setDataTable( dataTable.map( element => element.id === newElement.id ? element = newElement : element ) );
            }
            const newData = ArrayJsonTablasImpuestosAdd(dataTable.filter( element => element.id !== idElement.id ))
            setDataTable(newData);
            setData(newData);
            mensajeSuccess();
            return;
        }

        setDataTable( dataTable.filter( element => element.id !== idElement ) );
        mensajeSuccess();
    }
    /* ---------------------------------------------------------------------------------------------- */
    const successTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    } 
    
    const verificaAgrupacion = (positionColumn, columnas)=>{
        if(!columnas[parseInt(positionColumn.draggableId)].grouping && positionColumn.destination.droppableId==="groups" ){
            pushWarning({ title: 'No se puede agrupar por esta columna.', message: 'Contiene los mismos datos.' });
        }
    }

    const handleColumnDrag = (posicionAnterior, insertar) => {
        //función que actualiza las posición de las columnas al hacer drag and drop
        const columnsVisibles = columnsTable.filter(c => !c.hidden);
        const elementToMove = columnsVisibles[posicionAnterior];
        const elementNext = columnsVisibles[insertar];
        let positionElementNext = -1;
        columnsTable.map((e, key) => {
            if(e.title === elementNext.title) {
                positionElementNext = key;
            }
            return e;
        });

        let nuevoArrayColumnas = columnsTable.filter(item => item.field !== elementToMove.field);
        nuevoArrayColumnas.splice(positionElementNext, 0, elementToMove);
        nuevoArrayColumnas.map((col, key) => col.tableData.columnOrder = key+1);
        //nuevoArrayColumnas = columnsTable.sort((a, b)=> a.tableData.columnOrder > b.tableData.columnOrder ? 1 : a.tableData.columnOrder < b.tableData.columnOrder ? -1 :0 );
        setColumnsTable(nuevoArrayColumnas);
    };
    
    /* ---------------------------------------------------------------------------------------------- */
    return (
        <div className={ isReport ? "ContenedorDataTable report" : 'ContenedorDataTable'}>
            { error }
            <MuiThemeProvider theme={theme}>
                <MaterialTable 
                    title={ '' }
                    columns={ columnsTable }
                    data={ dataTable }
                    tableRef={tableRef}
                    actions={ actionsTable }
                    options={ isReport ? Object.assign(optionsTable, optionsTableReports): customOptionsTable ? Object.assign(optionsTable, customOptionsTable) :optionsTable }
                    localization={ localization }
                    icons={ tableIcons }
                    onColumnDraggedEnd={(e, b )=>{verificaAgrupacion(e, b)}}
                    onColumnDragged={handleColumnDrag}    
                    components={{
                        Toolbar: props => {
                            if( props.data.length !== 0 ){ contenidoActual( props.data ) }else{ contenidoActual( [] ) }
                            return( <div className="TableroDataTable"> <MTableToolbar {...props} /> </div> )
                        },
                        Action: props => {
                            if( props.action.tooltip === 'Eliminar' && id === 19 ){
                                return(
                                    <Tooltip title="Modificar Status">
                                        <Switch
                                            checked={ props.data.status.id === 1 ? true : false } //1: Usuarios Activos
                                            onChange={ (event) => props.action.onClick(event, props.data) }
                                            value="checkedA"
                                            size="small"
                                        />
                                    </Tooltip>
                                )
                            }else if(id === 25 || id === 27){
                                
                                if(props.action.tooltip === 'Eliminar'){
                                    if(props.data.fechafin==='-' || props.data.fecFin==='-') return null;
                                    return(<IconButton onClick={()=>onShowTable(true, props.data.id, null)}>
                                                <Tooltip title="Mostrar información" placement="left-start">
                                                    <Description style={{ color: "#212121" }}/>
                                                </Tooltip>
                                            </IconButton>)
                                }else if(props.action.tooltip === 'Editar' && (props.data.fechafin!=='-' && props.data.fecFin!=='-' )){
                                    return null
                                }else{
                                    return( <MTableAction {...props} /> )
                                }
                            }else if(id === 67 ||  id === 72){ 
                                if(props.action.tooltip === 'Eliminar' && !props.data.status){ 
                                    return(<IconButton onClick={()=>onShowTable(true, props.data, null)}>
                                                <Tooltip title="Mostrar información" placement="left-start">
                                                    <Description style={{ color: "#212121" }}/>
                                                </Tooltip>
                                            </IconButton>)
                                }else if(props.action.tooltip === 'Editar' && !props.data.status){
                                    return null
                                }else{
                                    return( <MTableAction {...props} /> )
                                }
                            } else if((id === 1001+"secondary") && props.action.tooltip === 'Eliminar') { //movimientos_especiales
                                
                                if( dataContent.find(element => element.tipo === props.data.tipo).id === props.data.id ){
                                    return( <MTableAction {...props} /> )
                                }
                                return null;

                            } else if(id === 62+"secondary" && props.action.tooltip === 'Eliminar') {
                                //obtener ultimo status
                                const ultimoStatus = data.find(item => item.tipo === 'STATUS' && item.fechaFinLong === null);
                                if(ultimoStatus) {
                                    if(props.data.fechaFinLong === null && props.data.fechaIniLong > ultimoStatus.fechaIniLong) {
                                        if(props.data.tipo === 'CONTRATO') return null;
                                        if(props.data.tipo === 'NOMBRAMIENTO') return null;
                                        return( <MTableAction {...props} /> )
                                    } 
                                }
                                return null;
                            }else if(id === 62+"vermovimientos") {
                                //if((props.data.fecMov === longDateToInputDate(informacionAdicional.fechaInicialPeriodo)) && (props.action.tooltip === 'Eliminar')) {
                                if((props.action.tooltip === 'Eliminar')) {
                                    return <MTableAction {...props} />;
                                } else if(props.action.tooltip === 'Ver Listado de Empleados del Movimiento Escalafonario') {
                                    return( <MTableAction {...props} /> )
                                } else {
                                    return null
                                }
                            } else if(id === 75+"secondary" && props.action.tooltip === 'Finalizar'){ //Préstamos
                                return(
                                    <IconButton onClick={(event) => props.action.onClick(event, props.data)}>
                                        <Tooltip title={props.data.fechaFinTab !== '-' ? 'Reactivar' : 'Finalizar' } placement="bottom" style={{ color: "#212121" }}>
                                            {props.data.fechaFinTab !== '-' ? <AlarmOn /> : <AlarmOff />}
                                        </Tooltip>
                                    </IconButton>
                                );
                            } else if(id === 34+"antiguedad" && props.action.tooltip === 'Editar') {
                                if(data.length) {
                                    const length = data.length;
                                    if(props.data.id !== data[length-1].id) {
                                        return null;
                                    }
                                }
                                return ( <MTableAction {...props} /> );
                            } else if(id === 34+"puesto" && props.action.tooltip === 'Editar') {
                                if(data.length) {
                                    if(props.data.fechaFinal === ''){
                                        return( <MTableAction {...props} /> )
                                    }
                                }
                                return null;
                            } else if(id === 34+"presupuestal" && props.action.tooltip === 'Editar') {
                                if(data.length) {
                                    if(props.data.fechaFinal === ''){
                                        return( <MTableAction {...props} /> )
                                    }
                                }
                                return null;
                            } else {
                                return( <MTableAction {...props} /> )
                            }
                        },
                        Cell: props => {
                            if( typeof( props.value ) === 'string' && props.value.length > 60 && !isReport && id !== 62 && id !== 83 ){
                                const substringValue = props.value.substring(0,45);
                                if(isEstadistica()) {
                                    if(/(ActAnt)$/g.test(props.columnDef.field) && /\n\//g.test(substringValue)) {
                                        const posicion = substringValue.indexOf('/');
                                        const tamanio = substringValue.length;
                                        if(posicion > -1 || posicion >= 45) {
                                            const actual = substringValue.substring(0, posicion).trim();
                                            const anterior = substringValue.substring(posicion+1, tamanio).trim();
                                            return  <Tooltip title={ props.value } placement="bottom-start" arrow>
                                                        <td className={`MuiTableCell-root MuiTableCell-body MuiTableCell-${(/^(empleadoClaveActAnt|plazaClaveActAnt)$/g.test(props.columnDef.field)) ? 'alignRight' : 'alignLeft'}`}>
                                                            <strong>{ actual }</strong><br/>{`/ ${anterior}...`}
                                                        </td>
                                                    </Tooltip>
                                        } else {
                                            return  <Tooltip title={ props.value } placement="bottom-start" arrow>
                                                        <td className={`MuiTableCell-root MuiTableCell-body MuiTableCell-${(/^(empleadoClaveActAnt|plazaClaveActAnt)$/g.test(props.columnDef.field)) ? 'alignRight' : 'alignLeft'}`}>
                                                            <strong>{ substringValue }</strong>...
                                                        </td>
                                                    </Tooltip>
                                        }
                                    }
                                }
                                return(
                                    <Tooltip title={ props.value } placement="bottom-start" arrow>
                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                            { `${substringValue}...` }
                                        </td>
                                    </Tooltip>
                                );
                            }else if( id === 62+"secondary" || id === 1001+"secondary"){
                                if( typeof props.value === 'object'){
                                    for (const key in props.value) {
                                        if (props.value.hasOwnProperty(key)) { 
                                            const result = [];
                                            for (const clave in props.value[key]) {
                                                if(!informacionAdicional.notAllowed.includes(clave)){
                                                    const newKeyDiccionario = informacionAdicional.allowed[clave]; 
                                                    result.push(<div key={props.value.id+clave}>
                                                                    <strong>
                                                                        {`${newKeyDiccionario ? newKeyDiccionario : clave}: `}
                                                                    </strong>
                                                                    {`${props.value[key][clave]===null ? '-' :props.value[key][clave]}`}
                                                                </div>);
                                                }                                                  
                                            }
                                            return  <td key={props.value.id+"td"} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                                       {result.map(element => element)}                                             
                                                    </td>
                                            
                                        }
                                    }
                                } else {
                                    if(props.value === 'ACTIVO')
                                    return  <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                                <strong>{ props.value }</strong>
                                            </td>
                                }
                                return( <MTableCell {...props}/> );
                            } else if(id === 83 && props.columnDef.title.toUpperCase() === 'STATUS') {
                                if(Number(props.rowData.nivLaboralClave) > 0) {
                                    return  <td key={props.value.id+"td"} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                                { props.value }
                                                <TooltipComponent tooltipText="Ya fue calculado.">
                                                    <Brightness1Icon className={classes.connected} />
                                                </TooltipComponent>
                                            </td>;
                                } else {
                                    return( <MTableCell {...props}/> );
                                }
                            } else if(isEstadistica()) {
                                if(props.columnDef.title === 'NUE' && ((id === '1001EstadPorPeriodoAltaR') || (id === '1001EstadPorPeriodoAltaV') || (id === '1001EstadPorPeriodoReingresoR') || (id === '1001EstadPorPeriodoReingresoV'))) {
                                    const element = dataTable.find(e => e.empleadoClave === props.value);
                                    if(element) {
                                        return  <td className={`MuiTableCell-root MuiTableCell-body MuiTableCell-${(/^(empleadoClaveActAnt|plazaClaveActAnt)$/g.test(props.columnDef.field)) ? 'alignRight' : 'alignLeft'}`}>
                                                    <TooltipComponent tooltipText={`Descarga de reporte de ${element.empleadoNomCompleto}`} position="left">
                                                        <div className="nue-estadisticas-alta" onClick={() => action(element)}>{ props.value }</div>
                                                    </TooltipComponent>
                                                </td>;
                                    }
                                }
                                if(/(ActAnt)$/g.test(props.columnDef.field) && /\n\//g.test(props.value)) {
                                    const posicion = props.value.indexOf('/');
                                    const tamanio = props.value.length;
                                    const actual = props.value.substring(0, posicion).trim();
                                    const anterior = props.value.substring(posicion+1, tamanio).trim();
                                    return  <td className={`MuiTableCell-root MuiTableCell-body MuiTableCell-${(/^(empleadoClaveActAnt|plazaClaveActAnt)$/g.test(props.columnDef.field)) ? 'alignRight' : 'alignLeft'}`}>
                                                <strong>{ actual }</strong><br/>{`/ ${anterior}`}
                                            </td>
                                }
                                return( <MTableCell {...props}/> );
                            } else { 
                                return( <MTableCell {...props}/> );
                            }
                        },
                    }}
                />
            </MuiThemeProvider>
            { /* ----------------------------------------------------- Modals ----------------------------------------------------- */ }
            { obtenerModalAdd( id, openAdd, setOpenAdd, agregar, editar, errorTable, successTable, informacionAdicional, dataTable ) }
            { obtenerModalEdit( (id === 62 && modalName !== '') ? `${id}${modalName}` : id, openEdit, setOpenEdit, elemmentSelect, editar, errorTable, successTable, informacionAdicional, dataTable ) }
            { obtenerModalDelete( (id === 62 && modalName !== '') ? `${id}${modalName}` : id, alertWarning, setAlertWarning, elemmentSelect, editar, eliminar, errorTable, informacionAdicional ) }
            { obtenerModalDeleteForm( id, openDeleteForm, setOpenDeleteForm, elemmentSelect, editar, eliminar, errorTable, successTable, informacionAdicional ) }
            { obtenerModalForm( id, openModalForm, setOpenModalForm, elemmentSelect, setElemmentSelect, editar, successTable, errorTable, informacionAdicional ) }
            { /* ---------------------------------------------------- Modal PDF --------------------------------------------------- */ }
            {/* <ModalPDF idModulo={ id } title={ title } openPDF={ openPDF } setOpenPDF={ setOpenPDF } pdfSelect={ pdfSelect }/> */}
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success" action={(actionAlert) ? () => actionAlert() : false}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            
        </div>
    );
}

export default DataTableService;