import React from 'react'
import DataTableService from '../../../../DataTableService/DataTableService';
import InputText from '../../../../../../../Services/Components/DataTable/InputText';
import { especiales_ } from '../../../../../../../Constants/Constants';

const TableMovimientosEspeciales = ({ id, empleadosActivos, mostrarTablaEscalafon, permisos }) => {
    return (
        <DataTableService id={id} title={especiales_}
            columns={[
                { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                { field: "nombreCompleto", title: "Nombre" },
                { field: "empleadoCurp", title: "CURP", emptyValue:'-', hidden: true, hiddenByColumnsButton: true },
                { field: "empleadoRfc", title: "RFC", emptyValue:'-' },
                { field: "empleadoNss", title: "NSS", emptyValue:'-' },
                { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                { field: "puestoCve", title: "Clave Puesto" },
                { field: "puestoNom", title: "Puesto" },
                { field: "nominaNombre", title: "Nómina" },
                { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
            ]}
            data={empleadosActivos}
            customOptionsTable={{ filtering: true }}
            hideIconEdit
            showIconAccountTree
            showIconMultilineChart
            showIconDescription
            showIconCompareArrows
            showIconSettingsBackupRestore
            onShowTable={mostrarTablaEscalafon}
            permisos={permisos}
        />
    );
}
 
export default TableMovimientosEspeciales;