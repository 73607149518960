import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../Constants/Constants';
import ConvertDate, { returnCadenaMes } from '../../../../../Usuario/Helpers/ConvertDate';
import PageTitleBar from '../../../../Layouts/PageTitleBar';
import BtnBack from '../../../../../../../Services/Components/SecondTable/BtnBack';
import CardComponent from '../../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import Formulario from './Formulario';
import Tablero from './Tablero';
import { estad_por_periodo } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../../Services/AlertForm';
import displayWindowSize from '../../../../Helpers/DisplayWindowSize';
import './styles.css';
import AdscripcionVigentes from './Tablas/AdscripcionVigentes';
import AdscripcionRegistrados from './Tablas/AdscripcionRegistrados';
import AltasVigentes from './Tablas/AltasVigentes';
import AltasRegistrados from './Tablas/AltasRegistrados';
import BajasVigentes from './Tablas/BajasVigentes';
import BajasRegistrados from './Tablas/BajasRegistrados';
import CategoriasVigentes from './Tablas/CategoriasVigentes';
import CategoriasRegistrados from './Tablas/CategoriasRegistrados';
import AlertError from '../../../../../../../Services/Alerts/AlertSyE';
import ProyectosVigentes from './Tablas/ProyectosVigentes';
import ProyectosRegistrados from './Tablas/ProyectosRegistrados';
import ReingresosVigentes from './Tablas/ReingresosVigentes';
import ReingresosRegistrados from './Tablas/ReingresosRegistrados';
import ProrrogaContratoVigentes from './Tablas/ProrrogaContratoVigentes';
import ProrrogaContratoRegistrados from './Tablas/ProrrogaContratoRegistrados';
import NoProrrogaContrato from './Tablas/NoProrrogaContrato';
import Nombramiento from './Tablas/Nombramiento';
import Escalafon from './Tablas/Escalafon';
import OtrosMovimientos from './Tablas/OtrosMovimientos';
import DatosEmpleado from './Tablas/DatosEmpleado';

const initialActive = {
    activeAdscripción: false,
    activeAltas: false,
    activeBajas: false,
    activeReingresos: false,
    activeDatosEmpleados: false,
    activeProrrogaContrato: false,
    activeNoProrrogaContrato: false,
    activeEscalafon: false,
    activeCategoría: false,
    activeProyecto: false,
    activeNombramiento: false
};

const EstadPorPeriodo = ({ relacionLaboral, id, openEstadPorPeriodos, setOpenEstadPorPeriodos, openMenu, usuario }) => {

    const url = `${servicios_grupohit_nomina}/procesos/estadisticas`;
    const url2 = `${servicios_grupohit_nomina}/procesos/complementaria`;
    
    const [isLoading, setIsLoading] = useState(false);
    const [tableroInfo, setTableroInfo] = useState(null);
    const [active, setActive] = useState(initialActive);
    const [mostrar, setMostrar] = useState(0);
    const [requestTabla, setRequestTabla] = useState(null);
    const [error, setError] = useState(null);
    const [tipoConsulta, setTipoConsulta] = useState({ tipo: 0, nombre: ''});
    const [maxWidth, setMaxWidth] = useState(displayWindowSize().width);
    const [bloqueaCards, setBloqueaCards] = useState(false);
    const [disabled, setDisabled] = useState({ nomina: false, anio: true, periodo: true, button: true });
    const [textAlertE, setTextAlertE] = useState('');
    const [openAlertE, setOpenAlertE] = useState(false);
    const [nominaSelect, setNominaSelect] = useState('');
    const [anioSelect, setAnioSelect] = useState('');
    const [periodoSelect, setPeriodoSelect] = useState('');
    const [periodosNomina, setPeriodosNomina] = useState('');
    
    window.addEventListener("resize", () => setMaxWidth(displayWindowSize().width));

    useEffect(() => {
        axiosPeriodosNomina();
    }, []);

    const axiosPeriodosNomina = () => {
        axios.get( `${servicios_grupohit_nomina}/config/periodos/99`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setPeriodosNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setPeriodosNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const obtenerPeriodo = ( anioSelect, periodoSelect ) =>{
        const periodoNomina = periodosNomina.find( periodo => periodo.periodoAnio === parseInt(anioSelect) && periodo.periodoPerAnio === parseInt(periodoSelect) );
        if( periodoNomina ){
            const fecIni = ConvertDate( periodoNomina.periodoFecini ).split('/');
            const fecFin = ConvertDate( periodoNomina.periodoFecfin ).split('/');
            return `${fecIni[0]} de ${returnCadenaMes(fecIni[1])} ${fecIni[2]} - ${fecFin[0]} de ${returnCadenaMes(fecFin[1])} ${fecFin[2]}`;
        }else{
            return '';
        }
    }

    useEffect(() => {
        setIsLoading(false);
        setTableroInfo(null);
        setActive(initialActive);
        setMostrar(0);
        setRequestTabla(null);
        setError(null);
    }, [relacionLaboral]);

    const limpiaActiveCards = () => {
        setActive(initialActive);
    };

    const regresarTablero = () => {
        muestraTabla(false);
        setOpenEstadPorPeriodos(false);
        limpiaActiveCards();
        setTableroInfo(null);
        setBloqueaCards(false);
    };

    const servicioInfoCards = (request, type) => {
        setError(null);
        setIsLoading(true);
        setDisabled({ nomina: true, anio: true, periodo: true, button: true });
        setRequestTabla(request);
        let urlService = '';
        if(type === 1) urlService = url;
        if(type === 2) urlService = `${url2}/estadisticas`;

        axios.post(urlService, request, headersAuth())
        .then(res => { 
            switch(res.data.status) {
                case 200:
                    setTableroInfo(res.data.dataResponse);
                    break;
                default:
                    setTextAlertE(res.data.msg);
                    setTableroInfo([]);
                    break;
            }
        })
        .catch(error => { setTextAlertE(verifique_conexion); setOpenAlertE(true); })
        .finally(() => {
            setDisabled({ nomina: false, anio: false, periodo: false, button: false });
            setIsLoading(false);
        });
    };

    const moverATabla = () => {
        setBloqueaCards(false);
        window.scroll({
            top: 830,
            left: 0,
            behavior: 'smooth'
        });
    };

    const muestraTabla = (type, ejecutar) => {
        limpiaTabla();
        if(ejecutar === false) {
            setError(<AlertForm message="No hay datos que mostrar" />);
            moverATabla();
            return;
        }

        switch(type) {
            case 'adscripcion':
                if(tipoConsulta.tipo === 1) setMostrar(1);
                if(tipoConsulta.tipo === 2) setMostrar(2);
                break;
            case 'altas':
                if(tipoConsulta.tipo === 1) setMostrar(3);
                if(tipoConsulta.tipo === 2) setMostrar(4);
                break;
            case 'bajas':
                if(tipoConsulta.tipo === 1) setMostrar(5);
                if(tipoConsulta.tipo === 2) setMostrar(6);
                break;
            case 'categoria':
                if(tipoConsulta.tipo === 1) setMostrar(7);
                if(tipoConsulta.tipo === 2) setMostrar(8);
                break;
            case 'proyecto':
                if(tipoConsulta.tipo === 1) setMostrar(9);
                if(tipoConsulta.tipo === 2) setMostrar(10);
                break;
            case 'reingresos':
                if(tipoConsulta.tipo === 1) setMostrar(11);
                if(tipoConsulta.tipo === 2) setMostrar(12);
                break;
            case 'prorrogaContrato':
                if(tipoConsulta.tipo === 1) setMostrar(13);
                if(tipoConsulta.tipo === 2) setMostrar(14);
                break;
            case 'noProrrogaContrato':
                setMostrar(15);
                break;
            case 'datosEmpleados':
                setMostrar(16);
                break;
            case 'nombramiento':
                setMostrar(17);
                break;
            case 'escalafon':
                setMostrar(18);
                break;
            case 'otrosMovimientos':
                setMostrar(19);
                break;
            default:
                setMostrar(0);
                break;
        }
    };

    const limpiaTabla = () => {
        setMostrar(0);
        setError(null);
    };

    const obtieneNomina = nomina => {
        (nomina)
        ?   (nomina.id === 0)
            ?   setNominaSelect(nomina.nominaClaveNombre)
            :   setNominaSelect(`${nomina.nominaClave} / ${nomina.nominaClaveNombre}`)
        :   setNominaSelect('');
    };
    
    const obtieneAnio = anio => {
        (anio?.periodoAnio)
        ?   setAnioSelect(anio.periodoAnio)
        :   setAnioSelect('');
    };

    const obtienePeriodo = periodo => {
        (periodo?.periodoPerAnio)
        ?   setPeriodoSelect(periodo.periodoPerAnio)
        :   setPeriodoSelect('');
    };

    return (
        <Fragment>
        {(openEstadPorPeriodos) &&
            <div id={`modulo${id}`}>
                <div style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                    <PageTitleBar title={estad_por_periodo} path="Tablero"/>
                    <BtnBack tooltipText="Regresar" action={regresarTablero}/>
                    <CardComponent title={null}>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <Formulario
                                    servicioInfoCards={servicioInfoCards} setTableroInfo={setTableroInfo}
                                    muestraTabla={muestraTabla} limpiaActiveCards={limpiaActiveCards}
                                    setTipoConsulta={setTipoConsulta} disabled={disabled} setDisabled={setDisabled}
                                    obtieneNomina={obtieneNomina} obtieneAnio={obtieneAnio} obtienePeriodo={obtienePeriodo}
                                />
                            </div>
                        </div>
                    </CardComponent>
                    <div className="tablero">
                        <br/>
                        {(tableroInfo)
                        ?   <Tablero
                                maxWidth={maxWidth} info={tableroInfo} muestraTabla={muestraTabla}
                                active={active} setActive={setActive} tipoConsulta={tipoConsulta}
                                bloqueaCards={bloqueaCards}
                            />
                        :   (isLoading) && <SpinnerOval/>
                        }
                    </div>
                </div>
                { error }
                {(mostrar === 1)
                ?   <AdscripcionVigentes
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 2)
                ?   <AdscripcionRegistrados
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 3)
                ?   <AltasVigentes
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 4)
                ?   <AltasRegistrados
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 5)
                ?   <BajasVigentes
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 6)
                ?   <BajasRegistrados
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 7)
                ?   <CategoriasVigentes
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 8)
                ?   <CategoriasRegistrados
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 9)
                ?   <ProyectosVigentes
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 10)
                ?   <ProyectosRegistrados
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 11)
                ?   <ReingresosVigentes
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 12)
                ?   <ReingresosRegistrados
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 13)
                ?   <ProrrogaContratoVigentes
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 14)
                ?   <ProrrogaContratoRegistrados
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 15)
                ?   <NoProrrogaContrato
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 16)
                ?   <DatosEmpleado
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 17)
                ?   <Nombramiento
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 18)
                ?   <Escalafon
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                {(mostrar === 19)
                ?   <OtrosMovimientos
                        id={id} requestTabla={requestTabla} setBloqueaCards={setBloqueaCards} setError={setError} moverATabla={moverATabla}
                        informacionAdicional={{ usuario, nomina: nominaSelect, anio: anioSelect, periodo: periodoSelect, infoPeriodo: obtenerPeriodo(anioSelect, periodoSelect) }}
                    />
                :   null
                }
                <AlertError show={openAlertE} setOpenAlertSyE={setOpenAlertE} title="Petición Fallida" text={textAlertE} textbtnconfirm="Aceptar" type="error"/>
            </div>
        }
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    openMenu: state.SystemReducer.openMenu,
    usuario: state.SessionReducer.usuario
});

export default connect(mapStateToProps, null)(EstadPorPeriodo);