import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../../../Constants/Constants';
import ModalService from "../../../../../ModalService/ModalService";
import TablaMovimientosEscalafon from '../../../../RecursosHumanos/Especiales/Components/Escalafon/TablaMovimientosEscalafon';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const ModalEscalafones = ({ id, openModalForm, setOpenModalForm, elementSelect, setElementSelect, errorTable }) => {

    const [listadoEmpleadosEscalafon, setListadoEmpleadosEscalafon] = useState({ info: null, empleadosListaEscalafon: null });

    const { info, empleadosListaEscalafon } = listadoEmpleadosEscalafon;

    useEffect(() => {
        if(elementSelect && elementSelect.id) {
            axios.get(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/${elementSelect.id}`, headersAuth())
            .then(res => {
                if(res.data.status === 200) {
                    const listadoEmpleados = res.data.dataResponse.empleados.map(item => {
                        item.nombreCompleto = `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`
                        return item;
                    });
                    setListadoEmpleadosEscalafon({ info: elementSelect, empleadosListaEscalafon: listadoEmpleados });
                } else {
                    setListadoEmpleadosEscalafon({ info: elementSelect, empleadosListaEscalafon: [] });
                    errorTable(res.data.msg);
                }
            })
            .catch(error => {
                setListadoEmpleadosEscalafon({ info: elementSelect, empleadosListaEscalafon: [] });
                errorTable(verifique_conexion);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const cerrarModal = () => {
        setOpenModalForm(false);
        setListadoEmpleadosEscalafon({ info: null, empleadosListaEscalafon: null });
        setElementSelect({});
    };
    
    return (
        <ModalService
            title="Escalafones"
            parentNode={id}
            minWidth="90%"
            maxWidth="90%"
            isOpen={openModalForm}
            onClose={cerrarModal}
        >
            {(empleadosListaEscalafon)
            ?   <TablaMovimientosEscalafon id={`${id}EscalafonListado`} info={info} empleadosListaEscalafon={empleadosListaEscalafon}/>
            :   <SpinnerOval/>
            }
        </ModalService>
    );
};

export default ModalEscalafones;