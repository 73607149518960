import React, { useState, createRef, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_admon, servicios_grupohit_org, verifique_conexion } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import AutocompleteComponent from '../../../../../../../../Services/Autocomplete/AutocompleteComponent';
import SelectValidation from '../../../../../../../../Services/Validation/SelectValidation';
import AlertError from '../../../../../../../../Services/Alerts/AlertSyE';
import { validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const FormPlazaArea = ({ actualizar, data }) => {

    const [selected, setSelected] = useState({ uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' } });
    const [cPresupuestales, setCPresupuestales] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ctosTrabajo, setCtosTrabajo] = useState([]);
    const [disabled, setDisabled] = useState({ departamento: true, zonaPago: true });
    const [errorCPresupuestal, setErrorCPresupuestal] = useState(false);
    const [errorDepartamento, setErrorDepartamento] = useState(false);
    const [textAlertError, setTextAlertError] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const { departamento: departamentoDisabled, zonaPago: zonaPagoDisabled } = disabled;
    const { departamento } = selected;

    const ctoTrabajoRef = createRef('');

    useEffect(() => {
        //servicio para cargar las claves presupuestales
        axios.get(`${servicios_grupohit_admon}/presupuestal/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setCPresupuestales(res.data.dataResponse);
                    break;
                case 404:
                    setCPresupuestales([]);
                    break;
                default:
                    setTextAlertError(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setTextAlertError(verifique_conexion);
            setOpenAlertSyE(true);
        });
    }, [])

    const activaCampos = (campo, valor) => {
        if(campo === "CPresupuestal") {
            if(valor !== null) {
                setDisabled({ ...disabled, departamento: false, zonaPago: false });
            } else {
                setDepartamentos([]);
                setDisabled({ ...disabled, departamento: true, zonaPago: true });
                setSelected({ ...selected, uResponsable : { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' } });
            }
        } else {
            setDisabled({ departamento: false, zonaPago: false });
            setSelected({ ...selected, uResponsable : { id: '',  clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' } });
        }
    };

    const validarCPresupuestal = valor => {
        activaCampos("CPresupuestal", valor);

        if(valor) {
            setErrorCPresupuestal(false);
            setSelected({ ...selected,
                uResponsable: {
                    id: valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id,
                    clave: valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaUr,
                    nombre: valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaRazonSocial
                },
                uEjecutora: {
                    id: valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.id,
                    clave: valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoraclave,
                    nombre: valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoranom 
                },
                programa: {
                    id: valor.obraAccion.proyecto.subPrograma.programa.id,
                    clave: '',
                    nombre: valor.obraAccion.proyecto.subPrograma.programa.programanom 
                },
                subprograma: {
                    id: valor.obraAccion.proyecto.subPrograma.id,
                    clave: valor.obraAccion.proyecto.subPrograma.subProgramaClave,
                    nombre: valor.obraAccion.proyecto.subPrograma.subProgramaNom 
                },
                pAccion: {
                    id: valor.obraAccion.proyecto.id,
                    clave: '',
                    nombre: valor.obraAccion.proyecto.proyectonom
                },
                oAccion: {
                    id: valor.obraAccion.id,
                    clave: valor.obraAccion.obraAccionClave,
                    nombre: valor.obraAccion.obraAccionNom
                }, 
                cPresupuestal: {
                    id: valor.id,
                    clave: valor.cpresupuestalclave,
                    nombre: valor.cpresupuestalnom
                },
                departamento: {
                    id: '',
                    clave: '',
                    nombre: ''
                }
            });
            ctoTrabajoRef.current.value = '';
            //petición para obtener los departamentos, según la unidad responsable seleccionada
            axios.get(`${servicios_grupohit_org}/area/${valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setDepartamentos(res.data.dataResponse);
                        break;
                    case 404:
                        setDepartamentos([]);
                        break;
                    default:
                        setTextAlertError(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setTextAlertError(verifique_conexion);
                setOpenAlertSyE(true);
            });

            //petición para obtener las zonas de pago, según la unidad responsable seleccionada
            axios.get(`${servicios_grupohit_admon}/compania/domicilios/${valor.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayCtosTrabajo = res.data.dataResponse.filter(item => item.tipoDom.id === 1);
                        setCtosTrabajo(arrayCtosTrabajo);
                        break;
                    case 404:
                        setCtosTrabajo([]);
                        break;
                    default:
                        setTextAlertError(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setTextAlertError(verifique_conexion);
                setOpenAlertSyE(true);
            });
        } else {
            setSelected({ ...selected,
                uResponsable: { id: '', clave: '', nombre: '' },
                uEjecutora: { id: '', clave: '', nombre: '' },
                programa: { id: '', clave: '', nombre: '' },
                subprograma: { id: '', clave: '', nombre: '' },
                pAccion: { id: '', clave: '', nombre: '' },
                oAccion: { id: '', clave: '', nombre: '' },
                cPresupuestal: { id: '', clave: '', nombre: '' },
                departamento: { id: '', clave: '', nombre: '' },
            });
            ctoTrabajoRef.current.value = '';
            setDepartamentos([]);
            setCtosTrabajo([]);
        }
    };

    const validarDepartamento = valor => {
        if(valor) {
            setErrorDepartamento(false);
            setSelected({ ...selected, departamento: { id: valor.id, clave: valor.areacve, nombre: valor.areanom } });
        } else {
            setSelected({ ...selected, departamento: { id: '', clave: '', nombre: '' } });
        }
    };

    const validar = e => {
        e.preventDefault();

        const validarCampos = () => {
            let respuesta = true;
            
            if(selected.cPresupuestal.id === '') {
                setErrorCPresupuestal(true);
                respuesta = false;
            }
            if(selected.departamento.id === '') {
                setErrorDepartamento(true);
                respuesta = false;
            }
            if(!validarSubmit('formPlazaArea')) {
                respuesta = false;
            }

            return respuesta;
        };

        if(validarCampos()) {
            const plaza = { 
                id: data.id,
                areaid: departamento.id,
                plazaclave: data.plazaclave,
                plazanivel: 1,
                plazasecuencia: 1,
                plazadisponible: data.plazadisponible,
                plazaidsup: 0,
                puesto: data.puesto,
                domicilio: parseInt(ctoTrabajoRef.current.value),
                nominaId: data.nominaId,
                empleadoTitular: (data.empleadotitular) ? data.empleadotitular.id : null 
            };

            actualizar(plaza, true);
        }
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <div><label><strong>Clave Presupuestal / Proyecto (Actual): </strong></label></div>
                    <div><label>{ data.presupuestal.cpresupuestalclave }</label></div>
                    <div><label>{ data.presupuestal.cpresupuestalnom }</label></div>
                </div>
            </div>
            <hr/>
        {(cPresupuestales.length > 0)
        ?   <Fragment>
                {(selected.uResponsable.id !== '') &&
                <Fragment>
                    <div><label><strong>Unidad Responsable / Dependencia: </strong>{ selected.uResponsable.nombre }</label></div>
                    <div><label><strong>Unidad Ejecutora: </strong>{ selected.uEjecutora.nombre }</label></div>
                    <div><label><strong>Programa: </strong>{ selected.programa.nombre }</label></div>
                    <div><label><strong>Subrograma: </strong>{ selected.subprograma.nombre }</label></div>
                    <div><label><strong>Proyecto Acción: </strong>{ selected.pAccion.nombre }</label></div>
                    <div><label><strong>Obra Acción: </strong>{ selected.oAccion.nombre }</label></div>
                    <div><label><strong>Clave Presupuestal: </strong>{ selected.cPresupuestal.clave }</label></div>
                    <br/>
                </Fragment>
                }        
                <form id="formPlazaArea" onSubmit={validar}>
                    <AutocompleteComponent
                        id="cPresupuestal"
                        title="Clave Presupuestal / Proyecto"
                        options={cPresupuestales}
                        optionListView="cpresupuestalnom"
                        action={validarCPresupuestal}
                        placeholder="Clave Presupuestal / Proyecto"
                        tooltipText="Busque la Clave Presupuestal / Proyecto, por nombre o clave, en el campo de autocompletado"
                        tooltipName="tooltipCPresupuestal"
                        error={errorCPresupuestal}
                        required
                    />
                    <AutocompleteComponent
                        id="departamento"
                        title="Clave Departamento / Departamento"
                        options={departamentos}
                        optionListView="areanom"
                        action={validarDepartamento}
                        disabled={departamentoDisabled}
                        placeholder="Clave Departamento / Departamento"
                        tooltipText="Busque el Departamento, por nombre o clave, en el campo de autocompletado"
                        tooltipName="tooltipDepartamento"
                        error={errorDepartamento}
                        clearDiasabled
                        required
                    />
                    <SelectValidation
                        title="Identificador de la Plaza / Centro de Trabajo" arrayOpciones={ctosTrabajo} refValue={ctoTrabajoRef}
                        defaultValue="" disabled={zonaPagoDisabled} required
                    />
                    <Button variant="contained" className="btn-color" type="submit">Actualizar</Button>
                </form>
                <AlertError show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={textAlertError} textbtnconfirm="Aceptar" type="error"/>
            </Fragment>
        :   <SpinnerOval />
        }
        </Fragment>
    );
}
 
export default FormPlazaArea;