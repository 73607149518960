import React from 'react';

const InfoExpLaboral = ({ expLaboral, obtieneNombreMes }) => {
    return (
        <div className="formRelLab">
            <div className="form-left">
                <div><strong>Empresa: </strong>{ expLaboral.empNom }</div>
                <div><strong>Desde: </strong>{ `${obtieneNombreMes(parseInt(expLaboral.dmes))} de ${expLaboral.danio}` }</div>
                <div><strong>Jefe Inmediato: </strong>{ expLaboral.jefe }</div>
            </div>
            <div className="form-right">
                <div><strong>Puesto: </strong>{ expLaboral.puestoExp }</div>
                <div><strong>Hasta: </strong>{ `${obtieneNombreMes(parseInt(expLaboral.hmes))} de ${expLaboral.hanio}` }</div>
                <div><strong>Teléfono: </strong>{ expLaboral.tel }</div>
            </div>
        </div>
    );
}
 
export default InfoExpLaboral;