import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import TooltipComponent from '../../../../../../../Services/Tooltip/TooltipComponent';
import { InfoRounded } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import { FormControlLabel } from '@material-ui/core';

const ConceptoCheckbox = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const ConceptosCheck = ({ conceptosTPD, setConceptosTPD }) => {

    const handleChange = e => {
        setConceptosTPD(!conceptosTPD);
    };

    return (
        <div className="row">
            <div className="col">
                <FormControl component="fieldset">
                    <label>
                        Concepto
                        <TooltipComponent tooltipText="Seleccione el concepto requerido">
                            <span className="spanInfo"><InfoRounded color="disabled"/></span>
                        </TooltipComponent>
                    </label>
                    <FormGroup>
                        <div className="accesosContenido mb-2">
                            <FormControlLabel
                                control={<ConceptoCheckbox checked={conceptosTPD} onChange={handleChange} name="Pensiones Alimenticias"/>}
                                label="Pensiones Alimenticias"
                            />
                        </div>
                    </FormGroup>
                </FormControl>
            </div>
        </div>
    );
}
 
export default ConceptosCheck;