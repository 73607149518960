import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
//import PasswordValidation from '../../../../../../../Services/Validation/PasswordValidation';
import FileService from '../../../../CropService/FileService';
import ProgressBar from '../../../../CropService/ProgressBar';

import AlertForm from '../../../../../../../Services/AlertForm';
import Tooltip from '@material-ui/core/Tooltip';
import Search from '@material-ui/icons/Search';

import axios from 'axios';
import { servicios_grupohit } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';

const FormularioCertificados = ({ agregar, alertaError, certificados, setNuevosCertificados }) => {
    const passwordRef = createRef('');
    const [ error, setError ] = useState( null );
    const [ progreso, setProgreso ] = useState( 0 );
    const [ documentoCer, setDocumentoCer ] = useState( null );
    const [ documentoKey, setDocumentoKey ] = useState( null );

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarCertificado' ) ){
            setError( null );
            const password = passwordRef.current.value;
            if( documentoCer && documentoKey ){
                if( documentoCer.name.includes( '.cer' ) && documentoKey.name.includes( '.key' ) ){
                    setProgreso( 100 );
                   enviarCertificados({
                    archivos: [
                            { archivo: documentoCer.base64, extension: ".cer", nombreArchivo: documentoCer.name.replace('.cer', '') },
                            { archivo: documentoKey.base64, extension: ".key", nombreArchivo: documentoKey.name.replace('.key', '') },
                        ],
                        pass: password
                    });
                }else{
                    setError( <AlertForm message="Asegúrate de seleccionar archivos con formato .cer y .key" /> );
                }
            }else{
                setError( <AlertForm message="Selecciona los archivos .cer y .key para continuar" /> );
            }
        }
    }

    const enviarCertificados = ( archivos ) => {
        axios.post( `${servicios_grupohit}/cfdi/`, archivos, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        setError( null );
                        if( certificados ){
                            setNuevosCertificados( false );
                        }
                        break;
                    case 400: //Datos no válidos
                    case 404: //El directorio no contiene ningun documento para mostrar
                        setProgreso( 0 );
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        setProgreso( 0 );
                        alertaError();
                        break;
                }
            })
            .catch( error => {
                setProgreso( 0 );
                alertaError();
            })
    }

    return (
        <form id="AgregarCertificado" onSubmit={enviar}>
            <span className="titleArchivos mb-2">Archivos</span>
            <div className="movimientosFlex mb-2">
                <div className="movimientosIzquerdo mt-3">
                    <span> <b>Extensión de Archivo:</b> .cer </span>
                </div>

                <div className="movimientosUploadDerecho file-upload text-right">
                    <Tooltip title="Cargar archivo .cer" placement="top-start">
                        <label htmlFor="carga_certificados_1" className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                    </Tooltip>
                    <FileService inputId="carga_certificados_1" getFile={(file) => setDocumentoCer(file)} formatAccept={`.cer`}/>
                </div>
            </div>
            { documentoCer &&
                <div className="mb-3">
                    <ProgressBar documento={documentoCer} actionClose={() => setDocumentoCer(null)} progressValue={progreso}/>
                </div>
            }

            <div className="movimientosFlex mb-2">
                <div className="movimientosIzquerdo mt-3">
                    <span> <b>Extensión de Archivo:</b> .key </span>
                </div>

                <div className="movimientosUploadDerecho file-upload text-right">
                    <Tooltip title="Cargar archivo .key" placement="top-start">
                        <label htmlFor="carga_certificados_2" className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                    </Tooltip>
                    <FileService inputId="carga_certificados_2" getFile={(file) => setDocumentoKey(file)} formatAccept={`.key`}/>
                </div>
            </div>
            { documentoKey &&
                <div className="mb-3">
                    <ProgressBar documento={documentoKey} actionClose={() => setDocumentoKey(null)} progressValue={progreso}/>
                </div>
            }

            <InputValidation title="Contraseña" type="passCFDI" placeholder="Escriba la contraseña del certificado" maxLength='256'
                tooltipName={'tooltipCertificadoPass'} tooltipText={'De 8 a 256 caracteres'}
                refValue={passwordRef} required/>

            {error}
            { certificados ?
                <div className="row mt-3">
                    <div className="col-md-6 col-sm-6">
                        <Button variant="outlined" className="btn-outline" onClick={ () => setNuevosCertificados( false) }> Cancelar </Button>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <Button variant="contained" className="btn-color" type="submit"> Aceptar </Button>
                    </div>
                </div>
                :
                <div className="mt-3">
                    <Button variant="contained" className="btn-color" type="submit">Cargar Certificado</Button>
                </div>
            }
        </form>
    );
};

export default FormularioCertificados;