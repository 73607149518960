import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, csvDownload } from '../../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import FileService from '../../../../CropService/FileService';
import AlertForm from '../../../../../../../Services/AlertForm';

import Tooltip from '@material-ui/core/Tooltip';
import Search from '@material-ui/icons/Search';
import { Progress } from 'reactstrap';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { obtenerInformacionExcel, axiosExcel } from './HelperCargasMasivas';
//import csvDownload from 'json-to-csv-export';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { csvDownloadOptional, xlsDownloadOptional } from '../../../../DataTableService/ActionsDatatable';

const CargasMasivas = ({ modulo, errorAlert, successAlert, enviar }) => {
    const [ informacionExcel, setInformacionExcel ] = useState( null );
    const [ errorDescarga, setErrorDescarga ] = useState( null );
    const [ descargaArchivo, setDescargaArchivo ] = useState( null );
    useEffect(() => {
        obtenerInformacionExcel( modulo.id, setInformacionExcel );
        // eslint-disable-next-line
    }, []);

    const decargarExcel = () => {
        if( modulo.id === 21 || modulo.id === 30 || modulo.id === 902 ){ //Proyectos & Empleados & PROROGAS
            const downloadLink = document.createElement("a");
            downloadLink.href = informacionExcel.informacion;
            downloadLink.download = `${informacionExcel.nombreArchivo}${ modulo.id === 902 ?'.csv' :'.xls'}`;
            downloadLink.click();
        }else{
            if( modulo.id === 906 ){
                xlsDownloadOptional( informacionExcel.informacion, `${informacionExcel.nombreArchivo}.xls` );
            }else{
                csvDownload( informacionExcel.informacion, `${informacionExcel.nombreArchivo}.csv` );
            }
        }
    }

    const decargarExcel2 = () => {
        setErrorDescarga( null );
        setDescargaArchivo( true );
        setTimeout( () => decargarExcelArchivo(), 1000 );
    }

    const decargarExcelArchivo = () => {
        if( informacionExcel.informacion.length > 0 ){
            csvDownloadOptional( informacionExcel.informacion, `${informacionExcel.nombreArchivo.toUpperCase()}.csv` );
            setDescargaArchivo( null );
        }else{
            setErrorDescarga( <AlertForm message="No existen registros para descargar" /> );
            setDescargaArchivo( null );
        }
    }

    /* ---------------------------------------- Funcion para obtener Tipo de Documento --------------------------------------- */
    const fechaRef = createRef('');
    const anioActualizacionRef = createRef('');
    const [ error, setError ] = useState( null );
    const [ progreso, setProgreso ] = useState( 0 );
    const [ documentoExcel, setDocumentoExcel ] = useState( null );
    const [ loading, setLoading ] = useState(false);

    const obtenerArchivo = ( file ) => {
        setDocumentoExcel( null );
        setError( null );
        setDocumentoExcel( file );
    }

    const enviarExcel64 = () => {
        setError( null );
        if( documentoExcel.name.includes( 'csv' ) || documentoExcel.name.includes( 'xls' ) ){
            if(enviar){
                setProgreso( 100 );
                enviar({ archivo: documentoExcel.base64 }, setProgreso, setError);
            }else if( modulo.id === 25 || modulo.id === 27 ){ //Tabuladores Sueldo & Quinquenios
                if( validarSubmit( 'enviarTabulador' ) ){
                    setProgreso( 100 );
                    const fecha = inputDateToLong( fechaRef.current.value );
                    axiosExcel( modulo.id, { archivo: documentoExcel.base64, fecha }, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
                }
            }else if( modulo.id === 906 ){ //Actualización de Proyectos
                if( validarSubmit( 'enviarActualizacionProyectos' ) ){
                    setProgreso( 100 );
                    const anioActualizacion = anioActualizacionRef.current.value;
                    axiosExcel( modulo.id, { archivo: documentoExcel.base64, anio: parseInt(anioActualizacion) }, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
                }
            }else{
                setProgreso( 100 );
                axiosExcel( modulo.id, { archivo: documentoExcel.base64 }, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert, setLoading );
            }
        }else{
            setError( <AlertForm message={`Asegúrate de seleccionar un archivo con formato ${modulo.id === 21 || modulo.id === 30 ? '.csv o .xls' : '.csv'}`} /> );
        }
    }
    /* ----------------------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <div className="movimientosFlex">
                { modulo.id === 905 ? //Baja Masiva de Empleados
                    <div className="movimientosIzquerdo">
                        { informacionExcel ?
                            !descargaArchivo ?
                                <Button className="btn-color" onClick={() => decargarExcel2()}> Descargar Formato </Button>
                                :
                                <Button className="btn-color" disabled> Descargar Formato <SpinnerOval /> </Button>
                            :
                            <Button className="btn-color" disabled> Descargar Formato <SpinnerOval/></Button>
                        }
                    </div>
                    :
                    <div className="movimientosIzquerdo">
                        { informacionExcel ?
                            <Button className="btn-color" onClick={() => decargarExcel()}> Descargar Formato </Button>
                            :
                            <Button className="btn-color" disabled> Descargar Formato <SpinnerOval/></Button>
                        }
                    </div>
                }

                <div className="movimientosUploadDerecho file-upload">
                    <Tooltip title="Cargar archivo" placement="top-start">
                        <label htmlFor="carga_masiva_excel" className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                    </Tooltip>
                    <FileService inputId="carga_masiva_excel" getFile={obtenerArchivo} formatAccept={ modulo.id === 21 || modulo.id === 30 ? '.csv, .xls, .xlsx' : modulo.id === 906 ? '.xls, .xlsx' : '.csv' } disabled={loading}/>
                </div>
            </div>
            { errorDescarga }

            { documentoExcel &&
                <React.Fragment>
                    <div className="movimientosFlex mt-3">
                        <div className="movimientosIzquerdo upload">
                            <div>
                                <span> {documentoExcel.name} </span>
                                <button style={{ float: "right" }} type="button" className="close" onClick={() => {setDocumentoExcel( null ); setError(null);}} disabled={loading}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Progress animated value={progreso} />
                        </div>
                        <div className="movimientosUploadDerecho btn-upload">
                            {loading
                            ?   <SpinnerOval/>
                            :   <Tooltip title="Enviar Archivo" placement="top-start">
                                    <Button variant="contained" className="btn-color" onClick={() => enviarExcel64()}>
                                        <CloudUpload/>
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                    </div>
                    { modulo.id === 25 || modulo.id === 27 ? //Tabuladores Sueldos & Quinquenios
                        <form id={'enviarTabulador'}>
                            <InputDateValidation title="Fecha Inicial" refValue={fechaRef} required disabled={loading}/>
                        </form>
                        :
                        modulo.id === 906 ? //Actualización de Proyectos
                            <form id={'enviarActualizacionProyectos'}>
                                <InputValidation title="Año de Actualización" type="agnio" placeholder="Escriba el Año de Actualización"
                                    tooltipText={'4 números'} tooltipName={'tooltipAnioUpdate'} maxLength='4' onlyNumbers
                                    refValue={anioActualizacionRef}
                                    required disabled={loading}/>
                            </form>
                            :
                            null
                    }
                    { error }
                </React.Fragment>
            }
            <Button style={{ display: 'none' }}> Test </Button>
        </React.Fragment>
    );
};

export default CargasMasivas;