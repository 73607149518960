import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import ModalService from '../../../../ModalService/ModalService';
import Formulario from '../components/Formulario';
import pdf from '../assets/pdf/pdf.json';
import xml from '../assets/xml/xml.json';

const ModalDescargarCFDI = ({ nombreModulo, open, setOpen, nombre, setOpenLoading }) => {

    const cerrarModal = () => {
        setOpen(false);
    };

    const crearZip = (data, type) => {
        setOpenLoading(true);
        var zip = new JSZip();

        data.forEach((value,i) => {
            zip.file(`${value.nombre}.${type}`, value.archivo, { base64: true });
        });
        
        zip.generateAsync({ type:"blob" })
        .then(function(content) {
            saveAs(content, `${type}.zip`);
            setOpenLoading(false);
        });
    };

    const descargarCFDI = (data, tipo) => {        
        if(tipo === 'PDF') {
            crearZip(pdf, tipo);
        }
        if(tipo === 'XML') {
            crearZip(xml, tipo);
        }
    };

    return (
        <ModalService
            title="Descargar CFDI"
            parentNode={nombreModulo}
            isOpen={open}
            onClose={cerrarModal}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <Formulario
                        nombre={nombre}
                        action={descargarCFDI}
                    />
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalDescargarCFDI;