import React from 'react';

const TimbradoErrores = ({ type }) => {
    return (
        <div className="text-center" style={{ color: '#747474' }}>
            <p><strong>CVE - INTERNA</strong></p>
            <p><strong>Año:</strong>{' '}2020{' '}<strong>Periodo:</strong>{' '}9</p>
            <p><strong>Tipo de Acumulado:</strong>{' '}Nómina</p>
            <p>{`Total de comprobantres ${type}: 20`}</p>
            <p>{`Total de comprobantres ${type} correctamente: 15`}</p>
            <p><strong>{`Total de comprobantes ${type} correctamente: 5`}</strong></p>
        </div>
    );
}
 
export default TimbradoErrores;