import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormTiposSecDep = ({ enviarTipoSecDep, elementSelect, actualizarTipoSecDep, isLoading }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const nombreRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('TipoSecDep')) {
            const nombre = nombreRef.current.value
            enviarTipoSecDep({ nombre });
        }
    };

    const actualizar = () => {
        if(validarSubmit('TipoSecDep')) {
            const nombre = nombreRef.current.value

            actualizarTipoSecDep({ nombre }, elementSelect.id);
        }
    };

    return (
        <Fragment>
            <form id="TipoSecDep" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Nombre" type="nombreTipoSecDep" placeholder="Escriba el nombre del tipo de secretaría o dependencia"
                    tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                    tooltipName="tooltipNomTipSecDep" refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.nombre : '' }
                    maxLength={128} required/>
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}

export default FormTiposSecDep;