import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, CODIGOPOSTAL_EXPR, deleteOptionals } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

/* Importaciones para poder realizar una peticion axios */
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_catalogo, ocurrio_un_error } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';

const FormularioDomicilios = ({ error, enviarDomicilio, elementSelect, actualizarDomicilio, informacion }) => {
    const tipoDomRef = createRef('');
    const codColoniaRef = createRef('');
    const domCiaCalleRef = createRef('');
    const domCiaNumIntRef = createRef('');
    const domCiaNumExtRef = createRef('');
    const domCiaTelRef = createRef('');
    const domCiaExtRef = createRef('');
    /* ------------------------- Metodo axios para consultar Colonias por Código Postal ------------------------- */
    const codPostalRef = createRef('');
    const [ errorColonias, setErrorColonias ] = useState( null );
    const [ colonias, setColonias ] = useState( null );
    const [ informacionColonias, setInformacionColonias ] = useState( null );
    useEffect(() => {
        if( elementSelect ){
            axiosColonias( elementSelect.codPostal.codigopCp );
        }
        // eslint-disable-next-line
    }, []);

    const axiosColonias = ( postalCode ) => {
        axios.get( `${servicios_grupohit_catalogo}s/colonias/${postalCode}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const { paisNom, estadoNom, ciudadNom, colonias } = respuesta.data.dataResponse;
                        setErrorColonias( null );
                        setColonias( colonias );
                        setInformacionColonias({ postalCode, paisNom, estadoNom, ciudadNom });
                        break;
                    case 404: //El código postal no existe
                        setErrorColonias( <AlertForm message={ respuesta.data.msg }/> );
                        setColonias( null );
                        break;
                    default:
                        setErrorColonias( <AlertForm message={ ocurrio_un_error }/> );
                        setColonias( null );
                        break;
                }
            })
            .catch( error => {
                setErrorColonias( <AlertForm message={ ocurrio_un_error }/> );
                setColonias( null );
            })
    }

    const consultarColonias = () => {
        const postalCode = codPostalRef.current.value;
        if( CODIGOPOSTAL_EXPR.test( postalCode ) ){
            axiosColonias( postalCode );
        }else{
            setColonias( null );
            setInformacionColonias( null );
        }
    }
    /* ---------------------------------- Funciones para agregar & actualizar ----------------------------------- */
    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarDomicilio' ) ){
            if( codColoniaRef.current ){
                const tipoDom = tipoDomRef.current.value;
                const codPostal = codColoniaRef.current.value;
                const domCiaCalle = domCiaCalleRef.current.value;
                const domCiaNumInt = domCiaNumIntRef.current.value;
                const domCiaNumExt = domCiaNumExtRef.current.value;
                const domCiaTel = domCiaTelRef.current.value;
                const domCiaExt = domCiaExtRef.current.value;
                enviarDomicilio( deleteOptionals({ companiaId: informacion.dependenciaID, tipoDom: parseInt(tipoDom), codPostal: parseInt(codPostal),
                    domCiaCalle, domCiaNumExt, domCiaNumInt, domCiaTel, domCiaExt }) );
            }else{
                setErrorColonias( <AlertForm message={ 'Consulte un Código Postal válido para continuar' }/> );
            }
        }
    }

    const actualizar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'EditarDomicilio' ) ){
            if( codColoniaRef.current ){
                const tipoDom = tipoDomRef.current.value;
                const codPostal = codColoniaRef.current.value;
                const domCiaCalle = domCiaCalleRef.current.value;
                const domCiaNumInt = domCiaNumIntRef.current.value;
                const domCiaNumExt = domCiaNumExtRef.current.value;
                const domCiaTel = domCiaTelRef.current.value;
                const domCiaExt = domCiaExtRef.current.value;
                actualizarDomicilio( deleteOptionals({ tipoDom: parseInt(tipoDom), codPostal: parseInt(codPostal),
                    domCiaCalle, domCiaNumExt, domCiaNumInt, domCiaTel, domCiaExt }), elementSelect.id);
            }else{
                setErrorColonias( <AlertForm message={ 'Consulte un Código Postal válido para continuar' }/> );
            }
        }
    }

    return (
        <form id={ elementSelect ? 'EditarDomicilio' : 'AgregarDomicilio' } onSubmit={ elementSelect ? actualizar : enviar }>
            <SelectValidation title="Tipo de Domicilio" arrayOpciones={informacion.tiposDomicilio} keyOpcion="tipodomNom"
                refValue={tipoDomRef} defaultValue={ elementSelect ? elementSelect.tipoDom.id : '' } required/>

            <label className="mt-2 mb-0"> Código Postal* </label>
            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <InputValidation title="" type="cp" placeholder="Escriba el código postal del domicilio" maxLength='5'
                        refValue={codPostalRef} defaultValue={ elementSelect ? elementSelect.codPostal.codigopCp : '' } required/>
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                    <Button className="btn-third btn-with" onClick={() => consultarColonias()}> Consultar </Button>
                </div>
            </div>

            { errorColonias }
            { colonias && informacionColonias &&
                <React.Fragment>
                    <span> <b> Estado: </b> {informacionColonias.estadoNom} </span> <br/>
                    <span> <b> Ciudad: </b> {informacionColonias.ciudadNom} </span>
                    <SelectValidation title="Colonia" arrayOpciones={colonias} keyOpcion="codigopColonia"
                        refValue={codColoniaRef} defaultValue={ elementSelect ? elementSelect.codPostal.id : '' } required/>
                </React.Fragment>
            }

            <InputValidation title="Calle" type="calle" placeholder="Escriba la calle del domicilio" maxLength='256'
                tooltipText={'Máximo 256 letras, números, espacios y/o caracteres especiales . , - & #'} tooltipName={'tooltipCalleDom'}
                refValue={domCiaCalleRef} defaultValue={ elementSelect ? elementSelect.domCiaCalle : '' } required/>

            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Número Exterior" type="numIntExt" placeholder="Escriba el número exterior del domicilio" maxLength='16'
                        tooltipText={'Máximo 16 letras, números, espacios y/o caracteres especiales - #'} tooltipName={'tooltipExtDom'}
                        refValue={domCiaNumExtRef} defaultValue={ elementSelect ? elementSelect.domCiaNumExt : '' } required/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Número Interior" type="numIntExt" placeholder="Escriba el número interior del domicilio" maxLength='16'
                        tooltipText={'Máximo 16 letras, números, espacios y/o caracteres especiales - #'} tooltipName={'tooltipIntDom'}
                        refValue={domCiaNumIntRef} defaultValue={ elementSelect ? elementSelect.domCiaNumInt : '' }
                        optional={true} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Teléfono" type="telefono" placeholder="Escriba el número de teléfono del domicilio"
                        tooltipText={'10 números'} tooltipName={'tooltipTelDom'} maxLength='10' onlyNumbers
                        refValue={domCiaTelRef} defaultValue={ elementSelect ? elementSelect.domCiaTel : '' }
                        optional={true} />
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Extensión Telefónica" type="extTelefonica" placeholder="Escriba la extensión telefónica del domicilio"
                        tooltipText={'De 1 a 5 números'} tooltipName={'tooltipExtTelDom'} maxLength='5' onlyNumbers
                        refValue={domCiaExtRef} defaultValue={ elementSelect ? elementSelect.domCiaExt : '' }
                        optional={true} />
                </div>
            </div>

            {error}
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
        </form>
    );
};

export default FormularioDomicilios;