import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import { empleados_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import ModalPagosUnicos from './ModalPagosPendientes';

const Empleados = ({ id, permisos, obtenerEmpleadosActivos, empleadosActivos, pagosPendientes }) => {

    const [textAlert, setTextAlert] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertS, setOpenAlertS] = useState(false);
    const [openModalPagosU, setOpenModalPagosU] = useState(false);

    const { empPagosPendientes, retienePagos} = pagosPendientes;

    useEffect(() => {
        obtenerEmpleadosActivos(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (empPagosPendientes !== null)
        ? setOpenModalPagosU(true)
        : setOpenModalPagosU(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empPagosPendientes]);

    const error = msg => {
        setTextAlert(msg);
        setOpenAlert(true)
    };

    const success = msg => {
        setTextAlert(msg);
        setOpenAlertS(true)
    };

    return (
        <div id={`modulo${id}`}>
            {   
                (empleadosActivos)
                ?   <DataTableService id={id} title={empleados_}
                        columns={[
                            { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                            { field: "nombreCompleto", title: "Nombre" },
                            { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
                            { field: "empleadoRfc", title: "RFC" },
                            { field: "empleadoNss", title: "NSS" },
                            { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                            { field: "puestoCve", title: "Clave Puesto" },
                            { field: "puestoNom", title: "Puesto" },
                            { field: "nominaNombre", title: "Nómina" },
                            { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                            { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                        ]}
                        data={empleadosActivos}
                        customOptionsTable={{ filtering: true }}
                        permisos={permisos}
                        informacionAdicional={{ permisos }}
                        showIconEmployee
                    />
                :   <SpinnerOval />
            }
            <AlertError show={openAlert} setOpenAlertSyE={setOpenAlert} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <AlertError show={openAlertS} setOpenAlertSyE={setOpenAlertS} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            {empPagosPendientes &&
                <ModalPagosUnicos nombreModulo={id} elementSelect={empPagosPendientes} retienePagos={retienePagos} openModal={openModalPagosU} setOpenModal={setOpenModalPagosU} successTable={success} errorTable={error}/> 
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
    pagosPendientes: state.EmpleadosReducer.pagosPendientes,
});
  
const mapDispatchToProps = { obtenerEmpleadosActivos };
 
export default connect(mapStateToProps, mapDispatchToProps)(Empleados);