import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';

import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_rh } from '../../../../../../Constants/Constants';
import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import InformacionMovimientosExcel from '../../Nomina/Variables/DataTableMovimientos/InformacionMovimientosExcel';

const InformacionAusentismos = ({ idModulo, errorTable, successTable, nombreModulo }) => {
    const fechaRef = createRef('');
    const [ peticion, setPeticion ] = useState( false );
    const [ nominas, setNominas ] = useState( null );
    const [ fechaConsulta, setFechaConsulta ] = useState( null );
    const [ error, setError ] = useState( null );

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'ConsultarAusentismos' ) ) {
            const fecha = inputDateToLong(fechaRef.current.value);
            axiosAusentismosNomina( fecha );
        }
    };

    const axiosAusentismosNomina = ( fecha ) => {
        setError( null );
        setPeticion( true );
        axios.get( `${servicios_grupohit_rh}/empleado/ausentismo/total/ausentismos/${fecha}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( respuesta.data.dataResponse );
                        setFechaConsulta( fecha );
                        break;
                    case 404:
                        setNominas( null );
                        setError( <AlertForm message={respuesta.data.msg} /> );
                        break;
                    default:
                        break;
                }
                setPeticion( false );
            })
            .catch( error => {
                console.log( error )
                setPeticion( false );
            })
    }

    return (
        <React.Fragment>
            <form id="ConsultarAusentismos" onSubmit={enviar}>
                <span style={{ marginTop: '5px' }}> Consultar Ausentismos por fecha </span>
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <InputDateValidation title="" refValue={fechaRef} required/>
                    </div>
                    <div className="col-md-4 col-sm-12 mt-1">
                        <Button variant="contained" className="btn-color" type="submit"> Consultar </Button>
                    </div>
                </div>
                {error}
            </form>

            { !peticion ?
                nominas && fechaConsulta ?
                    <InformacionMovimientosExcel
                        idModulo={idModulo}
                        errorTable={errorTable}
                        successTable={successTable}
                        nombreModulo={nombreModulo}
                        informacion={{ nominas, fecha: fechaConsulta }}
                    />
                    : null
                : <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default InformacionAusentismos;