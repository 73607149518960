import React , {useState, useEffect, Fragment }  from 'react';
import Axios from 'axios';
import { servicios_grupohit_nomina, indicadores_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import { ArrayJsonIndicadores } from '../../../Helpers/JsonToOneLevel';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Indicadores = ({ id, permisos }) => {

    const [ nominas, setNominas ] = useState(null); 
    const [ indicadores, setIndicadores ] = useState(null); 
    const [dinamicTable, setDinamicTable] =useState(false); 
    const [ elemmentSelect, setElemmentSelect ] = useState( null );
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(()=>{
        Axios.get(`${servicios_grupohit_nomina}/config/nomina`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setNominas(res.data.dataResponse);
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    const getDataIndicadores = (elemmentSelect)=>{
        Axios.get(`${servicios_grupohit_nomina}/indicadores/getbynomina/${elemmentSelect.id}`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setIndicadores(ArrayJsonIndicadores(res.data.dataResponse));
                    break;
                case 404:
                    setIndicadores([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    };
    
    const showTable = (value, elemmentSelect) => {  
        setElemmentSelect(elemmentSelect);
        setDinamicTable(value);
        if(elemmentSelect)  {
            getDataIndicadores(elemmentSelect);
        } else {
            setIndicadores(null);
        }
    };

    return (
        <div id={`modulo${id}`}>
        {(dinamicTable)
        ?   <Fragment>
                <BtnBack tooltipText="Regresar a tabla de conceptos por nómina" action={() => showTable(false, null)}/>
                <CardComponent
                    title={
                        <Fragment>
                            <h4>{`${elemmentSelect.nominaClave} - ${elemmentSelect.nominaNombre}`}</h4>
                            <br/>
                        </Fragment>
                    }
                >
                    {(indicadores)
                    ?   <DataTableService id={id +'secondary'} title={ indicadores_ }
                            permisos={permisos} 
                            columns={[
                                { field: "nomClaveIndicador", title: "Clave", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.nomClaveIndicador.startsWith(term) },
                                { field: "prioridad", title: "Prioridad" },
                                { field: "nomIndicador", title: "Nombre" },
                                { field: "nomNaturaleza", title: "Naturaleza" },
                                { field: "indicadorSat", title: "Indicador SAT" },
                                { field: "partida", title: "Partida" },
                                { field: "cuenta", title: "Cuenta" },
                                { field: "nomRecibo", title: "Recibo" }
                            ]}
                            data={indicadores}
                            informacionAdicional={elemmentSelect}
                        />
                    :   <SpinnerOval/>
                    }
                </CardComponent>
            </Fragment>
        :   null
        }
        {(nominas && !dinamicTable)
        ?   (nominas)
            ?   <DataTableService id={id} title={ indicadores_ }
                columns={[
                    { field: "nominaClave", title: "Clave Nómina" },
                    { field: "nominaNomCorto", title: "Nombre Corto" },
                    { field: "nominaNombre", title: "Nombre Nómina" }
                ]}
                data={nominas}
                hideIconAdd
                hideIconEdit
                hideIconDelete
                showIconDescription 
                onShowTable={showTable}
            />
            :   <SpinnerOval/>
        :   null
        }
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
    </div>
    );
}
 
export default Indicadores;