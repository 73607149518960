import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTipoAcumulado from './FormTipoAcumulado';

const ModalAddTipoAcumulado = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    }

    const enviarTipoAcumulado = info => {
        setIsLoading(true);
        Axios.post( `${servicios_grupohit_catalogo}s/tipoacumulado/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd( false );
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Tipo de Acumulado" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTipoAcumulado
                        enviarTipoAcumulado={enviarTipoAcumulado}
                        error={error}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddTipoAcumulado;