import React, { createRef, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../../Services/Validation/SelectValidation';
import { servicios_grupohit_admon, verifique_conexion } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import Alert from '../../../../../../../../Services/Alerts/AlertSyE';

const FormCAdscripcion = ({ enviar, actualizar, data, setModalIsOpen, id }) => {

    const [proyectos, setProyectos] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const claveRef = createRef('');
    const nombreRef = createRef('');
    const descripcionRef = createRef('');
    const proyectoRef = createRef('');

    useEffect(() => {
        const obtenerProyectos = () => {
            axios.get(`${servicios_grupohit_admon}/presupuestal/compania/${id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setProyectos(res.data.dataResponse);
                        break;
                    case 404:
                        setOpenAlert(true);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch((error) => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
        };

        obtenerProyectos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const agregar = e => {
        e.preventDefault();
        if(validarSubmit('CAdscripcion')) {
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value
            const descripcion = descripcionRef.current.value;
            const presupuestal = parseInt(proyectoRef.current.value);
            enviar({ clave, nivel: data.nivel, nombre, descripcion, id: data.id, presupuestal });
        }
    };

    const editar = e => {
        e.preventDefault();
        if(validarSubmit('CAdscripcion')) {
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value
            const descripcion = descripcionRef.current.value;
            const presupuestal = parseInt(proyectoRef.current.value);
            actualizar({ areacve: clave, areanom: nombre, areadescrip: descripcion, id: data.id, areaidsup: data.areaidsup, areanivel: data.areanivel, areasecuencia: data.areasecuencia, presupuestal: { id: presupuestal } });
        }
    };

    const cerrarModal = () => {
        setModalIsOpen({ state: false, type: null, data: {} });
    };

    return (
        <Fragment>
            {
                (enviar || proyectos.length)
                ?   <form id="CAdscripcion" onSubmit={ enviar ? agregar : editar } >
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Clave" type="claveCAdscripcion" placeholder="Escriba la clave del centro de adscripción"
                                    refValue={claveRef} defaultValue={actualizar ? data.areacve : ''} 
                                    tooltipText="De 2 a 20 letras, números y/o signos de puntuación = @ # * < >" tooltipName="cAdscripCve" maxLength={20} required/>
                                <InputValidation title="Nombre" type="nombreCAdscripcion" placeholder="Escriba el nombre del centro de adscripción"
                                    refValue={nombreRef} defaultValue={actualizar ? data.areanom : ''}
                                    tooltipText={'Máximo 124 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName="cAdscripNom" maxLength={124} required/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Descripción" type="descripcionCAdscripcion" placeholder="Escriba la descripción del centro de adscripción"
                                    refValue={descripcionRef} defaultValue={actualizar ? data.areadescrip : ''}
                                    tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName="cAdsripDescrip" maxLength={1024} fieldType="textarea" required/>
                            </div>  
                        </div>
                        <SelectValidation title="Proyecto" arrayOpciones={proyectos} keyOpcion="cpresupuestalnom"
                            refValue={proyectoRef} defaultValue={data.presupuestal ? data.presupuestal.id : ''} required/>
                        <Button variant="contained" className="btn-color" type="submit">{ enviar ? 'Agregar' : 'Actualizar' }</Button>
                    </form>
                :   <SpinnerOval/>
            }
            <Alert show={openAlert} setOpenAlertSyE={setOpenAlert} title="Proyectos" text="No hay proyectos asignados al departamento" textbtnconfirm="Aceptar" type="error" action={cerrarModal} />
            <Alert show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
export default FormCAdscripcion;