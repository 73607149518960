import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_catalogo, movimientos_relacionados, verifique_conexion } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import RelacionadosEmpleado from './RelacionadosEmpleado/RelacionadosEmpleado';
import './styles.css';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const MovimientosRelacionados = ({ id, permisos, empleadosActivos, obtenerEmpleadosActivos }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    /* ----------------------------- Axios para opbtener empleados activos ----------------------------- */

    const [ tiposAcumulado, setTiposAcumulado ] = useState( null );

    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        obtenerEmpleadosActivos((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });
        axiosTiposAcumulado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Axios para obtener tipos de acumulado */
    const axiosTiposAcumulado = () => {
        axios.get( `${servicios_grupohit_catalogo}s/tipoacumulado/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposAcumulado( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setTiposAcumulado([]);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }
    /* ------------------------------------------------------------------------------------------------- */
    const onShowTable = ( elementSelect ) => {
        setElementSelect( elementSelect );
    }

    return (
        <div id={`modulo${id}`}>
            { elementSelect && tiposAcumulado ?
                <RelacionadosEmpleado empleado={elementSelect} setEmpleado={setElementSelect} tiposAcumulado={tiposAcumulado} idModulo={ id }/>
                :
                <React.Fragment>
                    { empleadosActivos
                        ?   <DataTableService id={id} title={movimientos_relacionados}
                                columns={[
                                    { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                                    { field: "nombreCompleto", title: "Nombre" },
                                    { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
                                    { field: "empleadoRfc", title: "RFC" },
                                    { field: "empleadoNss", title: "NSS" },
                                    { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                                    { field: "puestoCve", title: "Clave Puesto" },
                                    { field: "puestoNom", title: "Puesto" },
                                    { field: "nominaNombre", title: "Nómina" },
                                    { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                                    { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                                    { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                                ]}
                                data={ empleadosActivos }
                                customOptionsTable={{ filtering: true }}
                                hideIconAdd hideIconEdit hideIconDelete
                                showIconDescription onShowTable={onShowTable}
                                permisos={permisos}
                            />
                        :   <SpinnerOval />
                    }
                </React.Fragment>
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
};

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});
  
const mapDispatchToProps = { obtenerEmpleadosActivos };
  
export default connect(mapStateToProps, mapDispatchToProps)(MovimientosRelacionados);