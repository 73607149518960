import React, { useEffect, useState } from 'react'; 
import { connect } from 'react-redux';
import DataTableService from '../../DataTableService/DataTableService';
import FormEmpleadosBusquedaAvanzada from './FormEmpleadosBusquedaAvanzada';
import PageTitleBar from '../../Layouts/PageTitleBar';
import BtnBack from '../../../../../Services/Components/SecondTable/BtnBack';
import SpinnerOval from '../../../../../Services/Spinner/SpinnerOval';
import displayWindowSize from '../../Helpers/DisplayWindowSize';
import InputText from '../../../../../Services/Components/DataTable/InputText';

const EmpleadosBusquedaAvanzada = ({ id, relacionLaboral, permisos, openEmpleadosBusquedaAvanzada, setOpenEmpleadosBusquedaAvanzada, openMenu }) => {
    
    const [ empleados, setEmpleados ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );

    const [maxWidth, setMaxWidth] = useState(displayWindowSize().width);
    
    window.addEventListener("resize", () => setMaxWidth(displayWindowSize().width));
    
    useEffect(()=>{
        setEmpleados(null);
        setIsLoading(false);
    },[relacionLaboral]);

    const regresar = () => {
        setOpenEmpleadosBusquedaAvanzada(false);
        setIsLoading(false);
        setEmpleados(null);
    };

    return (
        
        openEmpleadosBusquedaAvanzada &&
        <div id={`modulo${id}`}>
            <div style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                <PageTitleBar title="Búsqueda Avanzada" path="Tablero"/>
                <BtnBack tooltipText="Regresar" action={regresar}/>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <FormEmpleadosBusquedaAvanzada setEmpleados={setEmpleados} isLoading={isLoading} setIsLoading={setIsLoading} /> 
                    </div>
                </div>
            </div>
            { empleados &&
                <div style={{marginTop:'2rem'}}>
                    {
                        !isLoading ?
                        <DataTableService id={id} title={ 'Busqueda Avanzada' } permisos={permisos}
                            columns={[
                                { field: "empleadoClave", title: "NUE", type: 'numeric', filtering: false, filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                                { field: "nombreCompleto", title: "Nombre", filtering: false }, 
                                { field: "empleadoCurp", title: "CURP", filtering: false },
                                { field: "empleadoRfc", title: "RFC", filtering: false },
                                { field: "empleadoNss", title: "NSS", filtering: false },
                                { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia" },
                                { field: "puestoCve", title: "Clave Puesto", filtering: false},
                                { field: "puestoNom", title: "Puesto",  },
                                { field: "nominaNombre", title: "Nómina",   },
                                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto", filtering: false }, 
                                { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                                { field: "status", title: "Status" },
                            ]}
                            data={ empleados } 
                            hideIconAdd
                            hideIconDelete
                            hideIconEdit
                            showIconDescription
                            customOptionsTable={{filtering:true}} 
                        />
                        : 
                        <SpinnerOval/>
                    }
                    
                </div>
            }
        </div>
        
    );
}
 
const mapStateToProps = (state) => ({
    openMenu: state.SystemReducer.openMenu
});

export default connect(mapStateToProps, null)(EmpleadosBusquedaAvanzada);