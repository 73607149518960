export const ArrayJsonSubordinados = data => {
    const newJson = data.map( item => ({
        id: item.id,
        value: item.id,
        foto: item.foto,
        username: item.username,
        departamentoId: item.departamentoId,
        label: `${item.username} / ${item.datosUsuarioNom} ${item.datosUsuarioPat} ${item.datosUsuarioMat ? item.datosUsuarioMat : ''}`,
    }));
    return newJson;
};

/* Funcion para obtener arreglo de restricciones */
export const ArrayJsonRestricciones = data => {
    const newJson = data.map( item => ({
        usuario: item.usuario,
        modulo: item.modulo,
        fecini: item.fecini,
        fecfin: item.fecfin,
    }));
    return newJson;
};

/* Funcion que retorna arregl solicitado por servicio */
export const ArrayJsonSubordinadosService = ( usuarios, modulo, fecini, fecfin ) => {
    const newJson = usuarios.map( data => ({
        usuario: data.id,
        modulo: modulo,
        fecini: fecini,
        fecfin: fecfin,
    }));
    return newJson;
};

function encontrarUsuario( usuarioID, usuariosDepartamento ){
    if( usuariosDepartamento.find( usuario => usuario.id === usuarioID ) ){
        return true;
    }else{
        return false;
    }
}

/* Funciones para restricciones de ususario */
export function obtenerRestriccionesDepartamento( restricciones, moduloID, usuariosDepartamento ) {
    const restriccionesDepartamento = restricciones.filter( restriccion => restriccion.modulo === moduloID && encontrarUsuario( restriccion.usuario, usuariosDepartamento ) );
    return restriccionesDepartamento ;
}

export function obtenerRestriccionesSinDepartamento( restricciones, moduloID, usuariosDepartamento ) {
    const restriccionesNoModulo = restricciones.filter( restriccion => restriccion.modulo !== moduloID );
    const restriccionesSiModuloNoDepartamento = restricciones.filter( restriccion => restriccion.modulo === moduloID && encontrarUsuario( restriccion.usuario, usuariosDepartamento ) === false );
    const restriccionesSinDepartamento = restriccionesNoModulo.concat( restriccionesSiModuloNoDepartamento );
    return restriccionesSinDepartamento ;
}

function encontrarUsuarioRestriccion( usuarioID, restriccionesDepartamento ){
    if( restriccionesDepartamento.find( restriccion => restriccion.usuario === usuarioID ) ){
        return true;
    }else{
        return false;
    }
}

export function obtenerUsuariosRestricciones( usuariosDepartamento, restriccionesDepartamento ){
    return usuariosDepartamento.filter( usuario => encontrarUsuarioRestriccion( usuario.id, restriccionesDepartamento ) );
}

/* Funciones para restriccion de modulo */
export function obtenerRestriccionesModulo( restricciones, moduloID ) {
    const restriccionesModulo = restricciones.filter( restriccion => restriccion.modulo === moduloID );
    return restriccionesModulo ;
}

export function obtenerRestriccionesSinModulo( restricciones, moduloID ) {
    const restriccionesSinModulo = restricciones.filter( restriccion => restriccion.modulo !== moduloID );
    return restriccionesSinModulo ;
}