import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import AutocompleteComponent from '../../../../../../../../Services/Autocomplete/AutocompleteComponent';
import DatosEmpleadoEscalafon from './DatosEmpleadoEscalafon';
import DatosPlazaEscalafon from './DatosPlazaEscalafon';
import { Spinner } from 'reactstrap';

const useStyles = makeStyles({
    card: {
        minWidth: 275,
        marginBottom: 2,
    },
});

const CardAsignaEscalafon = (props) => {

    const {
        index, info, empleados, plazas, plazasResponse, puestos, cPresupuestales, ultimoMovimiento, setUltimoMovimiento, movimientos, setMovimientos,
        actualizaPlaza, setActualizaPlaza, guardaPrimerEmpleado, errores, setErrores, primerEmpleado, setEjecutaPlazas
    } = props;

    const classes = useStyles();

    const [plaza, setPlaza] = useState(null);
    const [plazaEdit, setPlazaEdit] = useState(null);
    const [empleado, setEmpleado] = useState(null);
    const [empleadosFilter, setEmpleadosFilter] = useState([]);
    const [muestraInput, setMuestraInput] = useState({ muestraPuesto: false, muestraProyecto: false });
    const [erroesCreado, setErroresCreado] = useState(false);

    const parsePlaza = () => {
        try {
            const newPlaza = plazas.find(plaza => plaza.id === info.empleado.plazaId);
            const plaza = {
                id: newPlaza.id,
                plazaClave: newPlaza.plazaClave,
                areaId: newPlaza.areaId,
                areaNom: newPlaza.areaNom,
                domciaId: newPlaza.domciaId,
                domciaCalle: newPlaza.domciaCalle,
                domciaNumext: newPlaza.domciaNumext,
                domciaNumint: newPlaza.domciaNumint,
                domciaCP: newPlaza.domciaCP,
                domciaColonia: newPlaza.domciaColonia,
                nivLaboralClave: newPlaza.nivLaboralClave,
                puestoId: newPlaza.puestoId,
                puestoCve: newPlaza.puestoCve,
                puestoNom: newPlaza.puestoNom,
                cPresupuestalCve: newPlaza.cPresupuestalCve,
                cPresupuestalId: newPlaza.cPresupuestalId,
                cPresupuestalNom: newPlaza.cPresupuestalNom
            };
            return plaza;
        } catch (error) {
            setEjecutaPlazas(true);
        }
    };

    useEffect(() => {
        if(info) {
            if(actualizaPlaza) {
                setPlaza(parsePlaza());
                setPlazaEdit(parsePlaza());
                setActualizaPlaza(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualizaPlaza]);

    useEffect(() => {
        if(empleados) {
            if(movimientos) {
                if(empleados.length >= movimientos.length) {
                    let newArrayEmpleados = [];                        
                    let empMov = [];
                    for(let i = 0; i <= index; i++) {
                        empleados.map(emp => (emp.id === movimientos[i].empleado.id) && empMov.push(emp));
                    }
                    newArrayEmpleados = empleados.filter(elemento => empMov.indexOf(elemento) === -1);
                        
                    if(newArrayEmpleados.length === 0) {
                        validaEmpleado(primerEmpleado, false);
                        setUltimoMovimiento(index+1);
                    }
                    setEmpleadosFilter(newArrayEmpleados);
                }
            } else {
                setEmpleadosFilter(empleados);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info, empleados, ultimoMovimiento]);

    useEffect(() => {
        if(empleadosFilter.length > 0) {
            validaEmpleado(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info]);
    
    useEffect(() => {
        if(ultimoMovimiento === index) {
            setEmpleado(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ultimoMovimiento]);

    const limpiaMovimientosPosteriores = () => {
        if(info) {
            const newMovimientos = movimientos.slice(0, index + 1);
            setMovimientos(newMovimientos);
        } else {
            setMovimientos([]);
        }
        setUltimoMovimiento(null);
    };

    const validaPlaza = value => {
        setMuestraInput({ muestraPuesto: false, muestraProyecto: false });
        validaEmpleado(null);
        setPlaza(value);
        setPlazaEdit(value);
        if(value !== null) {
            guardaPrimerEmpleado(value.id);
        } else {
            guardaPrimerEmpleado(null);
        }
    };

    const validaEmpleado = (value, creaErrores=true) => {
        setEmpleado(value);
        if(value === null) {
            limpiaMovimientosPosteriores();
        } else {
            if(!erroesCreado && creaErrores) {
                let newErrores = errores;
                newErrores = newErrores.concat([{ errorPuesto: false, errorProyecto: false }]);
                setErroresCreado(true);
                setErrores(newErrores);
            }

            if(info) {
                const encuentra = movimientos.find(mov => mov.id === index + 1);
                
                if(encuentra) {
                    const newMovimientos = movimientos.slice(0, index + 1);
                    setMovimientos([ ...newMovimientos, {
                        id: index + 1,
                        plaza: (plazaEdit) ? plazaEdit : parsePlaza(),
                        empleado: value
                    }]);
                } else {
                    setMovimientos([ ...movimientos, {
                        id: index + 1,
                        plaza: (plazaEdit) ? plazaEdit : parsePlaza(),
                        empleado: value
                    }]);
                }
            } else {
                setMovimientos([{
                    id: 0,
                    plaza: (plazaEdit) ? plazaEdit : plaza,
                    empleado: value
                }]);
            }
            setActualizaPlaza(true);
        }
    };

    const asignaPuesto = puesto => {
        if(!puesto && muestraInput.muestraPuesto) {
            let newErrores = errores;
            newErrores[(index >= 0) ? index+1 : 0].errorPuesto = true; 
            setErrores(newErrores);
        }
        if((puesto && muestraInput.muestraPuesto) || !muestraInput.muestraPuesto) {
            let newErrores = errores;
            newErrores[(index >= 0) ? index+1 : 0].errorPuesto = false; 
            setErrores(newErrores);
        }

        let plazaCard1 = null;

        if(!info) {
            plazaCard1 = plazasResponse.find(plz => plz.id === plaza.id);
        }

        const guardaPuestoEdit = () => {
            setPlazaEdit({
                ...plazaEdit,
                puestoId: (puesto) ? puesto.id : (info) ? plaza.puestoId : plazaCard1.puesto.id,
                puestoCve: (puesto) ? puesto.puestoCve : (info) ? plaza.puestoCve : plazaCard1.puesto.puestoCve,
                puestoNom: (puesto) ? puesto.puestoNom : (info) ? plaza.puestoNom : plazaCard1.puesto.puestoNom
            });
        };

        if(movimientos.length) {
            // ya se seleccionó el empleado que ocupará la plaza
            const id = (info) ? index+1 : 0;
            let objectMovimiento = movimientos.find(mov => mov.id === id);

            if(objectMovimiento) {
                objectMovimiento.plaza.puestoId = (puesto) ? puesto.id : (info) ? plaza.puestoId : plazaCard1.puesto.id;
                objectMovimiento.plaza.puestoCve = (puesto) ? puesto.puestoCve : (info) ? plaza.puestoCve : plazaCard1.puesto.puestoCve;
                objectMovimiento.plaza.puestoNom = (puesto) ? puesto.puestoNom : (info) ? plaza.puestoNom : plazaCard1.puesto.puestoNom;
    
                const newMovimientos = movimientos.map(mov => (mov.id === id) ? objectMovimiento : mov);
                setMovimientos(newMovimientos);
            } else {
                guardaPuestoEdit();
            }
        } else {
            guardaPuestoEdit();
        }
    };
    
    const asignaCPresupuestal = (proyecto, validaErrores=true) => {
        if(validaErrores) {
            if(proyecto === null && muestraInput.muestraProyecto === true) {
                let newErrores = errores;
                newErrores[(info) ? index+1 : 0].errorProyecto = true;
                setErrores(newErrores);
            }
            if((proyecto !== null && muestraInput.muestraProyecto === true) || muestraInput.muestraProyecto === false) {
                let newErrores = errores;
                newErrores[(info) ? index+1 : 0].errorProyecto = false; 
                setErrores(newErrores);
            }
        }

        let plazaCard1 = null;

        if(!info) {
            plazaCard1 = plazasResponse.find(plz => plz.id === plaza.id);
        }

        const guardaProyectoEdit = () => {
            setPlazaEdit({
                ...plazaEdit,
                cPresupuestalId: (proyecto) ? proyecto.id : (info) ? plaza.cPresupuestalId : plazaCard1.presupuestal.id,
                cPresupuestalCve: (proyecto) ? proyecto.cPresupuestalCve : (info) ? plaza.cPresupuestalCve : plazaCard1.presupuestal.cpresupuestalclave,
                cPresupuestalNom: (proyecto) ? proyecto.cPresupuestalNom : (info) ? plaza.cPresupuestalNom : plazaCard1.presupuestal.cpresupuestalnom
            });
        };

        if(movimientos.length) {
            // ya se seleccionó el empleado que ocupará la plaza
            const id = (info) ? index+1 : 0;
            let objectMovimiento = movimientos.find(mov => mov.id === id);

            if(objectMovimiento) {
                objectMovimiento.plaza.cPresupuestalId = (proyecto) ? proyecto.id : (info) ? plaza.cPresupuestalId : plazaCard1.presupuestal.id;
                objectMovimiento.plaza.cPresupuestalCve = (proyecto) ? proyecto.cPresupuestalCve : (info) ? plaza.cPresupuestalCve : plazaCard1.presupuestal.cpresupuestalclave;
                objectMovimiento.plaza.cPresupuestalNom = (proyecto) ? proyecto.cPresupuestalNom : (info) ? plaza.cPresupuestalNom : plazaCard1.presupuestal.cpresupuestalnom;
    
                const newMovimientos = movimientos.map(mov => (mov.id === id) ? objectMovimiento : mov);
                setMovimientos(newMovimientos);
            } else {
                guardaProyectoEdit();
            }
        } else {
            guardaProyectoEdit();
        }
    };

    return (
        <Fragment>
            {(ultimoMovimiento === index)
            ?   null
            :   <Card className={classes.card}>
                    <CardContent>
                        <div className="row">
                        {(!info)
                        ?   <div className="col">
                                <AutocompleteComponent
                                    id={`plaza${(info) ? index+1 : '0'}`} title="Plaza Disponible" placeholder="Seleccione la plaza"
                                    tooltipText='Busque la Plaza, por NUP, en el campo de autocompletado' tooltipName={`tooltipPlaza${(info) ? info.id : '0'}`}
                                    options={plazas} optionListView='plazaClave' action={validaPlaza} required
                                />
                            </div>
                        :   <div className="col d-flex justify-content-between align-items-center ml-3">
                                <h6>{ `Plaza: ${info.empleado.plazaClave}` }</h6>
                            </div>
                        }
                        {(empleadosFilter.length > 0)
                        ?   <div className="col">
                            {(empleados) &&
                                <AutocompleteComponent
                                    id={`emp${(index) ? index+1 : '0'}`} title={(!info) ? 'Empleado Disponible' : undefined} placeholder="Seleccione el empleado"
                                    tooltipText={(!info) ? 'Busque el Empleado, por nombre o NUE, en el campo de autocompletado' : undefined}
                                    tooltipName={(!info) ? `tooltipEmp${(info) ? info.id : '0'}` : undefined}
                                    options={empleadosFilter} optionListView='nombreCompleto' action={validaEmpleado}
                                    clearDiasabled disabled={(plaza) ? false : true} required={(!info) ? true : false } value={empleado}
                                />
                            }
                            </div>
                        :   <div className="col d-flex justify-content-between align-items-center ml-3">
                            {(empleado) &&
                                <h6>{ empleado.nombreCompleto }</h6>
                            }
                            </div>
                        }
                        </div>
                        <div id={`infoEscalafon${index}`} className="row">
                            <div className="col">
                            {(plaza)
                            ?   <DatosPlazaEscalafon
                                    index={index} plaza={plazaEdit} puestos={puestos} cPresupuestales={cPresupuestales}
                                    muestraInput={muestraInput} setMuestraInput={setMuestraInput}
                                    asignaPuesto={asignaPuesto} asignaCPresupuestal={asignaCPresupuestal}
                                    info={(info) ? true : false} errores={errores} setErrores={setErrores}
                                />
                            :   (movimientos.length)
                                ?   <Spinner />
                                :   null
                            }
                            </div>
                            <div className="col">
                            {(empleado) &&
                                <Fragment>
                                    <DatosEmpleadoEscalafon empleado={empleado}/>
                                </Fragment>
                            }
                            </div>
                        </div>
                    </CardContent>
                </Card>
            }
        </Fragment>
    );
}
 
export default CardAsignaEscalafon;