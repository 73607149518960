import React from 'react';

const InfoEmpleado = ({ nombreEmpleado, claveEmpleado })=>{
    return(
        <div className="formRelLab" style={{paddingBottom:15, paddingTop:15}}>
            <div className="form-left">
                    <div><strong>Empleado: </strong>{ nombreEmpleado }</div>
            </div>
            <div className="form-right">
                    <div><strong>Número Único de Empleado: </strong>{ claveEmpleado }</div>
            </div>
        </div>)
        
}

export default InfoEmpleado;