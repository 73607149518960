import React from 'react';

const DatosEmpleadoEscalafon = ({ empleado }) => {
    return (
        <div className="row panel-info panel-short">
            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <div className="textPerfil"><strong>NUP: </strong>{ empleado.plazaClave }</div>
                    </div>
                    <div className="col">
                        <div className="textPerfil"><strong>NUE: </strong>{ empleado.empleadoClave }</div>
                    </div>
                </div>
                <div className="textPerfil"><strong>Nombre: </strong>{ empleado.nombreCompleto }</div>
                <div className="textPerfil"><strong>RFC: </strong>{ empleado.empleadoRfc }</div>
                <div className="textPerfil"><strong>Nómina: </strong>{ `${empleado.nominaClave} - ${empleado.nominaNombre}` }</div>
                <div className="textPerfil"><strong>Puesto: </strong>{ `${empleado.puestoCve} - ${empleado.puestoNom}` }</div>
            </div>
        </div>
    );
}
 
export default DatosEmpleadoEscalafon;