import React, { createRef, useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, deleteOptionals, compararFechas, amountToFloat, floatToAmount, validarMontoMinMax } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

const cantidadQuinquenios = [
    { id: 1, cantidad: "1" },
    { id: 2, cantidad: "2" },
    { id: 3, cantidad: "3" },
    { id: 4, cantidad: "4" },
    { id: 5, cantidad: "5" },
    { id: 6, cantidad: "6" },
];

const returnTipocuota = ( tipoCuota ) => {
    switch( tipoCuota ){
        case "I": return "IMPORTE";
        case "P": return "PORCENTAJE";
        case "S": return "SALARIO MÍNIMO";
        case "U": return "UMA";
        default: return tipoCuota;
    }
}

const InformacionPension = ({ pensionSelect }) => (
    <React.Fragment>
        <div> <b> Número de Folio: </b> {pensionSelect.folio} </div>
        <div> <b> Nombre: </b> {pensionSelect.nombre} </div>
        <div className="row mb-2">
            <div className="col-md-6 col-sm-12">
                <div> <b> Primer Apellido: </b> {pensionSelect.apellidoPat} </div>
                <div> <b> Tipo de Cuota: </b> {returnTipocuota(pensionSelect.tipoCuota)} </div>
                <div> <b> Fecha de Inicio: </b> {pensionSelect.fechaIniTab} </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div> <b> Segundo Apellido: </b> {pensionSelect.apellidoMat} </div>
                <div> <b> {pensionSelect.tipoCuota === 'S' ? 'Cantidad:' : 'Monto:' } </b> {pensionSelect.montoPorc} </div>
                <div> <b> Fecha Final: </b> {pensionSelect.fechaFinTab} </div>
            </div>
        </div>
    </React.Fragment>
)

const InformacionDependiente = ({ dependienteSelect }) => (
    <React.Fragment>
        <div> <b> Nombre: </b> {dependienteSelect.nombre} </div>
        <div className="row mb-2">
            <div className="col-md-6 col-sm-12">
                <div> <b> Primer Apellido: </b> {dependienteSelect.apellidoPat} </div>
                <div> <b> CURP: </b> {dependienteSelect.curp} </div>
                <div> <b> Parentesco: </b> {dependienteSelect.parentescoNom} </div>
                <div> <b> Discapacidad: </b> {dependienteSelect.nombreDisc} </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div> <b> Segundo Apellido: </b> {dependienteSelect.apellidoMat} </div>
                <div> <b> Fecha de Nacimiento: </b> {dependienteSelect.fechaNacTab} </div>
                <div> <b> Escolaridad: </b> {dependienteSelect.escolaridadNom} </div>
            </div>
        </div>
    </React.Fragment>
)

const FormularioFijos = ({ error, enviarFijo, elementSelect, actualizarFijo, informacion, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ errorDate, setErrorDate ] = useState( null );
    const [ nominaIndicador, setNominaIndicador ] = useState( elementSelect ? informacion.indicadoresNomina.find( element => element.id === elementSelect.nomina.id ) : null );
    const [ errorIndicador, setErrorIndicador ] = useState( null );
    const cantidadRef = createRef('');
    const montoRef = createRef('');
    const acumuladoRef = createRef('');
    const fechaInicioRef = createRef('');
    const fechaFinalRef = createRef('');
    const empensionIdRef = createRef('');
    const [ pensionSelect, setPensionSelect ] = useState( null );
    const empdepecoIdRef = createRef('');
    const [ dependienteSelect, setDependienteSelect ] = useState( null );
    const [ quinquenioSelect, setQuinquenioSelect ] = useState( null );
    const [ nuevoMontoSalario, setNuevoMontoSalario ] = useState( null );

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    useEffect(() => {
        if(!elementSelect) {
            if(nominaIndicador?.indicador?.indicadorId === 50 && dependienteSelect?.fechaNac) {
                if(fechaFinalRef.current.value === '') {
                    fechaFinalRef.current.value = longDateToInput(dependienteSelect.fechaNac, 4);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependienteSelect, nominaIndicador]);

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarFijo' ) ){
            if( nominaIndicador ){
                const nomina = nominaIndicador.id;
                const cantidad = parseFloat(cantidadRef.current.value);
                var monto = amountToFloat(montoRef.current.value);
                if( quinquenioSelect && quinquenioSelect.monto === monto ){
                    monto = 0;
                }else if( pensionSelect && (pensionSelect.tipoCuota === 'S' || pensionSelect.tipoCuota === 'U') ){
                    //console.log( pensionSelect.tipoCuota );
                    //console.log( nuevoMontoSalario, monto );
                    if( nuevoMontoSalario.toFixed(2) === monto.toFixed(2) ){
                        monto = 0;
                    }
                }
                const acumulado = parseInt( acumuladoRef.current.value );
                const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
                const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
                const empensionId = empensionIdRef.current !== null ? parseInt( empensionIdRef.current.value ) ? parseInt( empensionIdRef.current.value ) : '' : '';
                const empdepecoId = empdepecoIdRef.current !== null ? parseInt( empdepecoIdRef.current.value ) ? parseInt( empdepecoIdRef.current.value ) : '' : '';
                if( compararFechas(fechaInicio, fechaFinal) ){
                    setErrorDate( null );

                    enviarFijo( deleteOptionals({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaInicio, fechaFinal, empensionId, empdepecoId }) );
                }else{
                    setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                }
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
        if( nominaIndicador ){
            validarMontoIndicador( nominaIndicador );
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarFijo' ) ){
            const nomina = elementSelect.nomina.id;
            const cantidad = parseFloat(cantidadRef.current.value);
            var monto = amountToFloat(montoRef.current.value);
            if( quinquenioSelect && quinquenioSelect.monto === monto ){
                monto = 0;
            }else if( pensionSelect && (pensionSelect.tipoCuota === 'S' || pensionSelect.tipoCuota === 'U') ){
                //console.log( pensionSelect.tipoCuota );
                //console.log( nuevoMontoSalario, monto );
                if( nuevoMontoSalario.toFixed(2) === monto.toFixed(2) ){
                    monto = 0;
                }
            }
            const acumulado = parseInt( acumuladoRef.current.value );
            const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
            const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
            const empensionId = empensionIdRef.current !== null ? parseInt( empensionIdRef.current.value ) ? parseInt( empensionIdRef.current.value ) : '' : '';
            const empdepecoId = empdepecoIdRef.current !== null ? parseInt( empdepecoIdRef.current.value ) ? parseInt( empdepecoIdRef.current.value ) : '' : '';
            if( compararFechas(fechaInicio, fechaFinal) ){
                setErrorDate( null );

                actualizarFijo( deleteOptionals({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaInicio, fechaFinal, empensionId, empdepecoId }) , elementSelect.id);
            }else{
                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
            }
        }
        if( nominaIndicador ){
            validarMontoIndicador( nominaIndicador );
        }
    };

    useEffect(() => {
        if( elementSelect && elementSelect.nomina.rutina.id === 13 && elementSelect.montoInt === 0 ){
            agregarMontoQuinquenio( elementSelect.cantidad );
        }

        if( elementSelect && elementSelect.empensionId !== null && elementSelect.montoInt === 0 ){
            obtenerPensionEmpleado( elementSelect.empensionId );
        }
        // eslint-disable-next-line
    }, []);

    /* Funcion para agregar Monto conforme al Quinquenio correspondiente */
    const agregarMontoQuinquenio = ( quinquenioID ) => {
        if( quinquenioID !== '' ){
            const quinquenioMonto = informacion.quinquenios.antigs.find( element => element.id === parseInt(quinquenioID) );
            montoRef.current.value = floatToAmount(quinquenioMonto.monto, 2);
            setQuinquenioSelect( quinquenioMonto );
        }else{
            montoRef.current.value = '';
            setQuinquenioSelect( null );
        }
    }

    const validarIndicador = (valor) => {
        if(valor) {
            setErrorIndicador( null );
            setNominaIndicador( valor );
            validarMontoIndicador( valor );
        }else{
            setErrorIndicador( true );
            setNominaIndicador( null );
            validarMontoIndicador( null );
        }
    };

    const validarMontoIndicador = ( nominaIndicador ) => {
        if( montoRef.current.value !== '' ){
            if( nominaIndicador && nominaIndicador.indicador.montoMin && nominaIndicador.indicador.montoMax ){
                validarMontoMinMax( elementSelect ? 'EditarFijo' : 'AgregarFijo', 'validarMonto', nominaIndicador.indicador.montoMin, nominaIndicador.indicador.montoMax );
            }else{
                validarMontoMinMax( elementSelect ? 'EditarFijo' : 'AgregarFijo', 'validarMonto', null, null );
            }
        }
    }

    const obtenerPensionEmpleado = ( pensionID ) => {
        if( pensionID !== '' ){
            const pensionSelect = informacion.pensiones.find( element => element.id === parseInt(pensionID) );
            setPensionSelect( pensionSelect );
            //console.log( pensionSelect );
            if( pensionSelect.tipoCuota === 'S' ){ //SALARIO MÍNIMO
                const nuevoMonto = informacion.salarioMinimo.monto * pensionSelect.montoPorc;
                montoRef.current.value = floatToAmount(nuevoMonto, 2);
                setNuevoMontoSalario( nuevoMonto );
            }else if( pensionSelect.tipoCuota === 'U' ){
                const nuevoMonto = informacion.salarioUMA.monto * pensionSelect.montoPorc;
                montoRef.current.value = floatToAmount(nuevoMonto, 2);
                setNuevoMontoSalario( nuevoMonto );
            }
        }else{
            setPensionSelect( null );
            setNuevoMontoSalario( null );
        }
    }

    const obtenerDependienteEmpleado = ( dependienteID ) => {
        if( dependienteID !== '' ){
            setDependienteSelect( informacion.dependientes.find( element => element.id === parseInt(dependienteID) ) );
        }else{
            setDependienteSelect( null );
        }
    }

    return (
        <Fragment>
            <form id={ elementSelect ? 'EditarFijo' : 'AgregarFijo' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                { elementSelect ?
                    <div> <b> Concepto de Nómina: </b> {elementSelect.nomina.nomIndicador} </div>
                    :
                    <AutocompleteComponent id="indicadorFijo" title="Concepto de Nómina" placeholder="Seleccione el Concepto de Nómina" size="100%"
                        tooltipName="tooltipIndicadorFijo" tooltipText={'Busque el Concepto, por nombre o clave, en el campo de autocompletado'} action={validarIndicador}
                        options={informacion.indicadoresNomina} optionListView={'nomIndicador'} optionKey="nomClave" error={errorIndicador} required
                    />
                }

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        { nominaIndicador === null ?
                            <InputValidation title="Cantidad" type="cantidad" placeholder="Digite la cantidad del movimiento fijo"
                                tooltipText={'Máximo 16 números'} tooltipName={'tooltipCantMov'} maxLength='16' onlyNumbers
                                refValue={cantidadRef} defaultValue={ elementSelect ? elementSelect.cantidad : '' } required/>
                            :
                            nominaIndicador && nominaIndicador.rutina.id === 13 ? //rutinaNom: "Quinquenio"
                                <SelectValidation title="Quinquenio" arrayOpciones={cantidadQuinquenios} keyOpcion="cantidad" onChangeExecute={agregarMontoQuinquenio}
                                    refValue={cantidadRef} defaultValue={ elementSelect ? elementSelect.cantidad : '' } required/>
                                :
                                <InputValidation title="Cantidad" type="cantidad" placeholder="Digite la cantidad del movimiento fijo"
                                    tooltipText={'Máximo 16 números'} tooltipName={'tooltipCantMov'} maxLength='16' onlyNumbers
                                    refValue={cantidadRef} defaultValue={ elementSelect ? elementSelect.cantidad : '' } required/>
                        }
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Monto" type="monto" placeholder="Digite el monto del movimiento fijo" className="validarMonto"
                            tooltipText={'Máximo 16 números y 2 decimales'} tooltipName={'tooltipMontMov'} maxLength='19'
                            refValue={montoRef} defaultValue={ elementSelect ? elementSelect.monto : '' } required
                            onlyNumbers="." onBlurDecimal={2} isAmount acceptZero onChangeExecute={() => validarMontoIndicador( nominaIndicador )}/>
                    </div>
                </div>

                <SelectValidation title="Tipo de Acumulado" arrayOpciones={informacion.tiposAcumulado} keyOpcion="acumuladoNombre"
                    refValue={acumuladoRef} defaultValue={ elementSelect ? elementSelect.acumulado.id : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechaInicioRef} required
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaInicio) : '' } />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} optional
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaFinal) : '' } />
                    </div>
                </div>
                { informacion.pensiones && informacion.pensiones.length > 0 && nominaIndicador && nominaIndicador.rutina.id === 16 && //rutinaNom: "MOVIMIENTO RELACIONADO" (PENSION ALIMENTICIA)
                    <Fragment>
                        <SelectValidation title="Beneficiario de Pensión" arrayOpciones={informacion.pensiones} keyOpcion="informacion"
                            refValue={empensionIdRef} defaultValue={ elementSelect ? elementSelect.empensionId : '' } optional
                            onChangeExecute={obtenerPensionEmpleado}/>
                        { pensionSelect && <InformacionPension pensionSelect={pensionSelect}/> }
                    </Fragment>
                }

                { informacion.dependientes && informacion.dependientes.length > 0 && nominaIndicador && nominaIndicador.rutina.id === 17 && //rutinaNom: "AYUDA GUARDERIA" (DEPENDIENTES ECONOMICOS)
                    <Fragment>
                        <SelectValidation title="Dependientes Económicos" arrayOpciones={informacion.dependientes} keyOpcion="informacion"
                            refValue={empdepecoIdRef} defaultValue={ elementSelect ? elementSelect.empdepecoId : '' } optional
                            onChangeExecute={obtenerDependienteEmpleado}/>
                        { dependienteSelect && <InformacionDependiente dependienteSelect={dependienteSelect}/> }
                    </Fragment>
                }

                {error}
                {errorIndicador}
                {errorDate}
                {isLoading
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
};

export default FormularioFijos;