import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import FileService from '../../../../CropService/FileService';
import ProgressBar from '../../../../CropService/ProgressBar';

import AlertForm from '../../../../../../../Services/AlertForm';
import { csvDownload } from '../../../../../../../Services/Validation/HelperValidation';
import Tooltip from '@material-ui/core/Tooltip';
import GetApp from '@material-ui/icons/GetApp';
import Search from '@material-ui/icons/Search';
import CloudUpload from '@material-ui/icons/CloudUpload';

//import csvDownload from 'json-to-csv-export';
import { obtenerInformacionExcel, axiosExcel } from './HelperMovimientosExcel';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE'; //Alertas
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { csvDownloadOptional } from '../../../../DataTableService/ActionsDatatable';

const MovimientosExcel = ({ idModulo, errorTable, successTable, informacion, nombreModulo, cerrarModal }) => {
    const [ alertError, setAlertError ] = useState( false );
    const errorExcel = () => {
        setAlertError( true );
    }

    /* Funcion para obtener Excel base64 por NominaID */
    const nominaIdRef = createRef('');
    const [ nominaSelect, setNominaSelect ] = useState( null );
    const [ errorDescarga, setErrorDescarga ] = useState( null );
    const [ excel64, setExcel64 ] = useState( idModulo === 41 || idModulo === 68 || idModulo === 75 ? true : null ); //Variables, Fijos, Prestamos
    const [ descargaArchivo, setDescargaArchivo ] = useState( null );

    useEffect(() => {
        if( idModulo === 904 ){ //CargasMasivas/PagosUnicos
            setNominaSelect( informacion.nominas.find( element => element.id === parseInt(informacion.nominaID) ) );
            setExcel64( true );
        }
        // eslint-disable-next-line
    }, []);

    const obtenerExcel64 = ( nominaID ) => {
        setErrorDescarga( null );
        if( nominaID !== '' ){
            setNominaSelect( informacion.nominas.find( element => element.id === parseInt(nominaID) ) );
            //axiosExcelNominaID( nominaID );
            setExcel64( true );
        }else{
            setNominaSelect( null );
            setExcel64( null );
        }
    }

    const decargarExcel1 = () => {
        setErrorDescarga( null );
        setDescargaArchivo( true );
        setTimeout( () => decargarExcelArchivo1(), 1000 );
    }

    const decargarExcelArchivo1 = () => {
        const empleadosNomina = informacion.empleados.filter( empleado => empleado.nominaId === nominaSelect.id );
        if( empleadosNomina.length > 0 ){
            const formato = obtenerInformacionExcel( idModulo, empleadosNomina );
            if( formato ){
                csvDownload( formato, `${nominaSelect.nominaNombre}.csv` );
            }
            setDescargaArchivo( null );
        }else{
            setErrorDescarga( <AlertForm message="No existen empleados para esta nómina" /> );
            setDescargaArchivo( null );
        }
    }

    const decargarExcel2 = () => {
        setErrorDescarga( null );
        setDescargaArchivo( true );
        setTimeout( () => decargarExcelArchivo2(), 1000 );
    }

    const decargarExcelArchivo2 = () => {
        if(idModulo === 31 || idModulo === 75) {
            const formato = obtenerInformacionExcel(idModulo);
            if( formato ){
                csvDownloadOptional( formato, `${nombreModulo.toUpperCase()}.csv`, false );
            }
            setDescargaArchivo( null );
        } else {
            const empleadosNomina = informacion.empleados ? informacion.empleados : [];
            if( empleadosNomina.length > 0 ){
                const formato = obtenerInformacionExcel( idModulo, empleadosNomina );
                if( formato ){
                    csvDownloadOptional( formato, `${nombreModulo.toUpperCase()}.csv`, false );
                }
                setDescargaArchivo( null );
            }else{
                setErrorDescarga( <AlertForm message="No existen empleados para esta nómina" /> );
                setDescargaArchivo( null );
            }
        }
    }

    /* Funcion para obtener Tipo de Documento */
    const [ error, setError ] = useState( null );
    const [ progreso, setProgreso ] = useState( 0 );
    const [ documentoExcel, setDocumentoExcel ] = useState( null );

    const obtenerArchivo = ( file ) => {
        setErrorDescarga( null );
        setDocumentoExcel( file );
    }

    const enviarExcel64 = () => {
        setError( null );
        if( documentoExcel.name.includes( 'csv' ) ){
            setProgreso( 100 );
            //axiosMultiplesExcel({ archivo: documentoExcel.base64 });
            axiosExcel( idModulo, { archivo: documentoExcel.base64 }, setProgreso, setDocumentoExcel, setError, errorExcel, successTable, cerrarModal );
        }else{
            setError( <AlertForm message="Asegúrate de seleccionar un archivo con formato .csv" /> );
        }
    }

    return (
        <React.Fragment>
            { idModulo === 31 || idModulo === 41 || idModulo === 68 || idModulo === 75 ? //Variables, Fijos, Prestamos
                <div className="movimientosFlex">
                    <div className="movimientosIzquerdo">
                        { informacion.empleados ?
                            !descargaArchivo ?
                                <Button className="btn-color" onClick={() => decargarExcel2()}> Descargar Formato </Button>
                                :
                                <Button className="btn-color" disabled> Descargando <SpinnerOval /> </Button>
                            :
                            <Button className="btn-color" disabled> Descargar Formato <SpinnerOval/> </Button>
                        }
                    </div>

                    <div className="movimientosUploadDerecho file-upload">
                        <Tooltip title="Cargar archivo" placement="top-start">
                            <label htmlFor={`carga_excel_${idModulo}`} className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                        </Tooltip>
                        <FileService inputId={`carga_excel_${idModulo}`} getFile={obtenerArchivo} formatAccept={`.csv`}/>
                    </div>
                </div>
                :
                <div className="movimientosFlex">
                    <div className="movimientosIzquerdo">
                        { idModulo === 904 ? //CargasMasivas/PagosUnicos
                            <SelectValidation title="" arrayOpciones={informacion.nominas} keyOpcion="nominaNombre"
                                refValue={nominaIdRef} defaultValue={informacion.nominaID} disabled={true}/>
                            :
                            <SelectValidation title="" arrayOpciones={informacion.nominas} keyOpcion="nominaNombre"
                                refValue={nominaIdRef} onChangeExecute={obtenerExcel64}/>
                        }
                    </div>
                    <div className="movimientosDerecho file-upload mt-1">
                        { excel64 ?
                            !descargaArchivo ?
                                <Button className="btn-third btn-auto ml-2 mr-2" onClick={() => decargarExcel1()}> <GetApp/> </Button>
                                :
                                <Button className="btn-third btn-auto ml-2 mr-2" disabled> <SpinnerOval/> </Button>
                            :
                            <Button className="btn-third btn-auto ml-2 mr-2" disabled> <GetApp/> </Button>
                        }

                        <Tooltip title="Cargar archivo" placement="top-start">
                            <label htmlFor={`carga_excel_${idModulo}`} className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                        </Tooltip>
                        <FileService inputId={`carga_excel_${idModulo}`} getFile={obtenerArchivo} formatAccept={`.csv`}/>
                    </div>
                </div>
            }
            { errorDescarga }

            { documentoExcel &&
                <React.Fragment>
                    <div className="movimientosFlex mt-3">
                        <div className="movimientosIzquerdo">
                            <ProgressBar documento={documentoExcel} actionClose={() => {setDocumentoExcel( null ); setError(null);}} progressValue={progreso}/>
                        </div>
                        <div className="movimientosUploadDerecho btn-upload">
                            <Button variant="contained" className="btn-color" onClick={() => enviarExcel64()}> <CloudUpload/> </Button>
                        </div>
                    </div>
                    { error }
                </React.Fragment>
            }
            <Button style={{ display: 'none' }}> Test </Button>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Archivo no válido" text={`Incluir toda la información y/o revisar que sea el formato de ${nombreModulo}`} textbtnconfirm="Aceptar" type="error"/>
        </React.Fragment>
    );
};

export default MovimientosExcel;