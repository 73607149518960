import React, { useState, createRef, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import { validarSubmit, inputDateToLong, hoyMilisegundos } from '../../../../../../../Services/Validation/HelperValidation';
import { registro_sera_actualizado, servicios_grupohit_rh } from '../../../../../../../Constants/Constants';
import Button from '@material-ui/core/Button';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../../Services/AlertForm';
import ConvertDate from '../../../../Helpers/ConvertDate';

const ModalOrganizacionEmpleadoEdit = (props) => {

    const {
        modulo, modalIsOpen, setModalIsOpen, actualizar, disabledFormOrgEdit, elementSelect, infoEmpleado,
        selected, idTurno, turnos, turnoRef, nominas, nominaRef, zonasPago, zonaPagoRef, isLoading, indicadoresNombramientoActivo, nombramientoEditInicial,
        indicadoresSelected, nominasRL, plazaDisponibleRango, fechaInicialPlazaDispRangoRef
    } = props;

    const [openAlert, setOpenAlert] = useState(false);
    const [hoy] = useState(ConvertDate(hoyMilisegundos()));
    const [fechaMinima, setFechaMinima] = useState(0);
    const [error, setError] = useState({ plaza: false, zonaPago: false, puesto: false, turno: false, nomina: false, reingreso: false });
    const [finPeriodoNom, setFinPeriodoNom] = useState(0);

    const { plaza: errorPlaza, zonaPago: errorZonaPago, puesto: errorPuesto, turno: errorTurno, nomina: errorNomina, reingreso: errorReingreso } = error;

    const plazaFechaRef = createRef('');
    const zonaPagoFechaRef = createRef('');
    const puestoFechaRef = createRef('');
    const turnoFechaRef = createRef('');
    const nominaFechaRef = createRef('');
    const reingresoFechaRef = createRef('');

    useEffect(() => {
        if(modulo === 30 && nominasRL && nominaRef.current) {
            const nominaSelect = (disabledFormOrgEdit.nomina) 
                                ?   nominasRL.find(n => n.nombreNomina.toUpperCase() === nominaRef.current.value.toUpperCase())
                                :   nominasRL.find(n => n.nomina === Number(nominaRef.current.value));
            setFinPeriodoNom(nominaSelect.periodoFecfin)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const obtenerFechaMinima = async () => {
        await axios.get(`${servicios_grupohit_rh}/empleado/status/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const statusSort = res.data.dataResponse.sort((a, b) => a.fecfin < b.fecfin ? 1 : a.fecfin > b.fecfin ? -1 : 0);
                    setFechaMinima(statusSort[0].fecfin);
                    break;
                default:
                    setFechaMinima(hoyMilisegundos());
                    break;
            }
        })
        .catch(error => {
            setFechaMinima(hoyMilisegundos());
        });
    };

    useEffect(() => {
        if(modulo === 32) {
            obtenerFechaMinima();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validaFechas = () => {
        if(modulo === 30) {
            if(errorPlaza || errorZonaPago || errorPuesto || errorTurno || errorNomina) {
                return false;
            } else {
                return true;
            }
        }

        if(modulo === 32) {
            if(errorReingreso) {
                return false;
            } else {
                return true;
            }
        }
    };
      
    const onChangePlaza = fecha => {
        const fechaInput = inputDateToLong(fecha);
        const fechaMin = infoEmpleado.plaza.fecini;
        if((fechaInput >= fechaMin) && (fechaInput <= finPeriodoNom)) {
            setError({ ...error, plaza: false });
        } else {
            setError({ ...error, plaza: true });
        }
    };

    const onChangeZonaPago = fecha => {
        const fechaInput = inputDateToLong(fecha);
        const fechaMin = infoEmpleado.zonadepago.fecini;
        if((fechaInput >= fechaMin) && (fechaInput <= finPeriodoNom)) {
            setError({ ...error, zonaPago: false });
        } else {
            setError({ ...error, zonaPago: true });
        }
    };

    const onChangePuesto = fecha => {
        const fechaInput = inputDateToLong(fecha);
        const fechaMin = infoEmpleado.puesto.fecini;
        if((fechaInput >= fechaMin) && (fechaInput <= finPeriodoNom)) {
            setError({ ...error, puesto: false });
        } else {
            setError({ ...error, puesto: true });
        }
    };

    const onChangeTurno = fecha => {
        const fechaInput = inputDateToLong(fecha);
        const fechaMin = infoEmpleado.turno.fecini;
        if((fechaInput >= fechaMin) && (fechaInput <= finPeriodoNom)) {
            setError({ ...error, turno: false });
        } else {
            setError({ ...error, turno: true });
        }
    };

    const onChangeNomina = fecha => {
        const fechaInput = inputDateToLong(fecha);
        const fechaMin = infoEmpleado.nomina.empnominafecini;
        if((fechaInput >= fechaMin) && (fechaInput <= finPeriodoNom)) {
            setError({ ...error, nomina: false });
        } else {
            setError({ ...error, nomina: true });
        }
    };

    const onChangeReingreso = fecha => {
        const fechaInput = inputDateToLong(fecha);
        if(fechaInput > fechaMinima) {
            setError({ ...error, reingreso: false });
        } else {
            setError({ ...error, reingreso: true });
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        if(validarSubmit('OrganizacionEmpleadoFechas')) {
            if(validaFechas()) {
                setOpenAlert(true);
            }
        }
    };

    const cerrarModal = () => {
        setModalIsOpen(false);
    };

    const enviarActualizar = () => {
        let info = {};
        
        if(validarSubmit('OrganizacionEmpleadoFechas')) {
            if(modulo === 30) {
                if(!disabledFormOrgEdit.plaza) {
                    info = { ...info, cPresupuestal: { empleadoid: elementSelect.id, cpresupuestalid: selected.cPresupuestal.id, empcpresfecini: inputDateToLong(plazaFechaRef.current.value) } };
                }
    
                if(!disabledFormOrgEdit.plaza) {
                    info = { ...info, plaza: { empId: elementSelect.id, plazaId: selected.plaza.id, empFecIni: inputDateToLong(plazaFechaRef.current.value) } };
                }
    
                if(!disabledFormOrgEdit.puesto) {
                    info = { ...info, puesto: { empId: elementSelect.id, puestoId: selected.puesto.id, empPuestoFecIni: inputDateToLong(puestoFechaRef.current.value) } };
                }
    
                if(!disabledFormOrgEdit.zonaPago) {
                    const zonapago = zonasPago.find( zpago => zpago.id === parseInt(zonaPagoRef.current.value));
                    info = { ...info, zonaPago: { empId: elementSelect.id, domciaId: zonapago.id, empzpfecini: inputDateToLong(zonaPagoFechaRef.current.value) } };
                }
                
                if(!disabledFormOrgEdit.turno) {
                    const turno = turnos.find( turno => turno.id === parseInt(turnoRef.current.value));
                    info = { ...info, turno: { id: idTurno, empId: elementSelect.id, turnoId: turno.id, fecini: inputDateToLong(turnoFechaRef.current.value) } };
                }
    
                if(!disabledFormOrgEdit.nomina) {
                    const nomina = nominas.find( nomina => nomina.id === parseInt(nominaRef.current.value));
                    info = { ...info, nomina: { empleadoid: elementSelect.id, nominaid: nomina.id, empnominafecini: inputDateToLong(nominaFechaRef.current.value) } };
                }
            }

            if(modulo === 32) {
                const zonapago = zonasPago.find( zpago => zpago.id === parseInt(zonaPagoRef.current.value));
                const turno = turnos.find( turno => turno.id === parseInt(turnoRef.current.value));    
                const nomina = nominas.find( nomina => nomina.id === parseInt(nominaRef.current.value));

                info = {
                    ...info,
                    cPresupuestal: { empleadoid: elementSelect.id, cpresupuestalid: selected.cPresupuestal.id, empcpresfecini: inputDateToLong(reingresoFechaRef.current.value) },
                    plaza: { empId: elementSelect.id, plazaId: selected.plaza.id, empFecIni: inputDateToLong(reingresoFechaRef.current.value) },
                    puesto: { empId: elementSelect.id, puestoId: selected.puesto.id, empPuestoFecIni: inputDateToLong(reingresoFechaRef.current.value) },
                    zonaPago: { empId: elementSelect.id, domciaId: zonapago.id, empzpfecini: inputDateToLong(reingresoFechaRef.current.value) },
                    turno: { id: idTurno, empId: elementSelect.id, turnoId: turno.id, fecini: inputDateToLong(reingresoFechaRef.current.value) },
                    nomina: { empleadoid: elementSelect.id, nominaid: nomina.id, empnominafecini: inputDateToLong(reingresoFechaRef.current.value) },
                    reingreso: inputDateToLong(reingresoFechaRef.current.value)
                }
            }

            setError(false);
            cerrarModal();

            if(nombramientoEditInicial === false) {
                const indicadoresNombramientoActivoSelected = indicadoresNombramientoActivo.find(ina => indicadoresSelected.indexOf(ina.indicadorid.indicadorId) > -1);
                const ejecutaBitacora10 = (indicadoresNombramientoActivoSelected) ? true : false;
                actualizar(info, ejecutaBitacora10);
            } else {
                actualizar(info, false);
            }
        }
    };

    return (
        <ModalService
            title={(modulo === 30) ? 'Actualizar Empleado' : 'Reingresar Empleado'}
            parentNode={modulo}
            isOpen={modalIsOpen}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="col-12">
                <form id="OrganizacionEmpleadoFechas" onSubmit={mostrarAlert}>
                    {   (modulo === 30) &&
                        <Fragment>
                            { !disabledFormOrgEdit.plaza &&
                                <Fragment>
                                    <InputDateValidation title="Plaza" refValue={plazaFechaRef} validateEqualLess onChangeExecute={onChangePlaza} required/>
                                    { errorPlaza &&
                                        <AlertForm
                                            message={`La fecha de nueva plaza debe ser mayor o igual que ${ConvertDate(infoEmpleado.plaza.fecini)} y menor o igual que ${ConvertDate(finPeriodoNom)}`}
                                        />
                                    }
                                </Fragment>
                            }
                            { !disabledFormOrgEdit.zonaPago &&
                                <Fragment>
                                    <InputDateValidation title="Zona de Pago" refValue={zonaPagoFechaRef} validateEqualLess onChangeExecute={onChangeZonaPago} required/>
                                    { errorZonaPago &&
                                        <AlertForm
                                            message={`La fecha de nueva zona de pago debe ser mayor o igual que ${ConvertDate(infoEmpleado.zonadepago.fecini)} y menor o igual que ${ConvertDate(finPeriodoNom)}`}
                                        />
                                    }
                                </Fragment>
                            }
                            { !disabledFormOrgEdit.puesto &&
                                <Fragment>
                                    <InputDateValidation title="Puesto" refValue={puestoFechaRef} validateEqualLess onChangeExecute={onChangePuesto} required/>
                                    { errorPuesto &&
                                        <AlertForm
                                            message={`La fecha de nuevo puesto debe ser mayor o igual que ${ConvertDate(infoEmpleado.puesto.fecini)} y menor o igual que ${ConvertDate(finPeriodoNom)}`}
                                        />
                                    }
                                </Fragment>
                            }
                            { !disabledFormOrgEdit.turno &&
                                <Fragment>
                                    <InputDateValidation title="Turno" refValue={turnoFechaRef} validateEqualLess onChangeExecute={onChangeTurno} required/>
                                    { errorTurno &&
                                        <AlertForm
                                            message={`La fecha de nuevo turno debe ser mayor o igual que ${ConvertDate(infoEmpleado.turno.fecini)} y menor o igual que ${ConvertDate(finPeriodoNom)}`}
                                        />
                                    }
                                </Fragment>
                            }
                            { !disabledFormOrgEdit.nomina &&
                                <Fragment>
                                    <InputDateValidation title="Nómina" refValue={nominaFechaRef} validateEqualLess onChangeExecute={onChangeNomina} required/>
                                    { errorNomina &&
                                        <AlertForm
                                            message={`La fecha de nueva nómina debe ser mayor o igual que ${ConvertDate(infoEmpleado.nomina.empnominafecini)} y menor o igual que ${ConvertDate(finPeriodoNom)}`}
                                        />
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    }
                    {   (modulo === 32) &&
                        <Fragment>
                            <InputDateValidation
                                title="Fecha de Reingreso" refValue={reingresoFechaRef} validateEqualLess onChangeExecute={onChangeReingreso} 
                                defaultValue={(plazaDisponibleRango)? fechaInicialPlazaDispRangoRef.current.value : ''}
                                disabled={(plazaDisponibleRango) ? true : false} required
                            />
                            { errorReingreso &&
                                <AlertForm
                                    message={`La fecha de reingreso debe ser mayor que ${ConvertDate(fechaMinima)} y menor o igual que ${hoy}`}
                                />
                            }
                        </Fragment>
                    }
                    {isLoading
                    ?   <SpinnerOval />
                    :   <Button variant="contained" className="btn-color" type="submit">
                            { (modulo === 30) ? 'Actualizar' : 'Reingresar' }
                        </Button>
                    }
                </form>
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviarActualizar}/>
            </div>
        </ModalService>
    );
}
 
const mapStateToProps = (state) => ({
    nominasRL: state.PeriodoActualReducer.nominasRL,
});

export default connect(mapStateToProps, null)(ModalOrganizacionEmpleadoEdit);