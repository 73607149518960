import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import { servicios_grupohit_url } from '../../../../../../../../Constants/Constants';
import { ArrayJsonUsuarios } from '../../../../../Helpers/JsonToOneLevel';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

import Modulo from './Modulo';
import CopiarAccesos from './CopiarAccesos/CopiarAccesos';

const AsignacionModulos = ({ error, informacion, accesosMenu, setAccesosMenu }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    /* ------------------------- Axios para obtener los modulos del usuario superior ------------------------ */
    const [ modulosSuperior, setModulosSuperior ] = useState( null );
    const [ usuarios, setUsuarios ] = useState( null );
    useEffect(() => {
        axiosModulosSuperior();
        axiosUsuarios();
        // eslint-disable-next-line
    }, []);

    const axiosModulosSuperior = () => {
        axios.get( `${servicios_grupohit_url}/menu`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setModulosSuperior( respuesta.data.dataResponse.menu );
                        break;
                    case 404:
                        //setModulosSuperior([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosUsuarios = () => {
        axios.get( `${servicios_grupohit_url}/all/subordinates`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios( ArrayJsonUsuarios(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <React.Fragment>
            { accesosMenu && modulosSuperior && usuarios ?
                <React.Fragment>
                    { usuarios.length > 1 &&
                        <CopiarAccesos usuarios={ usuarios.filter(usuario => usuario.id !== informacion.usuarioID) } setAccesosMenu={setAccesosMenu}/> }

                    { modulosSuperior.map( moduloPadre => {
                        if( moduloPadre.nombre !== 'Tablero' && moduloPadre.nombre !== 'Ayuda' && moduloPadre.children ){
                            return <Modulo key={moduloPadre.id} moduloPadre={moduloPadre} accesosMenu={accesosMenu} setAccesosMenu={setAccesosMenu}
                                elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}/>
                        }else{
                            return '';
                        }
                    })}
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default AsignacionModulos;