import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_url } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import NominasRelacionLaboral from './NominasRelacionLaboral';
import Button from '@material-ui/core/Button';
import AlertForm from '../../../../../../../Services/AlertForm';
import { obtenerNominas } from './HelperNominas';

const AsignacionNomina = ({ error, setError, guardarAccesosNomina, informacion }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    /* ------------------------- Axios para obtener las nóminas del usuario superior ------------------------ */
    const [ nominasSuperior, setNominasSuperior ] = useState( null );
    const [ accesosNominas, setAccesosNominas ] = useState( null );
    useEffect(() => {
        axiosNominasSuperior();
        // eslint-disable-next-line
    }, []);

    const axiosNominasSuperior = () => {
        axios.get( `${servicios_grupohit_url}/acceso/nomina/${informacion.usuarioID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominasSuperior( respuesta.data.dataResponse );
                        setAccesosNominas( obtenerNominas( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        //setNominasSuperior([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* -------------------- Funcion que permite consumir el servicio AsignarAccesosNomina ------------------- */
    const guardarAccesos = () => {
        if( accesosNominas.length > 0 ){
            setError( null );
            guardarAccesosNomina({ userId: informacion.usuarioID, nominas: accesosNominas });
        }else{
            setError( <AlertForm message={ 'Asegúrate de haber seleccionado accesos' } /> );
        }
    }
    /* ------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            { nominasSuperior && accesosNominas ?
                <React.Fragment>
                    <span> <b> Usuario: </b> {informacion.username} </span>
                    { nominasSuperior.map( relacionLaboral => (
                        <NominasRelacionLaboral key={relacionLaboral.id} relacionLaboral={relacionLaboral} accesosNominas={accesosNominas} setAccesosNominas={setAccesosNominas}
                            elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}/>
                    ))}
                    { error }
                    <Button variant="contained" className="btn-color" onClick={ () => guardarAccesos() }> Guardar </Button>
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default AsignacionNomina;