import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioRegimenFiscal = ({ error, enviarRegimenFiscal, elementSelect, actualizarRegimenFiscal, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarRegimenFiscal' ) ){
            const regfiscalclave = claveRef.current.value;
            const regfiscaldescrip = descripcionRef.current.value;
            enviarRegimenFiscal({ regfiscalclave, regfiscaldescrip });
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarRegimenFiscal' ) ){
            const regfiscalclave = claveRef.current.value;
            const regfiscaldescrip = descripcionRef.current.value;
            actualizarRegimenFiscal({ id: elementSelect.id, regfiscalclave, regfiscaldescrip });
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarRegimenFiscal' : 'AgregarRegimenFiscal' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveRegimenFiscal" placeholder="Escriba la clave del Régimen Fiscal"
                    tooltipText={'Máximo 3 números'} tooltipName={'tooltipCveRegFis'} maxLength='3' onlyNumbers
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.regFiscalClave : '' } required/>
                <InputValidation title="Descripción" type="descripcionRegimenFiscal" placeholder="Escriba la descripción del Régimen Fiscal" maxLength='128'
                    tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescRegFis'}
                    refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.regFiscalDesc : '' } required/>
                {error}
                {isLoading
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioRegimenFiscal;