export default function displayWindowSize(){
    // Get width and height of the window excluding scrollbars
    let w = document.documentElement.clientWidth;
    let h = document.documentElement.clientHeight;
    let c = Math.trunc((w-290) / 290);
    let l = Math.trunc((h-60) / 356);
    // Display result inside a div element
    
    return (
        { "width": w, "height": h, "chats": c, "lineas": l }
    )
}
 
// Attaching the event listener function to window's resize event
window.addEventListener("resize", displayWindowSize);
