import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, tipo_acumulado, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const TipoAcumulado = ({ id }) => {

    const [tipoAcumulado, setTipoAcumulado] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}s/tipoacumulado/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTipoAcumulado(res.data.dataResponse);
                    break;
                case 404:
                    setTipoAcumulado([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                tipoAcumulado
                ?   <DataTableService id={id} title={ tipo_acumulado }
                        columns={[
                            { field: "acumuladoClave", title: "Clave" },
                            { field: "acumuladoNombre", title: "Nombre" },
                            { field: "acumuladoDescripcion", title: "Descripción" }
                        ]}
                        data={tipoAcumulado}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default TipoAcumulado;
