import React, {  useState, useEffect, createRef, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button }from '@material-ui/core'; 
import { servicios_grupohit, servicios_grupohit_catalogo, servicios_grupohit_nomina, servicios_grupohit_url, verifique_conexion } from '../../../../../../Constants/Constants'; 
import axios from 'axios'; 
import { headersAuth } from '../../../../../../Services/AuthService';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import { inputDateToLong, validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../Services/AlertForm'; 
import { ArrayJsonNominas, arrayJsonNominasAll } from '../../../Helpers/JsonToOneLevel';
import NominasCheck from './Components/NominasCheck';
import ConceptosCheck from './Components/ConceptosCheck'
import ComplementoCLC from './Components/ComplementoCLC';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import ConceptosList from './Components/ConceptosList';

const tiposNaturaleza = [
    {
        id: 0,
        tipoNaturaleza: 'TODOS',
    },
    {
        id: 1,
        tipoNaturaleza: 'PERCEPCIONES',
    },
    {
        id: 2,
        tipoNaturaleza: 'DEDUCCIONES',
    },
];

const returnPeriodo = ( periodo ) => {
    if( typeof(periodo) === 'string' && periodo.includes('/') ){
        return periodo.split('/')[1];
    }else{
        return periodo;
    }
}

const FormularioNewReportNomina = ({
    usuario, error, enviarNewReportNomina, enviarReportDownload, isLoading, setError, downloadReportDefinido, infoReport, campos
}) => { 
    const nominaRef= createRef('');
    const agnioRef = createRef('');
    const periodoRef= createRef('');
    const tipoAcumuladoRef = createRef('');
    const conceptosRef = createRef('');
    const naturalezaRef = createRef('');
    const mensajeRef = createRef('');

    const tipoCLCRef = useRef('');
    const conceptoPagoRef = useRef('');
    const claveCFFRef = useRef('');
    const cffRef = useRef('');
    const claveFTERef = useRef('');
    const fteRef = useRef('');
    const claveFCTRef = useRef('');
    const fctRef = useRef('');
    const claveSORRef = useRef('');
    const sorRef = useRef('');
    const claveCSORef = useRef('');
    const csoRef = useRef('');
    const claveCFIRef = useRef('');
    const cfiRef = useRef('');
    const tipoNominaRef = useRef('');
    const beneficiarioRef = useRef('');
    const firmasRef = useRef('');
    const fechaReferenciaRef = useRef('');

    const [ nominas, setNominas ] = useState(null);
    const [ agniosNomina, setAgniosNomina ] = useState([]);
    const [ isLoading2, setIsLoading2 ] = useState({ nomina: false, anio: false, periodo: false, acumulado: false, concepto: false, conceptosList: false });
    const [ periodosAgnio, setPeriodosAgnio ] =  useState([]);
    const [ msgError, setMsgError ] = useState(null);
    const [tiposAcumulado, setTiposAcumulado] = useState([]);
    const [ conceptos, setConceptos ] = useState([]);
    const [ nominasCheckSuperior, setNominasCheckSuperior ] = useState([]);
    const [ nominasCheckSelected, setNominasCheckSelected ] = useState([]);
    const [ conceptosTPD, setConceptosTPD ] = useState(false);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const [conceptosxNomina, setConceptosxNomina] = useState([]);
    const [conceptosSeleccionados, setConceptosSeleccionados] = useState([]);
    
    useEffect(()=>{ 
        if(!infoReport || (
            infoReport.id!==3 && infoReport.id!==8 && infoReport.id!==145 && infoReport.id!==149 && infoReport.id!==150 &&
            infoReport.id!==159 && infoReport.id!==162 && infoReport.id!==163 && infoReport.id!==164 && infoReport.id!==165 &&
            infoReport.id!==167 && infoReport.id!==168 && infoReport.id!==169 && infoReport.id!==170 && infoReport.id!==171 &&
            infoReport.id!==179 && infoReport.id!==180 && infoReport.id!==181 && infoReport.id!==182 && infoReport.id!==183 &&
            infoReport.id!==184 && infoReport.id!==185 && infoReport.id!==187 && infoReport.id!==188 && infoReport.id!==189 &&
            infoReport.id!==190 && infoReport.id!==191 && infoReport.id!==192 && infoReport.id!==193 && infoReport.id!==194
        )) {
            setIsLoading2({ ...isLoading2, nomina: true, anio: false, periodo: false, acumulado: false, concepto: false });
            axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( ArrayJsonNominas( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        setNominas( [] );
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
            .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));
        }else{ 
            setIsLoading2({ ...isLoading2, nomina: false, anio: true, periodo: false, acumulado: false, concepto: false });
            axios.get( `${servicios_grupohit}/generar/reportes/nomina/`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        const agnios = respuesta.data.dataResponse.map(element=> ( {periodoAnio:element} ));
                        setAgniosNomina( agnios );
                        break;
                    case 404:
                        setAgniosNomina( [] );
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
            .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false })); 
        }
        
        if(campos.includes("nominasCheck") || campos.includes("nominasAll")) {
            axios.get(`${servicios_grupohit_url}/acceso/nomina/${usuario.id}`, headersAuth())
            .then(res => { 
                switch(res.data.status){
                    case 200:
                        if(campos.includes("nominasCheck")) {
                            const nominasSup = res.data.dataResponse.map(item => (
                                {
                                    clave: item.clave,
                                    id: item.id,
                                    descripcion: item.descripcion,
                                    nombre: item.nombre,
                                    nominas: item.nominas[0],
                                    nominasID: item.nominas[0].map(nRL => ( nRL.id ))
                                }
                            ));
                            setNominasCheckSuperior(nominasSup);
                        }
                        if(campos.includes("nominasAll")) {
                            setNominas(arrayJsonNominasAll(res.data.dataResponse));
                        }
                        break;
                    case 404:
                        if(campos.includes("nominasCheck")) setNominasCheckSuperior([]);
                        if(campos.includes("nominasAll")) setNominas([]);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                console.log(error)
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            }); 
        };

        if(campos.includes("conceptosList") && (infoReport?.id === 191)) {
            setIsLoading2({ ...isLoading2, conceptosList: true });
            setConceptosSeleccionados([]);
            axios.get( `${servicios_grupohit_catalogo}/indicador`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        let conceptosArray = [];
                        let itemsRepeated = {};
                        const conceptosDeduccion = respuesta.data.dataResponse.filter(c => c.naturaleza.id === 2);
                        conceptosDeduccion.map(cd => {
                            if(!itemsRepeated[cd.indicadorClave]) {
                                conceptosArray.push({
                                    id: cd.id,
                                    indicadorClave: cd.indicadorClave,
                                    indicadorNom: cd.indicadorNom,
                                })
                            }
                            itemsRepeated[cd.id] = cd.id
                            return {};
                        });

                        setConceptosxNomina(conceptosArray);
                        break;
                    case 404:
                        setConceptosxNomina([]);
                        setMsgError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
            .finally(() => setIsLoading2({ ...isLoading2, conceptosList: false }));
        }
    // eslint-disable-next-line
    }, []);

    const enviar = (type) => {
        if(validarSubmit('NewReportNomina')) {
            if(infoReport && infoReport.id===8){
                const anio = parseInt(agnioRef.current.value);
                const peranio = parseInt(returnPeriodo(periodoRef.current.value));
                const clave = conceptosRef.current.value
                enviarReportDownload({ anio, peranio, clave }, type);
            }else if(infoReport && (infoReport.id===145 || infoReport.id===149 || infoReport.id===150 || infoReport.id===162 || infoReport.id===179 || infoReport.id===181 || infoReport.id===184 || infoReport.id===185)) {
                const anio = parseInt(agnioRef.current.value);
                const periodo = parseInt(returnPeriodo(periodoRef.current.value));
                enviarReportDownload((infoReport.id===179) ? { anio, periodo, pensiones: true } : { anio, periodo }, type);
            } else if(infoReport && (infoReport.id === 168)) {
                const anio = parseInt(agnioRef.current.value);
                const periodo = parseInt(returnPeriodo(periodoRef.current.value));
                const naturaleza = parseInt(naturalezaRef.current.value);
                enviarReportDownload({ anio, periodo, naturaleza }, type);
            }else if(infoReport && (infoReport.id===151)) {
                const nominaId = parseInt(nominaRef.current.value);
                const anio = parseInt(agnioRef.current.value);
                const periodo = parseInt(returnPeriodo(periodoRef.current.value));
                if(isNaN(tipoAcumuladoRef.current.value)) {
                    enviarReportDownload({ nominaId, anio, periodo }, type);
                } else {
                    const acumuladoId = parseInt(tipoAcumuladoRef.current.value);
                    enviarReportDownload({ nominaId, anio, periodo, acumuladoId }, type);
                }
            } else if(infoReport && (infoReport.id === 159 || infoReport.id === 163 || infoReport.id === 164 || infoReport.id === 165 || infoReport.id === 167 || infoReport.id === 169 || infoReport.id === 171 || infoReport.id === 180 || infoReport.id === 183 || infoReport.id === 187 || infoReport.id === 188 || infoReport.id === 189 || infoReport.id === 190 || infoReport.id === 192 || infoReport.id === 193 || infoReport.id === 194)) {
                if(nominasCheckSelected.length === 0) {
                    setError(<AlertForm message="Debe seleccionar, al menos, una nómina" />);
                    return;
                }
                
                setError(null);
                
                let request = {
                    nominas: nominasCheckSelected,
                    anio: parseInt(agnioRef.current.value),
                    periodo: parseInt(returnPeriodo(periodoRef.current.value))
                };

                if(infoReport.id===159 && conceptosTPD) request.conceptos = ["216", "217", "218"];
                
                if(infoReport.id===167) request.concepto = conceptosRef.current.value;
                if(infoReport.id===194) request.indicadorClave = conceptosRef.current.value;

                if((infoReport.id===163) && (mensajeRef.current.value.trim() !== '')) request.msgRecibo = mensajeRef.current.value;

                enviarReportDownload(request, type);
            } else if(infoReport && (infoReport.id === 170)) {
                const anio = parseInt(agnioRef.current.value);
                const periodo = parseInt(returnPeriodo(periodoRef.current.value));

                const tipoCLC = tipoCLCRef.current.value;
                const conceptoPago = conceptoPagoRef.current.value;
                const claveCFF = (claveCFFRef.current.value.trim() === '') ? 'CFF' : claveCFFRef.current.value.trim();
                const cff = cffRef.current.value;
                const claveFTE = (claveFTERef.current.value.trim() === '') ? 'FTE' : claveFTERef.current.value.trim();
                const fte = fteRef.current.value;
                const claveFCT = (claveFCTRef.current.value.trim() === '') ? 'FCT' : claveFCTRef.current.value.trim();
                const fct = fctRef.current.value;
                const claveSOR = (claveSORRef.current.value.trim() === '') ? 'SOR_ID' : claveSORRef.current.value.trim();
                const sor = sorRef.current.value;
                const claveCSO = (claveCSORef.current.value.trim() === '') ? 'CSO_ID' : claveCSORef.current.value.trim();
                const cso = csoRef.current.value;
                const claveCFI = (claveCFIRef.current.value.trim() === '') ? 'CFI_ID' : claveCFIRef.current.value.trim();
                const cfi = cfiRef.current.value;
                const tipoNomina = parseInt(tipoNominaRef.current.value);
                const beneficiario = beneficiarioRef.current.value;
                const firmas = parseInt(firmasRef.current.value);
                const fechaReferencia = inputDateToLong(fechaReferenciaRef.current.value);
                
                const request = {
                    anio, periodo, tipoCLC, conceptoPago,
                    claveFinanciamiento: {
                        claveCFF, cff, claveFTE, fte, claveFCT, fct, claveSOR, sor,
                        claveCSO, cso, claveCFI, cfi
                    }, tipoNomina, firmas, fechaReferencia
                };
                
                if(beneficiario.trim() !== '') request.beneficiario = beneficiario;

                enviarReportDownload(request, type);

            } else if(infoReport && (infoReport.id === 182)) {
                if(conceptosSeleccionados.length > 0) {
                    const indicadores = conceptosSeleccionados.map(i => (
                        String(i.indicadorClave)
                    ));

                    const periodo = periodosAgnio.find(p => p.id === Number(periodoRef.current.value))
                    if(periodo) {
                        const request = {
                            nominaId: Number(nominaRef.current.value),
                            indicadores,
                            anio: Number(agnioRef.current.value),
                            periodo: parseInt(returnPeriodo(periodo.periodoPerAnio))
                        };
        
                        enviarReportDownload(request, type);
                    }
                    return;
                }

                setError(<AlertForm message="Debe seleccionar, al menos, un concepto"/>);

            } else if(infoReport && (infoReport.id === 186)) {
                const nominaId = parseInt(nominaRef.current.value);
                const anio = parseInt(agnioRef.current.value);

                const searchPeriodo = periodosAgnio.find(p => p.id === Number(periodoRef.current.value));
                const periodo = parseInt(returnPeriodo(searchPeriodo.periodoPerAnio));
        
                enviarReportDownload({ nominaId, anio, periodo }, type);
            } else if(infoReport && (infoReport.id === 191)) {
                if(conceptosSeleccionados.length > 0) {
                    const indicadores = conceptosSeleccionados.map(i => (
                        String(i.indicadorClave)
                    ));

                    const periodo = periodosAgnio.find(p => p.id === Number(periodoRef.current.value))
                    if(periodo) {
                        const request = {
                            nominas: nominasCheckSelected,
                            indicadores,
                            anio: Number(agnioRef.current.value),
                            periodo: parseInt(returnPeriodo(periodo.periodoPerAnio))
                        };
        
                        enviarReportDownload(request, type);
                    }
                    return;
                }

                setError(<AlertForm message="Debe seleccionar, al menos, un concepto"/>);
            } else {
                const nominaId = nominaRef.current.value;
                const periodoanio = agnioRef.current ? agnioRef.current.value : null;
                if(downloadReportDefinido && infoReport.id!==143){
                    if(infoReport && (infoReport.id===5 || infoReport.id===6)){
                        enviarReportDownload({ nominaId }, type);
                    }else{
                        const periodoperanio = parseInt(returnPeriodo(periodoRef.current.value));
                        const acumulado = parseInt(tipoAcumuladoRef.current.value);
                        if(infoReport.id===4){
                            const concepto = conceptosRef.current.value;
                            enviarReportDownload({nominaId, periodoperanio, periodoanio, acumulado, concepto }, type);
                        }else{
                            enviarReportDownload({nominaId, periodoperanio, periodoanio, acumulado }, type);
                        }
                    }                
                }else{ 
                    let periodoperanio = null;
                    try {
                        periodoperanio = periodosAgnio.find(element=> element.id[0] === parseInt(returnPeriodo(periodoRef.current.value))).periodoPerAnio[0];
                    } catch (error) {
                        periodoperanio = periodosAgnio.find(element=> element.id === parseInt(returnPeriodo(periodoRef.current.value))).periodoPerAnio;
                    }
                    const acumulado = (infoReport && infoReport.id===143) ? tipoAcumuladoRef.current.value : null;
                    const nominaClave = (parseInt(nominaId))
                                    ? nominas.find(nomina => nomina.id === parseInt(nominaId)).nominaClave
                                    : '';
                    enviarNewReportNomina({nominaId, nominaClave, periodoperanio: returnPeriodo(periodoperanio), periodoanio }, acumulado );
                }
            }
        } 
    }
 
    const searchAgnioNomina = async (nominaId) =>{ 
        if(nominaId!==""){
            setAgniosNomina([]);
            setPeriodosAgnio([]);
            setConceptos([]);
            setTiposAcumulado([]);
            setError(null);
            setMsgError(null);
            setIsLoading2({ ...isLoading2, nomina: false, anio: true, periodo: false, acumulado: false, concepto: false, conceptosList: true });

            if(campos.includes("conceptosList") && (infoReport?.id !== 191)) {
                setConceptosSeleccionados([]);
                await axios.get( `${servicios_grupohit_nomina}/indicadores/getbynomina/${nominaId}`, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const coneptosArray = respuesta.data.dataResponse.map(item => (
                                {
                                    id: item.indicador.indicadorId,
                                    indicadorClave: item.indicador.indicadorClave,
                                    indicadorNom: item.indicador.indicadorNom,
                                }
                            ));
                            setConceptosxNomina(coneptosArray);
                            break;
                        case 404:
                            setConceptosxNomina([]);
                            setMsgError(<AlertForm message={respuesta.data.msg} />)
                            break;
                        default:
                            setMessageAlertSyE(respuesta.data.msg);
                            setOpenAlertSyE(true);
                            break;
                    }
                })
                .catch( error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                })
                .finally(() => setIsLoading2({ ...isLoading2, conceptosList: false }));
            }

            await axios.get( `${servicios_grupohit_nomina}/acumulados/periodo/${nominaId}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setAgniosNomina(respuesta.data.dataResponse);
                        break;
                    case 404:
                        setAgniosNomina([]);
                        setMsgError(<AlertForm message={respuesta.data.msg} />);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
            .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));
        }else{ 
            setAgniosNomina([]);
            setPeriodosAgnio([]);
            setConceptos([]);
            setTiposAcumulado([]);
            setError(null);
            setMsgError(null);
        }
    }

    const searchPeriodosNomina = ( periodoID ) => { 
        if(infoReport && (infoReport.id===167 || infoReport.id===194)) setConceptos([]); 
        setPeriodosAgnio([]);
        setError(null);
        setMsgError(null);
        if(periodoID!=="") {
            if(infoReport && (infoReport.id!==8 && infoReport.id!==145 && infoReport.id!==149 && infoReport.id!==150 && infoReport.id!==159 && infoReport.id!==162 && infoReport.id!==163 && infoReport.id!==164 && infoReport.id!==165 && infoReport.id!==167 && infoReport.id!==168 && infoReport.id!==165 && infoReport.id!==169 && infoReport.id!==170 && infoReport.id!==171 && infoReport.id!==179 && infoReport.id!==180 && infoReport.id!==181 && infoReport.id!==183 && infoReport.id!==184 && infoReport.id!==185  && infoReport.id!==187 && infoReport.id!==188 && infoReport.id!==189 && infoReport.id!==190 && infoReport.id!==191 && infoReport.id!==192 && infoReport.id!==193 && infoReport.id!==194)) {
                setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: true, acumulado: false, concepto: false });
                axios.get( `${servicios_grupohit_nomina}/acumulados/peranio/${nominaRef.current.value}/${periodoID}`, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const periodosService = respuesta.data.dataResponse.filter(periodo => periodo.periodoPerAnio !== 9 && periodo.periodoPerAnio !== 23);
                            const periodos = periodosService.map(item => ({
                                id: item.periodoId,
                                periodoPerAnio: item.periodoPerAnio === 10 ? `9/10` : item.periodoPerAnio === 24 ? `23/24` : item.periodoPerAnio,
                            }));
                            setPeriodosAgnio(periodos);
                            break;
                        case 404:
                            setPeriodosAgnio( [] );
                            break;
                        default:
                            setMessageAlertSyE(respuesta.data.msg);
                            setOpenAlertSyE(true);
                            break;
                    }
                })
                .catch( error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                })
                .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));
            }else{
                setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: true, acumulado: false, concepto: false });
                axios.post( `${servicios_grupohit}/generar/reportes/nomina/periodo/`,{anio:parseInt(periodoID)}, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const periodosService = respuesta.data.dataResponse.filter(periodo => periodo !== 9 && periodo !== 23);
                            const periodos = periodosService.map(periodo => ({
                                id: periodo,
                                periodoPerAnio: periodo === 10 ? `9/10` : periodo === 24 ? `23/24` : periodo,
                            }));
                            setPeriodosAgnio(periodos);
                            break;
                        case 404:
                            setPeriodosAgnio( [] );
                            break;
                        default:
                            setMessageAlertSyE(respuesta.data.msg);
                            setOpenAlertSyE(true);
                            break;
                    }
                })
                .catch( error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                })
                .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));;
            }
            
        }
    } 
    
    const searchAcumulado = ( value ) =>{
        setError(null);
        setConceptos([]);
        if(downloadReportDefinido && value!=='' && infoReport.id!==145 && infoReport.id!==149 && infoReport.id!==150 && infoReport.id!==159 && infoReport.id!==162 && infoReport.id!==163 && infoReport.id!==164 && infoReport.id!==165 && infoReport.id!==167 && infoReport.id!==168 && infoReport.id!==169 && infoReport.id!==170 && infoReport.id!==171 && infoReport.id!==179 && infoReport.id!==180 && infoReport.id!==181 && infoReport.id!==182 && infoReport.id!==183 && infoReport.id!==184 && infoReport.id!==185 && infoReport.id!==187 && infoReport.id!==188 && infoReport.id!==189 && infoReport.id!==190 && infoReport.id!==191 && infoReport.id!==192 && infoReport.id!==193 && infoReport.id!==194){
            setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: true, concepto: false });
            setTiposAcumulado([]);
            const periodoId = parseInt(returnPeriodo(periodoRef.current.value))
            axios.get(`${servicios_grupohit_nomina}/acumulados/nomina/${parseInt(nominaRef.current.value)}/${periodoId}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const data = res.data.dataResponse.map(item => (
                            {
                                id: item.acumuladoId,
                                acumuladoNombre: item.acumuladoNombre, 
                            }
                        ));

                        if(infoReport.id===151) {
                            let acumulados = data;
                            acumulados.unshift({ id: 'T', acumuladoNombre: 'Todos' });
                            setTiposAcumulado(acumulados);
                        } else {
                            setTiposAcumulado(data);
                        }
                        break;
                    case 404:
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
            .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));
        }else{
            setTiposAcumulado([]);
        }
    }

    const searchConceptos = ( value )=>{
        setError(null);
        setConceptos([]);
        if(downloadReportDefinido && infoReport.id===4 && value!=='' ){
            setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: true });
            const periodoperanio = parseInt(returnPeriodo(periodoRef.current.value));
            axios.get(`${servicios_grupohit_nomina}/acumulados/procesos/${nominaRef.current.value}/${periodoperanio}`, headersAuth())
            .then(respues => {
                switch(respues.data.status) {
                    case 200:
                        const indicadores = respues.data.dataResponse.map(item => (
                            {
                                id: item.indicadorId,
                                indicadorNomClave: `${item.nominaClave} - ${item.nominaIndicador}`
                            }
                        ));                        
                        setConceptos(indicadores);
                        break;
                    case 404:
                        setConceptos([]);
                        break;
                    default:
                        setMessageAlertSyE(respues.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
            .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));
        }else if(infoReport.id===8 || infoReport.id===167){ 
            const periodoperanio = parseInt(returnPeriodo(periodoRef.current.value));
            if(periodoperanio) {
                setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: true });
                axios.post(`${servicios_grupohit}/generar/reportes/nomina/periodo/conceptos/`,{anio: parseInt(agnioRef.current.value), periodo: periodoperanio}, headersAuth())
                .then(respues => {
                    switch(respues.data.status) {
                        case 200:
                            let indicadores = respues.data.dataResponse.map(item => (
                                {
                                    id: item[0],
                                    indicadorNomClave: `${item[0]} - ${item[1]}`
                                }
                            ));
                            if(infoReport.id===194) {

                            }
                            setConceptos(indicadores);
                            break;
                        case 404:
                            setConceptos([]);
                            break;
                        default:
                            setMessageAlertSyE(respues.data.msg);
                            setOpenAlertSyE(true);
                            break;
                    }
                })
                .catch(error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                })
                .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));
            }
        } else if(infoReport.id===194){
            const periodoperanio = parseInt(returnPeriodo(periodoRef.current.value));
            if(periodoperanio) {
                setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: true });
                axios.get(`${servicios_grupohit}/catalogo/indicador/getall/relab`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const conceptosDeduccion = res.data.dataResponse.filter(c => c.naturaleza.id === 2);
                            let indicadores = conceptosDeduccion.map(item => (
                                {
                                    id: item.indicadorClave,
                                    indicadorNomClave: `${item.indicadorClave} - ${item.indicadorNom}`
                                }
                            ));
                            setConceptos(indicadores);
                            break;
                        case 404:
                            setConceptos([]);
                            break;
                        default:
                            setMessageAlertSyE(res.data.msg);
                            setOpenAlertSyE(true);
                            break;
                    }
                })
                .catch(error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                })
                .finally(() => setIsLoading2({ ...isLoading2, nomina: false, anio: false, periodo: false, acumulado: false, concepto: false }));
            }
        } else{
            setConceptos([]);
        }
    }

    return (
        <Fragment>
            <form id={'NewReportNomina'}>
                {(infoReport && (infoReport.id===5 || infoReport.id===6))
                ?   <Fragment>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    {
                                        nominas ? <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nominaClaveNombre" 
                                        refValue={nominaRef} required /> : <SpinnerOval/>
                                    } 
                                </div>
                            </div>
                            {error}
                            {msgError}
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    {
                                        isLoading ? 
                                        <SpinnerOval /> 
                                        :   
                                        <Button variant="contained" className="btn-color" onClick={enviar}>{ 'Generar' }</Button>  
                                    } 
                                </div>
                            </div>
                    </Fragment>
                :   <Fragment>
                        {(!infoReport || (infoReport.id!==8 && infoReport.id!==145 && infoReport.id!==149 && infoReport.id!==150 && infoReport.id!==159 && infoReport.id!==163 && infoReport.id!==164 && infoReport.id!==165 && infoReport.id!==167 && infoReport.id!==168 && infoReport.id!==169 && infoReport.id!==170 && infoReport.id!==171 && infoReport.id!==179 && infoReport.id!==180 && infoReport.id!==181 && infoReport.id!==183 && infoReport.id!==184 && infoReport.id!==185 && infoReport.id!==187 && infoReport.id!==188 && infoReport.id!==189 && infoReport.id!==190 && infoReport.id!==191 && infoReport.id!==192 && infoReport.id!==193 && infoReport.id!==194)) &&
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    {(!isLoading2.nomina)
                                    ?   nominas &&
                                        <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nominaClaveNombre" 
                                            refValue={nominaRef} onChangeExecute={searchAgnioNomina} required
                                        />
                                    :   <SpinnerOval/>
                                    } 
                                </div>
                            </div>
                        }
                        {campos.includes("nominasCheck") &&
                            <NominasCheck
                                nominasCheckSuperior={nominasCheckSuperior}
                                nominasCheckSelected={nominasCheckSelected}
                                setNominasCheckSelected={setNominasCheckSelected}
                            />
                        }
                        {campos.includes("conceptosCheck") &&
                            <ConceptosCheck
                                conceptosTPD={conceptosTPD}
                                setConceptosTPD={setConceptosTPD}
                            />
                        }
                        {campos.includes("conceptosList") &&
                            <ConceptosList
                                conceptosxNomina={conceptosxNomina} setConceptosxNomina={setConceptosxNomina} conceptosListIsLoading={isLoading2.conceptosList}
                                conceptosSeleccionados={conceptosSeleccionados} setConceptosSeleccionados={setConceptosSeleccionados} setError={setError}
                            />
                        }
                        <div className="row">
                                <div className={periodosAgnio ? `col-md-6 col-sm-12` : `col-md-12 col-sm-12`}>
                                    {campos.includes("agnio") &&
                                        (!isLoading2.anio)
                                        ?   <SelectValidation title="Año" arrayOpciones={agniosNomina} keyOpcion="periodoAnio" 
                                                refValue={agnioRef} onChangeExecute={searchPeriodosNomina} disabled={agniosNomina.length===0} required
                                            />
                                        :   <SpinnerOval/>
                                    } 
                                </div>
                            {campos.includes("periodo") && 
                                    <div className={`col-md-6 col-sm-12`}>
                                    {(!isLoading2.periodo)
                                    ?   <SelectValidation title="Periodo" arrayOpciones={periodosAgnio} keyOpcion="periodoPerAnio" 
                                            refValue={periodoRef} onChangeExecute={ infoReport && (infoReport.id===8 || infoReport.id===167  || infoReport.id===194) ? searchConceptos :searchAcumulado}
                                            disabled={periodosAgnio.length===0} required
                                        />
                                    :   <SpinnerOval/>
                                    }
                                    </div> 
                            }
                        </div>
                        {(campos.includes("tipoAcumulado") || campos.includes("concepto")) &&
                                <div className="row">
                                    {infoReport && infoReport.id!==8 && 
                                        <div className={ campos.includes("concepto") ? "col-md-6 col-sm-12" : "col-md-12 col-sm-12"}>
                                        {(!isLoading2.acumulado)
                                        ?   <SelectValidation title="Tipo de Acumulado" arrayOpciones={tiposAcumulado} keyOpcion="acumuladoNombre"
                                                refValue={tipoAcumuladoRef} onChangeExecute={searchConceptos} disabled={tiposAcumulado.length===0} required
                                            />
                                        :   <SpinnerOval/>
                                        }
                                        </div>
                                    }
                                    {campos.includes("concepto") && 
                                        <div className={infoReport.id===8 ? `col-md-12 col-sm-12` :`col-md-6 col-sm-12`}>
                                        {(!isLoading2.concepto)
                                        ?   <SelectValidation title="Concepto" arrayOpciones={conceptos} keyOpcion="indicadorNomClave" 
                                                refValue={conceptosRef} onChangeExecute={()=>setError(null)} disabled={conceptos.length===0} required
                                            />
                                        :   <SpinnerOval/>
                                        }
                                        </div> 
                                    }
                                </div>
                        }
                        {campos.includes("onlyConcepto") && 
                            <div className="row">
                                <div className="col">
                                {(!isLoading2.concepto)
                                ?   <SelectValidation title="Concepto" arrayOpciones={conceptos} keyOpcion="indicadorNomClave" 
                                        refValue={conceptosRef} onChangeExecute={()=>setError(null)} disabled={conceptos.length===0} required
                                    />
                                :   <SpinnerOval/>
                                }
                                </div>
                            </div> 
                        }
                        {campos.includes("naturaleza") && 
                            <div className="row">
                                <div className="col">
                                    <SelectValidation title="Concepto" arrayOpciones={tiposNaturaleza} keyOpcion="tipoNaturaleza" 
                                        refValue={naturalezaRef} onChangeExecute={()=>setError(null)} required
                                    />
                                </div>
                            </div> 
                        }
                        {campos.includes("clc") && 
                            <ComplementoCLC
                                tipoCLCRef={tipoCLCRef} conceptoPagoRef={conceptoPagoRef} claveCFFRef={claveCFFRef} cffRef={cffRef}
                                claveFTERef={claveFTERef} fteRef={fteRef} claveFCTRef={claveFCTRef} fctRef={fctRef}
                                claveSORRef={claveSORRef} sorRef={sorRef} claveCSORef={claveCSORef} csoRef={csoRef}
                                claveCFIRef={claveCFIRef} cfiRef={cfiRef} tipoNominaRef={tipoNominaRef}
                                beneficiarioRef={beneficiarioRef} firmasRef={firmasRef} fechaReferenciaRef={fechaReferenciaRef}
                            />
                        }
                        {campos.includes("mensaje") &&
                            <InputValidation
                                type="mensaje" refValue={mensajeRef} title="Mensaje" placeholder="Escriba un mensaje" maxLength={140}
                                defaultValue="" tooltipText={`140 caracteres alfanuméricos, espacio y/o caracteres especiales .,-)("#'`} fieldType="textarea" alfanumeric={` .,-)("#'`} optional
                            />
                        }
                        { error }
                        { msgError }
                        {isLoading 
                        ?   <SpinnerOval /> 
                        :   (infoReport && (infoReport.id===145 || infoReport.id===159 || infoReport.id===179))
                            ?   <div className="row">
                                    <div className="col">
                                        <Button variant="contained" className="btn-color" onClick={() => enviar('pdf')}>Generar PDF</Button>
                                    </div>
                                    <div className="col">
                                        <Button variant="contained" className="btn-color" onClick={() => enviar('xls')}>Generar XLSX</Button>
                                    </div>
                                </div>
                            :   <Button variant="contained" className="btn-color" onClick={enviar}>Generar</Button>
                        }
                    </Fragment>
                }
            </form> 
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    usuario: state.SessionReducer.usuario,
});

export default connect(mapStateToProps, null)(FormularioNewReportNomina);