import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, amountToFloat } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioMontoMinimo = ({ error, enviarMontoMinimo, elementSelect, actualizarMontoMinimo, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const montominimoRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if(validarSubmit('AgregarMontoMinimo')){
            const monto = amountToFloat(montominimoRef.current.value);
            enviarMontoMinimo({ monto });
        }
    }

    const actualizar = () => {
        if(validarSubmit('EditaMontoMinimo')){
            const monto = amountToFloat(montominimoRef.current.value);
            actualizarMontoMinimo({ monto }, elementSelect.id);
        }
    }

    return (
        <React.Fragment>
            <p> Ingrese el monto mínimo de liquidez que aplicará por defecto a todos los empleados de todas las relaciones laborales y nóminas para efectos de sus préstamos. </p>
            <form id={ elementSelect ? 'EditaMontoMinimo' : 'AgregarMontoMinimo' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Monto Mínimo" type="monto" placeholder="Digite el importe del monto mínimo"
                    tooltipText={'Máximo 16 números y 2 decimales'} tooltipName={'tooltipImportMontMin'} maxLength='19'
                    refValue={montominimoRef} defaultValue={ elementSelect ? elementSelect.montominimo : '' } required
                    onlyNumbers="." onBlurDecimal={2} isAmount/>

                {error}
                {isLoading
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioMontoMinimo;