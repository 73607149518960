import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../Media/Img/profile.jpg';

const Mensaje = ({mensajes, usuario, verMensajes, imgSent, imgRecibed}) => {
    let tipo;
    (mensajes.from === usuario)
    ?       tipo =  <div className="outgoing_msg" onClick={() => verMensajes()}>
                        <div className="sent_msg" onClick={() => verMensajes()}>
                            <p onClick={() => verMensajes()}>{mensajes.mensaje}</p>
                            <span className="time_date" onClick={() => verMensajes()}> {mensajes.fechaHora}</span>
                        </div>
                        <div className="outgoing_msg_img" onClick={() => verMensajes()}>
                            {
                                imgSent !== ''
                                ? <Avatar sizes="10" alt="avatar" src={`data:image/jpeg;base64, ${imgSent}`}/>
                                : <Avatar alt="avatar" src={profileIMG}/>
                            }
                        </div>
                    </div>
    :       tipo =  <div className="incoming_msg" onClick={() => verMensajes()}>
                        <div className="incoming_msg_img" onClick={() => verMensajes()}>
                            {
                                imgRecibed !== ''
                                ? <Avatar alt="avatar" src={`data:image/jpeg;base64, ${imgRecibed}`}/>
                                : <Avatar alt="avatar" src={profileIMG}/>
                            }
                        </div>
                        <div className="received_msg" onClick={() => verMensajes()}>
                            <div className="received_withd_msg" onClick={() => verMensajes()}>
                                <p onClick={() => verMensajes()}>{mensajes.mensaje}</p>
                                <span className="time_date" onClick={() => verMensajes()}> {mensajes.fechaHora}</span>
                            </div>
                        </div>
                    </div>;

    return(
        <React.Fragment>
            { tipo }
        </React.Fragment>
    );
}

export default Mensaje;