import React, { useState } from 'react'; 
import { timestampToDate, dateToTimestamp } from '../../../../../../Services/timestampCovert'; 
import ModalService from '../../../ModalService/ModalService';
import FormTabuladoresQuinquenios from './FormTabuladoresQuinquenios';  

const ModalAddTabuladoresQuinquenios = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarTabuladorQuinquenios = tabulador => {
        var timestamp = dateToTimestamp(tabulador.fechaini);
        var date= timestampToDate(timestamp);
        const info = { fecIni:  date, fecFin: '-' };
        agregar(info, timestamp);
        setOpenAdd( false );
    }

    return (
        <ModalService title="Agregar Tabulador Quinquenios" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTabuladoresQuinquenios
                        enviarTabuladorQuinquenios={enviarTabuladorQuinquenios}
                        error={error}
                        setError={setError}
                        informacionAdicional={informacionAdicional}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddTabuladoresQuinquenios;