import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import GetApp from '@material-ui/icons/GetApp';

import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_rh } from '../../../../../../Constants/Constants';

import AlertForm from '../../../../../../Services/AlertForm';
import { obtenerInformacionExcel } from '../Variables/DataTableMovimientos/HelperMovimientosExcel';
import { csvDownloadOptional } from '../../../DataTableService/ActionsDatatable';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const DescargarPrestamosActivos = ({ idModulo, informacion }) => {
    const nominaIdRef = createRef('');
    const [ errorDescarga, setErrorDescarga ] = useState( null );
    const [ excel64, setExcel64 ] = useState( null );
    const [ isLoading, setIsLoading ] = useState(false);
    const [ informacionExcel, setInformacionExcel ] = useState( null );

    const obtenerExcel64 = ( nominaID ) => {
        setErrorDescarga( null );
        setExcel64( null );
        if( nominaID !== '' ){
            axiosPrestamosActivos( nominaID );
        }
    }

    const axiosPrestamosActivos = ( nominIdClave ) => {
        setIsLoading(true);
        axios.get( `${servicios_grupohit_rh}/empleado/prestamos/vista/report/${nominIdClave}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setInformacionExcel( respuesta.data.dataResponse );
                        setExcel64( true );
                        break;
                    case 404: //No se encuentran datos de este empleado
                        setInformacionExcel( [] );
                        setExcel64( true );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
            .finally(() => setIsLoading(false));
    }

    const decargarExcel = () => {
        setErrorDescarga( null );
        if( informacionExcel.length > 0 ){
            const formato = obtenerInformacionExcel( `${idModulo}Activos`, informacionExcel );
            if( formato ){
                csvDownloadOptional( formato, `Prestamos_Activos.csv` );
            }
        }else{
            setErrorDescarga( <AlertForm message="No existen préstamos activos para esta nómina" /> );
        }
    }

    return (
        <React.Fragment>
            <div className="movimientosFlex divVariablesNomina">
                <div className="movimientosIzquerdo">
                    <SelectValidation title="" arrayOpciones={informacion.deducciones} keyOpcion="indicadorInfo"
                        refValue={nominaIdRef} onChangeExecute={obtenerExcel64}/>
                </div>

                <div className="movimientosUploadDerecho btn-upload mt-2">
                    { isLoading
                    ?   <SpinnerOval/>
                    :   <Button variant="contained" className="btn-color buttonDownloadExcel" onClick={(excel64) ? () => decargarExcel() : false}
                            disabled={(excel64) ? false : true}
                        >
                            <GetApp/>
                        </Button>
                    }
                </div>
            </div>
            <div className="divVariablesNomina divVariablesNominaWithoutPointer">
                { errorDescarga }
            </div>
        </React.Fragment>
    );
};

export default DescargarPrestamosActivos;