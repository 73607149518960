import React from 'react';

import DeleteSweep from '@material-ui/icons/DeleteSweep';

const SinElementos = () => {
    return (
        <div className="mensajesContenidoVacio">
            <DeleteSweep /> <br/>
            <span> Esta carpeta está vacía </span>
        </div>
    );
};

export default SinElementos;