import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';
import { amountToFloat } from '../../../../../../../Services/Validation/HelperValidation';
import ModalLoading from '../../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeletePrestamos = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable, informacionAdicional }) => {

    const [isLoading, setIsLoading] = useState(false);
    const { montoLiquido, setMontoLiquido } = informacionAdicional;
    
    const axiosEliminar = () => {
        setIsLoading(true);
        axios.delete( `${servicios_grupohit_rh}/empleado/prestamos/${elementSelect.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setMontoLiquido(montoLiquido+amountToFloat(elementSelect.importe));
                        eliminar( elementSelect.id );
                        break;
                    default:
                        errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                console.log(error);
                errorTable( registro_no_eliminado );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <Fragment>
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={elementSelect.fechaFinTab === '-' ? 'El préstamo no ha sido finalizado' :registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
        </Fragment>
    );
};

export default ModalDeletePrestamos;