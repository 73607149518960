import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, indicadores_generales, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonIndicadoresGenerales } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const IndicadoresGenerales = ({ id, permisos }) => {

    const [indicadoresGenerales, setIndicadoresGenerales] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false); 

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}/indicador`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresGenerales(res.data.dataResponse);
                    setIndicadoresGenerales(indicadores);
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                indicadoresGenerales
                ?   <DataTableService id={id} title={ indicadores_generales }
                        permisos={permisos} 
                        columns={[
                            { field: "indicadorClave", title: "Clave", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.indicadorClave.startsWith(term) },
                            { field: "indicadorNom", title: "Nombre" },
                            { field: "relacionLabNombre", title: "Relación Laboral" },
                            { field: "tipoNatuNom", title: "Naturaleza" },
                            { field: "indicadorSat", title: "Indicador SAT" },
                            { field: "clasificaNom", title: "Clasificación SAT" },
                            { field: "partida", title: "Partida" },
                            { field: "cuenta", title: "Cuenta" },
                        ]}
                        data={indicadoresGenerales}
                        showSyncAlt
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default IndicadoresGenerales;
