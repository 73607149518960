import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Code, VpnKey, Domain, SaveAlt, Search, CancelPresentation} from '@material-ui/icons';

const Tablero = ({ setOpenCancelar, setOpenConsultar, setOpenDescargar, setOpenTimbrar, setOpenCertificados, setMostrarTablaActualizarOrigenRecurso }) => {

    const openModal = nombre => {
        switch(nombre) {
            case 'cancelar':
                setOpenCancelar(true);
            break;
            case 'consultar':
                setOpenConsultar(true);
            break;
            case 'descargar':
                setOpenDescargar(true);
            break;
            case 'timbrar':
                setOpenTimbrar(true);
            break;
            case 'certificados':
                setOpenCertificados(true);
            break;
            case 'origenRecurso':
                setMostrarTablaActualizarOrigenRecurso(true);
            break;
            default:
            break;
        }
    };

    return (
        <div className="row">
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('certificados')}>
                    <CardContent>
                        <div className="icon"><VpnKey/></div>
                        <p className="name">Certificado de Sello Digital</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('origenRecurso')}>
                    <CardContent>
                        <div className="icon"><Domain/></div>
                        <p className="name">Origen del Recurso por Secretaría o Dependencia</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('timbrar')}>
                    <CardContent>
                        <div className="icon"><Code/></div>
                        <p className="name">Timbrar Recibos de Pago</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('consultar')}>
                    <CardContent>
                        <div className="icon"><Search/></div>
                        <p className="name">Consultar Recibos de Pago Timbrados</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('cancelar')}>
                    <CardContent>
                        <div className="icon"><CancelPresentation/></div>
                        <p className="name">Cancelar Recibos de Pago Timbrados</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('descargar')}>
                    <CardContent>
                        <div className="icon"><SaveAlt/></div>
                        <p className="name">Descargar Recibos de Pago Timbrados</p>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
 
export default Tablero;