import React from 'react';

const Titulo = ({ item, nue, empleado }) => {
    return (
        <div className="row">
            <div className="col margintb">
                <div className="row">
                    <div className="col">
                        <h4>Consulta de Histórico de { item }</h4>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col col-lg-2 col-md-3 col-sm-3">
                        <strong>NUE: </strong>{ nue }
                    </div>
                    <div className="col">
                        <strong>Empleado: </strong>{ empleado }
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Titulo;