import React, { useState, useEffect, createElement, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_org, registro_sera_eliminado, verifique_conexion } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import PlazasModal from '../OrganigramaModal';
import ScrollContainer from 'react-indiana-drag-scroll';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AlertForm from '../../../../../../../../Services/AlertForm';
import AlertSyE from '../../../../../../../../Services/Alerts/AlertSyE';
import AlertWarning from '../../../../../../../../Services/Alerts/AlertWarning';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../../../Media/Img/profile.jpg';
import { Tooltip } from '@material-ui/core';

const OrganigramaPlazas = ({ data, permisos, setMuestraBtnDescarga }) => {

    const [html, setHTML] = useState([]);
    const [toDoOrg, setToDoOrg] = useState(false);    
    const [organigrama, setOrganigrama] = useState([]);
    const [openAlertSyEEError, setOpenAlertSyEEError] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [openAlertSyEESuccess, setOpenAlertSyEESuccess] = useState(false);
    const [openAlertEliminar, setOpenAlertEliminar] = useState(false);
    const [valueEliminar, setValueEliminar] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenEdit, setModalIsOpenEdit] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenInfo, setModalIsOpenInfo] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenNuevoOrg, setModalIsOpenNuevoOrg] = useState({ state: false, type: null, data: {} });
    
    let nuevoHtml = [];

    useEffect(() => {
        if(organigrama === null) {
            setMuestraBtnDescarga(false);
        } else {
            setMuestraBtnDescarga(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organigrama]);

    const servicioEnviar = (info, type) => {
        axios.post(`${servicios_grupohit_org}/plaza/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const nuevo = res.data.dataResponse;
                    nuevo.puesto = res.data.dataResponse.puesto.id;
                    nuevo.statusid = 1;
                    nuevo.statusnom = "Activa";
                    nuevo.idCia = res.data.dataResponse.ciaId;
                    nuevo.domicilio = info.domicilio;
                    nuevo.nominaId = info.nomina;
                    if(type === 1 ) {
                        setOrganigrama([nuevo]);
                        setModalIsOpenNuevoOrg({ state: false, type: null, data: {} });
                    };
                    if(type === 2 ) {
                        setOrganigrama([...organigrama, nuevo]);
                        setModalIsOpen({ state: false, type: null, data: {} });
                    }
                    setOpenAlertSyEESuccess(true);
                    break;
                default:
                    if(type === 1 ) setModalIsOpenNuevoOrg({ state: false, type: null, data: {} });
                    if(type === 2 ) setModalIsOpen({ state: false, type: null, data: {} });
                    setMensajeError(res.data.msg);
                    setOpenAlertSyEEError(true);
                    break;
            }
        })
        .catch(e => {
            if(type === 1 ) setModalIsOpenNuevoOrg({ state: false, type: null, data: {} });
            if(type === 2 ) setModalIsOpen({ state: false, type: null, data: {} });
            setOpenAlertSyEEError(true);
        });
    };

    const enviarNuevoOrg = dataR => {
        const info = {
                        areaid: data.id,
                        plazaclave: dataR.clave,
                        plazanivel: dataR.nivel,
                        plazaidsup: dataR.id,
                        plazadisponible: true,
                        plazasecuencia: 1,
                        puesto: dataR.puesto,
                        domicilio: dataR.domicilio,
                        nomina: dataR.nominaId,
                    };
        
        if(dataR.empleadoTitular !== null) info.emp = dataR.empleadoTitular;
                    
        servicioEnviar(info, 1); 
    };

    const enviar = dataR => {
        const itemshermanos = (organigrama.filter(hijo => hijo.plazaidsup === dataR.id)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia)
        });

        let orden = 1;
        if(itemshermanos.length > 0) {
            orden = (itemshermanos[itemshermanos.length - 1].plazasecuencia) + 1;
        }

        const info = {
                        areaid: data.id,
                        plazaclave: dataR.clave,
                        plazanivel: dataR.nivel + 1,
                        plazaidsup: dataR.id,
                        plazadisponible: true,
                        plazasecuencia: orden,
                        puesto: dataR.puesto,
                        domicilio: dataR.domicilio,
                        nomina: dataR.nominaId,
                    };
        
        if(dataR.empleadoTitular !== null) info.emp = dataR.empleadoTitular;
                    
        servicioEnviar(info, 2);
    };

    const actualizar = async (item, cambiaArea = false) => {
        if(permisos.find(permiso => permiso.id === 2)) {
            const info = { 
                areaid: (cambiaArea) ? item.areaid : data.id,
                plazaclave: item.plazaclave,
                plazanivel: item.plazanivel,
                plazasecuencia: item.plazasecuencia,
                plazadisponible: item.plazadisponible,
                plazaidsup: item.plazaidsup,
                puesto: (item.puesto.id) ? item.puesto.id : item.puesto,
                domicilio: item.domicilio,
                statusId: item.statusId,
                nomina: item.nominaId,
            };
            
            if(item.empleadoTitular !== null) info.emp = item.empleadoTitular;

            const result = await axios.put(`${servicios_grupohit_org}/plaza/${item.id}`, info, headersAuth())
                                .then(response => {
                                    if(response.data.status === 200) {
                                        if(cambiaArea) {
                                            const newOrganigrama = organigrama.filter(item => item.id !== response.data.dataResponse.id);
                                            setOrganigrama(newOrganigrama);
                                        } else {
                                            let itemOrg = organigrama.find(element => element.id === item.id );

                                            itemOrg.plazaclave = response.data.dataResponse.plazaclave;
                                            itemOrg.plazanivel = response.data.dataResponse.plazanivel;
                                            itemOrg.plazasecuencia = response.data.dataResponse.plazasecuencia;
                                            itemOrg.plazadisponible = response.data.dataResponse.plazadisponible;
                                            itemOrg.plazaidsup = response.data.dataResponse.plazaidsup;
                                            itemOrg.puesto = response.data.dataResponse.puesto.id;
                                            itemOrg.domicilio = item.domicilio;
                                            itemOrg.statusid = item.statusId;
                                            itemOrg.statusnom = response.data.dataResponse.statusnom;
                                            itemOrg.nominaId = item.nominaId;
                                            itemOrg.empleadoTitular = response.data.dataResponse.empleadoTitular

                                            setOrganigrama(organigrama.map(plaza => {
                                                if(plaza.id === itemOrg.id) {
                                                    return itemOrg;
                                                } else {
                                                    return plaza;
                                                }
                                            }));
                                        }
                                        
                                        setModalIsOpenEdit({ state: false, type: null, data: {} });
                                        setOpenAlertSyEESuccess(true);
                                        return true;
                                    } else {
                                        setModalIsOpenEdit({ state: false, type: null, data: {} });
                                        setMensajeError(response.data.msg);
                                        setOpenAlertSyEEError(true);
                                        return false;
                                    }
                                })
                                .catch(e => {
                                    setModalIsOpenEdit({ state: false, type: null, data: {} });
                                    setMensajeError(verifique_conexion);
                                    setOpenAlertSyEEError(true);
                                    return false;
                                });

            return result;
        }
    };

    const agregar = (id, nivel) => {
        if(permisos.find(permiso => permiso.id === 1)) {
            setModalIsOpen({ state: true, type: 'agregarPlaza', data: { id, nivel, idCia: data.idCia } });
        }
    };

    const abrirModalEditar = (id, type) => {
        if(permisos.find(permiso => permiso.id === 2)) {
            let info = {};
            if(type === 'editarPlaza') info = { ...id, idCia: data.idCia };
            if(type === 'cambiarArea') {
                info = organigrama.find(item => item.id === parseInt(id));
                info.presupuestal = data.info;
            };
            setModalIsOpenEdit({ state: true, type, data: info });
        }
    };
    
    const openModalEliminar = id => {
        setValueEliminar(id);
        setOpenAlertEliminar(true);
    };

    const eliminar = id => {
        if(permisos.find(permiso => permiso.id === 3)) {
            const numNodosHijos = (organigrama.filter(item => item.plazaidsup === id)).length;

            axios.delete(`${servicios_grupohit_org}/plaza/${id}`, headersAuth())
            .then(response => {
                if(response.data.status === 200) {
                    if(numNodosHijos === 0) {
                        setOpenAlertSyEESuccess(true);
                        if(organigrama.length > 1) {
                            const nuevoOrganigrama = organigrama.filter(item => item.id !== id);
                            setOrganigrama(nuevoOrganigrama);
                        } else {
                            setOrganigrama(null);
                        }
                    }
                } else {
                    setMensajeError(response.data.msg);
                    setOpenAlertSyEEError(true);
                }
            })
            .catch((error) => {
                setMensajeError(verifique_conexion);
                setOpenAlertSyEEError(true);
            });
        }
    };

    var idDrag = 0;

    const allowDrop = e => {
        e.preventDefault();
    };
      
    const drag = e => {
        if(permisos.find(permiso => permiso.id === 2)) {    
            try{
                e.dataTransfer.setData("div", e.target.id);
                idDrag = parseInt(e.target.id, 10);
            } catch(e) {
            }
        }
    };
      
    const drop = async e => {
        if(permisos.find(permiso => permiso.id === 2)) {
            const idDrop = parseInt(e.target.id, 10);
            //se obtiene informacion del elemento que se mueve, para editar 
            let itemDrag = organigrama.find(item => item.id === idDrag);
            itemDrag.plazaNivelAnt = itemDrag.plazanivel;
            //se obtiene informacion del nuevo padre
            const datosNuevoPadre = organigrama.find(item => item.id === idDrop);

            const buscaRelaciones = (padre, nivel) => {
                if(itemDrag.plazanivel < nivel) {
                    if(padre === itemDrag.id) {
                        idDrag = 0;
                        return false;
                    } else {
                        const datosSigItem = organigrama.find(item => item.id === padre); 
                        buscaRelaciones(datosSigItem.plazaidsup, datosSigItem.plazanivel);   
                    }
                }
                return true;
            };

            if(idDrag > 0 & idDrop > 0 & idDrag !== idDrop) var acceder = buscaRelaciones(datosNuevoPadre.plazaidsup, datosNuevoPadre.plazanivel);

            //aquí se ejecutan los cambios 
            if(acceder & idDrag > 0 & idDrop > 0 & idDrag !== idDrop) {
                let nuevoOrganigrama = organigrama.filter(item => item.id !== idDrag);
                
                //nuevos valores de item que se cambiará de nodo padre
                const newHermanos = (organigrama.filter(item => item.plazaidsup === datosNuevoPadre.id)).sort((a, b) => {
                    return (a.plazasecuencia - b.plazasecuencia)
                });

                // se asigna nuevo valor de orgen
                let plazasecuencia 
                if(newHermanos.length > 0) {
                    plazasecuencia = newHermanos[newHermanos.length - 1].plazasecuencia + 1;
                } else {
                    plazasecuencia = 1;
                }
                
                const nuevosValores = {
                    puesto: itemDrag.puesto,
                    domicilio: itemDrag.domicilio,
                    plazaclave: itemDrag.plazaclave,
                    plazanivel: datosNuevoPadre.plazanivel + 1,
                    plazasecuencia: plazasecuencia,
                    plazadisponible: itemDrag.plazadisponible,
                    plazaidsup: idDrop,
                    id: itemDrag.id,
                    nominaId: itemDrag.nominaId,
                    empleadoTitular: (itemDrag.empleadoTitular) ? itemDrag.empleadoTitular.id : null
                };

                const efectuaUpdate = await actualizar(nuevosValores);

                if(efectuaUpdate) {
                    //ajustar nivel de los hijos (-1);
                    const ajustarNivelHijos = async (padre) => {
                        //diferencia entre nodo y nuevo padre antes del cambio
                        var hijos = nuevoOrganigrama.filter(item => item.plazaidsup === padre.id);
                        if(hijos.length > 0) {
                            for(let nh = 0; nh < hijos.length; nh++) {
                                const hijo = nuevoOrganigrama.find(item => item.id === hijos[nh].id);
                                hijo.plazaNivelAnt = hijo.plazanivel;
                                hijo.plazanivel = padre.plazanivel + hijo.plazaNivelAnt - padre.plazaNivelAnt;
                                hijo.empleadoTitular = (hijo.empleadotitular) ? hijo.empleadotitular.id : null;
                                const efectuaUpdate = await actualizar(hijo);
                                if(efectuaUpdate) {
                                    ajustarNivelHijos(hijo);
                                }
                            }
                            return;
                        }
                        return;
                    }//termina ajuste de nivel
                    ajustarNivelHijos(itemDrag);
                    setToDoOrg(false);
                    setOrganigrama([...nuevoOrganigrama, itemDrag]);
                }
            }
        }
    };

    const moverIzquierda = async (id, padre, orden) => {
        let item = organigrama.find(item => item.id === id);  
        const itemshermanos = (organigrama.filter(item => item.plazaidsup === padre & item.plazasecuencia < orden)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia)
        });
        let itemAnterior = itemshermanos[itemshermanos.length - 1];
        const nuevoOrganigrama = organigrama.filter(item => item.id !== id & item.id !== itemAnterior.id );
        item.plazasecuencia = itemAnterior.plazasecuencia;
        item.empleadoTitular = (item.empleadotitular) ? item.empleadotitular.id : null;
        const efectuaUpdate = await actualizar(item);
        if(efectuaUpdate) {
            itemAnterior.plazasecuencia = itemAnterior.plazasecuencia + 1;
            itemAnterior.empleadoTitular = (itemAnterior.empleadotitular) ? itemAnterior.empleadotitular.id : null;
            actualizar(itemAnterior);
            setOrganigrama([...nuevoOrganigrama, item, itemAnterior]);
            setToDoOrg(false);
        }
    };
    
    const moverDerecha = async (id, padre, orden) => {
        let item = organigrama.find(item => item.id === id);
        const itemshermanos = (organigrama.filter(item => item.plazaidsup === padre & item.plazasecuencia > orden)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia);
        });
        let itemSiguiente = itemshermanos[0];
        const nuevoOrganigrama = organigrama.filter(item => item.id !== id & item.id !== itemSiguiente.id);
        item.plazasecuencia = item.plazasecuencia + 1;
        item.empleadoTitular = (item.empleadotitular) ? item.empleadotitular.id : null;
        const efectuaUpdate = await actualizar(item);
        if(efectuaUpdate) {
            itemSiguiente.plazasecuencia = item.plazasecuencia;
            itemSiguiente.empleadoTitular = (itemSiguiente.empleadotitular) ? itemSiguiente.empleadotitular.id : null;
            actualizar(itemSiguiente);
            setOrganigrama([...nuevoOrganigrama, item, itemSiguiente]);
            setToDoOrg(false);
        }           
    };

    const moverArriba = async nodo => {
        const padreInfo = organigrama.find(item => item.id === nodo.plazaidsup);
        let copiaNodo = nodo;
        
        if((copiaNodo.plazanivel - padreInfo.plazanivel) === 1) {
            const hijos = (organigrama.filter(hijo => hijo.plazaidsup === padreInfo.plazaidsup)).sort((a, b) => {
                return (a.plazasecuencia - b.plazasecuencia);
            });
            copiaNodo.plazaidsup = padreInfo.plazaidsup;
            copiaNodo.plazasecuencia = hijos[hijos.length - 1].plazasecuencia + 1;
        }
        
        copiaNodo.plazanivel = copiaNodo.plazanivel - 1;
        copiaNodo.empleadoTitular = (copiaNodo.empleadotitular) ? copiaNodo.empleadotitular.id : null;
        const efectuaUpdate = await actualizar(copiaNodo);
        if(efectuaUpdate) {
            //ajustar nivel de los hijos (-1);
            const ajustarNivelHijos = async (id) => {
                const hijos = organigrama.filter(item => item.plazaidsup === id);
                
                if(hijos.length > 0){
                    for(let nh = 0; nh < hijos.length; nh++) {
                        hijos[nh].plazanivel = hijos[nh].plazanivel - 1;
                        hijos[nh].empleadoTitular = (hijos[nh].empleadotitular) ? hijos[nh].empleadotitular.id : null;
                        const efectuaUpdate = await actualizar(hijos[nh]);
                        if(efectuaUpdate) {
                            ajustarNivelHijos(hijos[nh].id);
                        }
                    }
                }

            }//termina ajuste de nivel
            ajustarNivelHijos(nodo.id);
            setToDoOrg(false);
        }
    };

    const moverAbajo = async nodo => {
        //nuevo orden
        let copiaNodo = nodo;
        copiaNodo.plazanivel = copiaNodo.plazanivel + 1;
        copiaNodo.empleadoTitular = (copiaNodo.empleadotitular) ? copiaNodo.empleadotitular.id : null;
        const efectuaUpdate = await actualizar(copiaNodo);
        if(efectuaUpdate) {
            //ajustar nivel de los hijos (+1);
            const ajustarNivelHijos = async (id) => {
                const hijos = organigrama.filter(item => item.plazaidsup === id);
                
                if(hijos.length > 0){
                    for(let nh = 0; nh < hijos.length; nh++) {
                        hijos[nh].plazanivel = hijos[nh].plazanivel + 1;
                        hijos[nh].empleadoTitular = (hijos[nh].empleadotitular) ? hijos[nh].empleadotitular.id : null;
                        const efectuaUpdate = await actualizar(hijos[nh]); 
                        if(efectuaUpdate) {
                            ajustarNivelHijos(hijos[nh].id);
                        }
                    }
                }
            }//termina ajuste de nivel
            ajustarNivelHijos(nodo.id);
            setToDoOrg(false);
        }
    };

    const generaOrganigrama = padre => {    
        const hijos = (organigrama.filter(hijo => hijo.plazaidsup === padre.id)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia);
        });

        if(hijos.length > 0) {
            let padreshijos = [];
            for(let x = 0; x < hijos.length; x++) {
                const respuesta = generaOrganigrama(hijos[x]);
                const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                    ?   <Tooltip title="Agregar nueva plaza" key={`btnAgregar${hijos[x].id}`}><div className="btnAgregar" onClick={() => agregar(hijos[x].id, hijos[x].plazanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                let btnEliminar = (permisos.find(permiso => permiso.id === 3) && hijos[x].plazadisponible === true)
                                    ?   <Tooltip title="Eliminar plaza" key={`btnEliminar${hijos[x].id}`}><div className="btnEliminar" onClick={() => openModalEliminar(hijos[x].id)}><DeleteForeverOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                let btnCambioArea = (permisos.find(permiso => permiso.id === 2) && hijos[x].plazadisponible === true)
                                    ?   <Tooltip title="Actualizar área de la plaza" key={`btnCambioArea${hijos[x].id}`}><div className="btnCambioArea" onClick={() => abrirModalEditar(hijos[x].id, 'cambiarArea')}><CompareArrowsIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                
                if(respuesta) {
                    btnCambioArea = '';
                    btnEliminar = '';
                }

                const btnInfo = (permisos.find(permiso => permiso.id === 4))
                                ?   <Tooltip title="Mostrar información" key={`btnInfo${hijos[x].id}`}><div className="btnInfo" onClick={() => setModalIsOpenInfo({ state: true, type: "infoPlaza", data: hijos[x] })}><InfoOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
                const btnArriba = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Subir nivel" key={`btnArriba${hijos[x].id}`}><div className="btnArriba" onClick={() => moverArriba(hijos[x])}><ArrowUpwardIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                const btnAbajo = (permisos.find(permiso => permiso.id === 2))
                                ?   <Tooltip title="Bajar nivel" key={`btnAbajo${hijos[x].id}`}><div className="btnAbajo" onClick={() => moverAbajo(hijos[x])}><ArrowDownwardIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                :   null;
                const btnIzquierda = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Mover a la izquierda" key={`btnIzquierda${hijos[x].id}`}><div className="btnIzquierda" onClick={() => moverIzquierda(hijos[x].id, hijos[x].plazaidsup, hijos[x].plazasecuencia)}><ArrowBackOutlinedIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                    :   null;
                const btnDerecha = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Mover a la derecha" key={`btnDerecha${hijos[x].id}`}><div className="btnDerecha" onClick={() => moverDerecha(hijos[x].id, hijos[x].plazaidsup, hijos[x].plazasecuencia)}><ArrowForwardOutlinedIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                    :   null;

                const h5 = createElement('h5', {key: `h5${hijos[x].id}`, className: "name"}, (hijos[x].plazadisponible === false) ? (hijos[x].empleadoId) ? [<strong key={`str${hijos[x].id}`}>Nombre: </strong>, `${hijos[x].empleadoNom} ${hijos[x].empleadoPat} ${hijos[x].empleadoMat}`] : <strong key={`str${hijos[x].id}`}>Plaza No disponible</strong> : <strong key={`str${hijos[x].id}`}>Plaza Disponible</strong>);
                const h6 = createElement('h6', {id: hijos[x].id, key: `h6${hijos[x].id}`, className: "num_empleado"}, [<strong key={`str1${hijos[x].id}`}>NUP: </strong>, hijos[x].plazaclave]);
                const h64 = createElement('h6', {key: `h64${hijos[x].id}`, className: "puesto"}, (!hijos[x].empleadoNom) ? [<strong key={`str5${hijos[x].id}`}>Status: </strong>, hijos[x].statusnom] : '');
                const h61 = createElement('h6', {key: `h61${hijos[x].id}`, className: "puesto"}, (hijos[x].plazadisponible === false && hijos[x].empleadoNom) ? [<strong key={`str2${hijos[x].id}`}>Clave del Puesto: </strong>, hijos[x].puestoCve] : '');
                const h62 = createElement('h6', {key: `h62${hijos[x].id}`, className: "puesto"}, (hijos[x].plazadisponible === false && hijos[x].empleadoNom) ? [<strong key={`str3${hijos[x].id}`}>Nivel del Puesto: </strong>, hijos[x].nivLaboralClave] : '');
                const h63 = createElement('h6', {key: `h63${hijos[x].id}`, className: "puesto"}, (hijos[x].plazadisponible === false && hijos[x].empleadoNom) ? [<strong key={`str4${hijos[x].id}`}>Nombre del Puesto: </strong>, hijos[x].puestoNom] : '');
                
                const div1 = createElement('div', {key: `div1${hijos[x].id}`, className: "info-wrap"}, [h5, h6, h64, h61, h62, h63]);
                let divBtn = null;
                
                /*  Diferancia de niveles entre padre e hijo    */
                const dif = hijos[x].plazanivel - padre.plazanivel; 
                /*  Valores de Secuencia de los primer y ultimo nodo */
                const ultimoNodo = hijos[hijos.length - 1].plazasecuencia;
                const primerNodo = hijos[0].plazasecuencia;

                if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba]);

                } else if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha]);

                } else if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda]);

                } else if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda, btnDerecha]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda, btnDerecha]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha,  btnIzquierda]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha,  btnIzquierda]);

                }
                
                const img = createElement('div', {id: hijos[x].id, key: `img${hijos[x].id}` , className: "profile-image"}, <Avatar alt="avatar" src={(hijos[x].foto) ? (hijos[x].foto !== "" && hijos[x].foto !== null) ? `data:image/jpeg;base64, ${hijos[x].foto}` : profileIMG : profileIMG} />);
                const div2 = createElement('div', {id: hijos[x].id, key: `div2${hijos[x].id}`, className: "personal-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, [img, div1, divBtn]);
                const div3 = createElement('div', {id: hijos[x].id, key: `div3${hijos[x].id}`, className: "info-block"}, div2);
                let elemento1 = createElement('label', {id: hijos[x].id, key: `label${hijos[x].id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, hijos[x].puestoNom);
                let elemento2 = <Tooltip title="Editar" key={`btnEditar${hijos[x].id}`}><div className="btnEditar" onClick={() => abrirModalEditar(hijos[x], 'editarPlaza')}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;
                const div4 = createElement('div', {id: hijos[x].id, key: `div4${hijos[x].id}`, className: "department-info"}, [elemento1, elemento2]);
                const div5 = createElement('div', {id: hijos[x].id, key: `div5${hijos[x].id}`, className: "employee-card", draggable: (permisos.find(permiso => permiso.id === 2)) ? true : false, onDragStart: (e) => drag(e)}, [div4, div3]);
                let div6 = null;

                if(respuesta) {
                    if(respuesta.length === 1) {
                        div6 = createElement('div', {id: hijos[x].id, key: `div6${hijos[x].id}`, className: `node${dif} sub-list`}, div5);
                        const ul = createElement('ul', {id: hijos[x].id, key: `ul${hijos[x].id}`, className: "sub-list2"}, respuesta);
                        const li = createElement('li', {id: hijos[x].id, key: `li${hijos[x].id}`}, [div6, ul]);
                        padreshijos.push(li);
              
                    } else {
                        div6 = createElement('div', {id: hijos[x].id, key: `div6${hijos[x].id}`, className: `node${dif} sub-list`}, div5);
                        const ul = createElement('ul', {id: hijos[x].id, key: `ul${hijos[x].id}`, className: "sub-list"}, respuesta);
                        const li = createElement('li', {id: hijos[x].id, key: `li${hijos[x].id}`}, [div6, ul]);
                        padreshijos.push(li);
                    }

                } else {
                    div6 = createElement('div', {id: hijos[x].id, key: `div6${hijos[x].id}`, className: `node${dif} no-child sub-list`}, div5);
                    const li = createElement('li', {id: hijos[x].id, key: `li${hijos[x].id}`}, div6);
                    padreshijos.push(li);
                }
            }

            if(padre.plazaidsup === 0) {
                const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                    ?   <Tooltip title="Agregar nueva plaza" key={`btnAgregar${padre.id}`}><div className="btnAgregar" onClick={() => agregar(padre.id, padre.plazanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                const btnInfo = (permisos.find(permiso => permiso.id === 4))
                                ?   <Tooltip title="Mostrar información" key={`btnInfo${padre.id}`}><div className="btnInfo" onClick={() => setModalIsOpenInfo({ state: true, type: "infoPlaza", data: padre })}><InfoOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
                
                const h5 = createElement('h5', {id: padre.id, key: `h5${padre.id}`, className: "name"}, (padre.plazadisponible === false) ? (padre.empleadoId) ? [<strong key={`str${padre.id}`}>Nombre: </strong>, `${padre.empleadoNom} ${padre.empleadoPat} ${padre.empleadoMat}`] : <strong key={`str${padre.id}`}>Plaza No disponible</strong> : <strong key={`str${padre.id}`}>Plaza Disponible</strong>);
                const h6 = createElement('h6', {id: padre.id, key: `h6${padre.id}`, className: "num_empleado"}, [<strong key={`str1${padre.id}`}>NUP: </strong>, padre.plazaclave]);
                const h64 = createElement('h6', {id: padre.id, key: `h64${padre.id}`, className: "puesto"}, (!padre.empleadoNom) ? [<strong key={`str5${padre.id}`}>Status: </strong>, padre.statusnom] : '');
                const h61 = createElement('h6', {id: padre.id, key: `h61${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str2${padre.id}`}>Clave del Puesto: </strong>, padre.puestoCve] : '');
                const h62 = createElement('h6', {id: padre.id, key: `h62${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str3${padre.id}`}>Nivel del Puesto: </strong>, padre.nivLaboralClave] : '');
                const h63 = createElement('h6', {id: padre.id, key: `h63${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str4${padre.id}`}>Nombre del Puesto: </strong>, padre.puestoNom] : '');
                
                const img = createElement('div', {id: padre.id, key: `img${padre.id}` , className: "profile-image"}, <Avatar alt="avatar" src={(padre.foto) ? (padre.foto !== "" && padre.foto !== null) ? `data:image/jpeg;base64, ${padre.foto}` : profileIMG : profileIMG} />);
                const div1 = createElement('div', {id: padre.id, key: `div1${padre.id}`, className: "info-wrap"}, [h5, h6, h64, h61, h62, h63]);

                const divBtn = createElement('div', {id: padre.id, key: `divBtn${padre.id}`, className: "btnsPosition"}, [btnAgregar, btnInfo]);
                const div2 = createElement('div', {id: padre.id, key: `div2${padre.id}`, className: "personal-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, [img, div1, divBtn]);
                const div3 = createElement('div', {id: padre.id, key: `div3${padre.id}`, className: "info-block"}, div2);
                
                let elemento1 = createElement('label', {id: padre.id, key: `label${padre.id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, padre.puestoNom);
                let elemento2 = <Tooltip title="Editar" key={`btnEditar${padre.id}`}><div className="btnEditar" onClick={() => abrirModalEditar(padre, 'editarPlaza')}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;

                const div4 = createElement('div', {id: padre.id, key: `div4${padre.id}`, className: "master-info"}, [elemento1, elemento2]);
                const div5 = createElement('div', {id: padre.id, key: `div5${padre.id}`, className: "employee-card"}, [div4, div3]);
                let div6 = null;
                
                if(padreshijos.length === 1) {
                    div6 = createElement('div', {id: padre.id, key: `div6${padre.id}`, className: "node1"}, div5);
                    const ul2 = createElement('ul', {id: padre.id, key: `ul2${padre.id}`}, padreshijos);
                    const div = createElement('div', {id: padre.id, key: `ul2${padre.id}`, className: "sub-list"}, ul2);
                    const li = createElement('li', {id: padre.id, key: `li${padre.id}`}, [div6, div]);
                    return (li);
          
                } else {
                    div6 = createElement('div', {id: padre.id, key: `div6${padre.id}`, className: "node1"}, div5);
                    const ul2 = createElement('ul', {id: padre.id, key: `ul2${padre.id}`, className: "sub-list"}, padreshijos);
                    const li = createElement('li', {id: padre.id, key: `li${padre.id}`}, [div6, ul2]);
                    return (li);
                }
                    
            } else {
                return (padreshijos);
            }
        }

        if(hijos.length === 0 & padre.plazanivel === 1) {
            const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                ?   <Tooltip title="Agregar nueva plaza" key={`btnAgregar${padre.id}`}><div className="btnAgregar" onClick={() => agregar(padre.id, padre.plazanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
            const btnEliminar = (permisos.find(permiso => permiso.id === 3) && padre.plazadisponible === true)
                                ?   <Tooltip title="Eliminar plaza" key={`btnEliminar${padre.id}`}><div className="btnEliminar" onClick={() => openModalEliminar(padre.id)}><DeleteForeverOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
            const btnCambioArea = (permisos.find(permiso => permiso.id === 2) && padre.plazadisponible === true)
                                ?   <Tooltip title="Actualizar área de la plaza" key={`btnCambioArea${padre.id}`}><div className="btnCambioArea" onClick={() => abrirModalEditar(padre.id, 'cambiarArea')}><CompareArrowsIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
            const btnInfo = (permisos.find(permiso => permiso.id === 4))
                            ?   <Tooltip title="Mostrar información" key={`btnInfo${padre.id}`}><div className="btnInfo" onClick={() => setModalIsOpenInfo({ state: true, type: "infoPlaza", data: padre })}><InfoOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                            :   null;

            const h5 = createElement('h5', {id: padre.id, key: `h5${padre.id}`, className: "name"}, (padre.plazadisponible === false) ? (padre.empleadoId) ? [<strong key={`str${padre.id}`}>Nombre: </strong>, `${padre.empleadoNom} ${padre.empleadoPat} ${padre.empleadoMat}`] : <strong key={`str${padre.id}`}>Plaza No disponible</strong> : <strong key={`str${padre.id}`}>Plaza Disponible</strong>);
            const h6 = createElement('h6', {id: padre.id, key: `h6${padre.id}`, className: "num_empleado"}, [<strong key={`str1${padre.id}`}>NUP: </strong>, padre.plazaclave]);
            const h64 = createElement('h6', {id: padre.id, key: `h64${padre.id}`, className: "puesto"}, (!padre.empleadoNom) ? [<strong key={`str5${padre.id}`}>Status: </strong>, padre.statusnom] : '');
            const h61 = createElement('h6', {id: padre.id, key: `h61${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str2${padre.id}`}>Clave del Puesto: </strong>, padre.puestoCve] : '');
            const h62 = createElement('h6', {id: padre.id, key: `h62${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str3${padre.id}`}>Nivel del Puesto: </strong>, padre.nivLaboralClave] : '');
            const h63 = createElement('h6', {id: padre.id, key: `h63${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str4${padre.id}`}>Nombre del Puesto: </strong>, padre.puestoNom] : '');

            const img = createElement('div', {id: padre.id, key: `img${padre.id}` , className: "profile-image"}, <Avatar alt="avatar" src={(padre.foto) ? (padre.foto !== "" && padre.foto !== null) ? `data:image/jpeg;base64, ${padre.foto}` : profileIMG : profileIMG} />);
            const div1 = createElement('div', {id: padre.id, key: `div1${padre.id}`, className: "info-wrap"}, [h5, h6, h64, h61, h62, h63]);
            const divBtn = createElement('div', {id: padre.id, key: `divBtn${padre.id}`, className: "btnsPosition"}, [btnCambioArea, btnAgregar, btnEliminar, btnInfo]);
            const div2 = createElement('div', {id: padre.id, key: `div2${padre.id}`, className: "personal-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, [img, div1, divBtn]);
            const div3 = createElement('div', {id: padre.id, key: `div3${padre.id}`, className: "info-block"}, div2);
            
            let elemento1 = createElement('label', {id: padre.id, key: `label${padre.id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, padre.puestoNom);
            let elemento2 = <Tooltip title="Editar" key={`btnEditar${padre.id}`}><div className="btnEditar" onClick={() => abrirModalEditar(padre, 'editarPlaza')}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;

            const div4 = createElement('div', {id: padre.id, key: `div4${padre.id}`, className: "master-info"}, [elemento1, elemento2]);
            const div5 = createElement('div', {id: padre.id, key: `div5${padre.id}`, className: "employee-card", draggable: (permisos.find(permiso => permiso.id === 2)) ? true : false, onDragStart: (e) => drag(e)}, [div4, div3]);
            const div6 = createElement('div', {id: padre.id, key: `div6${padre.id}`, className: "node1 no-child"}, div5);
            
            return (div6);
        }
    };

    useEffect(() => {
        setToDoOrg(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organigrama]);

    useEffect(() => {
        axios.get(`${servicios_grupohit_org}/plaza/empleado/${data.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setOrganigrama(res.data.dataResponse);
                    break;
                case 404:
                    setOrganigrama(null);
                    break;
                default:
                    setOrganigrama([]);
                    setMensajeError(res.data.msg);
                    setOpenAlertSyEEError(true);
                    break;
            }
        })
        .catch((error) => {
            setOpenAlertSyEEError(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!toDoOrg) {
            if(organigrama) {
                const padres = organigrama.filter(Obj => Obj.plazaidsup === 0);
                if(padres.length > 0) {
                    for(let nf = 0; nf < padres.length; nf++) {
                        nuevoHtml.push(<Fragment key={`rfr${nf}`}><br/><ScrollContainer className="scroll-container orgchart" hideScrollbars={false} ignoreElements=".employee-card" key={`divoc${nf}`}>{createElement('ul', {key:`ulpr${nf}`, className:"prime-list"}, generaOrganigrama(padres[nf]))}</ScrollContainer><br/></Fragment>);
                        setHTML(nuevoHtml);
                    }
                }
            } else {
                setHTML([]);
            }

            setToDoOrg(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDoOrg]);

    let contenido = null; 
    (!toDoOrg)
    ?   contenido = <SpinnerOval/>
    :   contenido = (html.length > 0)
                    ?   <Fragment>
                            { createElement('div', { key: 'div1', className: 'contenedorOrganigramaModal' }, html) }
                        </Fragment>
                    :   <SpinnerOval/>

    return (
        <div id="modulo26">
            {
                (organigrama === null)
                ?   <Fragment>
                        <AlertForm message="No hay elementos" />
                        <button className="btn btn-color-form" onClick={() => setModalIsOpenNuevoOrg({ state: true, type: 'agregarPlazaNuevoOrg', data: { id: 0, nivel: 1, idCia: data.idCia } }) }>Comenzar nuevo organigrama</button>
                    </Fragment>
                :   <Fragment>{ contenido }</Fragment>
            }
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpenNuevoOrg} setModalIsOpen={setModalIsOpenNuevoOrg} enviar={enviarNuevoOrg}
                setToDoOrg={setToDoOrg} parentNode="orgPlazas"/>
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} enviar={enviar}
                setToDoOrg={setToDoOrg} parentNode="orgPlazas"/>
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpenEdit} setModalIsOpen={setModalIsOpenEdit} actualizar={actualizar}
                setToDoOrg={setToDoOrg} parentNode="orgPlazas"/>
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpenInfo} setModalIsOpen={setModalIsOpenInfo}
                parentNode="orgPlazas"/>
            <AlertSyE show={openAlertSyEESuccess} setOpenAlertSyE={setOpenAlertSyEESuccess} title="Plaza" text="La tarea se realizó con éxito" textbtnconfirm="Aceptar" type="success"
                action={() => { setModalIsOpen({ state: false, type: null, data: {} }); setToDoOrg(false); }}/>
            <AlertSyE show={openAlertSyEEError} setOpenAlertSyE={setOpenAlertSyEEError}
                title="Plaza" text={mensajeError} textbtnconfirm="Aceptar" type="error"/>
            <AlertWarning show={openAlertEliminar} SetopenALert={setOpenAlertEliminar} title="Plaza" text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={eliminar} value={valueEliminar}/>
        </div>
    );
}

export default OrganigramaPlazas;
