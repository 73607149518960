import React, { createRef, useRef, useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { validarSubmit, inputDateToLong, longDateToInput, hoyMilisegundos, amountToFloat } from '../../../../../../Services/Validation/HelperValidation';
import { servicios_grupohit_catalogo, servicios_grupohit_admon, servicios_grupohit_org, servicios_grupohit_nomina, servicios_grupohit_rh,
            cambios_no_guardados, registro_actualizado, registro_no_guardado, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { connect } from 'react-redux';
import { editarEmpleado, editarEmpleadoOrganizacion, reingresoEmpleado } from '../../../../../../Redux/actions/EmpleadosActions';
import ConvertInfoEmp from '../Reingresos/Helpers/ConvertInfoEmp';
import FormDependientesEconomicos from './FormDependientesEconomicos';
import FormPensionesAlimenticias from './FormPensionesAlimenticias';
import FormOrganizacionEmpleado from './FormOrganizacionEmpleado';
import FormExperienciaLaboral from './FormExperienciaLaboral';
import FormNumerosEmergencia from './FormNumerosEmergencia';
import FormDatosPersonales from './FormDatosPersonales';
import FormPasatiempos from './FormPasatiempos';
import FormDomicilio from './FormDomicilio';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import ConvertDate from '../../../Helpers/ConvertDate';

const FormEmpleados = ({
    modulo, error, setError, nuevoEmpleado, agregar, elementSelect, editar, errorTable, successTable, cerrarModal,
    editarEmpleado, editarEmpleadoOrganizacion, reingresoEmpleado, reingresoRH, setReingresoRH, nominasRL, setOpenAdd
}) => {

    const [active, setActive] = useState(1);
    const [infoEmpleado, setInfoEmpleado] = useState(null);
    
    const [openAlertNUE, setOpenAlertNUE] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlert2, setOpenAlert2] = useState(false);
    const [openValue2, setOpenValue2] = useState(0);
    const [openAlert3, setOpenAlert3] = useState(false);
    const [openValue3, setOpenValue3] = useState(0);
    const [openAlert4, setOpenAlert4] = useState(false);
    const [openValue4, setOpenValue4] = useState(0);
    const [openAlert5, setOpenAlert5] = useState(false);
    const [openValue5, setOpenValue5] = useState(0);
    const [openAlert6, setOpenAlert6] = useState(false);
    const [openValue6, setOpenValue6] = useState(0);
    const [openAlert7, setOpenAlert7] = useState(false);
    const [openValue7, setOpenValue7] = useState(0);
    const [openAlert8, setOpenAlert8] = useState(false);
    const [openValue8, setOpenValue8] = useState(0);
    const [openAlert9, setOpenAlert9] = useState(false);
    const [openValue9, setOpenValue9] = useState(0);
    const [openAlert10, setOpenAlert10] = useState(false);
    const [openValue10, setOpenValue10] = useState(0);
    const [openAlert11, setOpenAlert11] = useState(false);
    const [openValue11, setOpenValue11] = useState(0);
    const [openAlert12, setOpenAlert12] = useState(false);
    const [openValue12, setOpenValue12] = useState(0);
    const [openAlert13, setOpenAlert13] = useState(false);
    const [openValue13, setOpenValue13] = useState(0);
    
    // formulario de Información personal
    const [puedeAsignarporCURP, setPuedeAsignarporCURP] = useState(false);
    const [fechaInicialGobierno, setFechaInicialGobierno] = useState('');
    const [nivelesEscolaridad, setNivelesEscolaridad] = useState([]);
    const [estadosNacimiento, setEstadosNacimiento] = useState([]);
    const [tiposEstadoCivil, setTiposEstadoCivil] = useState([]);
    const [infoEscolaridad, setInfoEscolaridad] = useState(null);
    const [fotografiaEdit, setFotografiaEdit] = useState(null);
    const [nueDisabled, setNueDisabled] = useState(false);
    const [fotografia, setFotografia] = useState(null);
    const [lengthCURP, setLengthCURP] = useState(0);
    const [datosNUE, setDatosNUE] = useState(null);
    const [lengthRFC, setLengthRFC] = useState(0);
    const [mostrarMadre, setMostrarMadre] = useState(false);
    const [esMadre, setEsMadre] = useState(false);
    const nivelEscolaridadRef = useRef('');
    const fechaNacimientoRef = useRef('');
    const cedProfesionalRef = useRef('');
    const folioEmpleadoRef = useRef('');
    const equipoEmpleadoRef = useRef('');
    const fechaIngresoRef = useRef('');
    const numEmpleadoRef = useRef('');
    const lNacimientoRef = useRef('');
    const estadoCivilRef = useRef('');
    const pApellidoRef = useRef('');
    const sApellidoRef = useRef('');
    const carreraRef = useRef('');
    const nombreRef = useRef('');
    const generoRef = useRef('');
    const curpRef = useRef('');
    const nssRef = useRef('');
    const rfcRef = useRef('');
    // formulario de formulario de organización del empleado
    const [selected, setSelected] = useState({ uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' }, plaza: { id: '', clave: '', nombre: '', titular: '' }, puesto: { id: '', clave: '', nombre: '', nombramiento: '' } });
    const [disabledFormOrgEdit, setDisabledFormOrgEdit] = useState({ plaza: true, zonaPago: true, puesto: true, turno: true, nomina: true });
    const [indicadoresSueldoNombramientoActivo, setIndicadoresSueldoNombramientoActivo] = useState([]);
    const [indicadoresNombramientoActivo, setIndicadoresNombramientoActivo] = useState([]);
    const [errorFormPago, setErrorFormPago] = useState({ active: false, message: '' });
    const [indicadoresSueldoActivo, setIndicadoresSueldoActivo] = useState([]);
    const [plazaDisponibleRango, setPlazaDisponilbeRango] = useState(false);
    const [indicadoresSelected, setIndicadoresSelected] = useState([]);
    const [errorConceptoPago, setErrorConceptoPago] = useState(null);
    const [formasPagoSelected, setFormasPagoSelected] = useState([]);
    const [infoNombramiento, setInfoNombramiento] = useState(null);
    const [muestraConceptos, setMuestraConceptos] = useState(true); 
    const [disabled, setDisabled] = useState({ zonaPago: true });
    const [infoAguinaldo, setInfoAguinaldo] = useState(null);
    const [retienePagos, setRetienePagos] = useState(false);
    const [infoContrato, setInfoContrato] = useState(null);
    const [errorPuesto, setErrorPuesto] = useState(false);
    const [errorPlaza, setErrorPlaza] = useState(false);
    const [indicadores, setIndicadores] = useState([]);
    const [zonasPago, setZonasPago] = useState([]);
    const [puestos, setPuestos] = useState([]);
    const [nominas, setNominas] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [plazas, setPlazas] = useState([]);
    const [plazasRango, setPlazasRango] = useState([]);
    const [notas, setNotas] = useState([]);
    const [monto, setMonto] = useState(0);
    const [sindicatoSeleccionado, setSindicatoSeleccionado] = useState({ id: '', sindicatoNombre: null, sindicatoPoc: null, sindicatoMonto: 0 });
    const [nombramientoEditInicial, setNombramientoEditInicial] = useState(false);
    const [sugerenciaPuesto, setSugerenciaPuesto] = useState(null);
    const [sugerenciaNomina, setSugerenciaNomina] = useState(null);
    const [infoTitularPlaza, setInfoTitularPlaza] = useState('no');
    const [infoRegPatronal, setInfoRegPatronal] = useState(null);
    const [infoMinLiquidez, setInfoMinLiquidez] = useState(null);
    const [infoSindicato, setInfoSindicato] = useState(null);
    const [sindicalizado, setSindicalizado] = useState('no');
    const [cuotaSindical, setCuotaSindical] = useState('no');
    const [titularPlaza, setTitularPlaza] = useState('no');
    const [minLiquidez, setMinLiquidez] = useState('si');
    const [aplicarSubsidioEmpleo, setAplicarSubsidioEmpleo] = useState('si');
    const [isLoading, setIsLoading] = useState(false);
    const [montoEdit, setMontoEdit] = useState(0);
    const fechaInicialPlazaDispRangoRef = useRef('');
    const fechaFinalPlazaDispRangoRef = useRef('');
    const fechaInicialContratoRef = useRef('');
    const fechaFinalContratoRef = useRef('');
    const fechaNombramientoRef = useRef('');
    const fechaAguinaldoRef = useRef('');
    const regPatronalRef = useRef('');
    const formaPagoRef = useRef('');
    const numCuentaRef = useRef('');
    const sindicatoRef = useRef('');
    const zonaPagoRef = useRef('');
    const cbeInterRef = useRef('');
    const contratoRef = useRef('');
    const nominaRef = useRef('');
    const turnoRef = useRef('');
    const bancoRef = useRef('');
    const notaRef = useRef('');
    // formulario de domicilio
    const [infoDomicilio, setInfoDomicilio] = useState(null);
    const numExteriorRef = createRef('');
    const numInteriorRef = createRef('');
    const coloniaRef = createRef('');
    const ciudadRef = createRef('');
    const estadoRef = createRef('');
    const calleRef = createRef('');
    const cpRef = createRef('');
    //formulario de dependientes económicos
    const [elementoSeleccionadoDepEcon, setElementoSeleccionadoDepEcon] = useState(null);
    const [mostrarFormAddDepEconEdit, setMostrarFormAddDepEconEdit] = useState(false);
    const [depEconomicosSavedEdit, setDepEconomicosSavedEdit] = useState([]);
    const [mostrarDiscapacidad, setMostrarDiscapacidad] = useState(false);
    const [depEconomicosSaved, setDepEconomicosSaved] = useState([]);
    const [tiposParentesco, setTiposParentesco] = useState([]);
    const [recargarInfo, setRecargarInfo] = useState(true);
    const gradoEstudiosDepEconomicoRef = createRef('');
    const parentescoDepEconommicoRef = createRef('');
    const escolaridadDepEconomicoRef = createRef('');
    const pApellidoDepEconomicoRef = createRef('');
    const sApellidoDepEconomicoRef = createRef('');
    const fechaNacDepEconomicoRef = useRef('');
    const promedioDepEconomicoRef = createRef('');
    const nombreDepEconomicoRef = createRef('');
    const nombreDiscapacidadRef = createRef('');
    const nivelDiscapacidadRef = createRef('');
    const curpDepEconomicoRef = createRef('');
    //formulario de pensiones alimenticias
    const [elementoSeleccionadoPensionAlim, setElementoSeleccionadoPensionAlim] = useState(null);
    const [mostrarFormAddPenAlimEdit, setMostrarFormAddPenAlimEdit] = useState(false);
    const [pensionesAlimSavedEdit, setPensionesAlimSavedEdit] = useState([]);
    const [pensionesAlimSaved, setPensionesAlimSaved] = useState([]);
    const pApellidoPensionAlimRef = createRef('');
    const sApellidoPensionAlimRef = createRef('');
    const tipoCuotaPensionAlimRef = createRef('');
    const coloniaNumEmergenciaRef = createRef('');
    const ciudadNumEmergenciaRef = createRef('');
    const nombrePensionAlimRef = createRef('');
    const folioPensionAlimRef = createRef('');
    const montoPensionAlimRef = createRef('');
    //formulario de números de emergencia
    const [elementoSeleccionadoNumEmer, setElementoSeleccionadoNumEmer] = useState(null);
    const [llamadasEmergenciaSavedEdit, setLlamadasEmergenciaSavedEdit] = useState([]);
    const [mostrarFormAddNumEmerEdit, setMostrarFormAddNumEmerEdit] = useState(false);
    const [llamadasEmergenciaSaved, setLlamadasEmergenciaSaved] = useState([]);
    const numInteriorNumEmergenciaRef = createRef('');
    const numExteriorNumEmergenciaRef = createRef('');
    const parentescoNumEmergenciaRef = createRef('');
    const fechaInicioPensionAlimRef = createRef('');
    const pApellidoNumEmergenciaRef = createRef('');
    const sApellidoNumEmergenciaRef = createRef('');
    const telMovilNumEmergenciaRef = createRef('');
    const fechaFinalPensionAlimRef = createRef('');
    const prioridadPensionAlimRef = createRef('');
    const telFijoNumEmergenciaRef = createRef('');
    const nombreNumEmergenciaRef = createRef('');
    const estadoNumEmergenciaRef = createRef('');
    const calleNumEmergenciaRef = createRef('');
    const cpNumEmergenciaRef = createRef('');
    //Formulario de Experiencia laboral
    const [elementoSeleccionadoExpLaboral, setElementoSeleccionadoExpLaboral] = useState(null);
    const [mostrarFormAddExpLaboralEdit, setMostrarFormAddExpLaboralEdit] = useState(false);
    const [expLaboralSavedEdit, setExpLaboralSavedEdit] = useState([]);
    const [expLaboralSaved, setExpLaboralSaved] = useState([]);
    const empresaExpLabRef = createRef('');
    const puestoExpLabRef = createRef('');
    const desdeMesExpLabRef = createRef('');
    const desdeAnioExpLabRef = createRef('');
    const hastaMesExpLabRef = createRef('');
    const hastaAnioExpLabRef = createRef('');
    const nombreJefeExpLabRef = createRef('');
    const telefonoExpLabRef = createRef('');
    //Formulario de Pasatiempos
    const [elementoSeleccionadoPasatiempo, setElementoSeleccionadoPasatiempo] = useState(null);
    const [mostrarFormAddPasatiempoEdit, setMostrarFormAddPasatiempoEdit] = useState(false);
    const [pasatiempoSavedEdit, setPasatiempoSavedEdit] = useState([]);
    const [pasatiempoSaved, setPasatiempoSaved] = useState([]);
    const [pasatiempos, setPasatiempos] = useState([]);
    const pasatiempoRef = createRef('');

    const { puesto: disabledPuesto, plaza: disabledPlaza, nomina: disabledNomina } = disabledFormOrgEdit;

    useEffect(() => {
        if((elementSelect && !disabledPuesto && (modulo === 30)) || !elementSelect || (elementSelect && (modulo === 32))) {
            validarPuesto(sugerenciaPuesto);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sugerenciaPuesto, disabledPuesto]);

    useEffect(() => {
        if((elementSelect && !disabledNomina) || (elementSelect && modulo === 32) || !elementSelect) {
            if(nominaRef.current) nominaRef.current.value = (sugerenciaNomina) ? sugerenciaNomina.id : '';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sugerenciaNomina, disabledNomina]);

    useEffect(() => {
        if(zonasPago.length > 0) {
            if(zonaPagoRef.current) zonaPagoRef.current.value = zonasPago[0].id;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zonasPago]);

    const generaDatosNUE = empleado => {
        return (
            <div>
                <div><strong>NUE: </strong>{ empleado.empleadoClave }</div>
                <div><strong>Nombre: </strong>{ empleado.empleadoNom }</div>
                <div><strong>Primer Apellido: </strong>{ empleado.empleadoPat }</div>
                <div><strong>Segundo Apellido: </strong>{ empleado.empleadoMat }</div>
                <div><strong>Plaza: </strong>{ empleado.plazaClave }</div>
            </div>
        )
    };

    const generaDatosNUERH = empleado => {
        return (
            <div>
                <div><strong>NUE: </strong>{ empleado.empCve }</div>
                <div><strong>Nombre: </strong>{ empleado.empNom }</div>
            </div>
        )
    };

    const longitudCURP = value => {
        setLengthCURP(value.length);
    };

    const longitudRFC = value => {
        setLengthRFC(value.length);
    };

    const asignaFechaEdoNacGen = value => {
        if(!elementSelect || puedeAsignarporCURP) {
            const curp = value.toUpperCase();
            const longitudCURP = curp.length;
            if(longitudCURP >= 13) {
                //asigna estado de nacimiento
                const lnac = curp.substr(11, 2);
                const claveEdo = estadosNacimiento.find(edo => edo.estadoCurp === lnac);
    
                lNacimientoRef.current.value = (claveEdo) ? `${claveEdo.id}` : '';
            } else if(longitudCURP < 13) {
                lNacimientoRef.current.value = '';
            }
    
            if(longitudCURP >= 11) {
                //asigna genero
                const genero = curp.substr(10, 1);
                generoRef.current.value =   (genero === 'H' || genero === 'M')
                                            ?   (genero === 'H') ? 'M' : (genero === 'M') ? 'F' : '' 
                                            :   '';
            } else if(longitudCURP < 11) {
                generoRef.current.value = '';
            }
    
            if(longitudCURP >= 10 ) {
                //asigna fecha de nacimiento
                const anio = curp.substr(4, 2);
                const mes = curp.substr(6, 2);
                const dia = curp.substr(8, 2);
                const anioActual2D = parseInt(ConvertDate(hoyMilisegundos()).substr(8,2));
                const anioCompleto = `${(parseInt(anio) < anioActual2D-18) ? '20' : '19' }${anio}`;
                fechaNacimientoRef.current.value = (new Date(anioCompleto, parseInt(mes)-1, dia, 0, 0))
                                                    ?   longDateToInput(new Date(anioCompleto, parseInt(mes)-1, dia, 0, 0))
                                                    :   '';
            } else if(longitudCURP < 10) {
                fechaNacimientoRef.current.value = '';
            }
        } else {
            setPuedeAsignarporCURP(true);
        }
    };

    const verificaEmpleadosHistorico = valor => {
        const nue = valor;
        setNueDisabled(true);
        axios.get(`${servicios_grupohit_rh}/empleado/historicorh/${nue}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(elementSelect) {
                        setDatosNUE(generaDatosNUERH(res.data.dataResponse));
                        setOpenAlertNUE(true);
                    } else {
                        nombreRef.current.value = res.data.dataResponse.empNom;
                        curpRef.current.value = res.data.dataResponse.empCurp;
                        rfcRef.current.value = res.data.dataResponse.empRfc;
                        nssRef.current.value = res.data.dataResponse.empNss;
                        fechaIngresoRef.current.value = (res.data.dataResponse.empStatusFecIni)
                                                        ? longDateToInput(res.data.dataResponse.empStatusFecIni)
                                                        : '';
                        pApellidoRef.current.value = '';
                        sApellidoRef.current.value = '';
                        estadoCivilRef.current.value = tiposEstadoCivil[0].id;
                        nivelEscolaridadRef.current.value = nivelesEscolaridad[0].id;
                        cedProfesionalRef.current.value = '';
                        carreraRef.current.value = '';
                        //folioEmpleadoRef.current.value = '';
                        //equipoEmpleadoRef.current.value = '';
                        setEsMadre(false);
                        asignaFechaEdoNacGen(res.data.dataResponse.empCurp);
                        if(setReingresoRH) setReingresoRH(true);
                    }
                    break;
                case 404:
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => {
            errorTable(verifique_conexion);
        })
        .finally(() => {
            if(numEmpleadoRef.current) numEmpleadoRef.current.value = nue;
            setNueDisabled(false);
        });
    };

    const verificaNUE = valor => {
        if(setReingresoRH) setReingresoRH(false);
        if(!elementSelect) {
            nombreRef.current.value = '';
            curpRef.current.value = '';
            rfcRef.current.value = '';
            nssRef.current.value = '';
            fechaIngresoRef.current.value = '';
            pApellidoRef.current.value = '';
            sApellidoRef.current.value = '';
            estadoCivilRef.current.value = tiposEstadoCivil[0]?.id;
            nivelEscolaridadRef.current.value = nivelesEscolaridad[0]?.id;
            cedProfesionalRef.current.value = '';
            carreraRef.current.value = '';
            setFotografia(null);
        }
        const nue = valor;
        if(valor.trim() !== '') {
            setNueDisabled(true);
            axios.get(`${servicios_grupohit_rh}/empleado/clave/${nue}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        if(res.data.dataResponse !== null && res.data.dataResponse !== 'NUE Válido') {
                            if(elementSelect) {
                                if(res.data.dataResponse.id === elementSelect.id) {
                                    setDatosNUE(null);
                                    return;
                                }
                            }
                            setDatosNUE(generaDatosNUE(res.data.dataResponse));
                            setOpenAlertNUE(true);
                            setNueDisabled(false);
                        } else {
                            //servicio 2
                            verificaEmpleadosHistorico(nue);
                            setDatosNUE(null);
                        }
                        break;
                    default:
                        errorTable(res.data.msg);
                        setNueDisabled(false);
                        break;
                }
            })
            .catch(error => {
                errorTable(verifique_conexion);
                setNueDisabled(false);
            });
        }
    };

    const validaAutocompleteOrganizacion = () => {
        if(selected.plaza.id === '') setErrorPlaza(true);
        if(selected.puesto.id === '') setErrorPuesto(true);
    };

    const verificaCambiosFormEmpleado = () => {
        if(
            numEmpleadoRef.current.value !== infoEmpleado.empleado.empleadoclave ||
            folioEmpleadoRef.current.value !== infoEmpleado.empleado.folioAlta ||
            equipoEmpleadoRef.current.value !== infoEmpleado.empleado.equipo ||
            esMadre !== infoEmpleado.empleado.esMadre ||
            nombreRef.current.value !== infoEmpleado.empleado.empleadonom ||
            pApellidoRef.current.value !== (infoEmpleado.empleado.empleadopat ? infoEmpleado.empleado.empleadopat :'') ||
            sApellidoRef.current.value !== (infoEmpleado.empleado.empleadomat ? infoEmpleado.empleado.empleadomat : '') ||
            fechaNacimientoRef.current.value !== longDateToInput(infoEmpleado.empleado.empleadofecnac) ||
            parseInt(lNacimientoRef.current.value) !== infoEmpleado.empleado.estado.id ||
            generoRef.current.value !== (infoEmpleado.empleado.empleadoGenero ? infoEmpleado.empleado.empleadoGenero : '') ||
            parseInt(estadoCivilRef.current.value) !== infoEmpleado.empleado.edoCivil.id ||
            rfcRef.current.value !== (infoEmpleado.empleado.empleadorfc ? infoEmpleado.empleado.empleadorfc : '') ||
            curpRef.current.value !== (infoEmpleado.empleado.empleadocurp ? infoEmpleado.empleado.empleadocurp : '') ||
            nssRef.current.value !== (infoEmpleado.empleado.empleadonss ? infoEmpleado.empleado.empleadonss : '') ||
            inputDateToLong(fechaIngresoRef.current.value) !== infoEmpleado.antiguedad.empantfecini ||
            parseInt(nivelEscolaridadRef.current.value) !== infoEscolaridad.escolaridad.id ||
            carreraRef.current.value !== (infoEscolaridad.empleadoCarrera ? infoEscolaridad.empleadoCarrera : '') ||
            cedProfesionalRef.current.value !== (infoEscolaridad.empleadoCedula ? infoEscolaridad.empleadoCedula : '') ||
            fotografiaEdit !== null
        ) {
            setError(null);
            return true;
        } else {
            setError(<AlertForm message="No se han realizado cambios en el formulario"/>);
            return false;
        }
    };

    const activaBtn = valor => {
        if(valor !== null) {
            setDisabled({ ...disabled, zonaPago: false });
        } else {
            setDisabled({ ...disabled, zonaPago: true });
            setSelected({ ...selected, uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' } });
        }
    };
    
    const validarPlaza = valor => {
        activaBtn(valor);
        if(valor) {
            if(valor.status.id !== 1) {
                setSelected({ ...selected, uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' }, plaza: { id: '', clave: '', nombre: '', titular: '' } });
                return;
            }
            setErrorPlaza(false);
            //guardar todos los datos en selected
            setSelected({ ...selected,
                uResponsable: {
                    id: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id,
                    clave: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaUr,
                    nombre: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaRazonSocial
                },
                uEjecutora: {
                    id: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.id,
                    clave: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoraclave,
                    nombre: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoranom
                },
                programa: {
                    id: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.id,
                    clave: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.programaclave,
                    nombre: valor.presupuestal.obraAccion.proyecto.subPrograma.programa.programanom
                },
                subprograma: {
                    id: valor.presupuestal.obraAccion.proyecto.subPrograma.id,
                    clave: valor.presupuestal.obraAccion.proyecto.subPrograma.subProgramaClave,
                    nombre: valor.presupuestal.obraAccion.proyecto.subPrograma.subProgramaNom
                },
                pAccion: {
                    id: valor.presupuestal.obraAccion.proyecto.id,
                    clave: valor.presupuestal.obraAccion.proyecto.proyectoclave,
                    nombre: valor.presupuestal.obraAccion.proyecto.proyectonom
                },
                oAccion: {
                    id: valor.presupuestal.obraAccion.id,
                    clave: valor.presupuestal.obraAccion.obraAccionClave,
                    nombre: valor.presupuestal.obraAccion.obraAccionNom
                },
                cPresupuestal: {
                    id: valor.presupuestal.id,
                    clave: valor.presupuestal.cpresupuestalclave,
                    nombre: valor.presupuestal.cpresupuestalnom
                },
                departamento: {
                    id: valor.areaid.id,
                    clave: valor.areaid.areacve,
                    nombre: valor.areaid.areanom
                },
                plaza: {
                    id: valor.id,
                    clave: valor.plazaclave,
                    titular: (valor.empleadoTitular) ? `${valor.empleadoTitular.empleadoclave} / ${(valor.empleadoTitular.empleadopat) ? `${valor.empleadoTitular.empleadopat} ` : ''}${(valor.empleadoTitular.empleadomat) ? `${valor.empleadoTitular.empleadomat} ` : ''}${valor.empleadoTitular.empleadonom}` : '-'
                }
            });
            //asigna puesto ligado a la plaza, al Autocomplete
            const valorPuesto = puestos.find(puesto => puesto.id === valor.puesto.id);
            if(valorPuesto) {
                setSugerenciaPuesto(valorPuesto);
            } else {
                setSugerenciaPuesto(null);
            }
            //asigna nomina ligada a la plaza, al Select
            const valorNomina = nominas.find(nomina => nomina.id === valor.nomina.id);
            if(valorNomina) {
                setSugerenciaNomina(valorNomina);
            } else {
                setSugerenciaNomina(null);
            }
            //obtener las zonas de pago de la cPresupuestal o puesto preguntar
            axios.get(`${servicios_grupohit_admon}/compania/domicilios/${valor.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayZonaPago = res.data.dataResponse.filter(item => item.tipoDom.id === 2);
                        setZonasPago(arrayZonaPago);
                        break;
                    case 404:
                        setZonasPago([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));
        } else {
            setSugerenciaPuesto(null);
            setSugerenciaNomina(null);
            setZonasPago([]);
            setSelected({ ...selected, uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' }, plaza: { id: '', clave: '', nombre: '', titular: '' } });
        }
    };

    const calcularMontoSueldoInicial = indicadoresInicial => {
        if(indicadoresSelected) {
            const percepciones = indicadoresInicial.filter(i => (i.indicadorid.naturaleza.id === 1) & (i.indicadorid.defecto === true));
            const deducciones = indicadoresInicial.filter(i => (i.indicadorid.naturaleza.id === 2) & (i.indicadorid.defecto === true));

            const totalMontoIndicadoresP = (percepciones.length > 0) ? percepciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0;
            const totalMontoIndicadoresD = (deducciones.length > 0) ? deducciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0;

            return totalMontoIndicadoresP - totalMontoIndicadoresD;
        } 
        return 0;
    };

    const validarPuesto = (valor) => {
        if(valor) {
            setMuestraConceptos(false);
            setErrorPuesto(false);
            axios.get(`${servicios_grupohit_org}/tab/puestoindicadores/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        //se quita de la lista Ayuda de Guardería, clave 7
                        const indicadoresInicial = res.data.dataResponse.filter(ind => ind.indicadorid.indicadorClave !== '7');
                        setIndicadores(indicadoresInicial);
                        
                        const sueldoInicial = calcularMontoSueldoInicial(indicadoresInicial);
                        setMonto(sueldoInicial);
                        //obtiene los indicadores con la bandera default activa
                        const indicadoresSueldoActivo = indicadoresInicial.filter(indicador => indicador.indicadorid.defecto === true );
                        const indicadorescheck = indicadoresSueldoActivo.map(item => item.indicadorid.indicadorId);
                        if(setIndicadoresSueldoActivo.length) setErrorConceptoPago(null);
                        setIndicadoresSelected(indicadorescheck);

                        if(elementSelect && (modulo === 32)) {
                            setMontoEdit(calcularMontoSueldoInicial(indicadoresInicial));                          
                        }

                        if(elementSelect && (modulo === 30) && (!disabledPuesto)) {
                            setMontoEdit(calcularMontoSueldoInicial(indicadoresInicial));
                        }
                            
                        setSelected({ ...selected, puesto: { id: valor.id, clave: valor.puestoCve, nombre: valor.puestoNom, nombramiento: valor.nombramiento } });
                        break;
                    case 404:
                        setSelected({ ...selected, puesto: { id: valor.id, clave: valor.puestoCve, nombre: valor.puestoNom, nombramiento: valor.nombramiento } });
                        setIndicadores([]);
                        setMonto(0);
                        if(elementSelect) {
                            setMontoEdit(0);
                        }
                        errorTable(res.data.msg);
                        break;
                    default:
                        setIndicadores([]);
                        setMonto(0);
                        if(elementSelect) {
                            setMontoEdit(0);
                        }
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => {
                setIndicadores([]);
                setMonto(0);
                if(elementSelect) {
                    setMontoEdit(0);
                }
                errorTable(verifique_conexion);
            })
            .finally(() => setMuestraConceptos(true));
        } else {
            setMonto(0);
            setSelected({ ...selected, puesto: { id: '', clave: '', nombre: '', nombramiento: '' } });
            setIndicadores([]);
            setIndicadoresSelected([]);
        }
    };

    useEffect(() => {
        //servicio que obtiene los niveles de escolaridad
        axios.get(`${servicios_grupohit_catalogo}/tipoEscolaridad`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNivelesEscolaridad(res.data.dataResponse);
                    if(!elementSelect) nivelEscolaridadRef.current.value = res.data.dataResponse[0].id;
                    break;
                case 404:
                    setNivelesEscolaridad([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        //servicio que obtiene los tipos de estado civil
        axios.get(`${servicios_grupohit_catalogo}/tipoedocivil/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTiposEstadoCivil(res.data.dataResponse);
                    if(!elementSelect) estadoCivilRef.current.value = res.data.dataResponse[0].id;
                    break;
                case 404:
                    setTiposEstadoCivil([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        //servicio que obtiene las Entidades federativas
        axios.get(`${servicios_grupohit_catalogo}s/estado/1`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEstadosNacimiento(res.data.dataResponse);
                    break;
                case 404:
                    setEstadosNacimiento([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        //servicio para obtenerPlazas
        axios.get(`${servicios_grupohit_org}/plaza/relacion/laboral/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayPlazas = res.data.dataResponse.map(plaza => {
                        plaza.plazaclave = (plaza.status.id === 1) ? plaza.plazaclave : `${plaza.plazaclave} (${plaza.status.nombre})`;
                        return plaza;
                    });
                    setPlazas(arrayPlazas);
                    break;
                case 404:
                    setPlazas([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        //servicio para cargar los puestos dependiendo de la relación laboral seleccionada al inicio
        axios.get(`${servicios_grupohit_org}/puesto/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayPuestos = res.data.dataResponse.map(puesto => ({
                        id: puesto.id,
                        puestoCve: puesto.puestoCve,
                        puestoNom: puesto.puestoNom,
                        puestoCveNom: `${puesto.puestoCve} / ${puesto.puestoNom}`,
                        nombramiento: puesto.nombramiento
                    }));
                    setPuestos(arrayPuestos);
                    break;
                case 404:
                    setPuestos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        //servicio para cargar los tipos de jornada
        axios.get(`${servicios_grupohit_catalogo}/turno/`, headersAuth() )
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTurnos(res.data.dataResponse);
                    //if((!elementSelect && (modulo === 30)) || (modulo === 32)) turnoRef.current.value = res.data.dataResponse[0];
                    break;
                case 404:
                    setTurnos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        //petición para obtener las nóminas según la relación laboral
        axios.get(`${servicios_grupohit_nomina}/config/nomina`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNominas(res.data.dataResponse);
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        //petición para obtener el catálogo de pasatiempos
        axios.get(`${servicios_grupohit_catalogo}/pasatiempos/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const respuesta = res.data.dataResponse.map(item => ({ 'id': item.id, 'pasatiempo': item.pasaNom, 'descripcion': item.panDesc }));
                    setPasatiempos(respuesta);
                    break;
                case 404:
                    setPasatiempos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(active === 4 || active === 6) {
            if(tiposParentesco.length === 0) {
                //petición para obtener el catalogo de tipo de parentesco
                axios.get(`${servicios_grupohit_catalogo}/tipoparentesco/`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setTiposParentesco(res.data.dataResponse);
                            break;
                        case 404:
                            setTiposParentesco([]);
                            break;
                        default:
                            errorTable(res.data.msg);
                            break;
                    }
                })
                .catch(error => errorTable(verifique_conexion));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    useEffect(() => {
        if(elementSelect && recargarInfo) {
            let url = '';
            if(modulo === 30) url = 'empleado';
            if(modulo === 32) url = 'empleado/inactivos';

            axios.get(`${servicios_grupohit_rh}/empleado/antiguedad/ultima/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setFechaInicialGobierno(longDateToInput(res.data.dataResponse.empantfecini));
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });

            axios.get(`${servicios_grupohit_rh}/${url}/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        if(modulo === 30) {
                            res.data.dataResponse.empleado.folioAlta = (!res.data.dataResponse.empleado.folioAlta) ? '' : res.data.dataResponse.empleado.folioAlta;
                            res.data.dataResponse.empleado.equipo = (!res.data.dataResponse.empleado.equipo) ? '' : res.data.dataResponse.empleado.equipo;
                            setInfoEmpleado(res.data.dataResponse);
                            if(res.data.dataResponse.empleado.empleadoGenero === 'F') {
                                setMostrarMadre(true);
                                setEsMadre(res.data.dataResponse.empleado.esMadre);                        
                            } 
                        }
                        if(elementSelect && (modulo === 32)) {
                            setInfoEmpleado(ConvertInfoEmp((res.data.dataResponse)))
                            if(res.data.dataResponse.empleadoGenero === 'F') {
                                setMostrarMadre(true);
                                setEsMadre(res.data.dataResponse.empEsMadre);                        
                            } 
                        };
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });

            axios.get(`${servicios_grupohit_rh}/empleado/aguinaldo/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const respuesta = {
                            id: res.data.dataResponse.id,
                            fechaAguinaldo: res.data.dataResponse.empFecAgui
                        };
                        setInfoAguinaldo(respuesta);
                        break;
                    case 404:
                        setInfoAguinaldo({ id: '', fechaAguinaldo: '' });
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });
    
            axios.get(`${servicios_grupohit_rh}/empleado/nombramiento/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const respuesta = {
                            id: res.data.dataResponse.id,
                            fechaNombramiento: res.data.dataResponse.empFecAgui,
                            fechaFinalNombramiento: (res.data.dataResponse.empNomFecFin) ? res.data.dataResponse.empNomFecFin : ''
                        };
                        setInfoNombramiento(respuesta);
                        break;
                    case 404:
                        setInfoNombramiento({ id: '', fechaNombramiento: '', fechaFinalNombramiento: '' });
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });

            if(modulo === 30) {
                axios.get(`${servicios_grupohit_rh}/empleado/contrato/${elementSelect.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setInfoContrato(res.data.dataResponse);
                            break;
                        case 404:
                            setInfoContrato({
                                contrato: {
                                    id: ''
                                },
                                fechaini: null,
                                fechafin: null
                            });
                            break;
                        default:
                            errorTable(res.data.msg);
                            break;
                    }
                })
                .catch(error => { errorTable(verifique_conexion) });

                 //petición para obtener información de sindicato
                axios.get(`${servicios_grupohit_rh}/empleado/sindicatos/${elementSelect.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status){
                        case 200:
                            const sindicato = {
                                id: res.data.dataResponse.id,
                                sindicato: res.data.dataResponse.sindicatoId,
                                aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                            };
                            setInfoSindicato(sindicato);
                            break;
                        case 404:
                            setInfoSindicato({ id: '', sindicato: { id: '' }, aplica: 'no' });
                            break;
                        default:
                            errorTable(res.data.msg);
                            setInfoSindicato({ id: '', sindicato: { id: '' }, aplica: 'no' });
                            break;
                    }
                })
                .catch(error => {errorTable(verifique_conexion); });
                
                //petición para obtener información de minimo Liquidez
                axios.get(`${servicios_grupohit_rh}/empleado/liquidez/${elementSelect.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status){
                        case 200:
                            const minimoLiquidez = (res.data.dataResponse.minimoLiquidez  === true)
                                                ?   'si' 
                                                :   (res.data.dataResponse.minimoLiquidez  === false)
                                                    ?   'no'
                                                    :   '';
                            setInfoMinLiquidez(minimoLiquidez);
                            setMinLiquidez(minimoLiquidez);
                            break;
                        case 404:
                            setInfoMinLiquidez('');
                            setMinLiquidez('si');
                            break;
                        default:
                            errorTable(res.data.msg);
                            setInfoMinLiquidez('');
                            setMinLiquidez('si');
                            break;
                    }
                })
                .catch(error => {
                    errorTable(verifique_conexion);
                    setMinLiquidez('si');
                });
            } else {
                setInfoAguinaldo({ id: '', fechaAguinaldo: '' });
                setInfoNombramiento({ id: '', fechaNombramiento: '', fechaFinalNombramiento: '' });
                setInfoSindicato({ id: '', sindicato: { id: '' }, aplica: 'no' });
                setInfoMinLiquidez('');
                setMinLiquidez('si');
            }

            axios.get(`${servicios_grupohit_org}/plaza/empleadotitular/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const infoPlaza = {
                            id: res.data.dataResponse.id,
                            clave: res.data.dataResponse.plazaclave
                        };
                        setInfoTitularPlaza(infoPlaza);
                        setTitularPlaza('si');
                        break;
                    case 404:
                        setInfoTitularPlaza('no');
                        setTitularPlaza('no');
                        break;
                    default:
                        setInfoTitularPlaza('no');
                        setTitularPlaza('no');
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => {
                setInfoTitularPlaza('no');
                setTitularPlaza('no');
                errorTable(verifique_conexion);
            });

            axios.get(`${servicios_grupohit_rh}/empleado/escolaridad/last/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const respuesta = { id: res.data.dataResponse.id , escolaridad: { id: (res.data.dataResponse.escolaridad.id) ? res.data.dataResponse.escolaridad.id : '' }, empleadoCarrera: (res.data.dataResponse.empleadoCarrera) ? res.data.dataResponse.empleadoCarrera : '', empleadoCedula: (res.data.dataResponse.empleadoCedula) ? res.data.dataResponse.empleadoCedula : '' };
                        setInfoEscolaridad(respuesta);
                        break;
                    case 404:
                        setInfoEscolaridad({ escolaridad: { id: '' }, empleadoCarrera: '', empleadoCedula: '' });
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });

            axios.get(`${servicios_grupohit_rh}/documentos/${elementSelect.id}/1`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setFotografia(res.data.dataResponse[0]);
                        break;
                    case 404:
                        setFotografia(null);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });

            axios.get(`${servicios_grupohit_rh}/empleado/domicilio/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        setInfoDomicilio(res.data.dataResponse);
                        break;
                    case 404:
                        setInfoDomicilio([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion); });
            //petición para obtener información de registro patronal
            axios.get(`${servicios_grupohit_rh}/empleado/registropat/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        const regPatronal = {
                            id: res.data.dataResponse.id,
                            regPatronal: {
                                id: res.data.dataResponse.regPat.id,
                                regPatronal: res.data.dataResponse.regPat.registroPatClave
                            }
                        };
                        setInfoRegPatronal(regPatronal);
                        break;
                    case 404:
                        setInfoRegPatronal({ id: '', regPatronal: { id: '' } });
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion); });
            // obtiene las notas adicionales del empleado
            axios.get(`${servicios_grupohit_rh}/empleado/notas/getall/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        setNotas(res.data.dataResponse);
                        break;
                    case 404:
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion); });

            setRecargarInfo(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect, recargarInfo]);

    const enviarEmpleado = (
        setIsLoading, datosEmpleado, fechaIngreso, datosPuesto, datosZonaPago, datosCPresupuestal, datosTurno, datosNomina,
        datosPlaza, formasPagoSelected, empPuestoFecIni, infoSindicato, infoPresMinLiquidez, depEconomicosSaved, pensionesAlimSaved,
        llamadasEmergenciaSaved, domicilio, datosEscolaridad, areaNom, infoFotografia, empleadoIndicadores, empleadoContrato,
        empFecAgui, empNombramiento, expLaboralSaved, pasatiempoSaved, regPatId, nota, ejecutaBitacora10, titularPlazaClave,
        pagosPendientes, retienePagos, plazaDisponibleRango
    ) => {
        nuevoEmpleado({
            reingresoRH, setIsLoading, datosEmpleado, fechaIngreso, datosPuesto, datosZonaPago, datosCPresupuestal, datosTurno, datosNomina,
            datosPlaza, formasPagoSelected, setFormasPagoSelected, empPuestoFecIni, infoSindicato, infoPresMinLiquidez, depEconomicosSaved, pensionesAlimSaved,
            llamadasEmergenciaSaved, domicilio, datosEscolaridad, areaNom, infoFotografia, empleadoIndicadores, empleadoContrato,
            empFecAgui, empNombramiento, expLaboralSaved, pasatiempoSaved, regPatId, nota, agregar, setOpenAdd, errorTable,
            ejecutaBitacora10, titularPlazaClave, pagosPendientes, retienePagos, plazaDisponibleRango
        });
    };

    const enviar = () => {
        if(validarSubmit('DatosPersonales') && validarSubmit('OrganizacionEmpleado') && formasPagoSelected.length > 0 && indicadoresSelected.length > 0) {
            if(selected.cPresupuestal.id !== '' && selected.departamento.id !== '' && selected.plaza.id !== '' && selected.puesto.id !== '') {
                const empleadoclave = numEmpleadoRef.current.value;
                const empleadoFolio = (folioEmpleadoRef.current.value.trim() === '') ? null : folioEmpleadoRef.current.value.trim();
                const empleadoEquipo = (equipoEmpleadoRef.current.value.trim() === '') ? null : equipoEmpleadoRef.current.value.trim();
                const empleadonom = nombreRef.current.value;
                const empleadopat = pApellidoRef.current.value;
                const empleadomat = sApellidoRef.current.value;
                const empleadofecnac = inputDateToLong(fechaNacimientoRef.current.value);
                const empleadocurp = curpRef.current.value;
                const empleadorfc = rfcRef.current.value;
                const empleadonss = nssRef.current.value;
                const empleadoGenero = generoRef.current.value;
                const edoCivil = parseInt(estadoCivilRef.current.value)
                const estado = parseInt(lNacimientoRef.current.value);
                
                let datosEmpleado = {
                    empleadoclave, empleadonom, empleadopat, empleadomat, empleadofecnac, edoCivil, estado,
                    empleadoFolio, empleadoEquipo, empleadoMadre: esMadre,
                };
    
                if(empleadopat !== '') datosEmpleado = { ...datosEmpleado, empleadopat };
                if(empleadomat !== '') datosEmpleado = { ...datosEmpleado, empleadomat };
                if(empleadocurp !== '') datosEmpleado = { ...datosEmpleado, empleadocurp };
                if(empleadorfc !== '') datosEmpleado = { ...datosEmpleado, empleadorfc };
                if(empleadonss !== '') datosEmpleado = { ...datosEmpleado, empleadonss };
                if(empleadoGenero !== '') datosEmpleado = { ...datosEmpleado, empleadoGenero };
    
                const empantfecini = inputDateToLong(fechaIngresoRef.current.value);
                const fechaIngreso = empantfecini;
    
                const puestoId = selected.puesto.id;
                const puestoCve = selected.puesto.clave;
                const puestoNom = selected.puesto.nombre;
                const empPuestoFecIni = inputDateToLong(fechaIngresoRef.current.value);
                const datosPuesto = { puestoId, puestoCve, puestoNom, empPuestoFecIni };
    
                const domciaId = parseInt(zonaPagoRef.current.value);
                const domicilioSelected = zonasPago.find(zP => zP.id === domciaId); 
                const domciaCalle = domicilioSelected.domCiaCalle;
                const domciaNumext = domicilioSelected.domCiaNumInt;
                const domciaNumint = domicilioSelected.domCiaNumExt;
                const empzpfecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosZonaPago = { domciaId, domciaCalle, domciaNumext, domciaNumint, empzpfecini };
    
                const ciaRazonSocial = selected.uResponsable.nombre;
                const cpresupuestalid = selected.cPresupuestal.id;
                const cpresupuestalclave = selected.cPresupuestal.clave;
                const cpresupuestalNom = selected.cPresupuestal.nombre;
                const empcpresfecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosCPresupuestal = { ciaRazonSocial, cpresupuestalid, cpresupuestalclave, cpresupuestalNom, empcpresfecini };
    
                const turnoId = parseInt(turnoRef.current.value);
                const turnoSelected = turnos.find(turno => turno.id === turnoId);
                const turnoNom = turnoSelected.turnoNom;
                const fecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosTurno = { turnoId, turnoNom, fecini };
    
                const nominaid = parseInt(nominaRef.current.value);
                const infoNomina = nominas.find(n => n.id === nominaid);
                const nominaNomCorto = infoNomina.nominaNomCorto;
                const nominaNom = infoNomina.nominaNombre;
                const nominaClave = infoNomina.nominaClave;
                const empnominafecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosNomina = { nominaid, nominaNomCorto, nominaNom, nominaClave, empnominafecini };
    
                const plazaId = selected.plaza.id;
                const plazaclave = selected.plaza.clave;
                const empFecIni = inputDateToLong(fechaIngresoRef.current.value);
                const datosPlaza = { plazaId, plazaclave, empFecIni };
    
                const calle = calleRef.current.value;
                const numint = numInteriorRef.current.value;
                const numext = numExteriorRef.current.value;
                const cpId = parseInt(coloniaRef.current.value);
                const domicilio = { calle, numint, numext, cpId };
    
                const escolaridad = parseInt(nivelEscolaridadRef.current.value);
                const empleadoCarrera = carreraRef.current.value;
                const empleadoCedula = cedProfesionalRef.current.value;
                const datosEscolaridad = { escolaridad, empleadoCarrera, empleadoCedula };
    
                const areaNom = selected.departamento.nombre;

                const documento = 1;
                const tempdocNota = "Fotografía del colaborador";
                const archivo = (fotografia) ? fotografia : null;
                let infoFofografia = null;
                if(archivo) infoFofografia = { documento, tempdocNota, archivo };

                let empleadoIndicadores = null;
                if(indicadoresSelected.length) empleadoIndicadores = indicadoresSelected;

                let empleadoContrato = null;
                if(contratoRef.current.value !== '') {
                    const contratoId = parseInt(contratoRef.current.value);
                    const Fecini = inputDateToLong(fechaInicialContratoRef.current.value);
                    const Fecfin = fechaFinalContratoRef.current ? inputDateToLong(fechaFinalContratoRef.current.value) : null;

                    empleadoContrato = { contratoId, Fecini }

                    if(fechaFinalContratoRef.current && fechaFinalContratoRef.current.value !== '') {
                        empleadoContrato = { ...empleadoContrato, Fecfin}
                    }
                }
    
                let empFecAgui = null;
                if(fechaAguinaldoRef.current.value !== '') {
                    empFecAgui = inputDateToLong(fechaAguinaldoRef.current.value);
                }

                let empNombramiento = null;
                if(fechaNombramientoRef.current) {
                    if(fechaNombramientoRef.current.value !== '') {
                        empNombramiento = { empFecNom: inputDateToLong(fechaNombramientoRef.current.value) }
                    }
                }

                let infoSindicato = null;
                if(sindicatoRef.current) {
                    if(sindicatoRef.current.value !== '') {
                        infoSindicato = {
                            sindId: parseInt(sindicatoRef.current.value),
                            aplica: (cuotaSindical === 'si') ? true : false
                        };
                    }
                }

                const infoPresMinLiquidez = (minLiquidez === 'si') ? true : false; 

                let regPatId = null;
                if(regPatronalRef.current.value !== '') {
                    regPatId = parseInt(regPatronalRef.current.value);
                }

                const nota = (notaRef.current.value.trim() !== '') ? notaRef.current.value.trim() : null;

                const indicadoresNombramientoActivoSelected = indicadoresNombramientoActivo.find(ina => indicadoresSelected.indexOf(ina.indicadorid.indicadorId) > -1);
                const ejecutaBitacora10 = (indicadoresNombramientoActivoSelected) ? true : false;

                const titularPlazaClave = (titularPlaza === 'si')
                ?   selected.plaza.id
                :   null; 

                const relLaboralInfo = nominasRL.find(n => n.nomina === Number(nominaRef.current.value));
                
                const pagosPendientes = (inputDateToLong(fechaInicialContratoRef.current.value) < relLaboralInfo.periodoFecini)
                                        ?   true
                                        :   false;

                const fechaFinalPlazaDispRango = (plazaDisponibleRango) ? inputDateToLong(fechaFinalPlazaDispRangoRef.current.value) : plazaDisponibleRango

                enviarEmpleado(
                    setIsLoading,
                    datosEmpleado,
                    fechaIngreso,
                    datosPuesto,
                    datosZonaPago,
                    datosCPresupuestal,
                    datosTurno,
                    datosNomina,
                    datosPlaza,
                    formasPagoSelected,
                    empPuestoFecIni,
                    infoSindicato,
                    infoPresMinLiquidez,
                    depEconomicosSaved,
                    pensionesAlimSaved,
                    llamadasEmergenciaSaved,
                    domicilio,
                    datosEscolaridad,
                    areaNom,
                    infoFofografia,
                    empleadoIndicadores,
                    empleadoContrato,
                    empFecAgui,
                    empNombramiento,
                    expLaboralSaved,
                    pasatiempoSaved,
                    regPatId,
                    nota,
                    ejecutaBitacora10,
                    titularPlazaClave,
                    pagosPendientes,
                    retienePagos,
                    fechaFinalPlazaDispRango
                );
            } else {
                validaAutocompleteOrganizacion();
            }
        } else {
            validaAutocompleteOrganizacion();
            if(!formasPagoSelected.length) {
                setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
            }
            if(!indicadoresSelected.length) {
                setErrorConceptoPago(<AlertForm message="No ha seleccionado algún concepto de pago" />);
            }
        }
    };

    const actualizarEmpleado = (infoEmp) => {
        if(editar) {
            editarEmpleado(modulo, elementSelect, infoEmp, infoEmpleado, setInfoEmpleado, editar, errorTable, setIsLoading);
        }
    };

    const actualizarEscolaridad = (infoEsd, idEsc) => {
        axios.put(`${servicios_grupohit_rh}/empleado/escolaridad/${idEsc}`, infoEsd, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setInfoEscolaridad({
                        ...infoEscolaridad,
                        escolaridad: res.data.dataResponse.escolaridad,
                        empleadoCedula: res.data.dataResponse.empleadoCedula,
                        empleadoCarrera: res.data.dataResponse.empleadoCarrera
                    });
                    successTable(registro_actualizado)
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    const actualizarFotografia = () => {
        if(elementSelect) {
            if(fotografiaEdit !== null) {
                if(fotografia) {
                    //actualiza fotografía
                    const documento = 1;
                    const tempdocNota = "Fotografía del colaborador";
                    const archivo = (fotografiaEdit) ? fotografiaEdit : null;
                    const empleado = elementSelect.id;
        
                    let infoFofografia = null;
        
                    if(archivo) infoFofografia = { documento, tempdocNota, archivo, empleado };
                    axios.put(`${servicios_grupohit_rh}/documentos/${fotografia.id}`, infoFofografia, headersAuth())
                    .then(res => {
                        switch(res.data.status){
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(res.data.msg);
                                break;
                        }
                    })
                    .catch( error => {
                        errorTable(registro_no_guardado)
                    });
                } else {
                    //guarda fotografía
                    const documento = 1;
                    const tempdocNota = "Fotografía del colaborador";
                    const archivo = (fotografiaEdit) ? fotografiaEdit : null;
                    const empleado = elementSelect.id;
        
                    let infoFofografia = null;
        
                    if(archivo) infoFofografia = { documento, tempdocNota, archivo, empleado };

                    axios.post(`${servicios_grupohit_rh}/documentos/`, infoFofografia, headersAuth())
                    .then(res => {
                        switch(res.data.status){
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(res.data.msg);
                                break;
                        }
                    })
                    .catch( error => {
                        errorTable(registro_no_guardado)
                    });
                }
            }
        }
    };

    const actualizarOrganizacion = (info, ejecutaBitacora10, pagosPendientes=false, plazaDisponibleRango=false) => {
        if(validarSubmit("OrganizacionEmpleado")) {
            editarEmpleadoOrganizacion({
                info, modulo, elementSelect, selected, zonasPago, turnos, nominas, indicadoresSelected, sindicatoSeleccionado,
                cuotaSindical, infoContrato, infoAguinaldo, infoNombramiento, infoRegPatronal, infoSindicato, minLiquidez, infoMinLiquidez,
                setInfoContrato, setInfoAguinaldo, setInfoRegPatronal, setInfoNombramiento, setInfoSindicato, setInfoMinLiquidez,
                contratoRef, fechaInicialContratoRef, fechaFinalContratoRef, fechaAguinaldoRef, fechaNombramientoRef, regPatronalRef,
                editar, successTable, errorTable, cerrarModal: (elementSelect && modulo === 32) ? cerrarModal : false, setIsLoading, ejecutaBitacora10,
                infoTitularPlaza, titularPlaza, setTitularPlaza, setNombramientoEditInicial, setRecargarInfo, pagosPendientes, retienePagos,
                plazaDisponibleRango: (plazaDisponibleRango) ? inputDateToLong(fechaFinalPlazaDispRangoRef.current.value) : plazaDisponibleRango
            });
        }
    };

    const reingresar = async (info) => {
        const request = {
            empId: elementSelect.id,
            empPuestoFecIni: info.reingreso
        };
        const request2 = {
            empleadoid: elementSelect.id,
            empantfecini: info.reingreso
        };
        const actualizaOrganizacionInactivo = datos => {
            const indicadoresNombramientoActivoSelected = indicadoresNombramientoActivo.find(ina => indicadoresSelected.indexOf(ina.indicadorid.indicadorId) > -1);
            const ejecutaBitacora10 = (nombramientoEditInicial === false) ? (indicadoresNombramientoActivoSelected) ? true : false : false;
            let pagosPendientes = false;
            const fechaIniNominaPeriodo = nominasRL.find(n => n.nomina === Number(nominaRef.current.value));
            if(inputDateToLong(fechaInicialContratoRef.current.value) < fechaIniNominaPeriodo.periodoFecini) {
                pagosPendientes = true;
            }
            actualizarOrganizacion({
                info: datos, modulo, elementSelect, selected, zonasPago, turnos, nominas, indicadoresSelected, sindicatoSeleccionado,
                cuotaSindical, infoContrato, infoAguinaldo, infoNombramiento, infoRegPatronal, infoSindicato, minLiquidez, infoMinLiquidez,
                contratoRef, fechaInicialContratoRef, fechaFinalContratoRef, fechaAguinaldoRef, fechaNombramientoRef, regPatronalRef,
                editar, successTable, errorTable, cerrarModal
            }, ejecutaBitacora10, pagosPendientes, plazaDisponibleRango);
        };
        
        await reingresoEmpleado(request, request2, elementSelect, info, actualizaOrganizacionInactivo, cerrarModal, errorTable, setIsLoading);
    };

    const actualizarEmpleadoDomicilio = (info, infoAdic) => {
        setIsLoading(true);
        axios.put(`${servicios_grupohit_rh}/empleado/domicilio/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setInfoDomicilio({
                        ...infoDomicilio,
                        empleado: {
                            id: info.empId
                        },
                        codPostal: {
                            codigopId: info.cpId,
                            codigopColonia: infoAdic.coloniaNom,
                            codigopCp: infoAdic.cpNom,
                            ciudadId: {
                                ciudadNom: infoAdic.ciudadNom,
                                estadoId: {
                                    estadoNom: infoAdic.estadoNom
                                }
                            }
                        },
                        calle: info.calle,
                        numExt: info.numext,
                        numInt: (info.numint) ? info.numint : ''
                    });
                    successTable(registro_actualizado);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    const agregarEmpleadoDomicilio = (info, infoAdic) => {
        setIsLoading(true);
        axios.post(`${servicios_grupohit_rh}/empleado/domicilio/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setInfoDomicilio({
                        id: res.data.dataResponse.id,
                        empleado: {
                            id: info.empId
                        },
                        codPostal: {
                            codigopId: info.cpId,
                            codigopColonia: infoAdic.coloniaNom,
                            codigopCp: infoAdic.cpNom,
                            ciudadId: {
                                ciudadNom: infoAdic.ciudadNom,
                                estadoId: {
                                    estadoNom: infoAdic.estadoNom
                                }
                            }
                        },
                        calle: info.calle,
                        numExt: info.numext,
                        numInt: (info.numint) ? info.numint : ''
                    });
                    successTable(registro_actualizado);
                    break;
                default:
                    errorTable(res.data.msg);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    const actualizarEmpleadoDepEcon = (info, id) => {
        setIsLoading(true);
        axios.put(`${servicios_grupohit_rh}/empleado/dependiente/economico/${id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = {
                        id: res.data.dataResponse.id,
                        nombre: res.data.dataResponse.nombre,
                        apellidoPat: res.data.dataResponse.apellidoPat,
                        apellidoMat: res.data.dataResponse.apellidoMat,
                        curp: res.data.dataResponse.curp,
                        fechaNac: res.data.dataResponse.fechaNac,
                        parentesco: res.data.dataResponse.parentesco.id,
                        parentescoNom: res.data.dataResponse.parentesco.parentescoNom,
                        escolaridad: res.data.dataResponse.escolaridad.id,
                        escolaridadNom: res.data.dataResponse.escolaridad.escolaridadNom,
                        gradoEscolar: res.data.dataResponse.gradoEscolar,
                        promedio: res.data.dataResponse.promedio,
                        depEconDiscapacidad: (res.data.dataResponse.discapacidad.id === 0) ? 'No' : 'Sí',
                        discapacidad: res.data.dataResponse.discapacidad.id,
                        nivelDisc: res.data.dataResponse.discapacidad.nivel,
                        nombreDisc: res.data.dataResponse.nombreDisc
                    };
                    const newDepEcon = depEconomicosSaved.filter(element => element.id && (element.id !== info.id));
                    setDepEconomicosSaved([ ...newDepEcon, info ]);
                    setElementoSeleccionadoDepEcon(null);
                    setMostrarFormAddDepEconEdit(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion) })
        .finally(() => setIsLoading(false));
    };

    const agregarEmpleadoDepEcon = () => {
        setIsLoading(true);    
        axios.post(`${servicios_grupohit_rh}/empleado/dependiente/economico/`, depEconomicosSavedEdit, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = res.data.dataResponse.map(res => (
                        {
                            id: res.id,
                            nombre: res.nombre,
                            apellidoPat: res.apellidoPat,
                            apellidoMat: res.apellidoMat,
                            curp: res.curp,
                            fechaNac: res.fechaNac,
                            parentesco: res.parentesco.id,
                            parentescoNom: res.parentesco.parentescoNom,
                            escolaridad: res.escolaridad.id,
                            escolaridadNom: res.escolaridad.escolaridadNom,
                            gradoEscolar: res.gradoEscolar,
                            promedio: res.promedio,
                            depEconDiscapacidad: (res.discapacidad.id === 0) ? 'No' : 'Sí',
                            discapacidad: res.discapacidad.id,
                            nivelDisc: res.discapacidad.nivel,
                            nombreDisc: res.nombreDisc
                        }
                    ));
                    setDepEconomicosSaved(depEconomicosSaved.concat(info));
                    setDepEconomicosSavedEdit([]);
                    setElementoSeleccionadoDepEcon(null);
                    setMostrarFormAddDepEconEdit(false);
                    break;
                default:
                    errorTable(`Dependientes Económicos ${res.data.msg}`);
                    break;
            }
        })
        .catch(error => errorTable(`Dependientes Económicos ${registro_no_guardado}`))
        .finally(() => setIsLoading(false));
    };

    const actualizarEmpleadoPensionAlim = (info, id) => {
        setIsLoading(true);
        axios.put(`${servicios_grupohit_rh}/empleado/pension/${id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info =
                        {
                            id: res.data.dataResponse.id,
                            folio: res.data.dataResponse.folio,
                            nombre: res.data.dataResponse.nombre,
                            apellidoPat: res.data.dataResponse.apellidoPat,
                            apellidoMat: res.data.dataResponse.apellidoMat,
                            tipoCuota: res.data.dataResponse.tipoCuota,
                            tipoCuotaNom: (res.data.dataResponse.tipoCuota === 'I') ? 'IMPORTE' : 'PORCENTAJE',
                            montoPorc: parseFloat(res.data.dataResponse.montoPorc),
                            fechaIni: res.data.dataResponse.fechaIni,
                            fechaFin: res.data.dataResponse.fechaFin,
                            prioridad: res.data.dataResponse.prioridad
                        };
                    const newPenAlim = pensionesAlimSaved.filter(element => element.id && (element.id !== info.id));
                    setPensionesAlimSaved([...newPenAlim, info]);
                    setElementoSeleccionadoPensionAlim(null);
                    setPensionesAlimSavedEdit([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    const agregarEmpleadoPensionAlim = info => {
        setIsLoading(true);
        const pensiones = info.map(penAlim => (
            (penAlim.fechaFin)
            ?   {
                    folio: penAlim.folio,
                    empleado: penAlim.empleadoId,
                    nombre: penAlim.nombre,
                    apellidoPat: penAlim.apellidoPat,
                    apellidoMat: penAlim.apellidoMat,
                    tipoCuota: penAlim.tipoCuota,
                    montoPorc: penAlim.montoPorc,
                    fechaIni: penAlim.fechaIni,
                    fechaFin: penAlim.fechaFin,
                    prioridad: penAlim.prioridad
                }
            :   {
                    folio: penAlim.folio,
                    empleado: penAlim.empleadoId,
                    nombre: penAlim.nombre,
                    apellidoPat: penAlim.apellidoPat,
                    apellidoMat: penAlim.apellidoMat,
                    tipoCuota: penAlim.tipoCuota,
                    montoPorc: penAlim.montoPorc,
                    fechaIni: penAlim.fechaIni,
                    prioridad: penAlim.prioridad
                }
        ));

        axios.post(`${servicios_grupohit_rh}/empleado/pension/`, pensiones, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = res.data.dataResponse.map(res => (
                        {
                            id: res.id,
                            folio: res.folio,
                            nombre: res.nombre,
                            apellidoPat: res.apellidoPat,
                            apellidoMat: res.apellidoMat,
                            tipoCuota: res.tipoCuota,
                            tipoCuotaNom: (res.tipoCuota === 'I') ? 'IMPORTE' : 'PORCENTAJE',
                            montoPorc: parseFloat(res.montoPorc),
                            fechaIni: res.fechaIni,
                            fechaFin: res.fechaFin,
                            prioridad: res.prioridad
                        }
                    ));
                    setPensionesAlimSaved(pensionesAlimSaved.concat(info));
                    setPensionesAlimSavedEdit([]);
                    setElementoSeleccionadoPensionAlim(null);
                    setMostrarFormAddPenAlimEdit(false);
                    break;
                default:
                    errorTable(`Pensiones Alimenticias ${res.data.msg}`);
                    break;
            }
        })
        .catch(error => errorTable(`Pensiones Alimenticias ${registro_no_guardado}`))
        .finally(() => setIsLoading(false));
    };

    const actualizarEmpleadoNumEmer = (info, id) => {
        setIsLoading(true);
        axios.put(`${servicios_grupohit_rh}/empleado/llamada/emergencia/${id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info =
                        {
                            id: res.data.dataResponse.id,
                            nombre: res.data.dataResponse.nombre,
                            apellidoPat: res.data.dataResponse.apellidoPat,
                            apellidoMat: res.data.dataResponse.apellidoMat,
                            telefonoFijo: res.data.dataResponse.telefonoFijo,
                            telefonoCelular: res.data.dataResponse.telefonoCelular,
                            parentesco: res.data.dataResponse.parentesco.id,
                            parentescoNom: res.data.dataResponse.parentesco.parentescoNom,
                            calle: res.data.dataResponse.calle,
                            numeroInt: (res.data.dataResponse.numeroInt) ? res.data.dataResponse.numeroInt : '',
                            numeroExt: (res.data.dataResponse.numeroExt) ? res.data.dataResponse.numeroExt : '',
                            cp: res.data.dataResponse.cp.codigopCp,
                            numEmerColonia: res.data.dataResponse.cp.id,
                            coloniaNom: res.data.dataResponse.cp.codigopColonia,
                            coloniaId: res.data.dataResponse.cp.id,
                            numEmerCiudad: res.data.dataResponse.cp.ciudadId.ciudadNom,
                            numEmerEstado: res.data.dataResponse.cp.ciudadId.estadoId.estadoNom
                        };
                    const newNumEmer = llamadasEmergenciaSaved.filter(element => element.id && (element.id !== info.id));
                    setLlamadasEmergenciaSaved([...newNumEmer, info]);
                    setElementoSeleccionadoNumEmer(null);
                    setLlamadasEmergenciaSavedEdit([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    const agregarEmpleadoNumEmer = info => {
        setIsLoading(true);
        const numEmergencia = info.map(numEme => (
            (numEme.calle && numEme.numeroExt && numEme.numeroInt)
            ?   {
                    empleado: numEme.empleadoId,
                    nombre: numEme.nombre,
                    apellidoPat: numEme.apellidoPat,
                    apellidoMat: numEme.apellidoMat,
                    telefonoFijo: numEme.telefonoFijo,
                    telefonoCelular: numEme.telefonoCelular,
                    parentesco: numEme.parentesco,
                    cp: numEme.coloniaId,
                    calle: numEme.calle,
                    numeroExt: numEme.numeroExt,
                    numeroInt: numEme.numeroInt
                }
            :   (numEme.calle && numEme.numeroExt)
                ?   {
                        empleado: numEme.empleadoId,
                        nombre: numEme.nombre,
                        apellidoPat: numEme.apellidoPat,
                        apellidoMat: numEme.apellidoMat,
                        telefonoFijo: numEme.telefonoFijo,
                        telefonoCelular: numEme.telefonoCelular,
                        parentesco: numEme.parentesco,
                        cp: numEme.coloniaId,
                        calle: numEme.calle,
                        numeroExt: numEme.numeroExt
                    }
                :   (numEme.calle)
                    ?   {
                            empleado: numEme.empleadoId,
                            nombre: numEme.nombre,
                            apellidoPat: numEme.apellidoPat,
                            apellidoMat: numEme.apellidoMat,
                            telefonoFijo: numEme.telefonoFijo,
                            telefonoCelular: numEme.telefonoCelular,
                            parentesco: numEme.parentesco,
                            cp: numEme.coloniaId,
                            calle: numEme.calle
                        }
                    :   {
                            empleado: numEme.empleadoId,
                            nombre: numEme.nombre,
                            apellidoPat: numEme.apellidoPat,
                            apellidoMat: numEme.apellidoMat,
                            telefonoFijo: numEme.telefonoFijo,
                            telefonoCelular: numEme.telefonoCelular,
                            parentesco: numEme.parentesco,
                            cp: numEme.coloniaId
                        }
        ));

        axios.post( `${servicios_grupohit_rh}/empleado/llamada/emergencia/`, numEmergencia, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = res.data.dataResponse.map(res => (
                        {
                            id: res.id,
                            nombre: res.nombre,
                            apellidoPat: res.apellidoPat,
                            apellidoMat: res.apellidoMat,
                            telefonoFijo: res.telefonoFijo,
                            telefonoCelular: res.telefonoCelular,
                            parentesco: res.parentesco.id,
                            parentescoNom: res.parentesco.parentescoNom,
                            calle: res.calle,
                            numeroInt: (res.numeroInt) ? res.numeroInt : '',
                            numeroExt: (res.numeroExt) ? res.numeroExt : '',
                            cp: res.cp.codigopCp,
                            numEmerColonia: res.cp.id,
                            coloniaNom: res.cp.codigopColonia,
                            coloniaId: res.cp.id,
                            numEmerCiudad: res.cp.ciudadId.ciudadNom,
                            numEmerEstado: res.cp.ciudadId.estadoId.estadoNom
                        }
                    ));
                    setLlamadasEmergenciaSaved(llamadasEmergenciaSaved.concat(info));
                    setLlamadasEmergenciaSavedEdit([]);
                    setElementoSeleccionadoNumEmer(null);
                    setMostrarFormAddNumEmerEdit(false);
                    break;
                default:
                    errorTable(`Números de Emergencia ${res.data.msg}`);
                    break;
            }
        })
        .catch(error => errorTable(`Números de Emergencia ${registro_no_guardado}`))
        .finally(() => setIsLoading(false));
    };
    
    const actualizarEmpleadoExpLab = info => {
        setIsLoading(true);
        axios.put(`${servicios_grupohit_rh}/empleado/experiencia/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const newExpLab = expLaboralSaved.filter(element => element.id && (element.id !== info.id));
                    setExpLaboralSaved([ ...newExpLab, info ]);
                    setElementoSeleccionadoExpLaboral(null);
                    setMostrarFormAddExpLaboralEdit(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion) })
        .finally(() => setIsLoading(false));
    };

    const agregarEmpleadoExpLab = info => {
        setIsLoading(true);
        const agregaIDEmpleado = expLab => {
            const arrayExpLaboral = expLab.map(expL => (
                    {
                        empId: elementSelect.id,
                        empNom: expL.empNom,
                        puestoExp: expL.puestoExp,
                        dmes: expL.dmes,
                        danio: expL.danio,
                        hmes: expL.hmes,
                        hanio: expL.hanio,
                        jefe: expL.jefe,
                        tel: expL.tel
                    }                        
                ));
            
            return arrayExpLaboral;
        };

        const request = agregaIDEmpleado(info);
        axios.post(`${servicios_grupohit_rh}/empleado/experiencia/`, { empleados: request }, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    setExpLaboralSaved(expLaboralSaved.concat(info));
                    setExpLaboralSavedEdit([]);
                    setElementoSeleccionadoExpLaboral(null);
                    setMostrarFormAddExpLaboralEdit(false);
                    break;
                default:
                    errorTable(`Experiencia Laboral ${res.data.msg}`);
                    break;
            }
        })
        .catch(error => errorTable(`Experiencia Laboral ${registro_no_guardado}`))
        .finally(() => setIsLoading(false));
    };

    const actualizarEmpleadoPasatiempo = (info, array) => {
        setIsLoading(true);
        axios.put(`${servicios_grupohit_rh}/empleado/pasatiempo/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    setPasatiempoSaved(array);
                    setElementoSeleccionadoPasatiempo(null);
                    setMostrarFormAddPasatiempoEdit(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion) })
        .finally(() => setIsLoading(false));
    };

    const agregarEmpleadoPasatiempo = info => {
        setIsLoading(true);
        const convierteArray = pasatiempos => {
            return pasatiempos.map(pasa => pasa.id); 
        };

        const arrayPasatiempos = convierteArray(info);

        const request = { empId: elementSelect.id, pasatiempos: arrayPasatiempos };
        axios.post(`${servicios_grupohit_rh}/empleado/pasatiempo/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    setPasatiempoSaved(pasatiempoSaved.concat(info));
                    setPasatiempoSavedEdit([]);
                    setElementoSeleccionadoPasatiempo(null);
                    setMostrarFormAddPasatiempoEdit(false);
                    break;
                default:
                    errorTable(`Pasatiempos ${res.data.msg}`);
                    break;
            }
        })
        .catch(error => errorTable(`Pasatiempos ${registro_no_guardado}`))
        .finally(() => setIsLoading(false));
    };

    const actualizar = () => {
        setError(null);
        let infoEmp = {};
            
        if(
            folioEmpleadoRef.current.value !== ((infoEmpleado.empleado.folioAlta) ? String(infoEmpleado.empleado.folioAlta).toUpperCase() : '') ||
            equipoEmpleadoRef.current.value !== ((infoEmpleado.empleado.equipo) ? infoEmpleado.empleado.equipo.toUpperCase() : '') ||
            esMadre !== infoEmpleado.empleado.esMadre ||
            numEmpleadoRef.current.value !== infoEmpleado.empleado.empleadoclave ||
            nombreRef.current.value !== infoEmpleado.empleado.empleadonom ||
            pApellidoRef.current.value !== (infoEmpleado.empleado.empleadopat ? infoEmpleado.empleado.empleadopat : '') ||
            sApellidoRef.current.value !== (infoEmpleado.empleado.empleadomat ? infoEmpleado.empleado.empleadomat : '') ||
            fechaNacimientoRef.current.value !== longDateToInput(infoEmpleado.empleado.empleadofecnac) ||
            parseInt(lNacimientoRef.current.value) !== infoEmpleado.empleado.estado.id ||
            generoRef.current.value !== (infoEmpleado.empleado.empleadoGenero ? infoEmpleado.empleado.empleadoGenero : '') ||
            parseInt(estadoCivilRef.current.value) !== infoEmpleado.empleado.edoCivil.id ||
            rfcRef.current.value !== (infoEmpleado.empleado.empleadorfc ? infoEmpleado.empleado.empleadorfc : '') ||
            curpRef.current.value !== (infoEmpleado.empleado.empleadocurp ? infoEmpleado.empleado.empleadocurp : '') ||
            nssRef.current.value !== (infoEmpleado.empleado.empleadonss ? infoEmpleado.empleado.empleadonss : '')
        ) {
            infoEmp = {
                id: elementSelect.id,
                empleadoclave: numEmpleadoRef.current.value,
                empleadonom: nombreRef.current.value,
                empleadofecnac: inputDateToLong(fechaNacimientoRef.current.value),
                edoCivil: parseInt(estadoCivilRef.current.value),
                estado: parseInt(lNacimientoRef.current.value),
                empleadopat: pApellidoRef.current.value,
                empleadomat: sApellidoRef.current.value,
                empleadoFolio: (folioEmpleadoRef.current.value.trim() === '') ? null : folioEmpleadoRef.current.value.trim(),
                empleadoEquipo: (equipoEmpleadoRef.current.value.trim() === '') ? null : equipoEmpleadoRef.current.value.trim(),
                empleadoMadre: esMadre
            };

            if(curpRef.current.value !== '') infoEmp = { ...infoEmp, empleadocurp: curpRef.current.value };
            if(rfcRef.current.value !== '') infoEmp = { ...infoEmp, empleadorfc: rfcRef.current.value };
            if(nssRef.current.value !== '') infoEmp = { ...infoEmp, empleadonss: nssRef.current.value };
            if(generoRef.current.value !== '') infoEmp = { ...infoEmp, empleadoGenero: generoRef.current.value };

        } else { 
            infoEmp = null;
        }

        let infoEsc = { };
            
        if(
            parseInt(nivelEscolaridadRef.current.value) !== infoEscolaridad.escolaridad.id ||
            carreraRef.current.value !== (infoEscolaridad.empleadoCarrera ? infoEscolaridad.empleadoCarrera : '') ||
            cedProfesionalRef.current.value !== (infoEscolaridad.empleadoCedula ? infoEscolaridad.empleadoCedula : '')
        ) {
            infoEsc = {
                escolaridad: parseInt(nivelEscolaridadRef.current.value),
                empleado: elementSelect.id
            };

            if(carreraRef.current.value !== '') infoEsc = { ...infoEsc, empleadoCarrera: carreraRef.current.value };
            if(cedProfesionalRef.current.value !== '') infoEsc = { ...infoEsc, empleadoCedula: cedProfesionalRef.current.value };

        } else {
            infoEsc = null;
        }            
           
        const idEscolaridad = infoEscolaridad.id;
        setError(null);
        if(infoEmp !== null && infoEsc !== null && fotografiaEdit !== null) {
            actualizarEmpleado(infoEmp);
            actualizarEscolaridad(infoEsc, idEscolaridad);
            actualizarFotografia();
        } else if(infoEmp !== null && infoEsc !== null && fotografiaEdit === null) {
            actualizarEmpleado(infoEmp);
            actualizarEscolaridad(infoEsc, idEscolaridad);
        } else if(infoEmp === null && infoEsc !== null && fotografiaEdit !== null) {
            actualizarEscolaridad(infoEsc, idEscolaridad);
            actualizarFotografia();
        } else if(infoEmp === null && infoEsc !== null && fotografiaEdit === null) {
            actualizarEscolaridad(infoEsc, idEscolaridad);
        } else if(infoEmp !== null && infoEsc === null && fotografiaEdit !== null) {
            actualizarEmpleado(infoEmp);
            actualizarFotografia();
        } else if(infoEmp !== null && infoEsc === null && fotografiaEdit === null) {
            actualizarEmpleado(infoEmp);
        } else if(infoEmp === null && infoEsc === null && fotografiaEdit !== null) {
            actualizarFotografia();
        }
    };

    const actualizarDomicilio = (type) => {
        if(elementSelect) {
            setError(null);
    
            const empId = elementSelect.id; 
            const calle = calleRef.current.value;
            const numext = numExteriorRef.current.value;
            const numint = (numInteriorRef.current) ? numInteriorRef.current.value : null;
            const cpId = parseInt(coloniaRef.current.value);
            const estadoNom = estadoRef.current.value;
            const ciudadNom = ciudadRef.current.value;
            const cpNom = cpRef.current.value;
            const coloniaNom = coloniaRef.current.value;
    
            let info = {
                empId, calle, numext, cpId
            };
    
            let infoAdic = {
                estadoNom, ciudadNom, cpNom, coloniaNom
            };
    
            if(numint !== '' && numint !== null) info = { ...info, numint };
    
            if(type === 'Agregar') {
                if(validarSubmit('FormEmpleadoDomicilio')) {
                    agregarEmpleadoDomicilio(info, infoAdic);
                }
                return;
            } 
    
            if(type === 'Editar') {
                const id = infoDomicilio.id;
                info = { ...info, id};
    
                actualizarEmpleadoDomicilio(info, infoAdic);
                return;
            }
        }
    };

    const actualizarDepEcon = () => {
        if(validarSubmit("FormDependientesEconomicos")) {
            setError(null);
            const empleado = elementSelect.id; 
            const nombre = nombreDepEconomicoRef.current.value;
            const apellidoPat = (pApellidoDepEconomicoRef.current) ? pApellidoDepEconomicoRef.current.value : null;
            const apellidoMat = (sApellidoDepEconomicoRef.current) ? sApellidoDepEconomicoRef.current.value : null;
            const curp = (curpDepEconomicoRef.current) ? curpDepEconomicoRef.current.value : null;
            const fechaNac = inputDateToLong(fechaNacDepEconomicoRef.current.value);
            const parentesco = parseInt(parentescoDepEconommicoRef.current.value);
            const escolaridad = parseInt(escolaridadDepEconomicoRef.current.value);
            const gradoEscolar = (gradoEstudiosDepEconomicoRef.current) ? gradoEstudiosDepEconomicoRef.current.value : null;
            const promedio = (promedioDepEconomicoRef.current) ? promedioDepEconomicoRef.current.value : null;
            const discapacidad = (nivelDiscapacidadRef.current) ? parseInt(nivelDiscapacidadRef.current.value) : 0;
            const nombreDisc = (nombreDiscapacidadRef.current) ? nombreDiscapacidadRef.current.value : null;

            let info = {
                empleado, nombre, fechaNac, parentesco, escolaridad, discapacidad
            };

            if(apellidoPat !== null && apellidoPat !== '') info = { ...info, apellidoPat };
            if(apellidoMat !== null && apellidoMat !== '') info = { ...info, apellidoMat };
            if(curp !== null && curp !== '') info = { ...info, curp };
            if(gradoEscolar !== null && gradoEscolar !== '') info = { ...info, gradoEscolar: parseInt(gradoEscolar) };
            if(promedio !== null && promedio !== '') info = { ...info, promedio: parseFloat(promedio) };
            if(discapacidad !== 0 ) info = { ...info, nombreDisc };

            const id = elementoSeleccionadoDepEcon.id;
            
            actualizarEmpleadoDepEcon(info, id);
        }
    };

    const agregarDepEconEdit = () => {
        if(depEconomicosSavedEdit.length) {
            agregarEmpleadoDepEcon();
        } else {
            errorTable('No ha agregado algún Dependiente Económico para guardar');
        }
    };

    const actualizarPensionAlim = () => {
        if(validarSubmit("FormPensionesAlimenticias")) {
            setError(null);
            const empleado = elementSelect.id;
            const folio = folioPensionAlimRef.current.value;
            const nombre = nombrePensionAlimRef.current.value;
            const apellidoPat = (pApellidoPensionAlimRef.current) ? pApellidoPensionAlimRef.current.value : null;
            const apellidoMat = (sApellidoPensionAlimRef.current) ? sApellidoPensionAlimRef.current.value : null;
            const tipoCuota = tipoCuotaPensionAlimRef.current.value;
            const montoPorc = (tipoCuotaPensionAlimRef.current.value === 'I') ? amountToFloat(montoPensionAlimRef.current.value) : parseFloat(montoPensionAlimRef.current.value);
            const fechaIni = inputDateToLong(fechaInicioPensionAlimRef.current.value);
            const fechaFin = (fechaFinalPensionAlimRef.current) ? inputDateToLong(fechaFinalPensionAlimRef.current.value) : null;
            const prioridad = Number(prioridadPensionAlimRef.current.value);

            let info = {
                empleado, folio, nombre, tipoCuota, montoPorc, fechaIni, prioridad
            };

            if(apellidoPat !== null && apellidoPat !== '') info = { ...info, apellidoPat };
            if(apellidoMat !== null && apellidoMat !== '') info = { ...info, apellidoMat };
            if(fechaFin !== null && fechaFin !== '') info = { ...info, fechaFin };

            const id = elementoSeleccionadoPensionAlim.id;

            actualizarEmpleadoPensionAlim(info, id)
        }
    };

    const agregarPensionAlimEdit = () => {
        if(pensionesAlimSavedEdit.length) {
            agregarEmpleadoPensionAlim(pensionesAlimSavedEdit);
        } else {
            errorTable('No ha agregado alguna Pensión Alimenticia para guardar');
        }
    };

    const actualizarNumEmer = () => {
        if(validarSubmit('FormNumerosEmergencia')) {
            setError(null);
            const empleado = elementSelect.id;
            const nombre = nombreNumEmergenciaRef.current.value;
            const apellidoPat = (pApellidoNumEmergenciaRef.current) ? pApellidoNumEmergenciaRef.current.value : null;
            const apellidoMat = (sApellidoNumEmergenciaRef.current) ? sApellidoNumEmergenciaRef.current.value : null;
            const telefonoFijo = (telFijoNumEmergenciaRef.current.value) ? telFijoNumEmergenciaRef.current.value : null;
            const telefonoCelular = (telMovilNumEmergenciaRef.current.value) ? telMovilNumEmergenciaRef.current.value : null;
            const parentesco = parseInt(parentescoNumEmergenciaRef.current.value);
            const cp = parseInt(coloniaNumEmergenciaRef.current.value);
            const calle = calleNumEmergenciaRef.current.value;
            const numeroExt = (numExteriorNumEmergenciaRef.current) ? numExteriorNumEmergenciaRef.current.value : null;
            const numeroInt = (numInteriorNumEmergenciaRef.current) ? numInteriorNumEmergenciaRef.current.value : null;

            let info = {
                empleado, nombre, parentesco, cp, calle
            };

            if(apellidoPat !== null && apellidoPat !== '') info = { ...info, apellidoPat };
            if(apellidoMat !== null && apellidoMat !== '') info = { ...info, apellidoMat };
            if(telefonoFijo !== null && telefonoFijo !== '') info = { ...info, telefonoFijo };
            if(telefonoCelular !== null && telefonoCelular !== '') info = { ...info, telefonoCelular };
            if(numeroExt !== null && numeroExt !== '') info = { ...info, numeroExt };
            if(numeroInt !== null && numeroInt !== '') info = { ...info, numeroInt };

            const id = elementoSeleccionadoNumEmer.id;

            actualizarEmpleadoNumEmer(info, id);
        }
    };

    const agregarNumEmerEdit = () => {
        if(llamadasEmergenciaSavedEdit.length) {
            agregarEmpleadoNumEmer(llamadasEmergenciaSavedEdit);
        } else {
            errorTable('No ha agregado algún Contacto de Emergencia para guardar');
        }
    };

    const actualizarExpLaboral = () => {
        if(validarSubmit("FormEmpleadoExpLaboral")) {
            setError(null);
            const id = elementoSeleccionadoExpLaboral.id;
            const empId = elementSelect.id
            const empNom = empresaExpLabRef.current.value;
            const puestoExp = puestoExpLabRef.current.value;
            const dmes = parseInt(desdeMesExpLabRef.current.value);
            const danio = parseInt(desdeAnioExpLabRef.current.value);
            const hmes = parseInt(hastaMesExpLabRef.current.value);
            const hanio = parseInt(hastaAnioExpLabRef.current.value);
            const jefe = nombreJefeExpLabRef.current.value;
            const tel = telefonoExpLabRef.current.value;

            let info = { id, empId, empNom, puestoExp, dmes, danio, hmes, hanio, jefe, tel };

            actualizarEmpleadoExpLab(info);
        }
    };

    const agregarExpLaboralEdit = () => {
        if(expLaboralSavedEdit.length) {
            agregarEmpleadoExpLab(expLaboralSavedEdit);
        } else {
            errorTable('No ha agregado alguna Experiencia Laboral para guardar');
        }
    };

    const actualizarPasatiempo = (newElement, val) => {
        const convierteArray = pasatiempos => {
            return pasatiempos.map(pasa => pasa.id); 
        };

        if(val === 1) {
            if(validarSubmit("FormEmpleadoPasatiempos")) {
                setError(null);

                const posicion = pasatiempoSaved.indexOf(elementoSeleccionadoPasatiempo);
                let newPasatiempos = pasatiempoSaved;
                newPasatiempos.splice(posicion, 1, newElement);
                const arrayPasatiempos = convierteArray(newPasatiempos);
                const empId = elementSelect.id;

                const info = { empId, pasatiempos: arrayPasatiempos };
                
                actualizarEmpleadoPasatiempo(info, newPasatiempos);
            }
        } else {
            setError(null);

            const arrayPasatiempos = convierteArray(newElement);
            const empId = elementSelect.id;

            const info = { empId, pasatiempos: arrayPasatiempos };
            
            actualizarEmpleadoPasatiempo(info, newElement);
        }
    };

    const agregarPasatiempoEdit = () => {
        if(pasatiempoSavedEdit.length) {
            agregarEmpleadoPasatiempo(pasatiempoSavedEdit);
        } else {
            errorTable('No ha agregado algún Pasatiempo para guardar');
        }
    };

    const irInfo = (e, pag) => {
        e.preventDefault();
        setError(null);
        switch(active) {
            case 2:
                if(formaPagoRef.current.value !== '') {
                    setOpenValue2(pag);
                    setOpenAlert2(true);
                    return;
                }
                if(elementSelect && (modulo === 30)) {
                    const validaDisabled = () => {
                        if(
                            !disabledPlaza ||
                            !disabledFormOrgEdit.zonaPago ||
                            !disabledPuesto ||
                            !disabledFormOrgEdit.turno ||
                            !disabledNomina
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }

                    const validaCampos = () => {
                        if(
                            (infoContrato.contrato.id !== parseInt(contratoRef.current.value)) ||
                            (longDateToInput(infoContrato.fechaini) !== fechaInicialContratoRef.current.value) ||
                            ((infoContrato.fechafin) ? longDateToInput(infoContrato.fechafin) : null) !== ((fechaFinalContratoRef.current) ? (fechaFinalContratoRef.current.value !== '') ? fechaFinalContratoRef.current.value : null : null) ||
                            ((infoAguinaldo.fechaAguinaldo) ? longDateToInput(infoAguinaldo.fechaAguinaldo) : null) !== ((fechaAguinaldoRef.current) ? (fechaAguinaldoRef.current.value !== '') ? fechaAguinaldoRef.current.value : null : null) ||
                            ((infoNombramiento.fechaNombramiento) ? longDateToInput(infoNombramiento.fechaNombramiento) : null) !== ((fechaNombramientoRef.current) ? (fechaNombramientoRef.current.value !== '') ? fechaNombramientoRef.current.value : null : null) ||
                            (infoRegPatronal.regPatronal.id !== (regPatronalRef.current ? (regPatronalRef.current.value === '' ? '' : parseInt(regPatronalRef.current.value)) : '')) ||
                            (infoSindicato.sindicato.id !== sindicatoSeleccionado.id) ||
                            (infoSindicato.aplica !== cuotaSindical) ||
                            (infoMinLiquidez !== minLiquidez) ||
                            ((infoTitularPlaza === 'no') ? 'no' : 'si') !== titularPlaza
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    };

                    if(formasPagoSelected.length > 0 && indicadoresSelected.length > 0) {
                        if(validaDisabled() && validaCampos()) {
                            setActive(pag);
                        } else {
                            setOpenValue2(pag);
                            setOpenAlert2(true);
                        }
                    } else {
                        if(!formasPagoSelected.length) {
                            setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                        }
                        if(!indicadoresSelected.length) {
                            setErrorConceptoPago(<AlertForm message="No ha seleccionado algún concepto de pago" />);
                        }
                    }
                } else {
                    if(pag === 1) {
                        setActive(pag);
                    } else {
                        if(validarSubmit('OrganizacionEmpleado')) {
                            if(
                                selected.cPresupuestal.id !== '' &&
                                selected.departamento.id !== '' &&
                                selected.plaza.id !== '' &&
                                selected.puesto.id !== ''
                            ) {
                                if(formasPagoSelected.length > 0 && indicadoresSelected.length > 0) {
                                    setActive(pag);
                                } else {
                                    validaAutocompleteOrganizacion();
                                    if(!formasPagoSelected.length) {
                                        setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                                    }
                                    if(!indicadoresSelected.length) {
                                        setErrorConceptoPago(<AlertForm message="No ha seleccionado algún concepto de pago" />);
                                    }
                                }
                            } else {
                                validaAutocompleteOrganizacion();
                                if(!formasPagoSelected.length) {
                                    setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                                }
                                if(!indicadoresSelected.length) {
                                    setErrorConceptoPago(<AlertForm message="No ha seleccionado algún concepto de pago" />);
                                }
                            }
                        } else {
                            validaAutocompleteOrganizacion();
                            if(!formasPagoSelected.length) {
                                setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                            }
                            if(!indicadoresSelected.length) {
                                setErrorConceptoPago(<AlertForm message="No ha seleccionado algún concepto de pago" />);
                            }
                        }
                    }
                }
                break;
            case 3:
                if(elementSelect && (modulo === 30)) {
                    if(
                        calleRef.current.value !== ((infoDomicilio.calle) ? infoDomicilio.calle : '') ||
                        numExteriorRef.current.value !== ((infoDomicilio.numExt) ? infoDomicilio.numExt : '') ||
                        numInteriorRef.current.value !== ((infoDomicilio.numInt) ? infoDomicilio.numInt : '' ) ||
                        cpRef.current.value !== ((infoDomicilio.codPostal) ? infoDomicilio.codPostal.codigopCp : '') ||
                        (isNaN(parseInt(coloniaRef.current.value)) ? '' : parseInt(coloniaRef.current.value)) !== ((infoDomicilio.codPostal) ? infoDomicilio.codPostal.codigopId : '')
                    ) {
                        setOpenValue3(pag);
                        setOpenAlert3(true);
                    } else {
                        setActive(pag);
                    }
                } else {
                    if(
                        calleRef.current.value !== '' ||
                        numExteriorRef.current.value !== '' ||
                        numInteriorRef.current.value !== '' ||
                        cpRef.current.value !== '' ||
                        coloniaRef.current.value !== ''
                    ) {
                        if(validarSubmit("FormEmpleadoDomicilio")) {
                            setActive(pag);
                        } else {
                            setOpenValue3(pag);
                            setOpenAlert3(true);
                        }
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 4:
                const difElementosDepEcon = () => {
                    if(
                        nombreDepEconomicoRef.current.value !== elementoSeleccionadoDepEcon.nombre ||
                        pApellidoDepEconomicoRef.current.value !== (elementoSeleccionadoDepEcon.apellidoPat ? elementoSeleccionadoDepEcon.apellidoPat : '') ||
                        sApellidoDepEconomicoRef.current.value !== (elementoSeleccionadoDepEcon.apellidoMat ? elementoSeleccionadoDepEcon.apellidoMat : '') ||
                        curpDepEconomicoRef.current.value !== elementoSeleccionadoDepEcon.curp ||
                        inputDateToLong(fechaNacDepEconomicoRef.current.value) !== elementoSeleccionadoDepEcon.fechaNac ||
                        parseInt(parentescoDepEconommicoRef.current.value) !== elementoSeleccionadoDepEcon.parentesco ||
                        parseInt(escolaridadDepEconomicoRef.current.value) !== elementoSeleccionadoDepEcon.escolaridad ||
                        parseInt(gradoEstudiosDepEconomicoRef.current.value) !== elementoSeleccionadoDepEcon.gradoEscolar ||
                        parseFloat(promedioDepEconomicoRef.current.value) !== parseFloat(elementoSeleccionadoDepEcon.promedio) ||
                        ((nivelDiscapacidadRef.current) ? parseInt(nivelDiscapacidadRef.current.value) : 0) !== elementoSeleccionadoDepEcon.discapacidad ||
                        ((nombreDiscapacidadRef.current) ? parseInt(nombreDiscapacidadRef.current.value) : '') !== elementoSeleccionadoDepEcon.nombreDisc
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                };
                const validaElementoDifVacioDepEcon = () => {
                    if(nombreDepEconomicoRef.current) {
                        if(
                            nombreDepEconomicoRef.current.value !== '' ||
                            pApellidoDepEconomicoRef.current.value !== '' ||
                            sApellidoDepEconomicoRef.current.value !== '' ||
                            curpDepEconomicoRef.current.value !== '' ||
                            fechaNacDepEconomicoRef.current.value !== '' ||
                            parentescoDepEconommicoRef.current.value !== '' ||
                            escolaridadDepEconomicoRef.current.value !== '' ||
                            gradoEstudiosDepEconomicoRef.current.value !== '' ||
                            promedioDepEconomicoRef.current.value !== '' ||
                            mostrarDiscapacidad !== false
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                };
                if(elementSelect && (modulo === 30)) {
                    if(depEconomicosSaved.length > 0) {
                        if(elementoSeleccionadoDepEcon) {
                            if(difElementosDepEcon()) {
                                setOpenValue4(pag);
                                setOpenAlert4(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                            
                            if(depEconomicosSavedEdit.length > 0) {
                                setOpenValue5(pag);
                                setOpenAlert5(true);
                            } else {
                                if(validaElementoDifVacioDepEcon()) {
                                    setOpenValue4(pag);
                                    setOpenAlert4(true);
                                } else {
                                    setActive(pag);
                                }
                            }
                        }
                    } else {
                        if(validaElementoDifVacioDepEcon()) {
                            setOpenValue4(pag);
                            setOpenAlert4(true);
                            return;
                        }

                        if(depEconomicosSavedEdit.length > 0) {
                            setOpenValue5(pag);
                            setOpenAlert5(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(validaElementoDifVacioDepEcon()) {
                        setOpenValue4(pag);
                        setOpenAlert4(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 5:
                const difElementosPenAlim = () => {
                    if(
                        (folioPensionAlimRef.current.value !== elementoSeleccionadoPensionAlim.folio) ||
                        (nombrePensionAlimRef.current.value !== elementoSeleccionadoPensionAlim.nombre) ||
                        (pApellidoPensionAlimRef.current.value !== (elementoSeleccionadoPensionAlim.apellidoPat ? elementoSeleccionadoPensionAlim.apellidoPat : '')) ||
                        (sApellidoPensionAlimRef.current.value !== (elementoSeleccionadoPensionAlim.apellidoMat ? elementoSeleccionadoPensionAlim.apellidoMat : '')) ||
                        (parseInt(tipoCuotaPensionAlimRef.current.value) !== elementoSeleccionadoPensionAlim.tipoCuota) ||
                        (((tipoCuotaPensionAlimRef.current.value === 'I') ? amountToFloat(montoPensionAlimRef.current.value) : parseFloat(montoPensionAlimRef.current.value)) !== parseFloat(elementoSeleccionadoPensionAlim.montoPorc)) ||
                        (inputDateToLong(fechaInicioPensionAlimRef.current.value) !== elementoSeleccionadoPensionAlim.fechaIni) ||
                        (inputDateToLong(fechaFinalPensionAlimRef.current.value) !== (elementoSeleccionadoPensionAlim.fechaFin ? elementoSeleccionadoPensionAlim.fechaFin : ''))
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                };

                const validaElementoDifVacioPenAlim = () => {
                    if(folioPensionAlimRef.current) {
                        if(
                            folioPensionAlimRef.current.value !== '' ||
                            nombrePensionAlimRef.current.value !== '' ||
                            pApellidoPensionAlimRef.current.value !== '' ||
                            sApellidoPensionAlimRef.current.value !== '' ||
                            tipoCuotaPensionAlimRef.current.value !== '' ||
                            montoPensionAlimRef.current.value !== '' ||
                            fechaInicioPensionAlimRef.current.value !== '' ||
                            fechaFinalPensionAlimRef.current.value !== ''
                        ) {
                            return true;
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                };

                if(elementSelect && (modulo === 30)) {
                    if(pensionesAlimSaved.length > 0) {
                        if(elementoSeleccionadoPensionAlim) {
                            if(difElementosPenAlim()) {
                                setOpenValue6(pag);
                                setOpenAlert6(true);
                            } else {
                                setActive(pag);
                            }
                        } else {
                            if(pensionesAlimSavedEdit.length > 0) {
                                setOpenValue7(pag);
                                setOpenAlert7(true);
                            } else {
                                if(validaElementoDifVacioPenAlim()) {
                                    setOpenValue6(pag);
                                    setOpenAlert6(true);
                                } else {
                                    setActive(pag);
                                }
                            }
                        }
                    } else {
                        if(validaElementoDifVacioPenAlim()) {
                            setOpenValue6(pag);
                            setOpenAlert6(true);
                            return;
                        }

                        if(pensionesAlimSavedEdit.length > 0) {
                            setOpenValue7(pag);
                            setOpenAlert7(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(validaElementoDifVacioPenAlim()) {
                        setOpenValue6(pag);
                        setOpenAlert6(true);
                    } else {
                        setActive(pag);
                    }
                }

                break;
            case 6:
                const difElementosNumEmer = () => {
                    if(
                        nombreNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.nombre ||
                        pApellidoNumEmergenciaRef.current.value !== (elementoSeleccionadoNumEmer.apellidoPat ? elementoSeleccionadoNumEmer.apellidoPat : '') || 
                        sApellidoNumEmergenciaRef.current.value !== (elementoSeleccionadoNumEmer.apellidoMat ? elementoSeleccionadoNumEmer.apellidoMat : '' ) ||
                        telFijoNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.telefonoFijo ||
                        telMovilNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.telefonoCelular ||
                        parseInt(parentescoNumEmergenciaRef.current.value) !== elementoSeleccionadoNumEmer.parentesco ||
                        calleNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.calle ||
                        numExteriorNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.numeroExt ||
                        numInteriorNumEmergenciaRef.current.value !== (elementoSeleccionadoNumEmer.numeroInt ? elementoSeleccionadoNumEmer.numeroInt : '') ||
                        cpNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.cp ||
                        parseInt(coloniaNumEmergenciaRef.current.value) !== elementoSeleccionadoNumEmer.coloniaId
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                };

                const validaElementoDifVacioNumEmer = () => {
                    if(nombreNumEmergenciaRef.current) {
                        if(
                            nombreNumEmergenciaRef.current.value !== '' ||
                            pApellidoNumEmergenciaRef.current.value !== '' ||
                            sApellidoNumEmergenciaRef.current.value !== '' ||
                            telFijoNumEmergenciaRef.current.value !== '' ||
                            telMovilNumEmergenciaRef.current.value !== '' ||
                            parentescoNumEmergenciaRef.current.value !== '' ||
                            calleNumEmergenciaRef.current.value !== '' ||
                            numExteriorNumEmergenciaRef.current.value !== '' ||
                            numInteriorNumEmergenciaRef.current.value !== '' ||
                            cpNumEmergenciaRef.current.value !== '' ||
                            coloniaNumEmergenciaRef.current.value !== ''
                        ) {
                            return true;
                        } else{
                            return false;
                        }
                    } else {
                        return false;
                    }
                };

                if(elementSelect && (modulo === 30)) {
                    if(llamadasEmergenciaSaved.length > 0) {
                        if(elementoSeleccionadoNumEmer) {
                            if(difElementosNumEmer()) {
                                setOpenValue8(pag);
                                setOpenAlert8(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                          
                            if(llamadasEmergenciaSavedEdit.length > 0) {
                                setOpenValue9(pag);
                                setOpenAlert9(true);
                            } else {
                                if(validaElementoDifVacioNumEmer()) {
                                    setOpenValue8(pag);
                                    setOpenAlert8(true);
                                } else {
                                    setActive(pag);
                                }
                            }
                        }
                    } else {
                        if(validaElementoDifVacioNumEmer()) {
                            setOpenValue8(pag);
                            setOpenAlert8(true);
                            return;
                        }

                        if(llamadasEmergenciaSavedEdit.length > 0) {
                            setOpenValue9(pag);
                            setOpenAlert9(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(validaElementoDifVacioNumEmer()) {
                        setOpenValue8(pag);
                        setOpenAlert8(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 7:
                const difElementosExpLab = () => {
                    if(
                        empresaExpLabRef.current.value !== elementoSeleccionadoExpLaboral.empNom ||
                        puestoExpLabRef.current.value !== elementoSeleccionadoExpLaboral.puestoExp ||
                        parseInt(desdeMesExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.dmes ||
                        parseInt(desdeAnioExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.danio ||
                        parseInt(hastaMesExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.hmes ||
                        parseInt(hastaAnioExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.hanio ||
                        nombreJefeExpLabRef.current.value !== elementoSeleccionadoExpLaboral.jefe ||
                        telefonoExpLabRef.current.value !== elementoSeleccionadoExpLaboral.tel
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                };

                const validaElementoDifVacioExpLab = () => {
                    if(empresaExpLabRef.current) {
                        if( 
                            empresaExpLabRef.current.value !== '' ||
                            puestoExpLabRef.current.value !== '' ||
                            desdeMesExpLabRef.current.value !== '' ||
                            desdeAnioExpLabRef.current.value !== '' ||
                            hastaMesExpLabRef.current.value !== '' ||
                            hastaAnioExpLabRef.current.value !== '' ||
                            nombreJefeExpLabRef.current.value !== '' ||
                            telefonoExpLabRef.current.value !== ''
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                };

                if(elementSelect && (modulo === 30)) {                   
                    if(expLaboralSaved.length > 0) {
                        if(elementoSeleccionadoExpLaboral) {
                            if(difElementosExpLab()) {
                                setOpenValue10(pag);
                                setOpenAlert10(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                          
                            if(expLaboralSavedEdit.length > 0) {
                                setOpenValue11(pag);
                                setOpenAlert11(true);
                            } else {
                                if(validaElementoDifVacioExpLab()) {
                                    setOpenValue10(pag);
                                    setOpenAlert10(true);
                                } else {
                                    setActive(pag);
                                }
                            }
                        }
                    } else {
                        if(validaElementoDifVacioExpLab()) {
                            setOpenValue10(pag);
                            setOpenAlert10(true);
                            return;
                        }

                        if(expLaboralSavedEdit.length > 0) {
                            setOpenValue11(pag);
                            setOpenAlert11(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(validaElementoDifVacioExpLab()) {
                        setOpenValue10(pag);
                        setOpenAlert10(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 8:
                if(elementSelect && (modulo === 30)) {
                    if(pasatiempoSaved.length > 0) {
                        if(elementoSeleccionadoPasatiempo) {
                            if(pasatiempoRef.current.value !== elementoSeleccionadoPasatiempo.pasatiempo) {
                                setOpenValue12(pag);
                                setOpenAlert12(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                          
                            if(pasatiempoSavedEdit.length > 0) {
                                setOpenValue13(pag);
                                setOpenAlert13(true);
                            } else {
                                if(pasatiempoRef.current) {
                                    if(pasatiempoRef.current.value !== '') {
                                        setOpenValue12(pag);
                                        setOpenAlert12(true);
                                    } else {
                                        setActive(pag);
                                    }
                                } else {
                                    setActive(pag);
                                }
                            }
                        }
                    } else {
                        if(pasatiempoRef.current.value !== '') {
                            setOpenValue12(pag);
                            setOpenAlert12(true);
                            return;
                        }

                        if(pasatiempoSavedEdit.length > 0) {
                            setOpenValue13(pag);
                            setOpenAlert13(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(pasatiempoRef.current.value !== '') {
                        setOpenValue12(pag);
                        setOpenAlert12(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            default:
                break;
        }
    };

    const siguiente = (e, pag) => {
        e.preventDefault();
        if(validarSubmit('DatosPersonales')) { 
            setError(null);
            if(datosNUE === null) {
                if(elementSelect) {
                    const valida = (
                        ((numEmpleadoRef.current.value !== infoEmpleado.empleado.empleadoclave) === true) ||
                        ((folioEmpleadoRef.current.value !== ((infoEmpleado.empleado.folioAlta) ? String(infoEmpleado.empleado.folioAlta).toUpperCase() : '')) === true) ||
                        ((equipoEmpleadoRef.current.value !== ((infoEmpleado.empleado.equipo) ? infoEmpleado.empleado.equipo.toUpperCase() : '')) === true) ||
                        ((esMadre !== infoEmpleado.empleado.esMadre) === true) ||
                        ((nombreRef.current.value !== infoEmpleado.empleado.empleadonom.toUpperCase()) === true) ||
                        ((pApellidoRef.current.value !== ((infoEmpleado.empleado.empleadopat) ? infoEmpleado.empleado.empleadopat.toUpperCase() : '')) === true) ||
                        ((sApellidoRef.current.value !== ((infoEmpleado.empleado.empleadomat) ? infoEmpleado.empleado.empleadomat.toUpperCase() : '')) === true) ||
                        ((fechaNacimientoRef.current.value !== longDateToInput(infoEmpleado.empleado.empleadofecnac)) === true) ||
                        ((parseInt(lNacimientoRef.current.value) !== infoEmpleado.empleado.estado.id) === true) ||
                        ((generoRef.current.value !== ((infoEmpleado.empleado.empleadoGenero) ? infoEmpleado.empleado.empleadoGenero : '')) === true) ||
                        ((parseInt(estadoCivilRef.current.value) !== infoEmpleado.empleado.edoCivil.id) === true) ||
                        ((rfcRef.current.value !== ((infoEmpleado.empleado.empleadorfc) ? infoEmpleado.empleado.empleadorfc.toUpperCase() : '')) === true) ||
                        ((curpRef.current.value !== ((infoEmpleado.empleado.empleadocurp) ? infoEmpleado.empleado.empleadocurp.toUpperCase() : '')) === true) ||
                        ((nssRef.current.value !== ((infoEmpleado.empleado.empleadonss) ? infoEmpleado.empleado.empleadonss : '')) === true) ||
                        ((isNaN(parseInt(nivelEscolaridadRef.current.value)) ? '' : parseInt(nivelEscolaridadRef.current.value) !== infoEscolaridad.escolaridad.id) === true) || 
                        ((carreraRef.current.value !== ((infoEscolaridad.empleadoCarrera) ? infoEscolaridad.empleadoCarrera.toUpperCase() : '')) === true) ||
                        ((cedProfesionalRef.current.value !== ((infoEscolaridad.empleadoCedula) ? infoEscolaridad.empleadoCedula.toUpperCase() : '')) === true) ||
                        (fotografiaEdit !== null)
                    );

                    if(valida === true) {
                        setOpenAlert(true);
                    } else {
                        setActive(pag);
                    }
                } else {
                    setActive(pag);
                }
            } else {
                setOpenAlertNUE(true);
            }
        }
    };

    const muestraMadre = valor => {
        if(valor === 'F') {
            setMostrarMadre(true);
        } else {
            setMostrarMadre(false);
            setEsMadre(false);
        }
    };

    const siguienteEdit = () => {
        setTimeout(() => {
            setActive(2);
        }, 300);
    };

    return (
        <Fragment>
        {(!elementSelect || (infoEmpleado && infoEscolaridad))
        ?   <Fragment>
                <div id="form-part-1" style={{ display: (active === 1) ? 'block' : 'none' }}>
                    <FormDatosPersonales
                        idModulo={modulo}
                        elementSelect={elementSelect}
                        actualizar={actualizar}
                        verificaCambiosFormEmpleado={verificaCambiosFormEmpleado}
                        siguiente={siguiente}
                        nivelesEscolaridad={nivelesEscolaridad}
                        tiposEstadoCivil={tiposEstadoCivil}
                        numEmpleadoRef={numEmpleadoRef}
                        folioEmpleadoRef={folioEmpleadoRef}
                        equipoEmpleadoRef={equipoEmpleadoRef}
                        esMadre={esMadre}
                        setEsMadre={setEsMadre}
                        mostrarMadre={mostrarMadre}
                        muestraMadre={muestraMadre}
                        datosNUE={datosNUE}
                        nombreRef={nombreRef}
                        pApellidoRef={pApellidoRef}
                        sApellidoRef={sApellidoRef}
                        fechaNacimientoRef={fechaNacimientoRef}
                        generoRef={generoRef}
                        nivelEscolaridadRef={nivelEscolaridadRef}
                        curpRef={curpRef}
                        carreraRef={carreraRef}
                        nssRef={nssRef}
                        estadosNacimiento={estadosNacimiento}
                        lNacimientoRef={lNacimientoRef}
                        estadoCivilRef={estadoCivilRef}
                        rfcRef={rfcRef}
                        cedProfesionalRef={cedProfesionalRef}
                        fechaIngresoRef={fechaIngresoRef}
                        infoEmpleado={infoEmpleado}
                        infoEscolaridad={infoEscolaridad}
                        fechaInicialGobierno={fechaInicialGobierno}
                        fechaInicialContratoRef={fechaInicialContratoRef}
                        fechaFinalContratoRef={fechaFinalContratoRef}
                        fechaNombramientoRef={fechaNombramientoRef}
                        fechaAguinaldoRef={fechaAguinaldoRef}
                        setFotografia={setFotografia}
                        fotografia={fotografia}
                        fotografiaEdit={fotografiaEdit}
                        setFotografiaEdit={setFotografiaEdit}
                        verificaNUE={verificaNUE}
                        openAlertNUE={openAlertNUE}
                        setOpenAlertNUE={setOpenAlertNUE}
                        nueDisabled={nueDisabled}
                        setError={setError}
                        longitudCURP={longitudCURP}
                        longitudRFC={longitudRFC}
                        asignaFechaEdoNacGen={asignaFechaEdoNacGen}
                        lengthCURP={lengthCURP}
                        lengthRFC={lengthRFC}
                        isLoading={isLoading}
                    />
                </div>
                <div id="form-part-2" style={{ display: (active === 2) ? 'block' : 'none' }}>
                    <FormOrganizacionEmpleado
                        modulo={modulo}
                        active={active}
                        successTable={(successTable) ? successTable : null}
                        errorTable={(errorTable) ? errorTable : null}
                        irInfo={irInfo}
                        infoEmpleado={infoEmpleado}
                        actualizar={(elementSelect && (modulo === 32)) ? reingresar : actualizarOrganizacion}
                        enviar={enviar}
                        elementSelect={elementSelect}
                        selected={selected}
                        monto={monto}
                        montoEdit={montoEdit}
                        setMonto={setMonto}
                        setMontoEdit={setMontoEdit}
                        indicadores={indicadores}
                        enviarEmpleado={(!elementSelect) ? enviarEmpleado : undefined}
                        disabled={disabled}
                        disabledFormOrgEdit={disabledFormOrgEdit}
                        setDisabledFormOrgEdit={setDisabledFormOrgEdit}
                        idTurno={(elementSelect) ? infoEmpleado.turno.id : null}
                        infoContrato={infoContrato}
                        fechaAguinaldo={infoAguinaldo}
                        fechaNombramiento={infoNombramiento}
                        plazaDisponibleRango={plazaDisponibleRango}
                        setPlazaDisponilbeRango={setPlazaDisponilbeRango}
                        fechaInicialPlazaDispRangoRef={fechaInicialPlazaDispRangoRef}
                        fechaFinalPlazaDispRangoRef={fechaFinalPlazaDispRangoRef}
                        plazasRango={plazasRango}
                        setPlazasRango={setPlazasRango}
                        plazas={plazas}
                        validarPlaza={validarPlaza}
                        titularPlaza={titularPlaza}
                        setTitularPlaza={setTitularPlaza}
                        infoTitularPlaza={infoTitularPlaza}
                        validaAutocompleteOrganizacion={validaAutocompleteOrganizacion}
                        zonasPago={zonasPago}
                        zonaPagoRef={zonaPagoRef}
                        turnos={turnos}
                        turnoRef={turnoRef}
                        puestos={puestos}
                        sugerenciaPuesto={sugerenciaPuesto}
                        validarPuesto={validarPuesto}
                        muestraConceptos={muestraConceptos}
                        nominas={nominas}
                        nominaRef={nominaRef}
                        formaPagoRef={formaPagoRef}
                        formasPagoSelected={formasPagoSelected}
                        setFormasPagoSelected={setFormasPagoSelected}
                        setIndicadoresSelected={setIndicadoresSelected}
                        indicadoresSelected={indicadoresSelected}
                        bancoRef={bancoRef}
                        numCuentaRef={numCuentaRef}
                        cbeInterRef={cbeInterRef}
                        contratoRef={contratoRef}
                        retienePagos={retienePagos}
                        setRetienePagos={setRetienePagos}
                        fechaInicialContratoRef={fechaInicialContratoRef}
                        fechaFinalContratoRef={fechaFinalContratoRef}
                        fechaNombramientoRef={fechaNombramientoRef}
                        fechaAguinaldoRef={fechaAguinaldoRef}
                        fechaIngresoRef={fechaIngresoRef}
                        regPatronalRef={regPatronalRef}
                        infoRegPatronal={infoRegPatronal}
                        sindicatoRef={sindicatoRef}
                        sindicalizado={sindicalizado}
                        setSindicalizado={setSindicalizado}
                        cuotaSindical={cuotaSindical}
                        setCuotaSindical={setCuotaSindical}
                        sindicatoSeleccionado={sindicatoSeleccionado}
                        setSindicatoSeleccionado={setSindicatoSeleccionado}
                        infoSindicato={infoSindicato}
                        minLiquidez={minLiquidez}
                        setMinLiquidez={setMinLiquidez}
                        aplicarSubsidioEmpleo={aplicarSubsidioEmpleo}
                        setAplicarSubsidioEmpleo={setAplicarSubsidioEmpleo}
                        infoMinLiquidez={infoMinLiquidez}
                        setInfoMinLiquidez={setInfoMinLiquidez}
                        notaRef={notaRef}
                        notas={notas}
                        setNotas={setNotas}
                        errorFormPago={errorFormPago}
                        setErrorFormPago={setErrorFormPago}
                        errorConceptoPago={errorConceptoPago}
                        setErrorConceptoPago={setErrorConceptoPago}
                        errorPlaza={errorPlaza}
                        errorPuesto={errorPuesto}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        indicadoresSueldoNombramientoActivo={indicadoresSueldoNombramientoActivo}
                        setIndicadoresSueldoNombramientoActivo={setIndicadoresSueldoNombramientoActivo}
                        indicadoresSueldoActivo={indicadoresSueldoActivo}
                        setIndicadoresSueldoActivo={setIndicadoresSueldoActivo}
                        indicadoresNombramientoActivo={indicadoresNombramientoActivo}
                        setIndicadoresNombramientoActivo={setIndicadoresNombramientoActivo}
                        nombramientoEditInicial={nombramientoEditInicial}
                        setNombramientoEditInicial={setNombramientoEditInicial}
                        setError={setError}
                    />
                </div>
                {(modulo === 30 || !elementSelect) &&
                <Fragment>
                    <div id="form-part-3" style={{ display: (active === 3) ? 'block' : 'none' }}>
                        <FormDomicilio
                            elementSelect={elementSelect}
                            actualizarDomicilio={actualizarDomicilio}
                            enviar={enviar}
                            irInfo={irInfo}
                            calleRef={calleRef}
                            numExteriorRef={numExteriorRef}
                            numInteriorRef={numInteriorRef}
                            cpRef={cpRef}
                            coloniaRef={coloniaRef}
                            ciudadRef={ciudadRef}
                            estadoRef={estadoRef}
                            infoDomicilio={infoDomicilio}
                            isLoading={isLoading}
                        />
                    </div>
                    <div id="form-part-4" style={{ display: (active === 4) ? 'block' : 'none' }}>
                        <FormDependientesEconomicos
                            elementSelect={elementSelect}
                            setError={setError}
                            actualizarDepEcon={actualizarDepEcon}
                            agregarDepEconEdit={agregarDepEconEdit}
                            enviar={enviar}
                            irInfo={irInfo}
                            nivelesEscolaridad={nivelesEscolaridad}
                            nombreDepEconomicoRef={nombreDepEconomicoRef}
                            pApellidoDepEconomicoRef={pApellidoDepEconomicoRef}
                            sApellidoDepEconomicoRef={sApellidoDepEconomicoRef}
                            curpDepEconomicoRef={curpDepEconomicoRef}
                            fechaNacDepEconomicoRef={fechaNacDepEconomicoRef}
                            tiposParentesco={tiposParentesco}
                            parentescoDepEconommicoRef={parentescoDepEconommicoRef}
                            escolaridadDepEconomicoRef={escolaridadDepEconomicoRef}
                            gradoEstudiosDepEconomicoRef={gradoEstudiosDepEconomicoRef}
                            promedioDepEconomicoRef={promedioDepEconomicoRef}
                            mostrarDiscapacidad={mostrarDiscapacidad}
                            setMostrarDiscapacidad={setMostrarDiscapacidad}
                            nivelDiscapacidadRef={nivelDiscapacidadRef}
                            nombreDiscapacidadRef={nombreDiscapacidadRef}
                            depEconomicosSaved={depEconomicosSaved}
                            setDepEconomicosSaved={setDepEconomicosSaved}
                            depEconomicosSavedEdit={depEconomicosSavedEdit}
                            setDepEconomicosSavedEdit={setDepEconomicosSavedEdit}
                            elementoSeleccionado={elementoSeleccionadoDepEcon}
                            setElementoSeleccionado={setElementoSeleccionadoDepEcon}
                            mostrarFormAddEdit={mostrarFormAddDepEconEdit}
                            setMostrarFormAddEdit={setMostrarFormAddDepEconEdit}
                            successTable={successTable}
                            errorTable={errorTable}
                            isLoading={isLoading}
                        />
                    </div>
                    <div id="form-part-5" style={{ display: (active === 5) ? 'block' : 'none' }}>
                        <FormPensionesAlimenticias
                            elementSelect={elementSelect}
                            setError={setError}
                            agregarPensionAlimEdit={agregarPensionAlimEdit}
                            actualizarPensionAlim={actualizarPensionAlim}
                            enviar={enviar}
                            irInfo={irInfo}
                            folioPensionAlimRef={folioPensionAlimRef}
                            nombrePensionAlimRef={nombrePensionAlimRef}
                            pApellidoPensionAlimRef={pApellidoPensionAlimRef}
                            sApellidoPensionAlimRef={sApellidoPensionAlimRef}
                            tipoCuotaPensionAlimRef={tipoCuotaPensionAlimRef}
                            montoPensionAlimRef={montoPensionAlimRef}
                            fechaInicioPensionAlimRef={fechaInicioPensionAlimRef}
                            fechaFinalPensionAlimRef={fechaFinalPensionAlimRef}
                            prioridadPensionAlimRef={prioridadPensionAlimRef}
                            pensionesAlimSaved={pensionesAlimSaved}
                            setPensionesAlimSaved={setPensionesAlimSaved}
                            pensionesAlimSavedEdit={pensionesAlimSavedEdit}
                            setPensionesAlimSavedEdit={setPensionesAlimSavedEdit}
                            elementoSeleccionado={elementoSeleccionadoPensionAlim}
                            setElementoSeleccionado={setElementoSeleccionadoPensionAlim}
                            mostrarFormAddEdit={mostrarFormAddPenAlimEdit}
                            setMostrarFormAddEdit={setMostrarFormAddPenAlimEdit}
                            successTable={successTable}
                            errorTable={errorTable}
                            isLoading={isLoading}
                        />
                    </div>
                    <div id="form-part-6" style={{ display: (active === 6) ? 'block' : 'none' }}>
                        <FormNumerosEmergencia
                            elementSelect={elementSelect}
                            setError={setError}
                            agregarNumEmerEdit={agregarNumEmerEdit}
                            actualizarNumEmer={actualizarNumEmer}
                            enviar={enviar}
                            irInfo={irInfo}
                            nombreNumEmergenciaRef={nombreNumEmergenciaRef}
                            pApellidoNumEmergenciaRef={pApellidoNumEmergenciaRef}
                            sApellidoNumEmergenciaRef={sApellidoNumEmergenciaRef}
                            telFijoNumEmergenciaRef={telFijoNumEmergenciaRef}
                            telMovilNumEmergenciaRef={telMovilNumEmergenciaRef}
                            tiposParentesco={tiposParentesco}
                            parentescoNumEmergenciaRef={parentescoNumEmergenciaRef}
                            calleNumEmergenciaRef={calleNumEmergenciaRef}
                            numExteriorNumEmergenciaRef={numExteriorNumEmergenciaRef}
                            numInteriorNumEmergenciaRef={numInteriorNumEmergenciaRef}
                            cpNumEmergenciaRef={cpNumEmergenciaRef}
                            coloniaNumEmergenciaRef={coloniaNumEmergenciaRef}
                            ciudadNumEmergenciaRef={ciudadNumEmergenciaRef}
                            estadoNumEmergenciaRef={estadoNumEmergenciaRef}
                            llamadasEmergenciaSaved={llamadasEmergenciaSaved}
                            setLlamadasEmergenciaSaved={setLlamadasEmergenciaSaved}
                            llamadasEmergenciaSavedEdit={llamadasEmergenciaSavedEdit}
                            setLlamadasEmergenciaSavedEdit={setLlamadasEmergenciaSavedEdit}
                            elementoSeleccionado={elementoSeleccionadoNumEmer}
                            setElementoSeleccionado={setElementoSeleccionadoNumEmer}
                            mostrarFormAddEdit={mostrarFormAddNumEmerEdit}
                            setMostrarFormAddEdit={setMostrarFormAddNumEmerEdit}
                            successTable={successTable}
                            errorTable={errorTable}
                            isLoading={isLoading}
                        />
                    </div>
                    <div id="form-part-7" style={{ display: (active === 7) ? 'block' : 'none' }}>
                        <FormExperienciaLaboral
                            empresaExpLabRef={empresaExpLabRef}
                            puestoExpLabRef={puestoExpLabRef}
                            desdeMesExpLabRef={desdeMesExpLabRef}
                            desdeAnioExpLabRef={desdeAnioExpLabRef}
                            hastaMesExpLabRef={hastaMesExpLabRef}
                            hastaAnioExpLabRef={hastaAnioExpLabRef}
                            nombreJefeExpLabRef={nombreJefeExpLabRef}
                            telefonoExpLabRef={telefonoExpLabRef}
                            elementSelect={elementSelect}
                            elementoSeleccionado={elementoSeleccionadoExpLaboral}
                            setElementoSeleccionado={setElementoSeleccionadoExpLaboral}
                            irInfo={irInfo}
                            actualizarExpLaboral={actualizarExpLaboral}
                            agregarExpLaboralEdit={agregarExpLaboralEdit}
                            enviar={enviar}
                            mostrarFormAddEdit={mostrarFormAddExpLaboralEdit}
                            setMostrarFormAddEdit={setMostrarFormAddExpLaboralEdit}
                            expLaboralSaved={expLaboralSaved}
                            setExpLaboralSaved={setExpLaboralSaved}
                            expLaboralSavedEdit={expLaboralSavedEdit}
                            setExpLaboralSavedEdit={setExpLaboralSavedEdit}
                            setError={setError}
                            successTable={successTable}
                            errorTable={errorTable}
                            isLoading={isLoading}
                        />
                    </div>
                    <div id="form-part-8" style={{ display: (active === 8) ? 'block' : 'none' }}>
                        <FormPasatiempos
                            pasatiempoRef={pasatiempoRef}
                            pasatiempos={pasatiempos}
                            elementSelect={elementSelect}
                            elementoSeleccionado={elementoSeleccionadoPasatiempo}
                            setElementoSeleccionado={setElementoSeleccionadoPasatiempo}
                            irInfo={irInfo}
                            actualizarPasatiempo={actualizarPasatiempo}
                            agregarPasatiempoEdit={agregarPasatiempoEdit}
                            enviar={enviar}
                            mostrarFormAddEdit={mostrarFormAddPasatiempoEdit}
                            setMostrarFormAddEdit={setMostrarFormAddPasatiempoEdit}
                            pasatiempoSaved={pasatiempoSaved}
                            setPasatiempoSaved={setPasatiempoSaved}
                            pasatiempoSavedEdit={pasatiempoSavedEdit}
                            setPasatiempoSavedEdit={setPasatiempoSavedEdit}
                            setError={setError}
                            errorTable={errorTable}
                            isLoading={isLoading}
                        />
                    </div>
                </Fragment>
                }
                { error }
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text="No ha guardado cambios. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={siguienteEdit}/>
                <AlertWarning show={openAlert2} SetopenALert={setOpenAlert2} text={(formaPagoRef.current) ? (formaPagoRef.current.value === '') ? 'No ha guardado cambios. ¿Desea continuar?' : 'No ha agregado la Forma de Pago seleccionada. ¿Desea continuar?' : 'No ha guardado cambios. ¿Desea continuar?'} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue2}/>
                <AlertWarning show={openAlert3} SetopenALert={setOpenAlert3} text={`${(elementSelect) ? 'No ha guardado cambios' : 'No ha completado el formulario' }. ¿Desea continuar?`} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue3}/>
                <AlertWarning show={openAlert4} SetopenALert={setOpenAlert4} text="No ha agregado el dependiente económico. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue4}/>
                <AlertWarning show={openAlert5} SetopenALert={setOpenAlert5} text="No ha guardado cambios. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue5}/>
                <AlertWarning show={openAlert6} SetopenALert={setOpenAlert6} text="No ha agregado la pensión alimenticia. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue6}/>
                <AlertWarning show={openAlert7} SetopenALert={setOpenAlert7} text="No ha guardado cambios. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue7}/>
                <AlertWarning show={openAlert8} SetopenALert={setOpenAlert8} text="No ha agregado el contacto de emergencia. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue8}/>
                <AlertWarning show={openAlert9} SetopenALert={setOpenAlert9} text="No ha guardado cambios. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue9}/>
                <AlertWarning show={openAlert10} SetopenALert={setOpenAlert10} text="No ha agregado la experiencia laboral. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue10}/>
                <AlertWarning show={openAlert11} SetopenALert={setOpenAlert11} text="No ha guardado cambios. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue11}/>
                <AlertWarning show={openAlert12} SetopenALert={setOpenAlert12} text="No ha agregado el pasatiempo. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue12}/>
                <AlertWarning show={openAlert13} SetopenALert={setOpenAlert13} text="No ha guardado cambios. ¿Desea continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue13}/>
            </Fragment>
        :   <SpinnerOval />
        }
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    nominasRL: state.PeriodoActualReducer.nominasRL,
});

const mapDispatchToProps = { editarEmpleado, editarEmpleadoOrganizacion, reingresoEmpleado };

export default connect(mapStateToProps, mapDispatchToProps)(FormEmpleados);
