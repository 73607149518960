import React from 'react';

const TerminosyCondiciones = () => {
    return (
        <div className="informacion-legal">
            <p><h4>CONTRATO DE LICENCIA DE USO DE LA PÁGINA DE INTERNET CUYA DIRECCIÓN ELECTRÓNICA ES GESTIONDRH.OAXACA.GOB.MX Y PORTALEMPLEADOS.OAXACA.GOB.MX (EN ADELANTE “LA PLATAFORMA”) Y LA APLICACIÓN PARA TELÉFONOS INTELIGENTES (EN ADELANTE “APP”), QUE CELEBRAN POR UNA PARTE EL GOBIERNO DEL ESTADO DE OAXACA A TRAVÉS DE LA SECRETARÍA DE ADMINISTRACIÓN (EN ADELANTE “GOBIERNO DE OAXACA”) Y, POR LA OTRA, EL USUARIO (EN ADELANTE “EL USUARIO” O “LOS USUARIOS”, INDISTINTAMENTE, SUJETÁNDOSE AMBAS PARTES, A LO ESTABLECIDO EN EL TEXTO DEL PRESENTE CONTRATO</h4></p>

            <p><h4>Términos y condiciones de uso</h4></p>

            <p><h6>1. Aceptación y adopción de los términos</h6></p>
            <p>1.1. Al ingresar y utilizar la página de Internet cuya dirección electrónica es gestiondrh.oaxaca.gob.mx y portalempleados.oaxaca.gob.mx (en adelante “LA PLATAFORMA”) y la aplicación para teléfonos inteligentes (en adelante “APP”), propiedad de <b>I-GENTER MEXICO, S. DE R.L. DE C.V.</b> (en lo sucesivo <b>I-GENTER</b>), y licenciada a favor de, administrada y operada por el <b>GOBIERNO DE OAXACA</b> usted (el Usuario), está entendiendo y aceptando los términos y las condiciones contenidos en este contrato y declara expresamente su aceptación, utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 1803 y 1834 bis del Código Civil Federal, 80, 81, 89 y demás relativos y aplicables del Código de Comercio y demás relativos y aplicables de la legislación aplicable.</p>
            <p>1.2. Si el Usuario no acepta en forma absoluta y completa los términos y condiciones de este contrato, deberá abstenerse de acceder, usar y ver LA PLATAFORMA y la APP.</p>
            <p>1.3. Para el caso que el Usuario continúe en el uso de LA PLATAFORMA y la APP, dicha acción se considerará como su absoluta y expresa aceptación a los términos y condiciones aquí estipulados.</p>
            <p>1.4. El solo hecho de que el público usuario (en adelante referido como el "Usuario" o los "Usuarios", indistintamente) haga uso de LA PLATAFORMA y de la APP, le otorga e implica la aceptación, plena e incondicional, de todas y cada una de las condiciones generales y particulares incluidas en estos términos y condiciones de uso en la versión publicada y administrada por el <b>GOBIERNO DE OAXACA</b>, en el momento mismo en que el Usuario acceda a LA PLATAFORMA y a la APP. Cualquier modificación a los presentes términos y condiciones será realizada cuando el <b>GOBIERNO DE OAXACA</b> lo considere apropiado, comenzando la vigencia de dichas modificaciones desde que las mismas se publiquen en LA PLATAFORMA y en la APP, siendo exclusiva responsabilidad del Usuario asegurarse de tomar conocimiento de tales modificaciones.</p>
            <p>1.5. Las partes declaran que al no existir error, dolo, mala fe o cualquier otro vicio de la voluntad que pudiera nulificar la validez del presente instrumento, ambas acuerdan en sujetarse al tenor de lo estipulado en el presente documento.</p>

            <p><h6>2. Descripción del servicio</h6></p>
            <p>2.1. El servicio ofrecido por el <b>GOBIERNO DE OAXACA</b> a través de LA PLATAFORMA y de la APP, es la provisión de una plataforma para brindar servicios de administración de recursos humanos y gestión de nóminas y Portal del Empleado bajo una modalidad de licencia de uso perpetua otorgada por <b>I-GENTER</b> al <b>GOBIERNO DE OAXACA</b> (desde ahora denominados como LOS SERVICIOS).</p>
            <p>2.2. Los Usuarios de LOS SERVICIOS establecidos en el presente, serán los indicados por el <p>GOBIERNO DE OAXACA</p> y podrán ser administradores y/o empleados de clientes que utilizan LA PLATAFORMA y/o de la APP bajo dicha modalidad de servicio.</p>
            <p>2.3. El uso de LA PLATAFORMA y de la APP está sujeto a las condiciones establecidas en los presentes términos y condiciones.</p>
            <p>2.4. LOS SERVICIOS ofrecidos por el <b>GOBIERNO DE OAXACA</b> a través de LA PLATAFORMA y la APP estarán disponibles para Usuarios de la República Mexicana y en idioma español.</p>
            <p>2.5. El <b>GOBIERNO DE OAXACA</b> otorga y concede al Usuario el derecho no exclusivo, revocable y no transferible de ver y usar LA PLATAFORMA y la APP, de conformidad con los términos y condiciones que aquí se establecen y de conformidad con el Acuerdo de licencia de uso del Sistema Integral de Nóminas iGenter® que el <b>GOBIERNO DE OAXACA</b> ha celebrado y aceptado de <b>I-GENTER</b>.</p>
            <p>2.6. Menores de Edad: Para hacer uso de LA PLATAFORMA y la APP y de LOS SERVICIOS ofrecidos por el <b>GOBIERNO DE OAXACA</b>, los menores de edad deben obtener previamente permiso de sus padres, tutores o representantes legales, quienes serán considerados responsables de todos los actos realizados por los menores a su cargo. La plena responsabilidad en la determinación de los contenidos y servicios a los que acceden los menores de edad corresponde a los mayores a cuyo cargo se encuentran.</p>
            <p>2.7. Para que el Usuario pueda utilizar LOS SERVICIOS provistos por el <b>GOBIERNO DE OAXACA</b> a través de LA PLATAFORMA y/o de la APP, deberá registrarse en la misma, ingresando los datos de contacto requeridos por el <b>GOBIERNO DE OAXACA</b>, respecto de cuyo tratamiento -en caso de incluir datos personales- será responsable el <b>GOBIERNO DE OAXACA</b> conforme a lo establecido en la normativa mexicana aplicable en la materia y quien se obliga a poner a disposición de dichos Usuarios, su propio aviso de privacidad. Una vez que el Usuario administrador designado por el <b>GOBIERNO DE OAXACA</b> se haya registrado en LA PLATAFORMA y/o en la APP, dicho Usuario administrador podrá crear sus propios usuarios según sus necesidades de operación. Al registrarse en LA PLATAFORMA y/o en la APP, el <b>GOBIERNO DE OAXACA</b> le otorgará al Usuario el derecho a utilizar LOS SERVICIOS que ofrece el <b>GOBIERNO DE OAXACA</b> conforme a lo establecido en el presente instrumento y al contenido de la relación comercial que celebre el <b>GOBIERNO DE OAXACA</b> con <b>I-GENTER</b>. Este derecho no es exclusivo y no es transferible.</p>
            <p>2.8. <b>Proceso de registro para la provisión de servicios</b></p>
            <p>2.8.1. Para que el Usuario pueda gozar de LOS SERVICIOS ofrecidos por el <b>GOBIERNO DE OAXACA</b> en LA PLATAFORMA y en la APP, deberá ingresar a LA PLATAFORMA o descargar la APP y registrarse en la misma, conforme lo establecido en el numeral 2.7. de los presentes términos y condiciones. Una vez registrado en LA PLATAFORMA o en la APP, deberá iniciar sesión con su nombre de usuario y contraseña. La contraseña generada por el Usuario es única e intransferible, por lo que dicho Usuario asume responsabilidad por la custodia y cuidado de la misma y por cualquier uso no autorizado a su cuenta en LA PLATAFORMA y en la APP.</p>
            <p>2.9. <b>Condiciones y/o restricciones en la provisión de los servicios adquiridos</b></p>
            <p>
                <ol type="a">
                    <li>LOS SERVICIOS provistos por el <b>GOBIERNO DE OAXACA</b> a través LA PLATAFORMA y/o de la APP únicamente serán brindados a Usuarios designados por el <b>GOBIERNO DE OAXACA</b>.</li>
                    <li>El Usuario acepta que las imágenes, fotografías, logos y/o cualquier contenido que provean a LA PLATAFORMA y/o a la APP son propiedad de ellos y/o tienen la titularidad de los derechos de propiedad intelectual y/o industrial sobre las mismas, y en caso de tratarse de imágenes, fotografías, logos y/o cualquier otro contenido protegido con derechos de autor y/o propiedad intelectual a favor de terceros, cuentan con la autorización expresa de dichos terceros para compartirlas en LA PLATAFORMA y/o en la APP, concediéndole al <b>GOBIERNO DE OAXACA</b> una licencia de uso no exclusiva, temporal y gratuita para su publicación en LA PLATAFORMA y/o en la APP, comprometiéndose a sacar en paz y a salvo al <b>GOBIERNO DE OAXACA</b> por cualquier uso no autorizado de material protegido con derechos de propiedad intelectual y/o industrial publicados en LA PLATAFORMA y/o en la APP.</li>
                </ol>
            </p>

            <p><h6>3. Obligaciones del Usuario</h6></p>
            <p>3.1. Para los efectos del presente contrato, las partes acuerdan que por Usuario se entenderá a cualquier persona de cualquier naturaleza que ingrese a LA PLATAFORMA y/o a la APP, y/o utilice LOS SERVICIOS ofrecidos por el <b>GOBIERNO DE OAXACA</b>.</p>
            <p>3.2. Asimismo, el Usuario declara bajo protesta de decir verdad que cuenta con la plena capacidad legal conforme a la normativa mexicana aplicable, para el uso de LOS SERVICIOS ofrecidos por el <b>GOBIERNO DE OAXACA</b> a través de LA PLATAFORMA y/o la APP -en los casos en los que aplique- y para cumplir con las obligaciones contraídas por medio de la utilización de LA PLATAFORMA y/o la APP.</p>
            <p>3.3. El Usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada en LA PLATAFORMA y/o en la APP exclusivamente para su uso personal, no comercial. En caso de ser persona moral se estará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor. Se le recuerda al Usuario que todas las fotografías, obras artísticas, marcas y/o logos de las mismas, se encuentran debidamente protegidas en términos de la legislación en materia de Propiedad Intelectual y son propiedad de sus respectivos titulares y que el <b>GOBIERNO DE OAXACA</b> puede haber utilizado imágenes, propiedad de terceros, por lo que la propiedad de ellas les corresponde a sus respectivos titulares y no se usan en términos de lo dispuesto por el artículo 62 del Reglamento de la Ley de la Propiedad Industrial conforme a lo establecido en los transitorios tercero y cuarto de la Ley Federal de Protección a la Propiedad Industrial.</p>
            <p>3.4. La reimpresión, re-publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por cualquier otro medio de cualquier información, documento o gráfico de o que aparezca en LA PLATAFORMA y/o APP en todo o en parte, para cualquier uso distinto al personal no comercial le está expresamente prohibido al Usuario, a menos que el <b>GOBIERNO DE OAXACA o sus respectivos titulares</b> le haya otorgado su autorización previamente y por escrito.</p>
            <p>3.5. El Usuario es el único responsable de las actividades que se lleven a cabo dentro de su perfil y de utilizar sus datos de acceso a las diferentes plataformas que el <b>GOBIERNO DE OAXACA</b> pone a disposición del Usuario. Si el Usuario encuentra cualquier uso no autorizado de sus datos de acceso, de su perfil, o cualquier otra violación a la seguridad, deberá de contactar a el <b>GOBIERNO DE OAXACA</b>, en los datos de contacto que se ponen a su disposición en el punto 5.2.6. El Usuario es el único responsable de mantener la confidencialidad sobre sus datos de acceso y por tanto será el único responsable sobre los daños y perjuicios que su mal uso pudieran causar.</p>
            <p>3.6. El Usuario será el único responsable del contenido que publique dentro de LA PLATAFORMA y/o la APP, ya que declara ser el titular de los derechos patrimoniales de autor sobre el mismo o contar con la autorización de su legítimo titular para incluirlo dentro de LA PLATAFORMA y/o APP, mismo que puede incluir información, datos de ubicación, gráficos, imágenes, fotografías, videos, etc.</p>
            <p>3.7. El <b>GOBIERNO DE OAXACA</b> busca mantener un ambiente amigable y seguro para los Usuarios de LA PLATAFORMA y/o la APP, así como para LOS SERVICIOS ofrecidos a través de LA PLATAFORMA y/o la APP por lo que enunciamos algunas conductas que el Usuario deberá evitar, al hacer uso de los productos y/o servicios que se ofrecen a través de LA PLATAFORMA y/o de la APP:</p>
            <p>3.7.1. Evitar ser abusivo, insultante, amenazante, intimidante.</p>
            <p>3.7.2. Evitar faltar el respeto a los operadores del <b>GOBIERNO DE OAXACA</b> que lo atienden para la provisión de LOS SERVICIOS que el <b>GOBIERNO DE OAXACA</b> ofrece.</p>
            <p>3.7.3. No deberá publicar contenido que sea inapropiado, difamatorio, obsceno, vulgar, racista, pornográfico o que incite a la intolerancia y/u odio o que promueva actividades que se encuentren fuera del marco de la ley.</p>
            <p>3.7.4. No deberá publicar contenido que infrinja y/o violente derechos de terceros, como podrían ser derechos de propiedad industrial, incluyendo marcas, patentes, diseños industriales, avisos comerciales, secretos industriales; así como derechos de propiedad intelectual, incluyendo derechos de autor o reservas de derechos al uso exclusivo, lo anterior, sin contar con la previa autorización del titular de dichos derechos.</p>
            <p>3.7.5. No deberá hacerse pasar por otra persona, lo que incluye no utilizar el nombre de Usuario ni contraseña de otros Usuarios, para ingresar a LA PLATAFORMA y/o la APP para utilizar LOS SERVICIOS ofrecidos por el <b>GOBIERNO DE OAXACA</b>.</p>
            <p>3.7.6. No deberá publicar contenido que incluya o contenga programas espía (spyware), adware, virus, documentos corruptos, programas gusano o cualquier otro código destinado a interrumpir, dañar, y/o limitar el funcionamiento de cualquier software, hardware, telecomunicaciones, redes, servidores u otros equipos.</p>
            <p>3.7.7. No deberá publicar contenido que esté relacionado con el envío de correo electrónico no deseado o “spam”.</p>
            <p>3.7.8. Su conducta deberá cumplir con todas las leyes Federales y Locales dentro de la República Mexicana. Si usted reside fuera de este país, deberá de cumplir con todas las leyes dentro de su lugar de residencia.</p>
            <p>3.7.9. No deberá eliminar ni modificar contenido que se encuentre publicado en LA PLATAFORMA y/o en la APP. Se exceptúa el contenido que sea propio del Usuario.</p>
            <p>3.7.10. No deberá de registrarse a ninguno de LOS SERVICIOS, plataformas, páginas de Internet ni APP usando nombres falsos.</p>
            <p>3.7.11. No deberá violentar el derecho a la privacidad de otros.</p>
            <p>3.7.12. No deberá de publicar de forma indebida ningún dato de contacto o bancario, ya sean propios o de terceros, como por ejemplo: direcciones, códigos postales, número de teléfono, correos electrónicos, URL, datos de tarjetas de crédito/débito o bancarios, etc. Si el Usuario decide revelar ese tipo de información, es bajo su propia responsabilidad.</p>
            <p>Aun y cuando el <b>GOBIERNO DE OAXACA</b> prohíbe las conductas antes mencionadas, el Usuario reconoce que puede estar expuesto a dichas conductas y contenido, por lo que, el uso de LA PLATAFORMA y/o la APP es bajo su propio riesgo y responsabilidad, liberando al <b>GOBIERNO DE OAXACA</b> de todo tipo de responsabilidad.</p>
            <p>3.8. Adicionalmente, el Usuario se compromete, de manera enunciativa más no limitativa, a lo siguiente:</p>
            <p>
                <ol type="a">
                    <li>No utilizar LA PLATAFORMA y/o la APP para cualquier procesamiento de datos o cualquier propósito comercial, de producción o comercial interno que no sea el uso interno para la administración de empleados, así como la gestión y procesamiento de nóminas.</li>
                    <li>A no eliminar o modificar cualquier marca de LA PLATAFORMA y/o de la APP o aviso de derechos de propiedad del <b>GOBIERNO DE OAXACA</b> o de un tercero licenciante.</li>
                    <li>No podrá otorgar el acceso a LA PLATAFORMA y/o a la APP a tercero que no tengan ningún tipo de relación y/o no hayan contratados sido autorizados por el <b>GOBIERNO DE OAXACA</b>.</li>
                    <li>Tampoco podrá ceder las obligaciones asumidas mediante la aceptación del presente contrato, a un tercero que no tenga ningún tipo de relación y/o no haya sido autorizado por el <b>GOBIERNO DE OAXACA</b>.</li>
                    <li>Asimismo, el Usuario tampoco podrá causar o permitir ingeniería inversa a LA PLATAFORMA y/o a la APP (a menos que sea requerido por la ley aplicable o autoridad competente), ni desmontaje o descompilación de LA PLATAFORMA y/o de la APP.</li>
                    <li>A no crear, modificar o cambiar el comportamiento de clases, interfaces o subpaquetes que de alguna manera se identifiquen como “java”, “html”, “js” o una convención similar a la especificada por <b>I-GENTER</b> y/o el <b>GOBIERNO DE OAXACA</b> en cualquier denominación o designación de la convención.</li>
                    <li>El Usuario manifiesta que se obliga frente al <b>GOBIERNO DE OAXACA</b> y a terceros, a compartir información veraz, correcta, completa, actualizada, sin errores y respecto de la cual se encuentra autorizado a compartirla, ya que la misma es necesaria para la prestación de LOS SERVICIOS provistos a través de LA PLATAFORMA y/o la APP.</li>
                </ol>
            </p>

            <p><h6>4. Privacidad, confidencialidad y seguridad</h6></p>
            <p><b>4.1. Aviso de privacidad sobre el uso de información y base de datos</b></p>
            <p>4.1.1. El <b>GOBIERNO DE OAXACA</b> pone a disposición de los Usuarios su Aviso de Privacidad el cual se encuentra disponible en LA PLATAFORMA y la APP en la pestaña correspondiente a Aviso de privacidad, el cual cumplirá con la legislación aplicable en la materia.<br/>El Usuario se podrá informar acerca del tratamiento que el <b>GOBIERNO DE OAXACA</b> le otorgue a sus datos personales en dicho Aviso de privacidad integral, así como los procedimientos para el ejercicio de sus derechos ARCO.</p>
            <p>4.1.2. El <b>GOBIERNO DE OAXACA</b> anunciará en LA PLATAFORMA y en la APP los cambios que se realicen en su Aviso de Privacidad y dichos cambios serán publicados en la mencionada PLATAFORMA y en la APP en la sección correspondiente a Aviso de privacidad, de manera que el Usuario pueda estar siempre informado acerca de los datos personales que el <b>GOBIERNO DE OAXACA</b> recabe del Usuario, cómo podrá utilizar dichos datos personales, con qué finalidades y el tratamiento que se le dará a los mismos.</p>
            <p><b>4.2. Confidencialidad</b></p>
            <p>4.2.1. La información del Usuario a la que el <b>GOBIERNO DE OAXACA</b> le brinde tratamiento y que tenga registrada con motivo de las actividades realizadas por los Usuarios en LA PLATAFORMA y/o en la APP es considerada como información confidencial. Toda la información del Usuario está protegida y debidamente resguardada en los servidores del <b>GOBIERNO DE OAXACA</b>.</p>
            <p><b>4.3. Seguridad</b></p>
            <p>4.3.1. LA PLATAFORMA y la APP cuentan con procesos y estándares de calidad e informáticos que respaldan el adecuado uso de la información que es compartida. En aquellos casos en que el <b>GOBIERNO DE OAXACA</b> haya detectado que algún Usuario ha realizado prácticas que pongan en riesgo la seguridad de LA PLATAFORMA y/o la APP, de los sistemas y/o de los servidores del <b>GOBIERNO DE OAXACA</b> respecto de la información de los Usuarios, el <b>GOBIERNO DE OAXACA</b> podrá realizar las investigaciones correspondientes, pudiendo reclamar en todo momento, de dicho Usuario, la subsanación de los daños y perjuicios ocasionados tanto a los demás Usuarios, a terceros y/o al <b>GOBIERNO DE OAXACA</b>.</p>
            <p>4.3.2. Asimismo, en todos los casos, el <b>GOBIERNO DE OAXACA</b> se reserva el derecho de llevar a cabo todas aquellas acciones legales correspondientes ante las autoridades competentes para obtener la reparación de los daños y perjuicios ocasionados.</p>

            <p><h6>5. Propiedad intelectual</h6></p>
            <p><b>5.1. Información contenida en LA PLATAFORMA y en la APP</b></p>
            <p>5.1.1. El Usuario reconoce y acepta que la información publicada o contenida en LA PLATAFORMA y/o en la APP generada por el <b>GOBIERNO DE OAXACA</b>, o por sus aliados comerciales, será claramente identificada de forma tal que se reconozca que la misma proviene del <b>GOBIERNO DE OAXACA</b>, o de los aliados comerciales.</p>
            <p>5.1.2. Las informaciones, conceptos y opiniones publicadas en LA PLATAFORMA y/o en la APP no necesariamente reflejan la posición del <b>GOBIERNO DE OAXACA</b> ni de sus empleados, oficiales, directores, accionistas. Por esta razón, el <b>GOBIERNO DE OAXACA</b> no se hace responsable por ninguna de las informaciones, opiniones y conceptos que se emitan en LA PLATAFORMA y en la APP o de cualquier otro espacio o servicio que se preste a través de LA PLATAFORMA y/o la APP. Asimismo, el <b>GOBIERNO DE OAXACA</b>, no se hace responsable de las consecuencias que se puedan derivar del uso de consejos, procedimientos, etc., en LA PLATAFORMA, y en la APP en el entendido de que es bajo el propio riesgo y responsabilidad del Usuario el uso y seguimiento a dichos consejos, comentarios, procedimientos, tips, etc., que se den en los espacios referidos.</p>
            <p>5.1.3. El <b>GOBIERNO DE OAXACA</b> se reserva el derecho de bloquear el acceso o remover en forma parcial o total, toda información, comunicación o material que a su exclusivo juicio pueda resultar: (i) abusivo, difamatorio u obsceno, sexual, racial (ii) fraudulento, artificioso o engañoso, (iii) violatorio de derechos de autor, marcas o cualquier derecho de propiedad intelectual de un tercero, (iv) ofensivo o (v) de cualquier forma contravenga lo establecido en este contrato. Si el Usuario desea obtener mayor información de un tema en específico proveído por el <b>GOBIERNO DE OAXACA</b>, o los aliados comerciales, el Usuario deberá consultarlo directamente con cada uno de ellos, según corresponda y/o con un especialista en la materia.</p>
            <p>5.1.4. El Usuario reconoce que el <b>GOBIERNO DE OAXACA</b> no trata de controlar o censurar previamente el contenido disponible en LA PLATAFORMA y en la APP. Por tal motivo, el <b>GOBIERNO DE OAXACA</b> no asume ninguna responsabilidad por el contenido provisto en LA PLATAFORMA y en la APP por terceros independientes ajenos a el <b>GOBIERNO DE OAXACA</b>. El <b>GOBIERNO DE OAXACA</b> no tiene el control editorial sobre el contenido y quedará exento de cualquier responsabilidad por información y/o material generado y/o provisto por terceros. Todas las opiniones, consejos, declaraciones, servicios, ofertas, u otras informaciones o contenidos expresados o puestos a disposición del público por terceros, pertenecen a su respectivo autor y el <b>GOBIERNO DE OAXACA</b> no asume responsabilidad alguna frente a ello.</p>
            <p>5.1.5. El <b>GOBIERNO DE OAXACA</b> no garantiza la exactitud, veracidad, amplitud y/o utilidad de cualquier contenido provisto por tales terceros. Adicionalmente, el <b>GOBIERNO DE OAXACA</b> no es responsable ni garantiza la exactitud, exhaustividad, veracidad y/o confiabilidad de cualquier opinión, información, consejo o declaración provistos por el <b>GOBIERNO DE OAXACA</b> a través de LA PLATAFORMA y/o la APP. Bajo ninguna circunstancia el <b>GOBIERNO DE OAXACA</b> será responsable de cualquier daño y/o perjuicio, directo o indirecto, causado en virtud de la confianza del Usuario en información obtenida a través de LA PLATAFORMA y/o la APP. El <b>GOBIERNO DE OAXACA</b> se reserva el derecho de suprimir o modificar el contenido de LA PLATAFORMA y/o la APP que, a exclusivo juicio del <b>GOBIERNO DE OAXACA</b>, no cumpla con los estándares del <b>GOBIERNO DE OAXACA</b> o que pudiera resultar contrario al ordenamiento jurídico vigente, aunque el <b>GOBIERNO DE OAXACA</b> no será responsable por cualquier falla o tardanza que se genere al eliminar tal material.</p>
            <p><b>5.2. Contenido</b></p>
            <p>5.2.1.  El <b>GOBIERNO DE OAXACA</b>, LA PLATAFORMA, la APP y sus logotipos y todo el material que aparece en LA PLATAFORMA y en la APP, como marcas, nombres de dominio y/o nombres comerciales son propiedad de sus respectivos titulares y se encuentran protegidos por los tratados internacionales y leyes aplicables en materia de propiedad intelectual y derechos de autor.</p>
            <p>5.2.2.  Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones, o en general cualquier información contenida o publicada en LA PLATAFORMA y en la APP se encuentran debidamente protegidos a favor de <b>I-GENTER</b> y/o el <b>GOBIERNO DE OAXACA</b>, sus afiliados, proveedores y/o de sus respectivos propietarios, de conformidad con la legislación aplicable en materia de propiedad intelectual.</p>
            <p>5.2.3. Se prohíbe expresamente al Usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de información contenida en LA PLATAFORMA y en la APP.</p>
            <p>5.2.4. Asimismo, el Usuario será el único responsable del contenido que publique dentro de LA PLATAFORMA y en la APP ya que declara ser el titular de los derechos patrimoniales de autor sobre el mismo o contar con la autorización de su legítimo titular para incluirlo dentro de LA PLATAFORMA y en la APP. Por lo anterior, se compromete a sacar en paz y a salvo a <b>I-GENTER</b> y/o al <b>GOBIERNO DE OAXACA</b> de cualquier reclamación, queja, demanda o denuncia que cualquier tercero inicie en virtud de la originalidad o titularidad de dicho contenido.</p>
            <p>5.2.5. Es política del <b>GOBIERNO DE OAXACA</b> actuar contra las violaciones que en materia de Propiedad Intelectual se pudieran generar u originar, según lo estipulado en la legislación aplicable y en otras leyes de propiedad intelectual aplicables, incluyendo la eliminación o el bloqueo del acceso a material que se encuentra sujeto a actividades que infrinjan el derecho de propiedad intelectual de terceros.</p>
            <p>5.2.6. En el caso de que algún Usuario o un tercero consideren que cualquiera de los Contenidos que se encuentren o sean introducidos en LA PLATAFORMA y en la APP violen sus derechos de propiedad intelectual, deberán enviar una notificación al <b>GOBIERNO DE OAXACA</b> a la dirección electrónica recursoshumanos.admon@oaxaca.gob.mx.</p>
            <p>5.2.7. El Usuario reconoce y acepta que el <b>GOBIERNO DE OAXACA</b> podrá divulgar su identidad u otra información vinculada al Usuario, a cualquier tercero que presente algún reclamo vinculado a la violación de sus derechos, incluidos sus derechos de propiedad intelectual o su derecho a la privacidad, o que el <b>GOBIERNO DE OAXACA</b> tome las acciones legales apropiadas, incluyendo, de manera enunciativa más no limitativa, la presentación de denuncias o reclamos ante las autoridades competentes por cualquier uso ilícito o no autorizado de LA PLATAFORMA y/o de la APP y/o por violación a los derechos de titularidad del <b>GOBIERNO DE OAXACA</b> y/o de terceros. Por lo tanto, el Usuario reconoce y acepta que el <b>GOBIERNO DE OAXACA</b> tendrá derecho a cooperar con cualquier autoridad administrativa o judicial que le solicite o le ordene divulgar la identidad del Usuario u otra información de cualquier tipo que publique en LA PLATAFORMA y/o en la APP. POR LO ANTERIOR, EL USUARIO RECONOCE Y ACEPTA QUE SE OBLIGA A SACAR EN PAZ Y A SALVO AL <b>GOBIERNO DE OAXACA</b> Y A SUS LICENCIATARIAS Y PROVEEDORES DE SERVICIO FRENTE A CUALQUIER RECLAMO QUE RESULTE POR CUALQUIER ACCIÓN TOMADA POR CUALQUIERA DE LAS PARTES ANTES INDICADAS DURANTE EL CURSO DE INVESTIGACIONES YA SEA QUE LAS MISMAS SEAN REALIZADAS POR CUALQUIERA DE DICHAS PARTES O POR AUTORIDADES COMPETENTES.</p>
            <p><b>5.2.8. Derechos de Propiedad Intelectual y Contenido del Usuario</b></p>
            <p>5.2.8.1. LA PLATAFORMA y/o la APP, características y funcionalidad (incluyendo de manera enunciativa más no limitativa todo el software, demostraciones, gráficos, ilustraciones, logotipos y el diseño, selección, apariencia y sentimiento y orden de los mismos) son propiedad de <b>I-GENTER</b> y/o del <b>GOBIERNO DE OAXACA</b>, sus licenciantes u otros proveedores de dicho material y están protegidos por las leyes locales y las leyes internacionales de derechos de autor, marcas, patentes, secretos comerciales y otras leyes de derechos de propiedad intelectual o exclusivos. El usar LA PLATAFORMA y/o la APP no le otorga al Usuario titularidad de ningún derecho de propiedad intelectual sobre LA PLATAFORMA y/o la APP o al contenido al que el Usuario accede, incluyendo el contenido brindado por el <b>GOBIERNO DE OAXACA</b> u otros Usuarios. El Usuario no puede utilizar el contenido del <b>GOBIERNO DE OAXACA</b> a menos que dicho Usuario obtenga el permiso expreso y por escrito de su propietario o que se le permita en virtud de la ley aplicable. El Usuario no retirará, ocultará o alterará ningún aviso legal mostrado en LA PLATAFORMA y/o en la APP. El <b>GOBIERNO DE OAXACA</b> le otorga al Usuario por medio del presente una licencia no exclusiva, limitada, no transferible, no sublicenciable, revocable para acceder y usar LA PLATAFORMA y/o la APP, únicamente para su uso personal, no comercial y solamente en cumplimiento con estos términos y condiciones de uso. El <b>GOBIERNO DE OAXACA</b> se reserva todos los derechos no expresamente otorgados en el presente.</p>
            <p>5.2.8.2. El <b>GOBIERNO DE OAXACA</b> le puede permitir al Usuario mostrar, enviar, publicar, demostrar o transmitir a otros Usuarios o a otras personas el contenido o materiales, incluyendo texto, palabras, fotos, videos, audio, etc. (conjuntamente, el “Contenido del Usuario”).</p>
            <p>5.2.8.3. El Usuario manifiesta y garantiza por medio del presente que es titular y controla todos los derechos del Contenido del Usuario que se carga, publica o transmite en LA PLATAFORMA y/o en la APP a través de su cuenta o que el Usuario ha recibido todas las autorizaciones, aprobaciones, licencias y/o permisos necesarios del titular de los derechos de propiedad intelectual sobre el contenido para otorgar la siguiente licencia (sin ninguna obligación de pagar regalías u otra contraprestación ni a ningún tercero): el Usuario por medio del presente otorga al <b>GOBIERNO DE OAXACA</b> y a sus afiliadas y proveedores de servicio y a cada una de sus respectivas licenciatarias, sucesores y cesionarias, una licencia mundial, libre de regalías, no exclusiva, sublicenciable, transferible y permanente para (a) mostrar el Contenido del Usuario dentro de LA PLATAFORMA y/o de la APP; (b) permitir a otros Usuarios ver, entrar y descargar el Contenido del Usuario; (c) autorizar a otros Usuarios a usar, modificar, adaptar, reproducir, hacer trabajos derivados, publicar y/o transmitir su Contenido de Usuario en cualquier formato y en cualquier plataforma, ya sea conocida o posteriormente inventada; (d) usar, albergar, almacenar, reproducir, modificar, crear trabajos derivados (tales como los que resultan de traducciones, adaptaciones u otros cambios que se realicen), comunicar, publicar, realizar públicamente, mostrar públicamente, distribuir o divulgar a terceros Contenido del Usuario (incluyendo para propósitos comerciales tales como promoción de LA PLATAFORMA y/o de la APP), y (e) usar el nombre de Usuario, imagen, voz y similitud para identificarlo como la fuente de cualquiera de su Contenido de Usuario. El Usuario reconoce y acepta que es responsable por cualquier Contenido de Usuario enviado o contribuido a través de su cuenta, incluyendo su legalidad, confiabilidad, exactitud y pertinencia.</p>
            <p>5.2.8.4.  Asimismo, el Usuario reconoce y acepta que el <b>GOBIERNO DE OAXACA</b> tiene el derecho de retirar o de negarse a mostrar cualquier Contenido del Usuario por cualquier razón, a su sola discreción o tomar cualquier acción con respecto a cualquier Contenido del Usuario que el <b>GOBIERNO DE OAXACA</b> considere necesaria o apropiada a su sola discreción, incluyendo si considera que dicho Contenido del Usuario viola estos términos y condiciones de uso, viola cualquier derecho de propiedad intelectual u otro derecho de cualquier persona o entidad, amenaza la seguridad personal de los Usuarios de LA PLATAFORMA y/o de la APP o del público o que podría crear responsabilidad para el <b>GOBIERNO DE OAXACA</b> o a cualquier otro Usuario. Sin perjuicio de lo anterior, el <b>GOBIERNO DE OAXACA</b> no puede y no se compromete a revisar todo el Contenido del Usuario antes de que se publique en LA PLATAFORMA y/o en la APP y no puede garantizar el retiro inmediato del material objetable después de que haya sido publicado. Asimismo, el <b>GOBIERNO DE OAXACA</b> no asume ninguna responsabilidad por ninguna acción o falta de acción con respecto a las transmisiones, comunicaciones o contenido proporcionado por cualquier Usuario o tercero. El <b>GOBIERNO DE OAXACA</b> no tiene ninguna responsabilidad ante nadie por la realización o falta de realización de las actividades descritas en esta sección.</p>
            <p>5.2.8.5. Si el Usuario considera que cualquier Contenido del Usuario viola sus derechos de autor, deberán enviar una notificación al <b>GOBIERNO DE OAXACA</b> a la dirección electrónica recursoshumanos.admon@oaxaca.gob.mx. Es política del <b>GOBIERNO DE OAXACA</b> cancelar las cuentas de Usuario de infractores reincidentes.</p>
            <p><b>5.3. Formatos y registro</b></p>
            <p>5.3.1. Para acceder a LOS SERVICIOS que se ofrecen en LA PLATAFORMA y/o en la APP será necesario que el Usuario se registre, proporcionando al <b>GOBIERNO DE OAXACA</b> los datos que éste requiera para el registro.</p>
            <p>5.3.2. Los Usuarios reconocen que al proporcionar la información de carácter personal requerida en alguno de LOS SERVICIOS que se prestan en LA PLATAFORMA y/o en la APP, otorgan al <b>GOBIERNO DE OAXACA</b> la autorización señalada en el artículo 109 de la Ley Federal del Derecho de Autor.</p>
            <p>5.3.3. El Usuario acepta y reconoce que los datos que proporcione, serán los correctos y que, en caso de haber proporcionado información errónea, inapropiada o falsa al <b>GOBIERNO DE OAXACA</b>, que llevara al Usuario a hacerse de una cosa y/o a obtener un lucro indebido, estaría cometiendo una conducta ilegal, por lo que desde ahora el <b>GOBIERNO DE OAXACA</b> se reserva el derecho de iniciar las acciones a que haya lugar ante las autoridades competentes.</p>
            <p>5.3.4. En todos los casos, los Usuarios responderán, bajo protesta de decir verdad, de la veracidad de la información proporcionada al <p>GOBIERNO DE OAXACA</p>, sacando a éste en paz y a salvo, por cualquier información proporcionada por el Usuario de forma falsa o incorrecta, o bien, cuando no tenga derecho o legitimación para hacerlo.</p>

            <p><h6>6. Responsabilidad y Daños y perjuicios</h6></p>
            <p><b>6.1. Garantías y reconocimientos</b></p>
            <p>6.1.1. El Usuario está de acuerdo con que el uso de LA PLATAFORMA y de la APP se realiza bajo su propio riesgo, LOS SERVICIOS que se prestan y ofrecen en LA PLATAFORMA y en la APP, se prevén sobre una base "tal cual" y "según sean disponibles", y que el funcionamiento de LA PLATAFORMA y de la APP podrá depender de terceros proveedores de servicios de Internet y electrónicos, y que por lo tanto el <b>GOBIERNO DE OAXACA</b> no puede garantizar al cien por ciento y en todo momento, la disponibilidad de LA PLATAFORMA y/o de la APP. El <b>GOBIERNO DE OAXACA</b> no garantiza que LA PLATAFORMA y/o la APP satisfagan al cien por ciento los requerimientos del Usuario, o que LOS SERVICIOS de LA PLATAFORMA y/o de la APP, se mantengan siempre ininterrumpidos, en tiempo, seguros o libres de errores. Siempre que sea razonablemente posible, el <b>GOBIERNO DE OAXACA</b> llevará a cabo las medidas inmediatas pertinentes a fin de no afectar el tiempo de espera del Usuario, la disponibilidad de LA PLATAFORMA y la APP, así como en caso de fuerza mayor, buscar las medidas alternas que garanticen al Usuario la adecuada prestación de LOS SERVICIOS y/o provisión de los productos.</p>
            <p>6.1.2. El <b>GOBIERNO DE OAXACA</b> no garantiza o avala en ninguna forma la veracidad, precisión, legalidad, moralidad o ninguna otra característica del contenido del material que se publique en LA PLATAFORMA y/o en la APP. El <b>GOBIERNO DE OAXACA</b> se libera de cualquier responsabilidad y condiciones, tanto expresas como implícitas, en relación con LOS SERVICIOS e información contenida o disponible en o a través de LA PLATAFORMA y/o la APP, incluyendo, sin limitación alguna:</p>
            <p>
                <ol type="a">
                    <li>La disponibilidad de uso de LA PLATAFORMA y/o de la APP, por motivo de problemas técnicos imputables a los sistemas de comunicación y transmisión de datos.</li>
                    <li>La ausencia de virus, errores, desactivadores o cualquier otro material contaminante o con funciones destructivas en la información o programas disponibles en o a través de LA PLATAFORMA y/o de la APP, o en general, cualquier falla en LA PLATAFORMA y/o en la APP.</li>
                </ol>
            </p>
            <p><b>6.2. Limitaciones a la responsabilidad</b></p>
            <p>6.2.1. Hasta el máximo permitido por las leyes aplicables, el <b>GOBIERNO DE OAXACA</b> no será responsable, en ningún caso, por daños directos, especiales, incidentales, indirectos, o consecuenciales que en cualquier forma se deriven o se relacionen con, de manera enunciativa más no limitativa con:</p>
            <p>
                <ol type="a">
                    <li>El uso o ejecución de LA PLATAFORMA y/o de la APP, con el retraso o falta de disponibilidad de uso de las mismas.</li>
                    <li>La proveeduría de o falta de proveeduría de servicios de cualesquier información o gráficos contenidos o publicados en o a través de LA PLATAFORMA y/o de la APP.</li>
                    <li>De la actualización o falta de actualización de la información.</li>
                    <li>Cualquier otro aspecto o característica de la información contenida o publicada en LA PLATAFORMA y/o en la APP o a través de las ligas que eventualmente se incluyan en las mismas.</li>
                    <li>La proveeduría o falta de proveeduría de los demás servicios, aún en los casos en que se le hubiere notificado o avisado al <b>GOBIERNO DE OAXACA</b> acerca de la posibilidad de que se ocasionaran dichos daños.</li>
                </ol>
            </p>
            <p><b>6.3. Daños y Perjuicios</b></p>
            <p>6.3.1. El Usuario reconoce y acepta que no podrá beneficiarse de ninguna manera de LA PLATAFORMA y/o de la APP para obtener un lucro directo que le ocasione un perjuicio o afectación económica al <b>GOBIERNO DE OAXACA</b>.</p>
            <p>6.3.2. Tampoco podrá el Usuario usurpar la identidad del <b>GOBIERNO DE OAXACA</b>.</p>
            <p>6.3.3. En caso de incumplir con lo establecido en el presente apartado, el Usuario deberá pagar al <b>GOBIERNO DE OAXACA</b> la cantidad que resulte por concepto de la afectación económica que le haya causado al <b>GOBIERNO DE OAXACA</b> así como los importes que el <b>GOBIERNO DE OAXACA</b> haya erogado por concepto de gastos y honorarios de abogados.</p>
            <p><b>6.4. Excluyente de responsabilidad</b></p>
            <p>6.4.1. Ocasionalmente, el <b>GOBIERNO DE OAXACA</b> podrá adicionar a los términos y condiciones del presente, provisiones adicionales relativas a áreas específicas o nuevos servicios que se proporcionen en o a través de LA PLATAFORMA y/o de la APP ("Términos Adicionales"), los cuales serán publicados en las áreas específicas de nuevos servicios de LA PLATAFORMA y/o de la APP para su lectura y aceptación. El Usuario reconoce y acepta que dichos Términos Adicionales forman parte integrante del presente contrato para todos los efectos legales a que haya lugar.</p>
            <p>6.4.2. El <b>GOBIERNO DE OAXACA</b> no tiene obligación de controlar y no controla la utilización que los Usuarios hacen de LA PLATAFORMA, y/o de la APP de LOS SERVICIOS utilizados y de los Contenidos.</p>
            <p>6.4.3. En particular, el <b>GOBIERNO DE OAXACA</b> no garantiza que los Usuarios utilicen LA PLATAFORMA y/o la APP, LOS SERVICIOS y los Contenidos de conformidad con estas Condiciones Generales y, en su caso, las Condiciones Particulares aplicables, ni que lo hagan de forma diligente y prudente. El <b>GOBIERNO DE OAXACA</b> tampoco tiene la obligación de verificar y no verifica la identidad de los Usuarios, ni la veracidad, vigencia, exhaustividad y/o autenticidad de los datos que los Usuarios proporcionan sobre sí mismos a otros Usuarios.</p>
            <p>6.4.4. EL <b>GOBIERNO DE OAXACA</b> NO ASUME RESPONSABILIDAD ALGUNA POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA QUE PUDIERAN DERIVARSE DE LA UTILIZACIÓN DE LOS PRODUCTOS, SERVICIOS Y DE LOS CONTENIDOS POR PARTE DE LOS USUARIOS O QUE PUEDAN DERIVARSE DE LA FALTA DE VERACIDAD, VIGENCIA, EXHAUSTIVIDAD Y/O AUTENTICIDAD DE LA INFORMACIÓN QUE LOS USUARIOS PROPORCIONAN Y, EN PARTICULAR, AUNQUE NO DE FORMA EXCLUSIVA, POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA QUE PUEDAN DERIVARSE DE LA SUPLANTACIÓN DE LA IDENTIDAD DE UN TERCERO EFECTUADA POR UN USUARIO EN CUALQUIER CLASE DE COMUNICACIÓN REALIZADA A TRAVÉS DE LA PLATAFORMA ASÍ COMO DE LA CALIDAD E INTEGRIDAD DE LOS PRODUCTOS Y SERVICIOS DE TERCEROS PROVISTOS POR EL <b>GOBIERNO DE OAXACA</b>.</p>
            <p><b>6.5. Indemnización</b></p>
            <p>6.5.1. El Usuario está de acuerdo en indemnizar al <b>GOBIERNO DE OAXACA</b> y a su equipo de cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de cualquier incumplimiento por parte del Usuario al presente contrato, incluyendo, sin limitación de alguna de las derivadas:</p>
            <p>
                <ol type="a">
                    <li>De cualquier aspecto relativo al uso de LA PLATAFORMA y/o de la APP;</li>
                    <li>De la información contenida o disponible en o a través de LA PLATAFORMA y/o de la APP;</li>
                    <li>De injurias, difamación o cualquier otra conducta violatoria del presente contrato por parte del Usuario en el uso de LA PLATAFORMA y/o de la APP; </li>
                    <li>De violación a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad intelectual.</li>
                </ol>
            </p>

            <p><h6>7. Misceláneas</h6></p>
            <p><b>7.1. Modificaciones a LA PLATAFORMA, a la APP y al contrato</b></p>
            <p>7.1.1. El <b>GOBIERNO DE OAXACA</b> se reserva el derecho de modificar los términos y condiciones de este contrato en cualquier tiempo, siendo efectivas dichas modificaciones de forma inmediata:</p>
            <p>
                <ol type="a">
                    <li>Por la notificación al Usuario sobre dichas modificaciones, a través de las direcciones de correo electrónico indicadas en LA PLATAFORMA y/o en la APP al momento del registro, previo a que dichas modificaciones entren en vigor. El Usuario está de acuerdo en revisar este contrato periódicamente a fin de estar al tanto de dichas modificaciones. No obstante lo anterior, cada ocasión que el Usuario haga uso de LA PLATAFORMA y/o de la APP, se considerará como aceptación absoluta a las modificaciones del contrato.</li>
                </ol>
            </p>
            <p><b>7.2. Cesión de derechos</b></p>
            <p>7.2.1. El <b>GOBIERNO DE OAXACA</b> podrá, en cualquier tiempo y cuando así lo estime conveniente, ceder total o parcialmente sus derechos y obligaciones derivados del presente contrato. En virtud de dicha cesión, el <b>GOBIERNO DE OAXACA</b> quedará liberada de cualquier obligación a favor del Usuario establecida en el presente contrato.</p>
            <p><b>7.3. Terminación</b></p>
            <p>7.3.1. El <b>GOBIERNO DE OAXACA</b> se reserva el derecho, a su exclusiva discreción, y sin necesidad de aviso o notificación al Usuario, para:</p>
            <p>7.3.2. Terminar definitivamente el presente contrato;</p>
            <p>7.3.3. Descontinuar o dejar de publicar definitivamente en LA PLATAFORMA y/o en la APP sin responsabilidad alguna para el <b>GOBIERNO DE OAXACA</b>, sus afiliados o proveedores.</p>
            <p><b>7.4. Notificaciones distintas a derechos de autor</b></p>
            <p>7.4.1. Cualquier notificación que el Usuario desee enviar al <b>GOBIERNO DE OAXACA</b> en virtud del presente contrato, deberá ser enviada vía correo electrónico a la dirección recursoshumanos.admon@oaxaca.gob.mx.</p>
            <p><b>7.5. Subsistencia</b></p>
            <p>7.5.1. Estos términos y condiciones, así como los Términos Adicionales, constituyen el acuerdo íntegro entre las partes, y sustituyen cualquier otro acuerdo o convenio celebrado con anterioridad. Cualesquier cláusula o provisión del presente contrato así como de los Términos Adicionales, legalmente declarada inválida, será eliminada o modificada, a elección del <b>GOBIERNO DE OAXACA</b>, a fin de corregir su vicio o defecto. Sin embargo, el resto de las cláusulas o provisiones mantendrán su fuerza, obligatoriedad y validez.</p>
            <p><b>7.6. No renuncia de derechos</b></p>
            <p>7.6.1. La inactividad por parte del <b>GOBIERNO DE OAXACA</b>, sus afiliados o proveedores al ejercicio de cualquier derecho o acción derivados del presente contrato, en ningún momento deberá interpretarse como renuncia a dichos derechos o acciones.</p>

            <p><h6>8. Legislación aplicable y jurisdicción</h6></p>

            <p>8.1. En caso de que se presente una controversia que se derive del presente contrato o se relacione con él, las partes se obligan a tratar de llegar a una transacción amistosa de esa controversia mediante la mediación; ésta tendrá lugar de conformidad con el Código Civil del Estado de Oaxaca. De no ser así, las partes podrán acudir ante los Tribunales del Estado de Oaxaca, renunciando a cualquier otro fuero que pudiera corresponderles, quedando este contrato a la interpretación de acuerdo con las leyes de la República Mexicana.</p>

            <p><b>Manifiesto que he leído de manera detallada, completa y consciente los presentes términos y condiciones, que he entendido el contenido incluido en los mismos y que, adicionalmente, he recibido asesoramiento legal para obtener una adecuada comprensión de dichos términos y condiciones, y en consecuencia acepto y otorgo mi consentimiento expreso a los presentes términos y condiciones de uso.</b></p>
        </div>
    );
};

export default TerminosyCondiciones;