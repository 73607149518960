import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, tipos_secretaria_o_dependencia, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const TiposSecretariaDependencia = ({ id, permisos }) => {

    const [tiposSecDep, setTiposSecDep] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}/dependencia/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTiposSecDep(res.data.dataResponse);
                    break;
                case 404:
                    setTiposSecDep([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                tiposSecDep
                ?   <DataTableService id={id} title={ tipos_secretaria_o_dependencia }
                        columns={[
                            { field: "nombre", title: "Nombre" }
                        ]}
                        data={tiposSecDep}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default TiposSecretariaDependencia;
