import React, { useState, useEffect, useRef } from 'react';

import Axios from 'axios';
import { servicios_grupohit, tabuladores_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import DinamicTable from './DinamicTable';
import DataTableService from '../../../DataTableService/DataTableService'; 
import ConvertDate from '../../../Helpers/ConvertDate';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Tabuladores = ({ id, permisos }) => {
    const [dinamicTable, setDinamicTable] = useState(false);

    const [tabuladoresBack, setTabuladoresBack]= useState(null);
    const [tabuladores, setTabuladores]= useState(null);
    const [idTab, setIdTab]=useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const fechainiRef = useRef(null);
    const obtenerRelacioLaboralLS = () => {
        return JSON.parse(localStorage.getItem("IGRelLab"));
    };

    useEffect(()=>{
        Axios.get(`${servicios_grupohit}/org/tab/periodos`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setTabuladoresBack(res.data.dataResponse);
                    var response = res.data.dataResponse;
                    response.map(( key, index)=>{
                        for (const prop in key) {
                            if(prop!=='id' && key[prop]!==null) {
                                response[index][prop] = ConvertDate(longDateToInput(key[prop]));
                            }
                            if( key[prop]===null) response[index][prop] ="-";
                        }
                        return null;
                    }); 
                    setTabuladores(response);
                    break;
                case 404:
                    setTabuladores([]);
                    setTabuladoresBack([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]); 
    
    const [puestos, setPuestos] = useState(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupohit}/org/puesto/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    const relacionLaboralLS = obtenerRelacioLaboralLS();
                    var response=[];
                    res.data.dataResponse.map((key, index)=>{
                        if(key.relabId.id===relacionLaboralLS.id){
                            response.push({puestoId:res.data.dataResponse[index].id, nivel:res.data.dataResponse[index].nvlId.nivlaboralClave, codigo:res.data.dataResponse[index].puestoCve, puesto: res.data.dataResponse[index].puestoNom});
                        }
                        return null;
                    })
                    setPuestos(response);
                    break;
                case 404:
                    setPuestos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);
    
    const showTable=(value, elemmentSelect=null, fechaIn=null, fechaSave=null)=>{
        setIdTab(elemmentSelect);
        fechainiRef.current=fechaIn;
        setDinamicTable(value);
        if(fechaSave!==null){
            
            if(tabuladores.length>=1){
                tabuladores[0].fechafin=ConvertDate(longDateToInput(fechaSave-86400000)); 
            } 
            var newData={id: elemmentSelect, fechaini: ConvertDate(longDateToInput(fechaSave)), fechafin: '-'}; 
            setTabuladoresBack([ newData, ...tabuladoresBack ]);
            setTabuladores([ newData, ...tabuladores ]);
        }

        //return value;
    }

   return (
       <div id={`modulo${id}`}> 
                {
                    dinamicTable
                    ?
                    <div>
                        {puestos && <DinamicTable puestos={puestos} permisos={permisos} tabuladoresBack={tabuladoresBack} idTab={idTab} fechainic={fechainiRef.current} onShowTable={showTable} idModulo={ id } /> }
                   </div>
                   :
                   <div>
                   {
                        tabuladores
                        ?
                        <DataTableService 
                            id={id}
                            title={ tabuladores_ }
                            columns={[
                                { field: "fechaini", title: "Fecha de Inicio" },
                                { field: "fechafin", title: "Fecha Final" },
                            ]}
                            data={tabuladores}
                            onShowTable={showTable}
                            permisos={permisos}
                            informacionAdicional={{tabulador: tabuladores.find(element=> element.fechafin==='-')}}
                            />
                        :<SpinnerOval/>
                    }
                    </div>
                }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
       </div>

   );
}
 
export default Tabuladores;




