import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_url } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
//import FormularioUsuario from './FormularioUsuario';
//import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

import { MuiThemeProvider } from '@material-ui/core';
import { theme } from '../CollapseMaterial/MaterialTheme';

import Checkbox from '@material-ui/core/Checkbox';
import AutocompleteComponent from '../../../../../../../../Services/Autocomplete/AutocompleteComponent';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import FileCopy from '@material-ui/icons/FileCopy';

import { obtenerAccesos } from '../CollapseMaterial/HelperModulos';
import { pushSuccess, pushError } from '../../../../../../../../Services/Notifications/PushService';

const CopiarAccesos = ({ usuarios, setAccesosMenu }) => {
    const [ selectCopy, setSelectCopy ] = useState( false );
    const [ usuarioCopy, setUsuarioCopy ] = useState( null );
    const [ errorUsuarioCopy, setErrorUsuarioCopy ] = useState( null );

    const validarUsuario = (valor) => {
        if(valor) {
            setErrorUsuarioCopy( null );
            setUsuarioCopy( valor );
        }else{
            setErrorUsuarioCopy( true );
            setUsuarioCopy( null );
        }
    };

    const axiosUsuario = () => {
        axios.get( `${servicios_grupohit_url}/info/and/permissions/${usuarioCopy.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setAccesosMenu( obtenerAccesos(respuesta.data.dataResponse.menu) );
                        pushSuccess({ title: 'Los accesos se han copiado correctamente' });
                        break;
                    default:
                        pushError({ title: 'No se han podido copiar los accesos' });
                        break;
                }
            })
            .catch( error => {
                pushError({ title: 'No se han podido copiar los accesos' });
            })
    }

    return (
        <MuiThemeProvider theme={theme}>
            <Checkbox
                checked={selectCopy}
                onChange={ () => { setSelectCopy( !selectCopy ); setErrorUsuarioCopy( null ); } }
            />
            Copiar Accesos de Otro Usuario

            { selectCopy &&
                <div className="accesosContenido paddingCopiarAccesos">
                    <div className="copiarAccesosIzquierdo">
                        <AutocompleteComponent id="copiarAccesos" title="" placeholder="Seleccione un usuario" size="100%" action={validarUsuario}
                            options={usuarios} optionListView={'usuarioCompleto'} error={errorUsuarioCopy}
                            value={usuarioCopy}/>
                    </div>
                    <div className="copiarAccesoserecho btn-upload">
                        { usuarioCopy ?
                            <Tooltip title="Copiar accesos" placement="top-start">
                                <Button variant="contained" className="btn-color" onClick={() => axiosUsuario()}> <FileCopy/> </Button>
                            </Tooltip>
                            :
                            <Button variant="contained" className="btn-color" disabled> <FileCopy/> </Button>
                        }
                    </div>
                </div>
            }
        </MuiThemeProvider>
    );
};

export default CopiarAccesos;