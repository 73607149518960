import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTipoPasatiempo from './FormTipoPasatiempo';

const ModalAddTipoPasatiempo = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenAdd(false);
    };

    const enviarTipoPasatiempo = tipoPasatiempo => {
        setIsLoading(true);
        const info = tipoPasatiempo;
        Axios.post(`${servicios_grupohit_catalogo}/pasatiempos/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService title="Agregar Tipo de Pasatiempo" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTipoPasatiempo
                        enviarTipoPasatiempo={enviarTipoPasatiempo}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddTipoPasatiempo;