import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTiposSecDep from './FormTiposSecDep';

const ModalEditTipoSecDep = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [isLoading, setIsLoading] = useState( false );

    const cerrarModal = () => {
        setOpenEdit( false );
    }

    const actualizarTipoSecDep = ( tipoSecDep, tipoSecDepId ) => {
        setIsLoading(true);
        const info = { id: tipoSecDepId, nombre: tipoSecDep.nombre };
        Axios.put( `${servicios_grupohit_catalogo}/dependencia/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar(res.data.dataResponse);
                setOpenEdit( false );
                break;
            default:
                errorTable(res.data.msg);
                break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Tipo de Secretaría o Dependencia" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTiposSecDep
                        elementSelect={elementSelect}
                        actualizarTipoSecDep={actualizarTipoSecDep}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTipoSecDep;