import axios from "axios";
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh } from '../../../../../../../Constants/Constants';

const servicioFechaMinima = ({ arrayEmpleados }) => {
    try {
        const respuesta = axios.post(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/fechamaxima/`, { empIds: arrayEmpleados }, headersAuth())
        return respuesta;
    } catch (error) {
        return error;
    }
};

export default servicioFechaMinima;
