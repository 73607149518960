import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_admon, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import FormularioDomicilios from './FormularioDomicilios';

const ModalAddDomicilio = ({ openAdd, setOpenAdd, agregar, errorTable, informacion, idModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarDomicilio = ( domicilio ) => {
        axios.post( `${servicios_grupohit_admon}/compania/domicilios`, domicilio, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Agregar Domicilio" parentNode={idModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioDomicilios
                    error={error}
                    enviarDomicilio={enviarDomicilio}
                    informacion={informacion}
                />
        </ModalService>
    );
};

export default ModalAddDomicilio;