import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, bases_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Bases = ({ id, permisos }) => {

    const [bases, setBases] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false); 

    useEffect(() => {
        axios.get(`${servicios_grupohit_catalogo}/bases/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setBases(res.data.dataResponse);
                    break;
                case 404:
                    setBases([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    }, []);

    return (
        <div id={`modulo${id}`}>
            {   
                (bases)
                ?   <DataTableService id={id} title={bases_}
                        columns={[
                            { field: "baseClave", title: "Clave" },
                            { field: "baseNom", title: "Nombre" },
                            { field: "baseDescrip", title: "Descripción" },
                        ]}
                        data={bases}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default Bases;
