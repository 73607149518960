import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import axios from 'axios';
import { servicios_grupohit_catalogo, servicios_grupohit_rh, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import ConvertDate from '../../../Helpers/ConvertDate';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import TitleAusentismosTable from './components/TitleAusentismosTable';
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import AusentismosTable from './tables/AusentismosTable';
import EmpleadosTable from './tables/EmpleadosTable';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Ausentismos = ({ id, permisos, obtenerEmpleadosActivos, empleadosActivos }) => {

    const [nominas, setNominas] = useState(null);
    const [ausentismos, setAusentismos] = useState(null);
    const [tiposAusentismo, setTiposAusentismo] = useState([]);
    const [tablaAusentismos, setTablaAusentismos] = useState(false);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        //empleados activos
        obtenerEmpleadosActivos((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });

        axios.get(`${servicios_grupohit_catalogo}/tipoausentismo/`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTiposAusentismo(res.data.dataResponse);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
        .then( res => {
            switch(res.data.status) {
                case 200:
                    setNominas(res.data.dataResponse);
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mostrarTablaAusentismos = selected => {
        setTablaAusentismos(true);
        axios.get(`${servicios_grupohit_rh}/empleado/ausentismo/${selected.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const data = res.data.dataResponse.map(item => (
                        {
                            id: item.id,
                            ausentismoId: item.ausentismo.id,
                            ausentismoNom: item.ausentismo.ausentismoNom,
                            auseFechaIni: (item.auseFechaIni) ? ConvertDate(item.auseFechaIni) : '--/--/----',
                            auseFechaIniInput: (item.auseFechaIni) ? longDateToInput(item.auseFechaIni) : '',
                            auseFechaFin: (item.auseFechaFin) ? ConvertDate(item.auseFechaFin) : '--/--/----',
                            auseFechaFinInput: (item.auseFechaFin) ? longDateToInput(item.auseFechaFin) : '',
                            auseFechaAplica: ConvertDate(item.auseFechaAplica),
                            auseFechaAplicaInput: longDateToInput(item.auseFechaAplica),
                            comentarios: item.comentarios,
                            cantidad: item.cantidad.toFixed(2)
                        }
                    ));
                    setAusentismos({ empleado: selected, data });
                    break;
                case 404:
                    setAusentismos({ empleado: selected, data: [] });
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    setTablaAusentismos(false);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
            setTablaAusentismos(false);
        });
    };

    const mostrarTablaEmpleados = () => {
        setTablaAusentismos(false);
        setAusentismos(null);
    };

    return (
        <div id={`modulo${id}`}>
            {
                (tablaAusentismos)
                ?   (ausentismos && ausentismos.empleado && ausentismos.data)
                    ?   <Fragment>
                            <BtnBack tooltipText="Regresar a tabla de empleados" action={mostrarTablaEmpleados}/>
                            <CardComponent title={<TitleAusentismosTable empleado={ausentismos.empleado}/>}>
                                <AusentismosTable id={id} permisos={permisos}
                                    empleado={ausentismos.empleado} data={ausentismos.data}
                                    tiposAusentismo={tiposAusentismo}
                                />
                            </CardComponent>
                        </Fragment>
                    :   <SpinnerOval />
                :   (empleadosActivos && nominas)
                    ?   <EmpleadosTable id={id} permisos={permisos} informacion={{ empleados: empleadosActivos, nominas }}
                            empleados={empleadosActivos} mostrarTablaAusentismos={mostrarTablaAusentismos}
                        />
                    :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});

const mapDispatchToProps = { obtenerEmpleadosActivos };
 
export default connect(mapStateToProps, mapDispatchToProps)(Ausentismos);
