import React, { createRef, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
//import PasswordValidation from '../../../../../../Services/Validation/PasswordValidation';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import AlertForm from '../../../../../../Services/AlertForm';

import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './AsignacionModulos/CollapseMaterial/MaterialTheme';
import Checkbox from '@material-ui/core/Checkbox';
import { ArrayJsonUsuarios } from '../../../Helpers/JsonToOneLevel';

/* Elementos para la Asignación de Módulos y Permisos */
import ModalAsignacionModulosAgregar from './AgregarUsuario/AsignacionModulosAgregar/ModalAsignacionModulos';
import ModalAsignacionNominaAgregar from './AgregarUsuario/AsignacionNominaAgregar/ModalAsignacionNomina';
import ModalAsignacionModulosEditar from './AsignacionModulos/ModalAsignacionModulos';
import ModalAsignacionNominaEditar from './AsignacionNomina/ModalAsignacionNomina';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const mayusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const minusculas = 'abcdefghijklmnopqrstuvwxyz';
const numeros = '0123456789';
const caracteres = '!@#$&*%/()=?¡.,|°<>;+_';

const generarAleatorio = ( arreglo ) => {
    /* Posicion aleatoria de 0 hasta length-1 de cadena */
    const size = arreglo.length - 1;
    const posicionAleatoria = Math.round(Math.random() * size);
    return arreglo[ posicionAleatoria ];
}

const generarContraseña = () => {
    const a = generarAleatorio( mayusculas );
    const b = generarAleatorio( minusculas );
    const c = generarAleatorio( numeros );
    const d = generarAleatorio( caracteres );
    const e = generarAleatorio( mayusculas );
    const f = generarAleatorio( minusculas );
    const g = generarAleatorio( numeros );
    const h = generarAleatorio( caracteres );
    return ''+a+b+c+d+e+f+g+h;
}

const FormularioUsuario = ({ error, enviarUsuario, elementSelect, actualizarUsuario, informacion, usernameEmail, departamentoUsuario, userSelectSuperior, administrador,
                             idModulo, accesosMenu, setAccesosMenu, accesosNominas, setAccesosNominas,
                             errorTable, successTable, usuario, axiosUsuario, loading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ rol, setRol ] = useState( departamentoUsuario.rol ? departamentoUsuario.rol : false );

    const [ departamento, setDepartamento ] = useState( departamentoUsuario.depto ? informacion.departamentos.find( element => element.id === departamentoUsuario.depto.id ) : null );
    const [ errorDepartamento, setErrorDepartamento ] = useState( null );
    const [ usuarioSuperior, setUsuarioSuperior ] = useState( userSelectSuperior ? ArrayJsonUsuarios(informacion.usuarios).find( element => element.id === userSelectSuperior.id ) : null );
    const [ errorUsuarioSuperior, setErrorUsuarioSuperior ] = useState( null );

    const usuarioNomRef = createRef('');
    const usuarioPatRef = createRef('');
    const usuarioMatRef = createRef('');
    const usuarioCelRef = createRef('');
    const usuarioFecfinRef = createRef('');
    const usuarioDescripRef = createRef('');
    //const passwordRef = createRef('');
    const usernameRef = createRef('');

    const [ openAsign, setOpenAsign ] = useState( false );
    const [ openNomina, setOpenNomina ] = useState( false );

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarUsuario' ) ){
            if( departamento ){
                const username = usernameRef.current.value;
                const password = generarContraseña();

                const usuarioNom = usuarioNomRef.current.value;
                const usuarioPat = usuarioPatRef.current.value;
                const usuarioMat = usuarioMatRef.current.value;
                const usuarioCel = usuarioCelRef.current.value;
                const usuarioFecfin = inputDateToLong( usuarioFecfinRef.current.value );
                const usuarioDescrip = usuarioDescripRef.current.value;

                enviarUsuario({ username, password, datos: deleteOptionals({ usuarioNom, usuarioPat, usuarioMat, usuarioCel, usuarioFecfin, usuarioDescrip }) }, {deptoId: departamento.id, rol});
            }else{
                setErrorDepartamento( <AlertForm message="Selecciona un departamento" /> );
            }
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarUsuario' ) ){
            if( departamento ){
                const usuarioNom = usuarioNomRef.current.value;
                const usuarioPat = usuarioPatRef.current.value;
                const usuarioMat = usuarioMatRef.current.value;
                const usuarioCel = usuarioCelRef.current.value;
                const usuarioFecfin = inputDateToLong( usuarioFecfinRef.current.value );
                const usuarioDescrip = usuarioDescripRef.current.value;
                
                if( usuarioSuperior ){
                    actualizarUsuario(deleteOptionals({ usuarioNom, usuarioPat, usuarioMat, usuarioCel, usuarioFecfin, usuarioDescrip, usuarioSup: usuarioSuperior.id }), elementSelect.id, departamentoUsuario, {deptoId: departamento.id, rol});
                }else{
                    setErrorUsuarioSuperior( <AlertForm message="Selecciona un Usuario Superior" /> );
                }
            }else{
                setErrorDepartamento( <AlertForm message="Selecciona un departamento" /> );
            }
        }
    }

    const validarDepartamento = (valor) => {
        if(valor) {
            setErrorDepartamento( null );
            setDepartamento( valor );
        }else{
            setErrorDepartamento( true );
            setDepartamento( null );
        }
    };

    const validarUsuario = (valor) => {
        if(valor) {
            setErrorUsuarioSuperior( null );
            setUsuarioSuperior( valor );
        }else{
            setErrorUsuarioSuperior( true );
            setUsuarioSuperior( null );
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarUsuario' : 'AgregarUsuario' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                { elementSelect && <span> <b> Usuario: </b> {usernameEmail} </span> }

                <InputValidation title="Nombre" type="usuarioNombre" placeholder="Escriba el nombre del usuario" maxLength='100'
                    tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUserNom'} onlyLetters=".,- "
                    refValue={usuarioNomRef} defaultValue={ elementSelect ? elementSelect.usuarioNom : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Primer Apellido" type="usuarioApellido" placeholder="Escriba el primer apellido del usuario" maxLength='100'
                            tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUserPat'} onlyLetters=".,- "
                            refValue={usuarioPatRef} defaultValue={ elementSelect ? elementSelect.usuarioPat : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Segundo Apellido" type="usuarioApellido" placeholder="Escriba el segundo apellido del usuario" maxLength='100'
                            tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUserMat'} onlyLetters=".,- "
                            refValue={usuarioMatRef} defaultValue={ elementSelect ? elementSelect.usuarioMat : '' }
                            optional={true} />
                    </div>
                </div>

                { !elementSelect &&
                    <React.Fragment>
                        <InputValidation title="Correo Electrónico" type="usuarioCorreo" placeholder="Escriba el correo electrónico del usuario" maxLength='100'
                            tooltipName={'tooltipUserEMail'} tooltipText={'De 6 a 100 letras, números, y/o signos . _ - con la estructura de correo electrónico (incluyendo @)'}
                            refValue={usernameRef} required/>
                    </React.Fragment>
                }

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Número Celular" type="telefono" placeholder="Escriba el número celular del usuario"
                            tooltipText={'10 números'} tooltipName={'tooltipUserCel'} maxLength='10' onlyNumbers
                            refValue={usuarioCelRef} defaultValue={ elementSelect ? elementSelect.usuarioCel : '' }
                            optional={true} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha de Inactivación" refValue={usuarioFecfinRef} optional
                            defaultValue={ elementSelect ? longDateToInput( elementSelect.usuarioFecfin ) : '' }/>
                    </div>
                </div>

                <AutocompleteComponent id="departamentoUsuario" title="Departamento" placeholder="Seleccione la departamento del usuario" size="100%"
                        tooltipName="tooltipUserDepartamento" tooltipText={'Busque el Departamento, por nombre o clave, en el campo de autocompletado'} action={validarDepartamento}
                        options={informacion.departamentos} optionListView={'deptoNom'} optionKey="deptoClave" error={errorDepartamento} required
                        value={departamento}/>

                <InputValidation title="Descripción Usuario" type="descripcionUsuario" placeholder="Escriba la descripción del usuario" maxLength='1024'
                    tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipUserDesc'}
                    refValue={usuarioDescripRef} defaultValue={ elementSelect ? elementSelect.usuarioDescrip : '' }
                    optional={true} />
                
                {(administrador) &&
                    <MuiThemeProvider theme={theme}>
                        <Checkbox
                            checked={rol}
                            onChange={ () => { setRol( !rol ); } }
                        /> Permisos de Administrador
                    </MuiThemeProvider>
                }

                { administrador && elementSelect &&
                    <AutocompleteComponent id="usuarioSuperior" title="Usuario Superior" placeholder="Seleccione un usuario" size="100%" action={validarUsuario}
                        options={ArrayJsonUsuarios(informacion.usuarios.filter(user => user.id !== usuario.perfil.id))} optionListView={'usuarioCompleto'} error={errorUsuarioSuperior}
                        value={usuarioSuperior}/>
                }

                {error}
                {errorDepartamento}
                {errorUsuarioSuperior}

                <div className="row mt-2">
                    <div className="col-md-6 col-sm-12">
                        <Button className="btn-third btn-with" onClick={() => setOpenAsign( true )}> Asignar Módulos </Button>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Button className="btn-third btn-with" onClick={() => setOpenNomina( true )}> Asignar Nómina </Button>
                    </div>
                </div>

                { !elementSelect ? //Elementos para la Asignación de Módulos y Permisos
                    <React.Fragment>
                        <ModalAsignacionModulosAgregar openAsign={openAsign} setOpenAsign={setOpenAsign}
                            informacion={{ usuarios: informacion.usuarios }} idModulo={idModulo} accesosMenu={accesosMenu} setAccesosMenu={setAccesosMenu}/>
                        <ModalAsignacionNominaAgregar openNomina={openNomina} setOpenNomina={setOpenNomina}
                            informacion={{}} idModulo={idModulo} accesosNominas={accesosNominas} setAccesosNominas={setAccesosNominas}/>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <ModalAsignacionModulosEditar openAsign={openAsign} setOpenAsign={setOpenAsign} menuUsuario={usuario.menu} errorTable={errorTable} successTable={successTable}
                            informacion={{ usuarioID: usuario.perfil.id, username: usuario.perfil.username, usuarios: informacion.usuarios }} axiosUsuario={axiosUsuario} idModulo={idModulo}/>
                        <ModalAsignacionNominaEditar openNomina={openNomina} setOpenNomina={setOpenNomina} errorTable={errorTable} successTable={successTable}
                            informacion={{ usuarioID: usuario.perfil.id, username: usuario.perfil.username }} idModulo={idModulo}/>
                    </React.Fragment>
                }
                <div className={`row ${(loading) && 'mt-2'}`}>
                    <div className="col">
                    {loading
                    ?   <SpinnerOval/>
                    :   <Button variant="contained" className="btn-color mt-3" type="submit">
                            { elementSelect ? 'Actualizar' : 'Agregar' }
                        </Button>
                    }
                    </div>
                </div>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    administrador: state.SessionReducer.administrador,
});

export default connect(mapStateToProps, null)(FormularioUsuario);