import React, { useState, createRef } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { hoyMilisegundos, inputDateToLong, validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import { registro_sera_actualizado, servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import ModalService from '../../../../ModalService/ModalService';
import Button from '@material-ui/core/Button';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../../Services/AlertForm';
import ConvertDate from '../../../../Helpers/ConvertDate';
import { jsonHistoricoAntiguedad } from '../../../../Helpers/JsonToOneLevel';

const ModalEditAntiguedad = ({ nombreModulo, openEdit, setOpenEdit, elementSelect, editar, errorTable }) => {
    
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const fechaInicioAntiguedadRef = createRef('');

    const cerrarModal = () => {
        setOpenEdit(false);
    };

    const validarFecha = () => {
        if(validarSubmit('editHistoricoAntiguedad')) {
            const fecha = inputDateToLong(fechaInicioAntiguedadRef.current.value);

            const fechaFin = (elementSelect.empantfecfinLong) ? elementSelect.empantfecfinLong : hoyMilisegundos();
            if(fecha <= fechaFin) {
                setError(null);
                return true;
            } else {
                setError(<AlertForm message={`La fecha debe ser menor que ${ConvertDate(fechaFin)}`} />);
                return false;
            }
        }
    };

    const abrirAlerta = e => {
        e.preventDefault();
        if(validarSubmit('editHistoricoAntiguedad') && validarFecha()) {
            setError(null);
            setOpenAlert(true);
        }
    };

    const actualizarFechaAntiguedad = () => {
        setIsLoading(true);
        const request = {
            id: elementSelect.id,
            empantfecini: inputDateToLong(fechaInicioAntiguedadRef.current.value)
        };
 
        axios.put(`${servicios_grupohit_rh}/empleado/antiguedad/update/fechaini/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const nuevaAntiguedad = jsonHistoricoAntiguedad(res.data.dataResponse);
                    editar(nuevaAntiguedad);
                    setOpenEdit(false);
                break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService
            title="Actualizar Ingreso a Gobierno"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <form id="editHistoricoAntiguedad" onSubmit={abrirAlerta}>
                        <InputDateValidation
                            title="Nueva Fecha de Ingreso a Gobierno" refValue={fechaInicioAntiguedadRef} defaultValue=""
                            onBlurExecute={validarFecha} validateEqualLess required
                        />
                        { error }
                        {(isLoading)
                        ?   <SpinnerOval />
                        :   <Button variant="contained" className="btn-color" type="submit">Actualizar</Button>
                        }
                    </form>
                </div>
            </div>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizarFechaAntiguedad}/>
        </ModalService>
    );
}
 
export default ModalEditAntiguedad;