import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonMovimientosFijosEmpleado } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioFijos from './FormularioFijos';

const ModalAddFijos = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, idModulo }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
        setIsLoading(false);
    }

    const enviarFijo = ( fijo ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_rh}/movimientos/fijo/`, fijo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonMovimientosFijosEmpleado( respuesta.data.dataResponse, informacionAdicional.pensiones ) );
                        informacionAdicional.axiosFijosEmpleado();
                        setOpenAdd( false );
                        axiosBitacora(respuesta.data.dataResponse.id);
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    const axiosBitacora = (movFijo) => {
        axios.post( `${servicios_grupohit_rh}/empleado/bitacora/`, { empId: informacionAdicional.empleado.id, tipoMovimientoId: 11, movFijo }, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta.data.status );
            })
            .catch( error => {
                //errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Agregar Movimiento Fijo" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioFijos
                    error={error}
                    informacion={informacionAdicional}
                    enviarFijo={enviarFijo}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddFijos;