import {
    GUARDA_EMPLEADOS_ALL, GUARDA_EMPLEADOS_ACTIVOS, GUARDA_EMPLEADOS_INACTIVOS,
    BORRA_EMPLEADOS_ALL, BORRA_EMPLEADOS_ACTIVOS, BORRA_EMPLEADOS_INACTIVOS,
    AGREGA_EMPLEADO, EDITA_EMPLEADO, BAJA_EMPLEADO, CANCELA_BAJA_EMPLEADO,
    REINGRESO_EMPLEADO, ELIMINA_EMPLEADO, EDITA_EMPLEADO_INACTIVO, ACTUALIZA_EMPLEADO_ESPECIALES,
    GUARDA_EMPLEADOS_ALL_ERROR, GUARDA_EMPLEADOS_ACTIVOS_ERROR, CAMBIOS_PERMUTA_TRANSFERENCIA,
    GUARDA_EMPLEADOS_INACTIVOS_ERROR, CAMBIOS_ESCALAFON_DEFINITIVO, TERMINA_ESCALAFON, DESHACER_REINGRESO,
    BORRA_EMP_PAGOS_PENDIENTES, MUESTRA_EMP_PAGOS_PENDIENTES
} from '../actionTypes';

const initialstate = {
    empleadosAll: null,
    empleadosActivos: null,
    empleadosInactivos: null,
    pagosPendientes: {
        empPagosPendientes: false,
        retienePagos: false
    }
};

const EmpleadosReducer = (state = initialstate, action) => {
    let relLaboral = { id: null };
    try {
        relLaboral = JSON.parse(localStorage.getItem("IGRelLab"));
    } catch (error) {
    }
    switch(action.type) {
        case GUARDA_EMPLEADOS_ACTIVOS:        
            if(relLaboral.id === action.payload[0]?.relabId) {
                return {
                    ...state,
                    empleadosActivos: action.payload,
                };
            } else {
                return state;
            }
        case GUARDA_EMPLEADOS_ACTIVOS_ERROR:
            return {
                ...state,
                empleadosActivos: []
            };
        case BORRA_EMPLEADOS_ACTIVOS:
            return {
                ...state,
                empleadosActivos: action.payload,
            }
        case GUARDA_EMPLEADOS_ALL:
        case BORRA_EMPLEADOS_ALL:
            return {
                ...state,
                empleadosAll: action.payload,
            }
        case GUARDA_EMPLEADOS_ALL_ERROR:
            return {
                ...state,
                empleadosAll: []
            }
        case GUARDA_EMPLEADOS_INACTIVOS:
        case BORRA_EMPLEADOS_INACTIVOS:
            return {
                ...state,
                empleadosInactivos: action.payload,
            }
        case GUARDA_EMPLEADOS_INACTIVOS_ERROR:
            return {
                ...state,
                empleadosInactivos: []
            }
        case AGREGA_EMPLEADO:
            return {
                ...state,
                empleadosActivos: [...state.empleadosActivos, action.payload.element],
                empleadosAll: [...state.empleadosAll, action.payload.element],
                pagosPendientes: {
                    empPagosPendientes: (action.payload.pagosPendientes) ? action.payload.element : null,
                    retienePagos: (action.payload.pagosPendientes) ? action.payload.retienePagos : false
                } 
            }
        case EDITA_EMPLEADO:
            const newEmpleadosAllEdita = state.empleadosAll.filter(empAll => empAll.id !== action.payload.id);
            const newEmpleadosActivosEdita = state.empleadosActivos.filter(empAct => empAct.id !== action.payload.id);
            return {
                ...state,
                empleadosAll: [action.payload, ...newEmpleadosAllEdita],
                empleadosActivos: [action.payload, ...newEmpleadosActivosEdita],
            }
        case EDITA_EMPLEADO_INACTIVO:
            const newEmpleadosAllEditaI = state.empleadosAll.filter(empAll => empAll.id !== action.payload.id);
            const newEmpleadosInactivosEditaI = state.empleadosInactivos.filter(empInact => {
                if(empInact.id !== action.payload.id) {
                    return action.payload
                } else {
                    return empInact
                }
            });
            return {
                ...state,
                empleadosAll: [action.payload, ...newEmpleadosAllEditaI],
                empleadosInactivos: newEmpleadosInactivosEditaI,
            }    
        case BAJA_EMPLEADO:
            const newEmpleadosActivosBaja = state.empleadosActivos.filter(empA => empA.id !== action.payload.id);
            return {
                ...state,
                empleadosActivos: newEmpleadosActivosBaja,
                empleadosInactivos: (state.empleadosInactivos) ? [action.payload, ...state.empleadosInactivos] : state.empleadosInactivos
            }
        case CANCELA_BAJA_EMPLEADO:
            const newEmpleadosActivosCBaja = [action.payload, ...state.empleadosActivos];
            const newEmpleadosInactivosCBaja = state.empleadosInactivos.filter(empI => empI.id !== action.payload.id);
            return {
                ...state,
                empleadosActivos: newEmpleadosActivosCBaja,
                empleadosInactivos: newEmpleadosInactivosCBaja
            }
        case REINGRESO_EMPLEADO:
            const newEmpleadosActivosReingreso = [action.payload, ...state.empleadosActivos];
            const newEmpleadosInactivosReingreso = state.empleadosInactivos.filter(empIn => empIn.id !== action.payload.id);
            let newEmpleadosAllReingreso = [];
            const existeEnRL = state.empleadosAll.find(empAll => empAll.id !== action.payload.id);
            if(existeEnRL) {
                const empleados = state.empleadosAll.filter(empAll => empAll.id !== action.payload.id);
                newEmpleadosAllReingreso = [action.payload, ...empleados];
            } else {
                newEmpleadosAllReingreso = [action.payload, ...state.empleadosAll];
            }               
            return {
                ...state,
                empleadosAll: newEmpleadosAllReingreso,
                empleadosActivos: newEmpleadosActivosReingreso,
                empleadosInactivos: newEmpleadosInactivosReingreso
            }
        case ELIMINA_EMPLEADO:
            const newEmpleadosAllElimina = state.empleadosAll.filter(empleado => 
                empleado.id !== action.payload
            );
            const newEmpleadosActivosElimina = state.empleadosActivos.filter(empleado => 
                empleado.id !== action.payload
            );
            const newEmpleadosInactivosElimina = state.empleadosInactivos !== null && state.empleadosInactivos.length
            ?   state.empleadosInactivos.filter(empleado => 
                    empleado.id !== action.payload
                )
            :   state.empleadosInactivos;
            return {
                ...state,
                empleadosAll: newEmpleadosAllElimina,
                empleadosActivos: newEmpleadosActivosElimina,
                empleadosInactivos: newEmpleadosInactivosElimina
            }
        case CAMBIOS_ESCALAFON_DEFINITIVO:
            let newEmpActivosED = []
            action.payload.forEach(emp => {
                const empleadoEscalafon = state.empleadosActivos.find(empAct => empAct.id === emp.empId);
                const empleadosSinEscalafon = state.empleadosActivos.filter(empAct => empAct.id !== emp.empId);
                //let indice = state.empleadosActivos.findIndex(item => item.id === emp.empId);
                //if(indice) {
                    empleadoEscalafon.cpresupuestalId = emp.cpresupuestalId;
                    empleadoEscalafon.cpresupuestalClave = emp.cpresupuestalClave;
                    empleadoEscalafon.cpresupuestalNom = emp.cpresupuestalNom;
                    //empleadoEscalafon.cpresupuestalId = emp.empcpresfecini;
                    //empleadoEscalafon.cpresupuestalId = emp.empcpresfecfin;
                    empleadoEscalafon.plazaId = emp.plazaid;
                    empleadoEscalafon.plazaClave = emp.plazaclave;
                    empleadoEscalafon.puestoId = emp.puestoId;
                    empleadoEscalafon.puestoCve = emp.puestoClave;
                    empleadoEscalafon.puestoNom = emp.puestoNom;
                //}
                newEmpActivosED = [ empleadoEscalafon, ...empleadosSinEscalafon ];
            });

            let newEmpAllED = state.empleadosAll;
            action.payload.forEach(emp => {
                const empleadoEscalafon = state.empleadosAll.find(empAct => empAct.id === emp.empId);
                const empleadosSinEscalafon = state.empleadosAll.filter(empAct => empAct.id !== emp.empId);
                //let indice = state.empleadosAll.findIndex(item => item.id === emp.empId);
                //if(indice) {
                    empleadoEscalafon.cpresupuestalId = emp.cpresupuestalId;
                    empleadoEscalafon.cpresupuestalClave = emp.cpresupuestalClave;
                    empleadoEscalafon.cpresupuestalNom = emp.cpresupuestalNom;
                    //empleadoEscalafon.cpresupuestalId = emp.empcpresfecini;
                    //empleadoEscalafon.cpresupuestalId = emp.empcpresfecfin;
                    empleadoEscalafon.plazaId = emp.plazaid;
                    empleadoEscalafon.plazaClave = emp.plazaclave;
                    empleadoEscalafon.puestoId = emp.puestoId;
                    empleadoEscalafon.puestoCve = emp.puestoClave;
                    empleadoEscalafon.puestoNom = emp.puestoNom;
                //}
                newEmpAllED = [ empleadoEscalafon, ...empleadosSinEscalafon ];
            });

            return {
                ...state,
                empleadosActivos: newEmpActivosED,
                empleadosAll: newEmpAllED,
            }
        case TERMINA_ESCALAFON:
            let newEmpActivosTE = state.empleadosActivos.map(emp => {
                const empSearch = action.payload.find(item => item.id === emp.id);
                if(empSearch) {
                    return empSearch;
                } else {
                    return emp;
                }
            });
            let newEmpAllTE = state.empleadosAll.map(emp => {
                const empSearch = action.payload.find(item => item.id === emp.id);
                if(empSearch) {
                    return empSearch;
                } else {
                    return emp;
                }
            });

            return {
                ...state,
                empleadosActivos: newEmpActivosTE,
                empleadosAll: newEmpAllTE,
            }
        case DESHACER_REINGRESO:
            const newEmpleadosActivosDR = state.empleadosActivos.filter(emp => emp.id !== action.payload);

            let newEmpleadosInactivosDR = state.empleadosInactivos;
            if(state.empleadosInactivos !== null) {
                const newEmpleadosInactivo = state.empleadosActivos.find(emp => emp.id === action.payload);
                newEmpleadosInactivosDR = [newEmpleadosInactivo, ...state.empleadosInactivos];
            }
            return {
                ...state,
                empleadosActivos: newEmpleadosActivosDR,
                empleadosInactivos: newEmpleadosInactivosDR
            }
        case CAMBIOS_PERMUTA_TRANSFERENCIA:
            const tipo = (action.payload.length === 2) ? 2 : 1;
            const elementosTrnsPer = action.payload.map(item => {
                let newEmpleado = state.empleadosActivos.find(emp => emp.id === parseInt(item.empleadoId));
                if(newEmpleado) {
                    newEmpleado.areaId = item.areaId;
                    newEmpleado.areaNom = item.areaNom;
                    newEmpleado.ciaId = parseInt(item.ciaId);
                    newEmpleado.ciaRazonSocial = item.ciaRazonSocial;
                    newEmpleado.ciaUr = item.ciaUr;
                    newEmpleado.cpresupuestalId = parseInt(item.presupuestalId);
                    newEmpleado.cpresupuestalClave = item.presupuestalClave;
                    newEmpleado.cpresupuestalNom = item.presupuestalNom;
                    newEmpleado.domciaCalle = item.domicilio.domCiaCalle;
                    newEmpleado.domciaExt = item.domicilio.domCiaExt;
                    newEmpleado.domciaId = item.domicilio.id;
                    newEmpleado.domciaNumext = item.domicilio.domCiaNumExt;
                    newEmpleado.domciaNumint = item.domicilio.domCiaNumInt;
                    newEmpleado.plazaClave = item.plazaClave;
                    newEmpleado.plazaId = item.plazaId;
                    newEmpleado.programaClave = item.areaPresupuestalProyectoProgramaclave;
                    newEmpleado.programaId = parseInt(item.areaPresupuestalProyectoProgramaId);
                    newEmpleado.programaNom = item.areaPresupuestalProyectoProgramaNom;
                    newEmpleado.proyectoClave = item.areaPresupuestalProyectoClave;
                    newEmpleado.proyectoId = item.areaPresupuestalProyectoId;
                    newEmpleado.proyectoNom = item.areaPresupuestalProyectoNom;
                    newEmpleado.tipodomId = item.domicilio.tipoDom.id;
                    newEmpleado.tipodomNom = item.domicilio.tipoDom.tipodomNom;
                    newEmpleado.udejecutoraClave = item.areaPresupuestalProyectoProgramaUdeEjecutoraClave;
                    newEmpleado.udejecutoraId = item.areaPresupuestalProyectoProgramaUdeEjecutoraId;
                    newEmpleado.udejecutoraNom = item.areaPresupuestalProyectoProgramaUdeEjecutoraNom;
                }
                return newEmpleado;
            });

            const newEmpleadosActivosTP = state.empleadosAll.filter(emp => 
                (tipo === 2)
                ?   ((emp.id !== elementosTrnsPer[0].id) && (emp.id !== elementosTrnsPer[1].id))
                :   emp.id !== elementosTrnsPer[0].id
            );
            const newEmpleadosAllTP = state.empleadosAll.filter(emp =>
                (tipo === 2)
                ?   ((emp.id !== elementosTrnsPer[0].id) && (emp.id !== elementosTrnsPer[1].id))
                :   emp.id !== elementosTrnsPer[0].id
            );
            
            return {
                ...state,
                empleadosActivos: [...elementosTrnsPer, ...newEmpleadosActivosTP],
                empleadosAll: [...elementosTrnsPer, ...newEmpleadosAllTP]
            };
        case ACTUALIZA_EMPLEADO_ESPECIALES:
            /*let newEmpleadoEsp = state.empleadosActivos.find(emp => emp.id === action.payload.empId);
            let filterEmpleadosActivosEsp = state.empleadosActivos.filter(emp => emp.id !== action.payload.empId);
            let filterEmpleadosAllEsp = state.empleadosAll.filter(emp => emp.id !== action.payload.empId);
            
            switch(action.payload.tipo) {
                case 'Zona de Pago'.toUpperCase():
                    newEmpleadoEsp.zonaPago = "Gobernador Agustín Vicente Eguia 46 Piso 10  SAN MIGUEL CHAPULTEPEC II SECCIÓN MIGUEL HIDALGO CIUDAD DE MÉXICO México"
                    newEmpleadoEsp.domciaCalle = "Gobernador Agustín Vicente Eguia"
                    newEmpleadoEsp.domciaExt = null
                    newEmpleadoEsp.domciaId = 446
                    newEmpleadoEsp.domciaNumext = "46 Piso 10"
                    newEmpleadoEsp.domciaNumint = null
                    newEmpleadoEsp.domciaTel = null
                    //tipoDom: data.domcia.tipoDom.id,
                    //domCiaCalle:data.domcia.domCiaCalle, 
                    //domCiaNumInt: data.domcia.domCiaNumInt, 
                    //domCiaNumExt: data.domcia.domCiaNumExt, 
                    //domCiaTel: data.domcia.domCiaTel, 
                    //domCiaExt: data.domcia.domCiaExt, 
                    //compania: data.domcia.compania.id,
                    //codPostal: data.domcia.codPostal.codigopId
                    break;
                case 'Turno'.toUpperCase():
                    newEmpleadoEsp.turnoId = 184
                    newEmpleadoEsp.turnoNom = "TEST"
                    //jornadaId: data.turno.jornada.id,
                    //jornadaNombre: data.turno.jornada.nombre,
                    //jornadaClave: data.turno.jornada.clave,
                    //turnoNombre: data.turno.turnoNom
                    break;
                case 'Nómina'.toUpperCase():
                    newEmpleadoEsp.nominaClave = "NQUIN"
                    newEmpleadoEsp.nominaId = 66
                    newEmpleadoEsp.nominaNomCorto = "NOMINTQUINCENAL"
                    newEmpleadoEsp.nominaNombre = "INTERNA"
                    //nomina: data.nomina
                    break;
                case 'Puesto'.toUpperCase():
                    newEmpleadoEsp.puestoCve = "93F"
                    newEmpleadoEsp.puestoId = 1087
                    newEmpleadoEsp.puestoNom = "AUXILIAR PARA TEST"
                    //puesto: data.puesto
                    break;
                case 'Plaza'.toUpperCase():
                    newEmpleadoEsp.plazaClave = "987321"
                    newEmpleadoEsp.plazaId = 20962
                    //plazaid: data.plazaid
                    break;
                case 'Clave Presupuestal / Proyecto'.toUpperCase():
                    newEmpleadoEsp.cpresupuestalClave = "9990010010000000101"
                    newEmpleadoEsp.cpresupuestalId = 982
                    newEmpleadoEsp.cpresupuestalNom = "IGENTER SISTEMAS"
                    //presupuestal: data.presupuestal
                    break;
                default:
                break;
            }
            return {
                ...state,
                empleadosAll: [newEmpleadoEsp, ...filterEmpleadosAllEsp],
                empleadosActivos: [newEmpleadoEsp, ...filterEmpleadosActivosEsp]
            }*/
            return state;
        case MUESTRA_EMP_PAGOS_PENDIENTES:
            return {
                ...state,
                pagosPendientes: {
                    empPagosPendientes: action.payload.pagosPendientes,
                    retienePagos: action.payload.retienePagos,
                }
            }
        case BORRA_EMP_PAGOS_PENDIENTES:
            return {
                ...state,
                pagosPendientes: {
                    retienePagos: false,
                    empPagosPendientes: false,
                }
            }
        default:
            return state;
    }
}

export default EmpleadosReducer;
