import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonMovimientosEmpleado } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioVariables from './FormularioVariables';

const ModalAddMovimientos = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, idModulo }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarMovimiento = ( movimiento ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_rh}/movimientos/`, movimiento, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonMovimientosEmpleado( respuesta.data.dataResponse ));
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Movimiento Variable" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioVariables
                    error={error}
                    informacion={informacionAdicional}
                    enviarMovimiento={enviarMovimiento}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddMovimientos;