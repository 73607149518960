import React, { useState, useRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';

const FormPlantillas = ({ setSecuencia, enviar }) => {
    
    const [openAlert, setOpenAlert] = useState(false);

    const secuenciaRef = useRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        if(validarSubmit('FormSecuencia')) {
            setOpenAlert(true);
        }
    };

    return ( 
        <Fragment>
            <form id="FormSecuencia" onSubmit={mostrarAlert}>
                <InputValidation
                    title="Secuencia" type="secuenciaPlantilla" placeholder="Escriba el valor inicial de la secuencia"
                    refValue={secuenciaRef} defaultValue="" 
                    tooltipText="Máximo 10 números" tooltipName="tooltipAsignaSecuencia"
                    onChangeExecute={setSecuencia}
                    maxLength={10} onlyNumbers required
                />
                <Button variant="contained" className="btn-color" type="submit">Asignar</Button>
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={`El valor inicial de la secuencia será ${secuenciaRef.current?.value}`} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviar}/>
        </Fragment> 
    );
}
export default FormPlantillas;