import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { registro_guardado, servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../../Constants/Constants';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';

const FormCorrespondenciaConceptos = ({ cambiosGuardados, setCambiosGuardados, successTable, errorTable }) => {

    const [conceptosBase, setConceptosBase] = useState(null);
    const [conceptosAll, setConceptosAll] = useState([]);
    const [conceptosAllFilter, setConceptosAllFilter] = useState([]);
    const [conceptosRelacionados, setConceptosRelacionados] = useState([]);

    const [conceptoBaseSelectedLast, setConceptoBaseSelectedLast] = useState(null);
    const [conceptoBaseSelected, setConceptoBaseSelected] = useState(null);
    const [conceptoRelSelected, setConceptoRelSelected] = useState(null);

    const [errorConceptoBase, setErrorConceptoBase] = useState(false);
    const [errorConceptoRel, setErrorConceptoRel] = useState(false);
    const [error, setError] = useState(null);

    const [isLoadingCR, setIsLoadingCR] = useState(false);
    const [isLoadingListCR, setIsLoadingListCR] = useState(false);

    const [openAlertW, setOpenAlertW] = useState(false);

    useEffect(() => {
        axios.get(`${servicios_grupohit_catalogo}/indicador`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const conceptos = res.data.dataResponse.map(item => (
                        {
                            id: item.id,
                            relacionLabId: item.relacionLab.id,
                            relacionLabNombre: item.relacionLab.nombre,
                            claveNombre: `${item.indicadorClave} / ${item.indicadorNom}`,
                            clave: Number(item.indicadorClave),
                            nombre: item.indicadorNom,
                            naturaleza: item.naturaleza.id
                        }
                    ));
                    const conceptosBase = conceptos.filter(item => item.relacionLabId === 7);
                    const conceptosSinBase = conceptos.filter(item => item.relacionLabId !== 7);
                    setConceptosBase(conceptosBase);
                    setConceptosAll(conceptosSinBase);
                    break;
                case 404:
                    setConceptosBase([]);
                    setConceptosAll([]);
                    setError(<AlertForm message={res.data.msg}/>);
                    break;
                default:
                    setConceptosBase([]);
                    setConceptosAll([]);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mostrarAlertW = () => {
        setOpenAlertW(true);
    };

    const regresarConceptoBase = () => {
        setConceptoBaseSelected(conceptoBaseSelectedLast);
    };

    const seleccionarConceptoBase = value => {
        if(value) {
            setIsLoadingCR(true);
            setIsLoadingListCR(true);
            setError(null);
            setCambiosGuardados(true);
            setErrorConceptoBase(false);
            setConceptoBaseSelected(value);
            setConceptoBaseSelectedLast(value);
            axios.get(`${servicios_grupohit_catalogo}/indicador/correspondencia/${value.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const nuevosConceptosAllFilter = conceptosAll.filter(c => res.data.dataResponse.indiCorres.indexOf(c.id) === -1);
                        const nuevosConceptosRelacionados = conceptosAll.filter(c => res.data.dataResponse.indiCorres.indexOf(c.id) > -1);
                        setConceptosAllFilter(nuevosConceptosAllFilter);
                        setConceptosRelacionados(nuevosConceptosRelacionados);
                        break;
                    case 404:
                        setConceptosAllFilter(conceptosAll);
                        setConceptosRelacionados([]);
                        break;    
                    default:
                        setConceptosAllFilter([]);
                        setConceptosRelacionados([]);
                        setError(<AlertForm message={res.data.msg}/>);
                        break;
                }
            })
            .catch(error => {
                setError(<AlertForm message={verifique_conexion}/>);
            })
            .finally(() => {
                setIsLoadingCR(false);
                setIsLoadingListCR(false);
            });
        } else {
            setConceptosAllFilter([]);
            setConceptosRelacionados([]);
            setConceptoBaseSelected(null);
            setConceptoRelSelected(null);
            setCambiosGuardados(true);
        }
    };

    const validarConceptoBase = value => {
        setError(null);
        if(cambiosGuardados) {
            seleccionarConceptoBase(value);
        } else {
            mostrarAlertW(true);
            setConceptoBaseSelected(value);
        }
    };

    const validarConceptoRel = value => {
        setError(null);
        setErrorConceptoRel(false);
        setConceptoRelSelected(value);
    }; 

    const addList = e => {
        e.preventDefault();
        if(conceptoBaseSelected && conceptoRelSelected) {
            //agregar a lista
            const nuevosConceptosRelacionados = [...conceptosRelacionados, conceptoRelSelected].sort((a, b) => a.clave > b.clave ? 1 : a.clave < b.clave ? -1 : 0);
            setConceptosRelacionados(nuevosConceptosRelacionados);
            setCambiosGuardados(false);
            //eliminar de setConceptosAllFilter
            const nuevosConceptosAllFilter = conceptosAllFilter.filter(concepto => concepto.id !== conceptoRelSelected.id).sort((a, b) => a.clave > b.clave ? 1 : a.clave < b.clave ? -1 : 0);
            setConceptosAllFilter(nuevosConceptosAllFilter);
            setConceptoRelSelected(null);
        } else {
            if(!conceptoBaseSelected) setErrorConceptoBase(true);
            if(!conceptoRelSelected) setErrorConceptoRel(true);
        }
        setError(null);
    };

    const enviar = () => {
        if(!cambiosGuardados) {
            const request = {
                id: conceptoBaseSelected.id,
	            indicadorIdCor: conceptosRelacionados.map(c => c.id)
            };
            
            axios.post(`${servicios_grupohit_catalogo}/indicador/correspondencia`, request, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setCambiosGuardados(true);
                        successTable(registro_guardado);
                        setConceptoBaseSelected(null);
                        setConceptosRelacionados([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });
        } else {
            setError(<AlertForm message="No hay cambios por guardar"/>);
        }
    };

    const eliminarConceptoRelacionado = id => {
        const nuevosConceptosRelacionados = conceptosRelacionados.filter(concepto => concepto.id !== id).sort((a, b) => a.clave > b.clave ? 1 : a.clave < b.clave ? -1 : 0);
        setConceptosRelacionados(nuevosConceptosRelacionados);
        const nuevosConceptosAllFilter = [...conceptosAllFilter, conceptosAll.find(concepto => concepto.id === id)].sort((a, b) => a.clave > b.clave ? 1 : a.clave < b.clave ? -1 : 0);
        setConceptosAllFilter(nuevosConceptosAllFilter);
        setCambiosGuardados(false);
    };

    return (
        <Fragment>
            <form id="FormCorrespondenciaConceptos" onSubmit={addList}>
                <div className="row">
                    <div className="col-md-5 col-sm-12">
                        {(conceptosBase === null)
                        ?   <SpinnerOval/>
                        :   <AutocompleteComponent id="indicadorBase" title="Concepto de Nómina de Base a Relacionar"
                                placeholder="Seleccione el Concepto de Nómina de Base a Relacionar" size="100%"
                                tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                options={conceptosBase} optionListView="nombre" optionKey="clave"
                                value={conceptoBaseSelected} action={validarConceptoBase} error={errorConceptoBase} required
                            />
                        }
                    </div>
                    <div className="col-md-5 col-sm-12">
                        {(isLoadingCR)
                        ?   <SpinnerOval/>
                        :   <AutocompleteComponent id="indicadorRelacionado" title="Concepto de Nómina a Basificar"
                                placeholder="Seleccione el Concepto de Nómina a Basificar" size="100%"
                                tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                options={conceptosAllFilter} optionListView="nombre" optionKey="clave"
                                value={conceptoRelSelected} action={validarConceptoRel} error={errorConceptoRel}
                                disabled ={(conceptosAllFilter.length) ? false : true} required
                            />
                        }
                    </div>
                    <div className="col-md-2 col-sm-12 align-self-start alignbtnInput" style={{ marginTop: '35px' }}>
                        <Button className="btn-autocompleteSueldo" type="submit" disabled={(conceptosBase) ? false : true}>
                            <Add/>
                        </Button>
                    </div>
                </div>
            </form>
            {(isLoadingListCR)
            ?   <SpinnerOval />
            :   (conceptosRelacionados)
                ?   <div className="mt-2 mb-2">
                        {conceptosRelacionados.map((concepto) => (
                            <div key={concepto.id} className="row mt-1 mb-1">
                                <div className="col-md-5 col-sm-12">
                                    <div className="listIndicadores without-mr">{ concepto.claveNombre }</div>
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <div className="listIndicadores without-mr">{ concepto.relacionLabNombre }</div>
                                </div>
                                <div className="col-md-2 col-sm-12">
                                    <Button className="btn-listIndicadores" onClick={() => eliminarConceptoRelacionado(concepto.id)}>
                                        <Close/>
                                    </Button>
                                </div>
                            </div>
                        ))}                    
                    </div>
                :   (conceptosRelacionados !== null) && <AlertForm message="No tiene Conceptos Relacionados"/>
            }
            { error }
            <Button variant="contained" className="btn-color" onClick={enviar} disabled={(!conceptosBase || cambiosGuardados) ? true : false}>
                Guardar
            </Button>
            <AlertWarning
                show={openAlertW} SetopenALert={setOpenAlertW} text="No ha guardado cambios. ¿Desea continuar?"
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar"
                action={() => seleccionarConceptoBase(conceptoBaseSelected)} actionCancel={regresarConceptoBase}
            />
        </Fragment>
    );
}
 
export default FormCorrespondenciaConceptos;
