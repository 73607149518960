import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './styles.css';

function PageTitleBar({ title, path }){
    const subPath = path.split('/');

    return(
        <div className="page-title d-flex justify-content-between align-items-center my-3">
            { title && <h2 className="pageTitle"> {title} </h2> }
            {path!=='' ?
            <Breadcrumb tag="nav">
                { subPath.map( (sub, index) => {
                    if( subPath.length === index + 1 ){
                        return <BreadcrumbItem active tag="span" key={index}> {sub} </BreadcrumbItem>
                    }else{
                        return(
                            <BreadcrumbItem tag="span" key={index}>
                                <span style={{color:"#4C638E"}}> {sub} </span>
                            </BreadcrumbItem>
                        )
                    }
                } )}
            </Breadcrumb>
            : null
            }
        </div>
    );
}

export default PageTitleBar;