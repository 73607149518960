import React from 'react';

const AvisoPrivacidad = () => {
    return (
        <div className="informacion-legal">
            <p><h4>AVISO DE PRIVACIDAD SIMPLIFICADO</h4></p><p><h4> SECRETARÍA DE ADMINISTRACIÓN</h4></p>

            <p>La Secretaría de Administración como sujeto responsable, tiene el compromiso de garantizar a las personas, la confiabilidad en el manejo o tratamiento de los datos personales que se le proporcionen, para realizar trámites o servicios, por ello, los datos personales que se recaban, se encuentran protegidos y tratados con la debida confidencialidad, garantizando su privacidad y autodeterminación informática.</p>

            <p><h6>Finalidad del tratamiento o manejo de datos personales: </h6></p>

            <p>Los datos personales que recabe la Secretaría de Administración, serán utilizados para atender las finalidades siguientes: a) Para verificar y corroborar con fines de seguridad, la identidad de quienes ingresan a las instalaciones de la Secretaría de Administración; b) Para integrar los expedientes, bases de datos y sistemas de información relacionados con el personal al servicio del Poder Ejecutivo del Estado; c) Para elaborar y suscribir instrumentos jurídicos en la contratación de personal, de adquisiciones, enajenaciones, arrendamientos de bienes y servicios, así como para la suscripción de convenios entre otros instrumentos que garanticen el ejercicio de las facultades y atribuciones de la Secretaría; d) Para integrar expedientes derivados de procedimientos administrativos; e) Para el ejercicio de las acciones legales que competen a la Secretaría. f) Para el control del padrón de proveedores; g) Para el registro, control y seguimiento de las solicitudes, trámites y servicios efectuados ante la Secretaría; h) Para realizar investigaciones y acciones necesarias para corroborar a través de terceros la información y datos proporcionados a la Secretaría.</p>

            <p><h6>Transferencia de tus datos personales:</h6></p>

            <p>La Secretaría de Administración no realiza transferencia datos personales a terceros, excepto en el caso de actualizarse alguno de los supuestos previstos en los artículos 15 y 62 de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados del Estado de Oaxaca o en el supuesto de que exista el consentimiento expreso del titular de los datos personales por un medio escrito o por un medio de autenticación similar.</p>

            <p><h6>Medios para limitar el tratamiento de datos personales:</h6></p>

            <p>Los datos personales recabados o proporcionados a la Secretaría de Administración, no podr´án ser usados y divulgados en medios electrónicos, escritos o de cualquier otro tipo, cuando exista la manifestación expresa y por escrito del titular de los datos personales, en oponerse a la publicación o divulgación de los mismos.</p>

            <p><h6>Ejercicio de los Derechos al Acceso, Rectificación, Cancelación y Oposición de tus datos personales (A.R.C.O.):</h6></p>

            <p>El titular de los derechos personales o su representante legal, para ejercer los Derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO), podrá acudir a la Unidad de Transparencia de la Secretaría de Administración, sita en el Edificio 2 "José Vasconcelos", Nivel 2, Ciudad Administrativa, Tlalixtac de Cabrera, Oaxaca, Carretera Oaxaca-lstmo Km. 11.5, C.P. 68270. Teléfono conmutador 01(951)501-5000, extensiones 10648 y 10877, en días y horas hábiles.</p>

            <p>También puede consultar nuestro aviso de privacidad integral en el sitio:</p>
            <p><a href="http://www.oaxaca.gob.mx/administracion/"><b>http://www.oaxaca.gob.mx/administracion/</b></a></p>

            <p><h6>Modificaciones al aviso de privacidad.</h6></p>

            <p>El presente aviso de privacidad es susceptible de modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, los cuales se darán a conocer a través de la página oficial de la Secretaría de Administración.</p>
        </div>
    );
};

export default AvisoPrivacidad;