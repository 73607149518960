import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, deleteOptionals } from '../../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import AlertForm from '../../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import { MuiThemeProvider } from '@material-ui/core';
import { theme } from '../../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/MaterialTheme';
import Checkbox from '@material-ui/core/Checkbox';

import SaveAlt from '@material-ui/icons/SaveAlt';
import Delete from '@material-ui/icons/Delete';

const FormularioRetroactivo = ({ error, enviarRetroactivo, eliminarRetroactivo, elementSelect, informacion, setError, actionSelectNomina }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ openAlertDelete, setOpenAlertDelete ] = useState( false );
    const [ conceptos, setConceptos ] = useState( [] );
    const nominaIdRef = createRef('');
    const periodoIdInicialRef = createRef('');
    const periodoIdFinalRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const encontrarPeriodo = ( periodoID ) => {
        const peridoAnio = informacion.nominaPeriodos.find( element => element.id === periodoID ).periodoPerAnio;
        return  (peridoAnio === '9/10')
                ?   10
                :   (peridoAnio === '23/24')
                    ?   24
                    : peridoAnio;
    }

    const enviar = (evt) => {
        evt.preventDefault();
        setError( null );
        if( validarSubmit( 'AgregarRetroactivo' ) ){
            const nominaId = parseInt(nominaIdRef.current.value);
            const periodoIdInicial = parseInt( periodoIdInicialRef.current.value );
            const periodoIdFinal = parseInt( periodoIdFinalRef.current.value );
            if( conceptos.length >= 1 ){
                const tabfechaId = informacion.indicadores.find( element => element.nomindId === conceptos[0] ).tabfechaId;
                if( encontrarPeriodo(periodoIdInicial) <= encontrarPeriodo(periodoIdFinal) ){
                    enviarRetroactivo( deleteOptionals({ nominaId, nomindId:conceptos, periodoIdInicial, periodoIdFinal, tabfechaId }) );
                }else{
                    setError( <AlertForm message={ `El Periodo Inicial no puede ser mayor al Periodo Final` } /> );
                }
            }else{
                setError( <AlertForm message={ `Selecciona por lo menos un Concepto del Tabulador para continuar` } /> );
            }
        }
    };

    const actualizar = () => {
        setError( null );
        if( validarSubmit( 'EditarRetroactivo' ) ){
            const nominaId = parseInt(nominaIdRef.current.value);
            const periodoIdInicial = parseInt( periodoIdInicialRef.current.value );
            const periodoIdFinal = parseInt( periodoIdFinalRef.current.value );
            if( periodoIdInicial < periodoIdFinal ){
                console.log({ nominaId, conceptos, periodoIdInicial, periodoIdFinal });
                //enviarRetroactivo( deleteOptionals({ nominaId, conceptos, periodoIdInicial, periodoIdFinal }) );
            }else{
                setError( <AlertForm message={ `La fecha Inicial no puede ser mayor o igual que la fecha final` } /> );
            }
            
        }
    };

    const actionNomina = ( nominaID ) => {
        setConceptos( [] );
        actionSelectNomina( nominaID );
    }

    const agregarConcepto = ( conceptoID ) => {
        if( conceptos.includes( conceptoID ) ){
            setConceptos( conceptos.filter( element => element !== conceptoID ) );
        }else{
            setConceptos( [ ...conceptos, conceptoID ] );
        }
    }

    /* Acciones para eliminar Retroactivo */
    const eliminar = () => {
        const nominaId = parseInt(nominaIdRef.current.value);
        if( informacion.nominaPeriodos && informacion.nominaPeriodos.length >= 1 ){
            eliminarRetroactivo( nominaId, informacion.nominaPeriodos[0].periodoAnio );
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarRetroactivo' : 'AgregarRetroactivo' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <SelectValidation title="Nómina" arrayOpciones={[{ id: 0, nominaNombre: 'TODAS' }, ...informacion.nominas]} keyOpcion="nominaNombre"
                    refValue={nominaIdRef} defaultValue={ elementSelect ? elementSelect.nomina.id : '' } required
                    onChangeExecute={actionNomina}/>

                <MuiThemeProvider theme={theme}>
                    { informacion.nominaID ?
                        informacion.indicadores && informacion.nominaPeriodos ?
                            <React.Fragment>
                                <div className="checkIndicador textCheckIndicador"> <span> Conceptos del Tabulador </span> </div>
                                { informacion.indicadores.map( indicador => (
                                    <div key={indicador.indicadorId} className="checkIndicador">
                                        <Checkbox
                                            checked={ conceptos.includes( indicador.nomindId ) }
                                            onChange={ () => agregarConcepto( indicador.nomindId ) }
                                        /> <span> {`${indicador.indicadorClave} / ${indicador.indicadorNom}`} </span>
                                    </div>
                                ))}
                                
                                { informacion.nominaPeriodos.length >= 1 &&
                                    <div className="mt-3 mb-1">
                                        <b> Año: {informacion.nominaPeriodos[0].periodoAnio} </b>
                                    </div>
                                }
                            </React.Fragment>
                            : <SpinnerOval />
                        : null
                    }
                </MuiThemeProvider>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Inicial" arrayOpciones={informacion.nominaPeriodos ? informacion.nominaPeriodos : []} keyOpcion="periodoPerAnioFecIni"
                            refValue={periodoIdInicialRef} defaultValue={ elementSelect ? elementSelect.nomina.id : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Final" arrayOpciones={informacion.nominaPeriodos ? informacion.nominaPeriodos : []} keyOpcion="periodoPerAnioFecFin"
                            refValue={periodoIdFinalRef} defaultValue={ elementSelect ? elementSelect.nomina.id : '' } required/>
                    </div>
                </div>

                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Calcular' }</Button>
            </form>

            { !informacion.peticion ?
                informacion.excel64 &&
                    <div className="textDownRetroactivo">
                        <span> Retroactivos Seleccionados </span>
                        <div className="actionRetroactivo">
                            {/* <a href={`data:application/vnd.ms-excel;base64,${informacion.excel64}`} download={ `Retroactivo.xlsx` }> */}
                            {console.log(informacion)}
                            <a href={`data:text/csv;base64,${informacion.excel64}`} download={ `Retroactivo.csv` }>
                                <SaveAlt/> <span className="textDownRetro"> Cálculo de Retroactivo </span>
                            </a>
                        </div>

                        <div className="actionRetroactivo retroDelete" onClick={ () => setOpenAlertDelete(true) }>
                            <Delete/> <span className="textDownRetro"> Eliminar Cálculo de Retroactivo </span>
                        </div>
                    </div>
                : <SpinnerOval />
            }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
            <AlertWarning show={openAlertDelete} SetopenALert={setOpenAlertDelete} text="El Retroactivo sera eliminado" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={eliminar}/>
        </React.Fragment>
    );
};

export default FormularioRetroactivo;