import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      overflowX: 'auto'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 'auto',
    },
}));