
import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; 
import { iconosDrawer } from '../../MenuDrawer/Drawer/IconosDrawer'; //Importacion de Iconos 
import { consulta_pagos9719 } from '../../../../../Constants/Constants';

const CardBusquedaHistorico9719 = ({ setOpenHistorico }) => {
     
    return (
        <Fragment>
            <Card className="cardBoard cardTablero" onClick={ ()=>{setOpenHistorico(true)}}>
                <CardContent>
                    <div className="icon">{iconosDrawer['history']}</div>
                    <p className="name">{ consulta_pagos9719 }</p> 
                </CardContent>
            </Card> 
        </Fragment>
    );
};
export default CardBusquedaHistorico9719;