import React from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { ArrayJsonEmpleadosReportesNominas } from '../../../../Helpers/JsonToOneLevel';
import { formatExcel } from '../../../../DataTableService/ActionsDatatable';

var acumuladoNuevoReporteNominas = [];

const columns = [
    {
        field: "empleadoClave",
        hidden: false,
        tableData: {columnOrder: 0, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 0},
        title: "NUE",
        type: "numeric",
    },
    {
        field: "nombreCompleto",
        hidden: false,
        tableData: {columnOrder: 1, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 1},
        title: "Nombre",
    },
    {
        field: "empleadoFecnac",
        hidden: false,
        tableData: {columnOrder: 2, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 2},
        title: "Fecha de Nacimiento",
    },
    {
        field: "empleadoCurp",
        hidden: false,
        tableData: {columnOrder: 3, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 3},
        title: "CURP",
    },
    {
        field: "empleadoRfc",
        filterPlaceholder: "Filtrar",
        hidden: false,
        tableData: {columnOrder: 4, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 4},
        title: "RFC",
    },
    {
        field: "empleadoNss",
        hidden: false,
        tableData: {columnOrder: 5, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 5},
        title: "NSS",
    },
    {
        field: "cpresupuestalClave",
        hidden: false,
        tableData: {columnOrder: 6, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 6},
        title: "Clave Presupuestal",
    },
    {
        field: "cpresupuestalNom",
        hidden: false,
        tableData: {columnOrder: 7, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 7},
        title: "Clave Presupuestal / Proyecto",
    },
    {
        field: "ciaRazonSocial",
        hidden: false,
        tableData: {columnOrder: 8, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 8},
        title: "Unidad Responsable / Dependencia",
    },
    {
        field: "nomindClave",
        hidden: false,
        tableData: {columnOrder: 9, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 9},
        title: "Clave Concepto",
    },
    {
        field: "tiponatuNom",
        hidden: false,
        tableData: {columnOrder: 10, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 10},
        title: "Nombre Tipo Naturaleza",
    },
    {
        field: "nomindNomIndicador",
        hidden: false,
        tableData: {columnOrder: 11, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 11},
        title: "Concepto por Nómina",
    },
    {
        field: "cantidad",
        hidden: false,
        tableData: {columnOrder: 12, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 12},
        title: "Cantidad",
    },
    {
        field: "monto",
        hidden: false,
        tableData: {columnOrder: 13, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 13},
        title: "Monto",
    },
    {
        field: "acumuladoClave",
        hidden: false,
        tableData: {columnOrder: 14, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 14},
        title: "Clave Acumulado",
    },
    {
        field: "acumuladoNombre",
        hidden: false,
        tableData: {columnOrder: 15, filterValue: undefined, groupOrder: undefined, groupSort: 'asc', id: 15},
        title: "Nombre Acumulado",
    },
];

const extractDataGroup=(data, columnsVisibles, groupBy)=>{
    var result = [];
    for (const key in data) {  
        if (data[key].groups) { 
            const colName = groupBy.filter(element => element.tableData.groupOrder===data[key].path.length-1)[0];
            result.push({" ": `${colName.title}: ${data[key].value}`}); 
            if(data[key].groups.length>0){ 
                const group = extractDataGroup(data[key].groups, columnsVisibles, groupBy);
                for (const key in group) {
                    result.push( group[key]);
                } 
            }else{
                const dataVisble = data[key].data.map(rowData =>{ const newJson={};  Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = rowData[columnDef.field]; return element})); return newJson;});
                dataVisble.map(element=>result.push(element)); 
            }
        }else{ 
            const newJson = {};
            Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = data[key][columnDef.field]; return element})); 
            result.push(newJson);
        }
    }
    return result;
};

const columnsVisibles = (columns) => { 
    return columns.filter(columnDef => (columnDef.hidden !== true  && columnDef.tableData.groupOrder === undefined)).sort((a, b)=> a.tableData.columnOrder > b.tableData.columnOrder ? 1 : a.tableData.columnOrder < b.tableData.columnOrder ? -1 :0 );
};

const groupByData = (columns) => {
    return columns.filter(columnDef => columnDef.tableData.groupOrder !== undefined);
};

const dataVisible = (columns, data)=>{    
    return extractDataGroup(data, columnsVisibles(columns), groupByData(columns));
};

const searchAcumulados = empleadosAcumulados =>{
    let listAcumulados = [];
    empleadosAcumulados.forEach((item, index)=>{ 
        if(listAcumulados.findIndex(element => element.acumuladoClave === item.acumuladoClave) ===-1 || index===0 ){
            listAcumulados.push(item);
        }   
    })
    return listAcumulados;
};

export const servicioNuevoReporteNominas = (data, limiteInf, cantidad, acumulado, restante, infoReport, setElementSelect, setInfoReport, setDownloadReportDefinido, setOpenNewReportNomina, setShowReport, setError, setIsLoading) => {
    axios.get(`${servicios_grupohit_nomina}/vacumperxnom/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}/${limiteInf}/${cantidad}`, headersAuth())
    .then(res => { 
        switch(res.data.status){
            case 200:
                if(restante === 0) {
                    if(setInfoReport && setElementSelect) {
                        setInfoReport(data);
                        const newData = acumuladoNuevoReporteNominas.concat(ArrayJsonEmpleadosReportesNominas(res.data.dataResponse));
                        newData[newData.length-1].isStatic = (infoReport && infoReport.id) === 143;
                        setElementSelect(newData);
                    } else {
                        const newData = acumuladoNuevoReporteNominas.concat(ArrayJsonEmpleadosReportesNominas(res.data.dataResponse));
                        newData[newData.length-1].isStatic = (data.id) === 143;
                        //aquí comienza a descargar el CSV
                        if(data.calculo) {
                            const acumulados = searchAcumulados(newData);
                            const empleados = newData;
                            let headerAcumulados = acumulados.map( (element, index) => `Clave Acumulado: ${element.acumuladoClave},Nombre Acumulado: ${element.acumuladoNombre} ${index===acumulados.length-1 ?'\n' :''}`);
                            const infoNomina = `Fecha Periodo: ${acumulados[0].periodoFecini} - ${acumulados[0].periodoFecfin}\nAño: ${acumulados[0].periodoAnio},Periodo: ${acumulados[0].periodoPeranio}\nClave Nómina: ${acumulados[0].nomindClave},Nombre Nómina: ${acumulados[0].nominaNomCorto}\n\n`;
                            let csvContent = formatExcel( dataVisible(columns, empleados), 0,  ',');
                            var a = window.document.createElement('a');
                            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent( headerAcumulados ?  "\uFEFF"+headerAcumulados.join('\n')+infoNomina+csvContent : "\uFEFF"+csvContent));
                            a.setAttribute('download', `AcumuladosNomina${data.nominaClave}Anio${data.periodoanio}Periodo${data.periodoperanio}.csv`);
                            window.document.body.appendChild(a);
                            a.click();
                        }
                    }
                    if(setDownloadReportDefinido) setDownloadReportDefinido(false);
                    if(setOpenNewReportNomina) setOpenNewReportNomina(false);
                    if(setShowReport) setShowReport(true);
                    if(!data.calculo) setError(null); 
                    setIsLoading(false);
                } else if(restante > 0) {
                    acumuladoNuevoReporteNominas = acumuladoNuevoReporteNominas.concat(ArrayJsonEmpleadosReportesNominas(res.data.dataResponse));
                    const cantidad = (restante < 50000) ? restante : 50000;
                    const limiteInferior = acumulado;
                    const newAcumulado = acumulado+cantidad;
                    servicioNuevoReporteNominas(data, limiteInferior, cantidad, newAcumulado, restante-cantidad, infoReport, setElementSelect, setInfoReport, setDownloadReportDefinido, setOpenNewReportNomina, setShowReport, setError, setIsLoading);
                } else {
                    (data.calculo) ? setError('Error en la operación') : setError(<AlertForm message="Error en la operación" />); 
                    setIsLoading(false);
                }
                break;
            default:
                (data.calculo) ? setError(res.data.msg) : setError(<AlertForm message={res.data.msg} />); 
                setIsLoading(false);
                break;
        }
    })
    .catch( error => {
        (data.calculo) ? setError(verifique_conexion) : setError(<AlertForm message={verifique_conexion} />); 
        setIsLoading(false); 
    });
};

export const servicioTotalNuevoReporteNominas = props => {
    const { data, infoReport, setElementSelect, setInfoReport, setDownloadReportDefinido, setOpenNewReportNomina, setShowReport, setError, setIsLoading } = props;
    acumuladoNuevoReporteNominas = [];
    axios.get(`${servicios_grupohit_nomina}/vacumperxnom/total/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}`, headersAuth())
    .then(res => {
        if(res.data.status === 200) {
            const cantidad = (res.data.dataResponse < 50000) ? res.data.dataResponse : 50000;
            servicioNuevoReporteNominas(data, 0, cantidad, cantidad, res.data.dataResponse-cantidad, infoReport, setElementSelect, setInfoReport, setDownloadReportDefinido, setOpenNewReportNomina, setShowReport, setError, setIsLoading);
        } else {
            (data.calculo) ? setError(res.data.msg) : setError(<AlertForm message={res.data.msg} />);
            setIsLoading(false);
        }
    })
    .catch(error => {
        (data.calculo) ? setError(verifique_conexion) : setError(<AlertForm message={verifique_conexion} />); 
        setIsLoading(false); 
    });
};