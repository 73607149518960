import { CAMBIA_ESTADO_MENU } from '../actionTypes';

const initialstate = {
    openMenu: true,
}

const SystemReducer = ( state = initialstate, action ) => {

    switch( action.type ) {
        case CAMBIA_ESTADO_MENU:
            return {
                ...state,
                openMenu: !state.openMenu,
            };
        default:
            return state;
    }
}

export default SystemReducer;