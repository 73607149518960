import React, { useState, Fragment } from 'react'; 
import { connect } from 'react-redux';
import DataTableService from '../../DataTableService/DataTableService';
import PageTitleBar from '../../Layouts/PageTitleBar';
import BtnBack from '../../../../../Services/Components/SecondTable/BtnBack';
import CardComponent from '../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import FormBusquedaHistoricoRH9719 from './FormBusquedaHistoricoRH9719';
import ModalLoading from '../../../../../Services/ModalLoading/ModalLoading';
import displayWindowSize from '../../Helpers/DisplayWindowSize';
import TooltipTable from '../../../../../Services/Tooltip/TooltipTable';
import { consulta_plazas9719 } from '../../../../../Constants/Constants';
import InputText from '../../../../../Services/Components/DataTable/InputText';

const BusquedaRH9719 = ({ id, openRHHistorico, setOpenRHHistorico , openMenu }) => {
    
    const [datosTabla, setDatosTabla] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [checkbox, setCheckbox] = useState({
        datos_especificos: false,
        pbpNup: false,
        pbpNue: false,
        pbpRfc: false,
        pbpNom: false,
        cproCve: false,
        sria: false,
        ccatCve: false,
        desPuesto: false,
        ctinCve: false
    });
    const [maxWidth, setMaxWidth] = useState(displayWindowSize().width);
    
    window.addEventListener("resize", () => setMaxWidth(displayWindowSize().width));

    const regresarTablero = () => {
        setDatosTabla(null);
        setOpenRHHistorico(false);
        setCheckbox({
            datos_especificos: false,
            pbpNup: false,
            pbpNue: false,
            pbpRfc: false,
            pbpNom: false,
            cproCve: false,
            sria: false,
            ccatCve: false,
            desPuesto: false,
            ctinCve: false
        });
    };

    return (
        <Fragment>
        {(openRHHistorico) &&
            <div id={`modulo${id}`}>
                <div style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                    <PageTitleBar title="Búsqueda Avanzada" path="Tablero"/>
                    <BtnBack tooltipText="Regresar" action={regresarTablero}/>
                    <CardComponent title={<h4>{ consulta_plazas9719 }</h4>}>
                        <div className="row" style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                            <div className="col-md-12 col-sm-12">
                                <FormBusquedaHistoricoRH9719 checkbox={checkbox} setCheckbox={setCheckbox} setDatosTabla={setDatosTabla} setIsLoading={setIsLoading}/>
                            </div>
                        </div>
                    </CardComponent>
                </div>
                    {(datosTabla) &&
                        <div style={{ marginTop: '2rem' }}>
                            <DataTableService id={id} title={ consulta_plazas9719 }
                                columns={[
                                    { field: "pbpNup", title: <TooltipTable title="PBPNUP" tooltipText="Número Único de la Plaza"/>, titleWTooltip: "PBPNUP", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.pbpNup.startsWith(term)  },
                                    { field: "pbpNue", title: <TooltipTable title="PBPNUE" tooltipText="Número Único del Empleado"/>, titleWTooltip: "PBPNUE", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.pbpNue.startsWith(term)  },
                                    { field: "pbpRfc", title: <TooltipTable title="PBPRFC" tooltipText="RFC del Empleado"/>, titleWTooltip: "PBPRFC" },
                                    { field: "pbpNom", title: <TooltipTable title="PBPNOM" tooltipText="Nombre del Empleado"/>, titleWTooltip: "PBPNOM" },
                                    { field: "cproCve", title: <TooltipTable title="CPROCVE" tooltipText="Clave Presupuestal"/>, titleWTooltip: "CPROCVE", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term), filtering: true },
                                    { field: "sria", title: <TooltipTable title="SRIA" tooltipText="Nombre de la Secretaria/Dependencia"/>, titleWTooltip: "SRIA" },
                                    { field: "ccatCve", title: <TooltipTable title="CCATCVE" tooltipText="Clave del Puesto"/>, titleWTooltip: "CCATCVE" },
                                    { field: "desPuesto", title: <TooltipTable title="DESPUESTO" tooltipText="Descripción del Puesto"/>, titleWTooltip: "DESPUESTO" },
                                    { field: "cnomCve", title: <TooltipTable title="CNOMCVE" tooltipText="Clave de la Ubicación de la Plaza (100=Centro, 160=Foráneo) "/>, titleWTooltip: "CNOMCVE", hidden: true, hiddenByColumnsButton: true },
                                    { field: "ctinCve", title: <TooltipTable title="CTINCVE" tooltipText="Clave Indicador de la Plaza (Primero)"/>, titleWTooltip: "CTINCVE", hidden: true, hiddenByColumnsButton: true },
                                    { field: "cticCve", title: <TooltipTable title="CTICCVE" tooltipText="Clave Indicador de la Plaza (Segundo)"/>, titleWTooltip: "CTICCVE", hidden: true, hiddenByColumnsButton: true },
                                    { field: "relabPlz", title: <TooltipTable title="RELABPLZ" tooltipText="Relación Laboral de la Plaza"/>, titleWTooltip: "RELABPLZ", hidden: true, hiddenByColumnsButton: true }, 
                                    { field: "cnomCve2", title: <TooltipTable title="CNOMCVE2" tooltipText="Clave de la Ubicación del Empleado (100=Centro, 160=Foráneo) "/>, titleWTooltip: "CNOMCVE2", hidden: true, hiddenByColumnsButton: true },
                                    { field: "ctinCve2", title: <TooltipTable title="CTINCVE2" tooltipText="Clave Indicador del Tipo de Contratación (Primero)"/>, titleWTooltip: "CTINCVE2", hidden: true, hiddenByColumnsButton: true },
                                    { field: "cticCve2", title: <TooltipTable title="CTICCVE2" tooltipText="Clave Indicador del Tipo de Contratación (Segundo)"/>, titleWTooltip: "CTICCVE2", hidden: true, hiddenByColumnsButton: true },
                                    { field: "relabEmpl", title: <TooltipTable title="RELABEMPL" tooltipText="Relación Laboral del Empleado"/>, titleWTooltip: "RELABEMPL", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpFig", title: <TooltipTable title="PBPFIG" tooltipText="Fecha de Ingreso a Gobierno"/>, titleWTooltip: "PBPFIG", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpFin", title: <TooltipTable title="PBPFIN" tooltipText="Fecha de Inicio en el Puesto"/>, titleWTooltip: "PBPFIN", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPagu", title: <TooltipTable title="PBPAGU" tooltipText="Fecha de Referencia Pago Aguinaldo"/>, titleWTooltip: "PBPAGU", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPind", title: <TooltipTable title="PBPIND" tooltipText="Estatus de la Plaza"/>, titleWTooltip: "PBPIND", hidden: true, hiddenByColumnsButton: true },
                                    { field: "estatus", title: <TooltipTable title="ESTATUS" tooltipText="Concepto del Estatus de la Plaza"/>, titleWTooltip: "ESTATUS", hidden: true, hiddenByColumnsButton: true },
                                    { field: "ctimCve", title: <TooltipTable title="CTIMCVE" tooltipText="Clave del Movimiento"/>, titleWTooltip: "CTIMCVE", hidden: true, hiddenByColumnsButton: true },
                                    { field: "movimiento", title: <TooltipTable title="MOVIMIENTO" tooltipText="Concepto de la Clave del Movimiento"/>, titleWTooltip: "MOVIMIENTO", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpFhm", title: <TooltipTable title="PBPFHM" tooltipText="Fecha del Histórico del Movimiento"/>, titleWTooltip: "PBPFHM", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPqum", title: <TooltipTable title="PBPQUM" tooltipText="Quincena del Movimiento"/>, titleWTooltip: "PBPQUM", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpFdes", title: <TooltipTable title="PBPFDES" tooltipText="Fecha de Inicio de Efectos del Último Movimiento"/>, titleWTooltip: "PBPFDES", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpFhas", title: <TooltipTable title="PBPFHAS" tooltipText="Fecha de Término del Último Movimiento"/>, titleWTooltip: "PBPFHAS", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPdes", title: <TooltipTable title="PBPDES" tooltipText="Desde (Año+Quincena) (debe corresponder a Fecha de Inicio)"/>, titleWTooltip: "PBPDES", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPhas", title: <TooltipTable title="PBPHAS" tooltipText="Hasta (Año+Quincena) (debe corresponder a Fecha de Término)"/>, titleWTooltip: "PBPHAS", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPobs1", title: <TooltipTable title="PBPOBS1" tooltipText="Observación 1 del Movimiento"/>, titleWTooltip: "PBPOBS1", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPobs2", title: <TooltipTable title="PBPOBS2" tooltipText="Observación 2 del Movimiento"/>, titleWTooltip: "PBPOBS2", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbParea", title: <TooltipTable title="PBPAREA" tooltipText="Área de Adscripción Empleado Actual (sólo Mando Medios y Superiores)"/>, titleWTooltip: "PBPAREA", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbPest", title: <TooltipTable title="PBPEST" tooltipText="Clave de Estatus de la Estructura para Mandos Medios y Superiores (1=Autorizada, 2=Fuera de Estructura)"/>, titleWTooltip: "PBPEST", hidden: true, hiddenByColumnsButton: true },
                                    { field: "desestruct", title: <TooltipTable title="DESESTRUCT" tooltipText="Concepto del Indicador de la Estructura"/>, titleWTooltip: "DESESTRUCT", hidden: true, hiddenByColumnsButton: true },
                                    { field: "dpto", title: <TooltipTable title="DPTO" tooltipText="Clave del Departamento que Envía el Movimiento"/>, titleWTooltip: "DPTO", hidden: true, hiddenByColumnsButton: true },
                                    { field: "desdPto", title: <TooltipTable title="DESDPTO" tooltipText="Concepto de la Clave del Departamento que Envía el Movimiento"/>, titleWTooltip: "DESDPTO", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpCurp", title: <TooltipTable title="PBPCURP" tooltipText="CURP del Empleado Actual"/>, titleWTooltip: "PBPCURP" },
                                    { field: "statusPlz", title: <TooltipTable title="STATUSPLZ" tooltipText="Clave del Indicador de la Plaza (para Movimientos Especiales)"/>, titleWTooltip: "STATUSPLZ", hidden: true, hiddenByColumnsButton: true },
                                    { field: "desstatPlz", title: <TooltipTable title="DESSTATPLZ" tooltipText="Concepto del Indicador Especial de la Plaza"/>, titleWTooltip: "DESSTATPLZ", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpMadre", title: <TooltipTable title="PBPMADRE" tooltipText="Clave Indicador para Madre de Familia (1=Si, 0=No)"/>, titleWTooltip: "PBPMADRE", hidden: true, hiddenByColumnsButton: true },
                                    { field: "madreFami", title: <TooltipTable title="MADREFAMI" tooltipText="Concepto del Indicador para Madre de Familia"/>, titleWTooltip: "MADREFAMI", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpNivest", title: <TooltipTable title="PBPNIVEST" tooltipText="Clave Indicador Nivel de Estudios"/>, titleWTooltip: "PBPNIVEST", hidden: true, hiddenByColumnsButton: true },
                                    { field: "desNivest", title: <TooltipTable title="DESNIVEST" tooltipText="Concepto para el Indicador del Nivel de Estudios"/>, titleWTooltip: "DESNIVEST", hidden: true, hiddenByColumnsButton: true },
                                    { field: "ccarcve", title: <TooltipTable title="CCARCVE" tooltipText="Clave de Carrera Profesional"/>, titleWTooltip: "CCARCVE", hidden: true, hiddenByColumnsButton: true },
                                    { field: "descarr", title: <TooltipTable title="DESCARR" tooltipText="Concepto para la Clave de Carrera Profesional Registrado"/>, titleWTooltip: "DESCARR", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpFreg", title: <TooltipTable title="PBPFREG" tooltipText="Fecha de Registro en el Sistema"/>, titleWTooltip: "PBPFREG", hidden: true, hiddenByColumnsButton: true },
                                    { field: "nacional", title: <TooltipTable title="NACIONAL" tooltipText="Indicador de Nacionalidad (1=Mexicano,  2=Extranjero)"/>, titleWTooltip: "NACIONAL", hidden: true, hiddenByColumnsButton: true },
                                    { field: "desnaciona", title: <TooltipTable title="DESNACIONA" tooltipText="Concepto para el Indicador de la Nacionalidad"/>, titleWTooltip: "DESNACIONA", hidden: true, hiddenByColumnsButton: true },
                                    { field: "referPlz", title: <TooltipTable title="REFERPLZ" tooltipText="Texto para Referenciar una Plaza en su Movimiento"/>, titleWTooltip: "REFERPLZ", hidden: true, hiddenByColumnsButton: true },
                                    { field: "pbpfNomb", title: <TooltipTable title="PBPFNOMB" tooltipText="Fecha del Último Nombramiento"/>, titleWTooltip: "PBPFNOMB", hidden: true, hiddenByColumnsButton: true }
                                ]}
                                data={datosTabla} 
                                hideIconAdd
                                hideIconDelete
                                hideIconEdit
                                customOptionsTable={{ filtering: true }} 
                            />
                        </div>
                    }
                    <ModalLoading nombreModulo={id} open={isLoading} setOpen={setIsLoading}/>
            </div>
        }
        </Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    openMenu: state.SystemReducer.openMenu
});

export default connect(mapStateToProps, null)(BusquedaRH9719);