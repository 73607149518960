import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    Storage, WorkOutline, AddCircleOutlineOutlined, RemoveCircleOutlineOutlined, AccountTreeOutlined, RepeatOne, AccountCircleOutlined,
    EventAvailableOutlined, EventBusyOutlined, SwapVertOutlined, DescriptionOutlined, PeopleAltOutlined, CheckCircleOutlineOutlined,
    HighlightOffOutlined, DirectionsWalkOutlined, EmojiPeopleOutlined, AssessmentOutlined, EventOutlined, AssignmentIndOutlined
} from '@material-ui/icons';
import thousandsSeparator from '../../../../Helpers/ThousandsSeparator';

const iconos = {
    add_circle: <AddCircleOutlineOutlined />,
    remove_circle: <RemoveCircleOutlineOutlined />,
    account_tree: <AccountTreeOutlined />,
    storage: <Storage />,
    work: <WorkOutline />,
    repeat_one: <RepeatOne />,
    account_circle: <AccountCircleOutlined />,
    event_available: <EventAvailableOutlined />,
    event_busy: <EventBusyOutlined />,
    swap_vert: <SwapVertOutlined />,
    description: <DescriptionOutlined />,
    people_alt: <PeopleAltOutlined />,
    check_circle: <CheckCircleOutlineOutlined />,
    highlight_off: <HighlightOffOutlined />,
    directions_walk: <DirectionsWalkOutlined />,
    emoji_people: <EmojiPeopleOutlined />,
    assessment: <AssessmentOutlined />,
    event: <EventOutlined />,
    assigment: <AssignmentIndOutlined/>
};

const CardTablero = ({ name, title, cantidad, cantidad1, icon, muestraTabla, active, setActive, bloqueaCards }) => {

    const activaCard = () => {
        switch(name) {
            case 'altas':
                setActive({
                    activeAdscripción: false,
                    activeAltas: true,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'bajas':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: true,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'reingresos':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: true,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'datosEmpleados':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: true,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'prorrogaContrato':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: true,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'noProrrogaContrato':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: true,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'escalafon':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: true,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'categoria':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: true,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'adscripcion':
                setActive({
                    activeAdscripción: true,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'proyecto':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: true,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'nombramiento':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: true,
                    activeOtrosMovimientos: false,
                });
                break;
            case 'otrosMovimientos':
                setActive({
                    activeAdscripción: false,
                    activeAltas: false,
                    activeBajas: false,
                    activeCategoría: false,
                    activeProyecto: false,
                    activeReingresos: false,
                    activeDatosEmpleados: false,
                    activeProrrogaContrato: false,
                    activeNoProrrogaContrato: false,
                    activeEscalafon: false,
                    activeNombramiento: false,
                    activeOtrosMovimientos: true,
                });
                break; 
            default:
                break;
        };
    };

    const continuar = () => {
        activaCard();
        muestraTabla(name, (cantidad1) ? (cantidad1 > 0) ? true : false : (cantidad > 0) ? true : false);
    };

    return (
        <Fragment>
            <Card
                className={`cardBoard cardTablero ${active ? 'cardBoardActive' : ''}`}
                onClick={(muestraTabla && setActive) ? (!bloqueaCards) ? continuar : undefined : undefined} style={{ minHeight: '12rem', color: '#5E5E5E', backgroundColor: (bloqueaCards) ? '#E4E5E6' : '#FFFFFF' }}
                disabled={bloqueaCards}
            >
                <CardContent>
                    <div className="contenedorCantidadIcono">
                        {icon && <div className="iconCardEst">{ iconos[icon] }</div>}
                    </div>
                    <div className="contenedorCantidadIcono">
                        <label className="textCardEst-cantidad">{ (cantidad) ? thousandsSeparator(cantidad) : 0 }</label>
                    </div>
                    <div className="contenedorCantidadIcono"><label className="textCardEst">{ title }</label></div>
                </CardContent>
            </Card>
            <br/>
        </Fragment>
    );
}
 
export default CardTablero;