import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_admon, servicios_grupohit_org, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import OrganigramaModal from './Organigrama/OrganigramaModal';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import OrganigramaAdscripcion from './Organigrama/CentrosAdscripcion/OrganigramaAdscripcion';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import Button from '@material-ui/core/Button';
import { GetApp, Search } from '@material-ui/icons';
import ModalBusquedaPlaza from './Organigrama/Plazas/ModalBusquedaPlaza';

const Departamentos = ({ id, permisos }) => {

    const [cAdList, setCAdList] = useState([]);
    const [cAdSelected, setCAdSelected] = useState(null);
    const [organigrama, setOrganigrama] = useState([]);
    const [muestraBtnDescarga, setMuestraBtnDescarga] = useState(false);
    const [openAlertSyESuccess, setOpenAlertSyESuccess] = useState(false);
    const [openAlertSyEError, setOpenAlertSyEError] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [toDoOrg, setToDoOrg] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState({ state: false, type: null, data: {} });
    const [modalPlazaIsOpen, setModalPlazaIsOpen] = useState(false);

    useEffect(() => {
        axios.get(`${servicios_grupohit_admon}/compania`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setCAdList(res.data.dataResponse);
                    break;
                default:
                    setMensajeError(res.data.msg);
                    setOpenAlertSyEError(true);
                    break;
            }
        })
        .catch(error => {
            setMensajeError(verifique_conexion);
            setOpenAlertSyEError(true);
        });
    }, []);

    const guardaSeleccionado = async (valor) => {
        if(valor) {
            await axios.get(`${servicios_grupohit_org}/area/organigrama/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setOrganigrama(res.data.dataResponse);
                        setMuestraBtnDescarga(true);
                        break;
                    case 404:
                        setOrganigrama(null);
                        break;
                    default:
                        setMensajeError(res.data.msg);
                        setOpenAlertSyEError(true);
                        setOrganigrama([]);
                        break;
                }
            })
            .catch((error) => {
                setMensajeError(verifique_conexion);
                setOpenAlertSyEError(true);
            });
            setCAdSelected(valor);
        } else {
            setCAdSelected(null);
            setOrganigrama([]);
            setMuestraBtnDescarga(false);
        }
    };

    const enviarNuevoOrg = data => {
        const info = {
                        ciaid: cAdSelected.id,
                        areacve: data.clave,
                        areanivel: data.nivel,
                        areasecuencia: 1,
                        areanom: data.nombre,
                        areadescrip: data.descripcion,
                        areaidsup: data.id,
                        presupuestal: data.presupuestal
                    };

        axios.post(`${servicios_grupohit_org}/area/`, info, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                setOrganigrama([res.data.dataResponse]);
                setOpenAlertSyESuccess(true);
            } else {
                setMensajeError(res.data.msg);
                setOpenAlertSyEError(true);
            }
        })
        .catch((error) => {
            setMensajeError(verifique_conexion);
            setOpenAlertSyEError(true);
        });
    };

    const downloadXLS = async (id, nombre) => {
        await axios.get(`${servicios_grupohit_org}/area/excel/organigrama/${id}`, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                const a = document.createElement("a");
                a.style.display = "none";
                document.body.appendChild(a);
                const data = `data:application/octet-stream;base64,${res.data.dataResponse}`
                a.href = data;
                const fileName = `Organigrama-${nombre}.xls`
                a.setAttribute("download", fileName);
                a.click();
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            }
        })
        .catch((error) => {
            setMensajeError(verifique_conexion);
            setOpenAlertSyEError(true);
        });
    };



    const ButtonDownloadChart = (muestraBtnDescarga && cAdSelected)
                                ?   <TooltipComponent tooltipText="Descargar organigrama">
                                        <Button variant="contained" className="btn-color btn-dl-org mb-0 float-right" onClick={() => downloadXLS(cAdSelected.id, cAdSelected.ciaRazonSocial)}>
                                            <GetApp/>
                                        </Button>
                                    </TooltipComponent>
                                :   null;

    return (
        <div id={`modulo${id}`}>
            <div className="row">
                <div className="col-12 col-sm-8 col-md-8 col-lg-10 col-xl-10">
                    <AutocompleteComponent id="D001" title="Unidad Responsable / Dependencia" options={cAdList} optionListView="ciaRazonSocial" action={guardaSeleccionado} disabled={false} size="100%" placeholder="Escriba el nombre o clave de la Dependencia"/>
                </div>
                {(permisos.find(permiso => permiso.id === 4))
                ?   <Fragment>
                        <div className="col-6 col-sm-2 col-md-2 col-lg-1 col-xl-1 align-self-end ctr-dl-org">
                            { ButtonDownloadChart }
                        </div>
                        <div className="col-6 col-sm-2 col-md-2 col-lg-1 col-xl-1 align-self-end ctr-dl-org">
                            <TooltipComponent tooltipText="Buscar plaza">
                                <Button variant="contained" className="btn-color btn-dl-org float-right mb-0" onClick={() => setModalPlazaIsOpen(true)}>
                                    <Search/>
                                </Button>
                            </TooltipComponent>
                        </div>
                    </Fragment>
                :   <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 align-self-end ctr-dl-org">
                        { ButtonDownloadChart }
                    </div>
                }
            </div>
            {
                (organigrama === null)
                ?   <Fragment>
                        <AlertForm message="No hay elementos" />
                        {   (permisos.find(permiso => permiso.id === 1)) &&
                            <Fragment>
                                <button className="btn btn-color-form" onClick={() => setModalIsOpen({ state: true, type: 'agregar', data: { id: 0, nivel: 1 } }) }>Comenzar nuevo organigrama</button>
                                <AlertSyE show={openAlertSyESuccess} setOpenAlertSyE={setOpenAlertSyESuccess} title="Centro de Adscripción" text="La tarea se realizó con éxito" textbtnconfirm="Aceptar" type="success" action={() => setModalIsOpen({ state: false, type: null, data: {} })}/>
                                <AlertSyE show={openAlertSyEError} setOpenAlertSyE={setOpenAlertSyEError} title="Centro de Adscripción" text={mensajeError} textbtnconfirm="Aceptar" type="error" />
                                <OrganigramaModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} enviar={enviarNuevoOrg} id={(cAdSelected) ? cAdSelected.id : ''} parentNode={id}/>
                            </Fragment>
                        }
                    </Fragment>
                :   (cAdSelected !== null)
                    ? <OrganigramaAdscripcion id={cAdSelected.id} organigrama={organigrama} setOrganigrama={setOrganigrama} toDoOrg={toDoOrg} setToDoOrg={setToDoOrg} permisos={permisos}/>
                    : null
            }
            <ModalBusquedaPlaza parentNode={id} permisos={permisos} modalIsOpen={modalPlazaIsOpen} setModalIsOpen={setModalPlazaIsOpen}/>
        </div>
    );
}
 
export default Departamentos;