export const JsonPlazas = arrayInfo => {
    const newArray = arrayInfo.map(item => parseJsonPlazas(item) );

    return newArray;
}

const parseJsonPlazas = item => {
    return {
        areaId: item.areaid.id,
        areaNom: item.areaid.areanom,
        cPresupuestalId: item.presupuestal.id,
        cPresupuestalCve: item.presupuestal.cpresupuestalclave,
        cPresupuestalNom: item.presupuestal.cpresupuestalnom,
        domciaCalle: (item.ciaId.domciaCalle) ? item.ciaId.domciaCalle : '',
        domciaId: (item.ciaId.idomciad) ? item.ciaId.domcia : '',
        domciaNumext: (item.ciaId.domCiaNumExt) ? item.ciaId.domCiaNumExt : '',
        domciaNumint: (item.ciaId.domCiaNumInt) ? item.ciaId.domCiaNumInt : '',
        domciaCP: (item.ciaId.codigopCp) ? item.ciaId.codigopCp : '',
        domciaColonia: (item.ciaId.codigoColonia) ? item.ciaId.codigoColonia : '',
        id: item.id,
        nivLaboralClave: item.puesto.nvlId.nivlaboralClave,
        plazaClave: item.plazaclave,
        puestoCve: item.puesto.puestoCve,
        puestoId: item.puesto.id,
        puestoNom: item.puesto.puestoNom,
        ciaId: item.ciaId.ciaId,
    }
};
