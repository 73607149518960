import React from 'react'
import TableSelectRows from '../../../../../../../Services/Tables/TableSelectRows';
import InputText from '../../../../../../../Services/Components/DataTable/InputText';
import { ChromeReaderMode } from '@material-ui/icons';

const TableEscalafon = ({ empleadosActivos, mostrarModalEscalafon, cleanTable, setCleanTable }) => {
    return (
        <TableSelectRows
            title=""
            columns={[
                { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                { field: "nombreCompleto", title: "Nombre" },
                { field: "empleadoCurp", title: "CURP", emptyValue: '-' },
                { field: "empleadoRfc", title: "RFC", emptyValue: '-' },
                { field: "empleadoNss", title: "NSS", emptyValue: '-' },
                { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia" },
                { field: "puestoCve", title: "Clave Puesto" },
                { field: "puestoNom", title: "Puesto" },
                { field: "nominaNombre", title: "Nómina" },
                { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
            ]}
            data={empleadosActivos}
            filtering={false}
            icon={ChromeReaderMode}
            tooltip="Finalizar selección de empleados"
            action={mostrarModalEscalafon}
            cleanTable={cleanTable}
            setCleanTable={setCleanTable}
        />
    );
}
 
export default TableEscalafon;
