import React from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, servicios_grupohit_nomina, registro_no_guardado } from '../../../../../../../Constants/Constants';
import AlertErrorDescarga from '../../CargaMasivaDatos/CargasMasivas/AlertErrorDescarga';

import { longDateToInput, floatToAmount } from '../../../../../../../Services/Validation/HelperValidation';
import ConvertDate from '../../../../Helpers/ConvertDate';

/* ---------------------- Funciones para obtener informacion necesaria para cada carga de archivos ---------------------- */
export function obtenerInformacionExcel( idModulo, empleados ){
    switch( idModulo ) {
        case 41:
            return formatoExcelVariables( empleados );
        case 68:
            return formatoExcelFijos( empleados );
        case 75:
            //return formatoExcelPrestamos( empleados );
            return formatoExcelPrestamos();
        case 75+'Activos':
            return formatoExcelPrestamosActivos( empleados );
        case 76:
            return formatoExcelAcumulados( empleados );
        case 31:
            return formatoExcelAusentismos();
        case 904: //CargasMasivas - Pagos Únicos
            return formatoExcelPagosUnicos( empleados );

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const formatoExcelVariables = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'NOMBRE': (item.nombreCompleto !== null) ? item.nombreCompleto : '',

        'CLAVE DE LA NÓMINA': item.nominaClave !== null ? item.nominaClave : '',
        'NOMBRE DE LA NÓMINA': (item.nominaNombre !== null) ? item.nominaNombre : '',

        'FECHA': '',
        'CANTIDAD': '',
        'CLAVE DE CONCEPTO': '',
        'CLAVE DE ACUMULADO': '',
        'IMPORTE': '',
    }));
}

const formatoExcelFijos = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'NOMBRE': (item.nombreCompleto !== null) ? item.nombreCompleto : '',

        'CLAVE DE LA NÓMINA': item.nominaClave !== null ? item.nominaClave : '',
        'NOMBRE DE LA NÓMINA': (item.nominaNombre !== null) ? item.nominaNombre : '',

        'FECHA INICIAL': '',
        'FECHA FINAL': '',
        'CANTIDAD': '',
        'CLAVE DE CONCEPTO': '',
        'CLAVE DE ACUMULADO': '',
        'IMPORTE': '',
    }));
}
const formatoExcelPrestamos = () => {
    return [{
        'clave de desconto': '',
        'folio de credito': '',
        'rfc': '',
        'nombre': '',
        'no_total_cuotas': '',
        'cuota_inicial': '',
        'monto de descuento': '',
        'valor liberado': '',
        'saldo': '',
        'tipo movimineto': '',
    }]
};

/*const formatoExcelPrestamos = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'NOMBRE': (item.nombreCompleto !== null) ? item.nombreCompleto : '',

        'CLAVE DE LA NÓMINA': item.nominaClave !== null ? item.nominaClave : '',
        'NOMBRE DE LA NÓMINA': (item.nominaNombre !== null) ? item.nominaNombre : '',

        'FECHA INICIAL': '',
        'FECHA FINAL': '',
        'FOLIO': '',
        'NÚMERO DE PAGOS': '',
        'NÚMERO INICIAL DE PAGO': '',
        'CLAVE DE CONCEPTO': '',
        'IMPORTE POR PAGO': '',
        'NOTA': '',
    }));
}*/

const formatoExcelPrestamosActivos = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empClave !== null) ? item.empClave : '',
        'NOMBRE': `${item.empNom ? item.empNom : ''} ${item.empPat} ${item.empMat ? item.empMat : ''}`,
        'RFC': (item.empRfc !== null) ? item.empRfc : '',

        'FOLIO': (item.empprestFolio !== null) ? item.empprestFolio : '',
        'CONCEPTO': (item.nomindClave !== null) ? item.nomindClave : '',

        'NÚMERO DE PAGOS': (item.numPago !== null) ? item.numPago : '',
        'TOTAL DE PAGOS': (item.empprestNumPagos !== null) ? item.empprestNumPagos : '',
        'MONTO': (item.empprestImporte !== null) ? floatToAmount(item.empprestImporte, 2) : '',
        'FECHA DE INICIO': (item.empprestFecini !== null) ? ConvertDate( longDateToInput(item.empprestFecini) ) : '-',
        'NOTA': (item.empPrestNota !== null) ? item.empPrestNota : '',
    }));
}

const formatoExcelAcumulados = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'NOMBRE': (item.nombreCompleto !== null) ? item.nombreCompleto : '',

        'CLAVE DE LA NÓMINA': item.nominaClave !== null ? item.nominaClave : '',
        'NOMBRE DE LA NÓMINA': (item.nominaNombre !== null) ? item.nominaNombre : '',

        'AÑO': '',
        'PERIODO': '',
        'CANTIDAD': '',
        'CLAVE DE CONCEPTO': '',
        'CLAVE DE ACUMULADO': '',
        'IMPORTE': '',
    }));
}

const formatoExcelAusentismos = () => {
    return [{
        'RFC': '',
        'NUE': '',
        'PROYECTO': '',
        'NOMBRE COMPLETO': '',
        'CLAVE PUESTO': '',
        'CLAVE NOMINA': '',
        'CLAVE RELACION LABORAL': '',
        'NSS': '',
        'CLAVE AUSENCIA': '',
        'CANTIDAD': '',
        'CURP': '',
        'FECHA DE AUSENTISMO': '',
        'FECHA DE APLICACION': '',
        'COMENTARIOS': '',
    }];
}

/*const formatoExcelAusentismos = ( empleados ) =>  {
    return empleados.map( item => ({
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'PROYECTO': (item.proyectoClave !== null) ? item.proyectoClave : '',
        'NOMBRE COMPLETO': (item.nombreCompleto !== null) ? item.nombreCompleto : '',

        'CLAVE PUESTO': (item.puestoCve !== null) ? item.puestoCve : '',
        'CLAVE NOMINA': (item.nominaClave !== null) ? item.nominaClave : '',
        'CLAVE RELACION LABORAL': (item.relabClave !== null) ? item.relabClave : '',
        'NSS': (item.empleadoNss !== null) ? item.empleadoNss : '',

        'CLAVE AUSENCIA': '',
        'CANTIDAD': '',
        'CURP': (item.empleadoCurp !== null) ? item.empleadoCurp : '',
        'FECHA DE AUSENTISMO': '',
        'FECHA DE APLICACIÓN': '',
        'COMENTARIOS': '',
    }));
}*/

const formatoExcelPagosUnicos = ( empleados ) =>  {
    return empleados.map( item => ({
        'IDENTIFICADOR': (item.acumId !== null) ? item.acumId : '',
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NOMBRE': (item.nombreCompleto !== null) ? item.nombreCompleto : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'CLAVE PRESUPUESTAL': (item.cpresupuestalClave !== null) ? "'" + item.cpresupuestalClave : '',

        'CLAVE PRESUPUESTAL / PROYECTO': (item.cpresupuestalNom !== null) ? item.cpresupuestalNom : '',
        'UNIDAD RESPONSABLE / DEPENDENCIA': (item.ciaRazonSocial !== null) ? item.ciaRazonSocial : '',
        'CLAVE CONCEPTO': (item.nomindClave !== null) ? item.nomindClave : '',
        'NOMBRE TIPO NATURALEZA': (item.tiponatuNom !== null) ? item.tiponatuNom : '',

        'CONCEPTO POR NOMINA': (item.nomindNomIndicador !== null) ? item.nomindNomIndicador : '',
        'CANTIDAD': (item.cantidad !== null) ? item.cantidad : '',
        'MONTO': (item.monto !== null) ? item.monto : '',
        'NOMBRE ACUMULADO': (item.acumuladoNombre !== null) ? item.acumuladoNombre : '',

        'AÑO INFORMATIVO': (item.periodoAnioInfo !== null) ? item.periodoAnioInfo : '',
        'PERIODO INFORMATIVO': (item.periodoPeranioInfo !== null) ? item.periodoPeranioInfo : '',
    }));
}

/* -------------------------------- Funciones para carga de archivos & axios a servicios -------------------------------- */
export function axiosExcel( idModulo, archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable, cerrarModal ){
    switch( idModulo ) {
        case 41:
            axiosExcelVariables( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
            break;
        case 68:
            axiosExcelFijos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
            break;
        case 75:
            axiosExcelPrestamos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
            break;
        case 76:
            axiosExcelAcumulados( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
            break;
        case 31:
            axiosExcelAusentismos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
            break;
        case 904:
            axiosExcelPagosUnicos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable, cerrarModal );
            break;

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const axiosExcelVariables = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ) => {
    axios.post( `${servicios_grupohit_rh}/movimientos/excel/test/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Variables" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( respuesta.data.msg );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}

const axiosExcelFijos = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ) => {
    axios.post( `${servicios_grupohit_rh}/movimientos/fijo/excel/insert/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Fijos" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}

const axiosExcelPrestamos = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ) => {
    axios.post( `${servicios_grupohit_rh}/empleado/prestamos/excel/nuevo/insert/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Préstamos" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}

const axiosExcelAcumulados = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ) => {
    axios.post( `${servicios_grupohit_nomina}/acumulados/tacumulados/excel/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Acumulados" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( registro_no_guardado );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}

const axiosExcelAusentismos = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ) => {
    axios.post( `${servicios_grupohit_rh}/empleado/ausentismo/excel/insert/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Ausentismos" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}

const axiosExcelPagosUnicos = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable, cerrarModal ) => {
    axios.post( `${servicios_grupohit_nomina}/acumulados/tacumulados/info/masivos/csv/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    cerrarModal();
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Ausentismos" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( respuesta.data.msg  );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}