import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_url } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import FormularioUsuario from './FormularioUsuario';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const InformacionUsuario = ({ error, userID, userSelectSuperior, informacion, actualizarUsuario, errorTable, successTable, idModulo, loading, setLoading }) => {
    const [ usuario, setUsuario ] = useState( null );
    const [ departamentoUsuario, setDepartamentoUsuario ] = useState( null );
    useEffect(() => {
        axiosUsuario();
        axiosDepartamentoUsuario();
        // eslint-disable-next-line
    }, []);

    /* Axios para obtener la información del usuario seleccionado */
    const axiosUsuario = () => {
        setUsuario( null );
        axios.get( `${servicios_grupohit_url}/info/and/permissions/${userID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuario( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setUsuario([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosDepartamentoUsuario = () => {
        setDepartamentoUsuario( null );
        axios.get( `${servicios_grupohit_url}/deptorol/${userID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDepartamentoUsuario( respuesta.data.dataResponse );
                        break;
                    case 404: //No existe un registro asociado con ese Id
                        setDepartamentoUsuario({});
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <React.Fragment>
            { usuario && departamentoUsuario ?
                <React.Fragment>
                    <FormularioUsuario
                        error={error}
                        elementSelect={usuario.perfil.datos}
                        actualizarUsuario={actualizarUsuario}
                        informacion={informacion}
                        usernameEmail={usuario.perfil.username}
                        departamentoUsuario={departamentoUsuario}
                        userSelectSuperior={userSelectSuperior}
                        loading={loading}
                        /* Props para componentes ModalAsignacionModulos & ModalAsignacionNomina */
                        errorTable={errorTable}
                        successTable={successTable}
                        idModulo={idModulo}
                        usuario={usuario}
                        axiosUsuario={axiosUsuario}
                    />
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default InformacionUsuario;