import React, { Fragment } from 'react';

const InfoPasatiempos = ({ pasatiempo }) => {
    return (
        <Fragment>
            <div><strong>{ pasatiempo.pasatiempo }</strong></div>
        </Fragment>
    );
}
 
export default InfoPasatiempos;