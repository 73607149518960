import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { hoyMilisegundos } from '../../../../../Services/Validation/HelperValidation';
import ConvertDate, { ConvertDateTime } from '../../Helpers/ConvertDate';
import { headersAuth } from '../../../../../Services/AuthService';
import { servicios_grupohit_rh } from '../../../../../Constants/Constants';

const InfoEmpleadoAcumulados = ({ empleado }) => {

    const [contrato, setContrato] = useState({ inicial: null, final: null });
    const [aguinaldo, setAguinaldo] = useState(null);
    const [nombramiento, setNombramiento] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupohit_rh}/empleado/aguinaldo/${empleado.id}`, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                setAguinaldo(res.data.dataResponse.empFecAgui ? ConvertDate(res.data.dataResponse.empFecAgui) : null);
            }
        })
        .catch(error => {});

        axios.get(`${servicios_grupohit_rh}/empleado/nombramiento/${empleado.id}`, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                setNombramiento(res.data.dataResponse.empFecAgui ? ConvertDate(res.data.dataResponse.empFecAgui) : null);
            }
        })
        .catch(error => {});

        axios.get(`${servicios_grupohit_rh}/empleado/contrato/${empleado.id}`, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                setContrato({
                    inicial: ConvertDate(res.data.dataResponse.fechaini),
                    final: (res.data.dataResponse.fechafin) ? ConvertDate(res.data.dataResponse.fechafin) : null
                });
            }
        })
        .catch(error => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <div><strong>Fecha de Emisión: </strong>{ ConvertDateTime(hoyMilisegundos(), true) }</div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div><strong>Nombre: </strong>{ `${(empleado.empleadoPat) ? `${empleado.empleadoPat} ` : ''}${(empleado.empleadoMat) ? `${empleado.empleadoMat} ` : ''}${empleado.empleadoNom}` }</div>
                </div>
                <div className="col">
                    <div><strong>NUE: </strong>{ empleado.empleadoClave }</div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div><strong>CURP: </strong>{ empleado.empleadoCurp }</div>
                </div>
                <div className="col">
                    <div><strong>RFC: </strong>{ empleado.empleadoRfc }</div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div><strong>Fecha de Ingreso a Gobierno: </strong>{ (empleado.empantFecini) ? ConvertDate(empleado.empantFecini) : '' }</div>
                </div>
                <div className="col">
                    <div><strong>NSS: </strong>{ empleado.empleadoNss }</div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div><strong>Clave Presupuestal: </strong>{ empleado.cpresupuestalClave }</div>
                </div>
                <div className="col">
                    <div><strong>NUP: </strong>{ empleado.plazaClave }</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div><strong>Proyecto: </strong>{ empleado.cpresupuestalNom }</div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div><strong>Puesto: </strong>{ empleado.puestoNom }</div>
                </div>
                <div className="col">
                    <div><strong>Clave Puesto: </strong>{ empleado.puestoCve }</div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div><strong>Fecha Inicial de Contrato: </strong>{ contrato.inicial }</div>
                </div>
                {(contrato.final) &&
                    <div className="col">
                        <div><strong>Fecha Final de Contrato: </strong>{ contrato.final }</div>
                    </div>
                }
            </div>
            <div className="row">
                {(aguinaldo) &&
                    <div className="col-8">
                        <div><strong>Fecha de Aguinaldo: </strong>{ aguinaldo }</div>
                    </div>
                }
                {(nombramiento) &&
                    <div className={`col${(aguinaldo) ? '' : '-8'}`}>
                        <div><strong>Fecha de Nombramiento: </strong>{ nombramiento }</div>
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default InfoEmpleadoAcumulados;
