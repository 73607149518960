import Axios from 'axios';
import { connect } from 'react-redux';
import React, { useState, Fragment, useRef, createRef, useEffect } from 'react';  
import ModalService from './../../../Usuario/ModalService/ModalService';  
import FormularioConsultaBusquedaAvanzada from './FormularioConsultaBusquedaAvanzada';
import { historico_, servicios_grupohit, verifique_conexion } from '../../../../../Constants/Constants';
import { headersAuth } from '../../../../../Services/AuthService';
import AlertForm from '../../../../../Services/AlertForm';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Tab, Tabs, MuiThemeProvider, createMuiTheme, Button } from '@material-ui/core';
import InfoEmpleado from './InfoEmpleado';
import InfoEmpleadoAcumulados from './InfoEmpleadoAcumulados';
import FormularioReciboPago from '../Reporteador/Nominas/FormularioReciboPago';
import FormAcumulados from '../Nomina/Acumulados/FormAcumulados';
import InfoEspecialesEmpleado from '../RecursosHumanos/Especiales/TablaInfoEspecialesEmpleado';
import Pdf from "react-to-pdf";
import displayWindowSize from '../../Helpers/DisplayWindowSize';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

const ModalConsultaBusquedaAvanzada = ({ openEdit, setOpenEdit, errorTable, editar, successTable, elementSelect, nombreModulo, openMenu }) => {
 
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [valueTab, setValueTab] = useState(0);
 
    const [muestraAcumulados, setMuestraAcumulados] = useState(false);
    const [nominas, setNominas] = useState([]);
    const [anios, setAnios] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [acumulados, setAcumulados] = useState([]);
    const [periodo, setPeriodo] = useState({ id: '', nombre: '' });

    const nominaRef = useRef('');
    const anioRef = useRef('');
    const periodoRef = useRef('');
    const acumuladoRef = useRef('');

    const ref = createRef();

    const cerrarModal = () => {
        setError(null);
        setLoading(false);
        setNominas([]);
        setAnios([]);
        setPeriodos([]);
        setAcumulados([]);
        setPeriodo({ id: '', nombre: '' });
        setValueTab(0);
        setMuestraAcumulados(false);
        setOpenEdit(false); 
    }; 

    useEffect(() => {
        if(periodoRef?.current?.value) {
            const periosoSelected = periodos.find(periodo => periodo.id === periodoRef.current.value);
            setPeriodo(periosoSelected);
        }
    }, [periodoRef, periodos]);

    const crearZip = data => {
        var zip = new JSZip();
 
        zip.file(`${elementSelect.empleadoClave} - ${elementSelect.nombreCompleto}.pdf`, data, { base64: true }); 
        
        zip.generateAsync({ type:"blob" }).then(function(content) {
            saveAs(content, `${elementSelect.empleadoClave} - ${elementSelect.nombreCompleto}.zip`);
        });

    };

    const descargarPDF = () =>{
        setLoading(true);
        Axios.get(`${servicios_grupohit}/configuracion/reportes/ficha/empleado/${elementSelect.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(res.data.dataResponse!==""){
                        setLoading(false);
                        crearZip(res.data.dataResponse); 
                        setOpenEdit(false);
                    }else{
                        setLoading(false);
                        setError(<AlertForm message = {'No se pudo descargar el reporte'} />);
                    }
                break;
                default:
                    setLoading(false);
                    setError(<AlertForm message = {'No se pudo descargar el reporte'} />);
                    errorTable(res.data.msg);
                break;
            }
        })
        .catch(error => { errorTable(verifique_conexion); setLoading(false); });
    }

    const changeTab = (event, value) => {

        switch(value){
            case 2:
                setMuestraAcumulados(false);
            break;
            default:
            
            break;
        }
        setValueTab(value);
    }

    const obtieneTamanio = () => {
        const width = displayWindowSize().width;

        if(width <= 992) { //150% /
            if(openMenu) {
                return 1.4;
            } else {
                return .9;
            }
        }

        if(width <= 1191) { //125% /
            if(openMenu) {
                return 1.1;
            } else {
                return .96;
            }
        }

        if(width <= 1353) { //110% /
            if(openMenu) {
                return .9;
            } else {
                return .85;
            }
        }
        
        if(width <= 1485) { //100% /
            if(openMenu) {
                return .81;
            } else {
                return .76;
            }
        }

        if(width <= 1570) { //95% /
            if(openMenu) {
                return .76;
            } else {
                return .71;
            }
        }

        if(width <= 1655) { //90% /
            if(openMenu) {
                return .71;
            } else {
                return .66;
            }
        }

        if(width <= 1759) { //85%
            if(openMenu) {
                return .66;
            } else {
                return .61;
            }
        }

        if(width <= 1863) { //80% /
            if(openMenu) {
                return .61;
            } else {
                return .56;
            }
        }

        if(width <= 1990) { //75% /
            if(openMenu) {
                return .57;
            } else {
                return .52;
            }
        }

        if(width <= 2110) { //71% 
            if(openMenu) {
                return .54;
            } else {
                return .49;
            }
        }

        if(width <= 2235) { //67% /
            if(openMenu) {
                return .50;
            } else {
                return .45;
            }
        }

        if(width <= 2605) { //59% 
            if(openMenu) {
                return .43;
            } else {
                return .38;
            }
        }

        if(width <= 2980) { //50% /
            if(openMenu) {
                return .35;
            } else {
                return .3;
            }
        }

        if(width <= 3730) { //41% 
            if(openMenu) {
                return .3;
            } else {
                return .25;
            }
        }

        if(width <= 4480) { //33% /
            if(openMenu) {
                return .23;
            } else {
                return .18;
            }
        }

        if(width <= 5225) { //29% 
            if(openMenu) {
                return .2;
            } else {
                return .15;
            }
        }

        if(width <= 5975) { //25% /
            if(openMenu) {
                return .16;
            } else {
                return .11;
            }
        }

    };
    return (
        <ModalService 
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal} 
            minWidth='80%' 
            maxWidth='80%' 
            showCloseIcon={true}
        >
            <br/>
            <MuiThemeProvider theme={theme}>
                <Tabs
                    width="100%"
                    value={valueTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={changeTab}
                    className="TabsInfoAdvancedSearch"
                    style={{paddingTop:10}}
                >
                    <Tab label={<h5 style={{fontWeight:'bold'}}>{"Detalles del Empleado"}</h5>} value={0} style={{fontFamily:'Univia'}} />
                    <Tab label={<h5 style={{fontWeight:'bold'}}>{"Histórico"}</h5>} value={1} />
                    <Tab label={<h5 style={{fontWeight:'bold'}}>{"Acumulados"}</h5>} value={2}  />
                </Tabs>
            </MuiThemeProvider>
            {
                valueTab === 0 &&
                <FormularioConsultaBusquedaAvanzada
                    elementSelect={elementSelect}
                    descargarPDF={descargarPDF}
                    error={error}
                    setError={setError}
                    loading={loading}
                    setLoading={setLoading}
                />
            }
            {
                valueTab === 1 &&
                <>
                    <InfoEmpleado nombreEmpleado={elementSelect.nombreCompleto} claveEmpleado={elementSelect.empleadoClave}/>
                    <InfoEspecialesEmpleado elementSelect={elementSelect} id={1001} hideIconDelete title={historico_}/>
                </>
            }
            {
                valueTab === 2 &&
                <Fragment>
                    <div ref={ref}>
                        {(nombreModulo === 1001)
                        ?   <InfoEmpleadoAcumulados empleado={elementSelect}/>
                        :   <InfoEmpleado nombreEmpleado={elementSelect.nombreCompleto} claveEmpleado={elementSelect.empleadoClave}/>
                        }
                        <div className="row"> 
                            <div className="col-12">
                                <FormAcumulados
                                    elementSelect={elementSelect}
                                    nominas={nominas}
                                    setNominas={setNominas}
                                    nominaRef={nominaRef}
                                    anios={anios}
                                    setAnios={setAnios}
                                    anioRef={anioRef}
                                    periodos={periodos}
                                    setPeriodos={setPeriodos}
                                    periodoRef={periodoRef}
                                    acumulados={acumulados}
                                    setAcumulados={setAcumulados}
                                    acumuladoRef={acumuladoRef}
                                    setMuestraTable={setMuestraAcumulados}
                                    error={error}
                                    setError={setError}
                                />
                                {   (muestraAcumulados) &&
                                    <Fragment>
                                        <hr/>
                                        <FormularioReciboPago
                                            elementSelect={{
                                                ...elementSelect,
                                                empleadoId: elementSelect.id,
                                                periodoId: periodoRef.current.value,
                                                acumuladoId: acumuladoRef.current.value
                                            }}
                                            periodo={periodo}
                                            busquedaAvanzada
                                        />  
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    {(nombreModulo === 1001 && muestraAcumulados)
                    ?   <div className="row">
                            <div className="col">
                                <Pdf targetRef={ref}
                                    filename={`Acumulados-${(elementSelect.empleadoPat) ? `${elementSelect.empleadoPat}` : ''}${(elementSelect.empleadoMat) ? `${elementSelect.empleadoMat} ` : ''}${elementSelect.empleadoNom}.pdf`}
                                    x={6} y={6} scale={obtieneTamanio()}
                                >
                                    {({toPdf}) => (
                                        <Button variant="contained" className="btn-color" onClick={toPdf}>Descargar PDF</Button>
                                    )}
                                </Pdf>
                            </div>
                        </div>
                    :   null
                    }
                </Fragment>
            }
        </ModalService>
    );
};

const mapStateToProps = (state) => ({
    openMenu: state.SystemReducer.openMenu
});

export default connect(mapStateToProps, null)(ModalConsultaBusquedaAvanzada);
