const items = [
    { id: 'contrato', nombre: 'Contrato' },
    { id: 'antiguedad', nombre: 'Ingreso a Gobierno' },
    { id: 'nombramiento', nombre: 'Nombramiento' },
    { id: 'nomina', nombre: 'Nómina' },
    { id: 'plaza', nombre: 'Plaza' },
    { id: 'presupuestal', nombre: 'Clave Presupuestal' },
    { id: 'puesto', nombre: 'Puesto' },
    { id: 'status', nombre: 'Status' },
    { id: 'sueldo', nombre: 'Sueldo' },
    { id: 'turno', nombre: 'Turno' },
    { id: 'zonadepago', nombre: 'Zona de Pago' },
];

export default items;