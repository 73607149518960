import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import { registro_no_eliminado, registro_no_guardado, servicios_grupohit_rh } from '../../../../../../../../Constants/Constants';
import { validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../../Services/Validation/InputValidation';
import TooltipComponent from '../../../../../../../../Services/Tooltip/TooltipComponent';
import ModalService from '../../../../../ModalService/ModalService';
import AlertForm from '../../../../../../../../Services/AlertForm';
import Nota from './Nota';
import { Save, Visibility } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const StyledBadge = withStyles(() => ({
    badge: {
        color: '#FFFFFF',
        // The border color match the background color.
        backgroundColor: red[500],
        borderStyle: 'solid',
        borderColor: '#FFFFFF',
    },
}))(Badge);

const NotasAdicionales = ({ modulo, notaRef, empleadoId, notas, setNotas, successTable, errorTable }) => {

    const [openModal, setOpenModal] = useState(false);
    const [idEditar, setIdEditar] = useState(null);
    const [error, setError] = useState(false);
    const [muestraTextAreaNota, setMuestraTextAreaNota] = useState(true);

    useEffect(() => {
        setMuestraTextAreaNota(true);
    }, [muestraTextAreaNota]);

    const cerrarModal = () => {
        setIdEditar(null);
        setOpenModal(false);
    };

    const servicioAgregarNota = data => {
        setMuestraTextAreaNota(false);
        axios.post(`${servicios_grupohit_rh}/empleado/notas/`, data, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNotas([res.data.dataResponse, ...notas]);
                    notaRef.current.value = '';
                    successTable(res.data.msg);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => {
            errorTable(registro_no_guardado);
        });
    };

    const addNota = e => {
        e.preventDefault();
        if(validarSubmit('FormNotesAdd') && notaRef.current?.value.trim().length > 0) {
            const request = {
                empId: empleadoId,
                nota: notaRef.current?.value
            };
            servicioAgregarNota(request);
        }
    };

    const muestraTextArea = notaId => {
        setIdEditar(notaId);
    };

    const ocultaTextArea = () => {
        setIdEditar(null);
    };

    const servicioEditarNota = data => {
        axios.put(`${servicios_grupohit_rh}/empleado/notas/`, data, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newNotas = notas.map(nota => (nota.id === res.data.dataResponse.id) ? res.data.dataResponse : nota);
                    setNotas(newNotas);
                    setError(false);
                    ocultaTextArea();
                    break;
                default:
                    setError(<AlertForm message={res.data.msg}/>);
                    break;
            }
        })
        .catch(error => {
            setError(<AlertForm message={registro_no_guardado}/>);
        });
    };

    const servicioEliminarNota = notaId => {
        axios.delete(`${servicios_grupohit_rh}/empleado/notas/borrar/${notaId}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newNotas = notas.filter(nota => nota.id !== notaId);
                    setNotas(newNotas);
                    setError(false);
                    break;
                default:
                    setError(<AlertForm message={res.data.msg}/>);
                    break;
            }
        })
        .catch(error => {
            setError(<AlertForm message={registro_no_eliminado}/>);
        });
    };

    const abrirModal = e => {
        e.preventDefault();
        setOpenModal(true);
    };

    return (
        <Fragment>
            <div id="FormNotesAdd" className="row">
                <div className={(empleadoId) ? 'col-sm-6 col-md-8 col-lg-8 col-xl-10' : 'col'}>
                    {muestraTextAreaNota &&
                        <InputValidation
                            title="Nota Adicional" type="notaAdicional" fieldType="textarea" refValue={notaRef}
                            tooltipText={'Máximo 2024 letras, números, espacios y/o signos de puntuación . , : ; ( ) " = - + * / \' % # & $ @'}
                            defaultValue="" optional={true} alfanumeric={'/.,:;\\-+=()"\'#&$@*% '}
                        />
                    }
                    <br/>
                </div>
                {(empleadoId) &&
                <Fragment>
                    <div className="col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-start alignbtnInput">
                        <TooltipComponent tooltipText="Guardar nota">
                            <button className="btn-autocompleteSueldo" onClick={addNota}><Save/></button>
                        </TooltipComponent>
                    </div>
                    <div className="col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-start d-flex justify-content-end alignbtnInput">
                        <StyledBadge badgeContent={(notas.length > 0) ? notas.length : '0'}>
                            <TooltipComponent tooltipText="Ver notas del empleado">
                                <button className="btn-autocompleteSueldo" onClick={abrirModal}><Visibility/></button>
                            </TooltipComponent>
                        </StyledBadge>
                    </div>
                </Fragment>
                }
            </div>
            {(empleadoId) &&
            <ModalService
                parentNode={modulo}
                title="Notas Adicionales"
                isOpen={openModal} onClose={cerrarModal}
                minWidth="60%"
                maxWidth="60%"
            >
            {(notas.length > 0)
            ?   <Fragment>
                {notas.map(nota => (
                    <Nota key={nota.id} notaObject={nota}
                        idEditar={idEditar} muestraTextArea={muestraTextArea} ocultaTextArea={ocultaTextArea}
                        servicioEditarNota={servicioEditarNota} servicioEliminarNota={servicioEliminarNota}
                    />
                ))}
                { error }
                </Fragment>
            :   <AlertForm message="No hay notas"/>
            }
            </ModalService>
            }
        </Fragment>
    );
}
 
export default NotasAdicionales;