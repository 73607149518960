import React, { createRef, useState, Fragment, useEffect } from 'react'; 
import { validarSubmit } from '../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../Services/AuthService';
import axios from 'axios';
import { servicios_grupohit, servicios_grupohit_rh } from '../../../../../Constants/Constants';
import { Search } from '@material-ui/icons'; 
import AlertForm from '../../../../../Services/AlertForm';  
import CircularProgress from '@material-ui/core/CircularProgress'; 
import InputValidation from '../../../../../Services/Validation/InputValidation'; 
import { createMuiTheme, MuiThemeProvider, Button, Radio} from '@material-ui/core';
import SelectValidation from '../../../../../Services/Validation/SelectValidation';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
});

const arraySearch = [
    { id: 'NUE', name: 'NUE' }, 
    { id: 'NUP', name: 'NUP' }, 
    { id: 'RFC', name: 'RFC' }, 
    { id: 'CURP', name: 'CURP' }, 
    { id: 'NSS', name: 'NSS' }, 
];

const FormEmpleadosBusquedaAvanzada = ({ error, setEmpleados, isLoading, setIsLoading, pagosUnicos }) => { 
    
    const searchTextRef = createRef( '' );
    const selectSearchRef = createRef('');
    const [ searchByUniqueData, setSearchByUniqueData ] = useState( false );
    const [ searchByGeneralData, setSearchByGeneralData ] = useState( true );
    const [ searchByPagosAutorizados, setSearchByPagosAutorizados ] = useState( false );
    const [ butonLevel, setButtonLevel ] = useState(false) ;
    const [ activeTypeSearch, setActiveTypeSearch ] =  useState('');
    const [ errorForm, setErrorForm ] = useState( null );
    const idForm = (pagosUnicos) ? 'FormPagosUnicos' : 'EmpleadosBusquedaAvanzada';

    useEffect(() => {
        if(searchByPagosAutorizados) {
            setIsLoading(true); 
            axios.get(`${servicios_grupohit}/buscador/pagos/pendientes/autorizados`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        if( res.data.dataResponse.length>0){
                            const data = res.data.dataResponse.map(element=>{element.nombreCompleto=`${element.empleadoPat} ${element.empleadoMat} ${element.empleadoNom}`; return element});
                            setEmpleados( data );
                        }else{
                            setEmpleados(null);
                            setErrorForm( <AlertForm message={'No se encontraron registros.'} /> ); 
                        } 
                        break; 
                    default:
                        setEmpleados(null);
                        setErrorForm( <AlertForm message={res.data.msg} /> ); 
                        break;
                }
            })
            .catch(error => {
                setIsLoading(false);
                setEmpleados(null);
                setErrorForm( <AlertForm message={'No se encontraron registros.'} /> );
            })
            .finally(() => setIsLoading(false)); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchByPagosAutorizados]);
    
    const consultarBusquedaAvanzada = ( data ) => {
        setIsLoading(true); 
        const servicio = searchByGeneralData ? `${servicios_grupohit}/buscador/vempleado/` :`${servicios_grupohit_rh}/vempleados/filters/`; 
        axios.post(servicio, data, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setIsLoading(false);
                    if( res.data.dataResponse.length>0){
                        const data = res.data.dataResponse.map(element=>{element.nombreCompleto=`${element.empleadoPat} ${element.empleadoMat} ${element.empleadoNom}`; return element});
                        setEmpleados( data );
                    }else{
                        setEmpleados(null);
                        setErrorForm( <AlertForm message={'No se encontraron registros.'} /> ); 
                    } 
                    break; 
                default:
                    setIsLoading(false);
                    setEmpleados(null);
                    setErrorForm( <AlertForm message={res.data.msg} /> ); 
                    break;
            }
        })
        .catch(error => {
            setIsLoading(false);
            setEmpleados(null);
            setErrorForm( <AlertForm message={'No se encontraron registros.'} /> );
        }); 
    }

    const enviarAuditorias = (evt) => {
        evt.preventDefault();
        if(validarSubmit(idForm)){
            setErrorForm( null );
            let data = { nue: searchTextRef.current.value };
             
            switch(activeTypeSearch){
                case 'NUP': data = { nup: searchTextRef.current.value }; break;
                case 'RFC': data = { rfc: searchTextRef.current.value }; break;
                case 'CURP': data = { curp: searchTextRef.current.value }; break;
                case 'NSS': data = { nss: searchTextRef.current.value }; break;
                default: break;
            }
            if(searchByGeneralData){
                data = { valor : searchTextRef.current.value };   
            }
            consultarBusquedaAvanzada(data); 
        }else{
            setButtonLevel( true );
        }
    }

    const validInput = ( ) => {
        if(searchTextRef.current.className === "form-control is-invalid" && searchTextRef.current.value===""){
            setButtonLevel( true );
        }else{
            setButtonLevel( false );
        }
    }
 
    const fields = (title) => {
        if(title === '') return;
        
        let result = [];
        const propsInput = { 
            tooltipNUE: 'Máximo 10 números', 
            typeNUE: 'numEmpleado',
            maxLengthNUE: 10,
            alfanumericNUE: false,
            onlyNumbersNUE: true, 
            placeholderNUE: "Número Único de Empleado",
            tooltipNUP: 'De 2 a 20 números, iniciando con un número del 1 al 9',
            typeNUP: 'clavePlaza',
            maxLengthNUP: 20,
            alfanumericNUP: false,
            onlyNumbersNUP: true,
            placeholderNUP: "Número Único de Plaza",
            tooltipRFC: '13 letras y números en formato de RFC',
            typeRFC: 'rfc',
            maxLengthRFC: 13,
            alfanumericRFC: true,
            onlyNumbersRFC: false,
            placeholderRFC: "Registro Federal de Contribuyentes",
            tooltipCURP: '18 letras y números en formato de CURP',
            typeCURP: 'curp',
            maxLengthCURP: 18,
            alfanumericCURP: true,
            onlyNumbersCURP: false,
            placeholderCURP: "Clave Única de Registro de Población",
            tooltipNSS: '11 números',
            typeNSS: 'nss',
            maxLengthNSS: 11,
            alfanumericNSS: false,
            onlyNumbersNSS: true, 
            placeholderNSS: "Número de Seguridad Social",
        }

        result.push(
            <Fragment key={title}>
                <div className="col-6">
                    <InputValidation 
                        type={propsInput['type'+title]} 
                        placeholder={`Escriba ${title==="CURP" ? "la" :"el"} ${propsInput['placeholder'+title]}`}
                        tooltipName={`tooltip${title}AdvancedSearch`} tooltipText={propsInput['tooltip'+title]} 
                        maxLength={propsInput['maxLength'+title]} alfanumeric={propsInput['alfanumeric'+title]} onlyNumbers={propsInput['onlyNumbers'+title]}
                        refValue={searchTextRef} required action={validInput} />
                </div>
                <div className="col-2">
                    <Button variant="contained" className="btn-third" style={{position:'absolute', bottom: butonLevel ? 21 :10}} type="submit"> 
                        { isLoading ? <CircularProgress size={25}/> : <Search/> } 
                    </Button> 
                </div>
            </Fragment>
        )
        return result;
    }

    const muestraInput = valor => {
        setActiveTypeSearch(valor);
    };

    return (
        <div className="card py-2 px-3 mb-2">
            <MuiThemeProvider theme={theme}>
            <form id={idForm} onSubmit={enviarAuditorias}>
                <h4 className="mt-2"> {'Consultar Empleado'} </h4>
                <label>{'Se puede consultar todo aquel empleado registrado en el sistema. Seleccione la opción de búsqueda e ingrese cualquiera de los datos solicitados.'}</label>
                <hr/>
                <div className="row">
                    <div className={`${(pagosUnicos) ? 'col-md-4' : 'col-md-6' } col-sm-12`}>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <Radio checked={searchByGeneralData} onChange={()=>{setSearchByGeneralData(true); setSearchByUniqueData(false); setSearchByPagosAutorizados(false); setEmpleados(null); setErrorForm(null)}} color="secondary" />
                                <label>{'Buscar por Datos Generales'}</label> 
                            </div>    
                        </div>
                        {
                            searchByGeneralData &&
                            <Fragment>    
                            <div className="row"> 
                                <div className="col-md-10 col-sm-10"  style={{paddingInlineStart:'8%'}}>
                                    <InputValidation type="text" placeholder="Escriba los datos generales del empleado a buscar"
                                        maxLength={100} refValue={searchTextRef} required action={validInput}
                                    />
                                </div>
                                <div className="col-md-2 col-sm-1">
                                    <Button variant="contained" className="btn-third" style={{position:'absolute', bottom: butonLevel ? 25 : 10}} type="submit"> 
                                        { isLoading ? <CircularProgress size={25}/> : <Search/> } 
                                    </Button> 
                                </div>
                            </div> 
                            <div className="row"> 
                                <div className="col-md-10 col-sm-10"  style={{paddingInlineStart:'8%'}}>
                                    {errorForm}
                                </div> 
                            </div>
                            </Fragment>
                        }
                    </div> 
                    <div className={`${(pagosUnicos) ? 'col-md-4' : 'col-md-6' } col-sm-12`}>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <Radio checked={searchByUniqueData} onChange={()=>{setSearchByGeneralData(false); setSearchByUniqueData(true); setSearchByPagosAutorizados(false); setEmpleados(null); setErrorForm(null)}} color="secondary" />
                                <label>{'Buscar por Datos Únicos'}</label> 
                            </div>    
                        </div>
                        {
                            searchByUniqueData && 
                            <Fragment> 
                                <div className="row">
                                    <div className="col-4">
                                        <SelectValidation title=""
                                            arrayOpciones={arraySearch} keyOpcion="name" refValue={selectSearchRef} defaultValue="" onChangeExecute={muestraInput} required 
                                        />
                                    </div>
                                    { fields(activeTypeSearch) } 
                                </div>
                                {errorForm}
                            </Fragment>
                        }
                    </div>
                    {(pagosUnicos) &&
                        <div className="col-md-4 col-sm-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <Radio checked={searchByPagosAutorizados} onChange={()=>{setSearchByGeneralData(false); setSearchByUniqueData(false); setSearchByPagosAutorizados(true); setEmpleados(null); setErrorForm(null)}} color="secondary" />
                                    <label>Buscar Empleados con Pagos Autorizados</label> 
                                </div>    
                            </div>
                        </div>
                    }
                </div>
            </form>
            </MuiThemeProvider>
        </div>
    );
};

export default FormEmpleadosBusquedaAvanzada;