import React, { useState, createRef, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_admon, registro_no_guardado } from '../../../../../../Constants/Constants';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonProyectos } from '../../../Helpers/JsonToOneLevel';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import Button from '@material-ui/core/Button';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const FormFaltante = ({ idModulo, error, info, setOpenAdd, agregar, errorTable, setOpenAddFirst }) => {

    const [claveUEjecutora, setClaveUEjecutora] = useState('');
    const [nombreUEjecutora, setNombreUEjecutora] = useState('');
    const [clavePrograma, setClavePrograma] = useState('');
    const [nombrePrograma, setNombrePrograma] = useState('');
    const [claveSubprograma, setClaveSubprograma] = useState('');
    const [nombreSubprograma, setNombreSubprograma] = useState('');
    const [clavePAccion, setClavePAccion] = useState('');
    const [nombrePAccion, setNombrePAccion] = useState('');
    const [claveOAccion, setClaveOAccion] = useState('');
    const [nombreOAccion, setNombreOAccion] = useState('');
    const [claveCPresupuestal, setClaveCPresupuestal] = useState('');
    const [nombreCPresupuestal, setNombreCPresupuestal] = useState('');
    const [openLoading, setOpenLoading] = useState(false);

    const claveUEjecutoraRef = createRef('');
    const nombreUEjecutoraRef = createRef('');
    const claveProgramaRef = createRef('');
    const nombreProgramaRef = createRef('');
    const claveSubprogramaRef = createRef('');
    const nombreSubprogramaRef = createRef('');
    const clavePAccionRef = createRef('');
    const nombrePAccionRef = createRef('');
    const claveOAccionRef = createRef('');
    const nombreOAccionRef = createRef('');
    const claveCvePresupuestalRef = createRef('');
    const nombreCvePresupuestalRef = createRef('');

    const guardarUEjecutora = () => {
        const udejecutoraNom = nombreUEjecutora;
        const udejecutoraClave = `${info.uResponsable.clave}${claveUEjecutora}`;

        const request = { ciaId: info.uResponsable.id, udejecutoraClave, udejecutoraNom };
        axios.post(`${servicios_grupohit_admon}/ejecutora/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    guardarPrograma(res.data.dataResponse.id);
                    break;
                default:
                    setOpenLoading(false);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { setOpenLoading(false); errorTable(registro_no_guardado) });
    };

    const guardarPrograma = (id = info.uEjecutora.id) => {
        const programanom = nombrePrograma;
        const programaclave = (info.info === 1)
                            ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}`
                            :   `${info.uEjecutora.clave}${clavePrograma}`;

        const request = { udejecutoraid: id, programaclave, programanom };
        axios.post(`${servicios_grupohit_admon}/programa/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    guardarSubprograma(res.data.dataResponse.id);
                    break;
                default:
                    setOpenLoading(false);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { setOpenLoading(false); errorTable(registro_no_guardado) });
    };

    const guardarSubprograma = (id = info.programa.id) => {
        const subProgramaNom = nombreSubprograma;
        const subProgramaClave = (info.info === 1)
                            ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${claveSubprograma}`
                            :   (info.info === 2) 
                                ?   `${info.uEjecutora.clave}${clavePrograma}${claveSubprograma}`
                                    :   `${info.programa.clave}${claveSubprograma}`

        const request = { programa: id, subProgramaClave, subProgramaNom };
        axios.post(`${servicios_grupohit_admon}/sub/programa/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    guardarPAccion(res.data.dataResponse.id);
                    break;
                default:
                    setOpenLoading(false);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { setOpenLoading(false); errorTable(registro_no_guardado) });
    };

    const guardarPAccion = (id = info.subprograma.id) => {
        const proyectonom = nombrePAccion;
        const proyectoclave = (info.info === 1)
                            ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${claveSubprograma}${clavePAccion}`
                            :   (info.info === 2) 
                                ?   `${info.uEjecutora.clave}${clavePrograma}${claveSubprograma}${clavePAccion}`
                                :   (info.info === 3)
                                    ?   `${info.programa.clave}${claveSubprograma}${clavePAccion}`
                                    :   `${info.subprograma.clave}${clavePAccion}`;

        const request = { subProgramaid: id, proyectoclave, proyectonom };
        axios.post(`${servicios_grupohit_admon}/proyecto/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    guardarOAccion(res.data.dataResponse.id);
                    break;
                default:
                    setOpenLoading(false);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { setOpenLoading(false); errorTable(registro_no_guardado) });
    };

    const guardarOAccion = (id = info.pAccion.id) => {
        const obraAccionNom = nombreOAccion;
        const obraAccionClave =  (info.info === 1)
                                    ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${claveSubprograma}${clavePAccion}${claveOAccion}`
                                    :   (info.info === 2) 
                                        ?   `${info.uEjecutora.clave}${clavePrograma}${claveSubprograma}${clavePAccion}${claveOAccion}`
                                        :   (info.info === 3)
                                            ?   `${info.programa.clave}${claveSubprograma}${clavePAccion}${claveOAccion}`
                                            :   (info.info === 4)
                                                ?   `${info.subprograma.clave}${clavePAccion}${claveOAccion}`
                                                :   `${info.pAccion.clave}${claveOAccion}`;

        const request = { proyecto: id, obraAccionClave, obraAccionNom };
        axios.post(`${servicios_grupohit_admon}/obra/accion/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    guardarCvePresupuestal(res.data.dataResponse.id);
                    break;
                default:
                    setOpenLoading(false);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error =>{ setOpenLoading(false); errorTable(registro_no_guardado) });
    };

    const guardarCvePresupuestal = id => {
        const cpresupuestalnom = nombreCPresupuestal;
        const cpresupuestalclave =  (info.info === 1)
                                    ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${claveSubprograma}${clavePAccion}${claveOAccion}${claveCPresupuestal}`
                                    :   (info.info === 2) 
                                        ?   `${info.uEjecutora.clave}${clavePrograma}${claveSubprograma}${clavePAccion}${claveOAccion}${claveCPresupuestal}`
                                        :   (info.info === 3)
                                            ?   `${info.programa.clave}${claveSubprograma}${clavePAccion}${claveOAccion}${claveCPresupuestal}`
                                            :   (info.info === 4)
                                                ?   `${info.subprograma.clave}${clavePAccion}${claveOAccion}${claveCPresupuestal}`
                                                :   `${info.pAccion.clave}${claveOAccion}${claveCPresupuestal}`;

        const request = { obraId: id, cpresupuestalclave, cpresupuestalnom };
        axios.post( `${servicios_grupohit_admon}/presupuestal/`, request, headersAuth())
        .then(res => {
            setOpenLoading(false);
            switch(res.data.status) {
                case 200:
                    const proyecto = JsonProyectos(res.data.dataResponse);
                    agregar(proyecto);
                    setOpenAdd(false);
                    setOpenAddFirst(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { setOpenLoading(false); errorTable(registro_no_guardado) });
    };
    
    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarFaltanteProyecto')) {
            setOpenLoading(true);
            switch(info.info) {
                case 1:
                    guardarUEjecutora();
                break;
                case 2:
                    guardarPrograma();
                break;
                case 3:
                    guardarSubprograma();
                break;
                case 4:
                    guardarPAccion();
                break;
                case 5:
                    guardarOAccion();
                break;
                default:
                    setOpenLoading(false);
                break;
            }
        }
    };

    const obtenerCveUE = () => {
        setClaveUEjecutora(claveUEjecutoraRef.current.value);
    };
    
    const obtenerNomUE = () => {
        setNombreUEjecutora(nombreUEjecutoraRef.current.value);
    };

    const obtenerCveProg = () => {
        setClavePrograma(claveProgramaRef.current.value);
    };

    const obtenerNomProg = () => {
        setNombrePrograma(nombreProgramaRef.current.value);
    };

    const obtenerCveSubprog = () => {
        setClaveSubprograma(claveSubprogramaRef.current.value);
    };

    const obtenerNomSubprog = () => {
        setNombreSubprograma(nombreSubprogramaRef.current.value);
    };

    const obtenerCvePA = () => {
        setClavePAccion(clavePAccionRef.current.value);
    };

    const obtenerNomPA = () => {
        setNombrePAccion(nombrePAccionRef.current.value);
    };

    const obtenerCveOA = () => {
        setClaveOAccion(claveOAccionRef.current.value);
    };

    const obtenerNomOA = () => {
        setNombreOAccion(nombreOAccionRef.current.value);
    };

    const obtenerCveCP = () => {
        setClaveCPresupuestal(claveCvePresupuestalRef.current.value);
    };

    const obtenerNomCP = () => {
        setNombreCPresupuestal(nombreCvePresupuestalRef.current.value);
    };

    return (
        <Fragment>
            <form id="AgregarFaltanteProyecto" onSubmit={ e => enviar(e) }>
                <div className="row">
                    <div className="col-12">
                        <label className="textGreen">Unidad Ejecutora</label>
                    </div>
                </div>
                <label>
                    Clave<strong className="requerido">* </strong>
                    <TooltipComponent tooltipText={(info.info >= 2) ? '6 números' : '3 números'}>
                        <span id="cveUEjecutora" className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                    </TooltipComponent>
                </label>
                <div className="row">
                    {
                        (info.info >= 2)
                        ?   null
                        :   <div className="col-7">
                                <label className="form-control label-control">
                                    { `${info.uResponsable.clave}` }
                                </label>
                            </div>
                    }
                    <div className={(info.info >= 2) ? 'col-12' : 'col-5'}>
                        <InputValidation type={(info.info >= 2) ? 'claveUEjecutora6' : 'claveUEjecutora3'} placeholder="Clave"
                            refValue={claveUEjecutoraRef} defaultValue={(info.info >= 2) ? info.uEjecutora.clave : ''} onlyNumbers
                            maxLength={(info.info >= 2) ? 6 : 3} action={obtenerCveUE} disabled={ (info.info >= 2) ? true : false}
                            optional={ (info.info >= 2) ? true : false}/>
                    </div>
                </div>
                <InputValidation title="Nombre" type="nombreUEjecutora" placeholder="Nombre" refValue={nombreUEjecutoraRef}
                    tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}
                    maxLength={128} defaultValue={(info.info >= 2) ? info.uEjecutora.nombre : ''} action={obtenerNomUE}
                    disabled={(info.info >= 2) ? true : false} optional={ (info.info >= 2) ? true : false} required/>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <label className="textGreen">Programa</label>
                    </div>
                </div>
                <label>
                    Clave<strong className="requerido">* </strong>
                    <TooltipComponent tooltipText={ (info.info >= 3) ? '9 números' : '3 números' }>
                        <span id="cvePrograma" className="spanInfo"><InfoRoundedIcon color="disabled"/> </span>
                    </TooltipComponent>
                </label>
                <div className="row">
                    {
                        (info.info >= 3)
                        ?   null
                        :   <div className="col-7">
                                <label className="form-control label-control">
                                    {
                                        (info.info === 1)
                                        ?   `${info.uResponsable.clave}${claveUEjecutora}`
                                        :   (info.info === 2)
                                            ?   info.uEjecutora.clave
                                            :   info.programa.clave
                                    }
                                </label>
                            </div>
                    }
                    <div className={(info.info >= 3) ? 'col-12' : 'col-5'}>
                        <InputValidation
                            type={(info.info >= 3) ? 'clavePrograma9' : 'clavePrograma3'} placeholder="Clave" refValue={claveProgramaRef}
                            defaultValue={(info.info >= 3) ? info.programa.clave : ''} action={obtenerCveProg} onlyNumbers
                            maxLength={(info.info >= 3) ? 9 : 3} disabled={(info.info >= 3) ? true : false} optional={(info.info >= 3) ? true : false}/>
                    </div>
                </div>
                <InputValidation title="Nombre" type="nombrePrograma" placeholder="Nombre" refValue={nombreProgramaRef}
                    tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}
                    maxLength={128} defaultValue={(info.info >= 3) ? info.programa.nombre : ''} action={obtenerNomProg}
                    disabled={(info.info >= 3) ? true : false} optional={ (info.info >= 3) ? true : false} required/>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <label className="textGreen">Subprograma</label>
                    </div>
                </div>
                <label>
                    Clave<strong className="requerido">* </strong>
                    <TooltipComponent tooltipText={(info.info >= 4) ? '11 números' : '2 números'}>
                        <span className="spanInfo"><InfoRoundedIcon color="disabled"/> </span>
                    </TooltipComponent>
                </label>
                <div className="row">
                    {
                        (info.info >= 4)
                        ?   null
                        :   <div className="col-7">
                                <label className="form-control label-control">
                                    {
                                        (info.info === 1)
                                        ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}`
                                        :   (info.info === 2)
                                            ?   `${info.uEjecutora.clave}${clavePrograma}`
                                            :   (info.info === 3)
                                                ?   `${info.programa.clave}`
                                                :   `${info.subprograma.clave}`
                                    }
                                </label>
                            </div>
                    }
                    <div className={(info.info >= 4) ? 'col-12' : 'col-5'}>
                        <InputValidation type={(info.info >= 4) ? 'claveSubprograma11' : 'claveSubprograma2'} placeholder="Clave" refValue={claveSubprogramaRef}
                            defaultValue={(info.info >= 4) ? info.subprograma.clave : ''} action={obtenerCveSubprog} onlyNumbers
                            maxLength={(info.info >= 4) ? 11 : 2} disabled={(info.info >= 4) ? true : false} optional={(info.info >= 4) ? true : false}/>
                    </div>
                </div>
                <div className="row">
                    
                </div>
                <InputValidation title="Nombre" type="nombreSubprograma" placeholder="Nombre" refValue={nombreSubprogramaRef}
                    tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}
                    maxLength={128} defaultValue={(info.info >= 4) ? info.subprograma.nombre : ''} action={obtenerNomSubprog}
                    disabled={(info.info >= 4) ? true : false} optional={ (info.info >= 4) ? true : false} required/>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <label className="textGreen">Proyecto Acción</label>
                    </div>
                </div>
                <label>
                    Clave<strong className="requerido">* </strong>
                    <TooltipComponent tooltipText={(info.info >= 5) ? '14 números' : '3 números'}>
                        <span id="cveProyAccion" className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                    </TooltipComponent>
                </label>
                <div className="row">
                    {
                        (info.info >= 5)
                        ?   null
                        :   <div className="col-7">
                                <label className="form-control label-control">
                                {
                                    (info.info === 1)
                                    ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${claveSubprograma}`
                                    :   (info.info === 2) 
                                        ?   `${info.uEjecutora.clave}${clavePrograma}${claveSubprograma}`
                                        :   (info.info === 3)
                                            ?   `${info.programa.clave}${claveSubprograma}`
                                            :   `${info.subprograma.clave}`
                                }
                                </label>
                            </div>
                    }
                    <div className={(info.info >= 5) ? 'col-12' : 'col-5'}>
                        <InputValidation type={(info.info >= 5) ? 'clavePAccion14' : 'clavePAccion3'} placeholder="Clave" refValue={clavePAccionRef}
                            defaultValue={(info.info >= 5) ? info.pAccion.clave : ''} action={obtenerCvePA} onlyNumbers
                            maxLength={(info.info >= 5) ? 14 : 3} disabled={(info.info >= 5) ? true : false} optional={(info.info >= 5) ? true : false}/>
                    </div>
                </div>
                <InputValidation title="Nombre" type="nombrePAccion" placeholder="Nombre" refValue={nombrePAccionRef}
                    tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'} action={obtenerNomPA}
                    defaultValue={(info.info >= 5) ? info.pAccion.nombre : ''} disabled={(info.info >= 5) ? true : false} maxLength={128} required/>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <label className="textGreen">Obra Acción</label>
                    </div>
                </div>
                <label>
                    Clave<strong className="requerido">* </strong>
                    <TooltipComponent tooltipText={'3 números'}>
                        <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                    </TooltipComponent>
                </label>
                <div className="row">
                    <div className="col-7">
                        <label className="form-control label-control">
                            {
                                (info.info === 1)
                                ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${claveSubprograma}${clavePAccion}`
                                :   (info.info === 2) 
                                    ?   `${info.uEjecutora.clave}${clavePrograma}${claveSubprograma}${clavePAccion}`
                                    :   (info.info === 3)
                                        ?   `${info.programa.clave}${claveSubprograma}${clavePAccion}`
                                        :   (info.info === 4)
                                            ?   `${info.subprograma.clave}${clavePAccion}`
                                            :   `${info.pAccion.clave}`
                            }
                        </label>
                    </div>
                    <div className="col-5">
                        <InputValidation type="claveOAccion3" placeholder="Clave" refValue={claveOAccionRef} defaultValue="" maxLength={3}
                            action={obtenerCveOA} onlyNumbers/>
                    </div>
                </div>
                <InputValidation title="Nombre" type="nombrePAccion" placeholder="Nombre" refValue={nombreOAccionRef}
                    tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'} action={obtenerNomOA}
                    defaultValue="" maxLength={128} required/>
                <br/>
                <div className="row">
                    <div className="col-12">
                        <label className="textGreen">Centro de Trabajo / Proyecto / Clave Presupuestal</label>
                    </div>
                </div>
                <label>
                    Clave<strong className="requerido">* </strong>
                    <TooltipComponent tooltipText={'2 letras, números y/o caracteres especiales = @ # * < >'}>
                        <span id="cveCtrTrabajo" className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                    </TooltipComponent>
                </label>
                <div className="row">
                    <div className="col-7">
                        <label className="form-control label-control">
                            {
                                (info.info === 1)
                                ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${claveSubprograma}${clavePAccion}${claveOAccion}`
                                :   (info.info === 2) 
                                    ?   `${info.uEjecutora.clave}${clavePrograma}${claveSubprograma}${clavePAccion}${claveOAccion}`
                                    :   (info.info === 3)
                                        ?    `${info.programa.clave}${claveSubprograma}${clavePAccion}${claveOAccion}`
                                        :   (info.info === 4)
                                            ?   `${info.subprograma.clave}${clavePAccion}${claveOAccion}`
                                            :   (info.info === 5)
                                                ?   `${info.pAccion.clave}${claveOAccion}`
                                                :   `${info.oAccion.clave}`
                                        
                            }
                        </label>
                    </div>
                    <div className="col-5">
                        <InputValidation type="claveCvePresupuestal" placeholder="Clave" refValue={claveCvePresupuestalRef}
                            action={obtenerCveCP} defaultValue="" maxLength={2}/>
                    </div>
                </div>
                <InputValidation title="Nombre" type="nombreCvePresupuestal" placeholder="Nombre" refValue={nombreCvePresupuestalRef}
                    tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'} action={obtenerNomCP}
                    defaultValue="" maxLength={128} required/>
                { error }
                <br/>
                <Button variant="contained" className="btn-color" type="submit">Agregar</Button>
            </form>
            <ModalLoading nombreModulo={idModulo} open={openLoading} setOpen={setOpenLoading}/>
        </Fragment>
    );
}
 
export default FormFaltante;