import React, { useState, useEffect, useRef, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit, servicios_grupohit_nomina, servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import FormularioReciboPago from '../../Reporteador/Nominas/FormularioReciboPago';
import FormAddConceptos from '../../Reporteador/Nominas/FormAddConceptosDeduccion';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import AlertForm from '../../../../../../Services/AlertForm';
import Button from '@material-ui/core/Button';
import { Spinner } from 'reactstrap';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import InfoEmpleado from '../../Tablero/InfoEmpleado';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import InfoPagosUnicos from './InfoPagosUnicos';

const FormModalPagosUnicos = ({ permisos, nombreModulo, empleado, errorTable, cerrarModal }) => {

    const [agregarPagoUnico, setAgregarPagoUnico] = useState(false);
    const [pagosUnicosVigentes, setPagosUnicosVigentes] = useState(null);
    const [nominas, setNominas] = useState([]);
    const [nomina, setNomina] = useState(null);
    const [anios, setAnios] = useState([]);
    const [anios2, setAnios2] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [periodos2, setPeriodos2] = useState([]);
    const [periodosInicial, setPeriodosInicial] = useState([]);
    const [periodosFinal, setPeriodosFinal] = useState([]);
    const [tiposAcumulado, setTiposAcumulado] = useState([]);
    const [isLoading, setIsLoading] = useState({ nominaLoading: false, anioLoading: false, periodoLoading: false, periodo2Loading: false, tipoAcumuladoLoading: false });
    const [isDisabled, setIsDisabled] = useState({ nominaDisabled: true, anioDisabled: true, anioInicialDisabled: true, periodoDisabled: true, periodo2Disabled: true, tipoAcumuladoDisabled: true });
    const [periodoInicialLoading, setPeriodoInicialLoading] = useState(false);
    const [periodoInicialDisabled, setPeriodoInicialDisabled] = useState(true);
    const [periodoFinalLoading, setPeriodoFinalLoading] = useState(false);
    const [periodoFinalDisabled, setPeriodoFinalDisabled] = useState(true);
    const [error, setError] = useState(null);
    const [error2, setError2] = useState(null);
    const [openLoading, setOpenLoading] = useState(false);
    const [ultimoAnio, setUltimoAnio] = useState('0');
    const [ultimoPeriodo, setUltimoPeriodo] = useState('0');

    const [muestraRecibo, setMuestraRecibo] = useState(false);
    const [indicadoresSelected, setIndicadoresSelected] = useState([]);
    const [percepciones, setPercepciones] = useState([]);
    const [deducciones, setDeducciones] = useState([]);
    const [totalP, setTotalP] = useState(0);
    const [totalD, setTotalD] = useState(0);
    const [openAlertW, setOpenAlertW] = useState(false);
    const [openAlertWAP, setOpenAlertWAP] = useState(false);
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const [alertData, setAlertData] = useState({ title: '', text: '', type: '', action: false });

    const { nominaLoading, anioLoading, anioInicialLoading, periodoLoading, periodo2Loading, tipoAcumuladoLoading } = isLoading;
    const { nominaDisabled, anioDisabled, anioInicialDisabled, periodoDisabled, periodo2Disabled, tipoAcumuladoDisabled } = isDisabled;

    const nominaRef = useRef('');
    const anioRef = useRef('');
    const anio2Ref = useRef('');
    const anioInicialRef = useRef('');
    const anioFinalRef = useRef('');
    const periodoRef = useRef('');
    const periodo2Ref = useRef('');
    const periodoInicialRef = useRef('');
    const periodoFinalRef = useRef('');
    const tipoAcumuladoRef = useRef('');

    useEffect(() => {
        if(anios2.length > 0 && !anioInicialLoading && !anioInicialDisabled) {
            anio2Ref.current.value = anios2[0].id;
            servicePeriodos2('aplica', anios2[0].id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anios2, anioInicialLoading, anioInicialDisabled]);

    useEffect(() => {
        if(periodos2.length > 0 && !periodo2Loading && !periodo2Disabled) {
            periodo2Ref.current.value = periodos2[0].id;
            if(ultimoAnio !== anio2Ref.current.value) {
                setOpenAlertWAP(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodos2, periodo2Loading, periodo2Disabled]);

    useEffect(() => {
        setIsLoading({ ...isLoading, nominaLoading: true });
        axios.get(`${servicios_grupohit_rh}/empleado/nomina/${empleado.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    let nominas = [];
                        let nominasRepeated = [];

                        res.data.dataResponse.map(nomina => {
                            if(!nominasRepeated[nomina.nomina.nominaClave]) {
                                nominas.push({
                                    id: nomina.nomina.id, 
                                    nombreNomina: `${nomina.nomina.nominaClave} - ${nomina.nomina.nominaNombre}`
                                })
                            }
                            nominasRepeated[nomina.nomina.nominaClave] = nomina.nomina.nominaClave;
                            return {};
                        });
                        setNominas(nominas);
                    break;
                default:
                    setError(<AlertForm message={res.data.msg} />);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => {
            setIsLoading({ ...isLoading, nominaLoading: false });
            setIsDisabled({ ...isDisabled, nominaDisabled: false });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(pagosUnicosVigentes && (pagosUnicosVigentes.length ===0)) {
            setAgregarPagoUnico(true);
        }
    }, [pagosUnicosVigentes]);

    const mostrarFormPagoUnico = () => {
        setAgregarPagoUnico(!agregarPagoUnico);
    };

    const limpiaInformativo = () => {
        setError2(null);
        setPeriodosInicial([]);
        setPeriodosFinal([]);
        setPeriodoInicialDisabled(true);
        setPeriodoFinalDisabled(true);
    };

    const obtieneAnios = nominaObject => {
        setError(null);
        setNomina(nominaObject);
        if(nominaObject) {
            setIsLoading({ ...isLoading, anioLoading: true });
            axios.get(`${servicios_grupohit_nomina}/acumulados/${empleado.id}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayAnios = res.data.dataResponse.map(item => ( { id: item.periodoAnio, anio: item.periodoAnio } ))
                        setAnios(arrayAnios);
                        break;
                    default:
                        setAnios([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setAnios([]); errorTable(verifique_conexion); })
            .finally(() => {
                setMuestraRecibo(false);
                setIsLoading({ ...isLoading, anioLoading: false });
                setIsDisabled({ ...isDisabled, anioDisabled: false, anioInicialDisabled: true, periodo2Disabled: true });
                limpiaInformativo();
            });
        } else {
            setMuestraRecibo(false);
            setAnios([]);
            setPeriodos([]);
            setPeriodos2([]);
            setTiposAcumulado([]);
            setIsDisabled({ ...isDisabled, anioDisabled: true, periodoDisabled: true, tipoAcumuladoDisabled: true, anioInicialDisabled: true, periodo2Disabled: true });
            limpiaInformativo();
        }
    };

    const obtieneAnios2 = () => {
        setIsLoading({ ...isLoading, anioInicialLoading: true });
        axios.get(`${servicios_grupohit_nomina}/acumulados/periodo/${nominaRef.current?.value}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayAnios = res.data.dataResponse.map(item => ( { id: item.periodoAnio, anio: item.periodoAnio } ));
                    setAnios2(arrayAnios);
                    setUltimoAnio(String(arrayAnios[0].id));
                    break;
                default:
                    setAnios2([]);
                    setError2(<AlertForm message={res.data.msg} />);
                    break;
            }
        })
        .catch(error => {
            setAnios2([]);
            errorTable(verifique_conexion);
        })
        .finally(() => {
            setIsLoading({ ...isLoading, anioInicialLoading: false });
            setIsDisabled({ ...isDisabled, anioInicialDisabled: false, periodo2Disabled: true });
        });
    };
    
    const obtienePeriodos = idAnio => {
        setError(null);
        if(idAnio) {
            setIsLoading({ ...isLoading, periodoLoading: true });
            axios.get(`${servicios_grupohit_nomina}/acumulados/periodos/nomina/${empleado.id}/${idAnio}/${nominaRef.current.value}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        let arrayPeriodos = res.data.dataResponse.map(item => ( { id: item.periodoId, periodoPerAnio: item.periodoPerAnio, periodo: item.periodoPerAnio } ));
                        const periodo9 = arrayPeriodos.find(item => item.periodo === 9);
                        const periodo10 = arrayPeriodos.find(item => item.periodo === 10);
                        const periodo23 = arrayPeriodos.find(item => item.periodo === 23);
                        const periodo24 = arrayPeriodos.find(item => item.periodo === 24);
                        if(periodo9) {
                            if(periodo10) {
                                arrayPeriodos.forEach(item => {
                                    if(item.periodo === 10) item.periodo = '9/10';
                                    return item;
                                });
                            }
                        }
                        if(periodo23) {
                            if(periodo24) {
                                arrayPeriodos.forEach(item => {
                                    if(item.periodo === 24) item.periodo = '23/24';
                                    return item;
                                });
                            }
                        }
                        arrayPeriodos = arrayPeriodos.filter(item => (item.periodo !== 9) && (item.periodo !== 23));
                        setPeriodos(arrayPeriodos);
                        break;
                    default:
                        setPeriodos([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion))
            .finally(() => {
                setMuestraRecibo(false);
                setIsLoading({ ...isLoading, periodoLoading: false, periodo2Loading: false });
                setIsDisabled({ ...isDisabled, periodoDisabled: false, tipoAcumuladoDisabled: true, periodo2Disabled: true });
                limpiaInformativo();
            });
        } else {
            setMuestraRecibo(false);
            setPeriodos([]);
            setTiposAcumulado([]);
            setIsDisabled({ ...isDisabled, periodoDisabled: true, tipoAcumuladoDisabled: true, anioInicialDisabled: true, periodo2Disabled: true });
            limpiaInformativo();
        }
    };

    const servicePeriodos2 = (type, idAnio) => {
        if(idAnio) {
            if(type === 'aplica') {
                setPeriodos2([]);
                setIsDisabled({ ...isDisabled, periodo2Disabled: true });
                setIsLoading({ ...isLoading, periodo2Loading: true });
            }
            if(type === 'inicial') {
                setPeriodosInicial([]);
                setPeriodoInicialDisabled(true);
                setPeriodoInicialLoading(true);
            }
            if(type === 'final') {
                setPeriodosFinal([]);
                setPeriodoFinalDisabled(true);
                setPeriodoFinalLoading(true);
            }
            const anio = parseInt(idAnio);
            axios.post(`${servicios_grupohit}/generar/reportes/nomina/periodo/`, { anio }, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        let arrayPeriodos = res.data.dataResponse.map(item => ( { id: item, periodo: item } ));
                        if(arrayPeriodos) {
                            console.log(arrayPeriodos)
                            const periodo9 = arrayPeriodos.find(item => item.periodo === 9);
                            const periodo10 = arrayPeriodos.find(item => item.periodo === 10);
                            const periodo23 = arrayPeriodos.find(item => item.periodo === 23);
                            const periodo24 = arrayPeriodos.find(item => item.periodo === 24);
                            if(periodo9) {
                                if(periodo10) {
                                    arrayPeriodos.forEach(item => {
                                        if(item.periodo === 10) item.periodo = '9 / 10';
                                        return item;
                                    });
                                }
                            }
                            if(periodo23) {
                                if(periodo24) {
                                    arrayPeriodos.forEach(item => {
                                        if(item.periodo === 24) item.periodo = '23 / 24';
                                        return item;
                                    });
                                }
                            }
                            arrayPeriodos = arrayPeriodos.filter(item => (item.periodo !== 9) && (item.periodo !== 23));
                        }
                        if(type === 'aplica') {
                            setPeriodos2(arrayPeriodos);
                            if(ultimoAnio === idAnio) {
                                setUltimoPeriodo(String(arrayPeriodos[0].id));
                            }
                        };
                        if(type === 'inicial') setPeriodosInicial(arrayPeriodos);
                        if(type === 'final') setPeriodosFinal(arrayPeriodos);
                        break;
                    default:
                        setPeriodos2([]);
                        if(type === 'inicial') setPeriodosInicial([]);
                        if(type === 'final') setPeriodosFinal([]);
                        setError2(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => {
                console.log(error)
                setPeriodos2([]);
                if(type === 'inicial') setPeriodosInicial([]);
                if(type === 'final') setPeriodosFinal([]);
                errorTable(verifique_conexion);
            })
            .finally(() => {
                if(type === 'aplica') {
                    setIsLoading({ ...isLoading, periodo2Loading: false });
                    setIsDisabled({ ...isDisabled, periodo2Disabled: false });
                }
                if(type === 'inicial') {
                    setPeriodoInicialLoading(false);
                    setPeriodoInicialDisabled(false);
                }
                if(type === 'final') {
                    setPeriodoFinalLoading(false);
                    setPeriodoFinalDisabled(false);
                }
            });
        } else {
            if(type === 'aplica') {
                setPeriodos2([]);
                setIsDisabled({ ...isDisabled, periodo2Disabled: true });
            }
            if(type === 'inicial') {
                setPeriodosInicial([]);
                setPeriodoInicialDisabled(true);
            }
            if(type === 'final') {
                setPeriodosFinal([]);
                setPeriodoFinalDisabled(true);
            }
        }
    };

    const obtienePeriodos2 = idAnio => {
        servicePeriodos2('aplica', idAnio);
    };

    const obtienePeriodosInicial = idAnio => {

        const limpia = () => {
            setPeriodosInicial([]);
            setPeriodoInicialDisabled(true);
            setPeriodoInicialLoading(false);
        };

        const envia = () => {
            servicePeriodos2('inicial', idAnio);
            setError2(null);
        };

        if(idAnio) {
            if(anioFinalRef.current.value) {
                const anioInicialValue = parseInt(idAnio);
                const anioFinalValue = parseInt(anioFinalRef.current.value);
                if(anioInicialValue > anioFinalValue) {
                    limpia();
                    setError2(<AlertForm message="El año inicial debe ser menor o igual que el año final"/>);
                } else {
                    envia();
                }
            } else {
                envia();
            }
        } else {
            limpia();
        }
    };

    const obtienePeriodosFinal = idAnio => {

        const limpia = () => {
            setPeriodosFinal([]);
            setPeriodoFinalDisabled(true);
            setPeriodoFinalLoading(false);
        };

        const envia = () => {
            servicePeriodos2('final', idAnio);
            setError2(null);
        };

        if(idAnio) {
            if(anioFinalRef.current.value) {
                const anioInicialValue = parseInt(anioInicialRef.current.value);
                const anioFinalValue = parseInt(idAnio);
                if(anioFinalValue < anioInicialValue) {
                    limpia();
                    setError2(<AlertForm message="El año final debe ser mayor o igual que el año inicial"/>);
                } else {
                    envia();
                }
            } else {
                envia();
            }
        } else {
            limpia();
        }
    };

    const obtieneTiposAcumulado = idPeriodo => {
        setError(null);
        if(idPeriodo) {
            setIsLoading({ ...isLoading, tipoAcumuladoLoading: true });
            axios.get(`${servicios_grupohit_nomina}/acumulados/inner/${empleado.id}/${idPeriodo}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayAcumulados = res.data.dataResponse.map(item => ( { id: item.acumuladoId, tipoAcumulado: item.acumuladoNombre } ));
                        setTiposAcumulado(arrayAcumulados);
                        break;
                    default:
                        setTiposAcumulado([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setTiposAcumulado([]); errorTable(verifique_conexion); })
            .finally(() => {
                setMuestraRecibo(false);
                setIsLoading({ ...isLoading, tipoAcumuladoLoading: false });
                setIsDisabled({ ...isDisabled, tipoAcumuladoDisabled: false });
                limpiaInformativo();
            });
        } else {
            setMuestraRecibo(false);
            setTiposAcumulado([]);
            setIsDisabled({ ...isDisabled, tipoAcumuladoDisabled: true });
            limpiaInformativo();
        }
    };

    const validaPeriodoInicial = value => {
        if(!periodoFinalDisabled) {
            if(anioFinalRef.current.value && periodoFinalRef.current.value) {
                const anioInicial = parseInt(anioInicialRef.current.value);
                const anioFinal = parseInt(anioFinalRef.current.value);
                const periodoInicialValue = parseInt(value);
                const periodoFinalValue = parseInt(periodoFinalRef.current.value);
                if(anioInicial < anioFinal) {
                    setError2(null);
                }

                if(anioInicial === anioFinal) {
                    if(periodoInicialValue > periodoFinalValue) {
                        setError2(<AlertForm message="El periodo inicial debe ser menor o igual que el periodo final"/>);
                    } else {
                        setError2(null);
                    }
                }
            }
        }
    };

    const validaPeriodoFinal = value => {
        if(!periodoInicialDisabled) {
            if(anioInicialRef.current.value && periodoInicialRef.current.value) {
                const anioInicial = parseInt(anioInicialRef.current.value);
                const anioFinal = parseInt(anioFinalRef.current.value);
                const periodoInicialValue = parseInt(periodoInicialRef.current.value);
                const periodoFinalValue = parseInt(value);
                if(anioFinal > anioInicial) {
                    setError2(null);
                }

                if(anioFinal === anioInicial) {
                    if(periodoFinalValue < periodoInicialValue) {
                        setError2(<AlertForm message="El periodo final debe ser mayor o igual que el periodo inicial"/>);
                    } else {
                        setError2(null);
                    }
                }
            }
        }
    };

    const activaButton = idTipoAcumulado => {
        limpiaInformativo();
        if(!idTipoAcumulado) {
            setMuestraRecibo(false);
        }
    };

    const activaButton2 = idTipoAcumulado => {
        if(idTipoAcumulado) {
            if(
                (anio2Ref.current?.value !== ultimoAnio) ||
                (periodo2Ref.current?.value !== ultimoPeriodo)
            ) {
               setOpenAlertWAP(true);
            }
        }
    };

    const mostrarRecibo = e => {
        e.preventDefault();
        if(validarSubmit('formCargaPagosUnicos')) {
            setMuestraRecibo(true);
            obtieneAnios2();
        }
    };

    const abreAlerta = e => {
        e.preventDefault();
        
        if(validarSubmit('formCargaPagosUnicosConfirma')) {
            const anioInicial = parseInt(anioInicialRef.current.value);
            const anioFinal = parseInt(anioFinalRef.current.value);

            if(anioFinal < anioInicial) {
                setError2(<AlertForm message="El año inicial debe ser menor o igual que el año final" />);
            } else {
                if(anioFinal > anioInicial) {
                    setOpenAlertW(true);
                } else {
                    const periodoInicial = parseInt(periodoInicialRef.current.value);
                    const periodoFinal = parseInt(periodoFinalRef.current.value);

                    if(periodoFinal < periodoInicial) {
                        setError2(<AlertForm message="El periodo inicial debe ser menor o igual que el periodo final" />);
                    } else {
                        setOpenAlertW(true);
                    }
                }
            }
        }
    };

    const cerrarModalFn = () => {
        cerrarModal();
        setAgregarPagoUnico(false);
        setPagosUnicosVigentes(null);
        setNominas([]);
        setNomina(null);
        setAnios([]);
        setAnios2([]);
        setUltimoAnio('0');
        setPeriodos([]);
        setPeriodos2([]);
        setUltimoPeriodo('0');
        setPeriodosInicial([]);
        setPeriodosFinal([]);
        setTiposAcumulado([]);
        setIsLoading({ nominaLoading: false, anioLoading: false, periodoLoading: false, periodo2Loading: false, tipoAcumuladoLoading: false });
        setIsDisabled({ nominaDisabled: true, anioDisabled: true, anioInicialDisabled: true, periodoDisabled: true, periodo2Disabled: true, tipoAcumuladoDisabled: true });
        setPeriodoInicialLoading(false);
        setPeriodoInicialDisabled(true);
        setPeriodoFinalLoading(false);
        setPeriodoFinalDisabled(true);
        setError(null);
        setError2(null);
        setMuestraRecibo(false);
        setIndicadoresSelected([]);
        setPercepciones([]);
        setDeducciones([]);
        setTotalP(0);
        setTotalD(0);
        setAlertData({ title: '', text: '', type: '', action: false });
    };

    const envia = () => {
        setOpenLoading(true);
        const indicadoresP = percepciones.map(i => ( { indicadorId: i.nomindId, monto: parseFloat(i.monto) } ));
        const indicadoresD = deducciones.map(i => ( { indicadorId: i.nomindId, monto: parseFloat(i.monto) } ));
        const indicadores = indicadoresP.concat(indicadoresD);
        const request = {
            empleadoId: empleado.id,
            nominaId: nomina.id,
            tipoacumId: 14,
            anioAplica: parseInt(anio2Ref.current.value),
            periodoAplica: parseInt(periodo2Ref.current.value),
            anioInicio: parseInt(anioInicialRef.current.value),
            periodoInicio: parseInt(periodoInicialRef.current.value),
            anioFinal: parseInt(anioFinalRef.current.value),
            periodoFinal: parseInt(periodoFinalRef.current.value),
            indicadores
        };

        axios.post(`${servicios_grupohit_rh}/movimientos/pagosUnicos`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    empleado.nivLaboralClave = request.indicadores.length;
                    setAlertData({ title: 'Petición Exitosa', text: res.data.msg, type: 'success', action: () => cerrarModalFn() });
                    setOpenAlertSyE(true);
                    break;
                default:
                    setAlertData({ title: 'Petición Fallida', text: res.data.msg, type: 'error', action: false });
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setAlertData({ title: 'Petición Fallida', text: verifique_conexion, type: 'error', action: false });
            setOpenAlertSyE(true);
        })
        .finally(() => {
            setOpenLoading(false);
        });
    };

    const reiniciaAnio2Periodo2 = () => {
        anio2Ref.current.value = anios2[0].id;
        obtienePeriodos2(anios2[0].id);
    };

    return (
        <Fragment>
            <InfoEmpleado nombreEmpleado={empleado.nombreCompleto} claveEmpleado={empleado.empleadoClave}/>
            <InfoPagosUnicos
                permisos={permisos} empleado={empleado} mostrarFormPagoUnico={mostrarFormPagoUnico}
                agregarPagoUnico={agregarPagoUnico} setAgregarPagoUnico={setAgregarPagoUnico}
                pagosUnicosVigentes={pagosUnicosVigentes} setPagosUnicosVigentes={setPagosUnicosVigentes}
            />
            {(agregarPagoUnico) &&
            <Fragment>
                <form id="formCargaPagosUnicos" onSubmit={mostrarRecibo}>
                    <div className="row">
                        <div className="col">
                            {nominaLoading
                            ?   <div className="mt-4"><Spinner/></div>
                            :   <SelectValidation title="Nómina"
                                    arrayOpciones={(nominaDisabled) ? [] : nominas} keyOpcion="nombreNomina" refValue={nominaRef}
                                    defaultValue="" onChangeReturnObject={obtieneAnios}
                                    tooltipText="Seleccione una nómina" required
                                    disabled={nominaDisabled}
                                /> 
                            }
                        </div>
                        <div className="col">
                            {anioLoading
                            ?   <div className="mt-4"><Spinner/></div>
                            :   <SelectValidation title="Año"
                                    arrayOpciones={(anioDisabled) ? [] : anios} keyOpcion="anio" refValue={anioRef}
                                    defaultValue="" onChangeExecute={obtienePeriodos}
                                    tooltipText="Seleccione un año" required
                                    disabled={anioDisabled}
                                />
                            }
                        </div>
                        <div className="col">
                            {periodoLoading
                            ?   <div className="mt-4"><Spinner/></div>
                            :   <SelectValidation title="Periodo"
                                    arrayOpciones={(periodoDisabled) ? [] : periodos} keyOpcion="periodo" refValue={periodoRef}
                                    defaultValue="" onChangeExecute={obtieneTiposAcumulado}
                                    tooltipText="Seleccione un periodo" required
                                    disabled={periodoDisabled}
                                />
                            }
                        </div>
                        <div className="col">
                            {tipoAcumuladoLoading
                            ?   <div className="mt-4"><Spinner/></div>
                            :   <SelectValidation title="Tipo de Acumulado"
                                    arrayOpciones={(tipoAcumuladoDisabled) ? [] : tiposAcumulado} keyOpcion="tipoAcumulado" refValue={tipoAcumuladoRef}
                                    defaultValue="" onChangeExecute={activaButton}
                                    tooltipText="Seleccione un tipo de acumulado" required
                                    disabled={tipoAcumuladoDisabled}
                                />
                            }
                        </div>
                        <div className="col" style={{ marginTop: '2rem' }}>
                            <Button type="submit" variant="contained" className="btn-color">
                                Aceptar
                            </Button>
                        </div>
                    </div>
                    { error && <div className="row"><div className="col">{ error }</div></div> }
                </form>
                {muestraRecibo &&
                <Fragment>
                    <hr/>
                    <FormAddConceptos
                        nominaId={nominaRef.current.value}
                        indicadoresSelected={indicadoresSelected}
                        setIndicadoresSelected={setIndicadoresSelected}
                        totalPD={totalP - totalD}
                        cargaPagosUnicos
                    />
                    <hr/>
                    <FormularioReciboPago
                        elementSelect={{
                            ...empleado,
                            empleadoId: empleado.id,
                            periodoId: periodoRef.current?.value,
                            acumuladoId: tipoAcumuladoRef.current?.value
                        }}
                        pagosUnicos
                        indicadoresSelected={indicadoresSelected}
                        setIndicadoresSelected={setIndicadoresSelected}
                        setPercepcionesActivas={setPercepciones}
                        setDeduccionesActivas={setDeducciones}
                        setTotalP={setTotalP}
                        setTotalD={setTotalD}
                    />
                    <form id="formCargaPagosUnicosConfirma" onSubmit={abreAlerta}>
                        <div className="row">
                            <div className="col"><h6>Informativo</h6></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {anioInicialLoading
                                ?   <div className="mt-4"><Spinner/></div>
                                :   <SelectValidation title="Año Inicial"
                                        arrayOpciones={(anioInicialDisabled) ? [] : anios2} keyOpcion="anio" refValue={anioInicialRef}
                                        defaultValue="" onChangeExecute={obtienePeriodosInicial}
                                        tooltipText="Seleccione un año" required
                                        disabled={anioInicialDisabled}
                                    />
                                }
                            </div>
                            <div className="col">
                                {periodoInicialLoading
                                ?   <div className="mt-4"><Spinner/></div>
                                :   <SelectValidation title="Periodo Inicial"
                                        arrayOpciones={(periodoInicialDisabled) ? [] : periodosInicial} keyOpcion="periodo" refValue={periodoInicialRef}
                                        defaultValue="" onChangeExecute={validaPeriodoInicial}
                                        tooltipText="Seleccione un periodo" required
                                        disabled={periodoInicialDisabled}
                                    />
                                }
                            </div>
                            <div className="col">
                                {anioInicialLoading
                                ?   <div className="mt-4"><Spinner/></div>
                                :   <SelectValidation title="Año Final"
                                        arrayOpciones={(anioInicialDisabled) ? [] : anios2} keyOpcion="anio" refValue={anioFinalRef}
                                        defaultValue="" onChangeExecute={obtienePeriodosFinal}
                                        tooltipText="Seleccione un año" required
                                        disabled={anioInicialDisabled}
                                    />
                                }
                            </div>
                            <div className="col">
                                {periodoFinalLoading
                                ?   <div className="mt-4"><Spinner/></div>
                                :   <SelectValidation title="Periodo Final"
                                        arrayOpciones={(periodoFinalDisabled) ? [] : periodosFinal} keyOpcion="periodo" refValue={periodoFinalRef}
                                        defaultValue="" onChangeExecute={validaPeriodoFinal}
                                        tooltipText="Seleccione un periodo" required
                                        disabled={periodoFinalDisabled}
                                    />
                                }
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col"><h6>Periodo a Aplicar</h6></div>
                        </div>
                        <div className="row">
                            <div className="col col-lg-5 col-md-5 col-sm-4">
                                {anioInicialLoading
                                ?   <div className="mt-4"><Spinner/></div>
                                :   <SelectValidation title="Año"
                                        arrayOpciones={(anioInicialDisabled) ? [] : anios2} keyOpcion="anio" refValue={anio2Ref}
                                        defaultValue="" onChangeExecute={obtienePeriodos2}
                                        tooltipText="Seleccione un año" required
                                        disabled={anioInicialDisabled}
                                    />
                                }
                            </div>
                            <div className="col col-lg-5 col-md-5 col-sm-4">
                                {periodo2Loading
                                ?   <div className="mt-4"><Spinner/></div>
                                :   <SelectValidation title="Periodo"
                                        arrayOpciones={(periodo2Disabled) ? [] : periodos2} keyOpcion="periodo" refValue={periodo2Ref}
                                        defaultValue="" onChangeExecute={activaButton2}
                                        tooltipText="Seleccione un periodo" required
                                        disabled={periodo2Disabled}
                                    />
                                }
                            </div>
                            <div className="col col-lg-2 col-md-2 col-sm-4" style={{ marginTop: '2rem' }}>
                                <Button
                                    type="submit" variant="contained" className="btn-color"
                                    disabled={anioInicialLoading || periodo2Loading || periodoInicialLoading || periodoFinalLoading}
                                >
                                    Aplicar
                                </Button>
                            </div>
                        </div>
                        { (error2) && <div className="row"><div className="col">{ error2 }</div></div> }
                    </form>
                    <AlertWarning
                        show={openAlertW} SetopenALert={setOpenAlertW}
                        text={
                            <p>
                                La Carga de Pago Único se aplicará para:<br/>
                                <strong className="negritas">Empleado: </strong>{ empleado.nombreCompleto }<br/>
                                <strong className="negritas">Nómina: </strong>{ nomina.nombreNomina }<br/>
                                <strong className="negritas">Año: </strong>{ anio2Ref.current?.value }<br/>
                                <strong className="negritas">Periodo: </strong>{ periodo2Ref.current?.value }
                            </p>
                        }
                        textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={envia}
                    />
                    <AlertWarning
                        show={openAlertWAP} SetopenALert={setOpenAlertWAP}
                        text="El Pago Único se acumulará a un periodo que ya fue cerrado. ¿Desea continuar?"
                        textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" actionCancel={reiniciaAnio2Periodo2}
                    />
                    <ModalLoading nombreModulo={nombreModulo} open={openLoading} setOpen={setOpenLoading} />
                    <AlertSyE Alert show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title={alertData.title} text={alertData.text} textbtnconfirm="Aceptar" type={alertData.type} action={alertData.action}/>
                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    );
}
 
export default FormModalPagosUnicos;