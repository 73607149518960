import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { headersAuth, obtenerUsuario } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_mensajes } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import { eliminarEtiquetas, mostrarFecha } from '../../HelperMensajes';
import Avatar from '@material-ui/core/Avatar';
//import Checkbox from '@material-ui/core/Checkbox';

import AccionesSalida from './AccionesSalida';
import MostrarMensaje from '../MostrarMensaje/MostrarMensaje';
import SeleccionaElemento from '../SeleccionaElemento';
import SinElementos from '../SinElementos';

import EnviarMensaje from '../EnviarMensaje/EnviarMensaje';

const BandejaSalida = ({ usuarios, setPanelDerecho, socket }) => {
    const { usuario } = obtenerUsuario();
    const [ seleccionado, setSeleccionado ] = useState( '' );
    /* ----------------------------- Axios para obtener mensajes Bandeja de Salida ---------------------------- */
    const [ mensajesSalida, setMensajesSalida ] = useState( null );
    useEffect(() => {
        axiosMensajesSalida();
    }, []);

    const axiosMensajesSalida = () => {
        const { usuario } = obtenerUsuario();
        axios.get( `${servicios_grupohit_mensajes}/salida/${usuario}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setMensajesSalida( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setMensajesSalida([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* --------------------------- Funciones para actualizar estado (mensajesSalida) -------------------------- */
    const reenviarMensaje = ( mensaje ) => {
        setPanelDerecho( <EnviarMensaje usuarios={usuarios} setPanelDerecho={setPanelDerecho} reenviar={mensaje} socket={socket}/> );
    }

    const eliminarSalida  = ( mensajeID ) => {
        setMensajesSalida( mensajesSalida.filter( element => element.id !== mensajeID ) );
        setPanelDerecho( <SeleccionaElemento /> );
    }

    const mostrarMensaje = ( mensaje ) => {
        const acciones = <AccionesSalida mensaje={mensaje} usuario={usuario} eliminarSalida={eliminarSalida} reenviarMensaje={reenviarMensaje}/>;
        setPanelDerecho( <MostrarMensaje mensaje={mensaje} acciones={acciones}/> );
        setSeleccionado( mensaje.id );
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            { mensajesSalida ?
                mensajesSalida.length > 0 ?
                    mensajesSalida.map( mensaje => (
                        <div key={mensaje.id} className={ `mensajeSalida ${ seleccionado === mensaje.id && 'seleccionado' }` }
                            onClick={ () => mostrarMensaje( mensaje ) }>
                            <div className="mensajesFlex">
                                <Avatar src={`data:image/jpeg;base64, ${mensaje.foto}`} className="mensajesAvatar"/>
                                <div className="divAvatarFrom">
                                    <div className="toLeido"> {mensaje.toNombre} </div>
                                </div>
                                <div className="divFecha">
                                    <div className="mensajesFecha"> { mostrarFecha(mensaje.fechaHora) } </div>
                                </div>
                            </div>
                            <div className="mensajesFlex">
                                <div className="marginAsunto"></div>
                                <div className="mensajesAsunto asuntoLeido"> {mensaje.asunto} </div>
                            </div>
                            <div className="mensajesFlex">
                                {/* <Checkbox className="mensajesCheckBox"/> */}
                                <div className="marginAsunto"></div>
                                <div className="mensajesMensaje"> {eliminarEtiquetas(mensaje.mensaje)} </div>
                            </div>
                        </div>
                    ))
                    : <SinElementos />
                : <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default BandejaSalida;