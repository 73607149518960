import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { servicios_grupohit_catalogo, servicios_grupohit_rh, registro_actualizado, registro_guardado, registro_no_guardado,
            registro_no_eliminado, registro_eliminado, servicios_grupohit_org, registro_sera_actualizado, verifique_conexion } from '../../../../../../Constants/Constants';
import { validarSubmit, longDateToInput, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import ModalOrganizacionEmpleadoEdit from './OrganizacionEmpleado/ModalOrganizacionEmpleadoEdit';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../Services/AlertForm';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import Sueldo from './OrganizacionEmpleado/Sueldo';
import RegistroPatronal from './OrganizacionEmpleado/RegistroPatronal';
import Sindicato from './OrganizacionEmpleado/Sindicato';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';
import NotasAdicionales from './OrganizacionEmpleado/NotasAdicionales/NotasAdicionales';
import Prestamos from './OrganizacionEmpleado/Prestamos';
import SubsidioEmpleo from './OrganizacionEmpleado/SubsidioEmpleo';
import TitularPlaza from './OrganizacionEmpleado/TitularPlaza';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import CheckPlazaRangoFechas from './OrganizacionEmpleado/CheckPlazaRangoFechas';
import CheckRetencionPagos from './OrganizacionEmpleado/CheckRetencionPagos';

const FormOrganizacionEmpleado = (props) => {

    const {
            modulo, successTable, errorTable, irInfo, infoEmpleado, actualizar, enviar, elementSelect, selected,
            monto, montoEdit, setMonto, setMontoEdit, indicadores, enviarEmpleado, disabled, disabledFormOrgEdit,
            setDisabledFormOrgEdit, idTurno, infoContrato, fechaAguinaldo, fechaNombramiento,
            fechaInicialPlazaDispRangoRef, fechaFinalPlazaDispRangoRef, plazaDisponibleRango, setPlazaDisponilbeRango,
            plazasRango, setPlazasRango, plazas, validarPlaza, titularPlaza, setTitularPlaza, infoTitularPlaza,
            validaAutocompleteOrganizacion, zonasPago, zonaPagoRef, turnos, turnoRef, puestos,
            sugerenciaPuesto, validarPuesto, muestraConceptos, nominas, nominaRef,
            formaPagoRef, formasPagoSelected, setFormasPagoSelected, setIndicadoresSelected, indicadoresSelected,
            bancoRef, numCuentaRef, cbeInterRef, contratoRef, retienePagos, setRetienePagos,
            fechaInicialContratoRef, fechaFinalContratoRef, fechaNombramientoRef, fechaAguinaldoRef, fechaIngresoRef,
            regPatronalRef, infoRegPatronal,
            sindicatoRef, sindicalizado, setSindicalizado, cuotaSindical, setCuotaSindical,
            sindicatoSeleccionado, setSindicatoSeleccionado, infoSindicato, minLiquidez, setMinLiquidez,
            aplicarSubsidioEmpleo, setAplicarSubsidioEmpleo, infoMinLiquidez, notaRef, notas, setNotas,
            errorFormPago, setErrorFormPago, errorConceptoPago, setErrorConceptoPago,
            errorPlaza, errorPuesto, isLoading, setIsLoading,
            indicadoresSueldoNombramientoActivo, setIndicadoresSueldoNombramientoActivo,
            indicadoresSueldoActivo, setIndicadoresSueldoActivo,
            indicadoresNombramientoActivo, setIndicadoresNombramientoActivo,
            nombramientoEditInicial, setNombramientoEditInicial, setError,
            nominasRL
    } = props;

    const { puesto: disabledPuesto, plaza: disabledPlaza, turno: disabledTurno, nomina: disabledNomina, zonaPago: disabledZonaPago } = disabledFormOrgEdit;
    
    const [muestraFormDatosBancarios, setMuestraFormDatosBancarios] = useState(false);
    const [editarElementoFormaPago, setEditarElementoFormaPago] = useState(null);
    const [consultaCuentahabiente, setConsultaCuentahabiente] = useState(false);
    const [infoClavePresupuestal, setInfoClavePresupuestal] = useState(null);
    const [enabledFechaContrato, setEnabledFechaContrato] = useState(false);
    const [openAlertWFFContrato, setOpenAlertWFFContrato] = useState(false);
    const [tarjetaCLABERequired, setTarjetaCLABERequired] = useState(true);
    const [openAlertWContrato, setOpenAlertWContrato] = useState(false);
    const [openAlertWEditOrg, setOpenAlertWEditOrg] = useState(false);
    const [numCuentaRequired, setNumCuentaRequired] = useState(true);
    const [mostrarContenido, setMostrarContenido] = useState(false);
    const [muestraFormaPago, setMuestraFormaPago] = useState(true);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [textAlertError, setTextAlertError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [plazaActual, setPlazaActual] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [formasPago, setFormasPago] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [idNomina, setIdNomina] = useState('');
    const [bancos, setBancos] = useState([]);
    const [errorFechasRango, setErrorFechasRango] = useState(null);
    const [isLoadingPlazasRango, setIsLoadingPlazasRango] = useState(false);

    const rlSeleccionada = JSON.parse(localStorage.getItem('IGRelLab'));

    useEffect(() => {
        //asigna turno en nuevo empleado y reingreso
        if(mostrarContenido && (turnos.length > 0) && ((!elementSelect && (modulo === 30)) || (modulo === 32))) {
            turnoRef.current.value = turnos[0].id;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turnos, mostrarContenido]);

    useEffect(() => {
        setIndicadoresSueldoNombramientoActivo(indicadores.filter(indicador => indicador.indicadorid.sueldo === true & indicador.indicadorid.nombramiento === true));
        setIndicadoresSueldoActivo(indicadores.filter(indicador => indicador.indicadorid.sueldo === true));
        setIndicadoresNombramientoActivo(indicadores.filter(indicador => indicador.indicadorid.nombramiento === true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indicadores]);

    useState(() => {
        setNumCuentaRequired(true);
        setTarjetaCLABERequired(true);
    }, [muestraFormDatosBancarios, editarElementoFormaPago]);

    useState(() => {
        if(elementSelect && infoContrato && (modulo === 30)) {
            if(infoContrato.fechafin !== null) setEnabledFechaContrato(true);
        }
    }, [infoContrato]);

    useEffect(() => {
        if((isLoading === false) && elementSelect && (modulo === 30)) {
            setTimeout(() => {
                setDisabledFormOrgEdit({ plaza: true, zonaPago: true, puesto: true, turno: true, nomina: true });
            }, 2000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const guardar = e => {
        e.preventDefault();
        validarSubmit('OrganizacionEmpleado')
        validaAutocompleteOrganizacion();
        if(formaPagoRef.current.value) {
            setOpenAlert(true);
        } else {
            if(indicadoresSelected.length > 0) {
                enviar();
            } else {
                setErrorConceptoPago(<AlertForm message="Debe seleccionar, al menos, un concepto"/>);
            }
        }
    };

    const abrirModal = () => {
        if(validarSubmit('OrganizacionEmpleado')) {
            if(
                (selected.plaza.id !== '' && disabledPlaza === false) ||
                (selected.puesto.id !== '' && disabledPuesto === false) ||
                (disabledTurno === false) ||
                (disabledNomina === false)
            ) {
                setModalIsOpen(true);
            } else {
                if(
                    disabledPlaza === true && disabledZonaPago === true &&
                    disabledPuesto === true && disabledTurno === true &&
                    disabledNomina === true
                ) {
                    if(modulo === 30 || !elementSelect) {
                        if(
                            (infoContrato.contrato.id !== parseInt(contratoRef.current.value)) ||
                            (infoContrato.fechaini !== inputDateToLong(fechaInicialContratoRef.current.value)) ||
                            (infoContrato.fechafin !== ((fechaFinalContratoRef.current) ? (fechaFinalContratoRef.current.value !== '') ? inputDateToLong(fechaFinalContratoRef.current.value) : null : null)) ||
                            (fechaAguinaldo.fechaAguinaldo !== (fechaAguinaldoRef.current ? (fechaAguinaldoRef.current.value === '' ? '' : inputDateToLong(fechaAguinaldoRef.current.value)) : '')) ||
                            (((!verificaFN() && (rlSeleccionada.clave === '501')) ? '' : fechaNombramiento.fechaNombramiento) !== (fechaNombramientoRef.current ? (fechaNombramientoRef.current.value === '' ? '' : inputDateToLong(fechaNombramientoRef.current.value)) : '')) ||
                            (infoRegPatronal.regPatronal.id !== (regPatronalRef.current ? (regPatronalRef.current.value === '' ? '' : parseInt(regPatronalRef.current.value)) : '')) ||
                            (infoSindicato.sindicato.id !== sindicatoSeleccionado.id) ||
                            (infoSindicato.aplica !== cuotaSindical) ||
                            (infoMinLiquidez !== minLiquidez) || 
                            (((infoTitularPlaza === 'no') ? 'no' : 'si') !== titularPlaza) 
                        ) {
                            setError(null);
                            setOpenAlertWEditOrg(true);
                        } else {
                            setError(<AlertForm message="No se han realizado cambios en el formulario"/>)
                        }
                        return;
                    }
                    if(elementSelect && (modulo === 32)) {
                        setModalIsOpen(true);
                        return;
                    }
                } else {
                    validaAutocompleteOrganizacion();
                }
            }
        } else {
            validaAutocompleteOrganizacion();
        }
    };

    const editar = e => {
        e.preventDefault();
        validarSubmit('OrganizacionEmpleado')
        validaAutocompleteOrganizacion();
        if(formaPagoRef.current.value !== '') {
            setOpenAlert(true);
        } else {
            if(indicadoresSelected.length > 0) {
                abrirModal();
            } else {
                setErrorConceptoPago(<AlertForm message="Debe seleccionar, al menos, un concepto"/>);
            }
        }
    };
    
    const muestraEditarFPago = elemento => {
        obtenerBancos();
        setTimeout(() => {
            if(elemento === editarElementoFormaPago) {
                formaPagoRef.current.value = '';
                setEditarElementoFormaPago(null);
            } else {
                switch(elemento.formapago) {
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        formaPagoRef.current.value = elemento.formapago;
                        setEditarElementoFormaPago(elemento);
                    break;
                    default:
                        formaPagoRef.current.value = '';
                        setEditarElementoFormaPago(null);
                    break;
                }
            }
        }, 500);
    };

    const asignaContrato = value => {
        const contrato = (value) ? value : contratos.find(c => c.id === parseInt(contratoRef.current.value));
        if(contrato.contratoSaoFechas && infoContrato && fechaFinalContratoRef.current) fechaFinalContratoRef.current.value = longDateToInput(infoContrato.fechafin);
        else if(fechaFinalContratoRef.current) limpiaFechaFinalContrato();
        setEnabledFechaContrato(contrato.contratoSaoFechas);
    };

    const validaContrato = idContrato => {
        if(idContrato !== '') {
            const contrato = contratos.find(c => c.id === parseInt(idContrato));

            if(contrato.contratoSaoFechas === true) {
                const existenNombramientoSeleccionados = indicadoresNombramientoActivo.find(i => indicadoresSelected.indexOf(i.indicadorid.indicadorId) > -1);
                if(existenNombramientoSeleccionados) {
                    setTextAlertError('Para otorgar un contrato temporal, es necesario asignar un puesto que tenga prendida la variable "Causa Nombramiento" previamente.');
                    setOpenAlertError(true);
                    contratoRef.current.value = '';
                    return;
                }
                asignaContrato(contrato);
                return;
            }
            
            if(contrato.contratoSaoFechas === false) {
                const existenNombramientoSeleccionados = indicadoresNombramientoActivo.find(i => indicadoresSelected.indexOf(i.indicadorid.indicadorId) > -1);
                if(existenNombramientoSeleccionados) {
                    asignaContrato(contrato);
                } else {
                    if(selected.puesto.nombramiento === true) {
                        asignaContrato(contrato);
                        return;
                    }
                    if(rlSeleccionada.clave === '501') {
                        setOpenAlertWContrato(true)
                    } else {
                        setTextAlertError('Para otorgar un contrato permanente, es necesario asignar un concepto que tenga prendida la variable "Causa Nombramiento" previamente.');
                        setOpenAlertError(true);
                        contratoRef.current.value = '';
                    }
                }
                return;
            }
        } else {
            if(fechaFinalContratoRef.current) limpiaFechaFinalContrato();
            setEnabledFechaContrato(false);
        }
    };

    const eliminaFormaPago = elemento => {
        if(formasPagoSelected.length < 1) {
            errorTable("El empleado debe tener, al menos, una Forma de Pago asignada");
        } else {
            axios.delete(`${servicios_grupohit_rh}/empleado/formapago/${elemento.idFormaPago}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const formasPago = formasPagoSelected.filter(fpago => fpago.idFormaPago !== elemento.idFormaPago);
                        setFormasPagoSelected(formasPago);
                        successTable(registro_eliminado);
                        setEditarElementoFormaPago(null);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(registro_no_eliminado));
        }
    };

    const editarFormaPago = e => {
        e.preventDefault();
        
        if(validarSubmit('formasPago')) {
            setIsLoading(true);
            const id = parseInt(editarElementoFormaPago.idCuentahabiente)
            let request = {
                banco: parseInt(bancoRef.current.value)
            };

            if(cbeInterRef.current.value !== '') request.clabe = cbeInterRef.current.value;
            if(numCuentaRef.current.value !== '') request.nocuenta = numCuentaRef.current.value;

            axios.put(`${servicios_grupohit_rh}/empleado/cuentahabiente/${id}`, request, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        if(formaPagoRef.current) formaPagoRef.current.value = '';
                        const formasPago = formasPagoSelected.map(fpago => (
                            (fpago.idCuentahabiente === res.data.dataResponse.id)
                            ?   {   
                                    idCuentahabiente: res.data.dataResponse.id,
                                    idFormaPago: res.data.dataResponse.empleadoFormaPago.id,
                                    formapago: res.data.dataResponse.empleadoFormaPago.formapago.id,
                                    formaPagoNom: res.data.dataResponse.empleadoFormaPago.formapago.formapagoNom,
                                    bancoId: res.data.dataResponse.banco.id,
                                    banco: res.data.dataResponse.banco.bancoNom,
                                    cbeInterbancaria: res.data.dataResponse.clabe,
                                    numCuenta: res.data.dataResponse.nocuenta
                                }
                            :   fpago
                        ));
                        setFormasPagoSelected(formasPago);
                        setEditarElementoFormaPago(null);
                        successTable(registro_actualizado);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) })
            .finally(() => setIsLoading(false));
        }
    };

    const obtenerBancos = () => {
        axios.get(`${servicios_grupohit_catalogo}/banco/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setBancos(res.data.dataResponse);
                    break;
                case 404:
                    setBancos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    };

    useEffect(() => {
        if(elementSelect && (modulo === 30)) {
            obtienePuesto(elementSelect);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puestos]);

    const obtienePuesto = (value) => {
        try{
            if(value) {
                const puesto = puestos.find(puesto => puesto.puestoCve === value.puestoCve);
                validarPuesto(puesto);
            } else {
                validarPuesto(null);
            }
        } catch(e) {

        }
    };

    const enablePlaza = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, plaza: value, zonaPago: value });
        if(value === false) {
            validarPlaza();
        }
    };

    const enabledTurno = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, turno: value });
    };

    const enableNomina = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, nomina: value });
    };

    const enablePuesto = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, puesto: value });
        if(value === false) {
            validarPuesto(null);
        } else {
            obtienePuesto(elementSelect);
        }
    };

    const enableZonaPago = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, plaza: value, zonaPago: value });
        if(value === false) {
            validarPlaza();
        }
    };

    useEffect(() => {
        axios.get(`${servicios_grupohit_catalogo}s/tipoformapago/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setFormasPago(res.data.dataResponse);
                    if(!elementSelect) {
                        const pagoEfectivo = res.data.dataResponse.find(fPago => fPago.id === 1);
                        const efectivo = {
                            formapago: parseInt(pagoEfectivo.id),
                            formaPagoNom: pagoEfectivo.formapagoNom
                        }
                        setFormasPagoSelected([...formasPagoSelected, efectivo]);
                    }
                    break;
                case 404:
                    setFormasPago([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion) });

        axios.get(`${servicios_grupohit_catalogo}s/contrato/sao/relab/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setContratos(res.data.dataResponse);
                    break;
                case 404:
                    setContratos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion) });

        if(elementSelect) {
            axios.get(`${servicios_grupohit_rh}/empleado/formapago/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const formasPago = res.data.dataResponse.filter(fPago => 
                            (fPago.formapago.id === 1 || fPago.formapago.id === 2 || fPago.formapago.id === 10)
                        );
                        if(formasPago.length > 0) {
                            const array = formasPago.map(fPago => ( { idFormaPago: fPago.id, formapago: fPago.formapago.id, formaPagoNom: fPago.formapago.formapagoNom } ));
                            setFormasPagoSelected(array);
                        }
                        setConsultaCuentahabiente(true);
                        break;
                    case 404:
                        setConsultaCuentahabiente(true);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(modulo === 30 && infoEmpleado) {
            axios.get(`${servicios_grupohit_org}/plaza/clave/${infoEmpleado.plaza.plazaid.plazaclave}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPlazaActual(res.data.dataResponse);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => {
                errorTable(verifique_conexion);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoEmpleado]);

    useEffect(() => {
        //actualiza los datos de la plaza cuando inicia el modal de editar
        if(elementSelect && modulo === 30 && plazaActual !== null && disabledPlaza === true) {
            validarPlaza(plazaActual);
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect, modulo, plazaActual, disabledPlaza]);

    useEffect(() => {
        if(elementSelect && consultaCuentahabiente) {
            axios.get(`${servicios_grupohit_rh}/empleado/cuentahabiente/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const formasPago = res.data.dataResponse.map(fPago => (
                            {   
                                idFormaPago: fPago.empleadoFormaPago.id, 
                                idCuentahabiente: fPago.id,
                                formapago: parseInt(fPago.formaPago),
                                formaPagoNom: fPago.empleadoFormaPago.formapago.formapagoNom,
                                bancoId: parseInt(fPago.banco.id),
                                banco: fPago.banco.bancoNom,
                                numCuenta: fPago.nocuenta,
                                cbeInterbancaria: fPago.clabe
                            }
                        ));
                            
                        if(formasPago.length > 0) {
                            const array = formasPagoSelected.concat(formasPago);
                            setFormasPagoSelected(array);
                        }
                        break;
                    case 404:
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });
            setConsultaCuentahabiente(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consultaCuentahabiente]);

    useEffect(() => {
        if(infoEmpleado && (modulo === 30)) {
            if(nominas.length > 0) {
                const nomina = nominas.find(nomina => nomina.nominaClave === infoEmpleado.nomina.nomina.nominaClave);
                if(nomina) setIdNomina(nomina.id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoEmpleado, nominas]);
    
    useEffect(() => {
        setInfoClavePresupuestal(
            <Fragment>
                <br/>
                <div><label><strong>Unidad Responsable / Dependencia: </strong>{ selected.uResponsable.nombre }</label></div>
                <div><label><strong>Unidad Ejecutora: </strong>{ selected.uEjecutora.nombre }</label></div>
                <div><label><strong>Programa: </strong>{ selected.programa.nombre }</label></div>
                <div><label><strong>Subrograma: </strong>{ selected.subprograma.nombre }</label></div>
                <div><label><strong>Proyecto Acción: </strong>{ selected.pAccion.nombre }</label></div>
                <div><label><strong>Obra Acción: </strong>{ selected.oAccion.nombre }</label></div>
                <div><label><strong>Clave Presupuestal: </strong>{ selected.cPresupuestal.clave }</label></div>
                <div><label><strong>Proyecto: </strong>{ selected.cPresupuestal.nombre }</label></div>
                <div><label><strong>Departamento: </strong>{ selected.departamento.nombre }</label></div>
                <div><label><strong>Titular de la Plaza: </strong>{ selected.plaza.titular }</label></div>
                <br/>
            </Fragment>
        );
    }, [selected]); 
    
    const verificarNumCuenta = value => {
        if(value === '') {
            setTarjetaCLABERequired(true);
        } else {
            setTarjetaCLABERequired(false);
        }
    };

    const verificarTarjetaCLABE = value => {
        if(value === '') {
            setNumCuentaRequired(true);
        } else {
            setNumCuentaRequired(false);
        }
    };

    const datosTransferencia =  <Fragment>
                                    <SelectValidation title="Banco" arrayOpciones={bancos} keyOpcion="bancoNom"
                                        refValue={bancoRef} defaultValue={editarElementoFormaPago ? editarElementoFormaPago.bancoId : ''} required/>
                                    <div className="formRelLab">
                                        <div className="form-left">
                                            <InputValidation title="Número de Cuenta" type="numCuenta" placeholder="Número de Cuenta" maxLength={11}
                                                tooltipText="10 u 11 números" tooltipName="tooltipNumCuenta" onlyNumbers
                                                refValue={numCuentaRef} defaultValue={editarElementoFormaPago ? editarElementoFormaPago.numCuenta : ''}
                                                action={verificarNumCuenta} optional={!numCuentaRequired} required={numCuentaRequired}
                                            />
                                        </div>
                                        <div className="form-right">
                                            <InputValidation title="Número de Tarjeta o CLABE Interbancaria" type="cbeInterbancaria-numTarjeta" placeholder="Número de Tarjeta o CLABE Interbancaria" maxLength={18}
                                                tooltipText="16 números para Número de Tarjeta o 18 números para CLABE Interbancaria" onlyNumbers
                                                refValue={cbeInterRef} defaultValue={editarElementoFormaPago ? editarElementoFormaPago.cbeInterbancaria : ''}
                                                action={verificarTarjetaCLABE} optional={!tarjetaCLABERequired} required={tarjetaCLABERequired}
                                            />
                                        </div>
                                    </div>
                                </Fragment>;
    
    const verificaCheck = e => {
        setErrorFormPago({ active: false, message: '' });
        switch(e) {
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if(bancos.length === 0) obtenerBancos();
                setMuestraFormDatosBancarios(true);
            break;
            default:
                setMuestraFormDatosBancarios(false);
            break;
        }
    };

    const guardarFormaPago = (e) => {
        e.preventDefault();
        if(validarSubmit('formasPago')) {
            const formaPago = formaPagoRef.current.value;
            const nomFormaPago = formasPago.find(forma => forma.id === parseInt(formaPago));
            let findFormaPago = {};
            if(nomFormaPago) findFormaPago = formasPagoSelected.find(forma => forma.formaPagoNom === nomFormaPago.formapagoNom);
            if(!findFormaPago) {
                if(elementSelect) {
                    setIsLoading(true);
                    const nuevaFormaPago = {
                        empleado: elementSelect.id,
                        formapago: parseInt(formaPago)
                    };

                    axios.post(`${servicios_grupohit_rh}/empleado/formapago/`, [nuevaFormaPago], headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(res.data.dataResponse[0].formapago.id < 10 && res.data.dataResponse[0].formapago.id > 2) {
                                    const request = {
                                        empleadoFormaPago: res.data.dataResponse[0].id,
                                        formaPago: parseInt(formaPago),
                                        banco: parseInt(bancoRef.current.value)
                                    };

                                    if(cbeInterRef.current.value !== '') request.clabe = cbeInterRef.current.value;
                                    if(numCuentaRef.current.value !== '') request.nocuenta = numCuentaRef.current.value;
                                    
                                    axios.post(`${servicios_grupohit_rh}/empleado/cuentahabiente/`, request, headersAuth())
                                    .then(res2 => {
                                        switch(res2.data.status) {
                                            case 200:
                                                setFormasPagoSelected([ ...formasPagoSelected, 
                                                    { 
                                                        idCuentahabiente: res2.data.dataResponse.id,
                                                        idFormaPago: res2.data.dataResponse.empleadoFormaPago.id,
                                                        formapago: res2.data.dataResponse.empleadoFormaPago.formapago.id,
                                                        formaPagoNom: res2.data.dataResponse.empleadoFormaPago.formapago.formapagoNom,
                                                        banco: res2.data.dataResponse.banco.bancoNom,
                                                        bancoId: res2.data.dataResponse.banco.id,
                                                        numCuenta: res2.data.dataResponse.nocuenta,
                                                        cbeInterbancaria: res2.data.dataResponse.clabe
                                                    }
                                                ]);
                                                setMuestraFormDatosBancarios(false);
                                                setErrorFormPago({ active: false, message: '' });
                                                successTable(registro_guardado);
                                                formaPagoRef.current.value = '';
                                            break;
                                            default:
                                                errorTable(`Cuenta, ${res2.data.msg}`);
                                            break;
                                        }
                                        return;
                                    })
                                    .catch(error => errorTable(`Cuenta, ${registro_no_guardado}`));
                                } else {
                                    formaPagoRef.current.value = '';
                                    setFormasPagoSelected([ ...formasPagoSelected,
                                        {
                                            idFormaPago: res.data.dataResponse[0].id,
                                            formapago: res.data.dataResponse[0].formapago.id,
                                            formaPagoNom: res.data.dataResponse[0].formapago.formapagoNom
                                        }
                                    ]);
                                    setErrorFormPago({ active: false, message: '' });
                                    successTable(registro_guardado);
                                }
                            break;
                            default:
                                errorTable(`Forma de Pago, ${res.data.msg}`);
                            break;
                        }
                    })
                    .catch(error => { errorTable(verifique_conexion) })
                    .finally(() => setIsLoading(false));
                
                } else {
                    if(numCuentaRef.current || cbeInterRef.current) {
                        const banco = bancos.find(banco => banco.id === parseInt(bancoRef.current.value));
                        setFormasPagoSelected([ ...formasPagoSelected, 
                                                { 
                                                    formapago: parseInt(formaPago),
                                                    formaPagoNom: nomFormaPago.formapagoNom,
                                                    banco: banco.bancoNom,
                                                    bancoId: parseInt(bancoRef.current.value),
                                                    numCuenta: numCuentaRef.current.value,
                                                    cbeInterbancaria: cbeInterRef.current.value
                                                }
                                            ]);
                    } else {
                        setFormasPagoSelected([ ...formasPagoSelected,
                                                    {
                                                        formapago: parseInt(formaPago),
                                                        formaPagoNom: nomFormaPago.formapagoNom
                                                    }
                                            ]);
                    }
        
                    setMuestraFormDatosBancarios(false);
                    setErrorFormPago({ active: false, message: '' });
                }
            } else {
                if(!nomFormaPago) {
                    setErrorFormPago({ active: true, message: 'Seleccione una Forma de Pago' });
                } else {
                    setErrorFormPago({ active: true, message: 'Forma de pago ya registrada' });
                }
            }
            setMuestraFormaPago(false);
            setTimeout(() => {
                setMuestraFormaPago(true);
            }, [100]);
        }
    };

    const verificaFN = () => {
        const existenNombramientoSeleccionados = indicadoresNombramientoActivo.find(i => indicadoresSelected.indexOf(i.indicadorid.indicadorId) > -1);
        if(existenNombramientoSeleccionados) {
            return true;
        }
        if(!existenNombramientoSeleccionados) {
            return false;
        }
    };

    const validaContratoInicial = value => {
        if(value !== '') {
            if(fechaAguinaldoRef.current.value !== '') {
                if(inputDateToLong(fechaAguinaldoRef.current.value) > inputDateToLong(value)) {
                    fechaAguinaldoRef.current.value = '';
                }
            }

            if(inputDateToLong(fechaIngresoRef.current.value) > inputDateToLong(value)) {
                setTextAlertError('La fecha de contrato debe ser mayor o igual que la fecha de ingreso a gobierno.');
                setOpenAlertError(true);
                fechaInicialContratoRef.current.value = '';
                return;
            }

            if(fechaFinalContratoRef.current && fechaFinalContratoRef.current.value !== '') {
                if(inputDateToLong(value) > inputDateToLong(fechaFinalContratoRef.current.value)) {
                    setTextAlertError('Las fecha inicial de contrato debe ser menor o igual que la fecha de final de contrato.');
                    setOpenAlertError(true);
                    fechaInicialContratoRef.current.value = '';
                }
                return;
            }
        }
    };

    const limpiaFechaFinalContrato = () => {
        if(fechaFinalContratoRef.current) {
            fechaFinalContratoRef.current.value = '';
        }
    };

    const limpiarContrato = () => {
        if(contratoRef.current) {
            contratoRef.current.value = '';
            if(fechaFinalContratoRef.current) limpiaFechaFinalContrato();
            setEnabledFechaContrato(false);
        }
    };

    const validaNominaFFContrato = nominaSelected => {
        if(fechaFinalContratoRef.current) {
            if((nominaSelected !== '') && (fechaFinalContratoRef.current.value !== '')) {
                const nominaPeriodoActual = nominasRL.find(n => 
                    String(n.nomina) === nominaSelected
                );
                if(nominaPeriodoActual) {
                    if(nominaPeriodoActual.periodoFecini > inputDateToLong(fechaFinalContratoRef.current.value)) {
                        setOpenAlertWFFContrato(true);
                    }
                } else {
                    nominaRef.current.value = '';
                }
            }
        }
    };

    const validaContratoFinal = value => {
        if(value !== '') {
            const nominaSelected = nominaRef.current.value;
            if(nominaSelected !== '') {
                if(inputDateToLong(fechaIngresoRef.current.value) > inputDateToLong(value)) {
                    setTextAlertError('La fecha de contrato debe ser mayor o igual que la fecha de ingreso a gobierno.');
                    setOpenAlertError(true);
                    limpiaFechaFinalContrato();
                }
    
                if(fechaInicialContratoRef.current.value !== '') {
                    if(inputDateToLong(fechaInicialContratoRef.current.value) > inputDateToLong(value)) {
                        setTextAlertError('La fecha final de contrato debe ser mayor o igual que la fecha de inicial de contrato.');
                        setOpenAlertError(true);
                        limpiaFechaFinalContrato();
                    }
                    return;
                }

                const nominaPeriodoActual = nominasRL.find(n => 
                    (n.nombreNomina === nominaSelected) || (String(n.nomina) === nominaSelected)
                );
                if(nominaPeriodoActual.periodoFecini > inputDateToLong(value)) {
                    setOpenAlertWFFContrato(true);
                    return;
                }
                validaNominaFFContrato(nominaSelected);
            } else {
                setTextAlertError('Debe seleccionar una nómina.');
                setOpenAlertError(true);
                limpiaFechaFinalContrato();
                return;
            }
        }
    };

    const validacionAguinaldoNombramiento = (fecha, tipo) => {
        const value = inputDateToLong(fecha)
        let fechaIngresoGob = '';
        let fechaInicioContrato = '';
        const textErrorG = 'Debe capturar fecha de ingreso a gobierno';
        const textErrorC = 'Debe capturar fecha inicial de contrato';
        try {
            fechaIngresoGob = (fechaIngresoRef.current.value) ? inputDateToLong(fechaIngresoRef.current.value) : '';
            fechaInicioContrato = (fechaInicialContratoRef.current.value) ? inputDateToLong(fechaInicialContratoRef.current.value) : '';
            if(fechaIngresoGob === '' || fechaInicioContrato === '') {
                if(fechaIngresoGob === '') {
                    setTextAlertError(textErrorG);
                } else if(fechaInicioContrato === '') {
                    setTextAlertError(textErrorC);
                }
                setOpenAlertError(true);
                if(tipo === 'aguinaldo') {
                    fechaAguinaldoRef.current.value = '';
                }
                if(tipo === 'inicio de nombramiento') {
                    if(fechaNombramientoRef.current) fechaNombramientoRef.current.value = '';
                }
                return;
            }

            if(tipo === 'aguinaldo') {
                if(fechaIngresoGob > value || fechaInicioContrato < value) {
                    setTextAlertError(`La fecha de ${tipo} debe ser mayor o igual que la fecha de ingreso a gobierno y menor o igual que la fecha inicial de contrato.`);
                    setOpenAlertError(true);
                    if(tipo === 'aguinaldo') {
                        fechaAguinaldoRef.current.value = '';
                    }
                    if(tipo === 'inicio de nombramiento') {
                        if(fechaNombramientoRef.current) fechaNombramientoRef.current.value = '';
                    }
                    return;
                }
            }

            if(tipo === 'inicio de nombramiento') {
                if(fechaIngresoGob > value) {
                    setTextAlertError(`La fecha de ${tipo} debe ser mayor o igual que la fecha de ingreso a gobierno.`);
                    setOpenAlertError(true);
                    if(tipo === 'aguinaldo') {
                        fechaAguinaldoRef.current.value = '';
                    }
                    if(tipo === 'inicio de nombramiento') {
                        if(fechaNombramientoRef.current) fechaNombramientoRef.current.value = '';
                    }
                    return;
                }
            }
        } catch(e) {
            if(tipo === 'aguinaldo') {
                fechaAguinaldoRef.current.value = '';
            }
            if(tipo === 'inicio de nombramiento') {
                if(fechaNombramientoRef.current)fechaNombramientoRef.current.value = '';
            }
        }
    };

    const validaAguinaldo = value => {
        if(value !== '') {
            validacionAguinaldoNombramiento(value, 'aguinaldo');
        }
    };

    const validaInicioNombramiento = value => {
        if(value !== '') {
            validacionAguinaldoNombramiento(value, 'inicio de nombramiento');
        }
    };

    const handleChangePlazaDispRango = () => {
        validarPlaza(null)
        setPlazaDisponilbeRango(!plazaDisponibleRango);
        setPlazasRango([]);
    };

    const validarFechasRango = () => {
        const fechaIniRango = fechaInicialPlazaDispRangoRef.current.value;
        const fechaFinRango = fechaFinalPlazaDispRangoRef.current.value;

        if(!fechaIniRango || !fechaFinRango) {
            setErrorFechasRango(<AlertForm message="Debe capturar las fechas inicial y final del rango."/>);
            return false;
        }

        if(inputDateToLong(fechaFinRango) < inputDateToLong(fechaIniRango)) {
            setErrorFechasRango(<AlertForm message="La fecha inicial debe ser menor o igual que la fecha final."/>);
            return false;
        }

        if(inputDateToLong(fechaIniRango) < inputDateToLong(fechaIngresoRef.current.value)) {
            setErrorFechasRango(<AlertForm message={`La Fecha Inicial debe ser mayor o igual que la Fecha de Ingreso a Gobierno (${inputDateToLong(fechaIngresoRef.current.value)}).`}/>);
            return false;
        }
        setErrorFechasRango(null);
        return true;
    };

    const buscarPlazasRango = e => {
        e.preventDefault();
        if(validarFechasRango()) {
            setIsLoadingPlazasRango(true);
            const request = {
                fechaIni: inputDateToLong(fechaInicialPlazaDispRangoRef.current.value),
	            fechaFin: inputDateToLong(fechaFinalPlazaDispRangoRef.current.value)
            };
            axios.post(`${servicios_grupohit_org}/plaza/funcion/disponibles/`, request, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayPlazas = res.data.dataResponse.map(plaza => {
                            plaza.plazaclave = (plaza.status.id === 1) ? plaza.plazaclave : `${plaza.plazaclave} (${plaza.status.nombre})`;
                            return plaza;
                        });
                        setPlazasRango(arrayPlazas);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion))
            .finally(() => setIsLoadingPlazasRango(false));
        }
    };

    const handleChangeRetienePagos = () => {
        setRetienePagos(!retienePagos);
    };

    setTimeout(() => {
        setMostrarContenido(true);
    }, 2000);

    return (
        <Fragment>
        {(mostrarContenido === true)
        ?   <Fragment>
                <h5>Organización del Empleado</h5>
                    { (selected.plaza.id !== '') && infoClavePresupuestal }
                    <form id="OrganizacionEmpleado" onSubmit={elementSelect ? editar : guardar}>
                        {(enviarEmpleado || (modulo === 32))
                        ?   <Fragment>
                                <div className="row">
                                    <CheckPlazaRangoFechas plazaDisponibleRango={plazaDisponibleRango} handleChangePlazaDispRango={handleChangePlazaDispRango}/>
                                    {(plazaDisponibleRango)
                                    ?   <Fragment>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                                <InputDateValidation title="Fecha Inicial" refValue={fechaInicialPlazaDispRangoRef}
                                                    defaultValue={(modulo === 30 || (modulo === 32 && !elementSelect)) ? fechaIngresoRef.current.value : ''}
                                                    onBlurExecute={validarFechasRango}
                                                    validateEqualLess disabled={(modulo === 30 || (modulo === 32 && !elementSelect)) ? true : false} required
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                                <InputDateValidation title="Fecha Final" refValue={fechaFinalPlazaDispRangoRef}
                                                    defaultValue="" onBlurExecute={validarFechasRango}
                                                    validateEqualLess required
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-1 align-self-end mb-3">
                                                <TooltipComponent tooltipText="Buscar Plazas">
                                                    <button className="btn-autocompleteInput" onClick={buscarPlazasRango}><SearchIcon/></button>
                                                </TooltipComponent>
                                            </div>
                                        </Fragment>
                                    :   <div className="col"></div>
                                    }
                                </div>
                                { errorFechasRango }
                                <div className="formRelLab">
                                    <div className="form-left">
                                    {(isLoadingPlazasRango)
                                    ?   <SpinnerOval/>
                                    :   <AutocompleteComponent
                                            id="plaza" title="Plaza" options={(plazaDisponibleRango) ? plazasRango : plazas} optionListView="plazaclave" action={validarPlaza}
                                            placeholder="Plaza" tooltipName="tooltipPlaza" error={errorPlaza}
                                            tooltipText="Busque la Plaza, por clave, en el campo de autocompletado"
                                            value={(selected.plaza.id) ? (plazaDisponibleRango) ? plazasRango.find(p => p.id === parseInt(selected.plaza.id)) : plazas.find(p => p.id === parseInt(selected.plaza.id)) : {}}
                                            required
                                        />
                                    }
                                    </div>
                                    <div className="form-right">
                                        <AutocompleteComponent
                                            id="puesto" title="Clave del Puesto / Puesto" options={puestos}
                                            optionListView="puestoCveNom" action={validarPuesto}
                                            value={(selected.puesto.id) ? puestos.find(p => p.id === parseInt(selected.puesto.id)) : sugerenciaPuesto}
                                            placeholder="Clave del Puesto / Puesto" tooltipName="tooltipPuesto" error={errorPuesto}
                                            tooltipText="Busque el Puesto, por nombre o clave, en el campo de autocompletado" required
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        :   null
                        }
                        {(elementSelect && (modulo === 30))
                        ?   <div className="row">
                                <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                {(disabledPlaza)
                                ?   <InputValidation title="Plaza" type="none"
                                        tooltipText="Busque la Plaza, por clave, en el campo de autocompletado" tooltipName="tooltipPlaza"
                                        defaultValue={(infoEmpleado) ? infoEmpleado.plaza.plazaid.plazaclave : ''} disabled required
                                    />
                                :   <AutocompleteComponent
                                        id="plaza" title="Plaza" options={(infoEmpleado) ? plazas.filter(plaza => plaza.id !== infoEmpleado.plaza.plazaid.id) : plazas}
                                        optionListView="plazaclave" action={validarPlaza}
                                        placeholder="Plaza" tooltipName="tooltipPlaza" clearDiasabled
                                        tooltipText="Busque la Plaza, por clave, en el campo de autocompletado"
                                        value={(selected.plaza.id) ? plazas.find(p => p.id === parseInt(selected.plaza.id)) : {}}
                                        error={errorPlaza} required
                                    />
                                }
                                </div>
                                <div className={`col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 ${((disabledPlaza) ? 'align-self-end mb-3' : '')}`} style={(!disabledPlaza) ? { marginTop: '36px' } : {}}>
                                {(disabledPlaza)
                                ?   <button className="btn-autocompleteInput" onClick={e => enablePlaza(e, false)}><EditIcon/></button>
                                :   <button className="btn-autocompletecancel" onClick={e => enablePlaza(e, true)}><CancelIcon/></button>
                                }
                                </div>
                                <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                {(disabledPuesto)
                                ?   <InputValidation title="Clave del Puesto / Puesto" type="none"
                                        tooltipText="Busque el Puesto, por nombre o clave, en el campo de autocompletado" tooltipName="tooltipPuesto"
                                        defaultValue={(infoEmpleado) ? `${infoEmpleado.puesto.puesto.puestoCve} / ${infoEmpleado.puesto.puesto.puestoNom}` : ''}
                                        disabled required
                                    />
                                :   <AutocompleteComponent
                                        id="puesto" title="Clave del Puesto / Puesto" options={(infoEmpleado) ? puestos.filter(puesto => puesto.id !== infoEmpleado.puesto.puesto.id) : puestos}
                                        optionListView="puestoCveNom" action={obtienePuesto}
                                        value={(selected.puesto.id) ? puestos.find(p => p.id === parseInt(selected.puesto.id)) : sugerenciaPuesto}
                                        placeholder="Clave del Puesto / Puesto" tooltipName="tooltipPuesto" error={errorPuesto}
                                        tooltipText="Busque el Puesto, por nombre o clave, en el campo de autocompletado" required
                                    />
                                }
                                </div>
                                <div className={`col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 ${(disabledPuesto) ? 'align-self-end mb-3' : ''}`} style={(!disabledPuesto) ? { marginTop: '36px' } : {}}>
                                {(disabledPuesto)
                                ?   <button className="btn-autocompleteInput" onClick={e => enablePuesto(e, false)}><EditIcon/></button>
                                :   <button className="btn-autocompletecancel" onClick={e => enablePuesto(e, true)}><CancelIcon/></button>
                                }
                                </div>
                            </div>
                        :   null
                        }
                        {(selected.plaza.titular === '-' && (infoTitularPlaza === 'no')) &&
                            <TitularPlaza titularPlaza={titularPlaza} setTitularPlaza={setTitularPlaza}/>
                        }
                        {(elementSelect && (infoTitularPlaza !== 'no')) &&
                            <AlertForm info message={`El empleado es titular de la plaza ${infoTitularPlaza.clave}`} withoutMargin/>
                        }
                        <Sueldo
                            modulo={modulo} elementSelect={elementSelect} indicadores={indicadores}
                            monto={monto} montoEdit={montoEdit} setMonto={setMonto} setMontoEdit={setMontoEdit}
                            setIndicadoresSelected={setIndicadoresSelected} indicadoresSelected={indicadoresSelected}
                            disabledPuesto={disabledPuesto} successTable={successTable}
                            errorTable={errorTable} errorConceptoPago={errorConceptoPago} setErrorConceptoPago={setErrorConceptoPago}
                            indicadoresSueldoNombramientoActivo={indicadoresSueldoNombramientoActivo}
                            indicadoresSueldoActivo={indicadoresSueldoActivo} indicadoresNombramientoActivo={indicadoresNombramientoActivo}
                            muestraConceptos={muestraConceptos} contratoRef={contratoRef} contratos={contratos} puestoSeleccionado={selected.puesto}
                            setNombramientoEditInicial={setNombramientoEditInicial} nombramientoEditInicial={nombramientoEditInicial}
                        />
                        {(enviarEmpleado || (modulo === 32))
                        ?   <SelectValidation title="Zona de Pago" arrayOpciones={zonasPago}
                                refValue={zonaPagoRef} defaultValue="" disabled={disabled.zonaPago} required
                            />
                        :   null
                        }
                        {(elementSelect && (modulo === 30))
                        ?   (disabledZonaPago)
                            ?   <div className="row">
                                    <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                        <InputValidation title="Zona de Pago" type="none"
                                            defaultValue={(infoEmpleado)
                                                        ?   `${infoEmpleado.zonadepago.domcia.domCiaCalle}${(infoEmpleado.zonadepago.domcia.domCiaNumExt) ? ` ${infoEmpleado.zonadepago.domcia.domCiaNumExt}` : ''}${(infoEmpleado.zonadepago.domcia.domCiaNumInt) ? ` ${infoEmpleado.zonadepago.domcia.domCiaNumInt}` : ''}`
                                                        :   ''
                                                        }
                                            disabled required
                                        />
                                    </div>
                                    <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                        <button className="btn-autocompleteInput" onClick={e => enableZonaPago(e, false)}><EditIcon/></button>
                                    </div>
                                </div>
                            :   <div className="row">
                                    <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                        <SelectValidation title="Zona de Pago" arrayOpciones={zonasPago}
                                            refValue={zonaPagoRef} defaultValue="" required
                                        />
                                    </div>
                                    <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                        <button className="btn-autocompletecancelInput" onClick={e => enableZonaPago(e, true)}><CancelIcon/></button>
                                    </div>
                                </div>
                        :   null
                        }
                        {/* para validar este campo será necesario hacerlo por el arreglo de objetos en donde se guardarán todas las formas de pago del empleado */}
                        <div id="formasPago">
                            <div className="row">
                                <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                {muestraFormaPago === true
                                ?   <SelectValidation title="Forma de Pago" arrayOpciones={formasPago} keyOpcion="formapagoNom" onChangeExecute={verificaCheck}
                                        refValue={formaPagoRef} defaultValue="" disabled={editarElementoFormaPago ? true : false} required optional={true}
                                    />
                                :   null
                                }
                                </div>
                                <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                {(isLoading)
                                ?   <SpinnerOval />
                                :   <TooltipComponent tooltipText="Guardar Forma de Pago">
                                        <button className="btn-autocompleteInput" onClick={(editarElementoFormaPago) ? editarFormaPago : guardarFormaPago}><SaveIcon/></button>
                                    </TooltipComponent>
                                }
                                </div>
                            </div>
                            { (muestraFormDatosBancarios || editarElementoFormaPago) && datosTransferencia }
                            { errorFormPago.active && <AlertForm message={errorFormPago.message}/> }
                            {(formasPagoSelected.length > 0)
                            ?   <Fragment>
                                {formasPagoSelected.map((forma, key) => (
                                    <Fragment key={key}>
                                        { key > 0 && <hr/> }
                                        <div className="formRelLab">
                                        {(elementSelect)
                                        ?   <Fragment>
                                            {(forma.formapago > 2 && forma.formapago < 10) &&
                                                <BtnEditItem id={forma.formapago} idArray="formapago" array={formasPagoSelected} action={muestraEditarFPago} remove/>
                                            }
                                                <BtnRemoveItem id={forma.formapago} idArray="formapago" array={formasPagoSelected} action={eliminaFormaPago} remove noArrayVacio/>
                                            </Fragment>
                                        :   <BtnRemoveItem id={forma.formapago} idArray="formapago" array={formasPagoSelected} action={setFormasPagoSelected} noArrayVacio/>
                                        }
                                        {(forma.banco)
                                        ?   <Fragment>
                                                <div className="form-left">
                                                    <div><strong>Forma de Pago: </strong>{ forma.formaPagoNom }</div>
                                                    <div><strong>Número de Cuenta: </strong>{ forma.numCuenta }</div>
                                                </div>
                                                <div className="form-right">
                                                    <div><strong>Banco: </strong>{ forma.banco }</div>
                                                    <div><strong>
                                                    {(forma.cbeInterbancaria !== null)
                                                    ?   (forma.cbeInterbancaria.length === 16)
                                                        ?   'Número de Tarjeta: '
                                                        :   (forma.cbeInterbancaria.length === 18)
                                                            ?   'CLABE Interbancaria: '
                                                            :   'CLABE Interbancaria / Número de Tarjeta: '
                                                    :   'CLABE Interbancaria / Número de Tarjeta: '
                                                    }
                                                    </strong>{ forma.cbeInterbancaria }</div>
                                                </div>
                                                </Fragment>
                                        :  <div><strong>Forma de Pago: </strong>{ forma.formaPagoNom }</div>
                                        }
                                        </div>
                                    </Fragment>
                                ))}
                                <br/>
                                    </Fragment>
                            :   null
                            }
                        </div>
                        {(enviarEmpleado || (modulo === 32))
                        ?   <div className="formRelLab">
                                <div className="form-left"> 
                                    <SelectValidation title="Turno" arrayOpciones={turnos} keyOpcion="turnoNom"
                                        refValue={turnoRef} defaultValue="" required
                                    />
                                </div>
                                <div className="form-right">
                                    <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nominaNombre"
                                        onChangeExecute={validaNominaFFContrato} refValue={nominaRef} defaultValue="" required
                                    />
                                </div>
                            </div>
                        :   null
                        }
                        {(elementSelect && (modulo === 30))
                        ?   <div className="row">
                                <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                {(disabledTurno)
                                ?   <InputValidation title="Turno" type="none" defaultValue={(infoEmpleado) ? infoEmpleado.turno.turno.turnoNom : ''} disabled required/>
                                :   <SelectValidation title="Turno"
                                        arrayOpciones={(infoEmpleado) ? turnos.filter(turno => turno.turnoNom !== infoEmpleado.turno.turno.turnoNom) : turnos}
                                        keyOpcion="turnoNom" refValue={turnoRef} defaultValue="" required
                                    />
                                }
                                </div>
                                <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                {(disabledTurno)
                                ?   <button className="btn-autocompleteInput" onClick={e => enabledTurno(e, false)}><EditIcon/></button>
                                :   <button className="btn-autocompletecancelInput" onClick={e => enabledTurno(e, true)}><CancelIcon/></button>
                                }
                                </div>
                                <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                {(disabledNomina)
                                ?   <InputValidation title="Nómina" type="none" refValue={nominaRef}
                                        tooltipText="Seleccione la Nómina" tooltipName="tooltipNomina"
                                        defaultValue={(infoEmpleado) ? infoEmpleado.nomina.nomina.nominaNombre : ''} disabled required
                                    />
                                :   <SelectValidation title="Nómina"
                                        arrayOpciones={(infoEmpleado) ? nominas.filter(nomina => nomina.nominaNombre !== infoEmpleado.nomina.nomina.nominaNombre) : nominas}
                                        keyOpcion="nominaNombre" onChangeExecute={validaNominaFFContrato}
                                        tooltipText="Seleccione la Nómina" tooltipName="tooltipNomina"
                                        refValue={nominaRef} defaultValue={idNomina} required
                                    />
                                }
                                </div>
                                <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                {(disabledNomina)
                                ?   <button className="btn-autocompleteInput" onClick={e => enableNomina(e, false)}><EditIcon/></button>
                                :   <button className="btn-autocompletecancelInput" onClick={e => enableNomina(e, true)}><CancelIcon/></button>
                                }
                                </div>
                            </div>
                        :   null
                        }
                        <div className="row">
                            <div className="col">
                                <SelectValidation title="Contrato" arrayOpciones={contratos} keyOpcion="contratoSaoNom"
                                    defaultValue={(elementSelect) ? (modulo === 30) ? infoContrato.contrato.id : '' : ''}
                                    onChangeExecute={validaContrato} refValue={contratoRef} required
                                />
                            </div>
                            <div className="col">
                                <InputDateValidation title="Fecha Inicial" refValue={fechaInicialContratoRef}
                                    defaultValue={elementSelect ? (modulo === 30) ? longDateToInput(infoContrato.fechaini) : '' : ''}
                                    onBlurExecute={validaContratoInicial} validateEqualLess required
                                />
                            </div>
                            <div className="col">
                            {(enabledFechaContrato) &&
                                <InputDateValidation title="Fecha Final" refValue={fechaFinalContratoRef}
                                    tooltipText="Se desbloquea dependiendo del Tipo de Contrato seleccionado"
                                    defaultValue={(elementSelect) ? (modulo === 30) ? (infoContrato.fechafin && enabledFechaContrato) ? longDateToInput(infoContrato.fechafin) : '' : '' : ''}
                                    required={enabledFechaContrato} onBlurExecute={validaContratoFinal}
                                    optional={!enabledFechaContrato} disabled={!enabledFechaContrato}
                                />
                            }
                            </div>
                        </div>
                        {(!elementSelect || modulo === 32) &&
                            <CheckRetencionPagos retienePagos={retienePagos} handleChangeRetienePagos={handleChangeRetienePagos}/>
                        }
                        <div className="row">
                            <div className="col">
                                <InputDateValidation title="Fecha de Aguinaldo" refValue={fechaAguinaldoRef} validateEqualLess
                                    defaultValue={(elementSelect) ? (fechaAguinaldo.fechaAguinaldo !== '') ? longDateToInput(fechaAguinaldo.fechaAguinaldo) : '' : '' }
                                    onBlurExecute={validaAguinaldo} optional={true}    
                                />
                            </div>
                            <div className="col">
                            {(verificaFN())
                            ?   <InputDateValidation title="Fecha Inicial de Nombramiento" refValue={fechaNombramientoRef} validateEqualLess
                                    defaultValue={(elementSelect)  ? (fechaNombramiento.fechaNombramiento !== '') ? longDateToInput(fechaNombramiento.fechaNombramiento) : '' : '' }
                                    onBlurExecute={validaInicioNombramiento} required={true}
                                />
                            :   (rlSeleccionada.clave === '501')
                                ?   null
                                :   <InputDateValidation title="Fecha Inicial de Nombramiento" refValue={fechaNombramientoRef} validateEqualLess
                                        defaultValue={(elementSelect)  ? (fechaNombramiento.fechaNombramiento !== '') ? longDateToInput(fechaNombramiento.fechaNombramiento) : '' : '' }
                                        onBlurExecute={validaInicioNombramiento} optional={true}
                                    />
                            }
                            </div>
                            <div className="col">
                            </div>
                        </div>
                        <RegistroPatronal modulo={modulo} regPatronalRef={regPatronalRef} infoRegPatronal={(infoRegPatronal) ? infoRegPatronal : null} />
                        <Sindicato
                            modulo={modulo} infoSindicato={(infoSindicato) ? infoSindicato : null} sindicatoRef={sindicatoRef}
                            sindicalizado={sindicalizado} setSindicalizado={setSindicalizado}
                            cuotaSindical={cuotaSindical} setCuotaSindical={setCuotaSindical}
                            sindicatoSeleccionado={sindicatoSeleccionado} setSindicatoSeleccionado={setSindicatoSeleccionado}
                        />
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <Prestamos minLiquidez={minLiquidez} setMinLiquidez={setMinLiquidez}/>
                            </div>
                            {/*<div className="col-sm-12 col-md-6">
                                <SubsidioEmpleo aplicarSubsidioEmpleo={aplicarSubsidioEmpleo} setAplicarSubsidioEmpleo={setAplicarSubsidioEmpleo}/>
                            </div>*/}
                        </div>
                        <NotasAdicionales
                            modulo={modulo} notaRef={notaRef} empleadoId={(elementSelect) ? elementSelect.id : null}
                            notas={notas} setNotas={setNotas}
                            successTable={successTable} errorTable={errorTable}
                        />
                        <BtnsInfoAdicional modulo={modulo} numForm={2} irInfo={irInfo} elementSelect={(elementSelect) ? true : false}/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 1)}>Atrás</Button>
                            </div>
                            <div className="form-right">
                            {isLoading
                            ?   <SpinnerOval />
                            :   <Button variant="contained" className="btn-color" type="submit">
                                    { (elementSelect && (modulo === 32)) ? 'Reingresar' : (elementSelect) ? 'Actualizar' : 'Guardar' }
                                </Button>
                            }
                            </div>
                        </div>
                    </form>
                    { (elementSelect && modalIsOpen) &&
                        <ModalOrganizacionEmpleadoEdit
                            modulo={modulo} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} actualizar={actualizar}
                            disabledFormOrgEdit={disabledFormOrgEdit} elementSelect={elementSelect} infoEmpleado={infoEmpleado}
                            selected={selected} idTurno={idTurno} turnos={turnos} turnoRef={turnoRef} nominas={nominas} nominaRef={nominaRef}
                            zonasPago={zonasPago} zonaPagoRef={zonaPagoRef} isLoading={isLoading} nombramientoEditInicial={nombramientoEditInicial}
                            indicadoresSelected={indicadoresSelected} indicadoresNombramientoActivo={indicadoresNombramientoActivo}
                            plazaDisponibleRango={plazaDisponibleRango} fechaInicialPlazaDispRangoRef={fechaInicialPlazaDispRangoRef}
                        />
                    }
                    <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text="No has agregado la Forma de Pago seleccionada. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={elementSelect ? abrirModal : enviar}/>
                    <AlertWarning show={openAlertWContrato} SetopenALert={setOpenAlertWContrato} text="Se asignará un contrato permanente sin otorgar un nombramiento definitivo." textButtonAceptar="Aceptar" action={asignaContrato} textButtonCancelar="Cancelar" actionCancel={limpiarContrato}/>
                    <AlertWarning show={openAlertWFFContrato} SetopenALert={setOpenAlertWFFContrato} text="La fecha final de contrato es menor que la fecha inicial del periodo que se está procesando." textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" actionCancel={limpiaFechaFinalContrato}/>
                    {elementSelect &&
                        <AlertWarning show={openAlertWEditOrg} SetopenALert={setOpenAlertWEditOrg} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={() => actualizar(null, false)}/>
                    }
                    <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title={textAlertError} text="" textbtnconfirm="Aceptar" type="error" />
                </Fragment>
            :   <SpinnerOval />
        }
        </Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    nominasRL: state.PeriodoActualReducer.nominasRL,
});


export default connect(mapStateToProps, null)(FormOrganizacionEmpleado);