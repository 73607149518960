import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import './styles.css';
import AlertForm from '../../../../../../Services/AlertForm';

import GraficaEstadisticas from './GraficaEstadisticas';


const Estadisticas = ({ relacionLaboral }) => {
    const [ estadisticas, setEstadisticas ] = useState( null );
    const [estadisticasRL, setEstadisticasRL] = useState( null );
    const [error, setError ] = useState( null );

    useEffect(() => {
        if(relacionLaboral) {
            const abortController = new AbortController();
            axiosEstadisticas();
            return function cleanup() {
                abortController.abort();
            };
        }
    }, [relacionLaboral]);

    const axiosEstadisticas = () => {
        setEstadisticas( null );
        setEstadisticasRL(null);
        setError(null);

        axios.get( `${servicios_grupohit_rh}/empleado/grafica/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEstadisticas(respuesta.data.dataResponse);
                        break;
                    case 404:
                        setEstadisticas({});
                    break;
                    default:
                        setEstadisticas({});
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                }
            })
            .catch( error => {
                setEstadisticas({});
                setError( <AlertForm message={verifique_conexion} /> )
            });

        axios.get(`${servicios_grupohit_rh}/empleado/totalemp/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    let objectEstadisticas = {}; 
                    res.data.dataResponse.forEach(item => {
                        objectEstadisticas[item.Relab_nom.replace(/ /g, "").replace(/-/g, "").toLowerCase()] = parseInt(item.Empleados);
                        objectEstadisticas.nominas = parseInt(item.Nominas)
                    });

                    setEstadisticasRL(objectEstadisticas);
                    break;
                case 404:
                    setEstadisticasRL({});
                break;
                default:
                    setEstadisticasRL({});
                    setError(<AlertForm message={res.data.msg} />);
                    break;
            }
        })
        .catch( error => {
            setEstadisticas({});
            setError(<AlertForm message={verifique_conexion} />);
        })
    }
    
    return (
        <Fragment>
            {(estadisticas && estadisticasRL)
            ?   <Fragment>
                    {error}
                    <GraficaEstadisticas estadisticas={estadisticas} estadisticasRL={estadisticasRL}/>
                </Fragment>
            :   <SpinnerOval />
            }
        </Fragment>
    );
};

export default Estadisticas;