import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { cancelarBajaEmpleado } from '../../../../../../Redux/actions/EmpleadosActions';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteBajas = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable, cancelarBajaEmpleado }) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const eliminarBaja = () => {
        cancelarBajaEmpleado(elementSelect, eliminar, errorTable, setIsLoading);
    };

    return(
        <Fragment>
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
            <AlertWarning show={alertWarning} SetopenALert={setAlertWarning} text="El empleado volverá a status Activo" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={eliminarBaja} />
        </Fragment>
    );
}

const mapDispatchToProps = { cancelarBajaEmpleado };

export default connect(null, mapDispatchToProps)(ModalDeleteBajas);
