import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormControl from "@material-ui/core/FormControl";
import ConvertDate from '../Helpers/ConvertDate';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const useStyles = makeStyles((theme) => ({
    formControl: { 
      minWidth: 120,
      width:'100%',
      height:'70%'
    }, 
  }));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{fontFamily:'Univia'}}>{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DetallesNominasRL = ({ toggleDetallesNominas, modal, relacionLaboral, nominasRL }) => {

    const classes = useStyles();

    const cerrarModal = () => {
        toggleDetallesNominas();
    };

    return (
        <Dialog onClose={toggleDetallesNominas} aria-labelledby="customized-dialog-title" open={modal} className="contenedorModalRelLab contenedorModalDetallesNomina">
            <DialogTitle id="customized-dialog-title">
              <div>
                <div style={{ display: 'inline-block', marginRight: '2rem' }}>
                Nóminas de Relación Laboral <strong>{ relacionLaboral }</strong>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <button
                    type="button" className="close" aria-label="Close" onClick={cerrarModal}
                    style={{ position: 'absolute', right: '0', top: '0', padding: '15px' }}
                  >
                      <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <FormControl variant="filled" className={classes.formControl}>
              {(nominasRL.length > 0)
              ? nominasRL.map((nomina, i) => (
                  <div key={i} className="detalle-periodosNomina">
                    <div className="row">
                      <div className="col">
                        <label><strong>Nómina: </strong>{ nomina.nombreNomina }</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><strong>Año: </strong>{ nomina.periodoAnio }</label>
                      </div>
                      <div className="col">
                        <label><strong>Periodo: </strong>{ nomina.periodoInfo ? nomina.periodoInfo : nomina.periodoPerAnio }</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><strong>Fecha Inicial: </strong>{ ConvertDate(nomina.periodoFecini) }</label>
                      </div>
                      <div className="col">
                        <label><strong>Fecha Final: </strong>{ ConvertDate(nomina.periodoFecfin) }</label>
                      </div>
                    </div>
                  </div>
                ))
              : null
              }
              </FormControl>
            </DialogContent>
        </Dialog>
    );
}

export default DetallesNominasRL;