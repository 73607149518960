const arrayEmpleados = empleados => {
    const newEmpleados = empleados.map(empleado => 
        jsonEmpleados(empleado)
    );

    return newEmpleados;
};

const jsonEmpleados = empleado => (
    {
        areaId: empleado.areaId,
        areaNom: empleado.areaNom,
        ciaId: empleado.ciaId,
        ciaRazonSocial: empleado.ciaRazonSocial,
        ciaUr: empleado.ciaUr,
        cpresupuestalClave: empleado.cpresupuestalClave,
        cpresupuestalId: empleado.cpresupuestalId,
        cpresupuestalNom: empleado.cpresupuestalNom,
        domciaCalle: empleado.domciaCalle,
        domciaExt: empleado.domciaExt,
        domciaId: empleado.domciaId,
        domciaNumext: empleado.domciaNumext,
        domciaNumint: empleado.domciaNumint,
        domciaTel: empleado.domciaTel,
        edoCivilId: empleado.edoCivilId,
        empantFecini: empleado.empantFecini,
        empantId: empleado.empantId,
        empleadoClave: empleado.empleadoClave,
        empleadoCurp: empleado.empleadoCurp,
        empleadoFecnac: empleado.empleadoFecnac,
        empleadoGenero: (empleado.empleadoGenero === 'M') ? 'Masculino' : (empleado.empleadoGenero === 'F') ? 'Femenino' : empleado.empleadoGenero,
        empleadoMat: empleado.empleadoMat,
        empleadoNom: empleado.empleadoNom,
        empleadoNss: empleado.empleadoNss,
        empleadoPat: empleado.empleadoPat,
        empleadoRfc: empleado.empleadoRfc,
        empstatusFecini: empleado.empstatusFecini,
        empstatusId: empleado.empstatusId,
        empsueldoMonto: empleado.empsueldoMonto,
        empturFecfin: empleado.empturFecfin,
        empturFecini: empleado.empturFecini,
        empturId: empleado.empturId,
        estadoId: empleado.estadoId,
        id: empleado.id,
        nivLaboralClave: empleado.nivLaboralClave,
        nombreCompleto: empleado.nombreCompleto,
        nominaClave: empleado.nominaClave,
        nominaId: empleado.nominaId,
        nominaNomCorto: empleado.nominaNomCorto,
        nominaNombre: empleado.nominaNombre,
        plazaClave: empleado.plazaClave,
        plazaId: empleado.plazaId,
        programaClave: empleado.programaClave,
        programaId: empleado.programaId,
        programaNom: empleado.programaNom,
        proyectoClave: empleado.proyectoClave,
        proyectoId: empleado.proyectoId,
        proyectoNom: empleado.proyectoNom,
        puestoCve: empleado.puestoCve,
        puestoId: empleado.puestoId,
        puestoNom: empleado.puestoNom,
        realabNom: empleado.realabNom,
        relabClave: empleado.relabClave,
        relabId: empleado.relabId,
        tipodomId: empleado.tipodomId,
        tipodomNom: empleado.tipodomNom,
        tipojornadaClave: empleado.tipojornadaClave,
        tipojornadaId: empleado.tipojornadaId,
        tipojornadaNom: empleado.tipojornadaNom,
        turnoId: empleado.turnoId,
        turnoNom: empleado.turnoNom,
        udejecutoraClave: empleado.udejecutoraClave,
        udejecutoraId: empleado.udejecutoraId,
        udejecutoraNom: empleado.udejecutoraNom,
        zonaPago: empleado.zonaPago,
    }
);

export default arrayEmpleados;