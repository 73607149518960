import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { validarSubmit, inputDateToLong, longDateToInput, floatToAmount, amountToFloat } from '../../../../../../Services/Validation/HelperValidation';
import { servicios_grupohit_rh, registro_actualizado, registro_sera_actualizado, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import InfoPensionesAlimenticias from './InfoPensionesAlimenticias';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const tiposCuota = [
    { id: 'I', cuota: 'Importe' },
    { id: 'P', cuota: 'Porcentaje' },
    { id: 'S', cuota: 'Salario Mínimo' },
    { id: 'U', cuota: 'UMA' },
];

const FormPensionesAlimenticias = ({
    elementSelect, setError, agregarPensionAlimEdit, actualizarPensionAlim, enviar, irInfo, folioPensionAlimRef,
    nombrePensionAlimRef, pApellidoPensionAlimRef, sApellidoPensionAlimRef, tipoCuotaPensionAlimRef, montoPensionAlimRef,
    fechaInicioPensionAlimRef, fechaFinalPensionAlimRef, prioridadPensionAlimRef, pensionesAlimSaved, setPensionesAlimSaved,
    setPensionesAlimSavedEdit, pensionesAlimSavedEdit, elementoSeleccionado, setElementoSeleccionado, mostrarFormAddEdit,
    setMostrarFormAddEdit, successTable, errorTable, isLoading
}) => {

    const [regexTipoCuota, setRegexTipoCuota] = useState('');
    const [openAlert, setOpenAlert] = useState(false);

    const mostrarAlert = e => {
        e.preventDefault();
        if(!elementSelect || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit)) {
            if(pensionesAlimSavedEdit.length && validaPrioridadPensiones()) {
                setOpenAlert(true);
            } else {
                setError(<AlertForm message="Para guardar debe de haber, al menos, una pensión alimenticia agregada"/>);
            }
        } else {
            if(validaPrioridadPensiones()) setOpenAlert(true);
        }
    };

    const guardar = e => {
        e.preventDefault();
        enviar();
    };

    useEffect(() => {
        if(elementoSeleccionado) {
            tipoCuota(elementoSeleccionado.tipoCuota, false);
            setTimeout(() => {
                montoPensionAlimRef.current.value = (elementoSeleccionado.tipoCuota === 'I') ? floatToAmount(elementoSeleccionado.montoPorc, 2) : parseFloat(elementoSeleccionado.montoPorc).toFixed(2);
            }, 300);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementoSeleccionado]);

    const tipoCuota = (valor, limpiarMonto=true) => {
        if(limpiarMonto) montoPensionAlimRef.current.value = '';
        switch(valor) {
            case 'I':
                setRegexTipoCuota('monto');
            break;
            case 'P':
                setRegexTipoCuota('porcentaje');
            break;
            case 'U':
            case 'S':
                setRegexTipoCuota('umaSMinimo');
            break;
            default:
                setRegexTipoCuota('');
            break;
        }
    };

    const abrirFormAddEdit = e => {
        e.preventDefault();
        setMostrarFormAddEdit(true);
    };

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupohit_rh}/empleado/pension/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const info = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                folio: res.folio,
                                nombre: res.nombre,
                                apellidoPat: res.apellidoPat,
                                apellidoMat: res.apellidoMat,
                                tipoCuota: res.tipoCuota,
                                tipoCuotaNom: (res.tipoCuota === 'I')
                                                ? 'IMPORTE'
                                                : (res.tipoCuota === 'P')
                                                   ? 'PORCENTAJE'
                                                   : (res.tipoCuota === 'U')
                                                      ? 'UMA'
                                                      : (res.tipoCuota === 'S')
                                                        ? 'SALARIO MÍNIMO'
                                                        : '',
                                montoPorc: parseFloat(res.montoPorc),
                                fechaIni: res.fechaIni,
                                fechaFin: res.fechaFin,
                                prioridad: res.prioridad
                            }
                        ));
                        setPensionesAlimSaved(info);
                        break;
                    case 404:
                        setPensionesAlimSaved([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion) });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eliminarPensionAlim = item => {
        axios.delete(`${servicios_grupohit_rh}/empleado/pension/${item.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPensionesAlimSaved(pensionesAlimSaved.filter(llEme => llEme.id !== item.id));
                    successTable(registro_actualizado);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion) });
    };

    const validaPrioridadPensiones = () => {
        const pensionesTotal = pensionesAlimSaved.concat(pensionesAlimSavedEdit);
        let prioridades =   (elementoSeleccionado && !isNaN(elementoSeleccionado.prioridad))
                            ?   pensionesTotal.filter(p => p.prioridad !== elementoSeleccionado.prioridad).map(p => (p.prioridad))
                            :   pensionesTotal.map(p => (p.prioridad));
        const coincidencia = prioridades.find(item => String(item) === prioridadPensionAlimRef.current.value.trim());
        if(coincidencia) {
            const itemCoincidencia = pensionesTotal.find(p => p.prioridad === coincidencia);
            setError(<AlertForm message={`El número de prioridad ya esta ocupado por la pensión alimenticia con folio ${itemCoincidencia.folio}`}/>)
            return false;
        }
        return true;
    };

    const guardaPension = (e, type) => {
        e.preventDefault();

        let lengthPensionAlim = 0;
        let lengthPensionAlimS = 0;

        if(pensionesAlimSaved.length) lengthPensionAlim = pensionesAlimSaved.length;
        if(pensionesAlimSavedEdit.length) lengthPensionAlimS = pensionesAlimSavedEdit.length;

        const total = lengthPensionAlim + lengthPensionAlimS;

        if(total < 10) {
            setError(null);
            if(validarSubmit("FormPensionesAlimenticias") && validaPrioridadPensiones()) {
                let tipoCuotaNom = '';
                switch(tipoCuotaPensionAlimRef.current.value) {
                    case 'I':
                        tipoCuotaNom = 'IMPORTE';
                        break
                    case 'P':
                        tipoCuotaNom = 'PORCENTAJE';
                        break
                    case 'U':
                        tipoCuotaNom = 'UMA';
                        break
                    case 'S':
                        tipoCuotaNom = 'SALARIO MÍNIMO';
                        break
                    default:
                        break;
                }
                let info = {
                    id: `${pensionesAlimSaved.length}${folioPensionAlimRef.current.value}`,
                    folio: folioPensionAlimRef.current.value,
                    nombre: nombrePensionAlimRef.current.value,
                    tipoCuota: tipoCuotaPensionAlimRef.current.value,
                    tipoCuotaNom,
                    montoPorc: (tipoCuotaPensionAlimRef.current.value === 'I') ? amountToFloat(montoPensionAlimRef.current.value) : parseFloat(montoPensionAlimRef.current.value),
                    fechaIni: inputDateToLong(fechaInicioPensionAlimRef.current.value),
                    prioridad: Number(prioridadPensionAlimRef.current.value.trim())
                };
    
                if(pApellidoPensionAlimRef.current.value !== '') info = { ...info, apellidoPat: pApellidoPensionAlimRef.current.value };
                if(sApellidoPensionAlimRef.current.value !== '') info = { ...info, apellidoMat: sApellidoPensionAlimRef.current.value };
                if(fechaFinalPensionAlimRef.current.value !== '') info = { ...info, fechaFin: inputDateToLong(fechaFinalPensionAlimRef.current.value) };
    
                try {
                    if(type === 'FormAgregar') setPensionesAlimSaved([ ...pensionesAlimSaved, info ]);
                    if(type === 'FormEditar') {
                        info = { ...info, empleadoId: elementSelect.id };
                        setPensionesAlimSavedEdit([ ...pensionesAlimSavedEdit, info ]);
                    }
                } catch(e) {
                    console.log(pensionesAlimSaved)
                    console.log(pensionesAlimSavedEdit)
                    console.log(info)
                    console.log(e)
                    setError(<AlertForm message="Vuelva a intentarlo"/>);
                }
    
                folioPensionAlimRef.current.value = '';
                nombrePensionAlimRef.current.value = '';
                pApellidoPensionAlimRef.current.value = '';
                sApellidoPensionAlimRef.current.value = '';
                tipoCuotaPensionAlimRef.current.value = '';
                montoPensionAlimRef.current.value = '';
                fechaInicioPensionAlimRef.current.value = '';
                fechaFinalPensionAlimRef.current.value = '';
                prioridadPensionAlimRef.current.value = '';
            }
        } else {
            setError(<AlertForm message="Has llegado al límite de Pensiones Alimenticias"/>);
        }
    };
    
    const limpiaSelected = e => {
        e.preventDefault();
        setError(null);
        if(pensionesAlimSavedEdit.length) setPensionesAlimSavedEdit([])
        else setElementoSeleccionado(null);
        setMostrarFormAddEdit(false);
    };

    const enviaInfo = () => {
        setError(null);
        if(!pensionesAlimSavedEdit.length && !mostrarFormAddEdit) {
            actualizarPensionAlim();
        } else {
            agregarPensionAlimEdit();
        }
    };

    return (
        <Fragment>
            <h5>Pensiones Alimenticias</h5>
            {
                (!elementSelect || (elementoSeleccionado === null && pensionesAlimSaved.length > 0 && !mostrarFormAddEdit))
                ?   pensionesAlimSaved.map((pAlim, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            {
                                (elementSelect)
                                ?   <Fragment>
                                        <BtnEditItem id={pAlim.id} idArray="id" array={pensionesAlimSaved} action={setElementoSeleccionado} remove/>
                                        <BtnRemoveItem id={pAlim.id} idArray="id" array={pensionesAlimSaved} action={eliminarPensionAlim} remove/>
                                    </Fragment>
                                :   <BtnRemoveItem id={pAlim.id} idArray="id" array={pensionesAlimSaved} action={setPensionesAlimSaved}/>
                            }
                            <InfoPensionesAlimenticias pAlim={pAlim}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (pensionesAlimSavedEdit)
                ?   pensionesAlimSavedEdit.map((pAlim, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            <BtnRemoveItem id={pAlim.id} idArray="id" array={pensionesAlimSavedEdit} action={setPensionesAlimSavedEdit}/>
                            <InfoPensionesAlimenticias pAlim={pAlim}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (!elementSelect || elementoSeleccionado !== null || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit))
                ?   <form id="FormPensionesAlimenticias" onSubmit={(elementSelect) ? mostrarAlert : guardar}>
                        <InputValidation title="Número de Folio" type="folioPensionAlimenticia"
                            placeholder="Escriba el número de folio de la pensión alimenticia" alfanumeric
                            refValue={folioPensionAlimRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.folio : ''} 
                            tooltipText="Máximo 8 letras y/o números" tooltipName="tooltipNomPension" maxLength={8} required/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Primer Apellido" type="pApellido" placeholder="Escriba el primer apellido" onlyLetters=" "
                                    refValue={pApellidoPensionAlimRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoPat) ? elementoSeleccionado.apellidoPat : '' : ''} 
                                    tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipPApellidoPensionAlim" maxLength={30} optional={true}/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Segundo Apellido" type="sApellido" placeholder="Escriba el segundo apellido" onlyLetters=" "
                                    refValue={sApellidoPensionAlimRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoMat) ? elementoSeleccionado.apellidoMat : '' : ''}
                                    tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipSApellidoPensionAlim" maxLength={30} optional={true}/>
                            </div>
                        </div>
                        <InputValidation title="Nombre" type="nombre" placeholder="Escribe el nombre"
                            refValue={nombrePensionAlimRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.nombre : ''} onlyLetters=" "
                            tooltipText="Máximo 60 letras y/o espacio" tooltipName="tooltipNomPensionAlim" maxLength={60} required/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <SelectValidation title="Tipo de Cuota" arrayOpciones={tiposCuota} keyOpcion="cuota"
                                    refValue={tipoCuotaPensionAlimRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.tipoCuota : ''} onChangeExecute={tipoCuota} required/>
                            </div>
                            <div className="form-right">
                                <InputValidation title={(regexTipoCuota === 'umaSMinimo') ? 'Cantidad' : `Monto${(regexTipoCuota === 'monto') ? '' : (regexTipoCuota === 'porcentaje') ? ' (%)' : ''}`}
                                    type={regexTipoCuota} placeholder="Escriba el monto de la pensión alimenticia"
                                    refValue={montoPensionAlimRef}
                                    defaultValue={elementoSeleccionado ? (elementoSeleccionado.tipoCuota === 'I') ? floatToAmount(elementoSeleccionado.montoPorc, 2) : parseFloat(elementoSeleccionado.montoPorc).toFixed(2) : ''}
                                    tooltipText={(regexTipoCuota === 'umaSMinimo') ? 'Máximo 3 números del 1 al 100' : (regexTipoCuota === 'monto') ? 'Máximo 6 números y dos decimales' : regexTipoCuota === 'porcentaje' ? 'Máximo 2 números y dos decimales del 0.00 al 100.00' : 'Debes seleccionar el Tipo de Cuota'}
                                    maxLength={(regexTipoCuota === 'umaSMinimo') ? 3 : (regexTipoCuota === 'monto') ? 9 : regexTipoCuota === 'porcentaje' ? 6 : 0}
                                    onBlurDecimal={2} onlyNumbers={(regexTipoCuota === 'umaSMinimo') ? '' : '.'} disabled={regexTipoCuota !== '' ? false : true}
                                    isAmount={(regexTipoCuota === 'monto') ? true : false} required
                                />
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputDateValidation title="Fecha de Inicio" refValue={fechaInicioPensionAlimRef}
                                    defaultValue={elementoSeleccionado ? longDateToInput(elementoSeleccionado.fechaIni) : ''} required/>
                            </div>
                            <div className="form-right">
                                <InputDateValidation title="Fecha Final" refValue={fechaFinalPensionAlimRef}
                                    defaultValue={elementoSeleccionado ? (elementoSeleccionado.fechaFin) ? longDateToInput(elementoSeleccionado.fechaFin) : '' : ''} optional={true}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <InputValidation title="Número de Prioridad" type="prioridad1_99" refValue={prioridadPensionAlimRef}
                                    defaultValue={elementoSeleccionado ? elementoSeleccionado.prioridad : ''}
                                    maxLength={2} onlyNumbers required
                                />
                            </div>
                        </div>
                        <BtnsInfoAdicional numForm={5} irInfo={irInfo} elementSelect={(elementSelect) ? true : false}/>
                        {
                            (!elementSelect || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit))
                            ?   (!elementSelect || pensionesAlimSaved.length === 0)
                                ?   <Button variant="outlined" className="btn-outline" onClick={e => (!elementSelect) ? guardaPension(e, 'FormAgregar') : guardaPension(e, 'FormEditar')}>Agregar Pensión</Button>
                                :   <Button variant="outlined" className="btn-outline" onClick={e => guardaPension(e, 'FormEditar')}>Agregar Pensión</Button>
                            :   null
                        }
                        {
                            (elementSelect && pensionesAlimSaved.length > 0 && !mostrarFormAddEdit)
                            ?   <div className="formRelLab">
                                    <div className="form-left">
                                        <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                    </div>
                                    <div className="form-right">
                                    {isLoading
                                    ?   <SpinnerOval />
                                    :   <Button variant="contained" className="btn-color" type="submit">{(!elementSelect || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit)) ? 'Guardar' : 'Actualizar' }</Button>
                                    }
                                    </div>
                                </div>
                            :   (!elementSelect || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit))
                                ?   <div className="formRelLab">
                                        <div className="form-left">
                                        {(!elementSelect || pensionesAlimSaved.length === 0)
                                        ?   <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                                        :   <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                        }
                                        </div>
                                        <div className="form-right">
                                        {isLoading
                                        ?   <SpinnerOval />
                                        :   <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
                                        }
                                        </div>
                                    </div>
                                :   null
                        }
                    </form>
                :   <Fragment>
                        <br />
                        <BtnsInfoAdicional numForm={5} irInfo={irInfo} elementSelect={(elementSelect) ? true : false}/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                            </div>
                            <div className="form-right">
                                <Button variant="outlined" className="btn-outline" onClick={e => abrirFormAddEdit(e)}>Agregar</Button>
                            </div>
                        </div>
                    </Fragment>
            }
            {   elementSelect &&
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaInfo}/>
            }
        </Fragment>
    );
}
 
export default FormPensionesAlimenticias;