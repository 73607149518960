import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_nomina, servicios_grupohit_catalogo, servicios_grupohit } from '../../../../../../../Constants/Constants';
import { ArrayJsonPeriodosRetroactivos } from '../../../../Helpers/JsonToOneLevel';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import AlertForm from '../../../../../../../Services/AlertForm';
import FormularioRetroactivo from './FormularioRetroactivo';

const Retroactivos = ({ errorAlert, successAlert }) => {
    const [ error, setError ] = useState( null );
    const [ nominaID, setNominaID ] = useState( null );
    const [ nominas, setNominas ] = useState( null );
    const [ indicadores, setIndicadores ] = useState( null );
    const [ nominaPeriodos, setNominaPeriodos ] = useState( null );
    const [ peticion, setPeticion ] = useState( false );
    const [ excel64, setExcel64 ] = useState( null );
    useEffect(() => {
        axiosNomninas();
    }, []);

    const axiosNomninas = () => {
        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setNominas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosIndicadores = ( nominaID ) => {
        setIndicadores( null );
        axios.get( `${servicios_grupohit_catalogo}/indicador/relab/${nominaID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadores( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setIndicadores([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosNominaPeriodos = ( nominaID ) => {
        setNominaPeriodos( null );
        axios.get( `${servicios_grupohit}/vnominaPeriodo/${nominaID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const periodosMostrar = respuesta.data.dataResponse.filter(p => (p.periodoPerAnio !== 9) && (p.periodoPerAnio !== 23));
                        setNominaPeriodos(ArrayJsonPeriodosRetroactivos(periodosMostrar));
                        break;
                    case 404:
                        //setNominaPeriodos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const actionSelectNomina = ( nominaID ) => {
        setError( null );
        setExcel64( null );
        if( nominaID !== '' ){
            setNominaID( nominaID );
            axiosIndicadores( nominaID );
            axiosNominaPeriodos( nominaID );
        }else{
            setNominaID( null );
            setIndicadores( null );
            setNominaPeriodos( null );
        }
    }

    const enviarRetroactivo = ( retroactivo ) => {
        setExcel64( null );
        setPeticion( true );
        axios.post( `${servicios_grupohit}/retroactivo/procesos/`, retroactivo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setExcel64( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorAlert( 'El retroactivo no pudo ser calculado' );
                        break;
                }
                setPeticion( false );
            })
            .catch( error => {
                errorAlert( 'El retroactivo no pudo ser calculado' );
                setPeticion( false );
            })
    }

    const eliminarRetroactivo = ( nominaID, anio ) => {
        axios.delete( `${servicios_grupohit}/retroactivo/procesos/${nominaID}/${anio}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setExcel64( null );
                        successAlert( 'Retroactivo eliminado' );
                        break;
                    default:
                        errorAlert( 'El retroactivo no pudo ser eliminado' );
                        break;
                }
            })
            .catch( error => {
                errorAlert( 'El retroactivo no pudo ser eliminado' );
            })
    }

    return (
        <React.Fragment>
            { nominas ?
                <FormularioRetroactivo
                    error={error}
                    enviarRetroactivo={enviarRetroactivo}
                    eliminarRetroactivo={eliminarRetroactivo}
                    informacion={{nominas, nominaID, indicadores, nominaPeriodos, peticion, excel64}}
                    setError={setError}
                    actionSelectNomina={actionSelectNomina}
                />
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default Retroactivos;