import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { inputDateToLong, validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertForm from '../../../../../../Services/AlertForm';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormAusentismos = ({ error, setError, enviarAusentismo, elementSelect, actualizarAusentismo, tiposAusentismo, claveEmpleado, isLoading }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const fechaInicialRef = createRef('');
    const fechaFinalRef = createRef('');
    const tipoAusentismoRef = createRef('');
    const fechaAplicacionRef = createRef('');
    const cantidadRef = createRef('');
    const comentariosRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        if(validarSubmit('Ausentismo')) {
            setOpenAlert(true);
        }
    };

    const validaFechas = () => {
        if(inputDateToLong(fechaInicialRef.current.value) > inputDateToLong(fechaFinalRef.current.value)) {
            setError(<AlertForm message="La fecha final debe ser mayor o igual que la fecha inicial"/>);
            return false;
        } else {
            setError(null);
            return true;
        }
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('Ausentismo')) {
            if(validaFechas()) {
                const empleado = parseInt(claveEmpleado);
                const ausentismo = parseInt(tipoAusentismoRef.current.value);
                const auseFechaIni = fechaInicialRef.current.value;
                const auseFechaFin = fechaFinalRef.current.value;
                const auseFechaAplica = inputDateToLong(fechaAplicacionRef.current.value);
                const cantidad = cantidadRef.current.value;
                const comentarios = comentariosRef.current.value.trim();
                const request = { empleado, ausentismo, auseFechaAplica, cantidad };
                if(auseFechaIni.length) request.auseFechaIni = inputDateToLong(auseFechaIni);
                if(auseFechaFin.length) request.auseFechaFin = inputDateToLong(auseFechaFin);
                if(comentarios.length) request.comentarios = comentarios;
                enviarAusentismo(request);
            }
        }
    };

    const actualizar = () => {
        if(validarSubmit('Ausentismo')) {
            if(validaFechas()) {
                const empleado = parseInt(claveEmpleado);
                const ausentismo = parseInt(tipoAusentismoRef.current.value);
                const auseFechaIni = fechaInicialRef.current.value;
                const auseFechaFin = fechaFinalRef.current.value;
                const auseFechaAplica = inputDateToLong(fechaAplicacionRef.current.value);
                const cantidad = cantidadRef.current.value;
                const comentarios = comentariosRef.current.value.trim();
                setError(null);
                const request = { empleado, ausentismo, auseFechaAplica, cantidad };
                if(auseFechaIni.length) request.auseFechaIni = inputDateToLong(auseFechaIni);
                if(auseFechaFin.length) request.auseFechaFin = inputDateToLong(auseFechaFin);
                if(comentarios.length) request.comentarios = comentarios;
                actualizarAusentismo(request, elementSelect.id);
            }
        }
    };

    return ( 
        <Fragment>
            <form id="Ausentismo" onSubmit={elementSelect ? mostrarAlert : enviar}>
                <div className="row">
                    <div className="col">
                        <InputDateValidation title="Fecha Inicial" refValue={fechaInicialRef}
                            defaultValue={elementSelect ? elementSelect.auseFechaIniInput : ''} optional={true}
                        />
                    </div>
                    <div className="col">
                        <InputDateValidation title="Fecha Final" refValue={fechaFinalRef}
                            defaultValue={elementSelect ? elementSelect.auseFechaFinInput : ''} optional={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <SelectValidation title="Tipo de Ausentismo" arrayOpciones={tiposAusentismo} keyOpcion="ausentismoNom"
                            tooltipText="Seleccione un tipo de ausentismo" refValue={tipoAusentismoRef}
                            defaultValue={elementSelect ? elementSelect.ausentismoId : ''} required
                        />
                    </div>
                    <div className="col">
                        <InputDateValidation title="Fecha de Aplicación" refValue={fechaAplicacionRef}
                            defaultValue={elementSelect ? elementSelect.auseFechaAplicaInput : ''} required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <InputValidation title="Cantidad" type="cantidadDecimal" placeholder="Escriba la cantidad"
                            refValue={cantidadRef} defaultValue={elementSelect ? elementSelect.cantidad : ''}
                            onlyNumbers='.' onBlurDecimal={2} maxLength={5}
                            tooltipText="Máximo 2 números y dos decimales" required
                        />
                    </div>
                    <div className="col">
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <InputValidation title="Comentarios" type="comentarios" placeholder="Escriba un comentario sobre el ausentismo"
                            refValue={comentariosRef} defaultValue={elementSelect ? elementSelect.comentarios : ''}
                            tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            maxLength={256} fieldType="textarea" optional={true}
                        />
                    </div>
                </div>
                { error }
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment> 
    );
}
export default FormAusentismos;