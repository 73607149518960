import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonRegistroPatronal } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormRegistroPatronal from './FormRegistroPatronal';

const ModalEditRegistroPatronal = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo }) => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarRegistroPatronal = (registoPatronal, registoPatronalId) => {
        setIsLoading(true);
        const info = registoPatronal;
        Axios.put( `${servicios_grupohit_catalogo}/registropat/${registoPatronalId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar( JsonRegistroPatronal(res.data.dataResponse) );
                setOpenEdit(false);
                break;
            default:
                errorTable(res.data.msg );
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService title="Actualizar Registro Patronal" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormRegistroPatronal
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        informacion={informacionAdicional}
                        actualizarRegistroPatronal={actualizarRegistroPatronal}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditRegistroPatronal;