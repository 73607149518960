import React, { useState, useEffect, Fragment, createRef } from 'react';
import axios from 'axios';
import { cambios_no_guardados, registro_actualizado, servicios_grupohit_plantillas } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonPlantillas } from '../../../Helpers/JsonToOneLevel';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import EditorHTML from './EditorHTML';
import Variables from './Variables';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TitleCard from './components/TitleCard';
import SubtitleCard from './components/SubtitleCard';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import AlertForm from '../../../../../../Services/AlertForm';
import { pushSuccess } from '../../../../../../Services/Notifications/PushService';
import { useStyles } from './styles/stylesCard';
import './styles/styles.css';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';

const EditPlantilla = ({ item, onShowTable, plantillas, setPlantillas }) => {
    const classes = useStyles();

    const {
        plantillaClave: clave,
        plantillaNom: nombre,
        plantillaHtml: html,
        id,
        plantillaDesc,
        margenSup,
        margenInf,
        margenIzq,
        margenDer
    } = item;

    const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [content, setContent] = useState(false);
    const [ textError, setTextError ] = useState(cambios_no_guardados);
    const [errorMSI, muestraErrorMSI] = useState(false);
    const [errorMID, muestraErrorMID] = useState(false);

    const margenIzquierdoRef = createRef('');
    const margenDerechoRef = createRef('');
    const margenSuperiorRef = createRef('');
    const margenInferiorRef = createRef('');

    useEffect(() => {
        setContent(html);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actualizarDatosPlantilla = () => {
        if(validarSubmit('margenesDocto')) {
            const margenSup = parseFloat(margenSuperiorRef.current.value);
            const margenInf = parseFloat(margenInferiorRef.current.value);
            const margenIzq = parseFloat(margenIzquierdoRef.current.value);
            const margenDer = parseFloat(margenDerechoRef.current.value);

            const request = {
                plantillaClave: clave,
                plantillaNom: nombre,
                plantillaDesc,
                plantillaHtml: content,
                margenSup: margenSup,
                margenDer: margenDer,
                margenInf: margenInf,
                margenIzq: margenIzq
            };
            axios.put(`${servicios_grupohit_plantillas}/${id}`, request, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const newPlantilla = JsonPlantillas(res.data.dataResponse);
                        setPlantillas(plantillas.map(element => 
                            element.id === newPlantilla.id ? element = newPlantilla : element
                        ));
                        setOpenAlertSuccess(true);
                    break;
                    default:
                        setTextError(res.data.msg);
                        setOpenAlertError(true);
                        break;
                }
            })
            .catch(error => setOpenAlertError(true));
        }
    };

    const asignarVariable = variable => {
        let tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = `{{${variable}}}`;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        pushSuccess({ title: 'La variable se copió al portapapeles', message: 'Agréguela a la plantilla con Ctrl + V' });
    };

    const verificaMSI = () => {
        let margenSuperior = 0;
        let margenInferior = 0;

        if(margenSuperiorRef.current.value !== '') margenSuperior = parseFloat(margenSuperiorRef.current.value);
        if(margenInferiorRef.current.value !== '') margenInferior = parseFloat(margenInferiorRef.current.value);
        
        const suma = margenSuperior + margenInferior;
        if(suma > 26) {
            muestraErrorMSI(true);
        } else {
            muestraErrorMSI(false);
        }
    };
    
    const verificaMID = () => {
        let margenIzquierdo = 0;
        let margenDerecho = 0;

        if(margenIzquierdoRef.current.value !== '') margenIzquierdo = parseFloat(margenIzquierdoRef.current.value);
        if(margenDerechoRef.current.value !== '') margenDerecho = parseFloat(margenDerechoRef.current.value);
        
        const suma = margenIzquierdo + margenDerecho;
        if(suma > 20) {
            muestraErrorMID(true);
        } else {
            muestraErrorMID(false);
        }
    };
    
    return (
        <Fragment>
            <BtnBack tooltipText="Regresar a tabla de plantillas" action={onShowTable}/>
            <div className="row">
                <div className="col col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 mb-1">
                    <Card>
                        <CardContent>
                            <div>
                                <div className="row">
                                    <div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-12 d-flex align-items-end">
                                        <TitleCard title={`${clave} - ${nombre}`}/>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4">
                                        <Tooltip title="Guardar" placement="left-start">
                                            <Button variant="contained" className="btn-w50 btn-color float-right" onClick={() => actualizarDatosPlantilla()}> 
                                                <SaveIcon/>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <form id="margenesDocto" className="row">
                                    <div className="col">
                                        <InputValidation
                                            title="Margen Izquierdo (cm)" refValue={margenIzquierdoRef}
                                            type="marginID" placeholder="Margen Izquierdo" maxLength={5}
                                            defaultValue={(margenIzq) ? parseFloat(margenIzq).toFixed(2) : '2.00'}
                                            onChangeExecute={verificaMID}
                                            tooltipText="Margen Izquierdo (cm)Máximo 2 números y dos decimales del 0.00 al 20.00. La suma de los margenes Izquierdo y Derecho puede ser hasta 20.00"
                                            onBlurDecimal={2} onlyNumbers="." required
                                        />
                                    </div>
                                    <div className="col">
                                        <InputValidation
                                            title="Margen Superior (cm)" refValue={margenSuperiorRef}
                                            type="marginSI" placeholder="Margen Superior" maxLength={5}
                                            defaultValue={(margenSup) ? parseFloat(margenSup).toFixed(2) : '2.00'}
                                            onChangeExecute={verificaMSI}
                                            tooltipText="Máximo 2 números y dos decimales del 0.00 al 26.00. La suma de los margenes Superior e Inferior puede ser hasta 26.00"
                                            onBlurDecimal={2} onlyNumbers="." required
                                        />
                                    </div>
                                    <div className="col">
                                        <InputValidation
                                            title="Margen Inferior (cm)" refValue={margenInferiorRef}
                                            type="marginSI" placeholder="Margen Inferior" maxLength={5}
                                            defaultValue={(margenInf) ? parseFloat(margenInf).toFixed(2) : '2.00'}
                                            onChangeExecute={verificaMSI}
                                            tooltipText="Máximo 2 números y dos decimales del 0.00 al 26.00. La suma de los margenes Superior e Inferior puede ser hasta 26.00 cm."
                                            onBlurDecimal={2} onlyNumbers="." required
                                        />
                                    </div>
                                    <div className="col">
                                        <InputValidation
                                            title="Margen Derecho (cm)" refValue={margenDerechoRef}
                                            type="marginID" placeholder="Margen Derecho" maxLength={5}
                                            defaultValue={(margenDer) ? parseFloat(margenDer).toFixed(2) : '2.00'}
                                            onChangeExecute={verificaMID}
                                            tooltipText="Máximo 2 números y dos decimales del 0.00 al 20.00. La suma de los margenes Izquierdo y Derecho puede ser hasta 20.00 cm."
                                            onBlurDecimal={2} onlyNumbers="." required
                                        />
                                    </div>
                                </form>
                                {(errorMSI) && <AlertForm message='La suma de los margenes Superior e Inferior no puede exceder de 26.00 cm.'/>}
                                {(errorMID) && <AlertForm message='La suma de los margenes Izquierdo y Derecho no puede exceder de 20.00 cm.'/>}
                            </div>
                        </CardContent>
                        {   (content === false)
                            ?   <SpinnerOval />
                            :   <EditorHTML content={content} setContent={setContent}/>
                        }
                    </Card>
                </div>
                <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-1">
                    <Card className={classes.root}>
                        <CardContent className={classes.content}>
                            <div className="row">
                                <TitleCard title="Variables"/>
                                <SubtitleCard subtitle="Haga clic en la variable del empleado que desea incluir en la plantilla y péguela en el texto del documento donde deba aparecer su valor."/>
                            </div>
                            <Variables asignarVariable={asignarVariable}/>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <AlertSyE show={openAlertSuccess} setOpenAlertSyE={setOpenAlertSuccess} title="Actualizar Plantilla" text={registro_actualizado} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Actualizar Plantilla" text={textError} textbtnconfirm="Aceptar" type="error"/>
        </Fragment>
    );
}
 
export default EditPlantilla;