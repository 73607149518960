import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_plantillas } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertForm from '../../../../../../Services/AlertForm';
import { Tooltip } from '@material-ui/core';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Variables = ({ asignarVariable }) => {

    const [variables, setVariables] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupohit_plantillas}/variables/`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setVariables(res.data.dataResponse);
                    setError(null);
                    break;
                default:
                    setError(<AlertForm message={res.data.msg}/>);
                break;
            }
        })
        .catch(error => setError(<AlertForm message="Error"/>));
    }, []);

    return (
        <Fragment>
        {
            (!error)
            ?   (variables.length)
                ?   <div className="row row-cols-2 ml-auto mr-auto">
                    {
                        variables.map((variable, key) => (                                   
                            <Tooltip key={key} title={variable.variableDescrip}>
                                <div id={variable.id} className="col variable-celda" onClick={() => asignarVariable(variable.variableAtributo)}>
                                    {variable.variableDescrip}
                                </div>
                            </Tooltip>
                        ))
                    }
                    </div>
                :   <SpinnerOval />
            :   { error }
        }
        </Fragment>
    );
}
 
export default Variables;