import React, { Fragment, useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteIndicadorTab = ({ idModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const [openLoading, setOpenLoading] = useState(false);

    const axiosEliminar = () => {
        setOpenLoading(true);
        var info={indicadores : [parseInt(elementSelect)]};
        var headers= headersAuth();
        var payload= {"Content-Type": headers['headers']["Content-Type"],"X-Authorization":headers['headers']["X-Authorization"]};
        
        Axios.delete(`${servicios_grupohit}/org/tab/`, {headers: payload, data: info }) 
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_eliminado) )
        .finally(() => setOpenLoading(false));
    }

    return(
        <Fragment>
            <ModalLoading nombreModulo={idModulo} open={openLoading} setOpen={setOpenLoading} />
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
        </Fragment>
    );
}

export default ModalDeleteIndicadorTab;