import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { cerrarSession, isAuthenticaded } from '../../Services/AuthService';
import { sao } from '../../Constants/Constants';

class LoginComposicion extends Component {
    /* ---------------------------------------------------------------------- */
    componentDidMount() {
        if( isAuthenticaded() ) {
            (sao)
            ?   this.props.history.push("/sao/usuario")
            :   this.props.history.push("/usuario")
        } else {
            cerrarSession();
        }
    }
    /* ---------------------------------------------------------------------- */
    render() {
        return ( <React.Fragment> {this.props.children} </React.Fragment> );
    }
}

export default withRouter( LoginComposicion );