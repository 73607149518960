import React, { useState, createRef } from 'react';
import { validarSubmit, inputDateToLong, longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import ModalService from '../../../ModalService/ModalService';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import Button from '@material-ui/core/Button';

const ModalReprocesar = ({ nombreModulo, modalIsOpen, setModalIsOpen, reprocesar, item }) => {

    const [openAlertSyESuccess, setOpenAlertSyESuccess] = useState(false);

    const fechaPagoRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        if(validarSubmit('FormCambiaFechaNomina')) {
            setOpenAlertSyESuccess(true);
        }
    };

    return (
        <ModalService
            title={`Reprocesar ${item.tipoAcumuladoNombre}`}
            parentNode={nombreModulo}
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            minWidth="55%"
            maxWidth="55%"
        >
            <form id="FormCambiaFechaNomina">
                <InputDateValidation title="Fecha de Pago" refValue={fechaPagoRef} defaultValue={longDateToInput(item.fechaPago)} 
                    tooltipText="Seleccione una fecha del calendario" tooltipName="tooltipFPago" required/>
                <Button variant="contained" className="btn-color" onClick={mostrarAlert}>Aceptar</Button>
            </form>
            <AlertWarning show={openAlertSyESuccess} SetopenALert={setOpenAlertSyESuccess} text="¡Se actualizará la fecha de pago!" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={() => reprocesar({ id: item.tipoAcumuladoId, fecha: (fechaPagoRef.current) ? inputDateToLong(fechaPagoRef.current.value) : ''})}/>
        </ModalService>
    );
}

export default ModalReprocesar;