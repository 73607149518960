import React, { createRef, useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, amountToFloat, floatToAmount } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';   
import { servicios_grupohit_catalogo,registro_guardado, registro_sera_actualizado, servicios_grupohit_nomina, verifique_conexion} from '../../../../../../Constants/Constants'; 
import Axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonSalarioMinUMA, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel'; 
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import AlertForm from '../../../../../../Services/AlertForm';
import { obtenerModalAdd, obtenerModalEdit } from '../../../DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; 
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
}); 

const FormularioIndicadores = ({ error, setError, enviarIndicador, elementSelect, idModulo, actualizarIndicador, informacion, informacionAdicional, indicadoresGeneralesAll, isLoading }) => { 
    
    const [ errorAuto, setErrorAuto ] = useState( null );
    
    const nombreReciboRef = createRef(false); 
    const prioridadRef = createRef(''); 
    const rutinaCalcRef = createRef(''); 
    const calcSobreRef = createRef('');
    const importeRef = createRef(0);
    const topeVecesRef = createRef(0);  
    const partidaCuentaRef = createRef('');  

    const [ apareceRecib , setApareceRecib ]= useState( elementSelect && (elementSelect.nomRecibo === 'Sí' || elementSelect.nomRecibo === 'SÍ') ? true : false );
    const [ dataConfigBase, setDataConfigBase ] = useState(null);
    const [ indicadorAuto, setIndicadorAuto ] = useState( null );
    const [ indicadorAutoExento, setIndicadorAutoExento ] = useState( null ); 
    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null);
    const [ infoIndicador , setInfoIndicador ]= useState(elementSelect ? elementSelect : null );
    const [ rutinas , setRutinas ]= useState(null);
    const [ updateIndicadorParametros, setUpdateIndicadorParametros ] = useState(false);
    const [ changeIndicador, setChangeIndicador ] = useState(false);
    const [ idParametroAEliminar, setIdParametroAEliminar ] = useState(null);

    const [ exentoReq , setExentoReq ]= useState(false);
    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [ montoZona , setMontoZona ]= useState(1);

    const [ openAdd, setOpenAdd ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState(''); 
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ defValIndicador, setDefIndicador ] = useState(null);
    const [ idIndicadorParametro, setIdIndicadorParametro ] = useState(null);
    const [ defValTopVec, setDefValTopVec ] = useState(null);
    const [ defValCalcSob, setDefCalcSob ] = useState(null); 
    const [ defValMonto, setDefValMonto ] = useState(null);
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ claveIndicadorGeneral, setClaveIndicadorGeneral ] = useState(elementSelect ? elementSelect.nomClaveIndicador : '');

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    const sMinimosF = ArrayJsonSalarioMinUMA(res.data.dataResponse).filter(item => item.fechaFinInp === null);
                    setSalarioMinimos(sMinimosF); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            } 
        })
        .catch(error => errorTable(verifique_conexion));
        
        Axios.get(`${servicios_grupohit_nomina}/indicadores/getindicadoresfaltantesnomina/${informacion.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    setIndicadoresGenerales(ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse));
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        Axios.get(`${servicios_grupohit_catalogo}/rutina/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    setRutinas( res.data.dataResponse );
                    break;
                case 404:
                    setRutinas([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion)); 

            if(elementSelect){ 
                Axios.get(`${servicios_grupohit_nomina}/indicadores/parametros/getall/${elementSelect.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const resultado = res.data.dataResponse[0]; 
                            let calcSobreNew = informacionAdicional.filter( element => (element.zonaId === resultado.zona) && element.fechaFinInp === null );
                            setDefValMonto( floatToAmount(parseFloat(resultado.topVeces) * amountToFloat(calcSobreNew[0].monto)) );
                            setIdIndicadorParametro( resultado.id );
                            setIdParametroAEliminar( resultado.id );
                            setDefValTopVec( resultado.topVeces );
                            setDefCalcSob(calcSobreNew[0].zonaId);
                            setMontoZona(amountToFloat(calcSobreNew[0].monto)); 
                            let indicadorJson = indicadoresGeneralesAll.filter( element => element.id === resultado.nomindIndicador)[0];
                            setIndicadorAutoExento(indicadorJson);
                            setIndicadorAuto(indicadorJson.id); 
                            setDefIndicador(indicadorJson); 
                            setUpdateIndicadorParametros(true);
                            setExentoReq( true ); 
                            break;
                        case 404:
                            setUpdateIndicadorParametros(false);
                            break;
                        default:
                            setUpdateIndicadorParametros(false);
                            break;
                    }
                })
                .catch(error => setUpdateIndicadorParametros(false)); 
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    
    const getExentoRequ = (id)=>{

        if(id!==null){
            Axios.get(`${servicios_grupohit_catalogo}/indicadores/parametros/${id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setChangeIndicador(true);
                            let response= res.data.dataResponse[0];
                            let calcSobreNew= informacionAdicional.filter( element => (element.zonaId === parseInt(response.zona.id)) && element.fechaFinInp === null);
                            setDefValMonto( floatToAmount( parseFloat(response.topeveces) * amountToFloat( calcSobreNew[0].monto ) ) );
                            setDefValTopVec( response.topeveces );
                            setDefCalcSob( calcSobreNew[0].zonaId );
                            let indicadorJson= indicadoresGeneralesAll.filter( element => element.id === response.indicador)[0];
                            setIndicadorAutoExento(indicadorJson); 
                            setIndicadorAuto(id); 
                            setDefIndicador(indicadorJson); 
                            setExentoReq( true );
                            break;
                        default:
                            break;
                    }
                })
                .catch(error => errorTable(verifique_conexion));
        }else{
            setUpdateIndicadorParametros(false); 
            setDefValMonto( null );
            setIdIndicadorParametro( null );
            setDefValTopVec( null );
            setDefCalcSob( null ); 
            setIndicadorAutoExento(null); 
            setIndicadorAuto(null); 
            setDefIndicador(null); 
            setExentoReq( false );
        }
        
    }

    const getInfoIndicador = ( ref ) => {
        setError(null);
        if( ref !==null){

            let indicadorIndex = indicadoresGenerales.findIndex( (element) => element.id === parseInt(ref.id));
            if(indicadorIndex!==-1){

                setClaveIndicadorGeneral(indicadoresGenerales[indicadorIndex].indicadorClave)
                nombreReciboRef.current.value=indicadoresGenerales[indicadorIndex].indicadorNom;
                rutinaCalcRef.current.value=indicadoresGenerales[indicadorIndex].rutinaId === null ? "" : parseInt(indicadoresGenerales[indicadorIndex].rutinaId.id);
                prioridadRef.current.value=indicadoresGenerales[indicadorIndex].prioridad;
                setApareceRecib(indicadoresGenerales[indicadorIndex].recibo); 
                getExentoRequ(ref.id);
                let info= {indicadorClave: indicadoresGenerales[indicadorIndex].indicadorClave,
                    indicadorNom: indicadoresGenerales[indicadorIndex].indicadorNom,
                    relacionLabNombre: indicadoresGenerales[indicadorIndex].relacionLabNombre,
                    tipoNatuNom: indicadoresGenerales[indicadorIndex].tipoNatuNom,
                    indicadorSat: indicadoresGenerales[indicadorIndex].indicadorSat,
                    clasificaNom: indicadoresGenerales[indicadorIndex].clasificaNom,
                }

                setIndicadorAuto(ref.id); 
                setInfoIndicador(info);
            }else{
                setInfoIndicador(null);
            } 
        }else{
            setInfoIndicador(null);
            nombreReciboRef.current.value="";
            rutinaCalcRef.current.value ="";
            prioridadRef.current.value = "";
            setApareceRecib(false);
            setIndicadorAuto(null);
            getExentoRequ(null);
        } 
        
    };

    const deleteOptionals =( objectRequest )=>{
        var temporal = objectRequest;  
        Object.keys( temporal ).forEach( key => { 
            if( temporal[key] === " " ){
                delete temporal[key];
            }
        });
        return temporal;
    }
 
    const enviar = (evt) => {
        evt.preventDefault();
        
        if( validarSubmit( 'FormConceptosNomina' ) ) { 
            if(indicadorAuto === null) {
                setErrorAuto(<AlertForm message="Complete los campos" />); 
                return;
            }else{
                let request = {
                    nominaId: informacion.id,
                    indicadorId: parseInt(indicadorAuto),
                    nominClave: infoIndicador.indicadorClave,
                    nominIndicador: nombreReciboRef.current.value,
                    rutinaId: parseInt(rutinaCalcRef.current.value),
                    prioridad: parseInt(prioridadRef.current.value),
                    nominaRecibo: (apareceRecib===null) ? false : apareceRecib,
                };

                if(partidaCuentaRef.current.value.trim() !== '') request.nominAlias = partidaCuentaRef.current.value.trim();
                
                var indicadorParametros=null; 
                
                if(exentoReq){
                    if(indicadorAutoExento!==null && indicadorAutoExento!==undefined){
                        const indicador = indicadorAutoExento.id;
                        const zonaId = salarioMinimos.find(item => item.zonaId === parseInt(calcSobreRef.current.value)).zonaId;
                        const topVeces = parseInt( topeVecesRef.current.value );
                        const nomindId= informacion.id;
                        indicadorParametros={ nomindId, indicador, zonaId, topVeces };
                    }else{
                        error=true;
                    }
                } 
                
                const dataConf=[];
                for (const key in dataConfigBase) {
                    dataConf.push(deleteOptionals(dataConfigBase[key]));
                } 
                if(!error) {
                    enviarIndicador(request, dataConf.length>0 ? { basesIndicadores: [dataConf] } : null, indicadorParametros );
                }else{
                    setErrorAuto(<AlertForm message="Complete los campos" />);
                }
            }
        }
        
    }

    const actualizar = () => {
        if( validarSubmit( 'FormConceptosNomina' ) ){
            
            const request = {
                id: elementSelect.id,
                nominClave: elementSelect.nomClaveIndicador,
                nominIndicador: nombreReciboRef.current.value,
                rutinaId: parseInt(rutinaCalcRef.current.value),
                prioridad: parseInt(prioridadRef.current.value),
                nominaRecibo: (apareceRecib===null) ? false : apareceRecib,
                nominAlias: partidaCuentaRef.current.value.trim()
            };
            

            let indicadorParametros=null;
            let eliminarParametroId = null;
            let error = false;

            if(exentoReq){
                if(indicadorAutoExento!==null && indicadorAutoExento!==undefined){
                    const indicador = indicadorAutoExento.id;
                    const zonaId = salarioMinimos.find(item => item.zonaId === parseInt(calcSobreRef.current.value)).zonaId;
                    const topVeces = parseInt( topeVecesRef.current.value );
                    indicadorParametros = { indicador, zonaId, topVeces };
                    if(updateIndicadorParametros){
                        indicadorParametros.id = idIndicadorParametro;
                    }
                }else{
                    error=true;
                }
            }else if(idParametroAEliminar!==null){
                eliminarParametroId = idParametroAEliminar;
            }
            if(!error){
                actualizarIndicador(request, indicadorParametros, updateIndicadorParametros,  eliminarParametroId);
            }else{
                setErrorAuto(<AlertForm message="Complete los campos" />);
            }
        }
    } 
    
    const indicadorAutocomplete = valor => {
        setIndicadorAutoExento(valor);
    }
    const updateTopeVeces = ( value ) => {               
        importeRef.current.value = value!=='' ? floatToAmount( parseFloat(value) * montoZona, 2 ) : floatToAmount( 0, 2 ) ;
    }

    const openConfig = (e) => {
        e.preventDefault();
        setOpenAdd(true);
    }
    const saveConfig = ( data ) =>{
        if(data===null){
            setTextAlert( 'Registro Actualizado' );
            setAlertSuccess( true );     
        }else{ 
            setTextAlert( registro_guardado );
            setAlertSuccess( true ); 
            setDataConfigBase( data );
        }

    } 
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }

    const successTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }

    const getMontoZona = ( zona  ) => {  
        var montoZon=0;  
        let calcSobreNew= salarioMinimos.filter( element => element.zonaId === parseInt(zona));
        if(calcSobreNew.length>0){
            montoZon=amountToFloat(calcSobreNew[0].monto);
            setMontoZona(montoZon); 
        }else{
            setMontoZona(0);
        } 
        importeRef.current.value= topeVecesRef.current.value !=='' ? floatToAmount( parseFloat(topeVecesRef.current.value) * montoZon, 2 ) : floatToAmount( 0, 2 ) ; 
    }
    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };
    
    return (
        <Fragment>
            <form id="FormConceptosNomina" onSubmit={elementSelect ? mostrarAlert : enviar}>
                {(!elementSelect)
                ?   <div className="row">
                        <div className="col-md-12 col-sm-12">
                        {(indicadoresGenerales)
                        ?   <AutocompleteComponent
                                id="cPresupuestal"
                                title="Concepto"
                                options={indicadoresGenerales}
                                action={getInfoIndicador}
                                optionListView="indicadorNomClve"
                                placeholder="Concepto"
                                size="100%"
                                tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                tooltipName="tooltipAutoIndGral"
                                required
                            />
                        :   null
                        }        
                        </div>
                    </div>
                :   null
                }
                {(infoIndicador && elementSelect)
                ?   <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <strong>Clave: </strong><label>{elementSelect ? elementSelect.nomClaveIndicador : infoIndicador.indicadorClave}</label><br/>
                            <strong>Nombre: </strong><label>{elementSelect ?  indicadoresGeneralesAll ? indicadoresGeneralesAll.find(element => element.indicadorClave===elementSelect.nomClaveIndicador).indicadorNom: '': infoIndicador.indicadorNom}</label><br/>
                            <strong>Relación Laboral: </strong><label>{elementSelect ? elementSelect.relacionLaboral :  infoIndicador.relacionLabNombre}</label><br/>
                            <strong>Naturaleza: </strong><label>{ elementSelect ? elementSelect.nomNaturaleza :  infoIndicador.tipoNatuNom}</label><br/>
                            <strong>Indicador SAT: </strong><label>{elementSelect ? elementSelect.indicadorSat :  infoIndicador.indicadorSat}</label><br/>
                            <strong>Clasificación SAT: </strong><label>{elementSelect ? elementSelect.clasificaSat :  infoIndicador.clasificaNom}</label><br/> 
                        </div>
                    </div>
                :   null
                }
                <hr/>  
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Nombre Recibo" type="nombreRecibo" placeholder="Escriba el nombre del recibo"
                            tooltipText={`Máximo 64 letras, números, espacios y/o signos de puntuación . , - ( ) " ' # % / @ * ; :`} maxLength={64}
                            refValue={nombreReciboRef} defaultValue={ elementSelect ? elementSelect.nomIndicador : '' } required
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                    {(rutinas)
                    ?   <SelectValidation title="Rutina de Cálculo"
                            arrayOpciones={rutinas} keyOpcion="rutinaNom" defaultValue={ elementSelect ? elementSelect.rutinaId : '' }
                            refValue={rutinaCalcRef} required
                        />
                    :   null
                    }
                    </div>
                </div> 
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Prioridad" type="prioridad" placeholder="Escriba el valor de la prioridad"
                            tooltipText={`Máximo 99999 números`} tooltipName={'tooltipPriorInd'} maxLength={99999}
                            refValue={prioridadRef} defaultValue={ elementSelect ? elementSelect.prioridad : '' } required onlyNumbers
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Partida / Cuenta" type="partidaCuenta" placeholder="Escriba la partida o cuenta"
                            tooltipText="Máximo 8 caracteres alfanuméricos" maxLength={8} optional
                            refValue={partidaCuentaRef} defaultValue={ elementSelect ? elementSelect.nominAlias : '' } onlyNumbers
                        />
                    </div> 
                </div>
                <div className="row mt-2 mb-1">
                    <div className="col">
                        <button onClick={e => openConfig(e) }  className="form-control btn-third">Bases</button>
                    </div>
                </div>
                <MuiThemeProvider theme={theme}> 
                    <div className="row">
                        <div className="col-md-6 col-sm-12"> 
                            <Checkbox  
                                onChange={()=>{setApareceRecib(!apareceRecib)}}
                                color="secondary"
                                checked={apareceRecib}
                            /> 
                            <label> Aparece en Recibo </label>
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                            <Checkbox 
                                onChange={()=>{setExentoReq(!exentoReq)}}
                                color="secondary"
                                checked={exentoReq}
                            />  
                            <label> Exento Requerido </label>
                        </div>
                    </div>
                </MuiThemeProvider>
                {(exentoReq)
                ?   <div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                            {(indicadoresGeneralesAll)
                            ?   <AutocompleteComponent
                                    id="IndicadoresGeneralesInGral"
                                    title="Concepto"
                                    options={indicadoresGeneralesAll.filter(element => element.indicadorClave !==claveIndicadorGeneral)}
                                    action={indicadorAutocomplete}
                                    optionListView="indicadorNomClve"
                                    placeholder="Concepto"
                                    size="100%"
                                    tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                    tooltipName="tooltipAutoIndGral"
                                    required 
                                    defaultValue={defValIndicador}
                                />
                            :   null
                            }    
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                            {(salarioMinimos)
                            ?   <SelectValidation title="Cálculo Sobre" arrayOpciones={salarioMinimos} keyId="zonaId" keyOpcion="zonaNom"
                                    refValue={calcSobreRef} required onChangeExecute={getMontoZona}
                                    defaultValue={ updateIndicadorParametros || changeIndicador ? defValCalcSob : '' }
                                />
                            :   null
                            }
                            </div>
                            <div className="col-md-6 col-sm-12"> 
                                <InputValidation title="Tope en Veces" type="topeVeces" placeholder="Escriba el Tope en Veces"
                                    tooltipText={'Máximo 999999 números'} tooltipName={'tooltipNomRecInd'} maxLength={999999}
                                    refValue={topeVecesRef} defaultValue={ updateIndicadorParametros || changeIndicador ? defValTopVec : '' } required onChangeExecute={updateTopeVeces}
                                />
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <InputValidation title="Importe"  type="monto"
                                    tooltipText={'Importe'} tooltipName={'tooltipImpo'} refValue={ importeRef }
                                    defaultValue={ updateIndicadorParametros || changeIndicador ? defValMonto : '$0.00' }
                                    disabled optional
                                />
                            </div>
                        </div> 
                    </div>
                :   null 
                } 
                {error}
                {errorAuto}
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            { !elementSelect ? obtenerModalAdd( idModulo+"config", openAdd, setOpenAdd, saveConfig, informacion, errorTable, successTable, dataConfigBase ) : null }
            { elementSelect ? obtenerModalEdit( idModulo+"config", openAdd, setOpenAdd, elementSelect, saveConfig, null, errorTable, successTable, dataConfigBase ) : null }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
        </Fragment>
    );
};

export default FormularioIndicadores;