import React from 'react';
import ModalService from '../../../../ModalService/ModalService';
import { control_pagos_unicos } from '../../../../../../../Constants/Constants';
import MovimientosExcel from '../../Variables/DataTableMovimientos/MovimientosExcel';

const ModalPagosUnicosExcel = ({ openAdd, setOpenAdd, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const cerrarModal = () => {
        setOpenAdd( false );
    }

    return (
        <ModalService title={`Carga Masiva de ${control_pagos_unicos}`} parentNode={informacionAdicional.parentNode} minWidth="33%" maxWidth="35%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <b>Descarga</b>
                <div>1. Seleccione la nómina.</div>
                <div>2. Baje el archivo.</div>
                <div>3. Realice los cambios necesarios en el archivo descargado.</div>
                <br/>
                <b>Carga</b>
                <div>1. Busque el archivo con extensión .csv.</div>
                <div>2. Una vez que el sistema lo analice:</div>
                <div>Si no se presentan incidencias, haga la carga. De lo contrario, modifique el archivo y repita el procedimiento.</div>
                <hr/>

                <MovimientosExcel
                    idModulo={nombreModulo}
                    errorTable={errorTable}
                    successTable={successTable}
                    informacion={informacionAdicional}
                    nombreModulo={control_pagos_unicos}
                    cerrarModal={cerrarModal}
                />
        </ModalService>
    );
};

export default ModalPagosUnicosExcel;