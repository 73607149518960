import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadores, ArrayJsonSalarioMinUMA, ArrayJsonIndicadoresByNomina } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormularioIndicadores from './FormularioIndicadores';

const ModalEditIndicadores = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo, informacionAdicional}) => {
    const [ error, setError ] = useState( null );
    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [ indicadoresGeneralesAll, setIndicadoresGeneralesAll ] = useState(null);
    const [isLoading, setIsLoading] = useState(false); 

    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    useEffect(() => {
        axios.get(`${servicios_grupohit_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            } 
        })
        .catch(error => errorTable(verifique_conexion));

        axios.get(`${servicios_grupohit_nomina}/indicadores/getbynomina/${informacionAdicional.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresByNomina(res.data.dataResponse);
                    setIndicadoresGeneralesAll(indicadores);
                    break;
                case 404:
                    setIndicadoresGeneralesAll([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
          // eslint-disable-next-line
    },[]); 

    const actualizarIndicador = ( indicador, indicadorParametros, shouldUpdateIndParamet, eliminarParametro ) => {
        setIsLoading(true);
        axios.put( `${servicios_grupohit_nomina}/indicadores/`, indicador, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        const nuevoIndicador = JsonIndicadores(respuesta.data.dataResponse)
                        if(shouldUpdateIndParamet && eliminarParametro===null){
                            actualizarIndicadorParametros( nuevoIndicador.id, indicadorParametros );
                        }else{
                            if(indicadorParametros!==null)
                            enviarIndicadorParametros(nuevoIndicador.id, indicadorParametros);
                        }
                        if(eliminarParametro!==null){
                            eliminarParametroNomina(eliminarParametro);
                        }
                        editar( nuevoIndicador );
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                //errorTable( cambios_no_guardados );
                errorTable(verifique_conexion);
            })
            .finally(() => setIsLoading(false));
    }

    const eliminarParametroNomina = async ( idParametro ) =>{
        await axios.delete( `${servicios_grupohit_nomina}/indicadores/parametros/${idParametro}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200: 
                        break;
                    default:
                        errorTable( respuesta.data.status+" en Parametros Conceptos" );
                        break;
                }
            })
            .catch( error => {
                errorTable(verifique_conexion);
            })
    }

    const enviarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {nomindIndicador:indicadorParametros.indicador , nomindId: idIndicador, zonaId: indicadorParametros.zonaId, topVeces: indicadorParametros.topVeces };
        
        await axios.post( `${servicios_grupohit_nomina}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion)); 
    }

    const actualizarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {id:indicadorParametros.id, nomindIndicador: indicadorParametros.indicador, zonaId: indicadorParametros.zonaId, topVeces: indicadorParametros.topVeces };
        
        await axios.put( `${servicios_grupohit_nomina}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    }

    return (
        <ModalService title="Actualizar Concepto por Nómina" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioIndicadores
                    error={error}
                    informacion={informacionAdicional}
                    elementSelect={elementSelect}
                    actualizarIndicador={actualizarIndicador}
                    informacionAdicional={salarioMinimos}
                    idModulo={nombreModulo}
                    setError={setError}
                    indicadoresGeneralesAll={indicadoresGeneralesAll}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalEditIndicadores;