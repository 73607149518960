import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { servicios_grupohit_rh, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import ConvertDate from '../../../../Helpers/ConvertDate';
import { amountToFloat, inputDateToLong, longDateToInput } from '../../../../../../../Services/Validation/HelperValidation';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';

import FormAgregarPagosPrestamo from './FormAgregarPagosPrestamo';
import FormFinalizaPrestamos from './FormFinalizaPrestamos';

const ModalFinalizaPrestamos = ({ id, openModalForm, setOpenModalForm, elementSelect, editar, errorTable, informacionAdicional, nominasRL }) => {
    
    const [ error, setError ] = useState( null );
    const [isLoading, setIsloading] = useState(false);

    const { empleado, montoLiquido, setMontoLiquido } = informacionAdicional;

    const cerrarModal = () => {
        setOpenModalForm( false );
        setError( null );
    }

    const cerrarPrestamo = ( fechaFinPrestamo ) => {
        setIsloading(true);
        axios.put( `${servicios_grupohit_rh}/empleado/prestamos/close/${elementSelect.id}`, fechaFinPrestamo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const nominaEmp = nominasRL.find(i => i.nomina === empleado.nominaId);
                        let elementAux = elementSelect;
                        if(fechaFinPrestamo.fechaFin) {
                            if(nominaEmp && (fechaFinPrestamo.fechaFin < nominaEmp.periodoFecini)) {
                                if(elementAux.numPago < elementAux.numeroPagos) setMontoLiquido(montoLiquido + amountToFloat(elementSelect.importe));
                            }
                        } else {
                            if(nominaEmp && (inputDateToLong(elementSelect.fechaFin) < nominaEmp.periodoFecfin)) {
                                //validar numeros de pagos
                                if(elementAux.numPago < elementAux.numeroPagos) setMontoLiquido(montoLiquido - amountToFloat(elementSelect.importe));
                            }
                        }
                        elementAux.calculo = true;
                        elementAux.fechaFin = respuesta.data.dataResponse.fechaFin ? longDateToInput(respuesta.data.dataResponse.fechaFin) : null;
                        elementAux.fechaFinTab = respuesta.data.dataResponse.fechaFin ? ConvertDate(longDateToInput(respuesta.data.dataResponse.fechaFin)) : '-';
                        editar( elementAux );
                        cerrarModal();
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
            .finally(() => setIsloading(false));
    }

    const agregarPagos = ( pagos ) => {
        axios.post( `${servicios_grupohit_rh}/empleado/prestamos/add/pagos/`, pagos, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        let elementAux = elementSelect;
                        elementAux.numPago = respuesta.data.dataResponse[0].numPago;
                        elementAux.numeroPagosPagados = `${respuesta.data.dataResponse[0].numPago} / ${respuesta.data.dataResponse[0].empprestNumPagos}`;
                        const nominaEmp = nominasRL.find(i => i.nomina === empleado.nominaId);
                        if(nominaEmp) {
                            if((elementAux.fechaFin > nominaEmp.periodoFecini) || (elementAux.fechaFin === null && elementAux.fechaIni < nominaEmp.periodoFecfin)) {
                                if(respuesta.data.dataResponse[0].numPago >= respuesta.data.dataResponse[0].empprestNumPagos) {
                                    setMontoLiquido(montoLiquido+amountToFloat(elementSelect.importe));
                                }
                            }
                        }
                        editar( elementAux );
                        cerrarModal();
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <React.Fragment>
            { elementSelect.agregar ?
                <ModalService title={elementSelect.agregar ? 'Agregar Pago' : 'Finalizar Préstamo'} parentNode={id} minWidth="35%" maxWidth="35%"
                    isOpen={ openModalForm } onClose={ () => cerrarModal() } >
                        <div> <b> Folio: </b> {elementSelect.folio} </div>
                        <div> <b> Concepto: </b> {elementSelect.indicadorNombre} </div>
                        <div> <b> Número de Pagos: </b> {elementSelect.numeroPagosPagados} </div>
                        <div> <b> Importe Pagos: </b> {elementSelect.importe} </div>
                        <div> <b> Fecha Inicio de Préstamo: </b> {elementSelect.fechaIniTab} </div>
                        <hr/>
                            <FormAgregarPagosPrestamo
                                error={error}
                                setError={setError}
                                agregarPagos={agregarPagos}
                                elementSelect={elementSelect}
                            />
                </ModalService>
                :
                elementSelect.fechaFinTab !== '-' ?
                    <Alertwarning show={openModalForm} SetopenALert={setOpenModalForm} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar"
                        text="El préstamo sera reactivado" action={() => cerrarPrestamo({})} />
                    :
                    <ModalService title={elementSelect.agregar ? 'Agregar Pago' : 'Finalizar Préstamo'} parentNode={id} minWidth="35%" maxWidth="35%"
                        isOpen={ openModalForm } onClose={ () => cerrarModal() } >
                            <div> <b> Folio: </b> {elementSelect.folio} </div>
                            <div> <b> Concepto: </b> {elementSelect.indicadorNombre} </div>
                            <div> <b> Número de Pagos: </b> {elementSelect.numeroPagosPagados} </div>
                            <div> <b> Importe Pagos: </b> {elementSelect.importe} </div>
                            <div> <b> Fecha Inicio de Préstamo: </b> {elementSelect.fechaIniTab} </div>
                            <hr/>
                                <FormFinalizaPrestamos
                                    error={error}
                                    setError={setError}
                                    cerrarPrestamo={cerrarPrestamo}
                                    isLoading={isLoading}
                                    elementSelect={elementSelect}
                                />
                    </ModalService>
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
    empleadosAll: state.EmpleadosReducer.empleadosAll,
    openMenu: state.SystemReducer.openMenu,
    nominasRL: state.PeriodoActualReducer.nominasRL,
});

export default connect(mapStateToProps)(ModalFinalizaPrestamos);
