import React from 'react';
import { csvDownload } from '../../../../../../../Services/Validation/HelperValidation';

const adjuntarErrores = ( arrayError ) => {
    const arrayErrors = [];

    arrayError.forEach( error => {
        Object.keys(error).forEach( errorKey => {
            if( Array.isArray( error[errorKey] ) ){
                error[errorKey].forEach( element => {
                    arrayErrors.push( element );
                })
            }
        })
    });

    return arrayErrors;
}

const AlertErrorDescarga = ({ data, fileName }) => {
    const decargarErroresExcel = () => {
        if( fileName === 'Error_Proyectos' ){
            csvDownload( adjuntarErrores( data.dataResponse ), `${fileName}.csv` );
        }else{
            csvDownload( data.dataResponse, `${fileName}.csv` );
        }
    }

    return (
        <React.Fragment>
            { Array.isArray(data.dataResponse) ?
                <div className="alert alert-danger alert_form col-12" role="alert">
                    La carga no pudo completarse, <span className="subrayado link" onClick={() => decargarErroresExcel()}>revisar errores</span>
                </div>
                :
                <div className="alert alert-danger alert_form col-12" role="alert">
                    { data.msg }
                </div>
            }
        </React.Fragment>
    );
};

export default AlertErrorDescarga;