import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import './Styles.css';

const AlertTitle = ({ open, setOpen, title, text, textBtnConfirm, type, action, customIcon }) => {
    const confirmAlert = () => {
        setOpen( false );
        if( action ){
            action();
        }
    }

    return (
        open &&
        <SweetAlert
            success={false}
            title={
                <React.Fragment>
                    <div className={`alertIconTitle ${ type === 'error' ? 'colorIconTitleError' : 'colorIconTitleSuccess' }`}>
                        {customIcon && customIcon}
                    </div>
                    <span>{title}</span>
                </React.Fragment>
            }
            customButtons={
                <React.Fragment>
                    <button className="btn-color-alert" onClick={() => confirmAlert()}> {textBtnConfirm} </button>
                </React.Fragment>
            }
            onConfirm={() => confirmAlert()}
        >
            {text}
        </SweetAlert>
    );
};

export default AlertTitle;