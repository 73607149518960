import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../Media/Img/profile.jpg';

const UsersSearchComponent = ({ options, agregaChat }) => {
    return (
        <Autocomplete
            id="usersSearch"
            options={options.map(oprion => ({ 'username': oprion.username, 'datosUsuarioNom': oprion.datosUsuarioNom, 'datosUsuarioPat': oprion.datosUsuarioPat, 'datosUsuarioMat': oprion.datosUsuarioMat, 'img': oprion.img }))}
            getOptionLabel={ option => '' }
            noOptionsText="No existen coincidencias"
            size="small"
            clearText="Borrar"
            style={{ width: '90%', marginLeft: '4%', marginRight: '4%', marginTop: '1rem' }}
            renderOption={option => (
                                        <ListItem
                                            button
                                            key={option.username}
                                            style={{ width: '100%', marginTop: 0, padding: 0 }}
                                            onClick={ () => agregaChat(option) }
                                        >
                                            <ListItemAvatar style={{ margin: 0 }}>
                                                {               
                                                    option.img !== ''
                                                    ? <Avatar style={{ margin: 0 }} alt="avatar" src={`data:image/jpeg;base64, ${option.img}`}/>
                                                    : <Avatar style={{ margin: 0 }} alt="avatar" src={profileIMG}/>
                                                }
                                            </ListItemAvatar>
                                            <ListItemText style={{ margin: 0 }} primary={option.datosUsuarioNom} secondary={option.datosUsuarioPat}/>
                                        </ListItem>
                                    )}
            renderInput={params => <TextField {...params} label="Buscar contacto" variant="outlined" fullWidth/>}
        />
    );
}
 
export default UsersSearchComponent;