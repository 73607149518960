import React, { createRef, useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../Constants/Constants';
import {Button, Switch, createMuiTheme, Tooltip, MuiThemeProvider} from '@material-ui/core';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

const CheckboxCausaNombramiento = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const FormPuestos = ({ error, setError, enviarPuesto, elementSelect, actualizarPuesto, errorTable, isLoading }) => {
    
    const nivelesRef = createRef(''); 
    const puestoCveRef = createRef('');
    const puestoNomRef = createRef('');
    const puestoDescripRef = createRef('');
    const [toogle, setToogle] =useState(elementSelect ? (elementSelect.status==='ACTIVO' || elementSelect.status==='ACTIVA') ? true : false : false);
    const [openAlert, setOpenAlert] = useState(false);
    const [niveles, setNiveles] = useState(null); 
    const [causaNombramiento, setCausaNombramiento] = useState(false); 

    useEffect(() => {
        if(elementSelect) setCausaNombramiento(elementSelect.nombramiento);

        axios.get(`${servicios_grupohit_catalogo}/nvlaboral/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    let response=[];
                    res.data.dataResponse.map((resp)=> resp.status.id === 1 ? response.push(resp) :null )
                    setNiveles(response);
                    break;
                default:
                    setNiveles([]);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarPuesto')) {
            const nivlaboral = parseInt(nivelesRef.current.value); 
            const puestoCve = puestoCveRef.current.value;
            const puestoNom = puestoNomRef.current.value;
            const puestoDescrip = puestoDescripRef.current.value;
            enviarPuesto({ nivlaboral, puestoCve,  puestoNom, puestoDescrip, nombramiento: causaNombramiento });
        }
    };

    const actualizar = ( ) => { 
        if(validarSubmit('EditarPuesto')) {
            const nivlaboral = parseInt(nivelesRef.current.value);
            const puestoCve = puestoCveRef.current.value;
            const puestoNom = puestoNomRef.current.value;
            const puestoDescrip = puestoDescripRef.current.value;
            const status = toogle ? 1 : 2 ;
            setError(null);
            actualizarPuesto({ nivlaboral, puestoCve, puestoNom, puestoDescrip, status, nombramiento: causaNombramiento });
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const handleChangeCausaNombramiento = () => {
        setCausaNombramiento(!causaNombramiento);
    };

    return (
        <form id={ elementSelect ? 'EditarPuesto' : 'AgregarPuesto' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
            {(niveles)
            ?   <SelectValidation title="Clave Nivel Laboral" arrayOpciones={niveles} keyOpcion="nivlaboralClave" 
                    refValue={nivelesRef} defaultValue={elementSelect ?  elementSelect.nivlaboralId :''} required
                />
            :   null
            }
            {(elementSelect)
            ?   <div className="row">
                    <div className="col-md-8">
                    <InputValidation title="Clave del Puesto" type="clavePuesto" placeholder="Escriba la clave del puesto"
                        refValue={puestoCveRef} defaultValue={ elementSelect ? elementSelect.puestoCve: '' }
                        tooltipText={'Máximo 20 letras y/o números'} maxLength={20} required alfanumeric/>
                    </div>               
                    <div className="col-md-4">
                        <div className="form-group text-center">
                            <label>{`Estado: ${toogle ? 'Activo': 'Inactivo'}`}</label>
                            </div>
                            <div className="text-center">
                            <MuiThemeProvider theme={theme}>
                                <Tooltip title="Modificar Status">
                                    <Switch
                                        checked={toogle}
                                        onChange={  () => setToogle(!toogle) }
                                        value="checkedA"
                                        size="small"
                                    />
                                </Tooltip>
                            </MuiThemeProvider>
                        </div>  
                    </div>
                </div>
            :   <InputValidation title="Clave del Puesto" type="clavePuesto" placeholder="Escriba la clave del puesto"
                    refValue={puestoCveRef} defaultValue={ elementSelect ? elementSelect.puestoCve: '' } 
                    tooltipText={'Máximo 20 letras y/o números'} maxLength={20} required alfanumeric
                />
            }
            <InputValidation title="Nombre" type="nombrePuesto" placeholder="Escriba el nombre del puesto"
                refValue={puestoNomRef} defaultValue={ elementSelect ? elementSelect.puestoNom: '' } 
                tooltipText={`Máximo 256 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} maxLength={256}
                required
            />
            <InputValidation title="Descripción" type="descPuesto" placeholder="Escriba la descripción del puesto" fieldType="textareaShort"
                refValue={puestoDescripRef} defaultValue={ elementSelect ? elementSelect.puestoDescrip : '' } 
                tooltipText={`Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} maxLength={1024}
                optional
            />
            <div className="row">
                <div className="col">
                    <FormControlLabel
                        control={<CheckboxCausaNombramiento checked={causaNombramiento} onChange={handleChangeCausaNombramiento} name="checkedCausaNombramientoPuesto"/>}
                        label="Causa Nombramiento"
                    />
                </div>
            </div>
            {error}
            {isLoading
            ?   <SpinnerOval />
            :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </form>   
    );
}
export default FormPuestos;
