import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { registro_sera_eliminado, servicios_grupohit_rh, registro_no_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning'; 
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeletePoliticas = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const urls = [
        { dir: '/politica/aguinaldo/', nombre: 'Aguinaldo' },
        { dir: '/politica/prima/vacacional/', nombre: 'Prima Vacacional' },
        { dir: '/politica/dias/ajuste/', nombre: 'Días de Ajuste' },
        { dir: '/politica/vacaciones/', nombre: 'Vacaciones' },
        { dir: '/unificado/politica/ausentismo/', nombre: 'Ausentismos' }
    ];

    const axiosEliminar = () => {
        setIsLoading(true);
        const regex=/VA|PV|DA|AG|AU$/;
        const dir = urls.filter(element => element.nombre.toUpperCase()===elementSelect.tipo.toUpperCase())[0].dir;
        axios.delete( `${servicios_grupohit_rh}${dir}${elementSelect.id.replace(regex,'')}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    eliminar( elementSelect.id );
                    break;
                default:
                    errorTable( respuesta.data.msg );
                    break;
            }
        })
        .catch( error => {
            errorTable( registro_no_eliminado );
        })
        .finally(() => setIsLoading(false));
    }

    return (
        <Fragment>
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
        </Fragment>
    );
};

export default ModalDeletePoliticas;