import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import InformacionUsuarioAgregar from './AgregarUsuario/InformacionUsuarioAgregar';

import AlertForm from '../../../../../../Services/AlertForm';
import { eliminarSuperior } from './AsignacionModulos/CollapseMaterial/HelperModulos';

const ModalAddUsuarios = ({ openAdd, setOpenAdd, agregar, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    /* Funciones para validar el envio de modulos & nominas */
    const [ accesosMenu, setAccesosMenu ] = useState( [] );
    const [ accesosNominas, setAccesosNominas ] = useState( [] );
    const [ loading, setLoading ] = useState(false);

    const enviarUsuario = ( usuario, departamento ) => {
        setError( null );
        if( accesosMenu.length > 0 && accesosNominas.length > 0 ){
            enviarUsuarioService( usuario, departamento );
        }else{
            setError( <AlertForm message={ 'Asegúrate de haber seleccionado Modulos y Nóminas' } /> );
        }
    }

    /* Axios para agregar un nuevo usuario */
    const enviarUsuarioService = ( usuario, departamento ) => {
        setLoading(true);
        axios.post( `${servicios_grupohit_url}/new`, usuario, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setError( null );
                        enviarDepartamentoUsuario( respuesta.data.dataResponse, Object.assign(departamento, {userId: respuesta.data.dataResponse.id}) );
                        guardarAccesosMenu( respuesta.data.dataResponse.id );
                        guardarAccesosNomina( respuesta.data.dataResponse.id );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            });
    }

    const enviarDepartamentoUsuario = ( usuarioResponse, departamentoUsuario ) => {
        axios.post( `${servicios_grupohit_url}/deptorol/`, departamentoUsuario, headersAuth() )
            .then( respuesta => {
                agregar( usuarioResponse );
                setOpenAdd( false );
            })
            .catch( error => {
                agregar( usuarioResponse );
                setOpenAdd( false );
            })
            .finally(() => setLoading(false));
    }

    const guardarAccesosMenu = ( usuarioID ) => {
        axios.post( `${servicios_grupohit_url}/menu/${usuarioID}`, {accesosMenu: eliminarSuperior(accesosMenu)}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //successTable( 'Registro Actualizado' );
                        //setOpenAsign( false );
                        break;
                    default:
                        //errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                //errorTable( cambios_no_guardados );
            })
    }

    const guardarAccesosNomina = ( usuarioID ) => {
        axios.post( `${servicios_grupohit_url}/acceso/nomina`, { userId: usuarioID, nominas: accesosNominas }, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //successTable( 'Registro Actualizado' );
                        //setOpenNomina( false );
                        break;
                    default:
                        //errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                //errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Agregar Usuario" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <InformacionUsuarioAgregar
                    error={error}
                    informacion={informacionAdicional}
                    enviarUsuario={enviarUsuario}
                    errorTable={errorTable}
                    successTable={successTable}
                    idModulo={nombreModulo}

                    accesosMenu={accesosMenu}
                    setAccesosMenu={setAccesosMenu}
                    accesosNominas={accesosNominas}
                    setAccesosNominas={setAccesosNominas}
                    loading={loading}
                    setLoading={setLoading}
                />
        </ModalService>
    );
};

export default ModalAddUsuarios;