
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; 
import { iconosDrawer } from '../../MenuDrawer/Drawer/IconosDrawer'; //Importacion de Iconos 

const CardBusquedaAvanzada = ({ setOpenEmpleadosBusquedaAvanzada }) => {
     
    return (
        <React.Fragment>
            <Card className="cardBoard cardTablero" onClick={ ()=>{setOpenEmpleadosBusquedaAvanzada(true)}}>
                <CardContent>
                    <div className="icon">{iconosDrawer[ 'people' ]}</div>
                    <p className="name">{'Consultar Empleado'}</p> 
                </CardContent>
            </Card> 
        </React.Fragment>
    );
};
export default CardBusquedaAvanzada;