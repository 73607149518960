import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, sesiones_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import Alert from '../../../../../../Services/Alerts/AlertSyE';
import { ArrayJsonSesiones } from '../../../Helpers/JsonToOneLevel';

const Sesiones = ({ id, permisos }) => {

    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const [textError, setTextError] = useState(null);
    const [usuarios, setUsuarios] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupohit_url}/activos/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const usuarios = ArrayJsonSesiones(res.data.dataResponse);
                    setUsuarios(usuarios);
                    break;
                default:
                    setUsuarios([]);
                    setTextError(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setUsuarios([]);
            setTextError('Error en el servicio');
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                (usuarios)
                ?   <DataTableService id={id} title={sesiones_}
                        columns={[
                            { field: "nombre", title: "Nombre" },
                            { field: "paterno", title: "Primer Apellido" },
                            { field: "materno", title: "Segundo Apellido" },
                            { field: "username", title: "Usuario" },
                            { field: "ultimoAcceso", title: "Inicio de Sesión" },
                        ]}
                        data={usuarios}
                        permisos={permisos}
                        hideIconAdd hideIconDelete hideIconEdit
                        showIconWorkOff
                    />
                :   <SpinnerOval />
            }
            <Alert show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Sesiones" text={textError} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default Sesiones;