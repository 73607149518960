import React, { Fragment } from 'react';

const InfoNumerosEmergencia = ({ llem }) => {
    return (
        <Fragment>
             <div className="formRelLab">
                <div className="form-left">
                    <div><strong>Primer Apellido: </strong>{ llem.apellidoPat }</div>
                </div>
                <div className="form-right">
                    <div><strong>Segundo Apellido: </strong>{ llem.apellidoMat }</div>
                </div>
            </div>
            <div><strong>Nombre: </strong>{ llem.nombre }</div>
            <div className="formRelLab">
                <div className="form-left">
                    <div><strong>Teléfono Fijo: </strong>{ llem.telefonoFijo }</div>
                    <div><strong>Parentesco: </strong>{ llem.parentescoNom }</div>
                    <div><strong>Número Exterior: </strong>{ llem.numeroExt }</div>
                    <div><strong>Código Postal: </strong>{ llem.cp }</div>
                    <div><strong>Ciudad: </strong>{ llem.numEmerCiudad }</div>
                </div>
                <div className="form-right">
                    <div><strong>Teléfono Móvil: </strong>{ llem.telefonoCelular }</div>
                    <div><strong>Calle: </strong>{ llem.calle }</div>
                    <div><strong>Número Interior: </strong>{ llem.numeroInt }</div>
                    <div><strong>Colonia: </strong>{ llem.coloniaNom }</div>
                    <div><strong>Estado: </strong>{ llem.numEmerEstado }</div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default InfoNumerosEmergencia;