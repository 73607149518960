import React from 'react';

// Componentes de Redireccion
import { Router, Route, Switch } from 'react-router-dom';
import history from '../Services/History';
//Proteccion de Rutas y Componentes
import RouteProtected from './Login/RouteProtected';
import LoginComposicion from './Login/LoginComposicion';

// Importacion de Componentes
import Login from './Login/Login';
import AppUsuario from './Usuarios/Usuario/AppUsuario';
import Recuperar from './Recuperar/Recuperar';
import Registro from './Registro/Registro';
import { sao } from '../Constants/Constants';

//Estilos
import './style.css';

function AppPrincipal() {
    return(
        <Router history={history}>
                <Switch>
                    <Route exact path={(sao) ? "/sao/recuperar/:token" : "/recuperar/:token"} component={Recuperar} />
                    <Route exact path={(sao) ? "/sao/activacion/:token" : "/activacion/:token"} component={Registro} />
                    <Route exact path={(sao) ? "/sao/acceso" : "/acceso"} component={Login} />
                    <LoginComposicion>
                        <RouteProtected exact path={(sao) ? "/sao/usuario" : "/usuario"} component={ AppUsuario } />
                    </LoginComposicion>
                </Switch>
        </Router>
    );
}

export default AppPrincipal;