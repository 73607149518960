import React, { useState, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; 
import { Tooltip } from '@material-ui/core';
import { Add, InfoRounded, AssignmentInd, Delete, PictureAsPdf} from '@material-ui/icons';
import CardActions from '@material-ui/core/CardActions';
import axios from 'axios';
import { servicios_grupohit, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth, obtenerUsuario } from '../../../../../../Services/AuthService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { iconosDrawer } from '../../../MenuDrawer/Drawer/IconosDrawer';
import ModalFormulario from './ModalFormularioReporte';
import { descargaPDF, descargaXLS } from './Helpers/DescargaArchivo';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const ReportesAccesoDirecto = ({ id, reporte, setOpenDelete, setElementSelect, setOpenInfoReport, setTitle, openReport, icon, permisos, informacionAdicional, setOpenNewReportNomina, downloadReport, setDownloadReportDefinido, setInfoReport, reporteSuperior, setTipoReporte, muestraTabla, setMuestraTabla, consolidado, setIsConsolidado }) => {

    const dividos = permisos ? permisos.filter(element=> element!==1 && element):null;
    
    const [ downloading, setDownloading ] = useState(false);
    const [openModalFormulario, setOpenModalFormulario] = useState(false);
    const [openAlertE, setOpenAlertE] = useState(false)
    
    const downloadXls = (type) => {
        setDownloading(true);
        axios.post( `${servicios_grupohit}/configuracion/reportes/generar/${type}/`,null, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    if(type==='csv') descargaXLS(reporte.reportesReporteNom, respuesta.data.dataResponse);
                    if(type==='pdf') descargaPDF(reporte.reportesReporteNom, respuesta.data.dataResponse);
                    break; 
                default:
                    setOpenAlertE(true);
                    break;
            }
        })
        .catch(() => setOpenAlertE(true))
        .finally(() => setDownloading(false));
    }
    
    return (
        <Fragment>
            <Card className="cardBoard" > 
                <CardContent
                    onClick={() => {
                        if(consolidado) setIsConsolidado(true);
                        else if(setIsConsolidado) setIsConsolidado(false);

                        if(reporte.reportesId === 160 || reporte.reportesId === 161) {
                            setOpenModalFormulario(true);
                            return;
                        }

                        if(setOpenNewReportNomina && !setDownloadReportDefinido) { 
                            setOpenNewReportNomina(true);
                            return;
                        }
                        
                        if(downloadReport && !setDownloadReportDefinido && reporte.reportesId !== 7) {
                            openReport( reporte );
                            setElementSelect( { isStatic: true});
                            return;
                        }

                        if(reporte.reportesId === 7) return;
                        
                        if(setDownloadReportDefinido) {
                            if(reporte.reportesReporteNom!=="Estatico"){
                                setInfoReport({ id: reporte.reportesId, nombre: reporte.reportesReporteNom });
                                if(reporte.reportesId === 166) { //Muestra componente de tabla en reporte de Simulación de Recibos
                                    (consolidado)
                                    ?   setMuestraTabla({ ...muestraTabla, historico: true })
                                    :   setMuestraTabla({ ...muestraTabla, periodoActual: true })
                                } else {
                                    setDownloadReportDefinido(true);
                                    setOpenNewReportNomina(true);
                                }
                            }
                            return;
                        }

                        setElementSelect( reporte ); openReport( reporte ) 
                    }}
                > 
                    <div className="icon">{ (icon === 'Add') ? <Add /> : iconosDrawer[icon] }</div>
                    {(reporte)
                    ?   (reporte.reportesReporteNom.length > 45)
                        ?   <Tooltip title={reporte.reportesReporteNom}>
                                <p className="name card-reportes">{ `${reporte.reportesReporteNom.substr(0, 45)}...` }</p>
                            </Tooltip>
                        :   <p className="name card-reportes">{ reporte.reportesReporteNom }</p>
                    :   '' 
                    }
                </CardContent>
                {!downloadReport && icon !== 'Add' && 
                <CardActions >
                        <div className="row" > 
                            <div className={`col-md-${12/dividos.length} col-sm-12`} style={{textAlign:'center'}}>
                                <Tooltip title="Mostrar información"> 
                                        <InfoRounded color="disabled" className={"info"} style={{ color: '#bdbdbd'}}
                                             onClick={() => { setElementSelect( informacionAdicional ? informacionAdicional :reporte  ); setTitle('Información del Reporte'); setOpenInfoReport( true ); }}/> 
                                </Tooltip>
                            </div> 
                            <div className={`col-md-${12/dividos.length} col-sm-12`} style={{textAlign:'center'}}>
                                <Tooltip title="Compartir Reporte a..."> 
                                        <AssignmentInd className={"info"} style={{ color: '#4C638E'}}
                                            onClick={() => { setElementSelect( reporte  ); setTitle('Compartir Reporte'); setOpenInfoReport( true ); }}/> 
                                </Tooltip>
                            </div>
                            {
                                (reporte.usuarioUsername === obtenerUsuario()?.usuario) && permisos.includes(3)
                                ?
                                <div className={`col-md-${12/dividos.length} col-sm-12`} style={{textAlign:'center'}}>
                                    <Tooltip title="Eliminar Reporte"> 
                                            <Delete color="disabled" className={"info"} onClick={() => { setElementSelect( reporte ); setTipoReporte(reporteSuperior); setOpenDelete( true ); }} /> 
                                    </Tooltip>
                                </div>
                                :   null
                            }
                            
                        </div>
                </CardActions>
                }
                {reporte.reportesId === 7 && 
                <CardActions>
                    <div className="row">
                    {downloading
                    ?   <div className={`col`} style={{ textAlign:'center' }}>
                            <Tooltip title="Descargando..."> 
                                <CircularProgress size={20}/>
                            </Tooltip> 
                        </div>
                    :   <>
                            <div className="col-md-6 col-sm-12" style={{ textAlign:'center' }}>
                                <Tooltip title="Descarga reporte en PDF"> 
                                    <PictureAsPdf color="disabled" className="info" onClick={() => downloadXls('pdf')}/>
                                </Tooltip>
                            </div> 
                            <div className="col-md-6 col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title="Descarga reporte en XLSX">
                                    <div style={{
                                            backgroundColor: '#5E5E5E', color: '#fff', fontSize: '.7rem', fontWeight: '900', borderRadius: '4px',
                                            width: '32px', height: '1.4rem', textAlign: 'center', alignContent: 'center'
                                        }}
                                        onClick={() => downloadXls('csv')}
                                    >
                                        XLSX
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    }
                    </div>
                    <AlertSyE show={openAlertE} setOpenAlertSyE={setOpenAlertE} title="Petición Fallida" text={verifique_conexion} textbtnconfirm="Aceptar" type="error"/>
                </CardActions>
                }
            </Card>
            <ModalFormulario
                id={id} reporte={reporte} open={openModalFormulario} setOpen={setOpenModalFormulario}
            />
        </Fragment>
    );
}; 
export default ReportesAccesoDirecto;