import axios from 'axios';
import { GUARDAR_SESSION, GUARDA_ADMINISTRADOR, ERROR_MESSAGE_LOGIN, CATCH_ERROR_SERVER_LOGIN, CERRAR_SESSION } from '../actionTypes';
import { subdominio } from '../../Constants/Constants';

export const guardarSession = ( token, usuario ) => (dispatch) => {
    dispatch({
        type: GUARDAR_SESSION,
        session: { token, usuario }
    })
}

export const cerrarSession = () => (dispatch) => {
    dispatch({
        type: CERRAR_SESSION
    })
}

export const errorMessageLogin = ( message ) => (dispatch) => {
    dispatch({
        type: ERROR_MESSAGE_LOGIN,
        message
    })
}

export const errorServerLogin = ( errorServer ) => (dispatch) => {
    dispatch({
        type: CATCH_ERROR_SERVER_LOGIN,
        errorServer
    })
}

export const guardarRol = rol => dispatch => {
    dispatch({
        type: GUARDA_ADMINISTRADOR,
        payload: rol
    })
}

/* ------------------------------------------------------------------------------------------------ */
export const obtenerInformacion = ( token ) => async (dispatch) => {
    await axios.post( `${subdominio}/igenter/usuario/informacionUsuario`, {token} )
    .then( respuesta => {
        const { user: usuario } = respuesta.data;
        if( usuario ){
            dispatch({
                type: "GUARDAR_SESSION",
                session: { token, usuario }
            })
        }else{
            const { message } = respuesta.data;
            dispatch({
                type: ERROR_MESSAGE_LOGIN,
                message
            })
        }
    })
    .catch( error => {
        dispatch({
            type: CATCH_ERROR_SERVER_LOGIN,
            errorServer: error.message
        })
    });
}
