import React, { useState, useEffect } from 'react';
import TooltipComponent from '../Tooltip/TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const SelectValidation = ({ title, label, arrayOpciones, keyId, keyOpcion, keyOpcionConcat, refValue, defaultValue, onChangeExecute, onChangeReturnObject,tooltipText, disabled, required, size, optional, informacionAdicional }) => {
    const [ valid, setValid ] = useState( null );
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'));    
    
    useEffect(() => {
        if(refValue) {
            if(onChangeReturnObject) {
                const item = (keyId)
                ?   (parseInt(refValue.current.value))
                    ?   arrayOpciones.find(item => (item[keyId] === refValue.current.value) || (item[keyId] === parseInt(refValue.current.value)))
                    :   arrayOpciones.find(item => item[keyId] === refValue.current.value)
                :   arrayOpciones.find(item => item.id === parseInt(refValue.current.value));
                onChangeReturnObject(item);
            }
            if(refValue.current.value === '') setValid(null);
        }

        if(optional === true & refValue.current.value === '') {
            setValid(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refValue]);

    const validar = () => {
        if( onChangeExecute ){
            if(refValue.current.value === '') setValid(null);
            if(informacionAdicional!==undefined){
                onChangeExecute( refValue.current.value, informacionAdicional );
            }else{ 
                onChangeExecute( refValue.current.value );
            }
        }

        if(onChangeReturnObject) {
            const item = (keyId)
                        ?   (parseInt(refValue.current.value))
                            ?   arrayOpciones.find(item => (item[keyId] === refValue.current.value) || (item[keyId] === parseInt(refValue.current.value)))
                            :   arrayOpciones.find(item => item[keyId] === refValue.current.value)
                        :   arrayOpciones.find(item => item.id === parseInt(refValue.current.value));
            onChangeReturnObject(item);
        }
        
        if(!optional && refValue.current.value === '') {
            setValid(false)
            return;
        }

        if(!optional && refValue.current.value !== '') {
            setValid(true)
            return;
        }

        if( optional === true && refValue.current.value === '' ){
            setValid(null);
        }else{
            setValid( true );
        }
    }

    return (
        <div className={`form-group ${ optional === true && valid === null ? '' : 'validar' }`}>
            {   title &&
                <label>
                    { title }{ (required) ? <strong className="requerido">* </strong> : (title === " ") ? <div className="requeridoWhitout"></div> : null }
                    { tooltipText &&
                        <TooltipComponent tooltipText={tooltipText}>
                            <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                        </TooltipComponent>
                    }
                </label>
            }
            <select className={ `form-control ${ valid === null ? '' : valid ? 'is-valid skip-image' : 'is-invalid skip-image' }` } style={(size) ? {width: size} : {width: '100%'}}
                ref={refValue} defaultValue={defaultValue} onChange={() => validar()} disabled={disabled ? disabled : false}>
                <optgroup label={label ? label.toUpperCase() : title.toUpperCase()}>
                <option value=""> - Seleccione - </option>
                    { arrayOpciones.map( (opcion, i) => (
                        (title === 'Zona de Pago' || title === 'Identificador de la Plaza / Centro de Trabajo')
                        ?   <option key={i} value={(keyId) ? opcion[keyId] : opcion.id }> {`${opcion.domCiaCalle}${(opcion.domCiaNumExt & opcion.domCiaNumExt) ? ` ${opcion.domCiaNumExt}`.toUpperCase() : ''}${(opcion.domCiaNumInt) ? ` ${opcion.domCiaNumInt}`.toUpperCase() : ''}${(opcion.codPostal.codigopColonia) ? `, ${opcion.codPostal.codigopColonia}`.toUpperCase() : ''}${(opcion.codPostal.ciudadId.ciudadNom) ? `, ${opcion.codPostal.ciudadId.ciudadNom}`.toUpperCase() : ''}`} </option>
                        :   <option key={i} value={(keyId) ? opcion[keyId] : opcion.id }> {(keyOpcionConcat) ? `${opcion[keyOpcion]} ${opcion[keyOpcionConcat]}`.toUpperCase() : `${opcion[keyOpcion]}`.toUpperCase()} </option>
                    ))}
                </optgroup>
            </select>
            <div className="invalid-feedback"> Seleccione un elemento </div>
        </div>
    );
};

export default SelectValidation;