import React, { Fragment } from "react";
import DatosEmpleado from './DatosEmpleado';

const Empleado = ({ empleado }) => {
    return (
        <Fragment>
            <h6>Información del Empleado</h6>
            <DatosEmpleado empleado={empleado}/>
        </Fragment>
    );
}
 
export default Empleado;