import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import ReportesAccesoDirecto from '../RecursosHumanos/ReportesAccesoDirecto';
import ModalDeleteReport from '../RecursosHumanos/ModalDeleteReport';
import ModalInfoReport from '../RecursosHumanos/ModalInfoReport';
import NominaTablaReporte from './NominaTablaReporte';
import ModalNewReportNomina from './ModalNewReportNomina';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import TitleCard from '../../RecursosHumanos/Plantillas/components/TitleCard';
import Tabla from './Tabla';

const ReportesNominas = ({ id, permisos, icono }) => { 
    
    const [ reportesPersonalizados, setReportesPersonalizados ] = useState( null );
    const [ reportesSuperiores, setReportesSuperiores ] = useState( null );  
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ openInfoReport, setOpenInfoReport ]  = useState( false );
    const [ openNewReportNomina, setOpenNewReportNomina ]  = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} ); 
    const [ infoReport, setInfoReport ] = useState( null );
    const [ showReport, setShowReport ]  = useState( false );
    const [muestraTabla, setMuestraTabla] = useState({ periodoActual: false, historico: false });
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const permisoss =  permisos.map(element=> element.id);
    const [ title, setTitle ] = useState('');
    const [ reportesDefinidos, setReportesDefinidos ] = useState( null );
    const [ downloadReportDefinido, setDownloadReportDefinido ] = useState(false);
    const [ tipoReporte, setTipoReporte ] = useState(null); 

    const [reportesTab, setReportesTab] = useState(1); 
    const [isConsolidado, setIsConsolidado] = useState(false);

    const cambiarActivo = id => {
        setReportesTab(id);
    };
    
    useEffect(() => { 

            axios.get( `${servicios_grupohit}/configuracion/reportes/2`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setReportesPersonalizados(  Array.isArray(respuesta.data.dataResponse.reportes) ? respuesta.data.dataResponse.reportes: [] );
                        const subordinados =  Array.isArray(respuesta.data.dataResponse.subordinados) ? respuesta.data.dataResponse.subordinados : [] ;
                        setReportesSuperiores( Array.isArray(respuesta.data.dataResponse.superiores) ? respuesta.data.dataResponse.superiores.concat(subordinados) : subordinados );
                        const globales = Array.isArray(respuesta.data.dataResponse.estaticosglobales) ? respuesta.data.dataResponse.estaticosglobales.map(element=> 
                            {   return {
                                    reportesTipoReporte: element.tipoReporte,
                                    reportesEstatico: element.estatico,
                                    reportesId:  element.id,
                                    reportesReporteDescrip:  element.reporteDescrip,
                                    usuarioDatosUsuarioPat: element.usuarioDatosUsuarioPat,
                                    reportesCreated: element.created,
                                    reportesReporteJson: element.reporteJson,
                                    reportesReporteNom: element.reporteNom,
                                    usuarioUsername: element.usuarioUsername,
                                    usuarioDatosUsuarioMat: element.usuarioDatosUsuarioMat,
                                    usuarioStatus: element.usuarioStatus,
                                    usuarioDatosUsuarioNom: element.usuarioDatosUsuarioNom,
                                    propietario: 10,
                                    usuarioId: element.usuarioId
                                }
                            }
                        ) : [];
                        
                        const arrayReportes = (Array.isArray(respuesta.data.dataResponse.estaticos)? respuesta.data.dataResponse.estaticos.concat(globales) : globales)
                                                .sort((a, b) => a.reportesReporteNom > b.reportesReporteNom ? 1 : a.reportesReporteNom < b.reportesReporteNom ? -1 : 0);
                        setReportesDefinidos(arrayReportes);
                        break;
                    case 404:
                        setReportesPersonalizados( [] );
                        setReportesSuperiores( [] ); 
                        setReportesDefinidos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }, []);
    
    const openReport = (action) => {
        setShowReport(action);
    }

    const eliminar = ( idElement ) => { 
        if(tipoReporte){
            setReportesSuperiores( reportesSuperiores.filter(element => element.reportesId !== idElement));
        }else{
            setReportesPersonalizados( reportesPersonalizados.filter( element => element.reportesId !== idElement ) ); 
        }
    } 
         
    const onAdd = ( newElement ) => {  
        setReportesPersonalizados( [...reportesPersonalizados, newElement ]); 
    } 

    const error =  ( msjError ) => {
        setTextAlert( msjError ); 
        setAlertError( true ); 
    }

    const mostrarReportes = (consolidado) => {
        (consolidado)
        ?   setMuestraTabla({ ...muestraTabla, historico: false })
        :   setMuestraTabla({ ...muestraTabla, periodoActual: false })
        setInfoReport(null);
    };
    
    return (
        <div id={`modulo${id}`}>
            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable-panel">
                        <div className="tabbable-line">
                            <ul className="nav nav-tabs">
                                <li
                                    style={{ paddingBottom: 12, width: '50%' }}
                                    className={ reportesTab === 1 ? 'active' : '' }
                                    onClick={() => cambiarActivo(1)}
                                >
                                    <span>Reportes Predefinidos del Periodo Actual</span>
                                </li>
                                <li
                                    style={{ paddingBottom: 12, width: '50%' }}
                                    className={ reportesTab === 2 ? 'active' : '' }
                                    onClick={() => cambiarActivo(2)}
                                >
                                    <span>
                                        Reportes Predefinidos Históricos
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div hidden={(reportesTab === 2) ? true : false}>
            {(muestraTabla.periodoActual)
            ?   <Fragment>
                    <BtnBack tooltipText="Regresar a tabla de empleados" action={() => mostrarReportes(false)}/>
                    <CardComponent title={<TitleCard title={(infoReport) ? infoReport.nombre : ''}/>}>
                        <Tabla
                            id={`${id}simRecibos`} permisos={permisos}
                            nombreReporte={(infoReport) ? infoReport.nombre : ''}
                        />
                    </CardComponent>
                </Fragment>
            :   <Fragment>
                {
                    showReport &&
                    <NominaTablaReporte id={id} permisos={permisoss} openReport={openReport} elementSelect={elementSelect} onAdd={onAdd} infoReport={infoReport} setInfoReport={setInfoReport}/>
                }
                {
                    !showReport && 
                    <Fragment> 
                        <h4 className="pageSubtitle" style={{margin:'2rem 0 2rem'}}> {`Reportes Predefinidos`} </h4>
                        {(reportesDefinidos)
                        ?   <Fragment>
                                <div className="row">
                                    { reportesDefinidos.map( reporte => (
                                        <div key={ `define-${reporte.reportesId}` } className="col-md-2 col-sm-12 my-2">
                                            <ReportesAccesoDirecto reporte={reporte} openReport={openReport} icon={icono} downloadReport setOpenNewReportNomina={setOpenNewReportNomina}
                                                setDownloadReportDefinido={setDownloadReportDefinido} setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} informacionAdicional={true}
                                                setInfoReport={setInfoReport} muestraTabla={muestraTabla} setMuestraTabla={setMuestraTabla} setIsConsolidado={setIsConsolidado}
                                            />
                                        </div>
                                    ))} 
                                </div>
                            </Fragment>
                        :   <SpinnerOval /> 
                        }
                        <Fragment>            
                        <h4 className="pageSubtitle" style={{margin:'2rem 0 2rem'}}> {`Reportes Personalizados`} </h4>
                        {
                           reportesPersonalizados  
                           ?
                           <Fragment>
                               <div className="row">
                                   {
                                       permisoss.includes(1) &&
                                        <div key={ 'customNew' } className="col-md-2 col-sm-12 my-2">
                                            <ReportesAccesoDirecto reporte={{reportesReporteNom:"Nuevo Reporte"}} openReport={openReport} setOpenNewReportNomina={setOpenNewReportNomina}
                                                setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} icon={'Add'} permisos={permisoss} informacionAdicional={true}
                                                setIsConsolidado={setIsConsolidado}
                                            />
                                        </div>
                                   }  
                                   { 
                                   Array.isArray(reportesPersonalizados) &&
                                   reportesPersonalizados.map( reporte => (
                                       <div key={ reporte.reportesId } className="col-md-2 col-sm-12 my-2">
                                           <ReportesAccesoDirecto reporte={reporte} openReport={openReport} permisos={permisoss} icon={icono}
                                                setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} setOpenInfoReport={setOpenInfoReport} reporteSuperior={false} setTipoReporte={setTipoReporte}/>
                                       </div>
                                   ))} 
                               </div> 
                           </Fragment>
                           :   
                           <SpinnerOval /> 
                        }
                    </Fragment> 
                    <Fragment>
                        {
                            Array.isArray(reportesSuperiores)
                            &&
                            <Fragment>
                                {(reportesSuperiores.length > 0) &&
                                   <h4 className="pageSubtitle" style={{margin:'2rem 0 2rem'}}> {`Reportes de Otros Usuarios`} </h4>
                                }
                                <div className="row"> 
                                    {  
                                    reportesSuperiores.map( reporte => (
                                        <div key={ `custom-${reporte.reportesId}`} className="col-md-2 col-sm-12 my-2">
                                            <ReportesAccesoDirecto reporte={reporte} openReport={openReport} permisos={permisoss} icon={icono}
                                                setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle}
                                                setOpenInfoReport={setOpenInfoReport} informacionAdicional={reporte} reporteSuperior={true} setTipoReporte={setTipoReporte}
                                                setIsConsolidado={setIsConsolidado}
                                            />
                                        </div>
                                    ))} 
                                </div> 
                            </Fragment>
                        }
                    </Fragment>
                    </Fragment>
                }
                </Fragment>
            }
            </div>
            <div hidden={(reportesTab === 1) ? true : false}>
            {(muestraTabla.historico)
            ?   <Fragment>
                    <BtnBack tooltipText="Regresar a tabla de empleados" action={() => mostrarReportes(true)}/>
                    <CardComponent title={<TitleCard title={(infoReport) ? infoReport.nombre : ''}/>}>
                        <Tabla
                            id={`${id}simRecibos`} permisos={permisos}
                            nombreReporte={(infoReport) ? infoReport.nombre : ''}
                        />
                    </CardComponent>
                </Fragment>
            :   <Fragment>
                {
                    showReport &&
                    <NominaTablaReporte id={id} permisos = {permisoss} openReport = {openReport} elementSelect = {elementSelect} onAdd={onAdd} infoReport={infoReport} setInfoReport={setInfoReport}/>
                }
                {
                    !showReport &&
                    <Fragment>
                        <h4 className="pageSubtitle" style={{margin:'2rem 0 2rem'}}>Reportes Predefinidos Históricos</h4>
                        {(reportesDefinidos)
                        ?   <Fragment>
                                <div className="row">
                                    { reportesDefinidos.map( reporte => (
                                        <div key={ `define-${reporte.reportesId}` } className="col-md-2 col-sm-12 my-2">
                                            <ReportesAccesoDirecto reporte={reporte} openReport={openReport} icon={icono} downloadReport setOpenNewReportNomina={setOpenNewReportNomina}
                                                setDownloadReportDefinido={setDownloadReportDefinido} setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} informacionAdicional={true}
                                                setInfoReport={setInfoReport} muestraTabla={muestraTabla} setMuestraTabla={setMuestraTabla} consolidado setIsConsolidado={setIsConsolidado}
                                            />
                                        </div>
                                    ))} 
                                </div> 
                            </Fragment>
                        :   <SpinnerOval />
                        }
                    </Fragment>
                }
                </Fragment>
            }
            </div>
            <ModalDeleteReport openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} error={error}/>
            <ModalInfoReport openInfoReport={openInfoReport} nombreModulo={id} setOpenInfoReport={setOpenInfoReport} elementSelect={elementSelect} title={title}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <ModalNewReportNomina
                openNewReportNomina={openNewReportNomina} nombreModulo={id} setOpenNewReportNomina={setOpenNewReportNomina} 
                setElementSelect={setElementSelect} setShowReport={setShowReport} setInfoReport={setInfoReport}
                downloadReportDefinido={downloadReportDefinido} setDownloadReportDefinido={setDownloadReportDefinido} infoReport={infoReport}
                isConsolidado={isConsolidado}
            />
        </div>
    );
}
 
export default ReportesNominas;