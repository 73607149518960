import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import DataTableService from '../../../../../DataTableService/DataTableService';
import InputText from '../../../../../../../../Services/Components/DataTable/InputText';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { arrayJsonMovimientosPorPeriodoBajas } from '../../../../../Helpers/JsonToOneLevel';
import { bajas, mov_registrados, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const BajasRegistrados = ({ id, requestTabla, setBloqueaCards, setError, moverATabla, informacionAdicional }) => {

    const [infoTabla, setInfoTabla] = useState(null);

    useEffect(() => {
        const servicioBajas = () => {
            setBloqueaCards(true);
            axios.post(`${servicios_grupohit_nomina}/procesos/estadisticas/complementaria/bajas`, requestTabla, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        setInfoTabla(arrayJsonMovimientosPorPeriodoBajas(res.data.dataResponse, 2));
                        break;
                    default:
                        setInfoTabla([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setInfoTabla([]); setError(<AlertForm message={verifique_conexion} />) })
            .finally(() => moverATabla() );
        };

        servicioBajas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
        {(infoTabla)
        ?   <DataTableService
                id={`${id}BajaR`}
                title={`${bajas} - ${mov_registrados}`}
                columns={[
                    { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term)  },
                    { field: "empleadoNomCompleto", title: "Nombre" },
                    { field: "empleadoFecNac", title: "Fecha Nacimiento", hidden: true, hiddenByColumnsButton: true },
                    { field: "empleadoCurpRfc", title: "CURP / RFC" },
                    { field: "empleadoNss", title: "NSS", hidden: true, hiddenByColumnsButton: true },
                    { field: "empleadoGenero", title: "Género", hidden: true, hiddenByColumnsButton: true },
                    { field: "empsueldoMonto", title: "Sueldo", hidden: true, hiddenByColumnsButton: true },
                    { field: "empantFecini", title: "Fecha Ingreso a Gobierno" },
                    { field: "puesto", title: "Clave Puesto / Puesto" },
                    { field: "relabClave", title: "Clave Rel. Lab.", hidden: true, hiddenByColumnsButton: true },
                    { field: "realab_nom", title: "Relación Laboral", hidden: true, hiddenByColumnsButton: true },
                    { field: "domciaCalle", title: "Calle", hidden: true, hiddenByColumnsButton: true },
                    { field: "domciaNumExt", title: "Núm. Ext.", hidden: true, hiddenByColumnsButton: true },
                    { field: "domciaNumInt", title: "Núm. Int.", hidden: true, hiddenByColumnsButton: true },
                    { field: "domciaTel", title: "Teléfono", hidden: true, hiddenByColumnsButton: true },
                    { field: "domciaExt", title: "Extensión", hidden: true, hiddenByColumnsButton: true },
                    { field: "tipodomNom", title: "Tipo Domicilio", hidden: true, hiddenByColumnsButton: true },
                    { field: "cpresupuestal", title: "Clave Presupuestal / Proyecto", customFilterAndSearch: (term, rowData) => rowData.cpresupuestal.startsWith(term) },
                    { field: "proyectoClave", title: "Clave Proyecto Acción", hidden: true, hiddenByColumnsButton: true },
                    { field: "proyectoNom", title: "Proyecto Acción", hidden: true, hiddenByColumnsButton: true },
                    { field: "programaClave", title: "Clave Programa", hidden: true, hiddenByColumnsButton: true },
                    { field: "programaNom", title: "Programa", hidden: true, hiddenByColumnsButton: true },
                    { field: "udejecutoraClave", title: "Clave Unidad Ejecutora", hidden: true, hiddenByColumnsButton: true },
                    { field: "udejecutoraNom", title: "Unidad Ejecutora", hidden: true, hiddenByColumnsButton: true },
                    { field: "ciaUr", title: "Clave Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                    { field: "ciaRazonsocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                    { field: "zonaPago", title: "Zona de Pago", hidden: true, hiddenByColumnsButton: true },
                    { field: "empturFecIni", title: "Fecha Inicial Turno", hidden: true, hiddenByColumnsButton: true },
                    { field: "turnoNom", title: "Turno", hidden: true, hiddenByColumnsButton: true },
                    { field: "tipojornadaClave", title: "Clave Tipo Jornada", hidden: true, hiddenByColumnsButton: true },
                    { field: "tipojornadaNom", title: "Tipo Jornada", hidden: true, hiddenByColumnsButton: true },
                    { field: "nomina", title: "Clave Nómina / Nómina", hidden: true, hiddenByColumnsButton: true },
                    //{ field: "nominaNomCorto", title: "Nómina (Nombre Corto)", hidden: true, hiddenByColumnsButton: true },
                    { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                    { field: "areaNom", title: "Departamento", hidden: true, hiddenByColumnsButton: true },
                    { field: "empStatusFecIni", title: "Fecha Inicial", hidden: true, hiddenByColumnsButton: true },
                    { field: "nivlaboralClave", title: "Clave Nivel Laboral", hidden: true, hiddenByColumnsButton: true },
                    { field: "status", title: "Status", hidden: true, hiddenByColumnsButton: true },
                    { field: "fechaBaja", title: "Fecha Baja" },
                    { field: "motivoBaja", title: "Motivo Baja" },
                    { field: "usuario", title: "Cuenta", hidden: true, hiddenByColumnsButton: true },
                    { field: "nombre", title: "Usuario" },
                    { field: "fechaMovimiento", title: "Fecha de Registro" },
                ]}
                data={infoTabla}
                customOptionsTable={{ filtering: true }} 
                hideIconAdd hideIconEdit hideIconDelete
                informacionAdicional={informacionAdicional}
            />
        :   <SpinnerOval/>
        }
        </Fragment>
    );
}
 
export default BajasRegistrados;
