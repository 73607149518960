import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService'; 
import FormDispersionBancos from './FormDispersionBancos'; ;

const ModalAddDispersion = ({ openEdit, setOpenEdit, errorTable, successTable, elementSelect, nombreModulo }) => {
    
    const [error, setError] = useState(null); 

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }; 

    const enviarDispersion = ( dispersion, nombreArchivo ) => {
        let ruta ='';
        switch(elementSelect.id){ 
                case 57: //BANORTE
                    ruta='banorte'
                break;
                case 40: //BANAMEX
                    ruta='banamex';
                break;
                /*case 44: //SANTANDER
                break;
                case 43: //BANCOMER 
                break; */
                default:
                break;
        }
        axios.post( `${servicios_grupohit}/dispersion/${ruta}/`, dispersion, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const linkSource = `data:application/pag;base64,${respuesta.data.dataResponse}`;
                        const downloadLink = document.createElement("a");
                        const fileName =`${elementSelect.bancoNom}_${nombreArchivo.agnioArchivo}_${nombreArchivo.periodoArchivo}_${nombreArchivo.nombreNomina}.pag`; 
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click(); 
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            });
    }
    
    return (
        <ModalService
            title={`Dispersión ${elementSelect.bancoNom}`}
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12"> 
                    <FormDispersionBancos 
                        error={error} 
                        elementSelect={elementSelect} 
                        enviarDispersion={enviarDispersion}
                        setError={setError}
                    />
                </div>  
            </div>
        </ModalService>
    );
}

export default ModalAddDispersion;