import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonPrestamos } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioPrestamos from './FormularioPrestamos';
import { amountToFloat } from '../../../../../../../Services/Validation/HelperValidation';

const ModalEditPrestamos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo, dataTable }) => {
    const [ error, setError ] = useState( null );
    const [minimoLiquidez, setMinimoLiquidez] = useState(true);
    const [montoMinimoLiquidez, setMontoMinimoLiquidez] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    
    const { empleado, montoLiquido, setMontoLiquido } = informacionAdicional;

    useEffect(() => {
        axios.get(`${servicios_grupohit_rh}/empleado/liquidez/${empleado.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setMinimoLiquidez(res.data.dataResponse.minimoLiquidez);
                    break;
                default:
                    errorTable(res.data.msg)
                    break;
            }
        })
        .catch(error => {
            errorTable(verifique_conexion);
            console.log(error);
        });

        axios.get(`${servicios_grupohit_catalogo}/montominimo/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setMontoMinimoLiquidez(res.data.dataResponse[0].montominimo);
                    break;
                default:
                    errorTable(res.data.msg)
                    break;
            }
        })
        .catch(error => {
            errorTable(verifique_conexion);
            console.log(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarPrestamo = ( prestamo ) => {
        setIsLoading(true);
        axios.put( `${servicios_grupohit_rh}/empleado/prestamos/${elementSelect.id}`, prestamo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setMontoLiquido(montoLiquido+amountToFloat(elementSelect.importe)-prestamo.importe);
                        editar( JsonPrestamos(respuesta.data.dataResponse, elementSelect) );
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable(verifique_conexion);
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Préstamo" parentNode={nombreModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioPrestamos
                    error={error}
                    elementSelect={elementSelect}
                    informacion={informacionAdicional}
                    actualizarPrestamo={actualizarPrestamo}
                    montoLiquido={montoLiquido}
                    minimoLiquidez={minimoLiquidez}
                    montoMinimoLiquidez={montoMinimoLiquidez}
                    setMinimoLiquidez={setMinimoLiquidez}
                    dataTable={dataTable}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalEditPrestamos;