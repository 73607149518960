import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh } from '../../../../../../../Constants/Constants';
import { ArrayJsonProrrogaContratosEmpleados } from '../../../../Helpers/JsonToOneLevel';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import TableUpdateContractEmployed from './TableUpdateContractEmployed';

const ProrrogaContratos = ({ id }) => {
    const [ empleados, setEmpleados ] = useState( null );
    const [ actualizado, setActualizado ] = useState( false );
    useEffect(()=>{
        axiosContratosEmpleados();
    }, []);

    const axiosContratosEmpleados = (clear = false) =>{
        axios.get( `${servicios_grupohit_rh}/empleado/contratos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        if(clear){
                            setActualizado(true);
                        }
                        const dataResp = ArrayJsonProrrogaContratosEmpleados(respuesta.data.dataResponse);
                        setEmpleados( dataResp );
                        break;
                    default:
                        setEmpleados([]);
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <React.Fragment>
            { empleados ?
                <TableUpdateContractEmployed id={id}
                    empleados={empleados}
                    setEmpleados={setEmpleados}
                    axiosContratosEmpleados={axiosContratosEmpleados}
                    actualizado={actualizado}
                    setActualizado={setActualizado}
                />
                : <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default ProrrogaContratos;