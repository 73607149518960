const ConvertInfoEmp = data => {
    const newJson = {
        empleado: {
            id: data.id,
            empleadonom: data.empleadoNom.toUpperCase(),
            empleadoclave: data.empleadoClave,
            empleadopat: (data.empleadoPat) ? data.empleadoPat.toUpperCase() : null,
            empleadomat: (data.empleadoMat) ? data.empleadoMat.toUpperCase() : null,
            nombreCompleto: data.nombreCompleto.toUpperCase(),
            empleadofecnac: data.empleadoFecnac,
            empleadocurp: (data.empleadoCurp) ? data.empleadoCurp.toUpperCase() : null,
            empleadorfc: (data.empleadoRfc) ? data.empleadoRfc.toUpperCase() : null,
            empleadonss: data.empleadoNss,
            empleadoGenero: data.empleadoGenero,
            edoCivil: {
                id: data.edoCivilId,
                edoCivilnom: null
            },
            estado: {
                id: data.estadoId,
                estadoNom: null,
                paisId: {
                    paisNom: null,
                    id: null
                }
            },
            folioAlta: (data.empFolioAlta) ? data.empFolioAlta : '',
            equipo: (data.empEquipo) ? data.empEquipo : '',
            esMadre: data.empEsMadre,
        },
        antiguedad: {
            empantfecini: data.empantFecini,
            empantfecfin: null,
            id: data.empantId,
        },
        nomina: {
            nomina: {
                nominaClave: data.nominaClave,
                nominaNombre: data.nominaNombre,
                id: data.nominaId
            }
        },
        plaza: {
            plazaid: {
                plazaclave: data.plazaClave,
                id: data.plazaId
            }
        },
        presupuestal: {
            presupuestal: {
                cpresupuestalnom: null,
                cpresupuestalclave: null,
                id: null
            }
        },
        puesto: {
            puesto: {
                puestoCve: data.puestoCve,
                id: data.puestoId
            }
        },
        sueldo: {
            id: null,
            monto: null
        },
        turno: {
            fecini: null,
            fecfin: null,
            id: null,
            turnoNom: null
        },
        zonadepago: {
            domcia: {
                id: null
            }
        }
    };

    return newJson
};

export default ConvertInfoEmp;