/****** URLS ******/
export const sao = true; //desarrollo
//export const sao = false; //oax

export const subdominio = (sao) ? 'grupohit.net/sao' : 'gestiondrh.oaxaca.gob.mx';

export const servicios_grupohit = `https://${subdominio}/api`;
export const servicios_grupohit_ayuda = `https://${subdominio}/ayuda`;
export const servicios_grupohit_url = `${servicios_grupohit}/user`;
export const servicios_grupohit_token = `${servicios_grupohit}/token`;
export const servicios_grupohit_catalogo = `${servicios_grupohit}/catalogo`;
export const servicios_grupohit_admon = `${servicios_grupohit}/admon`;
export const servicios_grupohit_org = `${servicios_grupohit}/org`;
export const servicios_grupohit_rh = `${servicios_grupohit}/rh`;
export const servicios_grupohit_nomina = `${servicios_grupohit}/nomina`;
export const servicios_grupohit_mensajes = `${servicios_grupohit}/usuarios/mensajes`;
export const servicios_grupohit_plantillas = `${servicios_grupohit}/generar/plantillas`;
export const servicios_grupohit_config = `${servicios_grupohit}/configuracion`;
export const servicios_grupohit_timbrado = `${servicios_grupohit}/cfdi/timbrado/`;


export const endpointSocket = `wss://${subdominio}`;

/****** Text Alerts ******/
export const registro_sera_eliminado = '¡El registro será eliminado!';
export const registro_sera_actualizado = '¡El registro será actualizado!';
export const registro_sera_guardado = '¡El registro será guardado!';
export const registro_actualizado = '¡El registro fue actualizado!';
export const registro_guardado = '¡El registro fue guardado!';
export const registro_eliminado = '¡El registro fue eliminado!';
export const cambios_no_guardados = 'No se han podido guardar los cambios';
export const registro_no_guardado = 'No se ha podido guardar el registro';
export const registro_no_eliminado = 'No se ha podido eliminar el registro';
export const sesion_usuario_sera_cerrada = '¡La sesión del usuario será cerrada!';
export const sesion_usuario_no_cerrada = 'No se ha podido cerrar la sesión del usuario';

export const registro_sera_inactivado = '¡El registro será desactivado!';
export const registro_sera_activado = '¡El registro será activado!';
export const registro_no_inactivado = 'No se ha podido desactivar el registro';
export const registro_no_activado = 'No se ha podido activar el registro';

export const no_hay_cambios = '¡No hay cambios por guardar!';

export const verifique_conexion = 'El proceso no pudo realizarse con éxito. Por favor, verifique su conexión a Internet e inténtelo nuevamente.';

/****** Mensajes Formularios ******/
export const campos_vacios = "Complete los campos";
export const ocurrio_un_error = "Ocurrió un error";
export const terminosYcondiciones = "Debe aceptar los términos y condiciones";
export const error_reporte = "No se pudo descargar el reporte.";

/****** Tamaños Inputs ******/
export const lengthNombre = 30;
export const lengthEmail = 50;
export const lengthPassword = 30;

/****** CHAT ******/
export const mensajesVentana = 7

/****** Constantes para titulo de tabla, id y parentNode en Modales ******/
export const tablero_ = 'Tablero';
/* Generales */
export const tipo_relacion_laboral = 'Tipo de Relación Laboral';
export const registro_patronal = 'Registro Patronal';
export const giros_ = 'Giros';
export const tipos_secretaria_o_dependencia = 'Tipos de Secretaría o Dependencia';
export const motivos_baja = 'Motivos de Baja';
export const parentesco_ = 'Parentesco';
export const colonias_ = 'Colonias';
export const bancos_ = 'Bancos';
export const bases_ = 'Bases';
export const tipos_ausentismos = 'Tipos de Ausentismos';
export const tipos_jornada = 'Tipos de Jornada';
export const tipo_domicilio = 'Tipo de Domicilio';
export const indicadores_generales = 'Conceptos Generales';
export const nivel_escolaridad = 'Nivel de Escolaridad';
export const tipo_estado_civil = 'Tipo de Estado Civil';
export const tipo_seguro_social = 'Tipo de Seguridad Social';
export const tipo_documentos = 'Tipo de Documentos';
export const regimen_fiscal = 'Régimen Fiscal';
export const origen_recurso = 'Origen de Recurso';
export const tipo_contrato = 'Tipo de Contrato';
export const tipo_regimen = 'Régimen de Contratación';
export const tipo_riesgo = 'Tipo de Riesgo';
export const tipo_acumulado = 'Tipo de Acumulado';
export const tipo_pasatiempo_ = 'Tipo de Pasatiempo';
export const contratos_relacion_laboral = 'Contratos por Relación Laboral';
export const salario_minimo_uma = 'Salario Mínimo / UMA';
export const impuesto_sobre_nomina = 'Impuesto Sobre Nómina';
export const tablas_impuestos = 'Tablas de Impuestos';
export const tablas_subsidios = 'Tablas de Subsidio';
export const sindicatos_ = 'Sindicatos';
export const departamentos_generales = 'Departamentos Generales';
export const monto_minimo_liquidez = 'Monto Mínimo de Liquidez';
/* Administración */
export const usuarios_ = 'Usuarios';
export const dependencias_ = 'Dependencias';
export const proyectos_ = 'Proyectos';
export const carga_logo = 'Carga Logo';
export const sesiones_ = 'Sesiones';
/* Organización */
export const niveles_ = 'Niveles';
export const puestos_ = 'Puestos';
export const tabuladores_ = 'Tabulador de Sueldos';
export const tabuladores_quinquenios = 'Tabulador de Quinquenios';
export const departamentos_ = 'Departamentos';
export const plazas_ = 'Plazas';
export const nominas_organizacion = 'Nóminas';
export const turnos_= 'Turnos';
/* Recursos Humanos */
export const empleados_ = 'Empleados';
export const ausentismos_ = 'Ausentismos';
export const reingresos_ = 'Reingresos';
export const expediente_ = 'Expediente';
export const historico_ = 'Históricos';
export const especiales_ = 'Movimientos Especiales';
export const plantillas_ = 'Plantillas';
/* Nómina */
export const nominas_nominas = 'Nóminas';
export const periodos_nomina = 'Periodos de Nómina';
export const indicadores_ = 'Conceptos por Nómina';
export const politicas_ = 'Políticas';
export const calculo_ = 'Cálculo';
export const movimientos_ = 'Movimientos Variables';
export const movimientos_fijos = 'Movimientos Fijos';
export const movimientos_relacionados = 'Movimientos Relacionados';
export const movimientos_pendientes = 'Movimientos Pendientes';
export const prestamos_ = 'Préstamos';
export const prestamos_empleado = 'Préstamos Empleado';
export const imss_ = 'IMSS';
export const pensiones_ = 'Pensiones';
export const acumulados_ = 'Acumulados';
export const control_pagos_unicos = 'Control de Pagos Únicos';
export const pagos_unicos = 'Pagos Únicos';
/* Reportes */
export const dispersiones_ = 'Dispersiones';
export const auditoria_ = 'Auditoría';
/*Búqueda Avanzada */
export const consulta_pagos9719 = 'Consultar Histórico de Pagos 1997 - 2024';
export const consulta_plazas9719 = 'Consultar Histórico de Plazas 1997 - 2024';
/*Estadistcias */
export const estad_por_periodo = 'Movimientos de Nómina por Periodo';
export const mov_vigentes = 'Movimientos Vigentes';
export const mov_registrados = 'Movimientos Registrados';
export const altas = 'Altas';
export const bajas = 'Bajas';
export const reingresos = 'Reingresos';
export const datos_empleados = 'Datos de Empleados';
export const prorrogas_contratos = 'Prórrogas de Contratos';
export const no_prorrogas_contratos = 'No Prórrogas de Contratos';
export const escalafones = 'Escalafones';
export const categorias = 'Categorías';
export const adscripciones = 'Adscripciones / Plazas';
export const proyectos = 'Proyectos';
export const nombramientos = 'Nombramientos';
export const otros_movimientos = 'Otros Movimientos';
/* SubModulos */
export const correspondencia_conceptos = 'Correspondencia de Conceptos';
export const pagos_pendientes = 'Pagos Pendientes';