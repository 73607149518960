import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { registro_sera_eliminado, servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import Button from '@material-ui/core/Button';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import { floatToAmount } from '../../../../../../Services/Validation/HelperValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertForm from '../../../../../../Services/AlertForm';

const InfoPagosUnicos = ({ permisos, empleado, mostrarFormPagoUnico, agregarPagoUnico, setAgregarPagoUnico, pagosUnicosVigentes, setPagosUnicosVigentes }) => {

   const [textAlert, setTextAlert] = useState({ type: '', text: '' });
   const [openAlertError, setOpenAlert] = useState(false);
   const [openAlertWarning, setOpenAlertWarning] = useState(false);
   const [idEliminar, setIdEliminar] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupohit_rh}/movimientos/pagosUnicos/${empleado.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPagosUnicosVigentes(res.data.dataResponse);
                    break;
                case 404:
                    setPagosUnicosVigentes([]);
                    if(permisos.find(permiso => permiso.id === 1)) setAgregarPagoUnico(true);
                    break;
                default:
                    mostrarAlerta({ type: 'error', text: res.data.msg });
                    setPagosUnicosVigentes([]);
                    break;
            }
        })
        .catch(error => {
            mostrarAlerta({ type: 'error', text: verifique_conexion });
            setPagosUnicosVigentes([]);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const abreAlerta = id => {
        if(id) {
            setIdEliminar(id);
            setOpenAlertWarning(true);
        }
    };

    const mostrarAlerta = ({ type, text }) => {
        setTextAlert({ type, text });
        setOpenAlert(true);
    };

    const eliminarPagoUnico = id => {
        if(id) {
            if(id === 'all') {
                axios.delete(`${servicios_grupohit_rh}/movimientos/pagosUnicos/deleteAll/${empleado.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            mostrarAlerta({ type: 'success', text: res.data.msg });
                            setPagosUnicosVigentes([]);
                            empleado.nivLaboralClave = "0";
                            break;
                        default:
                            mostrarAlerta({ type: 'error', text: res.data.msg });
                            break;
                    }
                })
                .catch(error => {
                    mostrarAlerta({ type: 'error', text: verifique_conexion });
                })
                .finally(() => {
                    setIdEliminar(null);
                });
            } else {
                axios.delete(`${servicios_grupohit_rh}/movimientos/pagosUnicos/${id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            mostrarAlerta({ type: 'success', text: res.data.msg });
                            const newPagosUnicos = pagosUnicosVigentes.filter(item => item.id !== id);
                            setPagosUnicosVigentes(newPagosUnicos);
                            if(newPagosUnicos.length === 0) {
                                empleado.nivLaboralClave = "0";
                            }
                            break;
                        default:
                            mostrarAlerta({ type: 'error', text: res.data.msg });
                            break;
                    }
                })
                .catch(error => {
                    mostrarAlerta({ type: 'error', text: verifique_conexion });
                })
                .finally(() => {
                    setIdEliminar(null);
                });
            }
        }
    };

    const limpiarTextos = () => {
        setTextAlert({ type: '', text: ''});
    };

    return (
        <Fragment>
        {(pagosUnicosVigentes)
        ?   (pagosUnicosVigentes.length > 0)
            ?   <Fragment>
                    <div className='mb-2 tablePagosUnicos'>
                        <table className='table table-sm table-hover'>
                            <thead>
                                <tr>
                                    <th scope="col"><strong>Nómina</strong></th>
                                    <th scope="col"><strong>Acumulado</strong></th>
                                    <th scope="col"><strong>Naturaleza</strong></th>
                                    <th scope="col"><strong>Clave</strong></th>
                                    <th scope="col"><strong>Concepto</strong></th>
                                    <th scope="col"><strong>Cantidad</strong></th>
                                    <th scope="col"><strong>Monto</strong></th>
                                    <th scope="col"><strong>Año / Periodo</strong></th>
                                    <th scope="col"><strong>Año Inicio /<br/>Periodo Inicio</strong></th>
                                    <th scope="col"><strong>Año Final /<br/>Periodo Final</strong></th>
                                    {(permisos.find(permiso => permiso.id === 3)) ? <th scope="col"></th> : '' }
                                </tr>
                            </thead>
                            <tbody>
                            {pagosUnicosVigentes.map((item, i) => (
                                <tr key={i}>
                                    <td>{ item.nominaClave }</td>
                                    <td>{ item.acumuladoNombre }</td>
                                    <td>{ item.tiponatuNom }</td>
                                    <td>{ item.indicadorClave }</td>
                                    <td>{ item.indicadorNombre }</td>
                                    <td>{ item.cantidad }</td>
                                    <td className='d-flex justify-content-end'>{ floatToAmount(item.monto, 2) }</td>
                                    <td>{ item.anioAplica } / { item.periodoAplica }</td>
                                    <td>{ item.anioInicio } / { item.periodoInicio }</td>
                                    <td>{ item.anioFinal } / { item.periodoFinal }</td>
                                    {(permisos.find(permiso => permiso.id === 3)) ? <td><Button size="small" variant="contained" className="btn-eliminar" onClick={() => abreAlerta(item.id)}>Eliminar</Button></td> : ''}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col">
                        {(permisos.find(permiso => permiso.id === 1))
                        ?   <Button variant="contained" className="btn-color" onClick={mostrarFormPagoUnico}>
                                {(agregarPagoUnico)
                                ?   'Ocultar Formulario Pago Único'
                                :   'Agregar Pago Único'
                                }
                            </Button>
                        :   ''
                        }
                        </div>
                        <div className="col">
                            <Button variant="contained" className="btn-color-eliminar" onClick={() => abreAlerta('all')}>
                                Eliminar Todos los Pagos Únicos
                            </Button>
                        </div>
                    </div>
                </Fragment>
            :   <AlertForm message="El empleado no tiene pagos únicos" warning />
        :   <SpinnerOval/>
        }
        <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlert} title={(textAlert.type === 'success') ? 'Petición Exitosa' : 'Petición Fallida'} text={textAlert.text} textbtnconfirm="Aceptar" type={textAlert.type} action={limpiarTextos}/>
        <AlertWarning show={openAlertWarning} SetopenALert={setOpenAlertWarning} text={(idEliminar === 'all') ? 'Los registros serán eliminados' : registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={eliminarPagoUnico} value={idEliminar}/>
        </Fragment>
    );
}
 
export default InfoPagosUnicos;