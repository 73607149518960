const BlueCheckboxRadioStyles = {
    root: {
      color: '#81A1DD',
      '&$checked': {
        color: '#81A1DD',
      },
    },
    checked: {},
};

export default BlueCheckboxRadioStyles;