import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_org, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from './../../../ModalService/ModalService';
import FormTurnos from './FormTurnos';
import { JsonTurnosAdd } from '../../../Helpers/JsonToOneLevel';

const ModalAddTurnos = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarTurnos = turno => {
        setIsLoading(true);
        Axios.post(`${servicios_grupohit_org}/turno/`, turno, headersAuth())
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:                    
                    agregar(JsonTurnosAdd(respuesta.data.dataResponse[0]));
                    setOpenAdd( false );
                    break;
                default:
                    errorTable( respuesta.data.msg );
                    break;
            }
        })
        .catch( error => {
            errorTable( registro_no_guardado );
        })
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Turno" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } minWidth="80%" maxWidth="80%">
            <div className="row">
                <div className="col-12">
                    <FormTurnos
                        enviarTurnos={enviarTurnos}
                        error={error}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddTurnos;