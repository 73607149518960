import React, { useState, useEffect } from 'react';
import { floatToAmount, longDateToInput } from '../../../../../Services/Validation/HelperValidation'; 
import { servicios_grupohit_rh } from '../../../../../Constants/Constants';
import Axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService'; 
import { Avatar, Button, CircularProgress } from '@material-ui/core';
import profileIMG from '../../../../../Media/Img/profile.jpg';
import { Fragment } from 'react';
import ConvertDate from '../../Helpers/ConvertDate';

const FormularioConsultaBusquedaAvanzada = ({ error, setError, elementSelect, descargarPDF, loading, setLoading }) => {
     
    const [fotografia, setFotografia] = useState(null);
    const [ dependientesEconomicos, setDependientesEconomicos ] = useState(null);
    const [ contactosEmergencia, setContactosEmergencia ] = useState(null);
    const [ experienciaLaboral, setExperienciaLaboral ] = useState(null);
    const [ pasatiempos, setPasatiempos ] = useState(null);
    
    useEffect(()=>{
        Axios.get(`${servicios_grupohit_rh}/documentos/${elementSelect.id}/1`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setFotografia(res.data.dataResponse[0]);
                        break;
                    case 404:
                        setFotografia(null);
                        break;
                    default: 
                        break;
                }
            })
            .catch(error => { console.log(error) });

            Axios.get(`${servicios_grupohit_rh}/empleado/dependiente/economico/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const data = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                nombre: res.nombre,
                                apellidoPat: res.apellidoPat,
                                apellidoMat: res.apellidoMat,
                                curp: res.curp,
                                fechaNac: res.fechaNac,
                                parentesco: res.parentesco.id,
                                parentescoNom: res.parentesco.parentescoNom,
                                escolaridad: res.escolaridad.id,
                                escolaridadNom: res.escolaridad.escolaridadNom,
                                gradoEscolar: res.gradoEscolar,
                                promedio: res.promedio,
                                depEconDiscapacidad: (res.discapacidad.id === 0) ? 'No' : 'Sí',
                                discapacidad: res.discapacidad.id,
                                nivelDisc: res.discapacidad.nivel,
                                nombreDisc: res.nombreDisc
                            }
                        ));
                        setDependientesEconomicos(data);
                        break;
                        default:
                        setDependientesEconomicos([]);
                        break;
                }
            })
            .catch(error => { console.log(error) });


            Axios.get(`${servicios_grupohit_rh}/empleado/llamada/emergencia/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const data = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                nombre: res.nombre,
                                apellidoPat: res.apellidoPat,
                                apellidoMat: res.apellidoMat,
                                telefonoFijo: res.telefonoFijo,
                                telefonoCelular: res.telefonoCelular,
                                parentesco: res.parentesco.id,
                                parentescoNom: res.parentesco.parentescoNom,
                                calle: res.calle,
                                numeroExt: (res.numeroExt) ? res.numeroExt : '',
                                numeroInt: (res.numeroInt) ? res.numeroInt : '',
                                cp: res.cp.codigopCp,
                                numEmerColonia: res.cp.id,
                                coloniaNom: res.cp.codigopColonia,
                                coloniaId: res.cp.id,
                                numEmerCiudad: res.cp.ciudadId.ciudadNom,
                                numEmerEstado: res.cp.ciudadId.estadoId.estadoNom
                            }
                        ));
                        setContactosEmergencia(data);
                        break;
                    default:
                        setContactosEmergencia([]);
                        break;
                }
            })
            .catch(error => { console.log(error) });

            Axios.get(`${servicios_grupohit_rh}/empleado/experiencia/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        let data = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                empId: res.empId.id,
                                empNom: res.empNom,
                                puestoExp: res.puestoExp,
                                dmes: res.dmes,
                                danio: res.danio,
                                hmes: res.hmes,
                                hanio: res.hanio,
                                jefe: res.jefe,
                                tel: res.tel
                            }
                        ));
                        setExperienciaLaboral(data);
                        break;
                    default:
                        setExperienciaLaboral([]);
                        break; 
                }
            })
            .catch(error => { console.log(error) });


            Axios.get(`${servicios_grupohit_rh}/empleado/pasatiempo/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const info = res.data.dataResponse.map(res => (
                            {
                                id: res.pasatiempo.id,
                                pasatiempo: res.pasatiempo.pasaNom
                            }
                        ));
                        setPasatiempos(info);
                        break;
                    default:
                        setPasatiempos([]);
                        break; 
                }
            })
            .catch(error => { console.log(error) });

    }, [elementSelect]);


    const enviar = ( e ) =>{
        e.preventDefault();
        descargarPDF();
    }

    const nombreEstadoCivil = {
        1:'NO REGISTRADO',
        2:'SOLTERO',
        3:'CASADO',
        4:'UNIÓN LIBRE',
        5:'DIVORCIADO',
    }

    return (
        <form id={ 'ConsultaBusquedaAvanzada' } onSubmit={enviar}>
            <div className="row" style={{paddingBottom:10, paddingTop:50}}>
                <div className="col-md-2 col-sm-12 avatarAdvancedSearch">  
                    <Avatar alt="avatar" src={(fotografia) ? `data:image/jpeg;base64, ${fotografia}` : profileIMG}/>
                </div>
                <div className="col-md-5 col-sm-12">
                    <strong>{"NÚMERO ÚNICO DE EMPLEADO: "}</strong>{elementSelect.empleadoClave}<br/>
                    <strong>{"PRIMER APELLIDO: "}</strong>{elementSelect.empleadoPat}<br/>
                    <strong>{"FECHA DE NACIMIENTO: "}</strong>{elementSelect.empleadoFecnac ? ConvertDate(longDateToInput(elementSelect.empleadoFecnac)) : '--/--/----'}<br/>
                    <strong>{"GÉNERO: "}</strong>{elementSelect.empleadoGenero ? elementSelect.empleadoGenero : '-'}<br/>
                    {/*<strong>{"ESCOLARIDAD: "}</strong>{elementSelect.empleadoClave}<br/>*/}
                    <strong>{"CURP: "}</strong>{elementSelect.empleadoCurp}<br/>
                    {/*<strong>{"CARRERA: "}</strong>{elementSelect.empleadoClave}<br/>*/}
                    <strong>{"FECHA DE INGRESO: "}</strong>{elementSelect.empantFecini ? ConvertDate(longDateToInput(elementSelect.empantFecini)) : '--/--/----'}<br/>
                </div> 
                <div className="col-md-5 col-sm-12">
                    <strong>{"NOMBRE: "}</strong>{elementSelect.empleadoNom}<br/>
                    <strong>{"SEGUNDO APELLIDO: "}</strong>{elementSelect.empleadoMat}<br/>
                    {/* <strong>{"LUGAR DE NACIMIENTO: "}</strong>{'PENDING...'}<br/> */}
                    <strong>{"ESTADO CIVIL: "}</strong>{elementSelect.edoCivilId ? nombreEstadoCivil[elementSelect.edoCivilId] ? nombreEstadoCivil[elementSelect.edoCivilId] : nombreEstadoCivil[1] : nombreEstadoCivil[1]}<br/>
                    <strong>{"RFC: "}</strong>{elementSelect.empleadoRfc}<br/>
                    {/* <strong>{"CÉDULA PROFESIONAL: "}</strong>{'PENDING...'}<br/> */}
                    <strong>{"NÚMERO DE SEGURIDAD SOCIAL: "}</strong>{elementSelect.empleadoNss}<br/>
                </div>
            </div>
            <hr/>
            <div className="row" style={{paddingBottom:10}}>
                <div className="col-md-12 col-sm-12">
                    <strong>{"ORGANIZACIÓN DEL EMPLEADO "}</strong>
                </div>
            </div>
            <div className="row" style={{paddingBottom:10}}> 
                
                <div className="col-md-6 col-sm-12">
                    <strong>{"STATUS: "}</strong>{elementSelect.status}<br/>
                    <strong>{"UNIDAD RESPONSABLE: "}</strong>{elementSelect.ciaRazonSocial}<br/>
                    <strong>{"UNIDAD EJECUTORA: "}</strong>{elementSelect.udejecutoraClave}<br/>
                    <strong>{"PROGRAMA: "}</strong>{elementSelect.programaNom}<br/>
                    <strong>{"PROYECTO ACCIÓN: "}</strong>{elementSelect.proyectoNom}<br/>
                    <strong>{"CLAVE PRESUPUESTAL: "}</strong>{elementSelect.cpresupuestalClave}<br/>
                    <strong>{"PROYECTO: "}</strong>{elementSelect.proyectoNom}<br/>
                    {/* <strong>{"DEPARTAMENTO: "}</strong>{"PENDING"}<br/> */}
                    <strong>{"NÚMERO ÚNICO DE PLAZA: "}</strong>{elementSelect.plazaClave}<br/>
                    <strong>{"NIVEL: "}</strong>{elementSelect.nivLaboralClave}<br/>
                    <strong>{"CLAVE DEL PUESTO: "}</strong>{elementSelect.puestoCve}<br/>
                </div> 
                <div className="col-md-6 col-sm-12">
                    <strong>{"RELACIÓN LABORAL: "}</strong>{elementSelect.realabNom}<br/>
                    <strong>{"SUELDO: "}</strong>{elementSelect.empsueldoMonto ? floatToAmount(elementSelect.empsueldoMonto, 2):''}<br/>
                    {/* <strong>{"ZONA DE PAGO: "}</strong>{'PENDING...'}<br/>
                    <strong>{"FORMA DE PAGO: "}</strong>{'PENDING...'}<br/>
                    <strong>{"BANCO: "}</strong>{'PENDING...'}<br/>
                    <strong>{"NÚMERO DE CUENTA: "}</strong>{'PENDING...'}<br/>
                    <strong>{"CLABE INTERBANCARIA: "}</strong>{'PENDING...'}<br/> */}
                    <strong>{"TURNO: "}</strong>{elementSelect.turnoNom}<br/>
                    <strong>{"NÓMINA: "}</strong>{elementSelect.nominaNomCorto}<br/>
                    {/* <strong>{"CONTRATO: "}</strong>{'PENDING...'}<br/> */}
                    <strong>{"FECHA INICIAL: "}</strong>{ elementSelect.empstatusFecini ? ConvertDate( (longDateToInput(elementSelect.empstatusFecini))) :'--/--/---'}<br/>
                    {/* <strong>{"FECHA DE AGUINALDO: "}</strong>{'PENDING...'}<br/> */}
                </div>
            </div> 
            <hr/>
            <div className="row" style={{paddingBottom:10}}>
                <div className="col-md-12 col-sm-12">
                    <strong>{"DOMICILIO "}</strong>
                </div>
            </div>
            <div className="row" style={{paddingBottom:10}}> 
                
                <div className="col-md-6 col-sm-12">
                    <strong>{"CALLE: "}</strong>{elementSelect.domciaCalle}<br/>
                    <strong>{"NÚMERO EXTERIOR: "}</strong>{elementSelect.domciaNumext ? elementSelect.domciaNumext !=="null" ? elementSelect.domciaNumext : '-' :'-'}<br/>
                    {/* <strong>{"CÓDIGO POSTAL: "}</strong>{'PENDING...'}<br/>
                    <strong>{"CIUDAD: "}</strong>{'PENDING...'}<br/> */}
                </div> 
                <div className="col-md-6 col-sm-12">
                    <strong>{"NÚMERO INTERIOR: "}</strong>{elementSelect.domciaNumint ? elementSelect.domciaNumint !=="null" ? elementSelect.domciaNumint : '-' :'-'}<br/>
                    {/* <strong>{"COLONIA: "}</strong>{'PENDING...'}<br/> */}
                    {/* <strong>{"ESTADO: "}</strong>{elementSelect.estadoId}<br/> */}
                </div>
            </div>
            <hr/>
            <div className="row" style={{paddingBottom:10}}>
                <div className="col-md-12 col-sm-12">
                    <strong>{"DEPENDIENTES ECONÓMICOS "}</strong>
                </div>
            </div>

            {
                dependientesEconomicos &&
                <div className="row" style={{paddingBottom:10}}>
                { 
                    dependientesEconomicos.length>0 ?
                    dependientesEconomicos.map(element => (
                        <Fragment key={element.id}>
                            <div className="col-md-6 col-sm-12">
                                <strong>{"NOMBRE: "}</strong>{element.nombre}<br/>
                                <strong>{"PRIMER APELLIDO: "}</strong>{element.apellidoPat}<br/>
                                <strong>{"CURP: "}</strong>{element.curp}<br/>
                                <strong>{"PARENTESCO: "}</strong>{element.parentescoNom}<br/>
                                <strong>{"GRADO DE ESTUDIOS: "}</strong>{element.gradoEscolar}<br/>
                                </div> 
                            <div className="col-md-6 col-sm-12">
                                <strong>{"SEGUNDO APELLIDO: "}</strong>{element.apellidoMat}<br/>
                                <strong>{"FECHA DE NACIMIENTO: "}</strong>{ elementSelect.fechaNac ? ConvertDate(longDateToInput(elementSelect.fechaNac)) : '--/--/----'}<br/>
                                <strong>{"ESCOLARIDAD: "}</strong>{element.escolaridad}<br/>
                                <strong>{"PROMEDIO: "}</strong>{element.promedio}<br/>
                                <strong>{"DISCAPACIDAD: "}</strong>{element.nombreDisc}<br/>
                            </div>
                        </Fragment>
                    )):
                    <div className="col-md-12 col-sm-12">
                         {'SIN REGISTRO'}<br/>        
                    </div>
                }
                </div> 
            }

            <hr/>
            <div className="row" style={{paddingBottom:10}}>
                <div className="col-md-12 col-sm-12">
                    <strong>{"NÚMEROS DE EMERGENCIA"}</strong>
                </div>
            </div>

            {
                contactosEmergencia &&
                <div className="row" style={{paddingBottom:10}}>  
                { 
                    contactosEmergencia.length>0 ?
                    contactosEmergencia.map(element => (
                        <Fragment key={element.id}>
                            <div className="col-md-6 col-sm-12">
                                <strong>{"NOMBRE: "}</strong>{element.nombre}<br/>
                                <strong>{"SEGUNDO APELLIDO: "}</strong>{element.apellidoMat}<br/>
                                <strong>{"TELÉFONO MÓVIL:"}</strong>{element.telefonoCelular}<br/>
                                <strong>{"CALLE: "}</strong>{element.calle}<br/>
                                <strong>{"NÚM. INTERIOR: "}</strong>{element.numeroInt}<br/>
                                <strong>{"COLONIA: "}</strong>{element.coloniaNom}<br/>
                                <strong>{"ESTADO: "}</strong>{element.numEmerEstado}<br/>
                                </div> 
                            <div className="col-md-6 col-sm-12">
                                <strong>{"PRIMER APELLIDO: "}</strong>{element.apellidoPat}<br/>
                                <strong>{"TELÉFONO FIJO: "}</strong>{element.telefonoFijo}<br/>
                                <strong>{"PARENTESCO: "}</strong>{element.parentesco}<br/> 
                                <strong>{"NÚM. EXTERIOR: "}</strong>{element.numeroExt}<br/>
                                <strong>{"CÓDIGO POSTAL: "}</strong>{element.cp}<br/>
                                <strong>{"CIUDAD: "}</strong>{element.numEmerCiudad}<br/>
                            </div>
                        </Fragment>
                    )):
                    <div className="col-md-12 col-sm-12">
                         {'SIN REGISTRO'}<br/>        
                    </div>
                }
                </div> 
            } 


            <hr/>
            <div className="row" style={{paddingBottom:10}}>
                <div className="col-md-12 col-sm-12">
                    <strong>{"EXPERIENCIA LABORAL"}</strong>
                </div>
            </div>

            {
                experienciaLaboral &&
                <div className="row" style={{paddingBottom:10}}>  
                { 
                    experienciaLaboral.length>0 ?
                    experienciaLaboral.map(element => (
                        <Fragment key={element.id}>
                            <div className="col-md-6 col-sm-12">
                                <strong>{"EMPRESA: "}</strong>{element.empNom}<br/>
                                <strong>{"DESDE: "}</strong>{`${element.dmes <10 ? '0'+element.dmes : element.dmes}/${element.danio}`}<br/>
                                <strong>{"JEFE INMEDIATO:"}</strong>{element.jefe}<br/>
                                </div> 
                            <div className="col-md-6 col-sm-12">
                                <strong>{"PUESTO "}</strong>{element.puestoExp}<br/>
                                <strong>{"HASTA: "}</strong>{`${element.hmes <10 ? '0'+element.hmes : element.hmes}/${element.hanio}`}<br/>
                                <strong>{"TELÉFONO: "}</strong>{element.tel}<br/>
                            </div>
                        </Fragment>
                    )):
                    <div className="col-md-12 col-sm-12">
                         {'SIN REGISTRO'}<br/>        
                    </div>
                }
                </div> 
            } 


            <hr/>
            <div className="row" style={{paddingBottom:10}}>
                <div className="col-md-12 col-sm-12">
                    <strong>{"PASATIEMPOS"}</strong>
                </div>
            </div>

            {
                pasatiempos &&
                <div className="row" style={{paddingBottom:10}}>  
                { 
                    pasatiempos.length>0 ?
                    pasatiempos.map(element => (
                        <Fragment key={element.id}>
                            <div className="col-md-6 col-sm-12">
                                {element.pasatiempo}<br/> 
                            </div> 
                        </Fragment>
                    )):
                    <div className="col-md-12 col-sm-12">
                         {'SIN REGISTRO'}<br/>        
                    </div>
                }
                </div> 
            }
            {error}
            <Button variant="contained" className="btn-color" type="submit"> {loading ? <CircularProgress size={25}/> : "Descargar PDF"} </Button> 
        </form>
    );
};

export default FormularioConsultaBusquedaAvanzada;