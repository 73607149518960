import React, { createRef, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, servicios_grupohit_org, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import Button from '@material-ui/core/Button';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import ConvertDate from '../../../Helpers/ConvertDate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';

const CheckboxStatusPlaza = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const FormBajaEmpleados = ({ error, setError, elementSelect, bajaEmpleado, isLoading, setIsLoading, errorTable }) => {

    const [motivosBaja, setMotivosBaja] = useState([]);
    const [status, setStatus] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [muestraFechaReanudacion, setMuestraFechaReanudacion] = useState(false);
    const [mostrarStatusPlaza, setMostrarStatusPlaza] = useState(false);
    const [borrarTitularPlaza, setBorrarTitularPlaza] = useState(false);
    const [infoTitularPlaza, setInfoTitularPlaza] = useState(null);

    const motivoBajaRef = createRef('');
    const descripcionRef = createRef('');
    const fechaBajaRef = createRef('');
    const fechaReanudaRef = createRef('');
    const statusRef = createRef('');

    useEffect(() => {
        axios.get(`${servicios_grupohit_catalogo}/motivobaja/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setMotivosBaja(res.data.dataResponse);
                    break;
                case 404:
                    setMotivosBaja([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

         //obtener listado de Status
        axios.get(`${servicios_grupohit_catalogo}/status/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayStatus = res.data.dataResponse.filter(s => s.id !== 1);
                    setStatus(arrayStatus);
                    break;
                default:
                    setStatus([]);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => {
            setStatus([]);
            errorTable(verifique_conexion);
        });

        axios.get(`${servicios_grupohit_org}/plaza/empleadotitular/${elementSelect.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const infoPlaza = {
                        id: res.data.dataResponse.id,
                        clave: res.data.dataResponse.plazaclave
                    };
                    setInfoTitularPlaza(infoPlaza);
                    break;
                case 404:
                    setInfoTitularPlaza(null);
                    break;
                default:
                    setInfoTitularPlaza(null);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => {
            setInfoTitularPlaza(null);
            errorTable(verifique_conexion);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const muestraAlerta = e => {
        e.preventDefault();
        setError(null);
        if(muestraFechaReanudacion) {
            if(validaFechas() && validarSubmit('BajaEmpleado')) {
                setOpenAlert(true);
            }
        } else {
            if(validaFechaInicial() && validarSubmit('BajaEmpleado')) {
                setOpenAlert(true);
            }
        }
    };

    const baja = () => {
        if(validarSubmit('BajaEmpleado')) {
            const fechaBaja = inputDateToLong(fechaBajaRef.current.value);

            const motivoBajaId = parseInt(motivoBajaRef.current.value);
            const motBaja = descripcionRef.current.value;
            const empPuestoFecfin = (muestraFechaReanudacion) ? fechaBaja-86400000 : fechaBaja;

            let request = { motivoBajaId, empPuestoFecfin, empId: elementSelect.id, setIsLoading };
            
            if(motBaja) request.motBaja = motBaja;
            if(muestraFechaReanudacion) request.fechaReanudacion = inputDateToLong(fechaReanudaRef.current.value);
            
            bajaEmpleado(request, (mostrarStatusPlaza) ? statusRef.current.value : false, borrarTitularPlaza);
            setError(null);
        }
    };

    const verificaFechaReanudacion = element => {
        if(element) {
            setMuestraFechaReanudacion(element.reanudaFechas);
        } else {
            setMuestraFechaReanudacion(false);
        }
    };

    const validaFechas = () => {
        setError(null);
        try {
            const fechaBaja = inputDateToLong(fechaBajaRef.current.value);
            const fechaReanudacion = inputDateToLong(fechaReanudaRef.current.value);
            if(fechaBaja > elementSelect.empstatusFecini) {
                if(fechaReanudacion <= fechaBaja) {
                    setError(<AlertForm message="La fecha de término de licencia debe ser mayor que la fecha de inicio de licencia" />);
                    return false;
                }
            } else {
                setError(<AlertForm message={`La fecha de ${((muestraFechaReanudacion)) ? 'inicio de licencia' : 'baja'} debe ser mayor o igual que la fecha de último ingreso`} />);
                return false;
            }
            return true;
        } catch (error) {
            setError(<AlertForm message="Seleccione ambas fechas" />);
            return false;
        }
    };

    const validaFechaInicial = () => {
        setError(null);
        try {
            const fechaBaja = inputDateToLong(fechaBajaRef.current.value);
            if(fechaBaja < elementSelect.empstatusFecini) {
                setError(<AlertForm message={`La fecha de ${((muestraFechaReanudacion) ? 'inicio de licnecia' : 'baja')} debe ser mayor o igual que la fecha de último ingreso`} />);
                return false;
            } else {
                return true;
            }
        } catch (error) {
            setError(<AlertForm message={`Seleccione la fecha de ${(muestraFechaReanudacion) ? 'inicio de licencia' : 'baja'}`} />);
            return false;
        }
    };

    const handleChangeMuestraStatusPlaza = () => {
        if(!mostrarStatusPlaza) {
            setBorrarTitularPlaza(false);
        }
        setMostrarStatusPlaza(!mostrarStatusPlaza);
    };

    const handleChangeBorrarTitularPlaza = () => {
        setBorrarTitularPlaza(!borrarTitularPlaza);
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-3">
                    <div><label><strong>NUE: </strong>{ elementSelect.empleadoClave }</label></div>
                </div>
                <div className="col-9">
                    <div><label><strong>Nombre del Empleado: </strong>{ elementSelect.nombreCompleto }</label></div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div><label><strong>Fecha de Último Ingreso: </strong>{ ConvertDate(elementSelect.empstatusFecini) }</label></div>
                </div>
            </div>
            <hr/>
            <form id="BajaEmpleado" onSubmit={muestraAlerta}>
                <div className="formRelLab">
                    <div className="form-left">
                        <SelectValidation title="Motivo de Baja" arrayOpciones={motivosBaja} keyOpcion="nombre" onChangeReturnObject={verificaFechaReanudacion} refValue={motivoBajaRef} required/>
                        <InputDateValidation title={((muestraFechaReanudacion)) ? 'Inicio de Licencia' : 'Fecha de Baja'} refValue={fechaBajaRef} defaultValue="" validateGreaterThan={elementSelect.empstatusFecini-21600000} onChangeExecute={() => setError(null)} required/>
                        {(muestraFechaReanudacion) &&
                            <InputDateValidation title="Término de Licencia" refValue={fechaReanudaRef} validateGreaterThan={elementSelect.empstatusFecini} onChangeExecute={() => setError(null)} defaultValue="" required/>
                        }
                    </div>
                    <div className="form-right">
                        <InputValidation
                            title="Descripción" type="descripcionBaja" placeholder="Escriba la descripción"
                            tooltipText={'Máximo 2024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            refValue={descripcionRef} fieldType="textarea" maxLength={2024} optional={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        <FormControlLabel
                            control={<CheckboxStatusPlaza checked={mostrarStatusPlaza} onChange={handleChangeMuestraStatusPlaza} name="checkedStatusPlaza" />}
                            label="Cambiar Status de la Plaza"
                        />
                        {(mostrarStatusPlaza)
                        ?   (status.length)
                            ?   <SelectValidation
                                    title="Status de la plaza" arrayOpciones={status} keyOpcion="statusNom"
                                    refValue={statusRef} defaultValue="" tooltipText="Seleccione el nuevo status de la plaza"
                                    required
                                />
                            :   <SpinnerOval/>
                        :   null
                        }
                    </div>
                    <div className='col'></div>
                </div>
                {(!mostrarStatusPlaza && infoTitularPlaza) &&
                <div className="row">
                    <div className='col'>
                    <FormControlLabel
                        control={<CheckboxStatusPlaza checked={borrarTitularPlaza} onChange={handleChangeBorrarTitularPlaza} name="checkedStatusPlaza" />}
                        label="Borrar Titular de la Plaza"
                    />
                    </div>
                    <div className='col mt-2'>
                            <div><label><strong>Plaza: </strong>{ infoTitularPlaza.clave }</label></div>
                    </div>
                </div>
                }
                { error }
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">Aceptar</Button>
                }
            </form>
            <Alertwarning show={openAlert} SetopenALert={setOpenAlert} text="El empleado será dado de baja" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={baja}/>
        </Fragment>
    );
}
 
export default FormBajaEmpleados;