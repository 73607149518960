
/* SessionReducer: Control de Sesion */
export const GUARDAR_SESSION = 'GUARDAR_SESSION';
export const GUARDA_ADMINISTRADOR = 'GUARDA_ADMINISTRADOR';
export const ERROR_MESSAGE_LOGIN = 'ERROR_MESSAGE_LOGIN';
export const CATCH_ERROR_SERVER_LOGIN = 'CATCH_ERROR_SERVER_LOGIN';
export const CERRAR_SESSION = 'CERRAR_SESSION';


export const GUARDAR_MODULOS_SERVICE = 'GUARDAR_MODULOS_SERVICE';
export const AGREGAR_NAVEGADOR = 'AGREGAR_NAVEGADOR';
export const QUITAR_NAVEGADOR = 'QUITAR_NAVEGADOR';
export const CAMBIAR_COMPONENTE_ACTIVO = 'CAMBIAR_COMPONENTE_ACTIVO';
export const LIMPIAR_PESTANAS = 'LIMPIAR_PESTANAS';

/* EMPLEADOS */
export const GUARDA_EMPLEADOS_ALL = 'GUARDA_EMPLEADOS_ALL';
export const GUARDA_EMPLEADOS_ALL_ERROR = 'GUARDA_EMPLEADOS_ALL_ERROR';
export const GUARDA_EMPLEADOS_ACTIVOS = 'GUARDA_EMPLEADOS_ACTIVOS';
export const GUARDA_EMPLEADOS_ACTIVOS_ERROR = 'GUARDA_EMPLEADOS_ACTIVOS_ERROR';
export const GUARDA_EMPLEADOS_INACTIVOS = 'GUARDA_EMPLEADOS_INACTIVOS';
export const GUARDA_EMPLEADOS_INACTIVOS_ERROR = 'GUARDA_EMPLEADOS_INACTIVOS_ERROR';
export const BORRA_EMPLEADOS_ALL = 'BORRA_EMPLEADOS_ALL';
export const BORRA_EMPLEADOS_ACTIVOS = 'BORRA_EMPLEADOS_ACTIVOS';
export const BORRA_EMPLEADOS_INACTIVOS = 'BORRA_EMPLEADOS_INACTIVOS';
export const AGREGA_EMPLEADO = 'AGREGA_EMPLEADO';
export const EDITA_EMPLEADO = 'EDITA_EMPLEADO';
export const EDITA_EMPLEADO_INACTIVO = 'EDITA_EMPLEADO_INACTIVO';
export const BAJA_EMPLEADO = 'BAJA_EMPLEADO';
export const CANCELA_BAJA_EMPLEADO = 'CANCELA_BAJA_EMPLEADO';
export const REINGRESO_EMPLEADO = 'REINGRESO_EMPLEADO';
export const ELIMINA_EMPLEADO = 'ELIMINA_EMPLEADO';
export const CAMBIOS_ESCALAFON_DEFINITIVO = 'CAMBIOS_ESCALAFON_DEFINITIVO';
export const CAMBIOS_ESCALAFON_TEMPORAL = 'CAMBIOS_ESCALAFON_TEMPORAL';
export const TERMINA_ESCALAFON = 'TERMINA_ESCALAFON';
export const CAMBIOS_PERMUTA_TRANSFERENCIA = 'CAMBIOS_PERMUTA_TRANSFERENCIA';
export const DESHACER_REINGRESO = 'DESHACER_REINGRESO';
export const ACTUALIZA_EMPLEADO_ESPECIALES = 'ACTUALIZA_EMPLEADO_ESPECIALES';
export const BORRA_EMP_PAGOS_PENDIENTES = 'BORRA_EMP_PAGOS_PENDIENTES';
export const MUESTRA_EMP_PAGOS_PENDIENTES = 'MUESTRA_EMP_PAGOS_PENDIENTES';

export const CAMBIA_ESTADO_MENU = 'CAMBIA_ESTADO_MENU';

export const GUARDA_PERIODO_ACTUAL = 'GUARDA_PERIODO_ACTUAL';
export const LIMPIA_PERIODO_ACTUAL = 'LIMPIA_PERIODO_ACTUAL';
