import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { cambios_no_guardados, registro_actualizado, servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadoresGenerales, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormIndicadoresGenerales from './FormIndicadoresGenerales';
import { amountToFloat } from '../../../../../../Services/Validation/HelperValidation';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditIndicadoresGenerales = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, successTable, nombreModulo }) => {

    const [error, setError] = useState(null);
    const [ numeroVista, setNumeroVista ]= useState(1);
    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null); 
    const [isLoading, setIsLoading] = useState(false);
    const [ejecutaER, setEjecutaER] = useState(false);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
        setError(null);
        setNumeroVista(1);
        setIndicadoresGenerales(null); 
        setIsLoading(false);
        setEjecutaER(false);
    };
    
    useEffect(() => {
        if(elementSelect?.id) {
            Axios.get(`${servicios_grupohit_catalogo}/indicador/getall/${elementSelect.relacionLabId}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const indicadores = ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse);
                        setIndicadoresGenerales(indicadores);
                        break;
                    case 404:
                        setIndicadoresGenerales([]);
                        break;
                    default:
                        errorTable(res.data.msg );
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));
        }
          // eslint-disable-next-line
    }, [elementSelect]);

    const validarCambiosConcepto = indicadorGeneral => {
        if(
            (((indicadorGeneral.cuentas?.ctacontable) ? indicadorGeneral.cuentas?.ctacontable : '') !== elementSelect.cuentaCon) ||
            (((indicadorGeneral.cuentas?.partidaesp) ? indicadorGeneral.cuentas?.partidaesp : '') !== elementSelect.partidaEsp) ||
            (((indicadorGeneral.cuentas?.partidagen) ? indicadorGeneral.cuentas?.partidagen : '') !== elementSelect.partidaGen) ||
            (((indicadorGeneral.cuentas?.subcta) ? indicadorGeneral.cuentas?.subcta : '') !== elementSelect.subcuentaCon) ||
            (indicadorGeneral.defecto !== elementSelect.defecto) ||
            (indicadorGeneral.indicadorClave !== elementSelect.indicadorClave) ||
            (indicadorGeneral.indicadorNom !== elementSelect.indicadorNom) ||
            (indicadorGeneral.indicadorSat !== elementSelect.indicadorSat) ||
            (((indicadorGeneral.montoMax) ? indicadorGeneral.montoMax : '') !== ((elementSelect.montoMax) ? amountToFloat(elementSelect.montoMax) : '')) ||
            (((indicadorGeneral.montoMin) ? indicadorGeneral.montoMin : '') !== ((elementSelect.montoMin) ? amountToFloat(elementSelect.montoMin) : '')) ||
            (indicadorGeneral.nombramiento !== elementSelect.nombramiento) ||
            (indicadorGeneral.prioridad !== elementSelect.prioridad) ||
            (indicadorGeneral.recibo !== elementSelect.recibo) ||
            (indicadorGeneral.rutinaId !== elementSelect.rutinaId) ||
            (indicadorGeneral.sueldo !== elementSelect.sueldo)
        ) {
            return true;
        }
        return false;
    };

    const validarCambiosParametrosConcepto = indicadorParametros => {
        if(
            (indicadorParametros?.id !== elementSelect?.idParametro) ||
            (indicadorParametros?.indicador !== elementSelect?.idParametroConcepto) ||
            (indicadorParametros?.topVeces !== elementSelect?.topeVeces) ||
            (indicadorParametros?.zonaId !== elementSelect?.zonaId)
        ) {
            return true;
        }
        return false;
    };

    const validaAccionIndicadorParam = async (indicadorGeneralId, indicadorParametros, shouldUpdateIndParamet) => {
        if(shouldUpdateIndParamet) {
            if(indicadorParametros!==null) {
                return await actualizarIndicadorParametros(indicadorGeneralId, indicadorParametros);
            } else {
                return await eliminarIndicadorParametros(indicadorGeneralId);
            }
        } else {
            if(indicadorParametros!==null) return await enviarIndicadorParametros(indicadorGeneralId, indicadorParametros);
        }
    };

    const actualizarIndicadorGeneral = async (indicadorGeneral, indicadorGeneralId, indicadorParametros, shouldUpdateIndParamet ) => {
        const validacionCambiosConcepto = validarCambiosConcepto(indicadorGeneral);
        const validacionCambiosParametrosConcepto = validarCambiosParametrosConcepto(indicadorParametros);
        if(validacionCambiosConcepto || validacionCambiosParametrosConcepto) {
            setIsLoading(true);
            setError(null);
            const errores = [null, null];
            if(validacionCambiosConcepto) {
                const info = indicadorGeneral;
                await Axios.put( `${servicios_grupohit_catalogo}/indicador/${indicadorGeneralId}`, info, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const nuevoIndicador = JsonIndicadoresGenerales(res.data.dataResponse);
                            editar(nuevoIndicador);
                            errores[0] = false;
                        break;
                        default:
                            errores[0] = `Concepto - ${res.data.msg}`;
                            break;
                    }
                })
                .catch(error => { errores[0] = `Concepto - ${verifique_conexion}` });
            }
            
            if(validacionCambiosParametrosConcepto) {
                await validaAccionIndicadorParam(indicadorGeneralId, indicadorParametros, shouldUpdateIndParamet)
                .then(res => {
                    if(res.data.status === 200) {
                        errores[1] = false;
                    } else {
                        errores[1] = `Exento Requerido - ${res.data.msg}`;
                    }
                })
                .catch(error => {
                    console.log(error)
                    errores[1] = `Exento Requerido - ${verifique_conexion}`;
                })
            }

            if((errores[0] === false) && (errores[1] === false)) {
                successTable(registro_actualizado);
                cerrarModal();
            } else if((errores[0] !== null) && (errores[1] !== null)) {
                if(errores[1] === false) {
                    setEjecutaER(true);
                    if((!shouldUpdateIndParamet )|| (shouldUpdateIndParamet && (indicadorParametros!==null))) {
                        elementSelect.idParametro = indicadorParametros.id;
                        elementSelect.idParametroConcepto = indicadorParametros.indicador;
                        elementSelect.topeVeces = indicadorParametros.topVeces;
                        elementSelect.zonaId = indicadorParametros.zonaId;
                    }
                    errorTable([`Registro actualizado con errores:`].concat(errores[0]));
                } else if(errores[0] === false) {
                    errorTable([`Registro actualizado con errores:`].concat(errores[1]));
                } else {
                    errorTable([`${cambios_no_guardados}. Errores en:`].concat(errores));
                }
            } else if(errores[0] !== null) {
                if(errores[0] === false) {
                    cerrarModal();
                    successTable(registro_actualizado);
                } else {
                    errorTable(errores[0]);
                }
            } else if(errores[1] !== null) {
                if(errores[1] === false) {
                    cerrarModal();
                    successTable(registro_actualizado);
                } else {
                    errorTable(errores[1]);
                }
            }
            setIsLoading(false);
        } else {
            setError(<AlertForm message="¡No hay cambios por guardar!"/>);
        }
    };

    const actualizarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {indicadorId: idIndicador, id:indicadorParametros.id, zonaId: indicadorParametros.zonaId, indicador: indicadorParametros.indicador, topVeces: indicadorParametros.topVeces };
        
        return await Axios.put( `${servicios_grupohit_catalogo}/indicadores/parametros/`, newData, headersAuth())
    };

    const eliminarIndicadorParametros = async idIndicador => {
        alert(`Aquí se debe ejecutar el servicio de eliminar del indicador Id ${idIndicador}`);
        /*await Axios.delete(`${servicios_grupohit_catalogo}/indicadores/parametros/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(res.data.msg +" en Parámetros Conceptos");
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion) });*/
    };

    const enviarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {indicadorId: idIndicador, zonaId: indicadorParametros.zonaId, indicador: indicadorParametros.indicador, topVeces: indicadorParametros.topVeces };
    
        return await Axios.post( `${servicios_grupohit_catalogo}/indicadores/parametros/`, newData, headersAuth())
    };

    const vista = (numero) =>{
        setNumeroVista(numero);
    }


    return (
        <ModalService title={numeroVista===1 ? "Actualizar Concepto General" : "Configuración de Concepto"}
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormIndicadoresGenerales
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarIndicadorGeneral={actualizarIndicadorGeneral}
                        vista={vista}
                        idModulo={nombreModulo}
                        indicadoresGeneral={indicadoresGenerales}
                        isLoading={isLoading}
                        ejecutaER={ejecutaER}
                        setEjecutaER={setEjecutaER}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditIndicadoresGenerales;