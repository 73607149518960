import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import DataTableService from '../../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonHistoricoAntiguedad } from '../../../../Helpers/JsonToOneLevel';

const TablaAntiguedad = ({ id, permisos, idEmpleado, nombreEmpleado, setTextAlertError, setOpenAlertError }) => {

    const [historicoAntiguedad, setHistoricoAntiguedad] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupohit_rh}/empleado/antiguedad/${idEmpleado}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const infoHistorico = ArrayJsonHistoricoAntiguedad(res.data.dataResponse);
                    setHistoricoAntiguedad(infoHistorico);
                    break;
                default:
                    setHistoricoAntiguedad([]);
                    setTextAlertError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => {
            setHistoricoAntiguedad([]);
            setTextAlertError(verifique_conexion);
            setOpenAlertError(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id={`modulo${id}`}>
            {   
                (historicoAntiguedad)
                ?   <DataTableService
                        id={`${id}antiguedad`}
                        title={`Histórico de Antigüedad de ${nombreEmpleado}`}
                        columns={[
                            { field: "empantfecini", title: "Fecha Inicial" },
                            { field: "empantfecfin", title: "Fecha Final" },
                        ]}
                        hideIconAdd hideIconDelete
                        permisos={permisos}
                        data={historicoAntiguedad}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default TablaAntiguedad;