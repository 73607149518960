import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputValidation from '../../../../../Services/Validation/InputValidation';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import TooltipCheckbox from '../../../../../Services/Tooltip/TooltipCheckbox';

const CheckboxComponent = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const CheckboxHistorico9719 = ({ checked, name, label, inputType, inputRef, handleChange, onlyLetters, onlyNumbers, alfanumeric, tooltip }) => {

    return (
        <div className="row">
            <div className="col-12">
                <FormControlLabel
                    control={<CheckboxComponent checked={checked} onChange={handleChange} name={name}/>}
                    label={(tooltip) ? <TooltipCheckbox label={label} tooltipText={tooltip}/> : label}
                />
            </div>
            <div className="col-12">
            {(checked)
            ?   <InputValidation
                    type={inputType} refValue={inputRef} placeholder={label}
                    defaultValue="" size='95%' optional={(checked) ? false : true}
                    onlyLetters={onlyLetters} onlyNumbers={onlyNumbers} alfanumeric={alfanumeric}
                />
            :   null
            }
            </div>
        </div>
    );
}
 
export default CheckboxHistorico9719;