import React from 'react';
import { pagos_unicos } from '../../../../../../Constants/Constants';
import ModalService from "../../../ModalService/ModalService";
import FormModalPagosUnicos from './FormModalPagosUnicos';

const ModalPagosUnicos = ({ nombreModulo, elementSelect, openModal, setOpenModal, errorTable, informacionAdicional }) => {
    
    const { permisos } = informacionAdicional;

    const cerrarModal = () => {
        setOpenModal(false);
    };
    
    return (
        <ModalService
            title={pagos_unicos} parentNode={nombreModulo} minWidth="80%"
            isOpen={openModal} onClose={cerrarModal}
        >
            <FormModalPagosUnicos permisos={permisos} nombreModulo={nombreModulo} empleado={elementSelect} errorTable={errorTable} cerrarModal={cerrarModal}/>
        </ModalService>
    );
};

export default ModalPagosUnicos;