import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_rh, politicas_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonPrimaVacacional, ArrayJsonAguinaldo, ArrayJsonDiasAjuste, ArrayJsonVacaciones, ArrayJsonAusentismosPoliticas } from '../../../Helpers/JsonToOneLevel';
import { inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';

const Politicas = ({ id, permisos }) => {
    
    const [politicasAll, setPoliticasAll] = useState(null);
    const [ primaVacacional, setPrimaVacacional ] = useState(null);
    const [ aguinaldo, setAguinaldo ] = useState(null);
    const [ vacaciones, setVacaciones ] = useState(null);
    const [ diasAjuste, setDiasAjuste ] = useState(null);
    const [ ausentismos, setAusentismos ] = useState(null);
    const [ openAlertError, setOpenAlertError ] = useState(false);
    const [ msgAlertError, setMsgAlertError ] = useState('');

    useEffect(()=>{
        Axios.get(`${servicios_grupohit_rh}/politica/prima/vacacional/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setPrimaVacacional( ArrayJsonPrimaVacacional(res.data.dataResponse) );
                    break;
                case 404:
                    setPrimaVacacional([]);
                    break;
                default:
                    setMsgAlertError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => {
            setMsgAlertError(verifique_conexion);
            setOpenAlertError(true);
        }); 

        Axios.get(`${servicios_grupohit_rh}/politica/aguinaldo/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setAguinaldo( ArrayJsonAguinaldo(res.data.dataResponse) );
                    break;
                case 404:
                    setAguinaldo([]);
                    break;
                default:
                    setMsgAlertError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => {
            setMsgAlertError(verifique_conexion);
            setOpenAlertError(true);
        }); 

        Axios.get(`${servicios_grupohit_rh}/politica/vacaciones/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setVacaciones( ArrayJsonVacaciones(res.data.dataResponse) );
                    break;
                case 404:
                    setVacaciones([]);
                    break;
                default:
                    setMsgAlertError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => {
            setMsgAlertError(verifique_conexion);
            setOpenAlertError(true);
        });

        Axios.get(`${servicios_grupohit_rh}/politica/dias/ajuste/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setDiasAjuste( ArrayJsonDiasAjuste(res.data.dataResponse) );
                    break;
                case 404:
                    setDiasAjuste([]);
                    break;
                default:
                    setMsgAlertError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => {
            setMsgAlertError(verifique_conexion);
            setOpenAlertError(true);
        });

        Axios.get(`${servicios_grupohit_rh}/politica/ausentismos/getall/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setAusentismos(ArrayJsonAusentismosPoliticas(res.data.dataResponse));
                    break;
                case 404:
                    setAusentismos([]);
                    break;
                default:
                    setMsgAlertError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => {
            setMsgAlertError(verifique_conexion);
            setOpenAlertError(true);
        });

    },[]);
    
    useEffect(() => {
        const politicas = (...arrays) => [].concat(...arrays.filter(Array.isArray));
        setPoliticasAll(politicas(primaVacacional, aguinaldo, vacaciones, diasAjuste, ausentismos));
    }, [primaVacacional, aguinaldo, vacaciones, diasAjuste, ausentismos]);

    return (
        <div id={`modulo${id}`}>
            {
                (primaVacacional && aguinaldo && vacaciones && diasAjuste && ausentismos)
                ? <DataTableService id={id} title={ politicas_ }
                        permisos={permisos} 
                        columns={[
                            { field: "tipo", title: "Tipo" },
                            { field: "fechaIni", title: "Fecha Inicial" },
                            { field: "fechaFin", title: "Fecha Final" } 
                        ]}
                        data={politicasAll.sort((a, b)=> inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 :0 )}
                    />
                    
                : <SpinnerOval/>
            }
             <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Petición Fallida" text={msgAlertError} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
}
 
export default Politicas;