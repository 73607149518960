import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_plantillas, plantillas_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonPlantillas } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import AssignPlantilla from './AssignPlantilla';
import EditPlantilla from './EditPlantilla';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Plantillas = ({ id, permisos }) => {

    const [plantillas, setPlantillas] = useState(null);
    const [mostrarElemento, setMostrarElemento] = useState('none');
    const [item, setItem] = useState({ id: 0 });
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        axios.get(`${servicios_grupohit_plantillas}/all/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newPlantillas = ArrayJsonPlantillas(res.data.dataResponse)
                    setPlantillas(newPlantillas);
                    break;
                case 404:
                    setPlantillas([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    const mostrarComponente = (item = { id: 0 }, type = 'none', dataTable) => {
        setItem(item);
        setMostrarElemento(type);
        if(dataTable) {
            setPlantillas(dataTable);
        }
    };

    return (
        <div id={`modulo${id}`}>
            {   
                (mostrarElemento !== 'none')
                ?   (mostrarElemento === 'asignar')
                    ?   <AssignPlantilla nombreModulo={id} plantilla={{ id: item.id, nombre: item.plantillaNom }} onShowTable={mostrarComponente}/>
                    :   (mostrarElemento === 'editar')
                        ?   <EditPlantilla item={item} onShowTable={mostrarComponente} plantillas={plantillas} setPlantillas={setPlantillas}/>
                        :   null
                :   (plantillas)
                    ?   <DataTableService id={id} title={plantillas_}
                            columns={[
                                { field: "plantillaClave", title: "Clave" },
                                { field: "plantillaNom", title: "Nombre" },
                                { field: "plantillaDesc", title: "Descripción" },
                            ]}
                            data={plantillas}
                            permisos={permisos}
                            onShowTable={mostrarComponente}
                            showIconPostAdd
                            showIconChromeReaderMode
                        />
                    :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default Plantillas;
