//convierte una fecha en milisegundos a dd/mm/aaaa
export default function ConvertDate(miliseconsDate) {
    const date = new Date(miliseconsDate);
    
    const anio = (date.getUTCFullYear() < 10)
                ?   `000${date.getUTCFullYear()}`
                :   (date.getUTCFullYear() < 100)
                    ? `00${date.getUTCFullYear()}`
                    :   (date.getUTCFullYear() < 1000)
                        ?   `0${date.getUTCFullYear()}`
                        :   date.getUTCFullYear();
    const mes = ((date.getUTCMonth()+1) > 9) ? date.getUTCMonth()+1 : `0${date.getUTCMonth()+1}`;
    const dia = (date.getUTCDate() > 9) ? date.getUTCDate() : `0${date.getUTCDate()}`;

    return `${dia}/${mes}/${anio}`;

};

export function ConvertDateWithoutUTC(miliseconsDate) {
    const date = new Date(miliseconsDate);

    const anio = (date.getFullYear() < 10)
                ?   `000${date.getFullYear()}`
                :   (date.getFullYear() < 100)
                    ? `00${date.getFullYear()}`
                    :   (date.getFullYear() < 1000)
                        ?   `0${date.getFullYear()}`
                        :   date.getFullYear();
    const mes = ((date.getMonth()+1) > 9) ? date.getMonth()+1 : `0${date.getMonth()+1}`;
    const dia = (date.getDate() > 9) ? date.getDate() : `0${date.getDate()}`;

    return `${dia}/${mes}/${anio}`;
};

export function ConvertDateTime(miliseconsDate, utc=true) {
    const date = new Date(miliseconsDate);
    let dateFormat = '';

    if(utc) {
        dateFormat = ConvertDate(miliseconsDate);
    } else {
        dateFormat = ConvertDateWithoutUTC(miliseconsDate);
    }

    const horas = (date.getHours() > 9) ? date.getHours() : `0${date.getHours()}`;
    const minutos = (date.getMinutes() > 9) ? date.getMinutes() : `0${date.getMinutes()}`;
    const segundos = (date.getSeconds() > 9) ? date.getSeconds() : `0${date.getSeconds()}`;

    return `${dateFormat} ${horas}:${minutos}:${segundos}`;

};

export function returnCadenaMes( mesString ) {
    switch( mesString ){
        case "01": return "Enero";
        case "02": return "Febrero";
        case "03": return "Marzo";
        case "04": return "Abril";
        case "05": return "Mayo";
        case "06": return "Junio";
        case "07": return "Julio";
        case "08": return "Agosto";
        case "09": return "Septiembre";
        case "10": return "Octubre";
        case "11": return "Noviembre";
        case "12": return "Diciembre";
        default: return mesString;
    }
}