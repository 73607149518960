import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { registro_sera_actualizado, servicios_grupohit_org, servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import ModalService from '../../../../ModalService/ModalService';
import Button from '@material-ui/core/Button';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';

const ModalEditPuesto = ({ nombreModulo, openEdit, setOpenEdit, elementSelect, errorTable, editar }) => {

    const [puestos, setPuestos] = useState(null);
    const [puestoSelected, setPuestoSelected] = useState(null);
    const [errorPuestoSelected, setErrorPuestoSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        if(elementSelect.id) {
            axios.get(`${servicios_grupohit_org}/puesto/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayPuestos = res.data.dataResponse.map(puesto => ({
                            id: puesto.id,
                            puestoCve: puesto.puestoCve,
                            puestoNom: puesto.puestoNom,
                            puestoCveNom: `${puesto.puestoCve} / ${puesto.puestoNom}`,
                            nombramiento: puesto.nombramiento
                        }));
                        setPuestos(arrayPuestos.filter(i => i.puestoCve !== elementSelect.puestoClave));
                    break;
                    default:
                        setPuestos([]);
                        cerrarModal();
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => {
                setPuestos([]);
                cerrarModal();
                errorTable(verifique_conexion);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const cerrarModal = () => {
        setPuestos(null);
        setPuestoSelected(null);
        setErrorPuestoSelected(false);
        setIsLoading(false);
        setOpenEdit(false);
    };

    const abrirAlerta = e => {
        e.preventDefault();
        if(puestoSelected) {
            setErrorPuestoSelected(false);
            setOpenAlert(true);
        } else {
            setErrorPuestoSelected(true);
        }
    };

    const validaPuesto = puesto => {
        setPuestoSelected(puesto);
    };

    const actualizarPuesto = () => {
        setIsLoading(true);
        const request = {
            id: elementSelect.id,
            puestoId: puestoSelected.id
        };
 
        axios.put(`${servicios_grupohit_rh}/empleado/puesto/cambiar/puesto/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newPuesto = { 
                        id: elementSelect.id,
                        fechaInicial: elementSelect.fechaInicial,
                        fechaFinal: elementSelect.fechaFinal,
                        puestoClave: res.data.dataResponse.puesto.puestoCve,
                        puestoNombre: res.data.dataResponse.puesto.puestoNom
                    };
                    editar(newPuesto);
                    cerrarModal();
                break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService
            title="Actualizar Puesto"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <form id="editHistoricoPuesto" onSubmit={abrirAlerta}>
                        {puestos === null
                        ?   <SpinnerOval />
                        :   <AutocompleteComponent
                                id="PuestoHistorico" title="Puesto" options={puestos} optionListView="puestoCveNom"
                                tooltipText="Busque un puesto, por clave o nombre, en el campo de autocompletado" error={errorPuestoSelected}
                                action={validaPuesto} disabled={puestos.length === 0} placeholder="Puesto" value={puestoSelected} required
                            />
                        }
                        {(isLoading)
                        ?   <SpinnerOval />
                        :   <Button variant="contained" className="btn-color" type="submit">Actualizar</Button>
                        }
                    </form>
                </div>
            </div>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizarPuesto}/>
        </ModalService>
    );
}
 
export default ModalEditPuesto;