import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_rh, movimientos_pendientes } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import PendientesEmpleado from './PendientesEmpleado/PendientesEmpleado';
import InputText from '../../../../../../Services/Components/DataTable/InputText';

const MovimientosPendientes = ({ id }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    /* ----------------------------- Axios para opbtener empleados activos ----------------------------- */
    const [ empleados, setEmpleados ] = useState( null );
    useEffect(() => {
        axiosEmpleados();
    }, []);

    const axiosEmpleados = () => {
        axios.get( `${servicios_grupohit_rh}/movimientos/pendiente/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEmpleados( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setEmpleados( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------- */
    const onShowTable = ( elementSelect ) => {
        setElementSelect( elementSelect );
    }

    return (
        <div id={`modulo${id}`}>
            { elementSelect ?
                <PendientesEmpleado empleado={elementSelect} setEmpleado={setElementSelect} idModulo={ id }/>
                :
                <React.Fragment>
                    { empleados ?
                        <DataTableService id={id} title={movimientos_pendientes}
                            columns={[
                                { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                                { field: "nombreCompleto", title: "Nombre" },
                                { field: "empleadoCurp", title: "CURP" },
                                { field: "empleadoRfc", title: "RFC" },
                                { field: "empleadoNss", title: "NSS" },
                                //{ field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia" },
                                { field: "puestoCve", title: "Clave Puesto" },
                                { field: "puestoNom", title: "Puesto" },
                                { field: "nominaNombre", title: "Nómina" },
                                { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                                { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                            ]}
                            hideIconAdd hideIconEdit hideIconDelete
                            showIconDescription onShowTable={onShowTable}
                            data={ empleados } />
                        :
                        <SpinnerOval />
                    }
                </React.Fragment>
            }
        </div>
    );
};

export default MovimientosPendientes;