import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, servicios_grupohit, servicios_grupohit_rh, error_reporte } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import ModalService from '../../../ModalService/ModalService'; 
import FormularioNewReportNomina from './FormularioNewReportNomina';
import AlertForm from '../../../../../../Services/AlertForm';
import { ArrayJsonEmpleadosReportesNominas } from '../../../Helpers/JsonToOneLevel'; 
import { servicioTotalNuevoReporteNominas } from './Servicios/NuevoReporteNominas';

const ModalNewReportNomina = ({ openNewReportNomina, setOpenNewReportNomina, setElementSelect, nombreModulo, setShowReport, setInfoReport, downloadReportDefinido, setDownloadReportDefinido, infoReport, isConsolidado }) => {
    const [ error, setError ] = useState( null ); 
    const [ isLoading, setIsLoading ] = useState( false );
    const  campos = infoReport
                    ?   infoReport.id === 2 || infoReport.id === 143 || infoReport.id === 151
                        ?   ["nominas","agnio", "periodo", "tipoAcumulado"]
                        :
                        infoReport.id === 3
                        ?   ["nominasAll","agnio", "periodo", "tipoAcumulado"]
                        : 
                        infoReport.id === 4
                        ?   ["nominas","agnio", "periodo", "tipoAcumulado", "concepto"]
                        :  
                        infoReport.id === 8
                        ?   ["agnio", "periodo", "concepto"]
                        :
                        infoReport.id === 145 ||  infoReport.id === 149 || infoReport.id === 150 || infoReport.id === 162 || infoReport.id === 179 || infoReport.id === 181 || infoReport.id === 184 || infoReport.id === 185
                        ?   ["agnio", "periodo"]
                        :   
                        infoReport.id === 168
                        ?   ["agnio", "periodo", "naturaleza"]
                        :   
                        infoReport.id === 159
                        ?   ["nominasCheck", "conceptosCheck", "agnio", "periodo"]
                        :   
                        infoReport.id === 163
                        ?   ["nominasCheck", "agnio", "periodo", "mensaje"]
                        :
                        infoReport.id === 164 || infoReport.id === 165 || infoReport.id === 169 || infoReport.id === 171 || infoReport.id === 180 || infoReport.id === 183 || infoReport.id === 187 || infoReport.id === 188 || infoReport.id === 189 || infoReport.id === 190 || infoReport.id === 192 || infoReport.id === 193
                        ?   ["nominasCheck", "agnio", "periodo"]
                        :   
                        infoReport.id === 167 || infoReport.id === 194
                        ?   ["nominasCheck", "agnio", "periodo", "onlyConcepto"]
                        :
                        infoReport.id === 170
                        ?   ["agnio", "periodo", "clc"]
                        :
                        infoReport.id === 182
                        ?   ["nominasAll", "conceptosList", "agnio", "periodo"]
                        :
                        infoReport.id === 186
                        ?   ["nominas","agnio", "periodo"]
                        :
                        infoReport.id === 191
                        ?   ["nominasCheck", "conceptosList", "agnio", "periodo"]
                        :   ["nominas"]
                    :   ["nominas","agnio", "periodo"];

    const cerrarModal = () => {
        setInfoReport(null);
        setOpenNewReportNomina( false );
        setDownloadReportDefinido(false);
        setError( null );
        setIsLoading(false);
    };

    const descargaPDF = (nombre, reporteBase64) => {
        const linkSource = `data:application/pdf;base64,${reporteBase64}`;
        const downloadLink = document.createElement("a");
        const fileName =`${nombre}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const descargaXLS = (nombre, reporteBase64, ext) => {
        const linkSource = `data:application/vnd.ms-excel;base64,${reporteBase64}`;
        const downloadLink = document.createElement("a");
        const fileName =`${nombre}.${ext}`; 
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const enviarNewReportNomina = ( data, acumulado=null ) => {
        setIsLoading(true);
        setError( null );

        if(acumulado) {
            axios.get( `${servicios_grupohit_nomina}/vacumperxnom/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}/${acumulado}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setInfoReport(data);
                        const newData = ArrayJsonEmpleadosReportesNominas( respuesta.data.dataResponse )
                        newData[newData.length-1].isStatic = (infoReport && infoReport.id) === 143;
                        setElementSelect(newData); 
                        setDownloadReportDefinido(false);
                        setOpenNewReportNomina( false );
                        setShowReport(true);
                        setError( null ); 
                        setIsLoading(false);
                        break;
                    default:
                        setError(<AlertForm message={respuesta.data.msg} />); 
                        setIsLoading(false);
                        break;
                }
            })
            .catch( error => {
                console.log(error)
                setError(<AlertForm message={error_reporte} />); 
                setIsLoading(false); 
            });
        } else {
            servicioTotalNuevoReporteNominas({ data, infoReport, setElementSelect, setInfoReport, setDownloadReportDefinido, setOpenNewReportNomina, setShowReport, setError, setIsLoading });
        }
    };

    const enviarReportDownload = (data, type) => {
        setIsLoading(true);
        setError( null ); 
        let direccion = '';
        switch(infoReport.id) {
            case 2:
                direccion = `${servicios_grupohit}/generar/reportes/${data.nominaId}/${data.periodoanio}/${data.periodoperanio}/${data.acumulado}`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false); 
                                break;
                        }
                    })
                    .catch( error => {
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
            break;
            case 3:
                direccion = `${servicios_grupohit}/generar/reportes/horizontal/${data.nominaId}/${data.periodoanio}/${data.periodoperanio}/${data.acumulado}`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion,null, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => {
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break;
            case 4:
                direccion = `${servicios_grupohit}/generar/reportes/${data.nominaId}/${data.periodoanio}/${data.periodoperanio}/${data.acumulado}/${data.concepto}`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => {
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break;
            case 5:
                direccion = `${servicios_grupohit_rh}/movimientos/fijo/reporte/variable/excel/${data.nominaId}`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){ 
                            case 200:
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => {
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break;
            case 6:
                direccion = `${servicios_grupohit_rh}/movimientos/fijo/reporte/excel/${data.nominaId}`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }  
                    })
                    .catch( error => { 
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break;
            case 8:
                direccion = `${servicios_grupohit}/generar/reportes/nomina/excel/`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }  
                    })
                    .catch( error => { 
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break
            case 145:
                direccion = `${servicios_grupohit}/generar/reportes/periodo/conceptos/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post( `${direccion}${type}${(isConsolidado && (type==='pdf')) ? '' : '/'}`, data, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:
                                if(type === 'pdf') descargaPDF(infoReport.nombre, respuesta.data.dataResponse)
                                if(type === 'xls') descargaXLS(infoReport.nombre, respuesta.data.dataResponse, 'xlsx')
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }  
                    })
                    .catch( error => { 
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break
            case 149:
                direccion = `${servicios_grupohit}/generar/reportes/periodo/total/conceptos/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post( direccion, data, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }  
                    })
                    .catch( error => { 
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break;
            case 151:
                direccion = `${servicios_grupohit}/generar/reportes/empleado/recibos/`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                    .then(respuesta => { 
                        switch(respuesta.data.status) {
                            case 200:
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName = `${infoReport.nombre}.xlsx`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            default:
                                setError(<AlertForm message={respuesta.data.msg} />); 
                                setIsLoading(false);
                                break;
                        }  
                    })
                    .catch( error => { 
                        setError(<AlertForm message={error_reporte} />); 
                        setIsLoading(false); 
                    });
                break;
            case 150:
                direccion = `${servicios_grupohit}/generar/reportes/periodo/total/perded/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch( error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 159:
                direccion = `${servicios_grupohit}/generar/reportes/totalesxnomina/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post( `${direccion}${type}${(isConsolidado && (type==='pdf')) ? '' : '/'}`, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            if(type === 'pdf') descargaPDF(infoReport.nombre, respuesta.data.dataResponse)
                            if(type === 'xls') descargaXLS(infoReport.nombre, respuesta.data.dataResponse, 'xlsx')
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch( error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 162:
                direccion = `${servicios_grupohit}/generar/reportes/totalperded/secretaria/`
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status ){
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch( error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 163:
                direccion = `${servicios_grupohit}/reportes/nomina/reciboNomina`
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:text/plain;base64,${res.data.dataResponse.recibo}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.txt`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 164:
                direccion = `${servicios_grupohit}/reportes/nomina/listadoAlfa`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xls`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 165:
                direccion = `${servicios_grupohit}/generar/reportes/pensiones/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg}/>); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message="No se pudo descargar el reporte."/>); 
                    setIsLoading(false); 
                });
                break;
            case 167:
                direccion = `${servicios_grupohit}/reportes/nomina/listadoAlfa/concepto`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xls`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg}/>); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message="No se pudo descargar el reporte."/>); 
                    setIsLoading(false); 
                });
                break;
            case 168:
                direccion = `${servicios_grupohit}/reportes/nomina/totalSecretaria/naturaleza`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xls`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg}/>); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message="No se pudo descargar el reporte."/>); 
                    setIsLoading(false); 
                });
                break;
            case 169:
                direccion = `${servicios_grupohit}/reportes/nomina/pagoNomina`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xls`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg}/>); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message="No se pudo descargar el reporte."/>); 
                    setIsLoading(false); 
                });
                break;
            case 170:
                direccion = `${servicios_grupohit}/generar/reportes/clc/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xls`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg}/>); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message="No se pudo descargar el reporte."/>); 
                    setIsLoading(false); 
                });
                break;
            case 171:
                direccion = `${servicios_grupohit}/reportes/nomina/listadoAlfa/liquido/quinquenio`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xls`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 179:
                direccion = `${servicios_grupohit}/generar/reportes/totalperded/relab/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post( `${direccion}${type}`, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            if(type === 'pdf') descargaPDF(infoReport.nombre, respuesta.data.dataResponse)
                            if(type === 'xls') descargaXLS(infoReport.nombre, respuesta.data.dataResponse, 'xlsx')
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch( error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 180:
                direccion = `${servicios_grupohit}/generar/reportes/pensiones/beneficiarios`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post(direccion, data, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${res.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xls`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                })
                break;
            case 181:
                direccion = `${servicios_grupohit}/generar/reportes/totalperded/secretaria/relab/`;
                if(isConsolidado) direccion = `${direccion}consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch( error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 182:
                direccion = `${servicios_grupohit}/reportes/nomina/listadoAlfa/analitico`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina( false ); 
                            setError( null ); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch( error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 183:
                direccion = `${servicios_grupohit}/reportes/nomina/reporteNomina`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.txt`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 184:
                direccion = `${servicios_grupohit}/reportes/nomina/listado/contrato/proximo/termino/${data.anio}/${data.periodo}`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.get( direccion, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 185:
                direccion = `${servicios_grupohit}/reportes/nomina/listado/contrato/termino/${data.anio}/${data.periodo}`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.get( direccion, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 186:
                direccion = `${servicios_grupohit}/reportes/nomina/cifras`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 187:
                direccion = `${servicios_grupohit}/reportes/nomina/listado/fonacot`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 188:
                direccion = `${servicios_grupohit}/reportes/nomina/listado/personal/banamex`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 189:
                direccion = `${servicios_grupohit}/reportes/nomina/listado/empleados/ausentismos`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 190:
                direccion = `${servicios_grupohit}/reportes/nomina/producto/nomina`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 191:
                direccion = `${servicios_grupohit}/reportes/nomina/total/concepto/prestamo/pension`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => { 
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 192:
                direccion = `${servicios_grupohit}/reportes/nomina/confronta/nomina`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => {
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 193:
                direccion = `${servicios_grupohit}/reportes/nomina/confronta/pension`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => {
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            case 194:
                direccion = `${servicios_grupohit}/reportes/nomina/confronta/prestamos`;
                if(isConsolidado) direccion = `${direccion}/consolidado/`;
                axios.post( direccion, data, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const linkSource = `data:text/plain;base64,${respuesta.data.dataResponse.reporte}`;
                            const downloadLink = document.createElement("a");
                            const fileName =`${infoReport.nombre}.xlsx`; 
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            setInfoReport(null);
                            setDownloadReportDefinido(false);
                            setOpenNewReportNomina(false); 
                            setError(null); 
                            setIsLoading(false);
                            break;
                        default:
                            setError(<AlertForm message={respuesta.data.msg} />); 
                            setIsLoading(false);
                            break;
                    }  
                })
                .catch(error => {
                    setError(<AlertForm message={error_reporte} />); 
                    setIsLoading(false); 
                });
                break;
            default:
                setInfoReport(null);
                setDownloadReportDefinido(false);
                setError(<AlertForm message={error_reporte} />); 
                setIsLoading(false);
            break;
        }
    }

    return (
        <ModalService title={infoReport ? infoReport.nombre : "Nuevo Reporte"} 
            parentNode={nombreModulo} minWidth="50%" maxWidth="50%"
            isOpen={openNewReportNomina} onClose={cerrarModal} >
            <FormularioNewReportNomina
                error={error}
                enviarNewReportNomina={enviarNewReportNomina}
                isLoading={isLoading} 
                setError={setError}
                downloadReportDefinido={downloadReportDefinido}
                setDownloadReportDefinido={setDownloadReportDefinido}
                enviarReportDownload={enviarReportDownload}
                infoReport={infoReport}
                campos={campos}
            />
        </ModalService>
    );
};

export default ModalNewReportNomina;