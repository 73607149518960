import React from 'react';
import CollapseMaterial from '../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/CollapseMaterial';
import Checkbox from '@material-ui/core/Checkbox';
import AlertForm from '../../../../../../Services/AlertForm';

const ModuloAccesos = ({ moduloPadre, accesosDirectos, nuevosAccesos, agregar, eliminar, setError, elementoActivo, setElementoActivo }) => {
    const seleccionarAcceso = ( accesoID ) => {
        if( nuevosAccesos.includes( accesoID ) ){
            eliminar( accesoID );
            setError( null );
        }else{
            if( 12-accesosDirectos.length-nuevosAccesos.length >= 1 ){
                agregar( accesoID );
            }else{
                setError( <AlertForm message="Sólo pueden seleccionarse un máximo de 12 accesos rápidos." /> );
            }
        }
    }
    return (
        <CollapseMaterial title={moduloPadre.nombre} icon={moduloPadre.icono} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
            { moduloPadre.children ?
                moduloPadre.children.map( moduloHijo => {
                    if( accesosDirectos.includes( moduloHijo.id ) ){
                        return(
                            <React.Fragment key={moduloHijo.id}>
                                <Checkbox disabled checked/> { moduloHijo.nombre } <br/>
                            </React.Fragment>
                        );
                    }else{
                        return(
                            <React.Fragment key={moduloHijo.id}>
                                <Checkbox
                                    checked={ nuevosAccesos.includes( moduloHijo.id ) ? true : false }
                                    onChange={ () => seleccionarAcceso( moduloHijo.id ) }
                                /> { moduloHijo.nombre } <br/>
                            </React.Fragment>
                        );
                    }
                })
                :
                <p> {moduloPadre.nombre} </p>
            }
        </CollapseMaterial>
    );
};

export default ModuloAccesos;