import React, { createRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { validarSubmit, deleteOptionals, longDateToInput, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AutoCompleteUsuarios from '../../Mensajes/MensajesContenido/EnviarMensaje/AutoCompleteUsuarios';
import { headersAuth, obtenerUsuario } from '../../../../../../Services/AuthService';
import axios from 'axios';
import { servicios_grupohit_url, servicios_grupohit, verifique_conexion } from '../../../../../../Constants/Constants';
import { ArrayJsonSubordinatesUsers, ArrayJsonAuditorias } from '../../../Helpers/JsonToOneLevel'; 
import { Description } from '@material-ui/icons';
import AlertForm from '../../../../../../Services/AlertForm'; 
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import { informacionModulos } from '../../../Helpers/HelperNavegacion';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { iconosDrawer } from '../../../MenuDrawer/Drawer/IconosDrawer';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';

const FormConsultaAuditoria = ({ setAuditorias, modulosApi, nominasRL, periodoActual }) => { 

    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'));    
    const fechaIniRef = createRef('');
    const fechaFinRef = createRef('');
    const [ user, setUser ] = useState( null );
    const [ modulo, setModulo ] = useState( '' );
    const [ operacion, setOperacion ] = useState( '' ); 
    const [ isLoading, setIsLoading ] = useState( false );
    const { usuario, nombre, apaterno, amaterno,  claveUsuario } = obtenerUsuario(); 
    const [ usuarios, setUsuarios ] = useState([]);
    const [ errorForm, setErrorForm] = useState( null );
    const [ openAlertError, setOpenAlertError] = useState( false );
    const operaciones = [ 
        {id:1, operacion:'Agregar', operation:"INSERT"},
        {id:2, operacion:'Actualizar', operation:"UPDATE"},
        {id:3, operacion:'Eliminar', operation:"DELETE"}
    ];
    var modulos = informacionModulos( modulosApi ); 

    useEffect(() => {
        if(nominasRL.length > 0) {
            const periodoNominaActual = nominasRL.find(p => (p.periodoAnio === periodoActual.anio) && (p.periodoPerAnio === periodoActual.periodo));
            fechaIniRef.current.value = longDateToInput(periodoNominaActual.periodoFecini);
            fechaFinRef.current.value = longDateToInput(periodoNominaActual.periodoFecfin);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{ 
        axios.get( `${servicios_grupohit_url}/all/subordinates`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        const usuariosArray = [
                            {
                                id: parseInt(claveUsuario),
                                username: usuario,
                                datosUsuarioNom: nombre,
                                datosUsuarioPat: apaterno,
                                datosUsuarioMat: amaterno,
                                img: ''
                            },
                            ...ArrayJsonSubordinatesUsers(respuesta.data.dataResponse) 
                        ];
                        axios.get( `${servicios_grupohit}/img/${usuario}/${claveUsuario}`, headersAuth() )
                        .then( respuesta => {
                            switch( respuesta.data.status ){
                                case 200:
                                    usuariosArray[1].img=respuesta.data.dataResponse;
                                    break;
                                default:
                                    break;
                            }
                        })
                        .catch( error => { console.log( error ) })
                        .finally(() => {
                            setUsuarios(usuariosArray);
                        });
                        break;
                    case 404:
                        setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { setUsuarios( [] ); console.log( error ) }); 
            enviarAuditorias();
            // eslint-disable-next-line
    }, []);
    
    const consultarAuditorias = ( data ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit}/Auditoria/`, data,headersAuth() )
        .then( respuesta => { 
            switch( respuesta.data.status ){
                case 200:
                    setAuditorias( ArrayJsonAuditorias(respuesta.data.dataResponse) );
                    setIsLoading(false);
                    break;
                default:
                    setIsLoading(false);
                    setAuditorias(null);
                    setErrorForm( <AlertForm message={respuesta.data.msg} /> ); 
                    break;
            }
        })
        .catch( error => {
            setOpenAlertError(true);
            setIsLoading(false);
            setAuditorias(null);
        });   
    }

    const enviarAuditorias = (evt) => {
        if(evt) evt.preventDefault();
        setErrorForm(null);
        if( validarSubmit( 'consultarAuditorias' ) && validaFechas()){                
            const usuario = user;
            const fechaIni = fechaIniRef.current.value.includes('/') ? fechaIniRef.current.value.split('/').reverse().join('-') :fechaIniRef.current.value;
            const fechaFin = fechaFinRef.current.value.includes('/') ? fechaFinRef.current.value.split('/').reverse().join('-') :fechaFinRef.current.value;
            consultarAuditorias(deleteOptionals({usuario: (user) ? usuario.id : 0, fechaIni, fechaFin, modulo, operacion}));
        }
    }

    const selectOperacion = ( element )=>{
        if(element!==null){ 
            setOperacion(element.operation);
        }else{
            setOperacion('');
        }
    }
 
    const validar = e => {
        if (e === undefined || e === null) {
            setModulo('');
        } else {
            let result = modulos.filter(mod => e.nombre === mod.nombre);
            if(result.length !== 0 ){
                setModulo(result[0].id);
            }
        }
    }; 

    const validaFechaInicial = fechaInicial => {
        if(fechaFinRef.current.value !== '') {
            if(fechaInicial !== null) {
                const fechaIni = inputDateToLong(fechaInicial);
                const fechaFin = inputDateToLong(fechaFinRef.current.value);
                if(fechaIni > fechaFin) {
                    setErrorForm(<AlertForm message="La fecha inicial debe ser menor o igual que la fecha final."/>);
                    return;
                }
            }
        }
        setErrorForm(null);
    };

    const validaFechaFinal = fechaFinal => {
        if(fechaIniRef.current.value !== '') {
            if(fechaFinal !== null) {
                const fechaFin = inputDateToLong(fechaFinal);
                const fechaIni = inputDateToLong(fechaIniRef.current.value);
                if(fechaFin < fechaIni) {
                    setErrorForm(<AlertForm message="La fecha final debe ser mayor o igual que la fecha inicial."/>);
                    return;
                }
            }
        }
        setErrorForm(null);
    };

    const validaFechas = () => {
        if((fechaFinRef.current.value !== '') && (fechaIniRef.current.value !== '')) {
            const fechaIni = inputDateToLong(fechaIniRef.current.value);
            const fechaFin = inputDateToLong(fechaFinRef.current.value);
            if(fechaIni > fechaFin) {
                setErrorForm(<AlertForm message="La fecha inicial debe ser menor o igual que la fecha final."/>);
                return false;
            }
        } else {
            setErrorForm(<AlertForm message="Debe capturar las fechas inicial y final."/>);
            return false;
        }
        setErrorForm(null);
        return true;
    };

    return (
        <div className="card py-2 px-3 mb-2">
            <form id='consultarAuditorias' onSubmit={enviarAuditorias}>
                <h4 className="mt-2">Auditar Operaciones Realizadas en el Sistema</h4>
                <label>Filtre la información que desea consultar a través de los campos que despliegan a continuación.</label>
                <hr/>
                <div className="row">
                    <div className="col-md-3 col-sm-12 auditorias">
                        <AutoCompleteUsuarios
                            usuarios={usuarios} setDestinatarios={setUser} multiple={false}
                            title="Usuario" tooltipText="Seleccione un usuario" placeholder="Todos los usuarios"
                            defaultValue=""
                        />
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechaIniRef} onBlurExecute={validaFechaInicial} required/>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechaFinRef} onBlurExecute={validaFechaFinal} required/>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <Autocomplete 
                            id="free-solo-demo" 
                            autoHighlight={true}
                            onChange={(e,v) => validar(v)}
                            getOptionLabel={option => option.nombre}
                            options={modulos.map(modu => ({'nombre':modu.nombre,'icono':modu.icono}))}
                            size="small" 
                            noOptionsText="No existen coincidencias" 
                            renderOption={ (options,{inputValue}) => {
                                const matches = match(options.nombre, inputValue)
                                const parts = parse(options.nombre, matches);
                                return (
                                    <React.Fragment>
                                        <table >
                                            <thead>
                                                <tr>
                                                    <th className="Icono">
                                                        { iconosDrawer[ options.icono ] }
                                                    </th>
                                                    <th >
                                                        { parts.map((part, index) => (
                                                            <span key={index} style={{ color: part.highlight ? '#4C638E': 'black' }}>
                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </React.Fragment>
                                );
                            }}      
                            renderInput={ params => (
                                <div className="form-group">
                                    <label style={{marginBottom: '0.5rem'}}>
                                        Módulo
                                        <TooltipComponent tooltipText="Busque el Módulo en el campo de autocompletado">
                                            <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                                        </TooltipComponent>
                                    </label>  
                                    <TextField
                                        id="mui-theme-provider-outlined-input"
                                        {...params} 
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Todos los módulos"
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className="col-md-2 col-sm-12"> 
                        <AutocompleteComponent
                                id="operacion"
                                title="Operación"
                                options={operaciones}
                                optionListView="operacion"
                                action={selectOperacion}
                                placeholder="Todas las operaciones"
                                tooltipText="Busque la Operación en el campo de autocompletado"
                            />
                    </div>
                    <div className="col-md-1 col-sm-12" style={{margin: '2.3rem 0 0 0'}}>
                        <Button
                            variant="contained" className="btn-third" type="submit"
                            disabled={isLoading ? true : false}
                        >
                            { isLoading ? <CircularProgress size={25}/>:<Description/> }
                        </Button> 
                    </div> 
                </div>
                <div className="row">
                    <div className="col-md-5 col-sm-12">
                        {errorForm}
                    </div>
                </div>
            </form>
            <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Petición Fallida" text={verifique_conexion} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    nominasRL: state.PeriodoActualReducer.nominasRL,
    periodoActual: state.PeriodoActualReducer.periodoActual,
});

export default connect(mapStateToProps)(FormConsultaAuditoria);