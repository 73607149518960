import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import { servicios_grupohit_catalogo, turnos_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import DataTableService from '../../../DataTableService/DataTableService'; 
import { ArrayJsonTurnos } from '../../../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Turnos = ({ id, permisos  }) => { 
    const [turnos, setTurnos]= useState(null); 
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(()=>{
        Axios.get(`${servicios_grupohit_catalogo}/turno/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    const turno = ArrayJsonTurnos(res.data.dataResponse);                  
                    setTurnos(turno); 
                    break;
                case 404:
                    setTurnos([]);  
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);
    
   return (
       <div id={`modulo${id}`}> 
                {
                    turnos
                    ?
                    <DataTableService 
                        id={id}
                        title={ turnos_ }
                        permisos={permisos}
                        columns={[
                            { field: "turnoClave", title: "Clave del Turno" },
                            { field: "turnoNom", title: "Nombre del Turno" },
                            { field: "jornadaNombre", title: "Jornada Laboral" },
                        ]}
                        data={turnos}
                        />
                    :<SpinnerOval/>
                }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
       </div>

   );
}
 
export default Turnos;




