import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

/* Elementos e Iconos para el listado de Documentos */
import './styles.css';
import Button from '@material-ui/core/Button';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import GetApp from '@material-ui/icons/GetApp';
import Delete from '@material-ui/icons/Delete';

/* Importación de modales para Domicilios */
import ModalDeleteDocumento from './ModalDeleteDocumento';
import FormularioDocumento from './FormularioDocumento';
import { gerURL } from '../../../../CropService/HelperFiles';

import AlertForm from '../../../../../../../Services/AlertForm';

const DocumentosEmpleado = ({ empleado, informacion, errorTable, successTable }) => {
    const [ openAdd, setOpenAdd ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} );
    /* --------------------- Axios para obtener los documentos del empleado seleccionado -------------------- */
    const [ documentos, setDocumentos ] = useState( null );
    const [ errorZip, setErrorZip ] = useState( null );
    useEffect(() => {
        axiosDocumentos();
        // eslint-disable-next-line
    }, []);

    const axiosDocumentos = () => {
        setDocumentos( null );
        axios.get( `${servicios_grupohit_rh}/documentos/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDocumentos( respuesta.data.dataResponse );
                        break;
                    case 404: //No hay datos que mostrar
                        setDocumentos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosZipEmpleado = () => {
        setErrorZip( null );
        axios.get( `${servicios_grupohit_rh}/documentos/zip/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        if( respuesta.data.dataResponse !== '' ){
                            const downloadLink = document.createElement("a");
                            downloadLink.href = `data:application/zip;base64,${respuesta.data.dataResponse}`;
                            downloadLink.download = `Expediente_${empleado.nombreCompleto}`;
                            downloadLink.click();
                        }else{
                            setErrorZip( <AlertForm message="Expediente Vacio" /> );
                        }
                        break;
                    case 404: //No hay datos que mostrar
                        break;
                    default:
                        setErrorZip( <AlertForm message="No se ha podido descargar el expediente" /> );
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------- Actualizaciones de estado (Documentos) ------------------------------- */
    const agregar = ( newElement ) => {
        setDocumentos( [ ...documentos, newElement ] );
        setOpenAdd( false );
        successTable( 'Registro Agregado' );
    }
    const eliminar = ( idElement ) => {
        setDocumentos( documentos.filter( element => element.id !== idElement ) );
        successTable( 'Registro Eliminado' );
    }
    /* ------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-9 col-sm-6">
                    <span> <b> Empleado: </b> {empleado.nombreCompleto} </span> <br/>
                    <span> <b> NOTA: </b> Límite de tamaño de archivo 6MB </span> 
                </div>
                <div className="col-md-3 col-sm-6 agregar">
                    <Button className="btn-third" onClick={() => setOpenAdd(!openAdd)}> <CreateNewFolder/> </Button>
                </div>
            </div>
            <hr/>

            { openAdd &&
                <FormularioDocumento empleado={empleado} tiposDocumentos={informacion.tiposDocumentos} setOpenAdd={setOpenAdd}
                    agregar={agregar} errorTable={errorTable}/>
            }

            <div className="row mb-4 head">
                <div className="col-md-8 col-sm-8"> <b>Archivos</b> </div>
                <div className="col-md-4 col-sm-4"> <b>Acciones</b> </div>
            </div>

            { documentos ?
                documentos.length > 0 ?
                    <React.Fragment>
                        { documentos.map( doc => (
                            <React.Fragment key={doc.id}>
                                <div className="row">
                                    <div className="col-md-8 col-sm-12">
                                        <span> <b> Documento: </b> { `${doc.documento.tipodocNom}` } </span> <br/>
                                        <span> <b> Extensión: </b> { `${doc.documento.extencion.tipoextNom}` } </span> <br/>
                                        <span> <b> Descripción: </b> { `${doc.documento.tipodocDesc}` } </span> <br/>
                                        <span> <b> Nota: </b> { `${doc.tempdocNota}` } </span>
                                    </div>
                                    <div className="col-md-4 col-sm-12 actions">
                                        <a className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-doc"
                                            href={`${gerURL(doc.documento.extencion.tipoextNom)}${atob(doc.ruta)}`} download={`${doc.documento.tipodocNom}`}>
                                            <GetApp/>
                                        </a>
                                        <Button className="btn-outline btn-doc" onClick={() => { setElementSelect( doc ); setOpenDelete( true ); }}> <Delete/> </Button>
                                    </div>
                                </div>
                                <hr/>
                            </React.Fragment>
                        ))}
                        <Button variant="contained" className="btn-color" onClick={() => axiosZipEmpleado()}> Descargar Expediente </Button>
                        { errorZip }
                    </React.Fragment>
                    :
                    <div className="text-center"> Aún no se ha guardado ningún archivo </div>
                :
                <SpinnerOval />
            }
            <ModalDeleteDocumento openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>
        </React.Fragment>
    );
};

export default DocumentosEmpleado;