import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useStyles } from './styles/stylesCard';

const CardComponent = ({ children, title }) => {

    const classes = useStyles();
    
    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                { title }
                <div className="table">
                    { children }
                </div>
            </CardContent>
        </Card>
    );
}
 
export default CardComponent;