import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, servicios_grupohit_nomina, movimientos_relacionados } from '../../../../../../../Constants/Constants';
import DataTableMovimientos from '../../Variables/DataTableMovimientos/DataTableMovimientos';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonMovimientosRelacionadosEmpleado, ArrayJsonPensionesRelacionado, ArrayJsonIndicadorRelacionadoFormulario } from '../../../../Helpers/JsonToOneLevel';

const RelacionadosEmpleado = ({ empleado, setEmpleado, tiposAcumulado, idModulo }) => {
    const [ relacionados, setRelacionados ] = useState( null );
    const [ pensiones, setPensiones ] = useState( null );
    const [ indicadoresNomina, setIndicadoresNomina ] = useState( null );
    useEffect(() => {
        //axiosRelacionadosEmpleado();
        axiosPensionesEmpleado();
        axiosIndicadoresNomina();
        // eslint-disable-next-line
    }, []);

    const axiosRelacionadosEmpleado = ( pensiones ) => {
        axios.get( `${servicios_grupohit_rh}/movimientos/relacionado/empleado/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setRelacionados( ArrayJsonMovimientosRelacionadosEmpleado( respuesta.data.dataResponse, pensiones ));
                        break;
                    case 404: //No hay datos disponibles
                        setRelacionados( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosPensionesEmpleado = () => {
        axios.get( `${servicios_grupohit_rh}/empleado/pension/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const pensiones = ArrayJsonPensionesRelacionado( respuesta.data.dataResponse );
                        setPensiones( pensiones.filter( pension => pension.tipoCuota === "P" ) ); //Solo Pensiones Con PORCENTAJE
                        axiosRelacionadosEmpleado(ArrayJsonPensionesRelacionado( respuesta.data.dataResponse ));
                        break;
                    case 404: //NO hay datos que mostrar
                        setPensiones( [] );
                        axiosRelacionadosEmpleado( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener los indicadores por nomina (Nomina a la cual esta relacionada el empleado) */
    const axiosIndicadoresNomina = () => {
        axios.get( `${servicios_grupohit_nomina}/indicadores/getbynomina/${empleado.nominaId}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresNomina( ArrayJsonIndicadorRelacionadoFormulario( respuesta.data.dataResponse ));
                        break;
                    case 404:
                        setIndicadoresNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setIndicadoresNomina( [] );
            })
    }

    return (
        <React.Fragment>
            { relacionados && pensiones && indicadoresNomina ?
                <DataTableMovimientos
                    idModulo={idModulo}
                    title={movimientos_relacionados}
                    empleado={empleado}
                    setEmpleado={setEmpleado}
                    columns={[
                        { field: "nomina.nomClave", title: "Clave Concepto" },
                        //{ field: "nomina.nomIndicador", title: "Nombre Concepto" },
                        { field: "informacionConcepto", title: "Nombre Concepto" },
                        { field: "acumulado.acumuladoNombre", title: "Tipo de Acumulado" },
                        { field: "fechaInicioTab", title: "Fecha Inicio" },
                        { field: "fechaFinalTab", title: "Fecha Final" },
                    ]}
                    data={ relacionados }
                    informacionAdicional={{ empleado, tiposAcumulado, indicadoresNomina, pensiones }}/>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default RelacionadosEmpleado;