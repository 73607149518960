import React from 'react';
import { pagos_pendientes } from '../../../../../../Constants/Constants';
import ModalService from "../../../ModalService/ModalService";
import FormModalPagosPendientes from './FormModalPagosPendientes';

const ModalPagosPendientes = ({ id, openModalForm, setOpenModalForm, elementSelect, successTable, errorTable }) => {

    const cerrarModal = () => {
        setOpenModalForm(false);
    };
    
    return (
        <ModalService
            title={pagos_pendientes}
            parentNode={id}
            minWidth="90%"
            maxWidth="90%"
            isOpen={openModalForm}
            onClose={cerrarModal}
        >
            <FormModalPagosPendientes id={id} elementSelect={elementSelect} successTable={successTable} errorTable={errorTable} cerrarModal={cerrarModal}/>
        </ModalService>
    );
};

export default ModalPagosPendientes;