import React, {useEffect, useState, createRef, useRef} from 'react';
import MaterialTable, { MTableCell, MTableAction } from 'material-table';
import { MuiThemeProvider, createMuiTheme, IconButton, CircularProgress } from '@material-ui/core';
import { localization, tableIcons } from '../../../../DataTableService/ElementTable';
import Axios from 'axios';
import { registro_actualizado, registro_no_guardado, servicios_grupohit, servicios_grupohit_admon, servicios_grupohit_catalogo } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { ArrayJsonDependencias } from '../../../../Helpers/JsonToOneLevel';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';
import DoneIcon from '@material-ui/icons/Done';
import { csvDownload } from '../../../../../../../Services/Validation/HelperValidation';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';
import ButtonReturn from '../components/ButtonReturn';
import { SaveAlt } from '@material-ui/icons';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#81A1DB' },
        secondary: { main: '#ffffff' },
    },
});

const TableActualizarOrigenRecurso = ({hidenTable, id} ) => {

    const optionsTable = {
        rowStyle: { textTransform: 'uppercase' },
        columnsButton: false,
        headerStyle: { zIndex: "0", color: '#4C638E', fontweight: 'bold', textTransform: 'uppercase' },
        actionsColumnIndex: -1,
        searchFieldAlignment: "left", 
    };

    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ tipoAlert, setTipoAlert ] = useState( 0 );
    const [ dependencias, setDependencias ] = useState([]);
    const [ origenRecursos, setOrigenRecursos ] = useState(null);
    

    const selectOrigenRecursoRefs = useRef([]); 
    selectOrigenRecursoRefs.current = dependencias.map(
        (ref, index) => selectOrigenRecursoRefs.current[index] = createRef('')
      );
      
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const [ searchedData, setSearchedData ] = useState(false);
    const [ actualizado, setActualizado ] = useState(false);
    const [ loading, setLoading ] = useState(false); 

    const tableRef = createRef();
    const [columns] = useState([
        { field: "ciaUr", title: "Unidad Responsable / Dependencia" }, 
        { field: "ciaRfc", title: "RFC Dependencia" },
        { field: "ciaRazonSocial", title: "Nombre Dependencia" },
        { field: "ciaNomCorto", title: "Nombre Corto" },        
        { field: "tipoDependNombre", title: "Tipo de Secretaría o Dependencia" },
        { field: "origenRecurso", title: "Origen del Recurso" },
    ]);

    const dataVisible = (columns, data)=>{    
        return extractDataGroup(data, columnsVisibles(columns), groupByData(columns));
    }

    const groupByData = (columns) => {
        return columns.filter(columnDef => columnDef.tableData.groupOrder !== undefined);
    }

    const columnsVisibles = (columns) => { 
        return columns.filter(columnDef => (columnDef.hidden !== true  && columnDef.tableData.groupOrder === undefined)).sort((a, b)=> a.tableData.columnOrder > b.tableData.columnOrder ? 1 : a.tableData.columnOrder < b.tableData.columnOrder ? -1 :0 );
    }

    const extractDataGroup=(data, columnsVisibles, groupBy)=>{
        var result = [];
        for (const key in data) {  
            if (data[key].groups) { 
                const colName = groupBy.filter(element => element.tableData.groupOrder===data[key].path.length-1)[0];
                result.push({" ": `${colName.title}: ${data[key].value}`}); 
                if(data[key].groups.length>0){ 
                    const group = extractDataGroup(data[key].groups, columnsVisibles, groupBy);
                    for (const key in group) {
                        result.push( group[key]);
                    } 
                }else{
                    const dataVisble = data[key].data.map(rowData =>{ const newJson={};  Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = rowData[columnDef.field]; return element})); return newJson;});
                    dataVisble.map(element=>result.push(element)); 
                }
            }else{ 
                const newJson = {};
                Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = data[key][columnDef.field]; return element})); 
                result.push(newJson);
            }
        }
        return result;
    } 

    useEffect(()=>{
        getDependencias();
        getOrigenRecursos();
    }, []);

    const getDependencias = ( clear = false) =>{
        Axios.get(`${servicios_grupohit_admon}/compania/`, headersAuth())
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        if(clear){
                            setActualizado(true);
                        }
                        const dataResp = ArrayJsonDependencias(respuesta.data.dataResponse);
                        setDependencias( dataResp );
                    break;
                    default:
                        setDependencias([]);
                    break;
                } 
                setSearchedData(true);
            });
    }

    const getOrigenRecursos = () => {

        Axios.get(`${servicios_grupohit_catalogo}/origenrecurso/`, headersAuth())
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:  
                        setOrigenRecursos( respuesta.data.dataResponse );
                    break;
                    default:
                        setOrigenRecursos([]);
                    break;
                }
            });

    }

    const updateDateContract = ( value, index ) =>{
        dependencias[index].origenRecurso= value;
    } 

    const alertReplicateData = ( index )=>{ 
        setActualizado(false);
        setElemmentSelect( index );
        setTipoAlert(0);
        setTextAlert("¡Se aplicará este origen de recursos a todos los campos!");
        setAlertWarning(true);
    }

    const replicateData = ( ) => {

        const dataVisible = tableRef.current.state.searchText==="" ? tableRef.current.state.originalData.map(element => element.id) : tableRef.current.state.renderData.map(element => element.id);
        
        setDependencias(dependencias.map(element => {
                if(dataVisible.includes(element.id)){
                    element.origenRecurso = elemmentSelect;
                } 
                return element;
            })
        );
    }

    const saveInfo = ( ) => {
        setLoading(true); 
        const companias = elemmentSelect.map(item=>{
                return  {
                            companiaId: item.id, 
                            recursos:[parseInt(item.origenRecurso)]
                        }
            }
        ); 
        Axios.post( `${servicios_grupohit}/cfdi/companiaorigen/`, {companias}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setLoading(false);
                        setTextAlert(registro_actualizado);
                        setAlertSuccess(true);
                        getDependencias( true);
                        break;
                    default:
                        setLoading(false);
                        setTextAlert( respuesta.data.msg );
                        setAlertError(true);
                        break;
                }
            })
        .catch( error => {
            setLoading(false);
            setTextAlert( registro_no_guardado );
            setAlertError(true);
        });
    } 

    const verificaDependenciasActualizar = ( data ) =>{
        const validos = data.filter( element => element.origenRecurso!=="" ); 
        setElemmentSelect(validos); 
        return validos;
    }

    return ( 
        <>
        <ButtonReturn action={hidenTable} type="actualizar"/>
        <div className={searchedData? "tableUpdateOrigen": ''}>
            {
                dependencias && searchedData ?
                    <MuiThemeProvider theme={theme}>
                    <div className="col-12 d-flex align-items-end margintb titleUpdateOrigen" >
                        <h4 style={{ paddingRight: '30px',paddingTop: '15px', fontWeight: 'bold' }}>
                            {"Origen del Recurso por Secretaría o Dependencia"}
                        </h4>
                    </div>  
                    <MaterialTable
                        title={""}
                        columns={columns}
                        data={dependencias}
                        options={optionsTable}
                        icons={tableIcons}
                        localization={localization}
                        tableRef={tableRef}
                        actions={[
                            {
                                tooltip: 'CSV',
                                isFreeAction: true,
                                icon: SaveAlt,
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'Actualizar',
                                isFreeAction: true,
                                icon: DoneIcon,
                                onClick: (evt, data) => {
                                }
                            },
                        ]}
                        components={{
                            Cell: props=>{                              
                                if(props.columnDef.field==='origenRecurso'){
                                    const isFirstRowRender = tableRef.current && tableRef.current.state.renderData.length>0 ? tableRef.current.state.renderData.findIndex(element=> element.id === props.rowData.id)===0 : false;
                                    return(
                                        <td className={`MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft`} style={{padding:isFirstRowRender ? '0px, 0px, 0px, 16px' : 16}}>
                                            <div style={{display: isFirstRowRender ? 'flex' :''}}> 
                                               {
                                                   origenRecursos &&
                                                    <SelectValidation 
                                                        title="" 
                                                        arrayOpciones={origenRecursos} keyOpcion="origenRecDesc"
                                                        refValue={selectOrigenRecursoRefs.current[props.rowData.tableData.id]} 
                                                        defaultValue={ actualizado ? '' : props.value }
                                                        onChangeExecute={updateDateContract}
                                                        informacionAdicional={props.rowData.tableData.id}
                                                        optional
                                                    />
                                               }
                                                
                                                {
                                                     tableRef.current && tableRef.current.state.renderData.length>0 ? isFirstRowRender &&
                                                        <IconButton onClick={()=>alertReplicateData(selectOrigenRecursoRefs.current[props.rowData.tableData.id].current.value)} className="customButtonReplicateOrigen">
                                                            <Tooltip title="Aplicar mismo origen" placement="left-start" >
                                                                <ArrowDropDownIcon fontSize="small"/>
                                                            </Tooltip>
                                                        </IconButton>
                                                        :null
                                                }
                                            </div>
                                        </td>
                                    )
                                }
                                return( <MTableCell {...props}/> );
                            },
                            Action: props => {
                                if(props.action.tooltip === 'Actualizar' && dependencias.length>0){ 
                                    return( <Tooltip title="Actualizar">
                                                {
                                                loading ? 
                                                    <button  className={'form-control btn-color buttonUpdateOrigen'}  style={{width: '40%', marginRight: '10px', display: 'inline-table'}}>
                                                        <CircularProgress size={25} color="secondary"/> 
                                                    </button>
                                                :
                                                    <button 
                                                        className={'form-control btn-color'}
                                                        style={{width: '40%', marginRight: '10px', display: 'inline-table'}} 
                                                        onClick={(evt, data) => {
                                                            if(verificaDependenciasActualizar(dependencias).length>0){
                                                                setTipoAlert(1);
                                                                setTextAlert("¡Se actualizará el origen del recurso!"); 
                                                                setAlertWarning(true);
                                                            }else{
                                                                setTextAlert("¡No ha modificado información!");
                                                                setAlertError(true); 
                                                            }
                                                        }}
                                                    >
                                                    <DoneIcon/> 
                                                    </button>
                                                
                                            }
                                            </Tooltip>)
                                }else if(props.action.tooltip === 'CSV' && dependencias.length>0){ 
                                    return( <Tooltip title="CSV">
                                                <button
                                                    className={'form-control btn-color'}
                                                    style={{width: '40%', marginRight: '10px', display: 'inline-table'}}
                                                    onClick={(evt, data) => { 
                                                        const resultData1 = dataVisible(tableRef.current.state.columns, tableRef.current.state.data);
                                                        const resultData2 = resultData1.map(rd => {
                                                            rd['Origen del Recurso'] = (rd['Origen del Recurso'])
                                                            ?   origenRecursos.find(or => or.id === parseInt(rd['Origen del Recurso'])).origenRecDesc
                                                            :   rd['Origen del Recurso'];

                                                            return rd;
                                                        });

                                                        if(resultData2) csvDownload( resultData2, `ORIGEN DE RECURSO.csv` );
                                                    }}
                                                >
                                                   <SaveAlt/> 
                                                </button>
                                            </Tooltip>)
                                }
                                return( <MTableAction {...props} /> )
                            }
                        }}
                    />
                </MuiThemeProvider>
                :
                <SpinnerOval />
            }
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={tipoAlert===0 ? replicateData :saveInfo } />
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </div>
        </>      
    );
}
 
export default TableActualizarOrigenRecurso;