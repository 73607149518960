import React, { createRef, Fragment } from 'react';
import TooltipComponent from '../../../../../../../../Services/Tooltip/TooltipComponent';
import InputValidation from '../../../../../../../../Services/Validation/InputValidation';
import BtnEditItem from '../../BtnEditItem';
import BtnRemoveItem from '../../BtnRemoveItem';
import { Save, Cancel } from '@material-ui/icons';
import { validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import ConvertDate from '../../../../../Helpers/ConvertDate';

const Nota = ({ notaObject, idEditar, muestraTextArea, ocultaTextArea, servicioEditarNota, servicioEliminarNota }) => {

    const { id, nota, usuario, notaFecha } = notaObject;

    const notaRef = createRef('');

    const cerrarEdicion = e => {
        e.preventDefault();
        ocultaTextArea();
    };

    const enviarEditar = e => {
        e.preventDefault();
        if(validarSubmit('FormNotesEdit')) {
            const request = {
                id,
                notaNueva: notaRef.current?.value
            };
            servicioEditarNota(request);
        }
    };

    return (
        <Fragment>
        {(idEditar === id)
        ?   <div id="FormNotesEdit" className="row">
                <div className="col-sm-6 col-md-8 col-lg-8 col-xl-10">
                    <InputValidation
                        title="Nota" type="notaAdicional" fieldType="textarea" refValue={notaRef}
                        tooltipText={'Máximo 2024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                        defaultValue={nota} required
                    />
                    <br/>
                </div>
                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-start alignbtnInput">
                    <TooltipComponent tooltipText="Guardar nota editada">
                        <button className="btn-autocompleteSueldo" onClick={enviarEditar}><Save/></button>
                    </TooltipComponent>
                </div>
                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-start alignbtnInput">
                    <TooltipComponent tooltipText="Cancelar editar nota">
                        <button className="btn-autocompleteSueldo btn-autocompletecancel" onClick={cerrarEdicion}><Cancel/></button>
                    </TooltipComponent>
                </div>
            </div>
        :   <div className="contenedor-nota">
                <BtnEditItem id={id} action={muestraTextArea} remove style={{ right: '55px' }}/>
                <BtnRemoveItem id={id} action={servicioEliminarNota} remove style={{ right: '33px' }}/>
                <div style={{ marginRight: '3rem' }}><strong>Creado por: </strong>
                { `${usuario.datos.usuarioNom} ${(usuario.datos.usuarioPat) ? usuario.datos.usuarioPat : '' } ${(usuario.datos.usuarioMat) ? usuario.datos.usuarioMat : ''}`}
                </div>
                <div style={{ marginBottom: '.5rem' }}><strong>Fecha: </strong>{ConvertDate(notaFecha)}</div>
                <div><label>{nota}</label></div>
            </div>
        }
        </Fragment>
    );
}
 
export default Nota;