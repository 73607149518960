import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_url } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import ModuloAccesos from './ModuloAccesos';
import Button from '@material-ui/core/Button';
import AlertForm from '../../../../../../Services/AlertForm';

const ListaDeAccesos = ({ error, setError, accesosDirectos, guardarAccesos }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    /* ------------------------- Axios para obtener los modulos del usuario superior ------------------------ */
    const [ modulosSuperior, setModulosSuperior ] = useState( null );
    const [ nuevosAccesos, setNuevosAccesos ] = useState( [] );
    useEffect(() => {
        axiosModulosSuperior();
        // eslint-disable-next-line
    }, []);

    const axiosModulosSuperior = () => {
        axios.get( `${servicios_grupohit_url}/menu`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setModulosSuperior( respuesta.data.dataResponse.menu );
                        break;
                    case 404:
                        //setModulosSuperior([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------ Funcion que permite consumir el servicio AsignarModulos&Permisos ------------------ */
    const guardar = () => {
        if( nuevosAccesos.length > 0 ){
            setError( null );
            guardarAccesos({ accesos: nuevosAccesos });
        }else{
            setError( <AlertForm message={ 'Asegúrate de seleccionar un acceso directo' } /> );
        }
    }
    /* ---------------- Funciones para agregar y eliminar elementos de state (nuevosAccesos) ---------------- */
    const agregar = ( newElement ) => {
        setNuevosAccesos( [ ...nuevosAccesos, newElement ] );
    }
    const eliminar = ( idElement ) => {
        setNuevosAccesos( nuevosAccesos.filter( element => element !== idElement ) );
    }
    /* ------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            { modulosSuperior ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <span> Seleccione máximo 12 accesos </span>
                        </div>
                        <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                            <span> Restantes: <b> { 12-accesosDirectos.length-nuevosAccesos.length } </b> </span>
                        </div>
                    </div>

                    { modulosSuperior.map( moduloPadre => {
                        if( moduloPadre.nombre !== 'Tablero' && moduloPadre.nombre !== 'Ayuda' && moduloPadre.nombre !== 'Reporteador' ){
                            return <ModuloAccesos key={moduloPadre.id} moduloPadre={moduloPadre} accesosDirectos={accesosDirectos}
                                nuevosAccesos={nuevosAccesos} agregar={agregar} eliminar={eliminar} setError={setError}
                                elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}/>
                        }else{
                            return '';
                        }
                    })}

                    { error }
                    <Button variant="contained" className="btn-color" onClick={ () => guardar() }> Guardar </Button>
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default ListaDeAccesos;