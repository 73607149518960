import React, { useState, useEffect, useRef, Fragment } from 'react';
import MaterialTable, { MTableCell } from 'material-table';
import { MuiThemeProvider, createMuiTheme, IconButton } from '@material-ui/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import printJS from 'print-js'
import copy from 'copy-to-clipboard';
import { convertColumnasPDF, convertColumnasPrint, formatExcel, getStylesPDF } from '../../Components/Usuarios/Usuario/DataTableService/ActionsDatatable';
import { csvDownload } from '../../Services/Validation/HelperValidation';
import { pushSuccess } from '../../Services/Notifications/PushService';
import { localization, tableIcons } from '../../Components/Usuarios/Usuario/DataTableService/ElementTable';
import { Delete, SaveAlt, PictureAsPdf, FileCopy, Print } from '@material-ui/icons';
import TooltipComponent from '../Tooltip/TooltipComponent';
import AlertTable from '../../Components/Usuarios/Usuario/DataTableService/AlertTable';
import AlertError from '../../Services/Alerts/AlertSyE';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#81A1DB' },
        secondary: { main: '#81A1DB' },
    },
});

const TableSelectRows = ({ id, title, columns, data, filtering, icon, tooltip, action, actionEdit, cleanTable, setCleanTable, pagosPendientes }) => {
    
    const [columnsTableSelect] = useState(columns);
    const [error, setError] = useState(null);
    const [textAlertError, setTextAlertError] = useState('');
    const [alertError, setAlertError] = useState(false);

    const tableRef = useRef();
    const columnasPDF = convertColumnasPDF( columns ); //this.props.columns
    const estilosPDF = getStylesPDF(id);

    useEffect(() => {
        if(cleanTable) {
            if(tableRef.current) {
                tableRef.current.onSearchChange('');
            }
            setTimeout(() => {
                tableRef.current.onAllSelected(false);
                setCleanTable(false);
            }, 200);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cleanTable]);
    
    const optionsTable = {
        rowStyle: { textTransform: 'uppercase' },
        columnsButton: false,
        headerStyle: { zIndex: "0", color: '#4C638E', fontweight: 'bold', textTransform: 'uppercase' },
        actionsColumnIndex: -1,
        searchFieldAlignment: "left",
        filtering: (filtering) ? filtering : false,
        selection: true,
        selectionProps: rowData => ({
            disabled:   (pagosPendientes)
                        ?   ((!rowData.pagoPendiente && !rowData.pagoAutorizado) || rowData.pagoAutorizado)
                        :   false,
            color: 'primary'
        }),
        showSelectAllCheckbox: (pagosPendientes) ? false : true
    };

    const errorTable = (mensaje) => {
        setTextAlertError(mensaje);
        setAlertError( true );
    } 

    let dataContent = data;

    const detectarContenido = () => {
        if( dataContent.length > 0 ){
            setError( null );
            return true;
        }else{
            setError( <AlertTable mensaje="Asegúrate de tener contenido para exportar" closeAlert={ () => setError(null) } /> );
            return false;
        }
    };

    const dataVisible = (columns, data, quitarSalto=false)=>{    
        return extractDataGroup(data, columnsVisibles(columns), groupByData(columns), quitarSalto);
    };

    const groupByData = (columns) => {
        return columns.filter(columnDef => columnDef.tableData.groupOrder !== undefined);
    };

    const columnsVisibles = (columns) => {
        return columns.filter(columnDef =>(columnDef.field !== '' && columnDef.hidden !== true && columnDef.tableData.groupOrder === undefined)).sort((a, b)=> a.tableData.columnOrder > b.tableData.columnOrder ? 1 : a.tableData.columnOrder < b.tableData.columnOrder ? -1 :0 );
    };

    const extractDataGroup=(data, columnsVisibles, groupBy, quitarSalto)=>{
        let result = [];
        for (const key in data) {  
            if (data[key].groups) { 
                const colName = groupBy.filter(element => element.tableData.groupOrder===data[key].path.length-1)[0];
                result.push({" ": `${colName.title}: ${data[key].value}`}); 
                if(data[key].groups.length>0){ 
                    const group = extractDataGroup(data[key].groups, columnsVisibles, groupBy);
                    for (const key in group) {
                        result.push( group[key]);
                    } 
                }else{
                    const dataVisble = data[key].data.map(rowData =>{ const newJson={};  Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = rowData[columnDef.field]; return element})); return newJson;});
                    dataVisble.map(element=>result.push(element)); 
                }
            }else{ 
                const newJson = {};
                Object.assign( newJson, ...columnsVisibles.map(columnDef => {
                    const element={};
                    if( columnDef.field === 'cpresupuestalClave' || columnDef.field === 'cpresupuestalCve' ){ //Se agrega comilla a campo Clave Presupuestal
                        try {
                            element[columnDef.title] = "'" + (quitarSalto) ? (data[key][columnDef.field]) ? data[key][columnDef.field].replace(/(\r\n|\n|\r)/gm," ") : data[key][columnDef.field] : data[key][columnDef.field];
                        } catch(e) {
                            element[columnDef.title] = data[key][columnDef.field];
                        }
                    }else{
                        try {
                            element[columnDef.title] = (quitarSalto) ? (data[key][columnDef.field]) ? data[key][columnDef.field].replace(/(\r\n|\n|\r)/gm," ") : data[key][columnDef.field] : data[key][columnDef.field];
                        } catch(e) {
                            element[columnDef.title] = data[key][columnDef.field];
                        }
                    }
                    return element;
                }));
                result.push(newJson);
            }
        }
        return result;
    };

    const options = [
        {
            icon: SaveAlt, tooltip: 'CSV',
            isFreeAction: true,
            onClick: (event) => {
                if(detectarContenido()){
                    const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);
                    const dataCsv = tableRef.current.state.data;
                    const resultData = dataVisible(tableRef.current.state.columns, dataCsv, true);
                    if(columnas.length > 0) {
                        if(resultData) {
                            csvDownload(resultData, `${title}${(pagosPendientes) ? `_${pagosPendientes}` : ''}.csv`, id);
                        }
                    } else {
                        errorTable('No ha seleccionado columnas para descargar');
                    }                                
                }
            }
        },
        {
            icon: PictureAsPdf, tooltip: 'PDF',
            isFreeAction: true,
            onClick: (event) => {
                if(detectarContenido()){
                    const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);

                    if(columnas.length > 0) {
                        const doc = new jsPDF(estilosPDF.orientation);
                        const titulo = `LISTA DE ${title.toUpperCase()}${(pagosPendientes) ? ` DE ${pagosPendientes}` : ''}`;
                        const dataPdf = tableRef.current.state.data;
                        const columnsPDF = columnasPDF;
                                
                        doc.autoTable(columnsPDF, dataVisible(tableRef.current.state.columns, dataPdf), {
                                                                margin: { top: 15, bottom: 15 },
                                                                rowPageBreak: 'avoid',
                                                                headStyles: { fillColor: [76, 99, 142] }, //#4C638E
                                                                didDrawPage: function(data) {
                                                                    doc.text(titulo, 14, 12);
                                                                    doc.setFontSize(10);
                                                                    (estilosPDF.orientation === 'landscape')
                                                                    ?   doc.text(`Página ${data.pageNumber}`, 260, 200)
                                                                    :   doc.text(`Página ${data.pageNumber}`, 170, 290)
                                                                },
                                                                styles: estilosPDF.styles,
                                                                columnStyles: estilosPDF.columnStyles
                        });                            
                        doc.save(`${title}${(pagosPendientes) ? `_${pagosPendientes}` : ''}.pdf`);
                    } else {
                        errorTable('No ha seleccionado columnas para descargar');
                    }
                }
            }
        },
        {
            icon: FileCopy, tooltip: 'Copiar',
            isFreeAction: true,
            onClick: (event) => {
                const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);

                if(columnas.length > 0) {
                    if(detectarContenido()){
                        const dataCopy =  tableRef.current.state.data;
                        
                        const resultData = dataVisible(tableRef.current.state.columns, dataCopy, true);
                        if(resultData){
                            const cadenaExcel = formatExcel( resultData, groupByData(tableRef.current.state.columns).length );
                            copy( cadenaExcel, {
                                format: 'text'
                            });
                            pushSuccess({ title: `Se han copiado ${resultData.length} registros al portapapeles` });
                        }
                    }
                } else {
                    errorTable('No ha seleccionado columnas para copiar');
                }
            }
        },
        {
            icon: Print, tooltip: 'Imprimir',
            isFreeAction: true,
            onClick: (event) => {
                const columnas = tableRef.current.state.columns.filter(column => !column.hidden || column.hidden === false);

                if(columnas.length > 0) {
                    if(detectarContenido()){
                        const dataPrint = tableRef.current.state.data;
                        const resultData = dataVisible(tableRef.current.state.columns, dataPrint); 
                        const lenghtGroupBy = groupByData(tableRef.current.state.columns).length
                        const resultVisible = resultData.map((element) => { 
                            const key= Object.keys(element);  
                            if(key.length===1){ 
                                let result = element;
                                Object.keys(resultData[resultData.length-1]).forEach(elements => {
                                    result[elements]=" ";
                                }); 
                                return result;
                            }else{
                                let result = element;
                                Object.keys(element).forEach(keys => {
                                    if(result[keys]===null){
                                        result[keys]="";
                                    }
                                }); 
                                if(lenghtGroupBy>0) result[" "]=" "; 
                                return result;
                            }
                        });
                        printJS({
                            printable: (lenghtGroupBy > 0) ? resultVisible : resultData,
                            properties: convertColumnasPrint(columnsVisibles(tableRef.current.state.columns), false, groupByData(tableRef.current.state.columns).length>0),
                            type: 'json'
                        })
                    }
                } else {
                    errorTable('No ha seleccionado columnas para imprimir');
                }
            }
        }
    ];

    const actionDefault = [
        {
            tooltip,
            icon,
            onClick: (evt, data) => {
                tableRef.current.onSearchChange('');
                action(data);
            }
        }
    ];

    return (
        <Fragment>
            { error }
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    tableRef={tableRef}
                    title={title}
                    columns={columnsTableSelect}
                    data={data}
                    options={optionsTable}
                    icons={tableIcons}
                    localization={localization}
                    actions={
                        (pagosPendientes)
                        ?   [...options, ...actionDefault]
                        :   actionDefault
                    }
                    components={{
                        Cell: props => {
                            if(props.columnDef.title === 'Acciones') {
                                if(pagosPendientes) {
                                    if((props.rowData.pagoPendiente === true) && (props.rowData.pagoAutorizado === true)) {
                                        return  <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone" style={{ width: '84px', padding: '0px 5px' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <TooltipComponent tooltipText="Quitar Autorización">
                                                            <IconButton onClick={() => actionEdit(props.rowData.periodoId)} color="inherit">
                                                                <Delete />
                                                            </IconButton>
                                                        </TooltipComponent>
                                                    </div>
                                                </td>;

                                    } else {
                                        return <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone" style={{ width: '84px', padding: '0px 5px' }}></td>;
                                    }
                                }
                            }
                            return <MTableCell {...props}/>;
                        }
                    }}
                    style={{ boxShadow: '0px 0px 0px 0px' }}
                />
            </MuiThemeProvider>
            <AlertError show={alertError} setOpenAlertSyE={setAlertError} text={textAlertError} textbtnconfirm="Aceptar" type="error"/>
        </Fragment>
    );
}
 
export default TableSelectRows;