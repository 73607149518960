import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_org, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService'; 
import FormTurnos from './FormTurnos';
import { JsonTurnosAdd } from '../../../Helpers/JsonToOneLevel';

const ModalEditTurnos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarTurno = turno => {
        setIsLoading(true);
        axios.put(`${servicios_grupohit_org}/turno/`, turno, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(JsonTurnosAdd(res.data.dataResponse[0]));
                    setOpenEdit( false );
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Turno" parentNode={nombreModulo} minWidth="80%" maxWidth="80%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormTurnos
                    error={error}
                    elementSelect={elementSelect}
                    actualizarTurno={actualizarTurno}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalEditTurnos;