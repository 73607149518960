import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormTipoEstadoCivil = ({ error, setError, enviarTipoEstadoCivil, elementSelect, actualizarTipoEstadoCivil, isLoading }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const nombreRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('TipoEstadoCivil')) {
            const edoCivilNom = nombreRef.current.value;

            enviarTipoEstadoCivil({ edoCivilNom });
        }
    };

    const actualizar = () => {
        if(validarSubmit('TipoEstadoCivil')) {
            const edoCivilNom = nombreRef.current.value;

            setError(null);
            actualizarTipoEstadoCivil({ edoCivilNom }, elementSelect.id);
        }
    };

    return (
        <Fragment>
            <form id="TipoEstadoCivil" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Nombre" type="nombreEdoCivil" placeholder="Escriba el nombre del Tipo de Estado Civil"
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.edoCivilnom : '' } 
                    tooltipText={'Máximo 50 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName="tooltipNomEstCiv" maxLength={50} required/>
                {error}
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}

export default FormTipoEstadoCivil;