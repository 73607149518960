import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
import FormularioColonias from './FormularioColonias';

const ModalEditColonias = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarColonia = ( colonia, coloniaId ) => {
        setIsLoading(true);
        axios.put( `${servicios_grupohit_catalogo}s/colonias/${coloniaId}`, colonia, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        editar({ id: coloniaId, codigopColonia: colonia.coloniaNombre });
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Colonia" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioColonias
                    error={error}
                    elementSelect={elementSelect}
                    actualizarColonia={actualizarColonia}
                    informacion={informacionAdicional}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalEditColonias;