import React, { createRef} from 'react';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import {Button} from '@material-ui/core';
import AlertForm from '../../../../../../Services/AlertForm';

const FormTabuladores = ({ error, setError, enviarTabulador, elementSelect, informacionAdicional }) => {
    
    const validaFecha = (newFechaInic) => {
        if(informacionAdicional.tabulador){
            if(newFechaInic <= inputDateToLong(informacionAdicional.tabulador.fechaini.split('/').reverse().join('-'))) {
                setError(<AlertForm message={`La fecha inicial debe ser mayor a ${informacionAdicional.tabulador.fechaini}`}/>);
                return false;
            } else {
                setError(null);
                return true;
            }
        }else{
            setError(null);
            return true;
        }
        
    };
    
    const fechainiRef = createRef('');

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarTabulador')) {
            if(validaFecha(inputDateToLong(fechainiRef.current.value))){
                const fechaini = inputDateToLong(fechainiRef.current.value);
                enviarTabulador( { fechaini} );
            }
        }
    };
    return (  
        
        <form id={ elementSelect ? 'EditarPuesto' : 'AgregarTabulador' } onSubmit={ enviar }>
            <div className={`form-group`}>
                <InputDateValidation title="Fecha de Inicio" refValue={fechainiRef} required />
            {error}
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </div>
        </form>   
    );
}
export default FormTabuladores;
