import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './stylesQuill.css';

import Button from '@material-ui/core/Button';
import Send from '@material-ui/icons/Send';

const CustomToolbar = ({ action }) => (
    <div id="toolbar">
        <div className="toolbar-middle">
            <span className="ql-formats">
                <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option />
                </select>
            </span>

            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-link" />
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
            </span>
            <span className="ql-formats">
                <button className="ql-clean" />
            </span>
        </div>

        <span className="btn-float">
            <Button className="btn-ql btn-third" onClick={() => action()}> Enviar <Send/> </Button>
        </span>
    </div>
);

const modules = {
    toolbar: { container: "#toolbar" },
    clipboard: { matchVisual: false }
};
const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "color"];

const QuillComponent = ({ mensaje, setMensaje, actionEnviar }) => {
    const action = () => {
        if( actionEnviar ){
            actionEnviar();
        }
    }
    return (
        <div className="text-editor">
            <CustomToolbar action={action}/>
            <ReactQuill value={mensaje} onChange={value => setMensaje(value)}
                modules={modules}
                formats={formats}
                theme={"snow"} // pass false to use minimal theme
            />
        </div>
    );
};

export default QuillComponent;