import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { amountToFloat, floatToAmount, validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

/* Elementos necesarios para la creacion de MuiTheme */
import Radio from '@material-ui/core/Radio';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
});

const FormularioSindicatos = ({ error, enviarSindicato, elementSelect, actualizarSindicato, informacion, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const sindicadtoAbreviaRef = createRef('');
    const sindicatoNombreRef = createRef('');
    const sindicatoDescripRef = createRef('');
    const [ sindicatoPoc, setSindicatoPoc ] = useState( elementSelect ? elementSelect.sindicatoPoc : 'P' );
    const sindicatoMontoRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarSindicato' ) ){
            const sindicadtoAbrevia = sindicadtoAbreviaRef.current.value;
            const sindicatoNombre = sindicatoNombreRef.current.value;
            const sindicatoDescrip = sindicatoDescripRef.current.value;
            const sindicatoMonto =  (sindicatoPoc === 'P')
                                    ? parseFloat(sindicatoMontoRef.current.value)
                                    : amountToFloat(sindicatoMontoRef.current.value)

            enviarSindicato({ sindicadtoAbrevia, sindicatoNombre, sindicatoDescrip, sindicatoPoc, sindicatoMonto });
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarSindicato' ) ){
            const sindicadtoAbrevia = sindicadtoAbreviaRef.current.value;
            const sindicatoNombre = sindicatoNombreRef.current.value;
            const sindicatoDescrip = sindicatoDescripRef.current.value;
            const sindicatoMonto =  (sindicatoPoc === 'P')
                                    ? parseFloat(sindicatoMontoRef.current.value)
                                    : amountToFloat(sindicatoMontoRef.current.value);

            actualizarSindicato({ id: elementSelect.id, sindicadtoAbrevia, sindicatoNombre, sindicatoDescrip, sindicatoPoc, sindicatoMonto });
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarSindicato' : 'AgregarSindicato' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Siglas" type="abreviaturaSindicato" placeholder="Escriba las siglas del Sindicato"
                    tooltipText={'Máximo 24 letras y/o números'} tooltipName={'tooltipAbrevSindi'} maxLength='24' alfanumeric
                    refValue={sindicadtoAbreviaRef} defaultValue={ elementSelect ? elementSelect.sindicatoAbrevia : '' } required/>

                <InputValidation title="Nombre" type="nombreSindicato" placeholder="Escriba el nombre del sindicato" maxLength='128'
                    tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomSindi'}
                    refValue={sindicatoNombreRef} defaultValue={ elementSelect ? elementSelect.sindicatoNombre : '' } required/>
                <InputValidation title="Descripción" type="descripcionSindicato" placeholder="Escriba la descripción del Sindicato" maxLength='256'
                    tooltipText={'Máximo 256 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescSindi'}
                    refValue={sindicatoDescripRef} defaultValue={ elementSelect ? elementSelect.sindicatoDescrip : '' } required/>

                <MuiThemeProvider theme={theme}>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Radio className="radioParametros" checked={sindicatoPoc === 'P'} onChange={ () => setSindicatoPoc('P') } />
                            Cuota en Porcentaje
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Radio className="radioParametros" checked={sindicatoPoc === 'C'} onChange={ () => setSindicatoPoc('C') } />
                            Cuota en Importe
                        </div>
                    </div>
                </MuiThemeProvider>
                { sindicatoPoc === 'P'
                ?   <InputValidation title="Porcentaje (%)" type="porcentaje" placeholder="Escriba la cuota en porcentaje"
                        tooltipText={'Máximo 3 números y dos decimales del 0.00 al 100.00'} maxLength='6'
                        refValue={sindicatoMontoRef} defaultValue={ elementSelect ? elementSelect.porcentajeCuota : '' } 
                        onBlurDecimal={2} onlyNumbers="." required
                    />
                :   <InputValidation title="Importe" type="monto" placeholder="Escriba la cuota en importe"
                        tooltipText={'Máximo 16 números y 2 decimales'} maxLength='19'
                        refValue={sindicatoMontoRef} defaultValue={ elementSelect ? floatToAmount(elementSelect.importeCuota, 2) : '' } 
                        onBlurDecimal={2} onlyNumbers="." isAmount required
                    />
                }
                {error}
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioSindicatos;