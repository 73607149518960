import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import DocumentosEmpleado from './DocumentosEmpleado/DocumentosEmpleado';

const ModalEditExpediente = ({ openEdit, setOpenEdit, elementSelect, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const cerrarModal = () => {
        setOpenEdit( false );
    }

    return (
        <ModalService title="Actualizar Expediente" parentNode={nombreModulo} minWidth="35%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <DocumentosEmpleado
                    empleado={elementSelect}
                    informacion={informacionAdicional}
                    errorTable={errorTable}
                    successTable={successTable}
                />
        </ModalService>
    );
};

export default ModalEditExpediente;