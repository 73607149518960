import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import DataTableService from '../../DataTableService/DataTableService';
import PageTitleBar from '../../Layouts/PageTitleBar';
import BtnBack from '../../../../../Services/Components/SecondTable/BtnBack';
import CardComponent from '../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import FormBusquedaHistorico9719 from './FormBusquedaHistorico9719';
import ModalLoading from '../../../../../Services/ModalLoading/ModalLoading';
import displayWindowSize from '../../Helpers/DisplayWindowSize';
import { consulta_pagos9719 } from '../../../../../Constants/Constants';
import InputText from '../../../../../Services/Components/DataTable/InputText';

const Busqueda9719 = ({ id, openHistorico, setOpenHistorico, openMenu }) => {
    
    const [datosTabla, setDatosTabla] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [checkbox, setCheckbox] = useState({
        datos_especificos: false,
        empleado_num: false,
        empleado_nombre: false,
        empleado_rfc: false,
        empleado_nss: false,
        anio_periodo: false,
        fec_ingreso: false,
        fec_vigencia: false,
        proyecto: false,
        plaza: false,
        categoria_puesto: false
    });
    const [maxWidth, setMaxWidth] = useState(displayWindowSize().width);
    
    window.addEventListener("resize", () => setMaxWidth(displayWindowSize().width));

    const regresarTablero = () => {
        setDatosTabla(null);
        setOpenHistorico(false);
        setCheckbox({
            datos_especificos: false,
            empleado_num: false,
            empleado_nombre: false,
            empleado_rfc: false,
            empleado_nss: false,
            anio_periodo: false,
            fec_ingreso: false,
            fec_vigencia: false,
            proyecto: false,
            plaza: false,
            categoria_puesto: false
        });
    };

    return (
        <Fragment>
        {(openHistorico) &&
            <div id={`modulo${id}`}>
                <div style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                    <PageTitleBar title="Búsqueda Avanzada" path="Tablero"/>
                    <BtnBack tooltipText="Regresar" action={regresarTablero}/>
                    <CardComponent title={<h4>{ consulta_pagos9719 }</h4>}>
                        <div className="row" style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                            <div className="col-md-12 col-sm-12">
                                <FormBusquedaHistorico9719 checkbox={checkbox} setCheckbox={setCheckbox} setDatosTabla={setDatosTabla} setIsLoading={setIsLoading}/>
                            </div>
                        </div>
                    </CardComponent>
                </div>
                    {(datosTabla) &&
                        <div style={{ marginTop: '2rem' }}>
                            <DataTableService id={id} title={consulta_pagos9719}
                                columns={[
                                    { field: "empleadoNum", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoNum.startsWith(term) },
                                    { field: "empleadoNombre", title: "Nombre" }, 
                                    { field: "rfc", title: "RFC" },
                                    { field: "empleadoNss", title: "NSS" },
                                    { field: "fecIngreso", title: "Año / Periodo Ingreso" },
                                    { field: "fecVigencia", title: "Año / Periodo Vigencia" },
                                    { field: "anioPeriodo", title: "Año / Periodo" },
                                    { field: "nomina", title: "Nómina" },
                                    { field: "proyecto", title: "Proyecto", },
                                    { field: "plaza", title: "NUP", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plaza.startsWith(term)  },
                                    { field: "categoriaPuesto", title: "Categoría Puesto" }, 
                                    { field: "totalPercepcion", title: "Total Percepciones", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                                    { field: "totalDeduccion", title: "Total Deducciones", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                                    { field: "totalLiquido", title: "Total Líquido", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                                ]}
                                data={datosTabla} 
                                hideIconAdd
                                hideIconDelete
                                hideIconEdit
                                customOptionsTable={{ filtering: true }} 
                            />
                        </div>
                    }
                    <ModalLoading nombreModulo={id} open={isLoading} setOpen={setIsLoading}/>
            </div>
        }
        </Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    openMenu: state.SystemReducer.openMenu
});

export default connect(mapStateToProps, null)(Busqueda9719);