import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth, obtenerUsuario } from '../../../../../../Services/AuthService';
import { servicios_grupohit_url, servicios_grupohit_catalogo, usuarios_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import './styles.css';

const Usuarios = ({ id, permisos }) => {
    const [ usuario, setUsuario ] = useState( null );
    const [ usuarios, setUsuarios ] = useState( null );
    const [ departamentos, setDepartamentos ] = useState( null );
    useEffect(() => {
        axiosUsuarios();
        axiosDepartamentos();
        setUsuario( obtenerUsuario() );
    }, []);

    const axiosUsuarios = () => {
        axios.get( `${servicios_grupohit_url}/all/subordinates`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosDepartamentos = () => {
        axios.get( `${servicios_grupohit_catalogo}/departamentos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDepartamentos( respuesta.data.dataResponse );
                        break;
                    case 404: //No hay registros para mostrar
                        setDepartamentos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { usuario && usuarios && departamentos ?
                <DataTableService id={id} title={ usuarios_ } permisos={permisos}
                    columns={[
                        { field: "datosUsuarioNom", title: "Nombre" },
                        { field: "datosUsuarioPat", title: "Primer Apellido" },
                        { field: "datosUsuarioMat", title: "Segundo Apellido" },
                        { field: "username", title: "Usuario" },
                    ]}
                    showIconLook={ usuario.rol === true ? true : false }
                    showIconView={ usuario.rol === true ? true : false }
                    data={ usuarios.filter(user => user.id !== usuario.claveUsuario) }
                    informacionAdicional={{ usuarios, departamentos }}/>
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default Usuarios;