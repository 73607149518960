import React, { useState } from 'react';

import ModalService from '../../../ModalService/ModalService';
import ConsultarNomina from './ConsultarNomina';

const ModalConsultaNomina = ({ moduloID, openAdd, setOpenAdd, nomina }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    return (
        <ModalService title={nomina.nominaNom} parentNode={moduloID} minWidth="60%" maxWidth="60%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <div className="mb-2">
                    <b> Periodo: </b> {nomina.fecIni} - {nomina.fecFin}
                </div>
                <ConsultarNomina moduloID={moduloID} nomina={nomina} error={error}/>
        </ModalService>
    );
};

export default ModalConsultaNomina;