import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
/* -----------------------------------------------------------------------------------------------------  */
import { connect } from 'react-redux';
import { agregarNavegador } from '../../../../../Redux/actions/NavActions';
/* -----------------------------------------------------------------------------------------------------  */
import { iconosDrawer } from '../../../Usuario/MenuDrawer/Drawer/IconosDrawer'; //Importacion de Iconos
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { longDateToInputDate } from '../../../../../Services/Validation/HelperValidation';

const AccesoDirecto = ({ modulo, setOpenDelete, setElementSelect, navegadoresStore, agregarNavegador, componenteActivoID }) => {
    const agregarNav = ( modulo ) => {
        const {id, nombre, permisos} = modulo;
        const moduloNew = { id, nombre, ruta: nombre, permisos };
        agregarNavegador( navegadoresStore, moduloNew, componenteActivoID );
    }

    return (
        <React.Fragment>
            <Card className="cardBoard cardTablero" onClick={ modulo.bloqueado && modulo.bloqueado.length > 0 ? null : () => agregarNav( modulo ) }>
                <CardContent>
                    <div className="icon">{iconosDrawer[ modulo.icono ]}</div>
                    <p className="name">{modulo.nombre}</p>
                    <div className="textRBloqueado">
                        { modulo.bloqueado && modulo.bloqueado.length > 0 &&
                            <React.Fragment>
                                <b> Módulo Restringido </b>
                                <p> {`${longDateToInputDate(modulo.bloqueado[0].fecini)} - ${longDateToInputDate(modulo.bloqueado[0].fecfin)}`} </p>
                            </React.Fragment>
                        }
                    </div>
                </CardContent>
            </Card>
            <IconButton size="small" style={{ position: 'absolute', right: '0', top: '0', marginRight: '1.4rem', marginTop: '0.5rem' }}
                onClick={() => { setElementSelect( modulo ); setOpenDelete( true ); }}>
                <ClearIcon fontSize="inherit" />
            </IconButton>
        </React.Fragment>
    );
};
/* ------------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
/* ------------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, { agregarNavegador })(AccesoDirecto);