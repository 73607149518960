import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import ChatIcon from '@material-ui/icons/Chat';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import useStylesMenu from '../UseStylesMenu';


function RenderMobileMenu({ mobileMoreAnchorEl, setMobileMoreAnchorEl, mobileMenuId, mensajeNuevo, setOpen }) {
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const classes = useStylesMenu();

    return (
        <Menu className="notifications" anchorEl={mobileMoreAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={mobileMenuId}
            keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen} onClose={ () => setMobileMoreAnchorEl(null) }
        >
            <MenuItem  >
                <IconButton naria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} classes={{ badge: classes.notificationColor }}>
                        <MailIcon />
                    </Badge>
                </IconButton>
                <label>Mensajes</label>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} classes={{ badge: classes.notificationColor }}>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <label>Notificaciones</label>
            </MenuItem>
            <MenuItem onClick={() => { setMobileMoreAnchorEl(null); setOpen(true) }}>
                <IconButton aria-label="open drawer" color="inherit" >
                    {
                        (mensajeNuevo.length > 0)
                        ? <Badge badgeContent={mensajeNuevo.length} classes={{ badge: classes.notificationColor }}><ChatIcon /></Badge>
                        : <ChatIcon />
                    }
                </IconButton>
                <label>Chat</label>
            </MenuItem>
        </Menu>
    );
}

export default RenderMobileMenu;
