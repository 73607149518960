import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosAll } from '../../../../../../Redux/actions/EmpleadosActions';
import { historico_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import InfoHistorico from './InfoHistorico';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Historico = ({ id, permisos, empleadosAll,  }) => {

    const [empleadoSelected, setEmpleadoSelected] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        //empleados activos y bajas
        obtenerEmpleadosAll((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const irInfo = empleado => {
        const datosEmpleado = {
            idEmpleado: empleado.id,
            nombreEmpleado: empleado.nombreCompleto,
            nueEmpleado: empleado.empleadoClave
        };
        setEmpleadoSelected(datosEmpleado);
    };

    return (
        <Fragment>
        {(empleadoSelected === null)
        ?   <div id={`modulo${id}`}>
            {(empleadosAll)
            ?   <DataTableService
                    id={id}
                    title={historico_}
                    columns={[
                        { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                        { field: "nombreCompleto", title: "Nombre" },
                        { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
                        { field: "empleadoRfc", title: "RFC" },
                        { field: "empleadoNss", title: "NSS" },
                        { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                        { field: "puestoCve", title: "Clave Puesto" },
                        { field: "puestoNom", title: "Puesto" },
                        { field: "nominaNombre", title: "Nómina" },
                        { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                        { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                        { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                    ]}
                    data={empleadosAll}
                    customOptionsTable={{ filtering: true }}
                    hideIconAdd hideIconDelete hideIconEdit
                    showIconDescription
                    onShowTable={irInfo}
                    permisos={permisos}
                />
            :   <SpinnerOval />
            }
            </div>
        :   <InfoHistorico id={id} permisos={permisos} empleadoSelected={empleadoSelected} setEmpleadoSelected={setEmpleadoSelected}/>
        }
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    empleadosAll: state.EmpleadosReducer.empleadosAll
});
  
const mapDispatchToProps = { obtenerEmpleadosAll };
  
export default connect(mapStateToProps, mapDispatchToProps)(Historico);