import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import DataTableService from '../../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonHistoricoZonaPago } from '../../../../Helpers/JsonToOneLevel';

const TablaZonaPago = ({ id, idEmpleado, nombreEmpleado, setTextAlertError, setOpenAlertError }) => {

    const [historicoZonaPago, setHistoricoZonaPago] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupohit_rh}/empleado/zonapago/${idEmpleado}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const infoHistorico = ArrayJsonHistoricoZonaPago(res.data.dataResponse);
                    setHistoricoZonaPago(infoHistorico);
                    break;
                default:
                    setHistoricoZonaPago([]);
                    setTextAlertError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => { 
            setHistoricoZonaPago([]);
            setTextAlertError(verifique_conexion);
            setOpenAlertError(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id={`modulo${id}`}>
            {   
                historicoZonaPago
                ?   <DataTableService
                        id={`${id}zonapago`}
                        title={`Histórico de Zonas de Pago de ${nombreEmpleado}`}
                        columns={[
                            { field: "tipoDomicilio", title: "Tipo de Domicilio" },
                            { field: "calle", title: "Calle" },
                            { field: "numeroExterior", title: "Núm. Exterior" },
                            { field: "numeroInterior", title: "Núm. Interior" },
                            { field: "cp", title: "C.P." },
                            { field: "colonia", title: "Colonia" },
                            { field: "municipio", title: "Municipio" },
                            { field: "pais", title: "País" },
                            { field: "telefono", title: "Teléfono" },
                            { field: "fechaInicial", title: "Fecha Inicial" },
                            { field: "fechaFinal", title: "Fecha Final" },
                        ]}
                        hideIconAdd hideIconDelete hideIconEdit
                        data={historicoZonaPago}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default TablaZonaPago;