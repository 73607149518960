import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import Restricciones from './Restricciones/Restricciones';

const ModalAddRestricciones = ({ id, openModalForm, setOpenModalForm, errorTable, successTable, informacionAdicional }) => {
    const cerrarModal = () => {
        setOpenModalForm( false );
    }

    return (
        <ModalService title="Restringir Módulos" parentNode={id} minWidth="40%" maxWidth="40%"
            isOpen={ openModalForm } onClose={ () => cerrarModal() } >

                <Restricciones idModulo={id} errorTable={errorTable} successTable={successTable} informacion={informacionAdicional}/>
        </ModalService>
    );
};

export default ModalAddRestricciones;