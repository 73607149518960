import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from "react-router";

/*Complementos*/
import { lengthPassword, servicios_grupohit_url, servicios_grupohit_token, ocurrio_un_error, sao, servicios_grupohit } from '../../Constants/Constants';
import AlertForm from '../../Services/AlertForm';
import AlertSyE from '../../Services/Alerts/AlertSyE';
import PasswordValidation from '../../Services/Validation/PasswordValidation';
import Footer from '../Footer/Footer';
import { validarSubmit } from '../../Services/Validation/HelperValidation';

/*Vista Material UI*/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const Recuperar = ({history}) => {
    
    // token: se cambia a true cuando el token validad existe
    // mensajeContrasena: se guardan componentes de muestra de mensajes
    const [tokenExist, setTokenExist] = useState(null);
    const [mensajeContrasena, setMensajeContrasena] = useState('');
    const [btnBloqueado, setBtnBloqueado] = useState(false);
    //state para control de alert
    const [openAlertSyE, setOpenAlertSyE] = useState(true);
    const [ logotipo, setLogotipo ] = useState(null);

    useEffect(() => {
        axiosLogotipo();
    }, []);

    /* Axios para obtener logotipo en base64 */
    const axiosLogotipo = () => {
        axios.get( `${servicios_grupohit}/logo/free/` )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setLogotipo( respuesta.data.dataResponse );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
    }

    const { token } = useParams();

    //Refs para obtener los valores de los inputs
    const pass1Ref = useRef('');
    const pass2Ref = useRef('');

    const returnLogin = (e) => {
        if(e) {
            e.preventDefault();
        }
        (sao)
        ?   history.push("/sao/acceso")
        :   history.push("/acceso");
    }

    const enviaMensajeConAction = (type, message) => {
        setMensajeContrasena(<AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Cambiar contraseña" text={message} textbtnconfirm="Aceptar" type={type} action={returnLogin}/>);
    }

    useEffect(() => {
        if(tokenExist === null) {

            //Al iniciar el componente se verifica que el token sea valido
            fetch(`${servicios_grupohit_token}/validate/${token}`, { method: 'POST' })
            .then( response => {
                return response.json();
            })
            .then( response => {
                switch(response.status){
                    case 200:
                        setTokenExist(true);
                        break;
                    default:
                        setTokenExist(false);
                        enviaMensajeConAction("error", response.msg);
                        break;
                }
            })
            .catch( error => {
                setTokenExist(false);
                enviaMensajeConAction("error", ocurrio_un_error);
            });       
        }
    });

    //se envía contraseña a backend para actualizar
    const enviarContraseña = e => {
            e.preventDefault();
            setBtnBloqueado(true);
            const pass1 = pass1Ref.current.value;
            const pass2 = pass2Ref.current.value;
            if(validarSubmit("form-recuperar")) {
                if(pass1 === pass2) {
                    fetch(`${servicios_grupohit_url}/account/update/password/${token}`,
                    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ "newPassword": pass1, "confirmPassword": pass2 }) })
                    .then( response => {
                        return response.json();
                    })
                    .then( response => {
                        switch(response.status){
                            case 200:
                                enviaMensajeConAction("success", response.msg);
                                break;
                            default:
                                enviaMensajeConAction("error", response.msg);
                                break;
                        }
                    })
                    .catch( error => {
                        setBtnBloqueado(false); 
                        enviaMensajeConAction("error", ocurrio_un_error);
                    })
                } else if(pass1 !== pass2) {
                    setBtnBloqueado(false);
                    setMensajeContrasena(<AlertForm message="Los campos no coinciden" />);
                }
            } else {
                setBtnBloqueado(false);
            }
    }


    let componente;
    (tokenExist === true)
    ? componente =  <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <Paper className="cont_log" elevation={6} style={{paddingBottom: '1rem'}}>
                                        <div className="log_form">
                                            <div className = "title">
                                                { logotipo && <img src={`data:image/jpeg;base64, ${logotipo}`} className="header_logo separation" alt="site-logo"/> }
                                                <span>Cambiar Contraseña</span>
                                            </div>
                                            <form id="form-recuperar" onSubmit={e => enviarContraseña(e)}>
                                                <PasswordValidation title="Nueva contraseña" required placeholder="Escriba su nueva contraseña" type="password" maxlength={lengthPassword} refValue={pass1Ref}
                                                    tooltipText={'De 8 a 16 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial incluyendo !@#$&*%/()=?¡.,|°<>;+_'} tooltipName={'tooltipPassword'}/>
                                                <PasswordValidation title="Confirme su contraseña" required placeholder="Confirme su nueva contraseña" type="password" maxlength={lengthPassword} refValue={pass2Ref}
                                                    tooltipText={'De 8 a 16 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial incluyendo !@#$&*%/()=?¡.,|°<>;+_'} tooltipName={'tooltipConfirmPassword'}/>
                                                { mensajeContrasena }
                                                <Button type="submit" variant="contained" className="btn-color" disabled={ btnBloqueado }>Guardar Nueva Contraseña</Button>
                                            </form>
                                        </div>
                                    </Paper>
                                </div>
                            </div>
                    </React.Fragment>
    : componente =  <React.Fragment>{ mensajeContrasena }</React.Fragment>;

    return (
        <div className = "background">
            <div className="container-fluid">
                { componente }
            </div>
            <Footer className="footer-login" />
        </div>
    );
}

export default Recuperar;