import React, { useState } from 'react';
import axios from 'axios';
import Joyride, { ACTIONS, STATUS, EVENTS } from 'react-joyride';
import { headersAuth } from '../../../../Services/AuthService';
import { servicios_grupohit_url } from '../../../../Constants/Constants';

const Tour = ({ setRun, setTour, steps }) => {

    const [stepIndex, setStepIndex] = useState(0);

    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
    
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
          // Update state to advance the tour
          setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)) ;
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
          // Need to set our running state to false, so we can restart if we click start again.
          setRun(false);
        }
    
        if(stepIndex === 6) {
          let url=`${servicios_grupohit_url}/disable/tour`;
          axios.post( url, null, headersAuth())
          .then( res => {
            const auxUsuarioJson=JSON.parse(localStorage.getItem("IGSESSID"));
            auxUsuarioJson.tour="false";
            const usuariocontourcompletado=JSON.stringify(auxUsuarioJson);
            localStorage.setItem("IGSESSID",usuariocontourcompletado);
            setTour("false");
          })
          .catch( error => {  
    
          });
        }
      };

    return(
        <Joyride 
            callback={handleJoyrideCallback}
            steps={steps}
            continuous={true}
            showProgress={true}
            disableScrolling={true}
            disableCloseOnEsc={true}
            disableOverlayClose={true}
            scrollToFirstStep={true}
            spotlightPadding={0}
            
            styles={{ 
                        buttonClose:{
                            display:'none'
                        },
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#FFFFFF',
                            overlayColor: 'rgba(0,0,0, 0.4)',
                            primaryColor: '#81A1DD',
                            textColor: 'Black',
                            width:500,
                            zIndex: 1500                                  
                        }
                        
                    }}
            locale={{
                        next:'Sig.',
                        back:'Volver',
                        last:'Último'
                    }}
        />
    );
}

export default Tour;