import React from 'react';
import { longDateToInputDate } from '../../../../../../../Services/Validation/HelperValidation';

import Avatar from '@material-ui/core/Avatar';
import { obtenerUsuariosRestricciones } from './HelperRestricciones';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
    },
}));

const InformacionRestricciones = ({ usuariosDepartamento, restriccionesDepartamento }) => {
    const classes = useStyles();
    const userSelected = obtenerUsuariosRestricciones( usuariosDepartamento, restriccionesDepartamento );
    const elementSelect = {
        fecini: longDateToInputDate(restriccionesDepartamento[0].fecini),
        fecfin: longDateToInputDate(restriccionesDepartamento[0].fecfin),
    };

    return (
        <React.Fragment>
            { userSelected.map( user => (
                <div key={user.id} className="accesosContenido centrarR mt-2">
                    <Avatar src={`data:image/jpeg;base64, ${user.foto}`} className={classes.small}/>
                    <span>{user.label}</span>
                </div>
            ))}
            <div className="mt-2"> <b> Periodo de Restricción: </b> {`${elementSelect.fecini} - ${elementSelect.fecfin}`} </div>
        </React.Fragment>
    );
};

export default InformacionRestricciones;