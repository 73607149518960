import React from 'react'
import DataTableService from '../../../../DataTableService/DataTableService';
import { especiales_ } from '../../../../../../../Constants/Constants';

const TableVerMovEscalafonarios = ({ id, escalafones, mostrarTablaVerEscalafon2, permisos, nominasRL, periodoActual }) => {
    return (
        <DataTableService id={`${id}vermovimientos`} title={especiales_}
            columns={[
                { field: "folio", title: "Folio" },
                { field: "tipo", title: "Tipo de Movimiento" },
                { field: "fecMov", title: "Fecha del Movimiento" },
                { field: "fecini", title: "Fecha Inicial" },
                { field: "fecfin", title: "Fecha Final" },
            ]}
            data={escalafones}
            customOptionsTable={{ filtering: true }}
            hideIconEdit
            hideIconAdd
            showIconPageview
            onShowTable={mostrarTablaVerEscalafon2}
            permisos={permisos}
            informacionAdicional={{ fechaInicialPeriodo: nominasRL.find(n => (n.periodoAnio === periodoActual.anio) && (n.periodoPerAnio === periodoActual.periodo)).periodoFecini }}
        />
    );
}
 
export default TableVerMovEscalafonarios;
