import React, { useState, useEffect, Fragment } from 'react'; 
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../Media/Img/profile.jpg';
import ConvertDate from '../../../../Usuario/Helpers/ConvertDate';
import { longDateToInput, longDateToInputDate } from '../../../../../../Services/Validation/HelperValidation';
import AlertForm from '../../../../../../Services/AlertForm';
import diccionario from './diccionario';

const FormInfoAuditoria = ({ elementSelect }) => { 

    const [ detalleCRUD, setDetalleCRUD ] = useState(null);
    const regexFechaHora= /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])) (?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d).[0-9]/;
    const [ error, setError ] = useState(null);

    const flatten = function(data) {
        var result = {};
        function recurse (cur, prop) {
            if (Object(cur) !== cur) {
                result[prop] = cur;
            } else if (Array.isArray(cur)) {
                    for(var i=0, l=cur.length; i<l; i++)
                        recurse(cur[i], prop + "[" + i + "]");
                if (l === 0)
                    result[prop] = [];
            } else {
                var isEmpty = true;
                for (var p in cur) {
                    isEmpty = false;
                    recurse(cur[p], p);
                }
                if (isEmpty && prop)
                    result[prop] = {};
            }
        }
        recurse(data, "");
        return result;
    }

    const res = (elementSelect.datos._children.length && !elementSelect.datos._children[0]._children.value._value.includes('[') && !elementSelect.datos._children[0]._children.value._value.includes('='))
                ?   { data: `{${elementSelect.datos._children.map(item => {
                        const newItem = `"${[item._children.column._value]}" : "${item._children.value._value.trim()}"`
                        return newItem;
                    })}}`}
                :   { data: elementSelect.datos._children[0]
                    ? !elementSelect.datos._children[0]._children.value._value.includes('[')
                        ?   `{${elementSelect.datos._children[0]._children.value._value.replace(/{/g,' { ').replace(/}/g,' } ')}}`
                        :   `{${elementSelect.datos._children[0]._children.value._value.replace(/\[/g,'{ ').replace(/\]/g,'}')}}`
                    : []
                    };

    const isArray = { value: false };

    useEffect (()=>{
        const regexLlavePrimaria = /["][a-zA-Z-_]+["][\s]?[:][\s]?["][a-zA-Z-_\s]+[[][a-zA-ZáéíóúüÜñÑ=0-9,\s-_]+[\]]["]/g;
        let searched = res.data.match(regexLlavePrimaria);
        for (const key in searched) { 
            //let newKey = searched[key];
            const regexPrimaryLabel = /["][a-zA-Z-_]+["][\s]?[:][\s]?["][a-zA-Z-_\s]+/g;
            const object1 = res.data.match(/[[]/g);
            const object2 = res.data.match(/[\]]/g);
            const newKey = searched[key].replace(regexPrimaryLabel, "").replace(/,/g, '","').replace(/=/g, '":"').replace(object1, '"').replace(object2, '');
            res.data = res.data.replace(regexLlavePrimaria, newKey);
        }
        //if((res.data!==null) && ((elementSelect.crudOperation !== "ACTUALIZAR") || (elementSelect.datos._children.length && (elementSelect.datos._children[0]._children.value._value.includes('[') || elementSelect.datos._children[0]._children.value._value.includes('='))))){
        if(typeof res.data !== 'object') { 
                if((res.data.match(/^[{]</g) && res.data.match(/>[}]$/g)) || res.data.match(/plantilla_html/g)) {
                    if(res.data.match(/^[{]</g) && res.data.match(/>[}]$/g)) {
                        const comillas = /"/g;
                        res.data = res.data.replace(comillas, "'");
                        const inicioHTML = /^[{]/g;
                        res.data = res.data.replace(inicioHTML, `{"${elementSelect.datos._children[0]._children.column._value}":"`);
                        const finHTML = /[}]$/g;
                        res.data = res.data.replace(finHTML, '"}');
                    }
                    if(res.data.match(/plantilla_html/g)) {
                        const regexPlantilla = /[<][p]\s[<>A-Za-záéíóúÁÉÍÓÚÑñ[üÜ\s="-.{_}:0-9;/^]+["]\s?[,]\s?/g;
                        const etiquetasHTMl = /[<][/a-z\s="-]+[>]/g;
                        const comillas = /"/g;
                        const finEtiqueta = /}}\\",/g;
                        let plantilla = res.data.match(regexPlantilla);
                        if(plantilla) {
                            plantilla[0] = plantilla[0].replace(etiquetasHTMl,'').replace(comillas, '\\"').replace(finEtiqueta, '}}",');
                            res.data = res.data.replace(regexPlantilla, plantilla[0]);
                        }
                    }
                    const espacio = /&nbsp;/g;
                    res.data = res.data.replace(espacio, ' ');
                    const etiquetasHTMl = /[<][0-9a-zA-Z/='\- ]+[>]/g;
                    res.data = res.data.replace(etiquetasHTMl, ' ');
                    const tabulacion = /\t/g;
                    res.data = res.data.replace(tabulacion, ' ');
                } else {
                    //ELIMINAR CONFIGURACIÓN DE REPORTES RRHH 
                    const regexLlaveReportesRRHH = /, reporteJson={.*"}}}}/;
                    let searchedLlaveReportesRRHH= res.data.match(regexLlaveReportesRRHH);
                    for (const key in searchedLlaveReportesRRHH) {    
                        res.data = res.data.replace(searchedLlaveReportesRRHH[key], '');    
                    }

                    const cPresupuestalEspecial = /[cC][pP]resupuestal(?:[a-zA-Z]+)?=([0-9A-Z]+|([A-Z]{1}))=/g;
                    let searchedCPresupuestalEsp = res.data.match(cPresupuestalEspecial);
                    for (const key in searchedCPresupuestalEsp) {  
                        let newKey = searchedCPresupuestalEsp[key];
                        res.data = res.data.replace(searchedCPresupuestalEsp[key], `"${newKey.match(/[cC][pP]resupuestal(?:[a-zA-Z]+)?/g)}": "${newKey.match(/([0-9A-Z]+|([A-Z]{1}))=/g)}"`);  
                    }

                    //CREAR LLAVES PRINCIPALES
                    const llavesDosPuntos = /[a-zA-Z]+:[^,}]+( |){/g;
                    let searchedLlavesDosPuntos = res.data.match(llavesDosPuntos);
                    for (const key in searchedLlavesDosPuntos) { 
                        let newKey = searchedLlavesDosPuntos[key];  
                        res.data = res.data.replace(searchedLlavesDosPuntos[key], newKey.replace(':','='));    
                    } 
    
                    const regexLlavePrimaria = /[A-Z-a-z]{1,100} \[|([A-Za-z]{1,100}=)[A-Za-z]{1,100} \[/g;
                    let searched = res.data.match(regexLlavePrimaria);
                    for (const key in searched) { 
                        let newKey = searched[key];
                        res.data = res.data.replace(searched[key], newKey.replace('=',''));
                        res.data = res.data.replace(searched[key], newKey.replace(' [','{'));
                    }
    
                    const regexLlaves = /[A-Za-z0-9]+=[A-Za-z0-9]+( |){/g
                    let searchedLlaves = res.data.match(regexLlaves);
                    for (const key in searchedLlaves) { 
                        let newKey =  '"' + searchedLlaves[key].replace('=','').replace('{','":{');
                        res.data = res.data.replace(searchedLlaves[key], newKey);    
                    }
                    
                    const tabulacion = /\t/g;
                    res.data = res.data.replace(tabulacion, ' ');
                    
                    let valorHTML = null;

                    if(res.data.match(/plantillaHtml=[\w\s]/g)) {
                        const html = /(plantillaHtml=([\s<a-zA-ZáéíóúÁÉÍÓÚ.,;:)\t&%$#"+[\](0-9_\-=\\>/]+(?:{{)?[\s<a-zA-ZáéíóúÁÉÍÓÚ.,;:)\t&%$#"+[\](0-9_\-=\\>/]+(?:}})?[\s<a-zA-ZáéíóúÁÉÍÓÚ.,;:)\t&%$#"+[\](0-9_\-=\\>/]+)+>)+|plantillaHtml=/g;
                        valorHTML = res.data.match(html);
                        res.data = res.data.replace(html, '');
                    }

                    if(valorHTML) {
                        const regexPlantillaHTML = /plantillaHtml=/g;
                        valorHTML[0] = valorHTML[0].replace(regexPlantillaHTML, '"plantillaHtml": "');
                        valorHTML[0] = `${valorHTML[0]}"`;
                        const comaLlave = /, }/g;
                        res.data = res.data.replace(comaLlave, '}');
                    }
                    
                    const regexLlavesComillas =/[A-Za-z]+ {/g
                    let searchedLlavesComillas = res.data.match(regexLlavesComillas);  
                    for (const key in searchedLlavesComillas) { 
                        let newKey = '"' + searchedLlavesComillas[key];
                        res.data = res.data.replace(searchedLlavesComillas[key], newKey.replace(' {','":{'));  
                    }

                    const regexLlavesFondo = /[,]\s?[a-zA-Z0-9]+[=]/g;
                    let searchedLlavesFondo = res.data.match(regexLlavesFondo);
                    for (const key in searchedLlavesFondo) {  
                        let newKey = ',"' + searchedLlavesFondo[key];
                        res.data = res.data.replace(searchedLlavesFondo[key], newKey.replace('=','":"'));  
                    }
    
                    const comillasFondo = /:"[^,}]+(,|})/g;
                    let searchedComillasFondo = res.data.match(comillasFondo);
                    for (const key in searchedComillasFondo) { 
                        let newKey = searchedComillasFondo[key]; 
                        if(!/{ }/g.test(newKey)){
                            res.data = res.data.replace(searchedComillasFondo[key], newKey.replace('}','"}')); 
                            res.data = res.data.replace(searchedComillasFondo[key], newKey.replace(',','",'));  
                        }
                    }
                 
                    const comillasDosPuntos = /[a-zA-Z]+:[^,}{]+/g;
                    let searchedComillasDosPuntos = res.data.match(comillasDosPuntos);
                    for (const key in searchedComillasDosPuntos) { 
                        let newKey = '"'+searchedComillasDosPuntos[key];  
                        res.data = res.data.replace(searchedComillasDosPuntos[key], newKey.replace(':','":"')+'"');    
                    }

                    const textoComas = /"[\w\s.áéíóúÁÉÍÓÚ]+",[\w\s.áéíóúÁÉÍÓÚ,]+}/g;
                    let searchedTextoComas = res.data.match(textoComas);
                    for (const key in searchedTextoComas) { 
                        let newKey = searchedTextoComas[key];  
                        res.data = res.data.replace(searchedTextoComas[key], `"${newKey.replace(/"/g,'').replace(/}/g,'').replace(/,/g,',')}"`);
                    }

                    const textoComas2 = /"[\w\s.áéíóúÁÉÍÓÚ]+",[\w\s.áéíóúÁÉÍÓÚ,]+\s/g;
                    let searchedTextoComas2 = res.data.match(textoComas2);
                    for (const key in searchedTextoComas2) { 
                        let newKey = searchedTextoComas2[key];  
                        res.data = res.data.replace(searchedTextoComas2[key], `"${newKey.replace(/"/g,'').replace(/,[\s]$/g,'", ')}`);
                    }

                    const regexComillasIgual =/:",/g
                    res.data = res.data.replace(regexComillasIgual, ':"",');
    
                    const cierreComillas =/[\w]}/g
                    res.data = res.data.replace(cierreComillas, ':"",');
    
                    const corcheteMal = /]["]+/g;
                    res.data = res.data.replace(corcheteMal, '"');
                    const corcheteMal2 = /],/g;
                    res.data = res.data.replace(corcheteMal2, '},');
    
                    const llaveMal =/["[A-Za-z0-9_]+" : "[A-Za-z0-9_]+{/g 
                    res.data = res.data.replace(llaveMal, '');
                    const llaveMal2 =/[A-Za-z] \[/g 
                    res.data = res.data.replace(llaveMal2, '":{');
    
                    const llaveMal3 =/]"/g;
                    res.data = res.data.replace(llaveMal3, '"}');
    
                    const llaveMal4 =/"}"/g;
                    res.data = res.data.replace(llaveMal4, '"');
    
                    const llaveMal5 =/}"}/g;
                    res.data = res.data.replace(llaveMal5, '}');
    
                    const doblesComillas3 =/:""+/g;
                    res.data = res.data.replace(doblesComillas3, ':" "');
                    const doblesComillas =/_"/g;
                    res.data = res.data.replace(doblesComillas, '_');
                    const doblesComillas2 =/"["]+/g;
                    res.data = res.data.replace(doblesComillas2, '"');
    
                    const comillasMal =/", [a-zA-Z]+"/g;
                    let searchedComillasMal = res.data.match(comillasMal);
                    for (const key in searchedComillasMal) { 
                        let newKey = searchedComillasMal[key];  
                        res.data = res.data.replace(searchedComillasMal[key], `", "${newKey.replace(/", /g,'')}`);
                    }
    
                    const comillasMal4 = /}["]+/g;
                    res.data = res.data.replace(comillasMal4, '');
    
                    const comaText =/:"[A-Za-z]+", "[A-Za-z]+,/g
                    let searchedComaText = res.data.match(comaText);
                    for (const key in searchedComaText) { 
                        let newKey = searchedComaText[key];
    
                        res.data = res.data.replace(searchedComaText[key],`${newKey.replace(/", "/g, ' ').replace(/,$/g, '')}",`);    
                    };
                    if(valorHTML) {
                        const agregaHTML = /[}]$/
                        res.data = res.data.replace(agregaHTML, `, ${valorHTML[0]}}`);
                    }

                    const idsMal = /{[a-zA-Z]+=[A-Za-z0-9]+,/g;
                    let searchedIdsMal = res.data.match(idsMal);
                    for (const key in searchedIdsMal) { 
                        let newKey = searchedIdsMal[key];  
                        res.data = res.data.replace(searchedIdsMal[key], `{ "${newKey.match(/{[a-zA-Z]+/g)[0].replace(/{/g, '')}": "${newKey.match(/[A-Za-z0-9]+,/g)[0].replace(/,/g, '')}", `);
                    }

                    const idsMal2 = /[,\s]+[a-zA-Z]+=[A-Za-z0-9]+,/g;
                    let searchedIdsMal2 = res.data.match(idsMal2);
                    for (const key in searchedIdsMal2) { 
                        let newKey = searchedIdsMal2[key];  
                        res.data = res.data.replace(searchedIdsMal2[key], `, "${newKey.match(/[,\s][a-zA-Z]+/g)[0].replace(/,/g, '')}": "${newKey.match(/[A-Za-z0-9]+,/g)[0].replace(/,/g, '')}", `);
                    }

                    const etiquetaSinComillas = /",[?:\s]+?[a-zA-Z]+["]/g;
                    let searchedEtiquetaSinComillas = res.data.match(etiquetaSinComillas);
                    for (const key in searchedEtiquetaSinComillas) { 
                        let newKey = searchedEtiquetaSinComillas[key];  
                        res.data = res.data.replace(searchedEtiquetaSinComillas[key], `", "${newKey.match(/[a-zA-Z\-_]+/g)[0]}"`);
                    }

                    const etiquetaSinComillas2 = /},[?:\s]+?[a-zA-Z]+["]/g;
                    let searchedEtiquetaSinComillas2 = res.data.match(etiquetaSinComillas2);
                    for (const key in searchedEtiquetaSinComillas2) { 
                        let newKey = searchedEtiquetaSinComillas2[key];  
                        res.data = res.data.replace(searchedEtiquetaSinComillas2[key], `}, "${newKey.match(/[a-zA-Z\-_]+/g)[0]}"`);
                    }

                    const valorSinComillas = /"[\w@.]+([}]|[,][\s"][\w_-\s]+)["](?:[\s]+)?[:]/g;
                    let searchedValorSinComillas = res.data.match(valorSinComillas);
                    for (const key in searchedValorSinComillas) { 
                        let newKey = searchedValorSinComillas[key];  
                        res.data = res.data.replace(searchedValorSinComillas[key], `${newKey.match(/"[\w@.]+/g)[0]}", "${newKey.match(/([}]|[,][\s"][\w_-\s]+)["](?:[\s]+)?[:]/g)[0].replace(/,/g, '')}`);
                    }

                    const espacio = /&nbsp;/g;
                    res.data = res.data.replace(espacio, ' ');
                    const etiquetasHTMl = /[<][0-9a-zA-Z/='\- ]+[>]/g;
                    res.data = res.data.replace(etiquetasHTMl, ' ');

                    const dosPuntos = /[A-Za-z_]+[{]\s?["]/g;
                    let searchedDosPuntos = res.data.match(dosPuntos);
                    for (const key in searchedDosPuntos) { 
                        const etiqueta = /[A-Za-z_]+/g;
                        let newKey = '"'+searchedDosPuntos[key].match(etiqueta)[0]+'":{"';
                        res.data = res.data.replace(searchedDosPuntos[key], newKey);    
                    }

                    const sinValor = /["][A-Za-z_]+["]\s?[:]\s?["][A-Za-z_]+["]\s?[:]\s?/g
                    let searchedSinValor = res.data.match(sinValor);
                    for (const key in searchedSinValor) { 
                        const etiqueta = /[A-Za-z_]+/g;
                        let newKey = '"'+searchedSinValor[key].match(etiqueta)[0]+'": "","'+searchedSinValor[key].match(etiqueta)[1]+'":';
                        res.data = res.data.replace(searchedSinValor[key], newKey);
                    }

                    const sinValor2 = /["]\s?[:]\s?["][,]/g
                    let searchedSinValor2 = res.data.match(sinValor2);
                    for (const key in searchedSinValor2) { 
                        let newKey = '" : "",'
                        res.data = res.data.replace(searchedSinValor2[key], newKey);
                    }

                    const dobleLlave = /["]?\s?[{]\s?[{]/g;
                    res.data = res.data.replace(dobleLlave, '{');

                    const dobleComa = /,,{/g;
                    res.data = res.data.replace(dobleComa, ',');

                    const dobleDosPuntos = /::" "/g;
                    res.data = res.data.replace(dobleDosPuntos, ':""');

                    const comillasMal2 = /[,]\s?["]\s?[,]/g;
                    res.data = res.data.replace(comillasMal2, ',');

                    const llaveMal6 = /:"{"/g;
                    res.data = res.data.replace(llaveMal6, ':"",');

                    const llaveMal7 = /{,/g;
                    res.data = res.data.replace(llaveMal7, '{');

                    const llaveMal8 = /,},/g;
                    res.data = res.data.replace(llaveMal8, '},');

                    const nullMal = /nul:/g;
                    res.data = res.data.replace(nullMal, 'null:');

                    const valorMal = /:([0-9]|null):"\s?"/g;
                    const searchedValorMal = res.data.match(valorMal);
                    for (const key in searchedValorMal) {
                        const valorRegex = searchedValorMal[key].match(/:([0-9]|null)/g);
                        const newKey = `${valorRegex}`
                        res.data = res.data.replace(searchedValorMal[key], newKey);
                    }

                    const corcheteMal4 = /,\[/g
                    res.data = res.data.replace(corcheteMal4, ',"');

                    const faltaComilla = /["]\s?[:]\s?["]\s?[}]/g
                    res.data = res.data.replace(faltaComilla, '":""}');

                    const arrayMal = /":"{[0-9\s",:]+}/g;
                    const searchedArrayMal = res.data.match(arrayMal);
                    for (const key in searchedArrayMal) {
                        const newArray=[];
                        const numeros = res.data.match(/[0-9]+/g);
                        for (const key in numeros) {
                            newArray.push(numeros[key])
                        }
                        res.data = res.data.replace(searchedArrayMal[key], `":[${newArray.toString()}]`);
                    }
                    
                    const contenidoCombinadoEtiqueta = /[:][\s]?["][A-Za-zÁÉÍÓÚáéíóúüÜñÑ.,-_\s]+[,][A-Za-zÁÉÍÓÚáéíóúüÜñÑ\-_\s]+["][:]/g;
                    const searchedContenidoCombinadoEtiqueta = res.data.match(contenidoCombinadoEtiqueta);
                    for (const key in searchedContenidoCombinadoEtiqueta) {
                        const contenidoRegex = /[:][\s]?["][A-Za-zÁÉÍÓÚáéíóúüÜñÑ.,-_\s]+[,]/;
                        const etiquetaRegex = /[A-Za-zÁÉÍÓÚáéíóúüÜñÑ\-_\s]+["][:]/;
                        const contenido = searchedContenidoCombinadoEtiqueta[key].match(contenidoRegex)[0];
                        const etiqueta = searchedContenidoCombinadoEtiqueta[key].match(etiquetaRegex)[0];
                        res.data = res.data.replace(searchedContenidoCombinadoEtiqueta[key], `${contenido.substring(0, contenido.length - 1)}","${etiqueta}`);
                    }

                    const noNameAtributoRegex = /["Este atributo no tiene la anotacion][\s@a-zA-Z."]+:\s?"\[/g;
                    const noNameAtributoValor = res.data.match(noNameAtributoRegex);
                    for (const key in noNameAtributoValor) {
                        res.data = res.data.replace(noNameAtributoValor[key], '');
                    }

                    if( res.data.charCodeAt(1) !== 34 ){ 
                        res.data = res.data.replace(/{/g,'{"');
                        res.data = res.data.substr(0,2)+res.data.substr(2,res.data.length-1).replace('{ ','":{');
                    }
                    res.data = res.data.replace(/"{ }/gi, '""');
                    res.data = res.data.replace(/'/gi, '');
                    //res.data = res.data.replace(/, "plantillaHtml":"/gi, '');
                }
                isArray.value = true;                
        } 
            
        let data = {};
        try {
            data = isArray.value ? JSON.parse(res.data) : null;
        } catch (error) {
            try {
                data = isArray.value ? JSON.parse(res.data.substring(0,res.data.length-1)) : null;
            } catch (error) {
                try {
                    data = isArray.value ? JSON.parse(res.data+'}') : null;
                } catch (error) {
                    try {
                        data = isArray.value ? JSON.parse(res.data+'}}') : null;
                    } catch (error) {
                        try {
                            data = isArray.value ? JSON.parse(res.data+'}}}') : null;
                        } catch (error) {
                            try {
                                data = isArray.value ? JSON.parse(res.data+'}}}}}}') : null;
                            } catch (error) {
                                try {
                                    data = isArray.value ? JSON.parse(res.data+'}}}}}}}') : null;
                                } catch (error) {
                                    try {
                                        data = isArray.value ? JSON.parse(res.data+'}}}}}}}}') : null;
                                    } catch (error) {
                                        try {
                                            data = isArray.value ? JSON.parse(res.data+'}}}}}}}}}') : null;
                                        } catch (error) {
                                            try {
                                                data = isArray.value ? JSON.parse(res.data+'}}}}}}}}}}') : null;
                                            } catch (error) {
                                                try {
                                                    data = isArray.value ? JSON.parse(res.data+'}}}}}}}}}}}') : null;
                                                } catch (error) {
                                                    try {
                                                        data = isArray.value ? JSON.parse(res.data+'}}}}}}}}}}}}}}}}}}') : null;
                                                    } catch (error) {
                                                        try {
                                                            data = isArray.value ? JSON.parse(res.data+'}}}}}}}}}}}}}}}}}}}') : null;
                                                        } catch (error) {
                                                            console.log(res.data)
                                                            console.log(error)
                                                            setError( <AlertForm message="Por el momento no se puede mostrar información del módulo." /> );
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
            
        try {
            const dataFlatten = isArray.value ? flatten(data) : null;
            const result = Object.keys(isArray.value ? dataFlatten : res.data ); 
            const dataResult = {};
            for (const key in result) { 
                if(diccionario[result[key].trim()]){  
                    const newKeyDiccionario = diccionario[result[key].trim()];
                    dataResult[ newKeyDiccionario ? newKeyDiccionario : result[ key ] ] = isArray.value ? dataFlatten[ result[ key ] ] : res[ result[ key ] ]; 
                } else {
                    console.log(result[key].trim(), isArray.value ? dataFlatten[result[key]] : res[result[key]])
                }
            } 
            setDetalleCRUD(dataResult); 
        } catch (error) {
            console.log(data)
            console.log(error)
        }
        //}
    // eslint-disable-next-line
    },[]); 
    return (
        <Fragment>
            <form id={ 'infoAuditoria' } >
                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        {
                            elementSelect.img
                            ? <Avatar alt="avatar" src={`data:image/jpeg;base64, ${elementSelect.img}`} style={{ width:'5rem', height:'5rem', boxSizing: 'content-box' }}/>
                            : <Avatar alt="avatar" src={profileIMG} style={{ width:'5rem', height:'5rem', boxSizing: 'content-box' }}/>
                        } 
                    </div>
                    <div className="col-md-9 col-sm-12"> 
                        <div><label><strong>Usuario: </strong>{ elementSelect.usuario }</label></div>
                        <div><label><strong>Nombre: </strong>{ elementSelect.nombre }</label></div>
                        <div><label><strong>Primer Apellido: </strong>{ elementSelect.apaterno }</label></div>
                        <div><label><strong>Segundo Apellido: </strong>{  elementSelect.amaterno }</label></div>
                        <div><label><strong>Módulo: </strong>{ elementSelect.moduleName}</label></div>
                        <div><label><strong>Operación: </strong>{ elementSelect.crudOperation }</label></div> 
                        <div><label><strong>Fecha: </strong>{`${elementSelect.fecha.split('T')[0].split('-').reverse().join('/')}` }</label></div> 
                        <div><label><strong>Hora: </strong>{`${elementSelect.fecha.split('T')[1]}` }</label></div> 
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-3">
                        <h6>DATOS:</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                         {
                             detalleCRUD &&
                             Object.keys(detalleCRUD).map( element => {
                                return <div key={element}>
                                            <label>
                                            <strong> { `${element}: `} </strong>
                                                {detalleCRUD[element]==='true' || detalleCRUD[element]==='true ' || detalleCRUD[element]===true
                                                ?   'Sí'
                                                :   detalleCRUD[element]==='false' || detalleCRUD[element]==='false ' || detalleCRUD[element]===false
                                                    ?   'No'
                                                    :   detalleCRUD[element]==='null' || detalleCRUD[element]==='null ' || detalleCRUD[element]===null 
                                                        ?   ''
                                                        :   regexFechaHora.test(detalleCRUD[element])
                                                            ?   longDateToInputDate(detalleCRUD[element]) 
                                                            :   /^[0-9]{13}$/.test(detalleCRUD[element])
                                                                ? ConvertDate(longDateToInput(parseInt(detalleCRUD[element]))) 
                                                                : detalleCRUD[element]
                                                }
                                            </label>
                                        </div>
                             })
                         }
                    </div>
                </div>
                { error }
            </form>
        </Fragment>
    );
};
export default FormInfoAuditoria;