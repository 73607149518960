import React, { useState, useEffect, createElement, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_org } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import OrganigramaModal from '../OrganigramaModal';
import ScrollContainer from 'react-indiana-drag-scroll';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AlertSyE from '../../../../../../../../Services/Alerts/AlertSyE';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import BubbleChartOutlinedIcon from '@material-ui/icons/BubbleChartOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Tooltip } from '@material-ui/core';
import '../Styles.css';

const OrganigramaAdscripcion = ({ id, organigrama, setOrganigrama, toDoOrg, setToDoOrg, permisos }) => {

    const [html, setHTML] = useState([]);
    const [openAlertSyEError, setOpenAlertSyEError] = useState(false);
    const [openAlertSyESuccess, setOpenAlertSyESuccess] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenEdit, setModalIsOpenEdit] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenPlaza, setModalIsOpenPlaza] = useState({ state: false, type: null, data: {} });
    
    let nuevoHtml = [];

    const enviar = data => {
        const itemshermanos = (organigrama.filter(hijo => hijo.areaidsup === data.id)).sort((a, b) => {
            return (a.areasecuencia - b.areasecuencia)
        });

        let orden = 1;
        if(itemshermanos.length > 0) {
            orden = (itemshermanos[itemshermanos.length - 1].areasecuencia) + 1;
        }

        const info = {
                        ciaid: id,
                        areacve: data.clave,
                        areanivel: data.nivel + 1,
                        areasecuencia: orden,
                        areanom: data.nombre,
                        areadescrip: data.descripcion,
                        areaidsup: data.id,
                        presupuestal: data.presupuestal
                    };

        axios.post(`${servicios_grupohit_org}/area/`, info, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                setOrganigrama([...organigrama, res.data.dataResponse]);
                setOpenAlertSyESuccess(true);
            } else {
                setOpenAlertSyEError(true);
            }
        })
        .catch((error) => {
            setOpenAlertSyEError(true);
        });
    };

    const actualizar = async item => {
        if(permisos.find(permiso => permiso.id === 2)) {
            const info = { 
                ciaid: id,
                areacve: item.areacve,
                areanivel: item.areanivel,
                areasecuencia: item.areasecuencia,
                areanom: item.areanom,
                areadescrip: item.areadescrip,
                areaidsup: item.areaidsup,
                presupuestal: item.presupuestal.id
            };
    
            const result = await axios.put(`${servicios_grupohit_org}/area/${item.id}`, info, headersAuth())
                                .then(response => {
                                    if(response.data.status === 200) {
                                        let itemOrg = organigrama.find(element => element.id === item.id );
                                        
                                        itemOrg.areacve = response.data.dataResponse.areacve;
                                        itemOrg.areanivel = response.data.dataResponse.areanivel;
                                        itemOrg.areasecuencia = response.data.dataResponse.areasecuencia;
                                        itemOrg.areanom = response.data.dataResponse.areanom;
                                        itemOrg.areadescrip = response.data.dataResponse.areadescrip;
                                        itemOrg.areaidsup = response.data.dataResponse.areaidsup;
                                        itemOrg.presupuestal = response.data.dataResponse.presupuestal;
                        
                                        setOpenAlertSyESuccess(true);
                                        return true;
                                    } else {
                                        setOpenAlertSyEError(true);
                                        return false;
                                    }
                                })
                                .catch((error) => {
                                    setOpenAlertSyEError(true);
                                    return false;
                                });
            return result;
        }
    };
    
    const agregar = (id, nivel) => {
        if(permisos.find(permiso => permiso.id === 1)) {
            setModalIsOpen({ state: true, type: 'agregar', data: { id, nivel } });
        }  
    };

    const editar = item => {
        if(permisos.find(permiso => permiso.id === 2)) {
            setModalIsOpenEdit({ state: true, type: 'editar', data: item });        
        }
    };

    const eliminar = id => {
        if(permisos.find(permiso => permiso.id === 3)) {
            const numNodosHijos = (organigrama.filter(item => item.areaidsup === id)).length;
            
            axios.delete(`${servicios_grupohit_org}/area/${id}`, headersAuth())
            .then(response => {
                if(response.data.status === 200) {
                    if(numNodosHijos === 0) {
                        const nuevoOrganigrama = organigrama.filter(item => item.id !== id);
                        setOrganigrama(nuevoOrganigrama);
                        setOpenAlertSyESuccess(true);
                    }
                } else {
                    setOpenAlertSyEError(true);
                }
            })
            .catch((error) => {
                setOpenAlertSyEError(true);
            });
        }
    };

    var idDrag = 0;

    const allowDrop = e => {
        e.preventDefault();
    };
      
    const drag = e => {
        if(permisos.find(permiso => permiso.id === 2)) {
            e.dataTransfer.setData("div", e.target.id);
            idDrag = parseInt(e.target.id, 10);
        }
    };

    const drop = async e => {
        if(permisos.find(permiso => permiso.id === 2)) {
            const idDrop = parseInt(e.target.id, 10);
            //se obtiene informacion del elemento que se mueve, para editar 
            let itemDrag = organigrama.find(item => item.id === idDrag);
            itemDrag.areaNivelAnt = itemDrag.areanivel;
            //se obtiene informacion del nuevo padre
            const datosNuevoPadre = organigrama.find(item => item.id === idDrop);

            const buscaRelaciones = (padre, nivel) => {
                if(itemDrag.areanivel < nivel) {
                    if(padre === itemDrag.id) {
                        idDrag = 0;
                        return false;
                    } else {
                        const datosSigItem = organigrama.find(item => item.id === padre); 
                        buscaRelaciones(datosSigItem.areaidsup, datosSigItem.areanivel);
                    }
                }
                return true;
            };

            if(idDrag > 0 & idDrop > 0 & idDrag !== idDrop) {
                var acceder = buscaRelaciones(datosNuevoPadre.areaidsup, datosNuevoPadre.areanivel);
            }

            //aquí se ejecutan los cambios 
            if(acceder & idDrag > 0 & idDrop > 0 & idDrag !== idDrop) {
                let nuevoOrganigrama = organigrama.filter(item => item.id !== idDrag);
                
                //nuevos valores de item que se cambiará de nodo padre
                const newHermanos = (organigrama.filter(item => item.areaidsup === datosNuevoPadre.id)).sort((a, b) => {
                    return (a.areasecuencia - b.areasecuencia)
                });

                // se asigna nuevo valor de orgen
                let areasecuencia 
                if(newHermanos.length > 0) {
                    areasecuencia = newHermanos[newHermanos.length - 1].areasecuencia + 1;
                } else {
                    areasecuencia = 1;
                }

                const nuevosValores = {
                    presupuestal: itemDrag.presupuestal,
                    areacve: itemDrag.areacve,
                    areanivel: datosNuevoPadre.areanivel + 1,
                    areasecuencia: areasecuencia,
                    areanom: itemDrag.areanom,
                    areadescrip: itemDrag.areadescrip,
                    areaidsup: idDrop,
                    id: itemDrag.id
                };

                const efectuaUpdate = await actualizar(nuevosValores);

                if(efectuaUpdate) {
                    //ajustar nivel de los hijos (-1);
                    const ajustarNivelHijos = async (padre) => {
                        //diferencia entre nodo y nuevo padre antes del cambio
                        var hijos = nuevoOrganigrama.filter(item => item.areaidsup === padre.id);
                        if(hijos.length > 0) {
                            for(let nh = 0; nh < hijos.length; nh++) {
                                let hijo = nuevoOrganigrama.find(item => item.id === hijos[nh].id);
                                hijo.areaNivelAnt = hijo.areanivel;
                                hijo.areanivel = padre.areanivel + hijo.areaNivelAnt - padre.areaNivelAnt;
                                const efectuaUpdate = await actualizar(hijo);
                                if(efectuaUpdate) {
                                    ajustarNivelHijos(hijo);
                                }
                            }
                            return;
                        }
                        return;
                    }//termina ajuste de nivel
                    ajustarNivelHijos(itemDrag);
                    setToDoOrg(false);
                    setOrganigrama([...nuevoOrganigrama, itemDrag]);
                }
            }
        }
    };

    const moverIzquierda = async (id, padre, orden) => {
        let item = organigrama.find(item => item.id === id);  
        const itemshermanos = (organigrama.filter(item => item.areaidsup === padre & item.areasecuencia < orden)).sort((a, b) => {
            return (a.areasecuencia - b.areasecuencia)
        });
        let itemAnterior = itemshermanos[itemshermanos.length - 1];
        const nuevoOrganigrama = organigrama.filter(item => item.id !== id & item.id !== itemAnterior.id );
        item.areasecuencia = itemAnterior.areasecuencia;
        const efectuaUpdate = await actualizar(item);
        if(efectuaUpdate) {
            itemAnterior.areasecuencia = itemAnterior.areasecuencia + 1;
            actualizar(itemAnterior);
            setOrganigrama([...nuevoOrganigrama, item, itemAnterior]);
            setToDoOrg(false);
        }
    };

    const moverDerecha = async (id, padre, orden) => {
        let item = organigrama.find(item => item.id === id);
        const itemshermanos = (organigrama.filter(item => item.areaidsup === padre & item.areasecuencia > orden)).sort((a, b) => {
            return (a.areasecuencia - b.areasecuencia);
        });
        let itemSiguiente = itemshermanos[0];
        const nuevoOrganigrama = organigrama.filter(item => item.id !== id & item.id !== itemSiguiente.id);

        item.areasecuencia = item.areasecuencia + 1;
        const efectuaUpdate = await actualizar(item);
        if(efectuaUpdate) {
            itemSiguiente.areasecuencia = item.areasecuencia;
            actualizar(itemSiguiente);
            setOrganigrama([...nuevoOrganigrama, item, itemSiguiente]);
            setToDoOrg(false);
        }
    };

    const moverArriba = async nodo => {
        const padreInfo = organigrama.find(item => item.id === nodo.areaidsup);
        let copiaNodo = nodo;
        
        if((copiaNodo.areanivel - padreInfo.areanivel) === 1) {
            const hijos = (organigrama.filter(hijo => hijo.areaidsup === padreInfo.areaidsup)).sort((a, b) => {
                return (a.areasecuencia - b.areasecuencia);
            });
            copiaNodo.areaidsup = padreInfo.areaidsup;
            copiaNodo.areasecuencia = hijos[hijos.length - 1].areasecuencia + 1;
        }
        
        copiaNodo.areanivel = copiaNodo.areanivel - 1;
        const efectuaUpdate = await actualizar(copiaNodo);
        if(efectuaUpdate) {
            //ajustar nivel de los hijos (-1);
            const ajustarNivelHijos = async (id) => {
                const hijos = organigrama.filter(item => item.areaidsup === id);
                
                if(hijos.length > 0){
                    for(let nh = 0; nh < hijos.length; nh++) {
                        hijos[nh].areanivel = hijos[nh].areanivel - 1;
                        const efectuaUpdate = await actualizar(hijos[nh]);
                        if(efectuaUpdate) {
                            ajustarNivelHijos(hijos[nh].id);
                        }
                    }
                }
            }//termina ajuste de nivel
            ajustarNivelHijos(nodo.id);
            setToDoOrg(false);
        }
    };

    const moverAbajo = async nodo => {
        //nuevo orden
        let copiaNodo = nodo;
        copiaNodo.areanivel = copiaNodo.areanivel + 1;
        const efectuaUpdate = await actualizar(copiaNodo)
        if(efectuaUpdate) {
            //ajustar nivel de los hijos (+1);
            const ajustarNivelHijos = async (id) => {
                const hijos = organigrama.filter(item => item.areaidsup === id);
                
                if(hijos.length > 0){
                    for(let nh = 0; nh < hijos.length; nh++) {
                        hijos[nh].areanivel = hijos[nh].areanivel + 1;
                        const efectuaUpdate = await actualizar(hijos[nh]);
                        if(efectuaUpdate) {
                            ajustarNivelHijos(hijos[nh].id);
                        }
                    }
                }
            }//termina ajuste de nivel
            ajustarNivelHijos(nodo.id);
            setToDoOrg(false);
        }
    };

    const generaOrganigrama = padre => {
        const hijos = (organigrama.filter(hijo => hijo.areaidsup === padre.id)).sort((a, b) => {
            return (a.areasecuencia - b.areasecuencia);
        });

        if(hijos.length > 0) {
            let padreshijos = [];
            for(let x = 0; x < hijos.length; x++) {
                const respuesta = generaOrganigrama(hijos[x]);
                const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                    ?   <Tooltip title="Agregar nuevo centro de adscripción" key={`btnAgregar${hijos[x].id}`}><div className="btlctrl btnAgregar" onClick={() => agregar(hijos[x].id, hijos[x].areanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                let btnEliminar = (permisos.find(permiso => permiso.id === 3))
                                    ?   <Tooltip title="Eliminar centro de adscripción" key={`btnEliminar${hijos[x].id}`}><div className="btlctrl btnEliminar" onClick={() => eliminar(hijos[x].id, hijos[x].areaidsup, hijos[x].areasecuencia)}><DeleteForeverOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;

                if(respuesta) btnEliminar = '';
                const btnInfo = (permisos.find(permiso => permiso.id === 4))
                                ?   <Tooltip title="Mostrar organigrama" key={`btnInfo${hijos[x].id}`}><div className="btlctrl btnInfo" onClick={() => setModalIsOpenPlaza({ state: true, type: 'organigrama', data: { id: hijos[x].id, nombre: hijos[x].areanom, idCia: hijos[x].ciaid.id, info: hijos[x].presupuestal } })}><BubbleChartOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip> 
                                :   null;
                const btnArriba = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Subir nivel" key={`btnArriba${hijos[x].id}`}><div className="btlctrl btnArriba" onClick={() => moverArriba(hijos[x])}><ArrowUpwardIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                const btnAbajo = (permisos.find(permiso => permiso.id === 2))
                                ?   <Tooltip title="Bajar nivel" key={`btnAbajo${hijos[x].id}`}><div className="btlctrl btnAbajo" onClick={() => moverAbajo(hijos[x])}><ArrowDownwardIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                :   null;
                const btnIzquierda = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Mover a la izquierda" key={`btnIzquierda${hijos[x].id}`}><div className="btlctrl btnIzquierda" onClick={() => moverIzquierda(hijos[x].id, hijos[x].areaidsup, hijos[x].areasecuencia)}><ArrowBackOutlinedIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                    :   null;
                const btnDerecha = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Mover a la derecha" key={`btnDerecha${hijos[x].id}`}><div className="btlctrl btnDerecha" onClick={() => moverDerecha(hijos[x].id, hijos[x].areaidsup, hijos[x].areasecuencia)}><ArrowForwardOutlinedIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                    :   null;

                const label = createElement('label', {id: hijos[x].id, key: `label${hijos[x].id}`, className: "clave"}, [<strong key={`str${hijos[x].id}`}>Clave: </strong>, hijos[x].areacve]);
                const label2 = createElement('label', {id: hijos[x].id, key: `label2${hijos[x].id}`, className: "proyecto"}, [<strong key={`str2${hijos[x].id}`}>Proyecto: </strong>, (hijos[x].presupuestal) ? hijos[x].presupuestal.cpresupuestalnom : '']);
                const h61 = createElement('h6', {id: hijos[x].id, key: `h61${hijos[x].id}`, className: "plazas-ocupadas"}, '');
                const h62 = createElement('h6', {id: hijos[x].id, key: `h62${hijos[x].id}`, className: "plazas-libres"}, '');

                const div1 = createElement('div', {key: `div1${hijos[x].id}`, className: "info-wrap"}, [label, <br key={`br${hijos[x].id}`}/>, label2, h61, h62]);
                let divBtn = null;

                /*  Diferancia de niveles entre padre e hijo    */
                const dif = hijos[x].areanivel - padre.areanivel; 
                /*  Valores de Secuencia de los primer y ultimo nodo */
                const ultimoNodo = hijos[hijos.length - 1].areasecuencia;
                const primerNodo = hijos[0].areasecuencia;

                if(hijos[x].areanivel === 2 & dif === 1 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo]);

                } else if(hijos[x].areanivel === 3 & dif === 2 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba]);

                } else if(hijos[x].areanivel === 2 & dif === 1 & hijos.length > 1 & hijos[x].areasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha]);

                } else if(hijos[x].areanivel === 2 & dif === 1 & hijos.length > 1 & hijos[x].areasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda]);

                } else if(hijos[x].areanivel === 3 & dif === 2 & hijos.length > 1 & hijos[x].areasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha]);

                } else if(hijos[x].areanivel === 3 & dif === 2 & hijos.length > 1 & hijos[x].areasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda]);

                } else if(hijos[x].areanivel === 2 & dif === 1 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda, btnDerecha]);

                } else if(hijos[x].areanivel === 3 & dif === 2 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda, btnDerecha]);

                } else if(hijos[x].areanivel >= 3 & dif === 1 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo]);

                } else if(hijos[x].areanivel >= 3 & dif === 2 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba]);

                } else if(hijos[x].areanivel >= 3 & dif === 2 & hijos.length > 1 & hijos[x].areasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha]);

                } else if(hijos[x].areanivel >= 3 & dif === 2 & hijos.length > 1 & hijos[x].areasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda]);

                } else if(hijos[x].areanivel >= 3 & dif === 1 & hijos.length > 1 & hijos[x].areasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha]);

                } else if(hijos[x].areanivel >= 3 & dif === 1 & hijos.length > 1 & hijos[x].areasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda]);

                } else if(hijos[x].areanivel >= 3 & dif === 1 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha,  btnIzquierda]);

                } else if(hijos[x].areanivel >= 3 & dif === 2 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha,  btnIzquierda]);

                }

                
                const div2 = createElement('div', {id: hijos[x].id, key: `div2${hijos[x].id}`, className: "areas-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, div1, divBtn);
                const div3 = createElement('div', {key: `div3${hijos[x].id}`, className: "info-block"}, div2);
                let elemento1 = createElement('label', {id: hijos[x].id, key: `label${hijos[x].id}`, className: "name",  onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, hijos[x].areanom);
                let elemento2 = <Tooltip title="Editar" key={`btnEditar${hijos[x].id}`}><div className="btlctrl btnEditar" onClick={() => editar(hijos[x])}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;
                const div4 = createElement('div', {key: `div4${hijos[x].id}`, className: "department-info"}, [elemento1, elemento2]);
                const div5 = createElement('div', {id: hijos[x].id, key: `div5${hijos[x].id}`, className: "area-card", draggable: (permisos.find(permiso => permiso.id === 2)) ? true : false, onDragStart: (e) => drag(e)}, [div4, div3]);
                let div6 = null;

                if(respuesta) {
                    if(respuesta.length === 1) {
                        div6 = createElement('div', {key: `div6${hijos[x].id}`, className: `node${dif} sub-list`, draggable: (permisos.find(permiso => permiso.id === 2)) ? true : false, onDragStart: (e) => drag(e)}, div5);
                        const ul = createElement('ul', {key: `ul${hijos[x].id}`, className: "sub-list2"}, respuesta);
                        const li = createElement('li', {key: `li${hijos[x].id}`}, [div6, ul]);
                        padreshijos.push(li);
              
                    } else {
                        div6 = createElement('div', {key: `div6${hijos[x].id}`, className: `node${dif} sub-list`}, div5);
                        const ul = createElement('ul', {key: `ul${hijos[x].id}`, className: "sub-list"}, respuesta);
                        const li = createElement('li', {key: `li${hijos[x].id}`}, [div6, ul]);
                        padreshijos.push(li);
                    }

                } else {
                    div6 = createElement('div', {key: `div6${hijos[x].id}`, className: `node${dif} no-child sub-list`}, div5);
                    const li = createElement('li', {key: `li${hijos[x].id}`}, div6);
                    padreshijos.push(li);
                }
            }

            if(padre.areaidsup === 0) {
                const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                    ?   <Tooltip title="Agregar nuevo centro de adscripción" key={`btnAgregar${padre.id}`}><div className="btlctrl btnAgregar" onClick={() => agregar(padre.id, padre.areanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                const btnInfo = (permisos.find(permiso => permiso.id === 4))
                                ?   <Tooltip title="Mostrar organigrama" key={`btnInfo${padre.id}`}><div className="btlctrl btnInfo" onClick={() => setModalIsOpenPlaza({ state: true, type: 'organigrama', data: { id: padre.id, nombre: padre.areanom, idCia: padre.ciaid.id, info: padre.presupuestal } })}><BubbleChartOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;


                const label = createElement('label', {key: `label${padre.id}`, className: "clave"}, [<strong key={`str${padre.id}`}>Clave: </strong>, padre.areacve]);
                const label2 = createElement('label', {id: padre.id, key: `label2${padre.id}`, className: "proyecto"}, [<strong key={`str2${padre.id}`}>Proyecto: </strong>, (padre.presupuestal) ? padre.presupuestal.cpresupuestalnom : '']);
                const h61 = createElement('h6', {key: `h61${padre.id}`, className: "plazas-ocupadas"}, '');
                const h62 = createElement('h6', {key: `h62${padre.id}`, className: "plazas-libres"}, '');

                
                const div1 = createElement('div', {key: `div1${padre.id}`, className: "info-wrap"}, [label, <br key={`br${padre.id}`}/>, label2, h61, h62]);
                const divBtn = createElement('div', {key: `divBtn${padre.id}`, className: "btnsPosition"}, [btnAgregar, btnInfo]);
                const div2 = createElement('div', {id: padre.id, key: `div2${padre.id}`, className: "areas-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, div1, divBtn);
                const div3 = createElement('div', {key: `div3${padre.id}`, className: "info-block"}, div2);

                let elemento1 = createElement('label', {id: padre.id, key: `label${padre.id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, padre.areanom);
                let elemento2 = <Tooltip title="Editar" key={`btnEditar${padre.id}`}><div className="btlctrl btnEditar" onClick={() => editar(padre)}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;

                const div4 = createElement('div', {key: `div4${padre.id}`, className: "master-info"}, [elemento1, elemento2]);
                const div5 = createElement('div', {key: `div5${padre.id}`, className: "area-card"}, [div4, div3]);
                let div6 = null;

                if(padreshijos.length === 1) {
                    div6 = createElement('div', {key: `div6${padre.id}`, className: "node1"}, div5);
                    const ul2 = createElement('ul', {key: `ul2${padre.id}`}, padreshijos);
                    const div = createElement('div', {key: `ul2${padre.id}`, className: "sub-list"}, ul2);
                    const li = createElement('li', {key: `li${padre.id}`}, [div6, div]);
                    return (li);
          
                } else {
                    div6 = createElement('div', {key: `div6${padre.id}`, className: "node1"}, div5);
                    const ul2 = createElement('ul', {key: `ul2${padre.id}`, className: "sub-list"}, padreshijos);
                    const li = createElement('li', {key: `li${padre.id}`}, [div6, ul2]);
                    return (li);
                }
                    
            } else {
                return (padreshijos);
            }
        }

        if(hijos.length === 0 & padre.areanivel === 1) {
            const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                ?   <Tooltip title="Agregar nuevo centro de adscripción" key={`btnAgregar${padre.id}`}><div className="btlctrl btnAgregar" onClick={() => agregar(padre.id, padre.areanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
            const btnEliminar = (permisos.find(permiso => permiso.id === 3))
                                ?   <Tooltip title="Eliminar centro de adscripción" key={`btnEliminar${padre.id}`}><div className="btlctrl btnEliminar" onClick={() => eliminar(padre.id, padre.areaidsup, padre.areasecuencia)}><DeleteForeverOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
            const btnInfo = (permisos.find(permiso => permiso.id === 4))
                            ?   <Tooltip title="Mostrar organigrama" key={`btnInfo${padre.id}`}><div id="btnInfo" className="btlctrl btnInfo" onClick={() => setModalIsOpenPlaza({ state: true, type: 'organigrama', data: { id: padre.id, nombre: padre.areanom, idCia: padre.ciaid.id, info: padre.presupuestal } })}><BubbleChartOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                            :   null;

            const label = createElement('label', {key: `label${padre.id}`, className: "clave"}, [<strong key={`str${padre.id}`}>Clave: </strong>, padre.areacve]);
            const label2 = createElement('label', {id: padre.id, key: `label2${padre.id}`, className: "proyecto"}, [<strong key={`str${padre.id}`}>Proyecto: </strong>, (padre.presupuestal) ? padre.presupuestal.cpresupuestalnom : '']);
            const h61 = createElement('h6', {key: `h61${padre.id}`, className: "plazas-ocupadas"}, '');
            const h62 = createElement('h6', {key: `h62${padre.id}`, className: "plazas-libres"}, '');


            const div1 = createElement('div', {key: `div1${padre.id}`, className: "info-wrap"}, [label, <br key={`br${padre.id}`}/>, label2, h61, h62]);
            const divBtn = createElement('div', {key: `divBtn${padre.id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo]);
            const div2 = createElement('div', {id: padre.id, key: `div2${padre.id}`, className: "areas-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, div1, divBtn);
            const div3 = createElement('div', {key: `div3${padre.id}`, className: "info-block"}, div2);
            
            let elemento1 = createElement('label', {id: padre.id, key: `label${padre.id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, padre.areanom);
            let elemento2 = <Tooltip title="Editar" key={`btnEditar${padre.id}`}><div className="btlctrl btnEditar" onClick={() => editar(padre)}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;

            const div4 = createElement('div', {key: `div4${padre.id}`, className: "master-info"}, [elemento1, elemento2]);
            const div5 = createElement('div', {key: `div5${padre.id}`, className: "area-card"}, [div4, div3]);
            const div6 = createElement('div', {key: `div6${padre.id}`, className: "node1 no-child"}, div5);
            
            return (div6);
        }
    };

    useEffect(() => {
        setToDoOrg(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organigrama]);

    useEffect(() => {
        if(!toDoOrg & organigrama !== null) {
            if(organigrama) {
                const padres = organigrama.filter(Obj => Obj.areaidsup === 0);
                if(padres.length > 0) {
                    for(let nf = 0; nf < padres.length; nf++) {
                        nuevoHtml.push(<Fragment key={`rfr${nf}`}><br/><ScrollContainer className="scroll-container orgchart" hideScrollbars={false} ignoreElements=".area-card" key={`divoc${nf}`}>{createElement('ul', {key:`ulpr${nf}`, className:"prime-list"}, generaOrganigrama(padres[nf]))}</ScrollContainer><br/></Fragment>);
                        setHTML(nuevoHtml);
                    }
                }

            } else {
                setHTML([]);
            }
            
            setToDoOrg(true);
        }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDoOrg]);

    let contenido = null; 
    (!toDoOrg)
    ?   contenido = <SpinnerOval/>
    :   contenido = (html.length > 0)
                    ?   <Fragment>
                            <div style={{ height: '55vh' }}>{ createElement('div', { key: 'div1', className: 'contenedorOrganigrama' }, html) }</div>
                            <OrganigramaModal minWidth="50%" modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} enviar={enviar} id={id} parentNode="orgPlazas"/>
                            <OrganigramaModal minWidth="50%" modalIsOpen={modalIsOpenEdit} setModalIsOpen={setModalIsOpenEdit} actualizar={actualizar} id={id} parentNode="orgPlazas"/>
                            <OrganigramaModal minWidth="95%" modalIsOpen={modalIsOpenPlaza} setModalIsOpen={setModalIsOpenPlaza} permisos={permisos} parentNode="orgPlazas"/>
                            <AlertSyE show={openAlertSyESuccess} setOpenAlertSyE={setOpenAlertSyESuccess} title="Centro de Adscripción" text="La tarea se realizó con éxito" textbtnconfirm="Aceptar" type="success" action={() => { setToDoOrg(false); setModalIsOpen({ state: false, type: null, data: {} }); setModalIsOpenEdit({ state: false, type: null, data: {} });}}/>
                            <AlertSyE show={openAlertSyEError} setOpenAlertSyE={setOpenAlertSyEError} title="Centro de Adscripción" text="La tarea no se pudo realizar" textbtnconfirm="Aceptar" type="error" />
                        </Fragment>
                    :   <SpinnerOval/>;
    return (
        <div id="moduloorgPlazas">
            { contenido }
        </div>
    );
}

export default OrganigramaAdscripcion;
