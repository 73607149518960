import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, compararFechas, hoyMilisegundos } from '../../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import AlertForm from '../../../../../../../Services/AlertForm';

import MultiSelect from "react-multi-select-component";
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { ArrayJsonSubordinadosService, obtenerUsuariosRestricciones } from './HelperRestricciones';

import Tooltip from '@material-ui/core/Tooltip';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const RestringirUsuarios = ({ error, enviarRestriccion, moduloID, usuariosDepartamento, restriccionesDepartamento, restriccionesSinDepartamento }) => {
    const classes = useStyles();
    const [ userSelected, setUserSelected ] = useState( restriccionesDepartamento.length > 0 ? obtenerUsuariosRestricciones( usuariosDepartamento, restriccionesDepartamento ) : []);
    const elementSelect = restriccionesDepartamento.length > 0 ? { fecini: restriccionesDepartamento[0].fecini, fecfin: restriccionesDepartamento[0].fecfin } : null;
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ errorDate, setErrorDate ] = useState( null );
    const feciniRef = createRef('');
    const fecfinRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        validarRestriccion();
    };

    const actualizar = () => {
        validarRestriccion();
    };

    const validarRestriccion = () => {
        if( validarSubmit( 'EnviarRestriccion' ) ){
            const fecini = inputDateToLong( feciniRef.current.value );
            const fecfin = inputDateToLong( fecfinRef.current.value );
            if( fecfin >= hoyMilisegundos() ){
                setErrorDate( null );
                if( compararFechas(fecini, fecfin) ){
                    setErrorDate( null );
                    if( userSelected.length > 0 ){
                        enviarRestriccion( restriccionesSinDepartamento.concat(ArrayJsonSubordinadosService(userSelected, moduloID, fecini, fecfin)));
                    }else{
                        setErrorDate( <AlertForm message="Selecciona al menos un usuario" /> );
                    }
                }else{
                    setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                }
            }else{
                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual que la Fecha Actual" /> );
            }
        }
    }

    const liberarRestriccion = () => {
        enviarRestriccion( restriccionesSinDepartamento );
    }

    return (
        <div className="mt-2 formMultiSelect">
            <MultiSelect
                options={usuariosDepartamento}
                value={userSelected}
                onChange={setUserSelected}
                overrideStrings={{
                    "selectSomeItems": "Seleccionar",
                    "allItemsAreSelected": "Todos los elementos han sido seleccionados",
                    "selectAll": "Seleccionar Todos",
                    "search": "Buscar",
                    "clearSearch": "Limpiar busqueda"
                }}
                ItemRenderer={data => {
                    if( data.option.value !== '' ){
                        return(
                            <div className="accesosContenido centrarR">
                                <Checkbox
                                    onChange={data.onClick}
                                    checked={data.checked}
                                    disabled={data.disabled}
                                />
                                <Avatar src={`data:image/jpeg;base64, ${data.option.foto}`} className={classes.small}/>
                                <span>{data.option.label}</span>
                            </div>
                        )
                    }else{
                        return(
                            <div className="accesosContenido centrarR">
                                <Checkbox
                                    onChange={data.onClick}
                                    checked={data.checked}
                                    disabled={data.disabled}
                                />
                                <span>{data.option.label}</span>
                            </div>
                        )
                    }
                }}
            />

            <form id="EnviarRestriccion" onSubmit={ elementSelect ? mostrarAlert : enviar } className="mt-1">
                <div className="accesosContenido">
                    <div className="restriccionesIzquierdo">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <InputDateValidation title="Fecha Inicial" refValue={feciniRef} required
                                    defaultValue={ elementSelect ? longDateToInput(elementSelect.fecini) : '' } />
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <InputDateValidation title="Fecha Final" refValue={fecfinRef} required
                                    defaultValue={ elementSelect ? longDateToInput(elementSelect.fecfin) : '' } />
                            </div>
                        </div>
                    </div>

                    { !elementSelect ?
                        <div className="restriccionesDerecho1">
                            <Tooltip title="Agregar Reestricción" placement="top">
                                <Button variant="contained" className="btn-color btn-rest" type="submit"> <Lock/> </Button>
                            </Tooltip>
                        </div>
                        :
                        <div className="restriccionesDerecho2">
                            <Tooltip title="Actualizar Reestricción" placement="top">
                                <Button variant="contained" className="btn-color btn-rest" type="submit"> <Lock/> </Button>
                            </Tooltip>
                            <Tooltip title="Liberar Reestricción" placement="top">
                                <Button variant="contained" className="btn-third btn-rest" onClick={ () => setOpenDelete(true) }> <LockOpen/> </Button>
                            </Tooltip>
                        </div>
                    }
                </div>

                {error}
                {errorDate}
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={"La restricción será actualizada"} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
            <AlertWarning show={openDelete} SetopenALert={setOpenDelete} text={"La restricción será eliminada"} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={liberarRestriccion} />
        </div>
    );
};

export default RestringirUsuarios;