/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import TooltipComponent from '../Tooltip/TooltipComponent'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

export default function AutocompleteComponent({ id, title, options, optionListView, optionKey, action, disabled, size, placeholder, required, tooltipText, error, defaultValue, value, clearDiasabled }) {

  const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if(clearDiasabled) {
      if(disabled === true) {
        setInputValue('');
      }
    }
  }, [disabled, clearDiasabled]);

  return (
    <Autocomplete
      id={id}
      style={{ margin: 0, width: (size) ? size : '100%' }}
      options={options}
      onChange={(e,v) => action(v)}
      noOptionsText="No existen coincidencias"
      size="small"
      clearText="Borrar"
      disabled={disabled ? disabled : false}
      defaultValue={defaultValue}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={option => (option[`${optionListView}`]) ?  option[optionKey] ? `${option[optionKey]} / ${option[optionListView]}` : option[`${optionListView}`] : ''}
      renderInput={params => (
        <div className="form-group">
          <label style={{marginBottom: '0.5rem'}}>
            { title }{ (required) ? <strong className="requerido">* </strong> : null }
            { tooltipText &&
              <TooltipComponent tooltipText={tooltipText}>
                  <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
              </TooltipComponent>
            }
          </label>
          <TextField {...params} variant="outlined" margin="normal" placeholder={placeholder} fullWidth error={error} helperText={error ? 'Complete el campo requerido' : ''}/>      
        </div>
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option[`${optionListView}`], inputValue);
        const parts = parse(option[`${optionListView}`], matches);

        return (
          <Fragment>
            { option[optionKey] ?
              <span> {`${option[optionKey]} / ${option[optionListView]}`} </span>
              :
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            }
          </Fragment>
        );
      }}
    />
  );
}