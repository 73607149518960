import React, { Fragment, useEffect, useState } from "react";
import SpinnerOval from "../../../../../../../../Services/Spinner/SpinnerOval";
import DatosEmpleado from './DatosEmpleado';

const Transferencia = ({ empleado, cPresupuestal, departamento }) => {

    const [datosEmpleado, setDatosEmpleado] = useState(null);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        if(departamento === null) {
            guardaEmpleado(null);
        } else {

            let empleadoJson = empleado;
            empleadoJson.ciaRazonSocial = cPresupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaRazonSocial;
            empleadoJson.udejecutoraNom = cPresupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoranom;
            empleadoJson.programaNom = cPresupuestal.obraAccion.proyecto.subPrograma.programa.programanom;
            empleadoJson.proyectoNom = cPresupuestal.obraAccion.proyecto.proyectonom;
            empleadoJson.cpresupuestalClave = cPresupuestal.cpresupuestalclave;
            empleadoJson.cpresupuestalNom = cPresupuestal.cpresupuestalnom;
            empleadoJson.areaNom = departamento.areanom;
            guardaEmpleado(empleadoJson);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departamento]);

    const guardaEmpleado = async (infoEmp) => {
        if(!infoEmp) {
            setDatosEmpleado(infoEmp);
            return;
        }
        setLoading(true);
        setTimeout(() => {
            setDatosEmpleado(infoEmp);
        }, 200);
        setTimeout(() => {
            setLoading(false);
        }, 400);
    };

    return (
        <Fragment>
            <h6>Nueva Información del Empleado</h6>
            {loading
            ?   <SpinnerOval/>
            :   <DatosEmpleado empleado={datosEmpleado}/>
            }
        </Fragment>
    );
}
 
export default Transferencia;