import React, { useState, Fragment, createRef, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import PasswordValidation from '../../../../../../../Services/Validation/PasswordValidation';
import SaveIcon from '@material-ui/icons/Save';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';

const FormCuentaPortal = ({ setError, error, elementSelect, asignarCorreo, actualizarCorreo, actualizarContrasena, permisos, isLoading }) => {

    const [valueRadio, setValueRadio] = useState('');
    const [alertEditaCorreo, setAlertEditaCorreo] = useState(false);
    const [alertEditaContrasena, setAlertEditaContrasena] = useState(false);
    const [correo, setCorreo] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const correoPortalRef = createRef('');
    const passwordPortalRef = createRef('');

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupohit_rh}/empleado/usuario/obtener/info/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setCorreo(res.data.dataResponse.email);
                        break;
                    case 404:
                        setCorreo('');
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRadioChange = e => {
        setValueRadio(e.target.value);
        setError(null);
    };
    
    const enviaNuevoCorreo = e => {
        e.preventDefault();
        if(validarSubmit("FormCuentaPortal")) {
            const info = {
                empId: elementSelect.id,
                email: correoPortalRef.current.value
            };
            asignarCorreo(info);
        }
    };

    const muestraAlertEditaCorreo = e => {
        e.preventDefault();
        if(validarSubmit("FormCuentaPortal")) {
            setAlertEditaCorreo(true);
        }
    };

    const enviaCorreoActualizado = () => {
        const info = {
            empId: elementSelect.id,
            email: correoPortalRef.current.value
        };
        actualizarCorreo(info);
    };

    const muestraAlertEditaContrasena = e => {
        e.preventDefault();
        if(validarSubmit("FormCuentaPortal")) {
            setAlertEditaContrasena(true);
        }
    };

    const enviaContrasenaActualizada = () => {
        const info = {
            empId: elementSelect.id,
            password: passwordPortalRef.current.value
        };
        actualizarContrasena(info);
    };

    const InputEmail =  <div className="row">
                            <div className="col-lg-11 col-md-10 col-sm-10">
                                <InputValidation title="Correo Electrónico" type="e-mail" placeholder="Escriba el correo electrónico del empleado"
                                    refValue={correoPortalRef} defaultValue={(valueRadio === '') ? correo : ''}
                                    tooltipText="Máximo 91 letras, números, puntos y arroba, en formato de correo electrónico" tooltipName="tooltipCorreoPortal"
                                    maxLength={91}/>
                            </div>
                            <div className="col-lg-1 col-md-2 col-sm-2 align-self-top">
                                {isLoading
                                ?   <SpinnerOval/>
                                :   <button className="btn-autocompleteInput" type="submit"><SaveIcon/></button>
                                }
                            </div>
                        </div>;

    const InputPassword =   <div className="row">
                                <div className="col-lg-11 col-md-10 col-sm-10">
                                    <PasswordValidation title="Nueva Contraseña" type="password" placeholder="Escriba la nueva contraseña"
                                        refValue={passwordPortalRef} defaultValue=""
                                        tooltipText={'De 8 a 16 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial incluyendo !@#$&*%/()=?¡.,|°<>;+_'}
                                        maxLength={16}/>
                                </div>
                                <div className="col-lg-1 col-md-2 col-sm-2 align-self-top">
                                    {isLoading
                                    ?   <SpinnerOval/>
                                    :   <button className="btn-autocompleteInput" type="submit"><SaveIcon/></button>
                                    }
                                </div>
                            </div>;

    return (
        <Fragment>
            {
                (correo === null)
                ?   <SpinnerOval/>
                :   <Fragment>
                        <div className="formRelLab">
                            <div><strong>Nombre: </strong>{ elementSelect.empleadoNom }</div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <div><strong>Primer Apellido: </strong>{ elementSelect.empleadoPat }</div>
                            </div>
                            <div className="form-right">
                                <div><strong>Segundo Apellido: </strong>{ elementSelect.empleadoMat }</div>
                            </div>
                        </div>
                        {
                            (correo !== '') &&
                            <div className="formRelLab">
                                <div><strong>Correo Electrónico: </strong>{ correo }</div>
                            </div>
                        }
                        <form id="FormCuentaPortal" onSubmit={(valueRadio === '') ? enviaNuevoCorreo : (valueRadio === 'email') ? muestraAlertEditaCorreo : muestraAlertEditaContrasena }>
                            {
                                (correo === '')
                                ?   (permisos.find(permiso => permiso.id === 1)) && InputEmail
                                :   (permisos.find(permiso => permiso.id === 2)) &&
                                    <Fragment>
                                        <RadioGroup row aria-label="position" name="position" value={valueRadio} onChange={handleRadioChange}>
                                            <FormControlLabel value="email" control={<Radio color="default" />} label="Cambiar Correo Electrónico" />
                                            <FormControlLabel value="password" control={<Radio color="default" />} label="Cambiar o Asignar Contraseña" />
                                        </RadioGroup>
                                        { (valueRadio === 'email') && InputEmail }
                                        { (valueRadio === 'password') && InputPassword }
                                    </Fragment>
                            }
                            { error }
                        </form>
                        <AlertWarning show={alertEditaCorreo} SetopenALert={setAlertEditaCorreo} text="El correo será cambiado" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaCorreoActualizado}/>
                        <AlertWarning show={alertEditaContrasena} SetopenALert={setAlertEditaContrasena} text="La contraseña será cambiada" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaContrasenaActualizada}/>
                    </Fragment>
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
export default FormCuentaPortal;