import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './style.css';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

/* -----------------------------------------------------------------------------------------------------  */
import { quitarNavegador, cambiarActivo } from '../../../../../Redux/actions/NavActions';
import { borrarEmpleadosInactivos } from '../../../../../Redux/actions/EmpleadosActions';
/* -----------------------------------------------------------------------------------------------------  */

function TabsContainer({ navegadoresStore, quitarNavegador, componenteActivoID, cambiarActivo, empleadosInactivos, borrarEmpleadosInactivos, opacity }){
    
    useEffect(() => {
        const moduloReingresos = navegadoresStore.find(tab => tab.id === 32);
        if(!moduloReingresos) {
            if(empleadosInactivos !== null) borrarEmpleadosInactivos();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navegadoresStore]);                                         

    return(
            <div className="row" style={{ position: "relative", zIndex: "1040" }} >
                <div className="col-md-12">
                    <div className="tabbable-panel" style={ opacity ? {opacity: 0} : {} }>
                        <div className="tabbable-line">
                            <ul className="nav nav-tabs ">

                                <li style={ {paddingBottom: 12} } className={ componenteActivoID === 1 ? 'active' : '' }>
                                    <span onClick={() =>  cambiarActivo( 1, componenteActivoID )} >
                                        Tablero 
                                    </span>
                                </li>

                                { Object.keys(navegadoresStore).map(key => {
                                    return(
                                        <li key={key} className={ componenteActivoID === navegadoresStore[key].id ? 'active' : '' }>
                                            <span onClick={() =>  cambiarActivo( navegadoresStore[key].id, componenteActivoID )}>
                                                {navegadoresStore[key].nombre}
                                            </span>
                                            
                                            <IconButton aria-label="delete" size="small" 
                                                onClick={ () => quitarNavegador( navegadoresStore[key].id, componenteActivoID, key, navegadoresStore ) }>
                                                <ClearIcon fontSize="inherit" />
                                            </IconButton>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    )
}

/* ------------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo,
    empleadosInactivos: state.EmpleadosReducer.empleadosInactivos
});
const mapDispatchToProps = { quitarNavegador, cambiarActivo, borrarEmpleadosInactivos };
/* ------------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, mapDispatchToProps)(TabsContainer);
