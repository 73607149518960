import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosAll } from '../../../../../../Redux/actions/EmpleadosActions';
import axios from 'axios';
import { servicios_grupohit_nomina, acumulados_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Acumulados = ({ id, permisos, empleadosAll, obtenerEmpleadosAll }) => {

    const [ nominas, setNominas ] = useState( null );
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    
    useEffect(() => {
        obtenerEmpleadosAll((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });
        axiosNomninas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    /* Axios para obtener listado de Nóminas */
    const axiosNomninas = () => {
        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setNominas([]);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    return (
        <div id={`modulo${id}`}>
            {   
                (empleadosAll && nominas)
                ?   <DataTableService
                        id={id}
                        title={acumulados_}
                        columns={[
                            { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                            { field: "nombreCompleto", title: "Nombre" },
                            { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
                            { field: "empleadoRfc", title: "RFC" },
                            { field: "empleadoNss", title: "NSS" },
                            { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                            { field: "puestoCve", title: "Clave Puesto" },
                            { field: "puestoNom", title: "Puesto" },
                            { field: "nominaNombre", title: "Nómina" },
                            { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                            { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                        ]}
                        data={empleadosAll}
                        customOptionsTable={{ filtering: true }}
                        hideIconDelete hideIconEdit showIconNewFolder
                        showIconApps
                        informacionAdicional={{ permisos, empleados: empleadosAll, nominas }}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
const mapStateToProps = (state) => ({
    empleadosAll: state.EmpleadosReducer.empleadosAll
});
  
const mapDispatchToProps = { obtenerEmpleadosAll };
  
export default connect(mapStateToProps, mapDispatchToProps)(Acumulados);