import React from 'react';
import { Dashboard, ViewQuilt, History,//Tablero
    Build, //Generales
    AssignmentInd, ChromeReaderMode, OpenWith, AccountBalance, AssignmentReturned, SupervisorAccount, Streetview,
    AccountBalanceWallet, EventBusy, Timelapse, NotListedLocation, Toc, TurnedInNot, Wc, LocalHospital, PermMedia,
    LocalAtm, PostAdd, NaturePeople, FormatListBulleted, Description, SupervisedUserCircle,
    LocationOn, School, Apartment, FileCopy, Gavel, InsertDriveFile, Contacts, Warning, AttachMoney, TableChart, ViewColumn, BlurLinear, SportsHandball, //Nuevos Iconos

    Web, //Administración
    TransferWithinAStation, Domain, Dehaze, ImageSearch, SettingsInputComponent,
    Storage, Panorama, //Nuevos Iconos

    Grain, //Organización
    MoreVert, Work, ViewList, ScatterPlot, ViewModule, MonetizationOn, Schedule, AccountTree, EventAvailable, Receipt,

    AccessibilityNew, //Recursos Humanos
    People, PlaylistAdd, RepeatOne, FolderShared, FeaturedPlayList, DateRange,

    InsertInvitation, //Nómina
    PermDataSetting, CalendarToday, DragIndicator, ListAlt, Repeat, FormatListNumbered,
    Exposure, LowPriority, Payment, Functions, Ballot, Update, //Nuevos Iconos

    DynamicFeed, //Reporteador
    Assessment, RecentActors, Code, PictureAsPdf, VideoLibrary, FindInPage,

    HelpOutline, //Ayuda
    Healing, Backup, LocalActivity,
    Money, MoneyOff, Gradient, GridOn, FlipCameraAndroid, WrapText, PauseCircleOutline,
    PermContactCalendar, Rowing, Apps, GroupWork, VerticalSplit, ViewWeek, Event,

    Search, //Buscador
    Cached,
    Replay,
    VpnKey,
    ExitToApp,
    Info,
    RotateLeft,
    Today,
    DeleteSweep,
    BarChart,
} from '@material-ui/icons';

export const iconosDrawer = {
    dashboard: <Dashboard />,
    view_quilt: <ViewQuilt />,
    history: <History />,
    bar_chart: <BarChart />,

    build: <Build />, //Generales
    notlistedlocation: <NotListedLocation />,
    openwith: <OpenWith />,
    account_balance_wallet: <AccountBalanceWallet />,
    streetview: <Streetview />,
    wc: <Wc />,
    turned_in_not: <TurnedInNot />,
    supervisor_account:<SupervisorAccount />,
    assignment_ind: <AssignmentInd />,
    localhospital: <LocalHospital />,
    chrome_reader_mode: <ChromeReaderMode />,
    account_balance: <AccountBalance />,
    toc: <Toc />,
    eventbusy: <EventBusy />,
    timelapse: <Timelapse />,
    assignment_returned: <AssignmentReturned />,
    permmedia: <PermMedia />,
    accountbalance: <AccountBalance />,
    localatm: <LocalAtm />,
    postadd: <PostAdd />,
    naturepeople: <NaturePeople />,
    formatlistbulleted: <FormatListBulleted />,
    description: <Description />,
    supervisedusercircle: <SupervisedUserCircle />,
    //Nuevos Iconos
    location_on: <LocationOn />,
    school: <School />,
    apartment: <Apartment />,
    file_copy: <FileCopy />,
    gavel: <Gavel />,
    insert_drive_file: <InsertDriveFile />,
    contacts: <Contacts />,
    warning: <Warning />,
    attach_money: <AttachMoney />,
    table_chart: <TableChart />,
    view_column: <ViewColumn />,
    blur_linear: <BlurLinear />,
    sports_hand_ball: <SportsHandball />,

    web: <Web />, //Administración
    transfer_within_a_station: <TransferWithinAStation />,
    domain: <Domain />,
    dehaze: <Dehaze />,
    imagesearch: <ImageSearch />,
    settingsinputcomponent:  <SettingsInputComponent />,
    //Nuevos Iconos
    storage: <Storage />,
    panorama: <Panorama />,

    grain: <Grain />, //Organización
    more_vert: <MoreVert />,
    work: <Work />,
    "view-list": <ViewList />,
    scatter_plot: <ScatterPlot />,
    "view-module": <ViewModule />,
    monetization_on: <MonetizationOn />,
    schedule: <Schedule />,
    account_tree: <AccountTree />,
    event_available: <EventAvailable />,
    receipt: <Receipt />,
    //Nuevos Iconos
    view_module: <ViewModule />,
    perm_data_setting: <PermDataSetting />,

    accessibility_new: <AccessibilityNew />, //Recursos Humanos
    people: <People />,
    playlist_add: <PlaylistAdd />, //Se emplea dos veces
    repeat_one: <RepeatOne />,
    folder_shered: <FolderShared />,
    featured_play_list: <FeaturedPlayList />,
    date_range: <DateRange />,

    insert_invitation: <InsertInvitation />, //Nómina
    permdatasetting: <PermDataSetting />,
    calendar_today: <CalendarToday />,
    drag_indicator: <DragIndicator />,
    list_alt: <ListAlt />,
    formatlistnumbered: <FormatListNumbered />,
    repeat: <Repeat />,
    //Nuevos Iconos
    exposure: <Exposure />,
    format_list_numbered: <FormatListNumbered />,
    low_priority: <LowPriority />,
    payment: <Payment />,
    functions: <Functions />,
    ballot: <Ballot />,
    update: <Update />,

    dynamic_feed: <DynamicFeed />, //Reporteador
    assessment: <Assessment />,
    recentactors: <RecentActors />,
    code: <Code />,
    monetizationon: <MonetizationOn />,
    pictureaspdf: <PictureAsPdf />,
    videolibrary: <VideoLibrary />,
    find_in_page: <FindInPage />,

    help_outline: <HelpOutline />,
    healing: <Healing />,
    backup: <Backup />,
    localactivity: <LocalActivity />,

    moneyoff: <MoneyOff />,
    money: <Money />,
    gradient: <Gradient />,
    gridon: <GridOn />,
    flipcameraandroid: <FlipCameraAndroid />,
    wraptext: <WrapText />,
    pausecircleoutline: <PauseCircleOutline />,
    permcontactcalendar: <PermContactCalendar />,
    rowing: <Rowing />,
    apps: <Apps />,
    groupwork: <GroupWork />,
    verticalsplit: <VerticalSplit />,
    viewweek: <ViewWeek />,
    event: <Event />,

    vpnkey: <VpnKey/>,
    cached: <Cached/>,
    replay: <Replay/>,
    search: <Search />,
    exit_to_app: <ExitToApp />,
    info: <Info />,
    rotate_left: <RotateLeft />,
    today: <Today />,
    delete_sweep: <DeleteSweep />,
}