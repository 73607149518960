import React, { createRef, useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, deleteOptionals, DIAS_AGNIO } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado, registro_sera_eliminado, registro_no_eliminado, servicios_grupohit_rh, servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../Constants/Constants';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import AlertForm from '../../../../../../Services/AlertForm';
import FormAusentismos from './FormAusentismos';
import { ArrayJsonPrimaVacacional, ArrayJsonAguinaldo, ArrayJsonDiasAjuste, ArrayJsonVacaciones} from '../../../Helpers/JsonToOneLevel';
import ConvertDate from '../../../Helpers/ConvertDate';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioPoliticas = ({ enviarPolitica, elementSelect, actualizarPolitica, vista, isLoading }) => {
    
    const tipos = [
        { id: 0, nombre: 'AGUINALDO' },
        { id: 1, nombre: 'PRIMA VACACIONAL' },
        { id: 2, nombre: 'DÍA DE AJUSTE' },
        { id: 3, nombre: 'VACACIONES' },
        { id: 4, nombre: 'AUSENTISMOS' }
    ];

    const [ errorDate, setErrorDate ] = useState( null );
    const [ errorDias, setErrorDias ] = useState( null );
    const [ errorPeriodosDias, setErrorPeriodosDias ] = useState( null );
    const [ errorPeriodos, setErrorPeriodos ] = useState( null );
    const [ diasBonoSemestre, setDiasBonoSemestre ] = useState( elementSelect ? elementSelect.diasBonoAnio/2 : null );
    const [ diasAguinaldoSemestre, setDiasAguinaldoSemestre ] = useState( elementSelect
        ?   tipos.filter(element => element.nombre.includes(elementSelect.tipo.replace('DÍAS','DÍA').toUpperCase()))[0].id === 0
            ? elementSelect.diasAguinaldoAnio/2
            : tipos.filter(element => element.nombre.includes(elementSelect.tipo.replace('DÍAS','DÍA').toUpperCase()))[0].id === 1
                ? elementSelect.diasPrimaAnio/2
                : null
        :   null
    ); 
    const [ openAlert, setOpenAlert ] = useState( false );
    const tipoRef = createRef('');
    const [active, setActive] = useState(elementSelect ? tipos.filter(element => element.nombre.includes(elementSelect.tipo.replace('DÍAS','DÍA').toUpperCase()))[0].id :-1);
    /*Ausentismos*/
    const [conceptosDeducciones, setConceptosDeducciones] = useState([]);
    const [conceptosPercepciones, setConceptosPercepciones] = useState([]);
    const [percepcionesBase, setPercepcionesBase] = useState([]);
    const [ausentismos, setAusentismos] = useState([]);
                
    const feciniRef = createRef('');
    const fecfinRef = createRef('');

    const feciniPrimaRef = createRef('');
    const fecfinPrimaRef = createRef('');
    
    const feciniDiaAjusteRef = createRef('');
    const fecfinDiaAjusteRef = createRef('');
    
    const feciniVacacionesRef = createRef('');
    const fecfinVacacionesRef = createRef('');

    const diasAguinaldoRef = createRef(''); 
    const diasAjusteRef = createRef('');
    const diasMinLabRef = createRef('')
    const diasAjustePeriodoRef = createRef(''); 
    const diasBonoRef = createRef(''); 
    const pagoSegundoSemRef = createRef(''); 
    const pagoPrimerSemRef = createRef(''); 
    const deAgniosRef = createRef(''); 
    const hastaAgniosRef = createRef(''); 
    const diasPrimerSemRef = createRef('');
    const diasSegundoSemRef = createRef('');  
    const periodoPagoRef = createRef('');

    /*Ausentismos */
    const fechaInicioAusentismoRef = createRef('');
    const fechaFinAusentismoRef = createRef('');
    const conceptoAusentismoRef = createRef('');
    const ausentismoRef = createRef('');
    const cantidadAusentismoRef = createRef('');

    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const [ titleAlert, setTitleAlert ] = useState(null);
    const [ deleteRow, setDeleteRow ] = useState( false );
    const [ canAdd, setCanAdd ] = useState( false );
    const [ dias, setDias] = useState([]);
    const [ diasVacaciones, setDiasVacaciones] = useState([]);
    const [ idToDelete, setIdToDelete ] = useState( null );
    const [data, setData ] = useState( [] );
    
    const [ periodosAgnio, setPeriodosAgnio ] =useState([
        {id:2}, {id:4}, 
        {id:6}, {id:8}, {id:10},
        {id:12}, {id:14}, {id:16}, {id:18}, {id:20},
        {id:22}, {id:24}  
    ]);

    const regex=/VA|PV|DA|AG$/;

    const compararPeridos = ( periodoInicial, periodoFinal ) => {
        if( periodoFinal === '' ){
            return true;
        }else{
            if( periodoInicial < periodoFinal ){
                return true;
            }else{
                return false;
            }
        }
    };

    useEffect(()=>{
        if(elementSelect){
            showForm(active);
        }
        if( elementSelect && active === 2 ){ 
            axios.get(`${servicios_grupohit_rh}/periodo/ajuste/politica/ajuste/${elementSelect.id.replace(regex,'')}`, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        const respuesta =res.data.dataResponse;
                        let newPeriodosAgnio = periodosAgnio;
                        let result =0;
                        let diaAjus=0;
                        for (const key in respuesta) {
                            diaAjus=parseFloat(respuesta[key].diasAjuste.diasAjuste);
                            result+=parseFloat(respuesta[key].diasPagoPeriodo); 
                            const newPeriodos = newPeriodosAgnio.filter(element => element.id !== respuesta[key].periodoPago) ;
                            newPeriodosAgnio = newPeriodos;
                        } 
                        if(result>=diaAjus){
                            setCanAdd(true);
                        }
                        setPeriodosAgnio(newPeriodosAgnio);
                        setDias( respuesta );
                        break;
                    case 404:
                        setDias([]);
                        break;
                    default:
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                }
            })
            .catch(error => {
                setTextAlert(verifique_conexion);
                setAlertError(true);
            });
        }
        if( elementSelect && active === 3 ){
            axios.get(`${servicios_grupohit_rh}/vacaciones/antiguedad/politcaVacaciones/${elementSelect.id.replace(regex,'')}`, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        setDiasVacaciones( res.data.dataResponse );
                        break;
                    case 404:
                        setDias([]);
                        break;
                    default:
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                }
            })
            .catch(error => {
                setTextAlert(verifique_conexion);
                setAlertError(true);
            });
        }

        // eslint-disable-next-line
    },[]);

    const mostrarAlert = (evt) => {
        if(evt) {
            evt.preventDefault();
        }
        setDeleteRow(false);
        setTextAlert(registro_sera_actualizado);
        setOpenAlert(true);
    };

    const mostrarAlertIncomplete = () => {
        setTitleAlert('¿Deseas continuar?');
        setTextAlert('Hay Conceptos a descontar sin agregar');
        setOpenAlert(true);
    };
    
    const enviar = (evt) => {
        if(evt) {
            evt.preventDefault();
        }
        clearError();  
        switch (active){
            case 0:
                if(validarSubmit('AgregarAguinaldo')){  
                    const fechaIni = inputDateToLong( feciniRef.current.value );
                    const fechaFin = inputDateToLong( fecfinRef.current.value );
                    const diasAguinaldoAnio = parseFloat( diasAguinaldoRef.current.value );  
                    const diasBonoAnio = parseFloat( diasBonoRef.current.value );
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value ); 
                    const lastFechaIncial = data.length > 0 ? inputDateToLong(data[0].fechaIn) : null;
                    const lastFechaIncialFormat = data.length > 0 ? data[0].fechaIni : null;   
                    if( fechaIni > lastFechaIncial ||  lastFechaIncial===null){
                        if( compararPeridos(fechaIni, fechaFin) ){
                            setErrorDate( null );
                            if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                                setErrorPeriodos( null );
                                enviarPolitica(deleteOptionals({fechaIni, fechaFin, diasAguinaldoAnio, diasBonoAnio, periodoPagoSem1, periodoPagoSem2}), active);
                            }else{
                                setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre." /> );
                            }
                        }else{
                            setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message={`La Fecha Inicial debe ser mayor a ${lastFechaIncialFormat}`} /> );
                    }
                }
            break; 
            case 1:
                if(validarSubmit('AgregarPrimaVacacional')){
                    const fechaIni = inputDateToLong( feciniPrimaRef.current.value );
                    const fechaFin = inputDateToLong( fecfinPrimaRef.current.value );
                    const diasPrimaAnio = parseFloat( diasAguinaldoRef.current.value ); 
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value );
                    const lastFechaIncial = data.length > 0 ? inputDateToLong(data[0].fechaIn) : null;
                    const lastFechaIncialFormat = data.length > 0 ? data[0].fechaIni : null;   

                    if( fechaIni > lastFechaIncial ||  lastFechaIncial===null){
                        if( compararPeridos(fechaIni, fechaFin) ){
                            setErrorDate( null );
                            if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                                setErrorPeriodos( null );
                                enviarPolitica(deleteOptionals({ fechaIni, fechaFin, diasPrimaAnio, periodoPagoSem1, periodoPagoSem2 }), active);
                            }else{
                                setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre." /> );
                            }
                        }else{
                            setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message={`La Fecha Inicial debe ser mayor a ${lastFechaIncialFormat}`} /> );
                    }
                    
                }
            break;
            case 2:
                
                if(validarSubmit('AgregarDiaAjuste')){
                    if(dias.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Pago y Días a Pagar." /> );
                    }else{
                        let result =0;
                        for (const key in dias) {
                            result+=parseFloat(dias[key].diasPagoPeriodo)
                        }
                        if(result > parseFloat( diasAjusteRef.current.value ) ) {
                            setErrorDias(<AlertForm message="Los días a pagar que desea agregar supera a la cantidad de días de ajuste." />); 
                        }else{
                            const fechaIni = inputDateToLong( feciniDiaAjusteRef.current.value );
                            const fechaFin = inputDateToLong( fecfinDiaAjusteRef.current.value );
                            const diasAjuste = parseFloat( diasAjusteRef.current.value );
                            const diasMinLab = parseFloat(diasMinLabRef.current.value);
                            const lastFechaIncial = data.length > 0 ? inputDateToLong(data[0].fechaIn) : null;
                            const lastFechaIncialFormat = data.length > 0 ? data[0].fechaIni : null;   

                            if( fechaIni > lastFechaIncial ||  lastFechaIncial===null){
                                if( compararPeridos(fechaIni, fechaFin) ){
                                    if(result === parseFloat( diasAjusteRef.current.value )){
                                        setErrorDate( null );
                                        enviarPolitica(deleteOptionals({ fechaIni, fechaFin, diasAjuste, diasMinLab }), active, dias);
                                    }else{
                                        setErrorDate( <AlertForm message="La suma de días a pagar es menor a la cantidad de días de ajuste." /> );
                                    }
                                }else{
                                    setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                                }
                            }else{
                                setErrorDate( <AlertForm message={`La Fecha Inicial debe ser mayor a ${lastFechaIncialFormat}`} /> );
                            }    
                        }
                    }
                    
                }
            break;
            case 3:
                if(validarSubmit('AgregarVacaciones')){ 
                    const fechaIni = inputDateToLong( feciniVacacionesRef.current.value );
                    const fechaFin = inputDateToLong( fecfinVacacionesRef.current.value );
                    if(diasVacaciones.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Vacaciones." /> );
                    }else{
                        const lastFechaIncial = data.length > 0 ? inputDateToLong(data[0].fechaIn) : null;
                        const lastFechaIncialFormat = data.length > 0 ? data[0].fechaIni : null;   

                        if( fechaIni > lastFechaIncial ||  lastFechaIncial===null){
                            if( compararPeridos(fechaIni, fechaFin) ){
                                setErrorDate( null );
                                enviarPolitica(deleteOptionals({ fechaIni, fechaFin }), active, diasVacaciones); 
                            }else{
                                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                            }
                        }else{
                            setErrorDate( <AlertForm message={`La Fecha Inicial debe ser mayor a ${lastFechaIncialFormat}`} /> );
                        }  
                    }
                    
                }
            break;
            case 4:
                if(validarSubmit('FormAusentismos')) {
                    const fechaini = inputDateToLong(fechaInicioAusentismoRef.current.value);
                    const indicadorId = parseInt(conceptoAusentismoRef.current.value);
                    const tipoAusentismoId = parseInt(ausentismoRef.current.value);
                    const cantidad = parseFloat(cantidadAusentismoRef.current.value);
                    const calculo = percepcionesBase.map(item => ({ indicadorId: item.percepcion.id, dias: parseFloat(item.cantidad) }));

                    let request = {
                        fechaini,
                        config: { tipoAusentismoId, indicadorId, cantidad },
                        calculo
                    };

                    if(inputDateToLong(fechaFinAusentismoRef.current.value))
                    request.fechafin = inputDateToLong(fechaFinAusentismoRef.current.value);

                    enviarPolitica(request, active);
                }
            break;
            default:
            break;
        }
        
    }

    const actualizar = () => {
        clearError();
        switch (active){
            case 0:
                if(validarSubmit('EditarAguinaldo')){  
                    const fechaIni = inputDateToLong( feciniRef.current.value );
                    const fechaFin = inputDateToLong( fecfinRef.current.value );
                    const diasAguinaldoAnio = parseFloat( diasAguinaldoRef.current.value );  
                    const diasBonoAnio = parseFloat( diasBonoRef.current.value );
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value );

                    const indexElementSelect = data.sort((a, b)=> inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 :0 ).findIndex(element => element.id === elementSelect.id );
                    const limiteInferior = data[indexElementSelect+1] ? inputDateToLong( data[indexElementSelect+1].fechaIn ) : null;
                    const limiteSuperior = data[indexElementSelect-1] ? inputDateToLong( data[indexElementSelect-1].fechaIn ) : null;
                    const limiteInferiorFormat = data[indexElementSelect+1] ? ConvertDate(data[indexElementSelect+1].fechaIn)  : null;
                    const limiteSuperiorFormat = data[indexElementSelect-1] ? ConvertDate(data[indexElementSelect-1].fechaIn)  : null;
                    
                    setErrorDate( null );
                    if( fechaIni > limiteInferior ||  limiteInferior===null ){
                        if(fechaIni < limiteSuperior || limiteSuperior===null  ){
                            if( compararPeridos(fechaIni, fechaFin) ){
                                setErrorDate( null );
                                if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                                    setErrorPeriodos( null );
                                    actualizarPolitica(deleteOptionals({fechaIni, fechaFin, diasAguinaldoAnio, diasBonoAnio, periodoPagoSem1, periodoPagoSem2}), active);      
                                }else{
                                    setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre." /> );
                                }
                            }else{
                                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                            }
                        }else{
                            setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser menor a ${limiteSuperiorFormat}` } /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser mayor a ${limiteInferiorFormat} ` } /> );
                    }
                }
            break; 
            case 1:
                if(validarSubmit('EditarPrimaVacacional')){
                    const fechaIni = inputDateToLong( feciniPrimaRef.current.value );
                    const fechaFin = inputDateToLong( fecfinPrimaRef.current.value );
                    const diasPrimaAnio = parseFloat( diasAguinaldoRef.current.value ); 
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value );
                    
                    const indexElementSelect = data.sort((a, b)=> inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 :0 ).findIndex(element => element.id === elementSelect.id );
                    const limiteInferior = data[indexElementSelect+1] ? inputDateToLong( data[indexElementSelect+1].fechaFin ) : null;
                    const limiteSuperior = data[indexElementSelect-1] ? inputDateToLong( data[indexElementSelect-1].fechaIn ) : null;
                    const limiteInferiorFormat = data[indexElementSelect+1] ? data[indexElementSelect+1].fechaFin  : null;
                    const limiteSuperiorFormat = data[indexElementSelect-1] ? data[indexElementSelect-1].fechaIni  : null;
                    
                    setErrorDate( null );
                    if( fechaIni > limiteInferior ||  limiteInferior===null ){
                        if(fechaIni < limiteSuperior || limiteSuperior===null  ){
                            if( compararPeridos(fechaIni, fechaFin) ){
                                
                                if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                                    setErrorPeriodos( null );
                                    actualizarPolitica(deleteOptionals({ fechaIni, fechaFin, diasPrimaAnio, periodoPagoSem1, periodoPagoSem2 }), active);
                                }else{
                                    setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre." /> );
                                }
                            }else{
                                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                            }
                        }else{
                            setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser menor a ${limiteSuperiorFormat}` } /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser mayor a ${limiteInferiorFormat} ` } /> );
                    }
                }
            break;
            case 2:
                if(validarSubmit('EditarDiaAjuste')){
                    if(dias.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Pago y Días a Pagar." /> );
                    }else{
                        let result =0;
                        for (const key in dias) {
                            result+=parseFloat(dias[key].diasPagoPeriodo)
                        }
                        if(result > parseFloat( diasAjusteRef.current.value ) ) { 
                            setErrorDias(<AlertForm message="La suma de días a pagar es mayor a la cantidad de días de ajuste." />); 
                        }else{
                            const fechaIni = inputDateToLong( feciniDiaAjusteRef.current.value );
                            const fechaFin = inputDateToLong( fecfinDiaAjusteRef.current.value );
                            const diasAjuste = parseFloat( diasAjusteRef.current.value );
                            const diasMinLab = parseFloat( diasMinLabRef.current.value );
                            const newDias = [];
                            for (const key in dias) { 
                                for (const llave in dias[key]) {
                                    if (llave==='idLocal') {
                                        newDias.push({
                                            diasPagoPeriodo: dias[key].diasPagoPeriodo,
                                            periodoPago: dias[key].periodoPago
                                        });
                                    }  
                                } 
                            }
                            const indexElementSelect = data.sort((a, b)=> inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 :0 ).findIndex(element => element.id === elementSelect.id );
                            const limiteInferior = data[indexElementSelect+1] ? inputDateToLong( data[indexElementSelect+1].fechaIn ) : null;
                            const limiteSuperior = data[indexElementSelect-1] ? inputDateToLong( data[indexElementSelect-1].fechaIn ) : null;
                            const limiteInferiorFormat = data[indexElementSelect+1] ? data[indexElementSelect+1].fechaIni : null;
                            const limiteSuperiorFormat = data[indexElementSelect-1] ? data[indexElementSelect-1].fechaIni  : null;
                            
                            setErrorDate( null );
                            if( fechaIni > limiteInferior ||  limiteInferior===null ){
                                if(fechaIni < limiteSuperior || limiteSuperior===null  ){
                            
                                    if( compararPeridos(fechaIni, fechaFin) ){
                                        if(result === parseFloat( diasAjusteRef.current.value )){
                                            setErrorDate( null );
                                            actualizarPolitica(deleteOptionals({ fechaIni, fechaFin, diasAjuste, diasMinLab }), active, newDias.length>0 ? newDias : null); 
                                        }else{
                                            setErrorDate( <AlertForm message="La suma de días a pagar es menor a la cantidad de días de ajuste." /> );
                                        }
                                    }else{
                                        setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                                    }
                                }else{
                                    setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser menor a ${limiteSuperiorFormat}` } /> );
                                }
                            }else{
                                setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser mayor a ${limiteInferiorFormat} ` } /> );
                            }
                        }
                    }
                }
            break;
            case 3:
                if(validarSubmit('EditarVacaciones')){ 
                    const fechaIni = inputDateToLong( feciniVacacionesRef.current.value );
                    const fechaFin = inputDateToLong( fecfinVacacionesRef.current.value );
                    if(diasVacaciones.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Vacaciones." /> );
                    }else{
                        const indexElementSelect = data.sort((a, b)=> inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 :0 ).findIndex(element => element.id === elementSelect.id );
                        const limiteInferior = data[indexElementSelect+1] ? inputDateToLong( data[indexElementSelect+1].fechaIn ) : null;
                        const limiteSuperior = data[indexElementSelect-1] ? inputDateToLong( data[indexElementSelect-1].fechaIn ) : null;
                        const limiteInferiorFormat = data[indexElementSelect+1] ? data[indexElementSelect+1].fechaIni : null;
                        const limiteSuperiorFormat = data[indexElementSelect-1] ? data[indexElementSelect-1].fechaIni  : null;
                        
                        setErrorDate( null );
                        if( fechaIni > limiteInferior ||  limiteInferior===null ){
                            if(fechaIni < limiteSuperior || limiteSuperior===null  ){
                            
                                if( compararPeridos(fechaIni, fechaFin) ){
                                    setErrorDate( null ); 
                                    actualizarPolitica(deleteOptionals({ fechaIni, fechaFin }), active);
                                }else{
                                    setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial." /> );
                                }
                            }else{
                                setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser menor a ${limiteSuperiorFormat}` } /> );
                            }
                        }else{
                            setErrorDate( <AlertForm message={ `La Fecha Inicial debe ser mayor a ${limiteInferiorFormat} ` } /> );
                        }
                    }
                }
            break;
            case 4:
                if(validarSubmit('FormAusentismos')) {
                    const fechaini = inputDateToLong(fechaInicioAusentismoRef.current.value);
                    const fechafin = (inputDateToLong(fechaFinAusentismoRef.current.value)) ? inputDateToLong(fechaFinAusentismoRef.current.value) : null;
                    const indicadorId = parseInt(conceptoAusentismoRef.current.value);
                    const tipoAusentismoId = parseInt(ausentismoRef.current.value);
                    const cantidad = parseFloat(cantidadAusentismoRef.current.value);

                    let request = {
                        polausencia_id: parseInt(elementSelect.id.replace(/AU/,'')),
                        fechaini,
                        fechafin,
                        config: {
                            tipoAusentismoId,
                            indicadorId,
                            cantidad
                        }
                    };

                    actualizarPolitica(request, 4);
                }
            break;
            default:
            break;
        }
    }
    
    const showForm = (id) => { 
        switch(parseInt(id)){
            case 0:
                axios.get(`${servicios_grupohit_rh}/politica/aguinaldo/`, headersAuth())
                .then(res => {            
                    switch(res.data.status) {
                        case 200: 
                            setData( ArrayJsonAguinaldo(res.data.dataResponse) );
                            break;
                        case 404:
                            setData([]);
                            break;
                        default:
                            setTextAlert(res.data.msg);
                            setAlertError(true);
                            break;
                    }
                })
                .catch(error => {
                    setTextAlert(verifique_conexion);
                    setAlertError(true);
                }); 
            break;
            case 1:
                axios.get(`${servicios_grupohit_rh}/politica/prima/vacacional/`, headersAuth())
                .then(res => {            
                    switch(res.data.status) {
                        case 200: 
                            setData( ArrayJsonPrimaVacacional(res.data.dataResponse) );
                            break;
                        case 404:
                            setData([]);
                            break;
                        default:
                            setTextAlert(res.data.msg);
                            setAlertError(true);
                            break;
                    }
                })
                .catch(error => {
                    setTextAlert(verifique_conexion);
                    setAlertError(true);
                });
            break;
            case 2:
                axios.get(`${servicios_grupohit_rh}/politica/dias/ajuste/`, headersAuth())
                .then(res => {            
                    switch(res.data.status) {
                        case 200: 
                            setData( ArrayJsonDiasAjuste(res.data.dataResponse) );
                            break;
                        case 404:
                            setData([]);
                            break;
                        default:
                            setTextAlert(res.data.msg);
                            setAlertError(true);
                            break;
                    }
                })
                .catch(error => {
                    setTextAlert(verifique_conexion);
                    setAlertError(true);
                });
            break;
            case 3:
                axios.get(`${servicios_grupohit_rh}/politica/vacaciones/`, headersAuth())
                .then(res => {            
                    switch(res.data.status) {
                        case 200: 
                            setData( ArrayJsonVacaciones(res.data.dataResponse) );
                            break;
                        case 404:
                            setData([]);
                            break;
                        default:
                            setTextAlert(res.data.msg);
                            setAlertError(true);
                            break;
                    }
                })
                .catch(error => {
                    setTextAlert(verifique_conexion);
                    setAlertError(true);
                });
            break;
            case 4:
                axios.get(`${servicios_grupohit_catalogo}/indicador/getall/relab`, headersAuth())
                .then(res => {            
                    switch(res.data.status) {
                        case 200:
                            let percepciones = [];
                            let deducciones = [];
                            res.data.dataResponse.forEach(item => {
                                item.id = item.indicadorId;
                                if(item.naturaleza.id === 1) {
                                    percepciones.push(item);
                                }
                                if(item.naturaleza.id === 2) {
                                    deducciones.push(item);
                                }
                            });
                            setConceptosPercepciones(percepciones);
                            setConceptosDeducciones(deducciones);
                            break;
                        case 404:
                            setConceptosPercepciones([]);
                            setConceptosDeducciones([]);
                            break;
                        default:
                            setConceptosPercepciones([]);
                            setConceptosDeducciones([]);
                            setTextAlert(res.data.msg);
                            setAlertError(true);
                            break;
                    }
                })
                .catch(error => {
                    setTextAlert(verifique_conexion);
                    setAlertError(true);
                });

                axios.get(`${servicios_grupohit_catalogo}/tipoausentismo/`, headersAuth())
                .then(res => {
                    switch(res.data.status){
                        case 200:
                            setAusentismos(res.data.dataResponse);
                            break;
                        case 404:
                            setAusentismos([]);
                            break;
                        default:
                            setAusentismos([]);
                            setTextAlert(res.data.msg);
                            setAlertError(true);
                            break;
                    }
                })
                .catch( error => {
                    setTextAlert(verifique_conexion);
                    setAlertError(true);
                })
            break;
            default: 
            break;
        }

        if(!elementSelect){
            tipoRef.current.value=''; 
            vista(parseInt(id));
            setActive(parseInt(id));
            
        }
    }
    
    const atras = (e, pag) => {
        if(e) e.preventDefault();

        setErrorDate( null );
        setErrorPeriodos( null );
        setErrorDias( null );
        switch (active){
            case 0: 
                    feciniRef.current.value = '';
                    fecfinRef.current.value = '';
                    diasAguinaldoRef.current.value = '';
                    diasBonoRef.current.value = '';
                    pagoPrimerSemRef.current.value = '';
                    pagoSegundoSemRef.current.value = '';
                    setDiasAguinaldoSemestre(null);
                    setDiasBonoSemestre(null);
            break; 
            case 1: 
                    feciniPrimaRef.current.value = '';
                    fecfinPrimaRef.current.value = '';
                    diasAguinaldoRef.current.value = '';
                    pagoPrimerSemRef.current.value = '';
                    pagoSegundoSemRef.current.value = '';
                    setDiasAguinaldoSemestre(null);
            break;
            case 2:
                    feciniDiaAjusteRef.current.value = ''; 
                    fecfinDiaAjusteRef.current.value = ''; 
                    diasAjusteRef.current.value = '';
                    diasMinLabRef.current.value = '';
                    periodoPagoRef.current.value = '';
                    diasAjustePeriodoRef.current.value = '';
            break;
            case 3:
                feciniVacacionesRef.current.value = '';
                fecfinVacacionesRef.current.value = '';
                deAgniosRef.current.value = '';
                hastaAgniosRef.current.value = '';
                diasPrimerSemRef.current.value = '';
                diasSegundoSemRef.current.value = '';
            break;
            case 4:
                fechaInicioAusentismoRef.current.value= '';
                fechaFinAusentismoRef.current.value = '';
                conceptoAusentismoRef.current.value = '';
                ausentismoRef.current.value = '';
                cantidadAusentismoRef.current.value = '';
                setPercepcionesBase([]);
            break;
            default:
            break;
        }
        vista(null)
        setActive(pag);
    };
 
    const deleteDay=(id)=>{
        
        axios.delete( `${servicios_grupohit_rh}/periodo/ajuste/${id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setTextAlert('Registro Eliminado');
                    setAlertSuccess(true);
                    break;
                case 404:
                    setTextAlert(respuesta.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setTextAlert( registro_no_eliminado );
                    setAlertError(true);
                    break;
            }
        })
        .catch( error => {
            setTextAlert( registro_no_eliminado );
            setAlertError(true);
        }) 
    }

    const deleteDayVac=(id)=>{
        
        axios.delete( `${servicios_grupohit_rh}/vacaciones/antiguedad/${id}`, headersAuth() )
        .then( res => {
            switch( res.data.status ){
                case 200:
                    setTextAlert('Registro Eliminado');
                    setAlertSuccess(true);
                    break;
                case 404:
                    setTextAlert(res.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setTextAlert(res.data.msg);
                    setAlertError(true);
                    break;
            }
        })
        .catch( error => {
            setTextAlert(registro_no_eliminado);
            setAlertError(true);

        }) 
    }

    const deleteDias = async () => {
        clearError();
        let diaAjus = parseFloat(diasAjusteRef.current.value);

        const indice = dias.findIndex(dia  => dia.id === parseInt(idToDelete));
        let newDias =[];
        if(indice!==-1){
            await deleteDay(parseInt(idToDelete));
            setPeriodosAgnio([...periodosAgnio, { id: parseInt(dias[indice].periodoPago)}].sort((a, b) => (a.id > b.id) ? 1 : -1));
            newDias = dias.filter(dia  => dia.id !== parseInt(idToDelete)); 
            await setDias(newDias);
        }else{
            const index = dias.findIndex(dia  => dia.idLocal === idToDelete);
            setPeriodosAgnio([...periodosAgnio, { id: parseInt(dias[index].periodoPago)}].sort((a, b) => (a.id > b.id) ? 1 : -1));
            newDias = dias.filter(dia  => dia.idLocal !==idToDelete); 
            await setDias(newDias);
        }
 
        let result=0;
        for(var i=0; i<newDias.length; i++){
            for(var j=0; j<2; j++){
                var label = document.getElementsByName(`${j}-${newDias[i].id}`);                
                label[0].value=newDias[i].periodoPago; 
            }
            result+=parseFloat(newDias[i].diasPagoPeriodo);
            if(result<diaAjus){
                setCanAdd(false);
            } 
        }
    }

    const deleteDiasVac = async () => { 
        clearError();
        const indice = diasVacaciones.findIndex(dia  => dia.id === parseInt(idToDelete));
        let newDias =[];
        if(indice!==-1){
            deleteDayVac(parseInt(idToDelete)); 
            newDias = diasVacaciones.filter(dia  => dia.id !== parseInt(idToDelete)); 
            setDiasVacaciones(newDias);
        }else{ 
            newDias = diasVacaciones.filter(dia  => dia.idLocal !==idToDelete); 
            await setDiasVacaciones(newDias);
        }
  
        for(var i=0; i<newDias.length; i++){
            for(var j=0; j<2; j++){
                var label = document.getElementsByName(`${j}-${newDias[i].id}`);                
                label[0].value=newDias[i].periodoPago; 
            }  
        }
    }

    const addDias = () =>{
        setErrorPeriodosDias(null);
        setErrorDate(null);
        setErrorDias(null);
        let result =0;
        if(diasAjusteRef.current.value === ''){
            diasAjusteRef.current.focus();
            return null;
        }

        if(diasMinLabRef.current.value === ''){
            diasMinLabRef.current.focus();
            return null;
        }

        if(periodoPagoRef.current.value === ''){
            periodoPagoRef.current.focus();
            return null;
        }
        
        if(diasAjustePeriodoRef.current.value === ''){
            diasAjustePeriodoRef.current.focus(); 
            return null;
        }else{
            for (const key in dias) {
                result+=parseFloat(dias[key].diasPagoPeriodo);
            }
            result += parseFloat(diasAjustePeriodoRef.current.value)
        }

        if( result <= parseFloat(diasAjusteRef.current.value) ){
             
                const newDiasSelect=periodosAgnio.filter(dia=>dia.id!==parseInt(periodoPagoRef.current.value));
                setDias([...dias, {idLocal: `${dias.length}-id`, periodoPago:parseInt(periodoPagoRef.current.value),
                        diasPagoPeriodo: parseInt(diasAjustePeriodoRef.current.value) }]);
                setPeriodosAgnio(newDiasSelect);  
                periodoPagoRef.current.value = '';
                diasAjustePeriodoRef.current.value = '';
                if(result===parseFloat(diasAjusteRef.current.value)){
                    setCanAdd( true );
                }  
        }else{
            setErrorPeriodosDias( <AlertForm message="Los días a pagar que desea agregar supera a la cantidad de días de ajuste." /> );
            diasAjustePeriodoRef.current.focus(); 
        }    
    }

    const addDiasVacaciones = () => {
        if(deAgniosRef.current.value === '' || !parseInt(deAgniosRef.current.value)){
            deAgniosRef.current.focus();
            return null;
        }

        if(hastaAgniosRef.current.value === '' || !parseInt(hastaAgniosRef.current.value) ){
            hastaAgniosRef.current.focus();
            return null;
        }
        
        if(diasPrimerSemRef.current.value === '' || !DIAS_AGNIO.test(diasPrimerSemRef.current.value) ){
            diasPrimerSemRef.current.focus(); 
            return null;
        }

        if(diasSegundoSemRef.current.value === '' || !DIAS_AGNIO.test(diasSegundoSemRef.current.value)){
            diasSegundoSemRef.current.focus(); 
            return null;
        }
        
        if(parseFloat(deAgniosRef.current.value) < parseFloat(hastaAgniosRef.current.value)){
            if(elementSelect){
                if(diasVacaciones[diasVacaciones.length-1].anioFin === parseFloat(deAgniosRef.current.value)-1 || diasVacaciones[diasVacaciones.length-1].anioFin === parseFloat(deAgniosRef.current.value)-0.5 ){
                
                    addDiasVacacionesBack( parseFloat(deAgniosRef.current.value), parseFloat(hastaAgniosRef.current.value), 
                    parseInt(diasPrimerSemRef.current.value), parseInt(diasSegundoSemRef.current.value)); 
                }else{
                    setErrorPeriodosDias(<AlertForm message={`De (Años) debe ser mayor a ${diasVacaciones[diasVacaciones.length-1].anioFin} y menor que ${diasVacaciones[diasVacaciones.length-1].anioFin+2} `} />); 
                }
            }else{ 
                setErrorPeriodosDias(null);
                setDiasVacaciones([
                    ...diasVacaciones,
                    {
                        idLocal: `${hastaAgniosRef.current.value}-id`,
                        anioIni: parseFloat(deAgniosRef.current.value),
                        anioFin: parseFloat(hastaAgniosRef.current.value),
                        diasSem1: parseInt(diasPrimerSemRef.current.value),
                        diasSem2: parseInt(diasSegundoSemRef.current.value) 
                    }
                ]);

                deAgniosRef.current.value = '';
                hastaAgniosRef.current.value = '';
                diasPrimerSemRef.current.value = '';
                diasSegundoSemRef.current.value = '';  
            }
        }else{
            setErrorPeriodosDias(<AlertForm message="Hasta (Años) debe ser mayor a De (Años)." />); 
        }
    }

    const alertDelete=(id)=>{
        setIdToDelete(id);
        setTextAlert(registro_sera_eliminado);
        setDeleteRow(true);
        setOpenAlert(true);
    }
    
    const addDiasVacacionesBack = (anioIni, anioFin, diasSem1, diasSem2) => {

        const diasVac={ politcaVacaciones: parseInt( elementSelect.id.replace(regex,'')), anioIni, anioFin, diasSem1, diasSem2}; 
        axios.post( `${servicios_grupohit_rh}/vacaciones/antiguedad/`, diasVac, headersAuth() )
            .then( res => { 
                switch( res.data.status ){
                    case 200:
                        deAgniosRef.current.value = '';
                        hastaAgniosRef.current.value = '';
                        diasPrimerSemRef.current.value = '';
                        diasSegundoSemRef.current.value = '';
                        setDiasVacaciones([...diasVacaciones, {id:res.data.dataResponse.id, anioIni:res.data.dataResponse.anioIni,
                        anioFin: res.data.dataResponse.anioFin, diasSem1: res.data.dataResponse.diasSem1, diasSem2: res.data.dataResponse.diasSem2  }]); 
                        setErrorPeriodosDias(null);    
                        break;
                    default:
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                }
            })
            .catch( error => { setTextAlert(verifique_conexion); setAlertError(true); })
    }

    const getDiasAjuste = () => {
        let res=[]; 
        dias.map((key, index)=>{
            res.push(
                <div className="row" key={`${index}`}>
                    <div className="col-md-5 col-sm-12" key={`${index}-per`}> 
                        <div className="form-group" key={`${index}-per2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`0-${key.id}`} id={key.id}>
                                {key.periodoPago}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-12" key={`${index}-dia`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.diasPagoPeriodo}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12" key={`${index}-del`} style={{textAlign: 'right'}}> 
                        <div className="form-group" key={`${index}-del2`}>
                            <IconButton aria-label="delete" size="small" onClick={e => alertDelete(key.id ? key.id : key.idLocal)} >
                                <ClearIcon style={{ color: "#4C638E" }}/>
                            </IconButton> 
                        </div>
                    </div>
                </div>
            );
            return null;
        });
        return res;
    }

    const canAddDia = (diaAjus) => { 
        if(!isNaN(diaAjus)){
            let result = 0;
            for (const key in dias) {
                result+=parseFloat(dias[key].diasPagoPeriodo);
            }  
            setCanAdd((diaAjus >=0) ? false : result>=parseFloat(diaAjus) ); 
        } 
    }

    const getDiasVacaciones = () => {
        let res=[]; 
        diasVacaciones.map((key, index)=>{
            res.push(
                <div className="row" key={`${index}`}>
                    <div className="col" key={`${index}-ini`}> 
                        <div className="form-group" key={`${index}-per2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`0-${key.id}`} id={key.id}>
                                {key.anioIni}
                            </label>
                        </div>
                    </div>
                    <div className="col" key={`${index}-fin`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.anioFin}
                            </label>
                        </div>
                    </div>
                    <div className="col" key={`${index}-dia1`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.diasSem1}
                            </label>
                        </div>
                    </div>
                    <div className="col" key={`${index}-dia2`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.diasSem2}
                            </label>
                        </div>
                    </div>
                    <div className="col-2" key={`${index}-del`} style={{ textAlign: 'right' }}> 
                        <div className="form-group" key={`${index}-del2`}>
                            <Button aria-label="delete" size="small" onClick={e => alertDelete(key.id ? key.id : key.idLocal)} >
                                <ClearIcon style={{ color: "#4C638E" }}/>
                            </Button> 
                        </div>
                    </div>
                </div>
            );
            return null;
        });
        return res;
    }

    const clearError = () =>{
        setErrorDias(null);
        setErrorPeriodos(null);
        setErrorPeriodosDias(null);
        setErrorDate(null);
        setTitleAlert(null);
    }

    return (
        <Fragment>
            {
                !elementSelect
                ?
                <div id="form-part-1" style={{ display: (active === -1) ? 'block' : 'none' }}>
                    <form id={'SelectTipoPolitica' } >
                        <SelectValidation title="Tipo" arrayOpciones={tipos} keyOpcion="nombre"
                            refValue={tipoRef} onChangeExecute={showForm}/>
                    </form>
                </div>
                :
                null
            }
        {/* FORM PARA AGUINALDO Y PRIMA VACACIONAL */}
        <div id="form-part-2" style={{ display: (active === 0 || active === 1) ? 'block' : 'none' }}>
            <form id={ elementSelect ? active === 0 ? 'EditarAguinaldo': 'EditarPrimaVacacional'  : active === 0 ? 'AgregarAguinaldo' : 'AgregarPrimaVacacional' } 
                  onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={ active === 0 ? feciniRef : feciniPrimaRef}
                            defaultValue={ elementSelect ? elementSelect.fechaIn : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={ active === 0 ? fecfinRef : fecfinPrimaRef }
                            defaultValue={ elementSelect ? elementSelect.fechaFi : '' }
                            optional />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title={ active === 0 ? "Días Aguinaldo por Año": "Días Prima Vacacional por Año" } type="diasAgino" placeholder="Escriba la Cantidad de Días" 
                            refValue={diasAguinaldoRef} defaultValue={ elementSelect ? active === 0 ? elementSelect.diasAguinaldoAnio :elementSelect.diasPrimaAnio : '' } required 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDiasAguiPoliticas'} maxLength={6} 
                            onChangeExecute={(value)=>{ if( isNaN(value) ) {setDiasAguinaldoSemestre(0)}else{ setDiasAguinaldoSemestre((parseFloat(value)/2).toFixed(2))}}}
                            onlyNumbers ='.' onBlurDecimal={2}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>{ active === 0 ? "Días Aguinaldo por Semestre": "Días Prima Vacacional por Semestre"}</label> 
                            <label className="form-control label-control" style={{marginTop:'0'}}>{ diasAguinaldoSemestre }</label>
                        </div>
                    </div>
                </div>
                {
                    active ===0 ?
                     <div className="row"> 
                        <div className="col-md-6 col-sm-12">
                            <InputValidation title="Días Bono por Año" type="diasAgino" placeholder="Escriba la Cantidad de Días de Bono por Año" 
                                refValue={diasBonoRef} defaultValue={ elementSelect ? elementSelect.diasBonoAnio : '' } required 
                                tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDiasBonoPoliticas'} maxLength={6} 
                                onChangeExecute={(value)=>{ if( isNaN(value) ) {setDiasBonoSemestre(0)}else{ setDiasBonoSemestre((parseFloat(value)/2).toFixed(2))}}}
                                onlyNumbers='.' onBlurDecimal={2}
                                />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Días Bono por Semestre</label> 
                                <label className="form-control label-control" style={{marginTop:'0'}}>{diasBonoSemestre}</label>
                            </div>
                        </div>
                    </div>
                    :null
                } 
                <div className="row"> 
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Pago 1er Semestre" arrayOpciones={periodosAgnio} keyOpcion="id"
                            refValue={pagoPrimerSemRef} defaultValue={ elementSelect ? elementSelect.periodoPagoSem1 : '' } required onChangeExecute={clearError}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Pago 2do Semestre" arrayOpciones={periodosAgnio} keyOpcion="id"
                            refValue={pagoSegundoSemRef} defaultValue={ elementSelect ? elementSelect.periodoPagoSem2 : '' } required onChangeExecute={clearError}/>
                    </div>
                </div>
                {errorDate}
                {errorPeriodos}
                <div className="row">
                    {
                        !elementSelect
                        ?
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={e => atras(e, -1)}>{ 'Atrás' } </Button>
                        </div>
                        :
                        null
                    } 
                    
                    <div className={ elementSelect ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'}  >
                        {(isLoading)
                        ?   <SpinnerOval/>
                        :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                        }
                    </div>
                </div>
            </form>
        </div>
        {/* FORM PARA DÍAS DE AJUSTE*/}
        <div id="form-part-3" style={{ display: (active === 2 ) ? 'block' : 'none' }}>
            <form id={ elementSelect ? 'EditarDiaAjuste' : 'AgregarDiaAjuste' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={feciniDiaAjusteRef}
                            defaultValue={ elementSelect ? elementSelect.fechaIn : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fecfinDiaAjusteRef}
                            defaultValue={ elementSelect ? elementSelect.fechaFi : '' }
                            optional />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col">
                        <InputValidation title={ "Días de Ajuste" } type="diasAginoMoreThanZero" placeholder="Escriba la cantidad de días de ajuste por año" 
                            refValue={diasAjusteRef} defaultValue={ elementSelect ? elementSelect.diasAjuste : '' } required 
                            onlyNumbers 
                            tooltipText={'Máximo 3 números'} tooltipName={'tooltipPeriodoDiasAjusPoliticas'} maxLength={3} onChangeExecute={canAddDia}/>
                    </div>
                    <div className="col">
                        <InputValidation title="Días Mínimos Laborados" type="diasAgino" placeholder="Escriba la cantidad de días mínimos laborados" 
                            refValue={diasMinLabRef} defaultValue={ elementSelect ? elementSelect.diasMinLab : '' } required 
                            onlyNumbers onChangeExecute={canAddDia}
                            tooltipText="Máximo 3 números" tooltipName="tooltipPeriodoDiasMinLab" maxLength={3} />
                    </div> 
                </div>
                <div className="row"> 
                    <div className="col-md-5 col-sm-12">
                        <SelectValidation title="Periodo Pago" arrayOpciones={periodosAgnio} keyOpcion="id"
                            refValue={periodoPagoRef} defaultValue={ elementSelect ? elementSelect.tipoDependId : '' } optional onChangeExecute={clearError}/>
                    </div>
                    <div className="col-md-5 col-sm-12">                        
                        <InputValidation title={ "Días a Pagar en el Periodo" } type="diasAgino" placeholder="Escriba la Cantidad de Días de Ajuste en el Periodo" 
                            refValue={diasAjustePeriodoRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDiasPagarDiasAjusPoliticas'} maxLength={6} optional onlyNumbers='.'/>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <div className="form-group" style={{paddingTop:'1.7rem', textAlign:'right'}}>
                            <Button variant="contained" className="btn-third" onClick={e =>addDias() } disabled={canAdd}>
                                <AddIcon/>
                            </Button>
                        </div>
                    </div>
                </div> 
                {getDiasAjuste()} 
                {errorDate}
                {errorDias}
                {errorPeriodosDias}
                <div className="row"> 
                    {
                        !elementSelect
                        ?
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={e => atras(e, -1)}>{ 'Atrás' } </Button>
                        </div>
                        :
                        null
                    }
                    <div className={ elementSelect ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'} >
                        {(isLoading)
                        ?   <SpinnerOval/>
                        :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                        }
                    </div>
                </div>
            </form>
        </div>
        {/* FORM PARA VACACIONES */}
        <div id="form-part-4" style={{ display: (active === 3 ) ? 'block' : 'none' }}>
            <form id={ elementSelect ? 'EditarVacaciones' : 'AgregarVacaciones' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={feciniVacacionesRef}
                            defaultValue={ elementSelect ? elementSelect.fechaIn : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fecfinVacacionesRef}
                            defaultValue={ elementSelect ? elementSelect.fechaFi : '' }
                            optional />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col">
                        <InputValidation title={ "De (Años)" } type="aginoDecimal5" placeholder="Escriba la Cantidad de Años" 
                            refValue={deAgniosRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDeAgniosVacPoliticas'} maxLength={6} onlyNumbers='.'/>
                    </div>
                    <div className="col">
                        <InputValidation title={ "Hasta (Años)" } type="aginoDecimal5" placeholder="Escriba la Cantidad de Años" 
                            refValue={hastaAgniosRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipHastaVacPoliticas'} maxLength={6} onlyNumbers='.'/>
                    </div>
                    <div className="col">
                        <InputValidation title={ "Días 1er Semestre" } type="diasAgino" placeholder="Escriba la Cantidad de Días" 
                            refValue={diasPrimerSemRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números'} tooltipName={'tooltipDiasPrimSemVacPoliticas'} maxLength={3} />
                    </div>
                    <div className="col">
                        <InputValidation title={ "Días 2do Semestre" } type="diasAgino" placeholder="Escriba la Cantidad de Días" 
                            refValue={diasSegundoSemRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números'} tooltipName={'tooltipDiasSegSemVacPoliticas'} maxLength={3} />
                    </div>
                    <div className="col-2">
                        <div className="form-group" style={{ paddingTop:'1.7rem', textAlign: 'right' }}>
                            <Button variant="contained" className="btn-third" onClick={addDiasVacaciones}>
                                <AddIcon/>
                            </Button>
                        </div>
                    </div>
                </div>
                {getDiasVacaciones()} 
                {errorDate}
                {errorPeriodosDias}
                <div className="row"> 
                    {
                        !elementSelect
                        ?
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={e => atras(e, -1)}>{ 'Atrás' } </Button>
                        </div>
                        :
                        null
                    }
                    <div className={ elementSelect ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'} >
                        {(isLoading)
                        ?   <SpinnerOval/>
                        :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                        }
                    </div>
                </div>
            </form>
        </div>
        {/* FORM PARA AUSENTISMOS */}
        {(active === 4) &&
            <FormAusentismos
                elementSelect={elementSelect} conceptosPercepciones={conceptosPercepciones} conceptosDeducciones={conceptosDeducciones} 
                ausentismos={ausentismos} percepcionesBase={percepcionesBase} setPercepcionesBase={setPercepcionesBase}
                fechaInicioAusentismoRef={fechaInicioAusentismoRef} fechaFinAusentismoRef={fechaFinAusentismoRef}
                conceptoAusentismoRef={conceptoAusentismoRef} ausentismoRef={ausentismoRef} cantidadAusentismoRef={cantidadAusentismoRef}
                mostrarAlertW={mostrarAlert} mostrarAlertWIncomplete={mostrarAlertIncomplete} setAlertSuccess={setAlertSuccess} setAlertError={setAlertError} setTextAlert={setTextAlert}
                atras={atras} enviar={enviar} actualizar={actualizar} isLoading={isLoading}
            />
        }
        <AlertWarning show={openAlert} SetopenALert={setOpenAlert} title={titleAlert} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={(deleteRow) ? (active === 2) ? deleteDias : deleteDiasVac: (elementSelect) ? actualizar : enviar}/>
        <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
        <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </Fragment>
    );
};

export default FormularioPoliticas;