import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { registro_actualizado, servicios_grupohit_org, verifique_conexion } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import EditPlazasModal from '../OrganigramaModal';
import TooltipComponent from '../../../../../../../../Services/Tooltip/TooltipComponent';
import EditIcon from '@material-ui/icons/Edit';
import AlertSyE from '../../../../../../../../Services/Alerts/AlertSyE';

const InfoPlaza = ({ parentNode, permisos, info, buscarPlaza }) => {

    const [openModalEdit, setOpenModalEdit] = useState({ state: false, type: null, data: {} });
    const [alertSettings, setAlertSettings] = useState({ type: '', title: '', text: '' });
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const abreModal = () => {
        const data = {
            id: info.id,
            plazaclave: info.plazaclave,
            nominaId: info.nomina.id,
            puesto: info.puesto.id,
            nivel: info.puesto.nvlId.id,
            plazadisponible: info.plazadisponible,
            plazaidsup: info.plazaidsup,
            plazasecuencia: info.plazasecuencia,
            idCia: info.presupuestal.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id,
            domicilio: info.domicilio.id,
            statusid: info.status.id,
            empleadoTitular: info.empleadoTitular
        }
        setOpenModalEdit({
            state: true,
            type: 'editBusquedaPlazas',
            data
        });
    };

    const openAlertAfterEdit = () => {
        setOpenAlertSyE(true);
        setOpenModalEdit({ state: false, type: null, data: {} });
    };

    const actualizar = (item) => {
        if(permisos.find(permiso => permiso.id === 2)) {
            const requestUpdate = { 
                areaid: info.areaid.id,
                plazaclave: item.plazaclave,
                plazanivel: info.plazanivel,
                plazasecuencia: item.plazasecuencia,
                plazadisponible: item.plazadisponible,
                plazaidsup: item.plazaidsup,
                puesto: item.puesto,
                domicilio: item.domicilio,
                statusId: item.statusId,
                nomina: item.nominaId,
            };
           
            if(item.empleadoTitular !== null) requestUpdate.emp = item.empleadoTitular
            
            axios.put(`${servicios_grupohit_org}/plaza/${item.id}`, requestUpdate, headersAuth())
            .then(response => {
                if(response.data.status === 200) {
                    setAlertSettings({ type: 'success', title: 'Petición Exitosa', text: registro_actualizado });
                    openAlertAfterEdit();
                    buscarPlaza(item.plazaclave);
                } else {
                    setAlertSettings({ type: 'error', title: 'Petición Fallida', text: response.data.msg });
                    openAlertAfterEdit();
                }
            })
            .catch(e => {
                setAlertSettings({ type: 'error', title: 'Petición Fallida', text: verifique_conexion });
                openAlertAfterEdit();
            });
        }
    };

    return (
        <Fragment>
            <div className="row">
                <hr/>
                <div className={info.plazadisponible ? 'col-sm-6 col-md-10' : 'col' }>
                    <div><label><strong>{(info.plazadisponible) ? 'Disponible' : 'No Disponible'}</strong></label></div>
                    <div><label><strong>Número Único de Plaza (NUP): </strong>{ info.plazaclave }</label></div>
                    <div><label><strong>Clave de Área: </strong>{ info.areaid?.areacve }</label></div>
                    <div><label><strong>Nombre de Área: </strong>{ info.areaid?.areanom }</label></div>
                    <div><label><strong>Clave de Puesto: </strong>{ info.puesto?.puestoCve }</label></div>
                    <div><label><strong>Nombre de Puesto: </strong>{ info.puesto?.puestoNom }</label></div>
                    <div><label><strong>Clave de Nivel Laboral: </strong>{ info.puesto?.nvlId?.nivlaboralClave }</label></div>
                    <div><label><strong>Nómina: </strong>{ `${info.nomina?.nominaClave} ${info.nomina?.nominaNombre}` }</label></div>
                    <div><label><strong>Titular de la Plaza: </strong>{ `${info.empleadoTitular ? `${info.empleadoTitular.empleadoclave} / ${(info.empleadoTitular.empleadopat) ? `${info.empleadoTitular.empleadopat} ` : ''}${(info.empleadoTitular.empleadomat) ? `${info.empleadoTitular.empleadomat} ` : ''}${info.empleadoTitular.empleadonom}` : '-'}` }</label></div>
                    <div><label><strong>Status: </strong>{ info.status.nombre.toUpperCase() }</label></div>
                </div>
                {(info.plazadisponible) &&
                    <div className="col-sm-6 col-md-2">
                        <TooltipComponent tooltipText="Actualizar Plaza">
                            <button className="btn-autocomplete btn-editPlazaModal" onClick={abreModal}><EditIcon fontSize="small"/></button>
                        </TooltipComponent>
                    </div>
                }
            </div>
            <EditPlazasModal
                parentNode={parentNode} minWidth="50%"
                modalIsOpen={openModalEdit} setModalIsOpen={setOpenModalEdit} actualizar={actualizar}
            />
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title={alertSettings.title} text={alertSettings.text} textbtnconfirm="Aceptar" type={alertSettings.type}/>
        </Fragment>
    );
}
 
export default InfoPlaza;