import React, { createRef } from 'react';
import { validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../../Services/Validation/InputValidation';
import Button from '@material-ui/core/Button';

const FormBusquedaPlaza = ({ buscarPlaza }) => {

    const nupRef = createRef('');

    const enviarDatos = e => {
        e.preventDefault();
        if(validarSubmit('FormBusquedaPlaza')) {
            const nup = nupRef.current?.value;
            buscarPlaza(nup);
        }
    };
    
    return (
        <form id="FormBusquedaPlaza" onSubmit={enviarDatos}>
            <InputValidation title="Número Único de Plaza (NUP)" type="clavePlaza" placeholder="Escriba el NUP"
                tooltipText="De 1 a 20 números, iniciando con un número del 1 al 9" tooltipName="plazaCve"
                maxLength={20} refValue={nupRef} onlyNumbers required
            />
            <Button variant="contained" className="btn-color" type="submit">Buscar</Button>
        </form>
    );
}
 
export default FormBusquedaPlaza;