import React, { useState } from 'react';
import ModalService from '../../../../../ModalService/ModalService';
import AsignacionNomina from './AsignacionNomina';

const ModalAsignacionNomina = ({ openNomina, setOpenNomina, informacion, idModulo, accesosNominas, setAccesosNominas }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenNomina( false );
        setError( null );
    }

    return (
        <ModalService title="Asignar Nómina" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openNomina } onClose={ () => cerrarModal() } >
                <AsignacionNomina
                    error={error}
                    informacion={informacion}

                    accesosNominas={accesosNominas}
                    setAccesosNominas={setAccesosNominas}
                />
        </ModalService>
    );
};

export default ModalAsignacionNomina;