import React from 'react';

const InputText = (props) => {

    const onChange = event => {
        props.onFilterChanged(props.columnDef.tableData.id, event.target.value);
    };

    let styles = {
        float: 'right',
        width: '5rem'
    };

    if(props.dateTable) styles.width = '100%';

    return (
        <div className="MuiFormControl-root MuiTextField-root" style={styles}>
            <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" title="Filtrar por Columna">
                        <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                    </svg>
                </div>
                <input
                    aria-invalid="false" type="text"
                    className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart"
                    onChange={(event) => onChange(event)}
                />
            </div>
        </div>
    );
}
 
export default InputText;