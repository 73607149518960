import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, movimientos_pendientes } from '../../../../../../../Constants/Constants';
import DataTableMovimientos from '../../Variables/DataTableMovimientos/DataTableMovimientos';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonMovimientosFijosEmpleado } from '../../../../Helpers/JsonToOneLevel';

const PendientesEmpleado = ({ empleado, setEmpleado, idModulo }) => {
    const [ pendientes, setPendientes ] = useState( null );
    useEffect(() => {
        axiosPendientesEmpleado();
        // eslint-disable-next-line
    }, []);

    const axiosPendientesEmpleado = () => {
        axios.get( `${servicios_grupohit_rh}/movimientos/pendiente/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setPendientes( ArrayJsonMovimientosFijosEmpleado(respuesta.data.dataResponse) );
                        break;
                    case 404: //No hay datos disponibles
                        setPendientes( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <React.Fragment>
            { pendientes ?
                <DataTableMovimientos
                    idModulo={idModulo+'secondary'}
                    title={movimientos_pendientes}
                    empleado={empleado}
                    setEmpleado={setEmpleado}
                    columns={[
                        { field: "nomina.nomClave", title: "Clave Concepto" },
                        { field: "nomina.nomIndicador", title: "Nombre Concepto" },
                        { field: "cantidad", title: "Cantidad" },
                        { field: "monto", title: "Monto" },
                        { field: "acumulado.acumuladoNombre", title: "Tipo de Acumulado" },
                    ]}
                    hideIconAdd hideIconEdit
                    data={ pendientes }
                    informacionAdicional={{ empleado }}/>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default PendientesEmpleado;