import React, { useState } from 'react'; 
import ModalService from '../../../ModalService/ModalService'; 
import FormularioConfigBasesIndGral from './FormularioConfigBasesIndGral';
import axios from 'axios';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const ModalConfigBaseIndicadoresGral = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo, elementSelect }) => {
    const [ error, setError ] = useState( null );
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ textAlert, setTextAlert ] = useState(''); 
    const [ alertError, setAlertError ] = useState( false );
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarConfigBaseIndicadoresGral = dataConfigBase => {
       agregar(dataConfigBase); 
       setOpenAdd( false );
    }

    const mostrarError = msg => {
        setTextAlert(msg);
        setAlertError( true );
    };
    
    const enviarConfigBaseIndicadoresGralExiste= dataConfigBase => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_nomina}/bases/indicadores/`, {basesIndicadores:dataConfigBase} , headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(null);
                    setOpenAdd( false );
                    break;
                default:
                    mostrarError(res.data.msg);
                    break;
            }
        })
        .catch(error => { 
            mostrarError(verifique_conexion);
        })
        .finally(() => setIsLoading(false));
     }

    const actualizarImpuestoNomina = dataConfigBase => {
        setIsLoading(true);
        axios.put( `${servicios_grupohit_nomina}/bases/indicadores/`, dataConfigBase, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar(null);
                        setOpenAdd( false );
                        break;
                    default:
                        mostrarError(respuesta.data.msg);
                        break;
                }
            })
            .catch( error => {
                mostrarError(verifique_conexion);
            })
            .finally(() => setIsLoading(false));
    }  

    return (
        <ModalService title="Configuración de Bases" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioConfigBasesIndGral
                    error={error}
                    informacion={informacionAdicional}
                    enviarConfigBaseIndicadoresGral={enviarConfigBaseIndicadoresGral}
                    elementSelect={elementSelect}
                    actualizarImpuestoNomina={actualizarImpuestoNomina}
                    enviarConfigBaseIndicadoresGralExiste={enviarConfigBaseIndicadoresGralExiste}
                    mostrarError={mostrarError}
                    isLoading={isLoading}
                />
                <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
                <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            
        </ModalService>
        
            
    );
};

export default ModalConfigBaseIndicadoresGral;