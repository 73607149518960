import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, verifique_conexion } from '../../../../Constants/Constants';
import { headersAuth } from '../../../../Services/AuthService';

import ModalTerminosPrivacidad from './ModalTerminosPrivacidad';
import AlertSyE from '../../../../Services/Alerts/AlertSyE';

const TerminosPrivacidad = ({ modal, setModal, setTyC, closeSession, setModalPassword }) => {

    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const cambiarTerminosCondiciones = () => {
        setModal( false );
        axios.post( `${servicios_grupohit_url}/agree/terms/and/conditions`, null, headersAuth())
            .then( res => {
                const auxUsuarioJson = JSON.parse(localStorage.getItem("IGSESSID"));
                auxUsuarioJson.tyc = "true";
                const usuarioAceptadoTyC = JSON.stringify(auxUsuarioJson);
                localStorage.setItem("IGSESSID", usuarioAceptadoTyC);
                setTyC("true");
            })
            .catch( error => setOpenAlertSyE(true))
        setModalPassword( true );
    }

    const cerrarCession = () => {
        setModal( false );
        closeSession();
    }

    return (
        <Fragment>
            <ModalTerminosPrivacidad open={modal} setOpen={setModal} actionAcept={cambiarTerminosCondiciones} actionCancel={cerrarCession}/>
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={verifique_conexion} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}

export default TerminosPrivacidad;
