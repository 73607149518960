import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonMovimientosRelacionadosEmpleado } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioRelacionados from './FormularioRelacionados';

const ModalEditRelacionados = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, idModulo }) => {
    const [ error, setError ] = useState( null );
    const [isLoading, setIsLoading] = useState(false);
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarRelacionado = ( relacionado, indicadoresDelete ) => {
        axios.put( `${servicios_grupohit_rh}/movimientos/relacionado/`, relacionado, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        editar( JsonMovimientosRelacionadosEmpleado(respuesta.data.dataResponse[0], informacionAdicional.pensiones) );
                        eliminarIndicadores(indicadoresDelete);
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    const eliminarIndicadores = async ( indicadores ) => {
        setIsLoading(true);
        await axios.post( `${servicios_grupohit_rh}/indicador/movimientos/delete/all/`, {ids: indicadores}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //axiosIndicadores();
                        break;
                    default:
                        //errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Movimiento Relacionado" parentNode={idModulo } minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioRelacionados
                    error={error}
                    elementSelect={elementSelect}
                    informacion={informacionAdicional}
                    actualizarRelacionado={actualizarRelacionado}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalEditRelacionados;