import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, compararFechas, hoyMilisegundos } from '../../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import AlertForm from '../../../../../../../Services/AlertForm';

import { ArrayJsonSubordinadosService } from './HelperRestricciones';

import Tooltip from '@material-ui/core/Tooltip';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

const RestringirModulo = ({ error, enviarRestriccion, moduloID, usuarios, restriccionesModulo, restriccionesSinModulo }) => {
    const elementSelect = restriccionesModulo.length > 0 && restriccionesModulo.length === usuarios.length ? { fecini: restriccionesModulo[0].fecini, fecfin: restriccionesModulo[0].fecfin } : null;
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ errorDate, setErrorDate ] = useState( null );
    const feciniRef = createRef('');
    const fecfinRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        validarRestriccion();
    };

    const actualizar = () => {
        validarRestriccion();
    };

    const validarRestriccion = () => {
        if( validarSubmit( 'EnviarRestriccion' ) ){
            const fecini = inputDateToLong( feciniRef.current.value );
            const fecfin = inputDateToLong( fecfinRef.current.value );
            if( fecfin >= hoyMilisegundos() ){
                if( compararFechas(fecini, fecfin) ){
                    setErrorDate( null );
                    if( usuarios.length > 0 ){
                        enviarRestriccion( restriccionesSinModulo.concat(ArrayJsonSubordinadosService(usuarios, moduloID, fecini, fecfin)) );
                    }else{
                        setErrorDate( <AlertForm message="Asegúrate de contar con al menos un usuario" /> );
                    }
                }else{
                    setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                }
            }else{
                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual que la Fecha Actual" /> );
            }
        }
    }

    const liberarRestriccion = () => {
        enviarRestriccion( restriccionesSinModulo );
    }

    return (
        <React.Fragment>
            <form id="EnviarRestriccion" onSubmit={ elementSelect ? mostrarAlert : enviar } className="mt-1">
                <div className="accesosContenido">
                    <div className="restriccionesIzquierdo">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <InputDateValidation title="Fecha Inicial" refValue={feciniRef} required
                                    defaultValue={ elementSelect ? longDateToInput(elementSelect.fecini) : '' } />
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <InputDateValidation title="Fecha Final" refValue={fecfinRef} required
                                    defaultValue={ elementSelect ? longDateToInput(elementSelect.fecfin) : '' } />
                            </div>
                        </div>
                    </div>

                    { !elementSelect ?
                        <div className="restriccionesDerecho1">
                            <Tooltip title="Agregar Reestricción" placement="top">
                                <Button variant="contained" className="btn-color btn-rest" type="submit"> <Lock/> </Button>
                            </Tooltip>
                        </div>
                        :
                        <div className="restriccionesDerecho2">
                            <Tooltip title="Actualizar Reestricción" placement="top">
                                <Button variant="contained" className="btn-color btn-rest" type="submit"> <Lock/> </Button>
                            </Tooltip>
                            <Tooltip title="Liberar Reestricción" placement="top">
                                <Button variant="contained" className="btn-third btn-rest" onClick={ () => setOpenDelete(true) }> <LockOpen/> </Button>
                            </Tooltip>
                        </div>
                    }
                </div>

                {error}
                {errorDate}
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={"La restricción será actualizada"} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
            <AlertWarning show={openDelete} SetopenALert={setOpenDelete} text={"La restricción será eliminada"} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={liberarRestriccion} />
        </React.Fragment>
    );
};

export default RestringirModulo;