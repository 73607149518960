import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import {registro_sera_actualizado, servicios_grupohit_catalogo, verifique_conexion} from '../../../../../../Constants/Constants';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import Axios from 'axios';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
});

const FormContratosRelLab = ({ error, setError, enviarContratosRelLab, elementSelect, actualizarContratosRelLab, isLoading }) => {

    const [alertWarning, setAlertWarning] = useState(false);
    const [ tiposContrato, setTiposContrato ] = useState( null );
    const [relacionLaboral, setRelacionLaboral] = useState(null);
    const [fecha, setFecha] = useState((elementSelect && elementSelect.contratoSaoFechas.toLowerCase() === 'no') ? false : true);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false); 
    
    const nombreRef = createRef('');
    const tipoContratolRef = createRef(''); 
    const relLaboralRef = createRef('');

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}/relacionlaboral/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                        setRelacionLaboral(res.data.dataResponse);
                    break;
                case 404:
                    setRelacionLaboral([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        Axios.get( `${servicios_grupohit_catalogo}/contrato/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposContrato( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setTiposContrato([]);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarContratosRelLab')) {
            const contratoSaoNom = nombreRef.current.value;
            const tipoContrato = parseInt(tipoContratolRef.current.value);
            const relacionLaboral = parseInt(relLaboralRef.current.value); 
            const contratoSaoFechas = fecha;
            enviarContratosRelLab({ contratoSaoNom, tipoContrato, relacionLaboral, contratoSaoFechas });
        }
    };

    const actualizar =()=> {
        if(validarSubmit('EditarContratosRelLab')) {
            const contratoSaoNom = nombreRef.current.value;
            const tipoContrato = parseInt(tipoContratolRef.current.value);
            const relacionLaboral = parseInt(relLaboralRef.current.value); 
            const contratoSaoFechas = fecha;
            setError(null);
            actualizarContratosRelLab({ contratoSaoNom, tipoContrato, relacionLaboral, contratoSaoFechas }, elementSelect.id);
            setAlertWarning(false);
        }
    };
    const alert = e =>{
        e.preventDefault();
        setAlertWarning(true);
    };
    
    return (  
        <form id={ elementSelect ? 'EditarContratosRelLab' : 'AgregarContratosRelLab' } onSubmit={ elementSelect ? alert : enviar }>
           <div className="row">
                <div className="col-md-12 col-sm-12">
                    <InputValidation title="Nombre del Contrato" type="nombreContrato" placeholder="Escriba el nombre del Contrato"
                        tooltipText={`Máximo 100 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipNomContratoRelLab'} maxLength={100}
                        refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.contratoSaoNom : '' } required/>
                </div>
            </div>
 
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    {   
                        tiposContrato
                        ?
                        <SelectValidation title="Tipo de Contrato" arrayOpciones={tiposContrato} refValue={tipoContratolRef} keyOpcion="tipoContratoDesc" 
                        defaultValue={ elementSelect ? elementSelect.tipoContratoId : ''} required/> 
                        :null
                    } 
                </div>
                <div className="col-md-6 col-sm-12">
                    {   
                        relacionLaboral
                        ?
                        <SelectValidation title="Tipo de Relación Laboral" arrayOpciones={relacionLaboral} refValue={relLaboralRef} keyOpcion="nombre" 
                        defaultValue={ elementSelect ? elementSelect.relacionLaboralId : ''} required/>
                        :null
                    } 
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12 form-group">
                    <label>¿Este contrato es permanente o temporal?</label>
                </div>
                <div className="col-md-6 col-sm-12 form-group">
                    <div className="form-check form-check-inline">
                        <MuiThemeProvider theme={theme}> 
                            <Radio checked={!fecha} onChange={() => setFecha(!fecha)} color="secondary" /> 
                        </MuiThemeProvider>Permanente
                    </div>
                    <div className="form-check form-check-inline">
                        <MuiThemeProvider theme={theme}> 
                            <Radio checked={fecha} onChange={() => setFecha(!fecha)} color="secondary" /> 
                        </MuiThemeProvider>Temporal
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col'>
                {isLoading
                ?   <SpinnerOval/> 
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
                </div>
            </div>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar} />
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </form>    
    );
}

export default FormContratosRelLab;