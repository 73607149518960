import React, { createRef, useState, Fragment, useEffect } from 'react';
import {Button } from '@material-ui/core';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado, servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../Constants/Constants';
import Axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const FormularioTablasInpuestos = ({ error, setError, enviarTablaImpuestos, elementSelect, actualizarTablaImpuestos }) => {
    
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const tipoRef = createRef(''); 
    const fechaInicialRef = createRef('');
    const fechaFinalRef = createRef('');
    const enviar = e => {
        e.preventDefault(); 
        if(validarSubmit('AgregarTablaImpuesto')) {
            const periodoImpto = parseInt(tipoRef.current.value);
            const fechaIni = inputDateToLong(fechaInicialRef.current.value);
            const fechaFin = fechaFinalRef.current.value!=='' ? inputDateToLong(fechaFinalRef.current.value) : null; 
            if(fechaFin===null){
                enviarTablaImpuestos( { periodoImpto, fechaIni} );
            }else{
                enviarTablaImpuestos( { periodoImpto, fechaIni, fechaFin } );
            }     
        }
    }; 
    
    const actualizar = () => {
        if(validarSubmit('EditarTablaImpuesto')) { 
            const fechaFin = inputDateToLong(fechaFinalRef.current.value); 
            actualizarTablaImpuestos( fechaFin ); 
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    }; 
    
    const [tipos, setTipos] = useState(null);
     
    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}s/tipo/periodo/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTipos(res.data.dataResponse);
                    break;
                case 404:
                    setTipos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);
    
    return ( 
        <Fragment> 
        <form id={ elementSelect ? 'EditarTablaImpuesto' : 'AgregarTablaImpuesto'} onSubmit={ elementSelect ? mostrarAlert : enviar  }> 
            {

            elementSelect 
            ?
            <div className="row"> 
                <div className="col-md-12 col-sm-12">
                    <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} required
                        defaultValue={ elementSelect ? elementSelect.fechaFinInp : ''  } /> 
                </div>
            </div> 
            :
            <>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    {
                        tipos
                        ?   <SelectValidation title="Tipo" arrayOpciones={tipos} keyOpcion="periodoNombre" refValue={tipoRef} 
                                defaultValue={elementSelect ? elementSelect.zonaId : ''} required/>
                        :   null
                    } 
                </div> 
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12">  
                    <InputDateValidation title="Fecha Inicial" refValue={fechaInicialRef} required
                        defaultValue={ elementSelect ? elementSelect.fechaIniInp : '' } />
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} optional
                        defaultValue={ elementSelect ? elementSelect.fechaFinInp : ''  } /> 
                </div>
            </div>
            </>
            }
            {error} 
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Guardar' : 'Agregar' }</Button> 
        </form>
        <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
export default FormularioTablasInpuestos;
