import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosInactivos } from '../../../../../../Redux/actions/EmpleadosActions';
import { reingresos_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import ModalPagosPendientes from '../../RecursosHumanos/Empleados/ModalPagosPendientes';

const Reingresos = ({ id, permisos, empleadosInactivos, obtenerEmpleadosInactivos, pagosPendientes }) => {

    const [textAlert, setTextAlert] = useState('');
    const [openAlertError, setOpenAlertError] = useState(false);
    const [openAlertS, setOpenAlertS] = useState(false);
    const [openModalPagosP, setOpenModalPagosP] = useState(false);

    const { empPagosPendientes, retienePagos} = pagosPendientes;

    useEffect(() => {
        //empleados bajas
        obtenerEmpleadosInactivos(error => {
            setTextAlert(error);
            setOpenAlertError(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (empPagosPendientes !== null)
        ? setOpenModalPagosP(true)
        : setOpenModalPagosP(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empPagosPendientes]);

    const error = msg => {
        setTextAlert(msg);
        setOpenAlertError(true);
    };

    const success = msg => {
        setTextAlert(msg);
        setOpenAlertS(true);
    };

    return (
        <div id={`modulo${id}`}>
            {   
                empleadosInactivos
                ?   <DataTableService
                        id={id}
                        title={reingresos_}
                        columns={[
                            { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                            { field: "nombreCompleto", title: "Nombre" },
                            { field: "empleadoRfc", title: "RFC" },
                            { field: "puestoCve", title: "Clave Puesto" },
                            { field: "puestoNom", title: "Puesto" },
                            { field: "nominaNombre", title: "Nómina" },
                            { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                            { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                        ]}
                        data={empleadosInactivos}
                        customOptionsTable={{ filtering: true }}
                        hideIconEdit hideIconDelete 
                        showIconRepeatOne
                        showIconSettingsBackupRestore
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
            <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <AlertError show={openAlertS} setOpenAlertSyE={setOpenAlertS} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            {empPagosPendientes &&
                <ModalPagosPendientes nombreModulo={id} elementSelect={empPagosPendientes} retienePagos={retienePagos} openModal={openModalPagosP} setOpenModal={setOpenModalPagosP} successTable={success} errorTable={error}/> 
            }
        </div>
    );
}
 
const mapStateToProps = (state) => ({
  empleadosInactivos: state.EmpleadosReducer.empleadosInactivos,
  pagosPendientes: state.EmpleadosReducer.pagosPendientes,
});

const mapDispatchToProps = { obtenerEmpleadosInactivos };

export default connect(mapStateToProps, mapDispatchToProps)(Reingresos);