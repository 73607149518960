import React from 'react';

const TitleCard = ({ title }) => {
    return (
            <div className="col-12 d-flex align-items-end margintb">
                <h4 style={{ paddingRight: '30px', fontWeight: 'bold' }}>
                    { title }
                </h4>
            </div>
    );
}
 
export default TitleCard;