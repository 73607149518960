import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from './../../../ModalService/ModalService';
import FormularioTipoRelacion from './FormularioTipoRelacion';

const ModalAddTipoRelacion = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo}) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenAdd(false);
    };

    const enviarTipoRelacion = tipoRelacion => {
        setIsLoading(true);
        const info = tipoRelacion;
        Axios.post( `${servicios_grupohit_catalogo}/relacionlaboral/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService title="Agregar Tipo de Relación Laboral" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormularioTipoRelacion
                        enviarTipoRelacion={enviarTipoRelacion}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddTipoRelacion;