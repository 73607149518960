import React from 'react';
import List from '@material-ui/core/List';
import Modulo from './Modulo';

function ModulosBlock({ modulos }) {
    return(
        <List className="my-second-step">
            { modulos.map((mod, index) => (
                <Modulo key={index} superModulo={mod}/>
            ))}
        </List>
    );
}

export default ModulosBlock;