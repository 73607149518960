import React, { useState, useEffect, Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ThousandsSeparator from '../../../../Helpers/ThousandsSeparator';
import BlueCheckboxRadioStyles from '../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import AlertError from '../../../../../../../Services/Alerts/AlertSyE';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';

const SueldoCheckbox = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const CheckIndicadoresSueldo = props => {
    
    const {
        modulo, elementSelect, monto, setMonto, montoEdit, setMontoEdit, indicador, montoIndicador, setIndicadoresSelected, indicadoresSelected, disabledPuesto, mostrar,
        indicadoresSueldoNombramientoActivo, indicadoresSueldoActivo, indicadoresNombramientoActivo, contratoRef, contratos, puestoSeleccionado, setErrorConceptoPago
    } = props;

    const [checked, setChecked] = useState(true);

    const [openAlertWarning, setOpenAlertWarning] = useState(false);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [textAlertError, setTextAlertError] = useState('');

    useEffect(() => {
        //desactiva los conceptos que no tiene activos el empleado, en editar empleado
        if(elementSelect && disabledPuesto && indicador.indicadorId && (modulo === 30)) {
            const indicadorFound = indicadoresSelected.find(i => i === indicador.indicadorId);
            if(!indicadorFound) {
                setChecked(false);
            } else {
                setChecked(true)
            }
            return;
        }

        if(elementSelect && !disabledPuesto) {
            if(indicador.defecto !== true) {
                setChecked(false);
            } else {
                setChecked(true)
            }
            return;
        }

        //desactiva los conceptos que no tienen la propiedad defecto activa,
        //en agregar empleado y reingresos
        if((!elementSelect) || modulo === 32) {
            if(indicador.defecto !== true) {
                setChecked(false);
            } else {
                setChecked(true)
            }
            return;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const activarCheck = () => {
        switch (indicador.naturaleza.id) {
            case 1:
                if(modulo === 30 && elementSelect) {
                    if(disabledPuesto) {
                        setMontoEdit(montoEdit + parseFloat(montoIndicador));
                    } else {
                        setMonto(monto + parseFloat(montoIndicador));
                    }
                } else {
                    setMonto(monto + parseFloat(montoIndicador));
                }
                break;
            case 2:
                if(modulo === 30 && elementSelect) {
                    if(disabledPuesto) {
                        setMontoEdit(montoEdit - parseFloat(montoIndicador));
                    } else {
                        setMonto(monto - parseFloat(montoIndicador));
                    }
                } else {
                    setMonto(monto - parseFloat(montoIndicador));
                }
                break;
            default:
                return;
        }
        const newIndicadores = indicadoresSelected.concat([indicador.indicadorId]);
        setIndicadoresSelected(newIndicadores);
        setChecked(!checked);
    };

    const handleChange = () => {
        if(checked === true) {
            //aquí entra cuando se desactiva el checkbox
            //valida si hay un contrato permanente seleccionado y sí, se ha quitado la seleccion de un
            //indicador con la variable causa permanente en true, se limpia el campo contrato
            //Solo puede haber un inidcador con CausaNombramiento true
            const contratoSeleccionado = contratos.find(c => c.id === parseInt(contratoRef.current?.value));
            if((indicador.nombramiento === true) & (contratoSeleccionado?.contratoSaoFechas === false)) {
               contratoRef.current.value = '';
            }
            switch (indicador.naturaleza.id) {
                case 1:
                    if(modulo === 30 && elementSelect) {
                        if(disabledPuesto) {
                            setMontoEdit(montoEdit - parseFloat(montoIndicador));
                        } else {
                            setMonto(monto - parseFloat(montoIndicador));
                        }
                    } else {
                        setMonto(monto - parseFloat(montoIndicador));
                    }
                    break;
                case 2:
                    if(modulo === 30 && elementSelect) {
                        if(disabledPuesto) {
                            setMontoEdit(montoEdit + parseFloat(montoIndicador));
                        } else {
                            setMonto(monto + parseFloat(montoIndicador));
                        }
                    } else {
                        setMonto(monto + parseFloat(montoIndicador));
                    }
                    break;
                default:
                    return;
            }
            const newIndicadores = indicadoresSelected.filter(i => i !== indicador.indicadorId);
            setIndicadoresSelected(newIndicadores);
            setChecked(false);
        } else {
            //aquí entra cuando se activa el checkbox.
            setErrorConceptoPago(null);
            //valida si hay un contrato temporal seleccionado y sí, se ha activado la seleccion de un 
            //indicadors con la variable causa permanente en true, se limpia el campo contrato
            //Solo puede haber un inidcador con CausaNombramiento true
            const contratoSeleccionado = contratos.find(c => c.id === parseInt(contratoRef.current?.value));
            if((indicador.nombramiento === true) & (contratoSeleccionado?.contratoSaoFechas === true)) {
               contratoRef.current.value = '';
            }
            const existenSueldoNombramientoSeleccionados = indicadoresSueldoNombramientoActivo.find(i => indicadoresSelected.indexOf(i.indicadorid.indicadorId) > -1);
            if(existenSueldoNombramientoSeleccionados && (indicador.sueldo === true) && (indicador.nombramiento === true)) {
                //si ya hay un concepto seleccionado con nombramiento y sueldo activos, muestra alerta para mencionar que no se puede seleccionar dos conceptos con esas características
                setTextAlertError('No está permitido asignar dos conceptos que tengan prendidas las variables "Indicador de Sueldo" y "Causa Nombramiento" simultáneamente.');
                setOpenAlertError(true);
                return;
            }

            const existenNombramientoSeleccionados = indicadoresNombramientoActivo.find(i => indicadoresSelected.indexOf(i.indicadorid.indicadorId) > -1);
            if(existenNombramientoSeleccionados && (indicador.nombramiento === true)) {
                //si ya hay un concepto seleccionado con nombramiento activo, muestra alerta para mencionar que no se puede seleccionar dos conceptos con esas características
                setTextAlertError('No está permitido asignar dos conceptos que tengan prendida la variable "Causa Nombramiento" simultáneamente.');
                setOpenAlertError(true);
                return;
            }

            if((puestoSeleccionado.nombramiento === true) & (indicador.nombramiento === true)) {
                //si ya hay un concepto seleccionado con sueldo activos, muestra alerta para preguntar si está seguro de marcarlo
                setTextAlertError('No está permitido asignar un concepto que tenga prendida la variable "Causa Nombramiento" a un empleado que tiene asignado un puesto que tiene prendida la variable "Causa Nombramiento".');
                setOpenAlertError(true);
                return;
            }

            const existenSueldoSeleccionados = indicadoresSueldoActivo.find(i => indicadoresSelected.indexOf(i.indicadorid.indicadorId) > -1);
            if(existenSueldoSeleccionados && (indicador.sueldo === true)) {
                //si ya hay un concepto seleccionado con sueldo activos, muestra alerta para preguntar si está seguro de marcarlo
                setOpenAlertWarning(true);
                return;
            }
                
            //si no hay un concepto seleccionado con nombramiento y sueldo activos, se puede seleccionar,
            //no hay un concepto seleccionado con nombramiento activo, se puede seleccionar,
            //no hay un concepto seleccionado con sueldo activo, se selecciona sin preguntar,
            //si el puesto y concepto seleccionado no tienen activo causa nombramiento (solo puede tenerlo activo uno de los dos)
            activarCheck();
        }
    };

    return (
        <Fragment>
            <div className="row" style={{ display: (!mostrar) ? 'none' : '' }}>
                <div className="col-1 col-md-1 col-sm-1">
                    <SueldoCheckbox checked={checked} onChange={handleChange} name="checked"/>
                </div>
                <div className="col-8 col-md-8 col-sm-8">
                    <div><label><strong>Clave: </strong>{ indicador ? `${indicador.indicadorClave} - ${indicador.naturaleza.id === 1 ? 'P ' : ' '}${indicador.naturaleza.id === 2 ? 'D ' : ' '}` : ' ' }{(indicador.nombramiento === true) ? <span style={{ color: '#F25A5A' }}>(Causa Nombramiento)</span> : ''}</label></div>
                    <div><label><strong>Nombre: </strong>{ indicador ? indicador.indicadorNom : '' }</label></div>
                </div>
                <div className="col-3 col-md-3 col-sm-3" style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <label style={{ marginRight: '22%' }}>
                            <strong>{ montoIndicador ? `$ ${ThousandsSeparator(parseFloat(montoIndicador).toFixed(2))}` : '' }</strong>
                        </label>
                    </div>
                </div>
            </div>
            <hr className="solidBlack" style={{ display: (!mostrar) ? 'none' : '' }}/>
            <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title={textAlertError} text="" textbtnconfirm="Aceptar" type="error"/>
            <AlertWarning show={openAlertWarning} SetopenALert={setOpenAlertWarning} text="Ya existe un concepto asignado para el pago de sueldo. ¿Desea conservar ambos conceptos?"  textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={activarCheck}/>
        </Fragment>
    );
}
 
export default CheckIndicadoresSueldo;