import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_guardado, verifique_conexion, servicios_grupohit_catalogo } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonPrestamos2 } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioPrestamos from './FormularioPrestamos';

const ModalAddPrestamos = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo, dataTable }) => {
    
    const [ error, setError ] = useState( null );
    const [minimoLiquidez, setMinimoLiquidez] = useState(true);
    const [montoMinimoLiquidez, setMontoMinimoLiquidez] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { empleado, indicadoresNomina, montoLiquido, setMontoLiquido } = informacionAdicional;

    useEffect(() => {
        axios.get(`${servicios_grupohit_rh}/empleado/liquidez/${empleado.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setMinimoLiquidez(res.data.dataResponse.minimoLiquidez);
                    break;
                default:
                    errorTable(res.data.msg)
                    break;
            }
        })
        .catch(error => {
            errorTable(verifique_conexion);
            console.log(error);
        });

        axios.get(`${servicios_grupohit_catalogo}/montominimo/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setMontoMinimoLiquidez(res.data.dataResponse[0].montominimo);
                    break;
                default:
                    errorTable(res.data.msg)
                    break;
            }
        })
        .catch(error => {
            errorTable(verifique_conexion);
            console.log(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarPrestamo = ( prestamo ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_rh}/empleado/prestamos/`, prestamo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setMontoLiquido(montoLiquido-respuesta.data.dataResponse.empprestImporte);
                        agregar( JsonPrestamos2(respuesta.data.dataResponse, indicadoresNomina) );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                console.log(error);
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Préstamo" parentNode={nombreModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioPrestamos
                    error={error}
                    empleadoId={empleado.id}
                    informacion={informacionAdicional}
                    enviarPrestamo={enviarPrestamo}
                    montoLiquido={montoLiquido}
                    minimoLiquidez={minimoLiquidez}
                    montoMinimoLiquidez={montoMinimoLiquidez}
                    setMinimoLiquidez={setMinimoLiquidez}
                    dataTable={dataTable}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddPrestamos;