import React, { useState, useEffect, Fragment, createRef } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { no_hay_cambios, registro_sera_actualizado, servicios_grupohit_admon, verifique_conexion } from '../../../../../../Constants/Constants';
import ModalAddFaltante from './ModalAddFaltante';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormProyectos = ({ openLoading, setOpenLoading, error, setError, enviarProyecto, elementSelect, actualizarProyecto, agregar, successTable, errorTable, setOpenAddFirst, idModulo }) => {

    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
    const [uResponsable, setUResponsable] = useState([]);
    const [uEjecutora, setUEjecutora] = useState([]);
    const [programa, setPrograma] = useState([]);
    const [subprograma, setSubprograma] = useState([]);
    const [pAccion, setPAccion] = useState([]);
    const [oAccion, setOAccion] = useState([]);
    const [selected, setSelected] = useState({ uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
    const [disabled, setDisabled] = useState({ uEjecutora: true, programa: true, subprograma: true, pAccion: true, oAccion: true, cvePresupuestal: true });
    const [edit, setEdit] = useState({ uEjecutora: false, programa: false, subprograma: false, pAccion: false, oAccion: false });
    const [openAdd, setOpenAdd] = useState(false);
    const [info, setInfo] = useState({});
    const [isLoadingUE, setIsLoadingUE] = useState(false);
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [isLoadingSP, setIsLoadingSP] = useState(false);
    const [isLoadingPA, setIsLoadingPA] = useState(false);
    const [isLoadingOA, setIsLoadingOA] = useState(false);
    const [openAlertW, setOpenAlertW] = useState(false);
    const [editType, setEditType] = useState('');

    const nombreUEjecutoraRef = createRef('');
    const nombreProgramaRef = createRef('');
    const nombreSubprogramaRef = createRef('');
    const nombrePAccionRef = createRef('');
    const nombreOAccionRef = createRef('');
    const clavePresupuestalClaveRef = createRef('');
    const clavePresupuestalNombreRef = createRef('');

    const { uEjecutora: editUEjecutora, programa: editPrograma, subprograma: editSubprograma, pAccion: editPAccion, oAccion: editOAccion } = edit;

    useEffect(() => {
        if(enviarProyecto) {
            axios.get(`${servicios_grupohit_admon}/compania`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setUResponsable(res.data.dataResponse);
                        break;
                    case 404:
                        setUResponsable([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable('error'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validarEditUEjecutora = () => {
        const valueNombreUE = nombreUEjecutoraRef.current.value;
        if(valueNombreUE !== elementSelect.udejecutoranom) {
            return true;
        }
        return false;
    };

    const validarEditPrograma = () => {
        const valueNombreP = nombreProgramaRef.current.value;
        if(valueNombreP !== elementSelect.programanom) {
            return true;
        }
        return false;
    };

    const validarEditSubprograma = () => {
        const valueNombreSP = nombreSubprogramaRef.current.value;
        if(valueNombreSP !== elementSelect.subprogramanom) {
            return true;
        }
        return false;
    };

    const validarEditPAccion = () => {
        const valueNombrePA = nombrePAccionRef.current.value;
        if(valueNombrePA !== elementSelect.proyectonom) {
            return true;
        }
        return false;
    };

    const validarEditOAccion = () => {
        const valueNombreOA = nombreOAccionRef.current.value;
        if(valueNombreOA !== elementSelect.obraAccionnom) {
            return true;
        }
        return false;
    };

    const validarEditCPresupuestal = () => {
        const valueClaveCP = clavePresupuestalClaveRef.current.value;
        const valueNombreCP = clavePresupuestalNombreRef.current.value;
        const claveCPlength = elementSelect.cpresupuestalclave.length;
        if((valueClaveCP !== elementSelect.cpresupuestalclave.substring(claveCPlength, claveCPlength-2)) || (valueNombreCP !== elementSelect.cpresupuestalnom)) {
            return true;
        }
        return false;
    };

    const validaEditAlertW = (type, e) => {
        if(e) e.preventDefault();

        setEditType(type);
        switch(type) {
            case 'UEjecutora':
                if(editUEjecutora) {
                    if(validarSubmit("formUEjecutora")) {
                        if(validarEditUEjecutora()) {
                            setOpenAlertW(true);
                        } else {
                            setError(<AlertForm message={no_hay_cambios} />)
                        }
                    }
                } else {
                    setEdit({ ...edit, uEjecutora: true });
                }
                break;
            case 'Programa':
                if(editPrograma) {
                    if(validarSubmit("formPrograma")) {
                        if(validarEditPrograma()) {
                            setOpenAlertW(true);
                        } else {
                            setError(<AlertForm message={no_hay_cambios} />)
                        }
                    }
                } else {
                    setEdit({ ...edit, programa: true });
                }
                break;
            case 'Subprograma':
                if(editSubprograma) {
                    if(validarSubmit("formSubprograma")) {
                        if(validarEditSubprograma()) {
                            setOpenAlertW(true);
                        } else {
                            setError(<AlertForm message={no_hay_cambios} />)
                        }
                    }
                } else {
                    setEdit({ ...edit, subprograma: true });
                }
                break;
            case 'PAccion':
                if(editPAccion) {
                    if(validarSubmit("formPAccion")) {
                        if(validarEditPAccion()) {
                            setOpenAlertW(true);
                        } else {
                            setError(<AlertForm message={no_hay_cambios} />)
                        }
                    }
                } else {
                    setEdit({ ...edit, pAccion: true });
                }
                break;
            case 'OAccion':
                if(editOAccion) {
                    if(validarSubmit("formOAccion")) {
                        if(validarEditOAccion()) {
                            setOpenAlertW(true);
                        } else {
                            setError(<AlertForm message={no_hay_cambios} />)
                        }
                    }
                } else {
                    setEdit({ ...edit, oAccion: true });
                }
                break;
            case 'CPresupuestal':
                if(validarSubmit('EditarProyecto')) {
                    if(validarEditCPresupuestal()) {
                        setOpenAlertW(true);
                    } else {
                        setError(<AlertForm message={no_hay_cambios} />)
                    }
                }
                break;
            default:
                break;
        }
    };

    const enviar = e => {
        e.preventDefault();
        if(!clavePresupuestalNombreRef.current.value) {
            setError(<AlertForm message="Complete los campos" />);
        } else {
            if(validarSubmit('AgregarProyecto')) {
                const clavePresupuestalClave = clavePresupuestalClaveRef.current.value;
                const clavePresupuestalNombre = clavePresupuestalNombreRef.current.value;
                setOpenLoading(true);
                enviarProyecto({ obraId: selected.oAccion.id, cpresupuestalclave: `${selected.oAccion.clave}${clavePresupuestalClave}`, cpresupuestalnom: clavePresupuestalNombre });
            }
        }
    };

    const actualizar = () => {
        if(validarSubmit('EditarProyecto')) {
            const clavePresupuestalClave = clavePresupuestalClaveRef.current.value;
            const clavePresupuestalNombre = clavePresupuestalNombreRef.current.value;
            setError(null);
            setOpenLoading(true);
            actualizarProyecto({ obraId: elementSelect.obraAccionid, cpresupuestalclave: `${elementSelect.cpresupuestalclave.substr(0,17)}${clavePresupuestalClave}`, cpresupuestalnom: clavePresupuestalNombre }, elementSelect.id);
        }
    };

    const editarUEjecutora = () => {
        setIsLoadingUE(true);
        const request = {  
            ciaId: elementSelect.ciaid,
            udejecutoraClave: elementSelect.udejecutoraclave,
            udejecutoraNom: nombreUEjecutoraRef.current.value.trim()
        };

        axios.put(`${servicios_grupohit_admon}/ejecutora/${elementSelect.udejecutoraid}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(res.data.msg);
                    elementSelect.udejecutoranom = res.data.dataResponse.udejecutoranom;
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable('No se ha actualizado el registro'))
        .finally(() => setIsLoadingUE(false));
    };
    
    const editarPrograma = () => {
        setIsLoadingP(true);
        const request = {  
            udejecutoraid: elementSelect.udejecutoraid,
            programaclave: elementSelect.programaclave,
            programanom: nombreProgramaRef.current.value.trim()
        };

        axios.put(`${servicios_grupohit_admon}/programa/${elementSelect.programaid}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(res.data.msg);
                    elementSelect.programanom = res.data.dataResponse.programanom;
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable('No se ha actualizado el registro'))
        .finally(() => setIsLoadingP(false));
    };
    
    const editarSubprograma = () => {
        setIsLoadingSP(true);
        const request = {
            programa: elementSelect.programaid,
            subProgramaClave: elementSelect.subprogramaclave,
            subProgramaNom: nombreSubprogramaRef.current.value.trim()
        };
        
        axios.put(`${servicios_grupohit_admon}/sub/programa/${elementSelect.subprogramaid}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(res.data.msg);
                    elementSelect.subprogramanom = res.data.dataResponse.subProgramaNom;
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable('No se ha actualizado el registro'))
        .finally(() => setIsLoadingSP(false));
    };
    
    const editarPAccion = () => {
        setIsLoadingPA(true);
        const request = {  
            subProgramaid: elementSelect.subprogramaid,
            proyectoclave: elementSelect.proyectoclave,
            proyectonom: nombrePAccionRef.current.value.trim()
        };
        
        axios.put(`${servicios_grupohit_admon}/proyecto/${elementSelect.proyectoid}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(res.data.msg);
                    elementSelect.proyectonom = res.data.dataResponse.proyectonom;
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable('No se ha actualizado el registro'))
        .finally(() => setIsLoadingPA(false));
    };
    
    const editarOAccion = () => {
        setIsLoadingOA(true);
        const request = {
            proyecto: elementSelect.proyectoid,
            obraAccionClave: elementSelect.obraAccionclave,
            obraAccionNom: nombreOAccionRef.current.value.trim()
        };

        axios.put(`${servicios_grupohit_admon}/obra/accion/${elementSelect.obraAccionid}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(res.data.msg);
                    elementSelect.obraAccionnom = res.data.dataResponse.obraAccionNom;
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable('No se ha actualizado el registro'))
        .finally(() => setIsLoadingOA(false));
    };

    const editar = () => {
        setError(null);
        switch(editType) {
            case 'UEjecutora':
                setEdit({ ...edit, uEjecutora: false });
                editarUEjecutora();
                break;
            case 'Programa':
                setEdit({ ...edit, programa: false });
                editarPrograma();
                break;
            case 'Subprograma':
                setEdit({ ...edit, subprograma: false });
                editarSubprograma();
                break;
            case 'PAccion':
                setEdit({ ...edit, pAccion: false });
                editarPAccion();
                break;
            case 'OAccion':
                setEdit({ ...edit, oAccion: false });
                editarOAccion();
                break;
            case 'CPresupuestal':
                actualizar();
                break;
            default:
                break;
        }
    };

    const activaBtn = (campo, valor) => {
        switch (campo) {
            case "UResponsable":
                if(valor !== null) {
                    setDisabled({ uEjecutora: false, programa: true, subprograma: true, pAccion: true, oAccion: true, cvePresupuestal: true });
                } else {
                    setDisabled({ uEjecutora: true, programa: true, subprograma: true, pAccion: true, oAccion: true, cvePresupuestal: true });
                    setSelected({ uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "UEjecutora":
                if(valor !== null) {
                    setDisabled({ ...disabled, programa: false, subprograma: true, pAccion: true, oAccion: true, cvePresupuestal: true });
                } else {
                    setDisabled({ ...disabled, programa: true, subprograma: true, pAccion: true, oAccion: true, cvePresupuestal: true });
                    setSelected({ ...selected, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "Programa":
                if(valor !== null) {
                    setDisabled({ ...disabled, subprograma: false, pAccion: true, oAccion: true, cvePresupuestal: true });
                } else {
                    setDisabled({ ...disabled, subprograma: true, pAccion: true, oAccion: true, cvePresupuestal: true });
                    setSelected({ ...selected, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "Subprograma":
                if(valor !== null) {
                    setDisabled({ ...disabled, pAccion: false, oAccion: true, cvePresupuestal: true });
                } else {
                    setDisabled({ ...disabled, pAccion: true, oAccion: true, cvePresupuestal: true });
                    setSelected({ ...selected, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "PAccion":
                if(valor !== null) {
                    setDisabled({ ...disabled, oAccion: false, cvePresupuestal: true });
                } else {
                    setDisabled({ ...disabled, oAccion: true, cvePresupuestal: true });
                    setSelected({ ...selected, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "OAccion":
                if(valor !== null) {
                    setDisabled({ ...disabled, cvePresupuestal: false });
                } else {
                    setDisabled({ ...disabled, cvePresupuestal: true });
                    setSelected({ ...selected, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            default:
                setDisabled({ uEjecutora: true, programa: true,  subprograma: true, pAccion: true, oAccion: true, cvePresupuestal: true });
                setSelected({ uResponsable: { id: '',  clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, subprograma: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, oAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
            break;
        }
    };

    const validarUResponsable = valor => {
        activaBtn("UResponsable", valor);

        if(valor) {
            setSelected({...selected, uResponsable: { id: valor.id, clave: valor.ciaUr , nombre: valor.ciaRazonSocial }});
            
            axios.get(`${servicios_grupohit_admon}/ejecutora/compani/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setUEjecutora(res.data.dataResponse);
                        break;
                    case 404:
                        setUEjecutora([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));
        } else {
            setUEjecutora([]);
        }
    };

    const validarUEjecutora = valor => {
        activaBtn("UEjecutora", valor);
        
        if(valor) {
            setSelected({...selected, uEjecutora: { id: valor.id, clave: valor.udejecutoraclave, nombre: valor.udejecutoranom }});
        
            axios.get(`${servicios_grupohit_admon}/programa/ejecutora/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPrograma(res.data.dataResponse);
                        break;
                    case 404:
                        setPrograma([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));
        }
    };

    const validarPrograma = valor => {
        activaBtn("Programa", valor);

        if(valor) {
            setSelected({...selected, programa: { id: valor.id, clave: valor.programaclave, nombre: valor.programanom }});
    
            axios.get(`${servicios_grupohit_admon}/sub/programa/programa/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setSubprograma(res.data.dataResponse);
                        break;
                    case 404:
                        setSubprograma([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));
        }
    };

    const validarSubprograma = valor => {
        activaBtn("Subprograma", valor);

        if(valor) {
            setSelected({...selected, subprograma: { id: valor.id, clave: valor.subProgramaClave, nombre: valor.subProgramaNom }});

            axios.get(`${servicios_grupohit_admon}/proyecto/subprograma/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPAccion(res.data.dataResponse);
                        break;
                    case 404:
                        setPAccion([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));
        }
    };

    const validarPAccion = valor => {
        activaBtn("PAccion", valor);
        
        if(valor) {
            setSelected({...selected, pAccion: { id: valor.id, clave: valor.proyectoclave, nombre: valor.proyectonom }});
            
            axios.get(`${servicios_grupohit_admon}/obra/accion/proyecto/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setOAccion(res.data.dataResponse);
                        break;
                    case 404:
                        setOAccion([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));
        }
    };

    const validarOAccion = valor => {
        activaBtn("OAccion", valor);
        
        if(valor) {
            setSelected({...selected, oAccion: { id: valor.id, clave: valor.obraAccionClave, nombre: valor.obraAccionNom }});
        }
    };

    const abrirModal = (e, info) => {
        e.preventDefault();
        setInfo({
            uResponsable: selected.uResponsable,
            uEjecutora: selected.uEjecutora,
            programa: selected.programa,
            subprograma: selected.subprograma,
            pAccion: selected.pAccion,
            oAccion: selected.oAccion,
            info
        });
        setOpenAdd(true);
    };

    return (
        <Fragment>
            {(enviarProyecto)
            ?   <Fragment>
                    <AutocompleteComponent
                        id="uResponsable"
                        title="Unidad Responsable / Dependencia"
                        options={uResponsable}
                        optionListView="ciaRazonSocial"
                        action={validarUResponsable}
                        placeholder="Unidad Responsable / Dependencia"
                        tooltipText="Busque la Unidad Responsable, por nombre o clave, en el campo de autocompletado"
                        clearDiasabled
                        required
                    />
                    <hr />
                    <div className="row">
                        <div className="col-10">
                            <AutocompleteComponent
                                id="uEjecutora"
                                title="Unidad Ejecutora"
                                options={uEjecutora}
                                optionListView="udejecutoranom"
                                action={validarUEjecutora}
                                disabled={disabled.uEjecutora}
                                placeholder="Unidad Ejecutora"
                                tooltipText="Busque la Unidad Ejecutora, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                clearDiasabled
                                required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            <button className="btn-autocomplete" disabled={disabled.uEjecutora} onClick={e => abrirModal(e, 1)}><AddIcon/></button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            <AutocompleteComponent
                                id="programa"
                                title="Programa"
                                options={programa}
                                optionListView="programanom"
                                action={validarPrograma}
                                disabled={disabled.programa}
                                placeholder="Programa"
                                tooltipText="Busque el Programa, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                clearDiasabled
                                required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            <button className="btn-autocomplete" disabled={disabled.programa} onClick={e => abrirModal(e, 2)}><AddIcon/></button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            <AutocompleteComponent
                                id="subprograma"
                                title="Subprograma"
                                options={subprograma}
                                optionListView="subProgramaNom"
                                action={validarSubprograma}
                                disabled={disabled.subprograma}
                                placeholder="Programa"
                                tooltipText="Busque el Subprograma, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                clearDiasabled
                                required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            <button className="btn-autocomplete" disabled={disabled.subprograma} onClick={e => abrirModal(e, 3)}><AddIcon/></button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            <AutocompleteComponent
                                id="pAccion"
                                title="Proyecto Acción"
                                options={pAccion}
                                optionListView="proyectonom"
                                action={validarPAccion}
                                disabled={disabled.pAccion}
                                placeholder="Proyecto Acción"
                                tooltipText="Busque el Proyecto Acción, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                clearDiasabled
                                required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            <button className="btn-autocomplete" disabled={disabled.pAccion} onClick={e => abrirModal(e, 4)}><AddIcon/></button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            <AutocompleteComponent
                                id="oAccion"
                                title="Obra Acción"
                                options={oAccion}
                                optionListView="obraAccionNom"
                                action={validarOAccion}
                                disabled={disabled.oAccion}
                                placeholder="Obra Acción"
                                tooltipText="Busque la Obra Acción, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                clearDiasabled
                                required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            <button className="btn-autocomplete" disabled={disabled.oAccion} onClick={e => abrirModal(e, 5)}><AddIcon/></button>
                        </div>
                    </div>
                </Fragment>
            :   <Fragment>
                    <h6>Unidad Responsable / Dependencia</h6>
                    <div className="row">
                        <div className='col-2'>
                            <label style={{marginBottom: '0rem'}}>Clave</label>
                            <div className="form-control label-control label-proyecto">{ elementSelect.ciaUr }</div>
                        </div>
                        <div className='col-10'>
                            <label style={{marginBottom: '0rem'}}>Nombre</label>
                            <div className="form-control label-control label-proyecto">{ elementSelect.ciaRazonSocial }</div>
                        </div>
                    </div>
                    <hr />
                    <h6>Unidad Ejecutora</h6>
                    <div id="formUEjecutora" className="row">
                        <div className='col-4'>
                            <label style={{marginBottom: '0rem'}}>Clave</label>
                            <div className="form-control label-control label-proyecto">{ elementSelect.udejecutoraclave }</div>
                        </div>
                        <div className='col-6'>
                            <label style={{marginBottom: '0rem'}}>
                                Nombre<strong className="requerido">* </strong>
                                <TooltipComponent tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}>
                                    <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                                </TooltipComponent>
                            </label>
                            <InputValidation
                                type="nombreUEjecutora" placeholder="Nombre" refValue={nombreUEjecutoraRef}
                                maxLength={128} defaultValue={elementSelect.udejecutoranom} disabled={!editUEjecutora} required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            {(isLoadingUE)
                            ?   <SpinnerOval/>
                            :   <button className="btn-autocomplete" onClick={() => validaEditAlertW('UEjecutora')}>
                                    {(editUEjecutora)
                                    ?   <SaveIcon fontSize="small"/>
                                    :   <EditIcon fontSize="small"/>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                    <h6>Programa</h6>
                    <div id="formPrograma" className="row">
                        <div className='col-4'>
                            <label>Clave</label>
                            <div className="form-control label-control label-proyecto">{ elementSelect.programaclave }</div>
                        </div>
                        <div className='col-6'>
                            <label>
                                Nombre<strong className="requerido">* </strong>
                                <TooltipComponent tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}>
                                    <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                                </TooltipComponent>
                            </label>
                            <InputValidation
                                type="nombrePrograma" placeholder="Nombre" refValue={nombreProgramaRef}
                                maxLength={128} defaultValue={elementSelect.programanom} disabled={!editPrograma} required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            {(isLoadingP)
                            ?   <SpinnerOval/>
                            :   <button className="btn-autocomplete" onClick={() => validaEditAlertW('Programa')}>
                                    {(editPrograma)
                                    ?   <SaveIcon fontSize="small"/>
                                    :   <EditIcon fontSize="small"/>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                    <h6>Subprograma</h6>
                    <div id="formSubprograma" className="row">
                        <div className='col-4'>
                            <label>Clave</label>
                            <div className="form-control label-control label-proyecto">{ elementSelect.subprogramaclave }</div>
                        </div>
                        <div className='col-6'>
                            <label>
                                Nombre<strong className="requerido">* </strong>
                                <TooltipComponent tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}>
                                    <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                                </TooltipComponent>
                            </label>
                            <InputValidation
                                type="nombrePrograma" placeholder="Nombre" refValue={nombreSubprogramaRef}
                                maxLength={128} defaultValue={elementSelect.subprogramanom} disabled={!editSubprograma} required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            {(isLoadingSP)
                            ?   <SpinnerOval/>
                            :   <button className="btn-autocomplete" onClick={() => validaEditAlertW('Subprograma')}>
                                    {(editSubprograma)
                                    ?   <SaveIcon fontSize="small"/>
                                    :   <EditIcon fontSize="small"/>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                    <h6>Proyecto Acción</h6>
                    <div id="formPAccion" className="row">
                        <div className='col-4'>
                            <label>Clave</label>
                            <div className="form-control label-control label-proyecto">{ elementSelect.proyectoclave }</div>
                        </div>
                        <div className='col-6'>
                            <label>
                                Nombre<strong className="requerido">* </strong>
                                <TooltipComponent tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}>
                                    <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                                </TooltipComponent>
                            </label>
                            <InputValidation
                                type="nombrePAccion" placeholder="Nombre" refValue={nombrePAccionRef}
                                defaultValue={elementSelect.proyectonom} maxLength={128} disabled={!editPAccion} required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            {(isLoadingPA)
                            ?   <SpinnerOval/>
                            :   <button className="btn-autocomplete" onClick={() => validaEditAlertW('PAccion')}>
                                    {(editPAccion)
                                    ?   <SaveIcon fontSize="small"/>
                                    :   <EditIcon fontSize="small"/>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                    <h6>Obra Acción</h6>
                    <div id="formOAccion" className="row">
                        <div className='col-4'>
                            <label>Clave</label>
                            <div className="form-control label-control label-proyecto">{ elementSelect.obraAccionclave }</div>
                        </div>
                        <div className='col-6'>
                            <label>
                                Nombre<strong className="requerido">* </strong>
                                <TooltipComponent tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}>
                                    <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                                </TooltipComponent>
                            </label>
                            <InputValidation
                                type="nombrePAccion" placeholder="Nombre" refValue={nombreOAccionRef}
                                defaultValue={elementSelect.obraAccionnom} maxLength={128} disabled={!editOAccion} required
                            />
                        </div>
                        <div className="col-2 align-self-end mb-2">
                            {(isLoadingOA)
                            ?   <SpinnerOval/>
                            :   <button className="btn-autocomplete" onClick={() => validaEditAlertW('OAccion')}>
                                    {(editOAccion)
                                    ?   <SaveIcon fontSize="small"/>
                                    :   <EditIcon fontSize="small"/>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                </Fragment>
            }
            <form id={elementSelect ? 'EditarProyecto' : 'AgregarProyecto'} onSubmit={ elementSelect ? e => validaEditAlertW('CPresupuestal', e) : enviar }>
                {(enviarProyecto)
                ?   <div className="row">
                        <div className="col-12">
                            <label>Centro de Trabajo / Proyecto / Clave Presupuestal</label>
                        </div>
                    </div>
                :   <h6>Centro de Trabajo / Proyecto / Clave Presupuestal</h6>
                }
                <label>
                    Clave<strong className="requerido">* </strong>
                    <TooltipComponent tooltipText={ '2 caracteres alfanuméricos o caracteres especiales = @ # * < >' }>
                        <span id="cveCtrTrabajo" className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                    </TooltipComponent>
                </label>
                <div className="row">
                    <div className="col-7">
                        <label className="form-control label-control label-proyecto">
                            { elementSelect ? elementSelect.cpresupuestalclave.substr(0,17) : selected.oAccion.clave }
                        </label>
                    </div>
                    <div className="col-5">
                        <InputValidation
                            type="claveCvePresupuestal" placeholder="Clave" refValue={clavePresupuestalClaveRef}
                            defaultValue={elementSelect ? elementSelect.cpresupuestalclave.substr(17,2) : ''} maxLength={2}
                            disabled={!elementSelect ? (disabled.cvePresupuestal === false) ? false : true : false}
                        /> 
                    </div>
                </div>    
                <InputValidation
                    title="Nombre" type="nombreCvePresupuestal" placeholder="Nombre"
                    refValue={clavePresupuestalNombreRef} defaultValue={elementSelect ? elementSelect.cpresupuestalnom : ''} maxLength={128}
                    disabled={!elementSelect ? (disabled.cvePresupuestal === false) ? false : true : false}
                    tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'} required
                />
                { error }
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <ModalLoading nombreModulo={idModulo} open={openLoading} setOpen={setOpenLoading}/>
            <ModalAddFaltante
                openAdd={openAdd} setOpenAdd={setOpenAdd} info={info} agregar={agregar} errorTable={errorTable}
                setOpenAddFirst={setOpenAddFirst} idModulo={idModulo}
            />
            {elementSelect &&
                <AlertWarning show={openAlertW} SetopenALert={setOpenAlertW} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={editar} value={editType}/>
            }
        </Fragment>
    );
}

export default FormProyectos;