import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { actualizarInfoEmpleadoEspeciales } from '../../../../../../Redux/actions/EmpleadosActions';
import axios from 'axios';
import { servicios_grupohit, registro_no_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; //Alertas
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteEspeciales = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable, informacionAdicional, actualizarInfoEmpleadoEspeciales }) => {

    const [isLoading, setIsLoading] = useState(false);

    let allGroups = [];
    let allItems = [];
    
    (elementSelect.dataContent) && elementSelect.dataContent.map(item => ( allGroups = allGroups.concat(item.groups) ));
    (allGroups) && allGroups.map(item => ( allItems = allItems.concat(item.data) ));
    const itemsType = (allItems)
                    ? (allItems.filter(item => item.tipo === elementSelect.rowData.tipo)).sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0)
                    : null;
    
    const  canDelete = itemsType ? itemsType.find(element => element.id !== elementSelect.rowData.id) : null;

    const axiosEliminar = () => {
        setIsLoading(true);
        let url = null;
        switch(elementSelect.rowData.tipo){
            case 'Zona de Pago'.toUpperCase():
                url = 'empleado/movimientos/especiales/zonapago';
                break;
            case 'Nómina'.toUpperCase():
                url = 'empleado/movimientos/especiales/nomina';
                break;
            case 'Puesto'.toUpperCase():
                url = 'empleado/movimientos/especiales/puesto';
                break;
            case 'Plaza'.toUpperCase():
                url = 'empleado/movimientos/especiales/plaza';
                break;
            case 'Clave Presupuestal / Proyecto'.toUpperCase():
                url = 'empleado/movimientos/especiales/presupuestal';
                break;
            default:
                break;
        }

        axios.post(`${servicios_grupohit}/${url}/${elementSelect.idEmpleado}`, null, headersAuth())
        .then(respuesta => {
            switch(respuesta.data.status){
                case 200:
                    actualizarInfoEmpleadoEspeciales({
                        empId: elementSelect.idEmpleado,
                        tipo: elementSelect.rowData.tipo,
                        info: informacionAdicional.data.filter(item => item.tipo === elementSelect.rowData.tipo)
                    });
                    eliminar(elementSelect.rowData.id);
                    break;
                default:
                    errorTable( respuesta.data.msg );
                    break;
            }
        })
        .catch(error => {
            errorTable(registro_no_eliminado);
        })
        .finally(() => setIsLoading(false));
    };

    const InfoReplaced = () => {
        for (const key in canDelete.informacion) {
            if (canDelete.informacion.hasOwnProperty(key)) {
                let llaves = Object.keys(canDelete.informacion[key]).length;
                const result = [];
                for(const clave in canDelete.informacion[key]) {
                    if(!informacionAdicional.notAllowed.includes(clave)){
                        const newKeyDiccionario = informacionAdicional.allowed[clave]; 
                        result.push(
                            <div className={llaves % 2 === 0 ? "col-md-6 col-sm-12" : "col-md-12 col-sm-12"} style={{fontSize:'12px', color:'rgba(0, 0, 0, 0.87)', textAlign:'left'}} key={canDelete.informacion.id+clave}>
                                <strong>
                                    {`${newKeyDiccionario ? newKeyDiccionario : clave}: `}
                                </strong>
                                {`${canDelete.informacion[key][clave]===null ? '-' : canDelete.informacion[key][clave]}`}
                            </div>
                        );
                    }                                              
                }
                return  <div className="row" style={{paddingTop:'20px'}} key={canDelete.id+"td"}>
                            { result.map(element => element) }
                        </div>;
            }
        }  
    };
    
    return (
        canDelete
        ?   <Fragment>
                <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
                <Alertwarning 
                    show={alertWarning} 
                    SetopenALert={setAlertWarning} 
                    text={'El empleado volverá a tener la siguiente organización:'} 
                    subtitle={<InfoReplaced/>} 
                    textButtonAceptar="Aceptar" 
                    textButtonCancelar="Cancelar"
                    action={ axiosEliminar } 
                />
            </Fragment>
        :   <AlertSyE show={alertWarning} setOpenAlertSyE={setAlertWarning} title="Petición Fallida" text={'No se puede eliminar la operación.'} textbtnconfirm="Aceptar" type="error"/>
    );
};

const mapDispatchToProps = { actualizarInfoEmpleadoEspeciales };

export default connect(null, mapDispatchToProps)(ModalDeleteEspeciales);