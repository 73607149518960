
export function obtenerNominas( nominas ) {
    const accesosNominas = [];

    nominas.forEach( relacionLaboral => {
        relacionLaboral.nominas[0].forEach( nomina => {
            if( nomina.hasAccess === true ){
                accesosNominas.push( nomina.id );
            }
        })
    });
    return accesosNominas ;
}