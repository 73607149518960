import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, registro_no_eliminado } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';

import SaveAlt from '@material-ui/icons/SaveAlt';
import Delete from '@material-ui/icons/Delete';

import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './../../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/MaterialTheme';
import Checkbox from '@material-ui/core/Checkbox';

/* --------------------------------- Funcion para obtener informacion sobre las nominas --------------------------------- */
export function obtenerInformacionExcel( idModulo, setInformacionNominas, informacion ){
    switch( idModulo ) {
        case 41: //MovimientosVariables
            axiosVariablesNomina( setInformacionNominas );
            break;
        case 31: //Ausentismos
            setInformacionNominas( informacion.nominas );
            break;
        case 68: //MovimientosVFijos
            axiosVariablesNominaFijos( setInformacionNominas );
            break;
        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const axiosVariablesNomina = ( setInformacionNominas ) => {
    axios.get( `${servicios_grupohit_rh}/movimientos/total/movimientos/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacionNominas( respuesta.data.dataResponse );
                    break;
                case 404:
                    //setInformacionNominas( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
}

const axiosVariablesNominaFijos = ( setInformacionNominas ) => {
    /*axios.get( `${servicios_grupohit_rh}/movimientos/total/movimientos/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacionNominas( respuesta.data.dataResponse );
                    break;
                case 404:
                    //setInformacionNominas( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })*/
    alert('función axiosVariablesNominaFijos')
}

/* --------------------------- Funcion para realizar axios & obtener descarga de archivo Excel -------------------------- */
export function axiosDescargarExcel( idModulo, nomina, setError, nombreModulo, informacion ){
    switch( idModulo ) {
        case 41: //MovimientosVariables
            axiosExcelVariables( nomina, setError, nombreModulo );
            break;
        case 31: //Ausentismos
            axiosExcelAusentismos( nomina, setError, nombreModulo, informacion );
            break;
        case 68: //MovimientosFijoe
            axiosExcelFijos( nomina, setError, nombreModulo, informacion );
            break;
        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const axiosExcelVariables = ( nomina, setError, nombreModulo ) => {
    setError( null );
    axios.get( `${servicios_grupohit_rh}/movimientos/fijo/reporte/variable/excel/${nomina.id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    const downloadLink = document.createElement("a");
                    downloadLink.href = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                    downloadLink.download = `${nombreModulo}_${nomina.nominaNombre}.xlsx`;
                    downloadLink.click();
                    break;
                case 404:
                    setError(<AlertForm message={respuesta.data.msg} />);
                    break;
                default:
                    setError(<AlertForm message={'No se pudo descargar el archivo.'} />);
                    break;
            }
        })
        .catch( error => {
            setError(<AlertForm message={'No se pudo descargar el archivo.'} />); 
        })
}

const axiosExcelFijos = ( nomina, setError, nombreModulo ) => {
    /*setError( null );
    axios.get( `${servicios_grupohit_rh}/movimientos/fijo/reporte/variable/excel/${nomina.id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    const downloadLink = document.createElement("a");
                    downloadLink.href = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                    downloadLink.download = `${nombreModulo}_${nomina.nominaNombre}.xlsx`;
                    downloadLink.click();
                    break;
                case 404:
                    setError(<AlertForm message={respuesta.data.msg} />);
                    break;
                default:
                    setError(<AlertForm message={'No se pudo descargar el archivo.'} />);
                    break;
            }
        })
        .catch( error => {
            setError(<AlertForm message={'No se pudo descargar el archivo.'} />); 
        })*/
       alert('función axiosExcelFijos')
}

const axiosExcelAusentismos = ( nomina, setError, nombreModulo, informacion ) => {
    setError( null );
    axios.get( `${servicios_grupohit_rh}/empleado/ausentismo/total/ausentismos/${informacion.fecha}/${nomina.id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    /*
                    const downloadLink = document.createElement("a");
                    downloadLink.href = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                    downloadLink.download = `${nombreModulo}_${nomina.nominaNombre}.xlsx`;
                    downloadLink.click();
                    */
                    break;
                case 404:
                    setError(<AlertForm message={respuesta.data.msg} />);
                    break;
                default:
                    setError(<AlertForm message={'No se pudo descargar el archivo.'} />);
                    break;
            }
        })
        .catch( error => {
            setError(<AlertForm message={'No se pudo descargar el archivo.'} />);
        })
}

/* ----------------------------- Funcion para realizar axios & eliminar elementos por Excel ----------------------------- */
export function axiosDeleteExcel( idModulo, nominaSelected, errorTable, successTable, setInformacionNominas, informacion ){
    switch( idModulo ) {
        case 41: //MovimientosVariables
            axiosEliminarVariables( idModulo, nominaSelected, errorTable, successTable, setInformacionNominas );
            break;
        case 31: //Ausentismos
            axiosEliminarAusentismos( idModulo, nominaSelected, errorTable, successTable, setInformacionNominas, informacion );
            break;
        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const axiosEliminarVariables = ( idModulo, nominaSelected, errorTable, successTable, setInformacionNominas ) => {
    axios.delete( `${servicios_grupohit_rh}/movimientos/nomina/${nominaSelected.id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    //eliminar( elementSelect.id );
                    successTable( 'Registro eliminado' );
                    obtenerInformacionExcel( idModulo, setInformacionNominas );
                    break;
                default:
                    errorTable( registro_no_eliminado );
                    break;
            }
        })
        .catch( error => {
            errorTable( registro_no_eliminado );
        })
}

const axiosEliminarAusentismos = ( idModulo, nominaSelected, errorTable, successTable, setInformacionNominas, informacion ) => {
    axios.delete( `${servicios_grupohit_rh}/empleado/ausentismo/eliminar/${nominaSelected.id}/${informacion.fecha}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    //eliminar( elementSelect.id );
                    successTable( 'Registro eliminado' );
                    obtenerInformacionExcel( idModulo, setInformacionNominas, informacion );
                    break;
                default:
                    errorTable( registro_no_eliminado );
                    break;
            }
        })
        .catch( error => {
            errorTable( registro_no_eliminado );
        })
}

/* ---------------------------------------------------------------------------------------------------------------------- */
const InformacionMovimientosExcel = ({ idModulo, errorTable, successTable, nombreModulo, informacion }) => {
    const [ informacionNominas, setInformacionNominas ] = useState( null );
    const [ descargar, setDescargar ] = useState( false );
    const [ eliminar, setEliminar ] = useState( false );
    const [ error, setError ] = useState( null );

    useEffect(() => {
        obtenerInformacionExcel( idModulo, setInformacionNominas, informacion );
        // eslint-disable-next-line
    }, []);

    const [ openDelete, setOpenDelete ] = useState( false );
    const [ nominaSelected, setNominaSelected ] = useState( false );
    const seleccionar = ( nomina ) => {
        setOpenDelete( true );
        setNominaSelected( nomina );
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div className="checkText" onClick={() => { setDescargar(!descargar); setError(null) }}>
                <Checkbox checked={descargar} /> 
                <span> {`Descargar Archivo de Últimos ${nombreModulo}`} </span>
            </div>
            { informacionNominas && descargar &&
                <React.Fragment>
                    <div className="divVariablesNomina">
                        { informacionNominas.map( nomina => (
                            <div onClick={() => axiosDescargarExcel( idModulo, nomina, setError, nombreModulo, informacion )}>
                                <SaveAlt/> <span>{ `${nomina.nominaNombre} (${nomina.cantidad} movimientos)` }</span>
                            </div>
                        ))}
                    </div>
                    { error }
                </React.Fragment>
            }

            <div className="checkText" onClick={() => setEliminar( !eliminar )}>
                <Checkbox checked={eliminar} /> 
                <span> {`Eliminar todos los ${nombreModulo}`} </span>
            </div>
            { informacionNominas && eliminar &&
                <div className="divVariablesNomina">
                    { informacionNominas.map( nomina => (
                        <div onClick={() => seleccionar( nomina )}>
                            <Delete/> <span>{ `${nomina.nominaNombre} (${nomina.cantidad} movimientos)` }</span>
                        </div>
                    ))}
                </div>
            }
            <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text={`¡Se eliminarán todos los ${nombreModulo}!`}
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar"
                action={() => axiosDeleteExcel( idModulo, nominaSelected, errorTable, successTable, setInformacionNominas, informacion )} />
        </MuiThemeProvider>
    );
};

export default InformacionMovimientosExcel;