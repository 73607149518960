import React from 'react';

const FormatoEjemplo = ({ jsonEjemplo }) => {
    return (
        <div className="contenedorEjemplo">
            <table className="formatoEjemplo">
                <tr className="titleFormatoEjemplo">
                    { Object.keys(jsonEjemplo[0]).map( key => (
                        <td>{key}</td>
                    ))}
                </tr>
                
                <tr>
                    { jsonEjemplo.map( element => (
                        Object.keys(element).map( key => (
                            <td>{element[key]}</td>
                        ))
                    ))}
                </tr>
            </table>
        </div>
    );
};

export default FormatoEjemplo;