import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioDepartamentos = ({ error, enviarDepartamento, elementSelect, actualizarDepartamento, informacion, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ dependencia, setDependencia ] = useState( elementSelect ? informacion.dependencias.find( element => element.id === elementSelect.ciaId ) : null );
    const [ errorDependencia, setErrorDependencia ] = useState( null );
    const deptoClaveRef = createRef('');
    const deptoNomRef = createRef('');
    const deptoDescripRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarDepartamentos' ) ){
            if( dependencia ){
                const ciaId = dependencia.id;
                const deptoClave = deptoClaveRef.current.value;
                const deptoNom = deptoNomRef.current.value;
                const deptoDescrip = deptoDescripRef.current.value;

                enviarDepartamento({ ciaId, deptoClave, deptoNom, deptoDescrip });
            }else{
                setErrorDependencia( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarDepartamentos' ) ){
            if( dependencia ){
                const id = elementSelect.id;
                const ciaId = dependencia.id;
                const deptoClave = deptoClaveRef.current.value;
                const deptoNom = deptoNomRef.current.value;
                const deptoDescrip = deptoDescripRef.current.value;

                actualizarDepartamento({ id, ciaId, deptoClave, deptoNom, deptoDescrip });
            }else{
                setErrorDependencia( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const validarDependencia = (valor) => {
        if(valor) {
            setErrorDependencia( null );
            setDependencia( valor );
        }else{
            setErrorDependencia( true );
            setDependencia( null );
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarDepartamentos' : 'AgregarDepartamentos' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveDepartamento" placeholder="Escriba la clave del departamento"
                    tooltipText={'Máximo 24 letras y/o números'} tooltipName={'tooltipClaveDep'} maxLength='24' alfanumeric
                    refValue={deptoClaveRef} defaultValue={ elementSelect ? elementSelect.deptoClave : '' } required/>

                <InputValidation title="Nombre Departamento" type="nombreDepartamento" placeholder="Escriba el nombre del departamento" maxLength='256'
                    tooltipText={'Máximo 256 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomDep'}
                    refValue={deptoNomRef} defaultValue={ elementSelect ? elementSelect.deptoNom : '' } required/>

                <AutocompleteComponent id="dependenciaDepartamentos" title="Unidad Responsable / Dependencia" placeholder="Seleccione la dependencia del departamento" size="100%"
                    tooltipName="tooltipDependenciaDep" tooltipText={'Busque la Dependencia, por nombre o clave, en el campo de autocompletado'} action={validarDependencia}
                    options={informacion.dependencias} optionListView={'ciaUrRazonSocial'} error={errorDependencia} required
                    value={dependencia}/>
                
                <InputValidation title="Descripción" type="descripcionDepartamento" placeholder="Escriba la descripción del departamento" maxLength='512'
                    tooltipText={'Máximo 512 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescDep'}
                    refValue={deptoDescripRef} defaultValue={ elementSelect ? elementSelect.deptoDescrip : '' } required/>

                {error}
                {errorDependencia}
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioDepartamentos;