import React from 'react';
import ConvertDate from '../../../../Helpers/ConvertDate';
import ModalService from '../../../../ModalService/ModalService';
import Formulario from '../components/Formulario';

const ModalConsultarTimbrados = ({ nombreModulo, open, setOpen, nombre, setOpenLoading, setData, setMostrarTabla }) => {

    const cerrarModal = () => {
        setOpen(false);
    };

    const showTable = () => {
        setOpenLoading(true);

        setTimeout(() => {
            setOpenLoading(false);
            setData({
                title: <strong>CVE- INTERNA</strong>,
                data: [
                    {
                        numRegistro: 1,
                        status: 'Código 123: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel felis a turpis fringilla vulputate et at dolor. Sed finibus facilisis accumsan. Duis bibendum a libero sed elementum. Nulla et magna quis elit scelerisque dapibus volutpat vitae lectus. Nulla mattis.',
                        numEmpleado: 112233,
                        nombre: 'Juan Pérez',
                        periodo: 9,
                        anio: 2020,
                        acumulado: 'Nómina',
                        fechaOperacion: ConvertDate(Date.now())
                    },
                    {
                        numRegistro: 2,
                        status: 'Código 123: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel felis a turpis fringilla vulputate et at dolor. Sed finibus facilisis accumsan. Duis bibendum a libero sed elementum. Nulla et magna quis elit scelerisque dapibus volutpat vitae lectus. Nulla mattis.',
                        numEmpleado: 112234,
                        nombre: 'Carlos Ramírez',
                        periodo: 9,
                        anio: 2020,
                        acumulado: 'Nómina',
                        fechaOperacion: ConvertDate(Date.now())
                    },
                    {
                        numRegistro: 3,
                        status: 'Código 123: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel felis a turpis fringilla vulputate et at dolor. Sed finibus facilisis accumsan. Duis bibendum a libero sed elementum. Nulla et magna quis elit scelerisque dapibus volutpat vitae lectus. Nulla mattis.',
                        numEmpleado: 112235,
                        nombre: 'Josué Cuervo',
                        periodo: 9,
                        anio: 2020,
                        acumulado: 'Nómina',
                        fechaOperacion: ConvertDate(Date.now())
                    }
                ]
            });
            setMostrarTabla(true);
            cerrarModal();
        }, 3000);
    };

    return (
        <ModalService
            title="Consultar Recibos de Pago Timbrados"
            parentNode={nombreModulo}
            isOpen={open}
            onClose={cerrarModal}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <Formulario
                        nombre={nombre}
                        action={showTable}
                    />
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalConsultarTimbrados;