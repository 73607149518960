import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../../../Constants/Constants';
import CheckboxTipoMovimiento from './CheckboxTipoMovimiento';
import InputDateValidation from '../../../../../../../../Services/Validation/InputDateValidation';
import InputValidation from '../../../../../../../../Services/Validation/InputValidation';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { Button } from '@material-ui/core';
import { inputDateToLong, validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import ConvertDate from '../../../../../Helpers/ConvertDate';
import AlertError from '../../../../../../../../Services/Alerts/AlertSyE';

const NuevoMovimiento = ({ tipoMovimiento, setTipoMovimiento, folioRef, setFolio, fechaInicialRef, fechaFinalRef, setFechaInicial, setFechaFinal, setMostrar, fechaMinima }) => {

    const [error, setError] = useState(null);
    const [loadingIniciarEscalafon, setLoadingIniciarEscalafon] = useState(false);
    const [openAlertE, setOpenAlertE] = useState(false);
    
    const { definitivo, temporal } = tipoMovimiento;

    const iniciarEscalafon = e => {
        e.preventDefault();
        setError(null);
        if(validarSubmit("FormEscalafon")) {
            if(fechaMinima <= inputDateToLong(fechaInicialRef.current.value)) {
                setLoadingIniciarEscalafon(true);
                axios.get(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/getall/`, headersAuth())
                .then((res) => {
                    const folioRepetido = res.data.dataResponse.find(f => f.folio === folioRef.current.value.trim());
                    if(!folioRepetido) {
                        if(temporal) {
                            if(inputDateToLong(fechaInicialRef.current.value) >= inputDateToLong(fechaFinalRef.current.value)) {
                                setError(<AlertForm message="La fecha final debe ser mayor a la fecha inicial"/>);
                                return;
                            }
                            setFechaFinal(inputDateToLong(fechaFinalRef.current.value));
                        }
                        setFechaInicial(inputDateToLong(fechaInicialRef.current.value));
                        setMostrar(2);
                    } else {
                        setError(<AlertForm message={`El folio ${folioRef.current.value} ya existe`}/>);
                    }
                })
                .catch(() => setOpenAlertE(true))
                .finally(() => setLoadingIniciarEscalafon(false));
            } else {
                setError(<AlertForm message={`La fecha inicial debe ser mayor o igual que ${ConvertDate(fechaMinima)}`}/>);
            }
        }
    };

    return (
        <form id="FormEscalafon" onSubmit={iniciarEscalafon}>
            <CheckboxTipoMovimiento tipoMovimiento={tipoMovimiento} setTipoMovimiento={setTipoMovimiento}/>
            {(definitivo || temporal) &&
            <Fragment>
                {(fechaMinima)
                ?   <Fragment>
                        <div className="row">
                            <div className="col">
                                <InputValidation
                                    title="Folio" type="folioEscalafon" tooltipText="Máximo 64 números, letras y/o signos de puntuación y/o caracteres especiales"
                                    refValue={folioRef} maxLength={64} defaultValue=""
                                    placeholder="Escriba el folio del movimiento escalafonario"
                                    onChangeExecute={setFolio} disabled={loadingIniciarEscalafon}
                                    required
                                />
                            </div>
                            <div className="col">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <InputDateValidation
                                    title="Fecha Inicial"
                                    tooltipText="Seleccione la fecha de inicio del movimiento escalafonario"
                                    validateDateGreaterEqualThan={fechaMinima}
                                    refValue={fechaInicialRef} disabled={loadingIniciarEscalafon}
                                    required
                                />
                            </div>
                            <div className="col">
                                {(temporal) &&
                                <InputDateValidation
                                    title="Fecha Final"
                                    tooltipText="Seleccione la fecha de fin del movimiento escalafonario"
                                    validateDateGreaterEqualThan={fechaMinima}
                                    refValue={fechaFinalRef} disabled={loadingIniciarEscalafon}
                                    required
                                />
                                }
                            </div>
                        </div>
                    </Fragment>
                :   (fechaMinima === 0)
                    ?   <AlertForm message="No hay fecha mínima para verificar"/>
                    :   <SpinnerOval/>
                }
            </Fragment>
            }
            { error }
            {(loadingIniciarEscalafon)
            ?   <SpinnerOval/>
            :   <Button variant="contained" className="btn-color" type="submit">
                    Iniciar Escalafón
                    { (definitivo) && ' Definitivo' }
                    { (temporal) && ' Temporal' }
                </Button>
            }
            <AlertError show={openAlertE} setOpenAlertSyE={setOpenAlertE} title="Petición Fallida" text={verifique_conexion} textbtnconfirm="Aceptar" type="error"/>
        </form>
    );
}
 
export default NuevoMovimiento;