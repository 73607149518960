import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { floatToAmount } from '../../../../../../Services/Validation/HelperValidation';

const CardNomina = ({ nomina, setElementSelect, setOpenAdd, percepciones, deducciones }) => {
    const seleccionarElemento = () => {
        setElementSelect( percepciones );
        setOpenAdd( true );
    }

    return (
        <React.Fragment>
            { percepciones && deducciones ?
                <Card className="cardBoard" onClick={ () => seleccionarElemento() }>
                    <CardContent className="cardNomina">
                        <p className="nameNomina">{nomina.nominaNombre}</p>

                        <div>
                            <div className="mb-2">
                                <b> Periodo: </b> <br/>
                                {percepciones.fecIni} - {percepciones.fecFin}
                            </div>

                            <div className="mb-2">
                                <b> NUEs: </b> <br/>
                                {percepciones.empleados}
                            </div>

                            <div className="mb-2">
                                <b> Total de Percepciones: </b> <br/>
                                {floatToAmount(percepciones.monto, 2)}
                            </div>

                            <div className="mb-2">
                                <b> Total de Deducciones: </b> <br/>
                                {floatToAmount(deducciones.monto, 2)}
                            </div>
                        </div>
                    </CardContent>
                </Card>
                :
                <Card className="cardBoard">
                    <CardContent className="cardNomina">
                        <p className="nameNomina">{nomina.nominaNombre}</p>

                        <div className="sinNomina">
                            No existe ningún proceso de cálculo
                        </div>
                    </CardContent>
                </Card>
            }
        </React.Fragment>
    );
};

export default CardNomina;