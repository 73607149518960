import React, { useState, Fragment } from 'react';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import { validarSubmit, longDateToInput, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import Button from '@material-ui/core/Button';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import CropService from '../../../CropService/CropService';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../Media/Img/profile.jpg';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import AlertForm from '../../../../../../Services/AlertForm';
import { Spinner } from 'reactstrap';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BlueCheckboxRadioStyles from '../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';

const CheckboxEsMadre = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const FormDatosPersonales = (props) => {

    const {
        idModulo, elementSelect, verificaCambiosFormEmpleado, actualizar, siguiente, nivelesEscolaridad, tiposEstadoCivil,
        numEmpleadoRef, folioEmpleadoRef, equipoEmpleadoRef, esMadre, setEsMadre, mostrarMadre, muestraMadre, datosNUE,
        nombreRef, pApellidoRef, sApellidoRef, fechaNacimientoRef, generoRef, nivelEscolaridadRef,
        curpRef, estadosNacimiento, lNacimientoRef, estadoCivilRef, rfcRef, cedProfesionalRef, carreraRef, nssRef,
        fechaIngresoRef, infoEmpleado, infoEscolaridad, fechaInicialGobierno, fechaInicialContratoRef, fechaFinalContratoRef, 
        fechaNombramientoRef, fechaAguinaldoRef,
        fotografia, setFotografia, fotografiaEdit, setFotografiaEdit, verificaNUE, openAlertNUE, setOpenAlertNUE, nueDisabled,
        setError, longitudCURP, longitudRFC, asignaFechaEdoNacGen, lengthCURP, lengthRFC,
        isLoading
    } = props;

    const [openAlert, setOpenAlert] = useState(false);
    const [mostrarContenido, setMostrarContenido] = useState(false);
    const genero = [{ id: 'F', genero: 'Femenino' }, { id: 'M', genero: 'Masculino' }];

    const mostrarAlert = () => {
        if(validarSubmit('DatosPersonales') & verificaCambiosFormEmpleado()) {
            if(
                numEmpleadoRef.current.value !== infoEmpleado.empleado.empleadoclave ||
                folioEmpleadoRef.current.value !== infoEmpleado.empleado.folioAlta ||
                equipoEmpleadoRef.current.value !== infoEmpleado.empleado.equipo ||
                nombreRef.current.value !== infoEmpleado.empleado.empleadonom ||
                pApellidoRef.current.value !== (infoEmpleado.empleado.empleadopat ? infoEmpleado.empleado.empleadopat : '') ||
                sApellidoRef.current.value !== (infoEmpleado.empleado.empleadomat ? infoEmpleado.empleado.empleadomat : '') ||
                fechaNacimientoRef.current.value !== longDateToInput(infoEmpleado.empleado.empleadofecnac) ||
                parseInt(lNacimientoRef.current.value) !== infoEmpleado.empleado.estado.id ||
                generoRef.current.value !== (infoEmpleado.empleado.empleadoGenero ? infoEmpleado.empleado.empleadoGenero : '') ||
                parseInt(estadoCivilRef.current.value) !== infoEmpleado.empleado.edoCivil.id ||
                rfcRef.current.value !== (infoEmpleado.empleado.empleadorfc ? infoEmpleado.empleado.empleadorfc : '') ||
                curpRef.current.value !== (infoEmpleado.empleado.empleadocurp ? infoEmpleado.empleado.empleadocurp : '') ||
                nssRef.current.value !== (infoEmpleado.empleado.empleadonss ? infoEmpleado.empleado.empleadonss : '') ||
                inputDateToLong(fechaIngresoRef.current.value) !== inputDateToLong(fechaInicialGobierno) ||
                parseInt(nivelEscolaridadRef.current.value) !== infoEscolaridad.escolaridad.id ||
                carreraRef.current.value !== (infoEscolaridad.empleadoCarrera ? infoEscolaridad.empleadoCarrera : '') ||
                cedProfesionalRef.current.value !== (infoEscolaridad.empleadoCedula ? infoEscolaridad.empleadoCedula : '') ||
                fotografiaEdit
            ) {
                setOpenAlert(true);
            } else {
                setError(<AlertForm message="No se ha realizado algún cambio para actualizar" />);
            }
        }
    };
    
    const obtenerBase64 = imgBase64 => {
        if(elementSelect) {
            setFotografiaEdit(imgBase64);
        } else {
            setFotografia(imgBase64);
        }
    };

    const verificaDatosNUE = e =>  {
        e.preventDefault();
        if(datosNUE === null) {
            mostrarAlert();
        } else {
            setOpenAlertNUE(true);
        }
    };

    const validaFechaIngreso = value => {
        if(fechaInicialContratoRef.current.value !== '') {
            if(inputDateToLong(fechaInicialContratoRef.current.value) < inputDateToLong(value)) {
                fechaInicialContratoRef.current.value = '';
            }
        }

        if(fechaFinalContratoRef.current) {
            if(fechaFinalContratoRef.current.value !== '') {
                if(inputDateToLong(fechaFinalContratoRef.current.value) < inputDateToLong(value)) {
                    fechaFinalContratoRef.current.value = '';
                }
            }
        }

        if(fechaNombramientoRef.current) {
            if(fechaNombramientoRef.current.value !== '') {
                if(inputDateToLong(fechaNombramientoRef.current.value) < inputDateToLong(value)) {
                    fechaNombramientoRef.current.value = '';
                }
            }
        }

        if(fechaAguinaldoRef.current.value !== '') {
            if(inputDateToLong(fechaAguinaldoRef.current.value) < inputDateToLong(value)) {
                fechaAguinaldoRef.current.value = '';
            }
        }
    };

    const handleChangeEsMadre = () => {
        setEsMadre(!esMadre);
    };

    setTimeout(() => {
        setMostrarContenido(true);
    }, 1000);

    return (
        <Fragment>
            {
                (!elementSelect || (infoEscolaridad !== undefined && mostrarContenido === true))
                ?   <Fragment>
                        <h5>Datos Personales</h5>
                        <form id="DatosPersonales" onSubmit={e => siguiente(e, 2)}>
                                <div className="row">
                                    <div className="col-8 col-sm-9 col-md-10 col-lg-11 col-xl-4">
                                        <InputValidation title="NUE" type="numEmpleado" placeholder="Escriba el NUE"
                                            refValue={numEmpleadoRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadoclave : ''}
                                            onBlurExecute={verificaNUE}
                                            tooltipText="Máximo 10 números" maxLength={10} disabled={nueDisabled}
                                            onlyNumbers required
                                        />
                                    </div>
                                    <div className="col-4 col-sm-3 col-md-2 col-lg-1 col-xl-1" style={{ marginTop: '2.5rem' }}>
                                        {(nueDisabled) && <Spinner/> }
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                        <InputValidation title="Folio" type="folioEmpleado" placeholder="Escriba el folio"
                                            refValue={folioEmpleadoRef}
                                            defaultValue={infoEmpleado ? infoEmpleado.empleado.folioAlta : ''}
                                            tooltipText="Máximo 10 números" maxLength={10} onlyNumbers optional
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                        <div className="imagen-upload btn-addPhotoEmp">
                                            <label style={{ backgroundColor: 'transparent'}} htmlFor="fotoEmpleado" type="button"><AddAPhotoIcon className="photoIcon"/></label>
                                            <CropService inputId="fotoEmpleado" parentNode={idModulo} getBase64={obtenerBase64} fotoEmpleado/>
                                        </div>
                                        <div className="avatar-addPhotoEmp">
                                            <Avatar alt="avatar" src={(fotografia) ? `data:image/jpeg;base64, ${(elementSelect) ? (fotografiaEdit) ? fotografiaEdit : atob(fotografia.ruta) : fotografia}` : (elementSelect) ? (fotografiaEdit) ? `data:image/jpeg;base64, ${fotografiaEdit}` : profileIMG : profileIMG}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <InputValidation title="CURP" type={(lengthCURP < 18) ? 'text' : 'curp'}
                                            placeholder="Escriba el CURP" action={longitudCURP}
                                            refValue={curpRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadocurp : ''} 
                                            tooltipText="18 letras y números en formato de CURP" tooltipName="tooltipCURP" maxLength={18}
                                            alfanumeric optional={true} onChangeExecute={(!elementSelect) ? asignaFechaEdoNacGen : false}/>
                                    </div>
                                    <div className="col-6">
                                        <InputValidation title="RFC" type={(lengthRFC < 13) ? 'text' : 'rfc'}
                                            placeholder="Escriba el RFC" action={longitudRFC}
                                            refValue={rfcRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadorfc : ''}
                                            tooltipText="13 letras y números en formato de RFC" tooltipName="tooltipRFC" maxLength={13} alfanumeric optional={true}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <InputValidation title="Primer Apellido" type="pApellido" placeholder="Escriba el primer apellido" refValue={pApellidoRef}
                                            defaultValue={infoEmpleado ? (infoEmpleado.empleado.empleadopat ? infoEmpleado.empleado.empleadopat : '') : ''} 
                                            tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipPApellido" onlyLetters=" " maxLength={30} optional={true}/>
                                    </div>
                                    <div className="col-6">
                                        <InputValidation title="Segundo Apellido" type="sApellido" placeholder="Escriba el segundo apellido" refValue={sApellidoRef}
                                            defaultValue={infoEmpleado ? (infoEmpleado.empleado.empleadomat ? infoEmpleado.empleado.empleadomat : '') : ''}
                                            tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipSApellido" onlyLetters=" " maxLength={30} optional={true}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <InputValidation title="Nombre" type="nombre" placeholder="Escriba el nombre"
                                            refValue={nombreRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadonom : ''} 
                                            tooltipText="Máximo 60 letras y/o espacio" tooltipName="tooltipNomEmp" onlyLetters=" " maxLength={60} required/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <InputDateValidation title="Fecha de Nacimiento" refValue={fechaNacimientoRef}
                                            defaultValue={elementSelect ? longDateToInput(elementSelect.empleadoFecnac) : ''} validateEqualLess required/>
                                    </div>
                                    <div className="col-6">
                                        <SelectValidation title="Lugar de Nacimiento" arrayOpciones={estadosNacimiento} keyOpcion="estadoNom"
                                            refValue={lNacimientoRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.estado.id : ''} required/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <SelectValidation
                                            title="Género" arrayOpciones={genero} keyOpcion="genero" refValue={generoRef}
                                            defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadoGenero : ''}
                                            optional={true} onChangeExecute={muestraMadre}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectValidation title="Estado Civil" arrayOpciones={tiposEstadoCivil} keyOpcion="edoCivilnom"
                                            refValue={estadoCivilRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.edoCivil.id : ''} required/>
                                    </div>
                                </div>
                                {mostrarMadre &&
                                    <div className="row">
                                        <div className="col">
                                            <FormControlLabel
                                                control={<CheckboxEsMadre checked={esMadre} onChange={handleChangeEsMadre} name="checkedEsMadre"/>}
                                                label="Madre"
                                            />
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-6">
                                        <SelectValidation title="Escolaridad" arrayOpciones={nivelesEscolaridad} keyOpcion="escolaridadNom"
                                            refValue={nivelEscolaridadRef} defaultValue={infoEmpleado ? infoEscolaridad !== null ? infoEscolaridad.escolaridad.id : '' : ''} required/>
                                    </div>
                                    <div className="col-6">
                                        <InputValidation title="Cédula Profesional" type="cedulaProfesional" placeholder="Escriba la cédula profesional"
                                            refValue={cedProfesionalRef} defaultValue={infoEmpleado ? infoEscolaridad !== null ? infoEscolaridad.empleadoCedula : '' : ''}
                                            tooltipText="Máximo 13 letras, números y/o guión" tooltipName="tooltipCedulaProf" maxLength={13} optional={true}/>
                                    </div>
                                </div>
                                <InputValidation title="Carrera" type="carrera" placeholder="Escriba el nombre de la carrera"
                                    refValue={carreraRef} defaultValue={infoEmpleado ? infoEscolaridad !== null ? infoEscolaridad.empleadoCarrera : '' : ''} 
                                    tooltipText="Máximo 230 letras y/o espacio" tooltipName="tooltipCarrera" maxLength={230} onlyLetters=" " optional={true}
                                />
                                <InputValidation title="Equipo" type="equipoEmpleado" placeholder="Escriba el nombre del equipo"
                                    refValue={equipoEmpleadoRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.equipo : ''} 
                                    tooltipText="Máximo 256 letras, números y/o espacio" maxLength={256} alfanumeric=" " optional={true}
                                />
                                <div className="row">
                                    <div className="col-6">
                                        <InputValidation title="Número de Seguridad Social" type="nss" placeholder="Escriba el NSS"
                                            refValue={nssRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadonss : ''} 
                                            tooltipText="11 números" tooltipName="tooltipNSS" maxLength={11} onlyNumbers optional={true}/>
                                    </div>
                                    <div className="col-6">
                                        <InputDateValidation title="Fecha de Ingreso a Gobierno" refValue={fechaIngresoRef} disabled={(elementSelect) ? true : false }
                                            defaultValue={elementSelect ? fechaInicialGobierno : ''} onBlurExecute={(!elementSelect) ? validaFechaIngreso : undefined}
                                            validateEqualLess required
                                        />
                                    </div>
                                </div>
                                {
                                    (elementSelect)
                                    ?   <div className="row">
                                            <div className="col-6">
                                                {(isLoading)
                                                ?   <SpinnerOval/>
                                                :   <Button variant="outlined" className="btn-outline" onClick={verificaDatosNUE}>Actualizar</Button>
                                                }
                                            </div>
                                            <div className="col-6">
                                                <Button variant="contained" className="btn-color" type="submit">Siguiente</Button>
                                            </div>
                                        </div>
                                    :   <Button variant="contained" className="btn-color" type="submit">Siguiente</Button>
                                }
                        </form>
                        {   elementSelect &&
                            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
                        }
                        <AlertSyE show={openAlertNUE} setOpenAlertSyE={setOpenAlertNUE} title="Este NUE ya se encuentra registrado con los siguientes datos:" text={datosNUE} textbtnconfirm="Aceptar" type="error"/>
                    </Fragment>
                :   null
            }
        </Fragment>
    );
}
 
export default FormDatosPersonales;