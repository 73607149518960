import React, { useState, Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import TooltipComponent from '../../../../../../../Services/Tooltip/TooltipComponent';
import { InfoRounded } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import CollapseMaterial from '../../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/CollapseMaterial';

const NominaCheckbox = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const NominasCheck = ({ nominasCheckSuperior, nominasCheckSelected, setNominasCheckSelected }) => {

    const [elementoActivo, setElementoActivo] = useState(0);
    const [seleccionaTodo, setSeleccionaTodo] = useState([]);

    const handleChange = e => {
        if(nominasCheckSelected.find(item => item === parseInt(e.target.name))) {
            setNominasCheckSelected(nominasCheckSelected.filter(item => item !== parseInt(e.target.name)));
        } else {
            setNominasCheckSelected([...nominasCheckSelected, parseInt(e.target.name)]);
        }
    };

    const handleChangeRL = (e, nominasID) => {
        if(seleccionaTodo.find(item => item === parseInt(e.target.name))) {
            const newNominasCheck = nominasCheckSelected.filter(nom => !nominasID.includes(nom));
            setNominasCheckSelected(newNominasCheck);
            setSeleccionaTodo(seleccionaTodo.filter(item => item !== parseInt(e.target.name)));
        } else {
            const newNominasCheck = nominasID.filter(nom => !nominasCheckSelected.includes(nom));
            setNominasCheckSelected(nominasCheckSelected.concat(newNominasCheck));
            setSeleccionaTodo([...seleccionaTodo, parseInt(e.target.name)]);
        }
    };

    return (
        <div className="row">
            <div className="col">
                <FormControl component="fieldset">
                    <label>
                        Nóminas
                        <TooltipComponent tooltipText="Seleccione las nóminas requeridas">
                            <span className="spanInfo"><InfoRounded color="disabled"/></span>
                        </TooltipComponent>
                    </label>
                    <FormGroup>
                    { nominasCheckSuperior
                        ?   <Fragment>
                                {nominasCheckSuperior.map((rl, i) => (
                                    <CollapseMaterial key={i} title={rl.nombre} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
                                        <div className="accesosContenido mb-2">
                                            <div className="accesosIzquierdo">
                                                <NominaCheckbox
                                                    checked={(seleccionaTodo.find(item => item === rl.id)) ? true : false}
                                                    onChange={(e) => handleChangeRL(e, rl.nominasID)} name={rl.id}
                                                />
                                            </div>
                                            <div className="accesoserecho mt-2">
                                                <span>Selecciona todas las nóminas de la Relación Laboral</span>
                                            </div>
                                        </div>
                                        {rl.nominas.map((nomina, i) => (
                                            <div key={nomina.id} className="accesosContenido mb-2">
                                                <div className="accesosIzquierdo">
                                                    <NominaCheckbox
                                                        checked={(nominasCheckSelected.find(item => item === nomina.id)) ? true : false}
                                                        onChange={handleChange} name={nomina.id}
                                                    />
                                                </div>
                                                <div className="accesoserecho">
                                                    <span><b>Clave: </b>{nomina.nominaClave}</span><br/>
                                                    <span><b>Nombre: </b>{nomina.nominaNombre}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </CollapseMaterial>
                                ))}
                            </Fragment>
                        :   <SpinnerOval />
                    }
                    </FormGroup>
                </FormControl>
            </div>
        </div>
    );
}
 
export default NominasCheck;