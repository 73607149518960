import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteAcumulados = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const servicioEliminar = () => {
        setIsLoading(true);
        axios.delete(`${servicios_grupohit_nomina}/acumulados/tacumulados/${elementSelect.id}`, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect.id);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_eliminado))
        .finally(() => setIsLoading(false));
    }

    return(
        <Fragment>
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
            <AlertWarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={servicioEliminar} />
        </Fragment>
    );
}

export default ModalDeleteAcumulados;