import React from 'react';
import './styles.css';

const AlertForm = ({ message, warning, info, withoutMargin }) =>{
    return(
        <div
            role="alert"
            className={`alert alert-${(warning) ? 'warning' : (info) ? 'info' : 'danger'} alert_form col-12 ${(withoutMargin) ? 'mt-0' : ''}`}
        >
            <span>{message}</span>
        </div>
    );
}

export default AlertForm;