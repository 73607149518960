import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import AlertWarn from '../../../../../../../Services/Alerts/AlertWarning';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const InformacionCertificados = ({ certificados, setNuevosCertificados }) => {
    const [ showPass, setShowPass ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );
    const cambiarFormulario = () => {
        setNuevosCertificados( true );
    }

    return (
        <React.Fragment>
            <div className="titleArchivos mb-3">Archivos</div>
            <div className="mb-3">
                <div> <b>Extensión de Archivo:</b> .cer </div>
                <div>
                    <b>Nombre de Archivo: </b>
                    <span className="breakWordModals"> {certificados.find(element => element.ext === ".cer").Nombre} </span>
                </div>
            </div>
            <div className="mb-3">
                <div> <b>Extensión de Archivo:</b> .key </div>
                <div>
                    <b>Nombre de Archivo: </b>
                    <span className="breakWordModals"> {certificados.find(element => element.ext === ".key").Nombre} </span>
                </div>
            </div>
            <div className="movimientosFlex mb-4">
                <div className="movimientosIzquerdo">
                    <span> <b>Contraseña:</b> {!showPass ? ''.padStart(certificados[0].pass.length, "*") : certificados[0].pass} </span>
                </div>
                <div className="movimientosUploadDerecho text-right">
                    <span className="iconPassword2" onClick={() => setShowPass(!showPass)}> {!showPass ? <Visibility/> : <VisibilityOff/>} </span>
                </div>
            </div>
            <Button variant="contained" className="btn-color" onClick={() => setAlertWarning(true)}> Cargar Nuevo Certificado </Button>
            <AlertWarn show={alertWarning} SetopenALert={setAlertWarning} text="Se eliminarán los certificados anteriores" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={cambiarFormulario} />
        </React.Fragment>
    );
};

export default InformacionCertificados;