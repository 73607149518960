import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, deleteOptionals, compararFechas } from '../../../../../../../Services/Validation/HelperValidation';
//import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';

import IndicadorRelacionadoEdit from './IndicadorRelacionado';
import IndicadorRelacionadoAdd from './IndicadorRelacionadoAdd';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

const FormularioRelacionados = ({ error, enviarRelacionado, elementSelect, actualizarRelacionado, informacion, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ errorDate, setErrorDate ] = useState( null );
    const [ nominaIndicador, setNominaIndicador ] = useState( elementSelect ? informacion.indicadoresNomina.find( element => element.id === elementSelect.nomina.id ) : null );
    const [ errorIndicador, setErrorIndicador ] = useState( null );
    const [ indicadoresAdd, setIndicadoresAdd ] = useState( [] );
    const [ indicadoresDelete, setIndicadoresDelete ] = useState( [] );
    const acumuladoRef = createRef('');
    const fechaInicioRef = createRef('');
    const fechaFinalRef = createRef('');
    const empensionIdRef = createRef('');
    const [ pensionSelect, setPensionSelect ] = useState( null );

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarRelacionado' ) ){
            if( nominaIndicador ){
                const nomina = nominaIndicador.id;
                const acumulado = parseInt( acumuladoRef.current.value );
                const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
                const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
                const empensionId = empensionIdRef.current !== null ? parseInt( empensionIdRef.current.value ) ? parseInt( empensionIdRef.current.value ) : '' : '';
                if( compararFechas(fechaInicio, fechaFinal) ){
                    setErrorDate( null );
                    enviarRelacionado([ deleteOptionals({ empleado: informacion.empleado.id, nomina, acumulado, fechaInicio, fechaFinal, empensionId }) ], indicadoresAdd);
                }else{
                    setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                }
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarRelacionado' ) ){
            const nomina = elementSelect.nomina.id;
            const acumulado = parseInt( acumuladoRef.current.value );
            const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
            const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
            const empensionId = empensionIdRef.current !== null ? parseInt( empensionIdRef.current.value ) ? parseInt( empensionIdRef.current.value ) : '' : '';
            if( compararFechas(fechaInicio, fechaFinal) ){
                setErrorDate( null );
                actualizarRelacionado([ deleteOptionals({ id: elementSelect.id, empleado: informacion.empleado.id, nomina, acumulado, fechaInicio, fechaFinal, empensionId }) ], indicadoresDelete);
            }else{
                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
            }
        }
    };

    const validarIndicador = (valor) => {
        if(valor) {
            setErrorIndicador( null );
            setNominaIndicador( valor );
        }else{
            setErrorIndicador( true );
            setNominaIndicador( null );
        }
    };

    const obtenerPensionEmpleado = ( pensionID ) => {
        if( pensionID !== '' ){
            setPensionSelect( informacion.pensiones.find( element => element.id === parseInt(pensionID) ) );
        }else{
            setPensionSelect( null );
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarRelacionado' : 'AgregarRelacionado' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                { elementSelect ?
                    <div> <b> Concepto de Nómina: </b> {elementSelect.nomina.nomIndicador} </div>
                    :
                    <AutocompleteComponent id="indicadorRelacionado" title="Concepto de Nómina" placeholder="Seleccione el Concepto de Nómina" size="100%"
                        tooltipName="tooltipIndicadorRelacionado" tooltipText={'Busque el Concepto, por nombre o clave, en el campo de autocompletado'} action={validarIndicador}
                        options={informacion.indicadoresNomina} optionListView={'nomIndicador'} optionKey="nomClave" error={errorIndicador} required/>
                }

                <SelectValidation title="Tipo de Acumulado" arrayOpciones={informacion.tiposAcumulado} keyOpcion="acumuladoNombre"
                    refValue={acumuladoRef} defaultValue={ elementSelect ? elementSelect.acumulado.id : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechaInicioRef} required
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaInicio) : '' } />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} optional
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaFinal) : '' } />
                    </div>
                </div>
                { informacion.pensiones && informacion.pensiones.length > 0 && nominaIndicador && nominaIndicador.rutina.id === 16 && //rutinaNom: "MOVIMIENTO RELACIONADO" (PENSION ALIMENTICIA)
                    <SelectValidation title="Pensiones" arrayOpciones={informacion.pensiones} keyOpcion="informacion"
                        refValue={empensionIdRef} defaultValue={ elementSelect ? elementSelect.empensionId : '' } optional
                        onChangeExecute={obtenerPensionEmpleado}/>
                }
                { pensionSelect &&
                    <React.Fragment>
                        <div> <b> Número de Folio: </b> {pensionSelect.folio} </div>
                        <div> <b> Nombre: </b> {pensionSelect.nombre} </div>
                        <div className="row mb-2">
                            <div className="col-md-6 col-sm-12">
                                <div> <b> Primer Apellido: </b> {pensionSelect.apellidoMat} </div>
                                <div> <b> Tipo de Cuota: </b> {pensionSelect.tipoCuota === 'I' ? 'IMPORTE' : pensionSelect.tipoCuota === 'P' ? 'PORCENTAJE' : pensionSelect.tipoCuota} </div>
                                <div> <b> Fecha de Inicio: </b> {pensionSelect.fechaIniTab} </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div> <b> Segundo Apellido: </b> {pensionSelect.apellidoPat} </div>
                                <div> <b> Monto: </b> {pensionSelect.montoPorc} </div>
                                <div> <b> Fecha Final: </b> {pensionSelect.fechaFinTab} </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                {error}
                {errorIndicador}
                {errorDate}
                { elementSelect ?
                    <IndicadorRelacionadoEdit elementSelect={elementSelect} informacion={informacion} indicadoresDelete={indicadoresDelete} setIndicadoresDelete={setIndicadoresDelete}/>
                    : <IndicadorRelacionadoAdd informacion={informacion} indicadoresAdd={indicadoresAdd} setIndicadoresAdd={setIndicadoresAdd}/>
                }
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioRelacionados;