import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';

const BlueRadio = withStyles(BlueCheckboxRadioStyles)((props) => <Radio color="default" {...props} />);

const RadioTipoMovimiento = ({ action }) => {
    return (
        <div className="row justify-content-center align-items-center mt-2">
            <div className="col-5">
                 <label>Tipo de Movimiento</label>
            </div>
            <div className="col-7">
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="position" defaultValue="Permuta" onChange={action}>
                        <FormControlLabel value="Permuta" control={<BlueRadio />} label={<label>Permuta</label>} labelPlacement="end"/>
                        <FormControlLabel value="Transferencia" control={<BlueRadio />} label={<label>Transferencia</label>} labelPlacement="end"/>
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
}
 
export default RadioTipoMovimiento;