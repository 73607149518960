import React, { Fragment, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_catalogo, servicios_grupohit_config, servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import ModalService from '../../../../Usuario/ModalService/ModalService';  
import InfoEmpleado from '../../Tablero/InfoEmpleado';
import FormAcumulados from '../../Nomina/Acumulados/FormAcumulados';
import FormAddConceptosDeduccion from './FormAddConceptosDeduccion';
import FormularioReciboPago from './FormularioReciboPago';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const ModalSimuladorReciboPago = ({ openEdit, setOpenEdit, elementSelect, id, nombreModulo }) => {

    const [muestraAcumulados, setMuestraAcumulados] = useState(false);
    const [anios, setAnios] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [acumulados, setAcumulados] = useState([]);
    const [periodo, setPeriodo] = useState({ id: '', nombre: '' });
    const [error, setError] = useState(null);
    const [indicadoresSelected, setIndicadoresSelected] = useState([]);
    const [percepciones, setPercepciones] = useState([]);
    const [deducciones, setDeducciones] = useState([]);
    const [totalP, setTotalP] = useState(0);
    const [totalD, setTotalD] = useState(0);
    const [opentAlertE, setOpenAlertE] = useState(false);
    const [textAlertE, setTextAlertE] = useState('');
    const [montoMinimo, setMontoMinimo] = useState(null);
    const [minimoLiquidez, setMinimoLiquidez] = useState(null);

    const anioRef = useRef('');
    const periodoRef = useRef('');
    const acumuladoRef = useRef('');
    const empresaRef = useRef('');
    const quincenasRef = useRef('');
    const elaboroRef = useRef('');
    
    useEffect(() => {
        if(elementSelect?.id) {
            axios.get(`${servicios_grupohit_rh}/empleado/liquidez/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setMinimoLiquidez(res.data.dataResponse.minimoLiquidez);
                        break;
                    default:
                        setTextAlertE(res.data.msg);
                        setOpenAlertE(true);
                        break;
                }
            })
            .catch(error => {
                setTextAlertE(verifique_conexion);
                setOpenAlertE(true);
                console.log(error);
            });
    
            axios.get(`${servicios_grupohit_catalogo}/montominimo/`, headersAuth())
            .then(res => {
                if(res.data.status === 200) {
                    setMontoMinimo(res.data.dataResponse[0].montominimo);
                } else {
                    setTextAlertE(verifique_conexion);
                    setOpenAlertE(true);
                }
            })
            .catch(error => {
                setTextAlertE(verifique_conexion);
                setOpenAlertE(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const cerrarModal = () => {
        setError(null);
        setAnios([]);
        setPeriodos([]);
        setAcumulados([]);
        setPeriodo({ id: '', nombre: '' });
        setMuestraAcumulados(false);
        setOpenEdit(false); 
        setMontoMinimo(0);
    };

    const crearZip = data => {
        var zip = new JSZip();
 
        zip.file(`SIMULADOR DE RECIBO DE PAGO ${elementSelect.empleadoClave} - ${elementSelect.nombreCompleto}.pdf`, data, { base64: true }); 
        
        zip.generateAsync({ type:"blob" }).then(function(content) {
            saveAs(content, `SIMULADOR DE RECIBO DE PAGO ${elementSelect.empleadoClave} - ${elementSelect.nombreCompleto}.zip`);
        });

    };

    const descargarRecibo = () => {
        if(validarSubmit('form-datos')) {
            const percepcionesRequest = percepciones.map(p => ({
                indNomClave: p.nominaClave,
                indNomNombre: p.nominaIndicador,
                importe: p.monto
            }));
            const deduccionesRequest = deducciones.map(d => ({
                indNomClave: d.nominaClave,
                indNomNombre: d.nominaIndicador,
                importe: d.monto
            }));

            const request = {
                "nuEmp": elementSelect.empleadoClave,
                "nombre": elementSelect.nombreCompleto,
                "rfc": elementSelect.empleadoRfc,
                "relacionLaboral": `${elementSelect.relabClave} ${elementSelect.realabNom}`,
                "depend/secre": `${elementSelect.ciaUr} ${elementSelect.ciaRazonSocial}`,
                "quincena": parseInt(periodoRef.current.value),
                "elaboro": elaboroRef.current.value,
                "empresa": empresaRef.current.value,
                "plazo": quincenasRef.current.value,
                "fechaIngreso": elementSelect.empantFecini,
                "TotalP": totalP,
                "TotalD": totalD,
                "TotalL": totalP - totalD,
                "percepciones": percepcionesRequest,
                "deducciones": deduccionesRequest,
            };
    
            axios.post(`${servicios_grupohit_config}/reportes/ficha/liquidez/`, request, headersAuth())
            .then((res) => {
                switch(res.data.status) {
                    case 200:
                        crearZip(res.data.dataResponse);
                        break;
                    default:
                        setTextAlertE(res.data.msg);
                        setOpenAlertE(true);
                        break;
                }
            })
            .catch((error) => {
                setTextAlertE(verifique_conexion);
                setOpenAlertE(true);
            });
        }
    };

    return (
        <ModalService
            title={nombreModulo}
            parentNode={id}
            isOpen={openEdit}
            onClose={cerrarModal} 
            minWidth='80%' 
            showCloseIcon={true}
        >
            <InfoEmpleado nombreEmpleado={elementSelect.nombreCompleto} claveEmpleado={elementSelect.empleadoClave}/>
            {((montoMinimo !== null) && (minimoLiquidez !== null))
            ?   <Fragment>
                    <div className="row">
                        <div className="col-12">
                            <FormAcumulados
                                elementSelect={elementSelect}
                                anios={anios}
                                setAnios={setAnios}
                                anioRef={anioRef}
                                periodos={periodos}
                                setPeriodos={setPeriodos}
                                periodoRef={periodoRef}
                                acumulados={acumulados}
                                setAcumulados={setAcumulados}
                                acumuladoRef={acumuladoRef}
                                setMuestraTable={setMuestraAcumulados}
                                error={error}
                                setError={setError}
                                simulador
                            />
                            {(muestraAcumulados) &&
                            <Fragment>
                                <FormAddConceptosDeduccion
                                    empresaRef={empresaRef}
                                    quincenasRef={quincenasRef}
                                    elaboroRef={elaboroRef}
                                    nominaId={elementSelect.nominaId}
                                    indicadoresSelected={indicadoresSelected}
                                    setIndicadoresSelected={setIndicadoresSelected}
                                    totalPD={totalP - totalD}
                                    minimoLiquidez={minimoLiquidez}
                                    montoMinimo={montoMinimo}
                                />
                                <hr/>
                                <FormularioReciboPago
                                    elementSelect={{
                                        ...elementSelect,
                                        empleadoId: elementSelect.id,
                                        periodoId: periodoRef.current.value,
                                        acumuladoId: acumuladoRef.current.value
                                    }}
                                    periodo={periodo}
                                    busquedaAvanzada
                                    indicadoresSelected={indicadoresSelected}
                                    setIndicadoresSelected={setIndicadoresSelected}
                                    setPercepcionesActivas={setPercepciones}
                                    setDeduccionesActivas={setDeducciones}
                                    setTotalP={setTotalP}
                                    setTotalD={setTotalD}
                                    montoMinimo={montoMinimo}
                                />  
                            </Fragment>
                            }
                        </div>
                    </div>
                    {(muestraAcumulados) &&
                    <Fragment>
                        <TooltipComponent tooltipText="Descargar">
                            <button className="btn-autocomplete btnMax" onClick={descargarRecibo}><PictureAsPdfIcon /></button>
                        </TooltipComponent>
                        <AlertError show={opentAlertE} setOpenAlertSyE={setOpenAlertE} title="Petición Fallida" text={textAlertE} textbtnconfirm="Aceptar" type="error" />
                    </Fragment>
                }
                </Fragment>
            :   <SpinnerOval/>
            }
        </ModalService>
    );
}
 
export default ModalSimuladorReciboPago;