import React, { createRef, useState } from 'react';
import axios from 'axios';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import { Button }from '@material-ui/core'; 
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { inputDateToLong, validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import AlertForm from '../../../../../../../Services/AlertForm';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit, verifique_conexion } from '../../../../../../../Constants/Constants';
import { descargaPDF, descargaXLS } from '../Helpers/DescargaArchivo';

const urls = {
    160: `${servicios_grupohit}/generar/reportes/registrosinsertmongo/`,
    161: `${servicios_grupohit}/generar/reportes/registrosupdatemongo/`
}; 

const Formulario = ({ reporte, cerrarModal }) => {

    const { reportesId, reportesReporteNom } = reporte;

    const [loading, setLoading] = useState(false);
    const [fecIni, setFecIni] = useState('');
    const [fecFin, setFecFin] = useState('');
    const [error, setError] = useState('');

    const fechaInicialRef = createRef();
    const fechaFinalRef = createRef();

    const obtieneFechaInicial = value => {
        setFecIni(inputDateToLong(value));
    };

    const obtieneFechaFinal = value => {
        setFecFin(inputDateToLong(value));
    };

    const envia = (type) => {
        if(validarSubmit('formReporteRH')) {
            if(fecFin >= fecIni) {
                setError(null);
                setLoading(true);
                const request = {
                    fechaInicial: fechaInicialRef.current.value,
                    fechaFinal: fechaFinalRef.current.value
                };

                axios.post(`${urls[reportesId]}${type}/`, request, headersAuth())
                .then(res => {
                    switch (res.data.status) {
                        case 200:
                            if(type==='csv') descargaXLS(reportesReporteNom, res.data.dataResponse);
                            if(type==='pdf') descargaPDF(reportesReporteNom, res.data.dataResponse);
                            setFecIni('');
                            setFecFin('');
                            cerrarModal();
                            break;
                        default:
                            setError(<AlertForm message={res.data.msg}/>);
                            break;
                    }
                })
                .catch(error => {
                    setError(<AlertForm message={verifique_conexion}/>);
                })
                .finally(() => setLoading(false));

            } else {
                setError(<AlertForm message="La fecha final debe ser igual o mayor a la fecha inicial" />);
            }
        }
    };

    return (
        <form id="formReporteRH">
            <div className="row">
                <div className="col">
                    <InputDateValidation title="Fecha Inicial"
                        refValue={fechaInicialRef} defaultValue="" onChangeExecute={obtieneFechaInicial}
                        tooltipText="Seleccione una fecha"
                        required
                    />
                </div>
                <div className="col">
                    <InputDateValidation title="Fecha Final"
                        refValue={fechaFinalRef} defaultValue="" onChangeExecute={obtieneFechaFinal}
                        tooltipText="Seleccione una fecha igual o mayor que la fecha inicial"
                        required
                    />
                </div>
            </div>
            {(error) &&
            <div className="row">
                <div className="col">
                    { error }
                </div>
            </div>
            }
            <div className="row">
                {(loading)
                ?   <dic className="col"><SpinnerOval/></dic>
                :   <>
                        <dic className="col">
                            <Button variant="contained" className="btn-color" onClick={() => envia('pdf')}>Generar PDF</Button>
                        </dic>
                        <dic className="col">
                            <Button variant="contained" className="btn-color" onClick={() => envia('csv')}>Generar XLSX</Button>
                        </dic>
                    </>
                }
            </div>
        </form>
    );
}
 
export default Formulario;