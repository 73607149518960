import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, contratos_relacion_laboral, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonContratoRelLab } from '../../../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const ContratosRealacionLaboral = ({ id, permisos }) => {

    const [contratosRealacionLaboral, setContratosRealacionLaboral] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false); 

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}s/contrato/sao/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setContratosRealacionLaboral(ArrayJsonContratoRelLab(res.data.dataResponse));
                    break;
                case 404:
                    setContratosRealacionLaboral([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                contratosRealacionLaboral
                ?   <DataTableService id={id} title={ contratos_relacion_laboral }
                        permisos={permisos} 
                        columns={[
                            { field: "contratoSaoNom", title: "Nombre de Contrato" },
                            { field: "tipoContrato", title: "Tipo de Contrato" },
                            { field: "relacionLaboral", title: "Relación Laboral" },
                            { field: "contratoSaoFechas", title: "Fecha" }
                        ]}
                        data={contratosRealacionLaboral}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default ContratosRealacionLaboral;
