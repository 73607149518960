import React, { useState } from 'react';
import { connect } from 'react-redux';
import { agregarNavegador } from '../../../../../Redux/actions/NavActions';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
/* ------------------------------------------------------------------------------ */
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../Media/Img/profile.jpg';
//Importacion de Iconos
import AccountCircle from '@material-ui/icons/AccountCircle';
import Chat from '@material-ui/icons/Chat';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import './Drawerstyles.css';
/* ------------------------------------------------------------------------------ */
const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(3),
    },
    color: {
        color: 'white',
    },
}));

function UserBlock({ closeSession, toogleProfile, usuario, imagenPerfil, navegadoresStore, agregarNavegador, componenteActivoID }) {
    const classes = useStyles();
    const [ open, setOpen ] = useState( false );
    const usuarioDrawer = `${usuario.usuario.substring(0,16)}...`;

    return (
        <List>
        <ListItem button onClick={ () => setOpen(!open) }>
            <ListItemAvatar>
                { !imagenPerfil ?
                    <Avatar alt="avatar" src={ profileIMG } className="my-first-step"/>
                    : <Avatar alt="avatar" src={ `data:image/jpeg;base64, ${imagenPerfil}` } className="my-first-step"/>
                }
            </ListItemAvatar>
            <Tooltip title={usuario.usuario}>
                <ListItemText classes={{ secondary: classes.color }} primary={ `${usuario.nombre} ${usuario.apaterno}` } secondary={ usuarioDrawer } />
            </Tooltip>
            {open ? <ExpandLess style={{ fontSize: 15 }} /> : <ExpandMore style={{ fontSize: 15 }} />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={toogleProfile}>
                    <ListItemIcon> 
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Perfil" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={ () => agregarNavegador(navegadoresStore, {id: 1000, nombre: 'Mensajes', ruta: 'Mensajes'}, componenteActivoID) }>
                    <ListItemIcon> 
                        <Chat />
                    </ListItemIcon>
                    <ListItemText primary="Mensajes" />
                </ListItem>

                <ListItem button className={classes.nested} onClick={ closeSession } >
                    <ListItemIcon> 
                        <PowerSettingsNew />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar Sesión" />
                </ListItem>
            </List>
        </Collapse>
        </List>
    );
}
/* --------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
/* --------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, { agregarNavegador })(UserBlock);