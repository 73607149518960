import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { headersAuth, obtenerUsuario } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_mensajes } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import { eliminarEtiquetas, mostrarFecha } from '../../HelperMensajes';
import Avatar from '@material-ui/core/Avatar';
//import Checkbox from '@material-ui/core/Checkbox';

import AccionesPapelera from './AccionesPapelera';
import MostrarMensaje from '../MostrarMensaje/MostrarMensaje';
import SeleccionaElemento from '../SeleccionaElemento';
import SinElementos from '../SinElementos';

const PapeleraReciclaje = ({ setPanelDerecho }) => {
    const { usuario } = obtenerUsuario();
    const [ seleccionado, setSeleccionado ] = useState( '' );
    /* --------------------------- Axios para obtener mensajes Papelera de Reciclaje -------------------------- */
    const [ mensajesPapelera, setMensajesPapelera ] = useState( null );
    useEffect(() => {
        axiosMensajesPapelera();
        // eslint-disable-next-line
    }, []);

    const axiosMensajesPapelera = () => {
        axios.get( `${servicios_grupohit_mensajes}/papelera/${usuario}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setMensajesPapelera( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setMensajesPapelera([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* -------------------------- Funciones para actualizar estado (mensajesPapelera) ------------------------- */
    const eliminarPapelera  = ( mensajeID ) => {
        setMensajesPapelera( mensajesPapelera.filter( element => element.id !== mensajeID ) );
        setPanelDerecho( <SeleccionaElemento /> );
    }

    const mostrarMensaje = ( mensaje ) => {
        const acciones = <AccionesPapelera mensaje={mensaje} usuario={usuario} eliminarPapelera={eliminarPapelera}/>;
        setPanelDerecho( <MostrarMensaje mensaje={mensaje} acciones={acciones}/> );
        setSeleccionado( mensaje.id );
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            { mensajesPapelera ?
                mensajesPapelera.length > 0 ?
                    mensajesPapelera.map( mensaje => (
                        <div key={mensaje.id} className={ `mensajeSalida ${ seleccionado === mensaje.id && 'seleccionado' }` }
                            onClick={ () => mostrarMensaje( mensaje ) }>
                            <div className="mensajesFlex">
                                <Avatar src={`data:image/jpeg;base64, ${mensaje.foto}`} className="mensajesAvatar"/>
                                <div className="divAvatarFrom">
                                    <div className="toLeido"> {mensaje.toNombre} </div>
                                </div>
                                <div className="divFecha">
                                    <div className="mensajesFecha"> { mostrarFecha(mensaje.fechaHora) } </div>
                                </div>
                            </div>
                            <div className="mensajesFlex">
                                <div className="marginAsunto"></div>
                                <div className="mensajesAsunto asuntoLeido"> {mensaje.asunto} </div>
                            </div>
                            <div className="mensajesFlex">
                                {/* <Checkbox className="mensajesCheckBox"/> */}
                                <div className="marginAsunto"></div>
                                <div className="mensajesMensaje"> {eliminarEtiquetas(mensaje.mensaje)} </div>
                            </div>
                        </div>
                    ))
                    : <SinElementos />
                : <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default PapeleraReciclaje;