import React from 'react';
import ModalService from '../../../../ModalService/ModalService';

import FormatoEjemplo from './FormatoEjemplo';

const ejemploVariables = [
    {
        NUE: '07330',
        NUP: 456557,
        RFC: 'GOBM690615HVZ',
        NOMBRE: 'Mateo Alberto González Barragán',
        'CLAVE DE LA NÓMINA': 'NTST01',
        'NOMBRE DE LA NÓMINA': 'Nómina de Ejemplo',
        'FECHA (dd/mm/aaaa)': '11/11/2020',
        CANTIDAD: 1,
        'CLAVE DE CONCEPTO': '201',
        'CLAVE DE ACUMULADO': 4,
        IMPORTE: 300,
    }
]

const ModalFormatoEjemplo = ({ openFormato, setOpenFormato, idModulo }) => {
    const cerrarModal = () => {
        setOpenFormato( false );
    }

    return (
        <ModalService title="Formato de Ejemplo" parentNode={idModulo} minWidth="80%" maxWidth="80%"
            subtitle="Asegúrese que el archivo contenga toda la información solicitada"
            isOpen={ openFormato } onClose={ () => cerrarModal() } >
                <FormatoEjemplo jsonEjemplo={ejemploVariables}/>
        </ModalService>
    );
};

export default ModalFormatoEjemplo;