import React from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';

const BlueRadio = withStyles(BlueCheckboxRadioStyles)((props) => <Radio color="default" {...props} />);

const TitularPlaza = ({ titularPlaza, setTitularPlaza }) => {

    const handleRadioSindicatoChange = (event) => {
        setTitularPlaza(event.target.value);
    };

    return (
        <div className="row justify-content-center align-items-center">
            <div className="col">
                <label>¿El empleado será titular de la plaza?</label>
            </div>
            <div className="col">
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="position"
                        defaultValue={titularPlaza}
                        onChange={handleRadioSindicatoChange}
                    >
                        <FormControlLabel value="si" control={<BlueRadio />} label="Sí"/>
                        <FormControlLabel value="no" control={<BlueRadio />} label="No"/>
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="col"></div><div className="col"></div>
        </div>
    );
}
 
export default TitularPlaza;
