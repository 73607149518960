import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

//import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
//import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import AlertForm from '../../../../../../../Services/AlertForm';

import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';

const IndicadorRelacionadoAdd = ({ informacion, indicadoresAdd, setIndicadoresAdd }) => {
    const [ error, setError ] = useState( null );
    const nominaIndicadorRef = createRef('');
    const porcentajeRef = createRef('');
    const [ idIndicador, setIdIndicador ] = useState( 1 );

    const enviar = () => {
        setError( null );
        if( nominaIndicadorRef.current.value !== '' && porcentajeRef.current.value !== '' ){
            if( validarSubmit( 'AgregarIndicadorRelacionadoAdd' ) ){
                const nominaIndicador = parseInt( nominaIndicadorRef.current.value );
                const porcentaje = parseFloat( porcentajeRef.current.value );

                agregarIndicador({ id: idIndicador, nomIndicador: obtenerNombreIndicador(nominaIndicador), nominaIndicador, porcentaje });
                setIdIndicador( idIndicador + 1 );
            }
        }else{
            setError( <AlertForm message={ 'Completa todos los campos para agregar un Indicador' } /> );
        }
    };

    /* Funciones para agregar & eliminar Indicadores */
    const obtenerNombreIndicador = ( idIndicador ) => {
        const nomIndicador = informacion.indicadoresNomina.find( element => element.id === idIndicador ).informacion;
        if( nomIndicador ){
            return nomIndicador;
        }else{
            return 'No encontrado';
        }
    }

    const agregarIndicador = ( indicador ) => {
        setIndicadoresAdd([ ...indicadoresAdd, indicador ]);
    }

    const eliminarIndicador = ( indicadorID ) => {
        setIndicadoresAdd( indicadoresAdd.filter( element => element.id !== indicadorID ) );
    }

    return (
        <React.Fragment>
            <form id="AgregarIndicadorRelacionadoAdd">
                <div className="relacionadosFlex">
                    <div className="relacionadosIzquerdo row">
                        <div className="col-md-7 col-sm-12">
                            <SelectValidation title="Concepto Relacionado" arrayOpciones={informacion.indicadoresNomina} keyOpcion="informacion"
                                refValue={nominaIndicadorRef} optional/>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <InputValidation title="Porcentaje (%)" type="porcentaje" placeholder="Escriba el porcentaje del concepto relacionado"
                                tooltipText={'Máximo 3 números y dos decimales del 0.00 al 100.00'} tooltipName={'tooltipPorcRel'} maxLength='6'
                                refValue={porcentajeRef} optional
                                onlyNumbers="." onBlurDecimal={2}/>
                        </div>
                    </div>
                    <div className="relacionadosDerecho marginRelacionadosDerecho">
                        <Button className="btn-third" onClick={() => enviar()}> <Add/> </Button>
                    </div>
                </div>
                {error}
            </form>

            { indicadoresAdd ?
                <div className="mb-3">
                    { indicadoresAdd.map( indicador =>(
                        <div key={indicador.id} className="relacionadosFlex mt-2">
                            <div className="relacionadosIzquerdo row">
                                <div className="col-md-7 col-sm-12">
                                    <div className="listIndicadores"> { indicador.nomIndicador } </div>
                                </div>
                                <div className="col-md-5 col-sm-12 deletePaddingCol">
                                    <div className="listIndicadores"> { indicador.porcentaje } </div>
                                </div>
                            </div>
                            <div className="relacionadosDerecho">
                                <Button className="buttonDeleteIndicador" onClick={ () => eliminarIndicador( indicador.id ) }> <Close/> </Button>
                            </div>
                        </div>
                    ))}
                </div>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default IndicadorRelacionadoAdd;