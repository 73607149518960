import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import BlueCheckboxRadioStyles from '../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';

const CheckboxRetencionPagos = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const CheckRetencionPagos = ({ retienePagos, handleChangeRetienePagos }) => {
    return (
        <div className="row">
            <div className="col">
                <FormControlLabel
                    control={<CheckboxRetencionPagos checked={retienePagos} onChange={handleChangeRetienePagos} name="checkedRetienePagos"/>}
                    label="¿Cubrir completo?"
                />
            </div>
        </div>
    );
}
 
export default CheckRetencionPagos;