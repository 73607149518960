import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit, puestos_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonPuestos } from '../../../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Puestos = ({ id, permisos }) => {

    const [puestos, setPuestos] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(()=>{
        Axios.get(`${servicios_grupohit}/org/puesto/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    const puesto = ArrayJsonPuestos(res.data.dataResponse);
                    setPuestos(puesto);
                    break;
                case 404:
                    setPuestos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);
    
    return (
        <div id={`modulo${id}`}>
        {(puestos)   
        ?   <DataTableService id={id} title={puestos_}
                permisos={permisos}
                columns={[
                    { field: "nivlaboralClave", title: "Clave Nivel Laboral" },
                    { field: "puestoCve", title: "Clave de Puesto" },
                    { field: "puestoNom", title: "Nombre del Puesto" },
                    { field: "puestoDescrip", title: "Descripción Puesto" },
                    { field: "status", title: "Estado" },
                    { field: "relabIdNombre", title: "Relación Laboral" },
                    { field: "relabIdDescripcion", title: "Descripción Relación Laboral" }
                ]}
                data={puestos}
            />
        :   <SpinnerOval/>
        }
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>

    );
}
 
export default Puestos;