import React, { useState } from 'react';
import axios from 'axios';
import { cambios_no_guardados, servicios_grupohit_plantillas } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonPlantillas } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormPlantillas from './FormPlantillas';

const ModalEditPlantillas = ({ nombreModulo, openEdit, setOpenEdit, elementSelect, editar, errorTable }) => {
    
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarDatosPlantilla = (plantilla, plantillaId) => {
        axios.put(`${servicios_grupohit_plantillas}/${plantillaId}`, plantilla, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newPlantilla = JsonPlantillas(res.data.dataResponse);
                    editar(newPlantilla);
                    setOpenEdit(false);
                break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    return (
        <ModalService
            title="Actualizar Plantilla"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormPlantillas
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarDatosPlantilla={actualizarDatosPlantilla}
                    />
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalEditPlantillas;