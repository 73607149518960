import React from 'react';
import AppPrincipal from './Components/AppPrincipal';
import './styleApp.css';

// Elementos de Redux
import { Provider } from 'react-redux';
import store from './Redux/store';

function App(){
    return(
        <Provider store={store}>
            <AppPrincipal />
        </Provider>
    );
}

export default App;