
import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; 
import { iconosDrawer } from '../../MenuDrawer/Drawer/IconosDrawer'; //Importacion de Iconos 
import { consulta_plazas9719 } from '../../../../../Constants/Constants';

const CardRHHistorico9719 = ({ setOpenRHHistorico }) => {
     
    return (
        <Fragment>
            <Card className="cardBoard cardTablero" onClick={() => setOpenRHHistorico(true)}>
                <CardContent>
                    <div className="icon">{iconosDrawer['history']}</div>
                    <p className="name">{ consulta_plazas9719 }</p> 
                </CardContent>
            </Card> 
        </Fragment>
    );
};
export default CardRHHistorico9719;