import React, { Fragment } from 'react';

const InfoEmpleados = ({ elementSelect }) => {

    return (
        <Fragment>
            <div><strong>Empleado: </strong>{ elementSelect.nombreCompleto }</div>
            <div className="formRelLab">
                <div className="form-left">
                        <div><strong>Nómina: </strong>{ elementSelect.nominaNombre }</div>
                </div>
                <div className="form-right">
                        <div><strong>Número Único de Empleado: </strong>{ elementSelect.empleadoClave }</div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default InfoEmpleados;