import React from 'react';
import FormularioUsuario from '../FormularioUsuario';

const InformacionUsuario = ({ error, informacion, enviarUsuario, idModulo, accesosMenu, setAccesosMenu, accesosNominas, setAccesosNominas, loading }) => {
    return (
        <React.Fragment>
            <FormularioUsuario
                error={error}
                informacion={informacion}
                enviarUsuario={enviarUsuario}
                departamentoUsuario={{}}
                loading={loading}
                /* Props para componentes ModalAsignacionModulos & ModalAsignacionNomina */
                idModulo={idModulo}
                accesosMenu={accesosMenu}
                setAccesosMenu={setAccesosMenu}
                accesosNominas={accesosNominas}
                setAccesosNominas={setAccesosNominas}
            />
        </React.Fragment>
    );
};

export default InformacionUsuario;