import React, { Fragment } from 'react';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';

const ComplementoCLC = ({ tipoCLCRef, conceptoPagoRef, claveCFFRef, cffRef, claveFTERef, fteRef, claveFCTRef, fctRef, claveSORRef, sorRef, claveCSORef, csoRef, claveCFIRef, cfiRef, modalidadRef, tipoNominaRef, beneficiarioRef, firmasRef, fechaReferenciaRef }) => {
    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Tipo CLC" type="tipoCLC" placeholder="Tipo CLC" maxLength={1} tooltipText="Máximo 1 letra"
                        refValue={tipoCLCRef} onlyLetters required
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Concepto de Pago" type="conceptoPago" placeholder="Concepto de Pago" maxLength={1} tooltipText="Máximo 1 número o letra"
                        refValue={conceptoPagoRef} alfanumeric required
                    />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col"><h6>Clave de Financiamiento</h6></div>
            </div>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Clave CFF" type="claveFinanciamientoCve" placeholder="Clave CFF" maxLength={12} tooltipText="Máximo 12 letras, números y/o _"
                        refValue={claveCFFRef} alfanumeric="_" optional
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Valor CFF" type="claveFinanciamientoVal" placeholder="Valor CFF" maxLength={1} tooltipText="Máximo 1 letra"
                        refValue={cffRef} onlyLetters required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Clave FTE" type="claveFinanciamientoCve" placeholder="Clave FTE" maxLength={12} tooltipText="Máximo 12 letras, números y/o _"
                        refValue={claveFTERef} alfanumeric="_" optional
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Valor FTE" type="claveFinanciamientoVal" placeholder="Valor FTE" maxLength={1} tooltipText="Máximo 1 letra"
                        refValue={fteRef} onlyLetters required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Clave FCT" type="claveFinanciamientoCve" placeholder="Clave FCT" maxLength={12} tooltipText="Máximo 12 letras, números y/o _"
                        refValue={claveFCTRef} alfanumeric="_" optional
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Valor FCT" type="claveFinanciamientoVal" placeholder="Valor FCT" maxLength={1} tooltipText="Máximo 1 letra"
                        refValue={fctRef} onlyLetters required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Clave SOR_ID" type="claveFinanciamientoCve" placeholder="Clave SOR_ID" maxLength={12} tooltipText="Máximo 12 letras, números y/o _"
                        refValue={claveSORRef} alfanumeric="_" optional
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Valor SOR_ID" type="claveFinanciamientoVal" placeholder="Valor SOR_ID" maxLength={1} tooltipText="Máximo 1 letra"
                        refValue={sorRef} onlyLetters required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Clave CSO_ID" type="claveFinanciamientoCve" placeholder="Clave CSO_ID" maxLength={12} tooltipText="Máximo 12 letras, números y/o _"
                        refValue={claveCSORef} alfanumeric="_" optional
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Valor CSO_ID" type="claveFinanciamientoVal" placeholder="Valor CSO_ID" maxLength={1} tooltipText="Máximo 1 letra"
                        refValue={csoRef} onlyLetters required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Clave CFI_ID" type="claveFinanciamientoCve" placeholder="Clave CFI_ID" maxLength={12} tooltipText="Máximo 12 letras, números y/o _"
                        refValue={claveCFIRef} alfanumeric="_" optional
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Valor CFI_ID" type="claveFinanciamientoVal2" placeholder="Valor CFI_ID" maxLength={2} tooltipText="Máximo 2 números"
                        refValue={cfiRef} onlyNumbers required
                    />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Tipo Nómina" type="tipoNomina" placeholder="Tipo Nómina" maxLength={1} tooltipText="Máximo 1 número"
                        refValue={tipoNominaRef} onlyNumbers required
                    />
                </div>
                <div className="col">
                    <InputValidation
                        title="Beneficiario" type="lastName" placeholder="Beneficiario" maxLength={30} tooltipText="Máximo 30 letras y espacio"
                        refValue={beneficiarioRef} onlyLetters=" " optional={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <InputValidation
                        title="Firmas" type="numFirmas" placeholder="Firmas" maxLength={1} tooltipText="Máximo 1 número"
                        refValue={firmasRef} onlyNumbers required
                    />
                </div>
                <div className="col">
                    <InputDateValidation title="Fecha de Referencia" refValue={fechaReferenciaRef} defaultValue="" required/>
                </div>
            </div>
        </Fragment>
    );
}
 
export default ComplementoCLC;