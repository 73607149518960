import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_nomina } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import Button from '@material-ui/core/Button';
import AlertForm from '../../../../../../Services/AlertForm';
import Checkbox from '@material-ui/core/Checkbox';

const IndicadoresPrestamos = ({ nomina, enviarIndicadores, error, setError, isDeleting }) => {
    const [ indicadoresPrestamos, setIndicadoresPrestamos ] = useState( null );
    useEffect(() => {
        axiosIndicadoresPrestamos();
        // eslint-disable-next-line
    }, []);

    const axiosIndicadoresPrestamos = () => {
        axios.get( `${servicios_grupohit_nomina}/indicadores/getbynomina/${nomina.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresPrestamos( filtrarDeducciones(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        //setIndicadoresPrestamos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const filtrarDeducciones = ( indicadores ) => { //Tipo de Naturaleza DEDUCCIÓN
        const newJson = indicadores.filter( indicador => indicador.indicador.naturaleza.id === 2 );
        return newJson;
    }

    /* ---- Seleccion de elementos ---- */
    const [ indicadoresNominas, setIndicadoresNominas ] = useState( [] );
    const [ selectAll, setSelectAll ] = useState( false );
    const seleccionarTodo = () => {
        setSelectAll( !selectAll );
        if( !selectAll ){
            agregarIndicadores();
        }else{
            eliminarIndicadores();
        }
    }
    /* ---- Funciones que permiten agregar y eliminar varios elementos dentro de un arreglo de objetos (indicadoresNominas) ---- */
    const agregarIndicadores = () => {
        let indicadoresTemporal = [ ...indicadoresNominas ];
        indicadoresPrestamos.forEach( indicador => {
            if( !indicadoresTemporal.includes( indicador.id ) ){
                indicadoresTemporal.push( indicador.id );
            }
        })
        setIndicadoresNominas( indicadoresTemporal );
    }

    const eliminarIndicadores = () => {
        let indicadoresTemporal = [ ...indicadoresNominas ];
        indicadoresPrestamos.forEach( indicador => {
            if( indicadoresTemporal.includes( indicador.id ) ){
                indicadoresTemporal = indicadoresTemporal.filter( acceso => acceso !== indicador.id );
            }
        })
        setIndicadoresNominas( indicadoresTemporal );
    }
    /* ------------------------------------- Funcion que permite agregar un solo acceso ------------------------------------ */
    const seleccionarIndicador = ( indicadorID ) => {
        let indicadoresTemporal = [ ...indicadoresNominas ];
        if( indicadoresTemporal.includes( indicadorID ) ){
            indicadoresTemporal = indicadoresTemporal.filter( indicador => indicador !== indicadorID );
        }else{
            indicadoresTemporal.push( indicadorID );
        }
        setIndicadoresNominas( indicadoresTemporal );
    }

    const enviar = () => {
        if( indicadoresNominas.length > 0 ){
            setError( null );
            //console.log({ nomina: nomina.id, nominds: indicadoresNominas });
            enviarIndicadores({ nomina: nomina.id, nominds: indicadoresNominas });
        }else{
            setError( <AlertForm message={ 'Asegúrate de haber seleccionado elementos' } /> );
        }
    }

    return (
        <div className="divVariablesNomina">
            { indicadoresPrestamos ?
                <React.Fragment>
                    <div className="checkText" onClick={() => seleccionarTodo()}>
                        <Checkbox checked={selectAll} />
                        <span className="textSelectAll"> Seleccionar todo </span>
                    </div>

                    { indicadoresPrestamos.map( indicador => (
                        <div className="checkText" onClick={() => seleccionarIndicador( indicador.id )}>
                            <Checkbox checked={ indicadoresNominas.includes( indicador.id ) ? true : false } />
                            <span> {`${indicador.nomClave} / ${indicador.nomIndicador}`} </span>
                        </div>
                    ))}

                    <div className="textButtonAlertPrest">
                        <div className="divVariablesNominaWithoutPointer">{ error }</div>
                        {(isDeleting)
                        ?   <SpinnerOval/>
                        :   <Button variant="contained" className="btn-color" onClick={() => enviar()}> Eliminar Préstamos </Button>
                        }
                    </div>
                </React.Fragment>
                : <SpinnerOval />
            }
        </div>
    );
};

export default IndicadoresPrestamos;