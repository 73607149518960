import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormularioPoliticas from './FormularioPoliticas';
import { JsonPrimaVacacional, JsonAguinaldo, JsonDiasAjuste, JsonVacaciones, JsonAusentismosPoliticas } from '../../../Helpers/JsonToOneLevel';

const ModalAddPoliticas = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo, informacionAdicional, dataTable }) => {

    const [ error, setError ] = useState( null ); 
    const [ nombreVista, setNombreVista ]= useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const cerrarModal = () => {
        setNombreVista(null);
        setOpenAdd( false );
        setError( null );
    }
    const tipoTitulos = [
        { id: 0, nombre: 'Nueva Política de Aguinaldo', dir: '/politica/aguinaldo/' },
        { id: 1, nombre: 'Nueva Política de Prima Vacacional', dir: '/politica/prima/vacacional/' },
        { id: 2, nombre: 'Nueva Política de Días de Ajuste', dir: '/politica/dias/ajuste/' },
        { id: 3, nombre: 'Nueva Política de Vacaciones', dir: '/politica/vacaciones/' },
        { id: 4, nombre: 'Nueva Política de Ausentismo', dir: '/unificado/politica/ausentismo/' }
    ];

    const enviarPolitica = ( politica, active, dataPeriodosAjuste=null ) => {
        setIsLoading(true);
        const dir = tipoTitulos.filter(element => element.id ===parseInt(active))[0].dir;
        axios.post( `${servicios_grupohit_rh}${dir}`, politica, headersAuth() )
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        switch (active){
                            case 0: 
                                agregar( JsonAguinaldo( res.data.dataResponse ) );
                                break;
                            case 1: 
                                agregar( JsonPrimaVacacional( res.data.dataResponse ) );
                                break;
                            case 2: 
                                if(dataPeriodosAjuste!==null) enviarPeriodoAjuste(res.data.dataResponse.id, dataPeriodosAjuste);
                                agregar( JsonDiasAjuste( res.data.dataResponse ) );
                                break;
                            case 3:
                                if(dataPeriodosAjuste!==null) enviarVacacionesAnti(res.data.dataResponse.id, dataPeriodosAjuste);
                                agregar( JsonVacaciones( res.data.dataResponse ) );
                                break;
                            case 4:
                                agregar(JsonAusentismosPoliticas({
                                    id: res.data.dataResponse.PolAusentismo.id,
                                    fechaini: res.data.dataResponse.PolAusentismo.fechaini,
                                    fechafin: res.data.dataResponse.PolAusentismo.fechafin
                                }));
                                break;
                            default:
                                break; 
                        }
                        cerrarModal();
                        break;
                    default:
                        errorTable( res.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }
    const enviarPeriodoAjuste = async (idPolitica, dataPeriodos) => { 
        const newData = dataPeriodos.map(element => {return {diasAjuste: idPolitica, periodoPago: element.periodoPago, diasPagoPeriodo: element.diasPagoPeriodo }});
        for (const key in newData) {
            await axios.post( `${servicios_grupohit_rh}/periodo/ajuste/`, newData[key], headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        return true; 
                    default:
                        errorTable(res.data.msg);
                        return false; 
                }
            })
            .catch(error => errorTable(registro_no_guardado));
        } 
    }

    const enviarVacacionesAnti = async (idPolitica, dataPeriodos) => { 
        const newData = dataPeriodos.map(element => {return {politcaVacaciones: idPolitica, anioIni: element.anioIni, anioFin: element.anioFin, diasSem1: element.diasSem1, diasSem2: element.diasSem2 }});
        for (const key in newData) {
            await axios.post( `${servicios_grupohit_rh}/vacaciones/antiguedad/`, newData[key], headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        return true; 
                    default:
                        errorTable(res.data.msg);
                        return false; 
                }
            })
            .catch(error => {errorTable(registro_no_guardado)});
        } 
    }

    const vista = (numero) =>{
        numero!==null ? setNombreVista(tipoTitulos.filter(element => element.id ===parseInt(numero))[0].nombre) : setNombreVista(null);
    } 

    return (
        <ModalService title={nombreVista ? nombreVista : "Nueva Política"}
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
            minWidth="70%"
            maxWidth="70%"
        >
            <FormularioPoliticas
                error={error}
                enviarPolitica={enviarPolitica}
                vista={vista}
                dataTable={dataTable}
                isLoading={isLoading}
            />
        </ModalService>
    );
};

export default ModalAddPoliticas;