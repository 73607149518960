import React, { useState, createRef } from 'react';
//import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import DescriptionIcon from '@material-ui/icons/Description';

import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_nomina, servicios_grupohit } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';

const FormularioConsultarPagosUnicos = ({ informacion, axiosInformacion, setInformacion }) => {
    const nominaRef = createRef('');
    const anioRef = createRef('');
    const periodoRef = createRef('');
    const [ aniosNomina, setAniosNomina ] = useState( [] );
    const [ periodosAnio, setPeriodosAnio ] =  useState( [] );
    const [ error, setError ] = useState( null );

    const consultaAnioNomina = ( nominaId ) =>{
        setError( null );
        setAniosNomina([]);
        setPeriodosAnio([]);
        setInformacion( null );
        if( nominaId !== "" ){
            axios.get( `${servicios_grupohit_nomina}/acumulados/periodo/${nominaId}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setAniosNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setAniosNomina( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }

    const consultaPeriodosNomina = ( anioID ) => {
        setError(null);
        setPeriodosAnio([]);
        setInformacion( null );
        if( anioID !== "" ){
            axios.post( `${servicios_grupohit}/generar/reportes/nomina/periodo/`, { anio: parseInt(anioID, 10) }, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const data = respuesta.data.dataResponse.map(item => (
                            {
                                id: item,
                                periodoPerAnio: item,
                            }
                        ));
                        setPeriodosAnio(data)
                        break;
                    case 404:
                        setPeriodosAnio( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }

    /*
    const consultaPeriodosNomina = ( anioID ) => {
        setError(null);
        setPeriodosAnio([]);
        if( anioID !== "" ){
            axios.get( `${servicios_grupohit_nomina}/acumulados/peranio/${nominaRef.current.value}/${anioID}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        const data = respuesta.data.dataResponse.map(item => (
                            {
                                id: item.periodoId,
                                periodoPerAnio: item.periodoPerAnio, 
                            }
                        ));
                        setPeriodosAnio(data)
                        break;
                    case 404:
                        setPeriodosAnio( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }
    */

    const mostrarTable = (evt) => {
        evt.preventDefault();
        if(validarSubmit("ConsultarPagosUnicos")) {
            const nominaID = nominaRef.current.value;
            //const periodoID = periodoRef.current.value;
            const periodo = periodosAnio.find( element => element.id === parseInt(periodoRef.current.value) );
            const anioID = anioRef.current.value;
            //console.log( nominaID, periodo, anioID );
            axiosInformacion( nominaID, periodo.periodoPerAnio, anioID, {aniosNomina, periodosAnio} );
        }
    };

    return (
        <form id="ConsultarPagosUnicos" onSubmit={mostrarTable} className="mb-3">
            <div className="row">
                <div className="col col-lg-5 col-md-4 col-sm-9">
                    <SelectValidation title="Nómina" arrayOpciones={informacion.nominas} keyOpcion="nominaClaveNombre"
                        refValue={nominaRef} onChangeExecute={consultaAnioNomina} required/>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <SelectValidation title="Año" arrayOpciones={aniosNomina} keyOpcion="periodoAnio"
                        refValue={anioRef} onChangeExecute={consultaPeriodosNomina} disabled={aniosNomina.length === 0} required/>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <SelectValidation title="Periodo" arrayOpciones={periodosAnio} keyOpcion="periodoPerAnio"
                        refValue={periodoRef} disabled={periodosAnio.length === 0} required/>
                </div>
                <div className="col col-lg-1 col-md-2 col-sm-3">
                    <button type="submit" className="btn-autocompleteInput"><DescriptionIcon /></button>
                </div>
            </div>
            { error }
        </form>
    );
};

export default FormularioConsultarPagosUnicos;