import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import FormAcumulados from './FormAcumulados';
import { JsonConceptosEmp2 } from '../../../../Helpers/JsonToOneLevel';

const ModalAddAcumulados = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo, informacionAdicional }) => {
    
    const [error, setError] = useState(null);
    const [error2, setError2] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
        setError2(null);
    };

    const enviarAcumulado = acumulado => {
        const request = acumulado;
        axios.post(`${servicios_grupohit_nomina}/acumulados/tacumulados/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(JsonConceptosEmp2(res.data.dataResponse));
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    };

    return (
        <ModalService
            title="Agregar Acumulado"
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormAcumulados
                        setError={setError}
                        error={error}
                        setError2={setError2}
                        error2={error2}
                        enviarAcumulado={enviarAcumulado}
                        informacionAdicional={informacionAdicional}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddAcumulados;