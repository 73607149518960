import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormBases from './FormBases';

const ModalAddBases = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    };

    const enviarBase = base => {
        setIsLoading(true);
        const info = base;
        axios.post(`${servicios_grupohit_catalogo}/bases/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService
            title="Agregar Base"
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormBases
                        enviarBase={enviarBase}
                        error={error}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddBases;