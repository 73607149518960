import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';

import CollapseMaterial from '../AsignacionModulos/CollapseMaterial/CollapseMaterial';
import RestringirUsuarios from './RestringirUsuarios';
import { obtenerRestriccionesDepartamento, obtenerRestriccionesSinDepartamento } from './HelperRestricciones';

const ModalRestringirUsuarios = ({ openUsuarios, setOpenUsuarios, moduloSelect, errorTable, successTable, informacion, idModulo, setRestricciones }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenUsuarios( false );
        setError( null );
    }

    const enviarRestriccion = ( usuarios ) => {
        axios.post( `${servicios_grupohit_url}/modulos/bloqueados/`, {modulos: usuarios}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        successTable( 'Registro Actualizado' );
                        setRestricciones( usuarios );
                        setElementoActivo( '' );
                        //setOpenUsuarios( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Restringir Usuarios" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openUsuarios } onClose={ () => cerrarModal() } >
                <div> <b> Módulo: </b> {moduloSelect.nombre} </div>

                { informacion.departamentos.map( departamento => {
                    const usuariosDepartamento = informacion.usuarios.filter( usuario => usuario.departamentoId === departamento.id );
                    if( usuariosDepartamento.length > 0 ){
                        return(
                            <CollapseMaterial key={departamento.id} title={departamento.deptoNom} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
                                <RestringirUsuarios
                                    error={error}
                                    enviarRestriccion={enviarRestriccion}
                                    moduloID={moduloSelect.id}
                                    usuariosDepartamento={usuariosDepartamento}
                                    restriccionesDepartamento={obtenerRestriccionesDepartamento(informacion.restricciones, moduloSelect.id, usuariosDepartamento)}
                                    restriccionesSinDepartamento={obtenerRestriccionesSinDepartamento(informacion.restricciones, moduloSelect.id, usuariosDepartamento)}
                                />
                            </CollapseMaterial>
                        )
                    }else{
                        return '';
                    }
                })}
        </ModalService>
    );
};

export default ModalRestringirUsuarios;