import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
//import { servicios_grupohit_rh, registro_actualizado, cambios_no_guardados, registro_sera_actualizado } from '../../../../../../Constants/Constants';
import { servicios_grupohit_rh, registro_sera_actualizado, verifique_conexion } from '../../../../../../Constants/Constants';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import InfoPasatiempos from './InfoPasatiempos';
import Button from '@material-ui/core/Button';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormPasatiempos = ({ pasatiempoRef, pasatiempos, elementSelect, elementoSeleccionado, setElementoSeleccionado, irInfo, actualizarPasatiempo, agregarPasatiempoEdit, enviar, mostrarFormAddEdit, setMostrarFormAddEdit, pasatiempoSaved, setPasatiempoSaved, pasatiempoSavedEdit, setPasatiempoSavedEdit, setError, errorTable, isLoading }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const mostrarAlert = e => {
        e.preventDefault();
        if(!elementSelect || pasatiempoSaved.length === 0 || (pasatiempoSaved.length > 0 && mostrarFormAddEdit)) {
            if(pasatiempoSavedEdit.length) {
                setOpenAlert(true);
            } else {
                setError(<AlertForm message="Para guardar debe de haber, al menos, un pasatiempo agregado"/>);
            }
        } else {
            setOpenAlert(true);
        }
    };

    const guardar = e => {
        e.preventDefault();
        enviar();
    };

    const abrirFormAddEdit = e => {
        e.preventDefault();
        setMostrarFormAddEdit(true);
    };

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupohit_rh}/empleado/pasatiempo/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const info = res.data.dataResponse.map(res => (
                            {
                                id: res.pasatiempo.id,
                                pasatiempo: res.pasatiempo.pasaNom
                            }
                        ));
                        setPasatiempoSaved(info);
                        break;
                    case 404:
                        setPasatiempoSaved([]);
                        break;
                    default:
                        setPasatiempoSaved([]);
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion); });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const guardaPasatiempo = (e, type) => {
        e.preventDefault();

        let lengthDepEcon = 0;
        let lengthDepEconS = 0;

        if(pasatiempoSaved.length) lengthDepEcon = pasatiempoSaved.length;
        if(pasatiempoSavedEdit.length) lengthDepEconS = pasatiempoSavedEdit.length;

        const total = lengthDepEcon + lengthDepEconS;

        if(total < 10) {
            setError(null);
            if(validarSubmit("FormEmpleadoPasatiempos")) {
                const idPasatiempo = pasatiempos.find(p => p.id === parseInt(pasatiempoRef.current.value));
                
                if((pasatiempoSaved.concat(pasatiempoSavedEdit).find(pas => pas.id === idPasatiempo.id))) {
                    setError(<AlertForm message="Has seleccionado este pasatiempo con anterioridad"/>);
                } else {
                    let info = {
                        id: idPasatiempo.id,
                        pasatiempo: idPasatiempo.pasatiempo,
                    };
                    try {
                        if(type === 'FormAgregar') setPasatiempoSaved([ ...pasatiempoSaved, info ]);
                        if(type === 'FormEditar') {
                            info = { ...info, empleado: elementSelect.id }
                            setPasatiempoSavedEdit([ ...pasatiempoSavedEdit, info ]);
                        }
                    } catch(e) {
                        setError(<AlertForm message="Vuelva a intentarlo"/>);
                    }
        
                    pasatiempoRef.current.value = '';
                }
            }
        } else {
            setError(<AlertForm message="Has llegado al límite de pasatiempos"/>)
        }

    };

    const limpiaSelected = e => {
        e.preventDefault();
        setError(null);
        if(pasatiempoSavedEdit.length) setPasatiempoSavedEdit([]);
        else setElementoSeleccionado(null);
        setMostrarFormAddEdit(false);
    };

    const eliminarPasatiempo = item => {
        const newPasatiempos = pasatiempoSaved.filter(pas => pas.id !== item.id);
        actualizarPasatiempo(newPasatiempos, 2);
    };

    const enviaInfo = () => {
        setError(null);
        if(!pasatiempoSavedEdit.length && !mostrarFormAddEdit) {
            const idPasatiempo = pasatiempos.find(p => p.id === parseInt(pasatiempoRef.current.value));
            let info = {
                id: idPasatiempo.id,
                pasatiempo: idPasatiempo.pasatiempo,
            };
            actualizarPasatiempo(info, 1);
        } else {
            agregarPasatiempoEdit();
        }
    };
    
    return (
        <Fragment>
            <h5>Pasatiempos</h5>
            {
                (!elementSelect || (elementoSeleccionado === null && pasatiempoSaved.length > 0 && !mostrarFormAddEdit))
                ?  pasatiempoSaved.map((pasatiempo, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            {
                                (elementSelect)
                                ?   <Fragment>
                                        <BtnEditItem id={pasatiempo.id} idArray="id" array={pasatiempoSaved} action={setElementoSeleccionado} remove/>
                                        <BtnRemoveItem id={pasatiempo.id} idArray="id" array={pasatiempoSaved} action={eliminarPasatiempo} remove/>
                                    </Fragment>
                                :   <BtnRemoveItem id={pasatiempo.id} idArray="id" array={pasatiempoSaved} action={setPasatiempoSaved}/>
                            }
                            <InfoPasatiempos pasatiempo={pasatiempo}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (pasatiempoSavedEdit.length)
                ?  pasatiempoSavedEdit.map((pasatiempo, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            <BtnRemoveItem id={pasatiempo.id} idArray="id" array={pasatiempoSavedEdit} action={setPasatiempoSavedEdit}/>
                            <InfoPasatiempos pasatiempo={pasatiempo}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                ((!elementSelect && pasatiempos.length)|| (elementoSeleccionado !== null && pasatiempos.length) || pasatiempoSaved.length === 0 || (pasatiempoSaved.length > 0 && mostrarFormAddEdit && pasatiempos.length))
                ?   <form id="FormEmpleadoPasatiempos" onSubmit={(elementSelect) ? mostrarAlert : guardar}>
                        <SelectValidation title="Pasatiempos" arrayOpciones={pasatiempos} keyOpcion="pasatiempo"
                            refValue={pasatiempoRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.id : ''} required/>
                        <BtnsInfoAdicional numForm={8} irInfo={irInfo} elementSelect={(elementSelect) ? true : false}/>
                        {
                            (!elementSelect || pasatiempoSaved.length === 0 || (pasatiempoSaved.length > 0 && mostrarFormAddEdit))
                            ?   (!elementSelect || pasatiempoSaved.length === 0)
                                ?   <Button variant="outlined" className="btn-outline" onClick={e => (!elementSelect) ? guardaPasatiempo(e, 'FormAgregar') : guardaPasatiempo(e, 'FormEditar')}>Agregar Pasatiempo</Button>
                                :   <Button variant="outlined" className="btn-outline" onClick={e => guardaPasatiempo(e, 'FormEditar')}>Agregar Pasatiempo</Button>
                            :   null
                        }
                        {
                            (elementSelect && pasatiempoSaved.length > 0 && !mostrarFormAddEdit)
                            ?   <div className="formRelLab">
                                    <div className="form-left">
                                        <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                    </div>
                                    <div className="form-right">
                                    {isLoading
                                    ?   <SpinnerOval />
                                    :   <Button variant="contained" className="btn-color" type="submit">{(!elementSelect || pasatiempoSaved.length === 0 || (pasatiempoSaved.length > 0 && mostrarFormAddEdit)) ? 'Guardar' : 'Actualizar' }</Button>
                                    }
                                    </div>
                                </div>
                            :   (!elementSelect || pasatiempoSaved.length === 0 || (pasatiempoSaved.length > 0 && mostrarFormAddEdit))
                                ?   <div className="formRelLab">
                                        <div className="form-left">
                                        {(!elementSelect || pasatiempoSaved.length === 0)
                                        ?   <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                                        :   <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                        }
                                        </div>
                                        <div className="form-right">
                                        {isLoading
                                        ?   <SpinnerOval />
                                        :   <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
                                        }
                                        </div>
                                    </div>
                                :   null
                        }
                    </form>
                :   <Fragment>
                        <br/>
                        <BtnsInfoAdicional numForm={8} irInfo={irInfo} elementSelect={(elementSelect) ? true : false}/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                            </div>
                            <div className="form-right">
                                <Button variant="outlined" className="btn-outline" onClick={e => abrirFormAddEdit(e)}>Agregar</Button>
                            </div>
                        </div>
                    </Fragment>
            }
            {   elementSelect &&
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaInfo}/>
            }
        </Fragment>
    );
}
 
export default FormPasatiempos;