import React, { useState, createRef, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { longDateToInput, validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertForm from '../../../../../../Services/AlertForm';
import { servicios_grupohit_rh, registro_guardado, registro_eliminado, verifique_conexion } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormAusentismos = props => {

    const {
        elementSelect, conceptosPercepciones, conceptosDeducciones, ausentismos, percepcionesBase, setPercepcionesBase,
        fechaInicioAusentismoRef, fechaFinAusentismoRef, conceptoAusentismoRef, ausentismoRef, cantidadAusentismoRef,
        mostrarAlertW, mostrarAlertWIncomplete, setAlertSuccess, setAlertError, setTextAlert, atras, enviar, isLoading
    } = props;

    const [mostrar, setMostrar] = useState(false);
    const [error, setError] = useState(null);
    const [elementoEdit, setElementoEdit] = useState({
        fechaini: '',
        fechafin: '',
        deduccionId: '',
        ausentismoId: '',
        cantidad: '',
        configId: null
    });

    const { fechaini, fechafin, deduccionId, ausentismoId, cantidad, configId } = elementoEdit;

    const conceptoBaseRef = createRef('');
    const cantidadPercecionBaseRef = createRef('');

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupohit_rh}/unificado/politica/ausentismo/${elementSelect.id.replace(/AU/,'')}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setElementoEdit({
                            fechaini: longDateToInput(res.data.dataResponse.PolAusentismo.fechaini),
                            fechafin: res.data.dataResponse.PolAusentismo.fechafin ? longDateToInput(res.data.dataResponse.PolAusentismo.fechafin) : '',
                            deduccionId: res.data.dataResponse.Config[0].indicadorid.indicadorId,
                            ausentismoId: res.data.dataResponse.Config[0].tipoAusentismo.id,
                            cantidad: parseFloat(res.data.dataResponse.Config[0].polAusenciaCant).toFixed(2),
                            configId: res.data.dataResponse.Config[0].id
                        });
                        setPercepcionesBase(res.data.dataResponse.Calc.map(item => ({
                            id: item.id,
                            percepcion: item.indicadorid,
                            cantidad: parseFloat(item.dias).toFixed(2)
                        })));
                        setTimeout(() => {
                            setMostrar(true);
                        }, 500);
                        break;
                    default:
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                }
            })
            .catch(res => {
                setTextAlert(verifique_conexion);
                setAlertError(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const agregaPercepcionBase = () => {
        const guardaPercepcionBaseState = item => {
            cantidadPercecionBaseRef.current.value = '';
            conceptoBaseRef.current.value = '';
            setPercepcionesBase([...percepcionesBase, item]);
        };
        
        if(validarSubmit('FormAddPercBase')) {
            const verifica = percepcionesBase.find(item => item.percepcion.id === parseInt(conceptoBaseRef.current.value));
            if(!verifica) {
                setError(null);
                const cantidad = cantidadPercecionBaseRef.current.value;
                const percepcion = conceptosPercepciones.find(item => item.id === parseInt(conceptoBaseRef.current.value));
                if(elementSelect) {
                    const request = {
                        polAusConfId: configId,
                        indicadorId: percepcion.id,
                        dias: parseFloat(cantidad)
                    };
                    axios.post(`${servicios_grupohit_rh}/politica/ausentismo/calculo/`, request, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                guardaPercepcionBaseState({ percepcion, id: res.data.dataResponse.id, cantidad });
                                setTextAlert(registro_guardado);
                                setAlertSuccess(true);
                                break;
                            default:
                                setTextAlert(res.data.msg);
                                setAlertError(true);
                                break;
                        }
                    })
                    .catch(error => {
                        setTextAlert(verifique_conexion);
                        setAlertError(true);
                    });
                } else {
                    guardaPercepcionBaseState({ percepcion, cantidad });
                }
            } else {
                setError(<AlertForm message="Concepto a descontar ya registrado"/>);
            }
        }
    };

    const eliminaPercepcionBase = (key, id = null) => {
        const eliminaPercepcionBaseState = () => {
            const newPercepcionesBase = percepcionesBase.filter(item => item.percepcion.indicadorId !== key);
            setPercepcionesBase(newPercepcionesBase);
        };

        if(elementSelect) {
            axios.delete(`${servicios_grupohit_rh}/politica/ausentismo/calculo/${id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        eliminaPercepcionBaseState();
                        setTextAlert(registro_eliminado);
                        setAlertSuccess(true);
                        break;
                    default:
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                }
            })
            .catch(error => {
                setTextAlert(verifique_conexion);
                setAlertError(true);
            });
        } else {
            eliminaPercepcionBaseState();
        }
    };

    const mostrarAlertaW = () => {
        if(validarSubmit('FormAusentismos')) {
            if(conceptoBaseRef.current.value !== '' || cantidadPercecionBaseRef.current.value !== '') {
                mostrarAlertWIncomplete();
            } else {
                mostrarAlertW();
            }
        }
    };

    const verificar = e => {
        e.preventDefault();
        if(conceptoBaseRef.current.value !== '' || cantidadPercecionBaseRef.current.value !== '') {
            mostrarAlertWIncomplete();
        } else {
            enviar();
        }
    };

    return (
        <Fragment>
        {(((elementSelect && mostrar) || !elementSelect) && conceptosPercepciones.length > 0 && conceptosPercepciones.length > 0 && ausentismos.length > 0)
        ?   <Fragment>
                <div id="FormAusentismos">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <InputDateValidation title="Fecha Inicial" refValue={fechaInicioAusentismoRef}
                                defaultValue={fechaini} required
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <InputDateValidation title="Fecha Final" refValue={fechaFinAusentismoRef}
                                defaultValue={fechafin} optional
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <SelectValidation title="Concepto a Generar en Nómina" arrayOpciones={conceptosDeducciones}
                                keyOpcion="indicadorClave" keyOpcionConcat="indicadorNom"
                                refValue={conceptoAusentismoRef} defaultValue={deduccionId} required
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <SelectValidation title="Tipo de Ausentismo" arrayOpciones={ausentismos}
                                keyOpcion="ausentismoCve" keyOpcionConcat="ausentismoNom"
                                refValue={ausentismoRef} defaultValue={ausentismoId} required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <InputValidation title="Cantidad" type="porcentaje" placeholder="Cantidad de días" 
                                refValue={cantidadAusentismoRef} defaultValue={cantidad} 
                                tooltipText="Números decimales" onlyNumbers="." onBlurDecimal={2}
                                maxLength={6} required
                            />
                        </div>
                    </div>
                </div>
                <div id="FormAddPercBase" className="row">
                    <div className="col-7">
                        <SelectValidation title="Concepto a Descontar" arrayOpciones={conceptosPercepciones}
                            keyOpcion="indicadorClave" keyOpcionConcat="indicadorNom"
                            refValue={conceptoBaseRef} defaultValue="" required
                        />
                    </div>
                    <div className="col-3">
                        <InputValidation title="Cantidad" type="porcentaje" placeholder="Cantidad de días a descontar" 
                            refValue={cantidadPercecionBaseRef} defaultValue=""
                            tooltipText="Números decimales" onlyNumbers="." onBlurDecimal={2}
                            maxLength={6} required
                        />
                    </div>
                    <div className="col-2">
                        <div className="form-group" style={{ paddingTop: '1.7rem', textAlign: 'right' }}>
                            <Button variant="contained" className="btn-third" onClick={agregaPercepcionBase}>
                                <AddIcon/>
                            </Button>
                        </div>
                    </div>
                </div>
                { error }
                { percepcionesBase.map((item, key) => (
                    <div key={key} className="row">
                        <div className="col-7">
                            <label className="form-control label-control" style={{ marginTop: '0' }}>
                                { `${item.percepcion.indicadorClave} ${item.percepcion.indicadorNom}` }
                            </label>
                        </div>
                        <div className="col-3">
                            <label className="form-control label-control" style={{ marginTop: '0' }}>
                                { item.cantidad }
                            </label>
                        </div>
                        <div className="col-2">
                            <div className="form-group" style={{ marginTop: '0', textAlign: 'right' }}>
                                <Button variant="contained" className="btn-third-delete" onClick={() => elementSelect ? eliminaPercepcionBase(item.percepcion.indicadorId, item.id) : eliminaPercepcionBase(item.percepcion.id)}>
                                    <DeleteIcon/>
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="row"> 
                    { (!elementSelect)
                    ?   <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={() => atras(null, -1)}>Atrás</Button>
                        </div>
                    :   null
                    }
                    <div className={ elementSelect ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'}>
                        {(isLoading)
                        ?   <SpinnerOval/>
                        :   <Button variant="contained" className="btn-color" type="button" onClick={elementSelect ? mostrarAlertaW : verificar}>{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                        }
                    </div>
                </div>
            </Fragment>
        :   <SpinnerOval />
        }
        </Fragment>
    );
}
 
export default FormAusentismos;