import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_rh } from '../../../../../../Constants/Constants';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';

import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/MaterialTheme';
import Checkbox from '@material-ui/core/Checkbox';
import AlertForm from '../../../../../../Services/AlertForm';

import CollapseMaterial from '../../../Modulos/Administracion/Usuarios/AsignacionModulos/CollapseMaterial/CollapseMaterial';
import IndicadoresPrestamos from './IndicadoresPrestamos';
import DescargarPrestamosActivos from './DescargarPrestamosActivos';

const InformacionPrestamos = ({ idModulo, errorTable, successTable, nombreModulo, informacion }) => {
    const [ eliminar, setEliminar ] = useState( false );
    const [ descargar, setDescargar ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ indicadoresSelected, setIndicadoresSelected ] = useState( [] );
    const [ error, setError ] = useState( null );
    const [ isDeleting, setIsDeleting ] = useState(false);

    const [ elementoActivo, setElementoActivo ] = useState( '' );

    useEffect(() => {
        setError(null);
    }, [elementoActivo]);

    const enviarIndicadores = ( indicadores ) => {
        setOpenDelete( true );
        setIndicadoresSelected( indicadores );
    }

    const axiosEliminarPrestamos = () => {
        setIsDeleting(true);
        axios.post( `${servicios_grupohit_rh}/empleado/prestamos/elimimar/bynomina/`, indicadoresSelected, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        successTable( 'Registros eliminados' );
                        setElementoActivo( '' );
                        break;
                    case 404: //No se encontraron registros para eliminar
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( 'No se han podido eliminar los registros' );
                        break;
                }
            })
            .catch( error => {
                errorTable( 'No se han podido eliminar los registros' );
            })
            .finally(() => setIsDeleting(false));
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div className="checkText mt-2" onClick={() => setEliminar( !eliminar )}>
                <Checkbox checked={eliminar} />
                <span> {`Eliminar todos los ${nombreModulo}`} </span>
            </div>
            { informacion.nominas && eliminar &&
                <React.Fragment>
                    { informacion.nominas.map( nomina => (
                        <CollapseMaterial key={nomina.id} title={nomina.nominaNombre} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
                            <IndicadoresPrestamos nomina={nomina} enviarIndicadores={enviarIndicadores} error={error} setError={setError} isDeleting={isDeleting}/>
                        </CollapseMaterial>
                    ))}
                </React.Fragment>
            }

            <div className="checkText" onClick={() => setDescargar( !descargar )}>
                <Checkbox checked={descargar} />
                <span> {`Descargar préstamos activos`} </span>
            </div>
            { informacion.deducciones && descargar &&
                <DescargarPrestamosActivos idModulo={idModulo} informacion={informacion}/>
            }

            <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text={`¡Se eliminarán todos los ${nombreModulo}!`}
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar"
                action={() => axiosEliminarPrestamos()} />
        </MuiThemeProvider>
    );
};

export default InformacionPrestamos;