import React from 'react';
import { forwardRef } from 'react';
/* ---------------------- Importacion directa de Iconos desde @material-ui/ ---------------------- */
//import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import FilterList from '@material-ui/icons/FilterList';

import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import Remove from '@material-ui/icons/Remove';

/* ------ Objeto que permitira reemplazar iconos en 'material-table' (Sin necesidad de CDN) ------ */
export const tableIcons = {
    Search: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),

    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),

    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),

    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
};
/* --------------- Elementos de Localizacion (Modificación de textos y contenidos) --------------- */
export const localization = {
    body: {
      emptyDataSourceMessage: 'No existen registros',
      filterRow: {
        filterTooltip: "Filtrar por Columna"
      }
    },
    toolbar: {
      searchTooltip: 'Filtrado General',
      searchPlaceholder: 'Filtrar Información ',
      exportTitle: 'Exportar',
      exportAriaLabel: 'Exportar',
      exportName: 'Exportar a CSV',
      showColumnsTitle: 'Mostrar Columnas',
      showColumnsAriaLabel: 'Mostrar Columnas',
      addRemoveColumns: 'Ocultar o Mostrar Columnas',
      nRowsSelected: ''
    },
    header: {
        actions: 'Acciones'
    },
    pagination: {
      labelRowsSelect: 'filas',
      labelDisplayedRows: ' {from}-{to} de {count}',
      firstTooltip: 'Primera página',
      previousTooltip: 'Página anterior',
      nextTooltip: 'Siguiente página',
      lastTooltip: 'Última página'
    },
    grouping: {
      groupedBy: 'Agrupado por:',
      placeholder: 'Arrastre y suelte en esta área la categoría por la que desea agrupar a los empleados.'
    }

}
/* ----------------------------------------------------------------------------------------------- */