import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import {registro_sera_actualizado} from '../../../../../../Constants/Constants';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormTipoAcumulado = ({ error, setError, enviarTipoAcumulado, elementSelect, actualizarTipoAcumulado, isLoading }) => {

    const claveRef = createRef('');
    const nombreRef = createRef('');
    const descripcionRef = createRef('');
    const [alertWarning, setAlertWarning]=useState(false);

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarTipoAcumulado')) {
            const acumuladoClave = claveRef.current.value;
            const acumuladoNombre = nombreRef.current.value;
            const acumuladoDescripcion = descripcionRef.current.value;
            enviarTipoAcumulado({ acumuladoClave, acumuladoNombre,  acumuladoDescripcion });
        }
    };

    const actualizar =()=> {
        if(validarSubmit('EditarTipoAcumulado')) {
            const acumuladoClave = claveRef.current.value;
            const acumuladoNombre = nombreRef.current.value;
            const acumuladoDescripcion = descripcionRef.current.value; 
            setError(null);
            actualizarTipoAcumulado({ acumuladoClave, acumuladoNombre,  acumuladoDescripcion }, elementSelect.id);
            setAlertWarning(false);
        }
    };
    const alert =e =>{
        e.preventDefault();
        setAlertWarning(true);
    };

    return (  
        <form id={ elementSelect ? 'EditarTipoAcumulado' : 'AgregarTipoAcumulado' } className="" onSubmit={ elementSelect ? alert : enviar }>
           <div className="formRelLab">
                <div className="form-left">
                    <InputValidation title="Clave Tipo Acumulado" type="claveTipAcu" placeholder="Escriba la clave del tipo de acumulado"
                        refValue={claveRef} defaultValue={ elementSelect ? elementSelect.acumuladoClave : '' }
                        tooltipText={'16 caracteres alfanuméricos'}  tooltipName={'tooltipCveTipAcu'} maxLength={16} required/>
                    <InputValidation title="Nombre" type="nombreTipoAcu" placeholder="Escriba el nombre del tipo de acumulado"
                        refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.acumuladoNombre : '' }
                        tooltipText={'64 caracteres alfanuméricos'}  tooltipName={'tooltipNomTipAcu'} maxLength={64} required/>
                </div>
                <div className="form-right">
                    <InputValidation title="Descripción" type="descripcionTipAcu" placeholder="Escriba la descripción del tipo de acumulado"
                        refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.acumuladoDescripcion : '' }
                        tooltipText={'1024 caracteres alfanuméricos'} maxLength={1024} fieldType="textarea" tooltipName={'tooltipDesTipAcu'} required/>
                </div>
                {error}
            </div>
            <div className='row'>
                <div className='col'>
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            }
                </div>
            </div>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar} />
        </form>    
    );
}

export default FormTipoAcumulado;