import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, acumulados_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import { ArrayJsonConceptosEmp, ArrayJsonConceptos } from '../../../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const TableAcumulados = ({ id, elementSelect, anioRef, periodo, permisos, acumuladoRef}) => {
    
    const [conceptosEmp, setConceptosEmp] = useState(null);
    const [nominaId, setNominaId] = useState(null);
    const [conceptos, setConceptos] = useState(null);
    const [hide, setHide] = useState(true);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const columns = [
        { field: "nominaClave", title: "Clave" },
        { field: "nominaIndicador", title: "Concepto de Nómina" },
        { field: "indicadorSat", title: "Concepto SAT" },
        { field: "cantidad", title: "Cantidad" },
        { field: "monto", title: "Importe", cellStyle: () => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}}, 
        { field: "status", title: 'Status'},
    ];
        
    useEffect(() => {
        axios.get(`${servicios_grupohit_nomina}/acumulados/${elementSelect.id}/${periodo.id}/${acumuladoRef.current.value}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(res.data.dataResponse[0].status === 'CERRADO') {
                        setHide(false);
                    }
                    setConceptosEmp(ArrayJsonConceptosEmp(res.data.dataResponse));
                    setNominaId(res.data.dataResponse[0].nominaId);
                    break;
                case 404:
                    setConceptosEmp([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(nominaId) {
            axios.get(`${servicios_grupohit_nomina}/indicadores/getbynomina/${nominaId}`, headersAuth() )
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setConceptos(ArrayJsonConceptos(res.data.dataResponse));
                        break;
                    case 404:
                        setConceptos([]);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
            setNominaId(null);
        }
    },[nominaId]);

    return (
        <div id={`modulo${id}secondary`}>
            {   
                (conceptosEmp)
                ?   <DataTableService
                        id={`${id}secondary`}
                        title={acumulados_}
                        columns={columns}
                        data={conceptosEmp}
                        informacionAdicional={{
                            anio: (anioRef.current) ? anioRef.current.value : '',
                            periodo: periodo,
                            acumulado: (acumuladoRef.current) ? parseInt(acumuladoRef.current.value) : '',
                            conceptos,
                            empleado:elementSelect
                        }}
                        permisos={permisos}
                        hideIconEdit={hide}
                        hideIconDelete={hide}
                        hideIconAdd={hide}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default TableAcumulados;