import React, { Fragment } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { ConvertDateTime } from '../../../Helpers/ConvertDate';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
});

const DetailsNotification = ({ info }) => {

    const { modulo, operacion, fechaMs, datos } = info;

    return (
        <MuiThemeProvider theme={theme}>
            <div className="row" style={{margin:'.2rem'}}>
                <div className="col-12"> 
                    <div>
                        <label>
                            <strong>Operación: </strong>
                            { `Se ${operacion} ${datos._children.length} registro${(datos._children.length > 1 ? 's' : '')} en ${modulo}` }
                        </label>
                    </div>

                    <div><label><strong>Fecha: </strong>{ ConvertDateTime(fechaMs) }</label></div>
                    { datos._children.map((item, i) => (
                        <Fragment key={i}>
                            <div><label><strong>Tabla: </strong>{item._children.table._value}</label></div>
                            <div><label><strong>Columna: </strong>{item._children.column._value}</label></div>
                            <div><label><strong>Valor: </strong>{item._children.value._value.replace(/], /g, `],\n`)}</label></div>
                        </Fragment>
                    ))}
                </div>
            </div>
        </MuiThemeProvider>
    );
}
 
export default DetailsNotification;