import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import ModalEscalafon from './ModalEscalafon';
import AlertForm from '../../../../../../Services/AlertForm';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import ConvertDate from '../../../Helpers/ConvertDate';
import TablaMovimientosEscalafon from './Components/Escalafon/TablaMovimientosEscalafon';
import TableMovimientosEspeciales from './Tables/TableMovimientosEspeciales';
import TableEscalafon from './Tables/TableEscalafon';
import TableVerMovEscalafonarios from './Tables/TableVerMovEscalafonarios';

const Especiales = ({ id, permisos, obtenerEmpleadosActivos, empleadosActivos, nominasRL, periodoActual }) => {

    const [muestraTablaEscalafon, setMuestraTablaEscalafon] = useState(false);
    const [muestraTablaVerEscalafon, setMuestraTablaVerEscalafon] = useState(false);
    const [muestraTablaVerEscalafon2, setMuestraTablaVerEscalafon2] = useState(false);
    const [muestraModalEscalafon, setMuestraModalEscalafon] = useState(false);
    const [empleadosEscalafon, setEmpleadosEscalafon] = useState([]);
    const [listadoEmpleadosEscalafon, setListadoEmpleadosEscalafon] = useState({ info: null, empleadosListaEscalafon: null });
    const [escalafones, setEscalafones] = useState(null);
    const [cleanTable, setCleanTable] = useState(false);
    const [error, setError] = useState(null);
    const [textError, setTextError] = useState('');
    const [openAlertE, setOpenAlertE] = useState(false);

    const { info, empleadosListaEscalafon } = listadoEmpleadosEscalafon;

    useEffect(() => {
        if(empleadosActivos) {
            obtenerEmpleadosActivos(errorFn);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const errorFn = msg => {
        setTextError(msg);
        setOpenAlertE(true)
    };

    const servicioEscalafones = () => {
        axios.get(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayEscalafones = res.data.dataResponse.map(item => ({ 
                        id: item.id,
                        folio: item.folio,
                        definitivo: item.definitivo,
                        tipo: (item.definitivo) ? 'DEFINITIVO' : 'TEMPORAL',
                        fecMov: (item.fecMov) ? ConvertDate(item.fecMov) : '-',
                        fecini: (item.fecini) ? ConvertDate(item.fecini) : '-',
                        fecfin: (item.fecfin) ? ConvertDate(item.fecfin) : '-',
                        usuario: {
                            username: item.usuario.username,
                            usuarioNom: item.usuario.datos.usuarioNom,
                            usuarioPat: (item.usuario.datos.usuarioPat) ? `${item.usuario.datos.usuarioPat} ` : '',
                            usuarioMat: (item.usuario.datos.usuarioMat) ? `${item.usuario.datos.usuarioMat} ` : '',
                        }
                     }));
                    setEscalafones(arrayEscalafones);
                    break;
                default:
                    setEscalafones([]);
                    setTextError(res.data.msg);
                    setOpenAlertE(true);
                    break;
            }
        })
        .catch(error => {
            setEscalafones([]);
            setTextError(verifique_conexion);
            setOpenAlertE(true);
        });
    };

    const mostrarTablaEscalafon = tipo => {
        if(tipo === 1) setMuestraTablaEscalafon(true);
        if(tipo === 2) {
            servicioEscalafones();
            setMuestraTablaVerEscalafon(true);
        }
        setMuestraTablaVerEscalafon2(false);
    };

    const ocultarTablaEscalafon = () => {
        setCleanTable(true);
        setError(null);
        setTextError('');
        setEscalafones(null);
        setEmpleadosEscalafon([]);
        setMuestraTablaEscalafon(false);
        setMuestraTablaVerEscalafon(false);
    };

    const ocultarTablaEscalafonListaEmpleados = () => {
        setError(null);
        setTextError('');
        setMuestraTablaEscalafon(false);
        setMuestraTablaVerEscalafon2(false);
        setMuestraTablaVerEscalafon(true);
        setListadoEmpleadosEscalafon({ info: null, empleadosListaEscalafon: null });
    };

    const mostrarModalEscalafon = (empleados) => {
        if(empleados.length > 1) {
            setError(null);
            setEmpleadosEscalafon(empleados);
            setMuestraModalEscalafon(true);
        } else {
            setError(<AlertForm message="Debe seleccionar, al menos, 2 Empleados"/>);
        }
    };

    const servicioEmpleadosEscalafon = movEscalafonario => {
        if(movEscalafonario) {
            axios.get(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/${movEscalafonario.id}`, headersAuth())
            .then(res => {
                if(res.data.status === 200) {
                    const listadoEmpleados = res.data.dataResponse.empleados.map(item => {
                        item.nombreCompleto = `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`
                        return item;
                    });
                    setListadoEmpleadosEscalafon({ info: movEscalafonario, empleadosListaEscalafon: listadoEmpleados });
                } else {
                    setListadoEmpleadosEscalafon({ info: movEscalafonario, empleadosListaEscalafon: [] });
                    setTextError(res.data.msg);
                    setOpenAlertE(true);
                }
            })
            .catch(error => {
                setListadoEmpleadosEscalafon({ info: movEscalafonario, empleadosListaEscalafon: [] });
                setTextError(verifique_conexion);
                setOpenAlertE(true);
            });
        }
    };

    const mostrarTablaVerEscalafon2 = movEscalafonario => {
        setError(null);
        setTextError('');
        setMuestraTablaEscalafon(false);
        setMuestraTablaVerEscalafon(false);
        setMuestraTablaVerEscalafon2(true);
        servicioEmpleadosEscalafon(movEscalafonario);
    };

    return (
        <div id={`modulo${id}`}>
        {(empleadosActivos)
        ?   <Fragment>
                <div id="table-emp" style={{ display: (muestraTablaEscalafon || muestraTablaVerEscalafon || muestraTablaVerEscalafon2) ? 'none' : 'block' }}>
                    <TableMovimientosEspeciales
                        id={id} empleadosActivos={empleadosActivos} mostrarTablaEscalafon={mostrarTablaEscalafon} permisos={permisos}
                    />
                </div>
                <div id="table-slct" style={{ display: (muestraTablaEscalafon) ? 'block' : 'none' }}>
                    <BtnBack tooltipText="Regresar a Tabla de Movimientos Especiales" action={ocultarTablaEscalafon}/>
                    <CardComponent title={<h4 style={{ paddingRight: '30px', fontWeight: 'bold' }}>Escalafón</h4>}>
                        { error }
                        <TableEscalafon
                            empleadosActivos={empleadosActivos} mostrarModalEscalafon={mostrarModalEscalafon}
                            cleanTable={cleanTable} setCleanTable={setCleanTable}
                        />
                    </CardComponent>
                    <ModalEscalafon
                        nombreModulo={id}
                        openEscalafon={muestraModalEscalafon} setOpenEscalafon={setMuestraModalEscalafon}
                        empleadosEscalafon={empleadosEscalafon} setEmpleadosEscalafon={setEmpleadosEscalafon}
                        ocultarTablaEscalafon={ocultarTablaEscalafon}
                    />
                </div>
                <div id="table-mov" style={{ display: (muestraTablaVerEscalafon) ? 'block' : 'none' }}>
                    <BtnBack tooltipText="Regresar a Tabla de Movimientos Especiales" action={ocultarTablaEscalafon}/>
                    {(escalafones)
                    ?   <CardComponent title={<h4 style={{ paddingRight: '30px', fontWeight: 'bold' }}>Ver Movimientos Escalafonarios</h4>}>
                            <TableVerMovEscalafonarios
                                id={id} escalafones={escalafones} mostrarTablaVerEscalafon2={mostrarTablaVerEscalafon2}
                                permisos={permisos} nominasRL={nominasRL} periodoActual={periodoActual}
                            />
                        </CardComponent>
                    :   <SpinnerOval/>
                    }
                </div>
                <div id="table-escalafonList" style={{ display: (muestraTablaVerEscalafon2) ? 'block' : 'none' }}>
                    <BtnBack tooltipText="Regresar a Tabla de Ver Movimientos Escalafonarios" action={ocultarTablaEscalafonListaEmpleados}/>
                    {(empleadosListaEscalafon)
                    ?   <TablaMovimientosEscalafon id={`${id}verEmpladosMovEsc`}
                            info={info}
                            empleadosListaEscalafon={empleadosListaEscalafon}
                        />
                    :   <SpinnerOval/>
                    }
                </div>
            </Fragment>
        :   <SpinnerOval/> 
        }
        <AlertError show={openAlertE} setOpenAlertSyE={setOpenAlertE} title="Petición Fallida" text={textError} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
    nominasRL: state.PeriodoActualReducer.nominasRL,
    periodoActual: state.PeriodoActualReducer.periodoActual,
});
  
const mapDispatchToProps = { obtenerEmpleadosActivos };
 
export default connect(mapStateToProps, mapDispatchToProps)(Especiales);