import React from 'react';
import { floatToAmount } from '../../../../../../../../Services/Validation/HelperValidation';
import ConvertDate from '../../../../../Helpers/ConvertDate';

const DatosEmpleado = ({ empleado }) => {
    return (
        <div className="row panel-info">
            { empleado &&
                <div className="col-12">
                    <div className="textPerfil"><strong>Nombre: </strong>{ empleado.nombreCompleto }</div>
                    <div className="textPerfil"><strong>Número Único de Empleado: </strong>{ empleado.empleadoClave }</div>
                    <div className="textPerfil"><strong>RFC: </strong>{ empleado.empleadoRfc }</div>
                    <div className="textPerfil"><strong>Unidad Responsable / Dependencia: </strong>{ empleado.ciaRazonSocial }</div>
                    <div className="textPerfil"><strong>Unidad Ejecutora: </strong>{ empleado.udejecutoraNom }</div>
                    <div className="textPerfil"><strong>Programa: </strong>{ empleado.programaNom }</div>
                    <div className="textPerfil"><strong>Proyecto Acción: </strong>{ empleado.proyectoNom }</div>
                    <div className="textPerfil"><strong>Clave Presupuestal: </strong>{ empleado.cpresupuestalClave }</div>
                    <div className="textPerfil"><strong>Proyecto: </strong>{ empleado.cpresupuestalNom }</div>
                    <div className="textPerfil"><strong>Departamento: </strong>{ empleado.areaNom }</div>
                    <div className="textPerfil"><strong>Número Único de Plaza: </strong>{ empleado.plazaClave }</div>
                    <div className="textPerfil"><strong>Nivel: </strong>{ empleado.nivLaboralClave }</div>
                    <div className="textPerfil"><strong>Clave del Puesto: </strong>{ empleado.puestoCve }</div>
                    <div className="textPerfil"><strong>Puesto: </strong>{ empleado.puestoNom }</div>
                    <div className="textPerfil"><strong>Sueldo: </strong>{ floatToAmount(parseFloat((empleado.empsueldoMonto)).toFixed(2)) }</div>
                    <div className="textPerfil"><strong>Zona de Pago: </strong>{ empleado.zonaPago }</div>
                    <div className="textPerfil"><strong>Turno: </strong>{ empleado.turnoNom }</div>
                    <div className="textPerfil"><strong>Nómina: </strong>{ empleado.nominaNombre }</div>
                    <div className="textPerfil"><strong>Fecha Inicial: </strong>{ ConvertDate(empleado.empstatusFecini) }</div>
                </div>
            }
        </div>
    );
}
 
export default DatosEmpleado;