import React, { createRef} from 'react';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import {Button} from '@material-ui/core';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertForm from '../../../../../../Services/AlertForm';

const FormTabuladoresQuinquenios = ({ error, setError, enviarTabuladorQuinquenios, elementSelect, informacionAdicional }) => {
    
    const validaFecha = (newFechaInic) => {
        if(informacionAdicional.tabulador){
            if(newFechaInic <= inputDateToLong(informacionAdicional.tabulador.fecIni.split('/').reverse().join('-'))) {
                setError(<AlertForm message={`La fecha inicial debe ser mayor a ${informacionAdicional.tabulador.fecIni}`}/>);
                return false;
            } else {
                setError(null);
                return true;
            }
        }else{
            setError(null);
            return true;
        }
        
    };

    const fechainiRef = createRef('');

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarTabuladorQuinquenios')) {
            if(validaFecha(inputDateToLong(fechainiRef.current.value))){
                const fechaini = inputDateToLong(fechainiRef.current.value);
                enviarTabuladorQuinquenios( { fechaini} );
            }
        }
    };
    return (  
        
        <form id={ elementSelect ? 'EditarPuesto' : 'AgregarTabuladorQuinquenios' } onSubmit={ enviar }>
            <div className={`form-group`}> 
                <InputDateValidation title="Fecha de Inicio" refValue={fechainiRef} required
                    defaultValue={ '' } />
            {error}
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </div>
        </form>   
    );
}
export default FormTabuladoresQuinquenios;
