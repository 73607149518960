import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Tabla = ({ id, permisos, nombreReporte, empleadosActivos, obtenerEmpleadosActivos }) => {

    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        obtenerEmpleadosActivos((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
        {(empleadosActivos)
        ?   <DataTableService id={id} title={ 'Busqueda Avanzada' } permisos={permisos}
                columns={[
                    { field: "empleadoClave", title: "NUE", type: 'numeric', filtering: false, filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                    { field: "nombreCompleto", title: "Nombre", filtering: false }, 
                    { field: "empleadoCurp", title: "CURP", filtering: false, hidden: true, hiddenByColumnsButton: true },
                    { field: "empleadoRfc", title: "RFC", filtering: false },
                    { field: "empleadoNss", title: "NSS", filtering: false },
                    { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                    { field: "puestoCve", title: "Clave Puesto", filtering: false},
                    { field: "puestoNom", title: "Puesto",  },
                    { field: "nominaNombre", title: "Nómina",   },
                    { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto", filtering: false }, 
                    { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                ]}
                data={empleadosActivos} 
                hideIconAdd
                hideIconDelete
                hideIconEdit
                showIconDescription
                customOptionsTable={{ filtering:true }}
                informacionAdicional={nombreReporte}
            />
        :   <SpinnerOval />
        }
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});
  
const mapDispatchToProps = { obtenerEmpleadosActivos };
  
export default connect(mapStateToProps, mapDispatchToProps)(Tabla);