import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Mensaje from './Mensaje';
import Spinner from '../../../../Services/Spinner/Spinner';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Tooltip } from 'reactstrap';
import { red, green } from '@material-ui/core/colors';
import { mensajesVentana } from '../../../../Constants/Constants';
import './ChatWindow.css';

const VentanaChat = ({ id, usuario, imgUsuario, socket, ventanaUsuario, eliminaChat, mensajeNuevo, setMensajeNuevo, lineas, ventanasXLinea, posiciones, setPosiciones, ventanaActiva, setVentanaActiva, usuarioActivo }) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const useStyles = makeStyles(theme => ({
        margin: {
            color: '#52547B',
            margin: theme.spacing(1),
            position: 'absolute',
            left: 225,
            bottom: 40,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        sendIcon: {
            fontSize: 25,
        },
        connected:{
            color: green[500],
            fontSize: 10,
            marginBottom: 8,
            marginRight: 5,
        },
        disconnected:{
            color: red[500],
            fontSize: 10,
            marginBottom: 8,
            marginRight: 5,
        },
    }));

    const classes = useStyles();
    //acciones de la ventana
    const [minimizar, setMinimizar] = useState(false);
    //información ventana
    const [sala, setSala] = useState(null);
    const [escribiendo, setEscribiendo] = useState(false);
    //mensajes
    const [mensajes, setMensajes] = useState([]);

    const [numMensajes, setNumMensajes] = useState(0);
    //state para manejo de la posición de scroll
    const [rango, setRango] = useState(0);
    const [rangoant, setRangoant] = useState(0);

    //ref para obtener el mensaje que se va a enviar
    const mensajeRef = useRef('');

    let styles = {
        position: 'fixed',
        bottom: '',
        left: '',
        height: 350,
        width: 280,
    };

    const styleButtonClose = {
        position: 'relative',
        left: 215,
        bottom: 33,
    }

    const styleButtonMin = {
        position: 'relative',
        left: 210,
        bottom: 33,
    }

    //recorrido al arreglo que contiene las ventanas abiertas pára darles la posición en el DOM
    for(let n = lineas; n > 0; n--) {
        if(id > (ventanasXLinea * n) - 1){
            styles.left = 300 + (290 * (id - (ventanasXLinea * n)));
            styles.bottom = posiciones[id - (ventanasXLinea * n)] + (355 * n);
        } else {
            styles.left = 300 + (290 * id);
            styles.bottom = 0;
        }
    }

    useEffect(() => {
        if(rango === 0 & rangoant === 0) {
            if(mensajeNuevo.length > mensajesVentana) {
                setRango(mensajeNuevo.length);
                setRangoant(mensajeNuevo.length - 1);
            } else {
                setRango(mensajesVentana);
                setRangoant(mensajesVentana);
            }
        }
    },[rango, rangoant, mensajeNuevo])

    useEffect(() => {
        var copiaPosiciones = posiciones.slice();
        if(id > ventanasXLinea-1 & id === copiaPosiciones.length) {
            copiaPosiciones.push(355);
            setPosiciones(copiaPosiciones);
        } else if(id < ventanasXLinea & id === copiaPosiciones.length) {
            copiaPosiciones.push(0);
            setPosiciones(copiaPosiciones);
        }
    },[id, posiciones, setPosiciones, ventanasXLinea]);

    useEffect(() => {
        if(socket === null) {
            return;
        }
        //reinicia mensajes recibidos cuando entra a la conversación
        const reiniciaMensajesNuevos = sala => {
            const nmensajes = mensajeNuevo.filter(mn => mn.from !== ventanaUsuario.username);
            if(nmensajes.length !== mensajeNuevo.length){
                setMensajeNuevo(nmensajes);
                socket.emit('chat:messagedesc', {sala, usuario: usuario.usuario})
            }
        }

        if(sala === null) {
            const uSala = [ventanaUsuario.username, usuario.usuario];
            const order = uSala.sort();
            const sala = order[0] + order[1];
            setSala(sala);
            socket.emit('chat:history', { nameDoc: sala, wuser: ventanaUsuario.username, user: usuario.usuario });
            reiniciaMensajesNuevos(sala);
        }
    },[mensajeNuevo, sala, setMensajeNuevo, socket, usuario, ventanaUsuario]);

    useEffect( () => {
        if(socket === null) {
            return;
        }
        socket.on(`chat:history${ventanaUsuario.username}`, data => {
            setMensajes(data);
        });
        
        return( () => {
          socket.off(`chat:history${ventanaUsuario.username}`);
        });
      },[socket, mensajes, ventanaUsuario]);

    useEffect( () => {
        if(socket === null) {
            return;
        }
        socket.on(`chat:message${ventanaUsuario.username}`, (data)=> {
            setMensajes([...mensajes, data.response]);
            if(data.sameChat === false & ventanaActiva !== ventanaUsuario.username ) {
                setEscribiendo(false);
                setNumMensajes(numMensajes + 1);
                if(numMensajes < mensajesVentana) {
                    setRango(mensajesVentana + numMensajes);
                    setRangoant(mensajesVentana + numMensajes + 1);
                } else {
                    setRango(numMensajes + 1);
                    setRangoant(numMensajes);
                }
            } else if(data.sameChat === false & ventanaActiva === ventanaUsuario.username) {
                setEscribiendo(false);
                if(rango > mensajesVentana) {
                    setRango(rango + 1);
                    setRangoant(rangoant + 1);
                    setNumMensajes(numMensajes + 1);
                }
            }
        });

        return(() => {
          socket.off(`chat:message${ventanaUsuario.username}`)
        });
    });

    useEffect( () => {
        if(socket === null) {
          return;
        }
        socket.on(`chat:typing${ventanaUsuario.username}`, data => {
            setEscribiendo(data);
        });
    
        return(() => {
          socket.off(`chat:typing${ventanaUsuario.username}`);
        });
    },[socket, ventanaUsuario]);

    //posiciona en los mensajes nuevos
    const verMensajes = () => {
        setVentanaActiva(ventanaUsuario.username);
        if(numMensajes < mensajesVentana) {
            setRango(mensajesVentana);
            setRangoant(mensajesVentana - 1);
            setNumMensajes(0);
        } else {
            setRango(numMensajes);
            setRangoant(numMensajes + 1);
            setNumMensajes(numMensajes - mensajesVentana);
        }
        //reinicia mensajes recibidos cuando entra a la conversación
        const reiniciaMensajesNuevos = () => {
            const nmensajes = mensajeNuevo.filter(mn => mn.from !== ventanaUsuario.username);
            if(nmensajes.length !== mensajeNuevo.length){
                setMensajeNuevo(nmensajes);
                socket.emit('chat:messagedesc', {sala, usuario: usuario.usuario})
            }
        }
        reiniciaMensajesNuevos();
    }

    //recibe la petición de minimizar solo esta ventana de chat
    useEffect( () => {
        if(socket === null) {
          return;
        }
        socket.on(`chat:minimizaVentana${ventanaUsuario.username}`, () => {
            var copiaPosiciones = posiciones.slice();
            const element = document.getElementById(`inbox_msg${ventanaUsuario.username}`);
            element.classList.toggle("minimizaVentana");
            setMinimizar(true)
            copiaPosiciones[id] = posiciones[id]-300;
            setPosiciones(copiaPosiciones);
            setVentanaActiva('');
        });
    
        return(() => {
          socket.off(`chat:minimizaVentana${ventanaUsuario.username}`);
        });
    },[socket, ventanaUsuario, id, posiciones, setPosiciones, setVentanaActiva]);

    useEffect( () => {
        if(socket === null) {
          return;
        }
        socket.on(`chat:maximizaVentana${ventanaUsuario.username}`, () => {
            var copiaPosiciones = posiciones.slice();
            const element = document.getElementById(`inbox_msg${ventanaUsuario.username}`);
            element.classList.toggle("minimizaVentana");
            setMinimizar(false);
            copiaPosiciones[id] = posiciones[id] + 300;
            setPosiciones(copiaPosiciones);
            setVentanaActiva(ventanaUsuario.username);
            //verMensajes
            if(numMensajes < mensajesVentana) {
                setRango(mensajesVentana);
                setRangoant(mensajesVentana - 1);
                setNumMensajes(0);
            } else {
                setRango(numMensajes);
                setRangoant(numMensajes - 1);
                setNumMensajes(numMensajes - mensajesVentana);
            }
            //termina vermensajes
            //reinicia mensajes recibidos cuando entra a la conversación
            const reiniciaMensajesNuevos = () => {
                const nmensajes = mensajeNuevo.filter(mn => mn.from !== ventanaUsuario.username);
                if(nmensajes.length !== mensajeNuevo.length) {
                    setMensajeNuevo(nmensajes);
                    socket.emit('chat:messagedesc', { sala, usuario: usuario.usuario })
                }
            }
            reiniciaMensajesNuevos();
        });
    
        return(() => {
          socket.off(`chat:maximizaVentana${ventanaUsuario.username}`);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[socket, posiciones]);
        
    let buttonMinMax;
    if(minimizar === true) {
        styles.height = 50;
        buttonMinMax = <label className="button-head" style={styleButtonMin} onClick={() => socket.emit('chat:minimizaVentana', { usuario: usuario.usuario, usuarioChat: ventanaUsuario.username, minimiza: false })}><ExpandLessIcon /></label>;
    } else {
        styles.height = 350;
        buttonMinMax = <label className="button-head" style={styleButtonMin} onClick={() => socket.emit('chat:minimizaVentana', { usuario: usuario.usuario, usuarioChat: ventanaUsuario.username, minimiza: true })}><ExpandMoreIcon /></label>;
    }

    useEffect( () => {
        const objDiv = document.getElementById(`msg_history${ventanaUsuario.username}`);

        if(rango === mensajesVentana) {
            objDiv.scrollTop = objDiv.scrollHeight;
        } else {
            if(rangoant < rango) {
                objDiv.scrollTop = 1;
                document.getElementById(`mensaje${ventanaUsuario.username}`).value = '';
            } else {
                objDiv.scrollTop = objDiv.scrollHeight-219;
            }
        }
    })

    //al presionar enter se envía el mensaje
    const enter = e => {
        if(socket === null) {
            return;
        }
        const escribe = type => {
            socket.emit('chat:typing', { from: usuario.usuario, forU: ventanaUsuario.username, type });
        }
    
        if(mensajeRef.current.value.length === 0) {
            escribe(false);
            setVentanaActiva('');
        } else if(mensajeRef.current.value.length > 0 & mensajeRef.current.value.length < 4) {
            escribe(true);
            setVentanaActiva(ventanaUsuario.username);
        }
    
        if(e.keyCode === 13) enviaMensaje();
    }

    const enviaMensaje = () => {
        if(socket === null) {
            return;
        }
        if(mensajeRef.current.value.trim().length > 0) {
            //state para manejo de la posición de scroll
            setRango(mensajesVentana);
            setRangoant(mensajesVentana);
            socket.emit('chat:message', { "mensaje": mensajeRef.current.value.trim(), "from":usuario, "forU":ventanaUsuario, sala });
            document.getElementById(`mensaje${ventanaUsuario.username}`).value='';
            document.getElementById(`mensaje${ventanaUsuario.username}`).focus();
            setVentanaActiva('');
        }
    }

    const scroll = () => {

        let elmnt = document.getElementById(`msg_history${ventanaUsuario.username}`);
        let y = elmnt.scrollTop;

        if(y === 0) {
            if(rango < mensajes.length) {
                setRango(rango + 1);
                setRangoant(rango);
            }
        } else if(y > elmnt.scrollHeight - 219 & rango >= mensajesVentana) {
            if(rango > mensajesVentana){
                setRango(rango - 1);
                setRangoant(rango);
            } else {
                const reiniciaMensajesNuevos = () => {
                    const nmensajes = mensajeNuevo.filter(mn => mn.from !== ventanaUsuario);
                    if(nmensajes.length !== mensajeNuevo.length) {
                        setMensajeNuevo(nmensajes);
                        socket.emit('chat:messagedesc', { usuario: usuario.usuario, nmensajes })
                    }
                }

                setRango(mensajesVentana);
                setRangoant(mensajesVentana);
                setNumMensajes(0);
                reiniciaMensajesNuevos();
            }
        }
    }
    
    let fragConv = '';
    if(mensajes.length > mensajesVentana) {
        const bloque = mensajes.length-rango;
        fragConv = mensajes.slice(bloque, bloque + mensajesVentana);
    } else {
        fragConv = mensajes;
    }

    let newMess = '';
    (rango > mensajesVentana) ? newMess = <IconButton aria-label="delete" className={classes.margin} size="small" onClick={() => verMensajes()}><ArrowDownwardIcon fontSize="inherit" /></IconButton> : newMess = '';

    let typing = '';
    (escribiendo) ? typing = <label id="typing" type="text">{ `${ventanaUsuario.datosUsuarioNom} está escribiendo` }{ <Spinner /> }</label> : typing = <label id="typing" type="text">{ '' }</label> ;

    const nombreUsuarioChat = `${ventanaUsuario.datosUsuarioNom} ${ventanaUsuario.datosUsuarioPat}`;

    return (
        <div id={`containerChat${ventanaUsuario.username}`} className="containerChat" style={styles}>
            <div className="messaging">
                <div className={(numMensajes > 0) ? 'usuario parpadea' : 'usuario'}>
                    <div className="containerHeaderChat">
                        { usuarioActivo ? <Brightness1Icon className={classes.connected} /> : <Brightness1Icon className={classes.disconnected} /> }
                        <span id={`_${ventanaUsuario.id}`} className="spanInfo">
                            <label>{ (nombreUsuarioChat.length > 20) ? `${nombreUsuarioChat.substring(0,20)}...` : nombreUsuarioChat }</label>
                        </span>
                        <Tooltip className="chatTooltip" placement="top" isOpen={tooltipOpen} target={`_${ventanaUsuario.id}`} toggle={toggle}>{ventanaUsuario.username}</Tooltip>
                    </div>
                    { buttonMinMax }
                    <label className="button-head" style={styleButtonClose} onClick={() => eliminaChat(ventanaUsuario, id, numMensajes, sala)}><CloseIcon /></label>
                </div>
                <div id={`inbox_msg${ventanaUsuario.username}`} className="inbox_msg" onClick={ () => verMensajes() }>
                    <div className="mesgs">
                        <div id={`msg_history${ventanaUsuario.username}`} className="msg_history" onScroll={() => scroll()} onClick={() => verMensajes()}>
                            {
                                Object.keys(fragConv).map(key => {
                                    return(
                                        <Mensaje
                                            key={key}
                                            mensajes={fragConv[key]}
                                            usuario={usuario.usuario}
                                            verMensajes={verMensajes}
                                            imgSent={imgUsuario.img}
                                            imgRecibed={ventanaUsuario.img}
                                        />
                                    );
                                })
                            }
                        </div>
                        { typing }
                        { newMess }
                        <div className="type_msg">
                            <div className="input_msg_write">
                                <input id={`mensaje${ventanaUsuario.username}`}  onClick={() => verMensajes()} type="text" autoComplete="off" autoFocus className="write_msg" placeholder="Escriba su mensaje" ref={mensajeRef} onKeyUp={e => enter(e)}/>
                                <button className="msg_send_btn" type="button" onClick={() => enviaMensaje()}><SendIcon className={classes.sendIcon} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VentanaChat;