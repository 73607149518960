import React, { useState, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import TooltipComponent from '../Tooltip/TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DateFnsUtils from "@date-io/date-fns";
import { es } from 'date-fns/locale'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"; 
import { comprobar, inputDateToLong, longDateToInputDateSafari } from './HelperValidation';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

const InputDateValidation = ({ title, refValue, defaultValue, required, optional, disabled, tooltipText, onChangeExecute, onBlurExecute, informacionAdicional, validateEqualLess, validateEqualGreater, validateGreaterThan, validateDateGreaterEqualThan, className }) => {
    
    const [ valid, setValid ] = useState( null );
    const [ value, setValue ] = useState(defaultValue ? longDateToInputDateSafari(inputDateToLong(defaultValue)) : null);

    let mensaje = '';
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'));    
    
    useEffect(() => {
        if(refValue.current) {
            if(refValue.current.value === '') setValid(null);
            if(isSafari) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                defaultValue = "";
            }
        }
    }, [refValue]);

    const comprobarValid = ( obj ) => {
        if( valid !== obj.status || mensaje !== obj.mensaje ){
            setValid(obj.status);
            mensaje = obj.mensaje;
        }
    };

    const validarSafari = (date) => {
        setValue(date);
        if( date === '' || date === null ){
            if( optional ){
                setValid( null );
            }else{
                setValid( false );
            }
        }else{ 
            var d = new Date(date);
            function menor(s) { return (s < 10) ? '0' + s : s; }
            let fecha = [menor(d.getDate()),menor(d.getMonth()+1), menor(d.getFullYear())].join('/');
            refValue.current.value=[menor(d.getFullYear()),menor(d.getMonth()+1),  menor(d.getDate())].join('-');
            if(validateEqualLess) {
                comprobarValid( comprobar( inputDateToLong(fecha), 'dateEqualLess') );
            } else if(validateEqualGreater) {
                comprobarValid( comprobar( inputDateToLong(fecha), 'dateEqualGreater') );
            } else {
                comprobarValid( comprobar( fecha, 'date' ) );
            }
            if(onChangeExecute){
                onChangeExecute(date, informacionAdicional);
            }
            if(onBlurExecute){
                onBlurExecute(date, informacionAdicional);
            }
        } 
    }

    const validar = () => {
        if( refValue.current.value === '' ){
            if( optional ){
                setValid( null );
            }else{
                setValid( false );
            }
        }else{
            if(validateEqualLess) {
                comprobarValid( comprobar( inputDateToLong(refValue.current.value), 'dateEqualLess' ) );
            } else if(validateEqualGreater) {
                comprobarValid( comprobar( inputDateToLong(refValue.current.value), 'dateEqualGreater' ) );
            }else if(validateGreaterThan) {
                comprobarValid( comprobar( inputDateToLong(refValue.current.value), 'dateGreaterThan', validateGreaterThan ) );
            } else if(validateDateGreaterEqualThan) {
                comprobarValid( comprobar( inputDateToLong(refValue.current.value), 'dateGreaterEqualThan', validateDateGreaterEqualThan ) );
            } else {
                setValid( true );
            }
        }
        if(onChangeExecute){
            onChangeExecute(refValue.current.value, informacionAdicional);
        }
    };

    const ejecutaOnBlur = () => {
        if(valid) {
            onBlurExecute(refValue.current.value, informacionAdicional);
        }
    };

    const ejecutaOnBlurSafari = (date) => {
        if(valid) {
            onBlurExecute(date, informacionAdicional);
        }
    };

    return (
        <div className={`form-group ${isSafari || (optional && valid === null) ? '' : 'validar'} ${className ? className : '' }`}>
            {title &&
            <label>
                { title }{ required && <strong className="requerido">* </strong> }
                <TooltipComponent tooltipText={(tooltipText) ? tooltipText : 'Seleccione una fecha del calendario'}>
                    <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                </TooltipComponent>
            </label>
            }
            {isSafari
            ?   <MuiThemeProvider theme={theme}> 
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}> 
                        <KeyboardDatePicker 
                            disableToolbar
                            placeholder='dd/mm/aaaa'
                            format="dd/MM/yyyy" 
                            id={(validateEqualLess) ? `dateEqualLess${Math.random()}` : (validateEqualGreater) ? `dateEqualGreater${Math.random()}` : null}  
                            onChange={validarSafari}
                            onBlur={(onBlurExecute) ? ejecutaOnBlurSafari : undefined}
                            inputRef={refValue}
                            fullWidth={true}
                            KeyboardButtonProps={{ "aria-label": "change date" }} 
                            className={`form-control ${valid === null ? '' : valid ? 'is-valid' : 'is-invalid'} ${optional && valid === null ? '' : 'validar'}`}
                            value={value} 
                            disabled={(disabled) ? disabled : false} 
                            autoOk={true}
                            helperText="" 
                            InputProps={{ disableUnderline: true, autoComplete: "off" }} 
                            cancelLabel={null}
                            okLabel={null}
                            DialogProps={{ disableEnforceFocus: true }}
                            minDate={Date(1000-1-1)}
                            maxDate={Date(9999-12-31)}
                        />  
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider> 
            :   <input
                    id={(validateEqualLess) ? `dateEqualLess${Math.random()}` : (validateEqualGreater) ? `dateEqualGreater${Math.random()}` : null}
                    type="date" className={`form-control ${valid === null ? '' : valid ? 'is-valid' : 'is-invalid'}`}
                    ref={refValue} defaultValue={defaultValue} onChange={validar} onBlur={(onBlurExecute) ? ejecutaOnBlur : undefined} disabled={(disabled) ? disabled : false} min="1000-01-01" max="9999-12-31"
                />
            }            
            <div className="invalid-feedback"> Seleccione una fecha válida </div>
        </div>
    );
};

export default InputDateValidation;