import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';  
import ArrowBackIcon from '@material-ui/icons/ArrowBack'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import { deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import { ArrayJsonUsuariosReportesRRHH } from '../../../Helpers/JsonToOneLevel';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const RecursosHumanosTablaReporte = ({ id, permisos, openReport, elementSelect, onAdd, empleadosActivos, obtenerEmpleadosActivos }) => {
    
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const [columnas] = useState([
        { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
        { field: "empleadoNom", title: "Nombre" },
        { field: "empleadoPat", title: "Primer Apellido" },
        { field: "empleadoMat", title: "Segundo Apellido" },
        { field: "empleadoFecnac", title: "Fecha de Nacimiento" }, 
        { field: "empleadoGenero", title: "Género" },
        { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
        { field: "empleadoRfc", title: "RFC" },
        { field: "empantFecini", title: "Fecha de Ingreso"},
        { field: "nivLaboralClave", title: "Nivel"},
        { field: "puestoCve", title: "Clave Puesto"},
        { field: "puestoNom", title: "Puesto" },
        { field: "relabClave", title: "Clave Relación Laboral" },
        { field: "realabNom", title: "Nombre Relación Laboral" }, 
        { field: "ciaUr", title: "Clave Unidad Responsable", hidden: true, hiddenByColumnsButton: true },
        { field: "ciaRazonSocial", title: "Unidad Responsable", hidden: true, hiddenByColumnsButton: true },  
        { field: "udejecutoraClave", title: "Clave Unidad Ejecutora" },
        { field: "udejecutoraNom", title: "Unidad Ejecutora" },
        { field: "programaClave", title: "Clave Programa" },  
        { field: "programaNom", title: "Programa" },
        { field: "proyectoClave", title: "Clave Proyecto Acción" },
        { field: "proyectoNom", title: "Proyecto Acción" },
        { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) }, 
        { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
        { field: "turnoNom", title: "Nombre Turno" },
        { field: "tipojornadaClave", title: "Clave Tipo de Jornada" },
        { field: "tipojornadaNom", title: "Nombre Tipo de Jornada" },
        { field: "nominaClave", title: "Clave Nómina" },
        { field: "nominaNomCorto", title: "Nombre Corto Nómina" },
        { field: "nominaNombre", title: "Nombre Nómina" },
        { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
        { field: "areaNom", title: "Nombre Área" },
        { field: "zonaPago", title: "Zona de Pago" },
    ]);

    let filtroGeneral ='';
    const convertColumns = (columns)=>{ 
        filtroGeneral = columns[0].filtroGeneral;
        let resultado = columns[0].campos.map(element =>{ return deleteOptionals({
            field        : element.nombre,
            title        : element.titulo,
            defaultSort  : element.ordenDefault,
            defaultFilter: element.defaultFiltro,
            hidden: element.oculto,
            defaultGroupOrder: element.posicionDefaultGrupo,
            defaultGroupSort: element.ordenDefaultGrupo
        })}); 
        return resultado; 
    }

    let reportesReporteJsonParse = false;
    
    try {
        JSON.parse(elementSelect.reportesReporteJson)
        reportesReporteJsonParse =  true;
    } catch (error) {
        reportesReporteJsonParse =  false
    }

    const columns = elementSelect.id
        ? convertColumns(JSON.parse(elementSelect.reporteJson))  
        :  elementSelect.reportesId
            ?   (reportesReporteJsonParse)
                ?   convertColumns(JSON.parse(elementSelect.reportesReporteJson)) 
                :   columnas
        :   columnas;

    useEffect(() => {  
        obtenerEmpleadosActivos((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);     

    return (
        <div id={`modulo${id}${elementSelect.isStatic ? `${id}secondary`: id}`}>
            
            {
                empleadosActivos && columns
                ?
                <Fragment>
                    <div className="row">
                        <div className="col-11"></div>
                        <div className="col-1 float-left" style={{marginBlockEnd:'18px'}}>
                            <button className="form-control" onClick={()=>openReport(false)}> 
                                <Tooltip title="Regresar" placement="left-start">
                                    <ArrowBackIcon/>
                                </Tooltip>
                            </button> 
                        </div>
                    </div> 
                    <DataTableService id={ elementSelect.isStatic ? `${id}secondary`: id} title={'Reporte de Recursos Humanos'}
                        columns={columns}
                        data={ArrayJsonUsuariosReportesRRHH(empleadosActivos)}
                        isReport
                        hideIconAdd
                        hideIconDelete
                        hideIconEdit
                        showIconPDF={elementSelect.isStatic}
                        informacionAdicional={{filtroGeneral, isStatic: elementSelect.isStatic}}
                        actionAlert={() => openReport(false)}
                        onShowTable={onAdd}
                    />

                </Fragment>
                :
                <SpinnerOval/>
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});

const mapDispatchToProps = { obtenerEmpleadosActivos };

export default connect(mapStateToProps, mapDispatchToProps)(RecursosHumanosTablaReporte);