import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { limpiarPestanas } from '../../../Redux/actions/NavActions';
import { guardarRol } from '../../../Redux/actions/sessionActions';
import { abreCierraMenu } from '../../../Redux/actions/SystemActions';

import axios from 'axios';
import { servicios_grupohit_url, servicios_grupohit_catalogo, ocurrio_un_error, endpointSocket, servicios_grupohit } from '../../../Constants/Constants';
import { cerrarSession, headersAuth, obtenerUsuario, isAuthenticaded } from '../../../Services/AuthService';
import { resetAppStore } from '../../../Redux/actions/ResetActions';
import { obtenerEmpleadosActivos, obtenerEmpleadosAll, borrarEmpleadosAll, borrarEmpleadosActivos } from '../../../Redux/actions/EmpleadosActions';
import { obtenerPeriodoActual, limpiarPeriodoActual } from '../../../Redux/actions/PeriodoActualActions';
import io from 'socket.io-client';
import Tour from './Tour/Tour';
import steps from './Tour/Steps.json';
import TerminosPrivacidad from './TerminosyCondiciones/TerminosPrivacidad';
import ModalTipoRelacionLaboral from './SeleccionTipoRelacionLaboral/ModalTipoRelacionLaboral';
import ModalDetallesNominasRL from './DetallesNominasRL/ModalDetallesNominasRL';
import { ArrayJsonNominasRL } from './Helpers/JsonToOneLevel';
import ModalCambiarPassword from './TerminosyCondiciones/ModalCambiarPassword';
/* Deben de ser importados en este orden  */
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderAppBar from './MenuDrawer/Header/HeaderAppBar';
import MenuDrawer from './MenuDrawer/Drawer/MenuDrawer';
import useStylesMenu from './MenuDrawer/UseStylesMenu'; //Los estilos son importados al ultimo

import Contenidos from './Modulos/Contenidos';
import TabsContainer from './MenuDrawer/TabsNavegacion/TabsContainer';
import Footer from '../../Footer/Footer';
import AlertForm from '../../../Services/AlertForm';

import HOCAppUsuario from './MenuDrawer/HOCAppUsuario';
import AlertPassSession from '../../../Services/AlertPassSession';
import { NotificationContainer } from 'react-notifications';
import AlertSyE from '../../../Services/Alerts/AlertSyE';

const AppUsuario = (props) => {

  const {
    obtenerEmpleadosActivos, obtenerEmpleadosAll, borrarEmpleadosAll, borrarEmpleadosActivos, guardarRol, openMenu, abreCierraMenu,
    limpiarPestanas, resetAppStore, modulosApi, cleanIntervals, openContrasena, setModalContraseña, setTimeout, activarEventosInactividad, newToken, setNewToken,
    nominasRL, periodoActual, obtenerPeriodoActual, limpiarPeriodoActual
  } = props;
  
  const classes = useStylesMenu();
  //url del servidor de chat
  const endpoint = endpointSocket;
  //guarda el socket del cliente
  const [socket, setSocket] = useState(null);
  //usuario logueado
  const [usuario, setUsuario] = useState( obtenerUsuario() );
  //variables de tour
  const [run, setRun] = useState(true);
  const [tour, setTour] = useState(usuario.tour);
  //terminos y condiciones
  const [tyc, setTyC] = useState(usuario.tyc);
  const [cambiarPassword, setCambiarPassword] = useState(usuario.cambiarPassword);
  const [modal, setModal] = useState(true);
  const [modalPassword, setModalPassword] = useState(usuario.tyc === "true" && usuario.tour === "true" ? true : false);
  //SeleecionRelacionLaboral
  const [relacionesLaborales, setRelacionesLaborales] = useState([]);
  const [relacionLaboral, setRelacionLaboral] = useState('');
  const [modalRelacionLaboral, setModalRelacionLaboral] = useState(false);
  const [modalDetallesNominasRL, setModalDetallesNominasRL] = useState(false);
  const [btnAcceptDisabled, setBtnAcceptDisabled] = useState(false);
  const [error, setError] = useState(null);
  //alrteError
  const [openAlert, setOpenAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(false);

  const [ logotipo, setLogotipo ] = useState( null );
  const [ imagenPerfil, setImagenPerfil ] = useState( null );

  const mostrarAlerta = msg => {
    setTextAlert(msg);
    setOpenAlert(true);
  };

  //funcion para modificar el estado del modal de Relaciones Laborales
  const toggleRelacionLaboral = () => {
    if(!modalRelacionLaboral) {
      setBtnAcceptDisabled(false);
    }
    setModalRelacionLaboral(!modalRelacionLaboral);
  };

  //funcion para modificar el estado del modal de DEtalles de Nóminas por Relación Laboral
  const toggleDetallesNominas = () => setModalDetallesNominasRL(!modalDetallesNominasRL);

  const obtenerRelacionLaboralLS = () => {
    try {
      return JSON.parse(localStorage.getItem("IGRelLab"));
    } catch (error) {
      return null;
    }
  };

  /**----- Cuando se ha realizado el tour y el socket es nulo, se obtiene el socket para comunicarse con el servidor de chat ------*/
  useEffect(() => {
    if(tour === "false") {
      if(socket === null) {
        const s = io(endpoint); 
        setSocket(s);
        s.emit('chat:login',  
          { usuario: usuario.usuario, token: usuario.token, nuevoUsuario: usuario.tour }
        );
      }
    }
    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, usuario, tour]);

  useEffect(() => {
    if(newToken && socket) {
      socket.emit('chat:changeToken',
        { usuario: usuario.usuario, nuevoToken: newToken }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToken]);

  useEffect(() => {
    if(relacionLaboral !== '') {
      limpiarPeriodoActual();
      obtenerPeriodoActual({ mostrarAlerta });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relacionLaboral]);

  useEffect(() => {
      axiosImagenPerfil();
      axiosLogotipo();
  }, []);

  const axiosImagenPerfil = () => {
    const { usuario, claveUsuario } = obtenerUsuario();
    axios.get( `${servicios_grupohit}/img/${usuario}/${claveUsuario}`, headersAuth() )
    .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setImagenPerfil( respuesta.data.dataResponse );
                    break;
                default:
                    break;
            }
    })
    .catch( error => { console.log( error ) })
  }

  /* Axios para obtener logotipo en base64 */
  const axiosLogotipo = () => {
      axios.get(`${servicios_grupohit}/logo/`, headersAuth())
      .then( respuesta => {
        switch( respuesta.data.status ){
          case 200:
            setLogotipo(respuesta.data.dataResponse);
            break;
          default:
            break;
        }
      })
      .catch( error => { console.log(error) })
  }


  const servicioSetRelLaboral = (relacionLaboralSelect, toggle=true) => {
    setBtnAcceptDisabled(true);
    const request = { relacionLab: relacionLaboralSelect.id }
    axios.put(`${servicios_grupohit_url}/asigna/relacionLab`, request, headersAuth())
    .then(res => {
        switch(res.data.status){
            case 200:
                (toggle) && toggleRelacionLaboral();
                borrarEmpleadosActivos();
                borrarEmpleadosAll();
                limpiarPestanas(limpiarPestanas);
                localStorage.setItem("IGRelLab", JSON.stringify(relacionLaboralSelect));
                setRelacionLaboral(relacionLaboralSelect);
                setError(null);
                break;
            default:
                setError(<AlertForm message={res.data.msg}/>);
                break;
        }
    })
    .catch(error => {
        setError(<AlertForm message={ocurrio_un_error}/>)
    })
    .finally(() => setBtnAcceptDisabled(false));
  };

  useEffect(() => {
    const obtenerRelLab = () => {
      axios.get(`${servicios_grupohit_catalogo}/${usuario.rol === true ? 'relacionlaboral/' : 'relacionlaboral/nominas/'}`, headersAuth())
      .then(res => {
          switch(res.data.status) {
              case 200:
                  setRelacionesLaborales(res.data.dataResponse);
                  if(res.data.dataResponse.length === 1) servicioSetRelLaboral(res.data.dataResponse[0]);
                  break;
              case 404: //No hay datos para mostrar
                  //closeSession();
                  break;
              default:
                  closeSession();
                  break;
          }
      })
      .catch(error => closeSession());
    };

    axios.get(`${servicios_grupohit_url}/rol`, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
              const { rol, usuario, depto } = res.data.dataResponse;
              const { id, username, datos } = usuario;
              const { usuarioNom: nombre, usuarioPat: paterno, usuarioMat: materno } = datos;
              const { deptoNom } = depto;
              guardarRol({ rol, usuario: { id, nombre, paterno, materno, username, deptoNom } });
              let IGSESSID = JSON.parse(localStorage.getItem('IGSESSID'));
              IGSESSID = {
                ...IGSESSID,
                claveUsuario: id, 
                nombre: nombre,
                apaterno: paterno, 
                amaterno: materno,
                usuario: username, 
                rol: rol 
              };
              localStorage.setItem( "IGSESSID", JSON.stringify(IGSESSID));
              obtenerRelLab();
              break;
            default:
              closeSession();
              break;
        }
    })
    .catch(error => { closeSession(); });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //al iniciar se busca en localstorage IGRelLab
  useEffect(() => {
    const relacionLaboralLS = obtenerRelacionLaboralLS();
    if(relacionLaboralLS) {
      setRelacionLaboral(relacionLaboralLS);
    } else {
      if(tour === "false") {
        if(relacionesLaborales.length === 1 & relacionLaboral === '') {
          setRelacionLaboral(relacionesLaborales[0]);
        } else {
          setModalRelacionLaboral(true);
        }
      }
    }
    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour]);

  //useEffect para obtener empleados al seleccionar la relación laboral
  useEffect(() => {
    if((relacionLaboral !== null) && (relacionLaboral !== '')) {
      obtenerEmpleadosActivos(mostrarAlerta);
      obtenerEmpleadosAll(mostrarAlerta);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relacionLaboral]);

  /* ------------------ Metodo para cerrar sesión ------------------ */
  const cerrarSocket = () => {
    if(socket !== null) {
      //emit al servidor de socket para que borre el socket y actualice el arreglo de conectados
      socket.emit('chat:disconnectSocket', usuario.usuario);
      //cierra el socket del cliente
      socket.close();
    }
  }

  const closeSession = () => {
    axios.post( `${servicios_grupohit_url}/logout`, null, headersAuth() );
    try {
      //emit al servidor de socket para que borre el socket y actualice el arreglo de conectados
      socket.emit('chat:disconnect');
      //cierra el socket del cliente
      socket.close();

      //Termina el setInterval() creado para refrescar el token
      cleanIntervals();
      cerrarSession();
      resetAppStore();
    } catch(error) {
      cerrarSession();
      resetAppStore();
    }
  }

  const verificaRL = () => {
    if(relacionesLaborales.length) {
      const rlStr = localStorage.getItem("IGRelLab");
  
      if(rlStr) {
        try{
          const rl = JSON.parse(rlStr);
          const rlFound = relacionesLaborales.find(relLab => relLab.id === Number(rl.id));
          if(rlFound) {
            localStorage.setItem("IGRelLab", JSON.stringify(rlFound));
            servicioSetRelLaboral(rlFound, false);
            return;
          }
        } catch(error) {
        }
      }
  
      localStorage.removeItem("IGRelLab"); 
      window.location.reload();
    }
  };

/* -----------------------Cierra socket cuando deja de existir la pestaña--------------------------- */
  window.addEventListener("beforeunload", () => cerrarSocket(), false);
/* --------------------------- Escuchar cambios en local Storage ---------------------------  */
  window.addEventListener('storage', e => {

    if(e.key === "IGSESSID") {
      const token = localStorage.getItem("IGSESSID");
      if(!token) {
        cerrarSession();
      } else {
        if(!isAuthenticaded()) {
          cerrarSession();
        }
      }
    }

    if(e.key === "IGRelLab") {
      verificaRL();
    }
  });

  const [activarTour, setActivarTour] = useState( false );

  return (
    <Fragment>
      <div className={classes.root}> 
        { (tyc === "false")
          /* no ha aceptado tyc*/
          ? <TerminosPrivacidad modal={modal} setModal={setModal} setTyC={setTyC} closeSession={closeSession} setModalPassword={setModalPassword}/>
          : null
        }
        { (modalPassword && (cambiarPassword && cambiarPassword === true) )
          ? <ModalCambiarPassword modalPassword={modalPassword} setModalPassword={setModalPassword} setCambiarPassword={setCambiarPassword} setActivarTour={setActivarTour}/>
          : null
        }
        { (relacionesLaborales.length > 1)
          /* solo tiene acceso a una compañia */
          ? <ModalTipoRelacionLaboral
              toggleRelacionLaboral={toggleRelacionLaboral} modal={modalRelacionLaboral}
              relacionesLaborales={relacionesLaborales} relacionLaboral={relacionLaboral}
              servicioSetRelLaboral={servicioSetRelLaboral}
              btnAcceptDisabled={btnAcceptDisabled} setBtnAcceptDisabled={setBtnAcceptDisabled}
              error={error} setError={setError}
            />
          : null
        }
        { (nominasRL.length > 0)
          ? <ModalDetallesNominasRL toggleDetallesNominas={toggleDetallesNominas} modal={modalDetallesNominasRL} relacionLaboral={relacionLaboral.nombre} nominasRL={ArrayJsonNominasRL(nominasRL)}/>
          : null
        }
        {  (tour !== "false" && activarTour)
          /* no ha hecho el tour */
          ? <Tour setRun={setRun} setTour={setTour} steps={steps} />
          : <style>{`.MuiDrawer-paper { overflow-y: auto !important; }`}</style>
        }
        <CssBaseline />
        <HeaderAppBar steps={steps} run={run} openMenu={openMenu} setOpenMenu={abreCierraMenu} socket={socket} usuario={usuario} modulosApi={modulosApi} tour={tour} relacionLaboral={relacionLaboral} toggleRelacionLaboral={toggleRelacionLaboral} relacionesLaborales={relacionesLaborales} toggleDetallesNominas={toggleDetallesNominas} periodoActual={periodoActual}/>
        <MenuDrawer
          steps={steps} run={run} classes={classes} openMenu={openMenu} modulosApi={modulosApi} socket={socket}
          closeSession={closeSession} usuario={usuario} setUsuario={setUsuario}
          logotipo={logotipo} imagenPerfil={imagenPerfil} setImagenPerfil={setImagenPerfil}
        />
          <main className={clsx(classes.content, {[classes.contentShift]: openMenu, })}>
            <div className={classes.toolbar} />
            <TabsContainer opacity/>
            <Contenidos
              openMenu={openMenu}
              relacionLaboral={relacionLaboral}
              modulosApi={modulosApi}
              socket={socket}
            />
          </main>
        <AlertPassSession
          openContrasena={openContrasena} setModalContraseña={setModalContraseña} setTimeout={setTimeout}
          activarEventosInactividad={activarEventosInactividad} closeSession={closeSession} setNewToken={setNewToken}
          logotipo={logotipo}
        />
      </div>
      <Footer className="footer-components"/>
      <NotificationContainer/>
      <AlertSyE show={openAlert} setOpenAlertSyE={setOpenAlert} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  empleadosActivos: state.EmpleadosReducer.empleadosActivos,
  empleadosAll: state.EmpleadosReducer.empleadosAll,
  openMenu: state.SystemReducer.openMenu,
  nominasRL: state.PeriodoActualReducer.nominasRL,
  periodoActual: state.PeriodoActualReducer.periodoActual,
});

const mapDispatchToProps = {
  resetAppStore, limpiarPestanas, obtenerEmpleadosActivos, obtenerEmpleadosAll, borrarEmpleadosAll, borrarEmpleadosActivos,
  guardarRol, abreCierraMenu, obtenerPeriodoActual, limpiarPeriodoActual
};

export default connect(mapStateToProps, mapDispatchToProps)( HOCAppUsuario(AppUsuario) );