import { AGREGAR_NAVEGADOR, QUITAR_NAVEGADOR, CAMBIAR_COMPONENTE_ACTIVO, LIMPIAR_PESTANAS } from '../actionTypes';

const initialstate = {
    navegadores: [  ],
    componenteActivo: 1,
}

const NavReducer = ( state = initialstate, action ) => {

    switch( action.type ) {

        case AGREGAR_NAVEGADOR:
            return {
                ...state,
                navegadores: [ ...state.navegadores, action.modulo ],
            };
        
        case QUITAR_NAVEGADOR:
            return {
                ...state,
                navegadores: state.navegadores.filter( nav => nav.id !== action.moduloID ),
            };
        
        case CAMBIAR_COMPONENTE_ACTIVO:
            return {
                ...state,
                componenteActivo: action.activoID
            };
        case LIMPIAR_PESTANAS:
            return {
                navegadores: [],
                componenteActivo: 1,
            };

        default:
            return state;
    }
}

export default NavReducer;