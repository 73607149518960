import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BlueCheckboxRadioStyles from '../../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';

const SaoCheckbox = withStyles(BlueCheckboxRadioStyles)(props => <Checkbox color="default" {...props} />);

const CheckboxTipoMovimiento = ({ tipoMovimiento, setTipoMovimiento }) => {

    const handleChange = name => event => {
        switch(name) {
            case 'definitivo':
                setTipoMovimiento({ definitivo: event.target.checked, temporal: false });
                break;
            case 'temporal':
                setTipoMovimiento({ definitivo: false, temporal: event.target.checked });
                break;
            default:
                setTipoMovimiento({ definitivo: false, temporal: false });
                break;
        }
    };

    const { definitivo, temporal } = tipoMovimiento;

    return (
        <Fragment>
            <h6>Tipo de Movimiento</h6>
            <FormGroup>
                <FormControlLabel
                    control={<SaoCheckbox checked={definitivo} onChange={handleChange('definitivo')} value="definitivo" />}
                    label={<label>Definitivo</label>}
                />
                <FormControlLabel
                    control={<SaoCheckbox checked={temporal} onChange={handleChange('temporal')} value="temporal" />}
                    label={<label>Temporal</label>}
                />
            </FormGroup>
        </Fragment>
    );
}
 
export default CheckboxTipoMovimiento;
