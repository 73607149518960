import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import DataTableService from '../../../../../DataTableService/DataTableService';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { arrayJsonMovimientosPorPeriodoOtrosMovimientos } from '../../../../../Helpers/JsonToOneLevel';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import InputText from '../../../../../../../../Services/Components/DataTable/InputText';

const Escalafon = ({ id, requestTabla, setBloqueaCards, setError, moverATabla, informacionAdicional }) => {

    const [infoTabla, setInfoTabla] = useState(null);

    useEffect(() => {
        const servicioEscalafones = () => {
            setBloqueaCards(true);
            const request = {
                periodoAnio: requestTabla.periodoAnio,
	            periodoPerAnio: requestTabla.periodoPerAnio,
	            nominaId: requestTabla.nomina
            };
            axios.post(`${servicios_grupohit_rh}/vmovimientos/fijos/bitacora/`, request, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        const arrayEscalafones = arrayJsonMovimientosPorPeriodoOtrosMovimientos(res.data.dataResponse);
                        setInfoTabla(arrayEscalafones);
                        break;
                    default:
                        setInfoTabla([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { console.log(error); setInfoTabla([]); setError(<AlertForm message={verifique_conexion} />) })
            .finally(() => moverATabla() );
        };

        servicioEscalafones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
        {(infoTabla)
        ?   <DataTableService
                id={`${id}OtrosMovimientos`}
                title="Otros Movimientos"
                columns={[
                    { field: "empleadoClave", title: "NUE", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                    { field: "nombreCompleto", title: "Nombre" },
                    { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                    { field: "puesto", title: "Puesto" },
                    { field: "conceptoCve", title: "Clave Concepto" },
                    { field: "concepto", title: "Concepto" },
                    { field: "nomina", title: "Nómina" },
                    { field: "nombre", title: "Usuario" },
                    { field: "fechaMovimiento", title: "Fecha de Registro" },
                ]}
                data={infoTabla}
                customOptionsTable={{ filtering: true }} 
                hideIconAdd hideIconEdit hideIconDelete
                informacionAdicional={informacionAdicional}
            />
        :   <SpinnerOval/>
        }
        </Fragment>
    );
};

export default Escalafon;
