import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_catalogo, monto_minimo_liquidez } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonMontoMinimo } from '../../../Helpers/JsonToOneLevel';

const MontoMinimoLiquidez = ({ id, permisos }) => {
    const [ montoMinimo, setMontoMinimo ] = useState( null );
    useEffect(() => {
        axiosMontoMinimo();
    }, []);

    const axiosMontoMinimo = () => {
        axios.get( `${servicios_grupohit_catalogo}/montominimo/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setMontoMinimo( ArrayJsonMontoMinimo(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        //setMontoMinimo( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { montoMinimo ?
                <DataTableService id={id} title={ monto_minimo_liquidez } permisos={permisos}
                    columns={[
                        { field: "montominimo", title: "Monto Mínimo" },
                    ]}
                    hideIconAdd hideIconDelete
                    data={ montoMinimo } /> : <SpinnerOval />
            }
        </div>
    );
};

export default MontoMinimoLiquidez;