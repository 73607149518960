import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';

import RestringirModulo from './RestringirModulo';
import { obtenerRestriccionesModulo, obtenerRestriccionesSinModulo } from './HelperRestricciones';

const ModalRestringirModulo = ({ openModulo, setOpenModulo, moduloSelect, errorTable, successTable, informacion, idModulo, setRestricciones }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenModulo( false );
        setError( null );
    }

    const enviarRestriccion = ( usuarios ) => {
        axios.post( `${servicios_grupohit_url}/modulos/bloqueados/`, {modulos: usuarios}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        successTable( 'Registro Actualizado' );
                        setRestricciones( usuarios );
                        setOpenModulo( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Restringir Módulo" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openModulo } onClose={ () => cerrarModal() } >
                <div> <b> Módulo: </b> {moduloSelect.nombre} </div>

                <RestringirModulo
                    error={error}
                    enviarRestriccion={enviarRestriccion}
                    moduloID={moduloSelect.id}
                    usuarios={informacion.usuarios}
                    restriccionesModulo={obtenerRestriccionesModulo(informacion.restricciones, moduloSelect.id)}
                    restriccionesSinModulo={obtenerRestriccionesSinModulo(informacion.restricciones, moduloSelect.id)}
                />
        </ModalService>
    );
};

export default ModalRestringirModulo;