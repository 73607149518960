import React, { useState } from 'react';
import { connect } from 'react-redux';
import displayWindowSize from '../../../Helpers/DisplayWindowSize';
import FormEmpleadosBusquedaAvanzada from '../../Tablero/FormEmpleadosBusquedaAvanzada';
import TablaEmpleados from './TablaEmpleados';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const PagosUnicos = ({ id, permisos, openMenu }) => {

    const [empleados, setEmpleados] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [maxWidth, setMaxWidth] = useState(displayWindowSize().width);
    
    window.addEventListener("resize", () => setMaxWidth(displayWindowSize().width));

    return (
        <div id={`modulo${id}`}>
            <div style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <FormEmpleadosBusquedaAvanzada
                            setEmpleados={setEmpleados} isLoading={isLoading} setIsLoading={setIsLoading} pagosUnicos
                        />
                    </div>
                </div>
            </div>
            { (empleados || isLoading) &&
                <div style={{marginTop:'2rem'}}>
                    {!isLoading
                    ?   <TablaEmpleados id={id} permisos={permisos} empleados={empleados} />
                    :   <SpinnerOval/>
                    }
                </div>
            }
        </div>
    );
}
 
const mapStateToProps = (state) => ({
    openMenu: state.SystemReducer.openMenu
});

export default connect(mapStateToProps, null)(PagosUnicos);