import React from 'react';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import DataTableService from '../../../DataTableService/DataTableService';

const TablaEmpleados = ({ id, permisos, empleados }) => {
    return (
        <DataTableService id={id} title="Busqueda de empleado" permisos={permisos}
            columns={[
                { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                { field: "nombreCompleto", title: "Nombre", filtering: false }, 
                { field: "empleadoCurp", title: "CURP", filtering: false, hidden: true, hiddenByColumnsButton: true },
                { field: "empleadoRfc", title: "RFC", filtering: false },
                { field: "empleadoNss", title: "NSS", filtering: false },
                { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                { field: "puestoCve", title: "Clave Puesto", filtering: false},
                { field: "puestoNom", title: "Puesto" },
                { field: "nominaNombre", title: "Nómina" },
                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto", filtering: false }, 
                { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                { field: "status", title: "Status" }
            ]}
            data={empleados} 
            showIconNewFolder
            hideIconDelete
            hideIconEdit
            showIconDescription
            showIconToday
            customOptionsTable={{ filtering: true }} 
            informacionAdicional={{ permisos }}
        />
    );
}
 
export default TablaEmpleados;