import React, { createRef, useState, Fragment, useEffect } from 'react';
import {Button } from '@material-ui/core';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import { servicios_grupohit_plantillas, verifique_conexion } from '../../../../../../../Constants/Constants';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonPlantillas } from '../../../../Helpers/JsonToOneLevel';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';

const FormPlantillaEmpleadosProrroga = ({ error, setError, enviarPlantilla, loading, setLoading}) => {

    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    
    const plantillaRef = createRef('');
    const enviar = e => {
        e.preventDefault();
        
        if(validarSubmit('GenerarPlantillaProrroga')) {
            const plantillaId = parseInt(plantillaRef.current.value);
            const nombrePlantilla = plantillas.find(element=> element.id===plantillaId).plantillaNom;
            enviarPlantilla( { plantillaId }, nombrePlantilla );  
        }
    }; 
    
    const [plantillas, setPlantillas] = useState(null);
     
    useEffect(() => {
        axios.get(`${servicios_grupohit_plantillas}/all/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newPlantillas = ArrayJsonPlantillas(res.data.dataResponse)
                    setPlantillas(newPlantillas);
                    break;
                case 404:
                    setPlantillas([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return ( 
        <Fragment> 
        <form id={'GenerarPlantillaProrroga'} onSubmit={ enviar }> 
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    {
                        plantillas
                        ?
                        <SelectValidation 
                            title="Plantilla" 
                            arrayOpciones={plantillas} 
                            keyOpcion="plantillaNom" 
                            refValue={plantillaRef}
                            required
                        />
                        :<SpinnerOval/>
                    } 
                </div>            
            </div>
            {error} 
            <Button variant="contained" className="btn-color" type="submit">{ loading ? <CircularProgress size={25}/> : 'Generar' }</Button> 
        </form>
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
export default FormPlantillaEmpleadosProrroga;
