import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
//import { JsonMovimientosEmpleado } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioPagosUnicos from './FormularioPagosUnicos';

const ModalEditPagosUnicos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarPagoUnico = ( pagoUnico ) => {
        axios.post( `${servicios_grupohit_nomina}/acumulados/tacumulados/info/`, pagoUnico, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const elementSelectAux = elementSelect;
                        elementSelectAux.periodoAnioInfo = respuesta.data.dataResponse.periodoId.periodoAnio;
                        elementSelectAux.periodoPeranioInfo = respuesta.data.dataResponse.periodoId.periodoPerAnio;
                        editar( elementSelectAux );
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        setOpenEdit( false ); //Borrar
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
                setOpenEdit( false ); //Borrar
            })
    }

    return (
        <ModalService title="Actualizar Pagos Único" parentNode={informacionAdicional.parentNode} minWidth="40%" maxWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <div> <b> Número de Empleado: </b> {elementSelect.empleadoClave} </div>
                <div className="row mb-2">
                    <div className="col-md-6 col-sm-12">
                        <div> <b> Nombre: </b> {elementSelect.nombreCompleto} </div>
                        <div> <b> Clave Presupuestal / Proyecto: </b> {elementSelect.cpresupuestalClave} </div>
                        <div> <b> Cantidad: </b> {elementSelect.cantidad} </div>
                        <div> <b> Año: </b> {elementSelect.periodoAnioInfo} </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div> <b> RFC: </b> {elementSelect.empleadoRfc} </div>
                        <div> <b> Unidad Responsable / Dependencia: </b> {elementSelect.ciaRazonSocial} </div>
                        <div> <b> Monto: </b> {elementSelect.monto} </div>
                        <div> <b> Periodo: </b> {elementSelect.periodoPeranioInfo} </div>
                    </div>
                </div>

                <FormularioPagosUnicos
                    error={error}
                    setError={setError}
                    elementSelect={elementSelect}
                    informacion={informacionAdicional}
                    actualizarPagoUnico={actualizarPagoUnico}
                />
        </ModalService>
    );
};

export default ModalEditPagosUnicos;