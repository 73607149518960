import React, { useState } from 'react';
import Axios from 'axios';
import { registro_guardado, servicios_grupohit_catalogo, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadoresGenerales } from '../../../Helpers/JsonToOneLevel';
import ModalService from './../../../ModalService/ModalService';
import FormIndicadoresGenerales from './FormIndicadoresGenerales';

const ModalAddIndicadoresGenerales = ({ openAdd, setOpenAdd, agregar, errorTable, successTable, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );
    const [ numeroVista, setNumeroVista ]= useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }  

    const enviarIndicadorGeneral = async (indicadorGeneral, configBaseData, indicadorParametros) => {
        setIsLoading(true);
        const info = indicadorGeneral;
        const respuesta = await Axios.post( `${servicios_grupohit_catalogo}/indicador`, info, headersAuth());
        const nuevoIndicador = JsonIndicadoresGenerales(respuesta.data.dataResponse);

        if(respuesta?.data?.status === 200) {
            let errores = [];
            if(configBaseData!==null) {
                const newData = configBaseData.basesIndicadores[0].map(element => { return { indicadorId: nuevoIndicador.id, baseId: element.baseId, operacion: element.operacion } });
                await Axios.post( `${servicios_grupohit_nomina}/bases/indicadores/`, { basesIndicadores: newData }, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            break;
                        default:
                            errores.push(`Bases: ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => errores.push(`Bases: ${verifique_conexion}`));
            }
            if(indicadorParametros !== null) {
                const newData = {indicadorId: nuevoIndicador.id, zonaId: indicadorParametros.zonaId, indicador: indicadorParametros.indicador, topVeces: indicadorParametros.topVeces };
                await Axios.post( `${servicios_grupohit_catalogo}/indicadores/parametros/`, newData, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            break;
                        default:
                            errores.push(`Exento Requerido: ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => errores.push(`Exento Requerido: ${verifique_conexion}`));
            }
            await agregar(nuevoIndicador);
            if(errores.length) {
                await errorTable(['Concepto guardado con errores: '].concat(errores));
            } else {
                successTable(registro_guardado);
            }
        } else {
            if(respuesta?.data?.msg) {
                errorTable(respuesta.data.msg);
            } else {
                errorTable(verifique_conexion);
            }
        }
        setIsLoading(false);
    }

    const vista = (numero) =>{
        setNumeroVista(numero);
    }
    
    return (

        <ModalService
            title={numeroVista===1 ? "Nuevo Concepto General" : "Configuración de Concepto"}
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormIndicadoresGenerales
                        enviarIndicadorGeneral={enviarIndicadorGeneral}
                        error={error}
                        vista={vista}
                        idModulo={nombreModulo}
                        setError={setError}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddIndicadoresGenerales;