import React, { useState } from 'react';
import ModalService from '../../../ModalService/ModalService';
import FormSecuencia from './FormSecuencia';

const ModalAddPlantillas = ({ nombreModulo, open, setOpen, enviaEmpleados }) => {

    const [secuencia, setSecuencia] = useState(null);
    
    const cerrarModal = () => {
        setOpen(false);
    };

    const enviar = () => {
        enviaEmpleados(secuencia);
        cerrarModal();
    };

    return (
        <ModalService
            title="Agregar Secuencia"
            parentNode={nombreModulo}
            isOpen={open}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormSecuencia setSecuencia={setSecuencia} enviar={enviar}/>
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalAddPlantillas;