import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_nomina } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';

const FormularioPagosUnicos = ({ error, setError, enviarPagoUnico, elementSelect, actualizarPagoUnico, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    //const [ aniosNomina, setAniosNomina ] = useState( elementSelect && informacion.informacion ? informacion.informacion.aniosNomina : [] );
    const aniosNomina = elementSelect && informacion.informacion ? informacion.informacion.aniosNomina : [];

    const anioRef1 = createRef('');
    const periodoRef1 = createRef('');
    const anioRef2 = createRef('');
    const periodoRef2 = createRef('');
    //const [ periodosAnio1, setPeriodosAnio1 ] =  useState( elementSelect && informacion.informacion ? informacion.informacion.periodosAnio : [] );
    const [ periodosAnio1, setPeriodosAnio1 ] =  useState( [] );
    const [ periodosAnio2, setPeriodosAnio2 ] =  useState( [] );
    const [ errorAniosPeriodo, setErrorAniosPEriodo ] = useState( null );

    useEffect(() => {
        consultaPeriodosNomina1( elementSelect ? elementSelect.periodoAnioInfo : '' );
        consultaPeriodosNomina2( elementSelect ? elementSelect.periodoAnioInfo : '' );
        // eslint-disable-next-line
    }, []);

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarPagoUnico' ) ){
            const anio = anioRef1.current.value;
            const periodo = parseInt(periodoRef1.current.value, 10);
            console.log({ anio, periodo });
            if( validarAniosPeriodo() ){
                //enviarPagoUnico({ anio, periodo });
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarPagoUnico' ) ){
            //const anio = anioRef1.current.value;
            const acumId = elementSelect ? elementSelect.acumId : '';
            const periodoId = parseInt(periodoRef1.current.value, 10);
            console.log({ acumId, periodoId });
            if( validarAniosPeriodo() ){
                actualizarPagoUnico({ acumId, periodoId });
            }
        }
    };

    /*
    const consultaPeriodosNomina = ( anioID ) => {
        setError(null);
        setPeriodosAnio1([]);
        if( anioID !== "" ){
            axios.post( `${servicios_grupohit}/generar/reportes/nomina/periodo/`, { anio: parseInt(anioID, 10) }, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const data = respuesta.data.dataResponse.map(item => (
                            {
                                id: item[0],
                                periodoPerAnio: item[0],
                            }
                        ));
                        setPeriodosAnio1(data)
                        break;
                    case 404:
                        setPeriodosAnio1( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }
    */
    const consultaPeriodosNomina1 = ( anioID ) => {
        //setError(null);
        setPeriodosAnio1([]);
        if( anioID !== "" ){
            axios.get( `${servicios_grupohit_nomina}/acumulados/peranio/${elementSelect.nominaId}/${anioID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const data = respuesta.data.dataResponse.map(item => (
                            {
                                id: item.periodoId,
                                periodoPerAnio: item.periodoPerAnio,
                            }
                        ));
                        setPeriodosAnio1(data)
                        break;
                    case 404:
                        setPeriodosAnio1( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }

    const consultaPeriodosNomina2 = ( anioID ) => {
        //setError(null);
        setPeriodosAnio2([]);
        if( anioID !== "" ){
            axios.get( `${servicios_grupohit_nomina}/acumulados/peranio/${elementSelect.nominaId}/${anioID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const data = respuesta.data.dataResponse.map(item => (
                            {
                                id: item.periodoId,
                                periodoPerAnio: item.periodoPerAnio,
                            }
                        ));
                        setPeriodosAnio2(data)
                        break;
                    case 404:
                        setPeriodosAnio2( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }

    const validarAniosPeriodo = () => {
        //setErrorIndicador( null );
        const anio1 = anioRef1.current.value;
        const anio2 = anioRef2.current.value;
        const periodo1 = periodoRef1.current.value;
        const periodo2 = periodoRef2.current.value;
        console.log( anio1, periodo1 );
        console.log( anio2, periodo2 );
        if( anio1 !== "" && anio2 !== "" ){
            if( anio2 >= anio1 ){
                if( anio2 === anio1 ){ //Si ambos años son iguales, se validan los periodos
                    if( periodo2 !== "" && periodo1 !== "" ){
                        if( periodo2 >= periodo1 ){
                            //setErrorIndicador( null );
                            return true;
                        }else{
                            setErrorAniosPEriodo( <AlertForm message="El periodo final no puede ser menor que el periodo inicial" /> );
                            return false;
                        }
                    }else{
                        setErrorAniosPEriodo( <AlertForm message="Selecciona los periodos para continuar" /> );
                        return false;
                    }
                }
            }else{
                setErrorAniosPEriodo( <AlertForm message="El año final no puede ser menor que el año inicial" /> );
                return false;
            }
        }else{
            if((anio1 !== "" && anio2 === "") || (anio1 === "" && anio2 !== "") ){
                setErrorAniosPEriodo( <AlertForm message="Selecciona los años para continuar" /> );
                return false;
            }
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarPagoUnico' : 'AgregarPagoUnico' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Año Informativo Inicial" arrayOpciones={aniosNomina} keyOpcion="periodoAnio"
                            refValue={anioRef1} defaultValue={ elementSelect ? elementSelect.periodoAnioInfo : '' } required
                            onChangeExecute={consultaPeriodosNomina1} disabled={aniosNomina.length === 0}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Informativo Inicial" arrayOpciones={periodosAnio1} keyOpcion="periodoPerAnio"
                            refValue={periodoRef1} defaultValue={ elementSelect ? elementSelect.periodoId : '' } required
                            disabled={periodosAnio1.length === 0}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Año Informativo Final" arrayOpciones={aniosNomina} keyOpcion="periodoAnio"
                            refValue={anioRef2} defaultValue={ elementSelect ? elementSelect.periodoAnioInfo : '' } optional
                            onChangeExecute={consultaPeriodosNomina2} disabled={aniosNomina.length === 0}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Informativo Final" arrayOpciones={periodosAnio2} keyOpcion="periodoPerAnio"
                            refValue={periodoRef2} defaultValue={ elementSelect ? elementSelect.periodoId : '' } optional
                            disabled={periodosAnio2.length === 0}/>
                    </div>
                </div>

                {error}
                {errorAniosPeriodo}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioPagosUnicos;