import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import ModalService from '../../../../../ModalService/ModalService';
import FormBusquedaPlaza from './FormBusquedaPlaza';
import InfoPlaza from './InfoPlaza';
import AlertError from '../../../../../../../../Services/Alerts/AlertSyE';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import { servicios_grupohit_org, verifique_conexion } from '../../../../../../../../Constants/Constants';

const ModalBusquedaPlaza = ({ parentNode, permisos, modalIsOpen, setModalIsOpen }) => {

    const [infoPlaza, setInfoPlaza] = useState(null);
    const [muestraSpinner, setMuestraSpinner] = useState(false);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [textError, setTextError] = useState(null);

    const cerrarModal = () => {
        setInfoPlaza(null);
        setMuestraSpinner(false);
        setOpenAlertError(false);
        setTextError(null);
        setModalIsOpen(false);
    };

    const buscarPlaza = nup => {
        setMuestraSpinner(true);
        axios.get(`${servicios_grupohit_org}/plaza/clave/${nup}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const informacionPlaza = res.data.dataResponse;
                    if(res.data.dataResponse.empleadoTitular) {
                        informacionPlaza.empleadoTitular.nueNombre = `${res.data.dataResponse.plazaclave} / ${(res.data.dataResponse.empleadoTitular.empleadopat) ? `${res.data.dataResponse.empleadoTitular.empleadopat} ` : ''}${(res.data.dataResponse.empleadoTitular.empleadomat) ? `${res.data.dataResponse.empleadoTitular.empleadomat} ` : ''}${res.data.dataResponse.empleadoTitular.empleadonom}`;
                    }
                    setInfoPlaza(res.data.dataResponse);
                    break;
                default:
                    setTextError(res.data.msg);
                    setOpenAlertError(true);
                    setInfoPlaza(null);
                    break;
            }
            setMuestraSpinner(false);
        })
        .catch(error => {
            setTextError(verifique_conexion);
            setOpenAlertError(true);
            setMuestraSpinner(false);
            setInfoPlaza(null);
        });
    };

    return (
        <ModalService
            title="Búsqueda de Plaza"
            parentNode={parentNode}
            isOpen={modalIsOpen}
            onClose={cerrarModal}
            minWidth="50%"
            maxWidth="50%"
        >
            <FormBusquedaPlaza buscarPlaza={buscarPlaza}/>
            {(muestraSpinner)
            ?   <Fragment>
                    <br/>
                    <SpinnerOval/>
                </Fragment>
            :   (infoPlaza)
                ?   <InfoPlaza parentNode={parentNode} permisos={permisos} info={infoPlaza} buscarPlaza={buscarPlaza}/>
                :   null
            }
            <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Petición Fallida" text={textError} textbtnconfirm="Aceptar" type="error" />
        </ModalService>
    );
}
 
export default ModalBusquedaPlaza;