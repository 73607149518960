import React from 'react';
import AutocompleteComponent from '../../../../../../../../Services/Autocomplete/AutocompleteComponent';
import { Tooltip } from '@material-ui/core';
import { Edit, RemoveCircleOutlineOutlined } from '@material-ui/icons';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const DatosPlazaEscalafon = ({ index, plaza, puestos, cPresupuestales, muestraInput, setMuestraInput, asignaPuesto, asignaCPresupuestal, info, errores, setErrores }) => {

    const { muestraPuesto, muestraProyecto } = muestraInput;
    const { errorPuesto, errorProyecto } = errores[(index >= 0) ? index+1 : 0];
    
    const mostrarInputText = type => {
        switch(type) {
            case 'Puesto':
                asignaPuesto(null, false);
                if(muestraPuesto) {
                    let newErrores = errores;
                    newErrores[(info) ? index+1 : 0].errorPuesto = false;
                    setErrores(newErrores);
                } else {
                    let newErrores = errores;
                    newErrores[(info) ? index+1 : 0].errorPuesto = true;
                    setErrores(newErrores);
                }
                setMuestraInput({ ...muestraInput, muestraPuesto: !muestraPuesto });
                break;
            case 'Proyecto':
                asignaCPresupuestal(null, false);
                if(muestraProyecto) {
                    let newErrores = errores;
                    newErrores[(info) ? index+1 : 0].errorProyecto = false;
                    setErrores(newErrores);
                } else {
                    let newErrores = errores;
                    newErrores[(info) ? index+1 : 0].errorProyecto = true;
                    setErrores(newErrores);
                }
                setMuestraInput({ ...muestraInput, muestraProyecto: !muestraProyecto });
                break;
            default:
                return;
        }
    };

    return (
        <div className="row panel-info panel-short">
            <div className="col-12">
                <div className="textPerfil"><strong>Número Único de Plaza: </strong>{ plaza.plazaClave }</div>
                <div className="textPerfil"><strong>Nivel: </strong>{ plaza.nivLaboralClave }</div>
                <div className="row">
                    <div className="col-10">
                        <div id="formPuesto" className="textPerfil">
                        <strong>Puesto: </strong>
                        {(muestraPuesto)
                        ?   (puestos.length > 0)
                            ?   <AutocompleteComponent id="PuestosEscalafon" title=""
                                    options={puestos} optionListView="puestoCveNom"
                                    action={asignaPuesto}
                                    placeholder="Seleccione Puesto" error={errorPuesto}
                                />
                            :   <SpinnerOval/>
                        :   `${plaza.puestoCve} / ${plaza.puestoNom}`
                        }
                        </div>
                    </div>
                    <div className="col-2">
                        <Tooltip title={(muestraPuesto) ? 'Cancelar Editar Puesto' : 'Editar Puesto'}>
                        {(muestraPuesto)
                        ?   <button className="btn-removeItem-outlined" onClick={() => mostrarInputText('Puesto')}>
                                <RemoveCircleOutlineOutlined fontSize="small"/>
                            </button>
                        :   <button className="btn-editItem-outlined" onClick={() => mostrarInputText('Puesto')}>
                                <Edit fontSize="small"/>
                            </button>
                        }
                        </Tooltip>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10">
                        <div id="formProyecto" className="textPerfil">
                        <strong>Proyecto: </strong>
                        {(muestraProyecto)
                        ?   (cPresupuestales.length > 0)
                            ?   <AutocompleteComponent id="ProyectosEscalafon" title=""
                                    options={cPresupuestales} optionListView="cPresupuestalNom"
                                    action={asignaCPresupuestal}
                                    placeholder="Seleccione Proyecto" error={errorProyecto}
                                />
                            :   <SpinnerOval/>
                        :   `${plaza.cPresupuestalNom}`
                        }
                        </div>
                    </div>
                    <div className="col-2">
                        <Tooltip title={(muestraProyecto) ? 'Cancelar Editar Proyecto' : 'Editar Proyecto'}>
                        {(muestraProyecto) 
                        ?   <button className="btn-removeItem-outlined" onClick={() => mostrarInputText('Proyecto')}>
                                <RemoveCircleOutlineOutlined fontSize="small"/>
                            </button>
                        :   <button className="btn-editItem-outlined" onClick={() => mostrarInputText('Proyecto')}>
                                <Edit fontSize="small"/>
                            </button>
                        }
                        </Tooltip>
                    </div>
                </div>
                <div className="textPerfil">
                    <strong>Centro de Trabajo: </strong>
                    { `${plaza.domciaCalle}${(plaza.domciaExt) ? `, ${plaza.domciaExt}` : ', S/N'}${(plaza.domciaNumint) ? `, ${plaza.domciaNumint}` : ''}${(plaza.domciaCP) ? `, ${plaza.domciaCP}` : ''}${(plaza.domciaColonia) ? `, ${plaza.domciaColonia}` : ''}` }
                </div>
            </div>
        </div>
    );
}
 
export default DatosPlazaEscalafon;