import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'; 
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper'; 
import DialogContent from '@material-ui/core/DialogContent'; 
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';  
import Tooltip from '@material-ui/core/Tooltip'; 
import ListNotifications from './ListNotifications';
import ConfigNotifications from './ConfigNotifications';
import DetailsNotification from './DetailsNotification';
import axios from 'axios';
import { informacionModulos } from '../../../Helpers/HelperNavegacion'; 
import { servicios_grupohit_url, servicios_grupohit } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ArrowBack } from '@material-ui/icons'; 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
  paper: {
    width:400,
    maxWidth: 400, 
    overflow: 'auto',
    maxHeight: 500,
    minHeight: 500
  }, 
  popper: {
    zIndex: 10000, 
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

export default function UserNotification({open, setOpen, anchorEl, notifications, setNotificaciones , setLenthNotifications}) {

  const [ openConfiguration, setOpenConfiguration ] = useState(false);
  const [ notificacionDetalles, setNotificacionDetalles ] = useState(null);
  const classes = useStyles();
  const [ modulosApi, setModulosApi ]= useState(null);
  const [ openAutocomplete, setOpenAutoComplete ]= useState(false);

  useEffect(() => {
      axios.get( `${servicios_grupohit_url}/menu`, headersAuth() )
          .then( res => {
              switch( res.data.status ){
                  case 200:
                      setModulosApi(informacionModulos( res.data.dataResponse.menu));
                      break;
                  default:
                      break;
              }
          } )
          .catch( error => { console.log( error ) });
  }, []);

  useEffect(() => {
    if(open === false) {
      setNotificacionDetalles(null);
    }
  }, [open])
   
  const handleClickAway = () => {
    if(!openAutocomplete) setOpen(false);
  };

  const openConfigurationView = () => {
    if(notificacionDetalles === null) {
      if(openConfiguration) loadMoreNotifications();
      setOpenConfiguration(!openConfiguration);
    } else {
      setNotificacionDetalles(null);
    }
  }

  const loadMoreNotifications = () => { 
    axios.get(`${servicios_grupohit}/user/notificacion/mongo/1`, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                const data = res.data.dataResponse
                if(data.length){ 
                    setNotificaciones(data);
                }
                break;
            default:
                break;
        }
    })
    .catch(error => console.log(error));
}

  return (
    <div className={classes.root}>    
          <div>
            <Popper 
              open={open}
              anchorEl={anchorEl}
              placement={'bottom'}
              disablePortal={false}
              className={classes.popper} 
            >
              <Paper className={classes.paper}>  
                <ClickAwayListener onClickAway={handleClickAway}> 
                    <div>
                      <div className="row headerNotifications" style={{marginRight:0}}>
                        <div className="col-md-9 col-sm-12 TitleNotification">
                            { openConfiguration ?  "Configuración de Notificaciones" : (notificacionDetalles === null) ? "Notificaciones" : 'Detalles de Notificación' } 
                        </div>
                        <div className="col-md-2 col-sm-12" style={{textAlign:'end', marginBottom:0, marginLeft:10}}>
                          <Tooltip title={(openConfiguration || notificacionDetalles !== null) ? "Regresar" :"Configurar Notificaciones"} style={{zIndex:200001}}>
                              <IconButton onClick={()=>{openConfigurationView()}}>
                                    {
                                      (openConfiguration || notificacionDetalles !== null) ? 
                                      <ArrowBack fontSize={'small'} />
                                      : 
                                      <SettingsIcon fontSize={'small'} />
                                    }
                              </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <DialogContent style={{padding: (openConfiguration || notificacionDetalles !== null) ? '12% 0px 0px 0px': '9% 0px 0px 0px'}}>
                        {
                          openConfiguration
                          ?  <ConfigNotifications modulosApi={modulosApi} openAutocomplete={openAutocomplete} setOpenAutoComplete={setOpenAutoComplete}/>
                          : (notificacionDetalles === null)
                            ? <ListNotifications notifications={notifications} modulosApi={modulosApi} setLenthNotifications={setLenthNotifications} setNotificacionDetalles={setNotificacionDetalles}/>
                            : <DetailsNotification info={notificacionDetalles}/>
                        }
                      </DialogContent> 
                      </div> 
                </ClickAwayListener>
              </Paper> 
            </Popper>  
          </div> 
    </div>
  );
}