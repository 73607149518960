import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import DataTableService from '../../../../../DataTableService/DataTableService';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { arrayJsonMovimientosPorPeriodoEscalafones } from '../../../../../Helpers/JsonToOneLevel';
import { escalafones, servicios_grupohit_rh, verifique_conexion } from '../../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const Escalafon = ({ id, requestTabla, setBloqueaCards, setError, moverATabla, informacionAdicional }) => {

    const [infoTabla, setInfoTabla] = useState(null);

    useEffect(() => {
        const servicioEscalafones = () => {
            setBloqueaCards(true);
            axios.post(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/getall/fechamovimiento/`, requestTabla, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        const arrayEscalafones = arrayJsonMovimientosPorPeriodoEscalafones(res.data.dataResponse);
                        setInfoTabla(arrayEscalafones);
                        break;
                    default:
                        setInfoTabla([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setInfoTabla([]); setError(<AlertForm message={verifique_conexion} />) })
            .finally(() => moverATabla() );
        };

        servicioEscalafones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
        {(infoTabla)
        ?   <DataTableService
                id={`${id}EscalafonListado`}
                title={escalafones}
                columns={[
                    { field: "folio", title: "Folio" },
                    { field: "tipo", title: "Tipo de Movimiento" },
                    { field: "fecini", title: "Fecha Inicial" },
                    { field: "fecfin", title: "Fecha Final" },
                    { field: "fecReg", title: "Fecha de Registro" },
                    { field: "nombre", title: "Usuario" },
                ]}
                data={infoTabla}
                customOptionsTable={{ filtering: true }} 
                hideIconAdd hideIconEdit hideIconDelete
                showIconPageview
                informacionAdicional={informacionAdicional}
            />
        :   <SpinnerOval/>
        }
        </Fragment>
    );
};

export default Escalafon;