import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTiposDomicilio from './FormTiposDomicilio';

const ModalEditTiposDomicilio = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [ isLoading, setIsLoading ] = useState( false );

    const cerrarModal = () => {
        setOpenEdit(false);
    }

    const actualizarTipoDomicilio = (tipoDomicilio, tipoDomicilioId) => {
        setIsLoading(true);
        const info = { nombre: tipoDomicilio.tipodomNom };
        Axios.put(`${servicios_grupohit_catalogo}s/tipos/domicilio/${tipoDomicilioId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar({ id: tipoDomicilioId, tipodomNom: tipoDomicilio.tipodomNom });
                    setOpenEdit(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Tipo de Domicilio" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTiposDomicilio
                        elementSelect={elementSelect}
                        actualizarTipoDomicilio={actualizarTipoDomicilio}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTiposDomicilio;