import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonPresupuestalEspeciales, ArrayJsonPlazaEspeciales, ArrayJsonPuestoEspeciales, 
         ArrayJsonNominaEspeciales, ArrayJsonZonaPagoEspeciales, ArrayJsonStatusEspeciales, ArrayJsonContratosEspeciales, ArrayJsonNombramientosEspeciales
        } from '../../../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const TablaInfoEspecialesEmpleado = ({ elementSelect, id, hideIconDelete, title }) => {

    const [presupuestal, setPresupuestal] = useState(null);
    const [plaza, setPlaza] = useState(null);
    const [puestos, setPuestos] = useState(null);
    const [nominas, setNominas] = useState(null);
    const [zonaPago, setZonaPago] = useState(null);
    const [status, setStatus] = useState(null);
    const [contratos, setContratos] = useState(null);
    const [nombramientos, setNombramientos] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    /* const [ turnos, setTurnos ] = useState(null); */

    const [ data, setData ] = useState( null );
    const diccionario = 
        {
            cpresupuestalclave: "Clave Presupuestal",
            cpresupuestalnom: "Proyecto",
            plazaclave: "NUP",
            puestoCve: "Clave Puesto",
            puestoNom: "Puesto",
            nominaClave: "Clave Nómina",
            nominaNombre: "Nombre Nómina",
            tipoDom: "Tipo de Domicilio",
            domCiaCalle: "Calle",
            domCiaNumInt: "Núm. Int.",
            domCiaNumExt: "Núm. Ext.",
            domCiaTel: "Teléfono",
            compania: "Compañía",
            codPostal: "CP.",
            monto: "Monto",
            domCiaExt:"Extensión Telefónica",
            jornadaNombre: "Nombre Jornada",
            jornadaClave: "Clave Jornada",
            turnoNombre: "Nombre Turno",
            claveBaja: "Clave Baja",
            nombreBaja: "Motivo Baja",
            fechaReanudacion: "Fecha Reanudación",
            contratoSaoNom: "Contrato",
            descripcion: "Descripción",
        };
    const notAllowed = ["attemptsLogin","id","password","icono","indicadorId","nominaid"];

    useEffect(() => {
        if(presupuestal && plaza && puestos && nominas && zonaPago && status && contratos && nombramientos) {
            setData(
                especiales(presupuestal, plaza, puestos, nominas, zonaPago, status, contratos, nombramientos/* , turnos */)
                .sort((a, b) =>
                    a.fechaIniLong < b.fechaIniLong ? 1 : a.fechaIniLong > b.fechaIniLong ? -1 : 0
                )
            );
        }
    },[presupuestal, plaza, puestos, nominas, zonaPago, status, contratos, nombramientos/* , turnos */]);

    useEffect(() => {
        axios.get(`${servicios_grupohit_rh}/empleado/presupuestal/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPresupuestal( ArrayJsonPresupuestalEspeciales( res.data.dataResponse ) );
                    break;
                case 404:
                    setPresupuestal([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        axios.get(`${servicios_grupohit_rh}/empleado/plaza/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPlaza( ArrayJsonPlazaEspeciales( res.data.dataResponse ) );
                    break;
                case 404:
                    setPlaza([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        axios.get(`${servicios_grupohit_rh}/empleado/puesto/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPuestos( ArrayJsonPuestoEspeciales( res.data.dataResponse ) );
                    break;
                case 404:
                    setPuestos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        axios.get(`${servicios_grupohit_rh}/empleado/nomina/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNominas( ArrayJsonNominaEspeciales( res.data.dataResponse ) );
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });        

        axios.get(`${servicios_grupohit_rh}/empleado/zonapago/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setZonaPago( ArrayJsonZonaPagoEspeciales( res.data.dataResponse ) );
                    break;
                case 404:
                    setZonaPago([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        axios.get(`${servicios_grupohit_rh}/empleado/status/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setStatus( ArrayJsonStatusEspeciales( res.data.dataResponse ) );
                    break;
                case 404:
                    setStatus([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        axios.get(`${servicios_grupohit_rh}/empleado/contrato/getall/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setContratos(ArrayJsonContratosEspeciales(res.data.dataResponse));
                    break;
                case 404:
                    setContratos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        axios.get(`${servicios_grupohit_rh}/empleado/nombramiento/getall/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNombramientos(ArrayJsonNombramientosEspeciales(res.data.dataResponse));
                    break;
                case 404:
                    setNombramientos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });

        // eslint-disable-next-line
    }, [elementSelect]);

    const especiales = (...arrays) => [].concat(...arrays.filter(Array.isArray)); 

    const validaBajaContratoNombramiento = dataTable => {
        if(dataTable.length > 0) {
            let group = [];
            let newData = dataTable;
            newData.forEach(element => {
                let fechaIniFind = group.find(i => i.fechaIni === element.fechaIni);
                const elmt = element;
                if(elmt.informacion && (typeof elmt.informacion === 'object') && elmt.informacion.baja) {
                    elmt.tipo = 'BAJA'
                }
                if(fechaIniFind) { 
                    if(fechaIniFind.informacion && (typeof fechaIniFind.informacion === 'object') && fechaIniFind.informacion.baja) {
                        fechaIniFind.tipo = 'BAJA'
                    }
                    fechaIniFind.tipos = [...fechaIniFind.tipos, { tipo: elmt.tipo }];
                    const fechaIniFilter = group.filter(i => i.fechaIni !== elmt.fechaIni);
                    group = [...fechaIniFilter, fechaIniFind];
                } else {
                    group.push({
                        fechaIni: elmt.fechaIni,
                        tipos: [{ tipo: elmt.tipo }]
                    })
                }
            });
            
            const buscarTipos = array => {
                return array.filter(e => e.tipo.toUpperCase() === 'BAJA' || e.tipo.toUpperCase() === 'CONTRATO' || e.tipo.toUpperCase() === 'NOMBRAMIENTO');
            };

            group.forEach(element => {
                const tipos = buscarTipos(element.tipos);
                    
                if(tipos.length === 1) {
                    newData.forEach(e => {
                        if(element.fechaIni === e.fechaIni) e.fechaIni = `${e.fechaIni} Movimiento: ${tipos[0].tipo.toUpperCase()}`;
                    });
                }

                if(tipos.length === 2) {
                    newData.forEach(e => {
                        if(element.fechaIni === e.fechaIni) e.fechaIni = `${e.fechaIni} Movimiento: ${tipos[0].tipo.toUpperCase()}, ${tipos[1].tipo.toUpperCase()}`
                    });
                }
                
                if(tipos.length === 3) {
                    newData.forEach(e => {
                        if(element.fechaIni === e.fechaIni) e.fechaIni = `${e.fechaIni} Movimiento: ${tipos[0].tipo.toUpperCase()}, ${tipos[1].tipo.toUpperCase()}, ${tipos[2].tipo.toUpperCase()}`
                    });
                }
            });

            return newData;
        } else {
            return dataTable;
        }
    };

    return (
        <div id={`modulo${id===1001 ? id+"advanced" : id}`}>
            {   
                (presupuestal && data)
                ?   <DataTableService
                        id={id+"secondary"}
                        title={title}
                        columns={[
                            { field: "fechaIni", title: "Fecha Inicial", defaultGroupOrder: 0, customSort: (a, b)=> a.fechaIni < b.fechaIni ? 1 : a.fechaIni > b.fechaIni ? -1 : 0 },
                            { field: "fechaFin", title: "Fecha Final", defaultGroupOrder: 1, defaultGroupSort: 'desc' },
                            { field: "tipo", title: "" },
                            { field: "informacion", title: "Movimiento" }, 
                        ]}
                        hideIconAdd hideIconEdit
                        hideIconDelete={hideIconDelete ? true : null }
                        data={validaBajaContratoNombramiento(data)}
                        informacionAdicional={{ data, allowed: diccionario, notAllowed, idEmpleado:elementSelect.id}}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default TablaInfoEspecialesEmpleado;