import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, niveles_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';


const Niveles = ({ id, permisos }) => {

    const [niveles, setNiveles] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(()=>{
        Axios.get(`${servicios_grupohit_catalogo}/nvlaboral/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    const arrayNiveles = res.data.dataResponse.map(item => ( { id: item.id, nivlaboralClave: item.nivlaboralClave, statusNom: item.status.statusNom } ))
                    setNiveles(arrayNiveles);
                    break;
                case 404:
                    setNiveles([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]); 

    return (
        
        <div id={`modulo${id}`}>
            {
                 niveles   
                ? <DataTableService id={id}
                    title={ niveles_ }
                    permisos={permisos}
                    columns={[
                        { field: "nivlaboralClave", title: "Clave Nivel Laboral" },
                        { field: "statusNom", title: "Estado" },
                    ]}
                    data={niveles}/>
                : <SpinnerOval/>
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>

    );
}
 
export default Niveles;