import React, { Fragment } from 'react';

const InfoEmpleadoPagosPendientes = ({ elementSelect }) => {
    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <div><strong>NUE: </strong>{ elementSelect.empleadoClave }</div>
                </div>
                <div className="col">
                    <div><strong>RFC: </strong>{ elementSelect.empleadoRfc }</div>
                </div>
                <div className="col"></div>
            </div>
            <div className="row">
                <div className="col">
                    <div><strong>Nombre: </strong>{ (elementSelect.empleadoPat) ? `${elementSelect.empleadoPat} ` : '' }{ (elementSelect.empleadoMat) ? `${elementSelect.empleadoMat} ` : '' }{ elementSelect.empleadoNom }</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div><strong>Status: </strong>{ elementSelect.status }</div>
                </div>
                <div className="col">
                    <div><strong>Clave Nómina: </strong>{ elementSelect.nominaClave }</div>
                </div>
                <div className="col">
                    <div><strong>Nómina: </strong>{ elementSelect.nominaNombre }</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div><strong>Clave Presupuestal: </strong>{ elementSelect.cpresupuestalClave }</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div><strong>Proyecto: </strong>{ elementSelect.cpresupuestalNom }</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div><strong>NUP: </strong>{ elementSelect.plazaClave }</div>
                </div>
                <div className="col">
                    <div><strong>Clave Puesto: </strong>{ elementSelect.puestoCve }</div>
                </div>
                <div className="col">
                    <div><strong>Puesto: </strong>{ elementSelect.puestoNom }</div>
                </div>
            </div>
            <hr/>
        </Fragment>
    );
}
 
export default InfoEmpleadoPagosPendientes;
