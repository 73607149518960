import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from './../../../ModalService/ModalService';
import FormularioTablasSubsidios from './FormularioTablasSubsidios';
import { JsonTablasImpuestos } from '../../../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const ModalAddTablaSubsidios = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );
    const [ alertSuccess, setAlertSuccess ] = useState( false );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    } 
    const enviarTablaSubsidios = tablaImpuestos => { 
          
            Axios.post(`${servicios_grupohit_catalogo}s/subsidio/rango/`, tablaImpuestos , headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        let result =  res.data.dataResponse;
                        result.status=true;
                        agregar(true, JsonTablasImpuestos(result));
                        setOpenAdd( false ); 
                        break;
                    default:
                        errorTable(res.data.msg); 
                        break;
                }
            }).catch(error =>  { errorTable(registro_no_guardado)});
    }
    const actualizarTablaSubsidios = fechaFin => { 
        const fechaIni =informacionAdicional.fechaIniLong; 
        const request= { periodoImpto: informacionAdicional.periodoImptoId, fechaIni, fechaFin }; 
        Axios.put( `${servicios_grupohit_catalogo}s/subsidio/rango/${informacionAdicional.id}`, request, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        let result =  respuesta.data.dataResponse;
                        result.status=true;
                        setAlertSuccess(true); 
                        setOpenAdd( false ); 
                        agregar(null, JsonTablasImpuestos(result)); 
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            });
    }

    return (
        <ModalService title={informacionAdicional ? "Finalizar Periodo" : "Nueva Tabla de Subsidios"} parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } minWidth="40%">
            <div className="row">
                <div className="col-12">
                    <FormularioTablasSubsidios
                        enviarTablaSubsidios={enviarTablaSubsidios}
                        error={error} 
                        elementSelect={informacionAdicional}
                        actualizarTablaSubsidios={actualizarTablaSubsidios}
                    />
                </div>
            </div>
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={'Registro Actualizado'} textbtnconfirm="Aceptar" type="success"/>
        </ModalService>
    );
}

export default ModalAddTablaSubsidios;