import React, { Fragment, useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteTipoAcumulado = ({ idModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {

    const [openLoading, setOpenLoading] = useState(false);
    
    const axiosEliminar = () => {
        setOpenLoading(true);
        Axios.delete(`${servicios_grupohit_catalogo}s/tipoacumulado/${elementSelect.id}`, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect.id);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_eliminado))
        .finally(() => setOpenLoading(false));
    }

    return(
        <Fragment>
            <ModalLoading nombreModulo={idModulo} open={openLoading} setOpen={setOpenLoading} />
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
        </Fragment>
    );
}

export default ModalDeleteTipoAcumulado;