import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip } from '@material-ui/core';

const BtnEditItem = ({ id, idArray, array, action, remove, style }) => {

    const muestrainfo = e => {
        e.preventDefault();
        if(array) {
            const elementSelected = array.find(element => element[idArray] === id);
            action(elementSelected);
        } else {
            action(id);
        }
    };

    return (
        <Tooltip title="Editar">
            <button
                className={remove ? 'btn-editItem-outlined btn-removeItem-service': 'btn-editItem-outlined'}
                style={(style) ? style : {}}
                onClick={e => muestrainfo(e)}
            >
                <EditIcon fontSize="small"/>
            </button>
        </Tooltip>
    );
}
 
export default BtnEditItem;