import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, tipo_documentos } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonTipoDocumentos } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const TipoDocumentos = ({ id, permisos }) => {
    const [ tiposDocumentos, setTiposDocumentos ] = useState( null );
    const [ extensiones, setExtensiones ] = useState( null );
    useEffect(() => {
        axiosTiposDocumentos();
        axiosExtensiones();
    }, []);

    const axiosTiposDocumentos = () => {
        axios.get( `${servicios_grupohit_rh}/documentos/catalogo/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposDocumentos( ArrayJsonTipoDocumentos( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        //setTiposDocumentos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio TipoExtensiones */
    const axiosExtensiones = () => {
        axios.get( `${servicios_grupohit_rh}/documentos/extension/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setExtensiones( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setExtensiones([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { tiposDocumentos && extensiones ?
                <DataTableService id={id} title={ tipo_documentos } permisos={permisos}
                    columns={[
                        { field: "tipodocNom", title: "Nombre" },
                        { field: "tipoextNom", title: "Extensión" },
                        { field: "tipodocCant", title: "Cantidad" },
                        { field: "tipodocDesc", title: "Descripción" },
                    ]}
                    data={ tiposDocumentos }
                    informacionAdicional={{ extensiones }} /> : <SpinnerOval />
            }
        </div>
    );
};

export default TipoDocumentos;