import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { pagos_pendientes, servicios_grupohit, verifique_conexion } from '../../../../../../Constants/Constants';
import TableSelectRows from '../../../../../../Services/Tables/TableSelectRows';
import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import ConvertDate, { ConvertDateTime } from '../../../Helpers/ConvertDate';
import { Today } from '@material-ui/icons';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const InfoPagosPendientes = ({ id, empID, empNombre, successTable, errorTable, cerrarModal }) => {

    const [pagosPendientes, setPagosPendientes] = useState(null);
    const [idQuitaAut, setIdQuitaAut] = useState(null);
    const [error, setError] = useState(null);
    const [openAlertW, setOpenAlertW] = useState(false);
    const [openAlertWQuitaAut, setOpenAlertWQuitaAut] = useState(false);
    const [periodosId, setPeriodosId] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        axios.get(`${servicios_grupohit}/empleado/pago/pendiente/${empID}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayPagosPendienteWithout9and23 = res.data.dataResponse.filter(item => (item.periodo_peranio !== 9) && (item.periodo_peranio !== 23));
                    const newArrayPagosPendientes = arrayPagosPendienteWithout9and23.map(pago => {
                        return {
                            id: pago.id,
                            nominaId: pago.nominaId,
                            nominaFecini: ConvertDate(pago.nominaFecini),
                            periodoId: pago.periodoId,
                            periodo_anio: pago.periodo_anio,
                            periodo_peranio: pago.periodo_peranio,
                            periodoFecini: ConvertDate(pago.periodoFecini),
                            periodoFecfin: ConvertDate(pago.periodoFecfin),
                            pagoPendiente: pago.pagoPendiente,
                            pagoUnicoPeriodoId: (!pago.pagoPendiente) ? pago.periodoPagoUnico.periodoId : '-',
                            pagoUnicoPeriodoAnio: (!pago.pagoPendiente) ? pago.periodoPagoUnico.periodoAnio : '-',
                            pagoUnicoPeriodoPerAnio: (!pago.pagoPendiente) ? pago.periodoPagoUnico.periodoPerAnio : '-',
                            pagoUnicoPeriodoFecini: (!pago.pagoPendiente) ? ConvertDate(pago.periodoPagoUnico.periodoFecini) : '-',
                            pagoUnicoPeriodoFecfin: (!pago.pagoPendiente) ? ConvertDate(pago.periodoPagoUnico.periodoFecfin) : '-',
                            pagoAutorizado: pago.pagoAutorizado,
                            pagoAutorizadoUsuariId: (pago.pagoAutorizado) ? pago.pagoAutorizadoDet.usuariId : null,
                            pagoAutorizadoUserName: (pago.pagoAutorizado) ? pago.pagoAutorizadoDet.userName : '-',
                            pagoAutorizadoUsuarioNombre: (pago.pagoAutorizado) ? `${pago.pagoAutorizadoDet.usuarioPat} ${pago.pagoAutorizadoDet.usuarioMat} ${pago.pagoAutorizadoDet.usuarioNom}` : '-',
                            pagoAutorizadoFecha: (pago.pagoAutorizado) ? ConvertDateTime(pago.pagoAutorizadoDet.autorizadoFecha, true) : '-'
                        }
                    });
                    setPagosPendientes(newArrayPagosPendientes);
                    break;
                case 400:
                    setPagosPendientes([]);
                    setError(<AlertForm message={res.data.msg}/>);
                    break;
                default:
                    setPagosPendientes([]);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => {
            setPagosPendientes([]);
            errorTable(verifique_conexion);
        })
        .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mostrarAlerta = value => {
        const arrayIdPeriodos = value.map(pago => ( pago.periodoId )); 
        setPeriodosId(arrayIdPeriodos);
        setOpenAlertW(true);
    };

    const mostrarAlertaQuitarAutorizacion = value => {
        setIdQuitaAut(value);
        setOpenAlertWQuitaAut(true);
    };
 
    const autorizarPagosPendientes = value => {
        const request = {
            empleadoId: empID,
            periodos: value
        };
        
        axios.post(`${servicios_grupohit}/empleado/pago/pendiente/autoriza`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(res.data.msg);
                    cerrarModal();
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    };

    const quitarAutorizacionPagoPendiente = periodoId => {        
        axios.delete(`${servicios_grupohit}/empleado/pago/pendiente/desautorizar/${empID}/${periodoId}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const nuevosPagosPendientes = pagosPendientes.map(pp => {
                        if(pp.periodoId === periodoId) {
                            return {
                                id: pp.id,
                                nominaId: pp.nominaId,
                                nominaFecini: pp.nominaFecini,
                                periodoId: pp.periodoId,
                                periodo_anio: pp.periodo_anio,
                                periodo_peranio: pp.periodo_peranio,
                                periodoFecini: pp.periodoFecini,
                                periodoFecfin: pp.periodoFecfin,
                                pagoPendiente: true,
                                pagoUnicoPeriodoId: '-',
                                pagoUnicoPeriodoAnio: '-',
                                pagoUnicoPeriodoPerAnio: '-',
                                pagoUnicoPeriodoFecini: '-',
                                pagoUnicoPeriodoFecfin: '-',
                                pagoAutorizado: false,
                                pagoAutorizadoUsuariId: null,
                                pagoAutorizadoUserName: '-',
                                pagoAutorizadoUsuarioNombre: '-',
                                pagoAutorizadoFecha: '-'
                            }
                        }

                        return pp;
                    });
                    setPagosPendientes(nuevosPagosPendientes);
                    successTable(res.data.msg);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    };

    return (
        <Fragment>
            {(pagosPendientes && pagosPendientes.length)
            ?   <Fragment>
                    <TableSelectRows
                        id={`${id}secondary`}
                        title=""
                        columns={[
                            { field: "periodo_anio", title: "Año" },
                            { field: "periodo_peranio", title: "Periodo" },
                            { field: "periodoFecini", title: "Fecha Inicio", filtering: false },
                            { field: "periodoFecfin", title: "Fecha Final", filtering: false },
                            { field: "pagoUnicoPeriodoAnio", title: "Año Pago Único", filtering: false },
                            { field: "pagoUnicoPeriodoPerAnio", title: "Periodo Pago Único", filtering: false },
                            { field: "pagoUnicoPeriodoFecini", title: "Fecha Inicio Pago Único", filtering: false },
                            { field: "pagoUnicoPeriodoFecfin", title: "Fecha Final Pago Único", filtering: false },
                            { field: "pagoAutorizadoUsuarioNombre", title: "Autorizado por", filtering: false },
                            { field: "pagoAutorizadoFecha", title: "Fecha Autorización", filtering: false },
                            { field: "", title: "Acciones", filtering: false },
                        ]}
                        data={pagosPendientes}
                        icon={Today}
                        tooltip={`Autorizar ${pagos_pendientes}`}
                        action={mostrarAlerta}
                        actionEdit={mostrarAlertaQuitarAutorizacion}
                        pagosPendientes={empNombre}
                        filtering
                    />
                    <ModalLoading nombreModulo={id} open={isLoading} setOpen={setIsLoading}/>
                    <AlertWarning show={openAlertW} SetopenALert={setOpenAlertW} text={`Los ${pagos_pendientes} serán autorizados`} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={autorizarPagosPendientes} value={periodosId}/>
                    <AlertWarning show={openAlertWQuitaAut} SetopenALert={setOpenAlertWQuitaAut} text="Se eliminará la autorización del pago único." textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={quitarAutorizacionPagoPendiente} value={idQuitaAut}/>
                </Fragment>
            :   (pagosPendientes === null) && <SpinnerOval />
            }
            { error }
        </Fragment>
    );
}
 
export default InfoPagosPendientes;