import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import BlueCheckboxRadioStyles from '../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';

const CheckboxPlazaDisponibleRango = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const CheckPlazaRangoFechas = ({ plazaDisponibleRango, handleChangePlazaDispRango }) => {
    return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
            <FormControlLabel
                control={<CheckboxPlazaDisponibleRango checked={plazaDisponibleRango} onChange={handleChangePlazaDispRango} name="checkedPlazaDispRango"/>}
                label="Plaza Disponible en Rango de Fechas"
            />
        </div>
    );
}
 
export default CheckPlazaRangoFechas;
