import React, { Fragment } from 'react';
import CardTablero from './EstadPorPeriodo/CardTablero';

const GraficaEstadisticas = ({ estadisticas, estadisticasRL }) => {
    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <CardTablero title="Total" cantidad={estadisticas.totalEmpleados} icon="people_alt"/>
                </div>
                <div className="col">
                    <CardTablero title="Activos" cantidad={estadisticas.totalActivos} icon="check_circle"/>
                </div>
                <div className="col">
                    <CardTablero title="Inactivos" cantidad={estadisticas.totalInactivos} icon="highlight_off"/>
                </div>
                <div className="col">
                    <CardTablero title="Hombres" cantidad={estadisticas.totalMasculinos} icon="directions_walk"/>
                </div>
                <div className="col">
                    <CardTablero title="Mujeres" cantidad={estadisticas.totalFemeninos} icon="emoji_people"/>
                </div>
                <div className="col">
                    <CardTablero title="Edad Promedio (años)" cantidad={estadisticas.edadPromedio} icon="assessment"/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <CardTablero title="Base" cantidad={estadisticasRL.base} icon="people_alt"/>
                </div>
                <div className="col">
                    <CardTablero title="Mandos Medios y Superiores" cantidad={estadisticasRL.mandosmediosysuperiores} icon="people_alt"/>
                </div>
                <div className="col">
                    <CardTablero title="Contrato Confianza" cantidad={estadisticasRL.contratoconfianza} icon="people_alt"/>
                </div>
                <div className="col">
                    <CardTablero title="Contrato - Contrato" cantidad={estadisticasRL.contratocontrato} icon="people_alt"/>
                </div>
                <div className="col">
                    <CardTablero title="Nombramiento Confianza" cantidad={estadisticasRL.nombramientoconfianza} icon="people_alt"/>
                </div>
                <div className="col">
                    <CardTablero title="Nóminas" cantidad={estadisticasRL.nominas} icon="event"/>
                </div>
            </div>
        </Fragment>
    );
}

export default GraficaEstadisticas;
