import React, {Fragment, useState} from 'react';
import axios from 'axios';
import { servicios_grupohit, registro_sera_eliminado, registro_no_eliminado } from '../../../../../Constants/Constants';
import { headersAuth } from '../../../../../Services/AuthService';
import Alertwarning from '../../../../../Services/Alerts/AlertWarning';
import AlertSyE from '../../../../../Services/Alerts/AlertSyE';

const ModalDeleteAcceso = ({ openDelete, setOpenDelete, elementSelect, eliminar }) => {

    const [ alertError, setAlertError ] = useState(false);
    const [ textAlert, setTextAlert ] = useState(registro_no_eliminado);

    const axiosEliminar = () => {
        axios.delete( `${servicios_grupohit}/dashboard/${elementSelect.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        eliminar( elementSelect.id );
                        break;
                    default:
                        setTextAlert( respuesta.data.msg );
                        setAlertError(true);
                        break;
                }
            })
            .catch( error => {
                setTextAlert( registro_no_eliminado );
                setAlertError(true);
            })
    }

    return (
        <Fragment>
            <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </Fragment>
    );
};

export default ModalDeleteAcceso;