import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormRegistroPatronal = ({ error, setError, enviarRegistroPatronal, elementSelect, actualizarRegistroPatronal, informacion, isLoading }) => {
    
    const [openAlert, setOpenAlert] = useState(false);

    const regPatronalClaveRef = createRef('');
    const sSocialIDRef = createRef('');
    const tipoRiesgoRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('RegistroPatronal')) {
            const registroPatClave = regPatronalClaveRef.current.value;
            const segSocId = parseInt(sSocialIDRef.current.value);
            const tipoRiesgo = parseInt(tipoRiesgoRef.current.value);
            enviarRegistroPatronal({ registroPatClave, segSocId, tipoRiesgo });
        }
    };

    const actualizar = () => {
        if(validarSubmit('RegistroPatronal')) {
            const registroPatClave = regPatronalClaveRef.current.value;
            const segSocId = parseInt(sSocialIDRef.current.value);
            const tipoRiesgo = parseInt(tipoRiesgoRef.current.value);
            setError(null);
            actualizarRegistroPatronal({ registroPatClave, segSocId, tipoRiesgo }, elementSelect.id);
        }
    };

    return (
        <Fragment>
            <form id="RegistroPatronal" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Registro Patronal" type="ClaveRegPatron" placeholder="Escriba la clave del registro patronal"
                    refValue={regPatronalClaveRef} defaultValue={elementSelect ? elementSelect.registroPatClave : ''}
                    tooltipText="1 letra mayúscula + 10 números" tooltipName="tooltipCveRegPat" maxLength={11} alfanumeric required/>
                <SelectValidation title="Seguridad Social" arrayOpciones={informacion.tiposSeguridadSocial} keyOpcion="segSocNom"
                    refValue={sSocialIDRef} defaultValue={elementSelect ? elementSelect.segSocId : ''} required/>
                <SelectValidation title="Riesgo de Trabajo" arrayOpciones={informacion.tiposRiesgo} keyOpcion="riesgoPtoDesc"
                    refValue={tipoRiesgoRef} defaultValue={elementSelect ? elementSelect.tipoRiesgoId : ''} required/>
                { error }
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}

export default FormRegistroPatronal;