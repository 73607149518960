import React from 'react';
import './styles.css';

const getBase64 = ( fileReader ) => {
    return fileReader.split( ';base64,', 2 )[1];
}

const FileService = ({ inputId, getFile, formatAccept, disabled }) => {
    const onSelectFile = evt => {
        if( evt.target.files && evt.target.files.length > 0 ){
            const { name, size, type } = evt.target.files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                getFile({ name, size, type, base64: getBase64(reader.result) });
            });
            reader.readAsDataURL(evt.target.files[0]);
        }
        document.getElementById( inputId ).value = "";
    };

    return (
        <input id={inputId} type="file" className="btn btn-light col-md-12" onChange={onSelectFile} accept={formatAccept} disabled={(disabled) ? disabled : false}/>
    );
};

export default FileService;