import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioColonias = ({ error, enviarColonia, elementSelect, actualizarColonia, informacion, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const coloniaRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarAsentamiento' ) ){
            const coloniaNombre = coloniaRef.current.value;
            enviarColonia({ coloniaNombre });
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarAsentamiento' ) ){
            const coloniaNombre = coloniaRef.current.value;
            actualizarColonia({ coloniaNombre }, elementSelect.id);
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarAsentamiento' : 'AgregarAsentamiento' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <h5> Colonia </h5>
                <div> <b> Estado: </b> {informacion.estadoNom} </div>
                <div> <b> Ciudad: </b> {informacion.ciudadNom} </div>
                <div> <b> Codigo Postal: </b> {informacion.postalCode} </div>
                <InputValidation title="Colonia" type="nombreColonia" placeholder="Escriba el nombre de la colonia" maxLength='120'
                    tooltipText={'Máximo 120 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomCol'}
                    refValue={coloniaRef} defaultValue={ elementSelect ? elementSelect.codigopColonia : '' } required/>
                {error}
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioColonias;