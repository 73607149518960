import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTipoPasatiempo from './FormTipoPasatiempo';

const ModalEditTipoPasatiempo = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenEdit(false);
    };

    const actualizarTipoPasatiempo = (pasatiempo, pasatiempoId) => {
        setIsLoading(true);
        const info = { id: pasatiempoId, pasaNom: pasatiempo.pasaNom, pasaDesc: pasatiempo.pasaDesc };
        axios.put(`${servicios_grupohit_catalogo}/pasatiempos/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(res.data.dataResponse);
                    setOpenEdit( false );
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService title="Actualizar Tipo de Pasatiempo" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTipoPasatiempo
                        elementSelect={elementSelect}
                        actualizarTipoPasatiempo={actualizarTipoPasatiempo}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTipoPasatiempo;