import React, { useState, createRef, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService';
import { servicios_grupohit, verifique_conexion } from '../../../../../Constants/Constants';
import Button from '@material-ui/core/Button';
import CheckboxHistorico9719 from './CheckboxHistorico9719';
import { validarSubmit } from '../../../../../Services/Validation/HelperValidation';
import AlertSyE from '../../../../../Services/Alerts/AlertSyE';

const FormBusquedaHistoricoRH9719 = ({ checkbox, setCheckbox, setDatosTabla, setIsLoading }) => {

    const { datos_especificos, pbpNom, pbpNup, pbpNue, pbpRfc, cproCve, sria, ccatCve, desPuesto, ctinCve } = checkbox;

    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false); 

    const datos_especificosRef = createRef('');
    const pbpNueRef = createRef('');
    const pbpNomRef = createRef('');
    const pbpRfcRef = createRef('');
    const pbpNupRef = createRef('');
    const cproCveRef = createRef('');
    const sriaRef = createRef('');
    const ccatCveRef = createRef('');
    const desPuestoRef = createRef('');
    const ctinCveRef = createRef('');

    const arrayChecked = [
        { checked: datos_especificos, name: 'datos_especificos', label: 'Datos Generales', inputType: 'generalHistorico9719', inputRef: datos_especificosRef, onlyLetters: false, onlyNumbers: false, alfanumeric: ' ', tooltip: 'Búsqueda sobre todos los campos' },
        { checked: pbpNup, name: 'pbpNup', label: 'PBPNUP', inputType: 'text', inputRef: pbpNupRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false, tooltip: 'Número Único de la Plaza' },
        { checked: pbpNue, name: 'pbpNue', label: 'PBPNUE', inputType: 'text', inputRef: pbpNueRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false, tooltip: 'Número Único del Empleado' },
        { checked: pbpRfc, name: 'pbpRfc', label: 'PBPRFC', inputType: 'rfc', inputRef: pbpRfcRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true, tooltip: 'RFC del Empleado' },
        { checked: pbpNom, name: 'pbpNom', label: 'PBPNOM', inputType: 'text', inputRef: pbpNomRef, onlyLetters: ' ', onlyNumbers: false, alfanumeric: false, tooltip: 'Nombre del Empleado' },
        { checked: cproCve, name: 'cproCve', label: 'CPROCVE', inputType: 'text', inputRef: cproCveRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true, tooltip: 'Clave Presupuestal' },
        { checked: sria, name: 'sria', label: 'SRIA', inputType: 'text', inputRef: sriaRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true, tooltip: 'Nombre de la Secretaría/Dependencia' },
        { checked: ccatCve, name: 'ccatCve', label: 'CCATCVE', inputType: 'text', inputRef: ccatCveRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true, tooltip: 'Clave del Puesto' },
        { checked: desPuesto, name: 'desPuesto', label: 'DESPUESTO', inputType: 'text', inputRef: desPuestoRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true, tooltip: 'Descripción del Puesto' },
        { checked: ctinCve, name: 'ctinCve', label: 'CTINCVE', inputType: 'text', inputRef: ctinCveRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false, tooltip: 'Clave Indicador de la Plaza (Primero)' },
    ];

    const handleChange = e => {
        if(e.target.name === 'datos_especificos') {
            setCheckbox({
                pbpNue: false,
                pbpNom: false,
                pbpRfc: false,
                pbpNup: false,
                cproCve: false,
                sria: false,
                ccatCve: false,
                desPuesto: false,
                ctinCve: false,
                [e.target.name]: !checkbox[[e.target.name]]
            });
        } else {
            setCheckbox({
                ...checkbox,
                datos_especificos: false,
                [e.target.name]: !checkbox[[e.target.name]]
            });
        }
    };

    const buscar = e => {
        e.preventDefault();
        if(validarSubmit('FormHistoricoRH')) {
            setIsLoading(true);
            
            if(datos_especificos) {
                const request = {
                    valor: datos_especificosRef.current.value.trim()
                };
                axios.post(`${servicios_grupohit}/historico/rh/busqueda/`, request, headersAuth()) 
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setDatosTabla(res.data.dataResponse);
                            setIsLoading(false);
                            break;
                        case 404:
                            setDatosTabla([]);
                            setIsLoading(false);
                            break;
                        default:
                            setMessageAlertSyE(res.data.msg);
                            setOpenAlertSyE(true);
                            setIsLoading(false);
                            break;
                    }
                })
                .catch(error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                    setIsLoading(false);
                });
            } else {
                const request = {};
                if(pbpNue) request.pbpNue = pbpNueRef.current.value;
                if(pbpNom) request.pbpNom = pbpNomRef.current.value;
                if(pbpRfc) request.pbpRfc = pbpRfcRef.current.value;
                if(pbpNup) request.pbpNup = pbpNupRef.current.value;
                if(cproCve) request.cproCve = cproCveRef.current.value;
                if(sria) request.sria = sriaRef.current.value;
                if(ccatCve) request.ccatCve = ccatCveRef.current.value;
                if(desPuesto) request.desPuesto = desPuestoRef.current.value;
                if(ctinCve) request.ctinCve = parseInt(ctinCveRef.current.value);
    
                axios.post(`${servicios_grupohit}/historico/rh/`, request, headersAuth()) 
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setDatosTabla(res.data.dataResponse);
                            setIsLoading(false);
                            break;
                        case 404:
                            setDatosTabla([]);
                            setIsLoading(false);
                            break;
                        default:
                            setMessageAlertSyE(res.data.msg);
                            setOpenAlertSyE(true);
                            setIsLoading(false);
                            break;
                    }
                })
                .catch(error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                    setIsLoading(false);
                });
            }
        }
    };

    return (
        <Fragment>
            <form id="FormHistoricoRH" onSubmit={buscar}>
                <div className="row row-cols-4 ml-auto mr-auto">
                    {(arrayChecked.map((check, i) => (
                        <div key={i} className="col">
                            <CheckboxHistorico9719
                                checked={check.checked}
                                name={check.name}
                                label={check.label}
                                inputType={check.inputType}
                                inputRef={check.inputRef}
                                handleChange={handleChange}
                                onlyLetters={check.onlyLetters}
                                onlyNumbers={check.onlyNumbers}
                                alfanumeric={check.alfanumeric}
                                tooltip={check.tooltip}
                            />
                        </div>
                    )))}
                </div>
                <Button variant="contained" className="btn-color" type="submit">Buscar</Button>
            </form>
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
export default FormBusquedaHistoricoRH9719;