import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import ModalService from '../../../ModalService/ModalService';
import FormularioSalarioMinUMA from './FormularioSalarioMinUMA';
import { JsonSalarioMinUMA } from '../../../Helpers/JsonToOneLevel';

const ModalAddSalarioMinUMA = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo, dataTable }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarSalarioMinUMA = ( salarioMin ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_catalogo}s/salario/minimouma/`, salarioMin, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonSalarioMinUMA(respuesta.data.dataResponse));
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }
    
    return (
        <ModalService title="Agregar Salario Mínimo / UMA" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioSalarioMinUMA
                    error={error}
                    enviarSalarioMinUMA={enviarSalarioMinUMA}
                    dataTable={dataTable}
                    setError={setError}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddSalarioMinUMA;