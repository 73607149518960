import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, servicios_grupohit_nomina, servicios_grupohit, servicios_grupohit_catalogo, movimientos_fijos } from '../../../../../../../Constants/Constants';
import DataTableMovimientos from '../../Variables/DataTableMovimientos/DataTableMovimientos';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonMovimientosFijosEmpleado, ArrayJsonPensionesRelacionado, ArrayJsonDependientesRelacionado } from '../../../../Helpers/JsonToOneLevel';

const FijosEmpleado = ({ empleado, setEmpleado, tiposAcumulado, idModulo }) => {
    const [ fijos, setFijos ] = useState( null );
    const [ pensiones, setPensiones ] = useState( null );
    const [ pensionesFilter, setPensionesFilter ] = useState( null );
    const [ dependientes, setDependientes ] = useState( null );
    const [ indicadoresNomina, setIndicadoresNomina ] = useState( null );
    const [ quinquenios, setQuinquenios ] = useState( null );
    const [ salarioUMA, setSalarioUMA ] = useState( null );
    const [ salarioMinimo, setSalarioMinimo ] = useState( null );
    useEffect(() => {
        axiosFijosEmpleado();
        axiosPensionesEmpleado();
        axiosDependientesEmpleado();
        axiosIndicadoresNomina();
        axiosTabuladores();
        axiosSalarioMinimo();
        // eslint-disable-next-line
    }, []);

    const axiosFijosEmpleado = () => {
        setFijos( null );
        axios.get( `${servicios_grupohit_rh}/movimientos/fijo/empleado/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setFijos( respuesta.data.dataResponse );
                        break;
                    case 404: //No hay datos disponibles
                        setFijos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosPensionesEmpleado = () => {
        axios.get( `${servicios_grupohit_rh}/empleado/pension/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const pensiones = ArrayJsonPensionesRelacionado( respuesta.data.dataResponse );
                        setPensiones( pensiones );
                        setPensionesFilter( pensiones.filter( pension => pension.tipoCuota === "I" || pension.tipoCuota === "S" || pension.tipoCuota === "U" ) ); //Solo Pensiones Con IMPORTE
                        break;
                    case 404: //NO hay datos que mostrar
                        setPensiones( [] );
                        setPensionesFilter( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosDependientesEmpleado = () => {
        axios.get( `${servicios_grupohit_rh}/empleado/dependiente/economico/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDependientes( ArrayJsonDependientesRelacionado(respuesta.data.dataResponse) );
                        break;
                    case 404: //NO hay datos que mostrar
                        setDependientes( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener los indicadores por nomina (Nomina a la cual esta relacionada el empleado) */
    const axiosIndicadoresNomina = () => {
        axios.get( `${servicios_grupohit_nomina}/indicadores/getbynomina/${empleado.nominaId}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setIndicadoresNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setIndicadoresNomina( [] );
            })
    }

    /* Axios para obtener listado de Tabuladores Quinquenios */
    const axiosTabuladores = () => {
        setQuinquenios(null);
        axios.get( `${servicios_grupohit}/org/quinquenios/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const tabulador = respuesta.data.dataResponse.find( element => element.fecFin === null );
                        axiosQuinquenios( tabulador.id );
                        break;
                    default:
                        setQuinquenios([]);
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setQuinquenios([]);
            });
    }

    const axiosQuinquenios = ( tabuladorActivoID ) => {
        axios.get( `${servicios_grupohit}/org/quinquenios/detalle/${tabuladorActivoID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const quinquenios = respuesta.data.dataResponse;
                        setQuinquenios( quinquenios.find( element => element.nivlaboralClave === empleado.nivLaboralClave ) );
                        break;
                    default:
                        setQuinquenios([]);
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setQuinquenios([]);
            });
    }

    /* Axios para obtener Salario Minimo / UMA */
    const axiosSalarioMinimo = () => {
        axios.get( `${servicios_grupohit_catalogo}s/salario/minimouma/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setSalarioUMA( respuesta.data.dataResponse.find( element => element.fechaFin === null && element.zona.zonaDesripcion === 'U' ) );
                        setSalarioMinimo( respuesta.data.dataResponse.find( element => element.fechaFin === null && element.zona.zonaDesripcion === 'S' ) );
                        break;
                    case 404: //NO hay datos que mostrar
                        setSalarioUMA( [] );
                        setSalarioMinimo( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <React.Fragment>
            { fijos && pensiones && pensionesFilter && dependientes && indicadoresNomina && quinquenios ?
                <DataTableMovimientos
                    idModulo={idModulo}
                    title={movimientos_fijos}
                    empleado={empleado}
                    setEmpleado={setEmpleado}
                    columns={[
                        { field: "nomina.nomClave", title: "Clave Concepto" },
                        //{ field: "nomina.nomIndicador", title: "Nombre Concepto" },
                        { field: "informacionConcepto", title: "Nombre Concepto" },
                        { field: "cantidad", title: "Cantidad", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}  },
                        { field: "monto", title: "Monto", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}  },
                        { field: "acumulado.acumuladoNombre", title: "Tipo de Acumulado" },
                        { field: "fechaInicioTab", title: "Fecha Inicio" },
                        { field: "fechaFinalTab", title: "Fecha Final" },
                    ]}
                    data={ArrayJsonMovimientosFijosEmpleado( fijos, pensiones )}
                    informacionAdicional={{ empleado, tiposAcumulado, indicadoresNomina, pensiones: pensionesFilter, dependientes, quinquenios, salarioMinimo, salarioUMA, axiosFijosEmpleado }}/>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default FijosEmpleado;