import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import ReportesAccesoDirecto from './ReportesAccesoDirecto';
import ModalDeleteReport from './ModalDeleteReport';
import ModalInfoReport from './ModalInfoReport';
import RecursosHumanosTablaReporte from './RecursosHumanosTablaReporte'; 
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const RecursosHumanos = ({ id, permisos, icono }) => { 
    
    const [ reportesPersonalizados, setReportesPersonalizados ] = useState( null );
    const [ reportesSuperiores, setReportesSuperiores ] = useState( null );  
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ openInfoReport, setOpenInfoReport ]  = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} );
    const [ showReport, setShowReport ]  = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const permisoss = permisos.map(element=> element.id);
    const [ title, setTitle ] = useState('');
    const [ reportesDefinidos, setReportesDefinidos ] = useState( null ); 
    const [ tipoReporte, setTipoReporte ] = useState(null);
    useEffect(() => { 

            axios.get( `${servicios_grupohit}/configuracion/reportes/1`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setReportesPersonalizados(  Array.isArray(respuesta.data.dataResponse.reportes) ? respuesta.data.dataResponse.reportes: [] );
                        const subordinados =  Array.isArray(respuesta.data.dataResponse.subordinados) ? respuesta.data.dataResponse.subordinados : [] ;
                        setReportesSuperiores( Array.isArray(respuesta.data.dataResponse.superiores) ? respuesta.data.dataResponse.superiores.concat(subordinados) : subordinados );
                        const globales = Array.isArray(respuesta.data.dataResponse.estaticosglobales) ? respuesta.data.dataResponse.estaticosglobales.map(element=> 
                            {   return {
                                    reportesTipoReporte: element.tipoReporte,
                                    reportesEstatico: element.estatico,
                                    reportesId:  element.id,
                                    reportesReporteDescrip:  element.reporteDescrip,
                                    usuarioDatosUsuarioPat: element.usuarioDatosUsuarioPat,
                                    reportesCreated: element.created,
                                    reportesReporteJson: element.reporteJson,
                                    reportesReporteNom: element.reporteNom,
                                    usuarioUsername: element.usuarioUsername,
                                    usuarioDatosUsuarioMat: element.usuarioDatosUsuarioMat,
                                    usuarioStatus: element.usuarioStatus,
                                    usuarioDatosUsuarioNom: element.usuarioDatosUsuarioNom,
                                    propietario: 10,
                                    usuarioId: element.usuarioId
                                }
                            }
                        ) : [];
                        setReportesDefinidos( (Array.isArray(globales) ? globales: [])
                                                .sort((a, b) => a.reportesReporteNom > b.reportesReporteNom ? 1 : a.reportesReporteNom < b.reportesReporteNom ? -1 : 0) 
                                            );
                        break;
                    case 404:
                        setReportesPersonalizados( [] );
                        setReportesSuperiores( [] ); 
                        setReportesDefinidos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }, []);
    
    const openReport = (action) => {
        setShowReport(action);
    }

    const eliminar = ( idElement ) => { 
        if(tipoReporte){
            setReportesSuperiores( reportesSuperiores.filter(element => element.reportesId !== idElement));
        }else{
            setReportesPersonalizados( reportesPersonalizados.filter( element => element.reportesId !== idElement ) ); 
        }
        
    } 
         
    const onAdd = ( newElement ) => {
        setReportesPersonalizados( [...reportesPersonalizados, newElement ]); 
    } 

    const error =  ( msjError ) => {
        setTextAlert( msjError ); 
        setAlertError( true ); 
    }  
    
    return (
        <div id={`modulo${id}`}>
            
            {
                showReport &&
                <RecursosHumanosTablaReporte
                    id={id} permisos={permisoss} openReport={openReport} elementSelect={elementSelect} onAdd={onAdd}
                />
            }
            {
                !showReport && 
                <React.Fragment> 
                {   
                    reportesDefinidos
                    ?
                    <React.Fragment>
                        {(reportesDefinidos.length > 0) &&
                            <h4 className="pageSubtitle" style={{margin:'2rem 0 2rem'}}> {`Reportes Predefinidos`} </h4>
                        }
                        <div className="row">
                            { reportesDefinidos.map( reporte => (
                                <div key={ `define-${reporte.reportesId}` } className="col-md-2 col-sm-12 my-2">
                                    <ReportesAccesoDirecto
                                        id={id} reporte={reporte} openReport={openReport} icon={icono} downloadReport
                                        setOpenDelete={setOpenDelete} setElementSelect={setElementSelect}
                                        informacionAdicional={true}
                                    />
                                </div>
                            ))} 
                        </div> 
                    </React.Fragment>
                    :   
                    <SpinnerOval /> 
                }
                <React.Fragment>            
                    <h4 className="pageSubtitle" style={{margin:'2rem 0 2rem'}}> {`Reportes Personalizados`} </h4>
                    {
                       reportesPersonalizados  
                       ?
                       <React.Fragment>
                           <div className="row">
                               {
                                   permisoss.includes(1) &&
                                    <div key={ 'customNew' } className="col-md-2 col-sm-12 my-2">
                                        <ReportesAccesoDirecto
                                            id={id} reporte={{reportesReporteNom:"Nuevo Reporte"}} openReport={openReport} 
                                            setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle}
                                            icon={'Add'} permisos={permisoss} informacionAdicional={true}
                                        />
                                    </div>
                               }  
                               { 
                               reportesPersonalizados.map( reporte => (
                                   <div key={ reporte.reportesId } className="col-md-2 col-sm-12 my-2">
                                       <ReportesAccesoDirecto
                                            id={id} reporte={reporte} openReport={openReport} permisos={permisoss} icon={icono}
                                            setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle}
                                            setOpenInfoReport={setOpenInfoReport} reporteSuperior={false} setTipoReporte={setTipoReporte}
                                        />
                                   </div>
                               ))} 
                           </div> 
                       </React.Fragment>
                       :   
                       <SpinnerOval /> 
                    }
                </React.Fragment> 
                <React.Fragment>            
                    {
                        reportesSuperiores 
                        &&
                        <React.Fragment>
                            {(reportesSuperiores.length > 0) &&
                               <h4 className="pageSubtitle" style={{margin:'2rem 0 2rem'}}> {`Reportes de Otros Usuarios`}</h4>
                            }
                            <div className="row"> 
                                { 
                                reportesSuperiores.map( reporte => (
                                    <div key={ `custom-${reporte.reportesId}`} className="col-md-2 col-sm-12 my-2">
                                        <ReportesAccesoDirecto
                                            id={id} reporte={reporte} openReport={openReport} permisos={permisoss} icon={icono}
                                            setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle}
                                            setOpenInfoReport={setOpenInfoReport} informacionAdicional={reporte} reporteSuperior={true} setTipoReporte={setTipoReporte}
                                        />
                                    </div>
                                ))} 
                            </div> 
                        </React.Fragment>
                    }
                </React.Fragment>
                </React.Fragment>
            }
            <ModalDeleteReport openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} error={error}/>
            <ModalInfoReport openInfoReport={openInfoReport} nombreModulo={id} setOpenInfoReport={setOpenInfoReport} elementSelect={elementSelect} title={title}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            
        </div>
    );
}
 
export default RecursosHumanos;