import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormMotivosBaja from './FormMotivosBaja';

const ModalEditMotivosBaja = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarMotivoBaja = (motivoBaja, motivoBajaId) => {
        setIsLoading(true);
        const info = { id: motivoBajaId, clave: motivoBaja.clave, nombre: motivoBaja.nombre, reanudaFechas: motivoBaja.reanudaFechas };
        Axios.put( `${servicios_grupohit_catalogo}/motivobaja/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar(info);
                setOpenEdit(false);
                break;
            default:
                errorTable(res.data.msg );
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Motivo de Baja" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormMotivosBaja
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarMotivoBaja={actualizarMotivoBaja}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditMotivosBaja;