const EXP_ETIQUETAS_HTML = /<[^>]*>/g;

export function eliminarEtiquetas( cadenaHTML ) {
    return cadenaHTML.replace( EXP_ETIQUETAS_HTML, '' );
}

export function getToArray( destinatarios ) {
    return destinatarios.map( elemento => ( elemento.username ) );
}

export function getToNombreArray( destinatarios ) {
    //return destinatarios.map( elemento => ( `${elemento.datosUsuarioNom} ${elemento.datosUsuarioPat}` ) );
    return destinatarios.map( elemento => ( elemento.datosUsuarioNom ) );
}

export function actualizarBadge( emails ) {
    const badge = document.getElementById( 'showEmails' );
    if( badge.childNodes[1] ){
        if( emails !== 0 ){
            badge.childNodes[1].className = 'MuiBadge-badge notificationColor MuiBadge-anchorOriginTopRightRectangle';
            badge.childNodes[1].textContent = emails;
        }else{
            badge.childNodes[1].className = '';
            badge.childNodes[1].textContent = '';
        }
    }
}

/* Funcion para obtener fechaActual en formato dd/mm/aaaa */
export function dateActual() {
    const dt = new Date();
    var day = '' + dt.getDate()
    var month = '' + (dt.getMonth()+1);

    if( day.length < 2 ){ day = '0' + day; }
    if( month.length < 2 ){ month = '0' + month; }

    return `${day}/${month}/${dt.getFullYear()}`;
}

export function mostrarFecha( fechaHora ) {
    const dateMensaje = fechaHora.split(' ');

    if( dateMensaje[0] === dateActual() ){ return dateMensaje[1].substr(0,5); }
    else{ return dateMensaje[0]; }
}