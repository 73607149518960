import React, { useState } from 'react';
import CollapseMaterial from '../../AsignacionModulos/CollapseMaterial/CollapseMaterial';

import Checkbox from '@material-ui/core/Checkbox';

const NominasRelacionLaboral = ({ relacionLaboral, accesosNominas, setAccesosNominas, elementoActivo, setElementoActivo }) => {
    const [ selectAll, setSelectAll ] = useState( false );
    const seleccionarTodo = () => {
        setSelectAll( !selectAll );
        if( !selectAll ){
            agregarAccesos();
        }else{
            eliminarAccesos();
        }
    }
    /* ------- Funciones que permiten agregar y eliminar varios elementos dentro de un arreglo de objetos (accesosNominas) ------- */
    const agregarAccesos = () => {
        let accesosTemporal = [ ...accesosNominas ];
        relacionLaboral.nominas[0].forEach( nomina => {
            if( !accesosTemporal.includes( nomina.id ) ){
                accesosTemporal.push( nomina.id );
            }
        })
        setAccesosNominas( accesosTemporal );
    }

    const eliminarAccesos = () => {
        let accesosTemporal = [ ...accesosNominas ];
        relacionLaboral.nominas[0].forEach( nomina => {
            if( accesosTemporal.includes( nomina.id ) ){
                accesosTemporal = accesosTemporal.filter( acceso => acceso !== nomina.id );
            }
        })
        setAccesosNominas( accesosTemporal );
    }
    /* ---------------------------------------- Funcion que permite agregar un solo acceso --------------------------------------- */
    const seleccionarAcceso = ( nominaID ) => {
        let accesosTemporal = [ ...accesosNominas ];
        if( accesosTemporal.includes( nominaID ) ){
            accesosTemporal = accesosTemporal.filter( acceso => acceso !== nominaID );
        }else{
            accesosTemporal.push( nominaID );
        }
        setAccesosNominas( accesosTemporal );
    }
    /* --------------------------------------------------------------------------------------------------------------------------- */
    return (
        <CollapseMaterial title={relacionLaboral.nombre} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
            <div className="accesosContenido">
                <div className="accesosIzquierdo">
                    <Checkbox
                        checked={ selectAll }
                        onChange={ () => seleccionarTodo() }
                    />
                </div>
                <div className="accesoserecho mt-2">
                    Seleccionar todo
                </div>
            </div>

            { relacionLaboral.nominas[0].map( nomina => (
                <div key={nomina.id} className="accesosContenido mb-2">
                    <div className="accesosIzquierdo">
                        <Checkbox
                            checked={ accesosNominas.includes( nomina.id ) ? true : false }
                            onChange={ () => seleccionarAcceso( nomina.id ) }
                        />
                    </div>
                    <div className="accesoserecho">
                        <span> <b> Clave: </b> {nomina.nominaClave} </span> <br/>
                        <span> <b> Nombre: </b> {nomina.nominaNombre} </span>
                    </div>
                </div>
            ))}
        </CollapseMaterial>
    );
};

export default NominasRelacionLaboral;