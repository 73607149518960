import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Add } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import BtnRemoveItem from '../../../Empleados/BtnRemoveItem';
import '../../styles.css';
import AutocompleteComponent from '../../../../../../../../Services/Autocomplete/AutocompleteComponent';
import AlertForm from '../../../../../../../../Services/AlertForm';
import TooltipComponent from '../../../../../../../../Services/Tooltip/TooltipComponent';

const ListaEmpleadosEscalafon = ({ empleadosActivos, empleadosEscalafon, setEmpleadosEscalafon, setMuestraEscalafon, obtenerFechaMinima }) => {

    const [empleados, setEmpleados] = useState([]);
    const [empleadoSelect, setEmpleadoSelect] = useState(null);
    const [errorAutocomplete, setErrorAutocomplete] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        //obtiene la diferencia entre el arreglo de empleados y empleados seleccionados en la tabla de escalafón
        const diferencia = empleadosActivos.filter(emp => empleadosEscalafon.indexOf(emp) === -1);
        const empDiferencia = diferencia.map(emp => ({
            id: emp.id,
            nombreCompleto: emp.nombreCompleto,
            empleadoRfc: emp.empleadoRfc,
            puestoCve: emp.puestoCve,
            puestoNom: emp.puestoNom,
            nominaClave: emp.nominaClave,
            nominaNombre: emp.nominaNombre,
            empleadoClave: emp.empleadoClave,
            plazaClave: emp.plazaClave
        }));
        setEmpleados(empDiferencia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empleadosActivos]);

    const eliminarEmpleado = empleado => {
        const newEmpEscalafon = empleadosEscalafon.filter(emp => emp.id !== empleado.id);
        setEmpleadosEscalafon(newEmpEscalafon);
        setEmpleados([...empleados, empleado]);
    };

    const agregarEmpleado = e => {
        e.preventDefault();
        if(empleadoSelect === null) {
            setErrorAutocomplete(true);
            return false;
        }
        setError(null);
        setErrorAutocomplete(false);
        const newEmpleadoSelect = empleadosActivos.find(emp => emp.id === empleadoSelect.id);
        const newEmpleados = empleados.filter(emp => emp.id !== empleadoSelect.id);
        setEmpleados(newEmpleados);
        setEmpleadosEscalafon([...empleadosEscalafon, newEmpleadoSelect]);
        setEmpleadoSelect(null);
    };

    const seleccionarEmpleado = empleado => {
        setEmpleadoSelect(empleado);
    };

    const irAMovimientosEscalafon = () => {
        if(empleadosEscalafon.length > 1) {
            obtenerFechaMinima();
            setError(null);
            setMuestraEscalafon(true);
        } else {
            setError(<AlertForm message="Debe seleccionar, al menos, 2 Empleados"/>);
        }
    };
    
    return (
        <Fragment>
            <div className="row">
                <div className="col-12 mt-2">
                    <h6>Agregar Empleado</h6>
                </div>
            </div>
            <div id="form-add-emp" className="form-add-emp-max">
                <form id="FromEmpEscalafon" className="row" onSubmit={agregarEmpleado}>
                    <div className="col-10">
                        <AutocompleteComponent
                            id="empEscalafon"
                            title="Empleado"
                            options={empleados}
                            optionListView="nombreCompleto"
                            optionKey="empleadoRfc"
                            action={seleccionarEmpleado}
                            value={empleadoSelect}
                            placeholder="Empleado"
                            tooltipText="Busque el Empleado, por nombre o NUE, en el campo de autocompletado"
                            tooltipName="tooltipEmpEscalafon"
                            clearDiasabled
                            error={errorAutocomplete}
                            required
                        />
                    </div>
                    <div className={`col-2 align-self-end ${errorAutocomplete ? 'mb-4' : 'mb-2'}`}>
                        <TooltipComponent tooltipText="Agregar empleado a lista de escalafón">
                            <Button variant="contained" className="btn-color" type="submit"><Add/></Button>
                        </TooltipComponent>                            
                    </div>
                </form>
            </div>
            <hr/>
            <h6>{`Empleados Seleccionados (${empleadosEscalafon.length})`}</h6>
            <div>
                <List dense={true}>
                {empleadosEscalafon.map((empleado) => (
                    <Fragment key={empleado.id}>
                        <ListItem>
                            <div>{ `${empleado.empleadoClave} / ${(empleado.empleadoRfc) ? `${empleado.empleadoRfc} / ` : ''}${empleado.nombreCompleto}` }</div>
                            <BtnRemoveItem
                                id={empleado.id} className="btn-position-top"
                                idArray="id" array={empleadosEscalafon}
                                action={eliminarEmpleado} remove
                            />
                        </ListItem>                  
                        <hr/>
                    </Fragment>
                ))}
                </List>
            </div>
            { error }
            <Button variant="contained" className="btn-color" onClick={irAMovimientosEscalafon}>Continuar</Button>
        </Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});
  
export default connect(mapStateToProps, null)(ListaEmpleadosEscalafon);