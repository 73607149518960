import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormularioTipoRelacion from './FormularioTipoRelacion';

const ModalEditTipoRelacion = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenEdit(false);
    };

    const actualizarTipoRelacion = (tipoRelacion, tipoRelacionId) => {
        setIsLoading(true);
        const info = { id: tipoRelacionId, clave: tipoRelacion.clave, nombre: tipoRelacion.nombre, descripcion: tipoRelacion.descripcion, modalidad: tipoRelacion.modalidad };
        Axios.put( `${servicios_grupohit_catalogo}/relacionlaboral/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar(res.data.dataResponse);
                setOpenEdit(false);
                break;
            default:
                errorTable(res.data.msg);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService title="Actualizar Tipo de Relación Laboral" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormularioTipoRelacion
                        elementSelect={elementSelect}
                        actualizarTipoRelacion={actualizarTipoRelacion}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTipoRelacion;