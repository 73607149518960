import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, sesion_usuario_no_cerrada, sesion_usuario_sera_cerrada } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteSesiones = ({ idModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {

    const [openLoading, setOpenLoading] = useState(false);

    const servicioCerrarSesion = () => {
        setOpenLoading(true);
        const request = { token: elementSelect.key };
        axios.post(`${servicios_grupohit_url}/activos/`, request, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect.id);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(sesion_usuario_no_cerrada))
        .finally(() => setOpenLoading(false));
    };

    return(
        <Fragment>
            <ModalLoading nombreModulo={idModulo} open={openLoading} setOpen={setOpenLoading}/>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={sesion_usuario_sera_cerrada} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={servicioCerrarSesion} />
        </Fragment>
    );
}

export default ModalDeleteSesiones;