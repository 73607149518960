import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteAusentismos = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const eliminarAusentismo = () => {
        setIsLoading(true);
        axios.delete(`${servicios_grupohit_rh}/empleado/ausentismo/${elementSelect.id}`, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect.id);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_eliminado))
        .finally(() => setIsLoading(false));
    }

    return(
        <Fragment>
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={eliminarAusentismo} />
        </Fragment>
    );
}

export default ModalDeleteAusentismos;