import React, { useState } from 'react';
import ModalService from '../../../ModalService/ModalService';
import { acumulados_ } from '../../../../../../Constants/Constants';
import FormDeleteAcumulados from './FormDeleteAcumulados';
import MovimientosExcel from '../Variables/DataTableMovimientos/MovimientosExcel';

import CollapseMaterial from '../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/CollapseMaterial';

const ModalAddAcumuladosExcel = ({ openAdd, setOpenAdd, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    const cerrarModal = () => {
        setOpenAdd( false );
    }

    return (
        <ModalService title={`Carga Masiva de ${acumulados_}`} parentNode={nombreModulo} minWidth="33%" maxWidth="35%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <b>Descarga</b>
                <div>1. Seleccione la nómina.</div>
                <div>2. Baje el archivo.</div>
                <div>3. Realice los cambios necesarios en el archivo descargado.</div>
                <br/>
                <b>Carga</b>
                <div>1. Busque el archivo con extensión .csv.</div>
                <div>2. Una vez que el sistema lo analice:</div>
                <div>Si no se presentan incidencias, haga la carga. De lo contrario, modifique el archivo y repita el procedimiento.</div>
                <CollapseMaterial title="Eliminar acumulados por periodo" elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
                    <FormDeleteAcumulados
                        errorTable={errorTable}
                        successTable={successTable}
                        informacion={informacionAdicional}
                    />
                </CollapseMaterial>
                <hr/>
                <MovimientosExcel
                    idModulo={nombreModulo}
                    errorTable={errorTable}
                    successTable={successTable}
                    informacion={informacionAdicional}
                    nombreModulo={acumulados_}
                />
        </ModalService>
    );
};

export default ModalAddAcumuladosExcel;