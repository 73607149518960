import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_nomina } from '../../../../../../../Constants/Constants';
import { ArrayJsonNominas, ArrayJsonEmpleadosPagosUnicos } from '../../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import FormularioConsultarPagosUnicos from './FormularioConsultarPagosUnicos';
import InputText from '../../../../../../../Services/Components/DataTable/InputText';

const PagosUnicos = ({ errorAlert, successAlert, moduloID, parentNode }) => {
    const [ nominas, setNominas ] = useState( null );
    const [ informacion, setInformacion ] = useState( null );
    const [ nominaID, setNominaID ] = useState( null );
    const [ informacionAdicional, setInformacionAdicional ] = useState( null );
    const [ peticion, setPeticion ] = useState( false );
    useEffect(() => {
        axiosNomninas();
    }, []);

    const axiosNomninas = () => {
        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( ArrayJsonNominas(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        //setNominas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosInformacion = ( nominaID, periodo, anioID, informacion ) => {
        setPeticion( true );
        //axios.get( `${servicios_grupohit_nomina}/vacumperxnom/${nominaID}/${periodo}/${anioID}`, headersAuth() )
        axios.get( `${servicios_grupohit_nomina}/acumulados/tacumulados/info/${nominaID}/${periodo}/${anioID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setInformacion( ArrayJsonEmpleadosPagosUnicos(respuesta.data.dataResponse) );
                        setNominaID( nominaID );
                        setInformacionAdicional( informacion );
                        break;
                    case 404: //No hay datos que mostrar
                    case 500: //Error al recuperar los datos
                        setInformacion([]);
                        setNominaID( null );
                        setInformacionAdicional( null );
                        break;
                    default:
                        break;
                }
                setPeticion( false );
            })
            .catch( error => {
                console.log( error )
                setPeticion( false );
            })
    }

    return (
        <React.Fragment>
            { nominas ?
                <React.Fragment>
                    <FormularioConsultarPagosUnicos
                        errorAlert={errorAlert}
                        successAlert={successAlert}
                        informacion={{ nominas }}
                        axiosInformacion={axiosInformacion}
                        setInformacion={setInformacion}
                    />
                    { !peticion ?
                        informacion ?
                            informacion.length > 0 ?
                                <DataTableService id={moduloID} title="Pagos Únicos"
                                    columns={[
                                        { field: "empleadoClave", title: "NUE", type: 'numeric', hidden:false, filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                                        { field: "nombreCompleto", title: "Nombre", hidden:false},
                                        //{ field: "empleadoFecnac", title: "Fecha de Nacimiento", hidden:false },
                                        //{ field: "empleadoCurp", title: "CURP", hidden:false },
                                        { field: "empleadoRfc", title: "RFC", hidden:false},
                                        //{ field: "empleadoNss", title: "NSS", hidden:false},
                                        { field: "cpresupuestalClave", title: "Clave Presupuestal", hidden:false, customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                                        { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto", hidden:false },
                                        { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden:false},
                                        { field: "nomindClave", title: "Clave Concepto", hidden:false },
                                        { field: "tiponatuNom", title: "Nombre Tipo Naturaleza", hidden:false },
                                        { field: "nomindNomIndicador", title: "Concepto por Nómina", hidden:false },
                                        { field: "cantidad", title: "Cantidad", cellStyle: rowData => ({ textAlign:'right'}), hidden:false },
                                        { field: "monto", title: "Monto", cellStyle: rowData => ({ textAlign:'right'}), hidden:false },
                                        //{ field: "acumuladoClave", title: "Clave Acumulado" },
                                        { field: "acumuladoNombre", title: "Nombre Acumulado" },

                                        { field: "periodoAnioInfo", title: "Año Informativo" },
                                        { field: "periodoPeranioInfo", title: "Periodo Informativo" },
                                    ]}
                                    hideIconDelete showIconNewFolder
                                    //showIconApps
                                    data={ informacion }
                                    informacionAdicional={{ empleados: informacion, nominas, nominaID, informacion: informacionAdicional, parentNode }}
                                    //permisos={permisos}
                                />
                                : <div className="text-center"> No hay datos que mostrar </div>
                            : null
                        : <SpinnerOval />
                    }
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default PagosUnicos;