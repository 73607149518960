import React, { useState } from 'react'; 
import ModalService from '../../../ModalService/ModalService'; 
import FormInfoAuditoria from './FormInfoAuditoria';

const ModalInfoAuditoria = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo}) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }
    
    return (
        <ModalService title="Consultar Detalles" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormInfoAuditoria
                    error={error}
                    elementSelect={elementSelect} 
                />
        </ModalService>
    );
};

export default ModalInfoAuditoria;