import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { agregarNavegador } from '../../../../../Redux/actions/NavActions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';

const ShowEmails = ({ navegadoresStore, agregarNavegador, componenteActivoID, socket }) => {

    /* ---------------------------- Axios para obtener mensajes Bandeja de Entrada ---------------------------- */
    const [emails, setEmails] = useState( null );

    useEffect( () => {
        if(socket !==null){ 
            socket.on('inbox:messageInbox', data => {
                if(data.length) setEmails(data);
            });
          
            return( () => {
                socket.off('inbox:messageInbox');
            }) 
        }
    },[socket, emails]);

    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <Tooltip title="Bandeja de entrada" >
            <IconButton aria-label="show new mails" onClick={ () => agregarNavegador(navegadoresStore, {id: 1000, nombre: 'Mensajes', ruta: 'Mensajes'}, componenteActivoID, socket) }>
                <Badge classes={{ badge: 'notificationColor' }} badgeContent={emails ? emails.length: emails} id="showEmails">
                    <MailIcon />
                </Badge>
            </IconButton>
        </Tooltip>
    );
};
/* --------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
/* --------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, { agregarNavegador })(ShowEmails);