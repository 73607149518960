import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_url } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Icon from '@material-ui/core/Icon';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Button from '@material-ui/core/Button';

const returnInfo = ( user ) => {
    return(
        <React.Fragment>
            <Icon edge="end" aria-label="connected" className="iconUsuarioArbol">
                { user.status.id === 1
                    ? <Brightness1Icon className="iconActivoArbol" />
                    : <Brightness1Icon className="iconInactivoArbol" />
                }
            </Icon>

            {`${user.datosUsuarioNom} ${user.datosUsuarioPat} ${user.datosUsuarioMat ? user.datosUsuarioMat : ''} / ${user.username}`}
        </React.Fragment>
    )
};

const returnUsuariosIds = ( usuarios ) => {
    return usuarios.map( usuario => usuario.id);
}

const ArbolUsuarios = () => {
    const [ usuarios, setUsuarios ] = useState( null );
    const [ usuariosList, setUsuariosList ] = useState( null );
    const [ expanded, setExpanded ] = React.useState( [] );
    useEffect(() => {
        axiosUsuarios();
        axiosUsuariosList();
    }, []);

    const axiosUsuarios = () => {
        axios.get( `${servicios_grupohit_url}/all/subordinates/tree`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios( respuesta.data.dataResponse.usuario );
                        break;
                    case 404:
                        //setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosUsuariosList = () => {
        axios.get( `${servicios_grupohit_url}/all/subordinates`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuariosList( returnUsuariosIds( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        //setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={returnInfo(nodes)}>
            {Array.isArray(nodes.subordinados) ? nodes.subordinados.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? usuariosList : [],
        );
    };

    return (
        <React.Fragment>
            { usuarios && usuariosList ?
                <React.Fragment>
                    <Button onClick={handleExpandClick} style={{ float: 'right' }}>
                        {expanded.length === 0 ? 'Expandir Todo' : 'Expandir Todo'}
                    </Button>

                    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} expanded={expanded} onNodeToggle={handleToggle}
                        defaultExpanded={[usuarios.id]}>
                        {renderTree(usuarios)}
                    </TreeView>
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default ArbolUsuarios;