import React from 'react';
import ModalService from '../../../../ModalService/ModalService';
import Certificados from '../Certificados/Certificados';

const ModalAddCertificados = ({ nombreModulo, open, setOpen, nombre }) => {
    const cerrarModal = () => {
        setOpen(false);
    };

    return (
        <ModalService title="Certificado de Sello Digital" parentNode={nombreModulo} minWidth="35%" maxWidth="35%"
            isOpen={open} onClose={cerrarModal} subtitle="Los archivos se requieren para el timbrado del CFDI">
                <Certificados />
        </ModalService>
    );
};

export default ModalAddCertificados;