import React from 'react';
import ModalService from '../../../../ModalService/ModalService';
//import AlertForm from '../../../../../../../Services/AlertForm';

import InformacionRestricciones from './InformacionRestricciones';
import { obtenerRestriccionesDepartamento } from './HelperRestricciones';

const ModalInformacionRestricciones = ({ openInformacion, setOpenInformacion, moduloSelect, informacion, idModulo }) => {
    const cerrarModal = () => {
        setOpenInformacion( false );
    }

    return (
        <ModalService title="Información de Restricción" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openInformacion } onClose={ () => cerrarModal() } >
                <div> <b> Módulo: </b> {moduloSelect.nombre} </div>
                <div> <b> Departamentos Restringidos: </b> </div>

                { informacion.departamentos.map( departamento => {
                    const usuariosDepartamento = informacion.usuarios.filter( usuario => usuario.departamentoId === departamento.id );
                    const restriccionesDepartamento = obtenerRestriccionesDepartamento(informacion.restricciones, moduloSelect.id, usuariosDepartamento);
                    if( usuariosDepartamento.length > 0 && restriccionesDepartamento.length > 0 ){
                        return(
                            <div key={departamento.id}>
                                <hr/>
                                <div> <b> Departamento {departamento.deptoNom}: </b> </div>
                                <InformacionRestricciones
                                    usuariosDepartamento={usuariosDepartamento}
                                    restriccionesDepartamento={restriccionesDepartamento}
                                />
                            </div>
                        )
                    }else{
                        return '';
                    }
                })}
        </ModalService>
    );
};

export default ModalInformacionRestricciones;