import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonMovimientosFijosEmpleado } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioFijos from './FormularioFijos';

const ModalEditFijos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, idModulo }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );

    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
        setIsLoading(false);
    }

    const actualizarFijo = ( fijo, fijoID ) => {
        setIsLoading(true);
        axios.put( `${servicios_grupohit_rh}/movimientos/fijo/${fijoID}`, fijo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        editar( JsonMovimientosFijosEmpleado( respuesta.data.dataResponse, informacionAdicional.pensiones ) );
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Movimiento Fijo" parentNode={idModulo } minWidth="40%" maxWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioFijos
                    error={error}
                    elementSelect={elementSelect}
                    informacion={informacionAdicional}
                    actualizarFijo={actualizarFijo}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalEditFijos;