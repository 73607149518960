import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

const BtnBack = ({ tooltipText, action }) => {
    return (
        <div className="row justify-content-between mb-2">
            <div className="col">
                <Tooltip title={tooltipText} placement="left-start">
                    <Button variant="contained" className="form-control float-right" style={{ maxWidth: '90px' }} onClick={action}> 
                        <ArrowBackIcon/>
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
}
 
export default BtnBack;