import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

/* Estilos Avatar */
import { makeStyles } from '@material-ui/core/styles';
import TooltipComponent from '../../../../../../../Services/Tooltip/TooltipComponent';
const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        marginRight: '1rem'
    },
}));

const AutoCompleteUsuarios = ({ usuarios, setDestinatarios, value, multiple=true, title, tooltipText, placeholder, required, defaultValue }) => {
    const classes = useStyles();
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
    return (
            <Autocomplete id="tags-outlined" size="small" multiple={multiple} filterSelectedOptions
                options={ usuarios } getOptionLabel={ option => option.username }
                onChange={ (event, value) => setDestinatarios( value ) }
                noOptionsText="No existen coincidencias"
                value={value}
                defaultValue={defaultValue}
                renderInput={ (params) => (
                    <div className="form-group">
                      <label style={{marginBottom: '0.5rem'}}>
                        { title }{ (required) ? <strong className="requerido">* </strong> : null }
                        { tooltipText &&
                          <TooltipComponent tooltipText={tooltipText}>
                              <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                          </TooltipComponent>
                        }
                      </label>  
                      <TextField {...params} variant="outlined" placeholder={placeholder} />
                    </div>
                )}

                renderOption={ (option) => (
                    <React.Fragment>
                        <Avatar src={`data:image/jpeg;base64, ${option.img}`} className={classes.small}/>
                        {option.username} {(option.datosUsuarioNom) ? `/ ${option.datosUsuarioNom} ${option.datosUsuarioPat}` : ''}
                    </React.Fragment>
                )}

                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" size="small" label={option.username} {...getTagProps({ index })}
                            avatar={<Avatar src={`data:image/jpeg;base64, ${option.img}`} />} />
                    ))
                }
            />
    );
};

export default AutoCompleteUsuarios;