import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_nomina } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import ModalConsultaNomina from './ModalConsultaNomina';
import CardNomina from './CardNomina';
import './styles.css';

const NominasNomina = ({ id }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    const [ openAdd, setOpenAdd ] = useState( false );
    /* ------------------------ Axios para obtener listado de nominas por Relacion Laboral ------------------------ */
    const [ nominas, setNominas ] = useState( null );
    const [ infoNominas, setInfoNominas ] = useState( null );
    useEffect(() => {
        axiosNomninas();
        axiosDetallesNomina();
    }, []);

    const axiosNomninas = () => {
        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setNominas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosDetallesNomina = () => {
        axios.get( `${servicios_grupohit_nomina}/vacumperprocxnominatotal/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setInfoNominas( respuesta.data.dataResponse );
                        break;
                    case 404: //No hay nominas registradas para este usuario
                        setInfoNominas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------------------ */
    return (
        <div id={`modulo${id}`}>
            { nominas && infoNominas ?
                <React.Fragment>
                    <div className="row">
                        { nominas.map( nomina => (
                            <div key={ nomina.id } className="col-md-4 col-sm-12 my-2">
                                <CardNomina nomina={nomina} setElementSelect={setElementSelect} setOpenAdd={setOpenAdd}
                                    percepciones={infoNominas.find(element => element.nominaId === nomina.id && element.naturaleza === "Percepciones")}
                                    deducciones={infoNominas.find(element => element.nominaId === nomina.id && element.naturaleza === "Deducciones")}
                                />
                            </div>
                        ))}
                    </div>
                    { elementSelect &&
                        <ModalConsultaNomina moduloID={id} openAdd={openAdd} setOpenAdd={setOpenAdd} nomina={elementSelect}/> }
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </div>
    );
}
 
export default NominasNomina;