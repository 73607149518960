import React, { useState, useRef, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../Constants/Constants';
import Button from '@material-ui/core/Button';
import CheckboxHistorico9719 from './CheckboxHistorico9719';
import { floatToAmount, validarSubmit } from '../../../../../Services/Validation/HelperValidation';
import AlertSyE from '../../../../../Services/Alerts/AlertSyE';

const FormBusquedaHistorico9719 = ({ checkbox, setCheckbox, setDatosTabla, setIsLoading }) => {

    const {
        datos_especificos, empleado_nombre, empleado_nss, empleado_num, empleado_rfc, anio_periodo, fec_ingreso, fec_vigencia,
        proyecto, plaza, categoria_puesto
    } = checkbox;

    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const datos_especificosRef = useRef('');
    const empleado_numRef = useRef('');
    const empleado_nombreRef = useRef('');
    const empleado_rfcRef = useRef('');
    const empleado_nssRef = useRef('');
    const anio_periodoRef = useRef('');
    const fec_ingresoRef = useRef('');
    const fec_vigenciaRef = useRef('');
    const proyectoRef = useRef('');
    const plazaRef = useRef('');
    const categoria_puestoRef = useRef('');

    const arrayChecked = [
        { checked: datos_especificos, name: 'datos_especificos', label: 'Datos Generales', inputType: 'generalHistorico9719', inputRef: datos_especificosRef, onlyLetters: false, onlyNumbers: false, alfanumeric: ' ' },
        { checked: empleado_num, name: 'empleado_num', label: 'NUE', inputType: 'text', inputRef: empleado_numRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false },
        { checked: empleado_nombre, name: 'empleado_nombre', label: 'Nombre', inputType: 'text', inputRef: empleado_nombreRef, onlyLetters: ' ', onlyNumbers: false, alfanumeric: false },
        { checked: empleado_rfc, name: 'empleado_rfc', label: 'RFC', inputType: 'text', inputRef: empleado_rfcRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true },
        { checked: empleado_nss, name: 'empleado_nss', label: 'NSS', inputType: 'text', inputRef: empleado_nssRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false },
        { checked: anio_periodo, name: 'anio_periodo', label: 'Año / Periodo', inputType: 'text', inputRef: anio_periodoRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false },
        { checked: fec_ingreso, name: 'fec_ingreso', label: 'Año / Periodo Ingreso', inputType: 'text', inputRef: fec_ingresoRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false },
        { checked: fec_vigencia, name: 'fec_vigencia', label: 'Año / Periodo Vigencia', inputType: 'text', inputRef: fec_vigenciaRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false },
        { checked: proyecto, name: 'proyecto', label: 'Proyecto', inputType: 'text', inputRef: proyectoRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true },
        { checked: plaza, name: 'plaza', label: 'Plaza', inputType: 'text', inputRef: plazaRef, onlyLetters: false, onlyNumbers: true, alfanumeric: false },
        { checked: categoria_puesto, name: 'categoria_puesto', label: 'Categoría Puesto', inputType: 'text', inputRef: categoria_puestoRef, onlyLetters: false, onlyNumbers: false, alfanumeric: true },
    ];

    const handleChange = e => {
        if(e.target.name === 'datos_especificos') {
            setCheckbox({
                empleado_num: false,
                empleado_nombre: false,
                empleado_rfc: false,
                empleado_nss: false,
                anio_periodo: false,
                fec_ingreso: false,
                fec_vigencia: false,
                proyecto: false,
                plaza: false,
                categoria_puesto: false,
                [e.target.name]: !checkbox[[e.target.name]]
            });
        } else {
            setCheckbox({
                ...checkbox,
                datos_especificos: false,
                [e.target.name]: !checkbox[[e.target.name]]
            });
        }
    };

    const buscar = e => {
        e.preventDefault();
        if(validarSubmit('FormHistorico')) {
            setIsLoading(true);
            
            if(datos_especificos) {
                const request = {
                    valor: datos_especificosRef.current.value.trim()
                }
                axios.post(`${servicios_grupohit_rh}/historico/busqueda/`, request, headersAuth()) 
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const parseDatos = res.data.dataResponse.map(item => {
                                item.totalPercepcion = floatToAmount(parseFloat(item.totalPercepcion).toFixed(2));
                                item.totalDeduccion = floatToAmount(parseFloat(item.totalDeduccion).toFixed(2));
                                item.totalLiquido = floatToAmount(parseFloat(item.totalLiquido).toFixed(2));
                                return item;
                            })
                            setDatosTabla(parseDatos);
                            setIsLoading(false);
                            break;
                        case 404:
                            setDatosTabla([]);
                            setIsLoading(false);
                            break;
                        default:
                            setMessageAlertSyE(res.data.msg);
                            setOpenAlertSyE(true);
                            setIsLoading(false);
                            break;
                    }
                })
                .catch(error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                    setIsLoading(false);
                });
            } else {
                const request = {};
                if(empleado_num) request.empleadoNum = parseInt(empleado_numRef.current.value);
                if(empleado_nombre) request.empleadoNombre = empleado_nombreRef.current.value;
                if(empleado_rfc) request.rfc = empleado_rfcRef.current.value;
                if(empleado_nss) request.empleadoNss = empleado_nssRef.current.value;
                if(anio_periodo) request.anioPeriodo = parseInt(anio_periodoRef.current.value);
                if(fec_ingreso) request.fecIngreso = fec_ingresoRef.current.value;
                if(fec_vigencia) request.fecVigencia = fec_vigenciaRef.current.value;
                if(proyecto) request.proyecto = proyectoRef.current.value;
                if(plaza) request.plaza = parseInt(plazaRef.current.value);
                if(categoria_puesto) request.categoriaPuesto = categoria_puestoRef.current.value;
    
                axios.post(`${servicios_grupohit_rh}/historico/`, request, headersAuth()) 
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const parseDatos = res.data.dataResponse.map(item => {
                                item.totalPercepcion = floatToAmount(parseFloat(item.totalPercepcion).toFixed(2));
                                item.totalDeduccion = floatToAmount(parseFloat(item.totalDeduccion).toFixed(2));
                                item.totalLiquido = floatToAmount(parseFloat(item.totalLiquido).toFixed(2));
                                return item;
                            })
                            setDatosTabla(parseDatos);
                            setIsLoading(false);
                            break;
                        case 404:
                            setDatosTabla([]);
                            setIsLoading(false);
                            break;
                        default:
                            setMessageAlertSyE(res.data.msg);
                            setOpenAlertSyE(true);
                            setIsLoading(false);
                            break;
                    }
                })
                .catch(error => {
                    setMessageAlertSyE(verifique_conexion);
                    setOpenAlertSyE(true);
                    setIsLoading(false);
                });
            }
        }
    };

    return (
        <Fragment>
            <form id="FormHistorico" onSubmit={buscar}>
                <div className="row row-cols-4 ml-auto mr-auto">
                    {(arrayChecked.map((check, i) => (
                        <div key={i} className="col">
                            <CheckboxHistorico9719
                                checked={check.checked}
                                name={check.name}
                                label={check.label}
                                inputType={check.inputType}
                                inputRef={check.inputRef}
                                handleChange={handleChange}
                                onlyLetters={check.onlyLetters}
                                onlyNumbers={check.onlyNumbers}
                                alfanumeric={check.alfanumeric}
                            />
                        </div>
                    )))}
                </div>
                <Button variant="contained" className="btn-color" type="submit">Buscar</Button>
            </form>
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
export default FormBusquedaHistorico9719;