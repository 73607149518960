import axios from 'axios';
import {
    GUARDA_EMPLEADOS_ALL, GUARDA_EMPLEADOS_ACTIVOS, GUARDA_EMPLEADOS_INACTIVOS, EDITA_EMPLEADO_INACTIVO,
    BORRA_EMPLEADOS_ALL, BORRA_EMPLEADOS_ACTIVOS, BORRA_EMPLEADOS_INACTIVOS, AGREGA_EMPLEADO, EDITA_EMPLEADO, BAJA_EMPLEADO, ELIMINA_EMPLEADO, REINGRESO_EMPLEADO, CANCELA_BAJA_EMPLEADO,
    GUARDA_EMPLEADOS_ALL_ERROR, GUARDA_EMPLEADOS_ACTIVOS_ERROR, GUARDA_EMPLEADOS_INACTIVOS_ERROR, CAMBIOS_ESCALAFON_DEFINITIVO, TERMINA_ESCALAFON,
    DESHACER_REINGRESO, CAMBIOS_PERMUTA_TRANSFERENCIA, ACTUALIZA_EMPLEADO_ESPECIALES, BORRA_EMP_PAGOS_PENDIENTES, MUESTRA_EMP_PAGOS_PENDIENTES
} from '../actionTypes';
import {
    servicios_grupohit_rh, registro_actualizado, registro_no_guardado, registro_no_eliminado, cambios_no_guardados, servicios_grupohit_org, verifique_conexion
} from '../../Constants/Constants';
import { headersAuth } from '../../Services/AuthService';
import { inputDateToLong } from '../../Services/Validation/HelperValidation';


export const borrarEmpleadosActivos = () => dispatch => {
    dispatch({
        type: BORRA_EMPLEADOS_ACTIVOS,
        payload: null
    });
};

export const borrarEmpleadosInactivos = () => dispatch => {
    dispatch({
        type: BORRA_EMPLEADOS_INACTIVOS,
        payload: null
    });
};

export const borrarEmpleadosAll = () => dispatch => {
    dispatch({
        type: BORRA_EMPLEADOS_ALL,
        payload: null
    });
};

export const obtenerEmpleadosActivos = action => async dispatch => {
    await axios.get(`${servicios_grupohit_rh}/empleado/activos/`, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                dispatch({
                    type: GUARDA_EMPLEADOS_ACTIVOS,
                    payload: res.data.dataResponse
                });
                break;
            default:
                action(`Empleados: ${res.data.msg}`);
                dispatch({
                    type: GUARDA_EMPLEADOS_ACTIVOS,
                    payload: []
                });
                break;
        }
    })
    .catch(error => {
        action(verifique_conexion);
        dispatch({
            type: GUARDA_EMPLEADOS_ACTIVOS_ERROR,
        });
    });
};

export const obtenerEmpleadosAll = action => dispatch => {
    axios.get(`${servicios_grupohit_rh}/empleado/all/`, headersAuth()) 
    .then(res => {
        switch(res.data.status) {
            case 200:
                dispatch({
                    type: GUARDA_EMPLEADOS_ALL,
                    payload: res.data.dataResponse
                });
                break;
            default:
                action(res.data.msg);
                dispatch({
                    type: GUARDA_EMPLEADOS_ALL,
                    payload: []
                });
                break;
        }
    })
    .catch(error => {
        action(verifique_conexion);
        dispatch({
            type: GUARDA_EMPLEADOS_ALL_ERROR
        });
    });
};

export const obtenerEmpleadosInactivos = action => dispatch => {
    axios.get(`${servicios_grupohit_rh}/empleado/inactivos/all/`, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                dispatch({
                    type: GUARDA_EMPLEADOS_INACTIVOS,
                    payload: res.data.dataResponse
                });
                break;
            default:
                action(res.data.msg);
                dispatch({
                    type: GUARDA_EMPLEADOS_INACTIVOS,
                    payload: []
                });
                break;
        }
    })
    .catch(error => {
        action(verifique_conexion);
        dispatch({
            type: GUARDA_EMPLEADOS_INACTIVOS_ERROR,
            empleadosAll: []
        });
    });
};

export const nuevoEmpleado = ({
    reingresoRH, setIsLoading, datosEmpleado, fechaIngreso, datosPuesto, datosZonaPago, datosCPresupuestal, datosTurno, datosNomina,
    datosPlaza, formasPagoSelected, setFormasPagoSelected, empPuestoFecIni, infoSindicato, infoPresMinLiquidez, depEconomicosSaved, pensionesAlimSaved,
    llamadasEmergenciaSaved, domicilio, datosEscolaridad, areaNom, infoFotografia, empleadoIndicadores, empleadoContrato,
    empFecAgui, empNombramiento, expLaboralSaved, pasatiempoSaved, regPatId, nota, agregar, setOpenAdd, errorTable,
    ejecutaBitacora10, titularPlazaClave, pagosPendientes, retienePagos, plazaDisponibleRango
}) => dispatch => {
    setIsLoading(true);
    const empleado = datosEmpleado;

    axios.post( `${servicios_grupohit_rh}/empleado/`, empleado, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                const servicios = async () => {

                    const errores = [];

                    const antiguedad = { empleadoid: res.data.dataResponse.id, empantfecini: fechaIngreso }
                    await axios.post(`${servicios_grupohit_rh}/empleado/antiguedad/`, antiguedad, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errores.push(`Antigüedad ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => errores.push(`Antigüedad ${registro_no_guardado}`));
    
                    const zonaPago = { empId: res.data.dataResponse.id, domciaId: datosZonaPago.domciaId, empzpfecini: datosZonaPago.empzpfecini }
                    await axios.post( `${servicios_grupohit_rh}/empleado/zonapago/`, zonaPago, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errores.push(`Zona de Pago ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => errores.push(`Zona de Pago ${registro_no_guardado}`));
    
                    const presupuestal = { empleadoid: res.data.dataResponse.id, cpresupuestalid: datosCPresupuestal.cpresupuestalid, empcpresfecini: datosCPresupuestal.empcpresfecini }
                    await axios.post( `${servicios_grupohit_rh}/empleado/presupuestal/`, presupuestal, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errores.push(`Clave Presupuestal ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => errores.push(`Clave Presupuestal ${registro_no_guardado}`));
    
                    const turno = { empId: res.data.dataResponse.id, turnoId: datosTurno.turnoId, fecini: datosTurno.fecini }
                    await axios.post( `${servicios_grupohit_rh}/empleado/turno/`, turno, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errores.push(`Turno ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => errores.push(`Turno ${registro_no_guardado}`));
    
                    const nomina = { empleadoid: res.data.dataResponse.id, nominaid: datosNomina.nominaid, empnominafecini: datosNomina.empnominafecini }
                    await axios.post( `${servicios_grupohit_rh}/empleado/nomina/`, nomina, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errores.push(`Nómina ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => errores.push(`Nómina ${registro_no_guardado}`));
    
                    const plaza = { empId: res.data.dataResponse.id, plazaId: datosPlaza.plazaId, empFecIni: datosPlaza.empFecIni };
                    await axios.post( `${servicios_grupohit_rh}/empleado/plaza/`, plaza, headersAuth())
                    .then(respuesta => {
                        switch(respuesta.data.status) {
                            case 200:
                                const actualizaTitular = async () => {
                                    await axios.put(`${servicios_grupohit_org}/plaza/empleadotitular/cambio/${titularPlazaClave}`, { emp: res.data.dataResponse.id }, headersAuth())
                                    .then(respuesta2 => {
                                        switch(respuesta2.data.status) {
                                            case 200:
                                                break;
                                            default:
                                                errores.push(`Titular de la Plaza ${respuesta2.data.msg}`);
                                                break;
                                        }
                                    })
                                    .catch(error =>errores.push(`Titular de la Plaza ${registro_no_guardado}`))
                                };
                                
                                if(titularPlazaClave) {
                                    actualizaTitular();
                                }
                                break;
                            default:
                                errores.push(`Plaza ${respuesta.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => errores.push(`Plaza ${registro_no_guardado}`));
    
                    const formasPago = formasPagoSelected.map(fPago => ({ empleado: res.data.dataResponse.id, formapago: fPago.formapago }));
                    await axios.post(`${servicios_grupohit_rh}/empleado/formapago/`, formasPago, headersAuth())
                    .then(respuesta => {
                        switch(respuesta.data.status) {
                            case 200:
                                if(formasPagoSelected.length){
                                    for(let i = 0; i < formasPagoSelected.length; i++) {
                                        if(formasPagoSelected[i].formapago > 2 && formasPagoSelected[i].formapago < 10) {
                                            const array = respuesta.data.dataResponse.map(item => ({ id: item.id, formapago: item.formapago.id }));
                                            const id = array.find(res => res.formapago === formasPagoSelected[i].formapago );
                                            let cuenta = {
                                                            empleadoFormaPago: id.id,
                                                            formaPago: formasPagoSelected[i].formapago,
                                                            banco: formasPagoSelected[i].bancoId
                                                        };
                                            if(formasPagoSelected[i].cbeInterbancaria) cuenta.clabe = formasPagoSelected[i].cbeInterbancaria;
                                            if(formasPagoSelected[i].numCuenta) cuenta.nocuenta = formasPagoSelected[i].numCuenta;
    
                                            axios.post(`${servicios_grupohit_rh}/empleado/cuentahabiente/`, cuenta, headersAuth())
                                            .then(res => {
                                                switch(res.data.status) {
                                                    case 200:
                                                        setFormasPagoSelected([ ...formasPagoSelected, 
                                                            { 
                                                                idCuentahabiente: res.data.dataResponse.id,
                                                                idFormaPago: res.data.dataResponse.empleadoFormaPago.id,
                                                                formapago: res.data.dataResponse.empleadoFormaPago.formapago.id,
                                                                formaPagoNom: res.data.dataResponse.empleadoFormaPago.formapago.formapagoNom,
                                                                bancoId: res.data.dataResponse.banco.id,
                                                                banco: res.data.dataResponse.banco.bancoNom,
                                                                cbeInterbancaria: res.data.dataResponse.clabe,
                                                                numCuenta: res.data.dataResponse.nocuenta
                                                            }
                                                        ])         
                                                        break;
                                                    default:
                                                        errores.push(`Forma de Pago / Cuenta ${respuesta.data.msg}`);
                                                        break;
                                                }
                                            })
                                            .catch(error => { console.log(error); errores.push(verifique_conexion) });
                                        }
                                    }
                                }
    
                                break;
                            default:
                                errores.push(`Forma de Pago ${respuesta.msg.data}`);
                                break;
                        }
                    })
                    .catch(error => { console.log(error); errores.push(verifique_conexion) });
    
                    const status = { empId: res.data.dataResponse.id, empPuestoFecIni };
                    await axios.post( `${servicios_grupohit_rh}/empleado/status/`, status, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errores.push(`Status ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => errores.push(`Status ${registro_no_guardado}`));
    
                    if(empleadoIndicadores) {
                        await axios.post(`${servicios_grupohit_rh}/empleado/indicadores/`, { Empleado: [{ EmpleadoId: res.data.dataResponse.id, Indicadores:empleadoIndicadores }] }, headersAuth())
                        .then(respuesta => {
                            switch(respuesta.data.status) {
                                case 200:
                                    const puesto = { empId: res.data.dataResponse.id, puestoId: datosPuesto.puestoId, empPuestoFecIni: datosPuesto.empPuestoFecIni }
                                    axios.post(`${servicios_grupohit_rh}/empleado/puesto/`, puesto, headersAuth())
                                    .then(resPuesto => {
                                        switch(resPuesto.data.status) {
                                            case 200:
                                                break;
                                            default:
                                                errores.push(`Puesto ${resPuesto.data.msg}`);
                                                break;
                                        }
                                    })
                                    .catch(error => errores.push(`Puesto ${registro_no_guardado}`));
                                    break;
                                default:
                                    errores.push(`Indicadores ${respuesta.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Indicadores ${registro_no_guardado}`));
                    }
    
                        //agrega tipo de Contrato
                    if(empleadoContrato !== null) {
                        const info = { ...empleadoContrato, empId: res.data.dataResponse.id };
                        await axios.post(`${servicios_grupohit_rh}/empleado/contrato/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Contrato ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Contrato ${registro_no_guardado}`));
                    }
                        
                        //agrega fecha de aguinaldo
                    if(empFecAgui !== null) {
                        const info = { empId: res.data.dataResponse.id, empFecAgui: empFecAgui };
                        await axios.post(`${servicios_grupohit_rh}/empleado/aguinaldo/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Fecha de Aguinaldo ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Fecha de Aguinaldo ${registro_no_guardado}`));
                    }
                        
                        //agrega fecha de nombramiento
                    if(empNombramiento !== null) {
                        const info = { empId: res.data.dataResponse.id, empFecNom: empNombramiento.empFecNom, empNomFecFin: empNombramiento.empFecFinNom };
                        await axios.post(`${servicios_grupohit_rh}/empleado/nombramiento/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Fecha de Nombramiento ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Fecha de Nombramiento ${registro_no_guardado}`));
                    }
    
                        //agrega reg patronal
                    if(regPatId !== null) {
                        const requestRegPatronal = { empId: res.data.dataResponse.id, regPatId };
                        await axios.post(`${servicios_grupohit_rh}/empleado/registropat/`, requestRegPatronal, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Registro Patronal ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Registro Patronal ${registro_no_guardado}`));
                    }
    
                        //agrega sindicato
                    if(infoSindicato !== null) {
                        const requestSindicato = { ...infoSindicato, empId: res.data.dataResponse.id };
                        await axios.post(`${servicios_grupohit_rh}/empleado/sindicatos/`, requestSindicato, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Sindicato ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Sindicato ${registro_no_guardado}`));
                    }

                    const requestLiquidez = { 
                        empId: res.data.dataResponse.id,
                        minimo: infoPresMinLiquidez
                    };
                    await axios.post(`${servicios_grupohit_rh}/empleado/liquidez/`, requestLiquidez, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errores.push(`Préstamo ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Préstamo ${registro_no_guardado}`);
                    });
    
                    if(nota !== null && nota.trim() !== '') {
                        const request = {
                            empId: res.data.dataResponse.id,
                            nota: nota.trim()
                        };
                        await axios.post(`${servicios_grupohit_rh}/empleado/notas/`, request, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Notas ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => {
                            errores.push(`Notas ${registro_no_guardado}`);
                        });
                    }
    
                        //agrega dependientes económicos
                    if(depEconomicosSaved.length > 0) {
                        const requestDepEcon = depEconomicosSaved.map(depEcon => ({
                            ...depEcon,
                            empleado: res.data.dataResponse.id
                        }));
                        await axios.post( `${servicios_grupohit_rh}/empleado/dependiente/economico/`, requestDepEcon, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Dependientes Económicos ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Dependientes Económicos ${registro_no_guardado}`));
                    }
    
                        //agrega pensiones alimenticias
                    if(pensionesAlimSaved.length > 0) {
                        const pensiones = pensionesAlimSaved.map(penAlim => (
                            (penAlim.fechaFin)
                            ?   {
                                    folio: penAlim.folio,
                                    empleado: res.data.dataResponse.id,
                                    nombre: penAlim.nombre,
                                    apellidoPat: penAlim.apellidoPat,
                                    apellidoMat: penAlim.apellidoMat,
                                    tipoCuota: penAlim.tipoCuota,
                                    montoPorc: penAlim.montoPorc,
                                    fechaIni: penAlim.fechaIni,
                                    fechaFin: penAlim.fechaFin,
                                    prioridad: penAlim.prioridad
                                }
                            :   {
                                    folio: penAlim.folio,
                                    empleado: res.data.dataResponse.id,
                                    nombre: penAlim.nombre,
                                    apellidoPat: penAlim.apellidoPat,
                                    apellidoMat: penAlim.apellidoMat,
                                    tipoCuota: penAlim.tipoCuota,
                                    montoPorc: penAlim.montoPorc,
                                    fechaIni: penAlim.fechaIni,
                                    prioridad: penAlim.prioridad
                                }
                        ));

                        await axios.post(`${servicios_grupohit_rh}/empleado/pension/`, pensiones, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:                                    
                                    break;
                                default:
                                    errores.push(`Pensiones Alimenticias ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Pensiones Alimenticias ${registro_no_guardado}`));
                    }
    
                        //agrega números de emergencia
                    if(llamadasEmergenciaSaved.length > 0) {
                        const numEmergencia = llamadasEmergenciaSaved.map(numEme => (
                            (numEme.calle && numEme.numeroExt && numEme.numeroInt)
                            ?   {
                                    empleado: res.data.dataResponse.id,
                                    nombre: numEme.nombre,
                                    apellidoPat: numEme.apellidoPat,
                                    apellidoMat: numEme.apellidoMat,
                                    telefonoFijo: numEme.telefonoFijo,
                                    telefonoCelular: numEme.telefonoCelular,
                                    parentesco: numEme.parentesco,
                                    cp: numEme.coloniaId,
                                    calle: numEme.calle,
                                    numeroExt: numEme.numeroExt,
                                    numeroInt: numEme.numeroInt
                                }
                            :   (numEme.calle && numEme.numeroExt)
                                ?   {
                                        empleado: res.data.dataResponse.id,
                                        nombre: numEme.nombre,
                                        apellidoPat: numEme.apellidoPat,
                                        apellidoMat: numEme.apellidoMat,
                                        telefonoFijo: numEme.telefonoFijo,
                                        telefonoCelular: numEme.telefonoCelular,
                                        parentesco: numEme.parentesco,
                                        cp: numEme.coloniaId,
                                        calle: numEme.calle,
                                        numeroExt: numEme.numeroExt
                                    }
                                :   (numEme.calle)
                                    ?   {
                                            empleado: res.data.dataResponse.id,
                                            nombre: numEme.nombre,
                                            apellidoPat: numEme.apellidoPat,
                                            apellidoMat: numEme.apellidoMat,
                                            telefonoFijo: numEme.telefonoFijo,
                                            telefonoCelular: numEme.telefonoCelular,
                                            parentesco: numEme.parentesco,
                                            cp: numEme.coloniaId,
                                            calle: numEme.calle
                                        }
                                    :   {
                                            empleado: res.data.dataResponse.id,
                                            nombre: numEme.nombre,
                                            apellidoPat: numEme.apellidoPat,
                                            apellidoMat: numEme.apellidoMat,
                                            telefonoFijo: numEme.telefonoFijo,
                                            telefonoCelular: numEme.telefonoCelular,
                                            parentesco: numEme.parentesco,
                                            cp: numEme.coloniaId
                                        }
                        ));
    
                        await axios.post(`${servicios_grupohit_rh}/empleado/llamada/emergencia/`, numEmergencia, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:                                    
                                    break;
                                default:
                                    errores.push(`Números de Emergencia ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Números de Emergencia ${registro_no_guardado}`));
                    }
                        
                        //agrega domicilio
                    if(domicilio.calle.length > 2 && domicilio.numext.length > 0 && domicilio.cpId > 0) {
                        const domicilioSend = { empId: res.data.dataResponse.id, calle: domicilio.calle, numint: domicilio.numint, numext: domicilio.numext, cpId: domicilio.cpId };
                        await axios.post(`${servicios_grupohit_rh}/empleado/domicilio/`, domicilioSend, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:                                    
                                    break;
                                default:
                                    errores.push(`Domicilio ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Domicilio ${registro_no_guardado}`));
                    }
    
                        //agrega escolaridad 
                    if(datosEscolaridad.escolaridad) {
                        let escolaridadSend = { empleado: res.data.dataResponse.id, escolaridad: datosEscolaridad.escolaridad };
                        
                        if(datosEscolaridad.empleadoCarrera.length > 2) {
                            escolaridadSend = { ...escolaridadSend, empleadoCarrera: datosEscolaridad.empleadoCarrera };
                        }
                            
                        if(datosEscolaridad.empleadoCedula.length > 2) {
                            escolaridadSend = { ...escolaridadSend, empleadoCedula: datosEscolaridad.empleadoCedula };
                        }
    
                        await axios.post(`${servicios_grupohit_rh}/empleado/escolaridad/`, escolaridadSend, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:                                    
                                    break;
                                default:
                                    errores.push(`Escolaridad ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Escolaridad ${registro_no_guardado}`));
                    }
    
                    if(infoFotografia) {
                        let info = infoFotografia;
                        info = { ...info, empleado: res.data.dataResponse.id };
                        await axios.post(`${servicios_grupohit_rh}/documentos/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status){
                                case 200:
                                    break;
                                default:
                                    errores.push(res.data.msg);
                                    break;
                            }
                        })
                        .catch( error => errores.push(registro_no_guardado));
                    }
    
                    if(expLaboralSaved.length > 0) {
                        const agregaIDEmpleado = expLab => {
                            const arrayExpLaboral = expLab.map(expL => (
                                {
                                    empId: res.data.dataResponse.id,
                                    empNom: expL.empNom,
                                    puestoExp: expL.puestoExp,
                                    dmes: expL.dmes,
                                    danio: expL.danio,
                                    hmes: expL.hmes,
                                    hanio: expL.hanio,
                                    jefe: expL.jefe,
                                    tel: expL.tel
                                }                        
                            ));
                                
                            return arrayExpLaboral;
                        };
    
                        const info = agregaIDEmpleado(expLaboralSaved);
                        await axios.post(`${servicios_grupohit_rh}/empleado/experiencia/`, { empleados: info }, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Experiencia Laboral ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Experiencia Laboral ${registro_no_guardado}`));
                    }
    
                    if(pasatiempoSaved.length > 0) {
                        const convierteArray = pasatiempos => {
                            return pasatiempos.map(pasa => pasa.id); 
                        };
    
                        const arrayPasatiempos = convierteArray(pasatiempoSaved);
    
                        const info = { empId: res.data.dataResponse.id, pasatiempos: arrayPasatiempos };
                        await axios.post(`${servicios_grupohit_rh}/empleado/pasatiempo/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errores.push(`Pasatiempo ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => errores.push(`Pasatiempo ${registro_no_guardado}`));
                    }

                    if(reingresoRH) {
                        await servicioBitacora({ empleadoId: res.data.dataResponse.id, tipoMovId: 6 });
                    } else {
                        await servicioBitacora({ empleadoId: res.data.dataResponse.id, tipoMovId: 1 });
                    }
                    if(ejecutaBitacora10 === true) {
                        await servicioBitacora({ empleadoId: res.data.dataResponse.id, tipoMovId: 10 });
                    }
    
                    const element = {
                        id: res.data.dataResponse.id,
                        areaNom: areaNom,
                        ciaRazonSocial: datosCPresupuestal.ciaRazonSocial,
                        cpresupuestalClave: datosCPresupuestal.cpresupuestalclave,
                        cpresupuestalNom: datosCPresupuestal.cpresupuestalNom,
                        empleadoClave: res.data.dataResponse.empleadoclave,
                        nombreCompleto: res.data.dataResponse.nombreCompleto,
                        empleadoNom: res.data.dataResponse.empleadonom,
                        empleadoPat: res.data.dataResponse.empleadopat,
                        empleadoMat: res.data.dataResponse.empleadomat,
                        empleadoFecnac: res.data.dataResponse.empleadofecnac,
                        empleadoCurp: res.data.dataResponse.empleadocurp,
                        empleadoRfc: res.data.dataResponse.empleadorfc,
                        empleadoNss: res.data.dataResponse.empleadonss,
                        empantFecini: fechaIngreso.empantfecini,
                        empstatusFecini: empPuestoFecIni,
                        nominaClave: datosNomina.nominaClave,
                        nominaNomCorto: datosNomina.nominaNomCorto,
                        nominaNombre: datosNomina.nominaNom,
                        puestoId: datosPuesto.puestoId,
                        puestoCve: datosPuesto.puestoCve,
                        puestoNom: datosPuesto.puestoNom,
                        status: 'ACTIVO',
                        tipojornadaClave: datosTurno.jornadaClave,
                        tipojornadaNom: datosTurno.jornadaNombre,
                        plazaId: datosPlaza.plazaId,
                        plazaClave: datosPlaza.plazaclave,
                        turnoId: datosTurno.turnoId,
                        turnoNom: datosTurno.turnoNom,
                        domciaId: datosZonaPago.domciaId,
                        domciaCalle: datosZonaPago.domciaCalle,
                        domciaNumext: datosZonaPago.domciaNumext,
                        domciaNumint: datosZonaPago.zonaPagoNumInt
                    };

                    if(plazaDisponibleRango) {
                        const infoBaja = {
                            motivoBajaId: 47,
                            empPuestoFecfin: plazaDisponibleRango,
                            empId: res.data.dataResponse.id,
                            motBaja: 'BAJA POR ACTUALIZACIÓN'
                        };
                        await axios.put(`${servicios_grupohit_rh}/empleado/status/`, infoBaja, headersAuth())
                        .then(res =>  {
                            switch(res.data.status) {
                                case 200:
                                    const ejecutaBaja = async () => {
                                        await servicioBitacora({ empleadoId: res.data.dataResponse.id, tipoMovId: 2 });
                                        await dispatch({
                                            type: BAJA_EMPLEADO,
                                            payload: element
                                        });
                                    };
                                    ejecutaBaja();
                                    break;
                                default:
                                    errores.push(`Baja ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => { console.log(error); errores.push(`Baja ${registro_no_guardado}`);});
                    }

                    if(errores.length) {
                        errores.unshift('El empleado fue agregado con errores:');
                        await errorTable(errores);
                    } else {
                        await agregar(element);
                    }

                    if(!plazaDisponibleRango) {
                        await dispatch({
                            type: AGREGA_EMPLEADO,
                            payload: { element, pagosPendientes, retienePagos }
                        });
                    }
                    
                    await setIsLoading(false);
                    await setOpenAdd(false);
                };
                servicios();
                break;
            default:
                errorTable(res.data.msg);
                setIsLoading(false);
                break;
        }
    })
    .catch(error => {
        console.log(error);
        errorTable(verifique_conexion);
        setIsLoading(false);
    });
};

export const editarEmpleado = (modulo, elementSelect, infoEmp, infoEmpleado, setInfoEmpleado, editar, errorTable, setIsLoading) => dispatch => {
    setIsLoading(true);
    axios.put(`${servicios_grupohit_rh}/empleado/`, infoEmp, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                setInfoEmpleado({
                    ...infoEmpleado,
                    empleado: {
                        id: elementSelect.id,
                        edoCivil: res.data.dataResponse.edoCivil,
                        empleadoGenero: res.data.dataResponse.empleadoGenero,
                        empleadoclave: res.data.dataResponse.empleadoclave,
                        empleadocurp: res.data.dataResponse.empleadocurp,
                        empleadofecnac: res.data.dataResponse.empleadofecnac,
                        empleadonom: res.data.dataResponse.empleadonom,
                        empleadopat: res.data.dataResponse.empleadopat,
                        empleadomat: res.data.dataResponse.empleadomat,
                        nombreCompleto: res.data.dataResponse.nombreCompleto,
                        empleadonss: res.data.dataResponse.empleadonss,
                        empleadorfc: res.data.dataResponse.empleadorfc,
                        estado: res.data.dataResponse.estado,
                        folioAlta: res.data.dataResponse.folioAlta,
                        equipo: res.data.dataResponse.equipo,
                        esMadre: res.data.dataResponse.esMadre,
                    }
                });

                elementSelect.empleadoClave = res.data.dataResponse.empleadoclave;
                elementSelect.nombreCompleto = res.data.dataResponse.nombreCompleto;
                elementSelect.empleadoNom = res.data.dataResponse.empleadonom;
                elementSelect.empleadoPat = res.data.dataResponse.empleadopat;
                elementSelect.empleadoMat = res.data.dataResponse.empleadomat;
                elementSelect.empleadoFecnac = res.data.dataResponse.empleadofecnac;
                elementSelect.empleadoCurp = res.data.dataResponse.empleadocurp;
                elementSelect.empleadoRfc = res.data.dataResponse.empleadorfc;
                elementSelect.empleadoNss = res.data.dataResponse.empleadonss;

                editar(elementSelect);

                if(modulo === 30) {
                    dispatch({
                        type: EDITA_EMPLEADO,
                        payload: elementSelect
                    });
                };

                if(modulo === 32) {
                    dispatch({
                        type: EDITA_EMPLEADO_INACTIVO,
                        payload: elementSelect
                    });
                };
                break;
            default:
                errorTable(res.data.msg);
                break;
        }
    })
    .catch(error => { errorTable(cambios_no_guardados); })
    .finally(() => setIsLoading(false));
};

export const editarEmpleadoOrganizacion = ({
    info, modulo, elementSelect, selected, zonasPago, turnos, nominas, indicadoresSelected, sindicatoSeleccionado,
    cuotaSindical, infoContrato, infoAguinaldo, infoNombramiento, infoRegPatronal, infoSindicato, minLiquidez, infoMinLiquidez,
    setInfoContrato, setInfoAguinaldo, setInfoRegPatronal, setInfoNombramiento, setInfoSindicato, setInfoMinLiquidez,
    contratoRef, fechaInicialContratoRef, fechaFinalContratoRef, fechaAguinaldoRef, fechaNombramientoRef, regPatronalRef,
    editar, successTable, errorTable, cerrarModal, setIsLoading, ejecutaBitacora10, setNombramientoEditInicial,
    infoTitularPlaza, titularPlaza, setTitularPlaza, setRecargarInfo, pagosPendientes, retienePagos, plazaDisponibleRango
}) => dispatch => {
    let errores = [];
    
    const servicios = async () => {
        await setIsLoading(true);
        if(info) {
            if(modulo === 30) {
                if(info.plaza) {
                    await axios.put(`${servicios_grupohit_rh}/empleado/presupuestal/`, info.cPresupuestal, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const servicios = async () => {
                                    await servicioBitacora({ empleadoId: elementSelect.id, tipoMovId: 5 });
                                    elementSelect.ciaRazonSocial = selected.uResponsable.nombre;
                                    elementSelect.cpresupuestalClave = selected.cPresupuestal.clave;
                                    elementSelect.cpresupuestalNom = selected.cPresupuestal.nombre;
                                    await dispatch({
                                        type: EDITA_EMPLEADO,
                                        payload: elementSelect
                                    });
                                    await axios.put(`${servicios_grupohit_rh}/empleado/plaza/`, info.plaza, headersAuth())
                                    .then(res => {
                                        switch(res.data.status) {
                                            case 200:
                                                servicioBitacora({ empleadoId: elementSelect.id, tipoMovId: 4 });
                                                elementSelect.plazaId = info.plaza.id;
                                                elementSelect.plazaClave = selected.plaza.clave;
                                                elementSelect.areaNom = selected.departamento.nombre
                                                dispatch({
                                                    type: EDITA_EMPLEADO,
                                                    payload: elementSelect
                                                });
                                                break;
                                            default:
                                                errores.push(`Plaza: ${res.data.msg}`);
                                                break;
                                        }
                                    })
                                    .catch(error => {
                                        errores.push(`Plaza: ${registro_no_guardado}`);
                                        console.log(error);
                                    });
                                    
                                    if(info.zonaPago) {
                                        await axios.put(`${servicios_grupohit_rh}/empleado/zonapago/`, info.zonaPago, headersAuth())
                                        .then(res => {
                                            switch(res.data.status) {
                                                case 200:
                                                    const infoZonaPago = zonasPago.find(item => item.id === info.zonaPago.domciaId);
                                                    elementSelect.domciaId = infoZonaPago.id;
                                                    elementSelect.domciaCalle = infoZonaPago.domCiaCalle;
                                                    elementSelect.domciaNumext = infoZonaPago.domCiaExt;
                                                    elementSelect.domciaNumint = infoZonaPago.domCiaNumInt;
                                                    dispatch({
                                                        type: EDITA_EMPLEADO,
                                                        payload: elementSelect
                                                    });
                                                    break;
                                                default:
                                                    errores.push(`Zona de Pago: ${res.data.msg}`);
                                                    break;
                                            }
                                        })
                                        .catch(error => {
                                            errores.push(`Zona de Pago: ${registro_no_guardado}`)
                                            console.log(error);
                                        });
                                    }
                                };
                                servicios();
                                break;
                            default:
                                errores.push(`Clave Presupuestal: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Clave Presupuestal: ${registro_no_guardado}`);
                        console.log(error);
                    });
                } 
                if(info.puesto) {
                    await axios.put(`${servicios_grupohit_rh}/empleado/puesto/`, info.puesto, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const ejecuta = async () => {
                                    await axios.post(`${servicios_grupohit_rh}/empleado/indicadores/`, { Empleado: [{ EmpleadoId: elementSelect.id, Indicadores: indicadoresSelected }] }, headersAuth())
                                    .then(res => {
                                        switch(res.data.status) {
                                            case 200:
                                                break;
                                            default:
                                                errores.push(`Indicadores ${res.data.msg}`);
                                                break;
                                        }
                                    })
                                    .catch(error => errores.push(`Indicadores ${registro_no_guardado}`));
                                    servicioBitacora({ empleadoId: elementSelect.id, tipoMovId: 3 });
                                    elementSelect.puestoId = selected.puesto.id;
                                    elementSelect.puestoCve = selected.puesto.clave;
                                    elementSelect.puestoNom = selected.puesto.nombre;
                                    dispatch({
                                        type: EDITA_EMPLEADO,
                                        payload: elementSelect
                                    });
                                };
                                ejecuta();
                                break;
                            default:
                                errores.push(`Puesto: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Puesto: ${registro_no_guardado}`);
                        console.log(error);
                    });
                }
                if(info.turno) {
                    await axios.put(`${servicios_grupohit_rh}/empleado/turno/`, info.turno, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const infoTurnos = turnos.find(item => item.id === info.turno.turnoId);
                                elementSelect.turnoId = infoTurnos.id;
                                elementSelect.turnoNom = infoTurnos.turnoNom;
                                dispatch({
                                    type: EDITA_EMPLEADO,
                                    payload: elementSelect
                                });
                                break;
                            default:
                                errores.push(`Turno: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Turno: ${registro_no_guardado}`);
                        console.log(error);
                    });
                }
                if(info.nomina) {
                    await axios.put(`${servicios_grupohit_rh}/empleado/nomina/`, info.nomina, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const infoNominas = nominas.find(item => item.id === info.nomina.nominaid);
                                elementSelect.nominaClave = infoNominas.nominaClave;
                                elementSelect.nominaNomCorto = infoNominas.nominaNomCorto;
                                elementSelect.nominaNombre = infoNominas.nominaNombre;
                                dispatch({
                                    type: EDITA_EMPLEADO,
                                    payload: elementSelect
                                });
                                break;
                            default:
                                errores.push(`Nómina: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Nómina: ${registro_no_guardado}`);
                        console.log(error);
                    });
                }
            }
            if(modulo === 32) {
                const servicioPuesto = async () => {
                    if(info.info.puesto) {
                        await axios.post(`${servicios_grupohit_rh}/empleado/puesto/`, info.info.puesto, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    elementSelect.puestoId = info.info.puesto.puestoId;
                                    elementSelect.puestoCve = selected.puesto.clave;
                                    elementSelect.puestoNom = selected.puesto.nombre;
                                    dispatch({
                                        type: EDITA_EMPLEADO,
                                        payload: elementSelect
                                    });
                                    break;
                                default:
                                    errores.push(`Puesto: ${res.data.msg}`);
                                    break;
                            }
                        })
                        .catch(error => {
                            errores.push(`Puesto: ${registro_no_guardado}`);
                        });
                    }
                };
                await axios.post(`${servicios_grupohit_rh}/empleado/indicadores/`, { Empleado: [{ EmpleadoId: elementSelect.id, Indicadores: indicadoresSelected }] }, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            servicioPuesto();
                            break;
                        default:
                            errores.push(`Indicadores ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => errores.push(`Indicadores ${registro_no_guardado}`));
                
                if(info.info.plaza) {
                    await axios.post(`${servicios_grupohit_rh}/empleado/presupuestal/`, info.info.cPresupuestal, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const servicios = async () => {
                                    elementSelect.ciaRazonSocial = selected.uResponsable.nombre;
                                    elementSelect.cpresupuestalClave = selected.cPresupuestal.clave;
                                    elementSelect.cpresupuestalNom = selected.cPresupuestal.nombre;
                                    await dispatch({
                                        type: EDITA_EMPLEADO,
                                        payload: elementSelect
                                    });
                                    await axios.post( `${servicios_grupohit_rh}/empleado/plaza/`, info.info.plaza, headersAuth())
                                    .then(res => {
                                        switch(res.data.status) {
                                            case 200:
                                                elementSelect.plazaClave = selected.plaza.clave;
                                                elementSelect.plazaId = selected.plaza.id;
                                                dispatch({
                                                    type: EDITA_EMPLEADO,
                                                    payload: elementSelect
                                                });
                                                break;
                                            default:
                                                errores.push(`Plaza ${res.data.msg}`);
                                                break;
                                        }
                                    })
                                    .catch(error => errores.push(`Plaza ${registro_no_guardado}`));
            
                                    if(info.info.zonaPago) {
                                        await axios.post(`${servicios_grupohit_rh}/empleado/zonapago/`, info.info.zonaPago, headersAuth())
                                        .then(res => {
                                            switch(res.data.status) {
                                                case 200:
                                                    const infoZonaPago = zonasPago.find(item => item.id === info.info.zonaPago.domciaId);
                                                    elementSelect.domciaId = infoZonaPago.id;
                                                    elementSelect.domciaCalle = infoZonaPago.domCiaCalle;
                                                    elementSelect.domciaNumext = infoZonaPago.domCiaExt;
                                                    elementSelect.domciaNumint = infoZonaPago.domCiaNumInt;
                                                    dispatch({
                                                        type: EDITA_EMPLEADO,
                                                        payload: elementSelect
                                                    });
                                                    break;
                                                default:
                                                    errores.push(`Zona de Pago: ${res.data.msg}`);
                                                    break;
                                            }
                                        })
                                        .catch(error => {
                                            errores.push(`Zona de Pago: ${registro_no_guardado}`)
                                        });
                                    }
                                };
                                servicios();
                                break;
                            default:
                                errores.push(`Clave Presupuestal: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Clave Presupuestal: ${registro_no_guardado}`);
                    });
                }
                if(info.info.turno) {
                    await axios.post(`${servicios_grupohit_rh}/empleado/turno/`, info.info.turno, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const infoTurnos = turnos.find(item => item.id === info.info.turno.turnoId);
                                elementSelect.turnoId = infoTurnos.id;
                                elementSelect.turnoNom = infoTurnos.turnoNom;
                                dispatch({
                                    type: EDITA_EMPLEADO,
                                    payload: elementSelect
                                });
                                break;
                            default:
                                errores.push(`Turno: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Turno: ${registro_no_guardado}`);
                    });
                }
                if(info.info.nomina) {
                    await axios.post(`${servicios_grupohit_rh}/empleado/nomina/`, info.info.nomina, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const infoNominas = nominas.find(item => item.id === info.info.nomina.nominaid);
                                elementSelect.nominaClave = infoNominas.nominaClave;
                                elementSelect.nominaNomCorto = infoNominas.nominaNomCorto;
                                elementSelect.nominaNombre = infoNominas.nominaNombre;
                                dispatch({
                                    type: EDITA_EMPLEADO,
                                    payload: elementSelect
                                });
                                break;
                            default:
                                errores.push(`Nómina: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        errores.push(`Nómina: ${registro_no_guardado}`);
                    });
                }
                //Bitacora para el caso de Reingreso
                await servicioBitacora({ empleadoId: elementSelect.id, tipoMovId: 6 });
            };
        }
    
        //contatos
        const editarContrato = async () => {
            let request = { empId: elementSelect.id, contratoId: parseInt(contratoRef.current.value), Fecini: inputDateToLong(fechaInicialContratoRef.current.value) };
            if(fechaFinalContratoRef.current && fechaFinalContratoRef.current.value !== '') request = { ...request, Fecfin: inputDateToLong(fechaFinalContratoRef.current.value) };
            await axios.post(`${servicios_grupohit_rh}/empleado/contrato/`, request, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setInfoContrato({
                            contrato: {
                                id: request.contratoId
                            },
                            fechaini: request.Fecini,
                            fechafin: fechaFinalContratoRef.current ? (fechaFinalContratoRef.current.value !== '') ? inputDateToLong(fechaFinalContratoRef.current.value) : null : null
                        });
                        break;
                    default:
                        errores.push(`Contrato ${res.data.msg}`);
                        break;
                }
            })
            .catch(error => {
                errores.push(`Contrato ${registro_no_guardado}`);
                console.log(error);
            });
        };
        
        if(modulo === 30) {
            //Contrato
            if(
                (infoContrato.contrato.id !== parseInt(contratoRef.current.value)) ||
                (infoContrato.fechaini !== inputDateToLong(fechaInicialContratoRef.current.value)) ||
                (infoContrato.fechafin !== ((fechaFinalContratoRef.current) ? (fechaFinalContratoRef.current.value !== '') ? inputDateToLong(fechaFinalContratoRef.current.value) : null : null))
            ) {
                await editarContrato();
            }
    
        }
    
        if(modulo === 32) {
            await editarContrato();
        }
        //termina contatos
        
        //titular de la plaza
        if(((infoTitularPlaza === 'no') ? 'no' : 'si' ) !== titularPlaza) {
            const editarTitularPlaza = async () => {
                await axios.put(`${servicios_grupohit_org}/plaza/empleadotitular/cambio/${selected.plaza.id}`, (titularPlaza === 'si') ? { emp: elementSelect.id } : {}, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setTitularPlaza('si');
                            break;
                        default:
                            errores.push(`Titular de la Plaza: ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => {
                    errores.push(`Titular de la Plaza: ${registro_no_guardado}`);
                    console.log(error);
                });
            };
            
            await editarTitularPlaza();
        }

        //Aguinaldo
        if(infoAguinaldo.fechaAguinaldo !== (fechaAguinaldoRef.current.value === '' ? '' : inputDateToLong(fechaAguinaldoRef.current.value))) {
            if(fechaAguinaldoRef.current.value === '') {
                await axios.delete(`${servicios_grupohit_rh}/empleado/aguinaldo/${infoAguinaldo.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const fechaAguinaldo = {
                                id: '',
                                fechaAguinaldo: ''
                            };
                            setInfoAguinaldo(fechaAguinaldo);
                            break;
                        default:
                            errores.push(`Fecha de Aguinaldo: ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => {
                    errores.push(`Fecha de Aguinaldo: ${registro_no_guardado}`);
                    console.log(error);
                });
            } else {
                const info = {
                    empId: elementSelect.id,
                    empFecAgui: inputDateToLong(fechaAguinaldoRef.current.value)
                };
                await axios.post(`${servicios_grupohit_rh}/empleado/aguinaldo/`, info, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            if(modulo === 30) {
                                const fechaAguinaldo = {
                                    id: res.data.dataResponse.id,
                                    fechaAguinaldo: res.data.dataResponse.empFecAgui
                                };
                                setInfoAguinaldo(fechaAguinaldo);
                            }
                            break;
                        default:
                            errores.push(`Fecha de Aguinaldo: ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => {
                    errores.push(`Fecha de Aguinaldo: ${registro_no_guardado}`);
                    console.log(error);
                });
            }
        }
        //Nombramiento
        if(fechaNombramientoRef.current) {
            if(infoNombramiento.fechaNombramiento !== (fechaNombramientoRef.current.value === '' ? '' : inputDateToLong(fechaNombramientoRef.current.value))) {
                if(fechaNombramientoRef.current.value === '') {
                    await axios.delete(`${servicios_grupohit_rh}/empleado/nombramiento/${infoNombramiento.id}`, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const fechaNombramiento = {
                                    id: '',
                                    fechaNombramiento: '',
                                    fechaFinalNombramiento: ''
                                };
                                setInfoNombramiento(fechaNombramiento);
                                break;
                            default:
                                errores.push(`Fecha de Nombramiento: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Fecha de Nombramiento: ${registro_no_guardado}`);
                        console.log(error);
                    });
                } else {
                    let request = {
                        empId: elementSelect.id,
                        empFecNom: inputDateToLong(fechaNombramientoRef.current.value),
                    };
                    await axios.post(`${servicios_grupohit_rh}/empleado/nombramiento/`, request, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(modulo === 30) {
                                    const fechaNombramiento = {
                                        id: res.data.dataResponse.id,
                                        fechaNombramiento: res.data.dataResponse.empFecAgui
                                    };
                                    setInfoNombramiento(fechaNombramiento);
                                }
                                break;
                            default:
                                errores.push(`Fecha de Nombramiento: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Fecha de Nombramiento: ${registro_no_guardado}`);
                        console.log(error);
                    });
                }
            }
        }
        //Registro Patronal
        if(infoRegPatronal.regPatronal.id !== (regPatronalRef.current.value === '' ? '' : parseInt(regPatronalRef.current.value))) {
            if(regPatronalRef.current.value === '') {
                await axios.delete(`${servicios_grupohit_rh}/empleado/registropat/${infoRegPatronal.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const regPatronal = {
                                id: '',
                                regPatronal: { id: '' }
                            };
                            setInfoRegPatronal(regPatronal);
                            break;
                        default:
                            errores.push(`Registro Patronal: ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => {
                    errores.push(`Registro Patronal: ${registro_no_guardado}`);
                    console.log(error);
                });
            } else {
                if(infoRegPatronal.id === '') {
                    const requestRegPatronal = { 
                        empId: elementSelect.id,
                        regPatId: parseInt(regPatronalRef.current.value)
                    };
                    await axios.post(`${servicios_grupohit_rh}/empleado/registropat/`, requestRegPatronal, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(modulo === 30) {
                                    const regPatronal = {
                                        id: res.data.dataResponse.id,
                                        regPatronal: {
                                            id: res.data.dataResponse.regPat.id,
                                            regPatronal: res.data.dataResponse.regPat.registroPatClave
                                        }
                                    };
                                    setInfoRegPatronal(regPatronal);
                                }
                                break;
                            default:
                                errores.push(`Registro Patronal: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Registro Patronal: ${registro_no_guardado}`);
                        console.log(error);
                    });
                } else {
                    const requestPatronal = {
                        empRegPatId: infoRegPatronal.id,
                        regPatId: parseInt(regPatronalRef.current.value)
                    };
                    await axios.put(`${servicios_grupohit_rh}/empleado/registropat/`, requestPatronal, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(modulo === 30) {
                                    const regPatronal = {
                                        id: res.data.dataResponse.id,
                                        regPatronal: {
                                            id: res.data.dataResponse.regPat.id,
                                            regPatronal: res.data.dataResponse.regPat.registroPatClave,
                                        }
                                    };
                                    setInfoRegPatronal(regPatronal);
                                }
                                break;
                            default:
                                errores.push(`Registro Patronal: ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Registro Patronal: ${registro_no_guardado}`);
                        console.log(error);
                    });
                }
            }
        }
        //Sindicatos
        if((infoSindicato.sindicato.id !== sindicatoSeleccionado.id) || (infoSindicato.aplica !== cuotaSindical)) {
            if(sindicatoSeleccionado.id === '') {
                await axios.delete(`${servicios_grupohit_rh}/empleado/sindicatos/${infoSindicato.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const sindicato = {
                                id: '',
                                sindicato: { id: '' },
                                aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                            };
                            setInfoSindicato(sindicato);
                            break;
                        default:
                            errores.push(`Sindicato ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => {
                    errores.push(`Sindicato ${registro_no_guardado}`);
                    console.log(error);
                });
            } else {
                if(infoSindicato.id === '') {
                    const requestSindicato = { 
                        empId: elementSelect.id,
                        sindId: sindicatoSeleccionado.id,
                        aplica: (cuotaSindical === 'si') ? true : false
                    };
                    await axios.post(`${servicios_grupohit_rh}/empleado/sindicatos/`, requestSindicato, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(modulo === 30) {
                                    const sindicato = {
                                        id: res.data.dataResponse.id,
                                        sindicato: res.data.dataResponse.sindicatoId,
                                        aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                                    };
                                    setInfoSindicato(sindicato);
                                }
                                break;
                            default:
                                errores.push(`Sindicato ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Sindicato ${registro_no_guardado}`);
                        console.log(error);
                    });
                } else {
                    const requestSindicato = { empSindId: infoSindicato.id, sindId: parseInt(sindicatoSeleccionado.id), aplica: cuotaSindical === 'si' ? true : false };
                    await axios.put(`${servicios_grupohit_rh}/empleado/sindicatos/`, requestSindicato, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(modulo === 30) {
                                    const sindicato = {
                                        id: res.data.dataResponse.id,
                                        sindicato: res.data.dataResponse.sindicatoId,
                                        aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                                    };
                                    setInfoSindicato(sindicato);
                                }
                                break;
                            default:
                                errores.push(`Sindicato ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {
                        errores.push(`Sindicato ${registro_no_guardado}`);
                        console.log(error);
                    });
                }
            }
        }
        // minimo Liquidez
        if(modulo === 32 || ((modulo === 30) && infoMinLiquidez !== minLiquidez)) {
            if(minLiquidez !== '') {
                const requestLiquidez = { 
                    empId: elementSelect.id,
                    minimo: (minLiquidez === 'si') ? true : false
                };
                await axios.post(`${servicios_grupohit_rh}/empleado/liquidez/`, requestLiquidez, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            if(modulo === 30) {
                                const minLiquidez = (res.data.dataResponse.minimoLiquidez) ? 'si' : 'no';
                                setInfoMinLiquidez(minLiquidez);
                            }
                            break;
                        default:
                            errores.push(`Préstamo ${res.data.msg}`);
                            break;
                    }
                })
                .catch(error => {
                    errores.push(`Préstamo ${registro_no_guardado}`);
                    console.log(error);
                });
            }
        }
        
        //se ejecuta la bitacora id=10 cuando el empleado pasa de tener nombramiento desde conceptos y antes no lo tenía
        //y cuando los tiene en un reingreso
        if(ejecutaBitacora10) {
            await servicioBitacora({ empleadoId: elementSelect.id, tipoMovId: 10 });
            await setNombramientoEditInicial(true);
        }
        if(modulo === 30) await setRecargarInfo(true);
        await setIsLoading(false);

        if(plazaDisponibleRango && modulo === 32) {
            const infoBaja = {
                motivoBajaId: 47,
                empPuestoFecfin: plazaDisponibleRango,
                empId: elementSelect.id,
                motBaja: 'BAJA POR ACTUALIZACIÓN'
            };
            await axios.put(`${servicios_grupohit_rh}/empleado/status/`, infoBaja, headersAuth())
            .then(res =>  {
                switch(res.data.status) {
                    case 200:
                        const ejecutaBaja = async () => {
                            await servicioBitacora({ empleadoId: elementSelect.id, tipoMovId: 2 });
                            await dispatch({
                                type: BAJA_EMPLEADO,
                                payload: elementSelect
                            });
                        };
    
                        ejecutaBaja();
                        break;
                    default:
                        errores.push(`Baja ${res.data.msg}`);
                        break;
                }
            })
            .catch(error => { console.log(error); errores.push(`Baja ${registro_no_guardado}`);});
        }

        if(modulo === 32) await cerrarModal();

        if(errores.length) {
            if(modulo === 32) {
                if(pagosPendientes) {
                    await dispatch({
                        type: MUESTRA_EMP_PAGOS_PENDIENTES,
                        payload: { pagosPendientes: elementSelect, retienePagos }
                    });
                }
                errores.unshift('El empleado se reingresó con errores: ');
            }
            errorTable(errores);
        } else {
            if(modulo === 32) {
                if(pagosPendientes) {
                    await dispatch({
                        type: MUESTRA_EMP_PAGOS_PENDIENTES,
                        payload: { pagosPendientes: elementSelect, retienePagos }
                    });
                }
            }
            successTable(registro_actualizado);
        }
    };

    servicios();
};

const servicioCambiaStatusPlaza = async (idPlaza, statusPlaza) => {
    let respuesta = '';
    await axios.put(`${servicios_grupohit_org}/plaza/status/cambio/${idPlaza}/${statusPlaza}`, null, headersAuth())
    .then(res =>  {
        respuesta = res.data.status;
    })
    .catch(error => respuesta = false);

    return respuesta;
};

const servicioBorraTitularPlaza = async (idPlaza) => {
    let respuesta = '';
    await axios.put(`${servicios_grupohit_org}/plaza/empleadotitular/cambio/${idPlaza}`, {}, headersAuth())
    .then(res =>  {
        respuesta = res.data.status;
    })
    .catch(error => respuesta = false);

    return respuesta;
};

export const bajaEmpleado = (infoBaja, statusPlaza, borrarTitularPlaza, elementSelect, eliminar, errorTable, cerrarModal, setIsLoading, successTable) => dispatch => {
    const ejecutarBajaEmpleado = async () => {
        setIsLoading(true);
        var idPlaza = null;
        var plazaClave = null;
        if(statusPlaza || borrarTitularPlaza) {
            await axios.get(`${servicios_grupohit_rh}/empleado/${elementSelect.id}`, headersAuth())
            .then(res =>  {
                switch(res.data.status) {
                    case 200:
                        idPlaza = res.data.dataResponse.plaza.plazaid.id
                        plazaClave = res.data.dataResponse.plaza.plazaid.plazaclave
                        break;
                    default:
                        idPlaza = null;
                        plazaClave = null;
                        break;
                }
            })
            .catch(error => { idPlaza = null; plazaClave = null; });
        }
    
        await axios.put(`${servicios_grupohit_rh}/empleado/status/`, infoBaja, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    const ejecutaBaja = async () => {
                        await servicioBitacora({ empleadoId: elementSelect.id, tipoMovId: 2 });
                        var resStatusPlaza = true;
                        var resTitularPlaza = true;
                        if(statusPlaza) {
                            try {
                                if(await servicioCambiaStatusPlaza(idPlaza, statusPlaza) !== 200) resStatusPlaza = false
                            } catch (error) {
                                resStatusPlaza = false
                            }
                        }
                        if(borrarTitularPlaza) {
                            try {
                                if(await servicioBorraTitularPlaza(idPlaza) !== 200) resTitularPlaza = false
                            } catch (error) {
                                resTitularPlaza = false
                            }
                        }
                        if(resStatusPlaza && resTitularPlaza) {
                            await eliminar(elementSelect.id);
                        } else {
                            if(!resStatusPlaza && !resTitularPlaza) await successTable(`Se realizó la baja del empleado sin actualizar el empleado titular, ni status de la plaza ${plazaClave}`);
                            if(!resStatusPlaza && resTitularPlaza) await successTable(`Se realizó la baja del empleado sin actualizar el status de la plaza ${plazaClave}`);
                            if(resStatusPlaza && !resTitularPlaza) await successTable(`Se realizó la baja del empleado sin actualizar el empleado titular de la plaza ${plazaClave}`);
                        }
                        
                        await dispatch({
                            type: BAJA_EMPLEADO,
                            payload: elementSelect
                        });
                        await cerrarModal();

                        await setIsLoading(false);
                    };

                    ejecutaBaja();
                    break;
                default:
                    errorTable(res.data.msg);
                    setIsLoading(false);
                    break;
            }
        })
        .catch(error => { console.log(error); errorTable(verifique_conexion); setIsLoading(false); });
    };
    ejecutarBajaEmpleado();
};

export const eliminarEmpleado = (id, eliminar, errorTable, setIsLoading) => dispatch => {
    axios.delete( `${servicios_grupohit_rh}/empleado/delete/empleado/${id}`, headersAuth() )
    .then(respuesta => {
        switch(respuesta.data.status){
            case 200:
                eliminar(id);
                dispatch({
                    type: ELIMINA_EMPLEADO,
                    payload: id
                });
                break;
            default:
                errorTable(respuesta.data.msg);
                break;
        }
    })
    .catch(error => {
        console.log(error);
        //errorTable(registro_no_eliminado);
    })
    .finally(() => setIsLoading(false));
};

export const reingresoEmpleado = (request, request2, elementSelect, info, actualizarOrganizacion, cerrarModal, errorTable, setIsLoading) => dispatch => {
    setIsLoading(true);
    axios.post(`${servicios_grupohit_rh}/empleado/status/`, request, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                axios.post(`${servicios_grupohit_rh}/empleado/antiguedad/`, request2, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const ejecuta = async () => {
                                elementSelect.status = 'ACTIVO';
                                dispatch({
                                    type: REINGRESO_EMPLEADO,
                                    payload: elementSelect
                                });
                                await actualizarOrganizacion(info);
                            };
                            ejecuta();
                            break;
                        default:
                            errorTable(res.data.msg);
                            setIsLoading(false);
                            break;
                    }
                })
                .catch(error => {
                    errorTable(registro_no_guardado);
                    console.log(error);
                    setIsLoading(false);
                });
                break;
            default:
                errorTable(res.data.msg);
                setIsLoading(false);
                break;
        }
    })
    .catch(error => {
        errorTable(registro_no_guardado);
        console.log(error);
        setIsLoading(false);
    });
};

export const deshacerReingreso = (id, eliminar, errorTable, setIsLoading) => dispatch => {
    axios.delete(`${servicios_grupohit_rh}/empleado/status/reingresos/${id}`, headersAuth())
    .then(res =>  {
        switch(res.data.status) {
            case 200:
                dispatch({
                    type: DESHACER_REINGRESO,
                    payload: id
                });
                eliminar(id);
                break;
            default:
                errorTable(res.data.msg);
                break;
        }
    })
    .catch(error => {
        errorTable(registro_no_eliminado);
    })
    .finally(() => {
        setIsLoading(false);
    });
};

export const cancelarBajaEmpleado = (elementSelect, eliminar, errorTable, setIsLoading) => dispatch => {
    setIsLoading(true);
    axios.delete(`${servicios_grupohit_rh}/empleado/status/bajas/${elementSelect.id}`, headersAuth())
    .then(res =>  {
        switch(res.data.status) {
            case 200:
                eliminar(elementSelect.id);
                dispatch({
                    type: CANCELA_BAJA_EMPLEADO,
                    payload: elementSelect
                });
                break;
            default:
                errorTable(res.data.msg);
                break;
        }
    })
    .catch(error => errorTable(verifique_conexion))
    .finally(() => setIsLoading(false));
};

const folioEscalafon = (requestFolio, setSettingsAlertSE, setOpenAlertSE) => {
    axios.post(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/`, requestFolio, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                break;
            default:
                setSettingsAlertSE({ type: 'error', msg: res.data.msg });
                setOpenAlertSE(true);
                break;
        }
    })
    .catch(error => {
        setSettingsAlertSE({ type: 'error', msg: verifique_conexion });
        setOpenAlertSE(true);
    });;
};

export const escalafonDefinitivo = (request, requestFolio, setOpenLoading, setSettingsAlertSE, setOpenAlertSE) => dispatch => {
    axios.post(`${servicios_grupohit_rh}/escalafon/`, request, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                folioEscalafon(requestFolio, setSettingsAlertSE, setOpenAlertSE);
                dispatch({
                    type: CAMBIOS_ESCALAFON_DEFINITIVO,
                    payload: res.data.dataResponse
                });
                setOpenLoading(false);
                setSettingsAlertSE({ type: 'success', msg: res.data.msg });
                setOpenAlertSE(true);
                break;
            default:
                setOpenLoading(false);
                setSettingsAlertSE({ type: 'error', msg: res.data.msg });
                setOpenAlertSE(true);
                break;
        }
    })
    .catch(error => {
        setOpenLoading(false);
        setSettingsAlertSE({ type: 'error', msg: verifique_conexion });
        setOpenAlertSE(true);
    });
};

export const escalafonTemporal = () => dispatch => {};

export const terminaEscalafon = data => dispatch => {
    dispatch({
        type: TERMINA_ESCALAFON,
        payload: data
    });
};

export const actualizaEmpleadosEscalafonDefinitivo = empleados => dispatch => {
    dispatch({
        type: CAMBIOS_ESCALAFON_DEFINITIVO,
        payload: empleados
    });
};

export const transferenciaPermutaEmpleados = (
    request, data, successTable, errorTable, cerrarModal,
    setCPresupuestal, setDepartamento, setErrorEmpleado, setErrorDepartamento, setErrorCPresupuestal,
    setIsLoading
) => dispatch => {
    axios.put(`${servicios_grupohit_rh}/empleado/transferencia/`, { registros: request }, headersAuth())
    .then(res => {
        switch(res.data.status){
            case 200:
                request.map(emp => servicioBitacora({ empleadoId: emp.empId, tipoMovId: 5 }));
                dispatch({
                    type: CAMBIOS_PERMUTA_TRANSFERENCIA,
                    payload: res.data.dataResponse
                });
                successTable(`La ${(request.length === 1) ? 'transferencia' : 'permuta'} se realizó con éxito`);
                setCPresupuestal(null);
                setDepartamento(null);
                cerrarModal();
                setErrorEmpleado(false);
                setErrorCPresupuestal(false);
                setErrorDepartamento(false);
                break;
            default:
                errorTable(res.data.msg);
                break;
        }
    })
    .catch(error => {
        errorTable(verifique_conexion);
    })
    .finally(() => setIsLoading(false));
};

export const actualizarInfoEmpleadoEspeciales = info => dispatch => {
    dispatch({
        type: ACTUALIZA_EMPLEADO_ESPECIALES,
        payload: info
    });
};

export const servicioBitacora = async ({ empleadoId, tipoMovId }) => {
    const requestBitacora = {
        empId: empleadoId,
	    tipoMovimientoId: tipoMovId,
    };

    try {
        await axios.post(`${servicios_grupohit_rh}/empleado/bitacora/`, requestBitacora, headersAuth());
    } catch (e) {

    }
};

export const borrarEmpPagosPendientes = () => dispatch => {
    dispatch({
        type: BORRA_EMP_PAGOS_PENDIENTES
    });
};
