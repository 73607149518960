import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_admon, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteDomicilio = ({ idModulo, openDelete, setOpenDelete, elementSelect, eliminar, errorTable, informacion }) => {

    const [openLoading, setOpenLoading] = useState(false);

    const axiosEliminar = () => {
        setOpenLoading(true);
        axios.delete( `${servicios_grupohit_admon}/compania/domicilios/${informacion.dependenciaID}/${elementSelect.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        eliminar( elementSelect.id );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_eliminado );
            })
            .finally(() => setOpenLoading(false));
    }
    return (
        <Fragment>
            <ModalLoading nombreModulo={idModulo} open={openLoading} setOpen={setOpenLoading}/>
            <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
        </Fragment>
    );
};

export default ModalDeleteDomicilio;