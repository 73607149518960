import decode from 'jwt-decode';
import history from './History';
import { sao } from '../Constants/Constants';

/* ---------------------------------------------------------------------------- */
export function guardarToken( authToken ) {
    localStorage.setItem( "IGSESSID", JSON.stringify( authToken ) );
    (sao)
    ?   history.push("/sao/usuario")
    :   history.push("/usuario");
}

export function obtenerUsuario() {
    try {
        return JSON.parse( localStorage.getItem("IGSESSID") );
    } catch(e) {
        return {token: null};
    }
}

export function obtenerIdUsuario() {
    const informacionUsuario = obtenerUsuario();
    return informacionUsuario.claveUsuario;
}

/* ----------------------------------------------------------------------------------------- */
export function refreshToken( authToken ) {
    const informacionUsuario = obtenerUsuario();
    informacionUsuario[ 'token' ] = authToken;
    localStorage.setItem( "IGSESSID", JSON.stringify( informacionUsuario ) );
}
/* ----------------------------------------------------------------------------------------- */
export function actualizarUsuarioStorage( informacion ) {
    const informacionUsuario = obtenerUsuario();
    informacionUsuario[ 'nombre' ] = informacion.usuarioNom;
    informacionUsuario[ 'apaterno' ] = informacion.usuarioPat;
    if( informacion.usuarioMat ){
        informacionUsuario[ 'amaterno' ] = informacion.usuarioMat;
    }
    localStorage.setItem( "IGSESSID", JSON.stringify( informacionUsuario ) );
}
/* ----------------------------------------------------------------------------------------- */
export function cerrarSession() {
    localStorage.removeItem( "IGSESSID" );
    localStorage.removeItem( "revalidateSession" );
    localStorage.removeItem( "IGRelLab" );
    if(sao) {
        if( window.location.pathname !== '/sao/acceso' ){
            history.push( "/sao/acceso" );
        }
    } else {
        if( window.location.pathname !== '/acceso' ){
            history.push( "/acceso" );
        }
    }
}

/* ---------------------------------------------------------------------------- */
export function authCheck() {
    const informacionUsuario = obtenerUsuario();
    let isValid = true;
    try{
        const token = informacionUsuario.token;
        const tokenSinBearer = token.substring(7);
        isValid = decode(tokenSinBearer);
    }catch (error){
        return false;
    }
    return isValid;
}
/* ---------------------------------------------------------------------------- */

export function isAuthenticaded() {
    const informacionUsuario = obtenerUsuario();
    let isValid = true;
    try {
        const token = informacionUsuario.token;
        const tokenSinBearer = token.substring(7);
        isValid = decode(tokenSinBearer);
    } catch(error) {
        return false;
    }
    return isValid;
}

/* ---------------------------------------------------------------------------- */

export function headersAuth() {
    const informacionUsuario = obtenerUsuario();
    if( informacionUsuario ){
        return {
            headers : {
                "X-Authorization": informacionUsuario.token,
                'Content-Type': 'application/json'
            }
        };
    }
}