import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_admon, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonProyectos } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormProyectos from './FormProyectos';

const ModalAddProyectos = ({ openAdd, setOpenAdd, agregar, successTable, errorTable, nombreModulo }) => {
    
    const [error, setError] = useState(null);
    const [openLoading, setOpenLoading] = useState(false);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    }
        
    const enviarProyecto = proyecto => {
        const info = proyecto;
        Axios.post( `${servicios_grupohit_admon}/presupuestal/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const proyecto = JsonProyectos(res.data.dataResponse);
                    agregar(proyecto);
                    setOpenLoading(false);
                    setOpenAdd(false);
                    break;
                default:
                    setOpenLoading(false);
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { setOpenLoading(false); errorTable(registro_no_guardado) });
    }

    return (
        <ModalService title="Agregar Proyecto" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormProyectos
                        openLoading={openLoading}
                        setOpenLoading={setOpenLoading}
                        enviarProyecto={enviarProyecto}
                        error={error}
                        setError={setError}
                        agregar={agregar}
                        successTable={successTable}
                        errorTable={errorTable}
                        setOpenAddFirst={setOpenAdd}
                        idModulo={nombreModulo}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddProyectos;