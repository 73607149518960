import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit, tablero_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import ListaDeAccesos from './ListaDeAccesos';
import {cambios_no_guardados} from '../../../../../../Constants/Constants';

const ModalAddAccesoDirecto = ({ openAdd, setOpenAdd, accesosDirectos, axiosAccesos }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const guardarAccesos = ( accesos ) => {
        axios.post( `${servicios_grupohit}/dashboard/`, accesos, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        axiosAccesos();
                        setOpenAdd( false );
                        break;
                    default:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                }
            })
            .catch( error => {
                setError( <AlertForm message={ cambios_no_guardados } /> );
            })
    }

    return (
        <ModalService title="Agregar Acceso Rápido" parentNode={tablero_} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <ListaDeAccesos
                    error={error}
                    setError={setError}
                    accesosDirectos={accesosDirectos}
                    guardarAccesos={guardarAccesos}
                />
        </ModalService>
    );
};

export default ModalAddAccesoDirecto;