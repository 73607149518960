import React, { useState, createRef } from 'react';
import { connect } from 'react-redux';
import { guardarRol } from '../../Redux/actions/sessionActions';
import AlertForm from '../../Services/AlertForm';
import { servicios_grupohit_url, campos_vacios, ocurrio_un_error, lengthEmail, lengthPassword, verifique_conexion, sao } from '../../Constants/Constants';
import { trimValue } from '../../Services/Validation/HelperValidation';

import { withRouter } from "react-router-dom";
import axios from 'axios';
import { authCheck } from '../../Services/AuthService';

/*Vista Material UI*/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ModalTerminosPrivacidad from '../Usuarios/Usuario/TerminosyCondiciones/ModalTerminosPrivacidad';
import PasswordInput from '../../Services/Validation/PasswordInput';

const LoginFormulario = ({ history, onChangeFormClick, logotipo, guardarRol }) => {
    const usuarioRef = createRef('');
    const contraseñaRef = createRef('');
    const [error, setError] = useState(null);
    const [diss, setDiss] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleChangeFormClick = (e) => {        
        e.preventDefault();
        onChangeFormClick(2);
    }

    /* Metodo Submit() Para inicio de sesión */
    const iniciarSesion = (evt) => {
        evt.preventDefault();
        if( !authCheck() ) {
            const usuario = usuarioRef.current.value.toLowerCase();
            const contraseña = contraseñaRef.current.value;
            if( usuario.trim() === '' || contraseña.trim() === '' ) {
                setError( <AlertForm message={campos_vacios} /> );
                return;
            }
            setError(null);
            loginSession(usuario, contraseña);
        }else{
            (sao)
            ?   history.push("/sao/usuario")
            :   history.push("/usuario");
        }
    }

    /* Metodo para iniciar session */
    const loginSession = async (username, password) => {
        setDiss(true);
        try {
                const respuesta = await axios.post( `${servicios_grupohit_url}/login`, {username, password} );
                const authToken = respuesta.headers["x-authorization"];
                if( authToken ) {
                    const { id, nombre, paterno, materno, username, tour, tyc, rol } = respuesta.data.dataResponse;
                    guardarRol({ rol, usuario: { id, nombre, paterno, materno, username } });
                    localStorage.setItem( "IGSESSID", JSON.stringify( { "token": authToken, "claveUsuario": id, "nombre" : nombre, "apaterno": paterno, "amaterno": materno, "usuario": username, "tour": tour, "tyc": tyc, "rol": rol, "cambiarPassword": true }));
                    //localStorage.setItem( "IGSESSID", JSON.stringify( { "token": authToken, "claveUsuario": id, "nombre" : nombre, "apaterno": paterno, "amaterno": materno, "usuario": username, "tour": "true", "tyc": "false", "rol": rol, "cambiarPassword": true }));
                    (sao)
                    ?   history.push("/sao/usuario")
                    :   history.push("/usuario");
                } else {
                    const msg = respuesta.data.msg;
                    if(msg) {
                        setError(<AlertForm message={msg} />);
                    } else {
                        setError(<AlertForm message={ocurrio_un_error} />);
                    }
                    setDiss(false);
                }
        } catch(error) {
                setDiss(false);
                setError(<AlertForm message={(error?.response?.data?.msg) ? error?.response?.data?.msg : verifique_conexion} />);
        }
    }
    
    return (
        <Paper className="cont_log" elevation={6}>
            <div className="log_form">
                <div className="title">
                    <div className="text-center">
                        { logotipo && <img src={`data:image/jpeg;base64, ${logotipo}`} className="header_logo" alt="site-logo"/> }
                    </div>
                </div>
                <form onSubmit={iniciarSesion} autoComplete="off">
                    <div className="form-group">
                        <label className="col-form-label label-form">Usuario<strong className="requerido">* </strong></label>
                        <input type="text" className="form-control" autoComplete="username" ref={usuarioRef} maxLength={lengthEmail} onBlur={() => trimValue(usuarioRef)} />
                    </div>
                    <div className="form-group">
                        <label className="col-form-label label-form">Contraseña<strong className="requerido">* </strong></label>
                        <PasswordInput refValue={contraseñaRef} maxLength={lengthPassword} />
                    </div>
                    { error }
                    <Button variant="contained" className="btn-color" type="submit" disabled={diss}>Iniciar Sesión</Button>
                </form>

                <div className="row">
                    <div className="col-md-6 col-sm-6 col-login">
                        <div className="div-text">
                            <button className="subrayado link" onClick={e => handleChangeFormClick(e)}>Olvidé mi contraseña</button>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-login">
                        <div className="div-text">
                            <button className="subrayado link" onClick={() => setOpenModal(true)}>Información legal</button>
                        </div>
                    </div>
                </div>
                <ModalTerminosPrivacidad open={openModal} setOpen={setOpenModal}/>
            </div>
        </Paper>
    );
};

const mapDispatchToProps = { guardarRol };

export default connect(null, mapDispatchToProps)(withRouter( LoginFormulario ));