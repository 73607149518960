import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_org, verifique_conexion } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import OrganigramaPlazas from './Plazas/OrganigramaPlazas';
import FormCAdscripcion from './CentrosAdscripcion/FormCAdscripcion';
import FormPlaza from './Plazas/FormPlaza';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../../Media/Img/profile.jpg';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import FormPlazaArea from './Plazas/FormPlazaArea';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';
import TooltipComponent from '../../../../../../../Services/Tooltip/TooltipComponent';

const OrganigramaModal = ({ minWidth, modalIsOpen, setModalIsOpen, enviar, actualizar, id, permisos, parentNode }) => {
    const [muestraBtnDescarga, setMuestraBtnDescarga] = useState(false);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    
    let contenido = null;
    let title = null;

    const downloadXLS = async (data) => {
        const { id, nombre } = data;
        await axios.get(`${servicios_grupohit_org}/plaza/excel/${id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const a = document.createElement("a");
                    a.style.display = "none";
                    document.body.appendChild(a);
                    const data = `data:application/octet-stream;base64,${res.data.dataResponse}`;
                    a.href = data;
                    const fileName = `Organigrama ${nombre}.xls`;
                    a.setAttribute("download", fileName);
                    a.click();
                    window.URL.revokeObjectURL(a.href);
                    document.body.removeChild(a);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            } 
        })
        .catch((error) => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    };

    switch(modalIsOpen.type) {
        case "organigrama":
            title = 'Plazas';
            contenido = <Fragment>
                            <div className="row">
                                <div className="col-10">
                                    <h6><strong>Clave Presupuestal: </strong>{ modalIsOpen.data.info?.cpresupuestalclave }</h6>
                                    <h6><strong>Proyecto: </strong>{ modalIsOpen.data.info?.cpresupuestalnom }</h6>
                                </div>
                                <div className="col-2 align-self-end ctr-dl-org">
                                {
                                (muestraBtnDescarga)
                                ?   <TooltipComponent tooltipText="Descargar organigrama">
                                        <Button variant="contained" className="btn-color btn-dl-org float-right" onClick={() => downloadXLS(modalIsOpen.data)}>
                                            <GetAppIcon/>
                                        </Button>
                                    </TooltipComponent>
                                :   null
                                }
                                </div>
                            </div>
                            <OrganigramaPlazas data={modalIsOpen.data} permisos={permisos} setMuestraBtnDescarga={setMuestraBtnDescarga}/>
                        </Fragment>;
        break;
        case "agregar":
            title = 'Nuevo Centro de Adscripción';
            contenido = <FormCAdscripcion enviar={enviar} data={modalIsOpen.data} setModalIsOpen={setModalIsOpen} id={id}/>;
        break;
        case "editar":
            title = 'Actualizar Centro de Adscripción';
            contenido = <FormCAdscripcion actualizar={actualizar} data={modalIsOpen.data} setModalIsOpen={setModalIsOpen} id={id}/>;
        break;
        case "agregarPlazaNuevoOrg":
        case "agregarPlaza":
            title = 'Nueva Plaza';
            contenido = <FormPlaza enviar={enviar} data={modalIsOpen.data}/>;
        break;
        case 'editBusquedaPlazas':
        case "editarPlaza":
            title = 'Actualizar Plaza';
            contenido = <FormPlaza actualizar={actualizar} data={modalIsOpen.data}/>;
        break;
        case "infoPlaza":
            title = 'Información de Plaza';
            contenido =
            <div className="row">
                <div className="col-1 col-md-2 col-sm-4 center">
                    <Avatar style={{width: '100%', height: 'auto'}} alt="avatar" src={(modalIsOpen.data.foto) ? (modalIsOpen.data.foto !== "" && modalIsOpen.data.foto !== null) ? `data:image/jpeg;base64, ${modalIsOpen.data.foto}` : profileIMG : profileIMG} />
                </div>
                <div className="col-11 col-md-10 col-sm-8">
                    {   !modalIsOpen.data.plazadisponible &&
                        <Fragment><label><strong>Nomenclatura del puesto: </strong>{ modalIsOpen.data.puestoNom }</label><br/></Fragment>
                    }
                    {   !modalIsOpen.data.plazadisponible &&
                        <Fragment><label><strong>Nombre del empleado: </strong>{ `${modalIsOpen.data.empleadoNom} ${modalIsOpen.data.empleadoPat} ${modalIsOpen.data.empleadoMat}` }</label><br/></Fragment>
                    }
                    <label><strong>Número Único de la Plaza (NUP): </strong>{ modalIsOpen.data.plazaclave }</label><br/>
                    {   !modalIsOpen.data.plazadisponible
                        ?   <Fragment>
                                <label><strong>Clave del Puesto: </strong>{ modalIsOpen.data.puestoCve }</label><br/>
                                <label><strong>Nivel del Puesto: </strong>{ modalIsOpen.data.nivLaboralClave }</label><br/>
                                <label><strong>Nombre del Puesto: </strong>{ modalIsOpen.data.puestoNom }</label><br/>
                                <label><strong>Nómina: </strong>{ `${modalIsOpen.data.nomina_clave} ${modalIsOpen.data.nomina_nom}` }</label><br/>
                                <label><strong>Identificación de la Plaza acorde a la estructura vigente: </strong>{ modalIsOpen.data.identificador }</label>
                            </Fragment>
                        :   <label><strong>PLAZA DISPONIBLE</strong></label>
                    }
                </div>
            </div>;
        break;
        case 'cambiarArea':
            title = 'Actualizar Área de la Plaza';
            contenido = <FormPlazaArea actualizar={actualizar} data={modalIsOpen.data}/>;
        break;
        default:
            title = null;
            contenido = null;
        break;
    }

    return (
        <ModalService
            title={title}
            parentNode={parentNode}
            isOpen={modalIsOpen.state}
            onClose={() => setModalIsOpen({ state: false, type: null })}
            minWidth={minWidth}
            maxWidth={minWidth}
        >
            <div width="100%">
                { contenido }
                <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
            </div>
        </ModalService>
    );
}

export default OrganigramaModal;