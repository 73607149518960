import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonMovimientosRelacionadosEmpleado } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import FormularioRelacionados from './FormularioRelacionados';

const obtenerAregloIndicadores = ( indicadores, elementSelectID ) => {
    return indicadores.map( item => ({
        'movrelacionado': elementSelectID,
        'nominaIndicador': item.nominaIndicador,
        'porcentaje': item.porcentaje,
    }));
}

const ModalAddRelacionados = ({ openAdd, setOpenAdd, agregar, editar, errorTable, informacionAdicional, idModulo }) => {
    const [ error, setError ] = useState( null );
    const [isLoading, setIsLoading] = useState(false);
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarRelacionado = ( relacionado, indicadoresAdd ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_rh}/movimientos/relacionado/`, relacionado, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonMovimientosRelacionadosEmpleado(respuesta.data.dataResponse[0], informacionAdicional.pensiones) );
                        const elementSelect = respuesta.data.dataResponse[0];
                        if( elementSelect ){

                            enviarIndicadores( obtenerAregloIndicadores(indicadoresAdd, elementSelect.id) );
                        }
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    const enviarIndicadores = async ( indicadores ) => {
        await axios.post( `${servicios_grupohit_rh}/indicador/movimientos/`, indicadores, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //axiosIndicadores();
                        break;
                    default:
                        //errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Agregar Movimiento Relacionado" parentNode={idModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioRelacionados
                    error={error}
                    informacion={informacionAdicional}
                    enviarRelacionado={enviarRelacionado}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddRelacionados;