import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_catalogo, regimen_fiscal } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const RegimenFiscal = ({ id, permisos }) => {
    const [ regimenFiscales, setRegimenFiscales ] = useState( null );
    useEffect(() => {
        axiosRegimenFiscales();
    }, []);

    const axiosRegimenFiscales = () => {
        axios.get( `${servicios_grupohit_catalogo}/regfiscal/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setRegimenFiscales( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setRegimenFiscales([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { regimenFiscales ?
                <DataTableService id={id} title={ regimen_fiscal } permisos={permisos}
                    columns={[
                        { field: "regFiscalClave", title: "Clave" },
                        { field: "regFiscalDesc", title: "Descripción" },
                    ]}
                    data={ regimenFiscales } /> : <SpinnerOval />
            }
        </div>
    );
};

export default RegimenFiscal;