import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import DataTableService from '../../../../../DataTableService/DataTableService';
import InputText from '../../../../../../../../Services/Components/DataTable/InputText';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { arrayJsonMovimientosPorPeriodoDatosEmpleado } from '../../../../../Helpers/JsonToOneLevel';
import { datos_empleados, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const DatosEmpleado = ({ id, requestTabla, setBloqueaCards, setError, moverATabla, informacionAdicional }) => {

    const [infoTabla, setInfoTabla] = useState(null);

    useEffect(() => {
        const servicioDatosEmpleado = () => {
            setBloqueaCards(true);
            axios.post(`${servicios_grupohit_nomina}/procesos/estadisticas/complementaria/datosempleado`, requestTabla, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        const arrayDatosEmpleado = arrayJsonMovimientosPorPeriodoDatosEmpleado(res.data.dataResponse);
                        setInfoTabla(arrayDatosEmpleado);
                        break;
                    default:
                        setInfoTabla([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setInfoTabla([]); setError(<AlertForm message={verifique_conexion} />) })
            .finally(() => moverATabla() );
        };

        servicioDatosEmpleado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
        {(infoTabla)
        ?   <DataTableService
                id={`${id}DatosEmpleado`}
                title={datos_empleados}
                columns={[
                    { field: "empleadoClaveActAnt", title: "NUE Actual / Anterior", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClaveActAnt.startsWith(term) },
                    { field: "empleadoNomCompletoActAnt", title: "Nombre Actual / Anterior" },
                    { field: "empleadoFecNacActAnt", title: "Fecha Nacimiento Actual / Anterior" },
                    { field: "empleadoGeneroActAnt", title: "Género Actual / Anterior" },
                    { field: "empleadoCurpActAnt", title: "CURP Actual / Anterior" },
                    { field: "empleadoRfcActAnt", title: "RFC Actual / Anterior" },
                    { field: "empleadoNssActAnt", title: "NSS Actual / Anterior" },
                    { field: "nomina", title: "Clave Nómina / Nómina" },
                    { field: "cpresupuestal", title: "Clave Presupuestal / Proyecto", customFilterAndSearch: (term, rowData) => rowData.cpresupuestal.startsWith(term) },
                    { field: "plazaClave", title: "NUP", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                    { field: "puesto", title: "Clave Puesto / Puesto" },
                    { field: "usuario", title: "Cuenta", hidden: true, hiddenByColumnsButton: true },
                    { field: "nombre", title: "Usuario" },
                    { field: "fechaMovimiento", title: "Fecha de Registro" },
                ]}
                data={infoTabla}
                customOptionsTable={{ filtering: true }} 
                hideIconAdd hideIconEdit hideIconDelete
                informacionAdicional={informacionAdicional}
            />
        :   <SpinnerOval/>
        }
        </Fragment>
    );
}
 
export default DatosEmpleado;
