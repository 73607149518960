import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { servicios_grupohit_catalogo, registro_sera_guardado, registro_sera_actualizado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormDomicilio = ({ elementSelect, actualizarDomicilio, enviar, irInfo, calleRef, numExteriorRef, numInteriorRef, cpRef, coloniaRef, ciudadRef, estadoRef, infoDomicilio, isLoading }) => {
    
    const [openAlert, setOpenAlert] = useState(false);
    const [colonias, setColonias] = useState([]);

    const mostrarAlert = e => {
        e.preventDefault();
        if(validarSubmit('FormEmpleadoDomicilio')) {
            setOpenAlert(true);
        }
    };

    const guardar = e => {
        e.preventDefault();
        enviar();
    };

    const enviaInfo = () => {
        if(!infoDomicilio.calle) {
            actualizarDomicilio('Agregar') 
        } else {
            actualizarDomicilio('Editar')
        }
    };

    const obtenerColonias = () => {
        axios.get(`${servicios_grupohit_catalogo}s/colonias/${cpRef.current.value}`, headersAuth())
        .then(res => {
            switch(res.data.status){
                case 200:
                    const { estadoNom, ciudadNom, colonias } = res.data.dataResponse;
                    setColonias(colonias);
                    estadoRef.current.value = estadoNom;
                    ciudadRef.current.value = ciudadNom;
                    break;
                default:
                    setColonias([]);
                    break;
            }
        })
        .catch(error => {});
    };

    useEffect(() => {
        if(infoDomicilio) {
            if(!colonias.length) {
                buscaCP();
                return;
            }
            coloniaRef.current.value = infoDomicilio.codPostal.codigopId;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoDomicilio]);

    useEffect(() => {
        if(infoDomicilio) {
            if(colonias.length) {
                if(infoDomicilio.codPostal) {
                    coloniaRef.current.value = infoDomicilio.codPostal.codigopId;
                } else {
                    coloniaRef.current.value = '';
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colonias]);

    const buscaCP = () => {
        if(cpRef.current.value.length === 5) {
            obtenerColonias();
        } else {
            setColonias([]);
            estadoRef.current.value = '';
            ciudadRef.current.value = '';
        }
    };

    return (
        <Fragment>
            {
                (enviar || (infoDomicilio && colonias))
                ?   <Fragment>
                        <h5>Domicilio Fiscal</h5>
                        <form id="FormEmpleadoDomicilio" onSubmit={e => (elementSelect) ? mostrarAlert(e) : guardar}>
                            <InputValidation title="Calle" type="calle" placeholder="Calle"
                                tooltipName="tooltipCalle" tooltipText="Máximo 60 letras, números, espacio y/o signos de puntuación . , - & #"
                                maxLength={60} refValue={calleRef} defaultValue={infoDomicilio ? infoDomicilio.calle : ''} required/>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <InputValidation title="Número Exterior" type="numIntExt" placeholder="Número Exterior"
                                        tooltipName="tooltipNumExt" tooltipText="Máximo 6 letras, números, espacio y/o guión"
                                        maxLength={6} refValue={numExteriorRef} defaultValue={infoDomicilio ? infoDomicilio.numExt : ''} required/>
                                </div>
                                <div className="form-right">
                                    <InputValidation title="Número Interior" type="numIntExt" placeholder="Número Interior"
                                        tooltipName="tooltipNumInt" tooltipText="Máximo 6 letras, números, espacio y/o guión"
                                        maxLength={6} refValue={numInteriorRef} defaultValue={infoDomicilio ? infoDomicilio.numInt : '' } optional={true}/>
                                </div>
                            </div>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <InputValidation title="Código Postal" type="cp" placeholder="Código Postal"
                                        tooltipName="tooltipCP" tooltipText="5 números" maxLength={5} refValue={cpRef} onlyNumbers
                                        defaultValue={infoDomicilio ? infoDomicilio.codPostal ? infoDomicilio.codPostal.codigopCp : '' : ''}
                                        action={buscaCP} required/>
                                </div>
                                <div className="form-right">
                                    <SelectValidation title="Colonia" arrayOpciones={colonias} keyOpcion="codigopColonia"
                                        tooltipName="tooltipColonia" tooltipText="Seleccione la colonia después de escribir el código postal"
                                        refValue={coloniaRef} defaultValue={infoDomicilio ? infoDomicilio.codPostal ? infoDomicilio.codPostal.codigopId : '' : ''} required/>
                                </div>
                            </div>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <InputValidation title="Ciudad" type="ciudad" placeholder="Ciudad" refValue={ciudadRef}
                                        tooltipName="tooltipCiudad" tooltipText="Se autocompleta después de escribir el código postal"
                                        defaultValue={infoDomicilio ? infoDomicilio.codPostal ? infoDomicilio.codPostal.ciudadId.ciudadNom : '' : ''}
                                        disabled required/>
                                </div>
                                <div className="form-right">
                                    <InputValidation title="Estado" type="estado" placeholder="Estado"
                                        tooltipName="tooltipEstado" tooltipText="Se autocompleta después de escribir el código postal"
                                        refValue={estadoRef} defaultValue={infoDomicilio ? infoDomicilio.codPostal ? infoDomicilio.codPostal.ciudadId.estadoId.estadoNom : '' : ''} disabled required/>
                                </div>
                            </div>
                            <BtnsInfoAdicional numForm={3} irInfo={irInfo} elementSelect={(elementSelect) ? true : false}/>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                                </div>
                                <div className="form-right">
                                    {(elementSelect && infoDomicilio !== null)
                                    ?   isLoading
                                        ?   <SpinnerOval />
                                        :   <Button variant="contained" className="btn-color" type="submit">{(!infoDomicilio.calle) ? 'Guardar' : 'Actualizar' }</Button>
                                    :   null
                                    }
                                    {!elementSelect
                                    ?   isLoading
                                        ?   <SpinnerOval />
                                        :   <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
                                    :   null
                                    }
                                </div>
                            </div> 
                        </form>
                        {   (elementSelect && infoDomicilio !== null) &&
                            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={!infoDomicilio.calle ? registro_sera_guardado : registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaInfo}/>
                        }
                    </Fragment>
                :   null
            }
        </Fragment>
    );
}
 
export default FormDomicilio;