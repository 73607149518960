import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const TooltipElement = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

const TooltipComponent = ({ children, tooltipText, position }) => {
    //positions:
    //top-start, top, top-end
    //left-start, left, left-end
    //right-start, right, right-end
    //bottom-start, bottom, bottom-end
    return (
        <TooltipElement title={tooltipText} placement={(position) ? position : 'bottom'}>
            { children }
        </TooltipElement>
    );
}
 
export default TooltipComponent;