import React, { useRef, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import { amountToFloat, validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';

const FormAddConceptosDeduccion = ({ empresaRef, quincenasRef, elaboroRef, nominaId, indicadoresSelected, setIndicadoresSelected, totalPD, cargaPagosUnicos, minimoLiquidez, montoMinimo, usuario }) => {

    const [indicadores, setIndicadores] = useState(null);
    const [indicadorSelected, setIndicadorSelected] = useState([]);
    const [errorIndicador, setErrorIndicador] = useState(null);
    const [idDelete, setIdDelete] = useState(1);
    const [disabledInput, setDisabledInput] = useState(true);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const [openAlertW, setOpenAlertW] = useState(false);
    const idForm = (cargaPagosUnicos) ? 'FormAgregaIndicadores' : 'FormAgregaIndicadoresDeduccion';

    const montoRef = useRef('');

    const { paterno: pApellido, materno: sApellido, nombre } = usuario;

    const nombreUsuario = `${(pApellido) ? `${pApellido} ` : ''}${(sApellido) ? `${sApellido} ` : ''}${nombre}`;

    useEffect(() => {
        const url = (cargaPagosUnicos)
        ?   `${servicios_grupohit_nomina}/indicadores/getbynomina/${nominaId}`
        :   `${servicios_grupohit_nomina}/indicadores/getdedpernomina/${nominaId}/2`;

        axios.get(url, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setIndicadores(res.data.dataResponse);
                    break;
                case 404:
                    setIndicadores([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nominaId]);

    const validaMonto = e => {
        e.preventDefault();
        if(validarSubmit(idForm)) {
            const montoIndicador = amountToFloat(montoRef.current.value);
            if(indicadorSelected.indicador.naturaleza.id === 2 && (totalPD-montoIndicador) <= 0) {
                if(minimoLiquidez && (montoMinimo > totalPD)) {
                    setMessageAlertSyE("El monto del préstamo disminuye la liquidez por debajo del monto mínimo configurado para todos los empleados y debe ser menor al total de liquidez.");
                } else {
                    setMessageAlertSyE("El monto del préstamo debe ser menor al total de liquidez.");
                }
                setOpenAlertSyE(true);
                return false;     
            }

            if(!cargaPagosUnicos) {
                if(minimoLiquidez && montoMinimo) {
                    const montoIndicador = amountToFloat(montoRef.current.value);
                    if(indicadorSelected.indicador.naturaleza.id === 2 && (totalPD-montoIndicador) < montoMinimo) {
                        setOpenAlertW(true);
                        return false;
                    }
                }
            }
            agregarConcepto();
        }
    };

    const agregarConcepto = () => {
        const montoIndicador = amountToFloat(montoRef.current.value);
        if(validarSubmit(idForm) && indicadorSelected !== null) {
            if(indicadorSelected.indicador.naturaleza.id === 2 && (totalPD-montoIndicador) <= 0) {
                setMessageAlertSyE("El monto del préstamo debe ser menor al total de liquidez.");
                setOpenAlertSyE(true);
                return;     
            }

            setIndicadoresSelected([
                ...indicadoresSelected,
                {
                    aplica: true,
                    cantidad: 1,
                    indicadorId: indicadorSelected.indicador.indicadorId,
                    nomindId: indicadorSelected.id,
                    indicadorSat: indicadorSelected.indicador.indicadorSat,
                    monto: montoIndicador,
                    nominaId: indicadorSelected.nominaid,
                    nominaClave: indicadorSelected.nomClave,
                    nominaIndicador: indicadorSelected.nomIndicador,
                    tiponatuId: indicadorSelected.indicador.naturaleza.id,
                    tiponatuNom: indicadorSelected.indicador.naturaleza.tipoNatuNom,
                    numPago: 0,
                    empprestNumpagos: 0,
                    idDelete
                }
            ]);
            setIdDelete(idDelete+1);
            setIndicadorSelected(null);
            montoRef.current.value = '';
            setDisabledInput(true);
        } else {
            if(indicadorSelected === null) {
                setErrorIndicador(true);
            }
        }
    }; 

    const guardarIndicador = value => {
        if(value) {
            setDisabledInput(false);
        } else {
            setDisabledInput(true);
            montoRef.current.value = '';
        }
        setIndicadorSelected(value);
    };
    
    return (
        <Fragment>
        {(indicadores)
        ?   <Fragment>
                {(empresaRef && quincenasRef && elaboroRef) &&
                <form id="form-datos">
                    <div className="row">
                        <div className="col col-lg-5 col-md-5 col-sm-4">
                            <InputValidation
                                title="Empresa" type="empresa" placeholder="Escriba el Nombre de la Empresa"
                                tooltipText="Máximo 64 letras, números, espacio y/o ." maxLength={64}
                                refValue={empresaRef} alfanumeric='. ' required
                            />
                        </div>
                        <div className="col col-lg-2 col-md-2 col-sm-4">
                            <InputValidation
                                title="Quincenas" type="word1-16" placeholder="Escriba el Número de Quincenas"
                                tooltipText="Máximo 16 números, letras y/o espacio" maxLength={16}
                                refValue={quincenasRef} alfanumeric=' ' required
                            />
                        </div>
                        <div className="col col-lg-5 col-md-5 col-sm-4">
                            <InputValidation
                                title="Elaboró" type="nameDot" placeholder="Escriba el Nombre completo"
                                tooltipText="Máximo 64 letras y/o espacio" maxLength={64}
                                defaultValue={nombreUsuario} refValue={elaboroRef} onlyLetters='. ' required
                            />
                        </div>
                    </div>
                </form>
                }
                <form id={idForm} onSubmit={e => validaMonto(e)}>
                    <div className="row">
                        <div className="col col-lg-5 col-md-5 col-sm-4">
                            <AutocompleteComponent
                                id="indicador"
                                title="Indicador"
                                options={indicadores}
                                optionKey="nomClave"
                                optionListView="nomIndicador"
                                action={guardarIndicador}
                                placeholder="Seleccione el Indicador"
                                tooltipText="Busque el Indicador, por nombre o clave, en el campo de autocompletado"
                                tooltipName="tooltipIndicadorRSN"
                                error={errorIndicador}
                                value={indicadorSelected}
                                required
                            />
                        </div>
                        <div className="col col-lg-5 col-md-5 col-sm-4">
                            <InputValidation
                                title="Monto" type="monto" placeholder="Escriba el Monto"
                                tooltipText="Máximo 7 números y 2 decimales" maxLength={13}
                                refValue={montoRef} onlyNumbers='.' onBlurDecimal={2} isAmount
                                disabled={disabledInput} required
                            />
                        </div>
                        <div className="col col-lg-2 col-md-2 col-sm-4 align-self-end">
                            <button type="submit" className="btn-autocomplete btnMax mb-2">Agregar</button>
                        </div>
                    </div>
                </form>
            </Fragment>
        :   <SpinnerOval />
        }
        {(!cargaPagosUnicos) &&
            <AlertWarning show={openAlertW} SetopenALert={setOpenAlertW} text="El total de liquidez esta por debajo del monto mínimo configurado para todos los empleados. ¿Desea continuar?"  textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={agregarConcepto}/>
        }
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    usuario: state.SessionReducer.usuario,
});
 
export default connect(mapStateToProps, null)(FormAddConceptosDeduccion);
