import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';

import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

import './stylesForm.css';
import ParametrosNominasOrg from './ParametrosNominasOrg';

const FormularioNominasOrg = ({ error, enviarNomina, elementSelect, actualizarNomina, informacion, isLoading }) => {
    const [ activo, setActivo ] = useState( 'formulario' );
    const [ alertUpdate, setAlertUpdate ] = useState( false );
    const [ paramNom, setParamNom ] = useState( false );

    const nominaClaveRef = createRef('');
    const nominaNombreRef = createRef('');
    const nominaNomCortoRef = createRef('');
    const nominaDescripRef = createRef('');

    const periodicidadRef = createRef('');
    const origenRecursoRef = createRef('');
    const regimenFiscalRef = createRef('');
    const tipoRegimenRef = createRef('');
    const paramImptoPeriodoRef = createRef('');

    const enviar = ( paramNom ) => {
        if( validarSubmit( 'NominaOrg' ) ){
            const nominaClave = nominaClaveRef.current.value;
            const nominaNombre = nominaNombreRef.current.value;
            const nominaNomCorto = nominaNomCortoRef.current.value;
            const nominaDescrip = nominaDescripRef.current.value;

            const periodicidad = parseInt(periodicidadRef.current.value);
            const origenRecurso = parseInt(origenRecursoRef.current.value);
            const regimenFiscal = parseInt(regimenFiscalRef.current.value);
            const tipoRegimen = parseInt(tipoRegimenRef.current.value);
            const paramImptoPeriodo = parseInt(paramImptoPeriodoRef.current.value);

            enviarNomina({ nominaClave, nominaNombre, nominaNomCorto, nominaDescrip, periodicidad, origenRecurso, regimenFiscal, tipoRegimen, paramImptoPeriodo, paramNom });
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'NominaOrg' ) ){
            const nominaClave = nominaClaveRef.current.value;
            const nominaNombre = nominaNombreRef.current.value;
            const nominaNomCorto = nominaNomCortoRef.current.value;
            const nominaDescrip = nominaDescripRef.current.value;

            const periodicidad = parseInt(periodicidadRef.current.value);
            const origenRecurso = parseInt(origenRecursoRef.current.value);
            const regimenFiscal = parseInt(regimenFiscalRef.current.value);
            const tipoRegimen = parseInt(tipoRegimenRef.current.value);
            const paramImptoPeriodo = parseInt(paramImptoPeriodoRef.current.value);

            actualizarNomina({ nominaClave, nominaNombre, nominaNomCorto, nominaDescrip, periodicidad, origenRecurso, regimenFiscal, tipoRegimen, paramImptoPeriodo, paramNom }, elementSelect.id);
        }
    };
    /* ---------------------------------- Funciones para cambiar formularios (Siguiente & Atras) según corresponda ---------------------------------- */
    const siguiente = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'NominaOrg' ) ){
            setActivo( 'parametros' );
        }
    }
    const atras = () => {
        setActivo( 'formulario' );
    }

    const alert = ( paramNom ) => {
        setParamNom( paramNom );
        setAlertUpdate( true );
    }
    /* ---------------------------------------------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <form id='NominaOrg' onSubmit={siguiente} style={{ display: activo === 'formulario' ? 'block' : 'none' }}>
                <InputValidation title="Clave" type="claveNominaOrg" placeholder="Escriba la clave de la nómina" maxLength='5'
                    tooltipText={'Máximo 5 letras, números y/o signo de puntuación -'} tooltipName={'tooltipCveNomOrg'}
                    refValue={nominaClaveRef} defaultValue={ elementSelect ? elementSelect.nominaClave : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Nombre" type="nombreNominaOrg" placeholder="Escriba el nombre de la nómina" maxLength='128'
                            tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( )'} tooltipName={'tooltipTipoNomNomOrg'}
                            refValue={nominaNombreRef} defaultValue={ elementSelect ? elementSelect.nominaNombre : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Nombre Corto" type="nomCortoNominaOrg" placeholder="Escriba el nombre corto de la nómina" maxLength='32'
                            tooltipText={'Máximo 32 letras, números, espacios y/o signos de puntuación . , - ( )'} tooltipName={'tooltipTipoCortNomOrg'}
                            refValue={nominaNomCortoRef} defaultValue={ elementSelect ? elementSelect.nominaNomCorto : '' } required/>
                    </div>
                </div>

                <InputValidation title="Descripción" type="descNominaOrg" placeholder="Escriba la descripción de la nómina" maxLength='1024'
                    tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( )'} tooltipName={'tooltipDescNomOrg'}
                    refValue={nominaDescripRef} defaultValue={ elementSelect ? elementSelect.nominaDescrip : '' } required/>

                <SelectValidation title="Régimen Fiscal" arrayOpciones={informacion.regimenFiscales} keyOpcion="regFiscalDesc"
                    refValue={regimenFiscalRef} defaultValue={ elementSelect ? elementSelect.regimenFiscal.id : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodicidad" arrayOpciones={informacion.tiposPeriodicidad} keyOpcion="perdenomNombre"
                            refValue={periodicidadRef} defaultValue={ elementSelect ? elementSelect.periodicidad.id : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Esquema de Pago" arrayOpciones={informacion.tiposRegimen} keyOpcion="regimenDesc"
                            refValue={tipoRegimenRef} defaultValue={ elementSelect ? elementSelect.tipoRegimen.id : '' } required/>
                    </div>
                </div>

                <SelectValidation title="Origen del Recurso" arrayOpciones={informacion.origenRecursos} keyOpcion="origenRecDesc"
                    refValue={origenRecursoRef} defaultValue={ elementSelect ? elementSelect.origenRecurso.id : '' } required/>

                <SelectValidation title="Tabla para Cálculo de Impuesto del Periodo" arrayOpciones={informacion.tiposPeriodo} keyOpcion="periodoNombre"
                    refValue={paramImptoPeriodoRef} defaultValue={ elementSelect ? elementSelect.paramImpto.paramImptoPeriodo : '' } required/>

                <Button variant="contained" className="btn-color" type="submit"> Siguiente </Button>
            </form>

            <div style={{ display: activo === 'parametros' ? 'block' : 'none' }}>
                <ParametrosNominasOrg error={error} atras={atras} elementSelect={elementSelect} action={ elementSelect ? alert : enviar } isLoading={isLoading}/>
            </div>

            <Alertwarning show={alertUpdate} SetopenALert={setAlertUpdate} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar} />
        </React.Fragment>
    );
};

export default FormularioNominasOrg;