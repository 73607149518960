import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import { prestamos_ } from '../../../../../../Constants/Constants';
import InformacionPrestamos from './InformacionPrestamos';
import MovimientosExcel from '../Variables/DataTableMovimientos/MovimientosExcel';

const ModalAddPrestamosExcel = ({ openAdd, setOpenAdd, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const cerrarModal = () => {
        setOpenAdd( false );
    }

    return (
        <ModalService title={`Carga Masiva de ${prestamos_}`} parentNode={nombreModulo} minWidth="33%" maxWidth="35%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <b>Descarga</b>
                {/*<div>1. Seleccione la nómina.</div>*/}
                <div>1. Baje el archivo.</div>
                <div>2. Realice los cambios necesarios en el archivo descargado.</div>
                <br/>
                <b>Carga</b>
                <div>1. Busque el archivo con extensión .csv.</div>
                <div>2. Una vez que el sistema lo analice:</div>
                <div>Si no se presentan incidencias, haga la carga. De lo contrario, modifique el archivo y repita el procedimiento.</div>
                <InformacionPrestamos
                    idModulo={nombreModulo}
                    errorTable={errorTable}
                    successTable={successTable}
                    nombreModulo={prestamos_}
                    informacion={informacionAdicional}
                />
                <hr/>
                <MovimientosExcel
                    idModulo={nombreModulo}
                    errorTable={errorTable}
                    successTable={successTable}
                    informacion={informacionAdicional}
                    nombreModulo={prestamos_}
                />
        </ModalService>
    );
};

export default ModalAddPrestamosExcel;