import React from 'react';
import { connect } from 'react-redux';
import PageTitleBar from '../Layouts/PageTitleBar';
import Tablero from './Tablero/Tablero';
import MostrarFormulario from '../Helpers/HelperNavegacion';
import ModuloRestringido from '../Helpers/ModuloRestringido';

function Contenidos({ navegadoresStore, componenteActivoID, openMenu, relacionLaboral, modulosApi, socket }) {

    const show = { display: 'block', minHeight: '69vh'};
    const hide = { display: 'none' };

    return (
        <React.Fragment>
            <style>
                {
                    (openMenu)
                    ?
                    `.styles_overlay__CLSq- {
                            background: rgba(0, 0, 0, 0.2);
                            padding-left: 264px;
                            transition: padding-left 0.2s ease-in-out;
                            -webkit-transition: padding-left 0.2s  ease-out;
                            -moz-transition: padding-left 0.2s  ease-out;
                            -o-transition: padding-left 0.2s  ease-out;
                        }`
                    :
                    `.styles_overlay__CLSq- {
                            background: rgba(0, 0, 0, 0.2);
                            padding-left: 24px;
                            transition: padding-left 0.2s ease-in-out;
                            -webkit-transition: padding-left 0.2s  ease-out;
                            -moz-transition: padding-left 0.2s  ease-out;
                            -o-transition: padding-left 0.2s  ease-out;
                        }`
                }
            </style>
            <div style={ componenteActivoID === 1 ? show : hide }>
                { modulosApi.length > 0 && <Tablero relacionLaboral={relacionLaboral} modulosApi={modulosApi}/> }
            </div>
            { Object.keys(navegadoresStore).map(key => {                
                if( componenteActivoID === navegadoresStore[key].id ){
                    var contenido = document.getElementById( `Contenido${navegadoresStore[key].nombre}` );
                    if( contenido ){
                        if( contenido.getElementsByClassName( 'styles_overlay__CLSq-' ).length === 0 ){
                            document.getElementById( 'html' ).style['position'] = "";
                            document.getElementById( 'html' ).style['overflow'] = "";
                        }else{
                            document.getElementById( 'html' ).style['position'] = ""; //"fixed"
                            document.getElementById( 'html' ).style['overflow'] = "hidden";
                        }
                    }
                }else{
                    document.getElementById( 'html' ).style['position'] = "";
                    document.getElementById( 'html' ).style['overflow'] = "";
                }
                return(
                    <div key={navegadoresStore[key].nombre} id={`Contenido${navegadoresStore[key].nombre}`} style={ componenteActivoID === navegadoresStore[key].id ? show : hide }>
                        <div style={{ position: "relative", zIndex: "100" }}>
                            <PageTitleBar title={navegadoresStore[key].nombre} path={navegadoresStore[key].ruta}/>
                            {   (navegadoresStore[key].bloqueado && navegadoresStore[key].bloqueado.length > 0)
                                ?   <ModuloRestringido id={navegadoresStore[key].id} position={key} restricciones={navegadoresStore[key].bloqueado}/>
                                :   <MostrarFormulario
                                        IDModulo={navegadoresStore[key].id}
                                        permisos={navegadoresStore[key].permisos}
                                        socket={socket}
                                        modulosApi={modulosApi}
                                    />
                            }
                        </div>
                    </div>
                )
            })}
        </React.Fragment>
    );
}

/* ------------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
const mapDispatchToProps = {};
/* ------------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, mapDispatchToProps)(Contenidos);

