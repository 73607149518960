import React, { Component, Fragment } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { isAuthenticaded, cerrarSession } from '../../Services/AuthService';
import { sao } from '../../Constants/Constants';

class RouteProtected extends Component {
    render() {
        return (
            isAuthenticaded()
            ? <Route {...this.props} />
            :   <Fragment>
                    { cerrarSession }
                    {(sao)
                    ?   <Redirect to="/sao/acceso"/>
                    :   <Redirect to="/acceso"/>
                    }
                </Fragment>
        )
    }
}

export default withRouter( RouteProtected );