import React, { useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import UserBlock from './UserBlock';
import ModulosBlock from './ModulosBlock';
import PerfilModal from '../../Perfil/PerfilModal';
import './Drawerstyles.css'

//import displayWindowSize from '../../Helpers/DisplayWindowSize';

function MenuDrawer({ classes, openMenu, modulosApi, closeSession, usuario, setUsuario, steps, run, logotipo, imagenPerfil, setImagenPerfil }) {

    const [modalProfile, setModalProfile] = useState(false);
    const toogleProfile = () => { setModalProfile(!modalProfile) }
    
    /* -------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <style>
                {
                    (openMenu)
                    ?  `
                            .sweet-alert {
                                margin: 0 0 0 240px !important;
                                transition: all 0.2s ease-in-out !important;
                                -webkit-transition: all 0.2s ease-out !important;
                                -moz-transition: all 0.2s ease-out !important;
                                -o-transition: all 0.2s ease-out !important;
                            }
                            `
                            /*.back {
                                background-color:#EFF4FD!important;
                                max-width: ${displayWindowSize().width-329}px !important;
                            }`*/
                    :   `
                            .sweet-alert {
                                margin: 0 0 0 0 !important;
                                transition: all 0.2s ease-in-out !important;
                                -webkit-transition: all 0.2s ease-out !important;
                                -moz-transition: all 0.2s ease-out !important;
                                -o-transition: all 0.2s ease-out !important;
                            }`
                            /*.back{
                                background-color:#EFF4FD!important;
                                max-width: ${displayWindowSize().width}px !important;
                            }`*/
                }
            </style>
            <Drawer className={classes.drawer} variant="persistent" anchor="left" classes={{paper: classes.drawerPaper,}} open={openMenu}>
                <div className={classes.toolbar}>
                    <div id="MenuDrawerLogo" className="text-center">
                        { logotipo && <img src={`data:image/jpeg;base64, ${logotipo}`} className="imageLogo" alt="site-logo"/> }
                    </div>
                </div>
                <UserBlock steps={steps}  run={run} closeSession={closeSession} toogleProfile={toogleProfile} usuario={usuario} imagenPerfil={imagenPerfil}/>
                <Divider />
                <ModulosBlock modulos={modulosApi} steps={steps} run={run}/>
            </Drawer>
            <PerfilModal modalProfile={modalProfile} toogleProfile={toogleProfile} usuario={usuario} setUsuario={setUsuario} imagenPerfil={imagenPerfil} setImagenPerfil={setImagenPerfil} openMenu={openMenu}/>
        </React.Fragment>
    );
}

export default (MenuDrawer);