const diccionario = {
    nombremodulo        : "Módulo",
    operacion           : "Operación",
    date                : "Fecha",
    nivlaboralClave     : "Clave Nivel Laboral",
    nivlaboral_clave    : "Clave Nivel Laboral",
    baseDescrip         : "Descripción Base",
    baseNom             : "Base",
    baseClave           : "Clave de Base",
    clasificaNom        : "Clasificación",
    clave               : "Clave",
    indicadorClave      : "Clave Concepto",
    indicadorNom        : "Concepto",
    indicador_nom       : "Concepto",
    indicadorSat        : "Indicador SAT",
    indicador_sat       : "Indicador SAT",
    recibo              : "Aparece en Recibo",
    rutinaDesc          : "Descripción Rutina",
    rutinaNom           : "Rutina",
    tipoNatuNom         : "Tipo de Naturaleza",
    nomClave            : "Nombre Clave",
    nomIndicador        : "Concepto",
    nomRecibo           : "Recibo",
    indicador           : "Concepto",
    zonaNom             : "Zona",
    zonaDesripcion      : "Descripción Zona",
    topeveces           : "Tope en Veces",
    tope_veces          : "Tope en Veces",
    topVeces            : "Tope en Veces",
    nombre              : "Nombre",
    puestoCve           : "Clave Puesto",
    puesto_cve          : "Clave Puesto",
    puestoNom           : "Puesto",
    puesto_nom          : "Puesto",
    puestoDescrip       : "Descripción Puesto",
    puesto_descrip      : "Descripción Puesto",
    monto               : "Monto",
    fechaini            : "Fecha Inicial",
    fechaInicial        : "Fecha Inicial",
    fechaInicio         : "Fecha Inicial",
    empcpresfecini      : "Fecha Inicial Clave Presupuestal",
    empcpres_fecini     : "Fecha Inicial Clave Presupuestal",
    fechafin            : "Fecha Final",
    fechaFinal          : "Fecha Final",
    empcpresfecfin      : "Fecha Final Clave Presupuestal",
    contratoSaoNom      : "Nombre Contrato",
    contratosao_nom     : "Nombre Contrato",
    tipoContratoClave   : "Clave Tipo de Contrato",
    tipocontrato_clave  : "Clave Tipo de Contrato",
    tipoContratoDesc    : "Descripción Tipo de Contrato",
    tipocontrato_descrip: "Descripción Tipo de Contrato",
    contratoSaoFechas   : "Fechas Contrato",
    contratosao_fechas  : "Fechas Contrato",
    subsidio_fecfin     : "Fecha Final",
    username            : "Usuario",
    usuario_nom         : "Nombre",
    usuarioNom          : "Nombre",
    usuario_pat         : "Primer Apellido",
    usuarioPat          : "Primer Apellido",
    usuario_mat         : "Segundo Apellido",
    usuarioMat          : "Segundo Apellido",
    usuario_cel         : "Teléfono Celular",
    usuarioCel          : "Teléfono Celular",
    usuario_fecfin      : "Fecha Final",
    usuarioFecfin       : "Fecha Final",
    usuario_descrip     : "Descripción",
    usuarioDescrip      : "Descripción",
    usuario_email       : "Correo Electrónico",
    usuarioEmail        : "Correo Electrónico",
    rol_admin           : "Rol de Administrador",
    usuario_numintentos : "Número de Intentos",
    usuarioNumintentos  : "Número de Intentos",
    superior            : "Superior",
    lastAccess          : "Ultimo Acceso",
    usuario_ultacceso   : "Último Acceso",
    usuarioUltacceso    : "Último Acceso",
    verificado          : "Verificado",
    usuario_verificado  : "Verificado",
    usuarioVerificado   : "Verificado",
    secuencia           : "Secuencia",
    tour                : "Tour",
    usuario_tour        : "Tour",
    usuarioTour         : "Tour",
    tyc                 : "Términos y Condiciones",
    usuario_tyc         : "Términos y Condiciones",
    usuarioTyc          : "Términos y Condiciones",
    periodoNombre       : "Nombre del Periodo",
    periodo_feccorte    : "Fecha de Corte Periodo",
    periodo_fecfin      : "Fecha Final Periodo",
    periodo_fecini      : "Fecha Inicial Periodo",
    periodo_mes         : "Mes",
    periodo_peranio     : "Periodo Año",
    periodo_permes      : "Periodo Mes",
    periodo_anio        : "Año",
    periodo_diasimss    : "Días IMSS por Periodo",
    periodo_diasnom     : "Días Nómina por Periodo",
    fechaIni            : "Fecha Inicial",
    fecIni              : "Fecha Inicial",
    fecini              : "Fecha Inicial",
    impto_fecini        : "Fecha Inicial",
    subdio_fecini       : "Fecha Inicial",
    fechaFin            : "Fecha Final",
    fecFin              : "Fecha Final",
    fecfin              : "Fecha Final",
    impto_fecfin        : "Fecha Final",
    limiteInf           : "Límite Inferior",
    impto_liminf        : "Límite Inferior",
    subdio_liminf       : "Límite Inferior",
    limiteSup           : "Límite Superior",
    impto_limsup        : "Límite Superior",
    subdio_limsup       : "Límite Superior",
    cuotaFija           : "Cuota Fija",
    impto_cuotafija     : "Cuota Fija",
    porcentaje          : "Porcentaje",
    imptoisn_porc       : "Porcentaje",
    impto_porcentaje    : "Porcentaje",
    quinAntAnios        : "Años de Antigüedad",
    prioridad           : "Prioridad",
    reporteNom          : "Nombre Reporte",
    reporteDescrip      : "Descripción Reporte",
    created             : "Creado",
    perdenomNombre      : "Nombre",
    perdenomDias        : "Días",
    dias                : "Días",
    perdenomClave       : "Clave",
    param_quinquenio    : "Quinquenio",
    paramQuinquenio     : "Quinquenio",
    param_redondeo      : "Redondeo",
    paramRedondeo       : "Redondeo",
    nominaClave         : "Clave Nómina",
    nominaNomCorto      : "Nombre Corto Nómina",
    nominaNombre        : "Nombre Nómina",
    nominaDescrip       : "Descripción Nómina",
    nomina              : "Nómina",
    paramCalculo        : "de Cálculo",
    paramAjusteImpto    : "Ajuste Impuesto",
    param_ajusteimpto   : "Ajuste Impuesto",
    param_timbrado      : "Timbrado",
    paramTimbrado       : "Timbrado",
    paramImptoPeriodo   : "Impuesto Periodo",
    paramImptoMensual   : "Impuesto Mensual",
    paramImptoAnual     : "Impuesto Anual",
    regFiscalClave      : "Clave Régimen Fiscal",
    regfiscal_clave     : "Clave Régimen Fiscal",
    regFiscalDesc       : "Descripción Régimen Fiscal",
    regfiscal_descrip   : "Descripción Régimen Fiscal",
    regimenClave        : "Clave Régimen",
    regimen_clave       : "Clave Régimen",
    regimenDesc         : "Descripción Régimen",
    regimen_descrip     : "Descripción Régimen",
    origenRecClave      : "Clave Origen del Recurso",
    origenrec_clave     : "Clave Origen del Recurso",
    origenRecDesc       : "Descripción Origen del Recurso",
    origenrec_descrip   : "Descripción Origen del Recurso",
    diaNombre           : "Día",
    turnoNom            : "Turno",
    turnoClave          : "Clave Turno",
    entrada             : "Entrada",
    salcomida           : "Salida Comida",
    regcomida           : "Regreso Comida",
    salida              : "Salida",
    param_pagsubultper  : "Pago de Subsidio",
    paramPagSubUltPer   : "Pago de Subsidio",
    diasAguinaldoAnio   : "Días Aguinaldo por Año",
    diasBonoAnio        : "Días Bono por Año",
    periodoPagoSem1     : "Periodo Pago 1er Semestre",
    periodoPagoSem2     : "Periodo Pago 2do Semestre",
    diasPrimaAnio       : "Días Prima Vacacional por Año",
    diasAjuste          : "Días de Ajuste",
    periodoPago         : "Periodo Pago",
    diasPagoPeriodo     : "Días a Pagar en el Periodo",
    anioIni             : "De (Años)",
    anioFin             : "Hasta (Años)",
    diasSem1            : "Días 1er Semestre",
    diasSem2            : "Días 2do Semestre",
    plantillaClave      : "Clave Plantilla",
    plantilla_clave     : "Clave Plantilla",
    plantillaNom        : "Nombre Plantilla",
    plantilla_nom       : "Nombre Plantilla",
    plantillaDesc       : "Descripción Plantilla",
    plantilla_desc      : "Descripción Plantilla",
    empleado_clave      : "Número Único de Empleado",
    reanudaFechas       : "Reanuda",
    reanuda_fechas      : "Reanuda",
    empleadonom         : "Nombre(s)",
    empleado_nom        : "Nombre(s)",
    empleadoclave       : "Número Único de Empleado",
    empleadopat         : "Primer Apellido",
    apellidoPat         : "Primer Apellido",
    empleado_pat        : "Primer Apellido",
    empleadomat         : "Segundo Apellido",
    empleado_mat        : "Segundo Apellido",
    apellidoMat         : "Segundo Apellido",
    empleadofecnac      : "Fecha de Nacimiento",
    fechaNac            : "Fecha de Nacimiento",
    empleado_fecnac     : "Fecha de Nacimiento",
    empleadocurp        : "CURP",
    empleado_curp       : "CURP",
    curp                : "CURP",
    empleadorfc         : "RFC",
    empleadonss         : "NSS",
    empleado_nss        : "NSS",
    empleadoGenero      : "Género",
    empleado_genero     : "Género",
    edoCivilnom         : "Estado Civil",
    edocilvil_nom       : "Estado Civil",
    estadoNom           : "Estado",
    estado              : "Estado",
    paisNom             : "País",
    formapagoCve        : "Clave Forma de Pago",
    formapagoNom        : "Forma de Pago",
    folio               : "Folio",
    folioAlta           : "Folio",
    esMadre             : "Madre",
    equipo              : "Equipo",
    numeroPagos         : "Número de Pagos",
    importe             : "Importe",
    subdio_importe      : "Importe",
    empPrestIniPagos    : "Inicio de Pagos",
    cantidad            : "Cantidad",
    polAusenciaCant     : "Cantidad",
    acumuladoClave      : "Clave Acumulado",
    acumulado_clave     : "Clave Acumulado",
    acumuladoNombre     : "Acumulado",
    acumulado_nombre    : "Acumulado",
    acumuladoDescripcion: "Descripción Acumulado",
    acumulado_descripcion: "Descripción Acumulado",
    fechaAplicacion     : "Fecha de Aplicación",
    fecAplicacion       : "Fecha de Aplicación",
    auseFechaAplica     : "Fecha de Aplicación",
    ausefecha_aplica    : "Fecha de Aplicación",
    tipoextNom          : "Extensión del Documento",
    tipoext_nom         : "Extensión del Documento",
    tipodocNom          : "Documento",
    tipodoc_nom         : "Documento",
    tipodocDesc         : "Descripción Documento",
    tipodoc_desc        : "Descripción Documento",
    tipodocCant         : "Cantidad Documento",
    tipodoc_cant        : "Cantidad Documento",
    tempdocNota         : "Nota Documento",
    tipodomNom          : "Tipo de Domicilio",
    tipodom_nom         : "Tipo de Domicilio",
    domCiaCalle         : "Calle",
    empdomic_calle      : "Calle",
    calle               : "Calle",
    domCiaNumInt        : "Número Interior",
    numInt              : "Número Interior",
    numeroInt           : "Número Interior",
    domCiaNumExt        : "Número Exterior",
    numExt              : "Número Exterior",
    numeroExt           : "Número Exterior",
    empdomic_numext     : "Número Exterior",
    telefonoCelular     : "Teléfono Celular",
    telefonoFijo        : "Teléfono Fijo",
    domCiaTel           : "Teléfono",
    domCiaExt           : "Extención",
    empPrestNota        : "Nota Préstamo",
    bancoCve            : "Clave Banco",
    banco_clave         : "Clave Banco",
    bancoNom            : "Banco",
    banco_nom           : "Banco",
    bancoDescrip        : "Descripción Banco",
    banco_descrip       : "Descripción Banco",
    giroNom             : "Giro",
    giro_nom            : "Giro",
    escolaridadNom      : "Escolaridad",
    escolaridad_nom     : "Escolaridad",
    codigopCp           : "Código Postal",
    codigop_cp          : "Código Postal",
    codigopColonia      : "Colonia",
    codigop_colonia     : "Colonia",
    ciudadNom           : "Ciudad",
    registroPatClave    : "Registro Patronal",
    registropat_clave   : "Registro Patronal",
    segSocClave         : "Clave Seguridad Social",
    segsoc_clave        : "Clave Seguridad Social",
    segSocNom           : "Seguridad Social",
    segsoc_nom          : "Seguridad Social",
    riesgoPtoClave      : "Clave Riesgo",
    riesgopto_clave     : "Clave Riesgo",
    riesgoPtoDesc       : "Descripción Riesgo",
    riesgopto_descrip   : "Descripción Riesgo",
    modalidad           : "Modalidad",
    sueldo              : "Sueldo",
    nombramiento        : "Nombramiento",
    ausentismoCve       : "Clave Ausentismo",
    ausentismo_clave    : "Clave Ausentismo",
    ausentismoNom       : "Ausentismo",
    ausentismo_nom      : "Ausentismo",
    tipojornada_nom     : "Tipo de Jornada",
    cpresupuestalclave  : "Clave Presupuestal",
    cpresupuestal_clave : "Clave Presupuestal",
    cpresupuestal_nom   : "Proyecto",
    cpresupuestalnom    : "Proyecto",
    ciaRfc              : "RFC",
    empleado_rfc        : "RFC",
    ciaRazonSocial      : "Razón Social",
    cia_razonsocial     : "Razón Social",
    ciaNomCorto         : "Nombre Corto",
    ciaFecConst         : "Fecha de Constitución",
    ciaWeb              : "Sitio Web",
    cia_web             : "Sitio Web",
    ciaUr               : "Clave Unidad Responsable",
    udejecutoraclave    : "Clave Unidad Ejecutora",
    udejecutoranom      : "Unidad Ejecutora",
    Programaclave       : "Clave Programa",
    Programanom         : "Programa",
    subProgramaClave    : "Clave Subprograma",
    subProgramaNom      : "Subprograma",
    subprograma_nom     : "Subprograma",
    proyectoclave       : "Clave Proyecto Acción",
    proyectonom         : "Proyecto Acción",
    obraAccionClave     : "Clave Obra Acción",
    obraAccionNom       : "Obra Acción",
    obra_accion_nom     : "Obra Acción",
    deptoClave          : "Clave Departamento",
    depto_clave         : "Clave Departamento",
    deptoNom            : "Departamento",
    depto_nom           : "Departamento",
    deptoDescrip        : "Descripción Departamento",
    depto_descrip       : "Descripción Departamento",
    cuentaBancaria      : "Cuenta Bancaria",
    usuario             : "Usuario",
    pasaNom             : "Pasatiempo",
    panDesc             : "Descripción Pasatiempo",
    sindicatoAbrevia    : "Abreviatura Sindicato",
    sindicato_abrevia   : "Abreviatura Sindicato",
    sindicatoNombre     : "Sindicato",
    sindicato_nombre    : "Sindicato",
    sindicatoDescrip    : "Descripción Sindicato",
    sindicato_descrip   : "Descripción Sindicato",
    sindicatoPoc        : "Descripción Tipo Descuento",
    sindicato_poc       : "Descripción Tipo Descuento",
    sindicatoMonto      : "Sindicato Descuento",
    sindicato_monto     : "Sindicato Descuento",
    motbaja_nom         : "Motivo de Baja",
    mbaja               : "Motivo de Baja",
    mbajaDescrp         : "Descripción Motivo de Baja",
    parentescoCve       : "Clave Parentesco",
    parentesco_clave    : "Clave Parentesco",
    parentescoNom       : "Parentesco",
    parentesco_nom      : "Parentesco",
    quincenaAplica      : "Aplica en la Quincena",
    cheque              : "Cheque",
    defecto             : "Defecto",
    Descripcion         : "Descripción",
    relab_clave         : "Clave Relación Laboral",
    Nombre              : "Nombre",
    montoMin            : "Monto Mínimo",
    montomin            : "Monto Mínimo",
    montoMax            : "Monto Máximo",
    montomax            : "Monto Máximo",
    tipojornada_clave   : "Clave Tipo de Jornada",
    motbaja_clave       : "Clave Motivo de Baja",
    imptoisn_fecini     : "Fecha Inicial ISN",
    imptoisn_fecfin     : "Fecha Final ISN",
    periodoImpto        : "Impuesto Periodo",
    fechaReanudacion    : "Fecha de Reanudación",
    plazaclave          : "Clave Plaza",
    plaza_clave         : "Clave Plaza",
    plazanivel          : "Nivel Plaza",
    plaza_nivel         : "Nivel Plaza",
    plazadisponible     : "Plaza Disponible",
    plaza_disponible    : "Plaza Disponible",
    areacve             : "Clave Área",
    area_cve            : "Clave Área",
    areanivel           : "Nivel Área",
    area_nivel          : "Nivel Área",
    areasecuencia       : "Secuencia Área",
    area_secuencia      : "Secuencia Área",
    areanom             : "Área",
    area_nom            : "Área",
    areadescrip         : "Descripción Plaza",
    area_descrip        : "Descripción Plaza",
    plazasecuencia      : "Secuencia Plaza",
    plaza_secuencia     : "Secuencia Plaza",
    empNom              : "Empresa en que Laboró",
    empresa_nom         : "Empresa en que Laboró",
    puestoExp           : "Puesto en que Laboró",
    puesto_exp          : "Puesto en que Laboró",
    dmes                : "Laboró desde (mes)",
    exp_dmes            : "Laboró desde (mes)",
    danio               : "Laboró desde (año)",
    exp_danio           : "Laboró desde (año)",
    hmes                : "Laboró hasta (mes)",
    exp_hmes            : "Laboró hasta (mes)",
    hanio               : "Laboró hasta (año)",
    exp_hanio           : "Laboró hasta (año)",
    jefe                : "Jefe Empleo Anterior",
    exp_jefe            : "Jefe Empleo Anterior",
    tel                 : "Teléfono Empleo Anterior",
    exp_tel             : "Teléfono Empleo Anterior",
    empantfecini        : "Fecha Ingreso a Gobierno",
    empant_fecini       : "Fecha Ingreso a Gobierno",
    empantfecfin        : "Fecha Baja de Gobierno",
    empnominafecini     : "Fecha Inicial de Nómina",
    empnominafecfin     : "Fecha Final de Nómina",
    comentario          : "Comentario",
    comentarios         : "Comentario",
    minimoLiquidez      : "Monto Mínimo de Liquidez",
    aplica              : "Aplica",
    empFecAgui          : "Fecha Aguinaldo",
    empNomFecFin        : "Fecha Final Nombramiento",
    notaFecha           : "Fecha Nota",
    nota                : "Nota",
    nota_txt            : "Nota",
    gradoEscolar        : "Grado Escolar",
    promedio            : "Promedio",
    nombreDisc          : "Discapacidad",
    nivel               : "Nivel Discapacidad",
    empleadoCarrera     : "Carrera",
    empesc_carrera      : "Carrera",
    empleadoCedula      : "Cédula Profesional",
    empesc_cedulap      : "Cédula Profesional",
    empleadoProfesion   : "Profesión",
    montoPorc           : "Monto",
    tipoCuota           : "Tipo de Cuota",
    cuentahabiente_clabe: "CLABE Interbancaria",
    cuentahabiente_nocuenta: "Número de Cuenta",
    empleadoTitular     : "Empleado Titular",
    emppuesto_fecfin    : "Fecha Final Puesto",
    empcpres_fecfin     : "Fecha Final Clave Presupuestal",
    empdepeco_fecnac    : "Fecha de Nacimiento Dependiente Económico",
    empdepeco_gradoest  : "Grado de Estudios Dependiente Económico",
    empdepeco_promedio  : "Promedio Dependiente Económico",
    empdepeco_nom       : "Nombre(s) Dependiente Económico",
    empdepeco_pat       : "Primer Apellido Dependiente Económico",
    empdepeco_mat       : "Segundo Apellido Dependiente Económico",
    empdepeco_curp      : "CURP Dependiente Económico",
    empdepeco_nombredisc: "Nombre Discapacidad Dependiente Económico",
    empension_folio     : "Folio Pensión Alimenticia",
    empension_fechaini  : "Fecha Inicial Pensión Alimenticia",
    empension_nom       : "Nombre(s) Pensión Alimenticia",
    empension_pat       : "Primer Apellido Pensión Alimenticia",
    empension_mat       : "Segundo Apellido Pensión Alimenticia",
    empension_tipocuota : "Tipo de Cuota Pensión Alimenticia",
    empension_monto_porc: "Cuota Pensión Alimenticia",
    empension_fechafin  : "Fecha Final Pensión Alimenticia",
    empllaem_nom        : "Nombre(s) Llamadas de Emergencia",
    empllaem_pat        : "Primer Apellido Llamadas de Emergencia",
    empllaem_mat        : "Segundo Apellido Llamadas de Emergencia",
    empllaem_calle      : "Calle Llamadas de Emergencia",
    empllaem_numext     : "Número Exterior Llamadas de Emergencia",
    empllaem_numint     : "Número Interior Llamadas de Emergencia",
    empllaem_telcel     : "Teléfono Móvil Llamadas de Emergencia",
    empllaem_telfijo    : "Teléfono Fijo Llamadas de Emergencia",
    auseFechaIni        : "Fecha Inicial Ausentismo",
    ausefecha_ini       : "Fecha Inicial Ausentismo",
    auseFechaFin        : "Fecha Final Ausentismo",
    ausefecha_fin       : "Fecha Final Ausentismo",
    plantilla_html      : "Plantilla",
    plantillaHtml       : "Plantilla",
    cpresupuestalClave  : "Clave Presupuestal",
    ciaUrAnterior       : "Clave Unidad Responsable Anterior",
    ciaUrNueva          : "Clave Unidad Responsable Nueva",
    ciaUrNomAnterior    : "Unidad Responsable Anterior",
    ciaUrNomNueva       : "Clave Unidad Responsable Nueva",
    udejecAnterior      : "Clave Unidad Ejecutora Anterior",
    udejecNueva         : "Clave Unidad Ejecutora Nueva",
    udejecNomAnterior   : "Unidad Ejecutora Anterior",
    udejecNomNueva      : "Unidad Ejecutora Nueva",
    programaAnterior    : "Clave Programa Anterior",
    programaNueva       : "Clave Programa Nuevo",
    programaNomAnterior : "Programa Anterior",
    programaNomNueva    : "Programa Nuevo",
    subProgramaAnterior : "Clave Subprograma Anterior",
    subProgramaNueva    : "Clave Subprograma Nuevo",
    subProgramaNomAnterior: "Subprograma Anterior",
    subProgramaNomNueva : "Subprograma Nuevo",
    proyectoAnterior    : "Clave Proyecto Acción Anterior",
    proyectoNueva       : "Clave Proyecto Acción Nuevo",
    proyectoNomAnterior : "Proyecto Acción Anterior",
    proyectoNomNueva    : "Proyecto Acción Nuevo",
    obraAnterior        : "Clave Obra Acción Anterior",
    obraNueva           : "Clave Obra Acción Nuevo",
    obraNomAnterior     : "Obra Acción Anterior",
    obraNomNueva        : "Obra Acción Nuevo",
    cpresupuestalClaveAnterior: "Clave Presupuestal Anterior",
    cpresupuestalClaveNueva: "Clave Presupuestal Nueva",
    cpresupuestalNomAnterior: "Proyecto Anterior",
    cpresupuestalNomNueva: "Proyecto Nuevo",
    anio                : "Año",
    enfmatpat           : "% Enfermedades Maternidad Patrón",
    enfmatobr           : "% Enfermedades Maternidad Obrera",
    pat                 : "Tope Cuotas Obrero-Patronal",
    pensionFpo          : "% Fondo de Pensión Obrero",
    margenSup           : "Margen Superior",
    margen_sup          : "Margen Superior",
    margenInf           : "Margen Inferior",
    margen_inf          : "Margen Inferior",
    margenIzq           : "Margen Izquierdo",
    margen_izq          : "Margen Izquierdo",
    margenDer           : "Margen Derecho",
    margen_der          : "Margen Derecho",
    oculto              : "Oculto",
    estatico            : "Estático",
    tipoReporte         : "Tipo Reporte",
    posicionColumna     : "Posición Columna",
    ordenDefaultGrupo   : "Orden Grupo",
    ordenDefault        : "Orden",
    posicionDefaultGrupo: "Posición Grupo",
    defaultFiltro       : "Filtro",
    filtroGeneral       : "Filtro Genaral",
    titulo              : "Título",

};

export default diccionario;
