import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService'; 
import ArrowBackIcon from '@material-ui/icons/ArrowBack'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import { deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import { ArrayJsonEmpleadosReportesNominas } from '../../../Helpers/JsonToOneLevel';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const NominaTablaReporte = ({ id, permisos, openReport, elementSelect, onAdd, infoReport, setInfoReport}) => {
    
    let filtroGeneral ='';
    
    let data = elementSelect.id ? JSON.parse(elementSelect.reporteJson)[0].infoReport : elementSelect.reportesId ? JSON.parse(elementSelect.reportesReporteJson)[0].infoReport : null;
    
    const convertColumns = (columns)=>{ 
        filtroGeneral = columns[0].filtroGeneral;
        data = columns[0].infoReport;
        let resultado = columns[0].campos.map(element =>{ return deleteOptionals({
            field        : element.nombre,
            title        : element.titulo==='Clave Nómina' ? 'Concepto' : element.titulo==='Nombre Indicador' ? 'Concepto por Nómina':element.titulo,
            defaultSort  : element.ordenDefault,
            defaultFilter: element.defaultFiltro,
            hidden: element.oculto,
            defaultGroupOrder: element.posicionDefaultGrupo,
            defaultGroupSort: element.ordenDefaultGrupo,
            cellStyle: rowData => ({ textAlign: element.nombre==='cantidad' ||element.nombre==='monto'? 'right':'left'})
        })}); 
        return resultado; 
    };
    
    const searchAcumulados = ( empleadosAcumulados ) =>{
        let listAcumulados = [];
        empleadosAcumulados.forEach((item, index)=>{ 
            if(listAcumulados.findIndex(element => element.acumuladoClave === item.acumuladoClave) ===-1 || index===0 ){
                listAcumulados.push(item);
            }   
        })
        return listAcumulados;
    };

    const [ empleados, setEmpleados ] = useState(infoReport ? elementSelect : null); 
    const [ acumulados, setAcumulados ] = useState(infoReport ? searchAcumulados(elementSelect) : []);
    const [error, setError] = useState(null);
    
    const columns = infoReport
    ?   [
            { field: "empleadoClave", title: "NUE", type: 'numeric', hidden:false, filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
            { field: "nombreCompleto", title: "Nombre", hidden:false}, 
            { field: "empleadoFecnac", title: "Fecha de Nacimiento", hidden:false },
            { field: "empleadoCurp", title: "CURP", hidden:false },
            { field: "empleadoRfc", title: "RFC", hidden:false},
            { field: "empleadoNss", title: "NSS", hidden:false}, 
            { field: "cpresupuestalClave", title: "Clave Presupuestal", hidden:false, customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) }, 
            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto", hidden:false },
            { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden:false},
            { field: "nomindClave", title: "Clave Concepto", hidden:false }, 
            { field: "tiponatuNom", title: "Nombre Tipo Naturaleza", hidden:false },
            { field: "nomindNomIndicador", title: "Concepto por Nómina", hidden:false },
            { field: "cantidad", title: "Cantidad", cellStyle: rowData => ({ textAlign:'right'}), hidden:false },
            { field: "monto", title: "Monto", cellStyle: rowData => ({ textAlign:'right'}), hidden:false },
            { field: "acumuladoClave", title: "Clave Acumulado" },
            { field: "acumuladoNombre", title: "Nombre Acumulado" },
        ]
    :   (elementSelect.id)
        ?   convertColumns(JSON.parse(elementSelect.reporteJson))  
        :   elementSelect.reportesId && convertColumns(JSON.parse(elementSelect.reportesReporteJson));

    const generarReporte = () => {
            var acumuladoNuevoReporteNominas = [];
            const reporteNominas = (data, limiteInf, cantidad, acumulado, restante, infoReport, setInfoReport) => {
                axios.get(`${servicios_grupohit_nomina}/vacumperxnom/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}/${limiteInf}/${cantidad}`, headersAuth())
                .then(res => { 
                    switch(res.data.status){
                        case 200:
                            if(restante === 0) {
                                const dataEmpleados = ArrayJsonEmpleadosReportesNominas(acumuladoNuevoReporteNominas.concat(res.data.dataResponse));
                                setAcumulados(searchAcumulados(dataEmpleados));
                                setEmpleados(dataEmpleados);
                            } else if(restante > 0) {
                                acumuladoNuevoReporteNominas = acumuladoNuevoReporteNominas.concat(res.data.dataResponse);
                                const cantidad = (restante < 50000) ? restante : 50000;
                                const limiteInferior = acumulado;
                                const newAcumulado = acumulado+cantidad;
                                reporteNominas(data, limiteInferior, cantidad, newAcumulado, restante-cantidad, infoReport, setInfoReport );
                            } else {
                                (data.calculo) ? setError('Error en la operación') : setError(<AlertForm message="Error en la operación" />); 
                            }
                            break;
                        default:
                            (data.calculo) ? setError(res.data.msg) : setError(<AlertForm message={res.data.msg} />); 
                            break;
                    }
                })
                .catch( error => {
                    (data.calculo) ? setError(verifique_conexion) : setError(<AlertForm message={verifique_conexion} />); 
                });
            };

            axios.get(`${servicios_grupohit_nomina}/vacumperxnom/total/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}`, headersAuth())
            .then(res => {
                if(res.data.status === 200) {
                    const cantidad = (res.data.dataResponse < 50000) ? res.data.dataResponse : 50000;
                    reporteNominas(data, 0, cantidad, cantidad, res.data.dataResponse-cantidad, infoReport, setInfoReport);
                } else {
                    (data.calculo) ? setError(res.data.msg) : setError(<AlertForm message={res.data.msg} />);
                }
            })
            .catch(error => {
                (data.calculo) ? setError(verifique_conexion) : setError(<AlertForm message={verifique_conexion} />); 
            });
    };

    useEffect(() => {
        if(!infoReport) {
            generarReporte();
            /*axios.get( `${servicios_grupohit_nomina}/vacumperxnom/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        const dataEmpleados = ArrayJsonEmpleadosReportesNominas(respuesta.data.dataResponse);
                        setAcumulados(searchAcumulados(dataEmpleados));
                        setEmpleados(dataEmpleados);  
                        break;
                    case 404:
                        setEmpleados([]);
                        break;
                    default: 
                        break;
                }
            })
            .catch( error => { 
            });*/
        }
        // eslint-disable-next-line  
    }, []);

    return (
        <div id={`modulo${id}tablaReporte`}>
            {(error)
            ?   <Fragment>
                    <div className="row">
                        <div className="col-11">
                        </div>
                        <div className="col-1 float-left" style={{marginBlockEnd:'18px'}}>
                            <button className="form-control" onClick={()=>{openReport(false); setInfoReport(null)}}> 
                                <Tooltip title="Regresar" placement="left-start">
                                    <ArrowBackIcon/>
                                </Tooltip>
                            </button> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            { error }
                        </div>
                    </div>
                </Fragment>
            :   (empleados)
                ?   <Fragment>
                        <div className="row">
                            <div className="col-11">
                            {(empleados[0]) &&
                                <Fragment>
                                {acumulados.map(item => {
                                    return <div key={item.acumuladoClave}> <b> Clave Acumulado: </b> {item.acumuladoClave} <b> Nombre Acumulado: </b> {item.acumuladoNombre} </div>
                                })
                                }
                                    <div> <b> Fecha Periodo: </b> {empleados[0].periodoFecini} <b> - </b> {empleados[0].periodoFecfin} </div>
                                    <div> <b> Año: </b> {empleados[0].periodoAnio} <b> Periodo: </b> {empleados[0].periodoPeranio} <br/> </div>
                                    
                                    <div style={{marginBottom:'1rem'}}> <b> Clave Nómina: </b> {empleados[0].nomindClave} <b> Nombre Nómina: </b> {empleados[0].nominaNomCorto} </div>  
                                </Fragment>
                            }
                            </div>
                            <div className="col-1 float-left" style={{marginBlockEnd:'18px'}}>
                                <button className="form-control" onClick={()=>{openReport(false); setInfoReport(null)}}> 
                                    <Tooltip title="Regresar" placement="left-start">
                                        <ArrowBackIcon/>
                                    </Tooltip>
                                </button> 
                            </div>
                        </div> 
                        <DataTableService id={ Array.isArray(elementSelect) ? elementSelect[elementSelect.length-1].isStatic ? `${id}secondary`: id : id } title={'Reporte de Nóminas'}
                            columns={columns}
                            data={empleados}
                            isReport
                            hideIconAdd
                            hideIconDelete
                            hideIconEdit
                            showIconDescription={ Array.isArray(elementSelect) ? elementSelect[elementSelect.length-1].isStatic : false }
                            informacionAdicional={{filtroGeneral, infoReport: elementSelect.reportesId ? JSON.parse(elementSelect.reportesReporteJson)[0].infoReport : infoReport, isStatic: Array.isArray(elementSelect) ? elementSelect[elementSelect.length-1].isStatic : false, acumulados }}
                            actionAlert={() => { openReport(false); setInfoReport(null)}}
                            onShowTable={onAdd} 
                        />
                    </Fragment>
                :   <SpinnerOval/>
        }
        </div>
    );
}
 
export default NominaTablaReporte;