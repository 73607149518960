import React from 'react';

const AlertTable = ({ mensaje, closeAlert }) => {
    return (
        <div className="alert alert-danger" role="alert">
            <span> { mensaje } </span>
            <button type="button" className="close" onClick={ () => closeAlert() } >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
};

export default AlertTable;