import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, deleteOptionals, amountToFloat } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioIMSS = ({ error, enviarIMSS, elementSelect, actualizarIMSS, informacion, setError, dataTable, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const fechainiRef = createRef('');
    const fechafinRef = createRef('');
    const enfmatpatRef = createRef('');
    const enfmatbrRef = createRef('');
    const patRef = createRef('');
    const sueldoRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarIMSS' ) ){
            
            const fechaini = inputDateToLong( fechainiRef.current.value );
            const fechafin = inputDateToLong( fechafinRef.current.value );
            const lastFechaIncial = dataTable.length > 0 ? inputDateToLong( dataTable[0].fechaini ) : null;
            const lastFechaIncialFormat = dataTable.length > 0 ? dataTable[0].fechainiTab : null; 
            if( fechaini > lastFechaIncial ||  lastFechaIncial===null ){
                const enfmatpat = parseFloat(enfmatpatRef.current.value);
                const enfmatbr = parseFloat(enfmatbrRef.current.value);
                const pat = amountToFloat(patRef.current.value);
                const sueldo = parseFloat(sueldoRef.current.value);
                enviarIMSS( deleteOptionals({ fechaini, fechafin, enfmatpat, enfmatbr, pat, sueldo }) );
            }else{
                setError( <AlertForm message={ `La fecha Inicial debe ser mayor a ${lastFechaIncialFormat}` } /> );
            }
            
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarIMSS' ) ){
            const fechaini = inputDateToLong( fechainiRef.current.value );
            const fechafin = inputDateToLong( fechafinRef.current.value ); 

            const indexElementSelect = dataTable.findIndex(element => element.id === elementSelect.id );
            const limiteInferior = dataTable[indexElementSelect+1] ? inputDateToLong( dataTable[indexElementSelect+1].fechaini ) : null;
            const limiteSuperior = dataTable[indexElementSelect-1] ? inputDateToLong( dataTable[indexElementSelect-1].fechaini ) : null;

            const limiteInferiorFormat = dataTable[indexElementSelect+1] ? dataTable[indexElementSelect+1].fechainiTab  : null;
            const limiteSuperiorFormat = dataTable[indexElementSelect-1] ? dataTable[indexElementSelect-1].fechainiTab  : null;
 
            if( fechaini > limiteInferior ||  limiteInferior===null ){
                if(fechaini < limiteSuperior || limiteSuperior===null  ){
                    const enfmatpat = parseFloat(enfmatpatRef.current.value);
                    const enfmatbr = parseFloat(enfmatbrRef.current.value);
                    const pat = amountToFloat(patRef.current.value);
                    const sueldo = parseFloat(sueldoRef.current.value);
                    actualizarIMSS( deleteOptionals({ id: elementSelect.id, fechaini, fechafin, enfmatpat, enfmatbr, pat, sueldo }) );
                }else{
                    setError( <AlertForm message={ `La fecha Inicial debe ser menor a ${limiteSuperiorFormat}` } /> );
                }
            }else{
                setError( <AlertForm message={ `La fecha Inicial debe ser mayor a ${limiteInferiorFormat} ` } /> );
            }
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarIMSS' : 'AgregarIMSS' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechainiRef} required
                            defaultValue={ elementSelect ? elementSelect.fechaini : '' } />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechafinRef} optional
                            defaultValue={ elementSelect ? elementSelect.fechafin : '' } />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="% Enfermedades Maternidad Patrón" type="porcentaje3" placeholder="Escriba el porcentaje de Maternidad Patrón"
                            tooltipText={'Máximo 3 números y tres decimales del 0.000 al 100.000'} tooltipName={'tooltipPorcPatron'} maxLength='7'
                            refValue={enfmatpatRef} defaultValue={ elementSelect ? elementSelect.enfmatpat : '' } required
                            onlyNumbers="." onBlurDecimal={3}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="% Enfermedades Maternidad Obrera" type="porcentaje3" placeholder="Escriba el porcentaje de Maternidad Obrera"
                            tooltipText={'Máximo 3 números y tres decimales del 0.000 al 100.000'} tooltipName={'tooltipPorcObrera'} maxLength='7'
                            refValue={enfmatbrRef} defaultValue={ elementSelect ? elementSelect.enfmatobr : '' } required
                            onlyNumbers="." onBlurDecimal={3}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Tope Cuotas Obrero-Patronal" type="cuotasIMSS" placeholder="Escriba el Tope Cuotas Obrero-Patronal"
                            tooltipText={'Valor de tipo numerico que no exceda xxxxx.xx (Entero de 5 digitos y 2 decimales) '} tooltipName={'tooltipFactorCuota'} maxLength='10'
                            refValue={patRef} defaultValue={ elementSelect ? elementSelect.pat : '' } required
                            onlyNumbers="." onBlurDecimal={2} isAmount/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Factor Aplicable al Sueldo" type="factorSueldoIMSS" placeholder="Escriba el Factor Aplicable al Sueldo"
                            tooltipText={'Valor de tipo numerico que no exceda x.xxxxxx (Entero de 1 digito y 6 decimales)'} tooltipName={'tooltipFactorAplicable'} maxLength='8'
                            refValue={sueldoRef} defaultValue={ elementSelect ? elementSelect.sueldo : '' } required
                            onlyNumbers="." onBlurDecimal={6}/>
                    </div>
                </div>

                {error}
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioIMSS;