import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_admon, servicios_grupohit_catalogo, dependencias_ } from '../../../../../../Constants/Constants';
import { ArrayJsonDependencias } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Dependencias = ({ id, permisos }) => {
    const [ dependencias, setDependencias ] = useState( null );
    const [ tiposDependencia, setTiposDependencia ] = useState( null );
    const [ giros, setGiros ] = useState( null );
    useEffect(() => {
        axiosDependencias();
        axiosTiposDependencia();
        axiosGiros();
    }, []);

    /* Axios para consumo de servicio Dependencias(Compañias) */
    const axiosDependencias = () => {
        axios.get( `${servicios_grupohit_admon}/compania`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDependencias( ArrayJsonDependencias(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        //setDependencias([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio TiposDependencia */
    const axiosTiposDependencia = () => {
        axios.get( `${servicios_grupohit_catalogo}/dependencia/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposDependencia( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposDependencia([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio Giros */
    const axiosGiros = () => {
        axios.get( `${servicios_grupohit_catalogo}s/giros/all`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setGiros( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setGiros([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { dependencias && tiposDependencia && giros ?
                <DataTableService id={id} title={ dependencias_ } permisos={permisos}
                    columns={[
                        { field: "ciaRfc", title: "RFC" },
                        { field: "ciaRazonSocial", title: "Secretaría / Dependencia" },
                        { field: "ciaUr", title: "Unidad Responsable / Dependencia" },
                        { field: "ciaNomCorto", title: "Nombre Corto" },
                        { field: "tipoDependNombre", title: "Tipo de Secretaría o Dependencia" },
                        { field: "giroNom", title: "Giro Comercial" },
                        { field: "ciaFecConst", title: "Fecha Constitución" },
                        { field: "cuentaBancaria", title: "Cuenta Bancaria" },
                        { field: "ciaWeb", title: "Sitio Web" },
                    ]}
                    data={ dependencias }
                    informacionAdicional={{ tiposDependencia, giros }} />
                :
                <SpinnerOval />
            }
        </div>
    );
}
 
export default Dependencias;