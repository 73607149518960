import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioDependencia = ({ error, enviarDependencia, elementSelect, actualizarDependencia, informacion, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const rfcRef = createRef('');
    const nombreCortoRef = createRef('');
    const razonSocialRef = createRef('');
    const unidadResponsableRef = createRef('');
    const giroIdRef = createRef('');
    const tipoDependenciaIdRef = createRef('');
    const fechaConstitucionRef = createRef('');
    const cuentaBancariaRef = createRef('');
    const ciaWebRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarDependencia' ) ){
            const rfc = rfcRef.current.value;
            const nombreCorto = nombreCortoRef.current.value;
            const razonSocial = razonSocialRef.current.value;
            const unidadResponsable = unidadResponsableRef.current.value;
            const giroId = giroIdRef.current.value;
            const tipoDependenciaId = tipoDependenciaIdRef.current.value;
            const fechaDeConstitucion = inputDateToLong( fechaConstitucionRef.current.value );
            const cuentaBancaria = cuentaBancariaRef.current.value;
            const ciaWeb = ciaWebRef.current.value;
            enviarDependencia( deleteOptionals({ rfc, nombreCorto, razonSocial, unidadResponsable, giroId, tipoDependenciaId, fechaDeConstitucion, cuentaBancaria, ciaWeb }) );
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarDependencia' ) ){
            const rfc = rfcRef.current.value;
            const nombreCorto = nombreCortoRef.current.value;
            const razonSocial = razonSocialRef.current.value;
            const unidadResponsable = unidadResponsableRef.current.value;
            const giroId = giroIdRef.current.value;
            const tipoDependenciaId = tipoDependenciaIdRef.current.value;
            const fechaDeConstitucion = inputDateToLong( fechaConstitucionRef.current.value );
            const cuentaBancaria = (cuentaBancariaRef.current.value === '') ? null : cuentaBancariaRef.current.value;
            const ciaWeb = (ciaWebRef.current.value === '') ? null : ciaWebRef.current.value;
            actualizarDependencia( deleteOptionals({ rfc, nombreCorto, razonSocial, unidadResponsable, giroId, tipoDependenciaId, fechaDeConstitucion, cuentaBancaria, ciaWeb }), elementSelect.id);
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarDependencia' : 'AgregarDependencia' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                { elementSelect && <h4>Dependencia</h4> }
                <InputValidation title="Registro Federal de Contribuyentes (RFC)" type="rfc" placeholder="Escriba el RFC de la dependencia"
                    tooltipName={'tooltipRfcDep'} tooltipText={`12/13 letras y números en formato de RFC`} maxLength='13' alfanumeric
                    refValue={rfcRef} defaultValue={ elementSelect ? elementSelect.ciaRfc : '' } required/>

                <InputValidation title="Secretaría / Dependencia" type="razonSocial" placeholder="Escriba el nombre de la Secretaría / Dependencia" maxLength='254'
                    tooltipText={'Máximo 254 letras, números, espacios y/o caracteres especiales'} tooltipName={'tooltipRazSocDep'}
                    refValue={razonSocialRef} defaultValue={ elementSelect ? elementSelect.ciaRazonSocial : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Unidad Responsable / Dependencia" type="claveUnidadResponsable" placeholder="Escriba los números de la Unidad Responsable"
                            tooltipText={'Unidad Responsable, 3 números'} tooltipName={'tooltipURDep'} maxLength='3' onlyNumbers
                            refValue={unidadResponsableRef} defaultValue={ elementSelect ? elementSelect.ciaUr : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Nombre Corto" type="nombreCorto" placeholder="Escriba el nombre corto de la dependencia" maxLength='30'
                            tooltipText={'Máximo 30 letras, números, espacios y/o caracteres especiales'} tooltipName={'tooltipNomDep'}
                            refValue={nombreCortoRef} defaultValue={ elementSelect ? elementSelect.ciaNomCorto : '' } required/>
                    </div>
                </div>

                <SelectValidation title="Giro Comercial" arrayOpciones={informacion.giros} keyOpcion="giroNom"
                    refValue={giroIdRef} defaultValue={ elementSelect ? elementSelect.giroId : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Tipo de Secretaría o Dependencia" arrayOpciones={informacion.tiposDependencia} keyOpcion="nombre"
                            refValue={tipoDependenciaIdRef} defaultValue={ elementSelect ? elementSelect.tipoDependId : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha de Constitución" refValue={fechaConstitucionRef}
                            defaultValue={ elementSelect ? elementSelect.ciaFecConst : '' }
                            optional />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Cuenta Bancaria" type="cbeInterbancaria-numTarjeta" placeholder="Escriba el número de la Cuenta Bancaria" maxLength='18'
                            tooltipText="16 números para Número de Tarjeta o 18 números para CLABE Interbancaria" tooltipName={'tooltipCuentaBanc'} onlyNumbers
                            refValue={cuentaBancariaRef} defaultValue={elementSelect ? elementSelect.cuentaBancaria : ''} optional/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Sitio Web" type="sitioWeb" placeholder="Escriba el sitio web de la dependencia" maxLength='30'
                            tooltipText={'Máximo 30 letras, números, espacios y/o caracteres especiales (Con la estructura de un sitio web)'} tooltipName={'tooltipSWebDep'}
                            refValue={ciaWebRef} defaultValue={ elementSelect ? elementSelect.ciaWeb : '' }
                            optional={true} />
                    </div>
                </div>

                {error}
                {isLoading
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioDependencia;