import React from 'react';
import { Progress } from 'reactstrap';

const ProgressBar = ({ documento, actionClose, progressValue }) => {
    return (
        <div className="upload">
            <div className="movimientosFlex">
                <div className="divIzquierdoName">
                    {documento.name}
                </div>
                <div className="divDerechoBtnClose">
                    <button type="button" className="close" onClick={() => actionClose()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <Progress animated value={progressValue} />
        </div>
    );
};

export default ProgressBar;