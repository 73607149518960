import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { mov_registrados, mov_vigentes, servicios_grupohit, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../Constants/Constants';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import Button from '@material-ui/core/Button';
import { ArrayJsonNominas } from '../../../../Helpers/JsonToOneLevel';
import { Spinner } from 'reactstrap';
import AlertForm from '../../../../../../../Services/AlertForm';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';

const Formulario = ({ servicioInfoCards, setTableroInfo, muestraTabla, limpiaActiveCards, setTipoConsulta, disabled, setDisabled, obtieneNomina, obtieneAnio, obtienePeriodo }) => {

    const [nominas, setNominas] = useState([]);
    const [anios, setAnios] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [error, setError] = useState(null);
    const [reporteSelected, setReporteSelected] = useState(2);

    const { nomina: nominaDisabled, anio: anioDisabled, periodo: periodoDisabled, button: buttonDisabled } = disabled;

    const nominaRef = useRef('');
    const anioRef = useRef('');
    const periodoRef = useRef('');

    useEffect(() => {
        setNominas(null);
        axios.get(`${servicios_grupohit_nomina}/config/nomina/all`, headersAuth())
        .then(res => { 
            switch(res.data.status){
                case 200:
                    const nominasArray = [{ id: 0, nominaClave: '', nominaClaveNombre: 'TODAS' }, ...ArrayJsonNominas(res.data.dataResponse)];
                    setNominas(nominasArray);
                    nominaRef.current.value = nominasArray[0].id;
                    servicioAnios(`${nominasArray[0].id}`);
                    break;
                default:
                    setNominas([]);
                    setError(<AlertForm message={res.data.msg}/>);
                break;
            }
        })
        .catch(error => { setError(<AlertForm message={verifique_conexion}/>) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const activaCampos = type => {
        switch(type) {
            case 'anio':
                setDisabled({
                    anio: false,
                    periodo: true,
                    button: true,
                });
                break;
            case 'periodo':
                setDisabled({
                    anio: false,
                    periodo: false,
                    button: true,
                });
                break;
            case 'button':
                setDisabled({
                    anio: false,
                    periodo: false,
                    button: false,
                });
                break;
            default:
                break;
        }
    };

    const bloqueaCampos = type => {
        switch(type) {
            case 'anio':
                setDisabled({
                    ...disabled,
                    anio: true,
                    periodo: true,
                    button: true,
                });
                break;
            case 'periodo':
                setDisabled({
                    ...disabled,
                    periodo: true,
                    button: true,
                });
                break;
            case 'button':
                setDisabled({
                    ...disabled,
                    button: true,
                });
                break;
            default:
                break;
        }
    };

    const ejecutaReporte = () => {
        if(validarSubmit('formEstadPorPeriodo') && reporteSelected) {
            if(reporteSelected === 1) enviarConsultaMovimientos();
            if(reporteSelected === 2) enviarConsultaMovimientosAuditoria();
        } else {
            limpiaActiveCards();
            setTableroInfo(null);
            muestraTabla(null);
            setError(null);
            setTipoConsulta({ tipo: 0, nombre: '' });
        }
    };

    const servicioAnios = (nominaId) => {
        limpiaActiveCards();
        setTableroInfo(null);
        muestraTabla(null);
        setAnios([]);
        setPeriodos([]);
        bloqueaCampos('anio');
        setError(null);
        setTipoConsulta({ tipo: 0, nombre: '' });
        if(nominaId.trim() !== '') {
            setAnios(null);
            axios.get(`${servicios_grupohit}/generar/reportes/nomina/`, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        const arrayAnios = res.data.dataResponse.map(anio => ( { periodoAnio: anio[0] } ));
                        setAnios(arrayAnios);
                        anioRef.current.value = arrayAnios[0].periodoAnio;
                        servicioPeriodos(`${arrayAnios[0].periodoAnio}`);
                        activaCampos('anio');
                        break;
                    default:
                        setAnios([]);
                        activaCampos('anio');
                        setError(<AlertForm message={res.data.msg}/>);
                        break;
                }
            })
            .catch(error => { setError(<AlertForm message={verifique_conexion}/>) });
        }
    };

    const servicioPeriodos = anioId => {
        limpiaActiveCards();
        setTableroInfo(null);
        muestraTabla(null);
        setPeriodos([]);
        bloqueaCampos('periodo');
        setError(null);
        setTipoConsulta({ tipo: 0, nombre: '' });

        const nominaId = nominaRef.current.value.trim();
        if(anioId.trim() !== '' && nominaId !== '') {
            setPeriodos(null);
            if(nominaId === '0') {
                axios.post(`${servicios_grupohit}/generar/reportes/nomina/periodo/`, { anio: parseInt(anioId.trim()) }, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const periodosService = res.data.dataResponse.filter(periodo => periodo !== 9 && periodo !== 23);
                            const arrayPeriodos = periodosService.map(periodo => ({
                                periodoId: periodo,
                                periodoPerAnio: periodo === 10 ? `9/10` : periodo === 24 ? `23/24` : periodo,
                            }));
                            setPeriodos(arrayPeriodos);
                            periodoRef.current.value = arrayPeriodos[0].periodoPerAnio;
                            activaButton(`${arrayPeriodos[0].periodoPerAnio}`);
                            activaCampos('periodo');
                            break;
                        default:
                            setPeriodos([]);
                            activaCampos('periodo');
                            setError(<AlertForm message={res.data.msg}/>);
                            break;
                    }
                })
                .catch(error => { setError(<AlertForm message={verifique_conexion}/>) });
            } else {
                axios.get(`${servicios_grupohit_nomina}/config/periodos/obtener/anios/periodos/${nominaId}/${anioId.trim()}`, headersAuth())
                .then(res => { 
                    switch(res.data.status) {
                        case 200:
                            const periodosService = res.data.dataResponse.filter(periodo => periodo !== 9 && periodo !== 23);
                            const arrayPeriodos = periodosService.map(periodo => ({
                                periodoId: periodo,
                                periodoPerAnio: periodo === 10 ? `9/10` : periodo === 24 ? `23/24` : periodo,
                            }));
                            setPeriodos(arrayPeriodos);
                            periodoRef.current.value = arrayPeriodos[0].periodoPerAnio;
                            activaButton(`${arrayPeriodos[0].periodoPerAnio}`);
                            activaCampos('periodo');
                            break;
                        default:
                            setPeriodos([]);
                            activaCampos('periodo');
                            setError(<AlertForm message={res.data.msg}/>);
                            break;
                    }
                })
                .catch(error => { setError(<AlertForm message={verifique_conexion}/>) });
            }
        }
    };

    const activarBotones = periodoId => {
        activaButton(periodoId, false);
    };

    const activaButton = (periodoId) => {
        limpiaActiveCards();
        setTableroInfo(null);
        muestraTabla(null);
        setTipoConsulta({ tipo: 0, nombre: '' });
        if(periodoId.trim() !== '') {
            activaCampos('button');
            ejecutaReporte();
        } else {
            bloqueaCampos('button');
        }
    };

    const enviarConsultaMovimientos = e => {
        if(e) e.preventDefault();
        if(validarSubmit('formEstadPorPeriodo')) {
            setReporteSelected(1);
            limpiaActiveCards();
            setTableroInfo(null);
            muestraTabla(null);
            const request = {
                nomina: parseInt(nominaRef.current.value),
                periodoAnio: parseInt(anioRef.current.value),
                periodoPerAnio: parseInt(periodoRef.current.value)
            };
            setTipoConsulta({ tipo: 1, nombre: `${mov_vigentes} en el Periodo` });
            servicioInfoCards(request, 1);
        }
    };

    const enviarConsultaMovimientosAuditoria = e => {
        if(e) e.preventDefault();
        if(validarSubmit('formEstadPorPeriodo')) {
            setReporteSelected(2);
            limpiaActiveCards();
            setTableroInfo(null);
            muestraTabla(null);
            const request = {
                nomina: parseInt(nominaRef.current.value),
                periodoAnio: parseInt(anioRef.current.value),
                periodoPerAnio: parseInt(periodoRef.current.value)
            };
            setTipoConsulta({ tipo: 2, nombre: `${mov_registrados} en el Periodo` });
            servicioInfoCards(request, 2);
        }
    };

    return (
        <form id="formEstadPorPeriodo">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg">
                {nominas
                ?   <SelectValidation
                        title="Nómina" arrayOpciones={nominas} keyOpcion="nominaClaveNombre"
                        refValue={nominaRef} defaultValue="" onChangeExecute={ejecutaReporte} onChangeReturnObject={obtieneNomina}
                        disabled={nominaDisabled || buttonDisabled} required
                    />
                :   <div className="mt-4"><Spinner/></div>
                }
                </div>
                <div className="col-sm-12 col-md-12 col-lg">
                {anios
                ?   <SelectValidation
                        title="Año" arrayOpciones={anios} keyId="periodoAnio" keyOpcion="periodoAnio"
                        refValue={anioRef} defaultValue="" onChangeExecute={servicioPeriodos} onChangeReturnObject={obtieneAnio}
                        disabled={anioDisabled || buttonDisabled} required
                    />
                :   <div className="mt-4"><Spinner/></div>
                }
                </div>
                <div className="col-sm-12 col-md-12 col-lg">
                {periodos
                ?   <SelectValidation
                        title="Periodo" arrayOpciones={periodos} keyId="periodoPerAnio" keyOpcion="periodoPerAnio"
                        refValue={periodoRef} defaultValue="" onChangeExecute={activarBotones} onChangeReturnObject={obtienePeriodo}
                        disabled={periodoDisabled || buttonDisabled} required
                    />
                :   <div className="mt-4"><Spinner/></div>
                }
                </div>
            </div>
            { error && <div className="row"><div className="col">{ error }</div></div> }
            <div className="row">
                <div className="col">
                    <Button variant="contained" className="btn-color withoutCapitalize" disabled={buttonDisabled} onClick={enviarConsultaMovimientos}>
                        { `Consultar ${mov_vigentes} en el Periodo` }
                    </Button>
                </div>
                <div className="col">
                    <Button variant="contained" className="btn-color withoutCapitalize" disabled={buttonDisabled} onClick={enviarConsultaMovimientosAuditoria}>
                        { `Consultar ${mov_registrados} en el Periodo` }
                    </Button>
                </div>
            </div>
        </form>
    );
}
 
export default Formulario;
