import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { servicios_grupohit, servicios_grupohit_catalogo, tabuladores_quinquenios, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import DinamicTableQuinquenios from './DinamicTableQuinquenios';
import DataTableService from '../../../DataTableService/DataTableService'; 
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation'; 
import ConvertDate from '../../../Helpers/ConvertDate';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const TabuladoresQuinquenios = ({ id, permisos }) => {
    const [dinamicTable, setDinamicTable] =useState('');

    const [tabuladoresBack, setTabuladoresBack]= useState(null);
    const [periodosTabuladoresQuin, setPeriodosTabuladoresQuin]= useState(null);
    const [idTab, setIdTab]=useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const fechainiRef = useRef(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupohit}/org/quinquenios/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    fechainiRef.current=res.data.dataResponse[0].fecIni;                    
                    setTabuladoresBack(res.data.dataResponse);
                    var response = res.data.dataResponse;
                    response.map(( key, index)=>{
                        for (const prop in key) {
                            if(prop!=='id' && key[prop]!==null) {
                                response[index][prop] = ConvertDate(longDateToInput(key[prop]));
                            }
                            if( key[prop]===null) response[index][prop] ="-";
                        }
                        return null;
                    }); 
                    setPeriodosTabuladoresQuin(response);
                    break;
                case 404:
                    setPeriodosTabuladoresQuin([]);
                    setTabuladoresBack([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]); 
    
    const [niveles, setNiveles] = useState(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupohit_catalogo}/nvlaboral/byrelab/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setNiveles(res.data.dataResponse);
                    break;
                case 404:
                    setNiveles([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    const showTable=(value, id, fechaIn, fechaSave=null)=>{
        setIdTab(id);
        if(fechaIn!==null){
            fechainiRef.current=fechaIn;
        } 
        setDinamicTable(value);

        if(fechaSave!==null){

            if(periodosTabuladoresQuin.length>=1){
                periodosTabuladoresQuin[0].fecFin=ConvertDate(longDateToInput(fechaSave-86400000)); 
            }
            var newData={id, fecIni: ConvertDate(longDateToInput(fechaSave)), fecFin: '-'};
            setTabuladoresBack([ newData, ...tabuladoresBack ]);
            setPeriodosTabuladoresQuin([ newData, ...periodosTabuladoresQuin ]);
             
        }
        return dinamicTable; 
    } 

    return (
        <div id={`modulo${id}`}> 
        {(dinamicTable)
        ?   <div>
                <DinamicTableQuinquenios
                    idModulo={id} permisos={permisos} niveles={niveles}
                    tabuladoresBack={tabuladoresBack} idTab={idTab} fechainic={fechainiRef.current} onShowTable={showTable}
                />
            </div>
        :   <div>
            {(periodosTabuladoresQuin)
            ?   <DataTableService 
                    id={id}
                    title={tabuladores_quinquenios}
                    columns={[
                        { field: "fecIni", title: "Fecha de Inicio" },
                        { field: "fecFin", title: "Fecha Final" },
                    ]}
                    data={periodosTabuladoresQuin}
                    onShowTable={showTable}
                    permisos={permisos}
                    informacionAdicional={{tabulador: periodosTabuladoresQuin.find(element=> element.fecFin==='-')}}
                />
            :   <SpinnerOval/>
            }
            </div>
        }
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default TabuladoresQuinquenios;
