import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import DataTableService from '../../../../../DataTableService/DataTableService';
import InputText from '../../../../../../../../Services/Components/DataTable/InputText';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { arrayJsonMovimientosPorPeriodoProrrogados } from '../../../../../Helpers/JsonToOneLevel';
import { mov_vigentes, prorrogas_contratos, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const ProrrogaContratoVigentes = ({ id, requestTabla, setBloqueaCards, setError, moverATabla, informacionAdicional }) => {

    const [infoTabla, setInfoTabla] = useState(null);

    useEffect(() => {
        const servicioProrrogados = () => {
            setBloqueaCards(true);
            axios.post(`${servicios_grupohit_nomina}/procesos/estadisticas/prorrogados`, requestTabla, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        setInfoTabla(arrayJsonMovimientosPorPeriodoProrrogados(res.data.dataResponse, 1));
                        break;
                    default:
                        setInfoTabla([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setInfoTabla([]); setError(<AlertForm message={verifique_conexion} />) })
            .finally(() => moverATabla() );
        };

        servicioProrrogados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
        {(infoTabla)
        ?   <DataTableService
                id={`${id}ProrrogaContratoV`}
                title={`${prorrogas_contratos} - ${mov_vigentes}`}
                columns={[
                    { field: "empleadoClave", title: "NUE", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                    { field: "empleadoNomCompleto", title: "Nombre" },
                    { field: "empleadoRfc", title: "RFC" },
                    { field: "nomina", title: "Clave Nómina / Nómina" },
                    { field: "cpresupuestal", title: "Clave Presupuestal / Proyecto", customFilterAndSearch: (term, rowData) => rowData.cpresupuestal.startsWith(term), hidden: true, hiddenByColumnsButton: true },
                    { field: "nivlaboralCve", title: "Nivel Laboral", hidden: true, hiddenByColumnsButton: true },
                    { field: "plazaClave", title: "NUP", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                    { field: "puesto", title: "Clave Puesto / Puesto" },
                    { field: "contratoSaoNom", title: "Contrato" },
                    { field: "empcontratosaoComentario", title: "Contrato Comentario" },
                    { field: "empcontratosaoFecIni", title: "Fecha Inicial Contrato" },
                    { field: "empcontratosaoFecFin", title: "Fecha Final Contrato" }
                ]}
                data={infoTabla}
                customOptionsTable={{ filtering: true }} 
                hideIconAdd hideIconEdit hideIconDelete
                informacionAdicional={informacionAdicional}
            />
        :   <SpinnerOval/>
        }
        </Fragment>
    );
}
 
export default ProrrogaContratoVigentes;
