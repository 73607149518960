import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, registro_patronal, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonRegistroPatronal } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const RegistroPatronal = ({ id, permisos }) => {
    const [registroPatronal, setRegistroPatronal] = useState(null);
    const [tiposSeguridadSocial, setTiposSeguridadSocial] = useState(null);
    const [tiposRiesgo, setTiposRiesgo] = useState( null );
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        axiosRegistroPatronal();
        axiosTipoSeguridadSocial();
        axiosTiposRiesgo();
    },[]);

    const axiosRegistroPatronal = () => {
        Axios.get(`${servicios_grupohit_catalogo}/registropat/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setRegistroPatronal( ArrayJsonRegistroPatronal( res.data.dataResponse ) );
                        break;
                    case 404:
                        setRegistroPatronal([]);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    /* Axios para obtener Tipos de Seguridad Social */
    const axiosTipoSeguridadSocial = () => {
        Axios.get(`${servicios_grupohit_catalogo}/tiposegsoc/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setTiposSeguridadSocial(res.data.dataResponse);
                        break;
                    case 404:
                        setTiposSeguridadSocial([]);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    /* Axios para obtener Tipos de Riesgo */
    const axiosTiposRiesgo = () => {
        Axios.get(`${servicios_grupohit_catalogo}/tiporiesgo/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setTiposRiesgo(res.data.dataResponse);
                        break;
                    case 404:
                        setTiposRiesgo([]);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch(error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    return (
        <div id={`modulo${id}`}>
            { registroPatronal && tiposSeguridadSocial && tiposRiesgo ?
                <DataTableService id={id} title={ registro_patronal }
                    columns={[
                        { field: "registroPatClave", title: "Clave" },
                        { field: "segSocNom", title: "Seguridad Social" },
                        { field: "tipoRiesgoNom", title: "Riesgo de Trabajo" }
                    ]}
                    data={registroPatronal}
                    informacionAdicional={{ tiposSeguridadSocial, tiposRiesgo }}
                    permisos={permisos}
                /> : <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default RegistroPatronal;
