import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService';
import { servicios_grupohit_url, servicios_grupohit_catalogo, servicios_grupohit_ayuda } from '../../../../../Constants/Constants'; 
import { informacionModulos } from '../../Helpers/HelperNavegacion';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { iconosDrawer } from '../../MenuDrawer/Drawer/IconosDrawer';
import TextField from '@material-ui/core/TextField';
import SpinnerOval from '../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../Services/AlertForm';
import { Tooltip } from 'reactstrap';
import { InfoRounded } from '@material-ui/icons';
import ModalService from '../../../../Usuarios/Usuario/ModalService/ModalService';

const VideosTutoriales = ({ id, permisos }) => {

    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
    const [ modulos, setModulos ]= useState([
                                            {id:1000, nombre: 'Pantalla de Ingreso', icono:'exit_to_app'},
                                            {id:1001, nombre: 'Recuperar Contraseña ', icono:'replay'},
                                            {id:1002, nombre: 'Cambio de Contraseña', icono:'cached'},
                                            {id:1003, nombre: 'Aviso de Privacidad / Términos y Condiciones de Uso', icono:'info'},
                                        ]); 
    const [ openVideo, setOpenVideo ] = useState( false ); 
    const [ titleVideo, setTitleVideo ] = useState( '' );
    const [ moduleName, setModuleName ] = useState( '' );
    const [ fileNotFound, setFileNotFound ] = useState(null);
    const [ tooltipOpenModulo, setTooltipOpenModulo ] = useState(false);
    const [ videosServer, setVideosServer ] =useState([]);
 
    useEffect(() => {
        

        axios.get(`${servicios_grupohit_catalogo}/documentos/ayuda/`, headersAuth())
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        setVideosServer( res.data.dataResponse[0] );
                        getModulosDisponibles(res.data.dataResponse[0])
                        break;
                    default:
                        break;
                }
            } )
            .catch( error => { console.log( error ) });
            // eslint-disable-next-line
    }, []); 
    

    const getModulosDisponibles = ( files  ) => {
        axios.get( `${servicios_grupohit_url}/menu`, headersAuth() )
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        const menuList = informacionModulos(res.data.dataResponse.menu).filter( element => files.includes(`${element.id}.mp4`)) ;
                        setModulos([...modulos, ...menuList]);
                        break;
                    default:
                        break;
                }
            } )
            .catch( error => { console.log( error ) });
    }

    const validar = (e) => {
        if (e === undefined || e === null) {
            setFileNotFound(null);
        } else {
            let result = modulos.filter(mod => e.id === mod.id);
            if(result.length !== 0 ){ 
                if(videosServer.includes(`${result[0].id}.mp4`)){
                    setTitleVideo(result[0].id);
                    setFileNotFound(null);  
                    setModuleName(result[0].nombre);
                    setOpenVideo( true ); 
                }else{
                    setFileNotFound( <AlertForm message="No se ha encontrado tutorial para el módulo seleccionado." /> );
                }     
            }
        }
    }

    const toggle = () => { 
        setTooltipOpenModulo(!tooltipOpenModulo); 
    }

    return (
        <div id={`modulo${id}`}>
            <div className="row justify-content-center">
                {
                    modulos ?
                    <div className="col-md-6 col-sm-12">
                    <div className="card py-2 px-3 mb-2">
                        <h4 className="mt-2"> Videos Tutoriales de Ayuda </h4> 
                        <hr/>
                        <label>{'Submódulo *'} 
                            <span id={'tooltipModulo'} className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRounded color="disabled"/></span> 
                        </label>
                        <Tooltip placement="right" isOpen={tooltipOpenModulo} target="tooltipModulo" toggle={toggle}>
                            { 'Ingrese o seleccione el submódulo que desea consultar' }
                        </Tooltip>
                        <Autocomplete 
                        id="free-solo-demo" 
                        autoHighlight={true}
                        onChange={(e,v) => validar(v)}
                        getOptionLabel={option => option.nombre}
                        options={modulos.map(modu => ({'nombre':modu.nombre,'icono':modu.icono, id: modu.id}))}
                        size="small" 
                        blurOnSelect={true}
                        noOptionsText="No existen coincidencias" 
                        renderOption={ (options,{inputValue}) => {
                            const matches = match(options.nombre, inputValue)
                            const parts = parse(options.nombre, matches);
                            return (
                                <React.Fragment>
                                    <table >
                                        <thead>
                                            <tr>
                                                <th className="Icono">
                                                    { iconosDrawer[ options.icono ] }
                                                </th>
                                                <th >
                                                    { parts.map((part, index) => (
                                                        <span key={index} style={{ color: part.highlight ? '#4C638E': 'black' }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </React.Fragment>
                            );
                        }}      
                        renderInput={ params => (
                                <TextField
                                    id="mui-theme-provider-outlined-input"
                                    {...params} 
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                        )}
                        />
                        {fileNotFound}
                    </div> 
                    </div>
                    : <SpinnerOval/>
                }
                
            </div>
            {
                openVideo
                &&
                <ModalService title={ moduleName } parentNode={ id } className="modal-style"
                    isOpen={ openVideo } onClose={ () => setOpenVideo   (false) } minWidth='80%' >
                    <div className='embed-responsive' style={{ paddingBottom: '60%'}}>
                        <video controls autoPlay>
                            <source src={`${servicios_grupohit_ayuda}/mp4/${titleVideo}.mp4`} onError={()=>{setOpenVideo(false); setFileNotFound( <AlertForm message="No se ha encontrado tutorial para el módulo seleccionado." /> ); }} type="video/mp4"/>  
                        </video>
                    </div>
                </ModalService>
            }
            
        </div>
    );
}
 
export default VideosTutoriales;