import { longDateToInput, floatToAmount } from '../../../../Services/Validation/HelperValidation';
import ConvertDate, { ConvertDateTime } from './ConvertDate';

export const ArrayJsonIndicadoresGenerales = data => { 
    
    const newJson = data.map(item => JsonIndicadoresGenerales(item)); 

    return newJson;
};

export const JsonIndicadoresGenerales = data => {
    const newJson = {
                        id: data.id,
                        indicadorClave: data.indicadorClave,
                        indicadorNom: data.indicadorNom,
                        relacionLabId: data.relacionLab.id,
                        relacionLabNombre: data.relacionLab.nombre,
                        naturalezaId: data.naturaleza.id,
                        tipoNatuNom: data.naturaleza.tipoNatuNom,
                        indicadorSat: data.indicadorSat,
                        clasificaSatId: data.clasificaSat.id,
                        clasificaNom: data.clasificaSat.clasificaNom,
                        partida: `${data.cuentas.PARTIDAGEN ? data.cuentas.PARTIDAGEN : ''}${data.cuentas.PARTIDAESP ? data.cuentas.PARTIDAESP : ''}`,
                        partidaGen: (data.cuentas.PARTIDAGEN) ? data.cuentas.PARTIDAGEN : '',
                        partidaEsp: (data.cuentas.PARTIDAESP) ? data.cuentas.PARTIDAESP : '',
                        cuenta: `${data.cuentas.CTACONTABLE ? data.cuentas.CTACONTABLE : ''}${data.cuentas.SUBCTA ? data.cuentas.SUBCTA : ''}`,
                        cuentaCon: (data.cuentas.CTACONTABLE) ? data.cuentas.CTACONTABLE : '',
                        subcuentaCon: (data.cuentas.SUBCTA) ? data.cuentas.SUBCTA : '',
                        indicadorNomClve: `${ data.indicadorClave} - ${data.indicadorNom}`,
                        prioridad: data.prioridad,
                        rutinaId: data.rutina ? data.rutina.id: null,
                        recibo: data.recibo,
                        montoMin: data.montoMin ? floatToAmount(data.montoMin, 2) :'',
                        montoMax: data.montoMax ? floatToAmount(data.montoMax, 2) :'',
                        sueldo: data.sueldo,
                        nombramiento: data.nombramiento,
                        defecto: data.defecto,
                    };

    return newJson;
};

export const ArrayJsonIndicadoresGeneralesByRelLab = data => {
    const newJson = data.map( item => JsonIndicadoresGeneralesByRelLab( item ) );
    return newJson;
};

export const JsonIndicadoresGeneralesByRelLab = data => {
    return {
        id: data.indicadorId,
        indicadorClave: data.indicadorClave,
        indicadorNom: data.indicadorNom,
        relacionLabId: data.relacionLab.id,
        relacionLabNombre: data.relacionLab.nombre,
        indicadorNomClve: `${ data.indicadorClave} - ${data.indicadorNom}`,
        naturalezaId: data.naturaleza.id,
        tipoNatuNom: data.naturaleza.tipoNatuNom,
        indicadorSat: data.indicadorSat,
        clasificaSatId: data.clasificaSat.id,
        clasificaNom: data.clasificaSat.clasificaNom,
        rutinaId: data.rutina,
        prioridad: data.prioridad, 
        recibo: data.recibo
    };
};

export const ArrayJsonIndicadoresByNomina = data => {
    const newJson = data.map( item => JsonIndicadoresByNomina( item ) );
    return newJson;
};

export const JsonIndicadoresByNomina = data => {
    
    return {
        id: data.id,
        indicadorClave: data.nomClave,
        indicadorNom: data.nomIndicador,
        relacionLabId: data.indicador.relacionLab.id,
        relacionLabNombre: data.indicador.relacionLab.nombre,
        indicadorNomClve: `${ data.nomClave} - ${data.nomIndicador}`,
        naturalezaId: data.indicador.naturaleza.id,
        tipoNatuNom: data.indicador.naturaleza.tipoNatuNom,
        indicadorSat: data.indicador.indicadorSat,
        clasificaSatId: data.indicador.clasificaSat.id,
        clasificaNom: data.indicador.clasificaSat.clasificaNom,
        rutinaId: data.rutina.id,
        prioridad: data.prioridad, 
        recibo: data.nomRecibo
    };
};

export const ArrayJsonProyectos = data => {
    const newJson = data.map( item => (
        JsonProyectos(item)
    )); 

    return newJson;
};

export const JsonProyectos = data => {
    const newJson = {
                        id: data.id,
                        cpresupuestalclave: data.cpresupuestalclave,
                        cpresupuestalnom: data.cpresupuestalnom,
                        obraAccionid: data.obraAccion.id,
                        obraAccionclave: data.obraAccion.obraAccionClave,
                        obraAccionnom: data.obraAccion.obraAccionNom,
                        proyectoid: data.obraAccion.proyecto.id,
                        proyectonom: data.obraAccion.proyecto.proyectonom,
                        proyectoclave: data.obraAccion.proyecto.proyectoclave,
                        subprogramaid: data.obraAccion.proyecto.subPrograma.id,
                        subprogramaclave: data.obraAccion.proyecto.subPrograma.subProgramaClave,
                        subprogramanom: data.obraAccion.proyecto.subPrograma.subProgramaNom,
                        programaid: data.obraAccion.proyecto.subPrograma.programa.id,
                        programanom: data.obraAccion.proyecto.subPrograma.programa.programanom,
                        programaclave: data.obraAccion.proyecto.subPrograma.programa.programaclave,
                        udejecutoraid: data.obraAccion.proyecto.subPrograma.programa.udejecutoraid.id,
                        udejecutoraclave: data.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoraclave,
                        udejecutoranom: data.obraAccion.proyecto.subPrograma.programa.udejecutoraid.udejecutoranom,
                        ciaid: data.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id,
                        ciaUr: data.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaUr,
                        ciaNomCorto: data.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaNomCorto,
                        ciaRazonSocial: data.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.ciaRazonSocial
                    };

    return newJson;
};

/* Registros Patronales */
export const ArrayJsonRegistroPatronal = data => {
    const newJson = data.map( item => JsonRegistroPatronal( item ) );
    return newJson;
};

export const JsonRegistroPatronal = data => {
    return {
        id: data.id,
        registroPatClave: data.registroPatClave,
        segSocId: data.segSoc.id,
        segSocNom: data.segSoc.segSocNom,
        tipoRiesgoId: data.tipoRiesgo.id,
        tipoRiesgoNom: data.tipoRiesgo.riesgoPtoDesc
    };
};

/* Tipo de Documentos */
export const ArrayJsonTipoDocumentos = data => {
    const newJson = data.map( item => JsonTipoDocumentos( item ) );
    return newJson;
};

export const JsonTipoDocumentos = data => {
    return {
        id: data.id,
        tipodocNom: data.tipodocNom,
        tipodocDesc: data.tipodocDesc,
        tipodocCant: data.tipodocCant,
        tipoextId: data.extencion.id,
        tipoextNom: data.extencion.tipoextNom
    };
};

/* Monto Mínimo de Liquidez */
export const ArrayJsonMontoMinimo = data => {
    const newJson = data.map( item => JsonMontoMinimo( item ) );
    return newJson;
};

export const JsonMontoMinimo = data => {
    return {
        id: data.id,
        montominimo: floatToAmount(data.montominimo, 2),
    };
};

/* Dependencias */
export const ArrayJsonDependencias = data => {
    const newJson = data.map( item => JsonDependencias( item ) );
    return newJson;
};

export const JsonDependencias = data => {
    return {
        id: data.id,
        ciaRfc: data.ciaRfc,
        ciaUr: data.ciaUr,
        ciaRazonSocial: data.ciaRazonSocial,
        ciaNomCorto: data.ciaNomCorto,
        ciaUrRazonSocial: `${data.ciaUr} / ${data.ciaRazonSocial}`,

        tipoDependId: data.tipoDepend.id,
        tipoDependNombre: data.tipoDepend.nombre,
        giroId: data.giroId.id,
        giroNom: data.giroId.giroNom,

        ciaFecConst: ConvertDate( data.ciaFecConst ),
        cuentaBancaria: data.cuentaBancaria,
        ciaWeb: data.ciaWeb,
        origenRecurso:''
    };
};

/* Usuarios */
export const ArrayJsonUsuarios = data => {
    const newJson = data.map( item => JsonUsuarios( item ) );
    return newJson;
};

export const JsonUsuarios = data => {
    return {
        id: data.id,
        datosUsuarioNom: data.datosUsuarioNom,
        datosUsuarioPat: data.datosUsuarioPat,
        datosUsuarioMat: data.datosUsuarioMat,
        foto: data.foto,
        status: data.status,
        username: data.username,
        verificado: data.verificado,
        nombreCompleto: `${data.datosUsuarioNom} ${data.datosUsuarioPat} ${data.datosUsuarioMat}`,
        usuarioCompleto: `${data.username} / ${data.datosUsuarioNom} ${data.datosUsuarioPat} ${data.datosUsuarioMat ? data.datosUsuarioMat : ''}`,
    };
};

export const ArrayJsonPuestos = data => {
    const newJson = data.map( item => JsonPuestos( item ) );
    return newJson;
};

export const JsonPuestos = data => {     
    return {
        id:data.id,
        nivlaboralId: data.nvlId.id,
        nivlaboralClave: data.nvlId.nivlaboralClave,
        puestoNom: data.puestoNom,
        puestoCve: data.puestoCve,
        puestoDescrip: data.puestoDescrip,
        relabId: data.relabId.id,
        relabIdNombre: data.relabId.nombre,
        relabIdDescripcion: data.relabId.descripcion,
        status: data.statusId.statusNom.toUpperCase(),
        nombramiento: data.nombramiento
    };
};

export const ArrayJsonTurnos = data => {
    const newJson = data.map( item => JsonTurnos( item ) );
    return newJson;
};

export const JsonTurnos = data => {
    return {
        id:data.id,
        turnoNom:data.turnoNom,
        jornadaNombre: data.jornada.nombre,
        jornadaId: data.jornada.id,
        turnoClave: data.turnoClave
    };
};

export const JsonTurnosAdd = data => {
    return {
        id:data.turno.id,
        turnoNom:data.turno.turnoNom,
        jornadaNombre: data.turno.jornada.nombre,
        jornadaId: data.turno.jornada.id,
        turnoClave: data.turno.turnoClave
    };
};

export const ArrayJsonContratoRelLab = data => {
    const newJson = data.map( item => JsonContratoRelLab( item ) );
    return newJson;
};

export const JsonContratoRelLab = data => {
    return {
        id:data.id,
        contratoSaoNom:data.contratoSaoNom,
        tipoContrato: data.tipoContrato.tipoContratoDesc,
        tipoContratoId: data.tipoContrato.id,
        relacionLaboral: data.relacionLaboral.nombre,
        relacionLaboralId: data.relacionLaboral.id,
        contratoSaoFechas: data.contratoSaoFechas ? 'Sí' : 'No',
    };
};

export const ArrayJsonSalarioMinUMA = data => {
    const newJson = data.map( item => JsonSalarioMinUMA( item ) );
    return newJson;
};

export const JsonSalarioMinUMA = data => {    
    return {
        id:data.id,
        fechaIni: ConvertDate(data.fechaIni),
        fechaFin: data.fechaFin!== null ? ConvertDate(data.fechaFin) : '-',
        monto: floatToAmount(data.monto, 2),
        zonaNom: data.zona.zonaNom,
        zonaId: data.zona.id,
        fechaIniInp: longDateToInput(data.fechaIni),
        fechaFinInp: longDateToInput(data.fechaFin)
    };
};

export const ArrayJsonSalarioMinUMAIndicadores = data => {
    const newJson = data.map( item => JsonSalarioMinUMAIndicadores( item ) );
    return newJson;
};

export const JsonSalarioMinUMAIndicadores = data => {    
    return {
        id: data.zona.id,
        fechaIni: ConvertDate(data.fechaIni),
        fechaFin: data.fechaFin!== null ? ConvertDate(data.fechaFin) : '-',
        monto: floatToAmount(data.monto, 2),
        zonaNom: data.zona.zonaNom, 
        fechaIniInp: longDateToInput(data.fechaIni),
        fechaFinInp: longDateToInput(data.fechaFin)
    };
};

/* ImpuestoSobreNomina */
export const ArrayJsonImpuestoNomina = data => {
    const newJson = data.map( item => JsonImpuestoNomina( item ) );
    return newJson;
};

export const JsonImpuestoNomina = data => {
    return {
        id:data.id,
        estadoId: data.estadoId.id,
        estadoNom: data.estadoId.estadoNom,
        porcentaje: data.porcentaje.toFixed(2),
        fechaini: longDateToInput( data.fechaini ),
        fechafin: longDateToInput( data.fechafin ),
        fechainiTab: data.fechaini ? ConvertDate( longDateToInput( data.fechaini ) ) : '-',
        fechafinTab: data.fechafin ? ConvertDate( longDateToInput( data.fechafin ) ) : '-',
    };
};

/* Sindicatos */
export const ArrayJsonSindicatos = data => {
    const newJson = data.map( item => JsonSindicatos( item ) );
    return newJson;
};

export const JsonSindicatos = data => {
    return {
        id:data.id,
        sindicatoAbrevia: data.sindicatoAbrevia,
        sindicatoNombre: data.sindicatoNombre,
        sindicatoDescrip: data.sindicatoDescrip,
        sindicatoPoc: data.sindicatoPoc,
        porcentajeCuota: data.sindicatoPoc === 'P' ? parseFloat(data.sindicatoMonto).toFixed(2) : '-',
        importeCuota: data.sindicatoPoc === 'C' ? parseFloat(data.sindicatoMonto).toFixed(2) : '-',
    };
};

/* Departamentos */
export const ArrayJsonDepartamentos = data => {
    const newJson = data.map( item => JsonDepartamentos( item ) );
    return newJson;
};

export const JsonDepartamentos = data => {
    return {
        id: data.id,
        deptoClave: data.deptoClave,
        deptoNom: data.deptoNom,
        ciaId: data.ciaId.id,
        ciaRazonSocial: data.ciaId.ciaRazonSocial,
        deptoDescrip: data.deptoDescrip,
    };
};

/* IMSS */
export const ArrayJsonIMSS = data => {
    data.sort((a, b)=> a.fechaini < b.fechaini ? 1 : a.fechaini > b.fechaini ? -1 :0 );
    const newJson = data.map( item => JsonIMSS( item ) );
    return newJson;
};

export const JsonIMSS = data => {
    return {
        id: data.id,
        fechaini: longDateToInput( data.fechaini ),
        fechafin: longDateToInput( data.fechafin ),
        enfmatpat: parseFloat(data.enfmatpat).toFixed(3),
        enfmatobr: parseFloat(data.enfmatobr).toFixed(3),
        pat: floatToAmount(data.pat, 2),
        sueldo: parseFloat(data.sueldo).toFixed(6),
        fechainiTab: data.fechaini ? ConvertDate( longDateToInput( data.fechaini ) ) : '-',
        fechafinTab: data.fechafin ? ConvertDate ( longDateToInput( data.fechafin ) ): '-',
    };
};

/* Pensiones */
export const ArrayJsonPensiones = data => {
    const newJson = data.map( item => JsonPensiones( item ) );
    return newJson;
};

export const JsonPensiones = data => {
    return {
        id: data.id,
        fechaini: longDateToInput( data.fechaini ),
        fechafin: longDateToInput( data.fechafin ),
        pensionFpo: parseFloat(data.pensionFpo).toFixed(2),
        pensionFpp: parseFloat(data.pensionFpp).toFixed(2),
        fechainiTab: data.fechaini ? ConvertDate ( longDateToInput( data.fechaini ) ) : '-',
        fechafinTab: data.fechafin ? ConvertDate ( longDateToInput( data.fechafin ) ) : '-',
    };
};

/* Préstamos */
export const ArrayJsonPrestamos = data => {
    const newJson = data.map( item => JsonPrestamos( item ) );
    return newJson;
};

export const JsonPrestamos = (data, elementSelect) => {
    return {
        id: data.id,
        folio: data.folio,
        indicadorId: data.indicador.id,
        indicadorNombre: `${data.indicador.nomClave} - ${data.indicador.nomIndicador}`,
        numeroPagos: data.numeroPagos,
        numPago: elementSelect ? elementSelect.numPago : 0,
        numeroPagosPagados: elementSelect ? `${elementSelect.numPago} / ${data.numeroPagos}` : `0 / ${data.numeroPagos}`,
        empPrestIniPagos: data.empPrestIniPagos || data.empPrestIniPagos === 0 ? data.empPrestIniPagos : '',
        importe: floatToAmount(data.importe, 2),
        fechaIni: longDateToInput( data.fechaIni ),
        fechaFin: longDateToInput( data.fechaFin ),
        fechaIniTab: data.fechaIni ? ConvertDate( longDateToInput( data.fechaIni ) ) : '-',
        fechaFinTab: data.fechaFin ? ConvertDate( longDateToInput( data.fechaFin ) ) : '-',
        empPrestNota: data.empPrestNota ? data.empPrestNota : '',
    };
};

export const ArrayJsonPrestamos2 = (data, indicadores) => {
    const newJson = data.map( item => JsonPrestamos2( item, indicadores ) );
    return newJson;
};

export const JsonPrestamos2 = (data, indicadores) => {
    const indicador = indicadores.find(element => element.id === data.nomindId);
    return {
        id: data.id,
        folio: data.empprestFolio,
        indicadorId: data.nomindId,
        indicadorNombre: `${indicador.nomClave} - ${indicador.nomIndicador}`,
        numeroPagos: data.empprestNumPagos,
        numPago: data.numPago,
        numeroPagosPagados: `${data.numPago} / ${data.empprestNumPagos}`,
        empPrestIniPagos: data.empPrestIniPagos || data.empPrestIniPagos === 0 ? data.empPrestIniPagos : '',
        importe: floatToAmount(data.empprestImporte, 2),
        fechaIni: longDateToInput( data.empprestFecini ),
        fechaFin: longDateToInput( data.empprestFecfin ),
        fechaIniTab: data.empprestFecini ? ConvertDate( longDateToInput( data.empprestFecini ) ) : '-',
        fechaFinTab: data.empprestFecfin ? ConvertDate( longDateToInput( data.empprestFecfin ) ) : '-',
        empPrestNota: data.empPrestNota ? data.empPrestNota : '',
        calculo: data.calculo,
    };
};

/* Indicador Deducciones */
export const ArrayIndicadorDeducciones = data => {
    const newJson = data.map( item => IndicadorDeducciones( item ) );
    return newJson;
};

export const IndicadorDeducciones = data => {
    return {
        //id: data.indicadorId,
        id: data.indicadorClave,
        indicadorClave: data.indicadorClave,
        indicadorNom: data.indicadorNom,
        naturaleza: data.naturaleza,
        indicadorInfo: `${data.indicadorClave} - ${data.indicadorNom}`,
    };
};

/* Retroactivos - Periodos */
export const ArrayJsonPeriodosRetroactivos = data => {
    const newJson = data.map( item => JsonPeriodosRetroactivos( item ) );
    return newJson;
};

export const JsonPeriodosRetroactivos = data => {
    return {
        id: data.id,
        nominaId: data.nominaId,
        periodoAnio: data.periodoAnio,
        periodoCerrado: data.periodoCerrado,
        periodoDiasImss: data.periodoDiasImss,
        periodoDiasNom: data.periodoDiasNom,
        periodoFecIni: longDateToInput( data.periodoFecIni ),
        periodoFecFin: longDateToInput( data.periodoFecFin ),
        periodoFecIniTab: data.periodoFecIni ? ConvertDate ( longDateToInput( data.periodoFecIni ) ) : '-',
        periodoFecFinTab: data.periodoFecFin ? ConvertDate ( longDateToInput( data.periodoFecFin ) ) : '-',
        periodoMes: data.periodoMes,
        periodoPerAnio: (data.periodoPerAnio===10) ? '9/10' : (data.periodoPerAnio===24) ? '23/24' : data.periodoPerAnio,
        periodoPerAnioFecIni: `${(data.periodoPerAnio===10) ? '9/10' : (data.periodoPerAnio===24) ? '23/24' : data.periodoPerAnio} - ${data.periodoFecIni ? ConvertDate ( longDateToInput( data.periodoFecIni ) ) : '-'}`,
        periodoPerAnioFecFin: `${(data.periodoPerAnio===10) ? '9/10' : (data.periodoPerAnio===24) ? '23/24' : data.periodoPerAnio} - ${data.periodoFecFin ? ConvertDate ( longDateToInput( data.periodoFecFin ) ) : '-'}`,
    };
};

export const ArrayJsonTablasImpuestos = data => {
     
    data.sort((a, b)=> a.id < b.id ? 1 : a.id > b.id ? -1 :0 );
    var periodosIndex=[];
    var periodosId=[];
    data.map((key, index)=>{
        if(!periodosId.includes(key.periodoImpto.id)){
            periodosId.push(key.periodoImpto.id);
            periodosIndex.push(index);
        }
        return null;
    })
    for (const key in periodosIndex) {
        data[periodosIndex[key]].status=true;
    }  
    const newJson = data.map( (item, index) => JsonTablasImpuestos( item ) );    
    return newJson;
};

export const JsonTablasImpuestos = data => {
    
    return {
        id:data.id,
        periodoImptoId: data.periodoImpto.id,
        periodoImpto: data.periodoImpto.periodoNombre,
        fechaIni: data.periodoImpto.periodoNombre==='Anual' ? longDateToInput(data.fechaIni).split('-')[0] : longDateToInput(data.fechaIni).split('-').reverse().join('/'),
        fechaFin: data.periodoImpto.periodoNombre==='Anual' && data.fechaFin!== null ? longDateToInput(data.fechaIni).split('-')[0] : data.fechaFin!== null ? longDateToInput(data.fechaFin).split('-').reverse().join('/'):'-',
        fechaIniLong: data.fechaIni,
        fechaFinLong: data.fechaFin,
        status: data.status ? data.status: false
    };
};

export const ArrayJsonTablasImpuestosAdd = data => {

    data.sort((a, b)=> a.id < b.id ? 1 : a.id > b.id ? -1 :0 );
    var periodosIndex=[];
    var periodosId=[];
    data.map((key, index)=>{
        if(key.status && key.periodoImptoId === data[0].periodoImptoId){
            data[index].status=false;
        }
        if(!periodosId.includes(key.periodoImptoId)){
            periodosId.push(key.periodoImptoId);
            periodosIndex.push(index);
        }
        return null;
    })
    for (const key in periodosIndex) {
        data[periodosIndex[key]].status=true;
    }  
    return data;
};


export const ArrayJsonIndicadores = data => {
    const newJson = data.map( item => JsonIndicadores( item ) ); 
    return newJson.sort((a, b)=> a.prioridad > b.prioridad ? 1 : a.prioridad < b.prioridad ? -1 :0 );
};

export const JsonIndicadores = data => {  
    return {
        id:data.id,
        nomClaveIndicador: data.nomClave,
        nomIndicador: data.nomIndicador,
        idNaturaleza: data.indicador.naturaleza.id,
        nomNaturaleza: data.indicador.naturaleza.tipoNatuNom,
        indicadorSat: data.indicador.indicadorSat,
        clasificaSat: data.indicador.clasificaSat.clasificaNom,
        nomRecibo: data.nomRecibo ? 'Sí' : 'No',
        relacionLaboral: data.indicador.relacionLab.nombre,
        prioridad: data.prioridad,
        rutinaId: data.rutina.id,
        partida: data.indicador.cuentas ? `${data.indicador.cuentas.PARTIDAGEN ? data.indicador.cuentas.PARTIDAGEN : ''}${data.indicador.cuentas.PARTIDAESP ? data.indicador.cuentas.PARTIDAESP : ''}` : '-',
        cuenta: data.indicador.cuentas ? `${data.indicador.cuentas.CTACONTABLE ? data.indicador.cuentas.CTACONTABLE : ''}${data.indicador.cuentas.SUBCTA ? data.indicador.cuentas.SUBCTA : ''}` : '-',
        nominAlias: data.nomAlias
    };
};

export const ArrayJsonIndGralsConfigBases = data => {
    const newJson = data.map( (item, index) =>  JsonIndGralsConfigBases( item, index ) );
    return newJson;
};

export const JsonIndGralsConfigBases = (data, index) => {  
    return {
        id:data.id,
        baseClave: data.baseClave,
        baseNom: data.baseNom,
        baseDescrip: data.baseDescrip,
        operacion:`${index}/ `
    };
};

export const ArrayJsonIndGralsConfigBasesEdit = data => { 
    const newJson = data.map( (item, index) =>  JsonIndGralsConfigBasesEdit( item, index ) );
    return newJson;
};

export const JsonIndGralsConfigBasesEdit = (data, index) => {
    return {
        id:data.id,
        indicadorId: data.indicador.indicadorId,
        baseId: data.base.id,
        baseClave: data.base.baseClave,
        baseNom: data.base.baseNom,
        baseDescrip: data.base.baseDescrip,
        operacion: `${index}/${data.operacion}`
    };
};

export const ArrayJsonIndNominaConfigBasesEdit = data => { 
    const newJson = data.map( (item, index) =>  JsonIndNominaConfigBasesEdit( item, index ) );
    return newJson;
};

export const JsonIndNominaConfigBasesEdit = (data, index) => {
    return {
        id         : data.id,
        nominId    : data.nomind.id,
        indicadorId: data.nomind.indicador.indicadorId,
        baseId     : data.base.id,
        baseClave  : data.base.baseClave,
        baseNom    : data.base.baseNom,
        baseDescrip: data.base.baseDescrip,
        operacion  : `${index}/${data.operacion}`
    };
};

export const ArrayJsonPrimaVacacional = data => { 
    const newJson = data.map( (item, index) =>  JsonPrimaVacacional( item ) );
    return newJson;
};

export const JsonPrimaVacacional = data => {
    return {
        id:data.id+'PV',
        tipo: 'Prima Vacacional',
        fechaIni: ConvertDate( longDateToInput( data.fechaIni )) ,
        fechaFin: data.fechaFin ? ConvertDate( longDateToInput( data.fechaFin )) :'-',
        diasPrimaAnio: data.diasPrimaAnio,
        periodoPagoSem1: data.periodoPagoSem1,
        periodoPagoSem2: data.periodoPagoSem2,
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonAguinaldo = data => { 
    const newJson = data.map( (item, index) =>  JsonAguinaldo( item ) );
    return newJson;
};

export const JsonAguinaldo = data => {
    return {
        id:data.id+'AG',
        tipo: 'Aguinaldo',
        fechaIni: longDateToInput( data.fechaIni ).split('-').reverse().join('/') ,
        fechaFin: data.fechaFin ? ConvertDate( longDateToInput( data.fechaFin )) :'-',
        diasAguinaldoAnio: data.diasAguinaldoAnio,
        diasBonoAnio: data.diasBonoAnio,
        periodoPagoSem1: data.periodoPagoSem1,
        periodoPagoSem2: data.periodoPagoSem2,
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonDiasAjuste = data => { 
    const newJson = data.map( (item, index) =>  JsonDiasAjuste( item ) );
    return newJson;
};

export const JsonDiasAjuste = data => {
    return {
        id:data.id+'DA',
        tipo: 'DÍAS DE AJUSTE',
        fechaIni: ConvertDate( longDateToInput( data.fechaIni )) ,
        fechaFin: data.fechaFin ? ConvertDate( longDateToInput( data.fechaFin )) :'-',
        diasAjuste: data.diasAjuste,
        diasMinLab: data.diasMinLab,
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonVacaciones = data => { 
    const newJson = data.map( (item, index) =>  JsonVacaciones( item ) );
    return newJson;
};

export const JsonVacaciones = data => {
    return {
        id:data.id+'VA',
        tipo: 'Vacaciones',
        fechaIni: ConvertDate( longDateToInput( data.fechaIni )) ,
        fechaFin: data.fechaFin ? ConvertDate( longDateToInput( data.fechaFin ))  :'-',
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonAusentismosPoliticas = data => {
    const newJson = data.map(item => JsonAusentismosPoliticas(item));
    return newJson;
};

export const JsonAusentismosPoliticas = data => {
    return {
        id: data.id+'AU',
        tipo: 'Ausentismos',
        fechaIni: ConvertDate(longDateToInput(data.fechaini)),
        fechaFin: data.fechafin ? ConvertDate(longDateToInput(data.fechafin)) : '-',
        fechaIn: longDateToInput(data.fechaini),
        fechaFi: data.fechafin ? longDateToInput(data.fechafin) : null,
    };
};

export const JsonAusentismosPoliticas2 = data => {
    return {
        id: data.PolAusentismo.id+'AU',
        tipo: 'Ausentismos',
        fechaIni: ConvertDate(longDateToInput(data.PolAusentismo.fechaini)),
        fechaFin: data.PolAusentismo.fechafin ? ConvertDate(longDateToInput(data.PolAusentismo.fechafin)) : '-',
        fechaIn: longDateToInput(data.PolAusentismo.fechaini),
        fechaFi: data.PolAusentismo.fechafin ? longDateToInput(data.PolAusentismo.fechafin) : null,
    };
};

export const ArrayJsonTablasSubsidios = data => { 
    const newJson = data.map( item  =>  JsonTablasSubsidios( item ) );
    return newJson;
};

export const JsonTablasSubsidios = data => {
    return {
        id:data.id,
        limiteInf: floatToAmount( data.limiteInf, 2 ),
        limiteSup: data.limiteSup ? floatToAmount( data.limiteSup, 2) : 0, 
        importe: floatToAmount( data.importe, 2)
    };
};

export const ArrayJsonTablasImpuestosData= data => { 
    const newJson = data.map( item  =>  JsonTablasImpuestosData( item ) );
    return newJson;
};

export const JsonTablasImpuestosData = data => {
    return {
        id:data.id,
        limiteInf: floatToAmount( data.limiteInf, 2 ),
        limiteSup: data.limiteSup ? floatToAmount( data.limiteSup, 2) : '', 
        cuotaFija: parseFloat( data.cuotaFija).toFixed(2),
        porcentaje: parseFloat( data.porcentaje).toFixed(2)
    };
};


export const ArrayJsonTabuladoresSueldo= data => { 
    const newJsonData = data.map( item  =>  JsonTabuladoresSueldo( item ) ); 
    return newJsonData;
};

export const JsonTabuladoresSueldo = data => {
    const newJsonIndicador={};
    const auxiliar = data.indicadores.map( item  =>  JsonTabuladoresSueldoIndicadores( item ) ); 
    Object.assign(newJsonIndicador, ...auxiliar); 
    return {
        puestoId:data.id,
        nivel: data.nivlaboralClave,
        codigo: data.puestoCve,
        puesto: data.puestoNom,
        ...newJsonIndicador
    };
}; 
export const JsonTabuladoresSueldoIndicadores = data => {
    const element={};
    element[data.id] = floatToAmount(data.monto, 2);
    return element;
};

export const ArrayJsonUsuariosReportesRRHH = data => {
    const newJson = data.map( item => JsonUsuariosReportesRRHH( item ) );
    return newJson;
};

export const JsonUsuariosReportesRRHH = data => {
    
    return {
        id: data.id,
        empleadoClave: data.empleadoClave,
        empleadoNom: data.empleadoNom,
        empleadoPat: data.empleadoPat,
        empleadoMat: data.empleadoMat,  
        empleadoFecnac: ConvertDate( longDateToInput( data.empleadoFecnac)),  
        empleadoCurp  : data.empleadoCurp,
        empleadoRfc  : data.empleadoRfc,
        empantFecini  : ConvertDate( longDateToInput( data.empantFecini)),
        puestoCve  : data.puestoCve,
        puestoNom: data.puestoNom,
        relabClave  : data.relabClave,
        realabNom  : data.realabNom,
        cpresupuestalClave  : data.cpresupuestalClave,
        cpresupuestalNom : data.cpresupuestalNom, 
        turnoNom  : data.turnoNom,
        tipojornadaClave: data.tipojornadaClave,  
        tipojornadaNom : data.tipojornadaNom, 
        nominaClave  : data.nominaClave,
        nominaNomCorto : data.nominaNomCorto, 
        nominaNombre : data.nominaNombre, 
        plazaClave : data.plazaClave, 
        areaNom : data.areaNom, 
        nivLaboralClave: data.nivLaboralClave,
        udejecutoraClave: data.udejecutoraClave,
        udejecutoraNom: data.udejecutoraNom,
        ciaUr: data.ciaUr,
        ciaRazonSocial: data.ciaRazonSocial,
        proyectoClave: data.proyectoClave,
        proyectoNom: data.proyectoNom,
        programaClave: data.programaClave,
        programaNom: data.programaNom,
        empleadoGenero: data.empleadoGenero,
        zonaPago: data.zonaPago
    };
};

export const ArrayJsonEmpleadosReportesNominas = data => {
    const newJson = data.map( item => JsonEmpleadosReportesNominas( item ) );
    return newJson;
};

export const JsonEmpleadosReportesNominas = data => {
    
    return {
        id: data.id,
        empleadoId: data.empleadoId,
        empleadoClave: data.empleadoClave,
        nombreCompleto: `${data.empleadoNom} ${data.empleadoPat} ${data.empleadoMat}`, 
        empleadoFecnac: ConvertDate( longDateToInput( data.empleadoFecnac)),  
        empleadoCurp  : data.empleadoCurp,
        empleadoRfc  : data.empleadoRfc,
        empleadoNss  : data.empleadoNss, 
        cpresupuestalClave  : data.cpresupuestalClave,
        cpresupuestalNom : data.cpresupuestalNom, 
        ciaUr  : data.ciaUr,
        ciaRazonSocial: data.ciaRazonSocial,
        nominaId  : data.nominaId,
        nomindClave  : data.nomindClave,
        tiponatuId  : data.tiponatuId,
        tiponatuNom: data.tiponatuNom,  
        nomindNomIndicador : data.nomindNomIndicador, 
        cantidad  : parseFloat(data.cantidad).toFixed(2),
        monto : floatToAmount(data.monto, 2), 
        periodoId : data.periodoId, 
        acumuladoClave : data.acumuladoClave, 
        acumuladoNombre : data.acumuladoNombre, 
        periodoFecini : ConvertDate( longDateToInput( data.periodoFecini)), 
        periodoFecfin : ConvertDate( longDateToInput( data.periodoFecfin)), 
        periodoPeranio : data.periodoPeranio, 
        periodoAnio : data.periodoAnio,
        plazaClave: data.plazaClave,
        puestoCve: data.puestoCve,
        puestoNom: data.puestoNom,
        nominaNomCorto: data.nominaNomCorto 
    };
};

/* Cargas Masivas - Pagos Únicos */
export const ArrayJsonEmpleadosPagosUnicos = data => {
    const newJson = data.map( item => JsonEmpleadosPagosUnicos( item ) );
    return newJson;
};

export const JsonEmpleadosPagosUnicos = data => {
    return {
        id: data.id,
        acumId: data.acumId,
        empleadoId: data.empleadoId,
        empleadoClave: data.empleadoClave,
        nombreCompleto: `${data.empleadoNom} ${data.empleadoPat} ${data.empleadoMat}`,
        //empleadoFecnac: ConvertDate( longDateToInput( data.empleadoFecnac)),
        //empleadoCurp  : data.empleadoCurp,
        empleadoRfc  : data.empleadoRfc,
        //empleadoNss  : data.empleadoNss,
        cpresupuestalClave  : data.cpresupuestalClave,
        cpresupuestalNom : data.cpresupuestalNom,
        ciaRazonSocial: data.ciaRazonSocial,
        nominaId  : data.nominaId,
        nomindClave  : data.nomindClave,
        tiponatuId  : data.tiponatuId,
        tiponatuNom: data.tiponatuNom,
        nomindNomIndicador : data.nomindNomIndicador,
        //cantidad  : parseFloat(data.cantidad).toFixed(2),
        cantidad: data.cantidad,
        monto : floatToAmount(data.monto, 2),
        periodoId : data.periodoId,
        //acumuladoClave : data.acumuladoClave,
        acumuladoNombre : data.acumuladoNombre,

        periodoAnioInfo : data.periodoAnioInfo,
        periodoPeranioInfo : data.periodoPeranioInfo,
    };
};

export const ArrayJJsonNewReport = data => {
    const newJson = data.map( item => JsonNewReport( item ) );
    return newJson;
};

export const JsonNewReport = data => {
    
    return {
        reportesId: data.id,
        reportesReporteDescrip: data.reporteDescrip, 
        reportesReporteJson: data.reporteJson,
        reportesReporteNom: data.reporteNom, 
        reportesCreated: data.created,
        usuarioDatosUsuarioMat: data.usuarioDatosUsuarioMat,
        usuarioDatosUsuarioNom: data.usuarioDatosUsuarioNom,
        usuarioDatosUsuarioPat: data.usuarioDatosUsuarioPat,
        usuarioId: data.usuarioId,
        usuarioUsername: data.usuarioUsername,
        usuarioStatus: data.usuarioStatus,
    };
};

export const ArrayJsonSubordinatesUsers= data => {
    const newJson = data.map( item => JsonSubordinatesUsers( item ) );
    return newJson;
};

export const JsonSubordinatesUsers = data => {
    
    return {
        id: data.id,
        datosUsuarioNom: data.datosUsuarioNom, 
        datosUsuarioPat: data.datosUsuarioPat,
        datosUsuarioMat: data.datosUsuarioMat, 
        status: data.status,
        username: data.username,
        img: data.foto
    };
};

export const ArrayJsonAuditorias= (data) => {
    const newJson = data.map( item => JsonAuditorias( item ) );
    return newJson;
};

export const JsonAuditorias = (data) => {
    return {
        id: data.id,
        usuario: data.mail,
        nombre: data.Nombre,
        apaterno: data.Paterno, 
        amaterno: data.Materno,
        moduleName: data.moduleName,
        crudOperation: data.crudOperation ==="INSERT" ? 'Agregar': data.crudOperation ==="UPDATE" ? 'Actualizar': 'Eliminar', 
        date : ConvertDateTime(data.date, true),
        fecha: data.date,
        datos: data.datos,
    };
};

export const ArrayJsonPlantillas = data => {
    const newJson = data.map(item => JsonPlantillas(item));
    return newJson;
};

export const JsonPlantillas = data => {
    return {
        id: data.id,
        plantillaClave: data.plantillaClave,
        plantillaNom: data.plantillaNom,
        plantillaDesc: data.plantillaDesc, 
        plantillaHtml: data.plantillaHtml,
        margenSup: data.margenSup,
        margenDer: data.margenDer,
        margenInf: data.margenInf,
        margenIzq: data.margenIzq
    };
};

export const ArrayJsonMovimientosEmpleado = data => {
    const newJson = data.map(item => JsonMovimientosEmpleado(item));
    return newJson;
};

export const JsonMovimientosEmpleado = data => {
    return {
        id: data.id,
        acumulado: data.acumulado,
        cantidad: data.cantidad,
        empleado: data.empleado, 
        fechaAplicacion: data.fechaAplicacion,
        monto: floatToAmount(data.monto, 2),
        nomina: data.nomina,
        fechaAplicacionTab: data.fechaAplicacion ? ConvertDate( longDateToInput( data.fechaAplicacion ) ) : '-',
    };
};

export const ArrayJsonMovimientosFijosEmpleado = (data, pensiones) => {
    const newJson = data.map(item => JsonMovimientosFijosEmpleado(item, pensiones));
    return newJson;
};

export const JsonMovimientosFijosEmpleado = (data, pensiones) => {
    const pensionElement = pensiones && pensiones.length > 0 ? pensiones.find(element => element.id === data.empensionId) : null;
    return {
        id: data.id, 
        previo: data.previo,
        empleado: data.empleado,
        nomina: data.nomina,
        cantidad: data.cantidad, 
        monto: floatToAmount(data.monto, 2),
        montoInt: data.monto,
        acumulado: data.acumulado, 
        fechaInicio: data.fechaInicio,
        fechaFinal: data.fechaFinal,
        empensionId: data.empensionId,
        empdepecoId: data.empdepecoId,
        fechaInicioTab: data.fechaInicio ? ConvertDate( longDateToInput( data.fechaInicio ) ) : '-',
        fechaFinalTab: data.fechaFinal ? ConvertDate( longDateToInput( data.fechaFinal ) ) : '-',
        pension: pensionElement,
        informacionConcepto: pensionElement ? `${data.nomina.nomIndicador} / ${pensionElement.informacion}` : data.nomina.nomIndicador,
    };
};

export const ArrayJsonMovimientosPendientesEmpleado = data => {
    const newJson = data.map(item => JsonMovimientosPendientesEmpleado(item));
    return newJson;
};

export const JsonMovimientosPendientesEmpleado = data => {
    return {
        id: data.id,
        empleado: data.empleado,
        nomina: data.nomina,
        acumulado: data.acumulado,
        cantidad: data.cantidad,
        monto: floatToAmount(data.monto, 2),
    };
};

/* Movimientos Relacionados */
export const ArrayJsonMovimientosRelacionadosEmpleado = (data, pensiones) => {
    const newJson = data.map(item => JsonMovimientosRelacionadosEmpleado(item, pensiones));
    return newJson;
};

export const JsonMovimientosRelacionadosEmpleado = (data, pensiones) => {
    const pensionElement = pensiones && pensiones.length > 0 ? pensiones.find(element => element.id === data.empensionId) : null;
    return {
        id: data.id, 
        empleado: data.empleado,
        nomina: data.nomina,
        acumulado: data.acumulado, 
        fechaInicio: data.fechaInicio,
        fechaFinal: data.fechaFinal, 
        empensionId: data.empensionId,
        fechaInicioTab: data.fechaInicio ? ConvertDate( longDateToInput( data.fechaInicio ) ) : '-',
        fechaFinalTab: data.fechaFinal ? ConvertDate( longDateToInput( data.fechaFinal ) ) : '-',
        pension: pensionElement,
        informacionConcepto: pensionElement ? `${data.nomina.nomIndicador} / ${pensionElement.informacion} / ${pensionElement.montoPorc}` : data.nomina.nomIndicador,
    };
};

const returnTipocuota = ( tipoCuota, montoPorc ) => {
    switch( tipoCuota ){
        case "I": return `IMPORTE ${floatToAmount(montoPorc, 2)}`;
        case "P": return `PORCENTAJE ${parseFloat(montoPorc)}%`;
        case "S": return `SALARIO MÍNIMO (${parseFloat(montoPorc)})`;
        case "U": return `UMA (${parseFloat(montoPorc)})`;
        default: return tipoCuota;
    }
}

const returnMontoPorc = ( tipoCuota, montoPorc ) => {
    switch( tipoCuota ){
        case "I": return floatToAmount(montoPorc, 2);
        case "P": return `${parseFloat(montoPorc)}%`;
        case "S": return parseFloat(montoPorc);
        case "U": return parseFloat(montoPorc);
        default: return montoPorc;
    }
}

/* Pensiones - Movimientos relacionados */
export const ArrayJsonPensionesRelacionado = data => {
    const newJson = data.map(item => JsonPensionesRelacionado(item));
    return newJson;
};

export const JsonPensionesRelacionado = data => {
    return {
        id: data.id,
        folio: data.folio,
        nombre: data.nombre,
        apellidoMat: data.apellidoMat,
        apellidoPat: data.apellidoPat,
        fechaIni: data.fechaIni,
        fechaFin: data.fechaFin,
        fechaIniTab: data.fechaIni ? ConvertDate( longDateToInput( data.fechaIni ) ) : '-',
        fechaFinTab: data.fechaFin ? ConvertDate( longDateToInput( data.fechaFin ) ) : '-',
        tipoCuota: data.tipoCuota,
        montoPorc: returnMontoPorc(data.tipoCuota, data.montoPorc),
        informacion: `${data.folio} / ${data.nombre} ${data.apellidoPat ? data.apellidoPat : ''} ${data.apellidoMat ? data.apellidoMat : ''} 
            / ${returnTipocuota(data.tipoCuota, data.montoPorc)}`,
    };
};

/* Dependientes - Movimientos relacionados */
export const ArrayJsonDependientesRelacionado = data => {
    const newJson = data.map(item => JsonDependientesRelacionado(item));
    return newJson;
};

export const JsonDependientesRelacionado = data => {
    return {
        id: data.id,
        nombre: data.nombre,
        apellidoMat: data.apellidoMat,
        apellidoPat: data.apellidoPat,
        curp: data.curp,
        fechaNac: data.fechaNac,
        fechaNacTab: data.fechaNac ? ConvertDate( longDateToInput( data.fechaNac ) ) : '-',
        parentescoNom: data.parentesco.parentescoNom,
        escolaridadNom: data.escolaridad.escolaridadNom,
        gradoEscolar: data.gradoEscolar,
        nombreDisc: data.nombreDisc,
        informacion: `${data.nombre} ${data.apellidoPat ? data.apellidoPat : ''} ${data.apellidoMat ? data.apellidoMat : ''}`,
    };
};

/* Indicadores - Movimientos Relacionados */
export const ArrayJsonIndicadorRelacionadoFormulario = data => {
    const newJson = data.map(item => JsonIndicadorRelacionadoFormulario(item));
    return newJson;
};

export const JsonIndicadorRelacionadoFormulario = data => {
    return {
        id: data.id,
        indicador: data.indicador,
        nomClave: data.nomClave,
        nomIndicador: data.nomIndicador,
        nomRecibo: data.nomRecibo,
        nominaid: data.nominaid,
        prioridad: data.prioridad,
        rutina: data.rutina,
        informacion: `${data.nomClave} - ${data.nomIndicador}`,
    };
};

export const ArrayJsonIndicadorRelacionado = data => {
    const newJson = data.map(item => JsonIndicadorRelacionado(item));
    return newJson;
};

export const JsonIndicadorRelacionado = data => {
    return {
        id: data.id,
        movrelacionado: data.movrelacionado,
        nominaIndicador: data.nominaIndicador,
        porcentaje: data.porcentaje.toFixed(2),
        informacion: `${data.nominaIndicador.nomClave} - ${data.nominaIndicador.nomIndicador}`,
    };
};

export const ArrayJsonConsultaNomina = data => { 
    const newJson = data.map( item =>  JsonConsultaNomina( item ) );
    return newJson;
};

export const JsonConsultaNomina = (data, index) => {
    return { 
        filaId : data.filaId,
        nominaId : data.nominaId,
        periodoId : data.periodoId,
        fecIni : data.fecIni,
        fecFin :  data.fecFin,
        acumuladoId : data.acumuladoId,
        acumuladoClave : data.acumuladoClave,
        acumuladoNombre :  data.acumuladoNombre ,
        indicadorId : data.indicadorId,
        nomindClave : data.nomindClave,
        nomindIndicador :  data.nomindIndicador ,
        round : `${data.tipoNatuId === 2 ? '-':''} ${floatToAmount(data.round, 2)}`,
        tipoNatuId : data.tipoNatuId,
        tipoNatuNom :  data.tipoNatuNom 
    };
};


/* ACUMULADOS */
export const ArrayJsonConceptos = data => { 
    const newJson = data.map(item => JsonConceptos(item));
    return newJson;
};

const JsonConceptos = data => {
    return { 
        id: data.id,
        nomClave: data.nomClave,
        nomIndicador: data.nomIndicador,
        nomRecibo: data.nomRecibo,
        nominaid: data.nominaid,
        claveNombreIndicador: `${data.nomClave} - ${data.nomIndicador}`
    };
};

export const ArrayJsonConceptosEmp = data => { 
    const newJson = data.map(item => JsonConceptosEmp(item));
    return newJson;
};

export const JsonConceptosEmp = data => {
    return { 
        indicadorSat: data.indicadorSat,
        nominaClave: data.nominaClave,
        nominaIndicador: `${data.nominaIndicador}${(data.cantidad > 1) ? ` (${data.cantidad})` : (data.numPago > 0) ? ` (${data.numPago}/${data.empprestNumpagos})` : ''}`,
        cantidad: data.cantidad,
        monto: floatToAmount(data.monto, 2),
        id: data.acumId,
        status: data.status,
    };
};

export const JsonConceptosEmp2 = data => {
    return { 
        indicadorSat: data.nominaIndicador.indicador.indicadorSat,
        nominaClave: data.nominaIndicador.nomClave,
        nominaIndicador: data.nominaIndicador.nomIndicador,
        cantidad: data.cantidad,
        monto: floatToAmount(data.monto, 2),
        id: data.id,
        status: 'CERRADO',
    };
};

/* TERMINA ACUMULADOS */
export const ArrayJsonNominas = data => { 
    const newJson = data.map( item =>  JsonNominas( item ) );
    return newJson;
};

export const JsonNominas = data => {
    return { 
        id: data.id,
        relacionLab: data.relacionLab, 
        periodicidad: data.periodicidad,
        nominaClave: data.nominaClave,
        nominaNomCorto: data.nominaNomCorto,
        nominaNombre: data.nominaNombre,
        nominaDescrip: data.nominaDescrip,
        status: data.status,
        paramNom: data.paramNom,
        paramImpto: data.paramImpto,
        origenRecurso: data.origenRecurso,
        regimenFiscal: data.regimenFiscal,
        tipoRegimen:  data.tipoRegimen,
        nominaClaveNombre: `${data.nominaClave} (${data.nominaNombre})`
    };
};

export const arrayJsonNominasAll = data => {
    let nominas = [];
    data.map(d => nominas = nominas.concat(d.nominas[0]));
    const newJson = nominas.map(item => JsonNominasAll(item)).sort((a, b) => a.nominaClave < b.nominaClave ? -1 : a.nominaClave > b.nominaClave ? 1 : 0);
    return newJson;
};

export const JsonNominasAll = data => {
    return { 
        id: data.id,
        nominaClave: data.nominaClave,
        nominaNombre: data.nominaNombre,
        nominaClaveNombre: `${data.nominaClave} (${data.nominaNombre})`
    };
};

export const ArrayJsonNominasRL = data => {
    const newJson = data.map( item =>  JsonNominasRL( item ) );
    return newJson;
};

export const JsonNominasRL = data => {
    var periodoInfo = null;
    if( data.periodicidadCalculo === 2 ){ //Verificamos si el periodo es número par
        periodoInfo = (data.periodoPerAnio % 2 === 0) ? `${data.periodoPerAnio-1}/${data.periodoPerAnio}` : `${data.periodoPerAnio}/${data.periodoPerAnio+1}`;
    }
    return {
        id: data.id,
        nombreNomina: data.nombreNomina,
        nomina: data.nomina,
        periodicidadCalculo: data.periodicidadCalculo,
        periodoAnio: data.periodoAnio,
        periodoDiasImss: data.periodoDiasImss,
        periodoDiasNom: data.periodoDiasNom,
        periodoFeccorte: data.periodoFeccorte,
        periodoFecfin: data.periodoFecfin,
        periodoFecini: data.periodoFecini,
        periodoMes: data.periodoMes,
        periodoPerAnio: data.periodoPerAnio,
        periodoPerMes:  data.periodoPerMes,
        periodoInfo: periodoInfo,
    };
};

/* Inicio Históricos */
export const sortArrayByIdDesc = array => {
    return array.sort((a, b)=> a.id < b.id ? 1 : a.id > b.id ? -1 : 0 );
};

export const ArrayJsonHistoricoAntiguedad = data => {
    const newArray = data.map(item => (
        jsonHistoricoAntiguedad(item)
    ));

    return sortArrayByIdDesc(newArray);
};

export const jsonHistoricoAntiguedad = item => {
    const respuesta = { 
        id: item.id,
        empantfecini: (item.empantfecini) ? ConvertDate(item.empantfecini) : '',
        empantfeciniLong: item.empantfecini,
        empantfecfin: (item.empantfecfin) ? ConvertDate(item.empantfecfin) : '',
        empantfecfinLong: item.empantfecfin,
    };

    return respuesta;
};

export const ArrayJsonHistoricoContrato = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.fechaini),
            fechaFinal: (item.fechafin) ? ConvertDate(item.fechafin) : '',
            tipoContrato: `${item.contrato.tipoContrato.tipoContratoClave} / ${item.contrato.tipoContrato.tipoContratoDesc}`,
            contratoNombre: item.contrato.contratoSaoNom,
            comentario: item.comentario,
            relacionLaboral: `${item.contrato.relacionLaboral.clave} / ${item.contrato.relacionLaboral.nombre}`
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoNombramiento = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.empFecAgui),
            fechaFinal: (item.empNomFecFin) ? ConvertDate(item.empNomFecFin) : '',
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoNomina = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.empnominafecini),
            fechaFinal: (item.empnominafecfin) ? ConvertDate(item.empnominafecfin) : '',
            nominaClave: item.nomina.nominaClave,
            nominaNombre: item.nomina.nominaNombre
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoPlaza = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.fecini),
            fechaFinal: (item.fecfin) ? ConvertDate(item.fecfin) : '',
            plazaClave: item.plazaid.plazaclave,
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoPresupuestal = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.empcpresfecini),
            fechaFinal: (item.empcpresfecfin) ? ConvertDate(item.empcpresfecfin) : '',
            presupuestalClave: item.presupuestal.cpresupuestalclave,
            presupuestalNombre: item.presupuestal.cpresupuestalnom
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoPuesto = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.fecini),
            fechaFinal: (item.fecfin) ? ConvertDate(item.fecfin) : '',
            puestoClave: item.puesto.puestoCve,
            puestoNombre: item.puesto.puestoNom,
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoStatus = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.fecini),
            fechaFinal: (item.fecfin) ? ConvertDate(item.fecfin) : '',
            bajaClave: (item.mbaja) ? item.mbaja.clave : '',
            bajaNombre: (item.mbaja) ? (item.fechaReanudacion) ? `${item.mbaja.nombre} (${ConvertDate(item.fecfin+86400000)} - ${ConvertDate(item.fechaReanudacion)})`: item.mbaja.nombre : '',
            mbajaDescrp: (item.mbajaDescrp) ? item.mbajaDescrp : ''
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoSueldo = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.fecini),
            fechaFinal: (item.fecfin) ? ConvertDate(item.fecfin) : '',
            monto: floatToAmount(item.monto, 2)
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoTurno = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            fechaInicial: ConvertDate(item.fecini),
            fechaFinal: (item.fecfin) ? ConvertDate(item.fecfin) : '',
            turnoNombre: item.turno.turnoNom,
            jornadaNombre: item.turno.jornada.nombre,
            jornadaClave: item.turno.jornada.clave
        }
    ));

    return sortArrayByIdDesc(newArray);
};

export const ArrayJsonHistoricoZonaPago = data => {
    const newArray = data.map(item => (
        { 
            id: item.id,
            tipoDomicilio: item.domcia.tipoDom.id,
            calle: item.domcia.domCiaCalle,
            numeroExterior: (item.domcia.domCiaNumExt) ? item.domcia.domCiaNumExt : '',
            numeroInterior: (item.domcia.domCiaNumInt) ? item.domcia.domCiaNumInt : '',
            cp: item.domcia.codPostal.codigopId,
            colonia: item.domcia.codPostal.codigopId,
            municipio: item.domcia.codPostal.codigopId,
            pais: item.domcia.codPostal.codigopId,
            telefono: item.domcia.domCiaTel,
            fechaInicial: ConvertDate(item.fecini),
            fechaFinal: (item.fecfin) ? ConvertDate(item.fecfin) : ''
        }
    ));

    return sortArrayByIdDesc(newArray);
};
/* Fin Históricos */
export const ArrayJsonPresupuestalEspeciales = data => { 
    const newJson = data.map( item =>  JsonPresupuestalEspeciales( item ) );
    return newJson;
};

export const JsonPresupuestalEspeciales = data => {
    return { 
        id: data.id,
        fechaIni: ConvertDate( longDateToInput( data.empcpresfecini ) ),
        fechaFin: (data.empcpresfecfin) ? ConvertDate( longDateToInput( data.empcpresfecfin ) ) : '',
        informacion: { presupuestal: data.presupuestal },
        fechaIniLong: data.empcpresfecini,
        fechaFinLong: data.empcpresfecfin,
        tipo: 'Clave Presupuestal / Proyecto'
    };
};

export const ArrayJsonPlazaEspeciales = data => { 
    const newJson = data.map( item =>  JsonPlazaEspeciales( item ) );
    return newJson;
};

export const JsonPlazaEspeciales = data => {
    return { 
        id: data.id,
        fechaIni: ConvertDate( longDateToInput( data.fecini ) ),
        fechaFin: (data.fecfin) ? ConvertDate( longDateToInput( data.fecfin ) ) : '',
        informacion: { plazaid: data.plazaid },
        fechaIniLong: data.fecini,
        fechaFinLong: data.fecfin,
        tipo: 'Plaza'
    };
};

export const ArrayJsonPuestoEspeciales = data => { 
    const newJson = data.map( item =>  JsonPuestoEspeciales( item ) );
    return newJson;
};

export const JsonPuestoEspeciales = data => {
    return { 
        id: data.id,
        fechaIni: ConvertDate( longDateToInput( data.fecini ) ),
        fechaFin: (data.fecfin) ? ConvertDate( longDateToInput( data.fecfin ) ) : '',
        informacion: { puesto: data.puesto },
        fechaIniLong: data.fecini,
        fechaFinLong: data.fecfin,
        tipo: 'Puesto'
    };
};

export const ArrayJsonNominaEspeciales = data => { 
    const newJson = data.map( item =>  JsonNominaEspeciales( item ) );
    return newJson;
};

export const JsonNominaEspeciales = data => {
    return { 
        id: data.id,
        fechaIni: ConvertDate( longDateToInput( data.empnominafecini ) ),
        fechaFin: (data.empnominafecfin) ? ConvertDate( longDateToInput( data.empnominafecfin ) ) : '',
        informacion: { nomina: data.nomina },
        fechaIniLong: data.empnominafecini,
        fechaFinLong: data.empnominafecfin,
        tipo: 'Nómina'
    };
};

export const ArrayJsonZonaPagoEspeciales = data => { 
    const newJson = data.map( item =>  JsonZonaPagoEspeciales( item ) );
    return newJson;
};

export const JsonZonaPagoEspeciales = data => {
    return { 
        id: data.id,
        fechaIni: ConvertDate( longDateToInput( data.fecini ) ),
        fechaFin: (data.fecfin) ? ConvertDate( longDateToInput( data.fecfin ) ) : '',
        informacion:{ domcia:   {
                                    tipoDom: data.domcia.tipoDom.id,
                                    domCiaCalle:data.domcia.domCiaCalle, 
                                    domCiaNumInt: data.domcia.domCiaNumInt, 
                                    domCiaNumExt: data.domcia.domCiaNumExt, 
                                    domCiaTel: data.domcia.domCiaTel, 
                                    domCiaExt: data.domcia.domCiaExt, 
                                    compania: data.domcia.compania.id,
                                    codPostal: data.domcia.codPostal.codigopId
                                } 
                    },
        fechaIniLong: data.fecini,
        fechaFinLong: data.fecfin,
        tipo: 'Zona de Pago'
    };
};

export const ArrayJsonTurnosEspeciales = data => { 
    const newJson = data.map( item =>  JsonTurnosEspeciales( item ) );
    return newJson;
};

export const JsonTurnosEspeciales = data => {
    return { 
        id: data.id,
        fechaIni: ConvertDate( longDateToInput( data.fecini ) ),
        fechaFin: (data.fecfin) ? ConvertDate( longDateToInput( data.fecfin ) ) : '',
        informacion:{ turno:   {
                                    jornadaNombre: data.turno.jornada.nombre,
                                    jornadaClave: data.turno.jornada.clave,
                                    turnoNombre: data.turno.turnoNom,
                                } 
                    },
        fechaIniLong: data.fecini,
        fechaFinLong: data.fecfin,
        tipo: 'Turno'
    };
};

export const ArrayJsonStatusEspeciales = data => { 
    const newJson = data.map( item =>  JsonStatusEspeciales( item ) );
    return newJson;
};

export const JsonStatusEspeciales = data => {
    let statusEspeciales = { 
        id: data.id,
        fechaIni: ConvertDate( longDateToInput( data.fecini ) ),
        fechaFin: (data.fecfin) ? ConvertDate( longDateToInput( data.fecfin ) ) : '',
        informacion: (data.mbaja)
        ?   (data.fechaReanudacion)
            ?   {
                    baja:   {
                        claveBaja: data.mbaja.clave,
                        nombreBaja: data.mbaja.nombre,
                        fechaReanudacion: (data.fechaReanudacion) ? ConvertDate(longDateToInput(data.fechaReanudacion)) : null,
                        descripcion: (data.mbajaDescrp) ? data.mbajaDescrp  : '-' ,
                    }
                }
            :   {
                    baja:   {
                        claveBaja: data.mbaja.clave,
                        nombreBaja: data.mbaja.nombre,
                        descripcion: (data.mbajaDescrp) ? data.mbajaDescrp : '-',
                    }
                }
        :   'ACTIVO',
        fechaIniLong: data.fecini,
        fechaFinLong: data.fecfin,
        tipo: 'Status',
    }

    return statusEspeciales;
};

export const ArrayJsonContratosEspeciales = data => {
    const newJson = data.map( item =>  JsonContratosEspeciales( item ) );
    return newJson;
};

export const JsonContratosEspeciales = data => {
    const contratosEspeciales = { 
        id: data.id,
        fechaIni: ConvertDate(longDateToInput(data.fechaini)),
        fechaFin: (data.fechafin) ? ConvertDate( longDateToInput( data.fechafin ) ) : '',
        informacion: {
            contrato: {
                contratoSaoNom: data.contrato.contratoSaoNom
            }
        },
        fechaIniLong: data.fechaini,
        fechaFinLong: data.fechafin,
        tipo: 'Contrato'
    };

    return contratosEspeciales;
};

export const ArrayJsonNombramientosEspeciales = data => {
    const newJson = data.map( item => JsonNombramientosEspeciales( item ) );
    return newJson;
};

export const JsonNombramientosEspeciales = data => {
    const nombramientosEspeciales = { 
        id: data.id,
        fechaIni: ConvertDate(longDateToInput(data.empFecAgui)),
        fechaFin: (data.empNomFecFin) ? ConvertDate(longDateToInput(data.empNomFecFin)) : '',
        informacion: null,
        fechaIniLong: data.empFecAgui,
        fechaFinLong: data.empNomFecFin,
        tipo: 'Nombramiento'
    };

    return nombramientosEspeciales;
};

// SESIONES -------------------------------
export const ArrayJsonSesiones = data => { 
    const newJson = data.map(item => JsonSesiones(item));
    return newJson;
};

export const JsonSesiones = data => {
    return { 
        id: data.id,
        username: data.username,
        nombre: data.nombre,
        paterno: data.paterno,
        materno: data.materno,
        key: data.key,
        ultimoAcceso: ConvertDateTime(data.ultimoAcceso, true)
    };
};
// FIN SESIONES ---------------------------

// INICIO PRORROGA CONTRATOS --------------
export const ArrayJsonProrrogaContratosEmpleados = data => { 
    const newJson = data.map(item =>  JsonProrrogaContratosEmpleados(item));
    return newJson;
};

export const JsonProrrogaContratosEmpleados = item => {
    return {
        id: item.id,
        empleadoClave: item.empleadoClave,
        nombreCompleto: `${item.empleadoPat ? item.empleadoPat :''} ${item.empleadoMat ? item.empleadoMat:''} ${item.empleadoNom}`,
        plazaClave: item.plazaClave,
        puestoCve: item.puestoCve,
        puestoNom: item.puestoNom,
        nivlaboralClave: item.nivlaboralClave,
        cpresupuestalNom: item.cpresupuestalNom,
        cpresupuestalClave: item.cpresupuestalClave,
        nominaId: item.nominaId,
        nominaClave: item.nominaClave,
        nominaNomcorto: item.nominaNomcorto,
        contratosaoNom: item.contratosaoNom,
        empcontratosaoFechaini: item.empcontratosaoFechaini,
        empcontratosaoFechafin: item.empcontratosaoFechafin,
        relabId: item.relabId,
        fechaVigencia: `${ConvertDate( longDateToInput( item.empcontratosaoFechaini ) )} - ${ConvertDate( longDateToInput( item.empcontratosaoFechafin ) )}`,
        newDate: '',
        comments: '',
        contratosaoId: item.contratosaoId,
        empleadoId: item.empleadoId,
        empleadoNom: item.empleadoNom,
        empleadoPat: item.empleadoPat,
        empleadoMat: item.empleadoMat,
        empleadoRfc: item.empleadoRfc,
        empcontratosaoFechainiFormat: ConvertDate( longDateToInput( item.empcontratosaoFechaini ) ),
        empcontratosaoFechafinFormat: ConvertDate( longDateToInput( item.empcontratosaoFechafin ) ),
        folio: (item.empcontratosao_comentario) ? item.empcontratosao_comentario : ''
    }
};
// FIN PRORROGA CONTRATOS --------------

/***Estadísticas  */
export const arrayJsonMovimientosPorPeriodo = (data, tipo) => { 
    const newJson = data.map(item => jsonMovimientosPorPeriodo(item, tipo));
    return newJson;
};

const jsonMovimientosPorPeriodo = (item, tipo) => {
    let respuesta = {
        id: item.empledoId,
        empleadoClave: item.empleadoClave,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoFecNac: ConvertDate(item.empleadoFecnac),
        empleadoCurpRfc: (item.empleadoCurp || item.empleadoRfc)
                        ? `${(item.empleadoCurp) ? item.empleadoCurp : '-'}\n/ ${(item.empleadoRfc) ? item.empleadoRfc : '-'}`
                        : '',
        empleadoNss: item.empleadoNss,
        empleadoGenero: item.empleadoGenero,
        empsueldoMonto: floatToAmount(item.empsueldoMonto, 2),
        empantFecini: ConvertDate(item.empantFecini),
        puesto: `${(item.puestoCve) ? item.puestoCve : '-'}\n/ ${(item.puestoNom) ? item.puestoNom : '-'}`,
        relabClave: item.relabClave,
        realab_nom: item.realab_nom,
        domciaCalle: item.domciaCalle,
        domciaNumExt: item.domciaNumExt,
        domciaNumInt: item.domciaNumInt,
        domciaTel: item.domciaTel,
        domciaExt: item.domciaExt,
        tipodomNom: item.tipodomNom,
        cpresupuestal: (item.cpresupuestalClave || item.cpresupuestalNom)
                        ? `${(item.cpresupuestalClave) ? item.cpresupuestalClave : '-'}\n/ ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        : '',
        proyectoClave: item.proyectoClave,
        proyectoNom: item.proyectoNom,
        programaClave: item.programaClave,
        programaNom: item.programaNom,
        udejecutoraClave: item.udejecutoraClave,
        udejecutoraNom: item.udejecutoraNom,
        ciaUr: item.ciaUr,
        ciaRazonsocial: item.ciaRazonsocial,
        zonaPago: item.zonaPago,
        empturFecIni: ConvertDate(item.empturFecIni),
        //empturFecFin: ConvertDate(item.empturFecFin),
        turnoNom: item.turnoNom,
        tipojornadaClave: item.tipojornadaClave,
        tipojornadaNom: item.tipojornadaNom,
        nomina: (item.nominaClave || item.nominaNombre) ? `${(item.nominaClave) ? item.nominaClave : '-'}\n/ ${(item.nominaNombre) ? item.nominaNombre : '-'}` : '',
        //nominaNomCorto: item.nominaNomCorto,
        plazaClave: item.plazaClave,
        areaNom: item.areaNom,
        empStatusFecIni: ConvertDate(item.empstatusFecIni),
        nivlaboralClave: item.nivlaboralClave,
        status: item.status,
    };

    if(tipo === 2) {
        respuesta.usuario = item.usuarioEmail;
        respuesta.nombre = `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`;
        respuesta.fechaMovimiento = (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '';
    }
    
    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoBajas = (data, tipo) => { 
    const newJson = data.map(item => jsonMovimientosPorPeriodoBajas(item, tipo));
    return newJson;
};

const jsonMovimientosPorPeriodoBajas = (item, tipo) => {
    let respuesta = {
        id: item.empleadoId,
        empleadoClave: item.empleadoClave,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoFecNac: ConvertDate(item.empleadoFecnac),
        empleadoCurpRfc: (item.empleadoCurp || item.empleadoRfc)
                        ? `${(item.empleadoCurp) ? item.empleadoCurp : '-'}\n/ ${(item.empleadoRfc) ? item.empleadoRfc : '-'}`
                        : '',
        empleadoNss: item.empleadoNss,
        empleadoGenero: item.empleadoGenero,
        empsueldoMonto: floatToAmount(item.empsueldoMonto, 2),
        empantFecini: ConvertDate(item.empantFecini),
        puesto: (item.puestoCve || item.puestoNom) ? `${(item.puestoCve) ? item.puestoCve : '-'}\n/ ${(item.puestoNom) ? item.puestoNom : '-'}` : '',
        relabClave: item.relabClave,
        realab_nom: item.realab_nom,
        domciaCalle: item.domciaCalle,
        domciaNumExt: item.domciaNumExt,
        domciaNumInt: item.domciaNumInt,
        domciaTel: item.domciaTel,
        domciaExt: item.domciaExt,
        tipodomNom: item.tipodomNom,
        cpresupuestal: (item.cpresupuestalClave || item.cpresupuestalNom)
                        ? `${(item.cpresupuestalClave) ? item.cpresupuestalClave : '-'}\n/ ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        : '',
        proyectoClave: item.proyectoClave,
        proyectoNom: item.proyectoNom,
        programaClave: item.programaClave,
        programaNom: item.programaNom,
        udejecutoraClave: item.udejecutoraClave,
        udejecutoraNom: item.udejecutoraNom,
        ciaUr: item.ciaUr,
        ciaRazonsocial: item.ciaRazonsocial,
        zonaPago: item.zonaPago,
        empturFecIni: ConvertDate(item.empturFecIni),
        //empturFecFin: ConvertDate(item.empturFecFin),
        turnoNom: item.turnoNom,
        tipojornadaClave: item.tipojornadaClave,
        tipojornadaNom: item.tipojornadaNom,
        nomina: (item.nominaClave || item.nominaNombre) ? `${(item.nominaClave) ? item.nominaClave : '-'}\n/${(item.nominaNombre) ? item.nominaNombre : '-'}` : '',
        //nominaNomCorto: item.nominaNomCorto,
        plazaClave: item.plazaClave,
        areaNom: item.areaNom,
        empStatusFecIni: ConvertDate(item.empstatusFecIni),
        nivlaboralClave: item.nivlaboralClave,
        status: item.status,
        fechaBaja: ConvertDate(item.fechaBaja),
        motivoBaja: item.motivoBaja,
    }

    if(tipo === 2) {
        respuesta.usuario = item.usuarioEmail;
        respuesta.nombre = `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`;
        respuesta.fechaMovimiento = (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '';
    }
    
    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoAdscripcion = (data, tipo) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoAdscripcion(item, tipo));
    return newJson;
};

const jsonMovimientosPorPeriodoAdscripcion = (item, tipo) => {
    const plazaClaveActual = (tipo === 2) ? item.plazaCveNuevo : item.plazaClaveNuevo;
    const plazaClaveAnterior = (tipo === 2) ? item.plazaCveAnt : item.plazaClaveAnt;

    let respuesta = {
        empleadoClave: item.empleadoClave,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoRfc: item.empleadoRfc,
        puestoClave: item.puestoCve,
        cpresupuestal: (item.cpresupuestalClave || item.cpresupuestalNom)
                        ?   `${(item.cpresupuestalClave) ? item.cpresupuestalClave : '-'}\n/ ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        :   '',
        plazaClaveActAnt: (plazaClaveActual === plazaClaveAnterior)
                            ?   (plazaClaveAnterior)
                                ?   plazaClaveAnterior
                                :   ''
                            :   `${(plazaClaveActual) ? plazaClaveActual : '-'}\n/ ${(plazaClaveAnterior) ? plazaClaveAnterior : '-'}`,
    };

    if(tipo === 2) {
        respuesta.cpresupuestalAnt =  (item.cpresupuestalClaveAnt || item.cpresupuestalNomAnt)
                            ? `${(item.cpresupuestalClaveAnt) ? item.cpresupuestalClaveAnt : '-'}\n/ ${(item.cpresupuestalNomAnt) ? item.cpresupuestalNomAnt : '-'}`
                            : ''
        respuesta.usuario = item.usuarioEmail;
        respuesta.nombre = `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`;
        respuesta.fechaMovimiento = (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '';
    }
    
    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoProyecto = (data, tipo) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoProyecto(item, tipo));
    return newJson;
};

const jsonMovimientosPorPeriodoProyecto = (item, tipo) => {
    let respuesta = {
        empleadoClave: item.empleadoClave,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoRfc: item.empleadoRfc,
        plazaClave: item.plazaClave,
        puestoClave: item.puestoCve,
        cpresupuestalNuevo: (item.cpresupuestalClaveNuevo || item.cpresupuestalNomNuevo) ? `${(item.cpresupuestalClaveNuevo) ? item.cpresupuestalClaveNuevo : '-'}\n/ ${(item.cpresupuestalNomNuevo) ? item.cpresupuestalNomNuevo : '-'}` : '',
        cpresupuestalAnt: (item.cpresupuestalClaveAnt || item.cpresupuestalNomAnt) ? `${(item.cpresupuestalClaveAnt) ? item.cpresupuestalClaveAnt : '-'}\n/ ${(item.cpresupuestalNomAnt) ? item.cpresupuestalNomAnt : '-'}` : '',
    };

    if(tipo === 2) {
        respuesta.usuario = item.usuarioEmail;
        respuesta.nombre = `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`;
        respuesta.fechaMovimiento = (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '';
    }

    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoCategoria = (data, tipo) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoCategoria(item, tipo));
    return newJson;
};

const jsonMovimientosPorPeriodoCategoria = (item, tipo) => {
    let respuesta = {
        empleadoClave: item.empleadoClave,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoRfc: item.empleadoRfc,
        plazaClave: item.plazaClave,
        cpresupuestal: (item.cpresupuestalClave || item.cpresupuestalNom)
                        ? `${(item.cpresupuestalClave) ? item.cpresupuestalClave : '-'} / ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        : '',
        puestoClaveAnt: item.puestoClaveAnt,
        puestoClaveNuevo: item.puestoClaveNuevo,
    };

    if(tipo === 2) {
        respuesta.usuario = item.usuarioEmail;
        respuesta.nombre = `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`;
        respuesta.fechaMovimiento = (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '';
    }

    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoReingresos = (data, tipo) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoReingresos(item, tipo));
    return newJson;
};

const jsonMovimientosPorPeriodoReingresos = (item, tipo) => {
    let respuesta = {
        id: item.empleadoId,
        cpresupuestal: (item.cpresupuestalCve || item.cpresupuestalNom)
                        ? `${(item.cpresupuestalCve) ? item.cpresupuestalCve : '-'}\n/ ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        : '',
        empStatusFecFin: (item.empStatusFecFin) ? ConvertDate(item.empStatusFecFin) : '',
        empStatusFecIni: (item.empStatusFecIni) ? ConvertDate(item.empStatusFecIni) : '',
        empleadoClave: item.empleadoClave,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoRfc: item.empleadoRfc,
        nomina: (item.nominaCve || item.nominaNomnre) ? `${(item.nominaCve) ? item.nominaCve : '-'}\n/ ${(item.nominaNomnre) ? item.nominaNomnre : '-'}` : '',
        plazaClave: item.plazaCve,
        puesto: (item.puestoCve || item.puestoNom) ? `${(item.puestoCve) ? item.puestoCve : '-'}\n/ ${(item.puestoNom) ? item.puestoNom : '-'}` : '',
    };

    if(tipo === 2) {
        respuesta.usuario = item.usuarioEmail;
        respuesta.nombre = `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`;
        respuesta.fechaMovimiento = (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '';
    }

    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoProrrogados = (data, tipo) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoProrrogados(item, tipo));
    return newJson;
};

const jsonMovimientosPorPeriodoProrrogados = (item, tipo) => {
    let respuesta = {
        contratoSaoNom: item.contratoSaoNom,
        cpresupuestal: (item.cpresupuestalCve || item.cpresupuestalNom)
                        ? `${(item.cpresupuestalCve) ? item.cpresupuestalCve : '-'}\n/ ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        : '',
        empcontratosaoComentario: item.empcontratosaoComentario,
        empcontratosaoFecFin: (item.empcontratosaoFecFin) ? ConvertDate(item.empcontratosaoFecFin) : '',
        empcontratosaoFecIni: (item.empcontratosaoFecIni) ? ConvertDate(item.empcontratosaoFecIni) : '',
        empleadoClave: item.empleadoClave,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoRfc: item.empleadoRfc,
        nivlaboralCve: item.nivlaboralCve,
        nomina: (item.nominaCve || item.nominaNomCorto) ? `${(item.nominaCve) ? item.nominaCve : '-'}\n/ ${(item.nominaNomCorto) ? item.nominaNomCorto : '-'}` : '',
        plazaClave: item.plazaCve,
        puesto: (item.puestoCve || item.puestoNom) ? `${(item.puestoCve) ? item.puestoCve : '-'}\n/ ${(item.puestoNom) ? item.puestoNom : '-'}` : '',
    };

    if(tipo === 2) {
        respuesta.usuario = item.usuarioEmail;
        respuesta.nombre = `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`;
        respuesta.fechaMovimiento = (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '';
    }

    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoNombramientos = (data) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoNombramientos(item));
    return newJson;
};

const jsonMovimientosPorPeriodoNombramientos = (item) => {
    const respuesta = {
        cpresupuestal: (item.cpresupuestalCve || item.cpresupuestalNom)
                        ? `${(item.cpresupuestalCve) ? item.cpresupuestalCve : '-'}\n/ ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        : '',
        empNomFecFin: (item.empNomFecFin) ? ConvertDate(item.empNomFecFin) : '',
        empNomFecIni: (item.empNomFecNom) ? ConvertDate(item.empNomFecNom) : '',
        empleadoClave: item.empleadoCve,
        empleadoNomCompleto: `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`,
        empleadoRfc: item.empleadoRfc,
        nomina: (item.nominaCve || item.nominaNom) ? `${(item.nominaCve) ? item.nominaCve : '-'}\n/ ${(item.nominaNom) ? item.nominaNom : '-'}` : '',
        plazaClave: item.plazaCve,
        puesto: (item.puestoCve || item.puestoNom) ? `${(item.puestoCve) ? item.puestoCve : '-'}\n/ ${(item.puestoNom) ? item.puestoNom : '-'}` : '',
        usuario: item.usuarioEmail,
        nombre: `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`,
        fechaMovimiento: (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '',
    };

    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoDatosEmpleado = (data) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoDatosEmpleado(item));
    return newJson;
};

const jsonMovimientosPorPeriodoDatosEmpleado = (item) => {
    const nombreActual = `${(item.empleadoPat) ? `${item.empleadoPat} ` : ''}${(item.empleadoMat) ? `${item.empleadoMat} ` : ''}${item.empleadoNom}`;
    const nombreAnterior = `${(item.empleadoPatAnt) ? `${item.empleadoPatAnt} ` : ''}${(item.empleadoMatAnt) ? `${item.empleadoMatAnt} ` : ''}${item.empleadoNomAnt}`;
    const generoActual = (item.empleadoGenero) ? (item.empleadoGenero === 'M') ? 'MASCULINO' : (item.empleadoGenero === 'F') ? 'FEMENINO' : item.empleadoGenero : '';
    const generoAnterior = (item.empleadoGeneroAnt) ? (item.empleadoGeneroAnt === 'M') ? 'MASCULINO' : (item.empleadoGeneroAnt === 'F') ? 'FEMENINO' : item.empleadoGeneroAnt : '';

    const respuesta = {
        cpresupuestal: (item.cpresupuestalCve || item.cpresupuestalNom)
                        ? `${(item.cpresupuestalCve) ? item.cpresupuestalCve : '-'}\n/ ${(item.cpresupuestalNom) ? item.cpresupuestalNom : '-'}`
                        : '',
        empleadoFecNacActAnt: (item.empleado_fecnac === item.empleado_fecnac_ant)
                            ?   (item.empleado_fecnac_ant)
                                ?   ConvertDate(item.empleado_fecnac_ant)
                                :   ''
                            :   `${(item.empleado_fecnac) ? ConvertDate(item.empleado_fecnac) : '-'}\n/ ${(item.empleado_fecnac_ant) ? ConvertDate(item.empleado_fecnac_ant) : '-'}`,
        empleadoClaveActAnt: (item.empleadoCve === item.empleadoCveAnt)
                            ?   (item.empleadoCveAnt)
                                ?   item.empleadoCveAnt
                                :   ''
                            :   `${(item.empleadoCve) ? item.empleadoCve : '-'}\n/ ${(item.empleadoCveAnt) ? item.empleadoCveAnt : '-'}`,
        empleadoNomCompletoActAnt: (nombreActual === nombreAnterior)
                            ?   (nombreAnterior)
                                ?   nombreAnterior
                                :   ''
                            :   `${(nombreActual) ? nombreActual : '-'}\n/ ${(nombreAnterior) ? nombreAnterior : '-'}`,
        empleadoGeneroActAnt: (generoActual === generoAnterior)
                            ?   (generoAnterior)
                                ?   generoAnterior
                                :   ''
                            :   `${(generoActual) ? generoActual : '-'}\n/ ${(generoAnterior) ? generoAnterior : '-'}`,
        empleadoCurpActAnt: (item.empleadoCurp === item.empleadoCurpAnt)
                            ?   (item.empleadoCurpAnt)
                                ?   item.empleadoCurpAnt
                                :   ''
                            :   `${(item.empleadoCurp) ? item.empleadoCurp : '-'}\n/ ${(item.empleadoCurpAnt) ? item.empleadoCurpAnt : '-'}`,
        empleadoRfcActAnt: (item.empleadoRfc === item.empleadoRfcAnt)
                            ?   (item.empleadoRfcAnt)
                                ?   item.empleadoRfcAnt
                                :   ''
                            :   `${(item.empleadoRfc) ? item.empleadoRfc : '-'}\n/ ${(item.empleadoRfcAnt) ? item.empleadoRfcAnt : '-'}`,
        empleadoNssActAnt: (item.empleadoNss === item.empleadoNssAnt)
                            ?   (item.empleadoNssAnt)
                                ?   item.empleadoNssAnt
                                :   ''
                            :   `${(item.empleadoNss) ? item.empleadoNss : '-'}\n/ ${(item.empleadoNssAnt) ? item.empleadoNssAnt : '-'}`,
        nomina: (item.nominaCve || item.nominaNom) ? `${(item.nominaCve) ? item.nominaCve : '-'}\n/ ${(item.nominaNom) ? item.nominaNom : '-'}` : '',
        plazaClave: item.plazaCve,
        puesto: (item.puestoCve || item.puestoNom) ? `${(item.puestoCve) ? item.puestoCve : '-'}\n/ ${(item.puestoNom) ? item.puestoNom : '-'}` : '',
        usuario: item.usuarioEmail,
        nombre: `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`,
        fechaMovimiento: (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, true) : '',
    };

    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoEscalafones = (data) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoEscalafones(item));
    return newJson;
};

const jsonMovimientosPorPeriodoEscalafones = (item) => {
    const respuesta = {
        id: item.id,
        folio: item.folio,
        definitivo: item.definitivo,
        tipo: (item.definitivo) ? 'DEFINITIVO' : 'TEMPORAL',
        fecReg: (item.fecReg) ? ConvertDateTime(item.fecReg, true) : '-',
        fecini: (item.fecini) ? ConvertDate(item.fecini) : '-',
        fecfin: (item.fecfin) ? ConvertDate(item.fecfin) : '-',
        username: item.usuario.username,
        nombre: `${(item.usuario.datos.usuarioPat) ? `${item.usuario.datos.usuarioPat} ` : ''}${(item.usuario.datos.usuarioMat) ? `${item.usuario.datos.usuarioMat} ` : ''}${item.usuario.datos.usuarioNom}`,
    };

    return respuesta;
};

export const arrayJsonMovimientosPorPeriodoOtrosMovimientos = (data) => {
    const newJson = data.map(item => jsonMovimientosPorPeriodoOtrosMovimientos(item));
    return newJson;
};

const jsonMovimientosPorPeriodoOtrosMovimientos = (item) => {
    const respuesta = {
        id: item.id,
        empleadoClave: item.empClave,
        nombreCompleto: `${item.empPat ? item.empPat :''} ${item.empMat ? item.empMat:''} ${item.empNom}`,
        plazaClave: item.plazaCve,
        puesto: (item.puestoCve || item.puestoNom) ? `${(item.puestoCve) ? item.puestoCve : '-'}\n/ ${(item.puestoNom) ? item.puestoNom : '-'}` : '',
        conceptoCve: (item.nomIndCve) ? item.nomIndCve : '-',
        concepto: (item.nomIndIndicador) ? `${item.nomIndIndicador}${(item.cantidad > 1) ? ` (${item.cantidad})` : ''}` : '-',
        nomina: (item.nominaCve || item.nominaNombre) ? `${(item.nominaCve) ? item.nominaCve : '-'}\n/ ${(item.nominaNombre) ? item.nominaNombre : '-'}` : '',
        nombre: `${(item.usuarioPat) ? `${item.usuarioPat} ` : ''}${(item.usuarioMat) ? `${item.usuarioMat} ` : ''}${item.usuarioNom}`,
        fechaMovimiento: (item.fechaMovimiento) ? ConvertDateTime(item.fechaMovimiento, false) : '',
    };

    return respuesta;
};
