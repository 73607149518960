import React, { useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";


const EditorHTML = ({ content, setContent }) => {

    const editor = useRef(null);

	const config = {
        disablePlugins: "source,paste-storage,print,preview,about,enter,drag-and-drop,xpath,tooltip,fullsize,hotkeys,iframe,inline-popup,limit,mobile,file,video,media,image,image-processor,image-properties,symbols",
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	};

    const handleChange = value => {
        setContent(value);
    };

    return useMemo( () => ( 
        <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={newContent => setContent(newContent.target.innerHTML)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => handleChange(newContent)}
        />
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [] )
}
 
export default EditorHTML;