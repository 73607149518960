import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Settings from '@material-ui/icons/Settings';
import VpnKey from '@material-ui/icons/VpnKey';

import FormularioInformacion from './FormularioInformacion';
import FormularioContrasena from './FormularioContrasena';
import AlertSyE from '../../../../../Services/Alerts/AlertSyE';

const ModificarInformacion = ({ usuario, setUsuario }) => {
    const [ openForm, setOpenForm ] = useState( false );
    const [ formActivo, setFormActivo ] = useState( '' );

    const seleccionarFormulario = ( nombreForm ) => {
        if( openForm ){
            if( formActivo !== nombreForm ){
                setFormActivo( nombreForm );
            }else{
                setOpenForm( false );
            }
        }else{
            setOpenForm( true );
            setFormActivo( nombreForm );
        }
    }

    /* Funciones para el manejo de alertas */
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );

    const successAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }

    return (
        <React.Fragment>
            <Tooltip title="Actualizar Contraseña" placement="left-start" style={{ float: "right" }} onClick={ () => seleccionarFormulario( 'Contraseña' ) } >
                <IconButton> <VpnKey/> </IconButton>
            </Tooltip>

            <Tooltip title="Modificar Información" placement="left" style={{ float: "right" }} onClick={ () => seleccionarFormulario( 'Informacion' ) } >
                <IconButton> <Settings/> </IconButton>
            </Tooltip>

            <h4> {`${usuario.nombre} ${usuario.apaterno} ${usuario.amaterno}`} </h4>
            <p> {usuario.usuario} </p>

            { openForm &&
                <React.Fragment>
                    <div style={{ display: formActivo === 'Informacion' ? 'block' : 'none' }}>
                        <FormularioInformacion usuario={usuario} setUsuario={setUsuario} setOpenForm={setOpenForm} successAlert={successAlert} errorAlert={errorAlert}/>
                    </div>

                    <div style={{ display: formActivo === 'Contraseña' ? 'block' : 'none' }}>
                        <FormularioContrasena setOpenForm={setOpenForm} successAlert={successAlert} errorAlert={errorAlert}/>
                    </div>
                </React.Fragment>
            }
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </React.Fragment>
    );
};

export default ModificarInformacion;