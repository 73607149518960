import React, { useState } from 'react';
import { correspondencia_conceptos } from '../../../../../../../Constants/Constants';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import ModalService from '../../../../ModalService/ModalService';
import FormCorrespondenciaConceptos from './FormCorrespondenciaConceptos';

const ModalCorrespondenciaConceptos = ({ id, openModalForm, setOpenModalForm, successTable, errorTable }) => {

    const [cambiosGuardados, setCambiosGuardados] = useState(true);
    const [openAlertW, setOpenAlertW] = useState(false);

    const cerrarModal = () => {
        setOpenModalForm(false);
        setCambiosGuardados(true);
    };

    const mostrarAlertW = () => {
        setOpenAlertW(true);
    };

    return (
        <ModalService
            title={correspondencia_conceptos}
            parentNode={id}
            maxWidth="50%" minWidth="50%"
            isOpen={openModalForm}
            onClose={(cambiosGuardados) ? cerrarModal : mostrarAlertW}
        >
            <FormCorrespondenciaConceptos
                cambiosGuardados={cambiosGuardados} setCambiosGuardados={setCambiosGuardados}
                successTable={successTable} errorTable={errorTable}
            />
            <AlertWarning
                show={openAlertW} SetopenALert={setOpenAlertW} text="No ha guardado cambios. ¿Desea continuar?"
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={cerrarModal}
            />
        </ModalService>
    );
}
 
export default ModalCorrespondenciaConceptos;
