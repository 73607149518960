import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { iconosDrawer } from '../../../../../MenuDrawer/Drawer/IconosDrawer'; //Importacion de Iconos
/* ------------------------- Elementos necesarios para la creacion de MuiTheme -------------------------  */
import './styles.css';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './MaterialTheme';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));
/* -----------------------------------------------------------------------------------------------------  */
const CollapseMaterial = ( props ) => {
    const classes = useStyles();
    const [ open, setOpen ] = useState(false);
    const handleClick = () => {
        setOpen(!open);
        props.setElementoActivo( props.title );
    };

    useEffect(() => {
        if( props.elementoActivo !== props.title ){ setOpen( false ); }
    // eslint-disable-next-line
    }, [props.elementoActivo]);

    return (
            <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root} >
                <ListItem button onClick={handleClick}>
                    { props.icon && <ListItemIcon> { iconosDrawer[props.icon] } </ListItemIcon> }
                    <ListItemText primary={ props.title } />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                { props.hideTheme ?
                    <Collapse in={open} timeout="auto" unmountOnExit className="materialCollapse">
                        { props.children }
                    </Collapse>
                    :
                    <MuiThemeProvider theme={theme}>
                        <Collapse in={open} timeout="auto" unmountOnExit className="materialCollapse">
                            { props.children }
                        </Collapse>
                    </MuiThemeProvider>
                }
            </List>
    );
};

export default CollapseMaterial;