import axios from 'axios';
import { servicios_grupohit_nomina, verifique_conexion } from '../../Constants/Constants';
import { headersAuth } from '../../Services/AuthService';
import { GUARDA_PERIODO_ACTUAL, LIMPIA_PERIODO_ACTUAL } from '../actionTypes';
import { JsonNominasRL } from '../../Components/Usuarios/Usuario/Helpers/JsonToOneLevel';

export const obtenerPeriodoActual = ({ mostrarAlerta }) => async dispatch => {
    axios.get(`${servicios_grupohit_nomina}/config/periodos/ultimo/`, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                const arrayOrdenadoPorAnio = res.data.dataResponse.sort((a, b) => {
                    if (a.periodoAnio < b.periodoAnio) {
                        return 1;
                    }
                    if (a.periodoAnio > b.periodoAnio) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });

                //obtiene el año más reciente
                //obtiene el periodo más reciente de los años resultantes
                const arrayAnioActual = arrayOrdenadoPorAnio.filter(item => item.periodoAnio === arrayOrdenadoPorAnio[0].periodoAnio);
                const anios = arrayAnioActual.sort((a, b) => {
                    if (a.periodoPerAnio < b.periodoPerAnio) {
                        return 1;
                    }
                    if (a.periodoPerAnio > b.periodoPerAnio) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                
                const nominasRL = res.data.dataResponse;
                const periodoActual = { anio: anios[0].periodoAnio, periodo: anios[0].periodoPerAnio, periodoInfo: JsonNominasRL(anios[0]).periodoInfo };
                
                dispatch({
                    type: GUARDA_PERIODO_ACTUAL,
                    payload: { nominasRL, periodoActual }
                });

                break;
            default:
                dispatch({
                    type: GUARDA_PERIODO_ACTUAL,
                    payload: { nominasRL: [], periodoActual: {} }
                });
                mostrarAlerta(res.data.msg);
                break;
        }
    })
    .catch(error => {
        mostrarAlerta(verifique_conexion);
    });  
};

export const limpiarPeriodoActual = () => dispatch => {
    dispatch({
        type: LIMPIA_PERIODO_ACTUAL
    });
};
