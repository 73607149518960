import React, { useState, createRef, useRef } from 'react';
import MaterialTable, { MTableCell, MTableAction, MTableActions } from 'material-table';
import { MuiThemeProvider, createMuiTheme, IconButton } from '@material-ui/core';
import { localization, tableIcons } from '../../../../DataTableService/ElementTable';
import Axios from 'axios';
import { registro_actualizado, registro_no_guardado, servicios_grupohit_rh } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import DeleteSweep from '@material-ui/icons/DeleteSweep';
import DoneIcon from '@material-ui/icons/Done';
import { inputDateToLong, deleteOptionals, csvDownload } from '../../../../../../../Services/Validation/HelperValidation';
import ModalGenerarPlantillaEmpleadosProrroga from '../CargasMasivas/ModalGenerarPlantillaEmpleadosProrroga'; 
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';
import { SaveAlt, CloudUpload } from '@material-ui/icons';
import ModalService from '../../../../ModalService/ModalService';
import CargasMasivas from '../CargasMasivas/CargasMasivas';
import AlertErrorDescarga from '../CargasMasivas/AlertErrorDescarga';
import InputText from '../../../../../../../Services/Components/DataTable/InputText';
import ConvertDate from '../../../../Helpers/ConvertDate';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#81A1DB' },
        secondary: { main: '#81A1DB' },
    },
});

const TableUpdateContractEmployed = ({ id, empleados, setEmpleados, axiosContratosEmpleados, actualizado, setActualizado }) => {

    const optionsTable = {
        rowStyle: { textTransform: 'uppercase' },
        columnsButton: false,
        headerStyle: { zIndex: "0", color: '#4C638E', fontweight: 'bold', textTransform: 'uppercase' },
        actionsColumnIndex: -1,
        searchFieldAlignment: "left",
        selection: true,
        filtering: true,
        pageSizeOptions: [5, 10, 20, 50],
        pageSize: (empleados.length > 20) ? 50 : (empleados.length <= 20 && empleados.length > 10) ? 20 : (empleados.length <= 10 && empleados.length > 5) ? 10 : 5,
        //pageSize: empleados.length >= 500 ? 500 : 5,
    };

    const dataVisiblefnc = (columns, data)=>{    
        return extractDataGroup(data, columnsVisibles(columns), groupByData(columns));
    }

    const groupByData = (columns) => {
        return columns.filter(columnDef => columnDef.tableData.groupOrder !== undefined);
    }

    const columnsVisibles = (columns) => { 
        return columns.filter(columnDef => (columnDef.hidden !== true  && columnDef.tableData.groupOrder === undefined)).sort((a, b)=> a.tableData.columnOrder > b.tableData.columnOrder ? 1 : a.tableData.columnOrder < b.tableData.columnOrder ? -1 :0 );
    }

    const extractDataGroup=(data, columnsVisibles, groupBy)=>{
        var result = [];
        for (const key in data) {  
            if (data[key].groups) { 
                const colName = groupBy.filter(element => element.tableData.groupOrder===data[key].path.length-1)[0];
                result.push({" ": `${colName.title}: ${data[key].value}`}); 
                if(data[key].groups.length>0){ 
                    const group = extractDataGroup(data[key].groups, columnsVisibles, groupBy);
                    for (const key in group) {
                        result.push( group[key]);
                    } 
                }else{
                    const dataVisble = data[key].data.map(rowData =>{ const newJson={};  Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = rowData[columnDef.field]; return element})); return newJson;});
                    dataVisble.map(element=>result.push(element)); 
                }
            }else{ 
                const newJson = {};
                Object.assign( newJson, ...columnsVisibles.map(columnDef => {
                    const element={};
                    if( columnDef.field === 'cpresupuestalClave' ){ //Se agrega comilla a campo Clave Presupuestal
                        element[columnDef.title] = "'" + data[key][columnDef.field];
                    }else{
                        element[columnDef.title] = data[key][columnDef.field];
                    }
                    return element;
                }));
                result.push(newJson);
            }
        }
        return result;
    } 

    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ rowsSelected, setRowsSelected ] = useState( [] );
    const [ isComment, setIsComment ] = useState( null );
    const [ tipoAlert, setTipoAlert ] = useState( 0 );
    const [ documentoExcel, setDocumentoExcel ] = useState( null );
    const [ openUploadFile, setOpenUploadFile ] = useState( false );
    const [ error, setError ] = useState( null );

    const cerrarModal = () => {
        setOpenUploadFile( false );
        setError( null );
    } 

    const successAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }

    const inputDatesRefs = useRef([]); 
    const inputCommentsRefs = createRef([]);
    inputCommentsRefs.current =[];
    inputDatesRefs.current = empleados.map(
        (ref, index) => inputDatesRefs.current[index] = createRef('')
    );
    inputCommentsRefs.current = empleados.map(
        (ref, index) =>   inputCommentsRefs.current[index] = createRef('')
    );
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const [ openModal, setOpenModal ] = useState(false);

    const tableRef = createRef();
    let [columns] = useState([ 
        { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
        { field: "nombreCompleto", title: "Nombre" },
        { field: "empleadoRfc", title: "RFC" },
        { field: "cpresupuestalClave", title: "Clave Presupuestal / Proyecto", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
        { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
        { field: "puestoCve", title: "Clave Puesto / Puesto" },
        { field: "contratosaoNom", title: "Contrato" },
        { field: "fechaVigencia", title: "Fecha Vigencia de Contrato" },
        { field: "folio", title: "Folio" },
        { field: "newDate", title: "Nueva Fecha Prórroga", cellStyle: rowData => ({ textTransform:'capitalize' }), filterComponent: (props) => <InputText {...props} dateTable/>, customFilterAndSearch: (term, rowData) => ConvertDate(rowData.newDate).startsWith(term) },
        { field: "comments", title: "Comentarios" }, 
    ]);

    const updateDateContract = ( value, index ) =>{
        empleados[index].newDate= value;
    }
    const updateCommentContract = ( value, index) =>{
        empleados[index].comments= value;
    }

    const alertReplicateData = ( index, type=null )=>{ 
        setActualizado(false);
        setElemmentSelect( index );
        setIsComment(type);
        setTipoAlert(0);
        setTextAlert( type ? "¡Se aplicará este comentario a todos los campos!" :"¡Se aplicará esta Fecha a todos los campos!");
        setAlertWarning(true);
    }

    const replicateData = ( ) => {
        const dataVisible = tableRef.current.state.searchText==="" ? tableRef.current.state.originalData.map(element => element.id) : tableRef.current.state.renderData.map(element => element.id);
        setEmpleados(empleados.map(element => {
            if(dataVisible.includes(element.id) && rowsSelected.find(row => row.id === element.id)){
                if(isComment){
                    element.comments = elemmentSelect;
                }else{
                    element.newDate = elemmentSelect;
                }
            }
            return element;
        }));
        setIsComment(null);
    }

    const saveInfo = ( ) => {
        if(documentoExcel){
            saveFile();
        }else{
            saveInfoTable();
        } 
    }

    const saveFile = ( archivo, setProgreso, setError ) => {
        /* const archivo = documentoExcel.base64; */
        Axios.post( `${servicios_grupohit_rh}/empleado/contrato/masivos/csv/`, archivo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTextAlert(registro_actualizado);
                        setAlertSuccess(true);
                        axiosContratosEmpleados( true );
                        break;
                    case 400:
                        setProgreso( 0 );
                        setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Prorroga_Contratos" /> );
                        break;
                    default:
                        setTextAlert( respuesta.data.msg );
                        setAlertError(true);
                        break;
                }
            })
        .catch( error => {
            setTextAlert( registro_no_guardado );
            setAlertError(true);
        });
    }

    const saveInfoTable = () =>{
        const prorrogas = elemmentSelect.map( item => {
            return deleteOptionals({
                empleadoId: item.empleadoId,
                contratosaoId: item.contratosaoId,
                empcontratosaoFecini: item.empcontratosaoFechafin+86400000,
                empcontratosaoFecfin: item.newDate!=="" ? inputDateToLong(item.newDate) :"",
                comentario: item.comments,
            });
        });

        Axios.post( `${servicios_grupohit_rh}/empleado/contrato/masivos/`, {prorrogas}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTextAlert(registro_actualizado);
                        setAlertSuccess(true);
                        axiosContratosEmpleados( true);
                        break;
                    default:
                        setTextAlert( respuesta.data.msg );
                        setAlertError(true);
                        break;
                }
            })
        .catch( error => {
            setTextAlert( registro_no_guardado );
            setAlertError(true);
        });
    }

    /* Funcion para tipoAlert === 3 (Deshacer Último Contrato) */
    const deshacerContratos = () =>{
        const empleadosIDs = elemmentSelect.map( item => {
            return item.empleadoId;
        });

        Axios.post( `${servicios_grupohit_rh}/empleado/contrato/masivos/borrar/`, {empleados: empleadosIDs}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTextAlert(registro_actualizado);
                        setAlertSuccess(true);
                        axiosContratosEmpleados( true);
                        break;
                    default:
                        setTextAlert( respuesta.data.msg );
                        setAlertError(true);
                        break;
                }
            })
        .catch( error => {
            setTextAlert( registro_no_guardado );
            setAlertError(true);
        });
    }
    
    const validarInputs = ( data ) => {
        /*const validos = data.map((element, index) => {  
            const indexCurrentInput = empleados.findIndex(item => item.id===element.id);
            const fechaInput = inputDatesRefs.current[indexCurrentInput].current.value !=="" ? inputDateToLong(inputDatesRefs.current[indexCurrentInput].current.value) : "";
            if(fechaInput <= element.empcontratosaoFechafin && fechaInput !=="" ){ 
                inputDatesRefs.current[indexCurrentInput].current.className = inputDatesRefs.current[indexCurrentInput].current.className.includes("is-invalid") ? inputDatesRefs.current[indexCurrentInput].current.className : inputDatesRefs.current[index].current.className+" is-invalid" ;
                return true;
            }else{
                return false;
            }
        }); */
        const validos = data.map((element, index) => {  
            const indexCurrentInput = empleados.findIndex(item => item.id===element.id);
            const empleado = empleados.find(item => item.id===element.id);
            const fechaInput = empleado.newDate !=="" ? inputDateToLong(empleado.newDate) : "";

            if((fechaInput !=="") && (fechaInput >= element.empcontratosaoFechafin)){
                return true;
            }else{
                try {
                    inputDatesRefs.current[indexCurrentInput].current.className = inputDatesRefs.current[indexCurrentInput].current.value !== '' ? inputDatesRefs.current[indexCurrentInput].current.className+" is-valid" : inputDatesRefs.current[indexCurrentInput].current.className+" is-invalid" ;
                    return false;
                } catch (error) {
                    return false;
                }
            }
        });

        return !validos.includes(false);
    }

    const verificaInputsVacios = ( data ) =>{
        const validos = data.map( element => element.newDate!=="" ); 
        return validos.includes(false);
    }

    const generarPlantilla = ( ) => {
        setOpenModal( true );
    }

    /* const DivUpload = ( {file} )=>{ 
        return <div className="movimientosIzquerdo upload">
                    <div>
                        <Tooltip title="Click en el botón Actualizar para subir el archivo" placement="left-start">
                            <span> {file.name} </span>
                        </Tooltip>
                        <Tooltip title="Click en el botón Actualizar para subir el archivo" placement="left-start">
                            <button style={{ float: "right" }} type="button" className="close" onClick={() => {deleteFile( )}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </Tooltip>
                    </div>
                    <Progress animated value={progreso} />
                </div>
    } */
    /* const obtenerArchivo = ( file ) => {
        setDocumentoExcel( null ); 
        setDocumentoExcel( file );
        var temp = document.createElement("div");
        
        ReactDOM.render(<DivUpload file={file} />, temp);  
        var container = document.getElementsByClassName("MTableToolbar-actions-47"); 
        container[0].appendChild(temp);
    } */

    /* const deleteFile = (  ) =>{
        setDocumentoExcel( null );  
        var temp = document.createElement("div"); 
        var container = document.getElementsByClassName("MTableToolbar-actions-47"); 
        container[0].replaceChild(temp, container[0].childNodes[1]);
    } */

    return ( 
        <div className="tableUpdateContracts">
                <MuiThemeProvider theme={theme}>
                    <div className="col-12 d-flex align-items-end margintb titleUpdateContracts" >
                        <h4 style={{ paddingRight: '30px',paddingTop: '15px', fontWeight: 'bold' }}>
                            {"Empleados a Prorrogar"}
                        </h4>
                    </div>  
                    <MaterialTable
                        title={""}
                        columns={columns}
                        data={empleados}
                        options={optionsTable}
                        icons={tableIcons}
                        localization={localization}
                        tableRef={tableRef}
                        actions={[
                            {
                                tooltip: 'actualizar',
                                icon: DoneIcon,
                                isFreeAction: true, 
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'asignarPlantilla',
                                icon: ChromeReaderModeIcon,
                                isFreeAction: true,
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'deshacerUltimoContrato',
                                icon: DeleteSweep,
                                isFreeAction: true,
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'csv',
                                icon: SaveAlt,
                                isFreeAction: true, 
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'cargarArchivo',
                                icon: CloudUpload,
                                isFreeAction: true, 
                                onClick: (evt, data) => {
                                }
                            },
                            /* {
                                tooltip: 'subirArchivo',
                                icon: CloudUpload,
                                isFreeAction: true, 
                                onClick: (evt, data) => {
                                }
                            }, */
                            {
                                tooltip: 'Actualizar',
                                icon: DoneIcon,
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'Asignar Plantilla',
                                icon: ChromeReaderModeIcon,
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'Deshacer Último Contrato',
                                icon: DeleteSweep,
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'CSV', 
                                icon: SaveAlt,
                                onClick: (evt, data) => {
                                }
                            },
                            {
                                tooltip: 'Cargar Archivo',
                                icon: CloudUpload, 
                                onClick: (evt, data) => {
                                }
                            },
                            /* {
                                tooltip: 'Subir Archivo',
                                icon: CloudUpload, 
                                onClick: (evt, data) => {
                                }
                            }, */
                        ]}
                        components={{
                            Cell: props=>{                                
                                if(props.columnDef.field==='newDate'){
                                    //const isFirstRowRender = tableRef.current && tableRef.current.state.renderData.length>0 ? tableRef.current.state.renderData.findIndex(element=> element.id === props.rowData.id)===0 : false;
                                    return(
                                        <td className={`MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft`} style={{ padding: (rowsSelected.find(row => row.id === props.rowData.id)) ? '0px, 0px, 0px, 16px' : 16 }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ minWidth: '170px' }}>
                                                        <InputDateValidation  
                                                            title="" 
                                                            refValue={ inputDatesRefs.current[props.rowData.tableData.id] } 
                                                            defaultValue={actualizado ? '' : props.value} 
                                                            onChangeExecute={updateDateContract} 
                                                            informacionAdicional={props.rowData.tableData.id} 
                                                            validateGreaterThan={props.rowData.empcontratosaoFechafin}
                                                            className="inputTable" 
                                                            disabled={(rowsSelected.find(row => row.id === props.rowData.id)) ? false : true}
                                                            optional={(rowsSelected.find(row => row.id === props.rowData.id)) ? false : true}
                                                        />
                                                </div>
                                                {(rowsSelected.find(row => row.id === props.rowData.id)) &&
                                                    <IconButton onClick={() => alertReplicateData(inputDatesRefs.current[props.rowData.tableData.id].current.value)}>
                                                        <Tooltip title="Aplicar misma fecha" placement="left-start" >
                                                            <ArrowDropDownIcon fontSize="small"/>
                                                        </Tooltip>
                                                    </IconButton>
                                                }
                                            </div>
                                        </td>
                                    )
                                }else if(props.columnDef.field==='comments'){
                                    //const isFirstRowRender = tableRef.current && tableRef.current.state.renderData.length>0 ? tableRef.current.state.renderData.findIndex(element=> element.id === props.rowData.id)===0 : false;
                                    return(
                                        <td className={`MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft`}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ minWidth: '170px' }}>
                                                        <InputValidation 
                                                            title="" 
                                                            type="NO" 
                                                            placeholder="Escriba un comentario" 
                                                            maxLength='250' 
                                                            fieldType='textareaShort'
                                                            refValue={inputCommentsRefs.current[props.rowData.tableData.id]} 
                                                            className="inputTable" 
                                                            defaultValue={actualizado ? '' : props.value}  
                                                            action={updateCommentContract}
                                                            informacionAdicional={props.rowData.tableData.id}
                                                            disabled={(rowsSelected.find(row => row.id === props.rowData.id)) ? false : true}
                                                            optional={false}
                                                        />
                                                </div>
                                                {(rowsSelected.find(row => row.id === props.rowData.id)) &&
                                                    <IconButton onClick={()=>alertReplicateData(inputCommentsRefs.current[props.rowData.tableData.id].current.value, true)}>
                                                        <Tooltip title="Aplicar mismo comentario" placement="left-start" >
                                                            <ArrowDropDownIcon fontSize="small"/>
                                                        </Tooltip>
                                                    </IconButton>
                                                }
                                            </div>
                                        </td>
                                    )
                                }
                                return( <MTableCell {...props}/> );
                            },
                            Action: props => { 
                                if(props.action.tooltip === 'actualizar' || props.action.tooltip === 'asignarPlantilla' || props.action.tooltip === 'deshacerUltimoContrato' || props.action.tooltip === 'csv' || props.action.tooltip === 'cargarArchivo' || props.action.tooltip === 'subirArchivo'){
                                    return( <Tooltip title={props.action.tooltip === 'actualizar' ? "Actualizar" : props.action.tooltip === 'asignarPlantilla' ? "Asignar Plantilla" : props.action.tooltip === 'deshacerUltimoContrato' ? "Deshacer Último Contrato" : props.action.tooltip === 'cargarArchivo' ? 'Cargar Archivo': "CSV"}>
                                                {
                                                /* props.action.tooltip !== 'cargarArchivos' ? */
                                                <button 
                                                    className={'form-control btn-color'}
                                                    style={{width: '40%', marginRight: '10px', display: 'inline-table', marginLeft: props.action.tooltip === 'subirArchivo' ? 10 :0}} 
                                                    onClick={(evt, data) => {
                                                        if(props.action.tooltip === 'csv'){
                                                             const resultData = dataVisiblefnc(tableRef.current.state.columns, tableRef.current.state.data);
                                                            if(resultData) csvDownload( resultData, `EMPLEADOS A PRORROGAR.csv` );
                                                        } else {
                                                            if(props.action.tooltip === 'cargarArchivo'){
                                                                setDocumentoExcel('');
                                                                setOpenUploadFile(true);
                                                            }else{
                                                                setTextAlert("¡Debe seleccionar al menos a un empleado!");
                                                                setAlertError(true);
                                                            }
                                                        }
                                                    }}
                                                >
                                                   {
                                                      props.action.tooltip === 'actualizar' ? 
                                                        <DoneIcon/>
                                                      :
                                                      props.action.tooltip === 'asignarPlantilla' ?
                                                        <ChromeReaderModeIcon/>
                                                      :
                                                      props.action.tooltip === 'deshacerUltimoContrato' ?
                                                        <DeleteSweep/>
                                                      :
                                                      props.action.tooltip === 'csv' ?
                                                        <SaveAlt/>
                                                      :
                                                        <CloudUpload/>
                                                   } 
                                                </button>
                                                /* :
                                                    <>
                                                        <label htmlFor="carga_masiva_excel" className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <CloudUpload/>  </label>
                                                        <FileService inputId="carga_masiva_excel" getFile={obtenerArchivo} formatAccept={'.csv' }/>
                                                    </>*/
                                                } 
                                            </Tooltip>)
                                } else if(props.action.tooltip === 'Actualizar'){ 
                                    return( <Tooltip title="Actualizar">
                                                <button 
                                                    className={'form-control btn-color'}
                                                    style={{width: '40%', marginRight: '10px', display: 'inline-table'}} 
                                                    onClick={(evt, data) => {
                                                        if(validarInputs(props.data)){
                                                            setTipoAlert(1);
                                                            setTextAlert("¡Se va a generar una prórroga para los empleados!");
                                                            setElemmentSelect(props.data);
                                                            setAlertWarning(true);
                                                        }
                                                    }}
                                                >
                                                   <DoneIcon/> 
                                                </button>
                                            </Tooltip>)
                                }else if(props.action.tooltip === 'Asignar Plantilla'){ 
                                    return( <><Tooltip title="Asignar Plantilla">
                                                <button
                                                    className={'form-control btn-color'}
                                                    style={{width: '40%', marginRight: '10px', display: 'inline-table'}}
                                                    onClick={(evt, data) => {
                                                        setElemmentSelect(props.data);
                                                        if(verificaInputsVacios(props.data)){
                                                            generarPlantilla();
                                                        }else{
                                                            setTextAlert("¡No ha guardado la información! ¿Desea generar una prórroga para los empleados?");
                                                            setTipoAlert(3);
                                                            setAlertWarning(true);
                                                        }
                                                    }}
                                                >
                                                   <ChromeReaderModeIcon/> 
                                                </button>
                                            </Tooltip>
                                            </>)
                                }else if(props.action.tooltip === 'Deshacer Último Contrato'){
                                    return( <><Tooltip title="Deshacer Último Contrato">
                                                <button
                                                    className={'form-control btn-color'}
                                                    style={{width: '40%', marginRight: '10px', display: 'inline-table'}}
                                                    onClick={(evt, data) => {
                                                        //if(validarInputs(props.data)){
                                                            setTipoAlert(3);
                                                            setTextAlert("Se cancelará la última prórroga de contrato para los empleados seleccionados.");
                                                            setElemmentSelect(props.data);
                                                            setAlertWarning(true);
                                                        //}
                                                    }}
                                                >
                                                   <DeleteSweep/>
                                                </button>
                                            </Tooltip>
                                            </>)
                                }else if(props.action.tooltip === 'CSV' && empleados.length>0){  
                                    return( <Tooltip title="CSV">
                                                <button
                                                    className={'form-control btn-color'}
                                                    style={{width: '40%', marginRight: '10px', display: 'inline-table'}}
                                                    onClick={(evt, data) => { 
                                                        const resultData = dataVisiblefnc(tableRef.current.state.columns, tableRef.current.state.data);
                                                        if(resultData) csvDownload( resultData, `EMPLEADOS A PRORROGAR.csv` );
                                                    }}
                                                >
                                                   <SaveAlt/> 
                                                </button>
                                            </Tooltip>)
                                }else if(props.action.tooltip === 'Cargar Archivo'){  
                                    return( <Tooltip title="Cargar Archivo">
                                                <button
                                                    className={'form-control btn-color'}
                                                    style={{width: '40%', marginRight: '10px', display: 'inline-table'}}
                                                    onClick={(evt, data) => { 
                                                        setDocumentoExcel('');
                                                        setOpenUploadFile(true);
                                                    }}
                                                >
                                                   <CloudUpload/> 
                                                </button>
                                            </Tooltip>)
                                }/* else if(props.action.tooltip === 'Subir Archivo'){  
                                    return( <>
                                            <Tooltip title="Subir Archivo">
                                                <label htmlFor="carga_masiva_excel" className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <CloudUpload/>  </label>
                                            </Tooltip>
                                            <FileService inputId="carga_masiva_excel" getFile={obtenerArchivo} formatAccept={'.csv' }/>
                                            </>
                                            )
                                }  */
                                return( <MTableAction {...props} /> )
                            },
                            Actions: props => (
                                <React.Fragment>
                                    <h5 style={{ minWidth: '360px', marginTop: '15px', textAlign: 'center' }}> Elementos Seleccionados: <b> {rowsSelected.length} </b> </h5>
                                    <MTableActions {...props} />
                                </React.Fragment>
                            )
                        }}
                        onSelectionChange={(rows) => setRowsSelected(rows)}
                    />
                </MuiThemeProvider>
            
            <ModalService title={`Carga Masiva de prórrogas de contratos`} parentNode={id} minWidth="33%" maxWidth="35%"
                isOpen={ openUploadFile } onClose={ () => cerrarModal() } >
                    <b>Descarga</b>
                    <div>1. Baje el formato</div>
                    <div>2. Capture la información solicitada</div>
                    <br/>
                    <b>Carga</b>
                    <div>1. Busque el archivo</div>
                    <div>2. Una vez que el sistema lo analice:</div>
                    <div>Si no se presentan incidencias haga la carga, de lo contrario modifique el archivo y repita el procedimiento</div>
                    <hr/>

                    <CargasMasivas modulo={{id:902}} error={error} errorAlert={errorAlert} successAlert={successAlert} enviar={saveFile} />
            </ModalService>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={tipoAlert === 0 ? replicateData : tipoAlert === 1 ? saveInfo : tipoAlert === 3 ? deshacerContratos : generarPlantilla} />
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <ModalGenerarPlantillaEmpleadosProrroga openModal={openModal} setOpenModal={setOpenModal} elemmentSelect={elemmentSelect} setElemmentSelect={setElemmentSelect}/>
        </div>         
    );
}
 
export default TableUpdateContractEmployed;