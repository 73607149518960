import React from 'react';
import TooltipComponent from './TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const TooltipTable = ({ title, tooltipText }) => {
    return (
        <div style={{ display: 'flex' }}>
            { title }
            <TooltipComponent tooltipText={tooltipText}>
                <span className="spanInfoTable">
                    <InfoRoundedIcon color="disabled"/>
                </span>
            </TooltipComponent>
        </div>
    );
}
 
export default TooltipTable;