import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bajaEmpleado } from '../../../../../../Redux/actions/EmpleadosActions';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import ModalService from '../../../ModalService/ModalService';
import FormBajaEmpleados from './FormBajaEmpleados';

const ModalDeleteFormEmpleados = ({ nombreModulo, openDeleteForm, setOpenDeleteForm, elementSelect, eliminar, errorTable, bajaEmpleado }) => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [textAlert, setTextAlert] = useState('');

    const cerrarModal = () => {
        setOpenDeleteForm(false);
        setError(null);
    };

    const successTable = msg => {
        setTextAlert(msg);
        setOpenAlert(true);
    };

    const enviarBajaEmpleado = (request, statusPlaza, borrarTitularPlaza) => {
        bajaEmpleado(request, statusPlaza, borrarTitularPlaza, elementSelect, eliminar, errorTable, cerrarModal, setIsLoading, successTable);
    };

    return(
        <Fragment>
            <ModalService
                title="Baja de Empleado"
                parentNode={nombreModulo}
                isOpen={openDeleteForm} 
                onClose={cerrarModal}
                maxWidth="60%"
                minWidth="60%"
            >
                <div className="row">
                    <div className="col-12">
                        <FormBajaEmpleados
                            setError={setError}
                            error={error}
                            elementSelect={elementSelect}
                            bajaEmpleado={enviarBajaEmpleado}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            errorTable={errorTable}
                        />
                    </div>
                </div>
            </ModalService>
            <AlertSyE show={openAlert} setOpenAlertSyE={setOpenAlert} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
        </Fragment>
    );
}

const mapDispatchToProps = { bajaEmpleado };

export default connect(null, mapDispatchToProps)(ModalDeleteFormEmpleados);
