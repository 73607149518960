import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_url } from '../../../../../../../Constants/Constants';
import { ArrayJsonSubordinados, ArrayJsonRestricciones } from './HelperRestricciones';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import CollapseMaterial from '../AsignacionModulos/CollapseMaterial/CollapseMaterial';
import IconButton from '@material-ui/core/IconButton';
import Info from '@material-ui/icons/Info';
import Person from '@material-ui/icons/Person';
import Switch from '@material-ui/core/Switch';

import { obtenerRestriccionesModulo } from './HelperRestricciones';
import ModalInformacionRestricciones from './ModalInformacionRestricciones';
import ModalRestringirUsuarios from './ModalRestringirUsuarios';
import ModalRestringirModulo from './ModalRestringirModulo';

const Restricciones = ({ idModulo, errorTable, successTable, informacion }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    /* ---------------------------------- Funciones para controlar modales ---------------------------------- */
    const [ openInformacion, setOpenInformacion ] = useState( false );
    const [ openUsuarios, setOpenUsuarios ] = useState( false );
    const [ openModulo, setOpenModulo ] = useState( false );
    const [ moduloSelect, setModuloSelect ] = useState( {} );

    const seleccionar1 = ( modulo ) => {
        setModuloSelect( modulo );
        setOpenUsuarios( true );
    }

    const seleccionar2 = ( modulo ) => {
        setModuloSelect( modulo );
        setOpenModulo( true );
    }

    const seleccionar3 = ( modulo ) => {
        setModuloSelect( modulo );
        setOpenInformacion( true );
    }
    /* ------------------------- Axios para obtener los modulos del usuario superior ------------------------ */
    const [ modulosSuperior, setModulosSuperior ] = useState( null );
    const [ usuarios, setUsuarios ] = useState( null );
    const [ restricciones, setRestricciones ] = useState( null );
    useEffect(() => {
        axiosModulosSuperior();
        axiosUsuarios();
        axiosUsuariosRestringidos();
        // eslint-disable-next-line
    }, []);

    const axiosModulosSuperior = () => {
        axios.get( `${servicios_grupohit_url}/menu`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setModulosSuperior( respuesta.data.dataResponse.menu );
                        break;
                    case 404:
                        //setModulosSuperior([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosUsuarios = () => {
        axios.get( `${servicios_grupohit_url}/all/subordinates`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios( ArrayJsonSubordinados(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosUsuariosRestringidos = () => {
        axios.get( `${servicios_grupohit_url}/modulos/bloqueados/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setRestricciones( ArrayJsonRestricciones(respuesta.data.dataResponse.Subordinados) );
                        break;
                    case 404:
                        //setRestricciones( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            { modulosSuperior && usuarios && restricciones ?
                <React.Fragment>
                    { modulosSuperior.map( moduloPadre => {
                        if( moduloPadre.nombre !== 'Tablero' && moduloPadre.nombre !== 'Ayuda' && moduloPadre.children ){
                            return(
                                <CollapseMaterial key={moduloPadre.id} title={moduloPadre.nombre} icon={moduloPadre.icono} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
                                    { moduloPadre.children ?
                                        moduloPadre.children.map( moduloHijo => {
                                            if( moduloHijo.permisos && moduloHijo.permisos.length >= 1 ){
                                                const restriccionesModulo = obtenerRestriccionesModulo(restricciones, moduloHijo.id);
                                                return(
                                                    <div key={moduloHijo.id} className="row nombreModulo restricciones">
                                                        <div className="col-md-8 col-sm-12 d-flex align-items-center">
                                                            {moduloHijo.nombre}
                                                        </div>
                                                        <div className="col-md-4 col-sm-12 text-right">
                                                            { restriccionesModulo.length > 0 && <Info className="iconRInfo" onClick={() => seleccionar3( moduloHijo )}/> }
                                                            <IconButton onClick={() => seleccionar1( moduloHijo )}> <Person fontSize="small" /> </IconButton>
                                                            <Switch checked={ restriccionesModulo.length > 0 && restriccionesModulo.length === usuarios.length ? true : false }
                                                                onChange={ () => seleccionar2( moduloHijo ) } size="small" />
                                                        </div>
                                                    </div>
                                                );
                                            }else{
                                                return '';
                                            }
                                        })
                                        :
                                        <p> {moduloPadre.nombre} </p>
                                    }
                                </CollapseMaterial>
                            );
                        }else{
                            return '';
                        }
                    })}
                    <ModalInformacionRestricciones openInformacion={openInformacion} setOpenInformacion={setOpenInformacion} moduloSelect={moduloSelect}
                        informacion={{ usuarios, departamentos: informacion.departamentos, restricciones }} idModulo={idModulo}/>

                    <ModalRestringirUsuarios openUsuarios={openUsuarios} setOpenUsuarios={setOpenUsuarios} moduloSelect={moduloSelect} errorTable={errorTable} successTable={successTable}
                        informacion={{ usuarios, departamentos: informacion.departamentos, restricciones }} idModulo={idModulo} setRestricciones={setRestricciones}/>
                    <ModalRestringirModulo openModulo={openModulo} setOpenModulo={setOpenModulo} moduloSelect={moduloSelect} errorTable={errorTable} successTable={successTable}
                        informacion={{ usuarios, restricciones }} idModulo={idModulo} setRestricciones={setRestricciones}/>
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default Restricciones;