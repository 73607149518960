import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, registro_sera_eliminado, verifique_conexion } from '../../../../../../../../Constants/Constants';
import { actualizaEmpleadosEscalafonDefinitivo } from '../../../../../../../../Redux/actions/EmpleadosActions';
import Alertwarning from '../../../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../../../Services/ModalLoading/ModalLoading';

const ModalEliminarEscalafon = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const [isLoading, setIsLoading] = useState(false);

    const eliminarEscalafon = () => {
        setIsLoading(true);
        axios.delete(`${servicios_grupohit_rh}/empleado/movimientos/especiales/escalafon/${elementSelect.id}`, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect.id);
                    const empleados = res.data.dataResponse.map(emp => {
                        const newEmp = emp;
                        newEmp.empId = emp.id;
                        return newEmp;
                    })
                    actualizaEmpleadosEscalafonDefinitivo(empleados);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => setIsLoading(false));
    }

    return(
        <Fragment>
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={eliminarEscalafon} />
        </Fragment>
    );
}

const mapDispatchToProps = { actualizaEmpleadosEscalafonDefinitivo };

export default connect(null, mapDispatchToProps)(ModalEliminarEscalafon)