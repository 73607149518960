import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';

import AlertForm from '../../../../../../../Services/AlertForm';

const FormAgregarPagosPrestamo = ({ error, setError, agregarPagos, elementSelect }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const numPagoRef = createRef('');

    const enviar = (evt) => {
        evt.preventDefault();
        setError( null );
        if( validarSubmit( 'AgregarPagos' ) ){
            setOpenAlert( true );
        }
    };

    const finalizarPrestamo = () => {
        const numPago = parseInt(numPagoRef.current.value);
        if( numPago > elementSelect.numPago && numPago <= elementSelect.numeroPagos ){
            agregarPagos({ empprestId: elementSelect.id, numPago });
        }else{
            setError( <AlertForm message="El número ingresado no puede ser menor al número de pagos actual, ni mayor al número de pagos por pagar" /> );
        }
    }

    return (
        <React.Fragment>
            <form id={ 'AgregarPagos' } onSubmit={ enviar }>
                <InputValidation title="Ingresa el Número de Pago a actualizar" type="cantidad" placeholder="Digite el número de pagos"
                    tooltipText={'Máximo 16 números'} tooltipName={'tooltipNumPrest'} maxLength='16' onlyNumbers
                    refValue={numPagoRef} required/>

                {error}
                <Button variant="contained" className="btn-color" type="submit"> Agregar Pagos </Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text="El préstamo será actualizado" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={finalizarPrestamo}/>
        </React.Fragment>
    );
};

export default FormAgregarPagosPrestamo;