import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_admon, proyectos_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonProyectos } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Proyectos = ({ id, permisos }) => {

    const [proyectos, setProyectos] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        Axios.get(`${servicios_grupohit_admon}/presupuestal/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const proyects = ArrayJsonProyectos(res.data.dataResponse);
                    setProyectos(proyects);
                    break;
                case 404:
                    setProyectos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                proyectos
                ?   <DataTableService id={id} title={ proyectos_ }
                        columns={[
                            { field: "cpresupuestalclave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalclave.startsWith(term) },
                            { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia" },
                            { field: "udejecutoranom", title: "Unidad Ejecutora" },
                            { field: "programanom", title: "Programa" },
                            { field: "subprogramanom", title: "Subprograma" },
                            { field: "proyectonom", title: "Proyecto Acción" },
                            { field: "obraAccionnom", title: "Obra Acción" },
                            { field: "cpresupuestalnom", title: "Centro de Trabajo / Proyecto / Clave Presupuestal" },
                        ]}
                        data={proyectos}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default Proyectos;
