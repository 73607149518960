import React, { useState } from 'react';
import { connect } from 'react-redux';
import { nuevoEmpleado } from '../../../../../../Redux/actions/EmpleadosActions';
import ModalService from './../../../ModalService/ModalService';
import FormEmpleados from './FormEmpleados';

const ModalAddEmpleados = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo, informacionAdicional, nuevoEmpleado }) => {
    
    const [reingresoRH, setReingresoRH] = useState(false);
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
        setReingresoRH(false);
    };

    return (
        <ModalService 
            title={`Agregar Empleado ${(reingresoRH) ? '(Reingreso de Histórico)' : ''}`}
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={() => cerrarModal()}
            maxWidth="60%"
            minWidth="60%"
        >
            <div className="row">
                <div className="col-12">
                    <FormEmpleados
                        modulo={typeof nombreModulo ==="string" ? parseInt(nombreModulo.split('-')[0]) : nombreModulo}
                        nuevoEmpleado={nuevoEmpleado}
                        agregar={agregar}
                        errorTable={errorTable}
                        error={error}
                        setError={setError}
                        informacionAdicional={informacionAdicional}
                        reingresoRH={reingresoRH}
                        setReingresoRH={setReingresoRH}
                        setOpenAdd={cerrarModal}
                    />
                </div>
            </div>
        </ModalService>
    );
}

const mapDispatchToProps = { nuevoEmpleado };

export default connect(null, mapDispatchToProps)(ModalAddEmpleados);
