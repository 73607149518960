import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIMSS } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormularioIMSS from './FormularioIMSS';

const ModalEditIMSS = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo, dataTable }) => {
    const [ error, setError ] = useState( null );
    const [isLoading, setIsLoading] = useState(false);
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarIMSS = ( porcentajeIMSS ) => {
        setIsLoading(true);
        axios.put( `${servicios_grupohit_nomina}/imss/`, porcentajeIMSS, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setError( null );
                        editar( JsonIMSS(respuesta.data.dataResponse) );
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
            .finally(() => setIsLoading(false));
    }
    
    return (
        <ModalService title="Actualizar % IMSS" parentNode={nombreModulo} minWidth="50%" maxWidth="50%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioIMSS
                    error={error}
                    elementSelect={elementSelect}
                    informacion={informacionAdicional}
                    actualizarIMSS={actualizarIMSS}
                    setError={setError}
                    dataTable={dataTable}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalEditIMSS;