import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, salario_minimo_uma, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonSalarioMinUMA } from '../../../Helpers/JsonToOneLevel';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const SalarioMinimoUMA = ({ id, permisos }) => {

    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    
    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}s/salario/minimouma/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse));
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                salarioMinimos
                ?   <DataTableService id={id} title={ salario_minimo_uma }
                        permisos={permisos} 
                        columns={[
                            { field: "zonaNom", title: "Zona" },
                            { field: "monto", title: "Monto", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}},
                            { field: "fechaIni", title: "Fecha Inicial" },
                            { field: "fechaFin", title: "Fecha Final" }
                        ]}
                        data={salarioMinimos}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default SalarioMinimoUMA;
