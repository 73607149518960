import React, { Fragment } from 'react';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import HouseIcon from '@material-ui/icons/House';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import WorkIcon from '@material-ui/icons/Work';
import PaletteIcon from '@material-ui/icons/Palette';
import AlertForm from '../../../../../../Services/AlertForm';

const BtnsInfoAdicional = ({ modulo, numForm, irInfo, elementSelect }) => {
    
    return (
        <Fragment>
            <label>Información Adicional </label>{(modulo === 30 || (!elementSelect && modulo === 32)) && <TooltipComponent tooltipText="De clic en el botón correspondiente a la información que desea agregar"><span className="spanInfo"><InfoRoundedIcon color="disabled"/></span></TooltipComponent>}
            {((!elementSelect && modulo === 32) || modulo === 30 || numForm > 2) &&
            <div className="row" style={{ marginBottom: '1rem', marginTop: '.6rem' }}>
                <div className="col-2 align-self-center text-center">
                    <button className={(numForm === 3) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 3)} disabled={(numForm === 3) ? true : false}>
                        <TooltipComponent tooltipText="Domicilio Fiscal" position="top">
                            <HouseIcon />
                        </TooltipComponent>
                    </button>
                </div>
                <div className="col-2 align-self-center text-center">
                    <button className={(numForm === 4) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 4)} disabled={(numForm === 4) ? true : false}>
                        <TooltipComponent tooltipText="Dependientes Económicos" position="top">
                            <MonetizationOnIcon />
                        </TooltipComponent>
                    </button>
                </div>
                <div className="col-2 align-self-center text-center">
                    <button className={(numForm === 5) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 5)} disabled={(numForm === 5) ? true : false}>
                        <TooltipComponent tooltipText="Pensiones Alimenticias" position="top">
                            <RestaurantIcon />
                        </TooltipComponent>
                    </button>
                </div>
                <div className="col-2 align-self-center text-center">
                    <button className={(numForm === 6) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 6)} disabled={(numForm === 6) ? true : false}>
                        <TooltipComponent tooltipText="Números de Emergencia" position="top">
                            <LocalHospitalIcon />
                        </TooltipComponent>
                    </button>
                </div>
                <div className="col-2 align-self-center text-center">
                    <button className={(numForm === 7) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 7)} disabled={(numForm === 7) ? true : false}>
                        <TooltipComponent tooltipText="Experiencia Laboral" position="top">
                            <WorkIcon />
                        </TooltipComponent>
                    </button>
                </div>
                <div className="col-2 align-self-center text-center">
                    <button className={(numForm === 8) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 8)} disabled={(numForm === 8) ? true : false}>
                        <TooltipComponent tooltipText="Pasatiempos" position="top">
                            <PaletteIcon />
                        </TooltipComponent>
                    </button>
                </div>
            </div>
            }
            {(elementSelect && modulo === 32) &&
            <AlertForm warning message="La información adicional se deberá actualizar desde el módulo de Empleados, después de hacer el Reingreso"/>
            }
        </Fragment>
    );
}
 
export default BtnsInfoAdicional;