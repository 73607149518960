import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import ArbolUsuarios from './ArbolUsuarios/ArbolUsuarios';

const ModalViewUsuarios = ({ openEdit, setOpenEdit, errorTable, successTable, nombreModulo, informacionAdicional }) => {
    const cerrarModal = () => {
        setOpenEdit( false );
    }

    return (
        <ModalService title="Usuarios" parentNode={nombreModulo} minWidth="50%" maxWidth="50%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >

                <ArbolUsuarios idModulo={nombreModulo} informacion={informacionAdicional}/>
        </ModalService>
    );
};

export default ModalViewUsuarios;