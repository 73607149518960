import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

/* Reducers */
import SessionReducer from './reducer/SessionReducer';
import NavReducer from './reducer/NavReducer';
import EmpleadosReducer from './reducer/EmpleadosReducer';
import SystemReducer from './reducer/SystemReducer';
import PeriodoActualReducer from './reducer/PeriodoActualReducer';

const appReducer = combineReducers( { SessionReducer, NavReducer, EmpleadosReducer, SystemReducer, PeriodoActualReducer } );

const rootReducer = (state, action) => {
    if (action.type === 'RESET_STATE_APP') {
      state = undefined;
    }
    return appReducer(state, action);
}

/* Exportacion del Store */
export default createStore(
    rootReducer, 
    //compose( applyMiddleware( thunk ), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() )
    compose( applyMiddleware( thunk ) )
);