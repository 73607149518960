import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
import FormularioRegimenFiscal from './FormularioRegimenFiscal';

const ModalAddRegimenFiscal = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarRegimenFiscal = ( regimenFiscal ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_catalogo}/regfiscal/`, regimenFiscal, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Régimen Fiscal" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioRegimenFiscal
                    error={error}
                    enviarRegimenFiscal={enviarRegimenFiscal}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddRegimenFiscal;