import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Add from '@material-ui/icons/Add';
import ModalAddAccesoDirecto from './ModalAddAccesoDirecto';

const AgregarAccesos = ({ accesosDirectos, axiosAccesos }) => {
    const [ openAdd, setOpenAdd ] = useState( false );
    return (
        <React.Fragment>
            <Card className="cardBoard cardTablero" onClick={() => setOpenAdd( true )}>
                <CardContent>
                    <div className="icon"> <Add/> </div>
                    <p className="name"> Agregar Acceso Rápido </p>
                    <div className="textRBloqueado"></div>
                </CardContent>
            </Card>
            <ModalAddAccesoDirecto openAdd={openAdd} setOpenAdd={setOpenAdd} accesosDirectos={accesosDirectos} axiosAccesos={axiosAccesos}/>
        </React.Fragment>
    );
};

export default AgregarAccesos;