import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import Formulario from './AuditoriaRegistroEmpleados/Formulario';

const ModalFormulario = ({ id, reporte, open, setOpen }) => {

    const { reportesId, reportesReporteNom } = reporte;

    const cerrarModal = () => {
        setOpen(false);
    };

    return (
        <ModalService
            title={reportesReporteNom}
            parentNode={id}
            isOpen={open}
            onClose={cerrarModal}
            minWidth="50%"
            maxWidth="50%"
            labelRight
            showCloseIcon
        >
        {(reportesId === 160 || reportesId === 161) &&
            <Formulario reporte={reporte} cerrarModal={cerrarModal} />
        }
        </ModalService>
    );
}
 
export default ModalFormulario;