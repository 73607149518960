import React, { Fragment } from 'react';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import InfoEmpleadoPagosPendientes from './InfoEmpleadoPagosPendientes';
import InfoPagosPendientes from './InfoPagosPendientes';

const FormModalPagosPendientes = ({ id, elementSelect, successTable, errorTable, cerrarModal }) => {
    return (
        <Fragment>
            <InfoEmpleadoPagosPendientes elementSelect={elementSelect}/>
            <CardComponent>
                <InfoPagosPendientes
                    id={id}
                    empNombre={`${(elementSelect.empleadoPat) ? `${elementSelect.empleadoPat} ` : ''}${(elementSelect.empleadoMat) ? `${elementSelect.empleadoMat} ` : ''}${elementSelect.empleadoNom}`}
                    empID={elementSelect.id} successTable={successTable} errorTable={errorTable}
                    cerrarModal={cerrarModal}
                />
            </CardComponent>
        </Fragment>
    );
}
 
export default FormModalPagosPendientes;
