import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_rh, expediente_, verifique_conexion } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Expediente = ({ id, permisos, empleadosActivos, obtenerEmpleadosActivos }) => {
    
    const [ tiposDocumentos, setTiposDocumentos ] = useState( null );
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        axiosTiposDocumentos();
        obtenerEmpleadosActivos((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Axios para consumo de servicio TiposDocumentos */
    const axiosTiposDocumentos = () => {
        axios.get( `${servicios_grupohit_rh}/documentos/catalogo/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposDocumentos( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposDocumentos([]);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    return (
        <div id={`modulo${id}`}>
            { empleadosActivos && tiposDocumentos
                ?   <DataTableService id={id} title={ expediente_ }
                        columns={[
                            { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                            { field: "nombreCompleto", title: "Nombre" },
                            { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
                            { field: "empleadoRfc", title: "RFC" },
                            { field: "empleadoNss", title: "NSS" },
                            { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                            { field: "puestoCve", title: "Clave Puesto" },
                            { field: "puestoNom", title: "Puesto" },
                            { field: "nominaNombre", title: "Nómina" },
                            { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                            { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                        ]}
                        data={ empleadosActivos }
                        customOptionsTable={{ filtering: true }}
                        hideIconAdd hideIconDelete
                        informacionAdicional={{ tiposDocumentos }}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});
  
const mapDispatchToProps = { obtenerEmpleadosActivos };
 
export default connect(mapStateToProps, mapDispatchToProps)(Expediente);