import React, { Fragment } from 'react';
import TooltipComponent from './TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const TooltipCheckbox = ({ label, tooltipText }) => {
    return (
        <Fragment>
            {label}
            <TooltipComponent tooltipText={tooltipText}>
                <span className="spanInfo2"><
                    InfoRoundedIcon color="disabled"/>
                </span>
            </TooltipComponent>
        </Fragment>
    );
}
 
export default TooltipCheckbox;