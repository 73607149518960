import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import FormularioContrasena from '../Perfil/ModificarInformacion/FormularioContrasena';
import AlertSyE from '../../../../Services/Alerts/AlertSyE';

const ModalCambiarPassword = ({ modalPassword, setModalPassword, setCambiarPassword, setActivarTour }) => {
    /* Funciones para el manejo de alertas */
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );

    const successAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }

    return (
        <React.Fragment>
            <Dialog open={modalPassword} onClose={() => setModalPassword(false)} disableEscapeKeyDown={true} disableBackdropClick={true} className="contenedorTerminosPrivacidad">
                <DialogTitle>
                    <div className="modalChangePassword">
                        Actualizar Contraseña
                    </div>
                </DialogTitle>

                <DialogContent>
                    <div style={{ width: '500px' }}>
                        <FormularioContrasena setOpenForm={setModalPassword} setCambiarPassword={setCambiarPassword} successAlert={successAlert} errorAlert={errorAlert} setActivarTour={setActivarTour}/>
                    </div>
                </DialogContent>
            </Dialog>

            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </React.Fragment>
    );
};

export default ModalCambiarPassword;