import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_nomina } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import CollapseMaterial from '../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/CollapseMaterial';
import DataTableService from '../../../DataTableService/DataTableService';
import { ArrayJsonConsultaNomina } from '../../../Helpers/JsonToOneLevel';

const conceptos = [ 'Nómina' ];
/*
const informacionConsulta = [
    { concepto: '1', descripción: 'sueldo', importe: 54125 },
    { concepto: '2', descripción: 'Canasta Básica', importe: 54125 },
    { concepto: '3', descripción: 'Actividades Culturales', importe: 54125 },
    { concepto: '100', descripción: 'Inasistenacias', importe: 54125 },

    { concepto: '', descripción: 'Total', importe: 246159 },
]
*/

const ConsultarNomina = ({ moduloID, nomina }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    /* ----------------------------- Axios para obtener listado de calculos por nomina ---------------------------- */
    const [ calculosNomina, setCalculosNomina ] = useState( null );
    useEffect(() => {
        axiosCalculosNomnina();
        // eslint-disable-next-line
    }, []);

    const axiosCalculosNomnina = () => {
        const { nominaId, periodoId } = nomina;
        axios.post( `${servicios_grupohit_nomina}/vacumperxacum/`, {nominaId, periodoId}, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setCalculosNomina( ArrayJsonConsultaNomina( respuesta.data.dataResponse) );
                        break;
                    case 404:
                        setCalculosNomina([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            { conceptos.map( elemento => (
                <CollapseMaterial key={elemento} title={elemento} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo} hideTheme>
                    { calculosNomina ?
                        <DataTableService id={moduloID} title={ 'Nóminas' }
                            columns={[
                                { field: "nomindClave", title: "Concepto" },
                                { field: "nomindIndicador", title: "Descripción" },
                                { field: "round", title: "Importe Pagos", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}  }
                            ]}
                            hideIconAdd hideIconEdit hideIconDelete
                            data={ calculosNomina } /> : <SpinnerOval />
                    }
                </CollapseMaterial>
            ))}
        </React.Fragment>
    );
};

export default ConsultarNomina;