import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_rh, servicios_grupohit_nomina, prestamos_empleado } from '../../../../../../../Constants/Constants';
import { ArrayJsonPrestamos2 } from '../../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

const PrestamosEmpleado = ({ id, permisos, empleado, montoLiquido, setMontoLiquido, obtienePrestamos, setObtienePrestamos, setMuestraMontoLiquido, nominasRL }) => {
    
    const [ prestamos, setPrestamos ] = useState( null );
    const [ indicadoresNomina, setIndicadoresNomina ] = useState( null );
    const [ infoTabla, setInfoTabla ] = useState( null );
    
    useEffect(() => {
        if(obtienePrestamos) {
            axiosPrestamos();
            axiosIndicadoresNomina();
        }
        // eslint-disable-next-line
    }, [obtienePrestamos]);

    useEffect(() => {
        if(prestamos && indicadoresNomina) {
            setInfoTabla( ArrayJsonPrestamos2( prestamos, indicadoresNomina ));
        }
    }, [prestamos, indicadoresNomina]);

    const axiosPrestamos = () => {
        setObtienePrestamos(false);
        //axios.get( `${servicios_grupohit_rh}/empleado/prestamos/${empleado.id}`, headersAuth() )
        axios.get( `${servicios_grupohit_rh}/empleado/prestamos/vista/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setPrestamos( respuesta.data.dataResponse );
                        const infoNomina = nominasRL.find(n => n.nomina === empleado.nominaId);
                        if(infoNomina) {
                            //prestamos que inician igual o despues que el ultimo periodo de nomina
                            const prestamosPost = respuesta.data.dataResponse.filter(p => 
                                (p.empprestFecfin) 
                                ? !p.calculo && (infoNomina.periodoFecfin > p.empprestFecini) && (infoNomina.periodoFecini < p.empprestFecfin)
                                : !p.calculo && (infoNomina.periodoFecfin > p.empprestFecini)
                            );
                            if(prestamosPost.length > 0) {
                                //acumulado de importe de prestamos post
                                const importePost = prestamosPost.reduce((total, prestamo) => ( { empprestImporte: total.empprestImporte + parseFloat(prestamo.empprestImporte) } )).empprestImporte;
                                setMontoLiquido(montoLiquido-importePost);
                            }
                        }
                        setMuestraMontoLiquido(true);
                        break;
                    case 404: //No hay datos que mostrar
                        setPrestamos([]);
                        setMuestraMontoLiquido(true);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener los indicadores por nomina (Nomina a la cual esta relacionada el empleado) */
    const axiosIndicadoresNomina = () => {
        axios.get( `${servicios_grupohit_nomina}/indicadores/getbynomina/${empleado.nominaId}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setIndicadoresNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setIndicadoresNomina( [] );
            })
    }

    return (
        <div id={`modulo${id}secondary`}>
            { infoTabla ?
                <DataTableService id={`${id}secondary`} title={ prestamos_empleado } permisos={permisos}
                    columns={[
                        { field: "folio", title: "Folio" },
                        { field: "indicadorNombre", title: "Concepto" },
                        { field: "numeroPagosPagados", title: "Número de Pagos", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "importe", title: "Importe Pagos", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "fechaIniTab", title: "Fecha Inicio Préstamo" },
                        { field: "fechaFinTab", title: "Fecha Fin Préstamo" },
                    ]}
                    showIconAux showIconAuxAdd
                    data={infoTabla}
                    informacionAdicional={{ empleado, indicadoresNomina, montoLiquido, setMontoLiquido }} />
                :
                <SpinnerOval />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
    empleadosAll: state.EmpleadosReducer.empleadosAll,
    openMenu: state.SystemReducer.openMenu,
    nominasRL: state.PeriodoActualReducer.nominasRL,
});

export default connect(mapStateToProps)(PrestamosEmpleado) ;