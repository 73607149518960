import React from 'react';
import DataTableService from '../../../../DataTableService/DataTableService';

const AusentismosTable = ({ id, permisos, empleado, data, tiposAusentismo }) => {
    return (
        <DataTableService
            id={`${id}secondary`}
            title={`Ausentimos ${empleado.empleadoClave} ${empleado.nombreCompleto}`}
            columns={[
                { field: "auseFechaIni", title: "Fecha Inicial" },
                { field: "auseFechaFin", title: "Fecha Final" },
                { field: "ausentismoNom", title: "Tipo de Ausentismo" },
                { field: "cantidad", title: "Cantidad" },
                { field: "auseFechaAplica", title: "Fecha de Aplicación" },
            ]}
            data={data}
            permisos={permisos}
            informacionAdicional={{ claveEmpleado: empleado.id, tiposAusentismo }}
        />
    );
}
 
export default AusentismosTable;