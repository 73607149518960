import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { servicios_grupohit_catalogo, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { limpiarPeriodoActual, obtenerPeriodoActual } from '../../../../../../Redux/actions/PeriodoActualActions';
import ModalService from '../../../ModalService/ModalService';
import FormCalculo from './FormCalculo';
import InfoNomina from './InfoNomina';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import { servicioNuevoReporteNominas } from '../../Reporteador/Nominas/Servicios/NuevoReporteNominas';

const ModalAddCalculo = ({ openEdit, setOpenEdit, errorTable, successTable, elementSelect, nombreModulo, informacionAdicional, limpiarPeriodoActual, obtenerPeriodoActual }) => {
    
    const [ejecutarServicioUltimoPeriodo, setEjecutarServicioUltimoPeriodo] = useState(true);
    const [ejecutarServicioProcesos, setEjecutarServicioProcesos] = useState(false);
    const [error, setError] = useState(null);
    const [tiposAcumulado, setTiposAcumulado] = useState([]);
    const [periodo, setPeriodo] = useState(null);
    const [acumuladosSelected, setAcumuladosSelected] = useState([]);
    const [alertWarningType, setAlertWarningType] = useState(null);
    const [alertWarning, setAlertWarning] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { permisos } = informacionAdicional;

    useEffect(() => {
        if(openEdit) {
            axios.get(`${servicios_grupohit_catalogo}s/tipoacumulado/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setTiposAcumulado(res.data.dataResponse);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion));

            setEjecutarServicioUltimoPeriodo(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openEdit]);

    const descargaAcumulados = () => {
        setIsLoading(true);
        obtenerInfoAcumulados();
    };

    const obtieneType = () => {
        switch(alertWarningType) {
            case 1:
                return {
                    title: '¿Desea retroceder un periodo?',
                    text: <p>Esta acción eliminará todos los resultados de cálculo realizados en el periodo. Se recomienda <TooltipComponent tooltipText="Descargar acumulados"><span className="subrayado link-text" onClick={descargaAcumulados}>descargar</span></TooltipComponent> todos los acumulados antes de continuar.</p>,
                    function: () => retrocederPeriodo()
                }
            case 2:
                return {
                    title: '¿Desea avanzar un periodo?',
                    text: <p>Esta acción cerrará el periodo sin generar ningún cálculo o acumulado.</p>,
                    function: () => avanzarPeriodo()
                }
            default:
                return {
                    title: '',
                    text: '', 
                    function: () => errorTable('No es posible ejecutar')
                }
        }
    };

    const actualizaPeriodos = () => {
        limpiarPeriodoActual();
        obtenerPeriodoActual({ mostrarAlerta: errorTable });
    };

    const verificaAcumuladosCerrados = () => {
        const buscaAbiertos = acumuladosSelected.find(acumS => acumS.cerrado === false);
        if(acumuladosSelected.length > 0) {
            if(buscaAbiertos) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const cerrarModal = () => {
        if(verificaAcumuladosCerrados()) {
            actualizaPeriodos();
        }
        setEjecutarServicioProcesos(false);
        setTiposAcumulado([]);
        setPeriodo(null);
        setAcumuladosSelected([]);
        setAlertWarningType(null);
        setAlertWarning(false);
        setAlertError(false);
        setOpenEdit(false);
        setError(null);
    };

    const obtenerInfoAcumulados = () => {
        //servicio para obtener último Periodo
        axios.get(`${servicios_grupohit_nomina}/ultimo/periodo/acumulado/${elementSelect.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const resultado = res.data.dataResponse.resultado;
                    if(resultado > 0) {
                        const data = {
                            nominaId: elementSelect.id,
                            nominaClave: elementSelect.nominaNombre,
                            periodoperanio: res.data.dataResponse.periodoPerAnio,
                            periodoanio: res.data.dataResponse.periodoAnio,
                            calculo: true
                        }
                        const cantidad = (resultado < 50000) ? resultado : 50000;
                        servicioNuevoReporteNominas(
                            data, 0, cantidad, cantidad, resultado-cantidad, null, null, null, null, null, null, errorTable, setIsLoading
                        );
                    } else {
                        errorTable('No hay datos que descargar')
                        setIsLoading(false);
                    }
                    break;
                default:
                    errorTable(res.data.msg);
                    setIsLoading(false);
                    break;
            }
        })
        .catch(error =>{ errorTable(verifique_conexion); setIsLoading(false); });
    };

    const confirmaRetrocederPeriodo = () => {
        if(acumuladosSelected.length > 0) {
            setAlertError(true);
        } else {
            setAlertWarningType(1);
            setAlertWarning(true);
        }
    };

    const confirmaAvanzarPeriodo = () => {
        if(acumuladosSelected.length > 0) {
            setAlertError(true);
        } else {
            setAlertWarningType(2);
            setAlertWarning(true);
        }
    };

    const retrocederPeriodo = () => {
        setIsLoading(true);
        const request = {
            nomina: elementSelect.id
        };

        axios.post(`${servicios_grupohit_nomina}/procesos/eliminaPeriodo`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPeriodo(null);
                    setAcumuladosSelected([]);
                    actualizaPeriodos();
                    setEjecutarServicioUltimoPeriodo(true);
                    successTable(res.data.msg);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => setIsLoading(false));
    };

    const avanzarPeriodo = () => {
        setIsLoading(true);
        const request = {
            acumulado: 1,
            fechaPago: periodo.periodoFecfin,
            periodo: periodo.id
        };

        axios.post(`${servicios_grupohit_nomina}/procesos/saltarPeriodo`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPeriodo(null);
                    setAcumuladosSelected([]);
                    actualizaPeriodos();
                    setEjecutarServicioUltimoPeriodo(true);
                    successTable(res.data.msg);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => setIsLoading(false));;
    };

    return (
        <ModalService
            title="Cálculo de Nómina"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <InfoNomina
                        ejecutarServicioUltimoPeriodo={ejecutarServicioUltimoPeriodo}
                        setEjecutarServicioUltimoPeriodo={setEjecutarServicioUltimoPeriodo}
                        setEjecutarServicioProcesos={setEjecutarServicioProcesos}
                        elementSelect={elementSelect}
                        periodo={periodo}
                        setPeriodo={setPeriodo}
                        setOpenEdit={setOpenEdit}
                        confirmaRetrocederPeriodo={confirmaRetrocederPeriodo}
                        confirmaAvanzarPeriodo={confirmaAvanzarPeriodo}
                    />
                    <hr/>
                    <FormCalculo
                        nombreModulo={nombreModulo}
                        ejecutarServicioProcesos={ejecutarServicioProcesos}
                        setEjecutarServicioProcesos={setEjecutarServicioProcesos}
                        elementSelect={elementSelect}
                        acumuladosSelected={acumuladosSelected}
                        setAcumuladosSelected={setAcumuladosSelected}
                        tiposAcumulado={tiposAcumulado}
                        periodo={periodo}
                        error={error}
                        setError={setError}
                        errorTable={errorTable}
                        successTable={successTable}
                        permisos={permisos}
                        setOpenEdit={setOpenEdit}
                    />
                </div>
            </div>
            <AlertError show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text="Para poder continuar, es necesario eliminar los acumulados procesados" textbtnconfirm="Aceptar" type="error"/>
            <AlertWarning show={alertWarning} SetopenALert={setAlertWarning} title={obtieneType().title} text={obtieneType().text} textButtonAceptar="Continuar" textButtonCancelar="Cancelar" action={obtieneType().function}/>
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}
                textTitle="Descarga de Acumulados en proceso..."
            />
        </ModalService>
    );
}

const mapDispatchToProps = {
    limpiarPeriodoActual,
    obtenerPeriodoActual
};

export default connect(null, mapDispatchToProps)(ModalAddCalculo);