import React from 'react';

const TimbradoCanceladoExitoso = ({ type }) => {
    return (
        <div className="text-center" style={{ color: '#747474' }}>
            <p><strong>CVE - INTERNA</strong></p>
            <p><strong>Año:</strong>{' '}2020{' '}<strong>Periodo:</strong>{' '}9</p>
            <p><strong>Tipo de Acumulado:</strong>{' '}Nómina</p>
            <p>{`Total de comprobantes ${type} correctamente: 20`}</p>
        </div>
    );
}
 
export default TimbradoCanceladoExitoso;