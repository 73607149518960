import React, { useState, createRef, useEffect, Fragment } from 'react';
import ModalService from './../../../ModalService/ModalService';
import InfoEmpleados from './InfoEmpleados';
import FormAcumulados from './FormAcumulados';
import TableAcumulados from './TableAcumulados';

const ModalAcumulados = ({ id, openModalForm, setOpenModalForm, elementSelect, informacionAdicional }) => {

    const [error, setError] = useState(null);
    const [muestraTable, setMuestraTable] = useState(false);
    const [nominas, setNominas] = useState([]);
    const [anios, setAnios] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [acumulados, setAcumulados] = useState([]);
    const [periodo, setPeriodo] = useState({ id: '', nombre: '' });

    const { permisos } = informacionAdicional;

    const nominaRef = createRef('');
    const anioRef = createRef('');
    const periodoRef = createRef('');
    const acumuladoRef = createRef('');
    
    useEffect(() => {
        if(periodoRef.current) {
            const periosoSelected = periodos.find(periodo => periodo.id === periodoRef.current.value);
            setPeriodo(periosoSelected);
        }
    }, [periodoRef, periodos]);

    const cerrarModal = () => {
        setOpenModalForm(false);
        setMuestraTable(false);
        setError(null);
    };

    return (
        <ModalService
            title="Consultar Acumulados"
            subtitle={<InfoEmpleados elementSelect={elementSelect}/>}
            parentNode={id}
            isOpen={openModalForm}
            onClose={cerrarModal}
            maxWidth="90%"
            minWidth="90%"
        >
            <div className="row">
                <div className="col-12">
                    <FormAcumulados
                        elementSelect={elementSelect}
                        nominas={nominas}
                        setNominas={setNominas}
                        nominaRef={nominaRef}
                        anios={anios}
                        setAnios={setAnios}
                        anioRef={anioRef}
                        periodos={periodos}
                        setPeriodos={setPeriodos}
                        periodoRef={periodoRef}
                        acumulados={acumulados}
                        setAcumulados={setAcumulados}
                        acumuladoRef={acumuladoRef}
                        setMuestraTable={setMuestraTable}
                        error={error}
                        setError={setError}
                    />
                    {   (muestraTable) &&
                        <Fragment>
                            <hr/>
                            <TableAcumulados
                                id={id}
                                elementSelect={elementSelect}
                                anioRef={anioRef}
                                periodo={periodo}
                                permisos={permisos}
                                acumuladoRef={acumuladoRef}
                            />
                        </Fragment>
                    }
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalAcumulados;