import React, { createRef, useState } from 'react';
import {Button, Switch, createMuiTheme, Tooltip, MuiThemeProvider} from '@material-ui/core';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});


const FormNiveles = ({ error, setError, enviarNivel, elementSelect, actualizarNivel, isLoading }) => {
    
    const claveRef = createRef('');
    const [toogle, setToogle] =useState(elementSelect ? (elementSelect.statusNom==='ACTIVA') ? true : false : true);
    const [openAlert, setOpenAlert] = useState(false);

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarNivel')) {
            const nivelCve = claveRef.current.value
            enviarNivel( { nivelCve } );
        }
    };

    const actualizar = () => { 
        if(validarSubmit('EditarNivel')) {
            const nivelCve = claveRef.current.value;
            const nivelStatus = toogle ? 1 : 2 ;
            setError(null);
            actualizarNivel( { nivelCve, nivelStatus }, elementSelect.id );
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };
    
    return (  
        <form id={ elementSelect ? 'EditarNivel' : 'AgregarNivel' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
            { elementSelect
            ?   <div className="row">
                    <div className="col-md-8">
                        <InputValidation title="Clave" type="claveNivel" placeholder="Escriba la clave del nivel"
                            refValue={claveRef} defaultValue={ elementSelect ? elementSelect.nivlaboralClave : '' } 
                            tooltipText={'Máximo 5 letras y/o números'} maxLength={5} 
                            required alfanumeric
                        />
                    </div>               
                    <div className="col-md-4">
                        <div className="form-group text-center">
                            <label>Inactivo / Activo</label>
                            </div>
                            <div className="text-center">
                            <MuiThemeProvider theme={theme}>
                                <Tooltip title="Modificar Status">
                                    <Switch
                                        checked={toogle}
                                        onChange={() => setToogle(!toogle)}
                                        value="checkedA"
                                        size="small"
                                    />
                                </Tooltip>
                            </MuiThemeProvider>
                        </div>  
                    </div>
                </div>
            :   <InputValidation title="Clave" type="claveNivel" placeholder="Escriba la clave del nivel"
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.nivlaboralClave : '' } 
                    tooltipText={'Máximo 5 letras y/o números'} maxLength={5} required alfanumeric
                />
            }
            {error}
            {(isLoading)
            ?   <SpinnerOval/>
            :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </form>   
    );
}
export default FormNiveles;
