import React, { useState, useEffect, Fragment, useRef } from 'react';
import Axios from 'axios';
import BtnRemoveItem from '../../../Modulos/RecursosHumanos/Empleados/BtnRemoveItem';
import BtnEditItem from '../../../Modulos/RecursosHumanos/Empleados/BtnEditItem';
import { amountToFloat, floatToAmount } from '../../../../../../Services/Validation/HelperValidation'; 
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import AlertForm from '../../../../../../Services/AlertForm';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import { Save } from '@material-ui/icons';

const FormularioReciboPago = ({ error, elementSelect, busquedaAvanzada, pagosUnicos, indicadoresSelected, setIndicadoresSelected, setPercepcionesActivas, setDeduccionesActivas, setTotalP, setTotalD, montoMinimo }) => {
    const [percepciones, setPercepciones] = useState(null);
    const [deducciones, setDeducciones] = useState(null);
    const [deduccionesSimulador, setDeduccionesSimulador] = useState(null);
    const [percepcionesSimulador, setPercepcionesSimulador] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
    const [elementEdit, setElementEdit] = useState({ id: null, idName: null });
    const [openAlertSyEPU, setOpenAlertSyEPU] = useState(false);

    const nuevoMontoRef = useRef('');

    useEffect(() => {
        if(setPercepcionesActivas && percepciones) {
            const percepcionesOrdenadas = percepciones.sort((a, b) => parseInt(a.nominaClave) > parseInt(b.nominaClave) ? 1 : parseInt(a.nominaClave) < parseInt(b.nominaClave) ? -1 : 0);
            setPercepcionesActivas(percepcionesOrdenadas);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percepciones]);

    useEffect(() => {
        if(setDeduccionesActivas && deducciones) {
            const deduccionesOrdenadas = deducciones.sort((a, b) => parseInt(a.nominaClave) > parseInt(b.nominaClave) ? 1 : parseInt(a.nominaClave) < parseInt(b.nominaClave) ? -1 : 0)
            setDeduccionesActivas(deduccionesOrdenadas);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deducciones]);

    useEffect(() => {
        Axios.get(`${servicios_grupohit_nomina}/acumulados/${elementSelect.empleadoId}/${elementSelect.periodoId}/${elementSelect.acumuladoId ? elementSelect.acumuladoId : 1}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(indicadoresSelected) {
                        setIndicadoresSelected([]);
                        const indicadores = res.data.dataResponse.map((indicador, key) => { indicador.idRemove = key+1; return indicador; });
                        setPercepcionesSimulador(indicadores.filter(element => element.tiponatuId === 1));
                        setDeduccionesSimulador(indicadores.filter(element => element.tiponatuId === 2));
                    } else {
                        setPercepciones(res.data.dataResponse.filter(element => element.tiponatuId === 1));
                        setDeducciones(res.data.dataResponse.filter(element => element.tiponatuId === 2));
                    }
                    break; 
                default:
                    setPercepciones([]);
                    setDeducciones([]);
                    if(indicadoresSelected) {
                        setDeduccionesSimulador([]);
                        setPercepcionesSimulador([]);
                    }
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(indicadoresSelected && deduccionesSimulador) {          
            setDeducciones(deduccionesSimulador.concat(indicadoresSelected.filter(ind => ind.tiponatuId === 2)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deduccionesSimulador, indicadoresSelected]);

    useEffect(() => {
        if(indicadoresSelected && percepcionesSimulador) {
            setPercepciones(percepcionesSimulador.concat(indicadoresSelected.filter(ind => ind.tiponatuId === 1)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percepcionesSimulador, indicadoresSelected]);

    const limpiaElementEdit = () => {
        setElementEdit({ id: null, idName: null });
    };

    const eliminaIndicadoresSelected = newArray => {
        setIndicadoresSelected(newArray);
        limpiaElementEdit();
    };

    const eliminaPercepciones = newArray => {
        setPercepcionesSimulador(newArray);
        limpiaElementEdit();
    };

    const eliminaDeducciones = newArray => {
        setDeduccionesSimulador(newArray);
        limpiaElementEdit();
    };

    const obtieneTotalPer = () => {
        if(percepcionesSimulador.length > 0) {
            const montoPercepciones = percepcionesSimulador.concat(indicadoresSelected.filter(i => i.tiponatuId === 1))
            .reduce((total, indicador) => ( { monto: total.monto + indicador.monto } )).monto;
            
            return montoPercepciones;
        }

        return 0;
    };

    const obtieneTotalDed = () => {
        if(deduccionesSimulador.length > 0) {
            const montoDeducciones = deduccionesSimulador.concat(indicadoresSelected.filter(i => i.tiponatuId === 2))
            .reduce((total, indicador) => ( { monto: total.monto + indicador.monto } )).monto;
        
            return montoDeducciones;
        }

        return 0;
    };

    const editaMontoPercepcionesIndicadoresSelected = e => {
        e.preventDefault();
        const element = indicadoresSelected.find(e => e.idDelete === elementEdit.id);
        const nuevoMonto = amountToFloat(nuevoMontoRef.current.value);
        const montoTotalPercepciones = obtieneTotalPer();
        const montoTotalDeducciones = obtieneTotalDed();
        if((montoTotalPercepciones-montoTotalDeducciones-element.monto+nuevoMonto) > 0) {
            element.monto = nuevoMonto;
            const newArray = indicadoresSelected.map(e => 
                (e.idDelete === elementEdit.id)
                ? element
                : e
            );
            setIndicadoresSelected(newArray);
            limpiaElementEdit();
            return;
        }
        setOpenAlertSyEPU(true);
    };

    const editaMontoDeduccionesIndicadoresSelected = e => {
        e.preventDefault();
        const element = indicadoresSelected.find(e => e.idDelete === elementEdit.id);
        const nuevoMonto = amountToFloat(nuevoMontoRef.current.value);
        const montoTotalPercepciones = obtieneTotalPer();
        const montoTotalDeducciones = obtieneTotalDed();
        if((montoTotalPercepciones-montoTotalDeducciones+element.monto-nuevoMonto) > 0) {
            element.monto = nuevoMonto;
            const newArray = indicadoresSelected.map(e => 
                (e.idDelete === elementEdit.id)
                ? element
                : e
            );
            setIndicadoresSelected(newArray);
            limpiaElementEdit();
            return;
        }
        setOpenAlertSyEPU(true);
    };

    const editaMontoPercepciones = e => {
        e.preventDefault();
        const element = percepcionesSimulador.find(e => e.idRemove === elementEdit.id);
        const nuevoMonto = amountToFloat(nuevoMontoRef.current.value);
        const montoTotalPercepciones = obtieneTotalPer();
        const montoTotalDeducciones = obtieneTotalDed();
        if((montoTotalPercepciones-montoTotalDeducciones-element.monto+nuevoMonto) > 0) {
            element.monto = nuevoMonto;
            const newArray = percepcionesSimulador.map(e => 
                (e.idRemove === elementEdit.id)
                ? element
                : e
            );
            setPercepcionesSimulador(newArray);
            limpiaElementEdit();
            return;
        }
        setOpenAlertSyEPU(true);
    };

    const editaMontoDeducciones = e => {
        e.preventDefault();
        const element = deduccionesSimulador.find(e => e.idRemove === elementEdit.id);
        const nuevoMonto = amountToFloat(nuevoMontoRef.current.value);
        const montoTotalPercepciones = obtieneTotalPer();
        const montoTotalDeducciones = obtieneTotalDed();
        if((montoTotalPercepciones-montoTotalDeducciones+element.monto-nuevoMonto) > 0) {
            element.monto = nuevoMonto;
            const newArray = deduccionesSimulador.map(e => 
                (e.idRemove === elementEdit.id)
                ? element
                : e
            );
            setDeduccionesSimulador(newArray);
            limpiaElementEdit();
            return;
        }
        setOpenAlertSyEPU(true);
    };

    const muestraInputMontoIndicadoresSelected = element => {
        setElementEdit({ id: element.idDelete, idName: 'idDelete' });
    };

    const muestraInputMontoPercepDeducSimulador = element => {
        setElementEdit({ id: element.idRemove, idName: 'idRemove' });
    };

    const getAcumuladosEmpleado=()=>{ 
        let res = [];  
        let totalPercepciones = 0;
        let totalDeducciones = 0;
        if( percepciones.length >= deducciones.length ) {
            percepciones.map((key, index) => { 

                const agregarNumPagosDeduccion = deducciones[index] ? deducciones[index].numPago !== 0 && deducciones[index].empprestNumpagos !== 0 : false;
                const agregarNumPagosPercepcion = key.numPago !== 0 && key.empprestNumpagos !==0;
                const agregarCantidadDeduccion = deducciones[index] ? deducciones[index].cantidad !== 1 : false;
                const agregarCantidadPercepcion = key.cantidad!==1;

                totalPercepciones += key.monto;
                totalDeducciones += deducciones[index] ? deducciones[index].monto : 0;

                res.push(<tr key={index} >
                            <td key={`${index}-percepcion`} style={{paddingTop:25}}> 
                                {   percepciones[index]
                                    ?   agregarNumPagosPercepcion
                                        ?   <Fragment>
                                            {
                                                (percepciones[index].idDelete) && <BtnRemoveItem id={percepciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador /> 
                                            }{
                                                (percepciones[index].idRemove) && <BtnRemoveItem id={percepciones[index].idRemove} idArray="idRemove" array={percepciones} action={eliminaPercepciones} simulador montoMinimo={montoMinimo} totalPD={((percepciones) ? percepciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)-((deducciones) ? deducciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)}/> 
                                            }{
                                                `${key.nominaClave}  ${key.nominaIndicador} (${key.numPago}/${key.empprestNumpagos}) ${agregarCantidadPercepcion ? '('+ key.cantidad + ')' : '' }`
                                            }
                                            </Fragment>
                                        :   <Fragment>
                                            {
                                                (percepciones[index].idDelete) && <BtnRemoveItem id={percepciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador /> 
                                            }{
                                                (percepciones[index].idRemove) && <BtnRemoveItem id={percepciones[index].idRemove} idArray="idRemove" array={percepcionesSimulador} action={eliminaPercepciones} simulador montoMinimo={montoMinimo} totalPD={((percepciones.length>0) ? percepciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)-((deducciones.length>0) ? deducciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)}/> 
                                            }{
                                                `${key.nominaClave}  ${key.nominaIndicador} ${agregarCantidadPercepcion ? '('+ key.cantidad + ')' : '' }` 
                                            }
                                            </Fragment>
                                    :   ''
                                }
                            </td>
                            <td key={`${index}-percepcionImporte`} style={{textAlign:"right", paddingInlineEnd:'6.25rem', paddingTop:25}}>
                                {(pagosUnicos && (percepciones[index].idDelete || percepciones[index].idRemove) && elementEdit.id !== null && elementEdit.idName !== null)
                                ?   (percepciones[index].idDelete && (elementEdit.id === percepciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   <div className='row'>
                                            <div className='col-4'></div>
                                            <div className="col-6">
                                                <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                    refValue={nuevoMontoRef} defaultValue={floatToAmount(key.monto, 2)}
                                                    onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                />
                                            </div>
                                            <div className='col-2 align-self-start'>
                                                <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                    <button className="btn-autocomplete-recpago" onClick={editaMontoPercepcionesIndicadoresSelected}>
                                                        <Save/>
                                                    </button>
                                                </TooltipComponent>
                                            </div>
                                        </div>
                                    :   (percepciones[index].idRemove && (elementEdit.id === percepciones[index].idRemove) &&(elementEdit.idName === 'idRemove'))
                                        ?   <div className='row'>
                                                <div className='col-4'></div>
                                                <div className="col-6">
                                                    <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                        refValue={nuevoMontoRef} defaultValue={floatToAmount(key.monto, 2)}
                                                        onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                    />
                                                </div>
                                                <div className='col-2 align-self-start'>
                                                    <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                        <button className="btn-autocomplete-recpago" onClick={editaMontoPercepciones}><Save/></button>
                                                    </TooltipComponent>
                                                </div>
                                            </div>
                                        :   <strong>  {`${ floatToAmount(key.monto, 2) }`} </strong>
                                :   <strong>  {`${ floatToAmount(key.monto, 2) }`} </strong>
                                }
                                {(pagosUnicos && percepciones[index].idDelete)
                                ?   ((elementEdit.id === percepciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={percepciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={muestraInputMontoIndicadoresSelected}/>
                                :   null
                                }
                                {(pagosUnicos && percepciones[index].idRemove)
                                ?   ((elementEdit.id === percepciones[index].idRemove) && (elementEdit.idName === 'idRemove'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={percepciones[index].idRemove} idArray="idRemove" array={percepcionesSimulador} action={muestraInputMontoPercepDeducSimulador}/>
                                :   null
                                }
                            </td>
                            <td key={`${index}-deduccion`} style={{paddingInlineStart:'6.25rem', paddingTop:25}}> 
                                {   deducciones[index]
                                    ?   agregarNumPagosDeduccion
                                        ?   <Fragment>
                                            {
                                                (deducciones[index].idDelete) && <BtnRemoveItem id={deducciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador /> 
                                            }{
                                                (deducciones[index].idRemove) && <BtnRemoveItem id={deducciones[index].idRemove} idArray="idRemove" array={deduccionesSimulador} action={eliminaDeducciones} simulador /> 
                                            }{
                                                `${deducciones[index].nominaClave} ${deducciones[index].nominaIndicador} (${deducciones[index].numPago}/${deducciones[index].empprestNumpagos}) ${agregarCantidadDeduccion ? '('+ deducciones[index].cantidad + ')' : '' }`
                                            }
                                            </Fragment>   
                                        :   <Fragment>
                                            {
                                                (deducciones[index].idDelete) && <BtnRemoveItem id={deducciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador /> 
                                            }{
                                                (deducciones[index].idRemove) && <BtnRemoveItem id={deducciones[index].idRemove} idArray="idRemove" array={deduccionesSimulador} action={eliminaDeducciones} simulador /> 
                                            }{
                                                `${deducciones[index].nominaClave} ${deducciones[index].nominaIndicador} ${agregarCantidadDeduccion ? '('+ deducciones[index].cantidad + ')' : '' }`  
                                            }
                                            </Fragment>
                                    :   ''
                                }
                            </td>
                            <td key={`${index}-deduccionImporte`} style={{textAlign:"right", paddingTop:25}}> 
                                {(pagosUnicos && deducciones[index] && (deducciones[index].idDelete || deducciones[index].idRemove) && elementEdit.id !== null && elementEdit.idName !== null)
                                ?   (deducciones[index].idDelete && (elementEdit.id === deducciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   <div className='row'>
                                            <div className='col-4'></div>
                                            <div className="col-6">
                                                <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                    refValue={nuevoMontoRef} defaultValue={floatToAmount(deducciones[index].monto, 2)}
                                                    onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                />
                                            </div>
                                            <div className='col-2 align-self-start'>
                                                <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                    <button className="btn-autocomplete-recpago" onClick={editaMontoDeduccionesIndicadoresSelected}>
                                                        <Save/>
                                                    </button>
                                                </TooltipComponent>
                                            </div>
                                        </div>
                                    :   (deducciones[index].idRemove && (elementEdit.id === deducciones[index].idRemove) && (elementEdit.idName === 'idRemove'))
                                        ?   <div className='row'>
                                                <div className='col-4'></div>
                                                <div className="col-6">
                                                    <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                        refValue={nuevoMontoRef} defaultValue={floatToAmount(deducciones[index].monto, 2)}
                                                        onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                    />
                                                </div>
                                                <div className='col-2 align-self-start'>
                                                    <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                        <button className="btn-autocomplete-recpago" onClick={editaMontoDeducciones}><Save/></button>
                                                    </TooltipComponent>
                                                </div>
                                            </div>
                                        :   <strong> {deducciones[index] ? `${floatToAmount(deducciones[index].monto, 2)}` : ''} </strong>
                                :   <strong> {deducciones[index] ? `${floatToAmount(deducciones[index].monto, 2)}` : ''} </strong>
                                }
                                {(pagosUnicos && deducciones[index] && deducciones[index].idDelete)
                                ?   ((elementEdit.id === deducciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={deducciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={muestraInputMontoIndicadoresSelected}/>
                                :   null
                                }
                                {(pagosUnicos && deducciones[index] && deducciones[index].idRemove)
                                ?   ((elementEdit.id === deducciones[index].idRemove) && (elementEdit.idName === 'idRemove'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={deducciones[index].idRemove} idArray="idRemove" array={deduccionesSimulador} action={muestraInputMontoPercepDeducSimulador}/>
                                :   null
                                }
                            </td>
                            <td key={`${index}-total`} style={{textAlign:"right", paddingTop:25}}></td>
                        </tr>); 
                return null;
            });
        } else {
            deducciones.map((key, index)=>{

                const agregarNumPagosPercepcion = percepciones[index] ? percepciones[index].numPago !== 0 && percepciones[index].empprestNumpagos !== 0 : false;
                const agregarNumPagosDeduccion = key.numPago !== 0 && key.empprestNumpagos !==0; 

                const agregarCantidadPercepcion = percepciones[index] ? percepciones[index].cantidad !== 1 : false;
                const agregarCantidadDeduccion = key.cantidad!==1;

                totalPercepciones += percepciones[index] ? percepciones[index].monto : 0;
                totalDeducciones += key.monto;

                res.push(<tr key={index}>
                            <td key={`${index}-percepcion`} style={{paddingTop:25}}> 
                                {   percepciones[index]
                                    ?   agregarNumPagosPercepcion
                                        ?   <Fragment>
                                            {
                                                (percepciones[index].idDelete) && <BtnRemoveItem id={percepciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador /> 
                                            }{
                                                (percepciones[index].idRemove) && <BtnRemoveItem id={percepciones[index].idRemove} idArray="idRemove" array={percepcionesSimulador} action={eliminaPercepciones} simulador montoMinimo={montoMinimo} totalPD={((percepciones) ? percepciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)-((deducciones) ? deducciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)}/> 
                                            }{
                                                `${percepciones[index].nominaClave}  ${percepciones[index].nominaIndicador} (${percepciones[index].numPago}/${percepciones[index].empprestNumpagos}) ${agregarCantidadPercepcion ? '('+ percepciones[index].cantidad + ')' : '' }`
                                            }
                                            </Fragment>
                                        :   <Fragment>
                                            {
                                                (percepciones[index].idDelete) && <BtnRemoveItem id={percepciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador /> 
                                            }{
                                                (percepciones[index].idRemove) && <BtnRemoveItem id={percepciones[index].idRemove} idArray="idRemove" array={percepcionesSimulador} action={eliminaPercepciones} simulador montoMinimo={montoMinimo} totalPD={((percepciones) ? percepciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)-((deducciones) ? deducciones.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0)}/> 
                                            }{
                                                `${percepciones[index].nominaClave}  ${percepciones[index].nominaIndicador} ${agregarCantidadPercepcion ? '('+ percepciones[index].cantidad + ')' : '' }` 
                                            }
                                            </Fragment>
                                    :   ''
                                }
                            </td>
                            <td key={`${index}-percepcionImporte`} style={{textAlign:"right", paddingInlineEnd:'6.25rem', paddingTop:25}}> 
                                {/*<strong>  {percepciones[index] ? `${ agregarCantidadPercepcion ? floatToAmount(percepciones[index].monto, 2) : ''}` :''} </strong>*/}
                                {(pagosUnicos && percepciones[index] && (percepciones[index].idDelete || percepciones[index].idRemove) && elementEdit.id !== null && elementEdit.idName !== null)
                                ?   (percepciones[index].idDelete && (elementEdit.id === percepciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   <div className='row'>
                                            <div className='col-4'></div>
                                            <div className="col-6">
                                                <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                    refValue={nuevoMontoRef} defaultValue={floatToAmount(percepciones[index].monto, 2)}
                                                    onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                />
                                            </div>
                                            <div className='col-2 align-self-start'>
                                                <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                    <button className="btn-autocomplete-recpago" onClick={editaMontoPercepcionesIndicadoresSelected}>
                                                        <Save/>
                                                    </button>
                                                </TooltipComponent>
                                            </div>
                                        </div>
                                    :   (percepciones[index].idRemove && (elementEdit.id === percepciones[index].idRemove) && (elementEdit.idName === 'idRemove'))
                                        ?   <div className='row'>
                                                <div className='col-4'></div>
                                                <div className="col-6">
                                                    <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                        refValue={nuevoMontoRef} defaultValue={floatToAmount(percepciones[index].monto, 2)}
                                                        onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                    />
                                                </div>
                                                <div className='col-2 align-self-start'>
                                                    <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                        <button className="btn-autocomplete-recpago" onClick={editaMontoPercepciones}><Save/></button>
                                                    </TooltipComponent>
                                                </div>
                                            </div>
                                        :   <strong>{percepciones[index] ? `${floatToAmount(percepciones[index].monto, 2)}` : ''}</strong>
                                :   <strong>{percepciones[index] ? `${floatToAmount(percepciones[index].monto, 2)}` : ''}</strong>
                                }
                                {(pagosUnicos && percepciones[index] && percepciones[index].idDelete)
                                ?   ((elementEdit.id === percepciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={percepciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={muestraInputMontoIndicadoresSelected}/>
                                :   null
                                }
                                {(pagosUnicos && percepciones[index] && percepciones[index].idRemove)
                                ?   ((elementEdit.id === percepciones[index].idRemove) && (elementEdit.idName === 'idRemove'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={percepciones[index].idRemove} idArray="idRemove" array={percepcionesSimulador} action={muestraInputMontoPercepDeducSimulador}/>
                                :   null
                                }
                            </td>
                            <td key={`${index}-deduccion`} style={{paddingInlineStart:'6.25rem', paddingTop:25}} >
                                {   agregarNumPagosDeduccion
                                    ?   <Fragment>
                                        {
                                            (deducciones[index].idDelete) && <BtnRemoveItem id={deducciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador />
                                        }{
                                            (deducciones[index].idRemove) && <BtnRemoveItem id={deducciones[index].idRemove} idArray="idRemove" array={deduccionesSimulador} action={eliminaDeducciones} simulador /> 
                                        }{
                                            `${key.nominaClave}  ${key.nominaIndicador} (${key.numPago}/${key.empprestNumpagos}) ${agregarCantidadDeduccion ? '('+ key.cantidad + ')' : '' }`
                                        }
                                        </Fragment>  
                                    :   <Fragment>
                                        {
                                            (deducciones[index].idDelete) && <BtnRemoveItem id={deducciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={eliminaIndicadoresSelected} simulador />
                                        }{
                                            (deducciones[index].idRemove) && <BtnRemoveItem id={deducciones[index].idRemove} idArray="idRemove" array={deduccionesSimulador} action={eliminaDeducciones} simulador /> 
                                        }{
                                            `${key.nominaClave}  ${key.nominaIndicador} ${agregarCantidadDeduccion ? '('+ key.cantidad + ')' : '' }` 
                                        }
                                        </Fragment>
                                }
                            </td>
                            <td key={`${index}-deduccionImporte`} style={{textAlign:"right", paddingTop:25}}> 
                                {(pagosUnicos && (deducciones[index].idDelete || deducciones[index].idRemove) && elementEdit.id !== null && elementEdit.idName !== null)
                                ?   (deducciones[index].idDelete && (elementEdit.id === deducciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   <div className='row'>
                                            <div className='col-4'></div>
                                            <div className="col-6">
                                                <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                    refValue={nuevoMontoRef} defaultValue={floatToAmount(key.monto, 2)}
                                                    onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                />
                                            </div>
                                            <div className='col-2 align-self-start'>
                                                <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                    <button className="btn-autocomplete-recpago" onClick={editaMontoDeduccionesIndicadoresSelected}>
                                                        <Save/>
                                                    </button>
                                                </TooltipComponent>
                                            </div>
                                        </div>
                                    :   (deducciones[index].idRemove && (elementEdit.id === deducciones[index].idRemove) && (elementEdit.idName === 'idRemove'))
                                        ?   <div className='row'>
                                                <div className='col-4'></div>
                                                <div className="col-6">
                                                    <InputValidation title="" type="monto" tooltipText="Nuevo Monto del Concepto"
                                                        refValue={nuevoMontoRef} defaultValue={floatToAmount(key.monto, 2)}
                                                        onBlurDecimal={2} onlyNumbers="." maxLength={9} required isAmount
                                                    />
                                                </div>
                                                <div className='col-2 align-self-start'>
                                                    <TooltipComponent tooltipText="Guardar nuevo monto de concepto">
                                                        <button className="btn-autocomplete-recpago" onClick={editaMontoDeducciones}><Save/></button>
                                                    </TooltipComponent>
                                                </div>
                                            </div>
                                        :   <strong> {`${floatToAmount(key.monto, 2)}`} </strong>
                                :   <strong> {`${floatToAmount(key.monto, 2)}`} </strong>
                                }
                                {(pagosUnicos && deducciones[index].idDelete)
                                ?   ((elementEdit.id ===  deducciones[index].idDelete) && (elementEdit.idName === 'idDelete'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={deducciones[index].idDelete} idArray="idDelete" array={indicadoresSelected} action={muestraInputMontoIndicadoresSelected}/>
                                :   null
                                }
                                {(pagosUnicos && deducciones[index].idRemove)
                                ?   ((elementEdit.id === deducciones[index].idRemove) && (elementEdit.idName === 'idRemove'))
                                    ?   null
                                    :   <BtnEditItem style={{ right: '0px', position: 'inherit' }} id={deducciones[index].idRemove} idArray="idRemove" array={deduccionesSimulador} action={muestraInputMontoPercepDeducSimulador}/>
                                :   null
                                }
                            </td>
                            <td key={`${index}-total`} style={{textAlign:"right", paddingTop:25}}></td>
                        </tr>); 
                return null;
            });
        }
        
        if(setTotalP && setTotalD) {
            setTotalP(totalPercepciones);
            setTotalD(totalDeducciones);
        }
        
        res.push(<tr key={'separacion'}>
            <td key={`separacion-percepcion`} style={{paddingTop:25}}> </td>
            <td key={`separacion-percepcionImporte`} style={{textAlign:"right", paddingInlineEnd:'6.25rem', paddingTop:25}}> </td>
            <td key={`separacion-deduccion`} style={{paddingInlineStart:'6.25rem', paddingTop:25}} > </td>
            <td key={`separacion-deduccionImporte`} style={{textAlign:"right", paddingTop:25}}> </td>
            <td key={`separacion-total`} style={{textAlign:"right", paddingTop:25}}></td>
        </tr>
        );
        res.push(<tr key={'total'}>
            <td key={`total-percepcion`} style={{paddingTop:25}}> <strong>{"Total Percepciones"}</strong> </td>
            <td key={`total-percepcionImporte`} style={{textAlign:"right", paddingInlineEnd:'6.25rem', paddingTop:25}}><strong>{floatToAmount(totalPercepciones, 2)}</strong>  </td>
            <td key={`total-deduccion`} style={{paddingInlineStart:'6.25rem', paddingTop:25}} > <strong>{"Total Deducciones"}</strong>  </td>
            <td key={`total-deduccionImporte`} style={{textAlign:"right", paddingTop:25}}><strong> {floatToAmount(totalDeducciones, 2)} </strong>  </td>
            <td key={`total-total`} style={{textAlign:"right", paddingTop:25}}> <strong> {floatToAmount( totalPercepciones - totalDeducciones, 2)} </strong>  </td>
        </tr>
        );

        return res;
    }

    return (
        <Fragment>
            <form id={ 'ReciboDePago' } /* onSubmit={} */>
                {(!busquedaAvanzada && !pagosUnicos) &&
                    <Fragment>
                        <div className="row" style={{paddingBottom:10}}>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"Número Único de Empleado: "}</strong>{elementSelect.empleadoClave}
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"Clave Presupuestal: "}</strong>{elementSelect.cpresupuestalClave}
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"Empleado: "}</strong>{elementSelect.nombreCompleto}
                            </div>
                        </div> 
                        <div className="row" style={{paddingBottom:10}}>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"CURP: "}</strong>{elementSelect.empleadoCurp ? elementSelect.empleadoCurp : "-"}
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"RFC: "}</strong>{elementSelect.empleadoRfc ? elementSelect.empleadoRfc : "-"}
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"Fecha de Pago: "}</strong>{elementSelect.periodoFecfin}
                            </div>
                        </div>
                        <div className="row" style={{paddingBottom:10}}>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"Nómina: "}</strong>{`${elementSelect.nominaNomCorto ? elementSelect.nominaNomCorto:''}`}
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"Número Único de Plaza: "}</strong>{`${elementSelect.plazaClave ? elementSelect.plazaClave :''}`}
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"Periodo: "}</strong>{`${elementSelect.periodoFecini} - ${elementSelect.periodoFecfin}`}
                            </div>
                        </div>
                        <div className="row" style={{paddingBottom:10}}>
                            <div className="col-md-4 col-sm-12">
                                <strong>{"NSS: "}</strong>{elementSelect.empleadoNss ? elementSelect.empleadoNss : "-"}
                            </div>
                            <div className="col-md-8 col-sm-12">
                                <strong>{"Clave y Puesto: "}</strong>{`${elementSelect.puestoCve ? elementSelect.puestoCve: ''} ${elementSelect.puestoNom ? elementSelect.puestoNom : ''}`}
                            </div>
                        </div>
                        <hr/>
                    </Fragment>
                }
                {((busquedaAvanzada || pagosUnicos) && (indicadoresSelected === undefined))
                ?   (percepciones !== null)
                    ?   <div className="row">
                            <div className="col">
                                <AlertForm
                                    info
                                    message={(percepciones[0].status.toUpperCase() === 'CERRADO')
                                        ?   'Los siguientes conceptos e importes ya fueron pagados en el periodo seleccionado.'
                                        :   'Los siguientes conceptos e importes del periodo seleccionado pueden variar, ya que la nómina se encuentra en proceso de cálculo y aún no han sido pagados.'
                                    }
                                />
                            </div>
                        </div>
                    :   null
                :   null
                }
                <table style={{width:"100%", margin:"30px 0px 30px 0px"}}>
                    <thead>
                            <tr>
                                <th><strong>{"Percepciones"}</strong></th>
                                <th style={{textAlign:"right", paddingInlineEnd:'6.25rem'}}><strong>{"Importe"}</strong></th>
                                <th style={{paddingInlineStart:'6.25rem'}}><strong>{"Deducciones"}</strong></th>
                                <th style={{textAlign:"right"}}><strong>{"Importe"}</strong></th>
                                <th style={{textAlign:"right"}}><strong>{"Total"}</strong></th>
                            </tr>
                    </thead>
                    <tbody>
                            {percepciones && deducciones && getAcumuladosEmpleado()} 
                    </tbody>
                </table>
                <hr/>
            </form>
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
            <AlertSyE show={openAlertSyEPU} setOpenAlertSyE={setOpenAlertSyEPU} title="El monto capturado no es válido. El monto total no puede ser negativo." text="" textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
};

export default FormularioReciboPago;