import React, { Fragment, useState } from 'react';
import Tablero from './components/Tablero';
import ModalCancelarTimbrados from './modals/ModalCancelarTimbrados';
import ModalConsultarTimbrados from './modals/ModalConsultarTimbrados';
import ModalDescargarCFDI from './modals/ModalDescargarCFDI';
import ModalTimbrarCFDI from './modals/ModalTimbrarCFDI';
import ModalAddCertificados from './modals/ModalAddCertificados';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';
import TableConsultar from './tables/TableConsultar';
import TableCancelar from './tables/TableCancelar';
import TableActualizarOrigenRecurso from './tables/TableActualizarOrigenRecurso';

const LayoutCFDI = ({ id, permisos }) => {

    const [data, setData] = useState({ title: '', data: [] });
    const [mostrarTablaConsultar, setMostrarTablaConsultar] = useState(false);
    const [mostrarTablaCancelar, setMostrarTablaCancelar] = useState(false);
    const [ mostrarTablaActualizarOrigenRecurso, setMostrarTablaActualizarOrigenRecurso ] = useState( false );
    const [openLoading, setOpenLoading] = useState(false);
    const [openCancelar, setOpenCancelar] = useState(false);
    const [openConsultar, setOpenConsultar] = useState(false);
    const [openDescargar, setOpenDescargar] = useState(false);
    const [openTimbrar, setOpenTimbrar] = useState(false);
    const [openCertificados, setOpenCertificados] = useState(false);


    const { title, data: info } = data;

    const hidenTable = (type) => {
        if(type === 'consultar') setMostrarTablaConsultar(false);
        if(type === 'cancelar') setMostrarTablaCancelar(false);
        if(type === 'actualizar') setMostrarTablaActualizarOrigenRecurso(false);
    };
    
    return (
        <Fragment>
            <div id={`modulo${id}`}>
                {
                    (mostrarTablaConsultar || mostrarTablaActualizarOrigenRecurso )
                    ?   
                        (mostrarTablaActualizarOrigenRecurso) 
                        ?   <TableActualizarOrigenRecurso hidenTable={hidenTable} id={id}/>   
                        :   <TableConsultar id={id} title={title} data={info} hidenTable={hidenTable}/>
                    :   (mostrarTablaCancelar)
                        ?   <TableCancelar title={title} data={info} setData={setData} hidenTable={hidenTable} setOpenLoading={setOpenLoading}
                                setMostrarTablaCancelar={setMostrarTablaCancelar} setMostrarTablaConsultar={setMostrarTablaConsultar}
                            />
                        :   <Fragment>
                                <Tablero
                                    setOpenCancelar={setOpenCancelar} setOpenConsultar={setOpenConsultar}
                                    setOpenDescargar={setOpenDescargar} setOpenTimbrar={setOpenTimbrar} setOpenCertificados={setOpenCertificados} setMostrarTablaActualizarOrigenRecurso={setMostrarTablaActualizarOrigenRecurso}
                                />
                                <ModalCancelarTimbrados nombreModulo={id} open={openCancelar} setOpen={setOpenCancelar} nombre="Cancelar" setOpenLoading={setOpenLoading} setData={setData} setMostrarTabla={setMostrarTablaCancelar}/>
                                <ModalConsultarTimbrados nombreModulo={id} open={openConsultar} setOpen={setOpenConsultar} nombre="Consultar" setOpenLoading={setOpenLoading} setData={setData} setMostrarTabla={setMostrarTablaConsultar}/>
                                <ModalDescargarCFDI nombreModulo={id} open={openDescargar} setOpen={setOpenDescargar} nombre="Descargar" setOpenLoading={setOpenLoading}/>
                                <ModalTimbrarCFDI nombreModulo={id} open={openTimbrar} setOpen={setOpenTimbrar} nombre="Timbrar" setOpenLoading={setOpenLoading} setData={setData} setMostrarTabla={setMostrarTablaConsultar}/>
                                <ModalAddCertificados nombreModulo={id} open={openCertificados} setOpen={setOpenCertificados} nombre="Certificados"/>
                            </Fragment>
                }
            </div>
            <ModalLoading nombreModulo={id} open={openLoading} setOpen={setOpenLoading}/>
        </Fragment>
    );
}
 
export default LayoutCFDI;