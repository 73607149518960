import React from 'react';
import InputText from '../../../../../../../Services/Components/DataTable/InputText';
import DataTableService from '../../../../DataTableService/DataTableService';

const EmpleadosTable = ({ id, permisos, empleados, mostrarTablaAusentismos, informacion }) => {
    return (
        <DataTableService
            id={id}
            title="Empleados"
            columns={[
                { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                { field: "nombreCompleto", title: "Nombre" },
                { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
                { field: "empleadoRfc", title: "RFC" },
                { field: "empleadoNss", title: "NSS" },
                { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                { field: "puestoCve", title: "Clave Puesto" },
                { field: "puestoNom", title: "Puesto" },
                { field: "nominaNombre", title: "Nómina" },
                { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
            ]}
            data={empleados}
            customOptionsTable={{ filtering: true }}
            hideIconAdd={id === 31 ? false : true} hideIconDelete hideIconEdit
            showIconNewFolder={id === 31 ? true : false}
            showIconDescription
            onShowTable={mostrarTablaAusentismos}
            permisos={permisos}
            informacionAdicional={informacion}
        />
    );
}
 
export default EmpleadosTable;