import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonPensiones } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormularioPensiones from './FormularioPensiones';

const ModalAddPensiones = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const [isLoading, setIsLoading] = useState(false);
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarPension = ( pension ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_nomina}s/pensiones/`, pension, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonPensiones(respuesta.data.dataResponse) );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable(verifique_conexion);
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Pensión" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioPensiones
                    error={error}
                    informacion={informacionAdicional}
                    enviarPension={enviarPension}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddPensiones;