import React, { Fragment, useState } from 'react';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

const ConceptosList = ({ conceptosxNomina, setConceptosxNomina, conceptosListIsLoading, conceptosSeleccionados, setConceptosSeleccionados, setError }) => {

    const [conceptoSelected, setConceptoSelected] = useState(null);
    const [errorConceptosList, setErrorConceptosList] = useState(false);

    const validarConceptoListSelected = concepto => {
        setConceptoSelected(concepto);
    };

    const agregarConcepto = () => {
        if(conceptoSelected) {
            setError(null);
            setErrorConceptosList(false);
            setConceptosSeleccionados([...conceptosSeleccionados, conceptoSelected]);
            setConceptosxNomina(conceptosxNomina.filter(c => c.id !== conceptoSelected.id));
            setConceptoSelected(null);
            return;
        }
        setErrorConceptosList(true);
    };

    const eliminarConcepto = concepto => {
        setConceptosSeleccionados(conceptosSeleccionados.filter(c => c.id !== concepto.id));
        setConceptosxNomina([concepto, ...conceptosxNomina]);
    };

    return (
        <Fragment>
        {(conceptosListIsLoading)
        ?   <SpinnerOval/>
        :   <Fragment>
                <div className="row">
                    <div className="col">
                        <AutocompleteComponent id="conceptoNomina" title="Concepto"
                            placeholder="Seleccione el Concepto de Nómina" size="100%"
                            tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                            options={conceptosxNomina} optionListView="indicadorNom" optionKey="indicadorClave"
                            value={conceptoSelected} action={validarConceptoListSelected}
                            disabled={(conceptosxNomina.length) ? false : true}
                            error={errorConceptosList} required
                        />
                    </div>
                    <div className="col-md-2 col-sm-12 align-self-start alignbtnInput" style={{ marginTop: '35px' }}>
                        <Button className="btn-autocompleteSueldo" onClick={agregarConcepto}>
                            <Add/>
                        </Button>
                    </div>
                </div>
                {(conceptosSeleccionados)
                ?   <div className="mt-2 mb-2">
                        {conceptosSeleccionados.map((concepto) => (
                            <div key={concepto.id} className="row mt-1 mb-1">
                                <div className="col-md-5 col-sm-12">
                                    <div className="listIndicadores without-mr">{ concepto.indicadorClave }</div>
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <div className="listIndicadores without-mr">{ concepto.indicadorNom }</div>
                                </div>
                                <div className="col-md-2 col-sm-12">
                                    <Button className="btn-listIndicadores" onClick={() => eliminarConcepto(concepto)}>
                                        <Close/>
                                    </Button>
                                </div>
                            </div>
                        ))}                    
                    </div>
                :   null
                }
            </Fragment>
        }  
        </Fragment>
    );
}
 
export default ConceptosList;
