    
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, dispersiones_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';

const Dispersiones = ({ id, permisos }) => {

    const [bancos, setBancos] = useState(null);
    const [textError, setTextError] = useState('');
    const [openAlertE, setOpenAlertE] = useState(false);

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}/banco/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setBancos(res.data.dataResponse.filter( element => [57, 40].includes(element.id )));
                    break;
                case 404:
                    setBancos([]);
                    break;
                default:
                    setTextError(res.data.msg);
                    setOpenAlertE(true);
                    break;
            }
        })
        .catch(error => { setTextError(verifique_conexion); setOpenAlertE(true); });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                bancos
                ?   <DataTableService id={id} title={ dispersiones_ }
                        permisos={permisos} 
                        columns={[
                            { field: "bancoCve", title: "Clave" },
                            { field: "bancoNom", title: "Nombre" },
                            { field: "bancoDescrip", title: "Descripción" },
                        ]}
                        data={bancos}
                        hideIconAdd hideIconDelete
                    />
                :   <SpinnerOval />
            }
            <AlertError show={openAlertE} setOpenAlertSyE={setOpenAlertE} title="Petición Fallida" text={textError} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
}
 
export default Dispersiones;
