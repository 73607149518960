import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { agregarAcceso, eliminarAcceso, obtenerPermisos } from '../../AsignacionModulos/CollapseMaterial/HelperModulos';;

const ListCheckbox = ({ moduloHijo, accesosMenu, acceso, setAccesosMenu }) => {
    const [ selectRow, setSelectRow ] = useState( false );
    const seleccionarFila = () => {
        setSelectRow( !selectRow );
        if( !selectRow ){
            agregarFila();
        }else{
            eliminarFila();
        }
    }
    /* --------- Funciones que permiten la agregacion y eliminacion de elementos dentro de un arreglo de objetos (accesosMenu) --------- */
    const agregarFila = () => {
        const accesosTemporal = agregarAcceso( accesosMenu, moduloHijo.superior, moduloHijo.id, obtenerPermisos(moduloHijo.permisos) );
        setAccesosMenu( accesosTemporal );
    }

    const eliminarFila = () => {
        const accesosTemporal = eliminarAcceso( accesosMenu, moduloHijo.superior, moduloHijo.id );
        setAccesosMenu( accesosTemporal );
    }
    /* --------------------------------- Funcion que permite la agregacion de un solo elemento permiso --------------------------------- */
    const seleccionarPermiso = ( permiso ) => {
        if( acceso ){
            if( acceso.permisos.includes( permiso ) ){
                if( acceso.permisos.length <= 1 ){
                    setAccesosMenu( eliminarAcceso( accesosMenu, moduloHijo.superior, moduloHijo.id ) );
                }else{
                    const permisos = acceso.permisos.filter( per => per !== permiso );
                    setAccesosMenu( agregarAcceso( accesosMenu, moduloHijo.superior, moduloHijo.id, permisos ) );
                }
            }else{
                const permisos = acceso.permisos.concat([ permiso ]);
                setAccesosMenu( agregarAcceso( accesosMenu, moduloHijo.superior, moduloHijo.id, permisos ) );
            }
        }else{
            setAccesosMenu( agregarAcceso( accesosMenu, moduloHijo.superior, moduloHijo.id, [permiso] ) );
        }
    }
    /* --------------------------------------------------------------------------------------------------------------------------------- */
    return (
            <div className="row">
                <div className="col-md-5 col-sm-12 nombreModulo d-flex align-items-center" onClick={ () => seleccionarFila() }>
                    {moduloHijo.nombre}
                </div>
                <div className="col-md-7 col-sm-12">
                    <div className="row textCrud">
                        <div className="col-md-3 col-sm-3 colCenterText">
                            { moduloHijo.permisos.find(permiso => permiso.id === 1) ?
                                <Checkbox
                                    checked={ acceso && acceso.permisos.includes( 1 ) ? true : false }
                                    onChange={ () => seleccionarPermiso(1) }
                                />
                                :
                                <Checkbox disabled/>
                            }
                        </div>
                        <div className="col-md-3 col-sm-3 colCenterText">
                            { moduloHijo.permisos.find(permiso => permiso.id === 2) ?
                                <Checkbox
                                    checked={ acceso && acceso.permisos.includes( 2 ) ? true : false }
                                    onChange={ () => seleccionarPermiso(2) }
                                />
                                :
                                <Checkbox disabled/>
                            }
                        </div>
                        <div className="col-md-3 col-sm-3 colCenterText">
                            { moduloHijo.permisos.find(permiso => permiso.id === 3) ?
                                <Checkbox
                                    checked={ acceso && acceso.permisos.includes( 3 ) ? true : false }
                                    onChange={ () => seleccionarPermiso(3) }
                                />
                                :
                                <Checkbox disabled/>
                            }
                        </div>
                        <div className="col-md-3 col-sm-3 colCenterText">
                            { moduloHijo.permisos.find(permiso => permiso.id === 4) ?
                                <Checkbox
                                    checked={ acceso && acceso.permisos.includes( 4 ) ? true : false }
                                    onChange={ () => seleccionarPermiso(4) }
                                />
                                :
                                <Checkbox disabled/>
                            }
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ListCheckbox;