import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import DescriptionIcon from '@material-ui/icons/Description';
import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormAcumulados = ({ elementSelect, nominas, setNominas, nominaRef, anios, setAnios, anioRef, periodos, setPeriodos, periodoRef, acumulados, setAcumulados, acumuladoRef, setMuestraTable, error, setError, simulador }) => {

    const [disabledAnios, setDisabledAnios] = useState(true);
    const [disabledPeriodos, setDisabledPeriodos] = useState(true);
    const [disabledAcumulados, setDisabledAcumulados] = useState(true);
    const [isLoadingNomina, setIsLoadingNomina] = useState(false);
    const [isLoadingAnio, setIsLoadingAnio] = useState(false);
    const [isLoadingPeriodo, setIsLoadingPeriodo] = useState(false);
    const [isLoadingAcum, setIsLoadingAcum] = useState(false);

    useEffect(() => {
        if(simulador) {
            setIsLoadingAnio(true);
            axios.get(`${servicios_grupohit_nomina}/acumulados/${elementSelect.id}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayAnios = res.data.dataResponse.map(item => ( { id: item.periodoAnio, anio: item.periodoAnio } ))
                        setAnios(arrayAnios);
                        break;
                    default:
                        setAnios([]);
                        setError(<AlertForm message={res.data.msg}/>);
                        break;
                }
            })
            .catch(error => { console.log(error); setError(<AlertForm message={verifique_conexion}/>)})
            .finally(() => setIsLoadingAnio(false));
        } else {
            setIsLoadingNomina(true);
            axios.get(`${servicios_grupohit_rh}/empleado/nomina/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        let nominas = [];
                        let nominasRepeated = [];

                        res.data.dataResponse.map(nomina => {
                            if(!nominasRepeated[nomina.nomina.nominaClave]) {
                                nominas.push({
                                    id: nomina.nomina.id, 
                                    nombreNomina: `${nomina.nomina.nominaClave} - ${nomina.nomina.nominaNombre}`
                                })
                            }
                            nominasRepeated[nomina.nomina.nominaClave] = nomina.nomina.nominaClave;
                            return {};
                        });
                        setNominas(nominas);
                        break;
                    default:
                        setNominas([]);
                        setError(<AlertForm message={res.data.msg}/>);
                        break;
                }
            })
            .catch(error => { console.log(error); setError(<AlertForm message={verifique_conexion}/>)})
            .finally(() => setIsLoadingNomina(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const muestraAnios = value => {
        setError(null);
        if(value !== '') {
            setIsLoadingAnio(true)
            anioRef.current.value = '';
            setAcumulados([]);
            setDisabledPeriodos(true);
            setDisabledAcumulados(true);
            setDisabledAnios(true);
            setMuestraTable(false);
            axios.get(`${servicios_grupohit_nomina}/acumulados/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayAnios = res.data.dataResponse.map(item => ( { id: item.periodoAnio, anio: item.periodoAnio } ))
                    setAnios(arrayAnios);
                    break;
                default:
                    setAnios([]);
                    setError(<AlertForm message={res.data.msg}/>);
                    break;
            }
        })
        .catch(error => setError('error'))
        .finally(() => setIsLoadingAnio(false));
        setDisabledAnios(false);
        } else {
            setPeriodos([]);
            setAcumulados([]);
            setAnios([]);
            setDisabledAnios(true);
            setMuestraTable(false);
        }
    };

    const muestraPeriodos = value => {
        setError(null);
        if(value !== '') {
            setIsLoadingPeriodo(true)
            periodoRef.current.value = '';
            setAcumulados([]);
            setDisabledPeriodos(true);
            setDisabledAcumulados(true);
            setMuestraTable(false);
            axios.get(`${servicios_grupohit_nomina}/acumulados/periodos/nomina/${elementSelect.id}/${value}/${(simulador) ? elementSelect.nominaId : nominaRef.current.value}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const periodosService = res.data.dataResponse.filter(periodo => periodo.periodoPerAnio !== '9' && periodo.periodoPerAnio !== '23');
                        const arrayPeriodos = periodosService.map(item => ({
                            id: item.periodoId,
                            periodo: item.periodoPerAnio === '10' ? `9/10` : item.periodoPerAnio === '24' ? `23/24` : item.periodoPerAnio,
                        }));
                        setPeriodos(arrayPeriodos);
                        break;
                    default:
                        setPeriodos([]);
                        setError(<AlertForm message={res.data.msg}/>);
                        break;
                }
            })
            .catch(error => setError(verifique_conexion))
            .finally(() => setIsLoadingPeriodo(false));
            setDisabledPeriodos(false);
        } else {
            setPeriodos([]);
            setAcumulados([]);
            setDisabledPeriodos(true);
            setMuestraTable(false);
        }
    };

    const muestraAcumulados = value => {
        setError(null);
        if(value !== '') {
            acumuladoRef.current.value = '';
            setMuestraTable(false);
            setDisabledAcumulados(true);
            setIsLoadingAcum(true);
            axios.get(`${servicios_grupohit_nomina}/acumulados/inner/${elementSelect.id}/${value}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayAcumulados = res.data.dataResponse.map(item => ( { id: item.acumuladoId, acumulado: item.acumuladoNombre } ))
                        setAcumulados(arrayAcumulados);
                        break;
                    default:
                        setAcumulados([]);
                        setError(<AlertForm message={res.data.msg}/>);
                        break;
                }
            })
            .catch(error => setError('error'))
            .finally(() => setIsLoadingAcum(false));
            setDisabledAcumulados(false);
        } else {
            setAcumulados([]);
            setDisabledAcumulados(true);
            setMuestraTable(false);
        }
    };

    const validaAcumulado = value => {
        setMuestraTable(false);
    };

    const mostrarTable = e => {
        e.preventDefault();
        if(validarSubmit("FormConsultaAcumulado")) {
            setMuestraTable(true);
        }
    };

    return (
        <form id="FormConsultaAcumulado" onSubmit={mostrarTable}>
            <div className="row">
                {(!simulador) &&
                    <div className="col-6 col-lg col-md-6 col-sm-6">
                        {(isLoadingNomina)
                        ?   <SpinnerOval/>
                        :   <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nombreNomina" onChangeExecute={muestraAnios}
                                refValue={nominaRef} defaultValue="" required
                            />
                        }
                    </div>
                }
                <div className="col-6 col-lg col-md-6 col-sm-6">
                    {(isLoadingAnio)
                    ?   <SpinnerOval/>
                    :   <SelectValidation title="Año" arrayOpciones={anios} keyOpcion="anio" onChangeExecute={muestraPeriodos}
                            refValue={anioRef} defaultValue="" disabled={(simulador) ? false : disabledAnios} required
                        />
                    }
                </div>
                <div className="col-6 col-lg col-md-6 col-sm-6">
                    {(isLoadingPeriodo)
                    ?   <SpinnerOval/>
                    :   <SelectValidation title="Periodo" arrayOpciones={periodos} keyOpcion="periodo" onChangeExecute={muestraAcumulados}
                            refValue={periodoRef} defaultValue="" disabled={disabledPeriodos} required    
                        />
                    }
                </div>
                <div className="col-6 col-lg col-md-6 col-sm-6">
                    {(isLoadingAcum)
                    ?   <SpinnerOval/>
                    :   <SelectValidation title="Tipo de Acumulado" arrayOpciones={acumulados} keyOpcion="acumulado" onChangeExecute={validaAcumulado}
                            refValue={acumuladoRef} defaultValue="" disabled={disabledAcumulados} required
                        />
                    }
                </div>
                <div className={(simulador) ? 'col-6 col-lg-2 col-md-6 col-sm-6' : 'col-12 col-lg-2 col-md-12 col-sm-12'}>
                    <button
                        type="submit" className="btn-autocompleteInput" style={{ width: '100%' }}
                        disabled={(isLoadingAnio || isLoadingPeriodo || isLoadingAcum) ? true : false}
                    >
                        <DescriptionIcon />
                    </button>
                </div>
               
            </div>
            {error
            ?   <div className="row"><div className="col">{ error }</div></div>
            :   null
            }
        </form>
    );
}
 
export default FormAcumulados;