import React from 'react';

import ReactHtmlParser from 'react-html-parser';
import Avatar from '@material-ui/core/Avatar';

const MostrarMensaje = ({ mensaje, acciones }) => {
    return (
        <div className="mostrarMensaje">
            <div className="asuntoMensaje">
                { mensaje.asunto === '' ? 'Sin Asunto' : mensaje.asunto }
            </div>
            <div className="mensajesContenido informacionMensaje">
                <Avatar src={`data:image/jpeg;base64, ${mensaje.foto}`} className="contenidoAvatar"/>
                <div className="divAvatarFrom">
                    <span className="fromNombreMensaje"> { mensaje.fromNombre } </span>
                    <span className="fromMensaje"> { `<${mensaje.from}>` } </span>
                    <div>
                        <span className="paraMensaje"> Para: </span>
                        <span className="toLeido"> { mensaje.to } </span>
                    </div>
                    <div className="mensajesFecha2"> { mensaje.fechaHora } </div>
                </div>
                <div className="divActionMensaje">
                    { acciones }
                </div>
            </div>

            <div className="cuerpoMensaje">
                { ReactHtmlParser(mensaje.mensaje) }
            </div>
        </div>
    );
};

export default MostrarMensaje;