import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import ChatIcon from '@material-ui/icons/Chat';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
/* ------------------------------------ Estilos y componentes Header -----------------------------------  */
import RenderMobileMenu from './RenderMobileMenu';
import SearchComponent from './SearchComponent';
import UsuariosChat from '../Drawer/UsuariosChat';
import useStylesHeader from './UseStylesHeader';
import UserNotification from './UserNotifications/UserNotification';
/* ---------------------------------- Ultimo que debe de ser importado ---------------------------------- */
import useStylesMenu from '../UseStylesMenu';
import './style.css';
/* -----------------------------------------------------------------------------------------------------  */
import ShowEmails from './ShowEmails';
import TabsContainer from '../../MenuDrawer/TabsNavegacion/TabsContainer';
import { Spinner } from 'reactstrap';

function HeaderAppBar({ openMenu, setOpenMenu, socket, modulosApi, usuario, steps, run, tour, relacionLaboral, toggleRelacionLaboral, relacionesLaborales, toggleDetallesNominas, periodoActual }) {
    const classes = useStylesMenu();
    /* ---------------------------- Estilos y variables Header-Mobile ---------------------------- */
    const classesHeader = useStylesHeader();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [mensajeNuevo, setMensajeNuevo] = useState([]);
    const [ userNotifications, setUserNotifications ] = useState([]);
    const [ lenthNotifications, setLenthNotifications ] = useState("");
    const [openNotification, setOpenNotification ] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const mobileMenuId = 'primary-search-account-menu-mobile';

    useEffect( () => {
        if(socket !==null){
            socket.on('chat:messagedesc', data => {
                if(data.length) setMensajeNuevo(data);
            });
        
            return( () => {
                socket.off('chat:messagedesc');
            })
          
        }
    },[socket, mensajeNuevo]);
    
    const styles = theme => ({
        margin: {
          margin: theme.spacing.unit * 2
        },
        customBadge: {
          backgroundColor: "#4C638E",
          color: "white"
        }
    });
    const openNotificationHandler = () => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(false);
        setOpenNotification(!openNotification); 
    };

    return (
        <Fragment>
        {(modulosApi) &&
            <div className={classesHeader.grow}>
                <AppBar position="fixed" className={clsx(classes.appBar, {[classes.appBarShift]: openMenu,})} >
                    <Toolbar className="toolBar">
                        <div  className="menuSearchComponent" >
                            <Tooltip title="Menú" >
                                <IconButton aria-label="open drawer" edge="start" className={classes.menuButton} onClick={ () => setOpenMenu() } >
                                    <MenuIcon />
                                </IconButton>
                            </Tooltip>
                            {/* ----------------------------------------------- Buscador ----------------------------------------------- */}
                            <SearchComponent modulosApi={modulosApi} steps={steps}  run={run}/>
                        </div> 
                        {
                            (relacionLaboral)
                            ?   <Fragment>
                                    <div className="contenedor-textoHeader">
                                    {(relacionesLaborales.length > 1)
                                    ?   <Tooltip title="Cambiar tipo de Relación Laboral" >  
                                            <label className="form-control contenidoRelLab-hover" onClick={(periodoActual) ? toggleRelacionLaboral : undefined}> 
                                                <span className="bold"> Relación Laboral:&nbsp;</span><span>{ relacionLaboral.nombre }</span>
                                            </label>
                                        </Tooltip>
                                    :   <label className="form-control contenidoRelLab"> 
                                            <span className="bold"> Relación Laboral:&nbsp;</span><span>{ relacionLaboral.nombre }</span>
                                        </label>
                                    }
                                    </div>
                                    {(periodoActual)
                                    ?   <div className="contenedor-textoHeader">
                                            {(periodoActual.anio && periodoActual.periodo)
                                            ?   <label className="form-control contenidoRelLab-hover" onClick={toggleDetallesNominas}> 
                                                    <span className="bold"> Periodo Actual:&nbsp;</span><span>{ `${periodoActual.anio} - ${periodoActual.periodoInfo ? periodoActual.periodoInfo : periodoActual.periodo}` }</span>
                                                </label>
                                            :   <label className="form-control contenidoRelLab"> 
                                                    <span className="bold"> NO TIENE ACCESO A NÓMINAS DE LA RL </span>
                                                </label>
                                            }
                                        </div>
                                    :   <Spinner/>
                                    }
                                </Fragment>
                            :   null
                        }
                        {/* ----------------------------------- Seccion Notificaciones y mensajes ----------------------------------- */}
                        
                        <div className={"notificacionesMensajes"}>
                            <div className="my-fourth-step" name="notification"> 
                                <ShowEmails socket={socket}/> 
                                <Tooltip title="Notificaciones" >
                                    <IconButton aria-label="show new notifications" onClick={openNotificationHandler()}>
                                        {
                                            lenthNotifications > 0
                                            ?   <Badge 
                                                    classes={{ badge: classes.notificationColor }} className={styles.margin}
                                                    badgeContent={lenthNotifications}
                                                >
                                                    <NotificationsIcon />
                                                </Badge>
                                            :   <NotificationsIcon />
                                        }
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="my-five-step">
                                <Tooltip title="Chat" >
                                    <IconButton aria-label="open drawer" edge="end" aria-haspopup="true" onClick={ () => {setOpen(true); setOpenNotification(false)} }>
                                        {
                                            (mensajeNuevo.length > 0)
                                            ? <Badge badgeContent={mensajeNuevo.length} classes={{ badge: classes.notificationColor }}><ChatIcon /></Badge>
                                            : <ChatIcon />
                                        }
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </Toolbar>
                    {/* --- Componente que solo se muestra cuando presionamos el icono de chat */}
                    <UsuariosChat open={open} setOpen={setOpen} socket={socket} usuario={usuario} mensajeNuevo={mensajeNuevo} setMensajeNuevo={setMensajeNuevo} tour={tour}/>
                    <UserNotification open={openNotification} setOpen={setOpenNotification} anchorEl={anchorEl} setNotificaciones={setUserNotifications} notifications={userNotifications} setLenthNotifications={setLenthNotifications}/>
                    <TabsContainer />
                </AppBar>
                {/* Componentes externos que renderizan menus en la vista movil */}
                <RenderMobileMenu steps={steps} run={run} mobileMoreAnchorEl={mobileMoreAnchorEl} setMobileMoreAnchorEl={setMobileMoreAnchorEl} mobileMenuId={mobileMenuId} mensajeNuevo={mensajeNuevo} setOpen={setOpen}/>
            </div>
        }
        </Fragment>
    );
}

export default HeaderAppBar;