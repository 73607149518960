import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ConvertDate from '../../../Helpers/ConvertDate';
import ModalService from '../../../ModalService/ModalService';
import FormAusentismos from './FormAusentismos';
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation';

const ModalAddAusentismos = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { claveEmpleado, tiposAusentismo } = informacionAdicional;

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    };

    const enviarAusentismo = ausentismo => {
        setIsLoading(true);
        const request = ausentismo;
        axios.post(`${servicios_grupohit_rh}/empleado/ausentismo/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const data = {
                        id: res.data.dataResponse.id,
                        ausentismoId: res.data.dataResponse.ausentismo.id,
                        ausentismoNom: res.data.dataResponse.ausentismo.ausentismoNom,
                        auseFechaIni: (res.data.dataResponse.auseFechaIni) ? ConvertDate(res.data.dataResponse.auseFechaIni) : '--/--/----',
                        auseFechaIniInput: (res.data.dataResponse.auseFechaIni) ? longDateToInput(res.data.dataResponse.auseFechaIni) : '',
                        auseFechaFin: (res.data.dataResponse.auseFechaFin) ? ConvertDate(res.data.dataResponse.auseFechaFin) : '--/--/----',
                        auseFechaFinInput: (res.data.dataResponse.auseFechaFin) ? longDateToInput(res.data.dataResponse.auseFechaFin) : '',
                        auseFechaAplica: ConvertDate(res.data.dataResponse.auseFechaAplica),
                        auseFechaAplicaInput: longDateToInput(res.data.dataResponse.auseFechaAplica),
                        comentarios: res.data.dataResponse.comentarios,
                        cantidad: res.data.dataResponse.cantidad.toFixed(2)
                    };
                    agregar(data);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService
            title="Agregar Ausentismo"
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormAusentismos
                        enviarAusentismo={enviarAusentismo}
                        error={error}
                        setError={setError}
                        claveEmpleado={claveEmpleado}
                        tiposAusentismo={tiposAusentismo}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddAusentismos;