export function headerExcel( jsonElement, separator, upperCase=true) {
    var headerText = '';
    Object.keys( jsonElement ).forEach( key => {
        if( key !== 'id' && key !== 'tableData' ){
            (upperCase)
            ?   headerText = headerText + key.toUpperCase() + separator
            :   headerText = headerText + key + separator;
        }
    })
    return headerText + '\n';
}

export function formatExcel( json, groupBy=0, separator='\t', upperCase=true ) { 
    let headers = json[groupBy]; 
    var cadenaExcel = headerExcel( groupBy>0 ? Object.assign({" ": " "}, headers ): headers, separator, upperCase);  
    json.forEach( element => {
        var filaExcel = '';
        Object.keys(element).forEach( (key, index) => {
            if( key !== 'id' && key !== 'tableData' ){
                if(index===0 && groupBy>0 && key!== " ") filaExcel = filaExcel + separator;
                let text =  element[key]===null ? '' : `${typeof element[key] === 'string' ? element[key].replace(/,/g, '').toUpperCase() :element[key]}`;
                //validación para poner o no poner las celdas con formato de texto en reportes
                if((key.trim() !== '') && ((key.trim().toLowerCase() === 'clave presupuestal') || (key.trim().toLowerCase() === 'nss') || (key.trim().toLowerCase() === 'clave proyecto acción'))) {
                    filaExcel = (text.trim() !== '') ? filaExcel + "=\"" + text + "\"" + separator : filaExcel + text + separator;
                } else {
                    filaExcel = filaExcel + text + separator;
                }
            }
        })
        cadenaExcel = cadenaExcel + filaExcel + '\n';
    });
    return cadenaExcel;
}

export const csvDownloadOptional = ( formato, nombre, upperCase=true  ) => {
    let csvContent = formatExcel( formato, 0,  ',', upperCase);
    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent("\uFEFF"+csvContent));
    a.setAttribute('download', nombre);
    window.document.body.appendChild(a);
    a.click();
}

export const xlsDownloadOptional = ( formato, nombre ) => {
    let csvContent = formatExcel( formato, 0,  ',');
    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:application/vnd.ms-excel; charset=utf-8,' + encodeURIComponent("\uFEFF"+csvContent));
    a.setAttribute('download', nombre);
    window.document.body.appendChild(a);
    a.click();
}

export function  convertColumnasPDF( jsonColumnas, isReport=false, groupBy=false ) {
    const newColumns = groupBy ? [{ 'dataKey': " ", 'title': " " }] : [];
    jsonColumnas.map( (column) => {
        if(column.field === '') {
            return '';
        } else {
            newColumns.push( { 'dataKey': column.title, 'title': (column.titleWTooltip) ? column.titleWTooltip?.toUpperCase() : (column.title) ? column.title.toUpperCase() : column.title } );
            return '';
        }
    });
    return newColumns;
}

export function  convertColumnasPrint( jsonColumnas, isReport=false, groupBy=false  ) {
    const newColumns = groupBy ? [{ 'field': " ", 'displayName': " " }] : [];
    jsonColumnas.map( (column) => {
        newColumns.push( { 'field': column.title, 'displayName': (column.titleWTooltip) ? column.titleWTooltip.toUpperCase() : (column.title) ? column.title.toUpperCase() : column.title } );
        return '';
    });
    return newColumns;
}

export function getStylesPDF(id, columnsAll) {
    const columnsVis = (columnsAll) ? columnsAll.filter(c => !c.hidden) : undefined
    const columnsFields = (columnsVis) ? columnsVis.map(c => ( c.field )) : undefined;
    const cp = (columnsFields) ? columnsFields.indexOf('cpresupuestal') : -1;
    const cpa = (columnsFields) ? columnsFields.indexOf('cpresupuestalAnt') : -1;
    const cpn = (columnsFields) ? columnsFields.indexOf('cpresupuestalNuevo') : -1;
    const ne = (columnsFields) ? columnsFields.indexOf('empleadoClave') : -1;
    const nea = (columnsFields) ? columnsFields.indexOf('empleadoClaveAnt') : -1;
    const cc = (columnsFields) ? columnsFields.indexOf('conceptoCve') : -1;
    const np = (columnsFields) ? columnsFields.indexOf('plazaClave') : -1;
    const npa = (columnsFields) ? columnsFields.indexOf('plazaClaveAnt') : -1;
    const npn = (columnsFields) ? columnsFields.indexOf('plazaClaveNuevo') : -1;
    const fig = (columnsFields) ? columnsFields.indexOf('empantFecini') : -1;
    const fm = (columnsFields) ? columnsFields.indexOf('fechaMovimiento') : -1;
    const fn = (columnsFields) ? columnsFields.indexOf('empleadoFecNac') : -1;
    const fna = (columnsFields) ? columnsFields.indexOf('empleadoFecNacAnt') : -1;
    const fit = (columnsFields) ? columnsFields.indexOf('empturFecIni') : -1;
    const fi = (columnsFields) ? columnsFields.indexOf('empStatusFecIni') : -1;
    const ff = (columnsFields) ? columnsFields.indexOf('empStatusFecFin') : -1;
    const fb = (columnsFields) ? columnsFields.indexOf('fechaBaja') : -1;
    const fin = (columnsFields) ? columnsFields.indexOf('empNomFecIni') : -1;
    const ffn = (columnsFields) ? columnsFields.indexOf('empNomFecFin') : -1;
    const fic = (columnsFields) ? columnsFields.indexOf('empcontratosaoFecIni') : -1;
    const ffc = (columnsFields) ? columnsFields.indexOf('empcontratosaoFecFin') : -1;
    const p = (columnsFields) ? columnsFields.indexOf('puestoClave') : -1;
    const pcv = (columnsFields) ? columnsFields.indexOf('puesto') : -1;
    const columnStyles = {};

    switch(id) {
        case 12: //Bancos
            return {
                orientation: '',
                styles: { },
                columnStyles: { 0: {cellWidth: 13} }
            }
        case 16: //Conceptos Generales
            return {
                orientation: '',
                styles: { fontSize: 7 },
                columnStyles: { 0: {cellWidth: 12},  3: {cellWidth: 30}, 4: {cellWidth: 20}, 5: {cellWidth: 31}, 6: {cellWidth: 14}, 7: {cellWidth: 14} }
            }
        case 20: //Dependencias
            return {
                orientation: 'landscape',
                styles: { cellPadding: 0.6, fontSize: 8 },
                columnStyles: { 0: {cellWidth: 25}, 1: {cellWidth: 100} }
            }
        case 21: //Proyectos
            return {
                orientation: 'landscape',
                styles: { cellPadding: 0.6, fontSize: 7.5 },
                columnStyles: { 0: {cellWidth: 30}, 5: {cellWidth: 40} }
            }
        case 30: //Empleados
        case 31: //Empleados de Ausentismos
        case 33: //Expedientes
        case 34: //Empleados de Históricos
        case 41: //Variables
        case 62: //Movimientos Especiales
        case 62+'verEmpladosMovEsc': //Movimientos Especiales Listado Escalafon
        case 68: //Fijos
        case 69: //Relacionados
        case 70: //Pendientes
        case 75: //Prestamos
        case 76: //Acumulados
        case 1001+'EstadPorPeriodoEscalafonListado': //Movimientos de Nómina por Periodos Listado Escalafon
            return {
                orientation: 'landscape',
                styles: { fontSize: 6 },
            }
        case 904: //CargasMasivas - PagosUnicos
            return {
                orientation: 'landscape',
                styles: { fontSize: 6.5 },
                columnStyles: { 0: {cellWidth: 10}, 4: {cellWidth: 26}, 5: {cellWidth: 26}, 12: {cellWidth: 13}, 13: {cellWidth: 13} }
            }
        case 32: //Reingresos
            return {
                orientation: '',
                styles: { fontSize: 7 },
            }
        case '34nomina': //Históricos Nomina
        case '34plaza': //Históricos plaza
        case '34presupuestal': //Históricos presupuestal
        case '34puesto': //Históricos puesto
        case '34status': //Históricos status
        case '34sueldo': //Históricos sueldo
        case '34turno': //Históricos turno
        case '34zonapago': //Históricos Nomina
        case '83secondary':
            return {
                orientation: 'landscape',
            }
        case 38+'secondary': //Conceptos Nómina
            return {
                orientation: '',
                styles: { fontSize: 7 },
                columnStyles: { 0: {cellWidth: 12}, 1: {cellWidth: 18}, 3: {cellWidth: 30}, 4: {cellWidth: 20}, 5: {cellWidth: 14}, 6: {cellWidth: 14}, 7: {cellWidth: 14} }
            }
        
        case 48: //Reporte de Nómina
        case 48+"secondary": //Recibos de Pagos
        return {
            orientation: 'landscape',
            styles: { cellPadding: 0.6, fontSize: 5 }, 
            /* columnStyles: { 0: {cellWidth: 8}, 2: {cellWidth: 14}, 3: {cellWidth: 24}, 4: {cellWidth: 18}, 5: {cellWidth: 14}, 6: {cellWidth: 24}, 7: {cellWidth: 25}, 8: {cellWidth: 24}, 9: {cellWidth: 9}, 11: {cellWidth: 20}, 12: {cellWidth: 10}, 13: {cellWidth: 18}, 14: {cellWidth: 8}} */
        }
        case 49: //Reporte Recursos Humanos
        case 49+"secondary": 
            return {
                orientation: 'landscape',
                styles: { cellPadding: 0.6, fontSize: 4 }, 
            }
        case "1001secondary": // Especiales Empleados Busqueda Avanzada
            return {
                columnStyles: { 0: {cellWidth: 25}}
            }
        case 1001+'RHhistorico9719':
            return {
                orientation: 'landscape',
                styles: { fontSize: 1 },
            }
        case 1001+'EstadPorPeriodoDatosEmpleado':
            return {
                orientation: 'landscape',
                styles: { fontSize: 4 },
            }
        case 83:
        case 1001:
        case 1001+'historico9719':
            return {
                orientation: 'landscape',
                styles: { fontSize: 7 },
            }
        case 1001+'EstadPorPeriodoProrrogaContratoV':
        case 1001+'EstadPorPeriodoNoProrrogaContrato':
        case 1001+'EstadPorPeriodoProrrogaContratoR':
        case 1001+'EstadPorPeriodoReingresoR':
        case 1001+'EstadPorPeriodoProyectoR':
        case 1001+'EstadPorPeriodoAltaR':
        case 1001+'EstadPorPeriodoCategoriaR':
        case 1001+'EstadPorPeriodoAdscripcionR':
        case 1001+'EstadPorPeriodoBajaR':
        case 1001+'EstadPorPeriodoReingresoV':
        case 1001+'EstadPorPeriodoNombramiento':
        case 1001+'EstadPorPeriodoProyectoV':
        case 1001+'EstadPorPeriodoBajaV':
        case 1001+'EstadPorPeriodoAltaV':
        case 1001+'EstadPorPeriodoCategoriaV':
        case 1001+'EstadPorPeriodoAdscripcionV':
        case 1001+'EstadPorPeriodoEscalafon':
        case 1001+'EstadPorPeriodoOtrosMovimientos':
            //clave presupuestal
            if(cp > -1) columnStyles[cp] = { cellWidth: 45 };
            if(cpa > -1) columnStyles[cpa] = { cellWidth: 45 };
            if(cpn > -1) columnStyles[cpn] = { cellWidth: 45 };
            //nup
            if(np > -1) columnStyles[np] = { cellWidth: 16 };
            if(npa > -1) columnStyles[npa] = { cellWidth: 16 };
            if(npn > -1) columnStyles[npn] = { cellWidth: 16 };
            //nue
            if(ne > -1) columnStyles[ne] = { cellWidth: 16 };
            if(nea > -1) columnStyles[nea] = { cellWidth: 16 };
            //fechas
            if(fig > -1) columnStyles[fig] = { cellWidth: 17 };
            if(fm > -1) columnStyles[fm] = { cellWidth: 15 };
            if(fn > -1) columnStyles[fn] = { cellWidth: 15 };
            if(fna > -1) columnStyles[fna] = { cellWidth: 15 };
            if(fit > -1) columnStyles[fit] = { cellWidth: 15 };
            if(fi > -1) columnStyles[fi] = { cellWidth: 15 };
            if(ff > -1) columnStyles[ff] = { cellWidth: 15 };
            if(fb > -1) columnStyles[fb] = { cellWidth: 15 };
            if(fin > -1) columnStyles[fin] = { cellWidth: 15 };
            if(ffn > -1) columnStyles[ffn] = { cellWidth: 15 };
            if(fic > -1) columnStyles[fic] = { cellWidth: 15 };
            if(ffc > -1) columnStyles[ffc] = { cellWidth: 15 };
            //puesto
            if(p > -1) columnStyles[p] = { cellWidth: 22 };
            if(pcv > -1) columnStyles[pcv] = { cellWidth: 22 };
            //concepto
            if(cc > -1) columnStyles[cc] = { cellWidth: 17 };

            return {
                orientation: 'landscape',
                styles: { fontSize: 6 },
                columnStyles
            }
        default:
            return {
                orientation: '',
                styles: { },
                columnStyles: { }
            }
    }
}