import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { escalafonDefinitivo } from '../../../../../../../../Redux/actions/EmpleadosActions';
import axios from 'axios';
import { servicios_grupohit_org, servicios_grupohit_admon } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import CardAsignaEscalafon from './CardAsignaEscalafon';
import AlertForm from '../../../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../../../Services/Alerts/AlertWarning';
import AlertSyE from '../../../../../../../../Services/Alerts/AlertSyE';
import ModalLoading from '../../../../../../../../Services/ModalLoading/ModalLoading';
import { JsonPlazas } from '../../Helpers/JsonPlazas';
import TooltipComponent from '../../../../../../../../Services/Tooltip/TooltipComponent';
import { Button, IconButton } from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import './styles.css';

const Escalafon = (props) => {

    const {
        empleadosEscalafon, folioRef, plazas, setPlazas, movimientos, fechaInicial, fechaFinal,
        setMovimientos, ultimoMovimiento, setUltimoMovimiento, actualizaPlaza, setActualizaPlaza, setMostrar, cerrarModal,
        escalafonDefinitivo, nominasRL
    } = props;

    const [error, setError] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [settingsAlertSE, setSettingsAlertSE] = useState({ type: 'success', msg: '' });
    const [openAlertSE, setOpenAlertSE] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [empleadosDisponibles, setEmpleadosDisponibles] = useState(null);
    const [primerEmpleado, setPrimerEmpleado] = useState({});
    const [puestos, setPuestos] = useState([]);
    const [cPresupuestales, setCPresupuestales] = useState([]);
    const [ejecutaPlazas, setEjecutaPlazas] = useState(true);
    const [plazasResponse, setPlazasResponse] = useState(null);
    const [errores, setErrores] = useState([{ errorPuesto: false, errorProyecto: false }]);

    const { type, msg } = settingsAlertSE;

    useEffect(() => {
        if(puestos.length < 1) {
            axios.get(`${servicios_grupohit_org}/puesto/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayPuestos = res.data.dataResponse.map(puesto => ({
                            id: puesto.id,
                            puestoCve: puesto.puestoCve,
                            puestoNom: puesto.puestoNom,
                            puestoCveNom: `${puesto.puestoCve} / ${puesto.puestoNom}`
                        }));
                        setPuestos(arrayPuestos);
                        break;
                    default:
                        setSettingsAlertSE({ type: 'error', msg: res.data.msg });
                        setOpenAlertSE(true);
                        break;
                }
            })
            .catch(error => {
                setSettingsAlertSE({ type: 'error', msg: 'No se pudo obtener Puestos de la Relación Laboral' });
                setOpenAlertSE(true);
            });
        }
        if(cPresupuestales.length < 1) {
            axios.get(`${servicios_grupohit_admon}/presupuestal/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayProyectos = res.data.dataResponse.map(presupuestal => ({
                            id: presupuestal.id,
                            cPresupuestalCve: presupuestal.cpresupuestalclave,
                            cPresupuestalNom: presupuestal.cpresupuestalnom
                        }));
                        setCPresupuestales(arrayProyectos);
                        break;
                    default:
                        setSettingsAlertSE({ type: 'error', msg: res.data.msg });
                        setOpenAlertSE(true);
                        break;
                }
            })
            .catch(error => {
                setSettingsAlertSE({ type: 'error', msg: 'No se pudo obtener Proyectos de la Relación Laboral' });
                setOpenAlertSE(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(ejecutaPlazas) {
            const plazaId = empleadosEscalafon.map(emp => (emp.plazaId));
            axios.post(`${servicios_grupohit_org}/plaza/organigrama/`, { plazaId } , headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPlazas(JsonPlazas(res.data.dataResponse));
                        setPlazasResponse(res.data.dataResponse);
                        break;
                    case 404:
                        setPlazas([]);
                        setPlazasResponse([]);
                        break;
                    default:
                        setSettingsAlertSE({ type: 'error', msg: res.data.msg });
                        setOpenAlertSE(true);
                        break;
                }
            })
            .catch(error => {
                setSettingsAlertSE({ type: 'error', msg: 'Verifica tu conexión a la red' });
                setOpenAlertSE(true);
                console.log(error);
            })
            .finally(() => {
                setEjecutaPlazas(false)
                setActualizaPlaza(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ejecutaPlazas]);

    useEffect(() => {
        if(ultimoMovimiento === 0) {
            const newMovimientos = movimientos.slice(0, 1);
            setMovimientos(newMovimientos);
            setError(null);
            return;
        }

        if(ultimoMovimiento) {
            const newMovimientos = movimientos.slice(0, ultimoMovimiento+1);
            setMovimientos(newMovimientos);
            setError(null);
            return;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ultimoMovimiento]);
    
    const guardaPrimerEmpleado = plazaId => {
        //quitarlo del arreglo de empleados
        const newEmpleados = empleadosEscalafon.filter(emp => emp.plazaId !== plazaId);
        setEmpleadosDisponibles(newEmpleados)
        //obtener primer empleado
        const empPlazaId = empleadosEscalafon.find(emp => emp.plazaId === plazaId);
        setPrimerEmpleado(empPlazaId);
    };

    const abreAlerta = () => {
        if(ultimoMovimiento === null) {
            setError(<AlertForm message="Debe asignar una plaza a todos los Empleados Disponibles"/>);
            return;
        }
        setError(null);
        setOpenAlert(true);
    };

    const movimientosEscalafon = () => {
        setOpenLoading(true);

        const empcpres_fecini = fechaInicial;
        
        const arrayEscalafon = movimientos.map(movimiento => (
            {
                empId: movimiento.empleado.id,
                cpresupuestalId: movimiento.plaza.cPresupuestalId,
                areaId: movimiento.plaza.areaId,
                plazaId: movimiento.plaza.id,
                puestoId: movimiento.plaza.puestoId,
                empcpres_fecini
            }
        ));
        
        const empIds = movimientos.map(movimiento => (
            movimiento.empleado.id
        ));

        const nominaId = movimientos[0].empleado.nominaId;
        const fecha_movimiento = nominasRL.find(nom => nom.nomina === nominaId).periodoFecini;
        let requestFolio = {};
        
        if(fechaFinal) {
            requestFolio = {
                folio: folioRef.current.value,
                definitivo: false,
                fecha_inicial: empcpres_fecini,
                fecha_final: fechaFinal,
                fecha_movimiento,
                empIds
            };
        } else {
            requestFolio = {
                folio: folioRef.current.value,
                definitivo: true,
                fecha_inicial: empcpres_fecini,
                fecha_movimiento,
                empIds
            };
        }
        const request = {
            registros: arrayEscalafon
        };
        
        //validar que campos puesto y proyecto de cards no vayan vacíos
        const findErrorTrue = errores.find(error => error.errorPuesto === true || error.errorProyecto === true);
        if(findErrorTrue) {
            setError(<AlertForm message="Hay campos vacios de Puesto y/o Proyecto"/>);
            setOpenLoading(false);
        } else {
            escalafonDefinitivo(request, requestFolio, setOpenLoading, setSettingsAlertSE, setOpenAlertSE);
        }
    };

    const atras = () => {
        setMostrar(1);
    };

    return (
        <Fragment>
            <div className="btn-escalafon-back">
                <TooltipComponent tooltipText="Regresar a selección de fecha">
                    <IconButton onClick={atras}>
                        <ArrowLeft/><small>Regresar</small> 
                    </IconButton>
                </TooltipComponent>
            </div>
            <CardAsignaEscalafon
                empleados={empleadosDisponibles} plazas={plazas} plazasResponse={plazasResponse} puestos={puestos} cPresupuestales={cPresupuestales}
                ultimoMovimiento={ultimoMovimiento} setUltimoMovimiento={setUltimoMovimiento}
                movimientos={movimientos} setMovimientos={setMovimientos}
                actualizaPlaza={actualizaPlaza} setActualizaPlaza={setActualizaPlaza}
                errores={errores} setErrores={setErrores}
                guardaPrimerEmpleado={guardaPrimerEmpleado}
            />
            {(movimientos.length > 0) &&
            movimientos.map((mov, i) => (
                <CardAsignaEscalafon
                    key={i} index={i} info={mov}
                    empleados={empleadosDisponibles} plazas={plazas} puestos={puestos} cPresupuestales={cPresupuestales}
                    ultimoMovimiento={ultimoMovimiento} setUltimoMovimiento={setUltimoMovimiento}
                    movimientos={movimientos} setMovimientos={setMovimientos}
                    actualizaPlaza={actualizaPlaza} setActualizaPlaza={setActualizaPlaza}
                    primerEmpleado={primerEmpleado} setEjecutaPlazas={setEjecutaPlazas}
                    errores={errores} setErrores={setErrores}
                />
            ))
            }
            {(movimientos.length === empleadosEscalafon.length)
            ?   <Fragment>
                    { error }
                    <Button variant="contained" className="btn-color" onClick={abreAlerta}>Realizar Escalafón</Button>
                    <AlertWarning show={openAlert} SetopenALert={setOpenAlert} title="¿Estás seguro?" text="¡Se realizará el escalafón!"  textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={movimientosEscalafon}/>
                    <ModalLoading nombreModulo="Escalafón" open={openLoading} setOpen={setOpenLoading} />
                </Fragment>
            :   null
            }
            <AlertSyE show={openAlertSE} setOpenAlertSyE={setOpenAlertSE} title={(type === 'success') ? 'Petición Exitosa' : 'Error'} text={msg} textbtnconfirm="Aceptar" type={type} action={() => (type === 'success') ? cerrarModal(true) : false}/>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    nominasRL: state.PeriodoActualReducer.nominasRL,
});
  
const mapDispatchToProps = { escalafonDefinitivo };
  
export default connect(mapStateToProps, mapDispatchToProps)(Escalafon);