import React, { createRef, useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_org, servicios_grupohit_admon, servicios_grupohit_catalogo, servicios_grupohit_nomina, servicios_grupohit, verifique_conexion, registro_sera_actualizado } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import { validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import Button from '@material-ui/core/Button';
import InputValidation from '../../../../../../../../Services/Validation/InputValidation';
import AutocompleteComponent from '../../../../../../../../Services/Autocomplete/AutocompleteComponent';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import SelectValidation from '../../../../../../../../Services/Validation/SelectValidation';
import AlertForm from '../../../../../../../../Services/AlertForm';
import SearchIcon from '@material-ui/icons/Search';
import TooltipComponent from '../../../../../../../../Services/Tooltip/TooltipComponent';
import AlertWarning from '../../../../../../../../Services/Alerts/AlertWarning';

const FormPlaza = ({ enviar, actualizar, data }) => {
    const [puestos, setPuestos] = useState([]);
    const [puestoSelect, setPuestoSelected] = useState(null);
    const [puesto, setPuesto] = useState(null);
    const [errorPuesto, setErrorPuesto] = useState(false);
    const [empleados, setEmpleados] = useState([]);
    const [empleadoTitularSelect, setEmpleadoTitularSelect] = useState(null);
    const [empleadoTitular, setEmpleadoTitular] = useState(null);
    const [ctosTrabajo, setCtosTrabajo] = useState([]);
    const [ctoTrabajoSelect, setCtoTrabajoSelect] = useState(null);
    const [ctoTrabajo, setCtoTrabajo] = useState(null);
    const [nominas, setNominas] = useState(null);
    const [nominaSelect, setNominaSelect] = useState(null);
    const [nomina, setNomina] = useState(null);
    const [errorCtoTrabajo, setErrorCtoTrabajo] = useState(false);
    const [errorNomina, setErrorNomina] = useState(false);
    const [status, setStatus] = useState(false);
    const [mensajeCambios, setMensajeCambios] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [errorBusqueda, setErrorBusqueda] = useState(null);
    const [openAlertW, setOpenAlertW] = useState(false);

    const claveRef = createRef('');
    const statusRef = createRef('');
    const busquedaTitularRef = createRef('');

    useEffect(() => {
        if(actualizar) {
            if(data.empleadoTitular) {
                setEmpleadoTitular(data.empleadoTitular.id);
                setEmpleadoTitularSelect(data.empleadoTitular);
                const empTitular = data.empleadoTitular;
                if(empTitular) {
                    empTitular.nueNombre = `${data.empleadoTitular.empleadoclave} / ${(data.empleadoTitular.empleadopat) ? `${data.empleadoTitular.empleadopat} ` : ''}${(data.empleadoTitular.empleadomat) ? `${data.empleadoTitular.empleadomat} ` : ''}${data.empleadoTitular.empleadonom}`
                }
                setEmpleados([empTitular]);
            } else {
                setEmpleadoTitular(null);
                setEmpleadoTitularSelect(null);
                setEmpleados([]);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!actualizar || (actualizar && puestos)) {
             //obtener todas las nominas
            axios.get(`${servicios_grupohit_nomina}/config/nomina/all`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setNominas(res.data.dataResponse);
                        break;
                    default:
                        setNominas([]);
                        break;
                }
            })
            .catch(error => setNominas([]));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puestos]);

    useEffect(() => {
        if(actualizar && nominas) {
            const nominaAsignada = nominas.find(nom => nom.id === data.nominaId);
            if(nominaAsignada) {
                setNomina(data.nominaId);
                setNominaSelect(nominaAsignada);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nominas]);

    useEffect(() => {            
        //obtener listado de puestos
        axios.get(`${servicios_grupohit_org}/puesto/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const respuesta = res.data.dataResponse.map(item => {
                        let newItem = item;
                        newItem.cveNom = `${item.puestoCve} / ${item.puestoNom}`;
                        return newItem;
                    });
                    if(actualizar) {
                        const puesto = respuesta.find(puesto => puesto.id === data.puesto);
                        if(puesto) {
                            setPuestoSelected(puesto);
                            setPuesto(puesto.id);
                        }
                    }
                    setPuestos(respuesta);
                    break;
                default:
                    break;
            }
        })
        .catch(error => console.log(error));
   
        //obtener listado de domicilios de centros de trabajo
        axios.get(`${servicios_grupohit_admon}/vcentrotrabajo/${data.idCia}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const domicilios = res.data.dataResponse.map(direccion => ({
                        id: direccion.domcia,
                        ciaId: direccion.ciaId,
                        direccion: `${direccion.domciaCalle}${direccion.domCiaExt ? `, ${direccion.domCiaExt}` : ', S/N'}${direccion.domCiaNumInt ? `, ${direccion.domCiaNumInt}` : ''}, ${direccion.codigoColonia}`,
                        codigoCp: direccion.codigoCp,
                    }));
                    if(actualizar) {
                        const domicilio = domicilios.find(domicilio => domicilio.id === data.domicilio);
                        if(domicilio) {
                            setCtoTrabajoSelect(domicilio);
                            setCtoTrabajo(domicilio.id);
                        }
                    }
                    setCtosTrabajo(domicilios);
                    break;
                default:
                    break;
            }
        })
        .catch(error => console.log(error));
        //obtener listado de Status
        axios.get(`${servicios_grupohit_catalogo}/status/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setStatus(res.data.dataResponse);
                    break;
                default:
                    setStatus([]);
                    break;
            }
        })
        .catch(error => setStatus([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.idCia]);

    const agregar = e => {
        e.preventDefault();
        if(isSearching) return;
        if(validarSubmit('Plaza') && (puestoSelect !== null) && (ctoTrabajoSelect !== null) && (nominaSelect !== null)) {
            const clave = claveRef.current.value;
            enviar({ 
                id: data.id,
                clave,
                nivel: data.nivel,
                puesto: puestoSelect.id,
                domicilio: ctoTrabajoSelect.id,
                nominaId: nominaSelect.id,
                empleadoTitular: (empleadoTitularSelect) ? empleadoTitularSelect.id : null
            });
        } else {
            if(puestoSelect === null) setErrorPuesto(true);
            if(ctoTrabajoSelect === null) setErrorCtoTrabajo(true);
            if(nominaSelect === null) setErrorNomina(true);
        }
    };

    const muestraAlert = e => {
        e.preventDefault();
        if(validarSubmit('Plaza') && (puestoSelect !== null) && (ctoTrabajoSelect !== null) && (nominaSelect !== null)) {
            if(data.plazadisponible === false) {
                if((ctoTrabajoSelect.id === ctoTrabajo) && (nominaSelect.id === nomina)) {
                    setMensajeCambios(true);
                    return;
                }
            }
            if(data.plazadisponible === true) {
                if(
                    ctoTrabajoSelect.id === ctoTrabajo &&
                    puestoSelect.id === puesto &&
                    nominaSelect.id === nomina &&
                    data.plazaclave === claveRef.current.value &&
                    data.statusid === parseInt(statusRef.current.value) &&
                   ((empleadoTitularSelect) ? empleadoTitularSelect.id : null) === empleadoTitular
                ) {
                    setMensajeCambios(true);
                    return;
                }
            }
            setOpenAlertW(true);
        } else {
            if(puestoSelect === null) setErrorPuesto(true);
            if(ctoTrabajoSelect === null) setErrorCtoTrabajo(true);
            if(nominaSelect === null) setErrorNomina(true);
        }
    };

    const editar = () => {
        if(isSearching) return;

        setMensajeCambios(false);
        let clave = data.plazaclave;
        let statusPlaza = data.statusid;
        if(data.plazadisponible === true) {
            clave = claveRef.current.value;
            statusPlaza = parseInt(statusRef.current.value);
        }
        actualizar({
            id: data.id,
            plazaclave: clave,
            plazaidsup: data.plazaidsup,
            plazanivel: data.plazanivel,
            plazasecuencia: data.plazasecuencia,
            plazadisponible: data.plazadisponible,
            puesto: puestoSelect.id,
            domicilio: ctoTrabajoSelect.id,
            statusId: statusPlaza,
            nominaId: nominaSelect.id,
            empleadoTitular: (empleadoTitularSelect !== null) ? empleadoTitularSelect.id : null
        });
    };

    const validarPuesto = value => {
        if(value === null) {
            setPuestoSelected(null);
            setErrorPuesto(true);
        } else {
            if(enviar || data.plazadisponible === true) {
                setPuestoSelected(value);
                setErrorPuesto(false);   
            }
        }
    };

    const validarCtoTrabajo = value => {
        if(value === null) {
            setCtoTrabajoSelect(null);
            setErrorCtoTrabajo(true);
        } else {
            setCtoTrabajoSelect(value);
            setErrorCtoTrabajo(false);
        }
    };

    const validarTitular = value => {
        setEmpleadoTitularSelect(value);
    };

    const validarNomina = value => {
        if(value === null) {
            setNominaSelect(null);
            setErrorNomina(true);
        } else {
            setNominaSelect(value);
            setErrorNomina(false);
        }
    };

    const buscarEmpleados = () => {
        setMensajeCambios(false);
        const parametroBusquedaTitular = busquedaTitularRef.current.value;
        if(parametroBusquedaTitular.trim() === '') {
            setErrorBusqueda(<div className="col col-lg-12 col-md-12 col-sm-12"><AlertForm message="Debe capturar un parámetro de búsqueda."/></div>);
            return;
        }
        setIsSearching(true);
        setErrorBusqueda(null);

        axios.post(`${servicios_grupohit}/buscador/vempleado/`, { valor: parametroBusquedaTitular }, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                setEmpleadoTitularSelect(null);
                const empleadosNoTitulares = res.data.dataResponse.filter(i => !i.empTitular);
                if(empleadosNoTitulares.length === 0) {
                    setErrorBusqueda(
                        <div className="col col-lg-12 col-md-12 col-sm-12">
                            <AlertForm message="No hay coincidencias."/>
                        </div>
                    );
                }
                const empleadosBusqueda = empleadosNoTitulares.map(emp => {
                    emp.nueNombre = `${emp.empleadoClave} / ${(emp.empleadoPat) ? `${emp.empleadoPat} ` : ''}${(emp.empleadoMat) ? `${emp.empleadoMat} ` : ''}${emp.empleadoNom}`
                    return emp;
                });
                setEmpleados(empleadosBusqueda);
            } else {
                if(actualizar) {
                    setEmpleadoTitularSelect(null);
                    const empTitular = data.empleadoTitular;
                    if(empTitular) {
                        empTitular.nueNombre = `${data.empleadoTitular.empleadoclave} / ${(data.empleadoTitular.empleadopat) ? `${data.empleadoTitular.empleadopat} ` : ''}${(data.empleadoTitular.empleadomat) ? `${data.empleadoTitular.empleadomat} ` : ''}${data.empleadoTitular.empleadonom}`
                    }
                    setEmpleados([empTitular]);
                }
                setErrorBusqueda(<div className="col col-lg-12 col-md-12 col-sm-12"><AlertForm message={res.data.msg}/></div>);
            }
        })
        .catch(error => {
            if(actualizar) {
                setEmpleadoTitularSelect(null);
                const empTitular = data.empleadoTitular;
                if(empTitular) {
                    empTitular.nueNombre = `${data.empleadoTitular.empleadoclave} / ${(data.empleadoTitular.empleadopat) ? `${data.empleadoTitular.empleadopat} ` : ''}${(data.empleadoTitular.empleadomat) ? `${data.empleadoTitular.empleadomat} ` : ''}${data.empleadoTitular.empleadonom}`
                }
                setEmpleados([empTitular]);
            }
            setErrorBusqueda(<div className="col col-lg-12 col-md-12 col-sm-12"><AlertForm message={verifique_conexion}/></div>);
        })
        .finally(() => setIsSearching(false));
    };

    return (
        <Fragment>
            { ((actualizar && puestos.length && ctosTrabajo.length && nominas.length) || (!actualizar))
                ?   <form id="Plaza" onSubmit={ enviar ? agregar : muestraAlert } >
                        <div className="row">
                            <div className="col">
                                <InputValidation title="Número Único de Plaza (NUP)" type="clavePlaza" placeholder="Escriba el NUP"
                                    refValue={claveRef} defaultValue={ actualizar ? data.plazaclave : '' } 
                                    tooltipText="De 1 a 20 números, iniciando con un número del 1 al 9" maxLength={20} onlyNumbers required
                                    disabled={(actualizar) ? !data.plazadisponible : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <AutocompleteComponent id="acPuesto" title="Clave Puesto / Puesto" options={puestos} value={puestoSelect}
                                    optionListView="cveNom" action={validarPuesto} placeholder="Busque el Puesto, por nombre o clave, en el campo de autocompletado"
                                    tooltipText="Busque el Puesto, por nombre o clave, en el campo de autocompletado" error={errorPuesto}
                                    disabled={(actualizar) ? !data.plazadisponible : false} required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                            {(nominas !== null)
                            ?   <AutocompleteComponent id="acNominaPlaza" title="Nómina" options={nominas} value={nominaSelect}
                                    optionListView="nominaNombre" action={validarNomina} placeholder="Busque la Nómina, por nombre o clave, en el campo de autocompletado"
                                    tooltipText="Busque la Nómina, por nombre o clave, en el campo de autocompletado" error={errorNomina}
                                    disabled={(actualizar) ? (nomina) ? !data.plazadisponible : false : (nominas !== null) ? false : true}
                                    required
                                />
                            :   <SpinnerOval/>
                            }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <AutocompleteComponent id="acCentroTrabajo" title="Identificador de la Plaza / Centro de Trabajo" options={ctosTrabajo} value={ctoTrabajoSelect}
                                    optionListView="direccion" action={validarCtoTrabajo} placeholder="Busque el Centro de Trabajo, por código postal o calle, en el campo de autocompletado"
                                    required tooltipText="Busque el Centro de Trabajo, por código postal o calle, en el campo de autocompletado" error={errorCtoTrabajo}
                                />
                            </div>
                        </div>
                        {(actualizar && data.plazadisponible) || !actualizar
                        ?   <div className="row">
                                <div className="col col-lg-3 col-md-8 col-sm-8">
                                    <InputValidation
                                        title="Buscar Empleados" type="text" refValue={busquedaTitularRef} defaultValue=""
                                        placeholder="Escriba los datos generales del empleado a buscar"
                                        tooltipText="Buscar empleados por datos generales para seleccionar el titular de la plaza." maxLength={100}
                                        required optional
                                    />
                                </div>
                                {isSearching
                                ?   <div className="col col-lg-9 col-md-4 col-sm-4 mt-3">
                                        <SpinnerOval/>
                                    </div>
                                :   <Fragment>
                                        <div className="col col-lg-1 col-md-4 col-sm-4 align-self-end mb-2">
                                            <TooltipComponent tooltipText="Buscar">
                                                <button type="button" className="btn-autocomplete" onClick={buscarEmpleados}><SearchIcon/></button>
                                            </TooltipComponent>
                                        </div>
                                        <div className="col col-lg-8 col-md-12 col-sm-12">
                                            <AutocompleteComponent id="acTitular" title="Titular de la Plaza" options={empleados} value={empleadoTitularSelect}
                                                optionListView="nueNombre" action={validarTitular} placeholder="Busque el empleado Titular de la Plaza, por NUE o nombre, en el campo de autocompletado"
                                                tooltipText="Busque el empleado Titular de la Plaza, por NUE o nombre, en el campo de autocompletado"
                                            />
                                        </div>
                                    </Fragment>
                                }
                                { errorBusqueda }
                            </div>
                        :   <div className="row">
                                <div className="col">
                                    <AutocompleteComponent
                                        id="acCentroTrabajo" title="Titular de la Plaza" options={empleados} value={empleadoTitularSelect}
                                        optionListView="nueNombre" disabled
                                    />
                                </div>
                            </div>
                        }
                        {(actualizar) &&
                        <div className="row">
                            <div className="col">
                                <SelectValidation
                                    title="Status" arrayOpciones={status} keyOpcion="statusNom"
                                    refValue={statusRef} defaultValue={data.statusid} tooltipText="Seleccione el status de la plaza"
                                    disabled={!data.plazadisponible} required
                                />
                            </div>
                        </div>
                        }
                        {(mensajeCambios) &&
                            <AlertForm message="¡No hay cambios por guardar!" />
                        }
                        <div className="row">
                            <div className="col">
                                <Button variant="contained" className="btn-color" type="submit" disabled={isSearching ? true : false}>{ enviar ? 'Agregar' : 'Actualizar' }</Button>
                            </div>
                        </div>
                    </form>
                :   <SpinnerOval />
            }
            <AlertWarning show={openAlertW} SetopenALert={setOpenAlertW} text={registro_sera_actualizado}  textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={editar}/>
        </Fragment>
    );
}
 
export default FormPlaza;