import React, { createRef, useState, Fragment, useEffect } from 'react';
import {Button } from '@material-ui/core';
import { validarSubmit, inputDateToLong, amountToFloat } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado, servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../Constants/Constants';
import Axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioSalarioMinUMA = ({ error, setError, enviarSalarioMinUMA, elementSelect, actualizarSalarioMinUMA, dataTable, isLoading }) => {
    
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const tipoSalarioRef = createRef('');
    const montoRef = createRef('');
    const fechaInicialRef = createRef('');
    const fechaFinalRef = createRef('');
    const enviar = e => {
        e.preventDefault();
        
        if(validarSubmit('AgregarSalarioMinUMA')) {
            setError(null);
            const zona = parseInt(tipoSalarioRef.current.value);
            const monto = amountToFloat(montoRef.current.value);
            const fechaIni = inputDateToLong(fechaInicialRef.current.value);
            const fechaFin = fechaFinalRef.current.value!=='' ? inputDateToLong(fechaFinalRef.current.value) : null; 
            const filterDataZona = dataTable.filter(element => element.zonaId === zona); 
            const filterOrderDataZona = filterDataZona.sort((a, b) => inputDateToLong(a.fechaIniInp) > inputDateToLong(b.fechaIniInp) ? 1 : inputDateToLong(a.fechaIniInp) < inputDateToLong(b.fechaIniInp) ? -1 : 0);
  
            const index = filterOrderDataZona.length-1;

            const lastFechaIncial = filterOrderDataZona[index] ? inputDateToLong(filterOrderDataZona[index].fechaIniInp) : null;
            const lastFechaIncialFormat = filterOrderDataZona[index] ? filterOrderDataZona[index].fechaIni : null;  
            const lastFechaFinal = filterOrderDataZona[index] ? inputDateToLong(filterOrderDataZona[index].fechaFinInp) : null;
            const lastFechaFinalFormat = filterOrderDataZona[index] ? filterOrderDataZona[index].fechaFin : null;  

            if(((lastFechaIncial !== null) & (fechaIni > lastFechaIncial)) || lastFechaIncial === null) {
                if(((lastFechaFinal !== null) && (fechaIni > lastFechaFinal)) || lastFechaFinal === null) {
                    if(fechaFin === null) {
                        setError(null);
                        enviarSalarioMinUMA({ zona, monto, fechaIni });
                    }else{
                        if(fechaFin < fechaIni){
                            setError( <AlertForm message="La fecha final no puede ser menor o igual a la fecha inicial" /> );
                        }else{
                            setError(null);
                            enviarSalarioMinUMA({ zona, monto, fechaIni, fechaFin });
                        }
                    } 
                } else {
                    setError( <AlertForm message={ `La fecha inicial debe ser mayor a ${lastFechaFinalFormat}` } /> );
                }
            }else{
                setError( <AlertForm message={ `La fecha incial debe ser mayor a ${lastFechaIncialFormat}` } /> );
            }   
        }
    }; 
    
    const actualizar = () => {
        if(validarSubmit('EditarSalarioMinUMA')) {
            const zona = parseInt(tipoSalarioRef.current.value);
            const monto = amountToFloat(montoRef.current.value);
            const fechaIni = inputDateToLong(fechaInicialRef.current.value);
            const fechaFin = fechaFinalRef.current.value!=='' ? inputDateToLong(fechaFinalRef.current.value) : null; 
            

            const filterDataZona = dataTable.filter(element => element.zonaId === zona );
            const filterOrderDataZona = filterDataZona.sort((a, b) => inputDateToLong(a.fechaIniInp) > inputDateToLong(b.fechaIniInp) ? 1 : inputDateToLong(a.fechaIniInp) < inputDateToLong(b.fechaIniInp) ? -1 : 0);
 
            const indexElementSelect = filterOrderDataZona.findIndex(element => element.id === elementSelect.id );

            const limiteInferior = filterOrderDataZona[indexElementSelect-1] ? inputDateToLong( filterOrderDataZona[indexElementSelect-1].fechaFinInp ) : null;
            const limiteSuperior = filterOrderDataZona[indexElementSelect+1] ? inputDateToLong( filterOrderDataZona[indexElementSelect+1].fechaIniInp ) : null;
            
            const limiteInferiorFormat = filterOrderDataZona[indexElementSelect-1] ? filterOrderDataZona[indexElementSelect-1].fechaFin : null;
            const limiteSuperiorFormat = filterOrderDataZona[indexElementSelect+1] ? filterOrderDataZona[indexElementSelect+1].fechaIni : null;
            
            
            if(((limiteInferior !== null) & (fechaIni > limiteInferior)) || limiteInferior === null) {
                if(((limiteSuperior !== null) && (fechaIni < limiteSuperior)) || limiteSuperior === null) {
                    if(fechaFin===null) {
                        setError(null);
                        actualizarSalarioMinUMA({ zona, monto, fechaIni });
                    }else{ 
                        if(((limiteSuperior !== null) && (fechaFin < limiteSuperior)) || limiteSuperior === null) {
                            if(fechaFin < fechaIni) {
                                setError( <AlertForm message="La fecha final debe ser mayor a la fecha inicial" /> );
                            } else {
                                setError(null);
                                actualizarSalarioMinUMA({ zona, monto, fechaIni, fechaFin });
                            }
                        } else {
                            setError( <AlertForm message={ `La fecha final debe ser menor a ${limiteSuperiorFormat}` } /> );
                        }
                    } 
                } else {
                    setError( <AlertForm message={ `La fecha inicial debe ser menor a ${limiteSuperiorFormat}` } /> );
                }
            } else {
                setError( <AlertForm message={ `La fecha inicial debe ser mayor a ${limiteInferiorFormat} ` } /> );
            }
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    }; 
    
    const [zonas, setZonas] = useState(null);
     
    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}s/zonas/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setZonas(res.data.dataResponse);
                    break;
                case 404:
                    setZonas([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return ( 
        <Fragment> 
        <form id={ elementSelect ? 'EditarSalarioMinUMA' : 'AgregarSalarioMinUMA'} onSubmit={ elementSelect ? mostrarAlert : enviar  }> 
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    {
                        zonas
                        ?
                        <SelectValidation title="Tipo de Salario" arrayOpciones={zonas} keyOpcion="zonaNom" refValue={tipoSalarioRef}
                            defaultValue={elementSelect ? elementSelect.zonaId : ''} required/>
                        :null
                    } 
                </div>
                <div className="col-md-6 col-sm-12"> 
                    <InputValidation title="Monto" type="monto" placeholder="Escriba el Monto"
                        tooltipText={'Máximo 128 números y 2 decimales'} tooltipName={'tooltipMonSalMinUMA'} maxLength={128}
                        refValue={montoRef} required defaultValue={elementSelect ? elementSelect.monto :''} onlyNumbers = '.' onBlurDecimal={2} isAmount/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12">  
                    <InputDateValidation title="Fecha Inicial" refValue={fechaInicialRef} required
                        defaultValue={ elementSelect ? elementSelect.fechaIniInp : '' } />
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} optional
                        defaultValue={ elementSelect ? elementSelect.fechaFinInp : ''  } /> 
                </div>
            </div> 
            {error} 
            {(isLoading)
            ?   <SpinnerOval />
            :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button> 
            }
        </form>
        <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
export default FormularioSalarioMinUMA;
