import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, servicios_grupohit_catalogo, nominas_nominas } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
//import { ArrayJsonTipoDocumentos } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const NominasOrganizacion = ({ id, permisos }) => {
    const [ nominas, setNominas ] = useState( null );
    const [ tiposPeriodicidad, setTiposPeriodicidad ] = useState( null );
    const [ origenRecursos, setOrigenRecursos ] = useState( null );
    const [ regimenFiscales, setRegimenFiscales ] = useState( null );
    const [ tiposRegimen, setTiposRegimen ] = useState( null );
    const [ tiposPeriodo, setTiposPeriodo ] = useState( null );
    useEffect(() => {
        axiosNomninas();
        axiosTiposPeriodicidad();
        axiosOrigenRecursos();
        axiosRegimenFiscales();
        axiosTiposRegimen();
        axiosTiposPeriodo();
    }, []);

    const axiosNomninas = () => {
        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //setTiposDocumentos( ArrayJsonTipoDocumentos( respuesta.data.dataResponse ) );
                        setNominas( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setNominas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio TiposPeriodicidad */
    const axiosTiposPeriodicidad = () => {
        axios.get( `${servicios_grupohit_catalogo}/periodicidad`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const sortPeriodicidad = respuesta.data.dataResponse.sort((x, y) => {
                            if (x.perdenomNombre < y.perdenomNombre) {return -1;}
                            if (x.perdenomNombre > y.perdenomNombre) {return 1;}
                            return 0;
                        });
                        setTiposPeriodicidad(sortPeriodicidad);
                        break;
                    case 404:
                        //setTiposPeriodicidad([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio OrigenRecursos */
    const axiosOrigenRecursos = () => {
        axios.get( `${servicios_grupohit_catalogo}/origenrecurso/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const sortOrigenRecursos = respuesta.data.dataResponse.sort((x, y) => {
                            if (x.origenRecDesc < y.origenRecDesc) {return -1;}
                            if (x.origenRecDesc > y.origenRecDesc) {return 1;}
                            return 0;
                        });
                        setOrigenRecursos(sortOrigenRecursos);
                        break;
                    case 404:
                        //setOrigenRecursos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio RegimenFiscal */
    const axiosRegimenFiscales = () => {
        axios.get( `${servicios_grupohit_catalogo}/regfiscal/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const sortRegimenFiscal = respuesta.data.dataResponse.sort((x, y) => {
                            if (x.regFiscalDesc < y.regFiscalDesc) {return -1;}
                            if (x.regFiscalDesc > y.regFiscalDesc) {return 1;}
                            return 0;
                        });
                        setRegimenFiscales(sortRegimenFiscal);
                        break;
                    case 404:
                        //setRegimenFiscales([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio TiposRegimen */
    const axiosTiposRegimen = () => {
        axios.get( `${servicios_grupohit_catalogo}/tiporegimen/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const sortEsquemaPago = respuesta.data.dataResponse.sort((x, y) => {
                            if (x.regimenDesc < y.regimenDesc) {return -1;}
                            if (x.regimenDesc > y.regimenDesc) {return 1;}
                            return 0;
                        });
                        setTiposRegimen(sortEsquemaPago);
                        break;
                    case 404:
                        //setTiposRegimen([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio TipoPeriodo */
    const axiosTiposPeriodo = () => {
        axios.get( `${servicios_grupohit_catalogo}s/tipo/periodo/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const arraySinSemanal = respuesta.data.dataResponse.filter(item => item.id !== 4);
                        const jsonSemanal = respuesta.data.dataResponse.find(item => item.id === 4);
                        setTiposPeriodo((jsonSemanal) ? [jsonSemanal, ...arraySinSemanal] : arraySinSemanal);
                        break;
                    case 404:
                        //setTiposPeriodo([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { nominas && tiposPeriodicidad && origenRecursos && regimenFiscales && tiposRegimen && tiposPeriodo ?
                <DataTableService id={id} title={ nominas_nominas } permisos={permisos}
                    columns={[
                        { field: "nominaClave", title: "Clave" },
                        { field: "nominaNomCorto", title: "Nombre Corto" },
                        { field: "nominaNombre", title: "Nombre Nómina" },
                        { field: "nominaDescrip", title: "Descripción" },
                    ]}
                    data={ nominas }
                    informacionAdicional={{ tiposPeriodicidad, origenRecursos, regimenFiscales, tiposRegimen, tiposPeriodo }} /> : <SpinnerOval />
            }
        </div>
    );
}
 
export default NominasOrganizacion;