import React, { useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './../../Administracion/Usuarios/AsignacionModulos/CollapseMaterial/MaterialTheme';

import InformacionMovimientosExcel from './DataTableMovimientos/InformacionMovimientosExcel';
import ModalFormatoEjemplo from './DataTableMovimientos/ModalFormatoEjemplo';

const InformacionVariables = ({ idModulo, errorTable, successTable, nombreModulo }) => {
    const [ openFormato, setOpenFormato ] = useState( false );
    /* ----------------------------- Axios para opbtener empleados activos ----------------------------- */
    return (
        <MuiThemeProvider theme={theme}>
            <div className="linkEjemploMov my-3" onClick={() => setOpenFormato(true)}> Ver Formato de Ejemplo </div>

            <InformacionMovimientosExcel
                idModulo={idModulo}
                errorTable={errorTable}
                successTable={successTable}
                nombreModulo={nombreModulo}
            />
            <ModalFormatoEjemplo openFormato={openFormato} setOpenFormato={setOpenFormato} idModulo={idModulo}/>
        </MuiThemeProvider>
    );
};

export default InformacionVariables;