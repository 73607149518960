import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_catalogo, ocurrio_un_error, colonias_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import FormularioConsultaCP from './FormulariosColonia/FormularioConsultaCP';
import AlertForm from '../../../../../../Services/AlertForm';
import ModalAddCodigoPostal from './FormulariosColonia/ModalAddCodigoPostal';

const Colonias = ({ id, permisos }) => {
    const [ error, setError ] = useState( null );
    const [ peticion, setPeticion ] = useState( false );
    const [ colonias, setColonias ] = useState( null );
    const [ informacion, setInformacion ] = useState( null );
    /* -------------------------------- useEffect() para peticiones de efecto [] -------------------------------- */
    const [ estados, setEstados ] = useState( null );
    useEffect(() => {
        axiosEstados();
    }, []);

    const axiosEstados = () => {
        axios.get( `${servicios_grupohit_catalogo}s/estado/1`, headersAuth() ) /* País 1: México */
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEstados( respuesta.data.dataResponse );
                        break;
                    case 404:
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------- Metodo axios para consultar Colonias por Código Postal ------------------------- */
    const axiosColonias = ( postalCode ) => {
        setPeticion( true );
        axios.get( `${servicios_grupohit_catalogo}s/colonias/${postalCode}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const { paisNom, estadoNom, ciudadNom, colonias } = respuesta.data.dataResponse;
                        setError( null );
                        setColonias( colonias );
                        setInformacion({ postalCode, paisNom, estadoNom, ciudadNom });
                        break;
                    case 404:
                        setError(
                            <React.Fragment>
                                <AlertForm message={ respuesta.data.msg }/>
                                <ModalAddCodigoPostal informacion={{ estados, postalCode }} axiosColonias={axiosColonias} idModulo={id}/>
                            </React.Fragment>
                        );
                        setColonias( null );
                        break;
                    default:
                        setError( <AlertForm message={ ocurrio_un_error }/> );
                        setColonias( null );
                        break;
                }
                setPeticion( false );
            })
            .catch( error => {
                setError( <AlertForm message={ ocurrio_un_error }/> );
                setPeticion( false );
            })
    }
    /* ------------------------------------------------ return() ------------------------------------------------ */
    return (
        <div id={`modulo${id}`}>
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    <FormularioConsultaCP error={error} axiosColonias={axiosColonias} peticion={peticion}/>
                </div>
            </div>
            { !peticion ?
                colonias && informacion &&
                <DataTableService id={id} title={ colonias_ } permisos={permisos}
                    columns={[
                        { field: "codigopColonia", title: "Colonia" }
                    ]}
                    data={colonias}
                    informacionAdicional={informacion} />
                :
                <SpinnerOval />
            }
        </div>
    );
}
 
export default Colonias;