import React, { createRef, useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, servicios_grupohit_admon, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button'; 
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { validarSubmit, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import { ArrayJsonNominas, ArrayJsonDependencias } from '../../../Helpers/JsonToOneLevel';
import AlertForm from '../../../../../../Services/AlertForm';

import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const FormDispersionBancos = ({ error, setError, enviarDispersion, elementSelect }) => {
    const [ nominas, setNominas ] = useState( [] );
    const [ dependencias, setDependencias ] = useState( null );
    const [ agniosNomina, setAgniosNomina ] = useState([]); 
    const [ periodosAgnio, setPeriodosAgnio ] =  useState([]);
    const [tiposAcumulado, setTiposAcumulado] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);
 
    const nominaRef = createRef('');
    const agnioRef = createRef('');
    const periodoDispersarRef = createRef('');
    const tipoAcumuladoRef = createRef('');
    const periodicidadDispersarRef = createRef('');
    const emisoraRef = createRef('');
    const numEmpresaRef = createRef('');
    const fechaPagoRef = createRef('');
    const fechaProcesoRef = createRef(''); 
    const numClienteRef = createRef('');
    const cuentaCargoRef = createRef(''); 
    const secuenciaArchivoRef = createRef('');
    const consecutivoRef = createRef('');

    //Ref's adicionales BANAMEX
    const [ compania, setCompania ] = useState( null );
    const [ errorCompania, setErrorCompania ] = useState( null );
    //const relabIdRef = createRef('');
    const numSucursalRef = createRef('');
    const NomSecretariaODepenRef = createRef('');
    const DescpArchivoRef = createRef('');

    useEffect(() => {
        axiosNominas();
        axiosDependencias();
        // eslint-disable-next-line
    }, []);

    const axiosNominas = () => {
        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( ArrayJsonNominas( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        setNominas( [] );
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    /* Axios para consumo de servicio Dependencias(Compañias) */
    const axiosDependencias = () => {
        axios.get( `${servicios_grupohit_admon}/compania`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDependencias( ArrayJsonDependencias(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        setDependencias([]);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    const enviar = () => {
        if( validarSubmit( 'AgregarDispersionBancos' ) ){
            const nomina = parseInt(nominaRef.current.value);
            const agnio = parseInt(agnioRef.current.value);
            const periodoDispersar = parseInt(periodoDispersarRef.current.value);
            const tipoAcumulado = parseInt(tipoAcumuladoRef.current.value);
            const nombreNomina = nominas.filter(element => element.id === nomina)[0].nominaNomCorto;
            const agnioArchivo = agnio;
            const periodoArchivo = periodosAgnio.filter(element=> element.id === periodoDispersarRef.current.value)[0].periodoPerAnio;

            switch (elementSelect.id){
                case 40: //BANAMEX
                    if( compania ){
                        const fechaPago = parseInt(fechaPagoRef.current.value.replace(/-/g, ''));
                        const numeroCliente = parseInt(numClienteRef.current.value);
                        const secuenciaArchivo = parseInt(secuenciaArchivoRef.current.value);
                        const cuentaCargo = parseInt(cuentaCargoRef.current.value);

                        //const relabId = parseInt(relabIdRef.current.value) ? parseInt(relabIdRef.current.value) : '';
                        const numSucursal = parseInt(numSucursalRef.current.value);
                        const NomSecretariaODepen = NomSecretariaODepenRef.current.value;
                        const DescpArchivo = DescpArchivoRef.current.value;
                        //console.log({ nomina, agnio, periodoDispersar, tipoAcumulado, periodicidadDispersar, fechaPago, numCliente, secuenciaArchivo, cuentaCargo, referenciaPago }, elementSelect.id);
                        enviarDispersion( deleteOptionals({ periodoId: periodoDispersar, acumuladoId: tipoAcumulado, cuentaCargo, secuenciaArchivo, fechaPago, numeroCliente, periodoPerAnio: parseInt(periodoArchivo),
                                ciaId: compania.id, nominaId: nomina, numSucursal, NomSecretariaODepen, DescpArchivo }),
                            {nombreNomina:nombreNomina.replace( /\./ , ''), agnioArchivo, periodoArchivo} );
                    }else{
                        setErrorCompania( <AlertForm message="Selecciona una compañia" /> );
                    }
                break;
                case 44: //SANTANDER
                    const periodicidadDispersarSantander = parseInt(periodicidadDispersarRef.current.value);
                    const fechaPagoSantander = parseInt(fechaPagoRef.current.value); 
                    const cuentaCargoSantander = parseInt(cuentaCargoRef.current.value); 
                    console.log({ nomina, agnio, periodoDispersar, tipoAcumulado, periodicidadDispersarSantander,fechaPagoSantander, cuentaCargoSantander }, elementSelect.id);
                    //enviarDispersion({ nomina, agnio, periodoDispersar, tipoAcumulado, periodicidadDispersarSantander,fechaPagoSantander, cuentaCargoSantander }, elementSelect.id);
                break;
                case 43: //BANCOMER
                    const numEmpresa = parseInt(numEmpresaRef.current.value);
                    const fechaPagoBancomer = parseInt(fechaPagoRef.current.value);
                    console.log({ nomina, agnio, periodoDispersar, tipoAcumulado, numEmpresa,fechaPagoBancomer }, elementSelect.id);
                    //enviarDispersion({ nomina, agnio, periodoDispersar, tipoAcumulado, numEmpresa,fechaPagoBancomer }, elementSelect.id);
                break;
                case 57: //BANORTE
                    const periodoId = periodoDispersar;
                    const acumuladoId = tipoAcumulado;
                    const emisora = emisoraRef.current.value.padStart(5,'0');
                    const consecutivo = consecutivoRef.current.value.padStart(2,'0');
                    const fechaProceso = fechaProcesoRef.current.value.replace(/-/g, '');
                    enviarDispersion({ periodoId, acumuladoId, emisora, consecutivo, fechaProceso }, 
                                     {nombreNomina:nombreNomina.replace( /\./ , ''), agnioArchivo, periodoArchivo} );
                break;
                default:
                break;
            }           
        }  
    }

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const searchAgnioNomina = (nominaId) =>{ 
        if(nominaId!==""){
            setAgniosNomina([]); 
            setError(null); 
            axios.get( `${servicios_grupohit_nomina}/acumulados/periodo/${nominaId}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setAgniosNomina(respuesta.data.dataResponse);  
                        setPeriodosAgnio([]);
                        setTiposAcumulado([]);
                        break;
                    default:
                        setAgniosNomina([]);  
                        setPeriodosAgnio([]);
                        setTiposAcumulado([]);
                        setError(<AlertForm message={respuesta.data.msg} />);
                        break;
                }
            })
            .catch( error => {
                setAgniosNomina([]);  
                setPeriodosAgnio([]);
                setTiposAcumulado([]);
                setError(<AlertForm message={verifique_conexion} />);
            });
        }else{ 
            setAgniosNomina([]);  
            setPeriodosAgnio([]);
            setTiposAcumulado([]);
            setError(null);
        }
    }

    const searchPeriodosNomina = ( periodoID ) => { 
        if(periodoID!==""){
            setPeriodosAgnio([]);
            setError(null); 
            axios.get( `${servicios_grupohit_nomina}/acumulados/peranio/${nominaRef.current.value}/${periodoID}`, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            let data = respuesta.data.dataResponse.map(item => (
                                {
                                    id: item.periodoId,
                                    periodoPerAnio: (item.periodoPerAnio === 10) ? '9/10' : (item.periodoPerAnio === 24) ? '23/24' : item.periodoPerAnio, 
                                }
                            ));
                            data = data.filter(p => (p.periodoPerAnio !== 9) && (p.periodoPerAnio !== 23));
                            setPeriodosAgnio(data); 
                            setTiposAcumulado([]);
                            break;
                        default:
                            setPeriodosAgnio( [] ); 
                            setTiposAcumulado([]);
                            setError(<AlertForm message={respuesta.data.msg} />);
                            break;
                    }
                })
                .catch( error => {
                    setPeriodosAgnio( [] ); 
                    setTiposAcumulado([]);
                    setError(<AlertForm message={verifique_conexion} />); 
                });
        }else{ 
            setPeriodosAgnio([]);
            setTiposAcumulado([]);
            setError(null);
        }
    }

    const searchAcumulado = ( value ) =>{
        setError(null); 
        if(value!==''){
            setTiposAcumulado([]);
            const periodoId = parseInt(periodoDispersarRef.current.value);
            axios.get(`${servicios_grupohit_nomina}/acumulados/nomina/${parseInt(nominaRef.current.value)}/${periodoId}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const data = res.data.dataResponse.map(item => (
                            {
                                id: item.acumuladoId,
                                acumuladoNombre: item.acumuladoNombre, 
                            }
                        ));
                        setTiposAcumulado(data);
                        break;
                    default:
                        setTiposAcumulado([]);
                        setError(<AlertForm message={res.data.msg} />); 
                        break;
                }
            })
            .catch(error => {
                setTiposAcumulado([]);
                setError(<AlertForm message={verifique_conexion} />); 
            });
        }else{
            setTiposAcumulado([]);
            setError(null); 
        }
    }

    const validarCompania = (valor) => {
        if(valor) {
            //console.log( valor );
            setErrorCompania( null );
            setCompania( valor );
        }else{
            setErrorCompania( true );
            setCompania( null );
        }
    };

    return (  
        <Fragment> 
            <form id={ 'AgregarDispersionBancos' } onSubmit={mostrarAlert}>
                { elementSelect.id === 40 && //BANAMEX
                    <AutocompleteComponent id="companiaDispBanc" title="Compañia" placeholder="Seleccione la compañia" size="100%"
                        tooltipName="tooltipCompaniaDispBanc" tooltipText={'Busque la compañia, por nombre o clave, en el campo de autocompletado'} action={validarCompania}
                        options={dependencias} optionListView={'ciaRazonSocial'} optionKey="ciaUr" error={errorCompania} required
                        value={compania}/>
                }
                <div className="row">  
                    <div className="col-md-12 col-sm-12">
                        <SelectValidation title="Nómina" arrayOpciones={nominas} refValue={nominaRef} keyOpcion="nominaClaveNombre" required
                            tooltipName={'tooltipTipNominaDispBanc'} tooltipText={'Seleccione una Nómina'} onChangeExecute={searchAgnioNomina}/> 
                    </div> 
                </div>
                <div className="row">  
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Año" arrayOpciones={agniosNomina} refValue={agnioRef} keyOpcion="periodoAnio" required
                            tooltipName={'tooltipTipNominaDispBanc'} tooltipText={'Seleccione un Año'} disabled={agniosNomina.length===0} 
                            onChangeExecute={searchPeriodosNomina}/> 
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo a Dispersar" arrayOpciones={periodosAgnio} refValue={periodoDispersarRef} keyOpcion="periodoPerAnio" required
                            tooltipName={'tooltipTipNominaDispBanc'} tooltipText={'Seleccione un Periodo'} onChangeExecute={searchAcumulado} disabled={periodosAgnio.length===0}/> 
                    </div> 
                </div>
                <div className="row">  
                    <div className={elementSelect.id===40 ? "col-md-12 col-sm-12" : "col-md-6 col-sm-12"}>
                        <SelectValidation title="Tipo de Acumulado" arrayOpciones={tiposAcumulado} keyOpcion="acumuladoNombre"
                                tooltipText="Seleccione el Tipo de Acumulado" tooltipName="tooltipTipoAcumulado"  disabled={tiposAcumulado.length===0}
                                refValue={tipoAcumuladoRef} required/>
                    </div>
                    {
                        elementSelect.id===40 || elementSelect.id === 44 //BANAMEX Y SANTANDER
                        ?
                            elementSelect.id === 44 &&
                            <div className="col-md-6 col-sm-12"> 
                                    <InputValidation title="Periodicidad a Dispersar" type="periodicidad" 
                                        placeholder="Escriba la clave de la periodicidad a dispersar" refValue={periodicidadDispersarRef}  
                                        tooltipText={'5 caracteres alfanuméricos'} tooltipName={'tooltipPeriodDispBanc'} maxLength={5} required/>
                                </div>
                        :
                        elementSelect.id === 57//BANORTE
                        ? 
                            <div className="col-md-6 col-sm-12"> 
                                <InputValidation title="Emisora" type="emisora" 
                                    placeholder="Escriba la clave de la Emisora" refValue={emisoraRef}  
                                    tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipEmisoraDispBanc'} maxLength={5} onlyNumbers required/>
                            </div>
                        : //BANCOMER	
                            <div className="col-md-6 col-sm-12"> 
                                <InputValidation title="Número de Empresa" type="numEmpresa" 
                                    placeholder="Escriba el número de la empresa" refValue={numEmpresaRef}  
                                    tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipnumEmpresaDispBanc'} maxLength={5} required/>
                            </div> 
                    }
                </div>
                <div className="row">
                    {
                        elementSelect.id !== 57 //DIFERENTES A BANORTE
                        ? 
                        <Fragment>
                            <div className={elementSelect.id === 43 ? "col-md-12 col-sm-12" : "col-md-6 col-sm-12"}>
                                <InputDateValidation title="Fecha de Pago" refValue={fechaPagoRef} required/>
                            </div>
                            {
                                elementSelect.id === 40 //BANAMEX
                                ?
                                    <div className="col-md-6 col-sm-12"> 
                                        <InputValidation title="Número de Cliente" type="numCliente" 
                                            placeholder="Escriba el número de cliente" refValue={numClienteRef}  onlyNumbers
                                            tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipnumClienteDispBanc'} maxLength={5} required/>
                                    </div>   
                                :
                                elementSelect.id === 44 //SANTANDER
                                ?
                                    <div className="col-md-6 col-sm-12"> 
                                        <InputValidation title="Cuenta de Cargo" type="cuentaCarco" 
                                            placeholder="Escriba la cuenta de cargo" refValue={cuentaCargoRef}  
                                            tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipnumCuentaCargoDispBanc'} maxLength={5} required/>
                                    </div>    
                                :
                                null
                            }
                        </Fragment>
                        : // BANORTE
                        <Fragment>
                            <div className="col-md-6 col-sm-12"> 
                                <InputValidation title="Consecutivo" type="consecutivo" 
                                    placeholder="Escriba el consecutivo" refValue={consecutivoRef}  
                                    tooltipText={'2 caracteres numéricos'} tooltipName={'tooltipConsecutivoDispBanc'} maxLength={2} onlyNumbers required/>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <InputDateValidation title="Fecha de Proceso" refValue={fechaProcesoRef} required/>
                            </div>
                        </Fragment> 
                    }  
                </div>
                { elementSelect.id === 40 && //BANAMEX
                    <Fragment>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <InputValidation title="Secuencia de Archivo" type="secuenciaArchivo"
                                    placeholder="Escriba la secuencia de archivo" refValue={secuenciaArchivoRef}  onlyNumbers
                                    tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipSecuenciaArchivoDispBanc'} maxLength={5} required/>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <InputValidation title="Cuentas de Cargo" type="cuentaCarco"
                                    placeholder="Escriba la cuenta de cargo" refValue={cuentaCargoRef}  onlyNumbers
                                    tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipnumCuentaCargoBanaDispBanc'} maxLength={5} required/>
                            </div>
                        </div>

                        <div className="row">
                            {/* <div className="col-md-6 col-sm-12">
                                <InputValidation title="Relación Laboral" type="secuenciaArchivo"
                                    placeholder="Escriba la relación laboral" refValue={relabIdRef} onlyNumbers
                                    tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipRelacionLaboralDispBanc'} maxLength={5} optional={true}/>
                            </div> */}
                            <div className="col-md-6 col-sm-12">
                                <InputValidation title="Numéro de Sucursal" type="secuenciaArchivo"
                                    placeholder="Escriba el numéro de la sucursal" refValue={numSucursalRef} onlyNumbers
                                    tooltipText={'5 caracteres numéricos'} tooltipName={'tooltipNumeroSucursalDispBanc'} maxLength={5} required/>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <InputValidation title="Nombre de la Secretaria o Dependencia" type="descripcionUsuario" placeholder="Escriba el nombre de la secretaria o dependencia" maxLength='512'
                                    tooltipText={'Máximo 512 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNombreSecretariaDispBanc'}
                                    refValue={NomSecretariaODepenRef} optional={true} />
                            </div>
                        </div>

                        <InputValidation title="Descripción del Archivo" type="descripcionUsuario" placeholder="Escriba la descripción del archivo" maxLength='512'
                            tooltipText={'Máximo 512 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescripcionArchivoDispBanc'}
                            refValue={DescpArchivoRef} optional={true} />
                    </Fragment>
                }
                {error}
                {errorCompania}
                <Button variant="contained" className="btn-color" type="submit"> Agregar </Button>
            </form> 
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={'¡Con esta información se generará su archivo de pago!'} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviar}/>
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}

export default FormDispersionBancos;