import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService';
/**Componentes */
import VentanaChat from '../../Chat/VentanaChat';
/* Helpers */
import displayWindowSize from '../../Helpers/DisplayWindowSize';
import { servicios_grupohit } from '../../../../../Constants/Constants';
/**Material-ui */
import Tooltip from '@material-ui/core/Tooltip';
import profileIMG from '../../../../../Media/Img/profile.jpg';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { red, green, blue } from '@material-ui/core/colors';
import UsersSearchComponent from '../../Chat/UsersSearchComponent';
import { pushError } from '../../../../../Services/Notifications/PushService';

const drawerWidth = 300;

const StyledBadge = withStyles(() => ({
  badge: {
    top: '30%',
    right: 2,
    // The border color match the background color.
    backgroundColor: blue[500],
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
  },
}))(Badge);

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  connected:{
    color: green[500],
    fontSize: 10,
    position: 'relative',
    bottom: 14,
  },
  disconnected:{
    color: red[500],
    fontSize: 10,
    position: 'relative',
    bottom: 14,
  },
}));

export default function PersistentDrawerRight({open, setOpen, socket, usuario, mensajeNuevo, setMensajeNuevo, tour}) {

  const classes = useStyles();
  const theme = useTheme();
  //usuarios activos
  const [activos, setActivos] = useState([]);
  /* arreglo de usuarios */
  const [usuarios, setUsuarios] = useState([]);
  /* arreglo de usuarios */
  const [imgUsuario, setImgUsuario] = useState('');
  /* arreglo de ventanas abiertas */
  const [ventana, setVentana] = useState([]);
  /* numero de lineas de ventanas de acuerdo al tamaño de la pantalla*/
  const [lineas, setLineas] = useState();
  /* numero de ventanas por linea de acuerdo al tamaño de la pantalla*/
  const [ventanasXLinea, setVentanasXLinea] = useState();
  /* arreglo controla la posicion de cada ventana para determinar la posicion de la ventana de arriba*/
  const [posiciones, setPosiciones] = useState([]); 
  /* guarda el nombre de la ventana queestá activa */
  const [ventanaActiva, setVentanaActiva] = useState('');

  const servicioUSuarios = async() => {
    await axios.get(`${servicios_grupohit}/usuarioschat/`, headersAuth())
    .then(response => {
        // If request is good...
        if(imgUsuario === '') setImgUsuario(response.data.dataResponse.usuarios.find(user => user.username === usuario.usuario));

        const listaUsuarios = response.data.dataResponse.usuarios.filter(user => user.username !== usuario.usuario);
        listaUsuarios.sort(function (a, b) {
          if (a.isActive > b.isActive) {
            return -1;
          }
          if (a.isActive < b.isActive) {
            return 1;
          }
          // a must be equal to b
          //mandar los falsos al servidor para cerrar el socket
          return 0;
        });
        const nuevosUsuarios = listaUsuarios.filter(user => user.isActive === true);

        setUsuarios(listaUsuarios);
        setActivos(nuevosUsuarios);
    })
    .catch((error) => {
        console.error(error);
    });
  }

  useEffect(() => {
    if (socket === null & tour === "false") {
      servicioUSuarios();

    } else if(socket !== null) {
      socket.on('chat:newUserList', () => {
        servicioUSuarios();
      });
  
      return( () => {
        socket.off('chat:newUserList');
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect( () => {
    if(socket === null){
      return;
    }

    socket.on('chat:verify', data => {
      servicioUSuarios();
    });

    return( () => {
      socket.off('chat:verify');
    })
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socket]);


  useEffect( () => {
    if(socket !==null) {
      socket.on('chat:ventanasOpen', data => {
          setVentana(data);
      });
      
      return( () => {
        socket.off('chat:ventanasOpen');
      })
    }
  },[socket, ventana]);
  
  if(ventanasXLinea !== displayWindowSize().chats || lineas !== displayWindowSize().lineas){
    setLineas(displayWindowSize().lineas);
    setVentanasXLinea(displayWindowSize().chats);

    if(ventana.length > displayWindowSize().chats * displayWindowSize().lineas){
      const dif = (displayWindowSize().chats * displayWindowSize().lineas) - ventana.length;
      setVentana(ventana.slice(0, dif));
      setPosiciones(posiciones.slice(0, dif));
    }
  }

  const agregaChat = (usuarioChat) => {
    if((displayWindowSize().chats * displayWindowSize().lineas) <= ventana.length) {
      setOpen(false);
      pushError({ title: 'Ha llegado al límite de ventanas de chat abiertas' });
    } else {
      const usuarioRepetido = ventana.find(user => user.username === usuarioChat.username);
      if(!usuarioRepetido) {
        socket.emit('chat:ventanasOpen', { usuario: usuario.usuario, ventanas: [...ventana, usuarioChat]});
      }
      setVentanaActiva(usuarioChat.username);
    }
  }

  const eliminaChat = (usuarioChat, id) => {
      const usuariosRestantes = ventana.filter(user => user.username !== usuarioChat.username);
      setVentana(usuariosRestantes);
      socket.emit('chat:ventanasOpen', {usuario: usuario.usuario, ventanas: usuariosRestantes});
      var copiaPosiciones = posiciones.slice();

      if(posiciones.length-1 >= ventanasXLinea) copiaPosiciones[ventanasXLinea] = copiaPosiciones[ventanasXLinea] - 355;
      copiaPosiciones.splice(id, 1);
      setPosiciones(copiaPosiciones);
  }

  return (
    <React.Fragment>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <h4>Chat</h4>
        </div>
        <Divider />
        <UsersSearchComponent options={usuarios} agregaChat={agregaChat}/>
        <List dense={true} >
          {
            usuarios.map(usuarioChat => {
              return(
                <ListItem
                    button
                    key={usuarioChat.username}
                    onClick={ () => agregaChat(usuarioChat) }
                >
                    <ListItemAvatar>
                    {               
                      (mensajeNuevo.find(nM => nM.from === usuarioChat.username))
                      ? <StyledBadge badgeContent={''}>
                          {
                            usuarioChat.img !== ''
                            ? <Avatar alt="avatar" src={`data:image/jpeg;base64, ${usuarioChat.img}`}/>
                            : <Avatar alt="avatar" src={profileIMG}/>
                          }
                        </StyledBadge>
                      : usuarioChat.img !== ''
                        ? <Avatar alt="avatar" src={`data:image/jpeg;base64, ${usuarioChat.img}`}/>
                        : <Avatar alt="avatar" src={profileIMG}/>
                    }
                    </ListItemAvatar>
                    <Tooltip title={usuarioChat.username}>
                      <ListItemText primary={usuarioChat.datosUsuarioNom} secondary={`${usuarioChat.username.substring(0,20)} ${(usuarioChat.username.length > 20) ? '...' : ''}`}/>              
                    </Tooltip>
                    <Icon edge="end" aria-label="connected">
                      {
                        /*(activos.find(activo => activo.name === usuarioChat.username ))*/
                        (activos.find(activo => activo.username === usuarioChat.username ))
                        ? <Brightness1Icon className={classes.connected} />
                        : <Brightness1Icon className={classes.disconnected} />
                      }
                    </Icon>
                </ListItem>
              )
            })
          }
        </List>
      </Drawer>
      {
            ventana.map((ventanaUsuario, i) => (
                <VentanaChat
                    key = { ventanaUsuario.username }
                    id = { i }
                    ventanaUsuario = { ventanaUsuario }
                    usuario = { usuario }
                    imgUsuario = { imgUsuario }
                    socket = { socket }
                    eliminaChat = { eliminaChat }
                    mensajeNuevo = { mensajeNuevo } 
                    setMensajeNuevo = { setMensajeNuevo }
                    lineas = { lineas }
                    ventanasXLinea = { ventanasXLinea }
                    posiciones = { posiciones }
                    setPosiciones = { setPosiciones }
                    ventanaActiva = { ventanaActiva }
                    setVentanaActiva = { setVentanaActiva }
                    usuarioActivo = { activos.find(activo => activo.username === ventanaUsuario.username )}
                />
            ))
      }
    </React.Fragment>
  );
}