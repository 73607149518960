import React from 'react';
import ModalService from '../../../ModalService/ModalService';

const ModalErrores = ({ nombreModulo, modalIsOpen, setModalIsOpen, erroresProc, limpiaErrores }) => {
    
    const { acumulado, errores } = erroresProc;

    const cerrarModal = () => {
        setModalIsOpen(false);
        limpiaErrores();
    };
    
    return (
        <ModalService
            title={`Errores de ${acumulado}`}
            parentNode={nombreModulo}
            isOpen={modalIsOpen}
            onClose={cerrarModal}
            minWidth="45%"
            maxWidth="45%"
        >
            {errores.map((error, key) => (
                <div key={key}><strong>{ error }</strong></div>
            ))}
        </ModalService>
    );
}
 
export default ModalErrores;