import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; 
import { iconosDrawer } from '../../../../MenuDrawer/Drawer/IconosDrawer'; //Importacion de Iconos 
import { estad_por_periodo } from '../../../../../../../Constants/Constants';

const CardEstadPorPeriodo = ({ setOpenEstadPorPeriodos }) => {
    return (
        <Fragment>
            <Card className="cardBoard cardTablero" onClick={() => setOpenEstadPorPeriodos(true)}>
                <CardContent>
                    <div className="icon">{iconosDrawer['bar_chart']}</div>
                    <p className="name">{ estad_por_periodo }</p> 
                </CardContent>
            </Card> 
        </Fragment>
    );
};
export default CardEstadPorPeriodo;