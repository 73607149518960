import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioTipoContrato = ({ error, enviarTipoContrato, elementSelect, actualizarTipoContrato, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarTipoContrato' ) ){
            const contratoClave = claveRef.current.value;
            const contratoDesc = descripcionRef.current.value;
            enviarTipoContrato({ contratoClave, contratoDesc });
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarTipoContrato' ) ){
            const contratoClave = claveRef.current.value;
            const contratoDesc = descripcionRef.current.value;
            actualizarTipoContrato({ id: elementSelect.id, contratoClave, contratoDesc });
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarTipoContrato' : 'AgregarTipoContrato' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveTipoContrato" placeholder="Escriba la clave del Tipo de Contrato"
                    tooltipText={'Máximo 2 números'} tooltipName={'tooltipCveTipoCont'} maxLength='2' onlyNumbers
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.tipoContratoClave : '' } required/>
                <InputValidation title="Descripción" type="descripcionTipoContrato" placeholder="Escriba la descripción del Tipo de Contrato" maxLength='64'
                    tooltipText={'Máximo 64 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescTipoCont'}
                    refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.tipoContratoDesc : '' } required/>
                {error}
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioTipoContrato;