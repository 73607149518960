import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import AsignacionNomina from './AsignacionNomina';

const ModalAsignacionNomina = ({ openNomina, setOpenNomina, errorTable, successTable, informacion, idModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenNomina( false );
        setError( null );
    }

    const guardarAccesosNomina = ( accesosNomina ) => {
        axios.post( `${servicios_grupohit_url}/acceso/nomina`, accesosNomina, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        successTable( 'Registro Actualizado' );
                        setOpenNomina( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Asignar Nómina" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openNomina } onClose={ () => cerrarModal() } >
                <AsignacionNomina
                    error={error}
                    setError={setError}
                    guardarAccesosNomina={guardarAccesosNomina}
                    informacion={informacion}
                />
        </ModalService>
    );
};

export default ModalAsignacionNomina;