import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_url, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import InformacionUsuario from './InformacionUsuario';

const ModalEditUsuarios = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState(false);

    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    /* Axios para actualización de usuario */
    const actualizarUsuario = ( usuario, userID, departamentoUsuario, departamento ) => {
        setLoading(true);
        axios.put( `${servicios_grupohit_url}/info/${userID}`, usuario, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        if( departamentoUsuario.id ){
                            actualizarDepartamentoUsuario( respuesta.data.dataResponse, Object.assign(departamento, {userDeptoId: departamentoUsuario.id}) );
                        }else{
                            enviarDepartamentoUsuario( respuesta.data.dataResponse, Object.assign(departamento, {userId: userID}) );
                        }
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            });
    }

    const actualizarDepartamentoUsuario = ( usuarioResponse, departamentoUsuario ) => {
        axios.put( `${servicios_grupohit_url}/deptorol/`, departamentoUsuario, headersAuth() )
            .then( respuesta => {
                editar( usuarioResponse );
                setOpenEdit( false );
            })
            .catch( error => {
                editar( usuarioResponse );
                setOpenEdit( false );
            })
            .finally(() => setLoading(false));
    }

    const enviarDepartamentoUsuario = ( usuarioResponse, departamentoUsuario ) => {
        axios.post( `${servicios_grupohit_url}/deptorol/`, departamentoUsuario, headersAuth() )
            .then( respuesta => {
                editar( usuarioResponse );
                setOpenEdit( false );
            })
            .catch( error => {
                editar( usuarioResponse );
                setOpenEdit( false );
            })
            .finally(() => setLoading(false));
    }

    return (
        <ModalService title="Actualizar Usuario" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <InformacionUsuario
                    error={error}
                    userID={elementSelect.id}
                    userSelectSuperior={elementSelect.superior}
                    informacion={informacionAdicional}
                    actualizarUsuario={actualizarUsuario}
                    errorTable={errorTable}
                    successTable={successTable}
                    idModulo={nombreModulo}
                    loading={loading}
                />
        </ModalService>
    );
};

export default ModalEditUsuarios;