import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { adscripciones, altas, bajas, categorias, datos_empleados, escalafones, nombramientos, no_prorrogas_contratos, otros_movimientos, prorrogas_contratos, proyectos, reingresos } from '../../../../../../../Constants/Constants';
import CardTablero from './CardTablero';

const Tablero = ({ maxWidth, info, muestraTabla, active, setActive, openMenu, tipoConsulta, bloqueaCards }) => {

    const {
        activeAdscripción, activeAltas, activeBajas, activeReingresos, activeDatosEmpleados, activeProrrogaContrato, activeNoProrrogaContrato, activeEscalafon,
        activeCategoría, activeProyecto, activeNombramiento, activeOtrosMovimientos
    } = active;
    
    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <h5 style={{ margin: '0rem .8rem .8rem .8rem' }}>{ tipoConsulta.nombre }</h5>
                </div>
            </div>
            <div className="row" style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                <div className="col">
                    <CardTablero name="altas" title={altas} cantidad={info.altas} icon="add_circle" muestraTabla={muestraTabla} active={activeAltas} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="bajas" title={bajas} cantidad={info.bajas} icon="remove_circle" muestraTabla={muestraTabla} active={activeBajas} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="reingresos" title={reingresos} cantidad={info.reingresos} icon="repeat_one" muestraTabla={muestraTabla} active={activeReingresos} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="datosEmpleados" title={datos_empleados} cantidad={info.datosempleado} icon="account_circle" muestraTabla={muestraTabla} active={activeDatosEmpleados} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="prorrogaContrato" title={prorrogas_contratos} cantidad={info.prorrogados} icon="event_available" muestraTabla={muestraTabla} active={activeProrrogaContrato} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="noProrrogaContrato" title={no_prorrogas_contratos} cantidad={info.no_prorrogados} icon="event_busy" muestraTabla={muestraTabla} active={activeNoProrrogaContrato} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
            </div>
            <div className="row" style={{ maxWidth: (openMenu) ? maxWidth-280 : maxWidth-42 }}>
                <div className="col">
                    <CardTablero name="escalafon" title={escalafones} cantidad={info.escalafones} icon="swap_vert" muestraTabla={muestraTabla} active={activeEscalafon} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="categoria" title={categorias} cantidad={info.categoria} icon="work" muestraTabla={muestraTabla} active={activeCategoría} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="adscripcion" title={adscripciones} cantidad={info.adscripcion} icon="account_tree" muestraTabla={muestraTabla} active={activeAdscripción} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="proyecto" title={proyectos} cantidad={info.proyecto} icon="storage" muestraTabla={muestraTabla} active={activeProyecto} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="nombramiento" title={nombramientos} cantidad={info.nombramientos} icon="description" muestraTabla={muestraTabla} active={activeNombramiento} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
                <div className="col">
                    <CardTablero name="otrosMovimientos" title={otros_movimientos} cantidad={info.otros} icon="assigment" muestraTabla={muestraTabla} active={activeOtrosMovimientos} setActive={setActive} bloqueaCards={bloqueaCards}/>
                </div>
            </div>
        </Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    openMenu: state.SystemReducer.openMenu
});

export default connect(mapStateToProps, null)(Tablero);