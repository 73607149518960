import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

const ButtonReturn = ({ action, type }) => {
    return (
        <div className="row justify-content-between mb-2">
            <div className="col">
                <Tooltip title="Regresar a Tablero de Layout CFDI" placement="left-start">
                    <Button variant="contained" className="form-control float-right" style={{ maxWidth: '90px' }} onClick={() => action(type)}> 
                        <ArrowBackIcon/>
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
}
 
export default ButtonReturn;