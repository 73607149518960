import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { registro_sera_actualizado, servicios_grupohit_admon, servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import ModalService from '../../../../ModalService/ModalService';
import Button from '@material-ui/core/Button';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import { inputDateToLong } from '../../../../../../../Services/Validation/HelperValidation';

const ModalEditCPresupuestal = ({ nombreModulo, openEdit, setOpenEdit, elementSelect, errorTable, editar, informacionAdicional }) => {

    const [proyectos, setProyectos] = useState(null);
    const [proyectoSelected, setProyectoSelected] = useState(null);
    const [errorProyectoSelected, setErrorProyectoSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        if(elementSelect.id) {
            axios.get(`${servicios_grupohit_admon}/presupuestal/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayProyectos = res.data.dataResponse.map(presupuestal => ({
                            id: presupuestal.id,
                            cPresupuestalCve: presupuestal.cpresupuestalclave,
                            cPresupuestalNom: presupuestal.cpresupuestalnom
                        }));
                        setProyectos(arrayProyectos.filter(i => i.cPresupuestalCve !== elementSelect.presupuestalClave));
                    break;
                    default:
                        setProyectos([]);
                        cerrarModal();
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => {
                setProyectos([]);
                cerrarModal();
                errorTable(verifique_conexion);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const cerrarModal = () => {
        setProyectos(null);
        setProyectoSelected(null);
        setErrorProyectoSelected(false);
        setIsLoading(false);
        setOpenEdit(false);
    };

    const abrirAlerta = e => {
        e.preventDefault();
        if(proyectoSelected) {
            setErrorProyectoSelected(false);
            setOpenAlert(true);
        } else {
            setErrorProyectoSelected(true);
        }
    };

    const validaProyecto = puesto => {
        setProyectoSelected(puesto);
    };

    const actualizarProyecto = () => {
        setIsLoading(true);
        const request = {
            empleadoid: informacionAdicional.idEmpleado,
            cpresupuestalid: proyectoSelected.id,
            empcpresfecini: inputDateToLong(elementSelect.fechaInicial)
        };
        axios.put(`${servicios_grupohit_rh}/empleado/presupuestal/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newPuesto = { 
                        id: elementSelect.id,
                        presupuestalNombre: proyectoSelected.cPresupuestalNom,
                        presupuestalClave: proyectoSelected.cPresupuestalCve,
                        fechaInicial: elementSelect.fechaInicial,
                        fechaFinal: ''
                    };
                    editar(newPuesto);
                    cerrarModal();
                break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService
            title="Actualizar Clave Presupuestal / Proyecto"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <form id="editHistoricoCPresupuestal" onSubmit={abrirAlerta}>
                        {proyectos === null
                        ?   <SpinnerOval />
                        :   <AutocompleteComponent
                                id="ProyectoHistorico" title="Clave Presupuestal / Proyecto" options={proyectos} optionListView="cPresupuestalNom"
                                tooltipText="Busque una clave presupuestal, por clave o proyecto, en el campo de autocompletado" error={errorProyectoSelected}
                                action={validaProyecto} disabled={proyectos.length === 0} placeholder="Clave Presupuestal / Proyecto" value={proyectoSelected} required
                            />
                        }
                        {(isLoading)
                        ?   <SpinnerOval />
                        :   <Button variant="contained" className="btn-color" type="submit">Actualizar</Button>
                        }
                    </form>
                </div>
            </div>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizarProyecto}/>
        </ModalService>
    );
}
 
export default ModalEditCPresupuestal;