import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { registro_no_guardado, servicios_grupohit_nomina, servicios_grupohit_catalogo, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadores, ArrayJsonSalarioMinUMA, ArrayJsonIndicadoresByNomina } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormularioIndicadores from './FormularioIndicadores';

const ModalAddIndicadores = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null ); 
    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [ indicadoresGeneralesAll, setIndicadoresGeneralesAll ] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarIndicador = ( indicador, configBaseData, indicadorParametros ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_nomina}/indicadores/`, indicador, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const nuevoIndicador = JsonIndicadores(respuesta.data.dataResponse);
                        if(configBaseData!==null) enviarIndicadorBase(nuevoIndicador.id, configBaseData);
                        if(indicadorParametros !== null) enviarIndicadorParametros(nuevoIndicador.id, indicadorParametros);
                        agregar( JsonIndicadores(respuesta.data.dataResponse) );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    const enviarIndicadorBase = async (idIndicador, configBaseData) => { 
        const newData = configBaseData.basesIndicadores[0].map(element => {return {nominId: idIndicador, baseId: element.baseId, operacion: element.operacion }});
        
        await axios.post( `${servicios_grupohit_nomina}/indicador/bases/`, {indicadoresBase:newData}, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    const enviarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {nomindIndicador:indicadorParametros.indicador , nomindId: idIndicador, zonaId: indicadorParametros.zonaId, topVeces: indicadorParametros.topVeces };
        
        await axios.post( `${servicios_grupohit_nomina}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
        
    }

    useEffect(() => {
        axios.get(`${servicios_grupohit_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            } 
        })
        .catch(error =>  errorTable(verifique_conexion));

        axios.get(`${servicios_grupohit_nomina}/indicadores/getbynomina/${informacionAdicional.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresByNomina(res.data.dataResponse);
                    setIndicadoresGeneralesAll(indicadores);
                    break;
                case 404:
                    setIndicadoresGeneralesAll([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        // eslint-disable-next-line
    },[]); 
    
    return (
        <ModalService title="Agregar Concepto por Nómina" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioIndicadores
                    error={error}
                    informacion={informacionAdicional}
                    enviarIndicador={enviarIndicador}
                    idModulo={nombreModulo}
                    informacionAdicional={salarioMinimos}
                    setError={setError}
                    indicadoresGeneralesAll={indicadoresGeneralesAll}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddIndicadores;