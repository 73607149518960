import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, calculo_, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Calculo = ({ id, permisos }) => {

    const [nominas, setNominas] = useState(null);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        axios.get(`${servicios_grupohit_nomina}/config/nomina`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNominas(res.data.dataResponse);
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    setNominas([]);
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setNominas([]);
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    return (
        <div id={`modulo${id}`}>
            {   
                (nominas)
                ?   <DataTableService id={id} title={ calculo_ }
                        columns={[
                            { field: "nominaClave", title: "Clave" },
                            { field: "nominaNomCorto", title: "Nombre Corto" },
                            { field: "nominaNombre", title: "Nombre Nómina" },
                            { field: "nominaDescrip", title: "Descripción" },
                        ]}
                        data={nominas}
                        permisos={permisos}
                        informacionAdicional={{ permisos }}
                        hideIconAdd hideIconEdit hideIconDelete showIconPayment
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default Calculo;
