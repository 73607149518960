import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { servicios_grupohit_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import ConvertDate from '../../../Helpers/ConvertDate';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../Services/AlertForm';
import Button from '@material-ui/core/Button';
import TooltipComponent from '../../../../../../Services/Tooltip/TooltipComponent';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

const InfoNomina = ({ ejecutarServicioUltimoPeriodo, setEjecutarServicioUltimoPeriodo, setEjecutarServicioProcesos, elementSelect, periodo, setPeriodo, setOpenEdit, administrador, confirmaRetrocederPeriodo, confirmaAvanzarPeriodo }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const [mensaje, setMensaje] = useState('');

    const rlSeleccionada = JSON.parse(localStorage.getItem('IGRelLab'));

    useEffect(() => {
        if(ejecutarServicioUltimoPeriodo) {
            axios.get(`${servicios_grupohit_nomina}/config/periodos/ultimo/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPeriodo(res.data.dataResponse);
                        setEjecutarServicioProcesos(true);
                        break;
                    case 404:
                        setMensaje(res.data.msg);
                        setOpenAlert(true);
                        break;
                    default:
                        setOpenEdit(false);
                        break;
                }
            })
            .catch(error => { setOpenEdit(false); })
            .finally(() => { setEjecutarServicioUltimoPeriodo(false); });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ejecutarServicioUltimoPeriodo]);

    const cerrarModal = () => {
        setOpenEdit(false)
    };

    return (
        <Fragment>
            {
                (periodo)
                ?   <Fragment>
                        <div><label><strong>Clave: </strong>{ elementSelect?.nominaClave }</label></div>
                        <div><label><strong>Nombre Corto: </strong>{ elementSelect?.nominaNomCorto }</label></div>
                        <div><label><strong>Nombre: </strong>{ elementSelect?.nominaNombre }</label></div>
                        <div><label><strong>Descripción: </strong>{ elementSelect?.nominaDescrip }</label></div>
                        <div><label><strong>Periodicidad: </strong>{ elementSelect?.periodicidad?.perdenomNombre }</label></div>
                        <div><label><strong>Origen del Recurso: </strong>{ elementSelect?.origenRecurso?.origenRecDesc }</label></div>
                        <div><label><strong>Régimen Fiscal: </strong>{ elementSelect?.regimenFiscal?.regFiscalDesc }</label></div>
                        <div><label><strong>Tipo de Régimen: </strong>{ elementSelect?.tipoRegimen?.regimenDesc }</label></div>
                        <div className="row">
                            <div className="col-8">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>
                                                <strong>Número de Periodo: </strong>
                                                { (periodo)
                                                ?   ((periodo.periodoPerAnio === 9) || (periodo.periodoPerAnio === 10))
                                                    ?   '9/10'
                                                    :   ((periodo.periodoPerAnio === 23) || (periodo.periodoPerAnio === 24))
                                                        ?   '23/24'
                                                        :   periodo.periodoPerAnio
                                                : '-'
                                                }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div><label><strong>Fecha Inicial: </strong>{ (periodo) ? ConvertDate(periodo.periodoFecini) : '--/--/----' }</label></div>
                                    </div>
                                    <div className="col">
                                        <div><label><strong>Fecha Final: </strong>{ (periodo) ? ConvertDate(periodo.periodoFecfin) : '--/--/----' }</label></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mt-auto">
                                {(administrador) &&
                                <div className="row">
                                    <div className="col">
                                        <TooltipComponent tooltipText="Retroceder periodo">
                                            <Button variant="contained" className="btn-color inputTable" onClick={confirmaRetrocederPeriodo}>
                                                <ArrowBack />
                                            </Button>
                                        </TooltipComponent>
                                    </div>
                                    <div className="col">
                                        <TooltipComponent tooltipText="Avanzar periodo">
                                            <Button variant="contained" className="btn-color inputTable" onClick={confirmaAvanzarPeriodo}>
                                                <ArrowForward />
                                            </Button>
                                        </TooltipComponent>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        {
                        (periodo.periodicidadCalculo === 2)
                        ?   <AlertForm
                                message={`La nómina se procesará de forma mensual, incluyendo el cálculo de aguinaldo, prima vacacional y bonos. ${(rlSeleccionada.clave === '504') ? 'Para efectos de un cálculo preciso de liquidez, deberá actualizar el concepto ISSS en el tabulador de sueldos y volver a procesar el periodo.' : ''}`}
                                warning
                            />
                        :   null
                        }
                    </Fragment>
                :   <SpinnerOval />

            }
            <AlertSyE show={openAlert} setOpenAlertSyE={setOpenAlert} title="Petición Fallida" text={mensaje}
                textbtnconfirm="Aceptar" type="error" action={cerrarModal}/>
        </Fragment>
        
    );
}
 
const mapStateToProps = (state) => ({
    administrador: state.SessionReducer.administrador,
});

export default connect(mapStateToProps, null)(InfoNomina);