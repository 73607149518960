import React, { createRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, amountToFloat, deleteOptionals } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_actualizado, registro_sera_actualizado, servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';

import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupohit_nomina } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import Alert from '../../../../../../../Services/Alerts/AlertSyE';

const FormularioPrestamos = ({ error, empleadoId, enviarPrestamo, elementSelect, actualizarPrestamo, informacion, montoLiquido, minimoLiquidez, montoMinimoLiquidez, setMinimoLiquidez, dataTable, isLoading, nominasRL, periodoActual }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [openAlertML, setOpenAlertML] = useState(false);
    const [textError, setTextError] = useState('');
    const [openAlertError, setOpenAlertError] = useState(false);
    const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
    const [ nominaIndicador, setNominaIndicador ] = useState( elementSelect ? informacion.indicadoresNomina.find( element => element.id === elementSelect.indicadorId ) : null );
    const [ errorIndicador, setErrorIndicador ] = useState( null );
    const [ errorFormulario, setErrorFormulario ] = useState( null );
    const [indicadoresNominaDisponibles, setIndicadoresNominaDisponibles ] = useState(null);
    
    const folioRef = createRef('');
    const numeroPagosRef = createRef('');
    const empPrestIniPagosRef = createRef('');
    const importeRef = createRef('');
    const fechaIniRef = createRef('');
    const empPrestNotaRef = createRef('');
    
    useEffect(()=>{ 
            axios.get( `${servicios_grupohit_nomina}/indicadores/getdedpernomina/${informacion.empleado.nominaId}/2`, headersAuth() )
                .then( respuesta => {
                    switch( respuesta.data.status ){
                        case 200:
                            setIndicadoresNominaDisponibles( respuesta.data.dataResponse );
                            break;
                        case 404:
                            setIndicadoresNominaDisponibles( [] );
                            break;
                        default:
                            break;
                    }
                })
                .catch( error => {
                    console.log( error );
                    setIndicadoresNominaDisponibles( [] );
                });
                // eslint-disable-next-line
    },[]);

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = () => {
        const empleado = informacion.empleado.id;
        const folio = folioRef.current.value;
        const indicador = nominaIndicador.id;
        const numeroPagos = parseInt(numeroPagosRef.current.value);
        const empPrestIniPagos = parseInt(empPrestIniPagosRef.current.value);
        const importe = amountToFloat(importeRef.current.value);
        const fechaIni = inputDateToLong( fechaIniRef.current.value );
        const empPrestNota = empPrestNotaRef.current.value;
        if( validarFolioConcepto(folio, indicador) ){
            enviarPrestamo(deleteOptionals({ empleado, folio, indicador, numeroPagos, importe, fechaIni, empPrestIniPagos, empPrestNota }) );
        }
    };

    const cambiaValidacionEmpleadoMinimoLiquidez = () => {
        const request = {
            empId: empleadoId,
	        minimo: false
        };

        axios.post(`${servicios_grupohit_rh}/empleado/liquidez/`, request, headersAuth() )
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setMinimoLiquidez(false);
                    setOpenAlertSuccess(true);
                    break;
                default:
                    setTextError(res.data.msg);
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => {
            setTextError(verifique_conexion);
            setOpenAlertError(true);
        });
    };

    const validar = (evt) => {
        if(evt) evt.preventDefault();
        if( validarSubmit( 'FormPrestamosEmp' ) ){
            if( nominaIndicador ){
                const numeroPagos = parseInt(numeroPagosRef.current.value);
                const empPrestIniPagos = parseInt(empPrestIniPagosRef.current.value);
                const importe = (elementSelect) ? amountToFloat(importeRef.current.value) - amountToFloat(elementSelect.importe) : amountToFloat(importeRef.current.value);
                if( empPrestIniPagos < numeroPagos ){
                    if(minimoLiquidez && ((montoLiquido-importe) < montoMinimoLiquidez)) {
                        if((montoLiquido-importe) >= 0) {
                            setOpenAlertML(true);
                        } else {
                            if(minimoLiquidez && (montoLiquido < montoMinimoLiquidez)) {
                                setTextError("El monto del préstamo disminuye la liquidez por debajo del monto mínimo configurado para todos los empleados y debe ser menor al total de liquidez.");
                            } else {
                                setTextError("El monto del préstamo debe ser menor al total de liquidez.");
                            }
                            setOpenAlertError(true);
                        }
                    } else {
                        if((montoLiquido-importe) >= 0) {
                            (elementSelect) ? actualizar() : enviar();
                        } else {
                            setTextError("El monto del préstamo debe ser menor al total de liquidez.");
                            setOpenAlertError(true);
                        }
                    }
                }else{
                    setErrorFormulario( <AlertForm message="El número de pago inicial no puede ser mayor al número total de pagos" /> );
                }
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'FormPrestamosEmp' ) ){
            if( nominaIndicador ){
                const empleado = informacion.empleado.id;
                const folio = folioRef.current.value;
                const indicador = nominaIndicador.id;
                const numeroPagos = parseInt(numeroPagosRef.current.value);
                const empPrestIniPagos = parseInt(empPrestIniPagosRef.current.value);
                const importe = amountToFloat(importeRef.current.value);
                const fechaIni = inputDateToLong( fechaIniRef.current.value );
                const empPrestNota = empPrestNotaRef.current.value;
                if( validarFolioConcepto(folio, indicador) ){
                    if( empPrestIniPagos < numeroPagos ){
                        actualizarPrestamo(deleteOptionals({ empleado, folio, indicador, numeroPagos, importe, fechaIni, empPrestIniPagos, empPrestNota }));
                    }else{
                        setErrorFormulario( <AlertForm message="El número de pago inicial no puede ser mayor al número total de pagos" /> );
                    }
                }
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const validarIndicador = (valor) => {
        if(valor) {
            setErrorIndicador( null );
            setNominaIndicador( valor );
        }else{
            setErrorIndicador( true );
            setNominaIndicador( null );
        }
    };

    const agregarNumeroPagos = ( numPagos ) => {
        if( parseInt(numPagos) > 0 && empPrestIniPagosRef.current.value === "" ){
            empPrestIniPagosRef.current.value = 0;
        }
    }

    const validarFolioConcepto = ( folio, indicador ) => {
        setErrorFormulario( null );
        const prestamosFilter = elementSelect ? dataTable.filter(element => element.id !== elementSelect.id) : dataTable;
        const prestamosWhitFolio = prestamosFilter.filter(element => element.folio === folio && element.indicadorId === indicador);
        //console.log( prestamosWhitFolio );
        //console.log( prestamosWhitFolio );
        //console.log( nominasRL, periodoActual );

        if( !prestamosWhitFolio ){
            return true;
        }else{
            const prestamoOpen = prestamosWhitFolio.find(element => element.fechaFin === null); //Prestamo abierto
            if( !prestamoOpen ){
                return true;
            }else{
                setErrorFormulario( <AlertForm message="No puede resgistrarse un préstamo con Concepto de Nómina y Folio de un préstamo ya existente." /> );
                return false;
            }
        }
    }

    return (
        <React.Fragment>
            { indicadoresNominaDisponibles ?
                <form id={ 'FormPrestamosEmp' } onSubmit={ elementSelect ? mostrarAlert : validar }>
                    <InputValidation title="Folio" type="folioPrestamo" placeholder="Escriba el folio del préstamo"
                        tooltipText={'Máximo 10 letras y/o números'} tooltipName={'tooltipFolioPrest'} maxLength='10' alfanumeric
                        refValue={folioRef} defaultValue={ elementSelect ? elementSelect.folio : '' } required/>

                    <AutocompleteComponent id="indicadorPrestamos" title="Concepto de Nómina" placeholder="Seleccione el Concepto de Nómina" size="100%"
                        tooltipName="tooltipIndicadorPrestamos" tooltipText={'Busque el Concepto, por nombre o clave, en el campo de autocompletado'} action={validarIndicador}
                        options={indicadoresNominaDisponibles} optionListView={'nomIndicador'} optionKey="nomClave" error={errorIndicador} required
                        value={nominaIndicador}/>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <InputValidation title="Número de Pagos" type="cantidad" placeholder="Digite el número de pagos"
                                tooltipText={'Máximo 16 números'} tooltipName={'tooltipNumPrest'} maxLength='16' onlyNumbers onBlurExecute={agregarNumeroPagos}
                                refValue={numeroPagosRef} defaultValue={ elementSelect ? elementSelect.numeroPagos : '' } required/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <InputValidation title="Inicializar Pago en" type="cantidadPagosPrestamos" placeholder="Digite el número de pagos"
                                tooltipText={'Máximo 16 números'} tooltipName={'tooltipNumIniPrest'} maxLength='16' onlyNumbers
                                refValue={empPrestIniPagosRef} defaultValue={ elementSelect ? elementSelect.empPrestIniPagos : '' } required/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <InputValidation title="Importe de Pagos" type="monto" placeholder="Digite el importe de pagos"
                                tooltipText={'Máximo 16 números y 2 decimales'} tooltipName={'tooltipImportPrest'} maxLength='19'
                                refValue={importeRef} defaultValue={ elementSelect ? elementSelect.importe : '' } required
                                onlyNumbers="." onBlurDecimal={2} isAmount/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <InputDateValidation title="Fecha Inicio del Préstamo" refValue={fechaIniRef} required
                                defaultValue={ elementSelect ? elementSelect.fechaIni : '' } />
                        </div>
                    </div>

                    <InputValidation title="Nota" type="descripcionUsuario" placeholder="Escriba la nota del Préstamo" maxLength='1024'
                        tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipPrestNota'}
                        refValue={empPrestNotaRef} defaultValue={ elementSelect ? elementSelect.empPrestNota : '' }
                        optional />

                    {error}
                    {errorIndicador}
                    {errorFormulario}
                    {(isLoading)
                    ?   <SpinnerOval/>
                    :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                    }
                </form>
                :
                <SpinnerOval />
            }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={validar}/>
            <AlertWarning show={openAlertML} SetopenALert={setOpenAlertML} title="¿Desea desactivar esta variable para este empleado?" text="El monto del préstamo disminuye la liquidez por debajo del monto mínimo configurado para todos los empleados." textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={cambiaValidacionEmpleadoMinimoLiquidez}/>
            <Alert show={openAlertError} setOpenAlertSyE={setOpenAlertError} title={textError} text="" textbtnconfirm="Aceptar" type="error" />
            <Alert show={openAlertSuccess} setOpenAlertSyE={setOpenAlertSuccess} title="Petición Exitosa" text={registro_actualizado} textbtnconfirm="Aceptar" type="success" />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    nominasRL: state.PeriodoActualReducer.nominasRL,
    periodoActual: state.PeriodoActualReducer.periodoActual,
});

export default connect(mapStateToProps)(FormularioPrestamos) ;