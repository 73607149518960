import React, { useState, Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'; 
import ListItemIcon from '@material-ui/core/ListItemIcon';  
import { iconosDrawer } from '../../Drawer/IconosDrawer'; //Importacion de Iconos
import { createMuiTheme  } from '@material-ui/core';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
    root: {
        color:'#4C638E'
      }
});

export default function ListNotifications ({ notifications, modulosApi, setLenthNotifications, setNotificacionDetalles }){

    const operations ={
        UPDATE:'actualizó',
        INSERT:'insertó',
        DELETE:'eliminó'
    }
    const [ buscarNumeroNotificaciones, setBuscarNumeroNotificaciones ] = useState(1);
    const [loading, setLoading] = useState(false); 
    const getDateOperation = (date, getTimeStamp=null) => {
        const fechaActual = new Date();
        const fechaOperacion = new Date( date );       
        const lapso = fechaActual.getTime() - fechaOperacion.getTime();

        if(getTimeStamp){
            return lapso;
        }
        
        if( lapso < 3600000  ) {
            const minutos = Math.round( lapso / 60000 );
            return `Hace ${ minutos } minuto${ minutos > 1 ? 's.':'.'} `;
        }else if( lapso < 86400000 ){
            const horas = Math.round(lapso / 3600000) ;
            return `Hace ${horas} hora${horas > 1 ? 's.':'.'} `;
        }else if( lapso < 604800016.56 ){
            const dias = Math.round(lapso / 86400000) ; 
            return dias > 1 ? `Hace ${dias} Días.`: 'Ayer';
        }else{ 
            const semanas = Math.round(lapso / 604800016.56) ;
            return `Hace ${semanas} Semana${semanas > 1 ? 's.':'.'} `;
        }
    }
    
    const getIconByModuleID = (id) =>{
        return modulosApi.filter(element=> element.id === id )[0].icono;
    }
    
    const auxiliarNotification = []; 
    
    for (const key in notifications) {
        if( Array.isArray(notifications[key]) ){
            for(const item in notifications[key]){
                auxiliarNotification.push(notifications[key][item]);
            }
        }
    } 
    
    const [notificaciones, setNotificaciones] = useState(
        auxiliarNotification.map( element => { 
            return  {
                        id: element.id, 
                        modulo: element.moduleName, 
                        operacion: operations[ element.crudOperation ], 
                        fecha: getDateOperation (element.date),
                        fechaMs: new Date(element.date),
                        icon: getIconByModuleID(element.moduleId), 
                        read: true,
                        datos: element.datos,
                        timestamp: getDateOperation ( element.date, true ),
                    }
            }
        ).sort((a, b)=> a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 :0 )
    );

    const markRead = (id)=>{
        const itemNotification = notificaciones.find(element=> element.id===id);
        if(itemNotification.read){ 
            itemNotification.read = false;
            setNotificaciones(notificaciones.map( element => element.id === id ? element = itemNotification : element )); 
        }
    }

    /* axios.delete(`${servicios_grupohit}/user/notificacion/${id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        itemNotification.read = false;
                        setNotificaciones(notificaciones.map( element => element.id === id ? element = itemNotification : element )); 
                        break;
                    default: 
                        break;
                }
            })
            .catch(error => console.log(error) ); */ 

    const loadMoreNotifications = () => {
        setLoading(true);
        axios.get(`${servicios_grupohit}/user/notificacion/mongo/${buscarNumeroNotificaciones+5}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const data = res.data.dataResponse
                    if(data.length){
                        let sumaNotificaciones = 0;
                        for (const key in data) {
                            sumaNotificaciones += data[key].length;
                        }
                        setLenthNotifications( sumaNotificaciones );
                        auxiliarNotification.length=0;
                        for (const key in data) {
                            if( Array.isArray(data[key]) ){
                                for(const item in data[key]){
                                    auxiliarNotification.push(data[key][item]);
                                }
                            }
                        }
                        const newDataNotifications = auxiliarNotification.map( element => { 
                            return  {
                                        id:element.id, 
                                        modulo: element.moduleName, 
                                        operacion:operations[ element.crudOperation ], 
                                        fecha: getDateOperation ( element.date ), 
                                        icon: getIconByModuleID(element.moduleId), 
                                        read: true,
                                        timestamp: getDateOperation ( element.date, true ),
                                    }
                            }
                        ).sort((a, b)=> a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 :0 )
                        setNotificaciones(newDataNotifications);
                        setBuscarNumeroNotificaciones(buscarNumeroNotificaciones+5);
                    }
                    setLoading(false);
                    break;
                default:
                    setLoading(false);
                    break;
            }
        })
        .catch(error => console.log(error));
    }
    
    const mostrarDetalles = data => {
        setNotificacionDetalles(data);
    };

    return(
        <List>
            {
                notificaciones ?
                
                    notificaciones.length >0 ? 
                    <Fragment>
                        {
                           notificaciones.map(element=>
                                <ListItem button key={element.id} onMouseEnter={()=>markRead(element.id) } onClick={() => mostrarDetalles(element)}> 
                                    <ListItemIcon> 
                                        {iconosDrawer[element.icon]} 
                                    </ListItemIcon>
                                    <ListItemText primary={` Se ${element.operacion} un registro en ${element.modulo}.`} secondary={element.fecha} />
                                    {/* element.read  && <MuiThemeProvider theme={theme}><Badge color="secondary" variant="dot" badgeContent=''/></MuiThemeProvider> */}
                                </ListItem>  
                            )
                        }
                        {
                            loading ? 
                                <CircularProgress className={theme.root} style={{marginLeft: '50%'}} size={20} /> 
                                : 
                                <button className="subrayado link showMoreNotifications"  onClick={() => { loadMoreNotifications()}}>{"Mostrar más"}</button>
                        }
                    </Fragment>
                    :
                        <div className="whitoutNotifications"> 
                            {"Sin Notificaciones."}
                        </div> 
                :
                <div style={{paddingTop:150}}> 
                    <SpinnerOval/>
                </div>
            }
        </List>
    );
}