import React, { useState } from 'react';
import ModalService from '../../../../../ModalService/ModalService';
import AsignacionModulos from './AsignacionModulos';

const ModalAsignacionModulos = ({ openAsign, setOpenAsign, informacion, idModulo, accesosMenu, setAccesosMenu }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAsign( false );
        setError( null );
    }

    return (
        <ModalService title="Asignar Módulos y Permisos" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openAsign } onClose={ () => cerrarModal() } >
                <AsignacionModulos
                    error={error}
                    informacion={informacion}

                    accesosMenu={accesosMenu}
                    setAccesosMenu={setAccesosMenu}
                />
        </ModalService>
    );
};

export default ModalAsignacionModulos;