import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';
import InformacionCertificados from './InformacionCertificados';
import FormularioCertificados from './FormularioCertificados';

const Certificados = () => {
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ nuevosCertificados, setNuevosCertificados ] = useState( false );
    /* ------------------------------------- Axios Certificados ------------------------------------- */
    const [ certificados, setCertificados ] = useState( null );
    useEffect(() => {
        axiosCertificados();
    }, []);

    const axiosCertificados = () => {
        axios.get( `${servicios_grupohit}/cfdi/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setCertificados( respuesta.data.dataResponse );
                        break;
                    case 404: //el directorio no contiene ningun documento para mostrar
                        setCertificados([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ---------------------------------------------------------------------------------------------- */
    const agregar = ( certificados ) => {
        setCertificados( certificados );
        setAlertSuccess( true );
    }

    const alertaError = () => {
        setAlertError( true );
    }

    return (
        <React.Fragment>
            { certificados ?
                !nuevosCertificados ?
                    certificados.length > 0 ?
                        <InformacionCertificados certificados={certificados} setNuevosCertificados={setNuevosCertificados}/>
                        :
                        <FormularioCertificados agregar={agregar} alertaError={alertaError}/>
                    :
                    <FormularioCertificados agregar={agregar} alertaError={alertaError} certificados={certificados} setNuevosCertificados={setNuevosCertificados}/>
                :
                <SpinnerOval />
            }
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text="Se ha cargado el certificado" textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text="El certificado no ha sido cargado" textbtnconfirm="Aceptar" type="error"/>
        </React.Fragment>
    );
};

export default Certificados;