import React, { Fragment } from 'react';
import { especiales_ } from '../../../../../../Constants/Constants';
import ModalService from '../../../ModalService/ModalService';
import InfoEspecialesEmpleado from './TablaInfoEspecialesEmpleado';

const ModalEspecialesEmpleado = ({ modalEspeciales, setModalEspeciales, elementSelect, id }) => {

    const cerrarModal = () => {
        setModalEspeciales( false );
    }

    const InfoEmpleado = ()=>{
        return(
        <Fragment>
            <div style={{fontSize:'16px'}}><strong>Empleado: </strong>{ elementSelect.nombreCompleto }</div>
            <div>{"Se podrán deshacer movimientos del empleado siempre y cuando no esté relacionado a otros procesos en el sistema."}</div>
        </Fragment>)
            
    }

    return (
        <ModalService title="Consulta Movimientos Especiales" parentNode={id} minWidth="70%" maxWidth="70%"
            isOpen={ modalEspeciales } onClose={ () => cerrarModal() } subtitle = {<InfoEmpleado/>}>

            <InfoEspecialesEmpleado elementSelect={elementSelect} id={id} title={especiales_}/>

        </ModalService>
    );
}
 
export default ModalEspecialesEmpleado;