import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_catalogo, impuesto_sobre_nomina } from '../../../../../../Constants/Constants';
import { ArrayJsonImpuestoNomina } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const ImpuestoSobreNomina = ({ id, permisos }) => {
    const [ impuestosNomina, setImpuestosNomina ] = useState( null );
    const [ estados, setEstados ] = useState( null );
    useEffect(() => {
        axiosImpuestosNomina();
        axiosEstados();
    }, []);

    const axiosImpuestosNomina = () => {
        axios.get( `${servicios_grupohit_catalogo}/isn/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setImpuestosNomina( ArrayJsonImpuestoNomina(respuesta.data.dataResponse) );
                        break;
                    case 404: //No hay registros para mostrar
                        setImpuestosNomina([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio Estados */
    const axiosEstados = () => {
        axios.get( `${servicios_grupohit_catalogo}s/estado/1`, headersAuth() ) /* País 1: México */
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEstados( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposParentesco( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { impuestosNomina && estados ?
                <DataTableService id={id} title={ impuesto_sobre_nomina } permisos={permisos}
                    columns={[
                        { field: "estadoNom", title: "Estado" },
                        { field: "porcentaje", title: "% ISN", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "fechainiTab", title: "Fecha Inicial" },
                        { field: "fechafinTab", title: "Fecha Final" },
                    ]}
                    data={ impuestosNomina }
                    informacionAdicional={{ estados }} />
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default ImpuestoSobreNomina;