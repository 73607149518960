import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { deshacerReingreso } from '../../../../../../Redux/actions/EmpleadosActions';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const ModalDeleteReingresos = ({ nombreModulo, alertWarning, setAlertWarning, elementSelect, eliminar, errorTable, deshacerReingreso }) => {

    const [isLoading, setIsLoading] = useState(false);

    const eliminarReingreso = () => {
        setIsLoading(true);
        deshacerReingreso(elementSelect.id, eliminar, errorTable, setIsLoading);
    };

    return(
        <Fragment>
            <AlertWarning
                show={alertWarning} SetopenALert={setAlertWarning}
                text="El empleado volverá a status de Baja"
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar"
                action={eliminarReingreso}
            />
            <ModalLoading nombreModulo={nombreModulo} open={isLoading} setOpen={setIsLoading}/>
        </Fragment>
    );
}
 
const mapDispatchToProps = { deshacerReingreso };

export default connect(null, mapDispatchToProps)(ModalDeleteReingresos);