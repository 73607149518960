import React, { useState, useEffect, createRef } from 'react'; 
import Axios from 'axios';
import {  servicios_grupohit, campos_vacios, registro_sera_eliminado, verifique_conexion} from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import {  Delete} from '@material-ui/icons';
import {IconButton, Button, TextField, Tooltip} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import '../../../../../../Components/Usuarios/Usuario/DataTableService/StyleDataTable.css';  
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import { obtenerModalDelete, obtenerModalAdd } from '../../../../../../Components/Usuarios/Usuario/DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; 
import { longDateToInput, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import AlertForm from '../../../../../../Services/AlertForm';

const useStyles = makeStyles(()=> ({
    root: {
      color:'#4C638E'
    }
}));

const DinamicTablePeriodosNomina = ({idTab, onShowTable, idModulo, permisos}) => {

    const permisosUsuario = permisos.map(element=> element.id);
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
    const columnHeaderShow =
        ["Fecha Inicial", "Fecha Final", "Año", "Mes", "Periodo", "Periodo Mes",
        "Días IMSS Por Periodo", "Días Nómina Por Periodo", "Fecha de Corte", "Procesado", "Acciones"];
    const columnsTable =[
        "periodoFecini","periodoFecfin", "periodoAnio", "periodoMes","periodoPerAnio", "periodoPerMes",
        "periodoDiasImss", "periodoDiasNom", "periodoFeccorte", "periodoProcesado", "accion"];   

    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ alertWarningLocal, setAlertWarningLocal ] = useState( false );
    const [ openAdd, setOpenAdd ] = useState( false ); 
    const [ shouldUpdate, setShouldUpdate ] = useState( false ); 
    const [ loading, setLoading ] = useState( false ); 
    const [ data, setData]= useState(null);  
    const [ dta, setDta]= useState(null);  
    const [ btnDisabled, setBtnDisabled ] = useState(true); 
    const [ isNew, setIsNew]= useState(false);
    const [ loadingData, setLoadingData]= useState(false);
    const [idTabBack, setIdTabBack] = useState({id: idTab.id, fechaFin:''});
    const inputSafariRefs = createRef([]);
    const [ openAddFocus, setOpenAddFocus ] = useState(false);
    const [anios, setAnios] = useState(null);

    useEffect(() => {
            setLoadingData(true);
            Axios.get(`${servicios_grupohit}/nomina/config/periodos/${idTab.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const aniosComplete =  res.data.dataResponse.map(e => { return { id: e.periodoAnio, anio: `${e.periodoAnio}`} });
                        const aniosSorted = aniosComplete.sort();
                        const aniosUnique = aniosSorted.filter((value, index) => {
                            return value.id !== aniosSorted[index + 1]?.id;
                        });
                        setAnios(aniosUnique);
                        setData(res.data.dataResponse.reverse());
                        setDta(res.data.dataResponse.reverse());
                        if(res.data.dataResponse.length>0){
                            setIsNew(false);
                            const newValue = idTabBack;
                            newValue.fechaFin=longDateToInput(res.data.dataResponse[0].periodoFecfin+86400000+86400000);
                            setIdTabBack(newValue);
                        }else{
                            setIsNew(true)
                        } 
                        break;
                    case 404:
                        break;
                    default:
                        errorTable(res.data.msg)
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion))
            .finally(() => setLoadingData(false));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []);
 
    useEffect(() => {
        if(btnDisabled) {
            setBtnDisabled(!isNew)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew]);

    const del = (n) => {
        
        var newDta=[]; 
        if(n.toString().length>10){
            newDta=dta.filter(element  => element.periodoFecfin !== parseInt(n)) 
        }else{
            newDta=dta.filter(element  => element.id !== parseInt(n)) 
        }    
        setDta(newDta);
        setData(newDta);
        for(var i=0; i<newDta.length; i++){
            for(var j=0; j<columnsTable.length; j++){ 
                
                var input = document.getElementsByName(`${i}${columnsTable[j]}`); 
                if(input[0]!==undefined){
                    if(columnsTable[j]==='periodoFeccorte'){
                        input[0].value=longDateToInput(newDta[i][columnsTable[j]]); 
                    }else if(columnsTable[j]==='periodoFecini' || columnsTable[j]==='periodoFecfin' ){
                        input[0].textContent=convertDateFormat(newDta[i][columnsTable[j]]);
                    }else if(columnsTable[j]==='periodoDiasImss'){
                        input[0].value=newDta[i][columnsTable[j]]; 
                    }else if(columnsTable[j]==='accion' ){
                    }else{
                        input[0].textContent=columnsTable[j]==='periodoProcesado' ? newDta[i][columnsTable[j]] ?'Sí':'No':newDta[i][columnsTable[j]];
                    } 
                }
            }
        }    
        const newValor = idTabBack;
        if(dta.length<=1){
            newValor.fechaFin='';
        }else{
            newValor.fechaFin=longDateToInput(newDta[0].periodoFecfin+86400000+86400000);
        }
        setIdTabBack(newValor);
    }
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    } 

    const invalidInputs=(index)=>{ 
        var name=`${index}periodoFeccorte`;
        var input = document.getElementsByName(name);
        input[0].className="inpTable is-invalid"; 
    } 

    //busqueda por años
    const classes = useStyles();

    const filterData = anio => {
        (anio)
        ?   setDta(data.filter(e => e.periodoAnio === anio.id))
        :   setDta(data);
    };

    const filterByYear = () => {
        if(anios) {
            return  <Autocomplete
                        id="auto-completeAnioP"
                        className={classes.root}
                        autoComplete
                        includeInputInList
                        options={anios}
                        getOptionLabel={(option) => option.anio}
                        onChange={(e,v) => filterData(v)}
                        renderInput={(params) => 
                                <TextField
                                    className={classes.root}
                                    {...params}
                                    id="mui-theme-provider-outlined-input"
                                    label="AÑO"
                                    margin="normal"
                                    style={{ width: 120}}
                                />
                        }
                    /> 
        } else {
            return columnHeaderShow[2].toUpperCase();
        }
    };

    const generateHeader= () => {
        return columnsTable.map((key, index) => {
            return  <th key={index} className="MuiTableCell-root negritas">
                    {(index === 2)
                    ?   filterByYear()
                    :   columnHeaderShow[index].toUpperCase()
                    }
                    </th>
        })
    };

    const updateData = (key, value, index, i) => {
        setBtnDisabled(false);
        try {
            const indice = dta.findIndex(item => item.id === Number(index));
            const indice2 = data.findIndex(item => item.id === Number(index));
            if(key==='periodoFeccorte') {
                dta[indice][key]=inputDateToLong(value);
                data[indice2][key]=inputDateToLong(value);
            }else{ 
                dta[indice][key]=Number(value);
                data[indice2][key]=Number(value);
            }
        } catch (error) {
            if(key==='periodoFeccorte') {
                dta[i][key]=inputDateToLong(value);
                data[i][key]=inputDateToLong(value);
            }else{ 
                dta[i][key]=Number(value);
                data[i][key]=Number(value);
            }
        }
        setShouldUpdate(true);
    };

     const validNumber=(index, value, e)=>{
        if(isNaN(value) || value<1){
            e.target.className="inpTable is-invalid";
        }else{
            e.target.className="inpTable";
        }
     }

     const convertDateFormat=(date)=>{
        var info = longDateToInput(date).split('-').reverse().join('/');
        return info;
     }

     const validDate=(index, value, e)=>{
        if(value===''){
            e.target.className="inpTable is-invalid";
        }else{
            e.target.className="inpTable";
        }
     }

     const keysAllowed = (e) =>{
        return e.keyCode!==8 && e.keyCode!==9 && !(e.keyCode >= 37 && e.keyCode <= 40 ) && !((e.keyCode === 65 || e.keyCode === 67) && (e.ctrlKey || e.metaKey ));
    }

     const validarInput=e=>{
        
        const number = /^[0-9]$/; 
        if( !number.test(e.key) && keysAllowed(e) ){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
    }
    const executeChange = (value, index) => { 
        updateData('periodoFeccorte', value.getTime(), index[0], index[1]);
    }

      const generateTableData=()=>{

         let res=[];  
         for(let i =0; i < dta.length; i++){
            res.push(
               <tr key={`${columnsTable[i]}-${i}`} className="MuiTableRow-root">
                {
                    columnsTable.map((key, index)=>{ 
                        if(key==='periodoDiasImss') 
                        return  <td key={`${key}${index}${dta[i][key]}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                    <input type="text" name={`${dta[i][key]}${key}`} onBlur={e=> {validNumber(e.target.id, e.target.value, e)}}  
                                        onKeyDown = { e => { validarInput(e) } } maxLength={8} 
                                        className={`inpTable`} defaultValue={dta[i][key]} id={dta[i].id} onChange={e => updateData(key, e.target.value, e.target.id, i)}/>
                                </td>
                        if(key==='periodoFeccorte'){
                            return  <td key={`${key}${index}${dta[i][key]}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                    {isSafari
                                    ?   <InputDateValidation  title="" defaultValue={longDateToInput(dta[i][key])} refValue={ inputSafariRefs } onChangeExecute={executeChange} informacionAdicional={[dta[i][key], i]}/>
                                    :   <input type="date" name={`${dta[i][key]}${key}`}  onBlur={e=> {validDate(e.target.id, e.target.value, e)}} className={`inpTable`} defaultValue={longDateToInput(dta[i][key])} id={dta[i].id} onChange={e => updateData(key, e.target.value, e.target.id, i)}/>
                                    } 
                                    </td>
                        }
                        
                        return  <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                    {(key==='periodoFecini' || key==='periodoFecfin')
                                    ?   <span name={`${i}${key}`}>
                                            {convertDateFormat(dta[i][key])}
                                        </span>
                                    :   (key==='periodoProcesado')
                                        ?   (dta[i][key])
                                            ?   <span name={`${i}${key}`}>{'SÍ'}</span>
                                            :   <span name={`${i}${key}`}>{'NO'}</span>
                                        :   (key==='accion' && !dta[i]['periodoProcesado'] && (i === 0) && dta[i]?.id)
                                            ?   <span name={`${i}${key}`}>
                                                {permisosUsuario.includes(3) &&
                                                    <Tooltip title="Eliminar">
                                                        <IconButton onClick={e => alertDelete(dta[i]['id'] ? dta[i]['id'] : dta[i]['periodoFecfin'])}>
                                                            <Delete fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                </span>
                                            :   (key==='accion')
                                                ?   ''
                                                :   <span name={`${i}${key}`}>
                                                        {`${dta[i][key]}`}
                                                    </span>
                                    }
                                </td>                        
                    })
                } 
               </tr>
            )
         }
         return res;
     }

     const alertDelete=(n)=>{ 
        
        setElemmentSelect(n)
        if(n.toString().length>10){
            setTextAlert(registro_sera_eliminado); 
            setAlertWarningLocal(true);
        }else{
            setAlertWarning( true ); 
        }
     }

     const delRow=()=> {
        setAlertSuccess(true);
        setTextAlert('Registro Eliminado');
        del(elemmentSelect);
      }

     const table=()=>{
         if(dta)
         return  <div><table style={{overflowX: "scroll"}} className="MuiTable-root">
            <thead className="MuiTableHead-root">
                <tr className="MuiTableRow-root MuiTableRow-head">
                {generateHeader()}
                </tr>
            </thead>
            <tbody className="MuiTableBody-root">
                {dta ? generateTableData() : null}
                 
                {dta.length<1  ?
                    <tr style={{textAlign: "center", height: "245px"}}>
                        <td colSpan={columnHeaderShow.length}>No existen registros</td>
                    </tr>
                :null}
            </tbody>
     </table></div> ;
     }
     
    const saveInfo= () =>{
        setLoading(true); 
        const obj=[]; 
        var validos=true;
        var invalidos=[]; 
        dta.map((key, index)=>{
            
            obj.push({
                periodoAnio: key['periodoAnio'],
                periodoMes:key['periodoMes'],
                periodoPerMes:key['periodoPerMes'],
                periodoPerAnio: key['periodoPerAnio'],
                periodoFecini: key['periodoFecini'],
                periodoFecfin: key['periodoFecfin'],
                periodoFeccorte: key['periodoFeccorte'],
                periodoDiasNom: key['periodoDiasNom'],
                periodoDiasImss: key['periodoDiasImss']
            }); 

            if(key['periodoDiasImss']===0 || isNaN(key['periodoDiasImss']) || key['periodoFeccorte']==='' || isNaN(key['periodoFeccorte'])){
                validos=false;
                if(key['periodoFeccorte']===''){
                    invalidos.push(index);
                }
            }
            return null;
        }) 

        if(validos){ 
            if(isNew){ 
                insertData({periodosNom:obj});
            }else if(shouldUpdate){
                updateDataBack({periodosNom:obj});
            }else{
                setTextAlert("¡No hay cambios por guardar!");
                setAlertSuccess(true);
                setLoading(false);
            }
        }else{
            setLoading(false);
            for (const key in invalidos) {
                invalidInputs(invalidos[key]);
            } 
            setTextAlert(campos_vacios)
            setAlertError(true);
        } 
    }
     
    const insertData=(request)=>{ 
        Axios.post(`${servicios_grupohit}/nomina/config/periodos/${idTab.id}`, request , headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        setData(res.data.dataResponse);
                        setDta(res.data.dataResponse);
                        setTextAlert(res.data.msg);
                        setAlertSuccess(true);
                        setIsNew(false);
                        setBtnDisabled(true);
                        break;
                    default: 
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                }
            })
            .catch(error => errorTable(verifique_conexion))
            .finally(() => setLoading(false));
    }
     
    const updateDataBack =(request)=>{
        Axios.put(`${servicios_grupohit}/nomina/config/periodos/${idTab.id}`,request, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setTextAlert(res.data.msg);
                    setAlertSuccess(true); 
                    setShouldUpdate(false);
                    setBtnDisabled(true);
                    break;
                default:
                    setTextAlert(res.data.msg);
                    setAlertError(true);
                    break;
            }
        })
        .catch(error => { errorTable(verifique_conexion)})
        .finally(() => setLoading(false));
    }
 
    const agregar = newElement => {
        setDta( newElement.concat(dta) );
        setData( newElement.concat(data) );
        setShouldUpdate(true);
        let newValue = idTabBack;
        newValue.fechaFin=longDateToInput(newElement[0].periodoFecfin+86400000+86400000);
        setIdTabBack(newValue);
        setAlertSuccess(true);
    }

    const closeForm=()=>{ 
        setIdTabBack({id: idTab.id, fechaFin:''});
        onShowTable(false, {});
    }

    const openModalAddPeriodos = () =>{
        setOpenAddFocus(true);
        setOpenAdd(true);
    }

    const quitarFoco = ( ) =>{
        const lastInput = document.getElementsByName(`${dta.length-1}periodoDiasImss`);
        if(lastInput[0]!==undefined){
            lastInput[0].focus();
        }
        setOpenAddFocus(false);
    }
 
    return (
       <div id={`modulo${idModulo}`} className="back MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded">
            {
                
                <div className="row justify-content-between titleDinamicTable">
                    <div className="col-auto mr-auto "> 
                        <div className="form-inline" > 
                            <div className=" form-inline">
                                <b style={{paddingRight:'1rem'}}>Clave: </b><span style={{paddingRight:'3rem'}}>{ idTab.nominaClave }</span>
                                <b style={{paddingRight:'1rem'}}>Nómina: </b><span style={{paddingRight:'3rem'}}>{ idTab.nominaNombre }</span>
                            </div>
                            {( permisosUsuario.includes(1) || permisosUsuario.includes(2) )
                            ?   (loadingData)
                                ?   <SpinnerOval/>
                                :   <Tooltip title="Generar nuevos periodos de nómina">
                                            <Button className="form-control btn-third" name="btnAddPeriodos" onClick={openModalAddPeriodos} style={{marginRight: "10px"}}> 
                                                <AddIcon/>
                                            </Button>   
                                        </Tooltip>
                            : null
                            }        
                        </div>
                    </div>
                    {!btnDisabled &&
                    <div className="col-auto form-inline mt-1">
                        <AlertForm message="Hay cambios sin guardar" warning withoutMargin/>
                    </div>
                    }
                    <div className="col-auto form-inline">
                        {loading
                        ?   <SpinnerOval/>
                        :   ( permisosUsuario.includes(1) || permisosUsuario.includes(2) ) &&
                            <Tooltip title="Guardar">
                                <button className="form-control btn-color" onClick={saveInfo} style={{marginRight: "10px"}} disabled={btnDisabled}> 
                                    <DoneIcon/>
                                </button>
                            </Tooltip>
                        }
                        <button className="form-control btn-outline" onClick={closeForm}>
                            <Tooltip title="Cancelar">
                                <CloseIcon/>
                            </Tooltip>
                        </button>
                    </div> 
                </div> 
            }         
           {
               <div style={{overflowX: "scroll"}} className="ContenedorDataTable AjustaDataTable">
                  {table()}
               </div>
           }
           {obtenerModalDelete( idModulo, alertWarning, setAlertWarning, elemmentSelect, null, delRow, errorTable, null )}
           { obtenerModalAdd( idModulo, openAdd, setOpenAdd, agregar, null, errorTable, null, { ...idTabBack, setIsNew } ) }
            <Alertwarning show={alertWarningLocal} SetopenALert={setAlertWarningLocal} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={delRow } />
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success" action={ openAddFocus ? quitarFoco : null}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
       </div>

    );
}
 
export default DinamicTablePeriodosNomina;