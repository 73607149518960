import React, { useState, Fragment } from 'react';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import { modulosDeCarga } from './CargasMasivas/HelperCargasMasivas';
import TarjetaCarga from './TarjetaCarga';
import ModalAddMasivos from './ModalAddMasivos';
import ProrrogaContratos from './ProrrogaContratos/ProrrogaContratos';

const CargaMasivaDatos = ({ id, permisos }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    const [ openAdd, setOpenAdd ] = useState( false );

    const mostrarCargaMasiva = () => {
        setElementSelect(null)
    }

    const mostrarComponente = () => {
        if(elementSelect) {
            switch(elementSelect.id) {
                case 902:
                    return <ProrrogaContratos id={id}/>;
                default:
                    return null;
            }
        }
    };

    return (
        <div id={`modulo${id}`}>
            {elementSelect && (elementSelect.id === 902)
            ?   <Fragment>
                    <BtnBack tooltipText="Regresar" action={mostrarCargaMasiva}/>
                    { mostrarComponente() }
                </Fragment>
            :   <Fragment>
                    <div className="row">
                        {modulosDeCarga.map( modulo => (
                            <div key={modulo.id} className="col-md-3 col-sm-12 my-2">
                                <TarjetaCarga modulo={modulo} setElementSelect={setElementSelect} setOpenAdd={setOpenAdd}/>
                            </div>
                        ))}
                    </div>
                    <ModalAddMasivos moduloID={id} openAdd={openAdd} setOpenAdd={setOpenAdd} modulo={elementSelect}/>
                </Fragment>
            }
        </div>
    );
};

export default CargaMasivaDatos;