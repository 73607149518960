import React, { useState, Fragment, useRef, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider, Button} from '@material-ui/core';
import Radio from '@material-ui/core/Radio'; 
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match'; 
import { iconosDrawer } from '../../../MenuDrawer/Drawer/IconosDrawer';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox'; 
import AlertForm from '../../../../../../Services/AlertForm'; 
import BtnRemoveItem from '../../../Modulos/RecursosHumanos/Empleados/BtnRemoveItem';
import BtnEditItem from '../../../Modulos/RecursosHumanos/Empleados/BtnEditItem';
import axios from 'axios';
import { headersAuth, obtenerIdUsuario } from '../../../../../../Services/AuthService';
import { servicios_grupohit, verifique_conexion } from '../../../../../../Constants/Constants'; 
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import { Tooltip } from 'reactstrap';
import { InfoRounded } from '@material-ui/icons';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
});

export default function ConfigNotifications ({modulosApi, openAutocomplete, setOpenAutoComplete}){

    const idUsuario = obtenerIdUsuario();
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'));
    const searchTextRef = useRef('');
    const [ modulosAutocomplete, setModulosAutocomplete ] = useState(modulosApi);
    const [showModules, setShowModules ] = useState(true); 
    const [ moduloSelect, setModuloSelect ] = useState( [] );
    const [ listaModulos, setListaModulos ] = useState([]);
    const [ updated, setUpdated ] = useState(false);
    const [ inserted, setInserted ] = useState(false);
    const [ deleted, setDeleted ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ actualizar, setActualizar ] =  useState(false);
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const [ tooltipOpenModulo, setTooltipOpenModulo ] = useState(false);

    const toggle = () => { 
        setTooltipOpenModulo(!tooltipOpenModulo); 
    }
 
    useEffect(() => {
        axios.get(`${servicios_grupohit}/user/notificacion/${idUsuario}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const data = res.data.dataResponse.map(element=>{ 
                        return  { 
                            id:element.modulo.id, 
                            nombre: element.modulo.nombre, 
                            inserted: element.permiso.id===1, 
                            updated:element.permiso.id===2, 
                            deleted: element.permiso.id===3,  
                    }}); 
                    let modulosRepetidos = [];
                    for (const key in data) {
                        const existId = modulosRepetidos.findIndex(element=> element.id === data[key].id); 
                        if(existId!==-1){
                            if(data[key].inserted && !modulosRepetidos[existId].inserted) modulosRepetidos[existId].inserted = true;
                            if(data[key].updated && !modulosRepetidos[existId].updated) modulosRepetidos[existId].updated = true;
                            if(data[key].deleted && !modulosRepetidos[existId].deleted) modulosRepetidos[existId].deleted = true;
                        }else{
                            modulosRepetidos.push(data[key]);
                        }
                    } 
                    setListaModulos(modulosRepetidos);  
                    break;
                default: 
                setListaModulos([]);
                    break;
            }
        })
        .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []); 

    useEffect(()=>{
        if(moduloSelect.length===0){
            setUpdated(false);
            setInserted(false);
            setDeleted(false);
            setActualizar(false);
            let newModulesAutocomplete = modulosAutocomplete;
            for (const key in listaModulos) {
                newModulesAutocomplete = newModulesAutocomplete.filter(element => element.id!==listaModulos[key].id) ;
            }
            setModulosAutocomplete( newModulesAutocomplete );
        }
        // eslint-disable-next-line
    }, [moduloSelect]); 

    const validar = e => { 
        if (e === undefined || e === null) {
            setModuloSelect(null);
        } else { 
            const oldModules = moduloSelect; 
            let newModules = modulosAutocomplete; 
            for (const key in e) {
                newModules = newModules.filter(element => element.id!==e[key].id);
            }  
            if(oldModules){
                for (const key in oldModules) {
                    const value = e.find(element => element.id===oldModules[key].id ); 
                    if(value===undefined){ 
                        newModules.unshift( oldModules[key] );
                    }
                }
            }
            setModulosAutocomplete(newModules);
            setModuloSelect(e); 
        }
    }; 

    const enviar = e => {
        e.preventDefault(); 
        if(moduloSelect.length>0 || !showModules) {
            if(inserted || updated || deleted || !showModules){ 
                setError(null); 
                if(actualizar){
                    moduloSelect[0].inserted = inserted;
                    moduloSelect[0].updated = updated;
                    moduloSelect[0].deleted = deleted; 
                    const newListaModulos =  listaModulos.map(element => element.id === moduloSelect[0].id ? element = moduloSelect[0] : element);
                    const modulos = newListaModulos.map( element =>{ 
                        const permisos = [];
                        if(element.inserted) permisos.push(1);
                        if(element.updated) permisos.push(2);
                        if(element.deleted) permisos.push(3);
                        return {modulo: element.id, permisos } 
                    });
                    const data = {notificacion:modulos}; 
                    insert(data, newListaModulos);
                    //setListaModulos( newListaModulos );
                    setActualizar(false);
                }else{ 
                    const permisos = [];
                    if(inserted) permisos.push(1);
                    if(updated) permisos.push(2);
                    if(deleted) permisos.push(3);
                    const listaModulosOld =  listaModulos.map( element =>{ 
                        const permisos = [];
                        if(element.inserted) permisos.push(1);
                        if(element.updated) permisos.push(2);
                        if(element.deleted) permisos.push(3);
                        return {modulo: element.id, permisos } 
                    });
                    const modulos = moduloSelect.map( element =>{ return {modulo: element.id, permisos } }).concat(listaModulosOld);
                    const data = {notificacion:modulos}; 
                    insert(data);
                } 
                setModuloSelect([]); 
                setUpdated(false);
                setInserted(false);
                setDeleted(false);
            }else{
                setError(<AlertForm message={'Debe seleccionar alguna acción.'} />);
            }
        }else{
            setError(<AlertForm message={'Debe seleccionar algún módulo.'} />);
        }
    }; 

    const insert = (data, newListaModulos=null)=>{
        axios.post(`${servicios_grupohit}/user/notificacion/`, data, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    const newModules = newListaModulos 
                        ? 
                            newListaModulos.map( element => {
                                return {
                                    id: element.id, 
                                    nombre: element.nombre,
                                    inserted: element.inserted,
                                    updated: element.updated, 
                                    deleted: element.deleted
                                }})
                        :
                            moduloSelect.map( element => {return {id: element.id, nombre: element.nombre ,inserted, updated, deleted}}).concat(listaModulos);
                    setListaModulos( newModules );
                    setTextAlert(newListaModulos ? 'Registro Actualizado' :'Registro Agregado');
                    setAlertSuccess(true);
                    break;
                default:
                    setError( <AlertForm message={ res.data.msg } /> );
                    break;
            }
        })
        .catch(error => <AlertForm message={verifique_conexion} /> ); 
    }
    
    const eliminaModulo = (modulo) =>{ 
        axios.delete(`${servicios_grupohit}/user/notificacion/${modulo.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const regresarModulo = modulosApi.find(element=> element.id=== modulo.id);
                        setModulosAutocomplete([regresarModulo, ...modulosAutocomplete]);
                        setListaModulos( listaModulos.filter(element=> element.id!==modulo.id));
                        setModuloSelect([]);
                        setError(null);
                        break;
                    default:
                        setError( <AlertForm message={ res.data.msg } /> );
                        break;
                }
            })
            .catch(error => <AlertForm message={verifique_conexion} /> ); 
    }
    
    const muestraEditarModulo = ( modulo ) =>{ 
        const actualizarModulo = modulosApi.find(element=> element.id=== modulo.id); 
        setInserted(modulo.inserted);
        setUpdated(modulo.updated);
        setDeleted(modulo.deleted);
        setModuloSelect([{'nombre':actualizarModulo.nombre,'icono':actualizarModulo.icono, 'id':actualizarModulo.id}]);
        setActualizar(true);
        setError(null);
    }

    return(
        <Fragment>
        <MuiThemeProvider theme={theme}>
            <div className="row" style={{marginRight:'0px'}}>
                <div className="col-md-5 col-sm-12"> 
                        <MuiThemeProvider theme={theme}> 
                            <Radio checked={showModules} onChange={()=>{setShowModules(!showModules); setListaModulos([])}} color="secondary" /> 
                            </MuiThemeProvider> 
                        <label>{'Módulos'} 
                            <span id={'tooltipModulo'} className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRounded color="disabled"/></span> 
                        </label>
                        <Tooltip placement="right" isOpen={tooltipOpenModulo} target="tooltipModulo" className="tooltipModulo" toggle={toggle}>
                            { 'Seleccione un Módulo' }
                        </Tooltip>
                </div>
            </div>
            <form id={ showModules ? 'SeguirModulo' : 'SeguirEspeciales'} onSubmit={ enviar }>
                <div className="row" style={{marginRight:'0px'}}>
                    <div className="col-md-12 col-sm-12" style={{marginRight:'0px'}}>
                        {
                            modulosAutocomplete && <Autocomplete
                                openText="Abrir"
                                closeText="Cerrar"
                                value={moduloSelect}
                                style={{ padding: '0px 10px'}} 
                                id="free-solo-demo" 
                                autoHighlight={true}
                                disableCloseOnSelect
                                onChange={(e,v) =>{validar(v) }}
                                getOptionLabel={option => option.nombre}
                                options={modulosAutocomplete.map(modu => ({'nombre':modu.nombre,'icono':modu.icono, 'id':modu.id})).filter(element=> !listaModulos.find( item => item.id === element.id ))}
                                size="small" 
                                ref={searchTextRef}
                                open={openAutocomplete}
                                onOpen={() => { 
                                    setOpenAutoComplete(true);
                                }}
                                onClose={() => { 
                                    setOpenAutoComplete(false)
                                }}
                                noOptionsText="No existen coincidencias"
                                multiple={true}
                                renderOption={ (options,{inputValue}) => {
                                    const matches = match(options.nombre, inputValue)
                                    const parts = parse(options.nombre, matches);
                                    return (
                                        <React.Fragment>
                                            <table >
                                                <thead>
                                                    <tr>
                                                        <th className="Icono">
                                                            { iconosDrawer[ options.icono ] }
                                                        </th>
                                                        <th >
                                                            { parts.map((part, index) => (
                                                                <span key={index} style={{ color: part.highlight ? '#4C638E': 'black' }}>
                                                                    {part.text}
                                                                </span>
                                                            ))}
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </React.Fragment>
                                    );
                                }}      
                                renderInput={ params => (
                                        <TextField
                                            id="mui-theme-provider-outlined-input"
                                            {...params} 
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth  
                                        />
                                )}
                            />
                        }
                    </div>
                </div>
                { 
                showModules &&
                    <Fragment>
                        <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                            <div className="col-md-12 col-sm-12"> 
                                <strong>Notificarme cuando: </strong> 
                            </div>
                        </div>
                        <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                            <div className="col-md-10 col-sm-12 d-flex align-items-center"> 
                                Se agreguen registros 
                            </div>
                            <div className="col-md-1 col-sm-12" style={{textAlign:'right'}}>  
                                <Checkbox  
                                    onChange={()=>{setInserted(!inserted)}}
                                    checked={inserted}
                                    style={{margin:0}}
                                /> 
                            </div>
                        </div>
                        <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                            <div className="col-md-10 col-sm-12  d-flex align-items-center"> 
                                Se actualicen registros
                            </div>
                            <div className="col-md-1 col-sm-12" style={{textAlign:'right'}}> 
                                <Checkbox  
                                    onChange={()=>{setUpdated(!updated)}}
                                    checked={updated}
                                    style={{margin:0}}
                                />
                            </div>
                        </div>
                        <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                            <div className="col-md-10 col-sm-12  d-flex align-items-center"> 
                                Se eliminen registros 
                            </div>
                            <div className="col-md-1 col-sm-12" style={{textAlign:'right'}}> 
                                <Checkbox  
                                    onChange={()=>{setDeleted(!deleted)}}
                                    checked={deleted}
                                    style={{margin:0}}
                                />
                            </div>
                        </div>
                    </Fragment>
                }
                <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                    <div className="col-md-12 col-sm-12 delModuleFollowing">
                        {error}
                        <Button variant="contained" className="btn-color" type="submit">{ actualizar ? 'Actualizar' : 'Aceptar' }</Button>
                        <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
                        <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
                    </div>
                </div> 
            </form> 
            <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                <div className="col-md-12 col-sm-12">
                    <hr/>
                    <strong>{showModules ? "Módulos seguidos:" : "Casos especiales seguidos:"} </strong> 
                </div>
            </div>
            {
                listaModulos &&
                <Fragment> 
                    <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                        {
                            listaModulos.length>0 ?
                            listaModulos.map(element=>(
                                <div className="col-md-6 col-sm-12" key={`listaModulos-${element.id}`}> 
                                    <div className="row d-flex align-items-center" style={{marginTop:'10px'}}>
                                        <div className="col-md-9 col-sm-12 d-flex align-items-center">
                                            {element.nombre} 
                                        </div>
                                        <div className="col-md-3 col-sm-12 d-flex align-items-center delModuleFollowing">
                                            {showModules && <BtnEditItem id={element.id} idArray="id" array={listaModulos} action={muestraEditarModulo} remove/> }
                                            <BtnRemoveItem id={element.id} idArray="id" array={listaModulos} action={eliminaModulo} remove/>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            <div className="row" style={{marginRight:'5px', marginLeft:'0px'}}>
                                <div className="col-md-12 col-sm-12  d-flex align-items-center" style={{textAlign:'center', marginTop:'10px'}}> 
                                    {showModules ? 'No está siguiendo ningún módulo.' : 'No está siguiendo ningún caso especial.'}
                                </div>
                            </div>
                        } 
                    </div> 
                </Fragment> 
            }
            <div style={{marginBottom:'15px'}}>
                        
            </div>
            </MuiThemeProvider>
        </Fragment>
    );
}