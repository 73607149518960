import React, { useState, createRef, Fragment } from 'react';
import items from './Assets/Items';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import TablaAntiguedad from './Tablas/TablaAntiguedad';
import TablaContrato from './Tablas/TablaContrato';
import TablaNombramiento from './Tablas/TablaNombramiento';
import TablaNomina from './Tablas/TablaNomina';
import TablaPlaza from './Tablas/TablaPlaza';
import TablaPresupuestal from './Tablas/TablaPresupuestal';
import TablaPuesto from './Tablas/TablaPuesto';
import TablaStatus from './Tablas/TablaStatus';
import TablaSueldo from './Tablas/TablaSueldo';
import TablaTurno from './Tablas/TablaTurno';
import TablaZonaPago from './Tablas/TablaZonaPago';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import Titulo from './Tablas/Components/Titulo';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';

const InfoHistorico = ({ id, permisos, empleadoSelected, setEmpleadoSelected }) => {

    const [error, setError] = useState(null);
    const [seleccionado, setSeleccionado] = useState('');
    const [openAlertError, setOpenAlertError] = useState(false);
    const [textAlertError, setTextAlertError] = useState('false');

    const { idEmpleado, nombreEmpleado, nueEmpleado } = empleadoSelected;

    const elementoRef = createRef('');

    const cambiaElemento = (item) => {
        const elemento = item;
        setSeleccionado(elemento);
    };

    const cerrarModal = () => {     
        setError(null);
        setTextAlertError('');
        setEmpleadoSelected(null);
    };

    const switchTable = (item) => {
        switch(item) {
            case 'antiguedad':
                return  <CardComponent title={<Titulo item="Ingreso a Gobierno" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaAntiguedad
                                id={id} permisos={permisos} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'contrato':
                return  <CardComponent title={<Titulo item="Contrato" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaContrato
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'nombramiento':
                return  <CardComponent title={<Titulo item="Nombramiento" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaNombramiento
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'nomina':
                return  <CardComponent title={<Titulo item="Nómina" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaNomina
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'plaza':
                return  <CardComponent title={<Titulo item="Plaza" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaPlaza
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'presupuestal':
                return  <CardComponent title={<Titulo item="Clave Presupuestal" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaPresupuestal
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'puesto':
                return  <CardComponent title={<Titulo item="Puesto" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaPuesto
                                id={id} permisos={permisos} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'status':
                return  <CardComponent title={<Titulo item="Status" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaStatus
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'sueldo':
                return  <CardComponent title={<Titulo item="Sueldo" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaSueldo
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'turno':
                return  <CardComponent title={<Titulo item="Turno" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaTurno
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            case 'zonadepago':
                return  <CardComponent title={<Titulo item="Zona de Pago" nue={nueEmpleado} empleado={nombreEmpleado}/>}>
                            <TablaZonaPago
                                id={id} idEmpleado={idEmpleado} nombreEmpleado={nombreEmpleado}
                                setTextAlertError={setTextAlertError} setOpenAlertError={setOpenAlertError}
                            />
                        </CardComponent>;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <BtnBack tooltipText="Regresar a tabla de empleados" action={cerrarModal} />
            <div className="row">
                <div className="col-12">
                    <SelectValidation
                        title="Categoría de Histórico" arrayOpciones={items} keyOpcion="nombre" onChangeExecute={cambiaElemento}
                        tooltipText="Seleccione la categoría de histórico que desea consultar"
                        refValue={elementoRef} defaultValue="" required
                    />
                    { error }
                </div>
            </div>
            { switchTable(seleccionado) }
            <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Petición Fallida" text={textAlertError} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
export default InfoHistorico;