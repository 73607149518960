import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, tablas_subsidios, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonTablasImpuestos, ArrayJsonTablasImpuestosAdd } from '../../../Helpers/JsonToOneLevel'; 
import DinamicTableTablasSubsidios from './DinamicTableTablasSubsidios';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';


const TablasSubsidio = ({ id, permisos }) => {

    const [tablasSubsidios, setTablasSubsidios] = useState(null); 
    const [dinamicTable, setDinamicTable] =useState(''); 
    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ onlyRead, setOnlyRead ] = useState(true);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        
        Axios.get(`${servicios_grupohit_catalogo}s/subsidio/rango/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTablasSubsidios(ArrayJsonTablasImpuestos( res.data.dataResponse ));
                    break;
                case 404:
                    setTablasSubsidios([]);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    },[]);

    const showTable = async ( value, elemmentSelect={}, isNew=null, editElement=null ) => { 
        
        await setOnlyRead( !elemmentSelect.status );

        if(isNew){
            let newData=[elemmentSelect, ...tablasSubsidios];
            
            //setTablasSubsidios( tablasSubsidios.map( element => element.periodoImptoId === elemmentSelect.periodoImptoId && element.status ? element.status = false : element ) );
            setTablasSubsidios(ArrayJsonTablasImpuestosAdd(newData));
        } 
        if(editElement!==null){ 
            setTablasSubsidios( tablasSubsidios.map( element => element.id === editElement.id ? element = editElement : element ) ); 
        }
        
        await setElemmentSelect(elemmentSelect);
        setDinamicTable(value); 
          
        return dinamicTable;
    }

    return (
        <div id={`modulo${id}`}>
            {   
                dinamicTable
                ?
                   <DinamicTableTablasSubsidios idTab={elemmentSelect}  onShowTable={showTable} onlyRead={onlyRead}  idModulo={ id }/>
                :
                    <div>
                    {
                        tablasSubsidios
                        ?   <DataTableService id={id} title={ tablas_subsidios }
                                permisos={permisos} 
                                columns={[
                                    { field: "periodoImpto", title: "Tipo" },
                                    { field: "fechaIni", title: "Fecha Inicial" },
                                    { field: "fechaFin", title: "Fecha Final" }
                                ]}
                                data={tablasSubsidios}
                                setData={setTablasSubsidios}
                                onShowTable={showTable}
                            />
                        :   <SpinnerOval />
                    }
                    </div>
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
}
 
export default TablasSubsidio;
