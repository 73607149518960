import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormPuestos from './FormPuestos'; 
import { JsonPuestos } from '../../../Helpers/JsonToOneLevel';

const ModalAddPuestos = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [ error, setError ] = useState( null );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarPuesto = puesto => {
        setIsLoading(true);
        const info = puesto;
        
        Axios.post(`${servicios_grupohit}/org/puesto/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(JsonPuestos(res.data.dataResponse));
                    setOpenAdd( false );
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Puesto" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormPuestos
                        enviarPuesto={enviarPuesto}
                        error={error}
                        errorTable={errorTable}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddPuestos;