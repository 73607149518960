import React, { useState, createRef } from 'react';

import axios from 'axios';
import { servicios_grupohit_mensajes, verifique_conexion } from '../../../../../../../Constants/Constants';
import { headersAuth, obtenerUsuario } from '../../../../../../../Services/AuthService';

import AutoCompleteUsuarios from './AutoCompleteUsuarios';
import TextField from '@material-ui/core/TextField';
import QuillComponent from './QuillComponent';

import { getToArray, getToNombreArray } from '../../HelperMensajes';
import SeleccionaElemento from '../SeleccionaElemento';

import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';

const EnviarMensaje = ({ usuarios, setPanelDerecho, socket, responder, reenviar }) => {
    const asuntoRef = createRef('');
    const [ destinatarios, setDestinatarios ] = useState( responder ? usuarios.filter( element => element.username === responder.from ) : [] );
    const [ mensaje, setMensaje ] = useState( reenviar ? reenviar.mensaje : '' );
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ textError, setTextError ] = useState( '' );
    const { usuario, nombre, apaterno, token } = obtenerUsuario();

    const enviar = () => {
        if( destinatarios.length > 0 ){
            const asunto = asuntoRef.current.value;
            axiosMensaje({ from: usuario, fromNombre: `${nombre} ${apaterno}`, to: getToArray(destinatarios), toNombre: getToNombreArray(destinatarios), mensaje, asunto });
            socket.emit('inbox:inboxdesc', { from: usuario, fromNombre: `${nombre} ${apaterno}`, to: getToArray(destinatarios), toNombre: getToNombreArray(destinatarios), mensaje, asunto, token });
        }
    }

    const mostrarError = msg => {
        setTextError(msg);
        setOpenAlert(true);
    };

    const axiosMensaje = ( mensaje ) => {
        axios.post( `${servicios_grupohit_mensajes}/`, mensaje, headersAuth() )
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        setPanelDerecho( <SeleccionaElemento /> );
                        break;
                    case 400:
                        break;
                    default:
                        mostrarError(res.data.msg);
                        break;
                }
            })
            .catch( error => mostrarError(verifique_conexion) )
    }

    return (
        <div className="enviarMensaje">
            <AutoCompleteUsuarios usuarios={usuarios} setDestinatarios={setDestinatarios} value={destinatarios}/>
            <TextField variant="outlined" size="small" placeholder="Agregar un asunto" defaultValue={ responder ? `RE: ${responder.asunto}` : reenviar ? `RV: ${reenviar.asunto}` : '' }
                inputRef={asuntoRef}/>
            <QuillComponent mensaje={mensaje} setMensaje={setMensaje} actionEnviar={enviar}/>
            <AlertSyE show={openAlert} setOpenAlertSyE={setOpenAlert} title="Petición Fallida" text={textError} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
};

export default EnviarMensaje;