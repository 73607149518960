import React,{Component, Fragment} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import './Styles.css'
import PasswordInput from '../Validation/PasswordInput';
import { lengthPassword, servicios_grupohit_url } from '../../Constants/Constants';
import Axios from 'axios';
import { headersAuth } from '../AuthService';
import AlertSyE from './AlertSyE';
import AlertForm from '../AlertForm';

export default class AlertWarning extends Component {
    /*
      Para utlizar la alerta de warnning , esta debe de recibir por props  
      show={} ,propiedad para mostrar el alert solo puede ser true 
      SetopenALert={} ,propiedad para ocultar el alert solo puede ser false
      text="" ,Esta cadena se muestra en el cuerpo de la alerta
      textButtonAceptar="" , Esta cadena se mostrará en el cuerpo del boton aceptar
      textButtonCancelar="" , Esta dadena se mostrará en el cuerpo del boton cancelar
      action={}, esta propiedad recibe la funcion a ejecutar, opcional
      valor={} esta propiedad es opcional y es el valor que se le quier pasara la función
      Por ejemplo :
      <AlertWarning show={openAlertW} SetopenALert={SetopenALertW} title="Warning" text="Hola desde otro componente 1"  textButtonAceptar=" Aceptar 1" textButtonCancelar=" Cancelar 1" action={namefunction} actionCancel={actionCancel} value={valor}/>
    */
    state = {
      disable: false,
      contraseñaRef : React.createRef(),
      alertError: false,
      textAlert : '',
      error:null
    };

    async Confirm(e){
        
        //metodo pro props
        
        //si existe la propiedad this.props.action, se ejecutartá
        if(this.props.action) {  
            if(this.props.requirePassword){ 
              let statusContrasena='';
              try {
                const data = { password: this.state.contraseñaRef.current.value};
                statusContrasena = await Axios.post( `${servicios_grupohit_url}/account/password/`, data, headersAuth() );
              } catch (error) {
                statusContrasena= error.data.status; 
              }

              if(statusContrasena.data?.status===200){
                this.setState({ disable: true })
                this.hideAlert();
                if(this.props.value){ 
                  this.props.action(this.props.value);
                }else{
                  this.props.action(e);
                }
              }else{
                this.setState({ error: <AlertForm  message={statusContrasena.data?.msg}/> });
              }
            }else{
              this.setState({ disable: true })
              this.hideAlert();
              if(this.props.value){
                this.props.action(this.props.value);
              }else{
                this.props.action(e);
              }
            }
            
          } else {
            this.setState({ disable: true })
            this.hideAlert();
        }
    }

    Cancel(){
      if(this.props.actionCancel) {
        this.props.actionCancel();
      } 
      this.hideAlert();
    }

    hideAlert() {
      this.props.SetopenALert(false);
    }

    hideAlertError (value){
      this.setState({ alertError: false });
    }
  
    render() {
      return (
        
            (this.props.show===false)?null:
            <Fragment>
            <SweetAlert 
                warning
                showCancel   
                //titulo del modal
                title={this.props.title ? this.props.title :"¿Está seguro?"}
                customButtons={
                  <React.Fragment>
                    <button className="btn-color-alert" onClick={() => this.Confirm()} disable={this.state.disable.toString()}>{this.props.textButtonAceptar}</button>
                    { (this.props.textButtonCancelar) &&
                      <button className="btn-outline-alert-cancel" onClick={()=>this.Cancel()}> {this.props.textButtonCancelar} </button>
                    }
                  </React.Fragment>
                }
                /*Metodos que se ejecutan en cual de las 2 opciones */
                onConfirm={() => this.Confirm()}
                onCancel={()=>this.Cancel()}
            >   
              <Fragment>
                  {this.props.text}
                  {this.props.subtitle &&
                      <div>
                          { this.props.subtitle }
                      </div>
                  }
                  {
                    this.props.requirePassword &&
                      <div className="form-group" style={{textAlign:'left'}}>
                        <label className="col-form-label label-form">Contraseña<strong className="requerido">* </strong></label>
                        <input style={{ position: 'relative', zIndex: -1000, width: 0, height: 0, bottom: '-20px' }}/>
                        <PasswordInput refValue={this.state.contraseñaRef} maxLength={lengthPassword} newPassword/>
                    </div>
                  }
                  {this.state.error}
              </Fragment>
            </SweetAlert>
            <AlertSyE show={this.state.alertError} setOpenAlertSyE={this.hideAlertError} title="Petición Fallida" text={this.state.textAlert} textbtnconfirm="Aceptar" type="error"/>
            </Fragment>
      );
    }
  }