import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import { pagos_unicos } from '../../../../../../Constants/Constants';
import CargasMasivas from '../CargaMasivaDatos/CargasMasivas/CargasMasivas';

const ModalCargaMasivaPagosUnicos = ({ openAdd, setOpenAdd, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    
    const cerrarModal = () => {
        setOpenAdd( false );
    };

    return (
        <ModalService title={`Carga Masiva de ${pagos_unicos}`} parentNode={nombreModulo} minWidth="33%" maxWidth="35%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <b>Descarga</b>
                <div>1. Baje el archivo.</div>
                <div>2. Realice los cambios necesarios en el archivo descargado.</div>
                <br/>
                <b>Carga</b>
                <div>1. Busque el archivo con extensión .csv.</div>
                <div>2. Una vez que el sistema lo analice:</div>
                <div>Si no se presentan incidencias, haga la carga. De lo contrario, modifique el archivo y repita el procedimiento.</div>
                <hr/>
                <CargasMasivas
                    modulo={{ id: nombreModulo }}
                    errorAlert={errorTable}
                    successAlert={successTable}

                />
        </ModalService>
    );
};

export default ModalCargaMasivaPagosUnicos;