import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import FormAcumulados from './FormAcumulados';
import { JsonConceptosEmp2 } from '../../../../Helpers/JsonToOneLevel';

const ModalEditAcumulados = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo, informacionAdicional }) => {

    const [error, setError] = useState(null);
    const [error2, setError2] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
        setError2(null);
    };

    const actualizarAcumulado = (infoAcumulado, acumuladoId) => {
        const request = infoAcumulado;
        const id = acumuladoId;
        axios.put(`${servicios_grupohit_nomina}/acumulados/tacumulados/${id}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(JsonConceptosEmp2(res.data.dataResponse));
                    setOpenEdit(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    return (
        <ModalService
            title="Actualizar Acumulado"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormAcumulados
                        setError={setError}
                        error={error}
                        setError2={setError2}
                        error2={error2}
                        elementSelect={elementSelect}
                        actualizarAcumulado={actualizarAcumulado}
                        informacionAdicional={informacionAdicional}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditAcumulados;