import React, { useState, useEffect, createRef, Fragment } from 'react';
import { transferenciaPermutaEmpleados } from '../../../../../../Redux/actions/EmpleadosActions';
import { connect } from 'react-redux';
import axios from 'axios';
import { servicios_grupohit_admon, servicios_grupohit_org, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { inputDateToLong, validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import ModalService from '../../../ModalService/ModalService';
import Empleado from './Components/PermutaTransferencia/Empleado';
import Permuta from './Components/PermutaTransferencia/Permuta';
import Transferencia from './Components/PermutaTransferencia/Transferencia';
import RadioTipoMovimiento from './Components/PermutaTransferencia/RadioTipoMovimiento';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import Button from '@material-ui/core/Button';
import servicioFechaMinima from './Helpers/ServicioFechaMinima';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../Services/AlertForm';
import ConvertDate from '../../../Helpers/ConvertDate';

const ModalPermutasTransferencias = ({ openModalPermTrans, setOpenModalPermTrans, elementSelect, errorTable, successTable, nombreModulo, empleadosActivos, transferenciaPermutaEmpleados }) => {

    const [movimiento, setMovimiento] = useState('Permuta');
    const [empleadosFilter, setEmpleadosFilter] = useState([]);
    const [empleado, setEmpleado] = useState(null);
    const [errorEmpleado, setErrorEmpleado] = useState(false);
    const [cPresupuestales, setCPresupuestales] = useState([]);
    const [cPresupuestal, setCPresupuestal] = useState(null);
    const [errorCPresupuestal, setErrorCPresupuestal] = useState(false);
    const [departamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState(null);
    const [disabledDepartamento, setDisabledDepartamento] = useState(true);
    const [errorDepartamento, setErrorDepartamento] = useState(false);
    const [fechaMinima, setFechaMinima] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fechaInicioRef = createRef('');

    const obtenerFechaMinima = async (arrayEmpleados) => {
        try {
            const res = await servicioFechaMinima({ arrayEmpleados });
            switch(res.data.status) {
                case 200:
                    setFechaMinima(res.data.dataResponse);
                    break;
                default:
                    setFechaMinima(0);

                    break;
            }
        } catch (error) {
            setFechaMinima(0);

        }
    };

    useEffect(() => {
        setError(null);
        setFechaMinima(null);
        if(elementSelect.id && (movimiento === 'Transferencia')) obtenerFechaMinima([elementSelect.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect, movimiento]);

    useEffect(() => {
        if(elementSelect && openModalPermTrans) {
            const emp = empleadosActivos.filter(empleado => empleado.areaId !== elementSelect.areaId && empleado.nivLaboralClave === elementSelect.nivLaboralClave);
            setEmpleadosFilter(emp);
        }
    }, [openModalPermTrans, elementSelect, empleadosActivos]);

    useEffect(() => {
        if(openModalPermTrans){
            obtenerCPresupuestales();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModalPermTrans]);

    const obtenerCPresupuestales = () => {
        axios.get(`${servicios_grupohit_admon}/presupuestal/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setCPresupuestales(res.data.dataResponse);
                    break;
                case 404:
                    setCPresupuestales([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    };

    const permutaTransferencia = (movimiento === 'Permuta')
    ?   <Permuta empleado={empleado}/>
    :   <Transferencia empleado={elementSelect} cPresupuestal={cPresupuestal} departamento={departamento}/>;

    const validaEmpleado = value => {
        setEmpleado(value);
        if(value !== null) {
            obtenerFechaMinima([elementSelect.id, value.id]);
            setErrorEmpleado(false);
            return;
        }
        setFechaMinima(null);
    };

    const validarCPresupuestal = value => {
        if(value !== null) {
            setCPresupuestal(value);
            setErrorCPresupuestal(false);
            setDisabledDepartamento(false);
            setDepartamento(null);
            setDepartamentos(null);
            //petición para obtener los departamentos, según la unidad responsable seleccionada
            axios.get(`${servicios_grupohit_org}/area/${value.obraAccion.proyecto.subPrograma.programa.udejecutoraid.ciaid.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        if(elementSelect.cpresupuestalId === value.id) {
                            const arrayDepartamentos = res.data.dataResponse.filter(dep => dep.id !== elementSelect.areaId)
                            setDepartamentos(arrayDepartamentos);
                        } else {
                            setDepartamentos(res.data.dataResponse);
                        }
                        break;
                    case 404:
                        setDepartamentos([]);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { errorTable(verifique_conexion); });
        } else {
            setDisabledDepartamento(true);
            setDepartamentos([]);
        }
    };

    const validarDepartamento = value => {
        setDepartamento(value);
        if(value !== null) {
            setErrorDepartamento(false);
        }
    };

    const campos = (movimiento === 'Permuta')
    ?   <AutocompleteComponent id="empleadoPermuta" title="Empleado a Permutar"
            options={empleadosFilter} action={validaEmpleado} optionListView="nombreCompleto"
            placeholder="Empleado" tooltipText="Busque el Empleado, por nombre o NUP, en el campo de autocompletado"
            error={errorEmpleado} required
        />
    :   <Fragment>
            <div className="mb-3">
                <AutocompleteComponent id="cPresupuestal" title="Clave Presupuestal / Proyecto"
                    options={cPresupuestales} optionListView="cpresupuestalnom"
                    action={validarCPresupuestal}
                    placeholder="Clave Presupuestal / Proyecto"
                    tooltipText="Busque la Clave Presupuestal / Proyecto, por nombre o clave, en el campo de autocompletado"
                    error={errorCPresupuestal} required
                />
            </div>
            <AutocompleteComponent id="departamento" title="Clave Departamento / Departamento"
                value={departamento}
                options={departamentos} optionListView="areanom"
                action={validarDepartamento} disabled={disabledDepartamento}
                placeholder="Clave Departamento / Departamento"
                tooltipText="Busque el Departamento, por nombre o clave, en el campo de autocompletado"
                error={errorDepartamento} clearDiasabled required
            />
        </Fragment>;

    const cerrarModal = () => {
        setMovimiento('Permuta');
        setEmpleadosFilter([]);
        setEmpleado(null);
        setErrorEmpleado(false);
        setCPresupuestales([]);
        setCPresupuestal(null);
        setErrorCPresupuestal(false);
        setDepartamentos([]);
        setDepartamento(null);
        setDisabledDepartamento(true);
        setErrorDepartamento(false);
        setOpenModalPermTrans(false);
        setFechaMinima(null);
    };

    const handleChangeRadioMovimiento = e => {
        setMovimiento(e.target.value);
        if(e.target.value === 'Permuta') {
            setCPresupuestal(null);
            setDepartamento(null);
            setEmpleado(null);
            return;
        }
        if(e.target.value === 'Transferencia') {
            setEmpleado(null);
            return;
        }
    };

    const hacerCambio = e => {
        e.preventDefault();

        if(fechaMinima < inputDateToLong(fechaInicioRef.current.value)) {
            const dataToRequest = data => {
                const request = data.map(item => ({
                    empId: item.empId,
                    plazaId: item.plazaId,
                    cpresupuestalId: item.cpresupuestalId,
                    areaId: item.areaId,
                    empcpres_fecini: item.empcpres_fecini
                }));
    
                return request;
            };
            
            const servicioTransferenciaPermuta = data => {
                setIsLoading(true);
                const request = dataToRequest(data);
                transferenciaPermutaEmpleados(
                    request, data, successTable, errorTable, cerrarModal,
                    setCPresupuestal, setDepartamento, setErrorEmpleado, setErrorDepartamento, setErrorCPresupuestal,
                    setIsLoading
                );
            };
    
            if(movimiento === 'Permuta') {
                if(validarSubmit("FormPermTrans") && (empleado !== null)) {
                    const empleado1 = {
                        empId: elementSelect.id,
                        plazaId: elementSelect.plazaId,
                        plazaClave: elementSelect.plazaClave,
                        cpresupuestalId: empleado.cpresupuestalId,
                        cpresupuestalClave: empleado.cpresupuestalClave,
                        cpresupuestalNom: empleado.cpresupuestalNom,
                        areaId: empleado.areaId,
                        areaNom: empleado.areaNom,
                        empcpres_fecini: inputDateToLong(fechaInicioRef.current.value)
                    };
        
                    const empleado2 = {
                        empId: empleado.id,
                        plazaId: empleado.plazaId,
                        plazaClave: empleado.plazaClave,
                        cpresupuestalId: elementSelect.cpresupuestalId,
                        cpresupuestalClave: elementSelect.cpresupuestalClave,
                        cpresupuestalNom: elementSelect.cpresupuestalNom,
                        areaId: elementSelect.areaId,
                        areaNom: elementSelect.areaNom,
                        empcpres_fecini: inputDateToLong(fechaInicioRef.current.value)
                    };
        
                    const request = [empleado1, empleado2];
                    servicioTransferenciaPermuta(request);
                } else {
                    if(empleado === null) setErrorEmpleado(true);
                }
                return;
            }
            if(movimiento === 'Transferencia') {
                if(validarSubmit("FormPermTrans") && (cPresupuestal !== null) && (departamento !== null)) {
                    const request = [
                        {
                            empId: elementSelect.id,
                            cpresupuestalId: cPresupuestal.id,
                            cpresupuestalClave: cPresupuestal.cpresupuestalclave,
                            cpresupuestalNom: cPresupuestal.cpresupuestalnom,
                            areaId: departamento.id,
                            areaNom: departamento.areanom,
                            plazaId: elementSelect.plazaId,
                            plazaClave: elementSelect.plazaClave,
                            empcpres_fecini: inputDateToLong(fechaInicioRef.current.value)
                        }
                    ];
                    servicioTransferenciaPermuta(request);
                } else { 
                    if(cPresupuestal === null) setErrorCPresupuestal(true);
                    if(departamento === null) setErrorDepartamento(true);
                }
                return;
            }
        } else {
            setError(<AlertForm message={`La fecha inicial debe ser mayor que ${ConvertDate(fechaMinima)}`}/>)
        }
    };

    return (
        <ModalService
            title="Realizar Movimiento Especial"
            parentNode={nombreModulo}
            isOpen={openModalPermTrans}
            onClose={cerrarModal}
            minWidth="85%"
            maxWidth="85%"
        >
            <div className="row">
                <div className="col">
                    <Empleado empleado={elementSelect} />
                </div>
                <div className="col">
                    { permutaTransferencia }
                </div>
            </div>
            <div className="row">
                <div className="col align-self-end">
                    <RadioTipoMovimiento action={handleChangeRadioMovimiento}/>
                    { campos }
                </div>
                <div className={`col align-self-end ${(movimiento === 'Permuta') ? 'mb-2' : ''}`}>
                    <form id="FormPermTrans" onSubmit={hacerCambio}>
                    {(fechaMinima)
                    ?   <Fragment>
                            <InputDateValidation
                                title="Fecha de Inicio" refValue={fechaInicioRef}
                                validateGreaterThan={fechaMinima}
                                onChangeExecute={() => setError(null)}
                                required
                            />
                            {(isLoading === true)
                            ?   <SpinnerOval />
                            :   <Button type="submit" variant="contained" className={`btn-color ${(movimiento === 'Transferencia') ? 'marginBtnPermutasTranferencias mb-2' : 'mb-1'}`}>
                                { (movimiento === 'Permuta') ? 'Realizar Permuta' : 'Realizar Transferencia' }
                                </Button>
                            }
                        </Fragment>
                    :   (fechaMinima === 0)
                        ?   <AlertForm message="No hay fecha mínima para verificar"/>
                        :   (empleado)
                            ?   <SpinnerOval/>
                            :   <AlertForm message="Seleccione un empleado"/>
                    }
                    </form>
                </div>
            </div>
            {error &&
            <div className='row'>
                <div className='col'>{ error }</div>
            </div>
            }
        </ModalService>
    );
}
 
const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos
});

const mapDispatchToProps = { transferenciaPermutaEmpleados };
  
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalPermutasTransferencias);