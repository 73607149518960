import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonImpuestoNomina } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormularioImpuestoNomina from './FormularioImpuestoNomina';

const ModalAddImpuestoNomina = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarImpuestoNomina = ( impuestoNomina ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit_catalogo}/isn/`, impuestoNomina, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonImpuestoNomina(respuesta.data.dataResponse) );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Agregar Impuesto Sobre Nómina" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioImpuestoNomina
                    error={error}
                    informacion={informacionAdicional}
                    enviarImpuestoNomina={enviarImpuestoNomina}
                    isLoading={isLoading}
                />
        </ModalService>
    );
};

export default ModalAddImpuestoNomina;