import React, { Fragment } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';

const BlueRadio = withStyles(BlueCheckboxRadioStyles)((props) => <Radio color="default" {...props} />);

const Prestamos = ({ minLiquidez, setMinLiquidez }) => {

    const handleRadioSindicatoChange = (event) => {
        setMinLiquidez(event.target.value);
    };
    
    return (
        <Fragment>
            <h5>Préstamos</h5>
            <div className="row justify-content-center align-items-center">
                <div className="col">
                    <label>¿Monto mínimo de liquidez?</label>
                </div>
                <div className="col">
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position"
                            defaultValue={minLiquidez}
                            onChange={handleRadioSindicatoChange}
                        >
                            <FormControlLabel value="si" control={<BlueRadio />} label="Sí"/>
                            <FormControlLabel value="no" control={<BlueRadio />} label="No"/>
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </Fragment>
    );
}
 
export default Prestamos;