import React from 'react';

const SubtitleCard = ({ subtitle }) => {
    return (
        <div className="col" style={{ marginBottom: '10px'}}>
            <h6>
                <small className="text-muted">
                    { subtitle }
                </small>
            </h6>
        </div>
    );
}
 
export default SubtitleCard;