import React from 'react';

const SpinnerOval = () => {
    return(
        <center>
            <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </center>
    );
}

export default SpinnerOval;