
/* Funcion para retornar encabezado de exportacion de un archivo en base64 */
export function gerURL( extension ) {
    switch ( extension ) {
        case 'jpg': return 'data:image/jpeg;base64,';
        case 'jpeg': return 'data:image/jpeg;base64,';
        case 'png': return 'data:image/png;base64,';

        case 'pdf': return 'data:application/pdf;base64,';
        case 'doc': return 'data:application/msword;base64,';
        case 'docx': return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';

        case 'xls': return 'data:application/vnd.ms-excel;base64,';
        case 'xlsx': return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
        case 'csv': return 'data:text/csv;base64,';
        default: return '';
    }
}