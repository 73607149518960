import React, { createRef, useState, useEffect, Fragment } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, registro_sera_actualizado, registro_guardado, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import { validarSubmit, amountToFloat, floatToAmount } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; 
import AlertForm from '../../../../../../Services/AlertForm'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core'; 
import { ArrayJsonSalarioMinUMAIndicadores, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel'; 
import { obtenerModalAdd, obtenerModalEdit } from '../../../DataTableService/HerlperDialogs';
import { withStyles } from '@material-ui/core/styles';
import BlueCheckboxRadioStyles from '../../../../../../Services/CheckBoxRadio/BlueCheckboxRadioStyles';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
});

const CheckboxConceptosGenerales = withStyles(BlueCheckboxRadioStyles)((props) => <Checkbox color="default" {...props} />);

const FormIndicadoresGenerales = ({ error, setError, enviarIndicadorGeneral, elementSelect, actualizarIndicadorGeneral, vista, idModulo, indicadoresGeneral, isLoading, ejecutaER, setEjecutaER }) => {

    const [ errorAuto, setErrorAuto ] = useState( null );
    const [relacionLaboral, setRelacionLaboral] = useState([]);
    const [naturaleza, setNaturaleza] = useState([]);
    const [clasificacionSAT, setClasificacionSAT] = useState([]);
    const [editRelacionLaboral, setEditRelacionLaboral] = useState('');
    const [editNaturaleza, setEditNaturaleza] = useState('');
    const [editClasificacionSAT, setEditClasificacionSAT] = useState('');
    const [openAlert, setOpenAlert] = useState(false);

    const [ openAdd, setOpenAdd ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState(''); 
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    

    const claveRef = createRef('');
    const nombreRef = createRef('');
    const relLaboralRef = createRef('');
    const naturalezaRef = createRef('');
    const indicadorSATRef = createRef('');
    const clasificacionSATRef = createRef('');
    const partGenericaRef = createRef('');
    const partEspecifRef = createRef('');
    const ctaContableRef = createRef('');
    const subCtaContableRef = createRef('');
    const importeRef = createRef(0);
    const montoMinimoRef = createRef('');
    const montoMaximoRef = createRef('');

     
    const [ montoMinimoRequired, setMontoMinimoRequired ] = useState(true);
    const [ montoMaximoRequired, setMontoMaximoRequired ] = useState(true);
    
    const [ dataConfigBase, setDataConfigBase ] = useState(null);
    const [ updateIndicadorParametros, setUpdateIndicadorParametros ] = useState(false);
    const [ defValTopVec, setDefValTopVec ] = useState(null);
    const [ defValCalcSob, setDefCalcSob ] = useState(null);
    const [ defValMonto, setDefValMonto ] = useState(null);

    const [ idIndicadorParametro, setIdIndicadorParametro ] = useState(null);
    
    const [active, setActive] = useState(1);
 
    const topeVecesRef = createRef(0);  
    const prioridadRef = createRef(''); 
    const rutinaCalcRef = createRef(''); 
    const calcSobreRef = createRef('');
    const [ indicadorAuto, setIndicadorAuto ] = useState( null ); 

    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null); 
    const [ exentoReq , setExentoReq ]= useState(false);
    const [ montoZona , setMontoZona ]= useState(0);
    const [ rutinas , setRutinas ]= useState(null);
    const [ apareceRecib , setApareceRecib ]= useState(elementSelect ? elementSelect.recibo:  false);
    const [salarioMinimos, setSalarioMinimos] = useState(null); 
    const claveIndicadorGeneral = elementSelect ? elementSelect.indicadorClave : '';

    const [indicadorSueldo, setIndicadorSueldo] = useState(false);
    const [causaNombramiento, setCausaNombramiento] = useState(false);
    const [porDefecto, setPorDefecto] = useState(false);
    
    useEffect(() => {
        if((elementSelect?.id && salarioMinimos && indicadoresGeneral) || ejecutaER) {
            setIndicadorSueldo(elementSelect.sueldo);
            setCausaNombramiento(elementSelect.nombramiento);
            setPorDefecto(elementSelect.defecto);

            Axios.get(`${servicios_grupohit_catalogo}/indicadores/parametros/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setUpdateIndicadorParametros(true);
                        let response= res.data.dataResponse[0]; 
                        let calcSobreNew= salarioMinimos.find( element => element.id === parseInt(response.zona.id));
                        elementSelect.idParametro = response.id;
                        elementSelect.idParametroConcepto = response.indicador;
                        elementSelect.zonaId = response.zona.id;
                        elementSelect.topeVeces = response.topeveces;
                        setDefValMonto( floatToAmount(parseFloat(response.topeveces) * amountToFloat(calcSobreNew.monto)) );
                        setIdIndicadorParametro( response.id );
                        setDefValTopVec( response.topeveces );
                        setDefCalcSob( response.zona.id ); 
                        setMontoZona(amountToFloat(calcSobreNew.monto)); 
                        let indicadorJson= indicadoresGeneral.find( element => element.id === response.indicador); 
                        setIndicadorAuto(indicadorJson); 
                        setExentoReq( true ); 
                        break;
                    default:
                        setUpdateIndicadorParametros(false);
                        break;
                }
            })
            .catch(error => {
                setEjecutaER(false);
                setUpdateIndicadorParametros(false);
            });
        }
        // eslint-disable-next-line
    }, [elementSelect, salarioMinimos, indicadoresGeneral, ejecutaER]);

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    const unidades = res.data.dataResponse.filter(item => item.fechaFin === null);
                    setSalarioMinimos(ArrayJsonSalarioMinUMAIndicadores(unidades)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            } 
        })
        .catch(error => errorTable(verifique_conexion));

        Axios.get(`${servicios_grupohit_catalogo}/rutina/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    setRutinas( res.data.dataResponse );
                    break;
                case 404:
                    setRutinas([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion)); 
        // eslint-disable-next-line
    }, [ejecutaER]);
    
    const deleteOptionals =( objectRequest )=>{
        var temporal = objectRequest;  
        Object.keys( temporal ).forEach( key => { 
            if( temporal[key] === " " ){
                delete temporal[key];
            }
        });
        return temporal;
    }

    const enviarForm = (evt) => {
        evt.preventDefault();
        if(indicadorAuto === null && exentoReq) {
            validarSubmit( 'AgregarIndicadorConfig' )
            setErrorAuto(<AlertForm message="Complete los campos" />); 
        }else{
            if( validarSubmit( 'AgregarIndicadorConfig' ) ){ 
                setErrorAuto('');
                setError(null);
                const indicadorClave = claveRef.current.value;
                const indicadorNom = nombreRef.current.value;
                const relacion = parseInt(relLaboralRef.current.value);
                const naturaleza = parseInt(naturalezaRef.current.value);
                const indicadorSat = indicadorSATRef.current.value;
                const clasificaSat = parseInt(clasificacionSATRef.current.value);
                const partidagen = partGenericaRef.current.value;
                const partidaesp = partEspecifRef.current.value;
                const ctacontable = ctaContableRef.current.value;
                const subcta = subCtaContableRef.current.value;
                const montoMin = montoMinimoRef.current.value !== '' ? amountToFloat(montoMinimoRef.current.value) : '';
                const montoMax = montoMaximoRef.current.value !== '' ? amountToFloat(montoMaximoRef.current.value) : '';

                const rutinaId = parseInt(rutinaCalcRef.current.value);
                const prioridad = parseInt(prioridadRef.current.value);
                const recibo = apareceRecib==null ? false : apareceRecib; 

                var indicadorParametros=null;
                if(exentoReq){ 
                    const indicador = indicadorAuto.id;
                    const zonaId = parseInt(calcSobreRef.current.value);
                    const topVeces = parseInt( topeVecesRef.current.value );
                    indicadorParametros={ indicador, zonaId, topVeces }; 
                }

                const dataConf=[];
                for (const key in dataConfigBase) {
                    dataConf.push(deleteOptionals(dataConfigBase[key]));
                } 

                let cuentas = {};
                if(partidagen.length >= 1 && partidagen.length <= 3) cuentas = { ...cuentas, partidagen };
                if(partidaesp.length >= 1 && partidaesp.length <= 3) cuentas = { ...cuentas, partidaesp };
                if(ctacontable.length >= 1 && ctacontable.length <= 3) cuentas = { ...cuentas, ctacontable };
                if(subcta.length >= 1 && subcta.length <= 3) cuentas = { ...cuentas, subcta };
                
                setError(null);
                if(montoMin!=='' && montoMax!=='' ){
                    enviarIndicadorGeneral({
                            indicadorClave, indicadorNom, relacion, naturaleza, indicadorSat, clasificaSat, cuentas, montoMin, montoMax, rutinaId,
                            prioridad, recibo, sueldo: indicadorSueldo, nombramiento: causaNombramiento, defecto: porDefecto
                        },
                        dataConf.length>0 ? { basesIndicadores: [dataConf] } : null,
                        indicadorParametros
                    );
                }else{
                    enviarIndicadorGeneral({
                            indicadorClave, indicadorNom, relacion, naturaleza, indicadorSat, clasificaSat, cuentas, rutinaId, prioridad, recibo,
                            sueldo: indicadorSueldo, nombramiento: causaNombramiento, defecto: porDefecto
                        },
                        dataConf.length>0 ? { basesIndicadores: [dataConf] } : null,
                        indicadorParametros
                    );
                }
            }
        }
    }

    useEffect(() => {
        Axios.get(`${servicios_grupohit_catalogo}/relacionlaboral/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(enviarIndicadorGeneral) {
                        setRelacionLaboral(res.data.dataResponse);
                    } else {
                        setEditRelacionLaboral(res.data.dataResponse.find(rl => rl.id === elementSelect.relacionLabId ));
                    }
                    break;
                case 404:
                    setRelacionLaboral([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        Axios.get(`${servicios_grupohit_catalogo}/tipoNaturaleza`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(enviarIndicadorGeneral) {
                        setNaturaleza(res.data.dataResponse);
                    } else {
                        setEditNaturaleza(res.data.dataResponse.find(csat => csat.id === elementSelect.naturalezaId ));
                    }
                    break;
                case 404:
                    setNaturaleza([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

        Axios.get(`${servicios_grupohit_catalogo}/clasificaSat`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(enviarIndicadorGeneral) {
                        setClasificacionSAT(res.data.dataResponse);
                    } else {
                        setEditClasificacionSAT(res.data.dataResponse.find(csat => csat.id === elementSelect.clasificaSatId ));
                    }
                    break;
                case 404:
                    setClasificacionSAT([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const indicadorAutocomplete = valor => {
        setIndicadorAuto(valor); 
    }

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const actualizar = () => {
        if(indicadorAuto === null && exentoReq) {
            validarSubmit( 'EditarIndicadorConfig' )
            setErrorAuto(<AlertForm message="Complete los campos" />); 
        }else{
            if(validarSubmit( 'EditarIndicadorConfig')) {
                const indicadorClave = claveRef.current.value;
                const indicadorNom = nombreRef.current.value;
                const indicadorSat = indicadorSATRef.current.value;
                const montoMin = montoMinimoRef.current.value !== '' ? amountToFloat(montoMinimoRef.current.value) : '';
                const montoMax = montoMaximoRef.current.value !== '' ? amountToFloat(montoMaximoRef.current.value) : '';

                const rutinaId = parseInt(rutinaCalcRef.current.value);
                const prioridad = parseInt(prioridadRef.current.value);
                const recibo = apareceRecib==null ? false : apareceRecib; 

                var indicadorParametros=null;
                if(exentoReq){ 
                    const indicador = indicadorAuto.id;
                    const zonaId = parseInt(calcSobreRef.current.value);
                    const topVeces = parseInt( topeVecesRef.current.value );
                    indicadorParametros={ indicador, zonaId, topVeces };
                    if(updateIndicadorParametros){
                        indicadorParametros.id = idIndicadorParametro;
                    }
                } 

                let cuentas = {};
                if(partGenericaRef.current.value.length >= 1 && partGenericaRef.current.value.length <= 3) cuentas = { ...cuentas, partidagen: partGenericaRef.current.value };
                if(partEspecifRef.current.value.length >= 1 && partEspecifRef.current.value.length <= 3) cuentas = { ...cuentas, partidaesp: partEspecifRef.current.value };
                if(ctaContableRef.current.value.length >= 1 && ctaContableRef.current.value.length <= 3) cuentas = { ...cuentas, ctacontable: ctaContableRef.current.value };
                if(subCtaContableRef.current.value.length >= 1 && subCtaContableRef.current.value.length <= 3) cuentas = { ...cuentas, subcta: subCtaContableRef.current.value };

                setError(null);
                if(montoMin!=='' && montoMax!=='' ){
                    actualizarIndicadorGeneral({
                            indicadorClave, indicadorNom, indicadorSat, cuentas, montoMin, montoMax, rutinaId, prioridad,
                            recibo, sueldo: indicadorSueldo, nombramiento: causaNombramiento, defecto: porDefecto
                        },
                        elementSelect.id,
                        indicadorParametros,
                        updateIndicadorParametros
                    );
                }else{
                    actualizarIndicadorGeneral({
                            indicadorClave, indicadorNom, indicadorSat, cuentas, rutinaId, prioridad, recibo,
                            sueldo: indicadorSueldo, nombramiento: causaNombramiento, defecto: porDefecto
                        },
                        elementSelect.id,
                        indicadorParametros,
                        updateIndicadorParametros
                    );
                }
                
            }
        }
    };

     const siguiente = (e, pag) => {
        e.preventDefault();
        if(validarSubmit( 'AgregarIndicadorGeneral' ) && validarMontos()) {
            vista(pag);
            setActive(pag);
            if(!elementSelect) buscarIndicadoresRelLab();
        }
    }; 
    
    const openConfig = (e) => {
        e.preventDefault();
        setOpenAdd(true);
    }

    const saveConfig = ( data ) =>{
        if(data===null){
            setTextAlert( 'Registro Actualizado' );
            setAlertSuccess( true );     
        }else{ 
            setTextAlert( registro_guardado );
            setAlertSuccess( true ); 
            setDataConfigBase( data );
        }

    } 
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }

    const successTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    
    const updateTopeVeces = ( value ) => {
        importeRef.current.value = value!=='' ? floatToAmount( parseFloat(value) * montoZona, 2 ) : floatToAmount( 0, 2 ) ;
    }

    const getMontoZona = ( zona  ) => {
        var montoZon=0;  
        let calcSobreNew= salarioMinimos.filter( element => element.id === parseInt(zona)); 
        if(calcSobreNew.length>0){
            montoZon=amountToFloat( calcSobreNew[0].monto );  
            setMontoZona(montoZon); 
        }else{
            setMontoZona(0);
        }
        importeRef.current.value= topeVecesRef.current.value !=='' ? floatToAmount( parseFloat(topeVecesRef.current.value) * montoZon, 2 ) : floatToAmount( 0, 2 ) ; 
    }

    const buscarIndicadoresRelLab = () =>{
        Axios.get(`${servicios_grupohit_catalogo}/indicador/getall/${relLaboralRef.current.value}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse);
                    setIndicadoresGenerales(indicadores);
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion));
    }

    const validarMontos = () =>{
        if( montoMaximoRef.current.value !== '' && montoMinimoRef.current.value ===''){
            montoMinimoRef.current.className = montoMinimoRef.current.className+" is-invalid";
            setMontoMinimoRequired(false);
            setMontoMaximoRequired(false);
            return false;
        }
        if( montoMinimoRef.current.value !=='' && montoMaximoRef.current.value === ''){ 
            montoMaximoRef.current.className = montoMaximoRef.current.className+" is-invalid";
            setMontoMinimoRequired(false);
            setMontoMaximoRequired(false);
            return false;
        }

        if( montoMinimoRef.current.value ==='' && montoMaximoRef.current.value === ''){
            montoMinimoRef.current.className = montoMinimoRef.current.className.replace("is-invalid","");
            montoMaximoRef.current.className = montoMaximoRef.current.className.replace("is-invalid","");
            setMontoMinimoRequired(true);
            setMontoMaximoRequired(true);
        }
        if( montoMinimoRef.current.value !=='' && montoMaximoRef.current.value !== ''){
            if(parseFloat(montoMaximoRef.current.value) < parseFloat(montoMinimoRef.current.value)){
                montoMaximoRef.current.className = montoMaximoRef.current.className+" is-invalid";
                setMontoMinimoRequired(false);
                setMontoMaximoRequired(false);
                return false;
            }else{ 
                montoMaximoRef.current.className = montoMaximoRef.current.className.replace("is-invalid","");
                setMontoMinimoRequired(true);
                setMontoMaximoRequired(true);
            } 
        }
        return true;
    };

    const handleChangeIndicadorSueldo = () => {
        setIndicadorSueldo(!indicadorSueldo);
    };
    
    const handleChangeCausaNombramiento = () => {
        setCausaNombramiento(!causaNombramiento);
    };

    const handleChangePorDefecto = () => {
        setPorDefecto(!porDefecto);
    };

    return (  
        <Fragment>
            <div id="form-part-1" style={{ display: (active === 1) ? 'block' : 'none' }}>
                <form id={ 'AgregarIndicadorGeneral' } onSubmit={e => siguiente(e, 2)}>
                    <InputValidation title="Clave" type="claveIndGenerales" placeholder="Escriba la clave del concepto general"
                        refValue={claveRef} defaultValue={ elementSelect ? elementSelect.indicadorClave : '' } 
                        tooltipText={'Máximo 5 números'} maxLength={5} required onlyNumbers disabled={!enviarIndicadorGeneral}
                    />
                    <InputValidation title="Nombre" type="nombreIndGenerales" placeholder="Escriba el nombre del concepto general"
                        refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.indicadorNom : '' } 
                        tooltipText={`Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " ' # % / @ * ; :`} required maxLength={128}
                    />
                    <div className="row"> 
                        <div className="col-md-6 col-sm-12"> 
                        {(enviarIndicadorGeneral)
                        ?   <SelectValidation title="Tipo de Relación Laboral" arrayOpciones={relacionLaboral} refValue={relLaboralRef} 
                                keyOpcion="nombre" onChangeExecute={() => indicadorAutocomplete(null)} required
                            />
                        :   <div className="form-group">
                                <label>Tipo de Relación Laboral  <strong className="requerido">* </strong></label>
                                <input type="text" className="form-control" readOnly defaultValue={editRelacionLaboral.nombre} disabled />
                            </div>
                        }
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                        {(enviarIndicadorGeneral)
                        ?   <SelectValidation title="Tipo de Naturaleza" arrayOpciones={naturaleza} refValue={naturalezaRef} 
                                keyOpcion="tipoNatuNom" required
                            />
                        :   <div className="form-group">
                                <label>Tipo de Naturaleza <strong className="requerido">* </strong></label>
                                <input type="text" className="form-control" readOnly defaultValue={editNaturaleza.tipoNatuNom} disabled/>
                            </div>
                        }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Indicador SAT" type="indicadorsat" placeholder="Escriba la Clave del Indicador SAT"
                                refValue={indicadorSATRef} defaultValue={ elementSelect ? elementSelect.indicadorSat : '' } 
                                tooltipText={'Máximo 3 letras y/o números'} maxLength={3} required alfanumeric/> 
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                        {(enviarIndicadorGeneral)
                        ?   <SelectValidation title="Clasificación SAT" arrayOpciones={clasificacionSAT} refValue={clasificacionSATRef} 
                                keyOpcion="clasificaNom" required
                            />
                        :   <div className="form-group">
                                <label>Clasificación SAT <strong className="requerido">* </strong></label>
                                <input type="text" className="form-control" readOnly defaultValue={editClasificacionSAT.clasificaNom} />
                            </div>    
                        }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Partida Genérica" type="cuentaIndicadores1-3" placeholder="Escriba la Clave de Partida Genérica"
                                refValue={partGenericaRef} defaultValue={(elementSelect &&  elementSelect.partidaGen) ? elementSelect.partidaGen.trim() : ''} optional={true} 
                                tooltipText={'Máximo 3 letras y/o números'} maxLength={3} alfanumeric/>
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Partida Específica" type="cuentaIndicadores1-3" placeholder="Escriba la Clave de Partida Específica"
                                refValue={partEspecifRef} defaultValue={(elementSelect && elementSelect.partidaEsp) ? elementSelect.partidaEsp.trim() : ''} optional={true}
                                tooltipText={'Máximo 3 letras y/o números'} maxLength={3} alfanumeric/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Cuenta Contable" type="cuentaIndicadores1-3" placeholder="Escriba la Clave de Cuenta Contable"
                                refValue={ctaContableRef} defaultValue={(elementSelect && elementSelect.cuentaCon) ? elementSelect.cuentaCon.trim() : ''} optional={true}
                                tooltipText={'Máximo 3 letras y/o números'} maxLength={3} alfanumeric/>
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Subcuenta Contable" type="cuentaIndicadores1-3" placeholder="Escriba la Clave de Subcuenta Contable"
                                refValue={subCtaContableRef} defaultValue={(elementSelect && elementSelect.subcuentaCon) ? elementSelect.subcuentaCon.trim() : ''} optional={true}
                                tooltipText={'Máximo 3 letras y/o números'} maxLength={3} alfanumeric/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Monto Mínimo" type="monto" placeholder="Escriba el monto mínimo"
                                refValue={montoMinimoRef} defaultValue={ elementSelect ? elementSelect.montoMin : '' }  
                                optional={montoMinimoRequired} tooltipText={'Máximo 7 números y dos decimales'}
                                maxLength={10} onlyNumbers='.' onBlurDecimal={2} isAmount 
                                action={validarMontos}
                            />
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Monto Máximo" type="monto" placeholder="Escriba el monto máximo"
                                refValue={montoMaximoRef} defaultValue={ elementSelect ? elementSelect.montoMax : '' } 
                                optional={montoMaximoRequired} tooltipText={'Máximo 7 números y dos decimales'} 
                                onChangeExecute={(value)=>{  }}
                                tooltipName={'tooltipMontoMaximo'} maxLength={10} onlyNumbers='.' onBlurDecimal={2}
                                action={validarMontos} isAmount 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormControlLabel
                                control={<CheckboxConceptosGenerales checked={indicadorSueldo} onChange={handleChangeIndicadorSueldo} name="checkedIndicadorSueldo"/>}
                                label="Indicador de Sueldo"
                            />
                        </div>
                        <div className="col">
                            <FormControlLabel
                                control={<CheckboxConceptosGenerales checked={causaNombramiento} onChange={handleChangeCausaNombramiento} name="checkedCausaNombramiento"/>}
                                label="Causa Nombramiento"
                            />
                        </div>
                        <div className="col">
                            <FormControlLabel
                                control={<CheckboxConceptosGenerales checked={porDefecto} onChange={handleChangePorDefecto} name="checkedPorDefecto"/>}
                                label="Por Defecto"
                            />
                        </div>
                    </div>
                    { error } 
                    <Button variant="contained" className="btn-color" type="submit">{ 'Siguiente' }</Button>
                </form>
            </div>
            <div id="form-part-2" style={{ display: (active === 2) ? 'block' : 'none' }}>
                <form id={ elementSelect ? 'EditarIndicadorConfig' : 'AgregarIndicadorConfig' } onSubmit={ elementSelect ? mostrarAlert : enviarForm }>     
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                        {(rutinas)
                        ?   <SelectValidation title="Rutina de Cálculo" arrayOpciones={rutinas} keyOpcion="rutinaNom"
                                defaultValue={ elementSelect ? elementSelect.rutinaId : '' } refValue={rutinaCalcRef} required
                            />
                        :   null
                        }
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Prioridad" type="prioridad" placeholder="Escriba el valor de la prioridad"
                                tooltipText={'Máximo 254 números'} tooltipName={'tooltipPriorInd'} maxLength={254} onlyNumbers
                                refValue={prioridadRef} defaultValue={ elementSelect ? elementSelect.prioridad : '' } required/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <button onClick={e => openConfig(e) }  className="form-control btn-third" style={{marginTop: "37px"}}>Bases</button>
                        </div>
                    </div>
                    <MuiThemeProvider theme={theme}>
                    <div className="row">
                        <div className="col-md-6 col-sm-12"> 
                            <Checkbox  
                                onChange={()=>{setApareceRecib(!apareceRecib)}}
                                color="secondary"
                                checked={apareceRecib}
                            /> 
                            <label> Aparece en Recibo </label>
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                            <Checkbox 
                                onChange={()=>{setExentoReq(!exentoReq)}}
                                color="secondary"
                                checked={exentoReq}
                            />  
                            <label> Exento Requerido </label>
                        </div>
                    </div>
                    </MuiThemeProvider>
                    {(exentoReq)
                    ?   <div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                {((!elementSelect && indicadoresGenerales) || (elementSelect && indicadoresGeneral))
                                ?   <AutocompleteComponent
                                        id="IndicadoresGeneralesInGral"
                                        title="Conceptos"
                                        options={((elementSelect) ? indicadoresGeneral : indicadoresGenerales).filter(element => element.indicadorClave !==claveIndicadorGeneral)}
                                        action={indicadorAutocomplete}
                                        optionListView="indicadorNomClve"
                                        placeholder="Conceptos"
                                        size="100%"
                                        tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                        tooltipName="tooltipAutoIndGral"
                                        required 
                                        value={indicadorAuto}
                                    />
                                :   <SpinnerOval/>
                                }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                {(salarioMinimos)
                                ?   <SelectValidation title="Cálculo Sobre" arrayOpciones={salarioMinimos} keyOpcion="zonaNom"
                                        refValue={calcSobreRef} required onChangeExecute={getMontoZona}
                                        defaultValue={ updateIndicadorParametros ? defValCalcSob : '' }
                                    />
                                :   null
                                }
                                </div>
                                <div className="col-md-6 col-sm-12"> 
                                    <InputValidation title="Tope en Veces" type="topeVeces" placeholder="Escriba la cantidad de tope en veces"
                                        tooltipText={'Máximo 100 números'} tooltipName={'tooltipNomRecInd'} maxLength={100} onlyNumbers
                                        refValue={topeVecesRef} defaultValue={ updateIndicadorParametros ? defValTopVec : '' } required onChangeExecute={updateTopeVeces}/>
                                </div> 
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <InputValidation title="Importe"  type="monto"
                                        tooltipText={'Importe'} tooltipName={'tooltipImpo'} refValue={ importeRef } defaultValue={ updateIndicadorParametros ? defValMonto :'$0.00' } disabled optional/>
                                </div>
                            </div> 
                        </div>
                    :   null
                    } 
                    { error } 
                    { errorAuto }
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={e => siguiente(e, 1)}>{ 'Atrás' } </Button>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            {isLoading
                            ?   <SpinnerOval/>
                            :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                            }
                        </div> 
                    </div>
                </form> 
            </div>
            { !elementSelect ? obtenerModalAdd( idModulo+"secondary", openAdd, setOpenAdd, saveConfig, null, errorTable, successTable, dataConfigBase ) : null }
            { elementSelect ? obtenerModalEdit( idModulo+"secondary", openAdd, setOpenAdd, elementSelect, saveConfig, null, errorTable, successTable, dataConfigBase ) : null }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
        </Fragment>
    );
}

export default FormIndicadoresGenerales;