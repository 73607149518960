import React, { useState } from 'react';

import ModalService from '../../../ModalService/ModalService';
import CargasMasivas from './CargasMasivas/CargasMasivas';
import Retroactivos from './Retroactivos/Retroactivos';
import PagosUnicos from './PagosUnicos/PagosUnicos';

/* Importacion de elementos para uso de Alertas */
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const ModalAddMasivos = ({ moduloID, openAdd, setOpenAdd, modulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }
    /* ---------------------------------- Funciones para el manejo de alertas ---------------------------------- */
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );

    const successAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }
    /* --------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            { modulo ?
                modulo.id === 903 ? //Cálculos de Retroactivo
                    <ModalService title={modulo.nombre} parentNode={moduloID} minWidth="33%" maxWidth="35%"
                        isOpen={ openAdd } onClose={ () => cerrarModal() } >
                            <Retroactivos errorAlert={errorAlert} successAlert={successAlert}/>
                    </ModalService>
                : modulo.id === 904 ? //Control de Pagos Únicos
                    <ModalService title={modulo.nombre} parentNode={moduloID} minWidth="90%" maxWidth="90%"
                        isOpen={ openAdd } onClose={ () => cerrarModal() } >
                            <PagosUnicos errorAlert={errorAlert} successAlert={successAlert} moduloID={modulo.id} parentNode={moduloID}/>
                    </ModalService>
                    :
                    <ModalService title={modulo.sinCarga ? modulo.nombre : `Carga Masiva de ${modulo.nombre}`} parentNode={moduloID} minWidth="33%" maxWidth="35%"
                        isOpen={ openAdd } onClose={ () => cerrarModal() } >
                            <b>Descarga</b>
                            <div>1. Baje el formato</div>
                            <div>2. Capture la información solicitada</div>
                            <br/>
                            <b>Carga</b>
                            <div>1. Busque el archivo</div>
                            <div>2. Una vez que el sistema lo analice:</div>
                            <div>Si no se presentan incidencias haga la carga, de lo contrario modifique el archivo y repita el procedimiento</div>
                            <hr/>

                            <CargasMasivas modulo={modulo} error={error} errorAlert={errorAlert} successAlert={successAlert}/>
                    </ModalService>
                : null
            }
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición Exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </React.Fragment>
    );
};

export default ModalAddMasivos;