import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import FileService from '../../../../CropService/FileService';

import axios from 'axios';
import { servicios_grupohit_rh, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import AlertForm from '../../../../../../../Services/AlertForm';

import { Progress } from 'reactstrap';
import Search from '@material-ui/icons/Search';
import CloudUpload from '@material-ui/icons/CloudUpload';

const FormularioDocumento = ({ empleado, tiposDocumentos, agregar, errorTable, setOpenAdd }) => {
    const [ error, setError ] = useState( null );
    const [ progreso, setProgreso ] = useState( 0 );
    /* --------------------- Funciones para obtener y enviar archivos en formato base64 --------------------- */
    const [ nuevoDocumento, setNuevoDocumento ] = useState( null );
    const obtenerArchivo = ( file ) => {
        setNuevoDocumento( file );
    }
    /* --------------------------------------- Funciones para obtener Tipo de Documento -------------------------------------- */
    const [ tipoDocumento, setTipoDocumento ] = useState( null );
    const obtenerExtension = ( extensionID ) => {
        setTipoDocumento( tiposDocumentos.find(element => element.id === parseInt(extensionID)) );
        setNuevoDocumento( null );
    }
    /* ------------------------- Funciones para validar y consumir servicio Insertar Nuevo Documento ------------------------- */
    const tipoDocumentoIdRef = createRef('');
    const notaRef = createRef('');
    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarDocumento' ) ){
            const documento = parseInt( tipoDocumentoIdRef.current.value );
            const tempdocNota = notaRef.current.value;
            setError( null );

            if( nuevoDocumento.name.includes( tipoDocumento.extencion.tipoextNom ) ){
                setProgreso( 100 );

                enviarDocumento({ empleado: empleado.id, documento, tempdocNota, archivo: nuevoDocumento.base64 });
            }else{
                setError( <AlertForm message="El formato no es correcto para la carga de este documento" /> );
            }
        }
    }

    const enviarDocumento = ( documento ) => {
        axios.post( `${servicios_grupohit_rh}/documentos/`, documento, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        setError( null );
                        break;
                    default:
                        setProgreso( 0 );
                        errorTable( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                setProgreso( 0 );
                errorTable( registro_no_guardado );
            })
    }
    /* ----------------------------------------------------------------------------------------------------------------------- */
    return (
        <form id="AgregarDocumento" onSubmit={enviar}>
            <p className="tituloArchivo"> Nuevo Archivo </p>
            <label className="labelDocumento"> Tipo de Documento </label>
            <div className="row">
                <div className="col-md-9 col-sm-6 selectDocumento">
                    <SelectValidation title="" arrayOpciones={tiposDocumentos} keyOpcion="tipodocNom"
                        refValue={tipoDocumentoIdRef} onChangeExecute={obtenerExtension}/>
                    { tipoDocumento && <span className="spanFormato"> Formato requerido <b>{`${tipoDocumento.extencion.tipoextNom}`}</b> </span> }
                </div>
                <div className="col-md-3 col-sm-6 file-upload">
                    { tipoDocumento &&
                        <React.Fragment>
                            <label htmlFor="carga_documento" className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                            <FileService inputId="carga_documento" getFile={obtenerArchivo} formatAccept={`.${tipoDocumento.extencion.tipoextNom}`}/>
                        </React.Fragment>
                    }
                </div>
            </div>

            { nuevoDocumento &&
                <React.Fragment>
                    <div className="row mt-3">
                        <div className="col-md-9 col-sm-6 upload">
                            <span> {nuevoDocumento.name} </span>
                            <Progress animated value={progreso} />
                        </div>
                        <div className="col-md-3 col-sm-6 btn-upload">
                            <Button variant="contained" className="btn-color" type="submit"> <CloudUpload/> </Button>
                        </div>
                    </div>

                    <InputValidation title="Nota" type="notaDocumento" placeholder="Escriba una nota para la carga de este documento"
                        tooltipText={'1024 caracteres alfanuméricos'} tooltipName={'tooltipDocNota'} maxLength='1024'
                        refValue={notaRef}/>
                    { error }
                </React.Fragment>
            }

            <button style={{ position: 'absolute', right: '0', top: '0', paddingRight: '2rem', marginTop: '10rem' }}
                type="button" className="close" aria-label="Close" onClick={() => setOpenAdd(false)}>
                <span aria-hidden="true">&times;</span>
            </button>
            <hr/>
        </form>
    );
};

export default FormularioDocumento;