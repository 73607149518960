import React, { createRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';

const FormularioConsultaCP = ({ error, axiosColonias, peticion }) => {
    const postalCodeRef = createRef('');
    
    const consultarColonias = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'consultarColonias' ) ){
            const postalCode = postalCodeRef.current.value;
            axiosColonias( postalCode );
        }
    }

    return (
        <div className="card py-2 px-3 mb-2">
            <form id='consultarColonias' onSubmit={consultarColonias}>
                <h4 className="mt-2"> Consultar Colonias </h4>
                <hr/>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <InputValidation title="Código Postal" type="cp" placeholder="Escriba el código postal"
                            tooltipText={'5 números'} tooltipName={'tooltipConsultaCp'} maxLength='5' onlyNumbers
                            refValue={postalCodeRef} required/>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <Button variant="contained" className="btn-color" type="submit" disabled={peticion}> Consultar </Button>
                        {error}
                    </div>
                    
                </div>
            </form>
        </div>
    );
};

export default FormularioConsultaCP;