import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import './stylePerfil.css';
import ModalCropReact from './ModalCropReact';
import profileIMG from '../../../../Media/Img/profile.jpg';
import { Divider } from '@material-ui/core';
import ModificarInformacion from './ModificarInformacion/ModificarInformacion';

function PerfilModal({ modalProfile, toogleProfile, usuario, setUsuario, imagenPerfil, setImagenPerfil, openMenu }) {
    return (
        <Fragment>
            <style>
                {
                    (openMenu)
                    ?
                    `#ModalCrop, #PerfilModal {
                            margin-left: 45%!important; 
                            margin-right: 30%!important; 
                            transition: margin 0.2s ease-in-out;
                            -webkit-transition: margin 0.2s ease-out;
                            -moz-transition: margin 0.2s ease-out;
                            -o-transition: margin 0.2s ease-out;
                        }`
                    :
                    `#ModalCrop, #PerfilModal {
                            margin-left: 37.5%!important; 
                            margin-right: 37.5%!important; 
                            transition: margin 0.2s ease-in-out;
                            -webkit-transition: margin 0.2s ease-out;
                            -moz-transition: margin 0.2s ease-out;
                            -o-transition: margin 0.2s ease-out;
                        }`
                }
            </style>
            <Modal id="PerfilModal" isOpen={modalProfile} toggle={toogleProfile}
                size="ms" style={{ marginTop: '125px' }}>
                <ModalHeader toggle={toogleProfile}> Perfil </ModalHeader>

                <ModalBody>
                    <div className="row">
                        <div className="col-md-12 imagen_button_container">
                            { !imagenPerfil || imagenPerfil === "sin foto perfil sube tu foto de perfil" ?
                                <img src={profileIMG} width="180" height="180"
                                    alt="avatar" className="rounded-circle img-responsive centerImg mb-5" />
                                :
                                <img src={ `data:image/jpeg;base64, ${imagenPerfil}` } width="180" height="180"
                                    alt="avatar" className="rounded-circle img-responsive centerImg mb-3" />
                            }
                            <Divider/>
                            <ModalCropReact usuario={usuario} setImagenPerfil={setImagenPerfil}/>
                        </div>

                        <div className="col-md-12">
                            <ModificarInformacion usuario={usuario} setUsuario={setUsuario}/>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}

export default PerfilModal;