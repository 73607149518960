import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';

import ModalService from '../../../../ModalService/ModalService';
import FormularioCodigoPostal from './FormularioCodigoPostal';
import AlertForm from '../../../../../../../Services/AlertForm';

const ModalAddCodigoPostal = ({ informacion, axiosColonias, idModulo }) => {
    const [ openAdd, setOpenAdd ] = useState( false );
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarCodigoPostal = ( codigoPostal ) => {
        axios.post( `${servicios_grupohit_catalogo}s/colonias/codigo/postal`, codigoPostal, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setOpenAdd( false );
                        axiosColonias( codigoPostal.codigoPostal );
                        break;
                    default:
                        setError( <AlertForm message={ respuesta.data.msg }/> );
                        break;
                }
            })
            .catch( error => {
                setError( <AlertForm message={ registro_no_guardado }/> );
            })
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <Button type="button" size="large" className="btn-third col-md-12 col-sm-12" onClick={() => setOpenAdd(true)}>Agregar</Button>
                </div>
            </div>
            <ModalService title="Agregar Código Postal" parentNode={idModulo} minWidth="40%"
                isOpen={ openAdd } onClose={ () => cerrarModal() } >
                    <FormularioCodigoPostal
                        error={error}
                        enviarCodigoPostal={enviarCodigoPostal}
                        informacion={informacion}
                    />
            </ModalService>
        </React.Fragment>
    );
};

export default ModalAddCodigoPostal;