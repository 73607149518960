import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { obtenerEmpleadosActivos } from '../../../../../../Redux/actions/EmpleadosActions';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_nomina, servicios_grupohit_rh, servicios_grupohit_catalogo, prestamos_, verifique_conexion } from '../../../../../../Constants/Constants';
import { ArrayIndicadorDeducciones } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import InputText from '../../../../../../Services/Components/DataTable/InputText';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const Prestamos = ({ id, permisos, empleadosActivos, obtenerEmpleadosActivos }) => {
    const [ nominas, setNominas ] = useState( null );
    const [ empleadosExcel, setEmpleadosExcel ] = useState( null );
    const [ deducciones, setDeducciones ] = useState( null );
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        obtenerEmpleadosActivos((msg) => {
            setMessageAlertSyE(msg);
            setOpenAlertSyE(true);
        });
        axiosNomninas();
        axiosEmpleadosExcel();
        axiosDeducciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Axios para obtener listado de Nóminas */
    const axiosNomninas = () => {
        axios.get( `${servicios_grupohit_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setNominas([]);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error =>  {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            })
    }

    /* Axios para obtener tipos de acumulado */
    const axiosEmpleadosExcel = () => {
        axios.get( `${servicios_grupohit_rh}/empleado/infovista/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEmpleadosExcel( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setEmpleadosExcel([]);
                        break;
                    default:
                        setMessageAlertSyE(respuesta.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
    }

    const axiosDeducciones = () => {
        axios.get( `${servicios_grupohit_catalogo}/indicador/getall/relab`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const deducciones = respuesta.data.dataResponse.filter( element => element.naturaleza.id === 2 ); //DEDUCCIONES
                        setDeducciones( ArrayIndicadorDeducciones(deducciones) );
                        break;
                    case 404: //NO hay datos que mostrar
                        setDeducciones( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={`modulo${id}`}>
            { empleadosActivos && nominas && deducciones
                ?   <DataTableService id={id} title={ prestamos_ }
                        columns={[
                            { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                            { field: "nombreCompleto", title: "Nombre" },
                            { field: "empleadoCurp", title: "CURP", hidden: true, hiddenByColumnsButton: true },
                            { field: "empleadoRfc", title: "RFC" },
                            { field: "empleadoNss", title: "NSS" },
                            { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia", hidden: true, hiddenByColumnsButton: true },
                            { field: "puestoCve", title: "Clave Puesto" },
                            { field: "puestoNom", title: "Puesto" },
                            { field: "nominaNombre", title: "Nómina" },
                            { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                            { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                        ]}
                        data={empleadosActivos}
                        customOptionsTable={{ filtering: true }}
                        hideIconDelete hideIconEdit showIconNewFolder
                        showIconApps
                        informacionAdicional={{ empleados: empleadosExcel, nominas, deducciones }}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </div>
    );
};

const mapStateToProps = (state) => ({
    empleadosActivos: state.EmpleadosReducer.empleadosActivos,
});
  
const mapDispatchToProps = { obtenerEmpleadosActivos };
  
export default connect(mapStateToProps, mapDispatchToProps)(Prestamos);
