import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, amountToFloat } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado, registro_sera_guardado } from '../../../../../../../Constants/Constants';
import AlertForm from '../../../../../../../Services/AlertForm';

const FormAcumulados = ({ error, setError, error2, setError2, enviarAcumulado, elementSelect, actualizarAcumulado, informacionAdicional }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const [conceptoId, setConceptoId] = useState(null);
    const [errorConcepto, setErrorConcepto] = useState(null);

    const cantidadRef = createRef('');
    const importeRef = createRef('');

    const { anio, periodo, conceptos, acumulado, empleado } = informacionAdicional;

    const getConceptos = value => {
        if(value) {
            setConceptoId(value.id);
            setErrorConcepto(null);
        } else {
            setConceptoId(null);
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        if(
            (parseFloat(cantidadRef.current.value) === 0) ||
            (parseFloat(amountToFloat(importeRef.current.value).toFixed(2)) === 0)
        ) {
            if(parseFloat(cantidadRef.current.value) === 0) {
                setError(<AlertForm message="La cantidad debe ser mayor a 0"/>);
            } else {
                setError(null);
            }
            if(parseFloat(amountToFloat(importeRef.current.value).toFixed(2)) === 0) {
                setError2(<AlertForm message="El importe debe ser mayor a $ 0.00"/>);
            } else {
                setError2(null);
            }
            return;
        }
        
        if(validarSubmit('FormAcumuladoSec')) {
            setError(null);
            setError2(null);
            setErrorConcepto(null);
            setOpenAlert(true);
        } else {
            if(conceptoId === null) {
                setErrorConcepto(<AlertForm message="La cantidad debe ser mayor a 0"/>);
            } else {
                setErrorConcepto(null);
            }
        }
    };

    const enviar = e => {
        const importeValue = amountToFloat(importeRef.current.value).toFixed(2);

        const periodoId = parseInt(periodo.id);
        const empleadoId = empleado.id;
        const nominaIndicador = parseInt(conceptoId);
        const cantidad = parseFloat(cantidadRef.current.value);
        const monto = parseFloat(importeValue);
        const tipoAcum = acumulado;
        setConceptoId(null);
        enviarAcumulado({ periodoId, empleado: empleadoId, nominaIndicador, cantidad, monto, tipoAcum });
    };

    const actualizar = () => {
        const importeValue = amountToFloat(importeRef.current.value).toFixed(2);

        const cantidad = parseFloat(cantidadRef.current.value);
        const monto = parseFloat(importeValue);
        actualizarAcumulado({ cantidad, monto }, elementSelect.id);
    };

    return ( 
        <Fragment>
            <form id="FormAcumuladoSec" onSubmit={mostrarAlert}>
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Año" type="anio" defaultValue={anio} required disabled/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Periodo" type="periodoAcumulado" defaultValue={periodo.periodo} required disabled/>
                    </div>
                </div>
                {
                    (elementSelect)
                    ?   <InputValidation title="Concepto" type="concepto" defaultValue={`${elementSelect.nominaClave} - ${elementSelect.nominaIndicador}`} required disabled/>
                    :   <AutocompleteComponent
                            id="conceptoAcumulados"
                            title="Concepto"
                            options={conceptos}
                            action={getConceptos}
                            optionListView="claveNombreIndicador"
                            placeholder="Concepto"
                            tooltipName="nameConceptoAcumulados"
                            tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                            error={errorConcepto}
                            required
                        />
                }
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Cantidad" type="diasAgino" placeholder="Escriba la cantidad"
                            tooltipName="tooltipCantidadAcum" tooltipText="Máximo 3 números, del 1 al 366"
                            refValue={cantidadRef} defaultValue={elementSelect ? elementSelect.cantidad : ''} 
                            maxLength={3} onlyNumbers required/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Importe" type="monto" placeholder="Escriba el importe"
                            tooltipName="tooltipImorteAcum" tooltipText="Máximo 6 números y dos decimales"
                            refValue={importeRef} defaultValue={elementSelect ? elementSelect.monto : ''} onlyNumbers="."
                            onBlurDecimal={2} maxLength={9} required isAmount/>
                    </div>
                { error }
                { error2 }
                </div>
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={(elementSelect) ? registro_sera_actualizado : registro_sera_guardado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={(elementSelect) ? actualizar : enviar}/>
        </Fragment> 
    );
}
export default FormAcumulados;