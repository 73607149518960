import React, {useEffect, useState} from 'react'; 
import axios from 'axios';
import { servicios_grupohit } from '../../../../../../Constants/Constants'; 
import ModalService from './../../../../Usuario/ModalService/ModalService'; 
import { headersAuth } from '../../../../../../Services/AuthService';    
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';

const ModalConsultaFichaEmpleado = ({ openEdit, setOpenEdit, errorTable, editar, successTable, elementSelect, nombreModulo  }) => {

    const [ pdfSelect, setPdfSelect ] = useState( null );
    const [ erroPDF, setErroPDF ] = useState( null );   
    const cerrarModal = () => {
        setPdfSelect(null);
        setOpenEdit(false); 
    }; 

    useEffect(() => {
        if(elementSelect.id && openEdit ){
            axios.get( `${servicios_grupohit}/configuracion/reportes/ficha/empleado/${elementSelect.id}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:  
                        if(respuesta.data.dataResponse!==""){
                            setPdfSelect( respuesta.data.dataResponse );
                        }else{
                            setErroPDF(true);
                        } 
                        break;
                    case 404:  
                        break;
                    default: 
                        break;
                }
            })
            .catch( error => { 
            }); 
        } 
        
            // eslint-disable-next-line
    }, [openEdit]);

    return (
        
            
                (pdfSelect || erroPDF) &&  
                <ModalService 
                    title="Visualizar" 
                    parentNode={nombreModulo}
                    isOpen={openEdit}
                    onClose={cerrarModal} 
                    minWidth='80%' 
                >
                    <div className='embed-responsive' style={{ paddingBottom: '100%'}}>
                        <object className='embed-responsive-item' aria-labelledby="Descargar"
                            data={ `data:application/pdf;base64,${pdfSelect}` } type='application/pdf' width='100%' height='100%'/>
                    </div> 
                    {erroPDF && <AlertWarning show={erroPDF} SetopenALert={setErroPDF} title={'No se encontró información del Empleado'} textButtonAceptar="Aceptar"  action={cerrarModal}/>}
                </ModalService> 
            
        
    );
};

export default ModalConsultaFichaEmpleado;