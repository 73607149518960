import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupohit_catalogo } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

const RegistroPatronal = ({ modulo, regPatronalRef, infoRegPatronal }) => {

    const [regPatronales, setRegPatronales] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupohit_catalogo}/registropat/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const regPat = res.data.dataResponse.map(item => ({
                        id: item.id,
                        regPatronal: item.registroPatClave
                    }));
                    setRegPatronales(regPat);
                    break;
                default:
                    setRegPatronales([]);
                    break;
            }
        })
        .catch(error => setRegPatronales([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(regPatronales !== null && regPatronales.length > 0) {
            if(infoRegPatronal && (modulo === 30)) {
                const regPatronal = regPatronales.find(regPatronal => regPatronal.id === infoRegPatronal.regPatronal.id);
                if(regPatronal) {
                    regPatronalRef.current.value = regPatronal.id;
                };
            } else {
                regPatronalRef.current.value = regPatronales[0].id;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regPatronales]);
    
    return (
        <Fragment>
        {regPatronales === null
        ?   <SpinnerOval />
        :   <SelectValidation
                title="Registro Patronal"
                arrayOpciones={regPatronales}
                keyOpcion="regPatronal"
                refValue={regPatronalRef}
                defaultValue=''
                optional={true}
            />
        }
        </Fragment>
    )
}
 
export default RegistroPatronal;