import React, {useState, createRef, Fragment} from 'react';
import { connect } from 'react-redux';
import { guardarRol } from '../Redux/actions/sessionActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import { servicios_grupohit_url, ocurrio_un_error, lengthPassword } from '../Constants/Constants';
import AlertForm from '../Services/AlertForm';
import axios from 'axios';

import {obtenerUsuario, headersAuth} from '../Services/AuthService';
import PasswordInput from '../Services/Validation/PasswordInput';

function AlertPassSession({ openContrasena, setModalContraseña, setTimeout, activarEventosInactividad, closeSession, guardarRol, setNewToken, logotipo }) {

    const contraseñaRef = createRef('');
    const [ error, setError ] = useState( null );
    const [ diss, setDiss ] = useState( false );

    const revalidarSesion = (evt) => {
        evt.preventDefault();
        const password = contraseñaRef.current.value;
        if( password.trim() === '' ){
            setError( <AlertForm className="mt-5" message = {"Ingrese su contraseña o cierra la sesión"} /> );
            return;
        }
        setError( null );
        serviceRefresh( password );
    }

    const logout = async () => {
        await axios.post( `${servicios_grupohit_url}/logout`, null, headersAuth() );
    }

    const obtenerRelacioLaboralLS = () => {
        return JSON.parse(localStorage.getItem("IGRelLab"));
    };
    const asignarRelacionLabolar=(authToken)=>{
        const relacionLaboralLS = obtenerRelacioLaboralLS();
        if(relacionLaboralLS){
            axios.put( `${servicios_grupohit_url}/asigna/relacionLab`, {relacionLab: relacionLaboralLS.id}, {headers : { 
                "X-Authorization": authToken,
                'Content-Type': 'application/json'
            }})
                .then(res => {
                    switch(res.data.status){
                        case 200:
                            localStorage.setItem( "IGRelLab", JSON.stringify(relacionLaboralLS));
                            break;
                        default:
                            setError( <AlertForm message={ res.data.msg } /> );
                            break;
                    }
                })
                .catch( error => {
                    setError( <AlertForm message={ ocurrio_un_error } /> )
            });
        }
        
    }

    const serviceRefresh = async ( password ) => {
        setDiss( true );
        logout();

        const usuario = obtenerUsuario();
        const data = { username: usuario.usuario, password };
            await axios.post( `${servicios_grupohit_url}/login`, data )
            .then(respuesta => {
                const authToken = respuesta.headers[ "x-authorization" ];
                if( authToken ) {
                    asignarRelacionLabolar(authToken);
                    const { id, nombre, paterno, materno, username, tour, tyc, rol } = respuesta.data.dataResponse;
                    guardarRol({ rol, usuario: { id, nombre, paterno, materno, username } });
                    localStorage.setItem( "IGSESSID", JSON.stringify( { "token": authToken, "claveUsuario": id, "nombre" : nombre, "apaterno": paterno, "amaterno": materno, "usuario": username, "tour": tour, "tyc": tyc, "rol": rol }));
                    setNewToken( authToken );
                    setDiss( false );
                    localStorage.setItem("revalidateSession", JSON.stringify( { revalidate: false } ));
                    setModalContraseña(false); //Cerrar Modal RecuperaSession
                    setTimeout();
                    activarEventosInactividad( true );
                } else {
                    const msg = respuesta.data.msg;
                    if( msg ) {
                        setError( <AlertForm message={ msg } /> );
                    } else {
                        setError( <AlertForm message={ ocurrio_un_error } /> );
                    }
                    setDiss( false );
                }
            })
            .catch((error) => {
                const msg = error?.response?.data?.msg;
                if( msg ) {
                    setError( <AlertForm message={ msg } /> );
                } else {
                    setError( <AlertForm message={ ocurrio_un_error } /> );
                }
                setDiss( false );
            })
            
    }

    return (
        <Fragment>
            <style>
                {`
                    .cont_log {
                        z-index: 15001 !important;
                    }
                `}
            </style>
            <Dialog open={openContrasena} aria-labelledby="alert-dialog-title" className="cont_log">
                <DialogContent>
                    <div className = "title">
                        { logotipo && <img src={`data:image/jpeg;base64, ${logotipo}`} className="header_logo" alt="site-logo"/> }
                        <span>Tiempo de inactividad superado</span>
                    </div>
                    <form onSubmit={ revalidarSesion }>
                            <label>Contraseña</label>
                            <PasswordInput refValue={contraseñaRef} placeholder="Contraseña" maxLength={lengthPassword}/>
                            { error }
                            <Button className="btn-color mt-3" type="submit" disabled={ diss }>Recuperar Sesión</Button>
                            <Button className="btn-outline" onClick={ closeSession } disabled={ diss }>Cerrar Sesión</Button> 
                    </form>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

const mapDispatchToProps = { guardarRol };

export default connect(null, mapDispatchToProps)(AlertPassSession);