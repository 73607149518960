import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioTipoRelacion = ({ enviarTipoRelacion, elementSelect, actualizarTipoRelacion, isLoading }) => {
    const [openAlert, setOpenAlert] = useState(false);
    const claveRef = createRef('');
    const nombreRef = createRef('');
    const modalidadRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };   

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('TipoRelacion')) {
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value;
            const modalidad = parseInt(modalidadRef.current.value);
            const descripcion = descripcionRef.current.value;
            enviarTipoRelacion({ clave, nombre, modalidad, descripcion });
        }
    };

    const actualizar = () => {
        if(validarSubmit('TipoRelacion')) {
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value;
            const modalidad = parseInt(modalidadRef.current.value);
            const descripcion = descripcionRef.current.value;

            actualizarTipoRelacion({ clave, nombre, modalidad, descripcion }, elementSelect.id);
        }
    };

    return (  
        <Fragment>
            <form id="TipoRelacion" className="" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Clave" type="claveTipoRelLab" placeholder="Escriba la clave del tipo de relación laboral"
                            refValue={claveRef} defaultValue={ elementSelect ? elementSelect.clave : '' }
                            tooltipText="Máximo 16 letras y/o números" tooltipName="tooltipCveTipRelLab" alfanumeric maxLength={16} required/>
                        <InputValidation title="Nombre" type="nombreTipoRelLab" placeholder="Escriba el nombre del tipo de relación laboral"
                            refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.nombre : '' }
                            tooltipText={'Máximo 64 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName="tooltipNomTipRelLab" maxLength={64} required/>
                        <InputValidation title="Modalidad" type="claveIndGenerales" placeholder="Escriba la modalidad del tipo de relación laboral"
                            refValue={modalidadRef} defaultValue={ elementSelect ? elementSelect.modalidad : '' }
                            tooltipText={'Máximo 2 números'} tooltipName="tooltipModTipRelLab" maxLength={2} onlyNumbers required/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Descripción" type="descripcionTipoRelLab" placeholder="Escriba la descripción del tipo de relación laboral"
                            refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.descripcion : '' }
                            tooltipText={'Máximo 512 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}  tooltipName="tooltipDesTipRelLab" maxLength={512} fieldType="textarea" required/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                    {(isLoading)
                    ?   <SpinnerOval />
                    :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                    }
                    </div>
                </div>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}

export default FormularioTipoRelacion;