import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormularioGiros = ({ error, enviarGiro, elementSelect, actualizarGiro, isLoading }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const nombreRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if(validarSubmit('AgregarGiro')){
            const nombre = nombreRef.current.value;
            enviarGiro({ nombre });
        }
    }

    const actualizar = () => {
        if(validarSubmit('EditarGiro')){
            const nombre = nombreRef.current.value;
            actualizarGiro({ nombre }, elementSelect.id);
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarGiro' : 'AgregarGiro' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Nombre" type="nombreGiro" placeholder="Escriba nombre del Giro" maxLength='120'
                    tooltipName={'tooltipNomGiro'} tooltipText={'Máximo 120 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } required/>

                {error}
                {isLoading
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioGiros;