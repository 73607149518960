import React, { useState } from 'react';
import CollapseMaterial from '../../AsignacionModulos/CollapseMaterial/CollapseMaterial';

import Checkbox from '@material-ui/core/Checkbox';
import ListCheckbox from './ListCheckbox';
import { agregarAcceso, eliminarAcceso, obtenerPermisos } from '../../AsignacionModulos/CollapseMaterial/HelperModulos';

const Modulo = ({ moduloPadre, accesosMenu, setAccesosMenu, elementoActivo, setElementoActivo }) => {
    const [ selectAll, setSelectAll ] = useState( false );
    const seleccionarTodo = () => {
        setSelectAll( !selectAll );
        if( !selectAll ){
            agregarAccesosModuloPadre();
        }else{
            eliminarAccesosModuloPadre();
        }
    }
    /* ------- Funciones que permiten la agregacion y eliminacion de varios elementos dentro de un arreglo de objetos (accesosMenu) ------- */
    const agregarAccesosModuloPadre = () => {
        let accesosTemporal = [ ...accesosMenu ];
        moduloPadre.children.forEach( moduloHijo => {
            if( moduloHijo.permisos && moduloHijo.permisos.length >= 1 ){ //Verificamos tener permisos sobre ModuloHijo ( permisos.length >= 1 || permisos !== [] -No sea un arreglo vacio )
                const permisos = obtenerPermisos( moduloHijo.permisos ); //El caso mas deseable [1,2,3,4]
                accesosTemporal = agregarAcceso( accesosTemporal, moduloPadre.id, moduloHijo.id, permisos ); //Asignamos los permisos del usuario superior a cada modulo hijo
            }
        });
        setAccesosMenu( accesosTemporal );
    }

    const eliminarAccesosModuloPadre = () => {
        let accesosTemporal = [ ...accesosMenu ];
        moduloPadre.children.forEach( moduloHijo => {
            accesosTemporal = eliminarAcceso( accesosTemporal, moduloPadre.id, moduloHijo.id );
        });
        setAccesosMenu( accesosTemporal );
    }
    /* ------------------------------------------------------------------------------------------------------------------------------------ */
    return (
        <CollapseMaterial title={moduloPadre.nombre} icon={moduloPadre.icono} elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}>
            <div className="row">
                <div className="col-md-5 col-sm-12"/>
                <div className="col-md-7 col-sm-12">
                    Seleccionar todo
                    <Checkbox
                        checked={selectAll}
                        onChange={ () => seleccionarTodo() }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-5 col-sm-12"/>
                <div className="col-md-7 col-sm-12">
                    <div className="row textCrud">
                        <div className="col-md-3 col-sm-3 colCenterText"> <b>Crear</b> </div>
                        <div className="col-md-3 col-sm-3 colCenterText"> <b>Actualizar</b> </div>
                        <div className="col-md-3 col-sm-3 colCenterText"> <b>Eliminar</b>  </div>
                        <div className="col-md-3 col-sm-3 colCenterText"> <b>Consultar</b> </div>
                    </div>
                </div>
            </div>

            { moduloPadre.children ?
                moduloPadre.children.map( moduloHijo => {
                    if( moduloHijo.permisos && moduloHijo.permisos.length >= 1 ){
                        return <ListCheckbox key={moduloHijo.id} moduloHijo={moduloHijo} accesosMenu={accesosMenu}
                                acceso={ accesosMenu.find( acceso => acceso.modulo === moduloHijo.id ) } setAccesosMenu={setAccesosMenu}/>
                    }else{
                        return '';
                    }
                })
                :
                <p> {moduloPadre.nombre} </p>
            }
        </CollapseMaterial>
    );
};

export default Modulo;