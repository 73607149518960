export const descargaXLS = (nombre, reporteBase64) => {
    const linkSource = `data:application/vnd.ms-excel;base64,${reporteBase64}`;
    const downloadLink = document.createElement("a");
    const fileName =`${nombre}.xlsx`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};

export const descargaPDF = (nombre, reporteBase64) => {
    const linkSource = `data:application/pdf;base64,${reporteBase64}`;
    const downloadLink = document.createElement("a");
    const fileName =`${nombre}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};