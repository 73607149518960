import React, { useState, createRef } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { validarSubmit, deleteOptionals } from '../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../Services/Validation/InputValidation';

import { servicios_grupohit_url } from '../../../../../Constants/Constants';
import { headersAuth, actualizarUsuarioStorage, obtenerUsuario } from '../../../../../Services/AuthService';
import AlertForm from '../../../../../Services/AlertForm';

const FormularioInformacion = ({ usuario, setUsuario, setOpenForm, successAlert, errorAlert }) => {
    const [ error, setError ] = useState( null );
    const usuarioNomRef = createRef('');
    const usuarioPatRef = createRef('');
    const usuarioMatRef = createRef('');

    const actualizar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'EditarPerfil' ) ){
            const usuarioNom = usuarioNomRef.current.value;
            const usuarioPat = usuarioPatRef.current.value;
            const usuarioMat = usuarioMatRef.current.value;

            actualizarUsuario( deleteOptionals({ usuarioNom, usuarioPat, usuarioMat }) );
        }
    }

    const actualizarUsuario = ( informacionUsuario ) => {
        axios.put( `${servicios_grupohit_url}/info`, informacionUsuario, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        successAlert( 'La información fue actualizada' );
                        actualizarUsuarioStorage( informacionUsuario );
                        setUsuario( obtenerUsuario() );
                        setOpenForm( false );
                        break;
                    default:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                }
            })
            .catch( error => {
                errorAlert( 'La información no pudo ser actualizada' );
            })
    }

    return (
        <form id="EditarPerfil" onSubmit={actualizar}>
            <InputValidation title="Nombre" type="usuarioNombre" placeholder="Escriba su nombre" maxLength='100'
                tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUsuarioNom'} onlyLetters=".,- "
                refValue={usuarioNomRef} defaultValue={ usuario.nombre } required/>
            <InputValidation title="Primer Apellido" type="usuarioApellido" placeholder="Escriba su primer apellido" maxLength='100'
                tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUsuarioPat'} onlyLetters=".,- "
                refValue={usuarioPatRef} defaultValue={ usuario.apaterno } required/>
            <InputValidation title="Segundo Apellido" type="usuarioApellido" placeholder="Escriba su segundo apellido" maxLength='100'
                tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUsuarioMat'} onlyLetters=".,- "
                refValue={usuarioMatRef} defaultValue={ usuario.amaterno }
                optional={true} />
            { error }
            <Button className="btn-color" type="submit"> Guardar Cambios </Button>
        </form>
    );
};

export default FormularioInformacion;