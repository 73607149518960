import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_plantillas } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import FormPlantillaEmpleadosProrroga from './FormPlantillaEmpleadosProrroga'; 
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import AlertForm from '../../../../../../../Services/AlertForm';

const ModalGenerarPlantillaEmpleadosProrroga = ({ openModal, setOpenModal, agregar, errorTable, nombreModulo, elemmentSelect, setElemmentSelect }) => {
    
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenModal( false );
        setError( null );
    }

    const crearZip = (data, nombrePlantilla) => {
        var zip = new JSZip();

        data.forEach((value,i) => {
            zip.file(`${value.empleadoClave} - ${value.nombreCompleto}.pdf`,value.pdf, { base64: true });
        });
        
        zip.generateAsync({ type:"blob" }).then(function(content) {
            saveAs(content, `${nombrePlantilla}.zip`);
        });

    };

    const enviarPlantilla = ( data, nombrePlantilla ) => {
        data.empleados= elemmentSelect.map(element=> element.id);
        setLoading(true); 
        axios.post(`${servicios_grupohit_plantillas}/pdf/`, data, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    crearZip(res.data.dataResponse, nombrePlantilla);
                    setLoading(false);
                    setOpenModal( false );
                    setElemmentSelect({});
                    break;
                default:
                    setError(<AlertForm message={res.data.msg} />);
                    setLoading(false);
                    break;
            }
        })
        .catch(error => {
            setError(<AlertForm message={'No se pudo generar el archivo'} /> );
            setLoading(false);
        });
    }
    
    return (
        <ModalService title="Generar Plantilla" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openModal } onClose={ () => cerrarModal() } >
                <FormPlantillaEmpleadosProrroga
                    error={error}
                    enviarPlantilla={enviarPlantilla}
                    elementSelect={elemmentSelect}
                    setError={setError}
                    setLoading={setLoading}
                    loading={loading}
                />
        </ModalService>
    );
};

export default ModalGenerarPlantillaEmpleadosProrroga;