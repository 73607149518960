import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormPuestos from './FormPuestos';
import { JsonPuestos } from '../../../Helpers/JsonToOneLevel';

const ModalEditPuestos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarPuesto = puesto => {
        setIsLoading(true);
        const info = puesto;

        Axios.put(`${servicios_grupohit}/org/puesto/${elementSelect.id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(JsonPuestos(res.data.dataResponse));
                    setOpenEdit(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    };

    return (
        <ModalService title="Actualizar Puesto" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormPuestos
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarPuesto={actualizarPuesto}
                        errorTable={errorTable}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditPuestos;