import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import FormCuentaPortal from './FormCuentaPortal';
import AlertForm from '../../../../../../../Services/AlertForm';

const ModalCuentaPortalEmpleados = ({ nombreModulo, openModalForm, setOpenModalForm, elementSelect, successTable, errorTable, informacionAdicional }) => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { permisos } = informacionAdicional;

    const cerrarModal = () => {
        setOpenModalForm(false);
        setError(null);
    };

    const asignarCorreo = info => {
        if(permisos.find(permiso => permiso.id === 1)) {
            setIsLoading(true);
            axios.post(`${servicios_grupohit_rh}/empleado/usuario/`, info, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        cerrarModal();
                        successTable("El correo electrónico se asignó correctamente");
                        break;
                    default:
                        cerrarModal();
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { cerrarModal(); errorTable(verifique_conexion) })
            .finally(() => setIsLoading(false));
        } else {
            setError(<AlertForm message="No tienes permisos asignados para realizar esta acción"/>);
        }
    };
    
    const actualizarCorreo = info => {
        if(permisos.find(permiso => permiso.id === 2)) {
            setIsLoading(true);
            axios.put(`${servicios_grupohit_rh}/empleado/usuario/superior/setusername/`, info, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        cerrarModal();
                        successTable("El correo electrónico se actualizó correctamente");
                        break;
                    default:
                        cerrarModal();
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { cerrarModal(); errorTable(verifique_conexion) })
            .finally(() => setIsLoading(false));
        } else {
            setError(<AlertForm message="No tienes permisos asignados para realizar esta acción"/>);
        }
    };

    const actualizarContrasena = info => {
        if(permisos.find(permiso => permiso.id === 2)) {
            setIsLoading(true);
            axios.put(`${servicios_grupohit_rh}/empleado/usuario/superior/setpassword/`, info, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        cerrarModal();
                        successTable("La contraseña se actualizó correctamente");
                        break;
                    default:
                        cerrarModal();
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { cerrarModal(); errorTable(verifique_conexion) })
            .finally(() => setIsLoading(false));
        } else {
            setError(<AlertForm message="No tienes permisos asignados para realizar esta acción"/>);
        }
    };

    return (
        <ModalService 
            title="Acceso Portal del Empleado"
            parentNode={nombreModulo}
            isOpen={openModalForm}
            onClose={cerrarModal}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormCuentaPortal
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        asignarCorreo={asignarCorreo}
                        actualizarCorreo={actualizarCorreo}
                        actualizarContrasena={actualizarContrasena}
                        permisos={permisos}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
};

export default ModalCuentaPortalEmpleados;