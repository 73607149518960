import React from 'react'
import CardComponent from '../../../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import DataTableService from '../../../../../DataTableService/DataTableService';
import InputText from '../../../../../../../../Services/Components/DataTable/InputText';

const TablaMovimientosEscalafon = ({ id, info, empleadosListaEscalafon }) => {
    return (
        <CardComponent
            title={ <h4 style={{ paddingRight: '30px', fontWeight: 'bold' }}>
                        {`Folio Escalafón ${(info.definitivo) ? 'Definitivo' : 'Temporal'}: ${info.folio} * Fecha Inicial: ${info.fecini}${(!info.definitivo) ? ` * Fecha Final: ${info.fecfin}` : ''} * Realizó: ${(info.usuario) ? `${info.usuario.usuarioPat}${info.usuario.usuarioMat}${info.usuario.usuarioNom}` : info.nombre}`}
                    </h4>
            }
        >
            <DataTableService id={id} title={`Empleados Escalafón ${info.folio}`}
                columns={[
                    { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                    { field: "nombreCompleto", title: "Nombre" },
                    { field: "empleadoCurp", title: "CURP" },
                    { field: "empleadoRfc", title: "RFC" },
                    { field: "empleadoNss", title: "NSS" },
                    { field: "ciaRazonSocial", title: "Unidad Responsable / Dependencia" },
                    { field: "puestoCve", title: "Clave Puesto" },
                    { field: "puestoNom", title: "Puesto" },
                    { field: "nominaNombre", title: "Nómina" },
                    { field: "cpresupuestalClave", title: "Clave Presupuestal", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalClave.startsWith(term) },
                    { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                    { field: "plazaClave", title: "NUP", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                ]}
                data={empleadosListaEscalafon}
                customOptionsTable={{ filtering: true }}
                hideIconEdit
                hideIconAdd
                hideIconDelete
                notEstadistica
            />
        </CardComponent>
    );
}
 
export default TablaMovimientosEscalafon;
