export function timestampToDate(timestamp) {    
    const date = new Date(timestamp); 
    var nombres_meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    var dia = date.getDate(); 
    var mes = date.getMonth();
    var anio = date.getFullYear();  
    return dia+ " de "+ nombres_meses[mes]+ " de "+ anio; 
}
export function dateToTimestamp(date) {
    const fecha = new Date(date);
    return fecha.getTime(); 
}