import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import DataTableService from '../../../../../DataTableService/DataTableService';
import InputText from '../../../../../../../../Services/Components/DataTable/InputText';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { arrayJsonMovimientosPorPeriodoAdscripcion } from '../../../../../Helpers/JsonToOneLevel';
import { adscripciones, mov_registrados, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

const AdscripcionRegistrados = ({ id, requestTabla, setBloqueaCards, setError, moverATabla, informacionAdicional }) => {

    const [infoTabla, setInfoTabla] = useState(null);

    useEffect(() => {
        const servicioAdscripcion = () => {
            setBloqueaCards(true);
            axios.post(`${servicios_grupohit_nomina}/procesos/estadisticas/complementaria/adscripcion`, requestTabla, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        setInfoTabla(arrayJsonMovimientosPorPeriodoAdscripcion(res.data.dataResponse, 2));
                        break;
                    default:
                        setInfoTabla([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setInfoTabla([]); setError(<AlertForm message={verifique_conexion} />) })
            .finally(() => moverATabla() );
        };

        servicioAdscripcion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
        {(infoTabla)
        ?   <DataTableService
                id={`${id}AdscripcionR`}
                title={`${adscripciones} - ${mov_registrados}`}
                columns={[
                    { field: "empleadoClave", title: "NUE", type: 'numeric', filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                    { field: "empleadoNomCompleto", title: "Nombre" },
                    { field: "empleadoRfc", title: "RFC" },
                    { field: "puestoClave", title: "Clave Puesto" },
                    { field: "cpresupuestalAnt", title: "Clave Presupuestal / Proyecto Anterior", customFilterAndSearch: (term, rowData) => rowData.cpresupuestalAnt.startsWith(term) },
                    { field: "cpresupuestal", title: "Clave Presupuestal / Proyecto", customFilterAndSearch: (term, rowData) => rowData.cpresupuestal.startsWith(term) },
                    { field: "plazaClaveActAnt", title: "NUP Actual / Anterior", type: 'numeric' },
                    { field: "usuario", title: "Cuenta", hidden: true, hiddenByColumnsButton: true },
                    { field: "nombre", title: "Usuario" },
                    { field: "fechaMovimiento", title: "Fecha de Registro" },
                ]}
                data={infoTabla}
                customOptionsTable={{ filtering: true }} 
                hideIconAdd hideIconEdit hideIconDelete
                informacionAdicional={informacionAdicional}
            />
        :   <SpinnerOval/>
        }
        </Fragment>
    );
}
 
export default AdscripcionRegistrados;
