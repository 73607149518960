import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';  
import { servicios_grupohit_catalogo, servicios_grupohit_nomina, registro_sera_actualizado } from '../../../../../../Constants/Constants'; 
import Axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonIndGralsConfigBases, ArrayJsonIndNominaConfigBasesEdit } from '../../../Helpers/JsonToOneLevel'; 
import Radio from '@material-ui/core/Radio'; 
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#81A1DD' },
    },
});

const FormularioConfigBasesIndNomina = ({ error, enviarConfigBaseIndicadoresGral, elementSelect, actualizarImpuestoNomina, informacion, enviarConfigBaseIndicadoresGralExiste, isLoading }) => { 
      
    const [ data, setData ]=useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [ souldInsert, setShouldInsert ] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
 
    useEffect(() => { 
        Axios.get(`${servicios_grupohit_catalogo}/bases/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(elementSelect){
                        getBases(ArrayJsonIndGralsConfigBases(res.data.dataResponse));
                    }else{
                        setData(ArrayJsonIndGralsConfigBases(res.data.dataResponse));
                    }
                    break;
                case 404:
                    setData([]);
                    break;
                default:
                    console.log(res.data.msg);
                    break;
            }
        })
        .catch(error => console.log(error)); 
            // eslint-disable-next-line
    },[]); 

    const getBases = (bases) => {  
            Axios.get(`${servicios_grupohit_nomina}/indicador/bases/${elementSelect.id}/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200: 
                        let resultEditables = ArrayJsonIndNominaConfigBasesEdit(res.data.dataResponse);
                        for (const key in bases) {
                            let resp = resultEditables.find(element=> element.baseId === bases[key].id);
                            if(resp===undefined){
                                bases[key].isNew     = true;
                                bases[key].operacion = `${resultEditables.length}/ `;
                                resultEditables.push(bases[key]);
                            }
                        }
                        setData( resultEditables ); 
                        break;
                    case 404:
                        setData(bases);
                        setShouldInsert(true);
                        break;
                    default:
                        console.log(res.data.msg);
                        break;
                }
            })
            .catch(error => console.log(error));
    } 
    
    const enviar = (evt) => {
        evt.preventDefault();
        if(elementSelect){ 
            const dataConfig= data.map(element => {return {nominId:elementSelect.id, baseId : element.id, operacion: element.operacion.split('/')[1]}});
            enviarConfigBaseIndicadoresGralExiste(dataConfig)
        }else{  
            const dataConfig= data.map(element => { if(element.operacion.split('/')[1]!==" "){ 
                                return { nomindId: informacion.id, baseId : element.id, operacion: element.operacion.split('/')[1]}
                            }else{
                                return "";
                            }});
            const result = dataConfig.filter( element => element!=="");   
            enviarConfigBaseIndicadoresGral(result);
        }
    }
    const actualizar = () => { 
        const dataConfig= data.map(element => { 
            if(element.isNew){
                if(element.operacion.split('/')[1]!==" "){ 
                    return {baseId : element.id, nominId: elementSelect.id, operacion: element.operacion.split('/')[1]}
                }else{
                    return "";
                }
            }else{ 
                return {id:element.id, baseId : element.baseId, nominId: element.nominId, operacion: element.operacion.split('/')[1]}
            }});
        const result = dataConfig.filter( element => element!=="");   
        actualizarImpuestoNomina({indicadoresBase: result});
    }
    
    const changeRadio = (event) =>{ 
        
        setSelectedValue(event.target.value)
        let newData = data; 
        let indice = event.target.value.split('/')[0];
        newData[indice].operacion=event.target.value; 
        setData(newData); 
        
    };

    const getData=()=>{ 
        let res=[];
        data.map((key, index)=>{
                res.push(<tr key={index} >
                                <td key={`${index}-con`}> {key.baseClave }</td>
                                <td key={`${index}-desc`}>{key.baseDescrip} </td> 
                                <td key={`${index}/+`} style={{textAlign:'center'}}> 
                                    <MuiThemeProvider theme={theme}> 
                                        <Radio checked={key.operacion === `${index}/+` ||  selectedValue === `${index}/+`} value={`${index}/+`} onChange={changeRadio} color="secondary" /> 
                                    </MuiThemeProvider> 
                                </td>
                                <td key={`${index}/-`} style={{textAlign:'center'}}> 
                                    <MuiThemeProvider theme={theme}> 
                                        <Radio  checked={key.operacion === `${index}/-` || selectedValue === `${index}/-`} value={`${index}/-`} onChange={changeRadio} color="secondary" /> 
                                    </MuiThemeProvider> 
                                </td> 
                                <td key={`${index}/ `} style={{textAlign:'center'}}> 
                                    <MuiThemeProvider theme={theme}> 
                                        <Radio  checked={key.operacion === `${index}/ ` || selectedValue === `${index}/ `} value={`${index}/ `} onChange={changeRadio} color="secondary" /> 
                                    </MuiThemeProvider> 
                                </td>  
                            </tr>);
                return null; 
            })
        return res;
    }

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarIndicadorFormConfig' : 'AgregarIndicadorFormConfig' } onSubmit={ elementSelect && !souldInsert ? mostrarAlert: enviar }>
                <table style={{width:"100%", margin:"30px 0px 30px 0px"}}>
                    <thead style={{color:'#4C638E'}}>
                            <tr>
                                <th className="label">Concepto </th>
                                <th className="label">Descripción</th>
                                <th className="label" style={{textAlign:'center'}}>+</th>
                                <th className="label" style={{textAlign:'center'}}>-</th>
                                <th className="label" style={{textAlign:'center'}}>N/A</th> 
                            </tr>
                    </thead>
                    <tbody>
                    { getData() }
                    </tbody>
                </table>
                {
                    data.length===0
                    ?
                    <div style={{marginBottom:'2rem', textAlign:'center'}}>
                        { `No Existen Bases Registradas` }
                    </div>
                    :null
                }
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ 'Guardar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioConfigBasesIndNomina;