import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService';
import { estad_por_periodo, servicios_grupohit, tablero_ } from '../../../../../Constants/Constants';
import SpinnerOval from '../../../../../Services/Spinner/SpinnerOval';

import PageTitleBar from '../../Layouts/PageTitleBar';
import AccesoDirecto from './AccesoDirecto';
import AgregarAccesos from './AgregarAccesos/AgregarAccesos';
import ModalDeleteAcceso from './ModalDeleteAcceso';
import { obtenerAccesos, agregarPermisosBloqueado } from './AgregarAccesos/HelperAccesos';

import Estadisticas from './Estadisticas/Estadisticas';
import CardBusquedaAvanzada from './CardBusquedaAvanzada';
import EmpleadosBusquedaAvanzada from './EmpleadosBusquedaAvanzada';
import CardBusquedaHistorico9719 from './CardBusquedaHistorico9719';
import CardRHHistorico9719 from './CardRHHistorico9719';
import Busqueda9719 from './Busqueda9719';
import BusquedaRH9719 from './BusquedaRH9719';
import './style.css';
import CardEstadPorPeriodos from './Estadisticas/EstadPorPeriodo/CardEstadPorPeriodo';
import EstadPorPeriodo from './Estadisticas/EstadPorPeriodo/EstadPorPeriodo';

const Tablero = ({ relacionLaboral, modulosApi }) => {

    const [ openDelete, setOpenDelete ] = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} ); 
    const [ openEmpleadosBusquedaAvanzada, setOpenEmpleadosBusquedaAvanzada] = useState(false);
    const [ openHistorico, setOpenHistorico] = useState(false);
    const [ openRHHistorico, setOpenRHHistorico] = useState(false);
    const [ openEstadPorPeriodos, setOpenEstadPorPeriodos] = useState(false);
    /* --------------------- Axios para obtener los accesos directos del usuario logueado --------------------- */
    const [ accesosDirectos, setAccesosDirectos ] = useState( null );
    useEffect(() => {
        axiosAccesos();
        // eslint-disable-next-line
    }, []); 

    useEffect(()=>{
        setOpenEmpleadosBusquedaAvanzada(false);
        setOpenHistorico(false);
        setOpenEstadPorPeriodos(false);
    },[relacionLaboral])

    const axiosAccesos = () => {
        setAccesosDirectos( null );
        axios.get( `${servicios_grupohit}/dashboard/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setAccesosDirectos( agregarPermisosBloqueado( modulosApi, respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        setAccesosDirectos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------ Actualizaciones de estado (accesosDirectos) ----------------------------- */
    const eliminar = ( idElement ) => {
        setAccesosDirectos( accesosDirectos.filter( element => element.modulo.id !== idElement ) );
        //successTable( 'Registro Eliminado' );
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <div id={`modulo${tablero_}`}> 
            {(!openEmpleadosBusquedaAvanzada && !openHistorico && !openRHHistorico && !openEstadPorPeriodos) &&
            <Fragment>
                <PageTitleBar title={estad_por_periodo} path="Tablero"/>
                <div className="estadisticas">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 my-2"> 
                            <CardEstadPorPeriodos setOpenEstadPorPeriodos={setOpenEstadPorPeriodos}/>
                        </div>
                    </div>
                </div>
                <div className="my-six-step">
                    <PageTitleBar title="Búsqueda Avanzada" path=""/>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 my-2"> 
                            <CardBusquedaAvanzada setOpenEmpleadosBusquedaAvanzada={setOpenEmpleadosBusquedaAvanzada}/>
                        </div>
                        <div className="col-md-3 col-sm-12 my-2"> 
                            <CardBusquedaHistorico9719 setOpenHistorico={setOpenHistorico}/>
                        </div>
                        <div className="col-md-3 col-sm-12 my-2"> 
                            <CardRHHistorico9719 setOpenRHHistorico={setOpenRHHistorico}/>
                        </div>
                    </div>
                    <PageTitleBar title="Accesos Rápidos" path=""/> 
                    <div className="row">
                    { accesosDirectos
                    ?   <Fragment>
                            { accesosDirectos.map( modulo => (
                                <div key={ modulo.id } className="col-md-3 col-sm-12 my-2">
                                    <AccesoDirecto modulo={modulo.modulo} setOpenDelete={setOpenDelete} setElementSelect={setElementSelect}/>
                                </div>
                            ))}
                            { accesosDirectos.length < 12 &&
                                <div className="col-md-3 col-sm-12 my-2">
                                    <AgregarAccesos accesosDirectos={obtenerAccesos( accesosDirectos )} axiosAccesos={axiosAccesos}/>
                                </div>
                            }
                            <ModalDeleteAcceso openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar}/>
                        </Fragment>
                    :   <div className='col'><SpinnerOval/></div>
                    }
                    </div>
                    <PageTitleBar title="Estadísticas de Empleados" path=""/> 
                    <div className="estadisticas">
                        <Estadisticas relacionLaboral={relacionLaboral}/>
                    </div>
                </div>
            </Fragment>
            }
            <EmpleadosBusquedaAvanzada id={1001} relacionLaboral={relacionLaboral} setOpenEmpleadosBusquedaAvanzada={setOpenEmpleadosBusquedaAvanzada} openEmpleadosBusquedaAvanzada={openEmpleadosBusquedaAvanzada}/>
            <Busqueda9719 id="1001historico9719" openHistorico={openHistorico} setOpenHistorico={setOpenHistorico}/>
            <BusquedaRH9719 id="1001RHhistorico9719" openRHHistorico={openRHHistorico} setOpenRHHistorico={setOpenRHHistorico}/>
            <EstadPorPeriodo id="1001EstadPorPeriodo" relacionLaboral={relacionLaboral} openEstadPorPeriodos={openEstadPorPeriodos} setOpenEstadPorPeriodos={setOpenEstadPorPeriodos}/>
        </div>
    );
};

export default Tablero;