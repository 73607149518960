import React, { createRef, Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';

const Formulario = ({ nombre, action }) => {

    const [nominas, setNominas] = useState([]);
    const [anios, setAnios] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [acumulados, setAcumulados] = useState([]);
    const [messageAlertSyE, setMessageAlertSyE] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    const nominaRef = createRef('');
    const anioRef = createRef('');
    const periodoRef = createRef('');
    const acumuladoRef = createRef('');

    useEffect(() => {
        axios.get(`${servicios_grupohit_nomina}/config/nomina/`, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    const respuesta = res.data.dataResponse.map(nomina => ( { id: nomina.id, nomina: nomina.nominaNombre } ));
                    setNominas(respuesta);
                    break;
                default:
                    setMessageAlertSyE(res.data.msg);
                    setOpenAlertSyE(true);
                    break;
            }
        })
        .catch(error => {
            setMessageAlertSyE(verifique_conexion);
            setOpenAlertSyE(true);
        });
    }, []);

    const envia = (e, tipo) => {
        e.preventDefault();
        if(validarSubmit("LayoutCFDI")) {
            const data = {};
            switch(tipo) {
                case 'PDF':
                case 'XML':
                    action(data, tipo);
                break;
                case 'Consultar':
                case 'Cancelar':
                case 'Timbrar':
                    action({
                        nomina: nominaRef.current.value,
                        anio: anioRef.current.value,
                        periodo: periodoRef.current.value,
                        acumulado: acumuladoRef.current.value,
                    });
                break;
                default:
                break;
            };
        }
    };

    const seleccionaNomina = value => {
        if(value.trim() === '') {
            setAnios([]);
        } else {
            axios.get(`${servicios_grupohit_nomina}/acumulados/periodo/${value}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        setAnios(res.data.dataResponse)
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
        }
        setPeriodos([]);
        setAcumulados([]);
    };
    
    const seleccionaAnio = value => {
        if(value.trim() === '') {
            setPeriodos([]);
        } else {
            const nominaId = nominaRef.current.value;
            axios.get(`${servicios_grupohit_nomina}/acumulados/peranio/${nominaId}/${value}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        let arrayPeriodos = res.data.dataResponse.map(periodo => ({
                            periodoId: periodo.periodoId,
                            periodoPerAnio: periodo.periodoPerAnio === 10 ? `9/10` : periodo.periodoPerAnio === 24 ? `23/24` : periodo.periodoPerAnio,
                        }));
                        arrayPeriodos = arrayPeriodos.filter(periodo =>( periodo.periodoPerAnio !== 9) && ( periodo.periodoPerAnio !== 23));
                        setPeriodos(arrayPeriodos);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
        }
        setAcumulados([]);
    };
    
    const seleccionaPeriodo = value => {
        if(value.trim() === '') {
            setAcumulados([]);
        } else {
            //if(nombre === 'Timbrar') setAcumulados([{ id: 1, acumulado: 'Nómina' }])
            //else setAcumulados([{ id: 'all', acumulado: 'Todos' }, { id: 1, acumulado: 'Nómina' }])
            const nominaId = nominaRef.current.value;
            axios.get(`${servicios_grupohit_nomina}/acumulados/nomina/${nominaId}/${value}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        setAcumulados(res.data.dataResponse);
                        break;
                    default:
                        setMessageAlertSyE(res.data.msg);
                        setOpenAlertSyE(true);
                        break;
                }
            })
            .catch( error => {
                setMessageAlertSyE(verifique_conexion);
                setOpenAlertSyE(true);
            });
        }
    };

    return (
        <Fragment>
            <form id="LayoutCFDI">
                <SelectValidation
                    title="Nómina"
                    arrayOpciones={nominas} keyOpcion="nomina"
                    refValue={nominaRef} defaultValue=""
                    onChangeExecute={seleccionaNomina}
                    disabled={(nominas.length > 0) ? false : true}
                    required
                />
                <div className="row">
                    <div className="col">
                        <SelectValidation
                            title="Año"
                            arrayOpciones={anios} keyOpcion="periodoAnio"
                            refValue={anioRef} defaultValue=""
                            onChangeExecute={seleccionaAnio}
                            disabled={(anios.length > 0) ? false : true}
                            required
                        />
                    </div>
                    <div className="col">
                        <SelectValidation
                            title="Periodo" arrayOpciones={periodos}
                            keyId="periodoId" keyOpcion="periodoPerAnio"
                            refValue={periodoRef} defaultValue=""
                            onChangeExecute={seleccionaPeriodo}
                            disabled={(periodos.length > 0) ? false : true}
                            required
                        />
                    </div>
                </div>
                <SelectValidation
                    title="Tipo de Acumulado" arrayOpciones={acumulados}
                    keyId="acumuladoId" keyOpcion="acumuladoNombre"
                    refValue={acumuladoRef} defaultValue=""
                    disabled={(acumulados.length > 0) ? false : true}
                    required
                />
                {
                    nombre === 'Descargar'
                    ?   <div className="row">
                            <div className="col">
                                <Button variant="contained" className="btn-color" onClick={(e) => envia(e, 'PDF')}>PDF</Button>
                            </div>
                            <div className="col">
                                <Button variant="contained" className="btn-color" onClick={(e) => envia(e, 'XML')}>{'<XML>'}</Button>
                            </div>
                        </div>
                    :   <Button variant="contained" className="btn-color" onClick={(e) => envia(e, nombre)}>{nombre}</Button>
                }
            </form>
            <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={messageAlertSyE} textbtnconfirm="Aceptar" type="error" />
        </Fragment>
    );
}
 
export default Formulario;