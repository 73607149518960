import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong } from '../../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../../Services/AlertForm';

const FormFinalizaPrestamos = ({ error, setError, cerrarPrestamo, isLoading, elementSelect }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const fechaFinRef = createRef('');

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'CerrarPrestamos' ) ){
            if(fechaFinRef.current.value) {
                if(inputDateToLong(fechaFinRef.current.value) < inputDateToLong(elementSelect.fechaIni)) {
                    setError(<AlertForm message="La fecha final debe ser mayor a la fecha inicial del préstamo."/>);
                    return;
                }
            }
            setOpenAlert( true );
        }
    };

    const finalizarPrestamo = () => {
        const fechaFin = inputDateToLong( fechaFinRef.current.value );
        cerrarPrestamo({ fechaFin });
    }

    return (
        <React.Fragment>
            <form id={ 'CerrarPrestamos' } onSubmit={ enviar }>
                <InputDateValidation title="Fecha Fin Préstamo" refValue={fechaFinRef} required />

                {error}
                {(isLoading)
                ?   <SpinnerOval />
                :   <Button variant="contained" className="btn-color" type="submit"> Finalizar </Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text="El préstamo será finalizado" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={finalizarPrestamo}/>
        </React.Fragment>
    );
};

export default FormFinalizaPrestamos;