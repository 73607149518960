import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, registro_actualizado, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { Tooltip } from 'reactstrap';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CheckIndicadoresSueldo from './CheckIndicadoresSueldo';
import { floatToAmount } from '../../../../../../../Services/Validation/HelperValidation';
import AlertForm from '../../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

const Sueldo = props => {

    const {
        modulo, elementSelect, indicadores, monto, montoEdit, setMonto, setMontoEdit, setIndicadoresSelected, indicadoresSelected,
        disabledPuesto, successTable, errorTable,errorConceptoPago, setErrorConceptoPago,
        indicadoresSueldoNombramientoActivo, indicadoresSueldoActivo, indicadoresNombramientoActivo, muestraConceptos,
        contratoRef, contratos, puestoSeleccionado, setNombramientoEditInicial, nombramientoEditInicial
    } = props;

    const [muestraIndicadores, setMuestraIndicadores] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [mostrar, setMostrar] = useState('none');

    const toggle = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        if(!elementSelect || (modulo === 32)) setMostrar('block');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indicadores]);

    useEffect(() => {
        setMostrar('none');
        if(elementSelect && (modulo === 30) && disabledPuesto) {
            setMuestraIndicadores(false);
            axios.get(`${servicios_grupohit_rh}/vistaempleado/indicadores/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const idIndicadores = res.data.dataResponse.map(indicador => indicador.indicadorId);
                        const montoIndicadoresP = res.data.dataResponse.filter(indicador => indicador.tipoNatuId === 1);
                        const montoIndicadoresD = res.data.dataResponse.filter(indicador => indicador.tipoNatuId === 2);
                        const totalMontoIndicadoresP = (montoIndicadoresP.length > 0) ? montoIndicadoresP.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0;
                        const totalMontoIndicadoresD = (montoIndicadoresD.length > 0) ? montoIndicadoresD.reduce((total, indicador) => ( { monto: total.monto + parseFloat(indicador.monto) } )).monto : 0;
                        setMontoEdit(totalMontoIndicadoresP-totalMontoIndicadoresD);
                        setIndicadoresSelected(idIndicadores);
                        setMuestraIndicadores(true);
                        //verifica si tiene concepto que cause nombramiento al editar empleado
                        const indicadoresSelectConNombramiento = indicadoresNombramientoActivo.find(ina => idIndicadores.indexOf(ina.indicadorid.indicadorId) > -1);
                        setNombramientoEditInicial((indicadoresSelectConNombramiento) ? true : false);
                        break;
                    default:
                        setIndicadoresSelected([]);
                        setMuestraIndicadores(true);
                        break;
                }
            })
            .catch(error => setIndicadoresSelected([]));
        }

        if(elementSelect && (modulo === 30) && !disabledPuesto) {
            setMostrar('block');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabledPuesto]);

    const mostrarCheck = e => {
        e.preventDefault();

        if(mostrar === 'none') {
            setMostrar('block');
        }

        if(mostrar === 'block') {
            if(indicadoresSelected.length > 0) {
                if(elementSelect && (modulo === 30)) {
                    axios.post(`${servicios_grupohit_rh}/empleado/indicadores/`, { Empleado: [{ EmpleadoId: elementSelect.id, Indicadores: indicadoresSelected }] }, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(nombramientoEditInicial === false) {
                                    const indicadoresNombramientoActivoSelected = indicadoresNombramientoActivo.find(ina => indicadoresSelected.indexOf(ina.indicadorid.indicadorId) > -1);
                                    const ejecutaBitacora10 = (indicadoresNombramientoActivoSelected) ? true : false;
                                    if(ejecutaBitacora10) {
                                        //se ejecuta el servicio de bitacora10cuando no teníaconcepto con causanombramiento y
                                        //se le asigna uno que sí causa nombramiento
                                        axios.post(`${servicios_grupohit_rh}/empleado/bitacora/`, { empleadoId: elementSelect.id, tipoMovId: 10 }, headersAuth());
                                    }
                                }
                                //verifica si tiene concepto que cause nombramiento al editar empleado
                                const indicadoresSelectConNombramiento = indicadoresNombramientoActivo.find(ina => indicadoresSelected.indexOf(ina.indicadorid.indicadorId) > -1);
                                setNombramientoEditInicial((indicadoresSelectConNombramiento) ? true : false);
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(`Indicadores ${res.data.msg}`);
                                break;
                        }
                    })
                    .catch(error => {errorTable(`Indicadores ${registro_no_guardado}`); console.log(error) });
                }
                setMostrar('none');
                setErrorConceptoPago(null);
            } else {
                setErrorConceptoPago(<AlertForm message="Debe seleccionar, al menos, un concepto"/>);
                return;
            }
        }
    };

    return (
        <Fragment>
            <div className="row">
                <div className={(elementSelect && (modulo === 30)) ? (disabledPuesto) ? 'col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11' : 'col' : 'col'}>
                    <label>Sueldo<span id="tooltipSueldo" className="spanInfo"><InfoRoundedIcon color="disabled"/></span></label>
                    <input className="form-control" value={floatToAmount((disabledPuesto) ? (elementSelect && (modulo === 30)) ? montoEdit : monto : monto, 2)} disabled/>
                    <Tooltip placement="right" isOpen={tooltipOpen} target="tooltipSueldo" toggle={toggle}>
                        Se registra automaticamente al seleccionar Puesto
                    </Tooltip>
                </div>
                {   (elementSelect && (modulo === 30) && disabledPuesto) &&
                    <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end">
                        <button className="btn-autocompleteSueldo" onClick={mostrarCheck}>{(mostrar === 'none') ? <EditIcon/> : <SaveIcon/>}</button>
                    </div>
                }
            </div>
            { errorConceptoPago }
            {(puestoSeleccionado.id !== '') &&
            <Fragment>
                <div style={{ display: mostrar }}>
                    <br/>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-9 col-ls-9 col-xl-9">
                            <label>Conceptos *</label>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-ls-3 col-xl-3">
                            <label>Monto *</label>
                        </div>
                    </div>
                    {(muestraConceptos)
                    ?   (!elementSelect || (modulo === 32))
                        ?   (indicadores)
                            ?   indicadores.map((indicador, key) => (
                                    <CheckIndicadoresSueldo
                                        key={key} modulo={modulo} monto={monto} setMonto={setMonto} indicador={indicador.indicadorid} montoIndicador={(indicador.indicadorid.recibo) ? indicador.monto : 0.00}
                                        setIndicadoresSelected={setIndicadoresSelected} indicadoresSelected={indicadoresSelected} disabledPuesto={disabledPuesto} mostrar={indicador.indicadorid.recibo}
                                        indicadoresSueldoNombramientoActivo={indicadoresSueldoNombramientoActivo} indicadoresSueldoActivo={indicadoresSueldoActivo} indicadoresNombramientoActivo={indicadoresNombramientoActivo}
                                        contratoRef={contratoRef} contratos={contratos} puestoSeleccionado={puestoSeleccionado}
                                        setErrorConceptoPago={setErrorConceptoPago}
                                    />
                                ))
                            :   null
                        :   (muestraIndicadores)
                            ?   indicadores.map((indicador, key) => (
                                    <CheckIndicadoresSueldo
                                        key={key} modulo={modulo} elementSelect={elementSelect} monto={monto} setMonto={setMonto} montoEdit={montoEdit} setMontoEdit={setMontoEdit} indicador={indicador.indicadorid} montoIndicador={(indicador.indicadorid.recibo) ? indicador.monto : 0.00}
                                        setIndicadoresSelected={setIndicadoresSelected} indicadoresSelected={indicadoresSelected} disabledPuesto={disabledPuesto} mostrar={indicador.indicadorid.recibo}    
                                        indicadoresSueldoNombramientoActivo={indicadoresSueldoNombramientoActivo} indicadoresSueldoActivo={indicadoresSueldoActivo} indicadoresNombramientoActivo={indicadoresNombramientoActivo}
                                        contratoRef={contratoRef} contratos={contratos} puestoSeleccionado={puestoSeleccionado}
                                        setErrorConceptoPago={setErrorConceptoPago}
                                    />
                                ))
                            :   null
                    :   <SpinnerOval />
                    }
                </div>
            </Fragment>
            }
        </Fragment>
    );
}
 
export default Sueldo;
