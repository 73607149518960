import React, { useState, createRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';

import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_nomina, verifique_conexion } from '../../../../../../Constants/Constants';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormDeleteAcumulados = ({ errorTable, successTable, informacion }) => {
    const nominaRef = createRef('');
    const anioRef = createRef('');
    const periodoRef = createRef('');
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ aniosNomina, setAniosNomina ] = useState( [] );
    const [ periodosAnio, setPeriodosAnio ] =  useState( [] );
    const [ error, setError ] = useState( null );
    const [ isLoading, setIsLoading ] = useState(false);

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const consultaAnioNomina = ( nominaId ) =>{
        setError( null );
        setAniosNomina([]);
        setPeriodosAnio([]);
        if( nominaId !== "" ){
            axios.get( `${servicios_grupohit_nomina}/acumulados/periodo/${nominaId}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setAniosNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setAniosNomina( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }

    const consultaPeriodosNomina = ( anioID ) => {
        setError(null);
        setPeriodosAnio([]);
        if( anioID !== "" ){
            axios.get( `${servicios_grupohit_nomina}/acumulados/peranio/${nominaRef.current.value}/${anioID}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        const data = respuesta.data.dataResponse.map(item => (
                            {
                                id: item.periodoId,
                                periodoPerAnio: item.periodoPerAnio, 
                            }
                        ));
                        setPeriodosAnio(data)
                        break;
                    case 404:
                        setPeriodosAnio( [] );
                        setError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }
    }

    const axiosDeleteAcumulados = ( periodoID ) => {
        setIsLoading(true);
        axios.delete( `${servicios_grupohit_nomina}/acumulados/tacumulados/periodo/${periodoID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setError( null );
                        //anioRef.current.value = '';
                        setPeriodosAnio([]);
                        successTable( "El periodo ha sido eliminado correctamente" );
                        break;
                    case 400: //El periodo que trata de eliminar no existe
                        errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable(respuesta.data.msg);
                        break;
                }
            })
            .catch( error => {
                errorTable(verifique_conexion);
            })
            .finally(() => setIsLoading(false));
    }

    const actualizar = () => {
        if( validarSubmit( 'DeleteAcumulados' ) ){
            const periodoID = periodoRef.current.value;
            axiosDeleteAcumulados( periodoID );
        }
    }

    return (
        <React.Fragment>
            <form id="DeleteAcumulados" onSubmit={mostrarAlert}>
                <SelectValidation title="Nómina" arrayOpciones={informacion.nominas} keyOpcion="nominaNombre"
                    refValue={nominaRef} onChangeExecute={consultaAnioNomina} required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Año" arrayOpciones={aniosNomina} keyOpcion="periodoAnio"
                            refValue={anioRef} onChangeExecute={consultaPeriodosNomina} disabled={aniosNomina.length === 0} required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo" arrayOpciones={periodosAnio} keyOpcion="periodoPerAnio"
                            refValue={periodoRef} disabled={periodosAnio.length === 0} required/>
                    </div>
                </div>

                {error}
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit" disabled={periodosAnio.length === 0}> Eliminar Periodo de este Acumulado </Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text="Se eliminarán los periodos de este acumulado" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormDeleteAcumulados;