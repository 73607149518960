import React, { useRef, useState } from 'react';
import AlertForm from '../../../../Services/AlertForm';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const useStyles = makeStyles((theme) => ({
    formControl: { 
      minWidth: 120,
      width:'100%',
      height:'70%'
    }, 
  }));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{fontFamily:'Univia'}}>{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalTipoRelacionLaboral = ({ toggleRelacionLaboral, modal, relacionesLaborales, relacionLaboral, servicioSetRelLaboral, btnAcceptDisabled, setBtnAcceptDisabled, error, setError }) => {

    const classes = useStyles();
    const relacionLaboralRef = useRef("");
    const [value, setValue ] = useState(relacionLaboral.id ? relacionLaboral.id : " ");
    
    const cambiarRelacionLaboral = () => {
        const relacionLaboralInt = parseInt(relacionLaboralRef.current.value);
        const relacionLaboralSelect = relacionesLaborales.find(relLab => relLab.id === relacionLaboralInt);
        if(relacionLaboralSelect) {
            //Solo se ejecuta cuando la relacionLaboral seleccionada es diferente a la actual o aun no se ha seleccionado
            if(relacionLaboralSelect.id !== relacionLaboral.id) {
                servicioSetRelLaboral(relacionLaboralSelect);
            }else{ //En caso contrario: la relacionLaboral seleccionada es la misma (No hay necesidad de hacer cambios)
                toggleRelacionLaboral();
                setError(null);
            }
        } else {
            setError(<AlertForm message="Seleccione un tipo de Relación Laboral válido" />);
        }
    };
    
    const validarSelect = (event) => { 
        setValue(event.target.value); 
    } 
    const cerrarModal = () => {
        if(relacionLaboral !== ''){
            toggleRelacionLaboral();
            setError(null);
        } else {
            setError(<AlertForm message="Debe seleccionar un tipo de Relación Laboral" />);
        }
    }

    return (
        <Dialog onClose={toggleRelacionLaboral} aria-labelledby="customized-dialog-title" open={modal} disableEscapeKeyDown={true} disableBackdropClick={true}  className="contenedorModalRelLab">
            <DialogTitle id="customized-dialog-title">Seleccione un tipo de Relación Laboral</DialogTitle>
            <DialogContent>
            <FormControl variant="filled" className={ `selectValidation ${ classes.formControl}` } >
                <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                <Select
                    inputProps={{inputRef:relacionLaboralRef}}
                    disableUnderline
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined" 
                    label=""
                    onChange={validarSelect}
                    value={ value } 
                > 
                <MenuItem className={"selectOption"} value={" "}>- Seleccione -</MenuItem>
                    {  
                        relacionesLaborales.map( (relLaboral) => (
                          <MenuItem className={"selectOption"} key={relLaboral.id} value={relLaboral.id}> {relLaboral.nombre} </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
                { error }
            </DialogContent>
            <DialogActions className="s">
                <Button className="btn-color btn-modal" onClick={cambiarRelacionLaboral} disabled={btnAcceptDisabled}>Aceptar</Button>
                <Button className="btn-outline btn-modal" onClick={cerrarModal}>Cancelar</Button>  
            </DialogActions>
        </Dialog>
    );
}

export default ModalTipoRelacionLaboral;