import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalService from '../../../ModalService/ModalService';
import PrestamosEmpleado from './PrestamosEmpleado/PrestamosEmpleado';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupohit_rh, verifique_conexion } from '../../../../../../Constants/Constants';
import Alert from '../../../../../../Services/Alerts/AlertSyE';
import { floatToAmount } from '../../../../../../Services/Validation/HelperValidation';
import { Spinner } from 'reactstrap';

const ModalConsultaPrestamos = ({ id, openModalForm, setOpenModalForm, elementSelect, setElementSelect }) => {

    const [montoLiquido, setMontoLiquido] = useState(null);
    const [alertText, setAlertText] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [obtienePrestamos, setObtienePrestamos] = useState(false);
    const [muestraMontoLiquido, setMuestraMontoLiquido] = useState(false);
    
    useEffect(() => {
        if(elementSelect.id) {
            axios.get(`${servicios_grupohit_rh}/empleado/liquido/${elementSelect.id}`, headersAuth())
            .then(res => {
                if(res.data.status === 200) {
                    setMontoLiquido(res.data.dataResponse[0]);
                } else {
                    setMontoLiquido(0);
                    setAlertText(res.data.msg);
                    setOpenAlert(true);
                }
                setTimeout(() => {
                    setObtienePrestamos(true);
                }, 500);
            })
            .catch(() => {
                setMontoLiquido(0);
                setAlertText(verifique_conexion);
                setOpenAlert(true);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const cerrarModal = () => {
        setMuestraMontoLiquido(false);
        setElementSelect({});
        setOpenModalForm( false );
        setAlertText('');
        setMontoLiquido(null);
    };

    return (
        <ModalService title="Consultar Préstamos" parentNode={id} minWidth="80%" maxWidth="80%"
            isOpen={ openModalForm } onClose={ () => cerrarModal() } >
                <div className="row mb-2">
                    <div className="col-md-6 col-sm-12">
                        <div> <b> Empleado: </b> {elementSelect.nombreCompleto} </div>
                        <div> <b> Número Empleado: </b> {elementSelect.empleadoClave} </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div> <b> Nómina: </b> {elementSelect.nominaNombre} </div>
                        <div> <b> Monto Líquido: </b> {((montoLiquido !== null) && muestraMontoLiquido) ? floatToAmount(montoLiquido, 2) : <Spinner />} </div>
                    </div>
                </div>
                <PrestamosEmpleado
                    id={id} empleado={elementSelect}
                    montoLiquido={montoLiquido} setMontoLiquido={setMontoLiquido}
                    obtienePrestamos={obtienePrestamos} setObtienePrestamos={setObtienePrestamos}
                    setMuestraMontoLiquido={setMuestraMontoLiquido}
                />
                <Alert show={openAlert} setOpenAlertSyE={setOpenAlert} text={alertText} textbtnconfirm="Aceptar" type="error"/>
        </ModalService>
    );
};

export default ModalConsultaPrestamos;