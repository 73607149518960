import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupohit_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTipoAcumulado from './FormTipoAcumulado';

const ModalEditTipoAcumulado = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarTipoAcumulado = (info, acumuladoId) => {
        setIsLoading(true);
        Axios.put( `${servicios_grupohit_catalogo}s/tipoacumulado/${acumuladoId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(res.data.dataResponse);
                    setOpenEdit(false);
                break;
                default:
                    errorTable(res.data.msg);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados))
        .finally(() => setIsLoading(false));
    }

    return (
        <ModalService title="Actualizar Tipo de Acumulado" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTipoAcumulado
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarTipoAcumulado={actualizarTipoAcumulado}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTipoAcumulado;