import React, { useState, Fragment } from 'react';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { Tooltip } from '@material-ui/core';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import { registro_sera_eliminado } from '../../../../../../Constants/Constants';

const BtnRemoveItem = ({ id, idArray, array, action, remove, simulador, style, noArrayVacio, montoMinimo, totalPD }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertError, setOpenAlertError] = useState(false);

    const mostrarAlerta = e => {
        e.preventDefault();
        if(noArrayVacio) {
            if(array.length < 2) {
                setOpenAlertError(true);
                return;
            }
        }

        if(array && idArray && id && totalPD) {
            const elementSelected = array.find(element => element[idArray] === id);
            if(elementSelected?.monto > totalPD) {
                setOpenAlertError(true);
                return;
            }
        }
        
        setOpenAlert(true);
    };

    const removeItem = () => {
        if(array) {
            const elementSelected = array.find(element => element[idArray] === id);
            if(remove) {
                action(elementSelected);
            } else {
                const newArray = array.filter(element => element[idArray] !== id);
                action(newArray);
            }
        } else {
            action(id);
        }
    };

    return (
        <Fragment>
            <Tooltip title="Eliminar">
                <button
                    className={(simulador) ? 'btn-removeItem-outlined-simulador' : 'btn-removeItem-outlined'}
                    style={(style) ? style : {}}
                    onClick={mostrarAlerta}
                >
                    <RemoveCircleOutlineOutlinedIcon fontSize="small"/>
                </button>
            </Tooltip>
            <Alertwarning show={openAlert} SetopenALert={setOpenAlert}
                text={  (id && array && idArray && montoMinimo && totalPD && array.find(element => element[idArray] === id)?.tiponatuId === 1)
                        ?   (totalPD-array.find(element => element[idArray] === id).monto< montoMinimo)
                            ?   'El total de liquidez esta por debajo del monto mínimo configurado para todos los empleados. ¿Desea continuar?'
                            :   registro_sera_eliminado
                        :   registro_sera_eliminado
                }
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={removeItem}
            />
            <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title={(simulador) ? 'El monto de la percepción que desa eliminar debe ser menor al total de liquidez.' : "No puede eliminar todas las formas de pago."} text="" textbtnconfirm="Aceptar" type="error"/>
        </Fragment>
    );
}
 
export default BtnRemoveItem;