import React from 'react';

import Email from '@material-ui/icons/Email';

const SeleccionaElemento = () => {
    return (
        <div className="mensajesContenidoVacio">
            <Email /> <br/>
            <span> Seleccione un elemento para leerlo </span>
        </div>
    );
};

export default SeleccionaElemento;