import React from 'react';
import axios from 'axios';
import { servicios_grupohit, verifique_conexion } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService'; 
import FormSaveConfigReport from './FormSaveConfigReport';
import { JsonNewReport } from '../../../Helpers/JsonToOneLevel';

const ModalSaveReport = ({ openEdit, setOpenEdit, errorTable, editar, successTable, elementSelect, nombreModulo }) => {

    const cerrarModal = () => {
        setOpenEdit(false);
    }; 

    const enviarConfigReport = ({ result, setIsLoading }) => {
        setIsLoading(true);
        axios.post( `${servicios_grupohit}/configuracion/reportes/`, result, headersAuth() )
        .then(respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    editar(JsonNewReport(respuesta.data.dataResponse));
                    setOpenEdit( false );
                    break;
                default:
                    errorTable( respuesta.data.msg );
                    break;
            }
        })
        .catch(error => {
            errorTable(verifique_conexion);
        })
        .finally(() => setIsLoading(false));
    }
    
    return (
        <ModalService
            title={`Guardar Configuración de Reporte`}
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="40%"
            maxWidth="40%"
        >
            <div className="row">
                <div className="col-12"> 
                    <FormSaveConfigReport  
                        elementSelect={elementSelect} 
                        enviarConfigReport={enviarConfigReport}
                    />
                </div>  
            </div>
        </ModalService>
    );
}

export default ModalSaveReport;