
export function obtenerAccesos( modulosSuperior ) {
    const accesoModulos = [];
    modulosSuperior.forEach( superModulo => {
        if( superModulo.nombre !== 'Tablero' && superModulo.nombre !== 'Ayuda' ){
            accesoModulos.push({ modulo: superModulo.id, permisos: [] });
            if( superModulo.children ){
                superModulo.children.forEach( mod => {
                    accesoModulos.push({ modulo: mod.id, permisos: obtenerPermisos(mod.permisos), superior: mod.superior });
                });
            }
        }
    });

    return accesoModulos;
}

export function obtenerPermisos( permisos ) {
    const permisosModulo = [];
    permisos.forEach( permiso => {
        if( permiso.id ) permisosModulo.push( permiso.id );
    });
    return permisosModulo ;
}

/* ------------------------- Funcion para limitar los elementos a {modulo, permisos} eliminando elemento superior -------------------------- */
export function eliminarSuperior( accesosMenu ) {
    const accesos = [];
    accesosMenu.forEach( acceso => {
        accesos.push({ modulo: acceso.modulo, permisos: acceso.permisos });
    });
    accesos.push({ modulo: 1, permisos: [] }); //Tablero
    accesos.push({ modulo: 43, permisos: [] }); //Ayuda
    accesos.push({ modulo: 52, permisos: [1,2,3,4] }); //Manual
    accesos.push({ modulo: 53, permisos: [1,2,3,4] }); //Video
    return accesos;
}

/* Funcion para agregar acceso a arrego de accesos (accesosMenu) */
export function agregarAcceso( accesosMenu, moduloPadreID, moduloHijoID, permisos ){
    const accesosTemporal = [ ...accesosMenu ];
    /* Verificamos y Agregamos modulo padre con permisos: [] */
    if( accesosTemporal.find( acceso => acceso.modulo === moduloPadreID ) ){
        accesosTemporal.map( acceso => acceso.modulo === moduloPadreID ? acceso.permisos = [] : acceso );
    }else{
        accesosTemporal.push({ modulo: moduloPadreID, permisos: [] });
    }

    /* Agregamos Modulo Hijo */
    if( accesosTemporal.find( acceso => acceso.modulo === moduloHijoID ) ){
        accesosTemporal.map( acceso => acceso.modulo === moduloHijoID ? acceso.permisos = permisos : acceso );
    }else{
        accesosTemporal.push({ modulo: moduloHijoID, permisos: permisos, superior: moduloPadreID });
    }
    return accesosTemporal;
}

/* Funcion para eliminar acceso de arreglo de accesos (accesosMenu) */
export function eliminarAcceso( accesosMenu, moduloPadreID, moduloHijoID ){
    let accesosTemporal = [ ...accesosMenu ];
    /* Eliminamos Modulo Hijo */
    if( accesosTemporal.find( acceso => acceso.modulo === moduloHijoID ) ){
        accesosTemporal = accesosTemporal.filter( acceso => acceso.modulo !== moduloHijoID );
    }

    /* Verificamos y eliminamos modulo padre */
    if( accesosTemporal.filter( acceso => acceso.superior  === moduloPadreID ).length < 1 ){
        if( accesosTemporal.find( acceso => acceso.modulo === moduloPadreID ) ){
            accesosTemporal = accesosTemporal.filter( acceso => acceso.modulo !== moduloPadreID );
        }
    }
    return accesosTemporal;
}