import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { pagos_pendientes, servicios_grupohit, verifique_conexion } from '../../../../../../Constants/Constants';
import { borrarEmpPagosPendientes } from '../../../../../../Redux/actions/EmpleadosActions';
import ModalService from '../../../ModalService/ModalService';
import FormModalPagosPendientes from '../../Nomina/PagosUnicos/FormModalPagosPendientes';

const ModalPagosPendientes = ({ nombreModulo, elementSelect, retienePagos, openModal, setOpenModal, successTable, errorTable, borrarEmpPagosPendientes }) => {

    const cerrarModal = () => {
        setOpenModal(false);
        borrarEmpPagosPendientes();
    };

    const autorizarPagosPendientes = value => {
        const request = {
            empleadoId: elementSelect.id,
            periodos: value
        };
        
        axios.post(`${servicios_grupohit}/empleado/pago/pendiente/autoriza`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(res.data.msg);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(verifique_conexion))
        .finally(() => cerrarModal());
    }; 

    const obtienePagosPendientes = () => {
        axios.get(`${servicios_grupohit}/empleado/pago/pendiente/${elementSelect.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newArrayPagosPendientesId = res.data.dataResponse.map(pago => {
                        return pago.periodoId;
                    });
                    autorizarPagosPendientes(newArrayPagosPendientesId);
                    break;
                case 400:
                    errorTable(res.data.msg);
                    cerrarModal();
                    break;
                default:
                    errorTable(res.data.msg);
                    cerrarModal();
                    break;
            }
        })
        .catch(error => {
            cerrarModal();
            errorTable(verifique_conexion);
        })
    };

    /*useEffect(() => {
        if(!retienePagos && elementSelect) {
            obtienePagosPendientes();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);*/
    
    return (
        <Fragment>
        {(retienePagos) &&
            <ModalService
                title={pagos_pendientes}
                parentNode={nombreModulo}
                minWidth="90%"
                maxWidth="90%"
                isOpen={openModal}
                onClose={cerrarModal}
            >
                <FormModalPagosPendientes id={nombreModulo} elementSelect={elementSelect} successTable={successTable} errorTable={errorTable} cerrarModal={cerrarModal}/>
            </ModalService>
        }
        </Fragment>
    );
};
  
const mapDispatchToProps = { borrarEmpPagosPendientes };
 
export default connect(null, mapDispatchToProps)(ModalPagosPendientes);
