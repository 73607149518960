import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupohit_rh, cambios_no_guardados, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormularioPoliticas from './FormularioPoliticas';
import { JsonPrimaVacacional, JsonAguinaldo, JsonDiasAjuste, JsonVacaciones, JsonAusentismosPoliticas2 } from '../../../Helpers/JsonToOneLevel';

const ModalEditPoliticas = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo, dataTable}) => {

    const [ error, setError ] = useState( null );
    const [isLoading, setIsLoading] = useState(false);
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const tipoTitulos = [
        { id: 0, nombre: 'Nuevo Aguinaldo', dir: '/politica/aguinaldo/' },
        { id: 1, nombre: 'Nueva Prima Vacacional', dir: '/politica/prima/vacacional/' },
        { id: 2, nombre: 'Nuevo Día de Ajuste', dir: '/politica/dias/ajuste/' },
        { id: 3, nombre: 'Nuevas Vacaciones', dir: '/politica/vacaciones/' },
        { id: 4, nombre: 'Nuevo Ausentismo', dir: '/unificado/politica/ausentismo/' }
    ];
    const regex=/VA|PV|DA|AG|AU$/;

    const actualizarPolitica = ( politica, active, newDias=null ) => {
        setIsLoading(true);
        const dir = tipoTitulos.filter(element => element.id ===parseInt(active))[0].dir;
        axios.put( `${servicios_grupohit_rh}${dir}${(active === 4) ? '' : elementSelect.id.replace(regex,'')}` , politica, headersAuth() )
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        switch (active){
                            case 0: 
                                editar( JsonAguinaldo( res.data.dataResponse ));
                                break;
                            case 1: 
                                editar( JsonPrimaVacacional( res.data.dataResponse ));
                                break;
                            case 2: 
                                if(newDias!==null) enviarPeriodoAjuste(newDias);
                                editar( JsonDiasAjuste( res.data.dataResponse ));
                                break;
                            case 3: 
                                editar( JsonVacaciones( res.data.dataResponse ));
                                break;
                            case 4:
                                editar(JsonAusentismosPoliticas2(res.data.dataResponse));
                                break;
                            default:
                            break;
                        }
                        cerrarModal();
                        break;
                    default:
                        errorTable( res.data.msg );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
            .finally(() => setIsLoading(false));
    };

    const enviarPeriodoAjuste = async (dataPeriodos ) => { 
        const newData = dataPeriodos.map(element => {return {diasAjuste: parseInt( elementSelect.id.replace(regex,'')), periodoPago: element.periodoPago, diasPagoPeriodo: element.diasPagoPeriodo }});
        for (const key in newData) {
            await axios.post( `${servicios_grupohit_rh}/periodo/ajuste/`, newData[key], headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                    break;
                    default:
                        errorTable(res.data.msg);
                        return false; 
                }
            })
            .catch(error => errorTable(registro_no_guardado));
        } 
    };
    
    return (
        <ModalService
            title={`Actualizar Política de ${Object.keys(elementSelect).length>0 ? `${elementSelect.tipo.charAt(0)}${elementSelect.tipo.slice(1).toLowerCase()}` : ''}`}
            parentNode={nombreModulo}
            minWidth="70%"
            maxWidth="70%"
            isOpen={openEdit}
            onClose={cerrarModal}
        >
            <FormularioPoliticas
                error={error}
                elementSelect={elementSelect}
                actualizarPolitica={actualizarPolitica}
                dataTable={dataTable}
                isLoading={isLoading}
            />
        </ModalService>
    );
};

export default ModalEditPoliticas;