import React, { useState, createRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormSaveConfigReport = ({ enviarConfigReport, elementSelect }) => {

    const [isLoading, setIsLoading] = useState(false);

    const nombreRef = createRef('');
    const descripcionRef = createRef('');
    
    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarReporteRecursosHumanos')) {
            const reporteNom = nombreRef.current.value; 
            const reporteDescrip = descripcionRef.current.value;
            const campos = elementSelect.map( (element, index) => {
                    if(index !== elementSelect.length-1)
                        return  { 
                                nombre: element.field,
                                titulo: element.title,
                                ordenDefault: element.defaultSort ? element.defaultSort : '',
                                defaultFiltro: element.tableData && element.tableData.filterValue ? element.tableData.filterValue : '',
                                oculto: element.hidden ? true : false,
                                posicionDefaultGrupo: element.tableData && element.tableData.groupOrder>=0 ? element.tableData.groupOrder : '',
                                ordenDefaultGrupo: element.tableData && element.tableData.groupSort ? element.tableData.groupSort : 'desc',
                                posicionColumna: element.tableData && element.tableData.columnOrder>=0 ?element.tableData.columnOrder :''
                            }
                    else return null;
            }).filter( element => element!==null);
            const result = { 
                reporteNom,
                reporteDescrip,
                tipoReporte: elementSelect[elementSelect.length-1].infoReport ? 2: 1,
                estatico:false,
                reporteJson:[
                    {
                        filtroGeneral: elementSelect[elementSelect.length-1].filtroGeneral,
                        campos: campos.sort((a, b)=> a.posicionColumna > b.posicionColumna ? 1 : a.posicionColumna < b.posicionColumna ? -1 : 0),
                        infoReport: elementSelect[elementSelect.length-1].infoReport ? elementSelect[elementSelect.length-1].infoReport :null
                    }
                ] 
                }; 
            enviarConfigReport({ result, setIsLoading });
        }
    };
 
    return (  
        <form id={'AgregarReporteRecursosHumanos' } onSubmit={ enviar }>
           <div className="row">
                <div className="col-md-12 col-sm-12">
                    <InputValidation title="Nombre del Reporte" type="nombreReporte" placeholder="Escriba el nombre del Reporte"
                        tooltipText={`Máximo 100 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipNomReport'} maxLength={100}
                        refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.nombre : '' } required/> 
                </div>
                <div className="col-md-12 col-sm-12">
                    <InputValidation title="Descripción" type="descReporte" placeholder="Escriba la descripción del Reporte"
                        tooltipText={`Máximo 100 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipNomReport'} maxLength={100}
                        refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.nombre : '' } required fieldType="textarea"/> 
                </div>
            </div>
            {(isLoading)
            ?   <SpinnerOval/>
            :   <Button variant="contained" className="btn-color" type="submit">{ 'Guardar' }</Button>
            }
        </form>    
    );
}

export default FormSaveConfigReport;