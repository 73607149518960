import React, { useState, createRef, Fragment } from 'react';
import ConvertDate from '../../../Helpers/ConvertDate';
import ModalService from '../../../ModalService/ModalService';
import NuevoMovimiento from './Components/Escalafon/NuevoMovimiento';
import Escalafon from './Components/Escalafon/Escalafon';
import ListaEmpleadosEscalafon from './Components/Escalafon/ListaEmpleadosEscalafon';
import servicioFechaMinima from './Helpers/ServicioFechaMinima';
import AlertError from '../../../../../../Services/Alerts/AlertSyE';
import { verifique_conexion } from '../../../../../../Constants/Constants';

const ModalEscalafon = ({ nombreModulo, openEscalafon, setOpenEscalafon, successTable, errorTable, empleadosEscalafon, setEmpleadosEscalafon, ocultarTablaEscalafon }) => {

    const [muestraEscalafon, setMuestraEscalafon] = useState(false);
    const [tipoMovimiento, setTipoMovimiento] = useState({ definitivo: false, temporal: false });
    const [mostrar, setMostrar] = useState(1);
    const [fechaInicial, setFechaInicial] = useState(null);
    const [fechaFinal, setFechaFinal] = useState(null);
    const [plazas, setPlazas] = useState([]);
    const [movimientos, setMovimientos] = useState([]);
    const [ultimoMovimiento, setUltimoMovimiento] = useState(null);
    const [actualizaPlaza, setActualizaPlaza] = useState(false);
    const [folio, setFolio] = useState(false);
    const [fechaMinima, setFechaMinima] = useState(null);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [textError, setTextError] = useState('');

    const fechaInicialRef = createRef('');
    const fechaFinalRef = createRef('');
    const folioRef = createRef('');

    const { definitivo, temporal } = tipoMovimiento;

    const cerrarModal = (ocultarTabla) => {
        setTipoMovimiento({ definitivo: false, temporal: false });
        setMostrar(1);
        setFechaInicial(null);
        setFechaFinal(null);
        setOpenEscalafon(false);
        setPlazas([]);
        setMovimientos([]);
        setUltimoMovimiento(null);
        setActualizaPlaza(false);
        setMuestraEscalafon(false);
        setFechaMinima(null);
        setOpenAlertError(false);
        setTextError('');
        if(ocultarTabla) ocultarTablaEscalafon();
    };

    const muestraAlertaError = mensaje => {
        setTextError(mensaje);
        setOpenAlertError(true);
    };

    const obtenerFechaMinima = async () => {
        const arrayEmpleados = empleadosEscalafon.map(emp => ( emp.id ));
        try {
            const res = await servicioFechaMinima({ arrayEmpleados });
            switch(res.data.status) {
                case 200:
                    setFechaMinima(res.data.dataResponse);
                    break;
                default:
                    setFechaMinima(0);
                    muestraAlertaError(res.data.msg);
                    break;
            }
        } catch (error) {
            setFechaMinima(0);
            muestraAlertaError(verifique_conexion);
        }
    };

    return (
        <ModalService
            title={`Movimiento Escalafonario ${(temporal) ? 'Temporal' : (definitivo) ? 'Definitivo' : ''} ${(mostrar === 2) ? folio : ''}`}
            labelSubtitle={(mostrar === 2) ? 'Fecha:' : null}
            subtitle={
                (mostrar === 2)
                ?   (temporal)
                    ? `${ConvertDate(fechaInicial) } - ${ConvertDate(fechaFinal)}`
                    : ConvertDate(fechaInicial)
                :   null
            }
            parentNode={nombreModulo}
            isOpen={openEscalafon}
            onClose={() => cerrarModal(false)}
            minWidth={(mostrar === 2) ? '85%' : '55%'}
            maxWidth={(mostrar === 2) ? '85%' : '55%'}
        >
        {(muestraEscalafon)
        ?   <Fragment>
                <div style={{ display: (mostrar === 1) ? 'block' : 'none' }}>
                    <NuevoMovimiento
                        tipoMovimiento={tipoMovimiento} setTipoMovimiento={setTipoMovimiento}
                        folioRef={folioRef} setFolio={setFolio} fechaInicialRef={fechaInicialRef} fechaFinalRef={fechaFinalRef}
                        setFechaInicial={setFechaInicial} setFechaFinal={setFechaFinal}
                        setMostrar={setMostrar} fechaMinima={fechaMinima}
                    />
                </div>
                <div style={{ display: (mostrar === 2) ? 'block' : 'none' }}>
                    <Escalafon
                        empleadosEscalafon={empleadosEscalafon}
                        folioRef={folioRef}
                        plazas={plazas}
                        setPlazas={setPlazas}
                        movimientos={movimientos}
                        fechaInicial={fechaInicial}
                        fechaFinal={fechaFinal}
                        setMovimientos={setMovimientos}
                        ultimoMovimiento={ultimoMovimiento}
                        setUltimoMovimiento={setUltimoMovimiento}
                        actualizaPlaza={actualizaPlaza}
                        setActualizaPlaza={setActualizaPlaza}
                        successTable={successTable}
                        errorTable={errorTable}
                        setMostrar={setMostrar}
                        cerrarModal={cerrarModal}
                    />
                </div>
            </Fragment>
        :   <ListaEmpleadosEscalafon
                empleadosEscalafon={empleadosEscalafon}
                setEmpleadosEscalafon={setEmpleadosEscalafon}
                setMuestraEscalafon={setMuestraEscalafon}
                obtenerFechaMinima={obtenerFechaMinima}
            />
        }
        <AlertError show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Petición Fallida" text={textError} textbtnconfirm="Aceptar" type="error" />
        </ModalService>
    );
}
 
export default ModalEscalafon;