import React, { useState, Fragment } from 'react';
import ModalSecuencia from './ModalSecuencia';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import TableSelectRows from '../../../../../../Services/Tables/TableSelectRows';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import arrayEmpleados from './helpers/arrayEmpleados';

const TableEmpleados = ({ nombreModulo, empleados, enviaEmpleados, contieneSecuencia }) => {

    const [openModalSecuencia, setOpenModalSecuencia] = useState(false);
    const [data, setData] = useState(null);

    const abrirModalFormularioSecuencia = data => {
        setOpenModalSecuencia(true);
        setData(data);
    };

    const enviaEmpleadosDesdeModal = secuencia => {
        enviaEmpleados(data, parseInt(secuencia));
    };

    return (
        <Fragment>
            {
                (empleados)
                ?   <Fragment>
                        <TableSelectRows
                            title=''
                            columns={[
                                { field: 'empleadoClave', title: 'NUP' },
                                { field: 'nombreCompleto', title: 'Nombre' },
                                { field: 'empleadoGenero', title: 'Género' },
                                { field: 'empleadoRfc', title: 'RFC' },
                                { field: 'puestoCve', title: 'Clave Puesto' },
                                { field: 'puestoNom', title: 'Puesto' },
                                { field: 'nominaNombre', title: 'Nómina' },
                                { field: 'cpresupuestalClave', title: 'Clave Presupuestal / Proyecto' },
                                { field: 'nivLaboralClave', title: 'Nivel' },
                            ]}
                            data={arrayEmpleados(empleados)}
                            filtering
                            icon={ChromeReaderModeIcon}
                            tooltip="Generar"
                            action={(contieneSecuencia) ? abrirModalFormularioSecuencia : enviaEmpleados}
                        />
                        <ModalSecuencia
                            nombreModulo={nombreModulo} open={openModalSecuencia} setOpen={setOpenModalSecuencia}
                            enviaEmpleados={enviaEmpleadosDesdeModal}
                        />
                    </Fragment>
                :   <SpinnerOval />
            }
        </Fragment>
    );
}
 
export default TableEmpleados;