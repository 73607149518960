import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormNivelEscolaridad = ({ error, setError, enviarNivelEscolaridad, elementSelect, actualizarNivelEscolaridad, isLoading }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const nombreRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('NivelEscolaridad')) {
            const escolaridadNom = nombreRef.current.value;
            enviarNivelEscolaridad({ escolaridadNom });
        }
    };

    const actualizar = () => {
        if(validarSubmit('NivelEscolaridad')) {
            const escolaridadNom = nombreRef.current.value;
            setError(null);
            actualizarNivelEscolaridad({ escolaridadNom }, elementSelect.id);
        }
    };

    return (
        <Fragment>
            <form id="NivelEscolaridad" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Nombre" type="nombreNivelEsc" placeholder="Escriba el nombre del nivel de escolaridad"
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.escolaridadNom : '' } 
                    tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                    tooltipName="tooltipNomNivEsc" maxLength={128} required/>
                { error }
                {(isLoading)
                ?   <SpinnerOval/>
                :   <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                }
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}

export default FormNivelEscolaridad;