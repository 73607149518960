import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import DataTableService from '../../../../../DataTableService/DataTableService';
import InputText from '../../../../../../../../Services/Components/DataTable/InputText';
import AlertForm from '../../../../../../../../Services/AlertForm';
import { arrayJsonMovimientosPorPeriodoReingresos } from '../../../../../Helpers/JsonToOneLevel';
import { mov_registrados, reingresos, servicios_grupohit_nomina, verifique_conexion } from '../../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';
import reporteAltaPersonal from './reporteAltaPersonal';
import AlertSyE from '../../../../../../../../Services/Alerts/AlertSyE';
import crearZip from './descargaZipdeReporteAltaPersonal';

const ReingresosRegistrados = ({ id, requestTabla, setBloqueaCards, setError, moverATabla, informacionAdicional }) => {

    const [infoTabla, setInfoTabla] = useState(null);
    const [textAlert, setTextAlert] = useState('');
    const [openAlertSyE, setOpenAlertSyE] = useState(false);

    useEffect(() => {
        const servicioReingresos = () => {
            setBloqueaCards(true);
            axios.post(`${servicios_grupohit_nomina}/procesos/estadisticas/complementaria/reingresos`, requestTabla, headersAuth())
            .then(res => { 
                switch(res.data.status) {
                    case 200:
                        setInfoTabla(arrayJsonMovimientosPorPeriodoReingresos(res.data.dataResponse, 2));
                        break;
                    default:
                        setInfoTabla([]);
                        setError(<AlertForm message={res.data.msg} />);
                        break;
                }
            })
            .catch(error => { setInfoTabla([]); setError(<AlertForm message={verifique_conexion} />) })
            .finally(() => moverATabla() );
        };

        servicioReingresos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const enviaReporteAltaPersonal = element => {
        const request = {
            PeriodoAnio: requestTabla.periodoAnio,
	        Periodo: requestTabla.periodoPerAnio,
	        empleadoId: [Number(element.id)]
        };
        
        reporteAltaPersonal(request)
        .then(res => {
            if(res.data.status === 200) {
                crearZip(element, res.data.dataResponse, 'reingreso')
            } else {
                setTextAlert(res.data.msg);
                setOpenAlertSyE(true);
            }
        })
        .catch(error => {
            setTextAlert(verifique_conexion);
            setOpenAlertSyE(true);
        });
    };
    
    return (
        <Fragment>
        {(infoTabla)
        ?   <Fragment>
                <DataTableService
                    id={`${id}ReingresoR`}
                    title={`${reingresos} - ${mov_registrados}`}
                    columns={[
                        { field: "empleadoClave", title: "NUE", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.empleadoClave.startsWith(term) },
                        { field: "empleadoNomCompleto", title: "Nombre" },
                        { field: "empleadoRfc", title: "RFC" },
                        { field: "nomina", title: "Clave Nómina / Nómina" },
                        { field: "cpresupuestal", title: "Clave Presupuestal / Proyecto", customFilterAndSearch: (term, rowData) => rowData.cpresupuestal.startsWith(term) },
                        { field: "plazaClave", title: "NUP", filterComponent: (props) => <InputText {...props}/>, customFilterAndSearch: (term, rowData) => rowData.plazaClave.startsWith(term) },
                        { field: "puesto", title: "Clave Puesto / Puesto" },
                        { field: "empStatusFecIni", title: "Fecha Inicial" },
                        { field: "usuario", title: "Cuenta", hidden: true, hiddenByColumnsButton: true },
                        { field: "nombre", title: "Usuario" },
                        { field: "fechaMovimiento", title: "Fecha de Registro" },
                    ]}
                    data={infoTabla}
                    customOptionsTable={{ filtering: true }} 
                    hideIconAdd hideIconEdit hideIconDelete
                    informacionAdicional={informacionAdicional}
                    action={enviaReporteAltaPersonal}
                    showDownloadEmployeeFile
                />
                <AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Petición Fallida" text={textAlert} textbtnconfirm="Aceptar" type="error" />
            </Fragment>
        :   <SpinnerOval/>
        }
        </Fragment>
    );
}
 
export default ReingresosRegistrados;
