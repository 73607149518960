//export const NAMEUSER_EXPR = /^[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{2,30}$|^[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}\s()[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}$|^[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}\s()[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}\s()[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}$/;
export const NAMEUSER_EXPR = /^(([A-Za-zÑñÁÉÍÓÚáéíóúÜü][\s]?)*){1,60}$/;
export const NAMEUSER_DEGREE_EXPR = /^(([A-Za-zÑñÁÉÍÓÚáéíóúÜü][.]?[\s]?)*){1,60}$/;
export const SURNAMEUSER_EXPR = /^(([A-Za-zÑñÁÉÍÓÚáéíóúÜü][\s]?)*){1,30}$/;
export const CORREO_EXPR = /^([\w.\-0-9]{5,63})([@]{1})([a-z]{2,20})([.]{1})([a-z]{2,3}|([a-z]{3}[.]{1}[a-z]{2}))$/;
// Caracteres especiales que deberán considerarse posteriormente para creación de password !@#$&*%/()=?¡.,|°<>;+-_
//export const PASSWORD_EXPR = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])\S{8,16}$/;
export const PASSWORD_EXPR = /^(?=.*[A-Z])(?=.*[!@#$&*%/()=?¡.,|°<>;+_])(?=.*[0-9])(?=.*[a-z])\S{8,16}$/;
export const RFC_EXPR = /^(([A-ZÑ]{1}[AEIOUX]{1}[A-ZÑ]{1,2}))([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([0-9A-ZÑ]{3})\b/;
export const CURP_EXPR = /^(([A-ZÑ]{1}[AEIOUX]{1}[A-ZÑ]{2}))([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]{1})(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)([B-DF-HJ-NP-TV-Z]{3})([0-9A-Z]{1}[0-9]{1})\b/;
export const COMPANIA_EXPR = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)]{2,64}$/;
export const CUENTAINDICADORES_1_3 = /^[A-Z0-9]{1,3}$/;
export const CUENTAINDICADORES_6 = /^[A-Z0-9]{6}$/;
export const CLAVE_2_NUM = /^[0-9]{2}$/;
export const CLAVE_3_NUM = /^[0-9]{3}$/;
export const CLAVE_1_5_NUM = /^[0-9]{1,5}$/;
export const CLAVE_6_NUM = /^[0-9]{6}$/;
export const CLAVE_8_NUM = /^[0-9]{8}$/;
export const CLAVE_9_NUM = /^[0-9]{9}$/;
export const CLAVE_11_NUM = /^[0-9]{11}$/;
export const CLAVE_12_ALFANUM_ = /^[A-Za-z0-9]{1,12}$/;
export const CLAVE_14_NUM = /^[0-9]{14}$/;
export const CLAVE_1_10_NUM = /^[0-9]{1,10}$/;
export const CLAVE_1_16_NUM = /^[0-9]{1,16}$/;
export const CLAVE_2_ESP = /^[A-Za-z0-9=@#*<>]{2}$/;
export const CLAVE_1_20_NUM = /^[1-9]{1}$|^[1-9]{1}[0-9]{1,19}$/;
export const CLAVE_2_20_ESP = /^[A-Za-z0-9=@#*<>]{2,20}$/;
export const CLAVE_1_3 = /^[A-Za-z0-9]{1,3}$/;
export const CLAVE_1_5 = /^[A-Za-z0-9]{1,5}$/;
export const CLAVE_REG_PATRONAL = /^([A-Z]{1}[0-9]{10})$/;
export const CLAVE_1_8 = /^[A-Za-z0-9]{1,8}$/;
export const CLAVE_1_16 = /^[A-Za-z0-9]{1,16}$/;
export const CLAVE_1_3_NUMEROS = /^[0-9]{1,3}$/;
export const CLAVE_1_3_MAYUSCULAS = /^[A-Z]{1,3}$/;
export const CED_PROF = /^[A-Z0-9-]{1,13}$/;
export const NOMBRE_1_50 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s]{1,50}$/;
export const NOMBRE_1_230 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s]{1,230}$/;
export const TEXTO_1_20 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,20}$/;
export const TEXTO_1_64 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,64}$/;
export const TEXTO_1_64_ESP = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#%/@*;:]{1,64}$/;
export const TEXTO_100 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,100}$/;
export const TEXTO_1_120 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,120}$/;
export const TEXTO_1_124 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,124}$/;
export const TEXTO_1_128 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,128}$/;
export const TEXTO_1_128_ESP = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#%/@*;:]{1,128}$/;
export const TEXTO_1_200 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,200}$/;
export const TEXTO_1_256 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,256}$/;
export const TEXTO_1_512 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,512}$/;
export const TEXTO_1_1024 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,1024}$/;
export const TEXTO_1_2024 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,2024}$/;
export const TEXTO_1_2024_ESPECIALES = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,:;\-+=)("'#&$@*%]{1,2024}$/;
export const AGNIO =/^(19|20)\d{2}$/;
export const MONTO =/^\$(([1-9]{1})(,\d{3})(,\d{3})|(\d{1,3})(,\d{3})|(\d{1,3}))(\.\d+)$/;
export const CANTIDAD_16 = /^[0-9]{1,16}$/;
export const GRADO_ESTUDIOS = /^[1-9]{1}$/;
export const DIAS_AGNIO = /^(0{0,2}[12]?[0-9]?[0-9]|3[0-5][0-9]|36[0-6])(\.\d+)?$/;
export const DIAS_AGNIO_WITHOUT_ZERO = /^[1-9]{1}$|^[1-9]{1}[0-9]{1}$|^[1-2]{1}[0-9]{1}[0-9]{1}$|^[3]{1}[0-5]{1}[0-9]{1}$|^[3]{1}[6]{1}[0-6]{1}$/;
export const AGNIOS_0_100_DECIMAL_5 = /^100(\.0{0,2})?|^\d{1,2}(\.5)?$/;
/* Expresiones regulares para modulo Dependencias/Domicilios */
export const CODIGOPOSTAL_EXPR = /^[0-9]{5}$/;
export const CALLE_EXPR = /^[A-Za-z0-9áéíóúÁÉÍÓÚñÑ\s.,\-&#]+$/;
export const NUM_INTERIOR_EXPR = /^[A-Za-z0-9\s#-]+$/;
export const TELEFONO_EXPR = /^[0-9]{10}$/;
export const EXT_TELEFONICA_EXPR = /^[0-9]{1,5}$/;
/* Expresiones regulares para modulo Usuarios */
export const USUARIO_CORREO_EXPR = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
export const USUARIO_NOMBRE_EXPR = /^[A-Za-záéíóúÁÉÍÓÚñÑ .,-]+$/;
/* Expresiones regulares para modulo /Organización/Nómina */
export const CLAVE_NOM_ORG = /^[A-Za-z0-9-]+$/;
export const TEXT_NOM_ORG = /^[A-Za-z0-9áéíóúÁÉÍÓÚñÑ /().,-]+$/;

export const TIPO_PARENTESCO = /^[A-Za-zÑñÁÉÍÓÚáéíóúÜü .,\-()]{2,64}/;
export const PROMEDIO_CALIFICACION = /^([0-9]{1}[.]{1}[0-9]{2}$)|10.00$/;
export const NSS = /^[0-9]{11}$/;
export const NUM_EMP = /^[0-9]{1,10}$/;

export const CUENTA = /^[0-9]{10,11}$/;
export const PARTIDA_CUENTA = /^[0-9]{0,8}$/;
export const CLABE_TARJETA = /^[0-9]{16}$|^[0-9]{18}$/;
export const IMPORTE_DECIMAL = /^[0-9]{1,6}[.]{1}[0-9]{2}$/;
export const CANTIDAD_1_100 = /^[1-9]{1}$|^[1-9]{1}[0-9]{1}$|^100$/;
export const CANTIDAD_DECIMAL = /^([0-9]{1,2}[.]{1}[0-9]{1,2}$)/;
export const PORCENTAJE_DECIMAL = /^([0-9]{1,2}[.]{1}[0-9]{2}$)|100.00$/;
export const PORCENTAJE_3_DECIMALES = /^([0-9]{1,2}[.]{1}[0-9]{3}$)|100.000$/;
export const PRIORIDAD = /^[0-9]{1,254}$/;
export const PRIORIDAD1_99 = /^[1-9]{1}|[1-9][0-9]{2}$/;
export const DATE_SAFARI = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const ENTERO_5_DECIMAL_2 = /^\$?((\d+|\d{1,2}(,\d{3}))[.]{1}[0-9]{2}$)$/; //1 entero de máximo 5 dígitos & 2 decimales
export const ENTERO_1_DECIMAL_6 = /^([0-9]{1}[.]{1}[0-9]{6}$)$/; //1 entero de máximo 1 dígito & 6 decimales
export const NUM_1_26_2DEC = /^([0-9]{1}|[01]{1}[0-9]{1}|[2]{1}[0-5]{1})([.]{1}[0-9]{2}$)|26.00$/;
export const NUM_1_20_2DEC = /^([0-9]{1}|[01]{1}[0-9]{1})([.]{1}[0-9]{2}$)|20.00$/;
export const CERO_A_99 = /^(0?[1-9]|[1-9][0-9])$/;
/*Consulta Histórico 1997 - */
export const WORD2_MIN4 = /^[\wÑñÁÉÍÓÚáéíóúÜü]{4,}$|^[\wÑñÁÉÍÓÚáéíóúÜü]{1,}\s(){1}[\wÑñÁÉÍÓÚáéíóúÜü]{2,}$|^[\wÑñÁÉÍÓÚáéíóúÜü]{2,}\s(){1}[\wÑñÁÉÍÓÚáéíóúÜü]{1,}$/;
/* */
export const WORD1_16 = /^(([\w][\s]?)*){1,16}$/
export const FOLIO_ESC_1_64 = /^[\w\W]{1,64}$/;

export const LETRAS_1 = /^[A-Za-z]{1}$/;
export const ALFANUMERICO_1 = /^[A-Za-z0-9]{1}$/;
export const ALFANUMERICO_1_64 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#0-9]{1,64}$/;
export const ALFANUMERICO_1_140 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#0-9]{1,140}$/;
export const ALFANUMERICO_1_256 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#0-9]{1,256}$/;
export const DIGITOS_1 = /^[0-9]{1}$/;
export const DIGITOS_2 = /^[0-9]{2}$/;

/* Funcion que retorna status y mensaje de validacion para el valor de un input */
export function comprobar( input, type, auxiliar=null ) {
    if(input !== ''){
        switch( type ){
            case 'name':
            case 'nombre':
                if( NAMEUSER_EXPR.test( input )){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nameDot':
                if( NAMEUSER_DEGREE_EXPR.test( input )){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'lastName':
            case 'pApellido':
            case 'sApellido':
                if( SURNAMEUSER_EXPR.test( input )){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Apellido no válido' };
                }
            case 'e-mail':
                if( CORREO_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Email no válido' };
                }
            case 'password':
            case 'pass':
                if( PASSWORD_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Contraseña no válida' };
                }
            case 'passCFDI':
                if( input.length >= 8 ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Contraseña no válida' };
                }
            case 'numEmpleado':
                if( NUM_EMP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'NUE no válido' };
                }
            case 'folioEmpleado':
                if( NUM_EMP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Folio no válido' };
                }
            case 'rfc':
                if( RFC_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'RFC no válido' };
                }
            case 'curp':
                if( CURP_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'CURP no válido' };
                }
            case 'gradoEstudios':
                if( GRADO_ESTUDIOS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'CURP no válido' };
                }
            case 'empresa':
            case 'compania':
                if( COMPANIA_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'claveCvePresupuestal':
                if( CLAVE_2_ESP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveSubprograma2':
                if( CLAVE_2_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveUEjecutora3':
            case 'clavePrograma3':
            case 'claveUnidadResponsable':
            case 'clavePAccion3':
            case 'claveOAccion3':
                if( CLAVE_3_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveUEjecutora6':
                if( CLAVE_6_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'clavePAccion':
                if( CLAVE_8_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'clavePrograma9':
                if( CLAVE_9_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveSubprograma11':
                if( CLAVE_11_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'clavePAccion14':
                if( CLAVE_14_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'indicadorsat':
            case 'claveBanco':
            case 'claveParentesco':
            case 'claveSeguroSocial':
            case 'claveJornada':
            case 'claveMotBaja':
                if( CLAVE_1_3.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveIndGenerales':
                if( CLAVE_1_5_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveNivel':
                if( CLAVE_1_5.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'secuenciaPlantilla':
                if( CLAVE_1_10_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Secuencia no válida' };
                }
            case 'claveCAdscripcion':
                if( CLAVE_2_20_ESP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'clavePlaza':
                if( CLAVE_1_20_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'ClaveRegPatron':
                if( CLAVE_REG_PATRONAL.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveTipoRelLab':
            case 'claveTipAcu':
                if( CLAVE_1_16.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'folioPensionAlimenticia':
            case 'claveAusentismo':
                if( CLAVE_1_8.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Folio no válido' };
                }
            case 'claveRegimenFiscal':
            case 'claveTipoContrato':
            case 'claveTipoRegimen':
            case 'claveTipoRiesgo':
                if( CLAVE_1_3_NUMEROS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveOrigenRecurso':
                if( CLAVE_1_3_MAYUSCULAS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveBase':
                if( CLAVE_1_16_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'nombreDiscapacidad':
                if( TEXTO_1_20.test( input ) ){
                    return { status: true, mensaje: '' };
                 }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'ciudad':
            case 'estado':
            case 'nombreEdoCivil':
                if( NOMBRE_1_50.test( input ) ){
                    return { status: true, mensaje: '' };
                 }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombrePasatiempo':
            case 'carrera':
                if( NOMBRE_1_230.test( input ) ){
                    return { status: true, mensaje: '' };
                 }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreTipoRelLab':
            case 'nombreTipoAcu':
            case 'nombreBase':
                if( TEXTO_1_64.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreRecibo':
                if( TEXTO_1_64_ESP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreTurno':
            case 'nombreContrato':
                if( TEXTO_100.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreTipoDomicilio':
                if( TEXTO_1_120.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreCAdscripcion':
                if( TEXTO_1_124.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreBanco':
            case 'nombreGiro':
            case 'nombreColonia':
            case 'nombreParentesco':
            case 'nombreSeguroSocial':
            case 'nombreAusentismo':
            case 'nombreJornada':
            case 'nombreDocumento':
            case 'nombreTipoSecDep':
            case 'nombreNivelEsc':
            case 'nombreUEjecutora':
            case 'nombrePrograma':
            case 'nombreSubprograma':
            case 'nombrePAccion':
            case 'nombreOAccion':
            case 'nombreCvePresupuestal':
            case 'nombreSindicato':
                if( TEXTO_1_128.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreIndGenerales':
                if( TEXTO_1_128_ESP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreMotBaja':
            case 'nombreDepartamento':
                if( TEXTO_1_256.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'equipoEmpleado':
                if( ALFANUMERICO_1_256.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Equipo no válido' };
                }
            case 'descripcionRegimenFiscal':
            case 'descripcionOrigenRecurso':
            case 'descripcionTipoContrato':
            case 'descripcionTipoRegimen':
            case 'descripcionTipoRiesgo':
                if( TEXTO_1_128.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionBanco':
            case 'descripcionSindicato':
                if( TEXTO_1_256.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionTipoRelLab':
            case 'descripcionDepartamento':
                if( TEXTO_1_512.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionCAdscripcion':
            case 'descripcionTipAcu':
            case 'descripcionDocumento':
            case 'descripcionUsuario':
            case 'descripcionBase':
                if( TEXTO_1_1024.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionPasatiempo':
            case 'descripcionBaja':
                if( TEXTO_1_2024.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'notaAdicional':
                if( TEXTO_1_2024_ESPECIALES.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nota no válida' };
                }
            case 'cuentaIndicadores1-3':
                if( CUENTAINDICADORES_1_3.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'cuentaIndicadores6':
                if( CUENTAINDICADORES_6.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            /* -- Casos Dependencias */
            case 'cp':
                if( CODIGOPOSTAL_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Código postal no válido' };
                }
            case 'calle':
                if( CALLE_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Dirección, calle no válida' };
                }
            case 'numIntExt':
                if( NUM_INTERIOR_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Número interior/exterior no válido' };
                }
            case 'parentesco':
                if( TIPO_PARENTESCO.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre de tipo de parentesco no válido' };
                }
            case 'telefono':
                if( TELEFONO_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Número de teléfono no válido' };
                }
            case 'extTelefonica':
                if( EXT_TELEFONICA_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Extensión telefónica no válida' };
                }
            /* -- Casos Usuario */
            case 'usuarioCorreo':
                if( USUARIO_CORREO_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cuenta de correo no válida' };
                }
            case 'usuarioNombre':
                if( USUARIO_NOMBRE_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'usuarioApellido':
                if( USUARIO_NOMBRE_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Apellido no válido' };
                }
            /* -- Casos /Organización/Nóminas */
            case 'claveNominaOrg':
                if( CLAVE_NOM_ORG.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'nombreNominaOrg':
            case 'nomCortoNominaOrg':
                if( TEXT_NOM_ORG.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'descNominaOrg':
                if( TEXT_NOM_ORG.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'promedio':
                if( PROMEDIO_CALIFICACION.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Promedio no válido' };
                }
            case 'nss':
                if( NSS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'NSS no válido' };
                }
            case 'cedulaProfesional':
                if( CED_PROF.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cédula no válida' };
                }
            case 'agnio':
                if(AGNIO.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Año no válido' };
                }
            case 'monto':
                if(MONTO.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Monto no válido' };
                }
                
            case 'cantidad':
                if(CANTIDAD_16.test(input) && input.charAt(0) !== '0'){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'cantidadPagosPrestamos':
                if(CANTIDAD_16.test(input)){
                    if( input.length > 1 && input.charAt(0) === '0' ){
                        return { status: false, mensaje: 'Cantidad no válida' };
                    }else{
                        return { status: true, mensaje: '' };
                    }
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'umaSMinimo':
                if(CANTIDAD_1_100.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'importe':
                if(IMPORTE_DECIMAL.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Importe no válido' };
                }
            case 'cantidadDecimal':
                if(CANTIDAD_DECIMAL.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'porcentaje':
                if(PORCENTAJE_DECIMAL.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Porcentaje no válido' };
                }
            case 'porcentaje3':
                if(PORCENTAJE_3_DECIMALES.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Porcentaje no válido' };
                }
            case 'cbeInterbancaria-numTarjeta':
                if(CLABE_TARJETA.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Tarjeta o CLABE no válida' };
                }
            case 'numCuenta':
                if(CUENTA.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cuenta no válida' };
                }
            case 'partidaCuenta':
                if(PARTIDA_CUENTA.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Valor no válida' };
                }
            case 'date':
                if(DATE_SAFARI.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Fecha no válida' };
                }
            case 'dateEqualLess':
                if(input <= hoyMilisegundos()){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Fecha no válida' };
                }
            case 'dateEqualGreater':
                if((input + 86399000) >= hoyMilisegundos()){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Fecha no válida' };
                }
            case 'dateGreaterThan':
                if( input > auxiliar){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Fecha no válida' };
                }
            case 'dateGreaterEqualThan':
                if( input >= auxiliar){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Fecha no válida' };
                }
            case 'prioridad':
                if(PRIORIDAD.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Prioridad no válida' };
                }
            case 'prioridad1_99':
                if(PRIORIDAD1_99.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Prioridad no válida' };
                }
            /* -- Casos IMSS */
            case 'cuotasIMSS':
                if(ENTERO_5_DECIMAL_2.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'factorSueldoIMSS':
                if(ENTERO_1_DECIMAL_6.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'aginoDecimal5':
                if(AGNIOS_0_100_DECIMAL_5.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Días no válidos' };
                }
            case 'diasAgino':
                if(DIAS_AGNIO.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Días no válidos' };
                }
            case 'diasAginoMoreThanZero':
                if(DIAS_AGNIO_WITHOUT_ZERO.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Días no válidos' };
                }
            case 'comentarios':
                if(TEXTO_1_1024.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Comentario no válido' };
                }
            case 'consecutivo':
                if(CERO_A_99.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Consecutivo no válido' };
                }
            case 'emisora':
                if(CLAVE_1_5_NUM.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Emisora no válida' };
                }
            case 'numCliente':
                if(CLAVE_1_5_NUM.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Número de Cliente no válido' };
                }
            case 'secuenciaArchivo':
                if(CLAVE_1_5_NUM.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Secuencia de Archivo no válida' };
                }
            case 'cuentaCarco':
                if(CLAVE_1_5_NUM.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cuentas de Cargo no válida' };
                }
            case 'marginSI':
                if(NUM_1_26_2DEC.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Margen no válido' };
                }
            case 'marginID':
                if(NUM_1_20_2DEC.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Margen no válido' };
                }
            case 'generalHistorico9719':
                if(WORD2_MIN4.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Texto no válido' };
                }
            case 'word1-16':
                if(WORD1_16.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Texto no válido' };
                }
            case 'folioEscalafon':
                if(FOLIO_ESC_1_64.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Folio no válido' };
                }
            case 'claveFinanciamientoCve':
                if(CLAVE_12_ALFANUM_.test(input)) {
                    return { status: true, mensaje: '' };
                } else {
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveFinanciamientoVal':
            case 'tipoCLC':
                if(LETRAS_1.test(input)) {
                    return { status: true, mensaje: '' };
                } else {
                    return { status: false, mensaje: 'Valor no válido' };
                }
            case 'claveFinanciamientoVal2':
            case 'modalidad':
                if(DIGITOS_2.test(input)) {
                    return { status: true, mensaje: '' };
                } else {
                    return { status: false, mensaje: 'Valor no válido' };
                }
            case 'conceptoPago':
                if(ALFANUMERICO_1.test(input)) {
                    return { status: true, mensaje: '' };
                } else {
                    return { status: false, mensaje: 'Valor no válido' };
                }
            case 'tipoNomina':
            case 'numFirmas':
                if(DIGITOS_1.test(input)) {
                    return { status: true, mensaje: '' };
                } else {
                    return { status: false, mensaje: 'Valor no válido' };
                }
            /* -- Caso default */
            case 'mensaje':
                if(ALFANUMERICO_1_140.test(input)) {
                    return { status: true, mensaje: '' };
                } else {
                    return { status: false, mensaje: 'Mensaje no válido' };
                }
            default:
                return { status: true, mensaje: '' };
                //return { status: false, mensaje: 'No encontrado' };
        }
    }else{
        return { status: false, mensaje: 'Complete el campo requerido' };
    }
}

export function hoyMilisegundos() {
    const hoy = new Date();
    return hoy.getTime();
}

export function validarSubmit( idForm ){
    const formulario = document.getElementById( idForm );
    const divs = formulario.getElementsByClassName( 'validar' );
    Array.from( divs ).map( div => {

        //VALIDACIÓN TIPO DATE PARA SAFARI
        if( div.childNodes[0].tagName === 'DIV' ){
            if(div.childNodes[0].children[0].tagName==='INPUT'){  
                let obj = ''
                if( /^dateEqualLess/.test(div.childNodes[1].id) ) {
                    obj = comprobar( inputDateToLong(div.childNodes[0].children[0].value), 'dateEqualLess' ); 
                } else if(/^dateEqualGreater/.test(div.childNodes[1].id)) {
                    obj = comprobar( inputDateToLong(div.childNodes[0].children[0].value), 'dateEqualGreater' ); 
                } else {
                    obj = comprobar( div.childNodes[0].children[0].value, 'date' ); 
                }
                if( div.childNodes[0].children[0].value === '' || obj.status === false ){ 
                    div.className = 'MuiFormControl-root MuiTextField-root form-control validar is-invalid';
                    div.replaceChild( document.createElement('div'), div.lastChild );
                    if(/^dateEqualLess/.test(div.childNodes[1].id)) {
                        div.lastChild.innerHTML = 'Seleccione una fecha menor o igual que hoy';
                    } else if(/^dateEqualGreater/.test(div.childNodes[1].id)) {
                        div.lastChild.innerHTML = 'Seleccione una fecha mayor o igual que hoy';
                    } else {
                        div.lastChild.innerHTML = 'Seleccione una fecha válida';
                    }
                    div.lastChild.className = 'invalid-feedback';
                }else{
                    div.className = 'MuiFormControl-root MuiTextField-root form-control validar is-valid';
                }
                return '';
            }

        }

        if( div.childNodes[1].tagName === 'INPUT' ){
            if( div.childNodes[1].type === 'date' ) {
                if( div.childNodes[1].value === '' ){
                    div.childNodes[1].className = 'form-control is-invalid';
                    div.replaceChild( document.createElement('div'), div.childNodes[2] );
                    div.childNodes[2].innerHTML = `Seleccione una fecha válida`;
                    div.childNodes[2].className = 'invalid-feedback';
                } else {
                    if(/^dateEqualLess/.test(div.childNodes[1].id)) {
                        const value = inputDateToLong(div.childNodes[1].value)
                        if(value <= hoyMilisegundos()) {
                            div.childNodes[1].className = 'form-control is-valid';
                        } else {
                            div.childNodes[1].className = 'form-control is-invalid';
                            div.replaceChild( document.createElement('div'), div.childNodes[2] );
                            div.childNodes[2].innerHTML = 'Seleccione una fecha menor o igual que hoy';
                            div.childNodes[2].className = 'invalid-feedback';
                        }
                    } else if(/^dateEqualGreater/.test(div.childNodes[1].id)) {
                        const value = inputDateToLong(div.childNodes[1].value)
                        if(value + 86399000 >= hoyMilisegundos()) {
                            div.childNodes[1].className = 'form-control is-valid';
                        } else {
                            div.childNodes[1].className = 'form-control is-invalid';
                            div.replaceChild( document.createElement('div'), div.childNodes[2] );
                            div.childNodes[2].innerHTML = 'Seleccione una fecha mayor o igual que hoy';
                            div.childNodes[2].className = 'invalid-feedback';
                        }
                    } else {
                        div.childNodes[1].className = 'form-control is-valid';
                    }
                }
                return '';
            }else{
                const obj = comprobar( div.childNodes[1].value, div.childNodes[1].attributes.type.value );
                if( obj.status === false ){
                    div.childNodes[1].className = 'form-control is-invalid';
                    div.replaceChild( document.createElement('div'), div.childNodes[2] );
                    div.childNodes[2].innerHTML = `${obj.mensaje}`;
                    div.childNodes[2].className = 'invalid-feedback';
                }else{
                    div.childNodes[1].className = 'form-control is-valid';
                }
                return '';
            }
        }
        /* valida claves de módulo de proyectos */
        if( div.childNodes[1].tagName === 'DIV' ){
            if( div.childNodes[1].className === "passIcon" ){ /* Valida si el div pertenece al componente PasswordValidation */
                const obj = comprobar( div.childNodes[1].childNodes[1].value, div.childNodes[1].childNodes[1].attributes.type.value );
                if( obj.status === false ){
                    div.childNodes[1].childNodes[1].className = 'form-control is-invalid';
                    //div.replaceChild( document.createElement('div'), div.childNodes[1].childNodes[2] );
                    div.childNodes[1].childNodes[2].innerHTML = `${obj.mensaje}`;
                    div.childNodes[1].childNodes[2].className = 'invalid-feedback';
                }else{
                    div.childNodes[1].childNodes[1].className = 'form-control is-valid';
                }
                return '';
            }else{
                const obj = comprobar( div.childNodes[0].value, div.childNodes[0].attributes.type.value );
                if( obj.status === false ){
                    div.childNodes[0].className = 'form-control is-invalid';
                    div.replaceChild( document.createElement('div'), div.childNodes[1] );
                    div.childNodes[1].innerHTML = `${obj.mensaje}`;
                    div.childNodes[1].className = 'invalid-feedback';
                }else{
                    div.childNodes[0].className = 'form-control is-valid';
                }
                return '';
            }
        }
        /* --------------------------------- */
        if( div.childNodes[1].tagName === 'TEXTAREA' ){
            const obj = comprobar( div.childNodes[1].value, div.childNodes[1].attributes.type.value );
            if( obj.status === false ){
                div.childNodes[1].className = 'descripcion is-invalid';
                div.replaceChild( document.createElement('div'), div.childNodes[2] );
                div.childNodes[2].innerHTML = `${obj.mensaje}`;
                div.childNodes[2].className = 'invalid-feedback';
            }else{
                div.childNodes[1].className = 'descripcion is-valid';
            }
            return '';
        }

        if( div.childNodes[1].tagName === 'SELECT' ){
            if( div.childNodes[1].value === '' ){
                div.childNodes[1].className = 'form-control is-invalid skip-image';
                div.replaceChild( document.createElement('div'), div.childNodes[2] );
                div.childNodes[2].innerHTML = `Seleccione una opción`;
                div.childNodes[2].className = 'invalid-feedback';
            }else{
                div.childNodes[1].className = 'form-control is-valid skip-image';
            }
            return '';
        }
        return '';
    });
    return comprobarSubmit( idForm );
}

export function comprobarSubmit( idForm ){
    const formulario = document.getElementById( idForm );
    const inputs = formulario.getElementsByClassName( 'is-invalid' );
    if( inputs.length === 0 ){
        return true;
    }
    inputs[0].focus();
    return false;
}

/* Funcion que limpia el valor de un ref - Debe colocarse en un evento onBlur() dentro deun input */
export function trimValue( ref ){
    ref.current.value = ref.current.value.trim();
}

/* Funcion para convertir inputDate a longDate(Milisegundos) formato requerido para almacenar en Base de datos */
export function inputDateToLong( inputDate ){
    if( inputDate === null || inputDate === '' ){
        return inputDate;
    }else{
        try {
            const splitDate= !inputDate.includes('/') ? inputDate.split( '-' ) : inputDate.split( '/' ).reverse();
            return new Date(Date.UTC(parseInt( splitDate[0] ), parseInt( splitDate[1] )-1, parseInt( splitDate[2] ))).getTime(); //Restar -1 a posicion[1] correspondiente al mes
        } catch(e) {
            
        }
    }
}

/* Funcion para convertir longDate(Milisegundos) a inputDate( Formato válido para <inputs type="date"/> ) */
const meses = { Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12' };
export function longDateToInput( longDate, addYear=false ){
    if( longDate === null ){
        return null;
    }else{
        const dateDay = new Date(longDate).getUTCDate();
        const dateMonth = new Date(longDate).getUTCMonth()+1;
        let dateYear = new Date(longDate).getUTCFullYear();
        if(!isNaN(addYear)) {
            dateYear = Number(dateYear)+addYear;
        }
        return `${dateYear}-${(dateMonth<10) ? `0${dateMonth}` : dateMonth}-${(dateDay<10) ? `0${dateDay}` : dateDay}`;
    }
}

export function longDateToInputDate( longDate ){
    if( longDate === null ){
        return null;
    }else{
        const dateString = new Date( longDate ).toString().split( ' ', 4 );
        return `${dateString[2]}/${meses[dateString[1]]}/${dateString[3]}`;
    }
}

export function longDateToInputDateSafari( longDate ){
    if( longDate === null ){
        return null;
    }else{
        const dateString = new Date( longDate ).toString().split( ' ', 4 );
        return `${meses[dateString[1]]}/${dateString[2]}/${dateString[3]}`;
    }
}

/* Funcion para validar que fechaInicial sea < que fechaFinal */
export function compararFechas( fechaInicial, fechaFinal ){
    if( fechaFinal === '' ){
        return true;
    }else{
        if( fechaInicial < fechaFinal ){
            return true;
        }else{
            return false;
        }
    }
}

/* Funcion para eliminar campos opcionales de un objeto (elementos que sean === '') */
export function deleteOptionals( objectRequest ){
    var temporal = objectRequest;
    Object.keys( temporal ).forEach( key => {
        if( temporal[key] === '' ){
            delete temporal[key];
        }
    });
    return temporal;
}

export function amountToFloat( value ) {
    return parseFloat(value.replace(/,/g,'').replace('$',''));
}

export function floatToAmount( value, decimales=2 ) {
    let valor = (/\$/g.test(value)) ? amountToFloat(value) : parseFloat(value);
    return '$'+valor.toLocaleString('en-US', {minimumFractionDigits: decimales});
}

export function csvDownload (data, name) {
    const items = data
    const filename = name.split('.')[0].toUpperCase()+'.csv' || `EXPORT.csv`
  
    const header = Array.from(
      new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
    )
    let csv = items.map(row =>
        header.map(fieldName =>
            JSON.stringify(row[fieldName] ? `${row[fieldName]}`.toUpperCase().replace(/,/g,'') : row[fieldName] || '')
        ).join(',')
    )
    const headerMayus = header.map(element => element.toUpperCase());
    csv.unshift(headerMayus.join(','))
    csv = csv.join('\r\n')
    const blob = new Blob(["\uFEFF"+csv], {
      type: 'text/csv;charset=UTF-8',
    })
  
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename)
      return
    }
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = filename
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

function replaceMonto( monto ){
    const montoSinS = monto.replace( '$', '' );
    return montoSinS.replace( ',', '' );
}

export function validarMontoMinMax( idForm, classInputMonto, montoMin, montoMax ){
    const formulario = document.getElementById( idForm );
    const div = formulario.getElementsByClassName( classInputMonto )[0];
    const monto = parseFloat( replaceMonto(div.childNodes[1].value) );
    if( monto ){
        if( montoMin && montoMax ){
            if( monto < montoMin || monto > montoMax ){
                div.childNodes[1].className = 'form-control is-invalid is-warning';
                div.replaceChild( document.createElement('div'), div.childNodes[2] );
                div.childNodes[2].innerHTML = `El monto ingresado está fuera del rango (${floatToAmount(montoMin, 2)} - ${floatToAmount(montoMax, 2)})`;
                div.childNodes[2].className = 'invalid-feedback warning-feedback';
            }else{
                div.childNodes[1].className = 'form-control is-valid';
            }
        }else{
            div.childNodes[1].className = 'form-control is-valid';
        }
    }else{
        div.childNodes[1].className = 'form-control';
    }
}