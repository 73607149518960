import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupohit_url } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../../../Services/Spinner/SpinnerOval';

import NominasRelacionLaboral from './NominasRelacionLaboral';
import { connect } from 'react-redux';

const AsignacionNomina = ({ error, informacion, usuario, accesosNominas, setAccesosNominas }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    const [ nominasSuperior, setNominasSuperior ] = useState( null );
    useEffect(() => {
        axiosNominasSuperior();
        // eslint-disable-next-line
    }, []);

    /* Axios para obtener las nóminas del usuario superior */
    const axiosNominasSuperior = () => {
        axios.get( `${servicios_grupohit_url}/acceso/nomina/${usuario.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominasSuperior( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setNominasSuperior([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <React.Fragment>
            { nominasSuperior && accesosNominas ?
                <React.Fragment>
                    { nominasSuperior.map( relacionLaboral => (
                        <NominasRelacionLaboral key={relacionLaboral.id} relacionLaboral={relacionLaboral} accesosNominas={accesosNominas} setAccesosNominas={setAccesosNominas}
                            elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}/>
                    ))}
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    usuario: state.SessionReducer.usuario,
});

export default connect(mapStateToProps, null)(AsignacionNomina);